import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getDMEODataDefinition } from "../../../api/dmeoApi";
interface Data {
  KPI: string;
  KPI_definition: string;
}

const DMEOData = () => {
  const [data, setData] = useState<Array<Data>>();

  useEffect(() => {
    getDMEODataDefinition().then((response: any) => {
      if (response && "error" in response) {
        toast.error("Error fetching data updates");
      } else {
        setData(response);
      }
    });
  }, []);

  return (
    <div className="data_documentation">
      <h3>DMEO - DATA OVERVIEW</h3>

      <br />

      <table className="docs_table">
        <thead>
          <tr>
            <th>KPI</th>
            <th>Definition</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((dataItem, index) => (
            <tr key={index}>
              <td>{dataItem.KPI}</td>
              <td style={{ whiteSpace: "pre-wrap" }}>{dataItem.KPI_definition}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <br />
    </div>
  );
};

export default DMEOData;
