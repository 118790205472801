import moment from "moment";
import "moment-fquarter";

export const MIN_SELECTABLE_DATE = "01/04/2019";

export const ONE_MARKET_REGIONS: Record<string, string> = { "IML Global": "IML Global", "IML China": "China" };

export const OCE_DATE_FILTER_OPTIONS = [
  "Last week",
  "This month",
  "Last month",
  "This quarter",
  "Last quarter",
  "This financial year",
  "Last financial year",
];

export const PMO_DATE_FILTER_OPTIONS = ["Last quarter", "This financial year"];
export const MIDAS_DATE_FILTER_OPTIONS = ["Last 6 months", "Last 12 months"];
export const SEO_DATE_FILTER_OPTIONS = [
  "This month",
  "Last month",
  "This quarter",
  "Last quarter",
  "This financial year",
  "Last financial year",
];
export const JOURNEY_EXCELLENCE_DATE_FILTER_OPTIONS = [
  "Last month",
  "This quarter",
  "Last quarter",
  "This financial year",
  "Last financial year",
];
export const CPO_OPERATIONAL_DATE_FILTER_OPTIONS = ["Last week", "This month", "Last month", "This quarter", "Last quarter"];

export const DEFAULT_DATE_RANGE = "Last week";

export const NISSAN_DATSUN = "Nissan Datsun";
export const NISSAN = "Nissan";
export const DATSUN = "Datsun";
export const INFINITI = "Infiniti";

export const ALL_OPTION = "All ";
export const ALL_OPTION_NO_SPACE = "All";
export const ALL_COUNTRIES = "All countries";
export const DEFAULT_METRIC = "visits";

export const DATE_FILTER_VALUE_MAPPING: { [index: string]: string } = {
  "Last week": "Last week",
  "Last 6 months": "Last 6 months",
  "Last 12 months": "Last 12 months",
  "Last month available": moment().subtract(1, "months").format("MMMM YYYY"),
  "This month": moment().format("MMMM YYYY"),
  "Last month": moment().subtract(1, "months").format("MMMM YYYY"),

  "This quarter": `${moment().fquarter().toString().split(" ")[0]} FY${moment().fquarter().toString().split("/")[0].substr(-2)}`,

  "Last quarter": `${moment().subtract(1, "quarter").fquarter().toString().split(" ")[0]} FY${moment()
    .subtract(1, "quarter")

    .fquarter()
    .toString()
    .split("/")[0]
    .substr(-2)}`,

  "This financial year": `FY${moment().fquarter().year.toString().substr(-2)}`,

  "Last financial year": `FY${moment().subtract(1, "year").fquarter().year.toString().substr(-2)}`,
};

export const DATE_FILTER_VALUE_MAPPING_MSRP: { [index: string]: string } = {
  "Last week": "Last week",
  "Last 6 months": "Last 6 months",
  "Last 12 months": "Last 12 months",
  "Last month available": moment().subtract(1, "months").format("MMMM YYYY"),
  "This month": moment().subtract(1, "months").format("MMMM YYYY"),
  "Last month": moment().subtract(2, "months").format("MMMM YYYY"),

  "This quarter": `${moment().fquarter().toString().split(" ")[0]} FY${moment().fquarter().toString().split("/")[0].substr(-2)}`,

  "Last quarter": `${moment().subtract(1, "quarter").fquarter().toString().split(" ")[0]} FY${moment()
    .subtract(1, "quarter")

    .fquarter()
    .toString()
    .split("/")[0]
    .substr(-2)}`,

  "This financial year": `FY${moment().fquarter().year.toString().substr(-2)}`,

  "Last financial year": `FY${moment().subtract(1, "year").fquarter().year.toString().substr(-2)}`,
};

export const VOC_DATE_FILTER_OPTIONS = ["Last month", "This quarter", "Last quarter", "This financial year", "Last financial year"];

export const USE_OWNERSHIP_DATE_FILTER_OPTIONS = ["Last month", "Last quarter", "This financial year", "Last financial year"];

export const DEFAULT_CCS_PARAMETERS = {
  date_range: "",
  brand: "Nissan",
  market: ALL_OPTION,
  region: ALL_OPTION,
  model: ALL_OPTION,
  service_type: ALL_OPTION_NO_SPACE,
  trigger_type: "User",
  user_engagement_market: ALL_OPTION_NO_SPACE,
  enrolled_user_market: ALL_OPTION_NO_SPACE,
};

export const DEFAULT_APP_RATINGS_PARAMETERS = {
  date_range: "",
  brand: "Nissan",
  region: ALL_OPTION,
  market: ALL_COUNTRIES,
  app_store: ALL_OPTION_NO_SPACE,
  app: ALL_OPTION_NO_SPACE,
  topic: ALL_OPTION_NO_SPACE,
};

export const DEFAULT_OCE_PARAMETERS = {
  date_range: "Last week",
  brand: "Nissan",
  region: ALL_OPTION,
  market: ALL_COUNTRIES,
  model: ALL_OPTION_NO_SPACE,
  last_touch_marketing_channel: ALL_OPTION,
  last_touch_marketing_channel_breakdown: ALL_OPTION,
  metric: "visits",
  granularity: "weekly",
};

export const DEFAULT_CPO_PARAMETERS = {
  date_range: "Last week",
  brand: "Nissan",
  region: ALL_OPTION,
  market: ALL_COUNTRIES,
  model: ALL_OPTION_NO_SPACE,
  last_touch_marketing_channel: ALL_OPTION,
  last_touch_marketing_channel_breakdown: ALL_OPTION,
  metric: "visits",
  granularity: "weekly",
};

export const DEFAULT_MIDAS_PARAMETERS = {
  date_range: "Last 6 months",
  yr_scnr_rv: "FY 23 ACT,FY 23 11x1",
  brand: ALL_OPTION_NO_SPACE,
  nsc: ALL_OPTION_NO_SPACE,
  local_currency_name: "CNY",
  fmi_sort: "FMI Total",
  mdl_nm_rev: ALL_OPTION_NO_SPACE,
  model: ALL_OPTION_NO_SPACE,
  period: "FY Quarter",
  unit: "K",
  mc_group: ALL_OPTION_NO_SPACE,
  subgroup: ALL_OPTION_NO_SPACE,
  market: ALL_OPTION_NO_SPACE,
  year_filter: "FY22",
  granularity: "Month",
  verticalScroll: false,
};

export const DEFAULT_CPO_OPERATIONAL_PARAMS = {
  date_range: "Last week",
  brand: "Nissan",
  market: "Belgium",
  metric: "visits",
  dealer: ALL_OPTION_NO_SPACE,
  outlet: ALL_OPTION_NO_SPACE,
  region: ALL_OPTION_NO_SPACE,
  territory: ALL_OPTION_NO_SPACE,
  model: ALL_OPTION_NO_SPACE,
  year: ALL_OPTION_NO_SPACE,
  price: ALL_OPTION_NO_SPACE,
  mileage: ALL_OPTION_NO_SPACE,
  certified: ALL_OPTION_NO_SPACE,
  district: ALL_OPTION_NO_SPACE,
  area: ALL_OPTION_NO_SPACE,
  granularity: "weekly",
};

export const DEFAULT_PARAMETER_VALUES = {
  date_range: "",
  brand: NISSAN,
  region: ALL_OPTION,
  market: ALL_COUNTRIES,
  model: ALL_OPTION_NO_SPACE,
  last_touch_marketing_channel: ALL_OPTION,
  last_touch_marketing_channel_breakdown: ALL_OPTION,
  mobile_device_type: ALL_OPTION,
  metric: DEFAULT_METRIC,
  app_store: ALL_OPTION_NO_SPACE,
  app: ALL_OPTION_NO_SPACE,
  granularity: "week",
  topic: ALL_OPTION_NO_SPACE,
  average_models: "",
  service_type: ALL_OPTION_NO_SPACE,
  trigger_type: "User",
  user_engagement_market: ALL_OPTION_NO_SPACE,
  enrolled_user_market: ALL_OPTION_NO_SPACE,
  grade: ALL_OPTION_NO_SPACE,
  star_rating_market: ALL_OPTION_NO_SPACE,
  objective_completion_market: ALL_OPTION_NO_SPACE,
  product: ALL_OPTION_NO_SPACE,
};

export const DEFAULT_EUROPE_ACTIVATION_PARAMETERS = {
  date_range: "",
  market: "",
  market_group: "G8",
  model: ALL_OPTION_NO_SPACE,
  sales_channel: "Private",
  dealer: ALL_OPTION_NO_SPACE,
  activation_format: "rates",
};
export const DEFAULT_MTM_PARAMETERS = {
  date_range: "Last 6 months",
  market: "Argentina",
  granularity: "monthly",
  model: "Nissan",
};

export const DEFAULT_SEO_PARAMETERS = {
  date_range: "Last month",
  hostname: ALL_OPTION_NO_SPACE,
  market: "United States",
  mobile_device_type: ALL_OPTION_NO_SPACE,
  search_engine: "Google",
  impressions_disabled: false,
  visits_disabled: false,
  query: "All",
  landing_page: "All",
  visitor_country: "All",
  visits_search_term: "",
  impressions_search_term: "",
  visits_search_type: "",
  impressions_search_type: "",
};

export const DEVICE_FILTER_OPTIONS = [ALL_OPTION_NO_SPACE, "Desktop", "Mobile", "Tablet"];
export const PURCHASE_FUNNEL_MARKETS = ["United Kingdom", "China", "Japan", "United States", "Mexico", "Brazil", "Canada"];

//MTM REPORT FILTERS
export const MTM_DATE_OPTIONS = ["Monthly view", "Quarterly view"];

export const MTM_MIDDLE_EAST_MARKETS = ["Bahrain", "Kuwait", "Qatar", "Oman", "Abu Dhabi", "Dubai"];

export const MTM_EUROPE_G5 = ["United Kingdom", "Spain", "Italy", "Denmark", "France"];

export const MTM_MARKETS = [
  "United States",
  "Mexico",
  "Chile",
  "Brazil",
  "Argentina",
  "Japan",
  "Canada",
  "China",
  "Thailand",
  "Philippines",
  "South Africa",
  "Saudi Arabia",
  "India",
  "Australia",
  ...MTM_MIDDLE_EAST_MARKETS,
  ...MTM_EUROPE_G5,
];

export const MTM_LAST_DATA_REFRESH = "2021-07-01";

//PURCHASE FUNNEL CONSTANTS
export const DEFAULT_PURCHASE_FUNNEL_PARAMETERS = {
  date_range: "This financial year",
  brand: "Nissan",
  market: "All",
  lead_group: ALL_OPTION_NO_SPACE,
  leads_source: ALL_OPTION_NO_SPACE,
};

//PURCHASE FUNNEL CONSTANTS
export const DEFAULT_MSRP_PARAMETERS = {
  date_range: "This financial year",
  brand: "Nissan",
  market: "Japan",
  currency: ALL_OPTION_NO_SPACE,
};

export const PURCHASE_FUNNEL_DATE_FILTER_OPTIONS = [
  "Last month",
  "This quarter",
  "Last quarter",
  "This financial year",
  "Last financial year",
];

export const NEW_PURCHASE_FUNNEL_MARKETS = ["China", "United States", "Canada", "All", "Brazil", "Mexico", "United Kingdom", "Japan"];

//TOP FLOP CONSTANTS
export const DEFAULT_TOP_FLOP_PARAMETERS = {
  dates: "",
  brand: ALL_OPTION_NO_SPACE,
  region: ALL_OPTION_NO_SPACE,
  model: ALL_OPTION_NO_SPACE,
  services: ALL_OPTION_NO_SPACE,
  service_group: ALL_OPTION_NO_SPACE,
  service_type: ALL_OPTION_NO_SPACE,
  service_trigger: ALL_OPTION_NO_SPACE,
  uid: ALL_OPTION_NO_SPACE,
};

export const DEFAULT_VOC_PARAMETERS = {
  date_range: "This financial year",
  brand: "Nissan",
  product: "Configurator V2",
  market: ALL_OPTION_NO_SPACE,
};

export const DEFAULT_VOC_QUICK_AFTER_SALES_PARAMETERS = {
  date_range: "This financial year",
  brand: "Nissan",
  dealer: ALL_OPTION_NO_SPACE,
  market: ALL_OPTION_NO_SPACE,
  model: ALL_OPTION_NO_SPACE,
  // model_type: ALL_OPTION_NO_SPACE,
};

export const DIS_PRESET_DATE_PARAMS = [
  "This month",
  "Last month",
  "This quarter",
  "Last quarter",
  "This financial year",
  "Last financial year",
];

export const DEFAULT_BRAND_PERFORMANCE_PARAMETERS = {
  date_range: "Last quarter",
  brand: "Nissan",
  market: "United States",
  model: "CORPORATE",
  competitor: ALL_OPTION_NO_SPACE,
  granularity: "monthly",
  category: "Total",
  sub_category: "Total",
  attribute: ALL_OPTION_NO_SPACE,
  nissan_competitor: "nissan_competitor",
  date_range_brand_perf: "Q1 FY 2023,Q2 FY 2023,Q3 FY 2023,Q4 FY 2023",
  performance_page: "volume",
};

export const DEFAULT_BRAND_PERFORMANCE_DATE_PARAMS = [
  "Last month",
  "This quarter",
  "Last quarter",
  "This financial year",
  "Last financial year",
];

export const DEFAULT_EXECUTIVE_PERFORMANCE_PARAMETERS = {
  date_range: "This financial year",
  brand: "Nissan",
  market: "All",
  product_category: ALL_OPTION_NO_SPACE,
  product: "All",
  lead_group: ALL_OPTION_NO_SPACE,
  leads_source: ALL_OPTION_NO_SPACE,
};

export const DEFAULT_PRODUCT_PERFORMANCE_PARAMETERS = {
  date_range: "Last quarter",
  brand: "Nissan",
  product: "Configurator V2",
  market: "Brazil",
};

export const DEFAULT_JOURNEY_EXCELLENCE_PARAMETERS = {
  date_range: "Last quarter",
  brand: "Nissan",
  market: ALL_OPTION_NO_SPACE,
  currency: "USD",
};

export const DEFAULT_ECOMMERCE_PARAMETERS = {
  date_range: "Last quarter",
  brand: "Nissan",
  market: ALL_OPTION_NO_SPACE,
  model: ALL_OPTION_NO_SPACE,
  product: ALL_OPTION_NO_SPACE,
};

export const DEFAULT_PMO_PARAMETERS = {
  date_range: "Last quarter",
  brand: "Nissan",
  market: "Australia",
  model: "Navara",
  granularity: "monthly",
};

export const DEFAULT_CCS_ANALYSIS_PARAMETERS = {
  date_range: "",
  brand: "Nissan",
  market: ALL_OPTION_NO_SPACE,
  model: ALL_OPTION_NO_SPACE,
};

export const DEFAULT_USE_OWNERSHIP_PARAMETERS = {
  date_range: "Last quarter",
  brand: "Nissan",
  market: ALL_OPTION_NO_SPACE,
};

export const DEFAULT_MARKET_PERFORMANCE_SUMMARY_PARAMETERS = {
  date_range: "Last quarter",
  yr_scnr_rv: "",
  brand: "Nissan",
  market: "Argentina",
  model: "Brand",
};

export const DEFAULT_DMEO_PARAMETERES = {
  date_range: "Last quarter",
  market: "All",
  channel: "All",
  campaign_objective: "All",
  model: "All",
};

export const DEFAULT_EXECUTIVE_SUMMARY_DIS = {
  date_range: "This financial year",
  brand: "Nissan",
  market: ALL_OPTION_NO_SPACE,
  lead_group: ALL_OPTION_NO_SPACE,
  leads_source: ALL_OPTION_NO_SPACE,
};

export const DEFAULT_EXECUTIVE_SUMMARY_PRODUCT_PERFORMANCE = {
  date_range: "Last quarter",
  brand: "Nissan",
  region: ALL_OPTION,
  market: ALL_COUNTRIES,
  product_category: ALL_OPTION_NO_SPACE,
  product: ALL_OPTION_NO_SPACE,
};
