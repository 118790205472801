import { ErrorBoundary } from "@sentry/react";
import React from "react";
import { KeyIndicatorMetric } from ".";
import { ErrorMsg } from "../../../components/AppMessages";
import { KeyIndicator } from "../../../constants/interface";
import { useVisible } from "../../../hooks";

interface Props {
  availableKbas: Array<{ name: string; key: string; metric_type: string; metric_visits: number | null; visits: number | null }>;
  availableLeads: Array<{ name: string; key: string; metric_type: string; metric_visits: number | null; visits: number | null }>;
  Current?: any;
  YoY?: any;
  PoP?: any;
  isCpoDashboard?: boolean;
  cpoOce?: any;
}

export const KeyIndicatorsVolume = (props: KeyIndicator & Props) => {
  const {
    activeMetric,
    popLabel,
    availableKbas,
    availableLeads,
    PoP: WoW,
    Current,
    YoY,
    showPoP,
    onHandleMetricClick,
    isCpoDashboard,
    cpoOce,
  } = props;
  const { wrapperRef: kbaTooltipRef, isVisible: kbaTooltipVisible, setIsVisible: setKbaTooltipVisible } = useVisible(false);
  const { wrapperRef: leadTooltipRef, isVisible: leadTooltipVisible, setIsVisible: setLeadTooltipVisible } = useVisible(false);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div id="volumes" className="indicator_funnel">
        <div
          className={`content ${activeMetric && activeMetric === "visits" ? "active" : ""} volume`}
          id="visits"
          data-test-id="visits"
          data-metric="visits"
          onClick={onHandleMetricClick}
        >
          <KeyIndicatorMetric
            metric_value={Current ? (isCpoDashboard ? Current[0]?.visits : Current[0]?.visits) : null}
            metric_yoy={YoY ? YoY[0]?.perc_visits : null}
            metric_wow={WoW ? WoW[0]?.perc_visits : null}
            showPoP={showPoP}
            metric={"visits"}
            popLabel={popLabel}
            showTooltip={false}
            isConversion={false}
            isCpoDashboard={isCpoDashboard}
            cpo_oce={cpoOce}
            title="Visits"
          />
        </div>
        <div
          className={`content ${activeMetric && activeMetric === "kbas" ? "active" : ""} volume`}
          id="kba"
          data-test-id="kba"
          data-metric="kbas"
          onClick={onHandleMetricClick}
          ref={kbaTooltipRef}
        >
          <KeyIndicatorMetric
            availableMetric={availableKbas}
            metric_value={Current ? (isCpoDashboard ? Current[0]?.kbas : Current[0]?.kbas) : null}
            metric_yoy={YoY ? YoY[0]?.perc_kbas : null}
            metric_wow={WoW ? WoW[0]?.perc_kbas : null}
            showPoP={showPoP}
            metric={"kbas"}
            popLabel={popLabel}
            showTooltip={true}
            kbaTooltipVisible={kbaTooltipVisible}
            leadTooltipVisible={leadTooltipVisible}
            setLeadTooltipVisible={setLeadTooltipVisible}
            setKbaTooltipVisible={setKbaTooltipVisible}
            isConversion={false}
            isCpoDashboard={isCpoDashboard}
            cpo_oce={cpoOce}
            title="KBA visits"
          />
        </div>
        <div
          className={`content ${activeMetric && activeMetric === "leads" ? "active" : ""} volume`}
          id="leads"
          data-test-id="leads"
          data-metric="leads"
          onClick={onHandleMetricClick}
          ref={leadTooltipRef}
        >
          <KeyIndicatorMetric
            availableMetric={availableLeads}
            metric_value={Current ? (isCpoDashboard ? Current[0]?.leads : Current[0]?.leads) : null}
            metric_yoy={YoY ? YoY[0]?.perc_leads : null}
            metric_wow={WoW ? WoW[0]?.perc_leads : null}
            showPoP={showPoP}
            metric={"leads"}
            popLabel={popLabel}
            showTooltip={true}
            kbaTooltipVisible={kbaTooltipVisible}
            leadTooltipVisible={leadTooltipVisible}
            setLeadTooltipVisible={setLeadTooltipVisible}
            setKbaTooltipVisible={setKbaTooltipVisible}
            isConversion={false}
            isCpoDashboard={isCpoDashboard}
            cpo_oce={cpoOce}
            title="Digital lead visits"
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};
