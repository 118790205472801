import { ErrorBoundary } from "@sentry/react";
import "moment";
import "moment-fquarter";
import moment from "moment/moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import "../../../../assets/styles/component/Ratings.scss";
import { ErrorMsg } from "../../../../components/AppMessages";
import { CYAN } from "../../../../constants";
import { CCSSubscriptionsData, IAppReviewBreakdown } from "../../../../constants/interface/ccsCockpit";
import { convertToPercentage, isNull } from "../../../../utils/utilityFunctions";
import { CockpitContext } from "../../subs/CockpitContext";
import { sortMonthYear } from "../subs/helpers";
import { AppReviewTile, DisabledTile, SatisfactionTile, ValueWithFY } from "../tiles";

interface Props {
  showMetric?: boolean;
}

export const CustomerQualityTiles = (props: Props) => {
  const { showMetric } = props;

  const { setMetric } = useContext(CockpitContext);

  const {
    all_data: allDataSbscriptionsData,
    all_months: monthlySubscriptionData,
    all_financial_years: allSubscriptionData,
    yoy_all_months: yoyMonthsSubscriptionData,
    yoy_all_data: yoyAllSubscriptionData,
    financial_year_count: numberOfYears,
  }: CCSSubscriptionsData = useSelector((state: RootStateOrAny) => state.ccs_cockpit.subscriptions);
  const {
    app_review: appReviewLoading,
    call_centre: callCentreLoading,
    ccs_subscription: ccsSubscriptionLoading,
    renewal_and_churn_rate: renewalChurnRateLoading,
  } = useSelector((state: RootStateOrAny) => state.loading);
  const { date_range: dateParamValue, region: regionParamValue } = useSelector((state: RootStateOrAny) => state.parameters);

  const appRatings: any = useSelector((state: RootStateOrAny) => state.ccs_cockpit.app_ratings);
  const appRatingsChartData: any = useSelector((state: RootStateOrAny) => state.ccs_cockpit.app_ratings_chart_data);
  const renewalChurnRate: any = useSelector((state: RootStateOrAny) => state.ccs_cockpit.renewal_and_churn_rate);
  const renewalChurnRateChartData: any = useSelector((state: RootStateOrAny) => state.ccs_cockpit.renewal_and_churn_rate_chart_data);

  const { breakdown }: IAppReviewBreakdown = useSelector((state: RootStateOrAny) => state.ccs_cockpit.app_review_breakdown);

  const dates: Array<string> = useMemo(() => dateParamValue.split(","), [dateParamValue]);

  const [fyears, setFyears] = useState<Array<number>>([]);

  const [isSingleYear, setIsSingleYear] = useState<boolean>(false);

  useEffect(() => {
    setIsSingleYear(numberOfYears && numberOfYears < 2 ? true : false);
  }, [dateParamValue, numberOfYears]);

  useEffect(() => {
    const map = new Map();
    setFyears([]);
    for (const date of dates) {
      const yearFromDate = parseInt(moment(date).format("YYYY"));
      if (!map.has(yearFromDate)) {
        map.set(yearFromDate, 1);
        setFyears((fyears) => [...fyears, yearFromDate]);
      }
    }
  }, [dates, monthlySubscriptionData]);

  const [avgEvRating, setAvgEvRating] = useState<number | null>(null);
  const [avgServiceRating, setAvgServiceRating] = useState<number | null>(null);
  const [avgMyNissanRating, setAvgMyNissanRating] = useState<number | null>(null);
  const [avgMyInfinitiRating, setAvgMyInfinitiRating] = useState<number | null>(null);
  const [comparisonAvgEvRating, setComparisonAvgEvRating] = useState<number | null>(null);
  const [comparisonAvgServiceRating, setComparisonAvgServiceRating] = useState<number | null>(null);
  const [comparisonAvgMyNissanRating, setComparisonAvgMyNissanRating] = useState<number | null>(null);
  const [comparisonAvgMyInfinitiRating, setComparisonAvgMyInfinitiRating] = useState<number | null>(null);

  const subscriptionChartData = useMemo(() => {
    if (!monthlySubscriptionData) return;

    return monthlySubscriptionData
      .filter((data) => fyears.includes(data.year))
      .map(({ year, month, churn_rate, renewal_ratio, bp_renewal_rate }) => ({
        title: month
          ? `${moment.monthsShort(month - 1)} ${year.toString().slice(year.toString().length - 2, year.toString().length)}`
          : null,
        churnRate: isNull(churn_rate) ? null : parseInt(convertToPercentage(churn_rate, 0), 10),
        renewalRate: isNull(renewal_ratio) ? null : parseInt(convertToPercentage(renewal_ratio, 0), 10),
        bp_renewal_rate: isNull(bp_renewal_rate) ? null : parseInt(convertToPercentage(bp_renewal_rate, 0), 10),
        color: CYAN,
      }));
  }, [allSubscriptionData, fyears]);

  const avgChurnRenewal_Rates = (monthlydata: any, key_to_check: string) => {
    if (monthlydata.length === 0) return null;
    let sum = 0;
    let non_null_bp = 0;

    for (const data of monthlydata) {
      if (key_to_check === "churn_rate" && data.churn_rate !== null) {
        sum += data.churn_rate;
      }
      if (key_to_check === "renewal_rate" && data.renewal_ratio !== null) {
        sum += data.renewal_ratio;
      }
      if (key_to_check === "bp_renewal_rate" && data.bp_renewal_rate !== null) {
        sum += data.bp_renewal_rate;
        non_null_bp += 1;
      }
    }
    return sum === 0 ? null : key_to_check === "bp_renewal_rate" ? sum / non_null_bp : sum / monthlydata.length;
  };
  const monthlyYoYDataAverages = useMemo(() => {
    if (!yoyMonthsSubscriptionData) return null;
    const selectedMonths: (string | null)[] = [];
    //check if date range is not more than a year
    if (dates.length <= monthlySubscriptionData.length) {
      //get an array of selected months in the date range
      for (const date of dates) {
        selectedMonths.push(moment().month(date.split(" ")[0]).format("M").toString());
      }
      // get monthly YoYs for months in the selected date range
      const selectedMonthlyYoY = monthlySubscriptionData
        .filter((data) => fyears.includes(data.year))
        .filter((data) => selectedMonths.includes(data.month ? data.month.toString() : null));

      // get monthly YoYs for months corresponding to the selected date range but for the previous year
      const previousMonthlyYoY = yoyMonthsSubscriptionData
        .filter((data) => data.year === fyears[0] - 1)
        .filter((data) => selectedMonths.includes(data.month ? data.month.toString() : null));

      //calculate CHURN RATE average for selected months YoY and previous months YoY
      const avgMonthlyYoY = avgChurnRenewal_Rates(selectedMonthlyYoY, "churn_rate");
      const prevAvgMonthly = avgChurnRenewal_Rates(previousMonthlyYoY, "churn_rate");

      //calculate RENEWAL RATE average for selected months YoY and previous months YoY
      let avgBpRenewalRateMonthlyYoY;
      if (regionParamValue === "Japan") avgBpRenewalRateMonthlyYoY = avgChurnRenewal_Rates(previousMonthlyYoY, "bp_renewal_rate");

      const avgRenewalMonthlyYoY = isNull(avgMonthlyYoY) ? null : 1 - Number(avgMonthlyYoY);
      const prevRenewalAvgMonthly = avgChurnRenewal_Rates(previousMonthlyYoY, "renewal_rate");

      return { avgMonthlyYoY, prevAvgMonthly, avgRenewalMonthlyYoY, prevRenewalAvgMonthly, avgBpRenewalRateMonthlyYoY };
    }
  }, [dates, yoyMonthsSubscriptionData, fyears, regionParamValue]);

  const avgBp = useMemo(() => {
    if (!monthlySubscriptionData) return null;

    //calculate BP target average for selected months YoY and previous months YoY
    let avgBpRenewalRateMonthlyYoY;
    if (regionParamValue === "Japan") avgBpRenewalRateMonthlyYoY = avgChurnRenewal_Rates(monthlySubscriptionData, "bp_renewal_rate");

    return avgBpRenewalRateMonthlyYoY;
  }, [dates, yoyMonthsSubscriptionData, fyears, regionParamValue]);

  const appRatingChartData = useMemo(() => {
    if (appRatingsChartData?.Current) {
      const formattedChartData = appRatingsChartData?.Current?.reduce(
        (result: { [index: string]: Record<string, number | string> }, row: any) => {
          const { date, application, rating } = row;
          const label = moment(date).format("MMM YY");

          const app_key = application?.replace(/\s/g, "_");

          if (Object.prototype.hasOwnProperty.call(result, label)) {
            result[label] = { ...result[label], [app_key]: rating };
          } else {
            result[label] = { [app_key]: rating };
          }

          return result;
        },
        {}
      );

      const activeDates = dates.reverse();

      const finalChartData = sortMonthYear(activeDates).map((activeDate) => {
        const label = moment(activeDate, "MMMM YYYY").format("MMM YY");
        const data = formattedChartData[label];

        return { ...data, label };
      });

      return finalChartData;
    }
  }, [dates, appRatingsChartData]);

  const formattedRenewalChurnRateChartData = useMemo(() => {
    if (appRatingsChartData?.Current) {
      const formattedChartData = renewalChurnRateChartData?.Current?.reduce(
        (result: { [index: string]: Record<string, number | string> }, row: any) => {
          const { date, churn_rate, renewal_ratio, bp_renewal_rate } = row;
          const label = moment(date).format("MMM YY");

          if (Object.prototype.hasOwnProperty.call(result, label)) {
            result[label] = { ...result[label], churnRate: churn_rate, renewalRate: renewal_ratio, bp_renewal_rate };
          } else {
            result[label] = { churnRate: churn_rate, renewalRate: renewal_ratio, bp_renewal_rate };
          }

          return result;
        },
        {}
      );

      const activeDates = dates.reverse();

      const finalChartData = sortMonthYear(activeDates).map((activeDate) => {
        const label = moment(activeDate, "MMMM YYYY").format("MMM YY");
        const data = formattedChartData[label];

        return { ...data, label };
      });

      return finalChartData;
    }
  }, [dates, renewalChurnRateChartData]);

  useEffect(() => {
    if (appRatings?.Current) {
      setAvgEvRating(appRatings?.Current?.find((item: any) => item.application == "NissanConnect EV")?.rating);
      setAvgServiceRating(appRatings?.Current?.find((item: any) => item.application == "NissanConnect Services")?.rating);
      setAvgMyNissanRating(appRatings?.Current?.find((item: any) => item.application == "MyNISSAN")?.rating);
      setAvgMyInfinitiRating(appRatings?.Current?.find((item: any) => item.application == "MyINFINITI")?.rating);
    } else {
      setAvgEvRating(null);
      setAvgServiceRating(null);
      setAvgMyNissanRating(null);
      setAvgMyInfinitiRating(null);
    }
  }, [appRatings]);

  return (
    <div className={`section_content ${showMetric ? "isMetric" : ""}`}>
      {regionParamValue == "China" ? (
        <DisabledTile title={"App ratings"} disableMessage={""} onClick={() => setMetric("Customer quality")} />
      ) : (
        <ErrorBoundary fallback={<ErrorMsg />}>
          <AppReviewTile
            myNissanRating={avgMyNissanRating}
            comparisonMyNissanRating={comparisonAvgMyNissanRating}
            myInfinitiRating={avgMyInfinitiRating}
            comparisonMyInfinitiRating={comparisonAvgMyInfinitiRating}
            servicesRating={avgServiceRating}
            evRating={avgEvRating}
            comparisonServicesRating={comparisonAvgServiceRating}
            comparisonEvRating={comparisonAvgEvRating}
            chartData={showMetric ? appRatingChartData : undefined}
            isLoading={appReviewLoading}
            data-target="customer_quality"
            onClick={() => setMetric("Customer quality")}
          />
        </ErrorBoundary>
      )}

      {regionParamValue == "China" ? (
        <DisabledTile title={"Satisfaction (call center)"} disableMessage={""} onClick={() => setMetric("Customer quality")} />
      ) : (
        <ErrorBoundary fallback={<ErrorMsg />}>
          <SatisfactionTile
            showMetric={showMetric}
            isLoading={callCentreLoading}
            data-target="customer_quality"
            onClick={() => setMetric("Customer quality")}
          />
        </ErrorBoundary>
      )}

      {/* For Renewal rates we take value from third element monthlyYoYDataAverages */}
      {/* For Renewal rates we take comparisonValue from fourth element monthlyYoYDataAverages */}
      {regionParamValue == "China" ? (
        <DisabledTile title={"Renewal rate"} disableMessage={""} onClick={() => setMetric("Customer quality")} />
      ) : (
        <ErrorBoundary fallback={<ErrorMsg />}>
          <ValueWithFY
            title={"Renewal rate"}
            value={renewalChurnRate?.Current ? renewalChurnRate?.Current[0]?.renewal_ratio : null}
            comparisonValue={renewalChurnRate?.YoY ? renewalChurnRate?.YoY[0]?.renewal_ratio : null}
            bpComparisonValue={renewalChurnRate?.Current ? renewalChurnRate?.Current[0]?.bp_renewal_rate_total : null}
            bpPts={renewalChurnRate?.YoY ? renewalChurnRate?.YoY[0]?.pts_bp : null}
            chartData={showMetric ? formattedRenewalChurnRateChartData : undefined}
            showMetric={showMetric}
            chartId={"renewal_rateChart"}
            chartDataKey={"renewalRate"}
            isLoading={renewalChurnRateLoading}
            data-target="customer_quality"
            onClick={() => setMetric("Customer quality")}
          // isSingleYear={isSingleYear}
          />
        </ErrorBoundary>
      )}

      {/* For Churn rates we take value from first element monthlyYoYDataAverages */}
      {/* For Churn rates we take comparisonValue from second element monthlyYoYDataAverages */}
      {regionParamValue == "China" ? (
        <DisabledTile title={"NissanConnect churn rate"} disableMessage={""} onClick={() => setMetric("Customer quality")} />
      ) : (
        <ErrorBoundary fallback={<ErrorMsg />}>
          <ValueWithFY
            title={"NissanConnect churn rate"}
            value={renewalChurnRate?.Current ? renewalChurnRate?.Current[0]?.churn_rate : null}
            comparisonValue={renewalChurnRate?.YoY ? renewalChurnRate?.YoY[0]?.churn_rate : null}
            chartData={showMetric ? formattedRenewalChurnRateChartData : undefined}
            chartId={"churn_rateChart"}
            chartDataKey={"churnRate"}
            isLoading={renewalChurnRateLoading}
            data-target="customer_quality"
            onClick={() => setMetric("Customer quality")}
            isChurnRate={true}
          />
        </ErrorBoundary>
      )}
    </div>
  );
};
