import { ErrorBoundary } from "@sentry/react";
import * as React from "react";
import { useMemo } from "react";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import UsedCarsPieChart from "../../../components/Charts/UsedCars/UsedCarsPieChart";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { ThemeContext } from "../../../context";
import { isNull } from "../../../utils/utilityFunctions";
import PmoTooltip from "../../ProductMarketingOptimization/components/common/PmoTooltip";

interface Props {
  data: any;
  loading: boolean;
  title: string;
  dataKey: string;
  toolTipParagraph: JSX.Element;
  colors?: Array<string>;
}

const InventoryByGraphs = (props: Props) => {
  const themeContext = React.useContext(ThemeContext);
  const { data, title, loading, dataKey, toolTipParagraph, colors } = props;

  const hasData = useMemo(() => {
    if (dataKey) return data?.some((val: { [x: string]: any }) => !isNull(val[dataKey]));
  }, [data]);

  return (
    <div className="list_price_graph inventory_graph primary_background">
      <div className="header">
        <h3 className="trendline_title tile_title">{title}</h3>
        <PmoTooltip paragraphs={toolTipParagraph} />
      </div>

      <div className="main_content" style={{ marginTop: "2em" }}>
        {data?.length && !loading && hasData ? (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <UsedCarsPieChart
              key={`${title.replace(/\s+/g, "_")}`}
              dataKey={dataKey}
              chartId={`${title.replace(/\s+/g, "_")}`}
              data={data}
              isLoading={loading}
              theme={themeContext.theme}
              colors={colors}
            />
          </ErrorBoundary>
        ) : loading ? (
          <LoadingEllipsis isLoading={loading} />
        ) : (
          <NoDataMsg message="No data available" />
        )}
      </div>
    </div>
  );
};

export default InventoryByGraphs;
