import { CategoryAxis, ColumnSeries, LabelBullet, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureCategoryAxis, configureValueAxis } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  theme: ThemeInt;
  chartId: string;
  isLoading: boolean;
}

class VisitsDropoffChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, theme } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#,###";

    // Create Axes
    const categoryAxis = this.chart.yAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.renderer.inversed = true;

    const valueAxis = this.chart.xAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.extraMax = 0.1;
    valueAxis.hidden = true;

    const series = this.chart.series.push(new ColumnSeries());
    series.dataFields.valueX = "value";
    series.dataFields.categoryY = "name";
    series.columns.template.tooltipText = `[bold; font-size: var(--regular_font_size);]{categoryY}: {valueX.formatNumber("#,###")}`;
    if (series.tooltip) {
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = color(WHITE);
    }

    const labelBullet = series.bullets.push(new LabelBullet());
    labelBullet.label.dx = 5;
    labelBullet.label.truncate = false;
    labelBullet.label.text = `{valueX.formatNumber("#,###")}`;
    labelBullet.label.fill = theme === "light" ? color(BLACK) : color(WHITE);
    labelBullet.label.horizontalCenter = "left";
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

export default VisitsDropoffChart;
