import { createStyles, makeStyles, Theme, WithStyles, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";

interface Props {
  open: boolean;
  user: any;
  handleClose: () => void;
  handleDelete: (email: string) => void;
}

const dialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof dialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(dialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes?.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes?.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DeleteUserDialog = (props: Props) => {
  const { handleClose, open, handleDelete, user } = props;

  const classes = makeStyles({
    dialog: {
      padding: "20px",
    },
    dialogContent: {
      textAlign: "center",
    },
  })();

  return (
    <Dialog className={classes.dialog} onClose={handleClose} aria-labelledby="delete-annotation-dialog-title" open={open} fullWidth>
      <DialogTitle id="delete-annotation-dialog-title" onClose={handleClose}>
        Delete Row
      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        <Typography gutterBottom>Are you sure you want to remove {user.email}?</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => handleDelete(user.email)} color="primary" data-test-id="btn-confirm-delete-user">
          Confirm Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserDialog;
