import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { fetchUseOwnershipLastDataRefresh, setPageName } from "../../../actions";
import {
  fetchOverviewAvgMonthlyActiveUsers,
  fetchOverviewAvgVocRatingAcrossPages,
  fetchOverviewChurnRate,
  fetchOverviewConnectedCarsSold,
  fetchOverviewEngagementTrend,
  fetchOverviewEnrolledCcsCustomers,
  fetchOverviewOnlineAccountAndEngagement,
  fetchOverviewRenewalRate,
  fetchOverviewRenewalTrend,
  fetchOverviewServiceLeads,
  fetchOverviewUserEngagement,
} from "../../../actions/useOwnershipTypes";
import "../../../assets/styles/pages/useOwnership.scss";
import LineTrendChart from "../../../components/Charts/UseOwnership/LineTrendChart";
import MultipleBarLineTrendChart from "../../../components/Charts/UseOwnership/MultipleBarLineTrendChart";
import UseOwnershipFilters from "../../../components/Filters/UseOwnershipFilters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import { CYAN, ORANGE } from "../../../constants";
import { USE_OWNERSHIP_BTN_FILTERS } from "../../../constants/useOwnershipConstants";
import { DashboardBannerContext, ThemeContext } from "../../../context";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import MetricTile from "../components/MetricTile";

const europeMarkets = ["France", "Germany", "Spain", "United Kingdom"];

const Overview = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const { setShowBanner, setBannerText } = useContext(DashboardBannerContext);
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.use_ownership_last_data_refresh);
  const { market: marketParamValue } = useSelector((state: RootStateOrAny) => state.parameters);
  const {
    Current: oavrapCurrent,
    PoP: oavrapPoP,
    YoY: oavrapYoY,
  } = useSelector((state: RootStateOrAny) => state.use_ownership.overview_avg_voc_rating_across_pages);
  const {
    Current: oaeCurrent,
    PoP: oaePoP,
    YoY: oaeYoY,
  } = useSelector((state: RootStateOrAny) => state.use_ownership.overview_online_account_and_engagement);
  const {
    Current: oslCurrent,
    PoP: oslPoP,
    YoY: oslYoY,
  } = useSelector((state: RootStateOrAny) => state.use_ownership.overview_service_leads);
  const {
    Current: enrolledCustomersCurrent,
    YoY: enrolledCustomersYoY,
    PoP: enrolledCustomersPoP,
  } = useSelector((state: RootStateOrAny) => state.use_ownership.overview_enrolled_ccs_customers);
  const {
    Current: avgActiveUsersCurrent,
    YoY: avgActiveUsersYoY,
    PoP: avgActiveUsersPoP,
  } = useSelector((state: RootStateOrAny) => state.use_ownership.overview_avg_monthly_active_users);
  const {
    Current: connectedCarsSoldCurrent,
    YoY: connectedCarsSoldYoY,
    PoP: connectedCarsSoldPoP,
  } = useSelector((state: RootStateOrAny) => state.use_ownership.overview_connected_cars_sold);
  const {
    Current: userEngagementCurrent,
    YoY: userEngagementYoY,
    PoP: userEngagementPoP,
  } = useSelector((state: RootStateOrAny) => state.use_ownership.overview_user_engagement);
  const { data: ccsEngagementTrendData, series: ccsEngagementTrendSeries } = useSelector(
    (state: RootStateOrAny) => state.use_ownership.overview_ccs_engagement_trend
  );
  const {
    Current: renewalRateCurrent,
    YoY: renewalRateYoY,
    PoP: renewalRatePoP,
  } = useSelector((state: RootStateOrAny) => state.use_ownership.overview_renewal_rate);
  const {
    Current: churnRateCurrent,
    YoY: churnRateYoY,
    PoP: churnRatePoP,
  } = useSelector((state: RootStateOrAny) => state.use_ownership.overview_churn_rate);
  const { data: ccsRenewalTrendData, series: ccsRenewalTrendSeries } = useSelector(
    (state: RootStateOrAny) => state.use_ownership.overview_ccs_renewal_trend
  );
  const {
    overview_online_account_and_engagement: oaeLoading,
    overview_service_leads: oslLoading,
    overview_avg_voc_rating_across_pages: oavrapLoading,
    overview_enrolled_ccs_customers: enrolledCustomersLoading,
    overview_avg_monthly_active_users: avgActiveUsersLoading,
    overview_connected_cars_sold: connectedCarsSoldLoading,
    overview_user_engagement: userEngagementLoading,
    overview_ccs_engagement_trend: ccsEngagementTrendLoading,
    overview_renewal_rate: renewalRateCurrentLoading,
    overview_churn_rate: churnRateLoading,
    overview_ccs_renewal_trend: ccsRenewalTrendLoading,
  } = useSelector((state: RootStateOrAny) => state.loading);

  useEffect(() => {
    dispatch(setPageName("USE Ownership Dashboard"));
    dispatch(fetchUseOwnershipLastDataRefresh("overview"));
    setBannerText(
      "Please note that data availability differs across markets in scope. N/A is displayed when data is not available. For more information on data availability by market view the complete data dictionary by clicking on the book sing on bottom left."
    );
    setShowBanner(true);

    return () => {
      setShowBanner(false);
      setBannerText("");
    };
  }, []);

  useEffect(() => {
    dispatch(fetchOverviewAvgVocRatingAcrossPages());
    dispatch(fetchOverviewOnlineAccountAndEngagement());
    dispatch(fetchOverviewServiceLeads());
    dispatch(fetchOverviewAvgMonthlyActiveUsers());
    dispatch(fetchOverviewChurnRate());
    dispatch(fetchOverviewConnectedCarsSold());
    dispatch(fetchOverviewEngagementTrend());
    dispatch(fetchOverviewEnrolledCcsCustomers());
    dispatch(fetchOverviewRenewalRate());
    dispatch(fetchOverviewRenewalTrend());
    dispatch(fetchOverviewUserEngagement());
  }, [history.location.search]);

  const handleBtnClick = useCallback(() => {
    history.push("/ccs_vital_signs/ccs_cockpit");
  }, []);

  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Use Ownership", page: props.history.location.pathname });
  }, [window.location]);

  const showCcsBanner = useMemo(() => {
    if (marketParamValue) {
      return marketParamValue.split(",").some((market: string) => europeMarkets.includes(market));
    }
    return false;
  }, [marketParamValue]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<UseOwnershipFilters filterBtns={USE_OWNERSHIP_BTN_FILTERS} page="Overview" />}
        dataDocumentation="use_ownership/overview"
        lastDataRefresh={lastDataRefresh}
        pageTitle={<PageTitle dataDocumentation="use_ownership/overview" />}
      >
        <div className="overview-page">
          <div className="tile okr"> OKRs</div>
          <div>
            <div className="service-section">
              <div className="online-account">
                <div className="tile section-header">Online Account & Engagement</div>
                <div className="online-account-tiles">
                  <MetricTile
                    title="No of users who complete account creation form"
                    titleTooltipText={"Users who complete the owner portal registration form"}
                    mainValue={oaeCurrent?.account_creation_form_complete_users}
                    yoyValue={oaeYoY?.perc_account_creation_form_complete_users}
                    popValue={oaePoP?.perc_account_creation_form_complete_users}
                    showPoP={true}
                    isLoading={oaeLoading}
                  />
                  <MetricTile
                    title="Monthly active users"
                    titleTooltipText={"Users who login to the Owner portal and lands on any of the owner portal pages"}
                    mainValue={oaeCurrent?.monthly_active_users}
                    yoyValue={oaeYoY?.perc_monthly_active_users}
                    popValue={oaePoP?.perc_monthly_active_users}
                    showPoP={true}
                    isLoading={oaeLoading}
                  />
                </div>
              </div>
              <div className="service-leads">
                <div className="tile section-header">Service Leads</div>
                <div className="service-leads-tiles">
                  <MetricTile
                    title="No of service leads"
                    titleTooltipText="Users who complete the service booking form"
                    mainValue={oslCurrent?.leads}
                    yoyValue={oslYoY?.perc_leads}
                    popValue={oslPoP?.perc_leads}
                    showPoP={true}
                    isLoading={oslLoading}
                  />
                </div>
              </div>
              <div className="self-service">
                <div className="tile section-header">Self Service</div>
                <div className="self-service-tiles">
                  <MetricTile
                    title="Avg DXS score across pages"
                    mainValue={null}
                    yoyValue={null}
                    popValue={null}
                    isRating={true}
                    maxRating={10}
                    showPoP={true}
                    isLoading={false}
                  />
                  <MetricTile
                    title="Avg VOC rating across pages"
                    titleTooltipText="Average out of 5 of all ratings given by users to evaluate their experience on Ownership pages"
                    mainValue={oavrapCurrent?.average_rating}
                    yoyValue={oavrapYoY?.pts_average_rating}
                    popValue={oavrapPoP?.pts_average_rating}
                    isRating={true}
                    maxRating={5}
                    showPoP={true}
                    isLoading={oavrapLoading}
                  />
                </div>
              </div>
            </div>
            <div className="ccs-section">
              <div className="tile section-header">
                <div className="section-header-title">CCS</div>
              </div>
              {showCcsBanner && (
                <div className="dashboard_banner">
                  Please note that 1. Connected Cars sold data is not available for Europe markets individually, therefore region total
                  values will show for any European markets selected. 2. Renewal rate and Churn rate are not available for Europe.
                </div>
              )}
              <div className="ccs-section-content">
                <div className="ccs-engagement-section">
                  <div className="ccs-engagement-metric-tiles">
                    <MetricTile
                      subText="Enrolled CCS Customers"
                      subTextTooltipText="User who have successfully paired their vehicle with NissanConnect app"
                      mainValue={enrolledCustomersCurrent?.enrolled_ccs_customers}
                      yoyValue={enrolledCustomersYoY?.perc_enrolled_ccs_customers}
                      popValue={enrolledCustomersPoP?.perc_enrolled_ccs_customers}
                      abbreviateMainValue={true}
                      showPoP={true}
                      isLoading={enrolledCustomersLoading}
                    />
                    <MetricTile
                      subText="Avg monthly active users"
                      subTextTooltipText="Avg of latest 3-month in selected period of unique NissanConnect users who use services at least one time per month"
                      mainValue={avgActiveUsersCurrent?.avg_active_users}
                      yoyValue={avgActiveUsersYoY?.perc_avg_active_users}
                      popValue={avgActiveUsersPoP?.perc_avg_active_users}
                      abbreviateMainValue={true}
                      showPoP={true}
                      isLoading={avgActiveUsersLoading}
                    />
                    <MetricTile
                      subText="of Connected Cars sold"
                      subTextTooltipText="Percentage of enrolled customers over the total number of cars sold"
                      mainValue={connectedCarsSoldCurrent?.connected_cars_sold}
                      yoyValue={connectedCarsSoldYoY?.pts_connected_cars_sold}
                      popValue={connectedCarsSoldPoP?.pts_connected_cars_sold}
                      isPercentage={true}
                      showPoP={true}
                      isLoading={connectedCarsSoldLoading}
                    />
                    <MetricTile
                      subText="User engagement"
                      subTextTooltipText="% of Average monthly active users from latest 3-month total enrolled users in selected period of NissanConnect app"
                      mainValue={userEngagementCurrent?.user_engagement}
                      yoyValue={userEngagementYoY?.pts_user_engagement}
                      popValue={userEngagementPoP?.pts_user_engagement}
                      isPercentage={true}
                      showPoP={true}
                      isLoading={userEngagementLoading}
                    />
                  </div>
                  <div className="ccs-engagement-chart tile">
                    <div className="title">
                      <div>CCS Engagement</div>
                      <Tooltip
                        position="top-end"
                        className={"normal_tippy"}
                        trigger="mouseenter"
                        animation="none"
                        interactive={true}
                        delay={1}
                        hideDelay={1}
                        duration={1}
                        html={
                          <div>
                            <p>
                              <b>Enrolled CCS Customers:</b> User who have successfully paired their vehicle with NissanConnect app.
                            </p>
                            <br />
                            <p>
                              <b>Avg monthly active users:</b> Avg of latest 3-month in selected period of unique NissanConnect users who
                              use services at least one time per month.
                            </p>
                            <br />
                            <p>
                              <b>User engagement:</b> % of Average monthly active users from latest 3-month total enrolled users in selected
                              period of NissanConnect app
                            </p>
                            <br />
                            <p>
                              <b>% of Connected Cars Sold:</b> Percentage of enrolled customers over the total number of cars sold
                            </p>
                          </div>
                        }
                        size={"small"}
                      >
                        <span className="info_tooltip" />
                      </Tooltip>
                    </div>
                    <div className="chart">
                      <MultipleBarLineTrendChart
                        chartId="ccsEngagementChart"
                        data={ccsEngagementTrendData ?? []}
                        seriesList={ccsEngagementTrendSeries ?? []}
                        theme={theme}
                        isLoading={ccsEngagementTrendLoading}
                        lineAxisIsPercentage={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="ccs-renewal-section">
                  <div className="ccs-renewal-metric-tiles">
                    <MetricTile
                      subText="Renewal rate"
                      subTextTooltipText="Rate of customers who renew NissanConnect subscribed services."
                      mainValue={renewalRateCurrent?.renewal_rate}
                      yoyValue={renewalRateYoY?.pts_renewal_rate}
                      popValue={renewalRatePoP?.pts_renewal_rate}
                      isPercentage={true}
                      showPoP={true}
                      isLoading={renewalRateCurrentLoading}
                    />
                    <MetricTile
                      subText="NissanConnect churn rate"
                      subTextTooltipText="Rate in which customers quit NissanConnect subscribed services"
                      mainValue={churnRateCurrent?.churn_rate}
                      yoyValue={churnRateYoY?.pts_churn_rate}
                      popValue={churnRatePoP?.pts_churn_rate}
                      isPercentage={true}
                      showPoP={true}
                      isLoading={churnRateLoading}
                    />
                  </div>
                  <div className="ccs-renewal-chart tile">
                    <div className="title">
                      <div>CCS Renewal</div>
                      <Tooltip
                        position="top-end"
                        className={"normal_tippy"}
                        trigger="mouseenter"
                        animation="none"
                        interactive={true}
                        delay={1}
                        hideDelay={1}
                        duration={1}
                        html={
                          <div>
                            <p>
                              <b>Renewal Rate:</b> Rate of customers who renew NissanConnect subscribed services.
                            </p>
                            <br />
                            <p>
                              <b>NissanConnect churn rate:</b> Rate in which customers quit NissanConnect subscribed services.
                            </p>
                          </div>
                        }
                        size={"small"}
                      >
                        <span className="info_tooltip" />
                      </Tooltip>
                    </div>
                    <div className="chart">
                      <LineTrendChart
                        chartId="ccsRenewalChart"
                        data={ccsRenewalTrendData ?? []}
                        seriesList={ccsRenewalTrendSeries ?? []}
                        theme={theme}
                        isLoading={ccsRenewalTrendLoading}
                        isPercentage={true}
                        seriesColors={[ORANGE, CYAN]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="more-info">
              <div>For more Connected Car Services information access:</div>
              <button className="more-info-btn" onClick={handleBtnClick}>
                CCS Vital Signs Dashboard
              </button>
            </div>
          </div>
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default Overview;
