import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { ALL_OPTION_NO_SPACE } from "../../../constants";
import { closeSubFilterOptions } from "../subs/helpers";

type FilterListInt = {
  value: string;
  checked: boolean;
  indeterminate: boolean;
  child?: Array<{ [index: string]: { value: string; checked: boolean; indeterminate: boolean } }>;
  [index: string]: any;
};

interface Props {
  filterName?: string;
  parentKey: string;
  childKey?: string;
  filterList: Array<FilterListInt>;
  value: string;
  parameterName: string;
  parameterValue: string;
  onShowFilterOptions: (evt: React.MouseEvent<HTMLElement>) => void;
  handleFilterSubmission: () => void;
  showOptionsOnly?: boolean;
  displayMessage?: string;
  hideAllOption?: boolean;
  extraClass?: string;
  unCheckNissan?: boolean;
  hasNoAll?: boolean;
  maxSelectable?: number;
  selectedYrSC?: string[];
  setSelectedYrSC?: (value: string[]) => void;
  showTotal?: boolean;
  searchString?: string;
}

interface RenderProps {
  filterList: Array<FilterListInt>;
  parentValue?: string;
}

export const MultiSelectFilter = (props: Props) => {
  const {
    filterName,
    parentKey,
    childKey,
    filterList,
    value,
    parameterValue,
    onShowFilterOptions,
    parameterName,
    handleFilterSubmission,
    showOptionsOnly,
    displayMessage,
    hideAllOption,
    extraClass,
    unCheckNissan,
    hasNoAll,
    maxSelectable: maxSelected,
    selectedYrSC,
    setSelectedYrSC,
    showTotal,
    searchString,
  } = props;

  const [checkedState, setCheckedState] = useState<{ [index: string]: { checked: boolean; indeterminate: boolean } }>({});
  const [draftCheckedState, setDraftCheckedState] = useState<{ [index: string]: { checked: boolean; indeterminate: boolean } }>({});
  const [isCCS, setIsCCS] = useState<boolean>(false);

  const filterState = useMemo(() => {
    if (typeof parameterValue === "string") {
      const isAll = parameterValue === "All";
      const parameterList = parameterValue?.split(/,(?=\w+)/);

      const result = filterList?.map((item: any) => {
        const isString = typeof item === "string";
        //@ts-ignore
        if (isString) {
          return {
            value: item,
            checked: unCheckNissan
              ? isAll || (parameterList.includes(item) && !item.includes("Nissan"))
              : isAll || parameterList.includes(item),
            indeterminate: false,
          };
        }

        const childResult = childKey
          ? item?.child?.map((childItem: any) => ({
            value: childItem[childKey],
            checked: parameterList.includes(`${childItem[childKey]} ${item[parentKey]}`),
            indeterminate: false,
          }))
          : [];

        return {
          value: item[parentKey],
          child: childResult,
          checked:
            parameterList.filter((paramItem) => {
              const parentRe = new RegExp(item[parentKey], "gi");
              return parentRe.test(paramItem);
            })?.length === childResult?.length,
          indeterminate:
            parameterList.filter((paramItem) => {
              const parentRe = new RegExp(item[parentKey], "gi");
              return parentRe.test(paramItem);
              //@ts-ignore
            })?.length < childResult?.length &&
            parameterList.filter((paramItem) => {
              const parentRe = new RegExp(item[parentKey], "gi");
              return parentRe.test(paramItem);
            })?.length > 0,
        };
      });

      return hasNoAll
        ? [...result]
        : hideAllOption
          ? result
          : [{ value: "All", checked: result?.length === result.filter((item) => item.checked)?.length, indeterminate: parameterList.length > 0 && parameterValue != "All" }, ...result];
    }
    return [];
  }, [filterList, parameterValue]);

  useEffect(() => {
    const stateResult = filterState.reduce((result, row) => {
      //@ts-ignore
      const { value: parentValue, checked: parentChecked, indeterminate: parentIndeterminate, child } = row;

      if (child) {
        const childResult = child.reduce(
          (
            result: { [index: string]: { checked: boolean; indeterminate: boolean } },
            childRow: { value: string; checked: boolean; indeterminate: boolean }
          ) => {
            const { value, checked, indeterminate } = childRow;
            const childValue = `${value} ${parentValue}`;
            return { ...result, [childValue]: { checked, indeterminate } };
          },
          {}
        );

        return { ...result, [parentValue]: { checked: parentChecked, indeterminate: parentIndeterminate }, ...childResult };
      }
      return { ...result, [parentValue]: { checked: parentChecked, indeterminate: parentIndeterminate } };
    }, {});
    setCheckedState(stateResult);
  }, [filterState]);

  useEffect(() => setDraftCheckedState(checkedState), [checkedState]);

  const handleMultiSelection = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value, checked, dataset },
      } = evt;
      const parentValue = dataset?.parentValue;

      const allChildCheckbox: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
        `[type="checkbox"][data-filter-name="${parameterName}"][data-parent-value="${parentValue}"]`
      );
      const selectedChildCheckbox: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
        `[type="checkbox"][data-filter-name="${parameterName}"][data-parent-value="${parentValue}"]:checked`
      );

      const availableNonAllValues: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
        `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]`
      );

      const selectedAvailableNonAllValues: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
        `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
      );

      if (parameterName == "YR_SCNR_RV" && setSelectedYrSC && selectedYrSC) {
        const arr: string[] = selectedYrSC;
        if (selectedYrSC?.includes(value)) {
          arr?.splice(selectedYrSC.indexOf(value), 1);
        }
        arr.push(value);
        setSelectedYrSC(arr);
      }

      // Sets current value
      let draftState = {
        ...draftCheckedState,
        [value]: { checked, indeterminate: false },
      };

      if (value === ALL_OPTION_NO_SPACE) {
        Object.keys(draftState).map((key) => {
          draftState = {
            ...draftState,
            [key]: { checked, indeterminate: false },
          };
        });

        setDraftCheckedState(draftState);
        return;
      }

      if (maxSelected && selectedAvailableNonAllValues?.length > maxSelected) {
        selectedAvailableNonAllValues?.forEach((selectedAvailableValue) => {
          const selectedValue: any = selectedAvailableValue.value;
          if (selectedValue !== value) {
            draftState = {
              ...draftState,
              [selectedValue]: { checked: false, indeterminate: false },
            };
          }
        });
      }

      //Updates current checkbox state
      if (parentValue) {
        draftState = {
          ...draftState,
          [parentValue]: {
            checked: allChildCheckbox?.length === selectedChildCheckbox?.length,
            indeterminate: selectedChildCheckbox?.length > 0 && selectedChildCheckbox?.length < allChildCheckbox?.length,
          },
        };
      } else {
        // Updates all child elements if present
        const childCheckboxes: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
          `[type="checkbox"][data-parent-value="${value}"]`
        );

        childCheckboxes?.forEach((childElement) => {
          const childValue = childElement.value;
          draftState = {
            ...draftState,
            [childValue]: { checked, indeterminate: false },
          };
        });

        draftState = {
          ...draftState,
          All: {
            checked: selectedAvailableNonAllValues?.length === availableNonAllValues?.length,
            indeterminate:
              selectedAvailableNonAllValues?.length !== 0 && selectedAvailableNonAllValues?.length < availableNonAllValues?.length,
          },
        };
      }

      setDraftCheckedState(draftState);
    },
    [draftCheckedState]
  );

  const handleFilterApply = () => {
    closeSubFilterOptions();
    handleFilterSubmission();
  };

  useEffect(() => {
    const uri = window.location.href;
    setIsCCS(uri.includes("ccs"));
  });

  const renderCheckedOptions = useCallback(
    (renderProp: RenderProps) => {
      const { filterList, parentValue } = renderProp;

      const handleMarketArrowClick = (evt: React.MouseEvent<HTMLElement>) => {
        const {
          currentTarget: { id: currentId, dataset },
        } = evt;
        const subCheckListId = dataset?.marketTarget;

        const arrowDownClass = "down";
        const showSubListClass = "show";

        const currentElement = currentId ? document.getElementById(currentId) : undefined;
        const targetUlElement = subCheckListId ? document.getElementById(subCheckListId) : undefined;

        if (!currentElement?.classList.contains(arrowDownClass)) closeSubFilterOptions();
        currentElement?.classList.toggle(arrowDownClass);
        targetUlElement?.classList.toggle(showSubListClass);
      };

      const optionsList = searchString ? filterList.filter(({ value }) => value.toLowerCase()?.includes(searchString?.toString()?.toLowerCase())) : filterList

      return optionsList.map((filterItem) => {
        const { value, child } = filterItem;
        const showArrow = child && child?.length ? "show" : "hidden";
        const checkValue = parentValue ? `${value} ${parentValue}` : value;

        return (
          <Fragment key={value}>
            <div className={`${extraClass} multiselect_container`}>
              <span
                id={parentValue ? `${parentKey}_${value}_${parentValue}_arrow` : `${parentKey}_${value}_arrow`}
                className={`arrow-span ${showArrow}`}
                data-market-target={`${value}_ul`}
                onClick={handleMarketArrowClick}
              >
                <span className={"market-arrow"} />
              </span>
              <input
                type="checkbox"
                className={"multiselect-checkbox"}
                id={parentValue ? `${parentKey}_${value}_${parentValue}` : `${parentKey}_${value}`}
                value={checkValue}
                checked={draftCheckedState[checkValue]?.checked ? draftCheckedState[checkValue].checked : false}
                onChange={handleMultiSelection}
                data-parent-value={parentValue}
                data-filter-name={parameterName}
                data-options-type={!parentValue && value !== ALL_OPTION_NO_SPACE ? "single" : "grouped"}
                data-relationship={parentValue && !child && value !== ALL_OPTION_NO_SPACE ? "child" : "parent"}
                ref={(input) => {
                  if (input) {
                    input.indeterminate = draftCheckedState[checkValue]?.indeterminate;
                  }
                }}
              />

              <label className="multiselect-label" htmlFor={parentValue ? `${parentKey}_${value}_${parentValue}` : `${parentKey}_${value}`}>
                {/* {value == ALL_OPTION_NO_SPACE ? "Total" : value} */}
                {value == ALL_OPTION_NO_SPACE && showTotal ? "Total" : value}
                {isCCS && value === "Note" && (
                  <span className="disclaimer_text">
                    {" "}
                    -<i>Prior to May 2022 Note data is an aggregation of Note + Aura data</i>
                  </span>
                )}
              </label>
            </div>
            {child && (
              <ul className="multicheck_sub_filter_options" id={`${value}_ul`} data-parent-key={value}>
                {/* @ts-ignore */}
                {renderCheckedOptions({ filterList: child, parentValue: value })}
              </ul>
            )}
          </Fragment>
        );
      });
    },
    [draftCheckedState, searchString]
  );

  return (
    <Fragment>
      {showOptionsOnly ? (
        <>
          {/* @ts-ignore */}
          {renderCheckedOptions({ filterList: filterState, parentKey, childKey })}
          <div className="filter_options__submit_area">
            <div className="filter_submit_btn submit_geo_filter" onClick={handleFilterApply}>
              Apply
            </div>
          </div>
        </>
      ) : (
        <div className="filter" id={`${filterName}_filter_div`}>
          <label className="filter_header" htmlFor={`${parentKey}_filter`}>
            {filterName}
          </label>

          <div className="input_arrow" onClick={onShowFilterOptions} data-options-ul={`${parentKey}_ul`}>
            <input
              readOnly
              type="text"
              id={`${parentKey}_filter`}
              className={"filter_input"}
              data-test-id={`${parentKey}`}
              value={showTotal && value == ALL_OPTION_NO_SPACE ? "Total" : value}
            />
            <span className="arrow_down " />
          </div>

          <ul className={`${extraClass} filter_options`} id={`${parentKey}_ul`} data-test-id="region_list">
            {/* @ts-ignore */}
            {renderCheckedOptions({ filterList: filterState, parentKey, childKey })}
            <div className={`${extraClass} filter_options__submit_area`}>
              {displayMessage?.length && <div className="message">{displayMessage}</div>}
              <div className="filter_submit_btn submit_geo_filter" onClick={handleFilterApply}>
                Apply
              </div>
            </div>
          </ul>
        </div>
      )}
    </Fragment>
  );
};
