import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, Container, create, NumberFormatter, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, CYAN, FOREST_GREEN, GRAY, RED, WHITE } from "../../../constants";
import { Annotation } from "../../../pages/Admin/types";
import ShareAnnotationModal from "../../Annotations/Dialogs/ShareAnnotationModal";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { configureDateAxis, configureValueAxis, legendConfiguration, lineSeriesConfiguration } from "../helpers/configurations";
import { formatDateAxisLabelToMonthly } from "../helpers/mtmConfigurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string | Array<Annotation>>>;
  seriesList: Array<{ name: string; field: string }>;
  chartName: string;
  isLoading: boolean;
  theme: ThemeInt;
}

interface State {
  shareModalOpen: boolean;
  annotations: Annotation[] | undefined;
}

const seriesColors = [CYAN, GRAY, RED, FOREST_GREEN];

class CurrentComparisonTrendline extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { shareModalOpen: false, annotations: undefined };

    this.toogleShareModal = this.toogleShareModal.bind(this);
  }

  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  toogleShareModal() {
    this.setState({
      shareModalOpen: !this.state.shareModalOpen,
    });
  }

  handleShareAnnotation(annotations: Annotation[]) {
    this.setState({
      annotations: annotations,
    });
    this.toogleShareModal();
  }

  initChart() {
    const { data, chartName, theme, seriesList } = this.props;

    this.chart = create(chartName, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;

    //Add legends
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.itemContainers.template.paddingTop = 2.5;
    this.chart.legend.itemContainers.template.marginBottom = 0;
    this.chart.legend.itemContainers.template.events.on("inited", function (ev) {
      const legend = ev.target;
      //@ts-ignore
      legend.dom.setAttribute("data-test-id", legend.dataItem?.dataContext.testId);
    });

    //Default x axis to show current date
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.minGridDistance = 30;
    dateAxis.dateFormats.setKey("day", "MMM");
    dateAxis.periodChangeDateFormats.setKey("day", "MMM");

    //Y Axis that is used by default
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);

    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text + "%";
    });

    if (chartName == "percentage_digitally_influenced_sales") {
      const topContainer = this.chart.chartContainer.createChild(Container);
      topContainer.toBack();
      topContainer.paddingBottom = 35;
      topContainer.width = percent(100);
    }

    //Function to create the series using the arguments given
    seriesList.forEach(({ name, field }, idx) => {
      const series = this.chart.series.push(new LineSeries());
      series.dataFields.dateX = "date";
      if (seriesColors) series.fill = color(seriesColors.length > 1 ? seriesColors[idx] : seriesColors[0]);
      if (seriesColors) series.stroke = color(seriesColors.length > 1 ? seriesColors[idx] : seriesColors[0]);
      if (series.tooltip) {
        series.tooltip.getFillFromObject = false;
        series.tooltip.autoTextColor = false;
        series.tooltip.background.fill = color(WHITE);
        series.tooltip.label.fill = color(BLACK);
      }

      const circleBullet = lineSeriesConfiguration(series, {
        valueY: field,
        name: name,
        yAxis: valueAxis,
      });
      series.numberFormatter = new NumberFormatter();
      series.numberFormatter.numberFormat = "###,###";
      circleBullet.tooltipText = `[bold font-size: var(--regular_font_size)]{name}:[bold font-size: var(--regular_font_size)] {valueY.numberFormat('#')}`;

      if (series.tooltip) {
        series.tooltip.label.fill = color(BLACK);
      }

      if (field.includes("target")) {
        series.strokeDasharray = "2,2";
      }

      circleBullet.adapter.add("tooltipText", (value, target) => {
        const dateValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };
        let text = `[bold font-size: var(--regular_font_size)]${formatDateAxisLabelToMonthly(dateValue?.date)}[/]\n`;
        this.chart.series.each((item) => {
          if (!item.isHidden && !(item.name.includes("forecast") && chartName == "percentage_digitally_influenced_sales")) {
            const value = item.dataFields.valueY && dateValue ? (dateValue[item.dataFields.valueY] as number) : null;

            text += `[font-size: var(--regular_font_size) ${item.stroke}]●[/] [font-size: var(--regular_font_size)]${
              item.name
            }: [font-size: var(--regular_font_size)]${value == null ? "n/a" : `${value}%`}\n`;
          }
        });
        return text;
      });
    });
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
        <ShareAnnotationModal
          open={this.state.shareModalOpen}
          handleToogleDialog={this.toogleShareModal}
          annotations={this.state.annotations}
        />
      </div>
    );
  }
}

export default CurrentComparisonTrendline;
