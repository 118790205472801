import { ErrorBoundary } from "@sentry/react";
import * as React from "react";
import { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import UsedCarsTrendlineChart from "../../../components/Charts/UsedCars/UsedCarsTrendlineChart";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { getCurrencyPMO, PMO_CHART_COLORS } from "../../../constants";
import { ThemeContext } from "../../../context";
import PmoTooltip from "../../ProductMarketingOptimization/components/common/PmoTooltip";

interface Props {
  title: string;
  chartData?: any;
  loading: boolean;
  series: { name: string; field: string }[];
  maxAmount?: number;
  total?: number;
  isCurrency?: boolean;
  toolTipParagraph: JSX.Element;
  hideLegend?: boolean;
}

const TrendlineGraphs = (props: Props) => {
  const themeContext = React.useContext(ThemeContext);
  const { title, isCurrency, chartData, loading, series, maxAmount, total, toolTipParagraph, hideLegend } = props;
  const params = useSelector((state: RootStateOrAny) => state.parameters);

  const seriesList = series?.map((series: { name: string; field: string }, idx: number) => ({
    name: series.name,
    field: series.field,
    seriesColor: PMO_CHART_COLORS[idx],
  }));

  const renderChart = useMemo(() => {
    if (loading) return false;
    if (!chartData?.length) return false;
    if (!seriesList) return false;
    return true;
  }, [chartData, seriesList, loading]);

  return (
    <div className="trendline_graph primary_background">
      <div className="header">
        <h3 className="trendline_title tile_title">{title}</h3>
        <PmoTooltip paragraphs={toolTipParagraph} />
      </div>

      {total ? (
        <h3 className="trendline_title tile_title tile_total">
          {isCurrency ? getCurrencyPMO(params?.market) : ""}
          {total.toFixed(2)}
        </h3>
      ) : null}

      <div className="main_content ">
        {renderChart ? (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <UsedCarsTrendlineChart
              chartId={title}
              data={chartData}
              theme={themeContext.theme}
              isLoading={loading}
              seriesList={seriesList}
              maxAmount={maxAmount}
              granularity={params?.granularity}
              market={params?.market}
              isCurrency={isCurrency}
              hideLegend={hideLegend}
            />
          </ErrorBoundary>
        ) : loading ? (
          <LoadingEllipsis isLoading={loading} />
        ) : (
          <NoDataMsg message="No data available" />
        )}
      </div>
    </div>
  );
};

export default TrendlineGraphs;
