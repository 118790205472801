export const FETCH_PRODUCT_MARKETING_OPTIMIZATION_FILTERS = "FETCH_PRODUCT_MARKETING_OPTIMIZATION_FILTERS";
export const SET_PRODUCT_MARKETING_OPTIMIZATION_FILTERS = "SET_PRODUCT_MARKETING_OPTIMIZATION_FILTERS";

export const FETCH_CUSTOMER_INTENTION_VS_SALES = "FETCH_CUSTOMER_INTENTION_VS_SALES";
export const SET_CUSTOMER_INTENTION_VS_SALES = "SET_CUSTOMER_INTENTION_VS_SALES";

export const FETCH_PRICE_VOLUME_SALES_DATA = "FETCH_PRICE_VOLUME_SALES_DATA";
export const SET_PRICE_VOLUME_SALES_DATA = "SET_PRICE_VOLUME_SALES_DATA";

export const FETCH_SEARCH_INTEREST_VS_FMI_DATA = "FETCH_SEARCH_INTEREST_VS_FMI_DATA";
export const SET_SEARCH_INTEREST_VS_FMI_DATA = "SET_SEARCH_INTEREST_VS_FMI_DATA";

export const FETCH_MEDIA_SPEND = "FETCH_MEDIA_SPEND";
export const SET_MEDIA_SPEND = "SET_MEDIA_SPEND";

export const FETCH_MEDIA_SPEND_TOTALS = "FETCH_MEDIA_SPEND_TOTALS";
export const SET_MEDIA_SPEND_TOTALS = "SET_MEDIA_SPEND_TOTALS";

export const FETCH_SALES_BY_TREND_DATA = "FETCH_SALES_BY_TREND_DATA";
export const SET_SALES_BY_TREND_DATA = "SET_SALES_BY_TREND_DATA";

export const FETCH_PRICE_VOLUME_TREND_DATA = "FETCH_PRICE_VOLUME_TREND_DATA";
export const SET_PRICE_VOLUME_TREND_DATA = "SET_PRICE_VOLUME_TREND_DATA";

export const FETCH_TOTALS_VISITS_DATA = "FETCH_TOTALS_VISITS_DATA";
export const SET_TOTALS_VISITS_DATA = "SET_TOTALS_VISITS_DATA";

export const FETCH_LEADS_FUNNEL = "FETCH_LEADS_FUNNEL";
export const SET_LEADS_FUNNEL = "SET_LEADS_FUNNEL";

export const FETCH_TOTAL_LEADS = "FETCH_TOTAL_LEADS";
export const SET_TOTAL_LEADS = "SET_TOTAL_LEADS";

export const FETCH_WEB_VISITS_BY_CHANNEL = "FETCH_WEB_VISITS_BY_CHANNEL";
export const SET_WEB_VISITS_BY_CHANNEL = "SET_WEB_VISITS_BY_CHANNEL";

export const FETCH_LEAD_TO_TEST_DRIVE_REQUESTS = "FETCH_LEAD_TO_TEST_DRIVE_REQUESTS";
export const SET_LEAD_TO_TEST_DRIVE_REQUESTS = "SET_LEAD_TO_TEST_DRIVE_REQUESTS";

export const FETCH_TEST_DRIVE_REQUESTS = "FETCH_TEST_DRIVE_REQUESTS";
export const SET_TEST_DRIVE_REQUESTS = "SET_TEST_DRIVE_REQUESTS";

export const FETCH_RETAIL_SALES = "FETCH_RETAIL_SALES";
export const SET_RETAIL_SALES = "SET_RETAIL_SALES";

export const FETCH_GRADE_MIX = "FETCH_GRADE_MIX";
export const SET_GRADE_MIX = "SET_GRADE_MIX";

export const FETCH_MARKEITNG_ACTIVITY = "FETCH_MARKEITNG_ACTIVITY";
export const SET_MARKEITNG_ACTIVITY = "SET_MARKEITNG_ACTIVITY";

export const FETCH_REGIONAL_COLOR_TREND_DATA = "FETCH_REGIONAL_COLOR_TREND_DATA";
export const SET_REGIONAL_COLOR_TREND_DATA = "SET_REGIONAL_COLOR_TREND_DATA";

export const FETCH_CUSTOMER_INTENTION_BY_COLOR = "FETCH_CUSTOMER_INTENTION_BY_COLOR";
export const SET_CUSTOMER_INTENTION_BY_COLOR = "SET_CUSTOMER_INTENTION_BY_COLOR";

export const FETCH_SOV_DATA = "FETCH_SOV_DATA";
export const SET_SOV_DATA = "SET_SOV_DATA";

export const FETCH_AVERAGE_MODEL = "FETCH_AVERAGE_MODEL";
export const SET_AVERAGE_MODEL = "SET_AVERAGE_MODEL";

export const FETCH_CUSTOMER_INTENTIONSVSSALE_POWER = "FETCH_CUSTOMER_INTENTIONSVSSALE_POWER";
export const SET_CUSTOMER_INTENTIONSVSSALE_POWER = "SET_CUSTOMER_INTENTIONSVSSALE_POWER";

export const FETCH_NIM_SALES_BY_TREND = "FETCH_NIM_SALES_BY_TREND";
export const SET_NIM_SALES_BY_TREND = "SET_NIM_SALES_BY_TREND";

export const FETCH_NIM_CUSTOMER_INTENTION_VS_SALES = "FETCH_NIM_CUSTOMER_INTENTION_VS_SALES";
export const SET_NIM_CUSTOMER_INTENTION_VS_SALES = "SET_NIM_CUSTOMER_INTENTION_VS_SALES";

export const FETCH_NIM_GRADES = "FETCH_NIM_GRADES";
export const SET_NIM_GRADES = "SET_NIM_GRADES";
