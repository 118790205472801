export const FETCH_ACTIVATION_OVERALL_DATA = "FETCH_ACTIVATION_OVERALL_DATA";
export const FETCH_COCKPIT_ACTIVATION_OVERALL_DATA = "FETCH_COCKPIT_ACTIVATION_OVERALL_DATA";
export const SET_ACTIVATION_OVERALL_DATA = "SET_ACTIVATION_OVERALL_DATA";

export const FETCH_ACTIVATION_COUNTRY_DATA = "FETCH_ACTIVATION_COUNTRY_DATA";
export const SET_ACTIVATION_COUNTRY_DATA = "SET_ACTIVATION_COUNTRY_DATA";

export const FETCH_ACTIVATION_MODEL_DATA = "FETCH_ACTIVATION_MODEL_DATA";
export const SET_ACTIVATION_MODEL_DATA = "SET_ACTIVATION_MODEL_DATA";

export const FETCH_ACTIVATION_DEALER_DATA = "FETCH_ACTIVATION_DEALER_DATA";
export const SET_ACTIVATION_DEALER_DATA = "SET_ACTIVATION_DEALER_DATA";

export const FETCH_COCKPIT_ACTIVATION_DATA = "FETCH_COCKPIT_ACTIVATION_DATA";
export const SET_COCKPIT_ACTIVATION_DATA = "SET_COCKPIT_ACTIVATION_DATA";
