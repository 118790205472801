import React from "react";
import "../../assets/styles/component/documentation.scss";
import { setHomePage } from "../MenuOptions/subs";

interface Props {
  isVisible: boolean;
  onCloseClick: () => void;
}

export const SaveHomePage = (props: Props) => {
  const { isVisible, onCloseClick } = props;

  const handleSettingHomePage = () => {
    setHomePage();
    onCloseClick();
  };

  return (
    <div className={`popup ${isVisible ? "show" : ""}`}>
      <div className="popupModal small">
        <button className="closePopup" onClick={onCloseClick}>
          ╳
        </button>
        <div className="data_message">
          <p>Applying this change will update your home page to the view and filter selection you are currently on.</p>
        </div>

        <div className={"popupBtns"}>
          <button id="confirmHomePage" data-test-id="confirmHomePage" onClick={handleSettingHomePage}>
            Confirm
          </button>
          <button id="cancelHomePage" data-test-id="cancelHomePage" onClick={onCloseClick}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
