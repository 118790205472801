import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { ALL_OPTION_NO_SPACE } from "../../../constants";
import { closeFilterOptions } from "../subs/helpers";

type FilterListInt = Array<{
  value: string;
  checked: boolean;
  indeterminate: boolean;
  child?: Array<{ [index: string]: { value: string; checked: boolean; indeterminate: boolean } }>;
  [index: string]: any;
}>;

interface Props {
  filterName?: string;
  parentKey: string;
  childKey?: string;
  grandChildKey?: string;
  filterList: FilterListInt;
  value: string;
  parameterName: string;
  parameterValue: string;
  onShowFilterOptions: (evt: React.MouseEvent<HTMLElement>) => void;
  handleFilterSubmission: (data: string[], parents: string[]) => void;
  showOptionsOnly?: boolean;
}

interface RenderProps {
  filterList: FilterListInt;
  parentValue?: string;
}

export const MultiSelectFilterSerena = (props: Props) => {
  const {
    filterName,
    parentKey,
    childKey,
    grandChildKey,
    filterList,
    value,
    parameterValue,
    onShowFilterOptions,
    parameterName,
    handleFilterSubmission,
    showOptionsOnly,
  } = props;

  const [checkedState, setCheckedState] = useState<{ [index: string]: { checked: boolean; indeterminate: boolean } }>({});
  const [draftCheckedState, setDraftCheckedState] = useState<{ [index: string]: { checked: boolean; indeterminate: boolean } }>({});

  const filterState = useMemo(() => {
    const isAll = parameterValue === "All";
    const parameterList = parameterValue.split(",");

    const createChildObj: any = (childItem: any, parentKeyValue: any, childKey: string) => {
      const checked_value = `${childItem[childKey]} ${parentKeyValue}`;
      return {
        value: childItem[childKey],
        checked: parameterList.includes(checked_value),
        indeterminate: !parameterList.includes(checked_value),
        child: childItem?.child
          ? childItem.child.map((item: any) => createChildObj(item, childItem[childKey], grandChildKey ? grandChildKey : ""))
          : [],
        [parentKey]: childItem[parentKey],
        [childKey]: childItem[childKey],
        hideCheckbox: childItem?.hideCheckbox ? childItem.hideCheckbox : false,
      };
    };

    const result = filterList?.map((item) => {
      const isString = typeof item === "string";
      //@ts-ignore
      if (isString) return { value: item, checked: isAll || parameterList.includes(item), indeterminate: false };

      const childResult = childKey ? item?.child?.map((childItem) => createChildObj(childItem, item[parentKey], childKey)) : [];

      const dta: string[] = [];

      parameterList.map((dt: any) => {
        if (dt.includes(`${" "}${item[parentKey]}`)) {
          dta.push(dt);
        }
      });

      return {
        value: item[parentKey],
        child: childResult,
        checked: dta.length === item.child?.length,
        indeterminate: !(dta.length === item.child?.length),
        hideCheckbox: item?.hideCheckbox ? item.hideCheckbox : false,
      };
    });

    return [
      {
        value: "All",
        checked: result?.length === result.filter((item) => item.checked)?.length,
        indeterminate: !(result?.length === result.filter((item) => item.checked)?.length),
      },
      ...result,
    ];
  }, [filterList, parameterValue]);

  const parents = useMemo(() => {
    const parent: any = [];
    filterList.map((e: any) => parent.push(e[parentKey]));
    return parent;
  }, [filterList]);

  useEffect(() => {
    const stateResult = filterState.reduce((result, row) => {
      //@ts-ignore
      const { value: parentValue, checked: parentChecked, indeterminate: parentIndeterminate, child } = row;

      if (child) {
        const childResult = child.reduce(
          (
            result: { [index: string]: { checked: boolean; indeterminate: boolean } },
            childRow: { value: string; checked: boolean; indeterminate: boolean }
          ) => {
            const { value, checked, indeterminate } = childRow;
            const childValue = `${value} ${parentValue}`;

            return { ...result, [childValue]: { checked, indeterminate } };
          },
          {}
        );

        let grandChildResult: any = {};

        child.forEach((chld: any) => {
          if (chld?.child) {
            chld.child.map((item: any) => {
              const { value, checked, indeterminate, group, model } = item;

              grandChildResult[`${value} ${model} ${group}`] = { checked, indeterminate };
            });
          }
        });
        if (child?.child) {
          grandChildResult = child.child.reduce(
            (
              result: { [index: string]: { checked: boolean; indeterminate: boolean } },
              childRow: { value: string; checked: boolean; indeterminate: boolean }
            ) => {
              const { value, checked, indeterminate } = childRow;
              const childValue = `${value} ${parentValue}`;

              return { ...result, [childValue]: { checked, indeterminate } };
            },
            {}
          );
        }

        return {
          ...result,
          [parentValue]: { checked: parentChecked, indeterminate: parentIndeterminate },
          ...childResult,
          ...grandChildResult,
        };
      }

      return { ...result, [parentValue]: { checked: parentChecked, indeterminate: parentIndeterminate } };
    }, {});
    setCheckedState(stateResult);
  }, [filterState]);

  useEffect(() => setDraftCheckedState(checkedState), [checkedState]);

  const handleMultiSelection = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value, checked, dataset },
      } = evt;

      const parentValue = dataset?.parentValue;

      // Sets current value
      let draftState = {
        ...draftCheckedState,
        [value]: { checked, indeterminate: false },
      };

      if (value === ALL_OPTION_NO_SPACE) {
        Object.keys(draftState).map((key) => {
          draftState = {
            ...draftState,
            [key]: { checked, indeterminate: false },
          };
        });

        setDraftCheckedState(draftState);
        return;
      }

      if (parentValue == undefined) {
        Object.keys(draftState).map((key) => {
          if (key.includes(`${" "}${value}`)) {
            draftState = {
              ...draftState,
              [key]: { checked, indeterminate: false },
            };
          }
        });

        let all_check = true;
        Object.keys(draftState).map((key) => {
          if (!draftState[key].checked && key != ALL_OPTION_NO_SPACE) {
            all_check = draftState[key].checked;
          }
        });

        draftState = {
          ...draftState,
          [ALL_OPTION_NO_SPACE]: { checked: all_check, indeterminate: false },
        };
      } else {
        draftState = {
          ...draftState,
          [value]: { checked, indeterminate: false },
        };
        let all_check = true;
        let parent_check = true;
        Object.keys(draftState).map((key) => {
          if (!draftState[key].checked && key != ALL_OPTION_NO_SPACE && key.includes(`${" "}${parentValue}`)) {
            parent_check = draftState[key].checked;
          }
        });

        draftState = {
          ...draftState,
          [parentValue]: { checked: parent_check, indeterminate: false },
        };

        Object.keys(draftState).map((key) => {
          if (!draftState[key].checked && key != ALL_OPTION_NO_SPACE) {
            all_check = draftState[key].checked;
          }
        });

        draftState = {
          ...draftState,
          [ALL_OPTION_NO_SPACE]: { checked: all_check, indeterminate: false },
        };
      }

      setDraftCheckedState(draftState);
    },
    [draftCheckedState]
  );

  const handleFilterApply = () => {
    const filterModels = Object.keys(draftCheckedState).filter((e: any) => {
      if (draftCheckedState[e].checked) {
        return e;
      }
    });

    const newModel: any = [];
    filterModels.filter((e: any) => {
      if (!parents.includes(e)) {
        parents.map((parent: any) => {
          if (e.includes(` ${parent}`)) newModel.push(e);
        });
      }
    });

    let draftState = {
      ...draftCheckedState,
    };

    Object.keys(draftCheckedState).map((e: any) => {
      draftState = {
        ...draftState,
        [e]: { checked: draftCheckedState[e].checked, indeterminate: draftCheckedState[e].checked ? false : true },
      };
    });

    setDraftCheckedState(draftState);
    handleFilterSubmission(newModel, parents);
    closeFilterOptions();
  };

  const renderCheckedOptions = useCallback(
    (renderProp: RenderProps) => {
      const { filterList, parentValue } = renderProp;
      const handleMarketArrowClick = (evt: React.MouseEvent<HTMLElement>) => {
        const {
          currentTarget: { id: currentId, dataset },
        } = evt;
        const subCheckListId = dataset?.marketTarget;

        const arrowDownClass = "down";
        const showSubListClass = "show";

        const currentElement = currentId ? document.getElementById(currentId) : undefined;
        const targetUlElement = subCheckListId ? document.getElementById(subCheckListId) : undefined;

        // if (!currentElement?.classList.contains(arrowDownClass)) closeSubFilterOptions();
        currentElement?.classList.toggle(arrowDownClass);
        targetUlElement?.classList.toggle(showSubListClass);
      };

      return filterList.map((filterItem) => {
        const { value, child, hideCheckbox, group } = filterItem;
        const showArrow = child && child?.length ? "show" : "hidden";
        const checkValue = parentValue ? `${value} ${group}` : `${value}`;
        const selectionValue = parentValue ? `${value} ${group}` : `${value}`;
        return (
          <Fragment key={value}>
            <div className={"multiselect_container"}>
              <span
                id={parentValue ? `${value}_${group}_arrow` : `${value}_arrow`}
                className={`arrow-span ${showArrow}`}
                data-market-target={parentValue ? `${value.replaceAll(" ", "_")}_${group}_ul` : `${value.replaceAll(" ", "_")}_ul`}
                onClick={handleMarketArrowClick}
              >
                <span className={"market-arrow"} />
              </span>
              {!hideCheckbox && (
                <input
                  type="checkbox"
                  className={"multiselect-checkbox"}
                  id={parentValue ? `${value} ${group}` : `${value}`}
                  value={checkValue}
                  checked={draftCheckedState[selectionValue]?.checked ? draftCheckedState[selectionValue].checked : false}
                  onChange={handleMultiSelection}
                  data-parent-value={parentValue ? `${group}` : undefined}
                  data-filter-name={parameterName}
                  data-options-type={!parentValue && value !== ALL_OPTION_NO_SPACE ? "single" : "grouped"}
                  data-relationship={parentValue && value !== ALL_OPTION_NO_SPACE ? "child" : "parent"}
                  ref={(input) => {
                    if (input) {
                      input.indeterminate = draftCheckedState[checkValue]?.indeterminate;
                    }
                  }}
                />
              )}

              <label
                className="multiselect-label"
                htmlFor={parentValue ? `${value.replaceAll(" ", "_")}_${group}` : `${value.replaceAll(" ", "_")}`}
              >
                {value}
              </label>
            </div>
            {child && (
              <ul className="multicheck_sub_filter_options" id={`${value}_ul`} data-parent-key={value}>
                {/* @ts-ignore */}
                {renderCheckedOptions({ filterList: child, parentValue: value })}
              </ul>
            )}
          </Fragment>
        );
      });
    },
    [draftCheckedState]
  );

  return (
    <Fragment>
      {showOptionsOnly ? (
        <>
          <div className="filter" id={`${filterName}_filter_div`}>
            <div className="flex justify-space-between width-full" style={{ paddingRight: "15px" }}>
              <label className="filter_header" htmlFor={`${parentKey}_${childKey}_filter`}>
                {filterName}
              </label>
            </div>

            <div className="input_arrow" onClick={onShowFilterOptions} data-options-ul={`${parentKey}_ul`}>
              <input
                readOnly
                type="text"
                id={`${parentKey}_${childKey}_filter`}
                className={"filter_input"}
                data-test-id={`${parentKey}`}
                value={value}
              />
              <span className="arrow_down " />
            </div>

            <ul className="filter_options" id={`${parentKey}_ul`} data-test-id="region_list">
              {/* @ts-ignore */}
              {renderCheckedOptions({ filterList: filterState, parentKey, childKey })}
              <div className="filter_options__submit_area">
                <div className="filter_submit_btn submit_geo_filter" onClick={handleFilterApply}>
                  Apply
                </div>
              </div>
            </ul>
          </div>
        </>
      ) : (
        <div className="filter" id={`${filterName}_filter_div`}>
          <label className="filter_header" htmlFor={`${parentKey}_${childKey}_filter`}>
            {filterName}
          </label>

          <div className="input_arrow" onClick={onShowFilterOptions} data-options-ul={`${parentKey}_ul`}>
            <input
              readOnly
              type="text"
              id={`${parentKey}_${childKey}_filter`}
              className={"filter_input"}
              data-test-id={`${parentKey}`}
              value={value}
            />
            <span className="arrow_down" />
          </div>

          <ul className="filter_options" id={`${parentKey}_ul`} data-test-id="region_list">
            {/* @ts-ignore */}
            {renderCheckedOptions({ filterList: filterState, parentKey, childKey })}
            <div className="filter_options__submit_area">
              <div className="filter_submit_btn submit_geo_filter" onClick={handleFilterApply}>
                Apply
              </div>
            </div>
          </ul>
        </div>
      )}
    </Fragment>
  );
};
