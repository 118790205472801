import { ErrorBoundary } from "@sentry/react";
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { FullFiguresGraph } from ".";
import { fetchFullFiguresData, fetchKeyIndicatorsVarianceData, fetchTrendlineData } from "../../../actions";
import "../../../assets/styles/component/baselineTrend.scss";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import { BaselineTrendChart } from "../../../components/Charts/DigitalPerformance";
import { OceCheckBox } from "../../../components/Forms/common";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { metricTypes, METRIC_TITLES } from "../../../constants";
import { baselineData } from "../../../constants/interface";
import { ThemeContext } from "../../../context";
import { attachAnnotationsToChartData } from "../../../utils/annotationFunctions";
import { CpoCheckboxes } from "./CpoCheckboxes";

interface Props {
  metric: metricTypes;
  granularity: string;
  onHandleRadioClick: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  toggleAnnotationsDialog: () => void;
  setAnnotationStartDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationEndDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationDefaultMetric: Dispatch<SetStateAction<string>>;
  isCpoDashboard?: boolean;
  isVolume: boolean;
}

export const BaselineTrend = (props: Props) => {
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const {
    metric,
    granularity,
    onHandleRadioClick,
    toggleAnnotationsDialog,
    setAnnotationEndDate,
    setAnnotationStartDate,
    setAnnotationDefaultMetric,
    isCpoDashboard,
    isVolume,
  } = props;
  const baselineVarianceData: baselineData = useSelector((state: RootStateOrAny) => state.digital_performance.trendline.baselineData);
  const cpoVarianceData: any = useSelector((state: RootStateOrAny) => state.cpo_digital_performance.key_indicator_variance);
  const baselineIsLoading: boolean = useSelector((state: RootStateOrAny) => state.loading.baseline_graph);
  const fullFiguresIsLoading = useSelector((state: RootStateOrAny) => state.loading.full_figures_graph);
  const parameters = useSelector((state: RootStateOrAny) => state.parameters);
  const annotations = useSelector((state: RootStateOrAny) => state.digital_performance.baseline_chart_annotations);
  const [isLastWeek, setIsLastWeek] = useState(false);
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [baselineVarianceDataWithAnnotations, setBaselineVarianceDataWithAnnotations] = useState<Array<any>>([]);
  const [kbaChecked, setKbaChecked] = useState(false);
  const [leadsChecked, setLeadsChecked] = useState(false);
  const [visitsChecked, setVisitsChecked] = useState(false);
  const [newCarsChecked, setNewCarsChecked] = useState(false);

  const [checkedOptions, setCheckedOptions] = useState<Array<metricTypes>>([]);

  useEffect(() => {
    setKbaChecked(false);
    setLeadsChecked(false);
    setVisitsChecked(false);
  }, [metric]);

  useEffect(() => {
    setCheckedOptions((prevState) => (kbaChecked ? [...prevState, "kbas"] : prevState.filter((metric) => metric !== "kbas")));
  }, [kbaChecked]);

  useEffect(() => {
    setCheckedOptions((prevState) => (leadsChecked ? [...prevState, "leads"] : prevState.filter((metric) => metric !== "leads")));
  }, [leadsChecked]);

  useEffect(() => {
    setCheckedOptions((prevState) => (visitsChecked ? [...prevState, "visits"] : prevState.filter((metric) => metric !== "visits")));
  }, [visitsChecked]);

  useEffect(() => {
    parameters.date_range === "Last week" ? setIsLastWeek(true) : setIsLastWeek(false);
  }, [parameters.date_range]);

  useEffect(() => {
    if (!isCpoDashboard) isLastWeek ? dispatch(fetchTrendlineData()) : dispatch(fetchFullFiguresData());
  }, [parameters, isLastWeek, dispatch]);

  useEffect(() => {
    isCpoDashboard && dispatch(fetchKeyIndicatorsVarianceData());
  }, [parameters, dispatch]);

  useEffect(() => {
    setLoadingOverlay(isLastWeek ? baselineIsLoading : fullFiguresIsLoading);
  }, [isLastWeek, baselineIsLoading, fullFiguresIsLoading]);

  useEffect(() => {
    setBaselineVarianceDataWithAnnotations([]);
    if (annotations && baselineVarianceData) {
      const data = attachAnnotationsToChartData(annotations, baselineVarianceData, granularity, "baseline");
      setBaselineVarianceDataWithAnnotations(data);
    } else {
      setBaselineVarianceDataWithAnnotations(baselineVarianceData);
    }
  }, [baselineVarianceData, annotations, metric, granularity]);

  useEffect(() => {
    setNewCarsChecked(false);
  }, [metric]);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className="baseline_trend">
        <h3>
          {!isVolume
            ? "Historic conversion rate graph"
            : `${checkedOptions.length ? "Key indicators " : METRIC_TITLES[metric]} ${
                isCpoDashboard && isLastWeek ? "variance to baseline* trend" : `${checkedOptions.length ? "normalised " : ""}trend`
              }`}
        </h3>
        <div className="main_content">
          <div className="oce_metric_selection">
            {isCpoDashboard && <CpoCheckboxes newCarsChecked={newCarsChecked} setNewCarsChecked={setNewCarsChecked} metric={metric} />}
            {metric !== "visits" && (
              <OceCheckBox
                name="visits"
                id="visits"
                labelName="Visits"
                value={"visits"}
                checked={visitsChecked}
                setChecked={setVisitsChecked}
              />
            )}
            {metric !== "kbas" && (
              <OceCheckBox name="kba" id="kba" labelName="KBA visits" value={"KBA"} checked={kbaChecked} setChecked={setKbaChecked} />
            )}
            {metric !== "leads" && (
              <OceCheckBox
                name="leads"
                id="leads"
                labelName="Digital lead visits"
                value={"leads"}
                checked={leadsChecked}
                setChecked={setLeadsChecked}
              />
            )}
          </div>

          {isLastWeek && isCpoDashboard ? (
            cpoVarianceData?.Current?.length > 0 ? (
              <ErrorBoundary fallback={<ErrorMsg />}>
                <BaselineTrendChart
                  isCpoDashboard={isCpoDashboard}
                  data={isCpoDashboard ? cpoVarianceData?.Current : baselineVarianceDataWithAnnotations}
                  metric={metric}
                  theme={themeContext.theme}
                  toggleAnnotationsDialog={toggleAnnotationsDialog}
                  setAnnotationStartDate={setAnnotationStartDate}
                  setAnnotationEndDate={setAnnotationEndDate}
                  setAnnotationDefaultMetric={setAnnotationDefaultMetric}
                  annotations={annotations}
                  checkedOptions={checkedOptions}
                  newCarsChecked={newCarsChecked}
                />
              </ErrorBoundary>
            ) : baselineIsLoading ? null : (
              <NoDataMsg />
            )
          ) : parameters.date_range ? (
            <ErrorBoundary fallback={<ErrorMsg />}>
              <FullFiguresGraph
                isVolume={isVolume}
                isCpoDashboard={isCpoDashboard}
                leads={leadsChecked}
                kbas={kbaChecked}
                visits={visitsChecked}
                checkedOptions={checkedOptions}
                newCarsChecked={newCarsChecked}
                granularity={granularity}
                metric={metric}
                onHandleRadioClick={onHandleRadioClick}
                toggleAnnotationsDialog={toggleAnnotationsDialog}
                setAnnotationStartDate={setAnnotationStartDate}
                setAnnotationEndDate={setAnnotationEndDate}
                setAnnotationDefaultMetric={setAnnotationDefaultMetric}
              />
            </ErrorBoundary>
          ) : null}
        </div>

        <LoadingEllipsis isLoading={loadingOverlay} />
      </div>
    </ErrorBoundary>
  );
};
