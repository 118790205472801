import React from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { deleteSpecificParameter } from "../../../actions";

const MetricBreadcrumb = () => {
  const dispatch = useDispatch();

  const specificLead = useSelector((state: RootStateOrAny) => state.parameters.specific_lead);
  const specificKba = useSelector((state: RootStateOrAny) => state.parameters.specific_kba);

  const resetMetric = () => {
    const specificParams = ["specific_kba", "specific_lead"];
    specificParams.map((specificParam) => dispatch(deleteSpecificParameter(specificParam)));
  };

  return specificKba || specificLead ? (
    <button id="metric_button">
      <span className="update_metric">{specificKba || specificLead}</span>
      <span className="remove_specific_metric" onClick={resetMetric}>
        X
      </span>
    </button>
  ) : null;
};

export default MetricBreadcrumb;
