import {
  SET_FUTURE_MARKET_BRAND_SEARCH,
  SET_FUTURE_MARKET_MEDIA_MLC,
  SET_FUTURE_MARKET_PAID_IMPRESSIONS,
  SET_FUTURE_MARKET_REACH,
  SET_IN_MARKET_CLOSE_RATE_SALES,
  SET_IN_MARKET_LEAD_VOLUME,
  SET_IN_MARKET_MEDIA_MLC,
  SET_IN_MARKET_TOTAL_SALES,
  SET_MTM_ANNOTATIONS,
  SET_MTM_CURRENCY,
  SET_MTM_MODELS,
  SET_NEAR_MARKET_KBA_VISITS,
  SET_NEAR_MARKET_MEDIA_MLC,
  SET_NEAR_MARKET_UNIQUE_VISITS,
  SET_NEAR_MARKET_VISITS,
} from "../actions/actionTypes";

const initialState = {
  future_market_media_mlc: [],
  future_market_paid_impressions: [],
  future_market_reach: [],
  future_market_brand_search: [],
  near_market_media_mlc: [],
  near_market_visits: {},
  near_market_unique_visits: [],
  near_market_kba_visits: {},
  in_market_media_mlc: [],
  in_market_lead_volume: [],
  in_market_close_rate_sales: [],
  in_market_total_sales: [],
  currency: "",
  models: [],
  annotations: [],
};

const mtmReportReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_FUTURE_MARKET_MEDIA_MLC:
      return { ...state, future_market_media_mlc: action.payload };

    case SET_FUTURE_MARKET_PAID_IMPRESSIONS:
      return { ...state, future_market_paid_impressions: action.payload };

    case SET_FUTURE_MARKET_BRAND_SEARCH:
      return { ...state, future_market_brand_search: action.payload };

    case SET_FUTURE_MARKET_REACH:
      return { ...state, future_market_reach: action.payload };

    case SET_NEAR_MARKET_MEDIA_MLC:
      return { ...state, near_market_media_mlc: action.payload };

    case SET_NEAR_MARKET_VISITS:
      return { ...state, near_market_visits: action.payload };

    case SET_NEAR_MARKET_UNIQUE_VISITS:
      return { ...state, near_market_unique_visits: action.payload };

    case SET_NEAR_MARKET_KBA_VISITS:
      return { ...state, near_market_kba_visits: action.payload };

    case SET_IN_MARKET_MEDIA_MLC:
      return { ...state, in_market_media_mlc: action.payload };

    case SET_IN_MARKET_LEAD_VOLUME:
      return { ...state, in_market_lead_volume: action.payload };

    case SET_IN_MARKET_CLOSE_RATE_SALES:
      return { ...state, in_market_close_rate_sales: action.payload };

    case SET_IN_MARKET_TOTAL_SALES:
      return { ...state, in_market_total_sales: action.payload };

    case SET_MTM_CURRENCY:
      return { ...state, currency: action.payload };

    case SET_MTM_MODELS:
      return { ...state, models: action.payload };

    case SET_MTM_ANNOTATIONS:
      return { ...state, annotations: action.payload };

    default:
      return state;
  }
};

export default mtmReportReducer;
