import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useContext, useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchMsrpFilters, setCurrency, setParameters, setSpecificParameter } from "../../actions";
import "../../assets/styles/component/filters.scss";
import { DEFAULT_MSRP_PARAMETERS, PURCHASE_FUNNEL_DATE_FILTER_OPTIONS } from "../../constants";
import { FilterContext } from "../../context";
import { isMonthlyCustomDate } from "../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { currentDefaultFiltersSet, defaultFiltersSet } from "../../utils/utilityFunctions";
import { ErrorMsg } from "../AppMessages";
import { FiltersLayout } from "../Layouts";
import { DefaultFilter } from "./common";
import PurchaseFunnelDateFilter from "./common/PurchaseFunnelDateFilter";
import { closeFilterOptions, closeMarketOptions } from "./subs/helpers";

const CURRENCY_MAPPING: Record<string, string> = {
  "Local ¥": "¥",
  USD: "$",
};

const MsrpDashboardFilters = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const {
    date_range: dateRangeParamValue,
    brand: brandParamValue,
    market: marketParamValue,
    currency: currencyParamValue,
  } = useSelector((state: RootStateOrAny) => state.parameters);

  const filters = useSelector((state: RootStateOrAny) => state.filters.msrp_filters);

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("MSRP");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_MSRP_PARAMETERS));
      currentDefaultFiltersSet("MSRP");
    }
  }, []);

  useEffect(() => {
    dispatch(fetchMsrpFilters());
  }, [history.location.search]);

  //Reset date range param value
  useEffect(() => {
    if (dateRangeParamValue) {
      const isValidDate = PURCHASE_FUNNEL_DATE_FILTER_OPTIONS.includes(dateRangeParamValue) || isMonthlyCustomDate(dateRangeParamValue);
      if (!isValidDate) dispatch(setSpecificParameter("date_range", "This financial year"));
    } else {
      dispatch(setSpecificParameter("date_range", "This financial year"));
    }
  }, [dateRangeParamValue]);

  useEffect(() => {
    if (filters?.brand && filters?.brand?.length > 0 && !filters.brand.includes(brandParamValue)) {
      dispatch(setSpecificParameter("brand", filters.brand[0]));
    }

    if (filters?.market && filters?.market?.length > 0 && !filters.market.includes(marketParamValue)) {
      dispatch(setSpecificParameter("market", filters.market[0]));
    }

    if (
      (filters?.currency && filters?.currency?.length > 0 && !filters.currency.includes(currencyParamValue)) ||
      (!currencyParamValue && filters?.currency)
    ) {
      dispatch(setSpecificParameter("currency", filters.currency[0]));
    }
  }, [filters, brandParamValue, marketParamValue, currencyParamValue, dispatch]);

  useEffect(() => {
    dispatch(setCurrency(CURRENCY_MAPPING[currencyParamValue]));
  }, [currencyParamValue]);

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));
      eventTracking(MixpanelEvents.filter_change, {
        filter: filterName,
        value: optionValue,
        dashboard: "MSRP",
        page: "MSRP",
      });
    }
    closeFilterOptions();
    setShowFilterOptions(false);
  };

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  //reset filter button functionality
  const resetFilters = useCallback(() => {
    dispatch(setParameters(DEFAULT_MSRP_PARAMETERS));
  }, []);

  // Set params
  useEffect(() => {
    const params = new URLSearchParams();

    params.set("date_range", dateRangeParamValue);
    params.set("brand", brandParamValue);
    params.set("market", marketParamValue);
    params.set("currency", currencyParamValue);
    history.push({ search: `?${params.toString()}` });
  }, [dateRangeParamValue, brandParamValue, marketParamValue, currencyParamValue]);

  return (
    <FiltersLayout resetFilters={resetFilters}>
      <>
        {/*Date range*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <PurchaseFunnelDateFilter
            value={dateRangeParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
            onFilterClick={showFilterOptionsFn}
            isMsrp={true}
          />
        </ErrorBoundary>

        {/*Brand*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={filters?.brand}
            filterName={"brand"}
            filterLabel={"BRAND"}
            filterValue={brandParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Market*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={filters?.market}
            filterName={"market"}
            filterLabel={"MARKET"}
            filterValue={marketParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Currency*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={filters?.currency}
            filterName={"currency"}
            filterLabel={"CURRENCY"}
            filterValue={currencyParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>
      </>
    </FiltersLayout>
  );
});

export default MsrpDashboardFilters;
