import React, { Dispatch, useEffect, useState } from "react";
import { fetchDataV2 } from "../../../api/utils";
import "../../../assets/styles/component/dropDown.scss";
import { useDropdownVisible } from "../../../hooks";

interface DepartmentInputProps {
  department: string;
  setDepartment: Dispatch<string>;
}

export const DepartmentInput = (Props: DepartmentInputProps): JSX.Element => {
  const { department, setDepartment } = Props;
  const { wrapperRef: departmentsRef, isVisible: showDepartments, setIsVisible: setShowDepartments } = useDropdownVisible(false);

  const [departmentOptions, setDepartmentOptions] = useState([]);

  const handleDepartmentSelect = (option: string) => {
    setDepartment(option);
    setShowDepartments(false);
  };

  useEffect(() => {
    fetchDataV2("/user_service/get-all-departments").then((response) => {
      if (response && !("error" in response)) setDepartmentOptions(response);
    });
  }, []);

  return (
    <div className="input_field" id="department_input" ref={departmentsRef}>
      <label htmlFor="department">Department</label>
      <div className="field">
        <div className="dropdown" onClick={() => setShowDepartments(!showDepartments)}>
          <input
            type="text"
            name="department"
            id="department"
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            data-test-id="business_function"
            readOnly
          />
          <span className="arrow_down"></span>
        </div>
        {showDepartments && (
          <ul className="dropdown-options" id="department_options">
            {departmentOptions.map((option: string) => (
              <li key={option} onClick={() => handleDepartmentSelect(option)}>
                {option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
