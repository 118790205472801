import {
  FiltersData,
  IAppRatingsFilters,
  ICockpitFiltersData,
  IEuropeActivationFiltersData,
  ISeoGeography,
  IUserGroupMarkets,
} from "../constants/interface";
import {
  FETCH_APP_RATINGS_FILTERS,
  FETCH_BRAND_PERFORMANCE_IMAGE_ATTRIBUTE_FILTERS,
  FETCH_BRAND_PERFORMANCE_OAO_FILTERS,
  FETCH_BRAND_PERFORMANCE_VOLUME_FILTERS,
  FETCH_CCS_FILTERS,
  FETCH_CPO_FILTERS,
  FETCH_CPO_OPERATIONAL_FILTERS,
  FETCH_ECOMMERCE_FILTERS,
  FETCH_EUROPE_ACTIVATION_FILTERS,
  FETCH_EUROPE_ACTIVATION_MARKET_GROUPS,
  FETCH_EXECUTIVE_SUMMARY_FILTERS,
  FETCH_GEO_SCOPE,
  FETCH_JOURNEY_EXCELLENCE_FILTERS,
  FETCH_MIDAS_FILTERS,
  FETCH_MIDAS_FIXED_FILTERS,
  FETCH_MIDAS_FMI_BY_MODEL_FILTERS,
  FETCH_MIDAS_MEDIA_MIX_FILTERS,
  FETCH_MIDAS_MTP_CATEGORY_FILTERS,
  FETCH_MIDAS_SPEND_VS_TRAFFIC_FILTERS,
  FETCH_MIDAS_STRATEGIC_CE_FILTERS,
  FETCH_MIDAS_STRATEGIC_DEALER_SUPPORT_FILTERS,
  FETCH_MSRP_FILTERS,
  FETCH_MTM_MARKETS,
  FETCH_NEW_PURCHASE_FUNNEL_FILTERS,
  FETCH_OCE_ALL_MARKET_REGIONS,
  FETCH_OCE_ALL_REGIONS,
  FETCH_OCE_CHANNELS_BY_BRAND_FILTERS,
  FETCH_OCE_MARKETS_BY_BRAND_FILTERS,
  FETCH_OCE_MODELS_BY_BRAND_FILTERS,
  FETCH_PRODUCT_PERFORMANCE_FILTERS,
  FETCH_PURCHASE_FUNNEL_FILTERS,
  FETCH_SEO_GEOGRAPHY,
  FETCH_UAE_ACTIVATION_FILTERS,
  FETCH_USER_GROUP_MARKETS,
  FETCH_USE_OWNERSHIP_FILTERS,
  FETCH_VOC_FILTERS,
  SET_APP_RATINGS_FILTERS,
  SET_BRAND_PERFORMANCE_IMAGE_ATTRIBUTE_FILTERS,
  SET_BRAND_PERFORMANCE_OAO_FILTERS,
  SET_BRAND_PERFORMANCE_VOLUME_FILTERS,
  SET_CCS_FILTERS,
  SET_CPO_OPERATIONAL_FILTERS,
  SET_DFP_RESPONSE_ERROR,
  SET_ECOMMERCE_FILTERS,
  SET_EUROPE_ACTIVATION_FILTERS,
  SET_EUROPE_ACTIVATION_MARKET_GROUPS,
  SET_EXECUTIVE_SUMMARY_FILTERS,
  SET_FILTERS,
  SET_FILTER_OPTIONS_LIST,
  SET_GEO_SCOPE,
  SET_JOURNEY_EXCLLENCE_FILTERS,
  SET_MIDAS_FILTERS,
  SET_MSRP_FILTERS,
  SET_MTM_MARKETS,
  SET_NEW_PURCHASE_FUNNEL_FILTERS,
  SET_OCE_ALL_MARKET_REGIONS,
  SET_OCE_ALL_REGIONS,
  SET_OCE_CHANNELS_BY_BRAND_FILTERS,
  SET_OCE_MARKETS_BY_BRAND_FILTERS,
  SET_OCE_MODEL_BY_BRAND_FILTERS,
  SET_PMO_RESPONSE_ERROR,
  SET_PRODUCT_PERFORMANCE_FILTERS,
  SET_PURCHASE_FUNNEL_FILTERS,
  SET_SEO_GEOGRAPHY,
  SET_SPEND_VS_TRAFFIC_RESPONSE_ERROR,
  SET_UAE_ACTIVATION_FILTERS,
  SET_USER_GROUP_MARKETS,
  SET_USE_OWNERSHIP_FILTERS,
  SET_VOC_FILTERS,
} from "./actionTypes";

export const fetchGeoScopeData = () => ({ type: FETCH_GEO_SCOPE });

export const fetchFiltersCpoData = () => ({ type: FETCH_CPO_FILTERS });

export const fetchCockpitFiltersData = () => ({ type: FETCH_CCS_FILTERS });

export const setFilters = (data: FiltersData) => ({ type: SET_FILTERS, payload: data });

export const setGeoScope = (data: FiltersData) => ({ type: SET_GEO_SCOPE, payload: data });

export const setCCSFiltersData = (data: ICockpitFiltersData) => ({ type: SET_CCS_FILTERS, payload: data });

export const fetchAppRatingsFiltersData = () => ({ type: FETCH_APP_RATINGS_FILTERS });
export const setAppRatingsFiltersData = (data: IAppRatingsFilters) => ({ type: SET_APP_RATINGS_FILTERS, payload: data });

export const fetchEuropeActivationFilters = () => ({ type: FETCH_EUROPE_ACTIVATION_FILTERS });
export const setEuropeActivationFiltersData = (data: IEuropeActivationFiltersData) => ({
  type: SET_EUROPE_ACTIVATION_FILTERS,
  payload: data,
});

export const fetchEuropeActivationMarketGroups = () => ({ type: FETCH_EUROPE_ACTIVATION_MARKET_GROUPS });
export const setEuropeActivationMarketGroups = (data: Array<any>) => ({ type: SET_EUROPE_ACTIVATION_MARKET_GROUPS, payload: data });

export const fetchPurchaseFunnelFilters = () => ({ type: FETCH_PURCHASE_FUNNEL_FILTERS });
export const setPurchaseFunnelFilters = (data: Array<any>) => ({ type: SET_PURCHASE_FUNNEL_FILTERS, payload: data });

export const fetchUserGroupMarkets = () => ({ type: FETCH_USER_GROUP_MARKETS });
export const setUserGroupMarkets = (data: Array<IUserGroupMarkets>) => ({ type: SET_USER_GROUP_MARKETS, payload: data });

export const fetchMTMMarketsData = () => ({ type: FETCH_MTM_MARKETS });
export const setMTMMarketsData = (data: Array<{ region: string; has_sub_region: boolean; markets: string[] }>) => ({
  type: SET_MTM_MARKETS,
  payload: data,
});

export const fetchSeoGeography = () => ({ type: FETCH_SEO_GEOGRAPHY });
export const setSeoGeography = (data: Array<ISeoGeography>) => ({ type: SET_SEO_GEOGRAPHY, payload: data });

export const setOptionsList = (data: any, filter: string) => ({ type: SET_FILTER_OPTIONS_LIST, payload: data, filter });

export const fetchNewPurchaseFunnelFilters = () => ({ type: FETCH_NEW_PURCHASE_FUNNEL_FILTERS });
export const setNewPurchaseFunnelFilters = (data: any) => ({ type: SET_NEW_PURCHASE_FUNNEL_FILTERS, payload: data });

export const fetchMidasFilters = () => ({ type: FETCH_MIDAS_FILTERS });
export const setMidasFilters = (data: any) => ({ type: SET_MIDAS_FILTERS, payload: data });

export const fetchMidasFmiByModelFilters = () => ({ type: FETCH_MIDAS_FMI_BY_MODEL_FILTERS });

export const fetchMidasMediaMixFilters = () => ({ type: FETCH_MIDAS_MEDIA_MIX_FILTERS });

export const fetchMidasMtpCategoryFilters = () => ({ type: FETCH_MIDAS_MTP_CATEGORY_FILTERS });

export const fetchMidasStrategicDealerSupportFilters = () => ({ type: FETCH_MIDAS_STRATEGIC_DEALER_SUPPORT_FILTERS });

export const fetchMidasStrategicCEFilters = () => ({ type: FETCH_MIDAS_STRATEGIC_CE_FILTERS });

export const fetchMidasFixedFilters = () => ({ type: FETCH_MIDAS_FIXED_FILTERS });

export const fetchMidasSpendVsTrafficFilters = () => ({ type: FETCH_MIDAS_SPEND_VS_TRAFFIC_FILTERS });

export const setPmoResponseError = (data: any) => ({ type: SET_PMO_RESPONSE_ERROR, payload: data });

export const setDfpResponseError = (data: any) => ({ type: SET_DFP_RESPONSE_ERROR, payload: data });

export const setMidasSpendVsTrafficResponseError = (data: any) => ({ type: SET_SPEND_VS_TRAFFIC_RESPONSE_ERROR, payload: data });

export const fetchProductPerformanceFilters = () => ({ type: FETCH_PRODUCT_PERFORMANCE_FILTERS });
export const setProductPerformanceFilters = (data: any) => ({ type: SET_PRODUCT_PERFORMANCE_FILTERS, payload: data });

export const fetchUaeActivationFilters = () => ({ type: FETCH_UAE_ACTIVATION_FILTERS });
export const setUaeActivationFilters = (data: any) => ({ type: SET_UAE_ACTIVATION_FILTERS, payload: data });

export const fetchVocFilters = () => ({ type: FETCH_VOC_FILTERS });
export const setVocFilters = (data: any) => ({ type: SET_VOC_FILTERS, payload: data });

export const fetchCpoOperationalFilters = () => ({ type: FETCH_CPO_OPERATIONAL_FILTERS });
export const setCpoOperationalFilters = (data: any) => ({ type: SET_CPO_OPERATIONAL_FILTERS, payload: data });

export const fetchEcommerceFilters = () => ({ type: FETCH_ECOMMERCE_FILTERS });
export const setEcommerceFilters = (data: any) => ({ type: SET_ECOMMERCE_FILTERS, payload: data });

export const fetchMsrpFilters = () => ({ type: FETCH_MSRP_FILTERS });
export const setMsrpFilters = (data: any) => ({ type: SET_MSRP_FILTERS, payload: data });

export const fetchJourneyExcellenceFilters = (metric: string) => ({ type: FETCH_JOURNEY_EXCELLENCE_FILTERS, payload: metric });
export const setJourneyExcellenceFilters = (data: any) => ({ type: SET_JOURNEY_EXCLLENCE_FILTERS, payload: data });
export const fetchOCEModelsByBrandFilters = () => ({ type: FETCH_OCE_MODELS_BY_BRAND_FILTERS });
export const setOCEModelsByBrandFilters = (data: any) => ({ type: SET_OCE_MODEL_BY_BRAND_FILTERS, payload: data });

export const fetchOCEMarketsByBrandFilters = () => ({ type: FETCH_OCE_MARKETS_BY_BRAND_FILTERS });
export const setOCEMarketsByBrandFilters = (data: any) => ({ type: SET_OCE_MARKETS_BY_BRAND_FILTERS, payload: data });

export const fetchOCEChannelsByBrandFilters = () => ({ type: FETCH_OCE_CHANNELS_BY_BRAND_FILTERS });
export const setOCEChannelsByBrandFilters = (data: any) => ({ type: SET_OCE_CHANNELS_BY_BRAND_FILTERS, payload: data });

export const fetchOCEAllRegions = () => ({ type: FETCH_OCE_ALL_REGIONS });
export const setOCEAllRegions = (data: any) => ({ type: SET_OCE_ALL_REGIONS, payload: data });

export const fetchOCEAllMarketRegions = () => ({ type: FETCH_OCE_ALL_MARKET_REGIONS });
export const setOCEAllMarketRegions = (data: any) => ({ type: SET_OCE_ALL_MARKET_REGIONS, payload: data });

export const fetchUseOwnershipFilters = (page: string) => ({ type: FETCH_USE_OWNERSHIP_FILTERS, payload: page });
export const setUseOwnershipFilters = (data: any) => ({ type: SET_USE_OWNERSHIP_FILTERS, payload: data });

export const fetchBrandPerformanceVolumeFilters = () => ({ type: FETCH_BRAND_PERFORMANCE_VOLUME_FILTERS });
export const setBrandPerformanceVolumeFilters = (data: any) => ({ type: SET_BRAND_PERFORMANCE_VOLUME_FILTERS, payload: data });

export const fetchBrandPerformanceOaOFilters = () => ({ type: FETCH_BRAND_PERFORMANCE_OAO_FILTERS });
export const setBrandPerformanceOaOFilters = (data: any) => ({ type: SET_BRAND_PERFORMANCE_OAO_FILTERS, payload: data });

export const fetchBrandPerformanceImageAttributeFilters = () => ({ type: FETCH_BRAND_PERFORMANCE_IMAGE_ATTRIBUTE_FILTERS });
export const setBrandPerformanceImageAttributeFilters = (data: any) => ({
  type: SET_BRAND_PERFORMANCE_IMAGE_ATTRIBUTE_FILTERS,
  payload: data,
});

export const fetchExecutiveSummaryFilters = () => ({ type: FETCH_EXECUTIVE_SUMMARY_FILTERS });
export const setExecutiveSummaryFilters = (data: any) => ({ type: SET_EXECUTIVE_SUMMARY_FILTERS, payload: data });
