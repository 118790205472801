import React from "react";
import "../../../assets/styles/docs/ccsData.scss";

export const ProductMarketingOptimization = (): JSX.Element => (
  <div className="data_documentation">
    <h3>Product marketing optimization - DATA OVERVIEW</h3>

    <h4>Data Definitions and Sources</h4>
    <br />

    <table className="docs_table">
      <thead>
        <tr>
          <th>KPI</th>
          <th>Dashboard</th>
          <th>Description</th>
          <th>Data Source</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>Customer intention</td>
          <td>PMO / DFP</td>
          <td>{`Volume of configurations completed by visitors online.`}</td>
          <td>Adobe Analytics</td>
        </tr>
        <tr>
          <td>Actual sales</td>
          <td>PMO / DFP</td>
          <td>Volume of completed sales</td>
          <td>IDEA database</td>
        </tr>
        <tr>
          <td>Sales volume</td>
          <td>PMO</td>
          <td>{`Volume of sales per price range.
`}</td>
          <td>JATO</td>
        </tr>

        <tr>
          <td>Search interest</td>
          <td>PMO</td>
          <td>Google searches in the chosen market for localised, exact match search terms.</td>
          <td>CEDAR-Google custom index search volume</td>
        </tr>
        <tr>
          <td>Media spend</td>
          <td>PMO / DFP</td>
          <td>
            Major component of investment, focusing on the promotion of models through media channels (internet, OOH, Print, Radio, TV,
            etc.).
          </td>
          <td>MIDAS</td>
        </tr>
        <tr>
          <td>FMI spend</td>
          <td>DFP</td>
          <td>
            Investment done strategically mainly on Media (Internet, OOH, Print, Radio, TV, etc.), Fixed (Motor shows, Sponsorship,
            Research, etc.) and Strategic (PACE platform, DND, etc.) to improve brand image, OaO, and Sales.
          </td>
          <td>MIDAS</td>
        </tr>
        <tr>
          <td>Website visit</td>
          <td>DFP</td>
          <td>Counts every time the site has at least one page fully loaded.</td>
          <td>Adobe Analytics</td>
        </tr>
        <tr>
          <td>KBA visit</td>
          <td>DFP</td>
          <td>Visits including at least one KBA.</td>
          <td>Adobe Analytics</td>
        </tr>
        <tr>
          <td>KBA</td>
          <td>DFP</td>
          <td>Key buying actions: model detail comparison, saved searches views, compare tools views, dealer search, etc.</td>

          <td>Adobe Analytics</td>
        </tr>
        <tr>
          <td>KBA downloads</td>
          <td>DFP</td>
          <td>Volume of brochure downloads from the Nissan website.</td>

          <td>Adobe Analytics</td>
        </tr>
        <tr>
          <td>KBA configurator completions</td>
          <td>DFP</td>
          <td>Volume of configurations completed by visitors online.</td>

          <td>Adobe Analytics</td>
        </tr>
        <tr>
          <td>Digital lead</td>
          <td>DFP</td>
          <td>Enquiry, test drive, request a video, reserve, etc.</td>

          <td>Adobe Analytics</td>
        </tr>
        <tr>
          <td>Lead visits</td>
          <td>DFP</td>
          <td>Volume of visits including one digital lead.</td>

          <td>Adobe Analytics</td>
        </tr>
        <tr>
          <td>Lead request a quote</td>
          <td>DFP</td>
          <td>Volume of visits where the lead requests a quote on the Nissan website.</td>

          <td>Adobe Analytics</td>
        </tr>
        <tr>
          <td>Lead visits to test drive requests</td>
          <td>DFP</td>
          <td>Ratio of leads that requested a test drive on Nissan website.</td>

          <td>Adobe Analytics</td>
        </tr>
        <tr>
          <td>Test drive requests</td>
          <td>DFP</td>
          <td>Volume of visitor requests for a test drive from the Nissan website.</td>

          <td>Adobe Analytics</td>
        </tr>
        <tr>
          <td>Share of Voice</td>
          <td>PMO</td>
          <td>
            Share of voice is a measure of the market each model owns compared to the competitors. It is based on the investments made in
            different media groupings as Cinema, Internet, OOH, Print, Radio and TV and considers the models in the same segment and
            geography. The calculation uses Japanese Yen as currency.
          </td>

          <td>BIG3</td>
        </tr>
      </tbody>
    </table>

    <br />
  </div>
);
