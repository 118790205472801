import { DELETE_SPECIFIC_PARAMETER, SET_PARAMETERS, SET_SPECIFIC_PARAMETER } from "./actionTypes";

export const setParameters = (data: any) => {
  return { type: SET_PARAMETERS, payload: data };
};

export const setSpecificParameter = (name: string, value: string | boolean | Record<string, unknown>) => {
  return { type: SET_SPECIFIC_PARAMETER, name, payload: value };
};

export const deleteSpecificParameter = (name: string) => {
  return { type: DELETE_SPECIFIC_PARAMETER, name };
};
