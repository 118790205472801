import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter, Sprite } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, BRIGHT_BLUE, CYAN, WHITE } from "../../../constants";
import { Annotation } from "../../../pages/Admin/types";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { configureDateAxis, configureValueAxis, legendConfiguration, lineSeriesConfiguration } from "../helpers/configurations";
import { formatDateAxisLabelToMonthly } from "../helpers/mtmConfigurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string | Array<Annotation>>>;
  seriesList: Array<{ name: string; field: string }>;
  chartName: string;
  isLoading: boolean;
  theme: ThemeInt;
}

const seriesColors = [CYAN, BRIGHT_BLUE, WHITE, WHITE];

class LeadSalesTrendChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.tooltipConfig = this.tooltipConfig.bind(this);
  }
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { data, chartName, theme, seriesList } = this.props;

    this.chart = create(chartName, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;

    //Add legends
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.itemContainers.template.paddingTop = 2.5;
    this.chart.legend.itemContainers.template.marginBottom = 25;
    this.chart.legend.itemContainers.template.events.on("inited", function (ev) {
      const legend = ev.target;
      //@ts-ignore
      legend.dom.setAttribute("data-test-id", legend.dataItem?.dataContext.testId);
    });

    //Default x axis to show current date
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.minGridDistance = 30;
    dateAxis.dateFormats.setKey("day", "MMM");
    dateAxis.periodChangeDateFormats.setKey("day", "MMM");

    //Y Axis that is used by default
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.numberFormatter.numberFormat = "#.0";
    valueAxis.maxPrecision = 1;
    valueAxis.adjustLabelPrecision = false;

    //Function to create the series using the arguments given
    seriesList.forEach(({ name, field }, idx) => {
      const series = this.chart.series.push(new LineSeries());
      series.dataFields.dateX = "date";
      if (seriesColors) series.fill = color(seriesColors.length > 1 ? seriesColors[idx] : seriesColors[0]);
      if (seriesColors) series.stroke = color(seriesColors.length > 1 ? seriesColors[idx] : seriesColors[0]);

      const circleBullet = lineSeriesConfiguration(series, {
        valueY: field,
        name: name,
        yAxis: valueAxis,
      });
      series.numberFormatter = new NumberFormatter();
      series.numberFormatter.numberFormat = "###,###";
      circleBullet.tooltipText = `[bold font-size: var(--regular_font_size)]{name}:[bold font-size: var(--regular_font_size)] {valueY.numberFormat('#')}`;

      if (series.tooltip) {
        series.tooltip.label.fill = color(BLACK);
      }

      if (field.includes("forecast")) {
        series.strokeDasharray = "2,2";
      }

      if (name.includes("Private Sales")) {
        series.fill = theme == "dark" ? color(WHITE) : color(BLACK);
        series.stroke = theme == "dark" ? color(WHITE) : color(BLACK);
      }

      this.chart.series.each((series) => {
        series.bullets.getIndex(0)?.adapter.add("tooltipText", this.tooltipConfig);
      });

      if (series.tooltip) {
        series.tooltip.fontSize = "var(--regular_font_size)";
      }
    });
  }

  tooltipConfig(value: string | undefined, target: Sprite) {
    const dataValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };

    let text = `[bold]${formatDateAxisLabelToMonthly(dataValue?.date)}[/]\n`;

    this.chart.series.each((item) => {
      const value = item?.dataFields?.valueY && dataValue ? (dataValue[item.dataFields.valueY] as number) : null;
      text += `[${item.stroke}]●[/] ${item.name}: ${value ? Number(value).toLocaleString() : "n/a"}\n`;
    });

    return text;
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default LeadSalesTrendChart;
