import React, { Dispatch, SetStateAction } from "react";
interface Props {
  id: string;
  setMetric: Dispatch<SetStateAction<string>>;
}

const MetricButton = (props: Props) => {
  const { setMetric, id } = props;
  return (
    <div id={`${id}_ccs_back_button`} onClick={() => setMetric("")} className="back_button" data-test-id={`${id}_back_button`}>
      &lt;
      <span className="back_text">Back</span>
    </div>
  );
};

export default MetricButton;
