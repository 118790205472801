import moment from "moment";
import { MIDAS_DATE_FILTER_OPTIONS } from "../constants";
import { apiFetchV2, fetchDataV2 } from "./utils";

export const midasApiParamsToObject = (params: string) => {
  const parameters = new URLSearchParams(params);
  const paramObj = Object.fromEntries(parameters.entries());

  const dateRangeParam = parameters?.get("date_range");
  const yr_scnr_rvParameter = paramObj?.yr_scnr_rv?.split(",");
  const brandParam = paramObj?.brand?.split(",");
  const modelParameter = /all/gi.test(paramObj?.model) ? ["All"] : paramObj?.model?.split(",");
  const granularityParam = paramObj?.granularity?.includes("Month") ? "monthly" : "quarterly";
  const mcGroupParam = /all/gi.test(paramObj?.mc_group) ? [] : paramObj?.mc_group?.split(",");
  const subgroupParam = /all/gi.test(paramObj?.subgroup) || paramObj?.subgroup == "undefined" ? [] : paramObj?.subgroup?.split(",");
  const marketParam = /all/gi.test(paramObj?.market) ? [] : paramObj?.market?.split(",");

  const date = dateRangeParam
    ? MIDAS_DATE_FILTER_OPTIONS.includes(dateRangeParam)
      ? dateRangeParam == "Last 12 months"
        ? "last_year"
        : dateRangeParam == "Last 6 months"
        ? "last_half_year"
        : dateRangeParam.split(" ").join("_").toLocaleLowerCase()
      : "custom"
    : "";

  delete paramObj?.yr_scnr_rv;
  delete paramObj?.model;
  delete paramObj?.unit;
  delete paramObj?.brand;
  delete paramObj?.granularity;
  delete paramObj?.date_range;
  delete paramObj?.mc_group;
  delete paramObj?.subgroup;

  if (date == "custom") {
    paramObj["start_date"] = dateRangeParam
      ? moment(dateRangeParam.split("-")[0].trim(), "MMMM YYYY").startOf("month").format("YYYY-MM-DD")
      : "";
    paramObj["end_date"] = dateRangeParam
      ? moment(dateRangeParam.split("-")[1].trim(), "MMMM YYYY").endOf("month").format("YYYY-MM-DD")
      : "";
  }

  return {
    ...paramObj,
    date_range: date,
    granularity: granularityParam,
    yr_scnr_rv: yr_scnr_rvParameter,
    model: modelParameter,
    mc_group: mcGroupParam,
    subgroup: subgroupParam,
    market: marketParam,
    brand: brandParam,
  };
};

const getUnitParam = (params: string): string => {
  const parameters = new URLSearchParams(params);
  const paramObj = Object.fromEntries(parameters.entries());
  return paramObj?.unit;
};

export const getShowTouchPoints = (params: string): string => {
  const parameters = new URLSearchParams(params);
  const paramObj = Object.fromEntries(parameters.entries());
  return paramObj?.show_touchpoints;
};

export const getMidasFmiByQuarter = (params: string) =>
  apiFetchV2("/midas/fmi-run-rate/fmi-by-quarter?use_cache=true", midasApiParamsToObject(params));

export const getMidasByModelFmiByQuarter = (params: string) => {
  return apiFetchV2(`/midas/fmi-by-model/fmi-by-quarter?unit=${getUnitParam(params)}&use_cache=true`, midasApiParamsToObject(params));
};

export const getMtpSpend = (params: string) => apiFetchV2("/midas/fmi-run-rate/mtp-spend?use_cache=true", midasApiParamsToObject(params));

export const getMediaMixDataByQuarter = (params: string) =>
  apiFetchV2("/midas/media-mix/data-by-quarter?use_cache=true", midasApiParamsToObject(params));

export const getMtpCategoryDataByQuarter = (params: string) =>
  apiFetchV2(`/midas/mtp-category/data-by-quarter?unit=${getUnitParam(params)}&use_cache=true`, midasApiParamsToObject(params));

export const getStrategicDealerSupportDataByQuarter = (params: string) =>
  apiFetchV2(
    `/midas/strategic-dealer-support/data-by-quarter?unit=${getUnitParam(params)}&touchpoints=${getShowTouchPoints(params)}&use_cache=true`,
    midasApiParamsToObject(params)
  );

export const getStrategicCEDataByQuarter = (params: string) =>
  apiFetchV2(
    `/midas/strategic-ce/data-by-quarter?unit=${getUnitParam(params)}&touchpoints=${getShowTouchPoints(params)}&use_cache=true`,
    midasApiParamsToObject(params)
  );

export const getFixedDataByQuarter = (params: string) =>
  apiFetchV2(
    `/midas/fixed/data-by-quarter?unit=${getUnitParam(params)}&touchpoints=${getShowTouchPoints(params)}&use_cache=true`,
    midasApiParamsToObject(params)
  );

export const getSpendVsTrafficData = (params: string) =>
  apiFetchV2(`/midas/spend-vs-traffic/data?unit=${getUnitParam(params)}&use_cache=true`, midasApiParamsToObject(params));

export const getSpendVsTrafficModelData = (params: string) =>
  apiFetchV2(`/midas/spend-vs-traffic/model-data?unit=${getUnitParam(params)}&use_cache=true`, midasApiParamsToObject(params));

export const getSpendVsTrafficMarketData = (params: string) =>
  apiFetchV2(`/midas/spend-vs-traffic/market-data?unit=${getUnitParam(params)}&use_cache=true`, midasApiParamsToObject(params));

export const getMidasDataDefinitions = () => fetchDataV2(`/midas/data-definition`);
