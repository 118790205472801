import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { getAvailableYearScenarios, getSortedYrScenarios, getTableData, getTotals } from "../../helpers";
import MidasShareTables from "../../sharedComponents/MidasShareTables";

const StrategicDealerSupportTables = () => {
  const yr_scnr_rv = useSelector((state: RootStateOrAny) => state.filters.midas_filters.yr_scnr_rv);

  const data = useSelector((state: RootStateOrAny) => state.midas.midas_strategic_dealer_support_data_by_quarter);
  const loading = useSelector((state: RootStateOrAny) => state.loading.midas_strategic_dealer_support_data_by_quarter);

  const yrScenarios: string[] = useMemo(() => getAvailableYearScenarios(data?.full_year), [data]);

  const sortedYrScenarios = useMemo(() => getSortedYrScenarios(yr_scnr_rv, yrScenarios), [yrScenarios, yr_scnr_rv]);

  const categories: string[] = useMemo(() => {
    if (data?.full_year?.length) {
      return Array.from(new Set(data?.full_year?.map((val: { fmi_category: string }) => val?.fmi_category)));
    }
    return [];
  }, [data]);

  const combinedData = useMemo(() => {
    return data?.q1?.concat(data?.q2?.concat(data?.q3?.concat(data?.q4?.concat(data?.full_year))));
  }, [data]);

  const q1TableData = useMemo(() => getTableData(data?.q1, categories, "fmi_category", [1]), [data, categories]);
  const q2TableData = useMemo(() => getTableData(data?.q2, categories, "fmi_category", [2]), [data, categories]);
  const q3TableData = useMemo(() => getTableData(data?.q3, categories, "fmi_category", [3]), [data, categories]);
  const q4TableData = useMemo(() => getTableData(data?.q4, categories, "fmi_category", [4]), [data, categories]);
  const fullYearTableData = useMemo(() => getTableData(data?.full_year, categories, "fmi_category", [0]), [data, categories]);

  const combinedTableData = useMemo(
    () => getTableData(combinedData, categories, "fmi_category", [1, 2, 3, 4, 0]),
    [combinedData, categories]
  );

  const q1Totals = useMemo(() => getTotals(q1TableData, sortedYrScenarios, [1]), [sortedYrScenarios, data, yrScenarios]);
  const q2Totals = useMemo(() => getTotals(q2TableData, sortedYrScenarios, [2]), [sortedYrScenarios, data, yrScenarios]);
  const q3Totals = useMemo(() => getTotals(q3TableData, sortedYrScenarios, [3]), [sortedYrScenarios, data, yrScenarios]);
  const q4Totals = useMemo(() => getTotals(q4TableData, sortedYrScenarios, [4]), [sortedYrScenarios, data, yrScenarios]);
  const fullYearTotals = useMemo(() => getTotals(fullYearTableData, sortedYrScenarios, [0]), [sortedYrScenarios, data, yrScenarios]);
  const combinedTotals = useMemo(
    () => getTotals(combinedTableData, sortedYrScenarios, [0, 1, 2, 3, 4]),
    [sortedYrScenarios, data, yrScenarios]
  );

  return (
    <MidasShareTables
      q1TableData={q1TableData}
      q2TableData={q2TableData}
      q3TableData={q3TableData}
      q4TableData={q4TableData}
      fullYearTableData={fullYearTableData}
      combinedTableData={combinedTableData}
      q1Totals={q1Totals}
      q2Totals={q2Totals}
      q3Totals={q3Totals}
      q4Totals={q4Totals}
      fullYearTotals={fullYearTotals}
      combinedTotals={combinedTotals}
      sortedYrScenarios={sortedYrScenarios}
      loading={loading}
      hasCategoryToggle
    />
  );
};

export default StrategicDealerSupportTables;
