import { ErrorBoundary } from "@sentry/react";
import React, { useState } from "react";
import { ErrorMsg } from "../../../components/AppMessages";
import Collapsible from "../../../components/Collapsible/Collapsible";
import PmoTooltip from "../components/common/PmoTooltip";
import VolumeToggle from "../components/common/VolumeToggle";
import ComparisonPriceVolume from "../components/ComparisonPriceVolume";
import CustomerIntentionCluster from "../components/CustomerIntentionCluster";
import CustomerIntentionComparison from "../components/CustomerIntentionComparison";
import CustomerIntentionComparisonPower from "../components/CustomerIntentionPowerTrains";
import PriceSalesVolume from "../components/PriceSalesVolume";
import NimTechSection from "./NimTechSection";

interface Props {
  hideModules: boolean;
  showPowerModules: boolean;
  setPriceType: React.Dispatch<React.SetStateAction<string>>;
  seriesList: { name: string; seriesColor: string }[];
}

const SpecificationSection = (props: Props) => {
  const { hideModules, seriesList, showPowerModules, setPriceType } = props;

  const [showVolumes, setShowVolumes] = useState<boolean>(false);
  const [showVolumesPowerTrains, setShowVolumesPowerTrains] = useState<boolean>(true);
  const TooltipParagraphs = () => (
    <>
      <p>
        <span style={{ fontWeight: "bold" }}>Customer intention</span> is based on the volume of configurations completed by visitors
        online.
      </p>
      <p>
        <u>Note</u>: visitors are able to complete more than one configuration, and can add to the volume of completed configurations.
      </p>
      <p>
        <u>Source</u>: Nissan website data from Adobe Analytics
      </p>
      <br />
      <p>
        <span style={{ fontWeight: "bold" }}>Actual sales</span> is the volume of completed sales.
      </p>
      <p>
        <u>Source</u>: sales data sourced from IDEA database
      </p>
    </>
  );

  const Paragraphs = () => (
    <>
      <p>
        <span style={{ fontWeight: "bold" }}>Customer intention</span> is based on the volume of configurations completed by visitors
        online.
      </p>
      <p>
        <u>Note</u>: visitors are able to complete more than one configuration, and can add to the volume of completed configurations.
      </p>

      <p>
        <u>Note2</u>: some configurations may have undefined powertrains. This numbers are not counted on the total figures.
      </p>

      <p>
        <u>Source</u>: Nissan website data from Adobe Analytics
      </p>
    </>
  );
  return (
    <Collapsible myStyle="pmo_sotu specification_and_prices_sotu" show={true} title="Specification and prices optimization">
      <>
        {!hideModules && (
          <>
            <div className="customer_intention_and_comparison primary_tile_background">
              <div className="header" style={{ padding: "1.5em" }}>
                <div className="title_container">
                  <h3>Customer intention vs sales by grades</h3>
                  <PmoTooltip paragraphs={<TooltipParagraphs />} />
                </div>
                <VolumeToggle showVolumes={showVolumes} setShowVolumes={setShowVolumes} />
              </div>
              <div className="chart_content">
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <CustomerIntentionCluster showVolumes={showVolumes} />
                </ErrorBoundary>
                <CustomerIntentionComparison showVolume={showVolumes} />
              </div>
            </div>

            {/* price sales volume curve */}
            <div className="price_sales_and_comparison primary_tile_background">
              <ErrorBoundary fallback={<ErrorMsg />}>
                <PriceSalesVolume seriesList={seriesList} setPriceType={setPriceType} />
              </ErrorBoundary>
              <ErrorBoundary fallback={<ErrorMsg />}>
                <ComparisonPriceVolume seriesList={seriesList} />
              </ErrorBoundary>
            </div>
          </>
        )}
        {/* e-POWER/EV acceptance */}
        {showPowerModules && (
          <div className="ev_power primary_tile_background">
            <div className="header" style={{ padding: "1.5em" }}>
              <div className="title_container">
                <h3>e-POWER/EV acceptance</h3>
                <PmoTooltip paragraphs={<Paragraphs />} />
              </div>
              <VolumeToggle mixLabel showVolumes={showVolumesPowerTrains} setShowVolumes={setShowVolumesPowerTrains} />
            </div>
            <div className="chart_content">
              <CustomerIntentionComparisonPower showVolume={showVolumesPowerTrains} target="powertrains" />
            </div>
          </div>
        )}

        <NimTechSection showVolumes={showVolumes} setShowVolumes={setShowVolumes} />
      </>
    </Collapsible>
  );
};

export default SpecificationSection;
