import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { DefaultTable } from "../../../components/Tables/subs";

const InfluenceShopppingToolsTable = () => {
  const influenceOnShoppingToolsData = useSelector((state: RootStateOrAny) => state.product_performance.influence_on_shopping_tools);
  const { product } = useSelector((state: RootStateOrAny) => state.parameters);

  const columns = useMemo(
    () => [
      {
        id: "metric",
        Header: "",
        accessor: "metric",
        disableSortBy: true,
      },
      {
        id: "metric_visits",
        Header: `${product}`,
        accessor: "metric_visits",
        disableSortBy: true,
        Cell: (cell: { value: number }) => {
          const { value } = cell;
          return value ? value.toLocaleString() : "-";
        },
      },
      {
        id: "all_visits",
        Header: "All visits",
        accessor: "all_visits",
        disableSortBy: true,
        Cell: (cell: { value: number }) => {
          const { value } = cell;
          return value ? value.toLocaleString() : "-";
        },
      },
    ],
    [influenceOnShoppingToolsData, product]
  );

  return (
    <DefaultTable
      columns={columns}
      data={influenceOnShoppingToolsData}
      id="influenceOnShoppingToolsTable"
      hiddenCols={[]}
      isChildComponent={false}
      initialSortColId={{ id: "none", desc: false }}
    />
  );
};

export default InfluenceShopppingToolsTable;
