import { CategoryAxis, ColumnSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { disposeChart } from "../helpers/chartHelpers";
import { columnSeriesConfiguration, configureCategoryAxis, configureValueAxis, legendConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<{ label: string; [index: string]: number | string }>;
  topServices: Array<string>;
  theme: ThemeInt;
}

class TopUsedServicesStackedChart extends Component<Props> {
  chartId = "top_used_servicesChart";
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId);
      this.initChart();
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, theme } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#a";
    this.chart.colors.list = [color("#39B8AA"), color("#374649"), color("#576B6D"), color("#F6625E"), color("#F2C80F")];

    // Creating Axes
    const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "label";

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.calculateTotals = true;
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text + "%";
    });

    // Dynamically Creating series using the keys in the object
    this.props.topServices.reverse().forEach((topService: string) => {
      // Creating Series
      const series = this.chart.series.push(new ColumnSeries());
      columnSeriesConfiguration(series, {
        name: topService,
        valueY: topService,
      });
      series.dataFields.categoryX = "label";
      series.columns.template.tooltipText = "{categoryX} {name}: {valueY.formatNumber('#.')}%";
      series.columns.template.width = percent(65);
      series.stacked = true;
    });

    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.position = window.innerWidth < 700 ? "bottom" : "right";
    this.chart.legend.reverseOrder = true;
  }
  render() {
    return <div id={"top_used_servicesChart"} className={"metricChart"} />;
  }
}

export default TopUsedServicesStackedChart;
