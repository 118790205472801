import { ErrorBoundary } from "@sentry/react";
import moment from "moment/moment";
import React, { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import "../../../../assets/styles/component/satisfaction.scss";
import { ErrorMsg } from "../../../../components/AppMessages";
import { CallCentreDonutChart, StackedSatisfactionChart } from "../../../../components/Charts/CCSCockpit";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { CALL_CENTER_COLORS } from "../../../../constants";
import { ThemeContext } from "../../../../context";

interface Props {
  isLoading: boolean;
  showMetric?: boolean;
  onClick?: () => void;
}

export const SatisfactionTile = (props: Props) => {
  const { isLoading, showMetric, onClick: handleClick } = props;
  const themeContext = useContext(ThemeContext);

  const callCenterData = useSelector((state: RootStateOrAny) => state.ccs_cockpit.callCentre);
  const callCenterChartData = useSelector((state: RootStateOrAny) => state.ccs_cockpit.callCentreChartData);

  const {
    date_range: dateParamValue,
    model: modelParamValue,
    region: regionParamValue,
  } = useSelector((state: RootStateOrAny) => state.parameters);
  const dates: Array<string> = useMemo(() => dateParamValue.split(","), [dateParamValue]);

  const donutChartData = useMemo(() => {
    let negativeCount = 0;
    let positiveCount = 0;
    if (callCenterData?.Current) {
      negativeCount = callCenterData?.Current[0]?.negative;
      positiveCount = callCenterData?.Current[0]?.positive;
    }
    return [
      { title: "negative", value: negativeCount, color: CALL_CENTER_COLORS["negative"] },
      { title: "positive", value: positiveCount, color: CALL_CENTER_COLORS["positive"] },
    ];
  }, [callCenterData]);

  const satisfactionChartData = useMemo(() => {
    if (callCenterChartData?.Current)
      return callCenterChartData?.Current?.reduce((result: [{ [index: string]: any }], row: any) => {
        const { negative, positive, date } = row;
        const label = moment(date).format("MMM YY");

        return [...result, { label, negative, positive }];
      }, []);

    return [];
  }, [callCenterChartData]);

  return (
    <div className="tile" id="satisfactionTile" onClick={handleClick}>
      <div className="tile_header">Satisfaction (call center)</div>
      <div className="CCS_main_content">
        <div className="tile_content">
          {callCenterData?.Current ? (
            <div id="callCenterChartArea">
              <div className="label" id="positiveLabel">
                <p className="value">
                  <span className="main">{`${Math.round(callCenterData.Current[0].positive)}%`}</span>

                  <span className="value_label">positive</span>
                </p>
              </div>

              <ErrorBoundary fallback={<ErrorMsg />}>
                <CallCentreDonutChart data={donutChartData} theme={themeContext.theme} />
              </ErrorBoundary>

              <div className="label" id="negativeLabel">
                <p className="value">
                  <span className="main">{`${Math.round(callCenterData.Current[0].negative)}%`}</span>

                  <span className="value_label">negative</span>
                </p>
              </div>
            </div>
          ) : (
            <p className="value no_chart">n/a</p>
          )}
        </div>
        {showMetric && (
          <div className="metric_content">
            <ErrorBoundary fallback={<ErrorMsg />}>
              <StackedSatisfactionChart data={satisfactionChartData} theme={themeContext.theme} />
            </ErrorBoundary>
          </div>
        )}
      </div>

      <LoadingEllipsis isLoading={isLoading} />
    </div>
  );
};
