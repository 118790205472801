import { Button } from "@material-ui/core";
import React, { useCallback } from "react";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import "../../../assets/styles/component/filterBtns.scss";

interface Props {
  id: string;
  navigateTo: string;
  name: string;
  withBackArrow?: boolean;
  extraContainerClass?: string;
  extraBtnClass?: string;
  showOnMobile?: boolean;
}

export const FilterBtns = withRouter((props: Props & RouteComponentProps) => {
  const { id, navigateTo, name, history, withBackArrow, extraBtnClass, extraContainerClass, showOnMobile } = props;

  const onBtnLinkClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      const {
        currentTarget: { dataset },
      } = evt;
      const pageLink = dataset?.page;
      const urlSearchString = window.location.search;
      history.push({ pathname: pageLink, search: urlSearchString });
    },
    [history]
  );

  return (
    <div className={`filter filter_btn ${showOnMobile ? "" : "hide_on_mobile"} ${extraContainerClass ?? ""}`} id={id}>
      <Button
        variant={"outlined"}
        className={`filter_btn_element ${extraBtnClass ?? ""}`}
        data-page={navigateTo}
        onClick={(evt) => onBtnLinkClick(evt)}
        size={"small"}
        data-test-id={id}
      >
        {withBackArrow && <span className="back_filter_arrow" />}
        {name}
      </Button>
    </div>
  );
});
