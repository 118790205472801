import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchJourneyExcellenceFilters, setParameters, setSpecificParameter } from "../../../actions";
import { setJourneyExcellenceCurrency } from "../../../actions/journeyExcellenceActions";
import {
  ALL_OPTION_NO_SPACE,
  DEFAULT_JOURNEY_EXCELLENCE_PARAMETERS,
  DEFAULT_PARAMETER_VALUES,
  JOURNEY_EXCELLENCE_DATE_FILTER_OPTIONS,
  MIN_SELECTABLE_DATE,
} from "../../../constants";
import { currencies as currencyMapping } from "../../../constants/currenctConstants";
import { FilterContext } from "../../../context";
import { JourneyExcellenceContext } from "../../../pages/Journey Excellence/DeepDive/context/JourneyExcellenceContext";
import { isMonthlyCustomDate } from "../../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { currentDefaultFiltersSet, defaultFiltersSet } from "../../../utils/utilityFunctions";
import { ErrorMsg } from "../../AppMessages";
import { FiltersLayout } from "../../Layouts";
import { DefaultFilter, MultiSelectFilter } from "../common";
import { DigitalLeadsSourcesFilter } from "../common/DigitalLeadsSourcesFilter";
import PurchaseFunnelDateFilter from "../common/PurchaseFunnelDateFilter";
import { closeFilterOptions, closeMarketOptions } from "../subs/helpers";

interface Props {
  filterBtns: Array<{ id: string; navigateTo: string; name: string }>;
}
const JourneyExcellenceDeepDiveFilters = withRouter((props: RouteComponentProps & Props): JSX.Element => {
  const { history, filterBtns } = props;
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);
  const { metric } = useContext(JourneyExcellenceContext);

  const brandParamValue = useSelector((state: RootStateOrAny) => state.parameters.brand);
  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.parameters.date_range);
  const currencyParamValue = useSelector((state: RootStateOrAny) => state.parameters.currency);
  const marketParamValue = useSelector((state: RootStateOrAny) => state.parameters.market);
  const modelParamValue = useSelector((state: RootStateOrAny) => state.parameters.model);
  const channelParamValue = useSelector((state: RootStateOrAny) => state.parameters.channel);
  const leadGroupParamValue = useSelector((state: RootStateOrAny) => state.parameters.lead_group);
  const leadsSourceParamValue = useSelector((state: RootStateOrAny) => state.parameters.leads_source);

  const brands = useSelector((state: RootStateOrAny) => state.filters.journey_excellence_filters.brand);
  const markets = useSelector((state: RootStateOrAny) => state.filters.journey_excellence_filters.market);
  const currencies = useSelector((state: RootStateOrAny) => state.filters.journey_excellence_filters.currency);
  const models = useSelector((state: RootStateOrAny) => state.filters.journey_excellence_filters.model);
  const channels = useSelector((state: RootStateOrAny) => state.filters.journey_excellence_filters.channel);
  const leadsSources = useSelector((state: RootStateOrAny) => state.filters.journey_excellence_filters.leads_source);

  const dataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.digital_performance);

  //Sets the initial start and end selectable date to be 1st April 2019 and the latest data refresh | today() - 1 (yesterday) if the last data refresh is not yet defined
  const [marketDataAvailability, setMarketDataAvailability] = useState<{ start: string; end: string }>({
    start: MIN_SELECTABLE_DATE,
    end: dataRefresh || moment().subtract(1, "days").format("DD/MM/YYYY"),
  });

  const [modelFilterValue, setModelFilterValue] = useState(modelParamValue);
  const [marketFilterValue, setMarketFilterValue] = useState(marketParamValue);
  const [leadsSourceFilterValue, setLeadsSourceFilterValue] = useState(leadsSourceParamValue);

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("Journey Excellence Dashboard");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_JOURNEY_EXCELLENCE_PARAMETERS));
      currentDefaultFiltersSet("Journey Excellence Dashboard");
    }
  }, []);

  useEffect(() => {
    //add allowance for setting of query params used in api call
    setTimeout(() => dispatch(fetchJourneyExcellenceFilters(metric)), 100);
  }, [dateRangeParamValue, brandParamValue, marketParamValue, metric]);

  //Reset date range param value
  useEffect(() => {
    if (dateRangeParamValue) {
      const isValidDate = JOURNEY_EXCELLENCE_DATE_FILTER_OPTIONS.includes(dateRangeParamValue) || isMonthlyCustomDate(dateRangeParamValue);
      if (!isValidDate) dispatch(setSpecificParameter("date_range", "Last quarter"));
    } else {
      dispatch(setSpecificParameter("date_range", "Last quarter"));
    }
  }, [dateRangeParamValue]);

  //Validate brand param
  useEffect(() => {
    if (brands && brands.length > 0 && !brands.includes(brandParamValue)) {
      dispatch(setSpecificParameter("brand", brands[0]));
    }
  }, [brands]);

  //Validate market param
  useEffect(() => {
    if (markets && markets.length > 0 && marketParamValue) {
      const selectedMarkets = marketParamValue.split(",");
      !selectedMarkets.every((market: string) => markets.includes(market)) && dispatch(setSpecificParameter("market", markets.join(",")));
    } else if (markets && markets.length > 0) {
      dispatch(setSpecificParameter("market", markets.join(",")));
    }
  }, [marketParamValue, markets, metric]);

  //Validate currency param
  useEffect(() => {
    if (currencies && currencies.length > 0) {
      dispatch(setSpecificParameter("currency", currencies[0]));
    }
  }, [currencies]);

  //Validate leads source param
  useEffect(() => {
    if (leadsSourceParamValue && leadsSources && leadsSources.length > 0) {
      const leadSourceArr = leadsSourceParamValue.split(",");
      const leadSourcesOptions = leadsSources.flatMap((item: any) => item.leads_source);
      const leadSourceAreValid =
        leadSourceArr.every((lead: string) => leadSourcesOptions.includes(lead)) ||
        (marketParamValue != "Japan" && leadsSourceParamValue === "All");
      if (!leadSourceAreValid)
        marketParamValue == "Japan"
          ? dispatch(setSpecificParameter("leads_source", leadSourcesOptions[0]))
          : dispatch(setSpecificParameter("leads_source", "All"));
    } else if (!leadsSourceParamValue) {
      dispatch(setSpecificParameter("leads_source", "All"));
    }
  }, [leadsSourceParamValue, leadsSources, marketParamValue]);

  useEffect(() => {
    if (leadGroupParamValue && leadsSources && leadsSources.length > 0) {
      const leadGroupArr = leadGroupParamValue.split(",");
      const leadGroupOptions = leadsSources.flatMap((item: any) => item.lead_group);
      const leadGroupAreValid =
        leadGroupArr.every((lead: string) => leadGroupOptions.includes(lead)) ||
        (marketParamValue != "Japan" && leadGroupParamValue === "All");
      if (!leadGroupAreValid)
        marketParamValue == "Japan"
          ? dispatch(setSpecificParameter("lead_group", leadGroupOptions[0]))
          : dispatch(setSpecificParameter("lead_group", "All"));
    } else if (!leadGroupParamValue) {
      dispatch(setSpecificParameter("lead_group", "All"));
    }
  }, [leadGroupParamValue, leadsSources, marketParamValue]);

  //Validate channel param
  useEffect(() => {
    if (!channelParamValue || channelParamValue === "undefined") {
      dispatch(setSpecificParameter("channel", ALL_OPTION_NO_SPACE));
    } else if (channelParamValue && channels && channels.length > 0) {
      !channels.includes(channelParamValue) && dispatch(setSpecificParameter("channel", ALL_OPTION_NO_SPACE));
    }
  }, [channelParamValue, channels]);

  //Validate model param
  useEffect(() => {
    if (models && models.length > 0 && modelParamValue != ALL_OPTION_NO_SPACE) {
      const selectedModels = modelParamValue.split(",");
      !selectedModels.every((model: string) => models.includes(model)) && dispatch(setSpecificParameter("model", ALL_OPTION_NO_SPACE));
    }
  }, [modelParamValue, models, metric]);

  useEffect(() => {
    if (currencyParamValue) {
      //@ts-ignore
      dispatch(setJourneyExcellenceCurrency(currencyMapping[currencyParamValue]?.symbol));
    }
  }, [currencyParamValue]);

  // Set model filter value
  useEffect(() => {
    if (models && modelParamValue) {
      const selectedModelsArr = modelParamValue.split(",");
      const modelSourceValue =
        selectedModelsArr?.length === models.length
          ? "All"
          : selectedModelsArr?.length > 1
            ? `${selectedModelsArr?.length} model selected`
            : modelParamValue;

      setModelFilterValue(modelSourceValue);
    }
  }, [models, modelParamValue]);

  // Set leads source filter value
  useEffect(() => {
    const leadSourcesOptions = leadsSources?.flatMap((item: any) => item.leads_source);

    if (leadSourcesOptions && leadsSourceParamValue) {
      const selectedLeadsSourcesArr = leadsSourceParamValue.split(",");
      const leadsSourceSourceValue =
        selectedLeadsSourcesArr?.length === leadSourcesOptions.length
          ? "All"
          : selectedLeadsSourcesArr?.length > 1
            ? `${selectedLeadsSourcesArr?.length} leads sources selected`
            : leadsSourceParamValue;

      setLeadsSourceFilterValue(leadsSourceSourceValue);
    }
  }, [leadsSources, leadsSourceParamValue, marketParamValue, brandParamValue]);

  // Set market filter value
  useEffect(() => {
    if (markets && marketParamValue) {
      const selectedMarketsArr = marketParamValue.split(",");
      const marketSourceValue =
        selectedMarketsArr?.length === markets.length
          ? "All"
          : selectedMarketsArr?.length > 1
            ? `${selectedMarketsArr?.length} market selected`
            : marketParamValue;

      setMarketFilterValue(marketSourceValue);
    } else {
      setMarketFilterValue(ALL_OPTION_NO_SPACE);
    }
  }, [markets, marketParamValue]);

  const resetFilters = useCallback(() => {
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(DEFAULT_PARAMETER_VALUES)) {
      params.set(key, value);
    }

    history.push({ search: `?${params.toString()}` });
    window.location.reload();
  }, [history]);

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));
    }
    eventTracking(MixpanelEvents.filter_change, {
      filter: filterName,
      value: optionValue,
      dashboard: "Journey Excellence",
      page: "Journey Excellence",
    });
    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);
  };

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  const handleModelFilterSubmission = useCallback(() => {
    const parameterName = "model";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = selectedValues?.length === models?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, {
      filter: parameterName,
      value: filterValue,
      dashboard: "Journey Excellence",
    });
  }, [models]);

  const handleMarketFilterSubmission = useCallback(() => {
    const parameterName = "market";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = selectedValues?.join(",");
    closeFilterOptions();
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, {
      filter: parameterName,
      value: filterValue,
      dashboard: "Journey Excellence",
    });
  }, [markets]);

  const showDigitalLeadsFilter = useMemo(() => {
    if (metric != "Leads Management System") {
      return false;
    }
    return true;
  }, [marketParamValue, metric]);

  const digitalLeadsSourceOptions = useMemo(() => {
    if (showDigitalLeadsFilter && leadsSources) {
      const options: any = [{ value: "All", checked: false }];
      leadsSources.forEach((leadsSource: any) => {
        options.push({
          value: leadsSource.lead_group,
          checked: false,
          indeterminate: false,
          options: leadsSource.leads_source.map((item: string) => ({
            value: item,
            checked: false,
          })),
        });
      });
      return options;
    }
    return [];
  }, [leadsSources]);

  // Set params
  useEffect(() => {
    const params = new URLSearchParams();

    params.set("date_range", dateRangeParamValue);
    params.set("brand", brandParamValue);
    params.set("market", marketParamValue);
    if (metric != "Product Journey") {
      params.set("currency", currencyParamValue);
    } else {
      params.delete("currency");
    }
    if (metric == "Online Journey") {
      params.set("model", modelParamValue);
    } else {
      params.delete("model");
    }
    if (showDigitalLeadsFilter) {
      params.set("lead_group", leadGroupParamValue);
      params.set("leads_source", leadsSourceParamValue);
    } else {
      params.delete("lead_group");
      params.delete("leads_source");
    }
    // if (metric == "Product Journey") {
    //   params.set("channel", channelParamValue);
    // } else {
    //   params.delete("channel");
    // }
    history.push({ search: `?${params.toString()}` });
  }, [
    dateRangeParamValue,
    brandParamValue,
    marketParamValue,
    currencyParamValue,
    modelParamValue,
    metric,
    leadGroupParamValue,
    leadsSourceParamValue,
    channelParamValue,
    showDigitalLeadsFilter,
  ]);

  return (
    <FiltersLayout extraClass={"iframeFilters-midas"} resetFilters={resetFilters} filterBtns={filterBtns}>
      <div className="filte">
        {/*Date Range*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <PurchaseFunnelDateFilter
            value={dateRangeParamValue}
            onFilterClick={showFilterOptionsFn}
            handleFilterOptionClick={handleFilterOptionClick}
            presetDatesList={JOURNEY_EXCELLENCE_DATE_FILTER_OPTIONS}
          />
        </ErrorBoundary>

        {/*Brand*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={brands}
            filterName={"brand"}
            filterLabel={"BRAND"}
            filterValue={brandParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Market*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="market"
            filterList={markets ? markets : []}
            filterName="MARKET"
            value={marketFilterValue}
            parameterName={"market"}
            parameterValue={marketParamValue}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleMarketFilterSubmission}
          />
        </ErrorBoundary>

        <ErrorBoundary fallback={<ErrorMsg />}>
          {metric == "Online Journey" ? (
            <MultiSelectFilter
              parentKey="model"
              filterList={models ? models : []}
              filterName="MODEL"
              value={modelFilterValue}
              parameterName={"model"}
              parameterValue={modelParamValue}
              onShowFilterOptions={showFilterOptionsFn}
              handleFilterSubmission={handleModelFilterSubmission}
            />
          ) : null}
        </ErrorBoundary>

        {/*Currency*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          {metric != "Product Journey" ? (
            <DefaultFilter
              list={currencies}
              filterName={"currency"}
              filterLabel={"CURRENCY"}
              filterValue={currencyParamValue}
              handleFilterOptionClick={handleFilterOptionClick}
            />
          ) : null}
        </ErrorBoundary>

        {/* Lead Source */}
        <ErrorBoundary fallback={<ErrorMsg />}>
          {showDigitalLeadsFilter ? (
            <DigitalLeadsSourcesFilter
              parentKey="leads_source"
              filterList={digitalLeadsSourceOptions}
              filterName="DIGITAL LEADS SOURCE"
              value={leadsSourceFilterValue}
              parameterName={"leads_source"}
              parameterValue={leadsSourceParamValue}
              onShowFilterOptions={showFilterOptionsFn}
              dashboard="Journey Excellence"
            />
          ) : null}
        </ErrorBoundary>

        {/*Channel*/}
        {/* <ErrorBoundary fallback={<ErrorMsg />}>
          {metric == "Product Journey" ? (
            <DefaultFilter
              list={channels ? ["All", ...channels] : ["All"]}
              filterName={"channel"}
              filterLabel={"CHANNEL"}
              filterValue={channelParamValue}
              handleFilterOptionClick={handleFilterOptionClick}
            />
          ) : null}
        </ErrorBoundary> */}
      </div>
    </FiltersLayout>
  );
});

export default JourneyExcellenceDeepDiveFilters;
