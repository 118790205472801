import moment from "moment";
import { DIS_PRESET_DATE_PARAMS } from "../constants";
import { newApiFetchV2, newFetchDataV2 } from "./utils";

export const getParams = () => {
  const parameters = new URLSearchParams(window.location.search);

  const dateRangeParam = parameters.get("date_range");
  const brandParam = parameters.get("brand") == "All" ? [] : parameters.get("brand")?.split(",");
  const marketParam = parameters.get("market") == "All" ? [] : parameters.get("market")?.split(",");
  const leadsSourceParameter = parameters?.get("leads_source");
  const leadGroupParameter = parameters?.get("lead_group");

  const paramObj: any = {
    brand: brandParam,
    market: marketParam,
  };

  if (dateRangeParam) {
    if (DIS_PRESET_DATE_PARAMS.includes(dateRangeParam)) {
      paramObj["date_range"] = dateRangeParam.split(" ").join("_").toLocaleLowerCase();
    } else {
      paramObj["date_range"] = "custom";
      paramObj["start_date"] = moment(dateRangeParam.split("-")[0], "MMMM YYYY").format("YYYY-MM-DD");
      paramObj["end_date"] = moment(dateRangeParam.split("-")[1], "MMMM YYYY").endOf("month").format("YYYY-MM-DD");
    }
  }

  if (leadGroupParameter) {
    paramObj["lead_group"] = leadGroupParameter == "All" ? [] : leadGroupParameter.split(",");
  }

  if (leadsSourceParameter) {
    paramObj["leads_source"] = leadsSourceParameter == "All" ? [] : leadsSourceParameter.split(",");
  }

  return paramObj;
};

export const getExecutiveSummaryDisSummary = () => newApiFetchV2("/executive-summary/dis/dis-summary", getParams());
export const getExecutiveSummaryAggregatedPurchaseFunnel = () =>
  newApiFetchV2("/executive-summary/dis/aggregated-purchase-funnel", getParams());
export const getExecutiveSummaryAggregatedPurchaseFunnelTable = () =>
  newApiFetchV2("/executive-summary/dis/aggregated-purchase-funnel-table", getParams());
export const getExecutiveSummaryDetailedComparisons = () => newApiFetchV2("/executive-summary/dis/detailed-comparisons", getParams());
export const getExecutiveSummaryChannelMixPerformance = () => newApiFetchV2("/executive-summary/dis/channel-mix-performance", getParams());
export const getExecutiveSummaryAggregatedPrivateSalesByMarket = () =>
  newApiFetchV2("/executive-summary/dis/aggregated/private-sales-breakdown-by-market", getParams());
export const getExecutiveSummaryAggregatedPrivateSalesByDate = () =>
  newApiFetchV2("/executive-summary/dis/aggregated/private-sales-breakdown-by-date", getParams());
export const getExecutiveSummaryAggregatedDisPercentageByMarket = () =>
  newApiFetchV2("/executive-summary/dis/aggregated/dis-percentage-breakdown-by-market", getParams());
export const getExecutiveSummaryAggregatedDisPercentageByDate = () =>
  newApiFetchV2("/executive-summary/dis/aggregated/dis-percentage-breakdown-by-date", getParams());
export const getExecutiveSummaryAggregatedCloseRateByMarket = () =>
  newApiFetchV2("/executive-summary/dis/aggregated/close-rate-breakdown-by-market", getParams());
export const getExecutiveSummaryAggregatedCloseRateByDate = () =>
  newApiFetchV2("/executive-summary/dis/aggregated/close-rate-breakdown-by-date", getParams());
export const getExecutiveSummaryDataDefinitions = (page: string) => newFetchDataV2(`/executive-summary/${page}/data-dictionary`);
export const getExecutiveSummaryKeyIndicators = (metric: string, period: string) =>
  newApiFetchV2(`/executive-summary/dis/key-indicators?metric=${metric}&period=${period}`, getParams());
