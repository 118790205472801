import { ErrorBoundary } from "@sentry/react";
import React, { useEffect, useMemo, useState } from "react";
import { fetchDepositInsightsByCity_USA } from "../../../../api/Ariya";
import { ErrorMsg } from "../../../../components/AppMessages";
import { plusSignNumbers } from "../../../../utils/utilityFunctions";
import { AriyaCityTable } from "../../Components";
import { reduceCityMetrics } from "../../helpers/dataFunctions";
import styles from "./preorderByCity_Japan.module.scss";

type tableDataType = Array<{
  city: string;
  completedShare: number;
  cancelledShare: number;
  depositShare: number;
  completedTotal: number;
  cancelledTotal: number;
  depositTotal: number;
  completedTotal_previous: number;
  cancelledTotal_previous: number;
  depositTotal_previous: number;
}>;

export const PreordersByCity_USA = () => {
  const [hiddenColumns] = useState([""]);
  const [tableData, setTableData] = useState<tableDataType>([]);
  const [activeMetric, setActiveMetric] = useState("completed");

  // Preorder city breakdown
  useEffect(() => {
    fetchDepositInsightsByCity_USA().then((response) => {
      if (response && !("error" in response)) {
        const allCitiesData = response.report.data;

        const groupedPreOrdersData = reduceCityMetrics(allCitiesData);
        const lastSevenDayPreOrdersData = reduceCityMetrics(allCitiesData.slice(-7));
        const groupedValues = [...Object.values(groupedPreOrdersData)];

        const highestPreOrdersValues = {
          completed: Math.max(...groupedValues.map((value) => value.completed)),
          deposit: Math.max(...groupedValues.map((value) => value.deposit)),
          cancelled: Math.max(...groupedValues.map((value) => value.cancelled)),
        };

        const mergedPreOrdersData = Object.entries(groupedPreOrdersData)
          .map(([key, value]) => {
            if (lastSevenDayPreOrdersData[key]) {
              const completedShare = parseFloat(((value.completed / highestPreOrdersValues.completed) * 100).toFixed(2));
              const depositShare = parseFloat(((value.deposit / highestPreOrdersValues.deposit) * 100).toFixed(2));
              const cancelledShare = parseFloat(((value.cancelled / highestPreOrdersValues.cancelled) * 100).toFixed(2));

              const totals = groupedPreOrdersData[key];
              const lastSevenDays = lastSevenDayPreOrdersData[key];
              return {
                city: key,
                completedShare,
                cancelledShare,
                depositShare,
                completedTotal: totals.completed,
                cancelledTotal: totals.cancelled,
                depositTotal: totals.deposit,
                completedTotal_previous: lastSevenDays.completed,
                cancelledTotal_previous: lastSevenDays.cancelled,
                depositTotal_previous: lastSevenDays.deposit,
              };
            }
          })
          .filter((row) => !!row); // Filters out undefined
        setTableData(mergedPreOrdersData as tableDataType);
      }
    });
  }, []);

  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(
    () => [
      {
        id: "city",
        Header: "",
        accessor: "city",
        sortable: false,
      },
      {
        id: "completedShare",
        Header: "",
        accessor: `${activeMetric}Share`,
        sortable: false,
        Cell: function renderProgressBar(props1: { value: number }) {
          return (
            <div className="progress-bar">
              <span style={{ width: `${props1.value}%` }}>&nbsp;</span>
            </div>
          );
        },
      },
      {
        Header: `Total ${activeMetric === "completed" ? "online pre-orders" : "cancellations"}`,
        id: "completedTotal",
        accessor: `${activeMetric}Total`,
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: function renderVisitsType(props1: { value: number }) {
          const { value } = props1;

          return value.toLocaleString();
        },
      },
      {
        Header: "In the last 7 days",
        accessor: `${activeMetric}Total_previous`,
        id: "completedTotal_previous",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: function renderVisitsType(props1: { value: string }) {
          const { value } = props1;

          return plusSignNumbers(value);
        },
      },
    ],
    [activeMetric]
  );

  const handleFilterClick = (evt: React.MouseEvent<HTMLInputElement>) => {
    setActiveMetric(evt.currentTarget.dataset.value as string);
  };

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className={styles.filterContainer}>
        <div
          className={activeMetric === "completed" ? styles.filterItemActive : styles.filterItem}
          data-value="completed"
          onClick={handleFilterClick}
        >
          Pre-orders
        </div>

        <div
          className={activeMetric === "cancelled" ? styles.filterItemActive : styles.filterItem}
          data-value="cancelled"
          onClick={handleFilterClick}
        >
          Cancellations
        </div>
      </div>

      {data.length ? (
        <AriyaCityTable
          columns={columns}
          data={data}
          id={"preorder_city_table"}
          data-test-id={"preorder_city_table"}
          renderRowSubComponent={() => <></>}
          isChildComponent={false}
          initialSortColId={{ id: "completedTotal", desc: true }}
          hiddenCols={hiddenColumns}
          sortable={false}
        />
      ) : (
        <></>
      )}
    </ErrorBoundary>
  );
};
