import { Box, Button, Grid } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { setLastDataRefresh } from "../../../../actions";
import {
  fetchPreorderCancellationTrendData_Germany,
  fetchPreorderData_Germany,
  fetchPreorderDrilldownColors_Germany,
  fetchPreorderDrilldownVersions_Germany,
  fetchVisitsData,
} from "../../../../api/Ariya";
import { DrilldownLayout } from "../../../../components/Layouts/Ariya";
import { IChartData, IMetricTotal, popularState } from "../../../../constants/interface/ariya";
import { ThemeContext } from "../../../../context";
import { eventTracking, MixpanelEvents } from "../../../../utils/userTracking";
import commonStyles from "../../ariya.module.scss";
import { ExperienceCard } from "../../Experience Insight/components/Card";
import { ProgressTable } from "../../Experience Insight/components/Tables";
import { MajorCard } from "../../HandraisersInsights/Cards";
import { getGraphData } from "../../helpers/charts";
import { extractMonitorData, extractProgressTableDataFromData, mergeGraphData } from "../../helpers/dataFunctions";
import { MonitorCard } from "../../Monitor/components/Card";
import { MonitorTrendline } from "../../Monitor/components/Charts";
import { PreordersByCity_Germany } from "../Tables";
import japanStyles from "./japan.module.scss";
import norwayStyles from "./norway.module.scss";

export const PreorderInsights_Germany = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const themeContext = useContext(ThemeContext);

  const dispatch = useDispatch();
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.ariya_monitor);

  const [preorderTotals, setPreorderTotals] = useState<IMetricTotal>({ current: null, previous: null });
  const [cancellationsTotals, setCancellationsTotals] = useState<IMetricTotal>({ current: null, previous: null });
  const [preorderChartData, setPreorderChartData] = useState<Array<IChartData>>([]);
  const [cancellationsChartData, setCancellationsChartData] = useState<Array<IChartData>>([]);
  const [mergedChartData, setMergedChartData] = useState<Array<IChartData>>([]);
  const [topVersions, setTopVersions] = useState<popularState>({ records: [] });
  const [topColours, setTopColours] = useState<popularState>({ records: [] });

  const pageNavigation = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      const {
        currentTarget: { dataset },
      } = evt;
      const pageLink = dataset?.page;

      history.push({ pathname: pageLink, search: "" });
    },
    [history]
  );

  // Usage tracking
  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { page: "Ariya Preorder - Germany" });
  }, []);

  // Last data refresh
  useEffect(() => {
    fetchVisitsData().then((response) => {
      if (response && !("error" in response)) {
        const {
          chart_data: { year, month, day },
        } = extractMonitorData(response);
        dispatch(setLastDataRefresh("ariya_monitor", `${year}-${month}-${day}`));
      }
    });
  }, []);

  // Preorder
  useEffect(() => {
    Promise.all([
      fetchPreorderData_Germany().then((response) => {
        if (response && !("error" in response)) {
          const { data, totals } = response.report;
          const lastDay = data[data.length - 1].counts[0];
          setPreorderTotals({ current: +totals[0], previous: +lastDay });
          setPreorderChartData(getGraphData(data));
        }
      }),
      fetchPreorderCancellationTrendData_Germany().then((response) => {
        if (response && !("error" in response)) {
          const data = response.report.data;
          const lastDay = +data[data.length - 1].counts[0];
          const total = +response.report.totals[0];
          setCancellationsChartData(getGraphData(data));
          setCancellationsTotals({ current: total, previous: lastDay });
        }
      }),
    ]);
  }, []);

  // Merge chart data
  useEffect(() => {
    setMergedChartData(mergeGraphData(preorderChartData, cancellationsChartData));
  }, [preorderChartData, cancellationsChartData]);

  // Grades data
  useEffect(() => {
    fetchPreorderDrilldownVersions_Germany().then((response) => {
      if (response && !("error" in response)) {
        const data = response.report.data
          .filter((row) => !row.name.includes("No version selected"))
          .sort((a, b) => +b.counts[0] - +a.counts[0])
          .slice(0, 5);
        const total = Number(response.report.totals[0]);
        setTopVersions({ records: extractProgressTableDataFromData(data, total) });
      }
    });
  }, []);

  // Colours data
  useEffect(() => {
    fetchPreorderDrilldownColors_Germany().then((response) => {
      if (response && !("error" in response)) {
        const data = response.report.data.sort((a, b) => +b.counts[0] - +a.counts[0]);
        const total = Number(response.report.totals[0]);
        setTopColours({ records: extractProgressTableDataFromData(data, total) });
      }
    });
  }, []);

  return (
    <DrilldownLayout
      flagName={"DE"}
      title="Germany online pre-order insights"
      lastDataRefresh={lastDataRefresh}
      previousPagePath="/ariya/preorder"
    >
      <>
        <Box display={"grid"} gridGap={15} className={norwayStyles.cardContainer}>
          <MonitorCard
            metric={{
              title: "Online pre-orders",
              lastDayValue: preorderTotals.previous,
              value: preorderTotals.current,
              legend: "solid",
            }}
            metric2={{
              title: "Cancellations",
              lastDayValue: cancellationsTotals.previous,
              value: cancellationsTotals.current,
              legend: "dotted",
            }}
            chart={
              <MonitorTrendline
                data={mergedChartData}
                chartId={"preorder_de"}
                series={["preorder", "cancellation"]}
                theme={themeContext.theme}
              />
            }
            extraClass={norwayStyles.chartCard}
            hideMore={true}
            spanBy={0}
          />

          <MajorCard
            id="version_colours_insights"
            content1={<ProgressTable id="top_versions" title="Top grades" data={topVersions} />}
            content2={<ProgressTable id="top_colours" title="Top body colours" data={topColours} resetStyles={false} />}
            className={norwayStyles.tablesCard}
          />

          <ExperienceCard title="Insights by city" id="handraisers_city" className={japanStyles.major2}>
            <PreordersByCity_Germany />
          </ExperienceCard>
        </Box>

        <Grid container spacing={10} justifyContent="space-evenly">
          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page={"/ariya/monitor"}
              data-test-id="ariyaHome"
            >
              Ariya home
            </Button>
          </Grid>

          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page={"/ariya/experience"}
              data-test-id="ariyaExperience"
            >
              Experience insights
            </Button>
          </Grid>

          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page={"/ariya/handraisers"}
              data-test-id="ariyaHandraisers"
            >
              Hand-raisers insights
            </Button>
          </Grid>
        </Grid>
      </>
    </DrilldownLayout>
  );
});
