import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ErrorBoundary } from "@sentry/react";
import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { ErrorMsg } from "../../../../components/AppMessages";
import Collapsible from "../../../../components/Collapsible/Collapsible";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { DefaultTable } from "../../../../components/Tables/subs";

const CompletionRateTable = () => {
  const { date_range: dateRangeParam } = useSelector((state: RootStateOrAny) => state.parameters);
  const { Current: currentCompletionRate, YoY: yoyCompletionRate } = useSelector(
    (state: RootStateOrAny) => state.voc.rating_per_touchpoint.completion_rate
  );
  const { rating_per_touchpoint_completion_rate: loading } = useSelector((state: RootStateOrAny) => state.loading);

  const columns = useMemo(
    () => [
      {
        id: "touchpoints",
        Header: "Touchpoints",
        accessor: "touchpoint",
        enableRowSpan: true,
        sort: false,
        disableSortBy: true,
      },
      {
        id: "metric",
        Header: "Metric",
        accessor: "metric",
        sort: false,
        disableSortBy: true,
      },
      {
        id: "value",
        Header: dateRangeParam,
        accessor: "value",
        sort: false,
        disableSortBy: true,
        Cell: (cell: { value: number; row: any }) => {
          const { value } = cell;
          const metric = cell.row.original.metric;
          return (
            <div className={metric == "Percentage of 'yes'" ? `${value >= 80 ? "textGreen" : value >= 70 ? "textAmber" : "textRed"}` : ""}>
              {value ? `${value.toLocaleString()}${metric == "Percentage of 'yes'" ? "%" : ""}` : value != 0 ? value : ""}
            </div>
          );
        },
      },
      {
        id: "significant",
        Header: () => {
          return (
            <div style={{ display: "flex" }}>
              <ArrowDownwardIcon htmlColor="#A9A9A9" className="rtp-significance-arrow" />
              <Tooltip
                position="right"
                className={"normal_tippy"}
                trigger="mouseenter"
                animation="none"
                interactive={true}
                delay={1}
                hideDelay={1}
                duration={1}
                title={
                  "Watch out for low statistical significance. A 'down arrow' signifies a low volume of respondents (lower than 16 per month) (lower than 16 per month)"
                }
                size={"small"}
              >
                <span className="info_tooltip" />
              </Tooltip>
            </div>
          );
        },
        accessor: "significant",
        sort: false,
        disableSortBy: true,
        Cell: (cell: any) => {
          const showIcon = cell.row.values.significant;
          return !showIcon ? <ArrowDownwardIcon htmlColor="#A9A9A9" className="rtp-significance-arrow" /> : null;
        },
      },
      {
        id: "ytd",
        Header: "YTD",
        accessor: "yoy_value",
        sort: false,
        disableSortBy: true,
        Cell: (cell: { value: number; row: any }) => {
          const { value } = cell;
          const metric = cell.row.original.metric;
          return <div>{value ? `${value.toLocaleString()}${metric == "Percentage of 'yes'" ? " PTS" : ""}` : value != 0 ? value : ""}</div>;
        },
      },
      {
        id: "yoy_significant",
        Header: () => {
          return (
            <div style={{ display: "flex" }}>
              <ArrowDownwardIcon htmlColor="#A9A9A9" className="rtp-significance-arrow" />
              <Tooltip
                position="right"
                className={"normal_tippy"}
                trigger="mouseenter"
                animation="none"
                interactive={true}
                delay={1}
                hideDelay={1}
                duration={1}
                title={
                  "Watch out for low statistical significance. A 'down arrow' signifies a low volume of respondents (lower than 16 per month) (lower than 16 per month)"
                }
                size={"small"}
              >
                <span className="info_tooltip" />
              </Tooltip>
            </div>
          );
        },
        accessor: "yoy_significant",
        sort: false,
        disableSortBy: true,
        Cell: (cell: any) => {
          const showIcon = cell.row.values.yoy_significant;
          return !showIcon ? <ArrowDownwardIcon htmlColor="#A9A9A9" className="rtp-significance-arrow" /> : null;
        },
      },
    ],
    [currentCompletionRate]
  );

  const tableData = useMemo(() => {
    const data: any = [];
    if (currentCompletionRate && yoyCompletionRate) {
      currentCompletionRate.forEach((item: any) => {
        const yoyEquivalent = yoyCompletionRate.find((yoyItem: any) => yoyItem.touchpoint == item.touchpoint);
        data.push(
          {
            touchpoint: item.touchpoint,
            metric: "Percentage of 'yes'",
            value: item.rate,
            significant: true,
            yoy_value: yoyEquivalent?.pts_rate,
            yoy_significant: true,
          },
          {
            touchpoint: item.touchpoint,
            metric: "Total # answers",
            value: item.answers,
            significant: item.significant,
            yoy_value: yoyEquivalent?.pts_answers,
            yoy_significant: yoyEquivalent?.significant,
          }
        );
      });
    }
    return data;
  }, [currentCompletionRate, yoyCompletionRate]);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      {tableData && tableData.length > 0 && (
        <div className="row grid">
          <div className="grid-col-span-6 min-h-15">
            <Collapsible title="Exit Survey" show={false}>
              <div className="tile">
                {loading ? (
                  <LoadingEllipsis isLoading={loading} />
                ) : (
                  <DefaultTable
                    columns={columns}
                    data={tableData}
                    id={"CompletionRateTable"}
                    isChildComponent={false}
                    initialSortColId={{ id: "touchpoints", desc: false }}
                    hiddenCols={[]}
                    manualSortBy={true}
                  />
                )}
              </div>
            </Collapsible>
          </div>
        </div>
      )}
    </ErrorBoundary>
  );
};

export default CompletionRateTable;
