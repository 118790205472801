import { IAriyaMonitorData, IAriyaMonitorDataReportFormat } from "../../constants/interface/ariya";
import { postData } from "../utils";
import { apiPrefix, apiPrefix_Dp } from "./constants";

export const fetchVisitsData = (): Promise<IAriyaMonitorData> => postData(`${apiPrefix_Dp}/ariya_visits`, {});
export const fetchHandraisersData = (): Promise<IAriyaMonitorData> => postData(`${apiPrefix_Dp}/ariya_hand_raisers`, {});
export const fetchPreOrderData = (): Promise<IAriyaMonitorData> =>
  postData(`${apiPrefix_Dp}/ariya_pre_order`, { market: ["Japan", "Norway"] });
export const fetchPreOrderDepositsData = (): Promise<IAriyaMonitorDataReportFormat> =>
  postData(`${apiPrefix}/ariya_online_deposits_total`, {});
export const fetchPreOrderDepositsChartData = (): Promise<IAriyaMonitorDataReportFormat> =>
  postData(`${apiPrefix}/ariya_online_deposits_total_last_4_weeks`, {});
