import { CircleBullet, DateAxis, ILineSeriesDataFields, LineSeries, ValueAxis, XYChart, XYCursor } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { disposeChart } from "../../../../../components/Charts/helpers/chartHelpers";
import { configureDateAxis, configureValueAxis } from "../../../../../components/Charts/helpers/configurations";
import { ThemeInt } from "../../../../../components/Charts/helpers/types";
import { ACCENT_BROWN, WHITE } from "../../../../../constants";
import { ariyaChartConfiguration } from "../../../helpers/charts";

interface Props {
  chartId: string;
  data: Array<any>;
  series: Array<string>;
  theme: ThemeInt;
}

export class MonitorTrendline extends Component<Props> {
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.props.chartId);
      this.initChart();
    }
  }

  componentWillUnmount() {
    disposeChart(this.props.chartId);
  }

  initChart() {
    const { data, chartId, theme } = this.props;

    this.chart = create(chartId, XYChart);
    ariyaChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#a";

    // Chart cursor
    this.chart.cursor = new XYCursor();
    this.chart.cursor.lineX.disabled = true;
    this.chart.cursor.lineY.disabled = true;

    //date format
    // this.chart.dateFormatter.inputDateFormat = "dd MMM";

    // Create Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.minGridDistance = 150;
    dateAxis.renderer.minLabelPosition = 0.01;
    dateAxis.endLocation = 0.8;
    if (dateAxis.tooltip) dateAxis.tooltip.disabled = true;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.numberFormatter.numberFormat = "#,###";
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.grid.template.disabled = true;
    if (valueAxis.tooltip) valueAxis.tooltip.disabled = true;
    valueAxis.renderer.baseGrid.disabled = true;

    // Creating series
    this.createSeries(this.props.series);

    this.chart.data.sort(function (a, b) {
      //@ts-ignore
      return new Date(a.label) - new Date(b.label);
    });
  }

  createSeries(SeriesList: string[]) {
    SeriesList?.forEach((metric, idx) => {
      const series = this.chart.series.push(new LineSeries());
      series.name = metric;
      series.stroke = color(ACCENT_BROWN);
      series.strokeWidth = 3;
      if (idx === 1) {
        series.strokeDasharray = "2, 2";
        series.toBack();
      }

      if (series.tooltip) {
        series.tooltip.getFillFromObject = false;
        series.tooltip.autoTextColor = false;
        series.tooltip.background.fill = color(ACCENT_BROWN);
        series.tooltip.label.fill = color(WHITE);
        series.tooltip.defaultState.transitionDuration = 0;
        series.tooltip.hiddenState.transitionDuration = 0;
        series.tooltipText =
          "[font-size: var(--regular_font_size);] {dateX.formatDate('dd MMM')} [/font-size: var(--regular_font_size);bold]: {valueY.formatNumber('#,###.')}";
      }

      const bullet: CircleBullet = series.bullets.push(new CircleBullet());
      bullet.circle.stroke = color(ACCENT_BROWN);
      bullet.circle.fill = color(ACCENT_BROWN);
      bullet.circle.propertyFields.radius = "bulletRadius";

      bullet.tooltipText =
        "[font-size: var(--regular_font_size);] {dateX.formatDate('dd MMM')} [/font-size: var(--regular_font_size);bold]: {valueY.formatNumber('#,###.')}";

      series.dataFields = {
        ...series.dataFields,
        dateX: "label",
        valueY: idx === 1 ? "value2" : "value",
      } as ILineSeriesDataFields;
    });
  }
  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartId} className={"graph"} data-test-id={this.props.chartId} />
      </div>
    );
  }
}
