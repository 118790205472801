import React from "react";
import "../../../assets/styles/docs/modelData.scss";

export const ModelData = (): JSX.Element => (
  <div className="data_documentation">
    <h3>Assigning a nameplate to a visit</h3>

    <p>
      As customers explore and interact with Nissan websites, Adobe Analytics collects data about their behaviour. One of the pieces of data
      collected by Adobe Analytics is the Model Code, such as 28953 or J11B.
    </p>
    <br />

    <p>
      Each market has its own Model Code nomenclature, for instance in Japan the Model Code for Kicks is PU_2 and in Thailand it is 29062.
      Each market also has its own Model Name, for instance in Japan it would be キックス and in Thailand it would be คิกส์ อี-พาวเวอร์.
    </p>
    <br />

    <p>
      In order to display meaningful information that can be understood by all Nissan stakeholders, the CEDAR team maintains a
      classification in each market. A classification is a mapping table between all the different values Adobe Analytics receives, and a
      friendly name CEDAR displays in the dashboard. For example, with the above values, we have a classification such as:
    </p>

    <table className="model_info_table">
      <thead>
        <tr>
          <th>Values collected in Adobe Analytics</th>
          <th>Values displayed in CEDAR Home</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>PU_2</td>
          <td>Kicks</td>
        </tr>
        <tr>
          <td>29062</td>
          <td>Kicks</td>
        </tr>
        <tr>
          <td>キックス</td>
          <td>Kicks</td>
        </tr>
        <tr>
          <td>คิกส์ อี-พาวเวอร์</td>
          <td>Kicks</td>
        </tr>
      </tbody>
    </table>
    <br />

    <p>
      Some nameplates share the same name in all markets (the Kicks is always called Kicks); but some nameplates have different names based
      on their markets or the year they were released. This is the case for the Qashqai in Europe, which is called Rogue Sport in Nissan
      USA, or for the Navara in South Africa, which is called Frontier in Brazil.
    </p>
    <br />

    <p>
      To accommodate for those different names, CEDAR is maintaining two types of classification: a classification with the local market
      names and a classification with the generic, global names. Those classifications are available in Adobe Analytics as well; you can
      search for (c) Nameplate or (c) Nameplate (Global) to see how each value collected in Adobe Analytics is mapped to a nameplate.
    </p>

    <table className="model_info_table">
      <thead>
        <tr>
          <th>Values collected in Adobe Analytics</th>
          <th>
            Values displayed in CEDAR Home, at a market level
            <br />
            In Adobe Analytics: (c) Nameplate
          </th>
          <th>
            Values displayed in CEDAR Home, at a regional or global level
            <br />
            In Adobe Analytics: (c) Nameplate (Global)
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>PU_2</td>
          <td>Kicks</td>
          <td>Kicks</td>
        </tr>
        <tr>
          <td>29062</td>
          <td>Kicks</td>
          <td>Kicks</td>
        </tr>
        <tr>
          <td>キックス</td>
          <td>Kicks</td>
          <td>Kicks</td>
        </tr>
        <tr>
          <td>คิกส์ อี-พาวเวอร์</td>
          <td>Kicks</td>
          <td>Kicks</td>
        </tr>
        <tr>
          <td>D23</td>
          <td>
            in Brazil: Frontier
            <br />
            in South Africa: Navara
          </td>
          <td>Navara/Frontier/NP300</td>
        </tr>
        <tr>
          <td>J11B</td>
          <td>
            in Europe: Qashqai
            <br />
            in USA: Rogue Sport
          </td>
          <td>Qashqai/Rogue Sport</td>
        </tr>
      </tbody>
    </table>
    <br />

    <p>
      If you notice an inconsistency or a value that was misclassified in your market, please inform the CEDAR team so we can rectify the
      classification in Adobe Analytics. The classification can be applied retro-actively, which means that we can correct nameplates
      mapping in the past too.
    </p>
  </div>
);
