import moment from "moment";
import {
  IAriyaMonitorCountData,
  IAriyaMonitorData,
  IChartData,
  IMetricTotal,
  IPopularData,
  IPopularRow,
  IPreorderCityData,
  IRegionSplitState,
} from "../../../constants/interface/ariya";

export const extractMonitorData = (response: IAriyaMonitorData) => {
  const { chart_data, count_data } = response;
  const { day, month, year } = chart_data.data[chart_data.data.length - 1];
  const current = count_data.totals[0];
  const previous = chart_data.data[chart_data.data.length - 1].counts[0];

  return { chart_data: { data: chart_data.data, day, month, year }, totals: { current, previous } };
};

export const extractRegionSplitData = (data: IAriyaMonitorCountData, total: IMetricTotal) => {
  const initialState = { current: null, percentage: null };
  const result: Record<"japan" | "nae" | "usa" | "other" | "norway", IRegionSplitState> = {
    japan: initialState,
    nae: initialState,
    usa: initialState,
    other: initialState,
    norway: initialState,
  };

  if (data?.data) {
    const jpnPresent = data.data.find((row) => row.name === "Japan");
    const norwayPresent = data.data.find((row) => row.name === "Norway");
    const naePresent = data.data.find((row) => row.name === "AMIEO");
    const usaPresent = data.data.find((row) => row.name === "United States");
    const otherPresent = data.data.find((row) => row.name === "Other");

    result["japan"] = {
      current: jpnPresent ? jpnPresent.counts[0] : null,
      percentage: total.current && jpnPresent ? jpnPresent.counts[0] / (total.current as number) : null,
    };

    result["norway"] = {
      current: norwayPresent ? norwayPresent.counts[0] : null,
      percentage: total.current && norwayPresent ? norwayPresent.counts[0] / (total.current as number) : null,
    };

    result["nae"] = {
      current: naePresent ? naePresent.counts[0] : null,
      percentage: total && naePresent ? naePresent.counts[0] / (total.current as number) : null,
    };
    result["usa"] = {
      current: usaPresent ? usaPresent.counts[0] : null,
      percentage: total && usaPresent ? usaPresent.counts[0] / (total.current as number) : null,
    };
    result["other"] = {
      current: otherPresent ? otherPresent.counts[0] : null,
      percentage: total && otherPresent ? otherPresent.counts[0] / (total.current as number) : null,
    };
  }

  return result;
};

export const extractProgressTableDataFromResponse = (response: IPopularData) => {
  const total = +response.report.totals[0];
  const rows = response.report.data
    .sort((a, b) => +b.counts[0] - +a.counts[0])
    .map((row) => ({ name: row.name, percentage: Math.round((+row.counts[0] / total) * 100) }))
    .filter((row) => row.percentage);

  return rows;
};

export const extractProgressTableDataFromData = (data: Array<IPopularRow>, total: number) => {
  const rows = data
    .map((row) => ({ name: row.name, percentage: Math.round((+row.counts[0] / total) * 100) }))
    .filter((row) => row.percentage);

  return rows;
};

export const reduceCityMetrics = (data: IPreorderCityData, isUK = false) => {
  // @ts-ignore
  const reduceFunction = (result: Record<string, { completed: number; cancelled: number; deposit: number }>, row) => {
    const { name, counts, breakdown } = row;
    const formattedName = name?.split(" (")[0];
    if (breakdown) {
      result = { ...result, ...breakdown.reduce(reduceFunction, result) };
    } else {
      const completedCounts = isUK ? +counts[1] : +counts[0];
      const cancelledCounts = isUK ? +counts[0] : +counts[1];
      if (!(formattedName in result)) {
        result[formattedName] = {
          completed: completedCounts,
          cancelled: cancelledCounts,
          deposit: +counts[2],
        };
      } else {
        result[formattedName] = {
          completed: result[formattedName].completed + completedCounts,
          cancelled: result[formattedName].cancelled + cancelledCounts,
          deposit: result[formattedName].deposit + +counts[2],
        };
      }
    }
    return result;
  };

  return data.reduce(reduceFunction, {});
};

export const mergeGraphData = (data1: Array<IChartData>, data2: Array<IChartData>) => {
  const result: Array<IChartData> = [];

  if (data1.length && data2.length) {
    let followerIdx = 0;

    const anchor: Array<IChartData> = [...data1];
    const follower: Array<IChartData> = [...data2.map((row) => ({ ...row, value2: row.value }))];

    const anchorIsAfter = moment(anchor[0].label, "DD MMM").isAfter(moment(follower[0].label, "DD MMM"));

    // if (anchorIsAfter) {
    //   const temp = anchor;
    //   anchor = follower;
    //   follower = temp;
    // }

    follower.forEach((element, index) => {
      if (anchor[0].label == element.label) {
        followerIdx = index;
      }
    });

    for (let i = 0; i <= anchor.length - 1; i++) {
      const anchorRow = anchor[i];
      const followerRow = follower[followerIdx];

      if (anchorRow?.label === followerRow?.label) {
        result.push(anchorIsAfter ? { ...anchorRow, ...followerRow } : { ...followerRow, ...anchorRow });
        followerIdx += 1;
      } else if (anchor) {
        result.push(anchorIsAfter ? { ...anchorRow, value: null } : { ...anchorRow, value2: null });
      } else {
        result.push(anchorIsAfter ? { ...followerRow, value2: null } : { ...followerRow, value: null });
      }
    }
  }

  return result;
};

//@ts-ignore
export const sortChartData = (arr: Array<IChartData>) => arr.sort((a, b) => new Date(a.label) - new Date(b.label));

export const sumObjectsByKey = (objs: Array<IChartData>) =>
  Object.values(
    objs.reduce((result: any, row: any) => {
      result[row.label] = result[row.label] || { label: row.label };

      for (const k in row) {
        if (k === "value") {
          result[row.label][k] = result[row.label][k] ? result[row.label][k] + row[k] : row[k];
        } else {
          result[row.label][k] = row[k];
        }
      }

      return result;
    }, {})
  );
