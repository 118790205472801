import { ErrorBoundary } from "@sentry/react";
import React, { useMemo, useState } from "react";
import { ErrorMsg } from "../../../../components/AppMessages";
import { isNull } from "../../../../utils/utilityFunctions";
import { AriyaDefaultTable } from "../../Components";
import styles from "./totalHandraisers.module.scss";

interface Props {
  total: number | null;
  data: Array<{ market: string; total: number; share: number }>;
}

export const TotalHandRaisers = (props: Props) => {
  const [hiddenColumns] = useState([""]);

  const data = useMemo(() => props.data, [props.data]);
  const columns = useMemo(
    () => [
      {
        id: "market",
        Header: "Market",
        accessor: "market",
        defaultCanSort: true,
        sortDescFirst: true,
        sortType: "alphanumericIgnoreCase",
      },
      {
        id: "handraisers",
        Header: "Hand-raisers",
        accessor: "total",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: function renderVisitsType(props1: { value: number }) {
          const { value } = props1;

          return value.toLocaleString();
        },
      },
      {
        Header: "Conversion rate",
        accessor: `share`,
        id: "share",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: function renderVisitsType(props1: { value: string }) {
          const { value } = props1;

          return `${value}%`;
        },
      },
    ],
    []
  );

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <p className={styles.totalHandraisersValue}>{isNull(props.total) || props.total === 0 ? "-" : props.total?.toLocaleString()}</p>

      {data.length ? (
        <AriyaDefaultTable
          columns={columns}
          data={data}
          id={"total_handraiser_table"}
          data-test-id={"total_handraiser_table"}
          // @ts-ignore
          renderRowSubComponent={() => {}}
          isChildComponent={false}
          initialSortColId={{ id: "handraisers", desc: true }}
          hiddenCols={hiddenColumns}
          sortable={false}
        />
      ) : (
        <></>
      )}
    </ErrorBoundary>
  );
};
