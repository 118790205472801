import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  fetchBrandImageAttributesAverage,
  fetchBrandImageAttributesTrend,
  fetchBrandOaOAverage,
  fetchBrandOaOTrend,
  fetchBrandPerformanceImageAttributeFilters,
  fetchBrandPerformanceLastDataRefresh,
  fetchBrandPerformanceOaOFilters,
  fetchBrandPerformanceVolumeFilters,
  fetchBrandVolumeAverage,
  fetchBrandVolumeTrend,
  setPageName,
  setParameters,
  setSpecificParameter,
} from "../../actions";
import { brandPerformanceApiParamsToObject } from "../../api/brandPerformanceApi";
import "../../assets/styles/pages/brandPerformanceDashboard.scss";
import UsedCarsGranularityOptions from "../../components/Charts/components/UsedCarsGranularityOptions";
import { getGranularitiesToEnable } from "../../components/Charts/helpers/helpers";
import BrandPerformanceFilters from "../../components/Filters/BrandPerformanceDashboard/BrandPerformanceFilters";
import { DashboardLayout } from "../../components/Layouts";
import { DefaultPageContent } from "../../components/PageContent";
import { PageTitle } from "../../components/PageContent/subs";
import { DEFAULT_BRAND_PERFORMANCE_PARAMETERS, performancePages } from "../../constants";
import BrandImageAttributes from "./components/BrandImageAttributes/BrandImageAttributes";
import BrandOaO from "./components/BrandOaO/BrandOaO";
import BrandVolume from "./components/BrandVolume/BrandVolume";
import PerfNavButtons from "./components/PerfNavButtons/PerfNavButtons";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";

const BrandPerformanceDashboard = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const {
    date_range,
    granularity,
    nissan_competitor: nissanCompetitorParam,
    brand,
    competitor,
    category,
    sub_category,
    model,
    market,
    attribute,
    date_range_brand_perf,
    performance_page: performancePageParam,
  } = useSelector((state: RootStateOrAny) => state.parameters);

  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.brand_performance_last_data_refresh);
  const [nissan_competitor, setNissanCompetitor] = useState<string>("nissan_competitor");
  const [performancePage, setPerformancePage] = useState<performancePages>("volume");

  useEffect(() => {
    dispatch(setParameters(DEFAULT_BRAND_PERFORMANCE_PARAMETERS));
  }, []);

  const [chartGranularity, setChartGranularity] = useState(granularity);
  const [granularitiesList, setGranularityList] = useState(["monthly"]);
  const [diff, setDiff] = useState(0);

  useEffect(() => {
    let list: string[] = [];

    if (date_range?.includes("-") && performancePage == "volume") {
      const diff = moment(date_range?.split("-")[1], "MMM YYYY")
        .endOf("month")
        .diff(moment(date_range?.split("-")[0], "MMM YYYY").startOf("month"), "days");
      setDiff(diff);
      const toEnable = getGranularitiesToEnable(diff, "brand-performance");
      list = toEnable;
    } else if (performancePage == "oao" || performancePage == "image-attributes") {
      const param = brandPerformanceApiParamsToObject(window.location.search);
      //@ts-ignore
      const diff = moment(param?.end_date, "YYYY-MM-DD").diff(moment(param?.start_date, "YYYY-MM-DD"), "days");
      console.log(diff);
      const toEnable = getGranularitiesToEnable(diff, "brand-performance");
      list = toEnable?.filter((val) => val !== "monthly");
      list = list?.length == 0 ? ["quarterly"] : list;
      setDiff(diff);
    } else {
      date_range?.includes("month")
        ? (list = ["monthly"])
        : date_range?.includes("quarter")
          ? (list = ["monthly"])
          : (list = ["monthly", "quarterly", "yearly"]);
    }

    setGranularityList(list);
  }, [date_range, performancePageParam, date_range_brand_perf, performancePage]);

  const handleGranularityRadioChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = evt;

      setChartGranularity(value);
    },
    [setChartGranularity]
  );

  const handleRadioChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = evt;

      setNissanCompetitor(value);
      dispatch(setSpecificParameter("nissan_competitor", value));
    },
    [nissanCompetitorParam, setNissanCompetitor]
  );

  useEffect(() => {
    Promise.all([
      // Sets page name
      dispatch(setPageName("Brand Performance")),
    ]);
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchBrandPerformanceLastDataRefresh(performancePage));
    eventTracking(MixpanelEvents.page_view, { dashboard: "Brand Performance", page: performancePage });
  }, [performancePageParam, performancePage]);

  useEffect(() => {
    setSpecificParameter("performance_page", performancePage);
  }, [performancePage]);

  useEffect(() => {
    if (performancePage == "volume") {
      Promise.all([dispatch(fetchBrandPerformanceVolumeFilters())]);
    } else if (performancePage == "oao") {
      Promise.all([dispatch(fetchBrandPerformanceOaOFilters())]);
    } else {
      Promise.all([dispatch(dispatch(fetchBrandPerformanceImageAttributeFilters()))]);
    }
  }, [
    performancePageParam,
    nissanCompetitorParam,
    brand,
    competitor,
    category,
    sub_category,
    model,
    market,
    attribute,
    date_range_brand_perf,
    granularity,
    date_range,
    performancePage,
  ]);

  useEffect(() => {
    if (performancePage == "volume") {
      Promise.all([dispatch(fetchBrandVolumeTrend()), dispatch(fetchBrandVolumeAverage())]);
    }
    if (performancePage == "oao") {
      Promise.all([dispatch(fetchBrandOaOTrend()), dispatch(fetchBrandOaOAverage())]);
    }
    if (performancePage == "image-attributes") {
      Promise.all([dispatch(fetchBrandImageAttributesTrend()), dispatch(fetchBrandImageAttributesAverage())]);
    }
  }, [
    performancePageParam,
    nissanCompetitorParam,
    brand,
    competitor,
    category,
    sub_category,
    model,
    market,
    attribute,
    date_range_brand_perf,
    granularity,
    date_range,
    performancePage,
  ]);

  useEffect(() => {
    dispatch(setSpecificParameter("granularity", chartGranularity));
  }, [chartGranularity]);

  useEffect(() => {
    if (!granularitiesList?.includes(granularity)) dispatch(setSpecificParameter("granularity", granularitiesList[0]));
  }, [granularitiesList, granularity, date_range, chartGranularity]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={
          <BrandPerformanceFilters
            granularityOptions={
              <UsedCarsGranularityOptions
                onHandleRadioClick={handleGranularityRadioChange}
                granularity={granularity}
                name={"brand_perf_granularities"}
                dateValue={date_range}
                granularitiesList={granularitiesList}
              />
            }
            activePage={performancePage}
            lastRefreshDate={lastDataRefresh}
          />
        }
        dataDocumentation="brand_performance"
        lastDataRefresh={lastDataRefresh}
        pageTitle={<PageTitle dataDocumentation="brand_performance" />}
      >
        <div className="brand_performance_sotu">
          <div className="perf_dashboard">
            <PerfNavButtons setPerformancePage={setPerformancePage} activePage={performancePage} />
            <div className="perf_pages">
              {performancePage == "volume" ? (
                <BrandVolume
                  diff={diff}
                  activePage={performancePage}
                  onHandleRadioClick={handleRadioChange}
                  nissanCompetitor={nissan_competitor}
                />
              ) : performancePage == "oao" ? (
                <BrandOaO
                  diff={diff}
                  activePage={performancePage}
                  onHandleRadioClick={handleRadioChange}
                  nissanCompetitor={nissan_competitor}
                />
              ) : (
                <BrandImageAttributes
                  diff={diff}
                  activePage={performancePage}
                  onHandleRadioClick={handleRadioChange}
                  nissanCompetitor={nissan_competitor}
                />
              )}
            </div>
          </div>
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default BrandPerformanceDashboard;
