import React from "react";

const EcommerceData = () => {
  return (
    <div className="data_documentation">
      <h3>ECOMMERCE - DATA OVERVIEW</h3>
      <h4>Ecommerce KPI definitions</h4>
      <br />

      <table className="docs_table">
        <thead>
          <tr>
            <td>Section</td>
            <td>KPI</td>
            <td>Definition</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Visits drop-off within the ecommerce funnel</td>
            <td>Online Order CVR</td>
            <td>Online order conversion rate based on the number of ecommerce qualified visits</td>
          </tr>
          <tr>
            <td>Visits drop-off within the ecommerce funnel</td>
            <td>Website</td>
            <td>The total number of sessions across all visitors on the website</td>
          </tr>
          <tr>
            <td>Visits drop-off within the ecommerce funnel</td>
            <td>Ecommerce Qualified Visits</td>
            <td>The total number of ecommerce qualified visits on the website</td>
          </tr>
          <tr>
            <td>Visits drop-off within the ecommerce funnel</td>
            <td>Checkout</td>
            <td>The total number of checkout on the website</td>
          </tr>
          <tr>
            <td>Visits drop-off within the ecommerce funnel</td>
            <td>Product Detail Pages</td>
            <td>The total number of product detail pages on the website</td>
          </tr>
          <tr>
            <td>Visits drop-off within the ecommerce funnel</td>
            <td>Online Order</td>
            <td>The total number of online order pages on the website</td>
          </tr>
          <tr>
            <td>Visits drop-off within the ecommerce funnel</td>
            <td>Cancellation</td>
            <td>The total number of cacnellation pages on the website</td>
          </tr>
          <tr>
            <td>Influence of shopping tools</td>
            <td>Ecommerce Trade-in page visits</td>
            <td>The total number of Ecommerce Trade-in page visits pages on the website</td>
          </tr>
          <tr>
            <td>Influence of shopping tools</td>
            <td>Ecommerce Trade-in page purchase conversion rate</td>
            <td>Number of online order over number of qualified visits for Ecommerce Trade-in page</td>
          </tr>
          <tr>
            <td>Influence of shopping tools</td>
            <td>Ecommerce Finance calculator visits</td>
            <td>The total number of Ecommerce Finance calculator visits on the website</td>
          </tr>
          <tr>
            <td>Influence of shopping tools</td>
            <td>Ecommerce Finance calculator purchase conversion rate</td>
            <td>Number of online order over number of qualified visits for Finance calculator</td>
          </tr>
          <tr>
            <td>Ecommerce orders by region</td>
            <td>Online order</td>
            <td>The total number of online order broken down per region</td>
          </tr>
          <tr>
            <td>Ecommerce orders by nameplate</td>
            <td>Online order</td>
            <td>The total number of online order broken down per nameplate</td>
          </tr>
          <tr>
            <td>TD/S Leads</td>
            <td>Online order</td>
            <td>The total number of online order pages on the website</td>
          </tr>
          <tr>
            <td>Non-TD/S Leads</td>
            <td>Contact dealers</td>
            <td>The total number of contact dealers on the website</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default EcommerceData;
