import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import SearchInterestVsFMIChart from "../../../components/Charts/ProductMarketingOptimization/SearchInterestVsFMIChart";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { ToggleSwitch } from "../../../components/Toogle";
import { ThemeContext } from "../../../context";

const SearchInterestVsFMI = () => {
  const ref = useRef<any>(null);
  const themeContext = useContext(ThemeContext);
  const loading: boolean = useSelector((state: RootStateOrAny) => state.loading.search_interest_vs_fmi);
  const data = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.search_interest_vs_fmi ?? {});
  const current = data?.Current?.search_interest;
  const previous = data?.YoY?.search_interest;
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const parameters = useSelector((state: RootStateOrAny) => state.parameters);
  const market = useSelector((state: RootStateOrAny) => state.parameters.market);
  const [toggleActive, setToggleActive] = useState<boolean>(true);

  const { currentData, previousData } = useMemo(() => {
    const currentData = current
      ?.map((val: { date: string }) => ({ date_f: Date.parse(val.date), ...val }))
      .map(
        (val: {
          exclude: boolean;
          media_spend_amount?: number;
          media_spend_amount_on?: number;
          media_spend_amount_off?: number;
          fmi_amount?: number;
          fmi_amount_on?: number;
          fmi_amount_off?: number;
        }) => {
          if (val.exclude) {
            const result: any = {};

            result["media_spend_amount_light"] = val?.media_spend_amount;
            result["media_spend_amount_on_light"] = val?.media_spend_amount_on;
            result["media_spend_amount_off_light"] = val?.media_spend_amount_off;
            result["fmi_amount_light"] = val?.fmi_amount;
            result["fmi_amount_on_light"] = val?.fmi_amount_on;
            result["fmi_amount_off_light"] = val?.fmi_amount_off;
            delete val?.fmi_amount;
            delete val?.fmi_amount_off;
            delete val?.fmi_amount_on;
            delete val?.media_spend_amount;
            delete val?.media_spend_amount_off;
            delete val?.media_spend_amount_on;
            return { ...val, ...result };
          } else {
            return val;
          }
        }
      );
    const previousData = previous?.map((val: { date: string }) => ({ date_f: Date.parse(val.date), ...val }));
    return { currentData, previousData };
  }, [current, previous]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (tooltipVisible && ref.current != null && !ref.current.contains(e.target)) {
        setTooltipVisible(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [tooltipVisible]);

  return (
    <>
      <div className="search_interest_vs_fmi primary_tile_background">
        <div className="header">
          <div className="title_container">
            <h3>Search interest vs {toggleActive ? "media spend" : "FMI spend"}</h3>
            {Object.keys(data).length > 0 && (
              <Tooltip
                position="left"
                className="key_indicator_tooltip"
                html={
                  <div ref={ref}>
                    <p>
                      <span style={{ fontWeight: "bold" }}>Search interest</span> represents Google searches in the chosen market for
                      localised, exact match search terms.
                    </p>
                    <p>
                      <u>Source:</u> CEDAR-Google custom index search volume
                    </p>
                    <br />
                    <br />
                    <p>
                      <span style={{ fontWeight: "bold" }}>{toggleActive ? "Media spend" : "FMI (Fixed Marketing Investment)"}</span>{" "}
                      {toggleActive
                        ? "is a major component of total FMI that Nissan spends and focuses on the promotion of models through media channels (internet, OOH, Print, Radio, TV, etc.)."
                        : "represents the investment strategically done mainly on Media (internet, OOH, Print, Radio, TV, etc.), Fixed (Motor shows, Sponsorship, Research, etc.) and Strategic ( PACE platform, DND, etc.) to improve brand image, OaO and Sales."}
                    </p>
                    <br />
                    <p>Offline spend reports on the Non-digital investment and Online spend reports on Digital investment.</p>
                    <p>
                      Previous year investment displays ACT values (investment that was actually made) and selected date range investment
                      displays the most recent FMI scenario available.
                    </p>
                    <br />
                    <p>
                      <u>Source</u>: MIDAS
                    </p>
                  </div>
                }
                trigger="click"
                animation="none"
                theme="dark"
                interactive={true}
                delay={1}
                hideDelay={1}
                duration={1}
                open={tooltipVisible}
                //@ts-ignore
                onShown={() => setTooltipVisible(!tooltipVisible)}
              >
                <span
                  className="info_tooltip hide_on_mobile"
                  onClick={(evt: React.MouseEvent<HTMLElement>) => {
                    evt.stopPropagation();
                    setTooltipVisible(!tooltipVisible);
                  }}
                />
              </Tooltip>
            )}
          </div>

          <div className="toggle-container">
            <ToggleSwitch
              activeToggleLabel="Media spend"
              inactiveToggleLabel="FMI spend"
              active={toggleActive}
              handleToggleClick={() => setToggleActive(!toggleActive)}
            />
          </div>
        </div>

        <div className="main_content">
          {currentData?.length || previousData?.length ? (
            <ErrorBoundary fallback={<ErrorMsg />}>
              <SearchInterestVsFMIChart
                current={currentData}
                previous={previousData}
                seriesList={["previous", "current"]}
                theme={themeContext.theme}
                isLoading={loading}
                granularity={parameters.granularity}
                toggleActive={toggleActive}
                market={market}
              />
            </ErrorBoundary>
          ) : loading ? (
            <LoadingEllipsis isLoading={loading} />
          ) : (
            <NoDataMsg message="No data available for this market" />
          )}
        </div>
      </div>
    </>
  );
};

export default SearchInterestVsFMI;
