import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { fetchUseOwnershipFilters, setParameters, setSpecificParameter } from "../../actions";
import {
  ALL_OPTION_NO_SPACE,
  DEFAULT_USE_OWNERSHIP_PARAMETERS,
  MIN_SELECTABLE_DATE,
  USE_OWNERSHIP_DATE_FILTER_OPTIONS,
} from "../../constants";
import { FilterContext } from "../../context";
import { isMonthlyCustomDate } from "../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { currentDefaultFiltersSet, defaultFiltersSet } from "../../utils/utilityFunctions";
import { ErrorMsg } from "../AppMessages";
import { FiltersLayout } from "../Layouts";
import { DefaultFilter, FilterBtns, MultiSelectFilter } from "./common";
import PurchaseFunnelDateFilter from "./common/PurchaseFunnelDateFilter";
import { closeFilterOptions, closeMarketOptions } from "./subs/helpers";

interface Props {
  filterBtns: Array<{ id: string; navigateTo: string; name: string }>;
  page: string;
}

const UseOwnershipFilters = withRouter((props: RouteComponentProps & Props): JSX.Element => {
  const { filterBtns, page, history } = props;
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);
  const {
    date_range: dateRangeParamValue,
    brand: brandParamValue,
    market: marketParamValue,
  } = useSelector((state: RootStateOrAny) => state.parameters);
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.use_ownership_last_data_refresh);
  const brands = useSelector((state: RootStateOrAny) => state.filters.use_ownership_filters.brand);
  const markets = useSelector((state: RootStateOrAny) => state.filters.use_ownership_filters.market);

  const currentUrl = window.location.href;

  const [marketFilterValue, setMarketFilterValue] = useState(marketParamValue);

  //Sets the initial start and end selectable date to be 1st April 2019 and the latest data refresh | today() - 1 (yesterday) if the last data refresh is not yet defined
  const [marketDataAvailability, setMarketDataAvailability] = useState<{ start: string; end: string }>({
    start: MIN_SELECTABLE_DATE,
    end: lastDataRefresh || moment().subtract(1, "days").format("DD/MM/YYYY"),
  });

  const [initializeMarketFilter, setInitializeMarketFilter] = useState(true);
  const [marketsValidated, setMarketsValidated] = useState(false);

  const trackingDashboardName = "Use Ownership";

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("USE Ownership Dashboard");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_USE_OWNERSHIP_PARAMETERS));
      currentDefaultFiltersSet("USE Ownership Dashboard");
    }
  }, []);

  useEffect(() => {
    setTimeout(() => dispatch(fetchUseOwnershipFilters(page)), 100);
  }, [page, brandParamValue]);

  //Reset date range param value
  useEffect(() => {
    if (dateRangeParamValue) {
      const isValidDate = USE_OWNERSHIP_DATE_FILTER_OPTIONS.includes(dateRangeParamValue) || isMonthlyCustomDate(dateRangeParamValue);
      if (!isValidDate) dispatch(setSpecificParameter("date_range", "Last quarter"));
    } else {
      dispatch(setSpecificParameter("date_range", "Last quarter"));
    }
  }, [dateRangeParamValue]);

  //Validate brand param
  useEffect(() => {
    if (brands && brands.length > 0 && !brands.includes(brandParamValue)) {
      dispatch(setSpecificParameter("brand", brands[0]));
    }
  }, [brands]);

  //Validate market param
  useEffect(() => {
    setTimeout(() => {
      if (markets && markets.length > 0) {
        if (initializeMarketFilter) {
          dispatch(setSpecificParameter("market", markets.join(",")));
          setMarketsValidated(true);
          setInitializeMarketFilter(false);
          return;
        }

        if (!marketsValidated && !initializeMarketFilter) {
          const selectedMarkets = marketParamValue.split(",");
          const validMarkets: Array<string> = [];
          selectedMarkets.forEach((market: string) => {
            if (markets.includes(market)) validMarkets.push(market);
          });

          if (validMarkets.length > 0 && validMarkets != selectedMarkets) {
            dispatch(setSpecificParameter("market", validMarkets.join(",")));
          } else {
            dispatch(setSpecificParameter("market", markets.join(",")));
          }
          setMarketsValidated(true);
        }
      }
    }, 2500);
  }, [marketParamValue, markets]);

  // Set market filter value
  useEffect(() => {
    if (markets && marketParamValue) {
      const selectedMarketsArr = marketParamValue.split(",");
      const marketSourceValue =
        selectedMarketsArr?.length === markets.length
          ? "All"
          : selectedMarketsArr?.length > 1
          ? `${selectedMarketsArr?.length} market selected`
          : marketParamValue;

      setMarketFilterValue(marketSourceValue);
    } else {
      setMarketFilterValue(ALL_OPTION_NO_SPACE);
    }
  }, [markets, marketParamValue]);

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));
      eventTracking(MixpanelEvents.filter_change, {
        filter: filterName,
        value: optionValue,
        dashboard: trackingDashboardName,
        page: props.page,
      });
    }
    closeFilterOptions();
    setShowFilterOptions(false);
  };

  const showFilterOptionsFn = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const optionsId = dataset?.optionsUl;
      const shownClass = "showOptions";

      const optionsElement = optionsId ? document.getElementById(optionsId) : undefined;

      if (showFilterOptions && optionsElement?.classList.contains(shownClass)) {
        closeFilterOptions();
        closeMarketOptions();
        setShowFilterOptions(false);
      } else {
        closeFilterOptions();
        optionsElement?.classList.toggle(shownClass);
        setShowFilterOptions(!showFilterOptions);
      }
    },
    [showFilterOptions, setShowFilterOptions]
  );

  const onRenderOptions = ({ list, filter }: { list: string[]; filter: string }) => {
    return (
      <>
        {list?.map((item: string) => {
          return (
            <li key={item} data-filter={filter} onClick={handleFilterOptionClick} data-value={item}>
              {item}
            </li>
          );
        })}
      </>
    );
  };

  const handleMarketFilterSubmission = useCallback(() => {
    const parameterName = "market";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = selectedValues?.join(",");
    closeFilterOptions();
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, {
      filter: parameterName,
      value: filterValue,
      dashboard: trackingDashboardName,
      page: props.page,
    });
  }, [markets]);

  // Set params
  useEffect(() => {
    const params = new URLSearchParams();

    params.set("date_range", dateRangeParamValue);
    params.set("brand", brandParamValue);
    params.set("market", marketParamValue);
    history.push({ search: `?${params.toString()}` });
  }, [dateRangeParamValue, brandParamValue, marketParamValue]);

  return (
    <FiltersLayout extraClass={"iframeFilters-midas"}>
      <>
        <div className="nav_btns">
          {filterBtns?.map((btn) => (
            <FilterBtns
              id={btn.id}
              navigateTo={btn.navigateTo}
              name={btn.name}
              extraBtnClass={currentUrl.includes(btn.navigateTo) ? "active" : ""}
              key={btn.id}
            />
          ))}
        </div>

        <div className="filte">
          {/* Date range */}
          <ErrorBoundary fallback={<ErrorMsg />}>
            <PurchaseFunnelDateFilter
              value={dateRangeParamValue}
              onFilterClick={showFilterOptionsFn}
              handleFilterOptionClick={handleFilterOptionClick}
              presetDatesList={USE_OWNERSHIP_DATE_FILTER_OPTIONS}
            />
          </ErrorBoundary>

          {/*Brand*/}
          <ErrorBoundary fallback={<ErrorMsg />}>
            <DefaultFilter
              list={brands}
              filterName={"brand"}
              filterLabel={"BRAND"}
              filterValue={brandParamValue}
              handleFilterOptionClick={handleFilterOptionClick}
            />
          </ErrorBoundary>

          {/*Market*/}
          <ErrorBoundary fallback={<ErrorMsg />}>
            <MultiSelectFilter
              parentKey="market"
              filterList={markets ? markets : []}
              filterName="MARKET"
              value={marketFilterValue}
              parameterName={"market"}
              parameterValue={marketParamValue}
              onShowFilterOptions={showFilterOptionsFn}
              handleFilterSubmission={handleMarketFilterSubmission}
            />
          </ErrorBoundary>
        </div>
      </>
    </FiltersLayout>
  );
});

export default UseOwnershipFilters;
