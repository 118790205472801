import React from "react";
import "../../../../assets/styles/component/disByMarketTable.scss";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";

interface Props {
  data: Array<Record<string, number | string>>;
  showWebsiteVisits: boolean;
  isLoading: boolean;
  dateLabel: string;
}

const marketFlags: Record<string, string> = {
  China: "China",
  "United States": "USA",
  Canada: "Canada",
  Mexico: "Mexico",
  "United Kingdom": "UK",
  Brazil: "Brazil",
  Japan: "Japan",
};

const DisByMarketTable = (props: Props) => {
  const { data, showWebsiteVisits, isLoading, dateLabel } = props;
  return isLoading ? (
    <LoadingEllipsis isLoading={isLoading} />
  ) : (
    <table className="dis-table dis-by-market-table">
      <tr>
        <td className="performance-header bold" colSpan={2}>
          {dateLabel}
        </td>
        {showWebsiteVisits && (
          <td className="visits ">
            <p className="bold">Visits</p>
            <p className="bold sub-text">(to Nissan Tier 1 website)</p>
          </td>
        )}
        <td className="visits-to-lead bold">Visits to Lead Conversion</td>
        <td className="tracked-leads bold">
          <p className="bold">Tracked Leads</p>
          <p className="bold sub-text">(from Nissan OEM channels)</p>
        </td>
        <td className="oem-leads bold">OEM Leads to DIS Conversion</td>
        <td className="total-digital-leads bold">TTL Digital Leads</td>
        <td className="total-dis bold">
          <p className="bold">TTL Digitally Influenced Sales</p> <p className="bold sub-text">(from all digital channels)</p>
        </td>
        <td className="private-sales bold">Total Private Sales</td>
        <td className="dis bold">
          <p className="bold">DIS %</p> <p className="bold sub-text">(from all digital channels)</p>
        </td>
      </tr>
      {data.map((dataItem) => (
        <tr key={dataItem.visits}>
          <td>
            <div className="market-flag bold">
              <span className={`flagIcon ${marketFlags[dataItem.market]}`}></span>
              {dataItem.market}
            </div>
          </td>
          <td className="performance-header bold">Performance</td>
          {showWebsiteVisits && <td className="visits">{dataItem.visits?.toLocaleString()}</td>}
          <td className="visits-to-lead">
            {dataItem.visits_to_lead}
            {`${dataItem.visits_to_lead ? "%" : ""}`}
          </td>
          <td className="tracked-leads">{dataItem.tracked_leads?.toLocaleString()}</td>
          <td className="oem-leads">
            {dataItem.oem_leads_to_dis}
            {`${dataItem.oem_leads_to_dis ? "%" : ""}`}
          </td>
          <td className="total-digital-leads">{dataItem.ttl_digital_leads?.toLocaleString()}</td>
          <td className="total-dis">{dataItem.ttl_digitally_influenced_sales?.toLocaleString()}</td>
          <td className="private-sales">{dataItem.private_sales?.toLocaleString()}</td>
          <td className="dis bold">
            DIS: {dataItem.dis_perc}
            {`${dataItem.dis_perc ? "%" : ""}`}
          </td>
        </tr>
      ))}
    </table>
  );
};

export default DisByMarketTable;
