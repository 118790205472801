import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { Dispatch, SetStateAction, useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { VisitsTrendGraph } from ".";
import "../../../assets/styles/component/baselineTrend.scss";
import { ErrorMsg } from "../../../components/AppMessages";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { VisitsTrendBreakdownTable } from "../../../components/Tables";
import { DocumentationContext } from "../../../context";
interface Props {
  dateTitleValue: string;
  dateTitleDescription: string;
  granularity: string;
  breakdownTitle: string;
  breakdownColumnTitle: string;
  metric: string;
  showPop: boolean;
  popLabel: string;
  isNameplateTooltipVisible: boolean;
  isCpoDashboard?: boolean;
  onHandleRadioClick: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  toggleAnnotationsDialog: () => void;
  setAnnotationStartDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationEndDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationDefaultMetric: Dispatch<SetStateAction<string>>;
  setAnnotationDefaultRegion: Dispatch<SetStateAction<string | undefined>>;
  setAnnotationDefaultMarket: Dispatch<SetStateAction<string | undefined>>;
  setAnnotationDefaultModel: Dispatch<SetStateAction<string | undefined>>;
}

export const VisitsTrend = (props: Props): JSX.Element => {
  const {
    dateTitleValue,
    dateTitleDescription,
    granularity,
    breakdownTitle,
    metric,
    breakdownColumnTitle,
    showPop,
    popLabel,
    isNameplateTooltipVisible,
    isCpoDashboard,
    onHandleRadioClick,
    toggleAnnotationsDialog,
    setAnnotationStartDate,
    setAnnotationEndDate,
    setAnnotationDefaultMetric,
    setAnnotationDefaultRegion,
    setAnnotationDefaultMarket,
    setAnnotationDefaultModel,
  } = props;

  const { setDocumentation, setIsVisible } = useContext(DocumentationContext);

  const visitsGraphIsLoading = useSelector((state: RootStateOrAny) => state.loading.visits_breakdown_graph);
  const visitsTableIsLoading = useSelector((state: RootStateOrAny) => state.loading.visits_breakdown_table);

  const newTrendBreakdownTotal = useSelector((state: RootStateOrAny) => state.digital_performance.new_key_indicator_totals.data);
  const cpoBreakdownTotal = useSelector((state: RootStateOrAny) => state.cpo_digital_performance.key_indicator_all);

  const period = useMemo(() => {
    if (dateTitleValue == "last week") {
      const startDate = moment(isCpoDashboard ? cpoBreakdownTotal?.start_date : newTrendBreakdownTotal?.start_date).format("DD-MMM");
      const endDate = moment(isCpoDashboard ? cpoBreakdownTotal?.end_date : newTrendBreakdownTotal?.end_date).format("DD-MMM");

      return `(${startDate} to ${endDate} )`;
    }
    return dateTitleDescription;
  }, [newTrendBreakdownTotal, cpoBreakdownTotal, dateTitleValue]);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className="visits_trend">
        <div id="visits_trend_graph" data-test-id={"visits_trend_graph"} className={"graphArea"}>
          <VisitsTrendGraph
            isCpoDashboard={isCpoDashboard}
            granularity={granularity}
            breakdownTitle={breakdownTitle}
            onHandleRadioClick={onHandleRadioClick}
            metric={metric}
            toggleAnnotationsDialog={toggleAnnotationsDialog}
            setAnnotationEndDate={setAnnotationEndDate}
            setAnnotationStartDate={setAnnotationStartDate}
            setAnnotationDefaultMetric={setAnnotationDefaultMetric}
            setAnnotationDefaultRegion={setAnnotationDefaultRegion}
            setAnnotationDefaultMarket={setAnnotationDefaultMarket}
            setAnnotationDefaultModel={setAnnotationDefaultModel}
          />
        </div>

        <div id="visits_trend_table" data-test-id={"visits_trend_table"} className={"tableArea"}>
          <div className="header">
            <h3>
              Breakdown for {dateTitleValue} <span className="period_updatable">{period}</span>
            </h3>
            {isNameplateTooltipVisible && (
              <span
                className="info_tooltip "
                id="model_data_button"
                data-test-id="model_data_button"
                onClick={() => {
                  setDocumentation("model_data");
                  setIsVisible(true);
                }}
              />
            )}
          </div>
          <div className="main_content">
            <VisitsTrendBreakdownTable
              isCpoDashboard={isCpoDashboard}
              metric={metric}
              breakdownColumnTitle={breakdownColumnTitle}
              showPop={showPop}
              popLabel={popLabel}
            />
          </div>
        </div>

        <LoadingEllipsis isLoading={visitsTableIsLoading} className={"metric_table_overlay"} />
        <LoadingEllipsis isLoading={visitsGraphIsLoading} className={"metric_graph_overlay"} />
      </div>
    </ErrorBoundary>
  );
};
