import moment from "moment";
import { Flash, IFlashMsgs } from "../constants/interface/admin/flash";
import { IKpiUpdateLog, KPILog } from "../constants/interface/admin/kpiUpdate";
import { IReleaseNote } from "../constants/interface/admin/releaseNotes";
import { ISeoInsight, ISeoInsights } from "../constants/interface/admin/seoInsights";
import { deleteDataV2, fetchData, fetchDataV2, postData, postDataV2, updateDataV2 } from "./utils";

export const getBrandsData = () => fetchDataV2("/user_service/get-all-brands");
export const getMarketsData = () => fetchDataV2("/user_service/get-all-markets");
export const getRegionsData = () => fetchDataV2("/user_service/get-all-regions");
export const getViewsData = () => fetchDataV2("/user_service/get-all-views");
export const getModelsData = () => fetchDataV2("/annotation/get-all-models");
export const getChannelsData = () => fetchDataV2("/annotation/get-all-channels");
export const getChannelBreakdownsData = () => fetchDataV2("/annotation/get-all-channel-breakdowns");
export const getMetricsData = () => fetchDataV2("/annotation/get-all-metrics");

// Flash
export const getFlashMessages = (): Promise<IFlashMsgs> => fetchData("/admin/flash/get");
export const createFlashMessage = (values: Flash): Promise<Flash> =>
  postData("/admin/flash/create", { ...values, valid: values.valid ? 1 : 0 });
export const updateFlashMessage = (values: Flash): Promise<Flash> =>
  postData("/admin/flash/update", { ...values, valid: values.valid ? 1 : 0 });
export const deleteFlashMessage = (id: number): Promise<"success" | "error"> => postData("/admin/flash/delete", { id });

// Release notes
export const getReleaseNotes = (): Promise<Array<IReleaseNote>> => fetchDataV2("/user-messages/get-all-release-notes");
export const createReleaseNote = (values: IReleaseNote): any =>
  postDataV2("/user-messages/create-release-note", {
    ...values,
    release_date: moment(values.release_date).format("YYYY-MM-DD HH:mm:ss"),
  });
export const updateReleaseNote = (values: IReleaseNote): any =>
  updateDataV2(`/user-messages/update-release-note?id=${values.id}`, {
    ...values,
    release_date: moment(values.release_date).format("YYYY-MM-DD HH:mm:ss"),
  });
export const deleteReleaseNote = (id: number): Promise<IReleaseNote> => deleteDataV2(`/user-messages/delete-release-note?id=${id}`);

// SEO Insights
export const getSeoInsights = (): Promise<ISeoInsights> => fetchData("/admin/seo_insights/get");
export const createSeoInsight = (values: ISeoInsight): Promise<ISeoInsight> =>
  postData("/admin/seo_insights/create", {
    ...values,
    show_insights: values.show_insights ? 1 : 0,
  });
export const updateSeoInsight = (values: ISeoInsight): Promise<ISeoInsight> =>
  postData("/admin/seo_insights/update", {
    ...values,
    show_insights: values.show_insights ? 1 : 0,
  });
export const deleteSeoInsight = (id: number): Promise<"success" | "error"> => postData("/admin/seo_insights/delete", { id });

// KPI update
export const getKPIs = (): Promise<IKpiUpdateLog> => fetchData("/admin/kpi_update/get");
export const createKPILog = (values: KPILog): Promise<KPILog> =>
  postData("/admin/kpi_update/create", { ...values, update_date: moment(values.update_date).format("YYYY-MM-DD HH:mm:ss") });
export const updateKPILog = (values: KPILog): Promise<KPILog> =>
  postData("/admin/kpi_update/update", { ...values, update_date: moment(values.update_date).format("YYYY-MM-DD HH:mm:ss") });
export const deleteKPILog = (id: number): Promise<"success" | "error"> => postData("/admin/kpi_update/delete", { id });
