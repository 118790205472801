import React, { Fragment } from "react";
import { useDropdownVisible } from "../../../hooks";

interface ColumnFilterProps {
  column: any;
  data: any;
  value: string;
  showDropdown: boolean;
}

export const ColumnFilter = (Props: ColumnFilterProps): JSX.Element => {
  const { column, data, value, showDropdown } = Props;
  const { filterValue, setFilter } = column;
  const { wrapperRef, isVisible, setIsVisible } = useDropdownVisible(false);
  const valuesRendered: Array<string> = Array.from(new Set([...data.map((row: { [index: string]: string | number }) => row[value])]));

  return (
    <div className="column_filter" ref={wrapperRef}>
      <div className={`column_filter_icon ${filterValue ? "active" : ""}`} onClick={() => setIsVisible(!isVisible)}></div>
      {isVisible && (
        <Fragment>
          <ul className="dropdown-options">
            <input value={filterValue || ""} onChange={(e) => setFilter(e.target.value)} placeholder="Filter records" />
            {showDropdown &&
              valuesRendered?.map((option) => (
                <li
                  key={option}
                  onClick={() => {
                    setFilter(option);
                    setIsVisible(false);
                  }}
                >
                  {option}
                </li>
              ))}
          </ul>
        </Fragment>
      )}
    </div>
  );
};
