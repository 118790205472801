import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import {
  deleteSpecificParameter,
  fetchBaselineChartAnnotations,
  fetchBrandsData,
  fetchChannelBreakdownsData,
  fetchChannelsData,
  fetchCpoBreakdownTrendAllData,
  fetchCpoBreakdownTrendData,
  fetchCpoKbaBreakdownTableData,
  fetchCpoKbaLabelMapping,
  fetchCpoLastDataRefresh,
  fetchCpoLeadBreakdownTableData,
  fetchCpoLeadLabelMapping,
  fetchCpoLeadVisitsData,
  fetchFiltersCpoData,
  fetchKeyIndicatorsAllData,
  fetchLastDataRefresh,
  fetchMarketsData,
  fetchMetricsData,
  fetchModelKeyIndicatorsData,
  fetchModelTrendData,
  fetchNewModelsData,
  fetchOCEAnnotationsKbaTypes,
  fetchOCEAnnotationsLeadTypes,
  fetchRegionsData,
  fetchVisitsChartAnnotations,
  setPageName,
  setSpecificParameter,
} from "../../actions";
import "../../assets/styles/pages/digitalPerformance.scss";
import { AnnotationDialog } from "../../components/Annotations/Dialogs/AnnotationDialog";
import { ErrorMsg } from "../../components/AppMessages";
import { getDefaultGranularity } from "../../components/Charts/helpers/helpers";
import { CpoFilters } from "../../components/Filters";
import { DashboardLayout } from "../../components/Layouts";
import { DefaultPageContent } from "../../components/PageContent";
import { PageTitle } from "../../components/PageContent/subs";
import { ALL_COUNTRIES, DEFAULT_DATE_RANGE, DEFAULT_METRIC, metricTypes } from "../../constants";
import { IMappedGeographies } from "../../constants/interface";
import { getStartEnd, isCustomDate, isDigitalPerformanceDate } from "../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { getVisitsAnnotationApiParams } from "../../utils/utilityFunctions";
import { BaselineTrend, KeyIndicators, MetricTrend, ModelsTrend, VisitsTrend } from "./components";
import { getDateDependentValues, getGeographyDependentValues } from "./subs/helpers";

export const CPODigitalPerformance = withRouter(() => {
  const dispatch = useDispatch();

  const parameters = useSelector((state: RootStateOrAny) => state.parameters);
  const visitsMarkets = useSelector((state: RootStateOrAny) => state.cpo_digital_performance.cpo_breakdown_trend_markets) ?? [];
  const models = useSelector((state: RootStateOrAny) => state.models.data ?? []);
  const cpodigitalPerformanceLastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.cpo_digital_performance);

  const { date_range: dateRangeParam, region: regionParam, market: marketParam, metric: parameterMetric } = parameters;
  const [popLabel, setPopLabel] = useState("WoW");
  const [dateTitleDescription, setDateTitleDescription] = useState("");
  const [dateTitleValue, setDateTitleValue] = useState("");
  const [showPop, setShowPop] = useState(true);
  const [chartGranularity, setChartGranularity] = useState("weekly");
  const [breakdownBy, setBreakdownBy] = useState("region");
  const [breakdownFirstColumn, setBreakdownFirstColumn] = useState("Regions");
  const [nameplateTooltipVisible, setNameplateTooltipVisible] = useState(false);

  const [activeMetric, setActiveMetric] = useState<metricTypes>(parameterMetric || DEFAULT_METRIC);
  const [isVolume, setIsVolume] = useState(true);

  const [annotationDialogOpen, setAnnotationDialogOpen] = useState<boolean>(false);
  const [annotationStartDate, setAnnotationStartDate] = useState<Date | null>(null);
  const [annotationEndDate, setAnnotationEndDate] = useState<Date | null>(null);
  const [annotationDefaultMetric, setAnnotationDefaultMetric] = useState<string>("visits");
  const [annotationDefaultRegion, setAnnotationDefaultRegion] = useState<string | undefined>();
  const [annotationDefaultMarket, setAnnotationDefaultMarket] = useState<string | undefined>();
  const [annotationDefaultModel, setAnnotationDefaultModel] = useState<string | undefined>();
  const [annotationDefaultMetricType, setAnnotationDefaultMetricType] = useState<string | undefined>();

  const {
    filterOptions: { geography: geographies },
  } = useSelector((state: RootStateOrAny) => state.filters);

  // Sets page name
  useEffect(() => {
    Promise.all([
      dispatch(setPageName("CPO Online CE performance")),
      dispatch(fetchLastDataRefresh()),
      dispatch(fetchCpoLastDataRefresh()),
      dispatch(fetchFiltersCpoData()),
    ]);
  }, [dispatch]);

  useEffect(() => {
    return () => disableSpecificMetric();
  }, []);

  // Resets date range Filter
  useEffect(() => {
    const validDate = isDigitalPerformanceDate(dateRangeParam) || isCustomDate(dateRangeParam);

    if (!dateRangeParam || !validDate) dispatch(setSpecificParameter("date_range", DEFAULT_DATE_RANGE));
  }, [dateRangeParam, dispatch]);

  // Add refresh date to params
  useEffect(() => {
    if (cpodigitalPerformanceLastDataRefresh) dispatch(setSpecificParameter("cpo_last_refresh_date", cpodigitalPerformanceLastDataRefresh));
  }, [cpodigitalPerformanceLastDataRefresh, dispatch]);
  // Reset region if invalid
  useEffect(() => {
    if (regionParam === "Europe") {
      dispatch(setSpecificParameter("region", "NAE"));
    }
  }, [regionParam]);

  //Reset markets if they are not validush
  useEffect(() => {
    if (geographies && geographies.length > 1) {
      const allMarkets = geographies.map((geography: IMappedGeographies) => geography.markets).flat();
      const marketParamArr = marketParam?.split(",");
      const isMarketValid = marketParam && marketParamArr?.every((market: string) => allMarkets?.includes(market));
      !isMarketValid && dispatch(setSpecificParameter("market", ALL_COUNTRIES));
    }
  }, [marketParam, geographies, dispatch]);

  // Resets date range Filter
  useEffect(() => {
    const validDate = isDigitalPerformanceDate(dateRangeParam) || isCustomDate(dateRangeParam);

    if (!dateRangeParam || !validDate) dispatch(setSpecificParameter("date_range", DEFAULT_DATE_RANGE));
  }, [dateRangeParam, dispatch]);

  // Updates dashboard on date range filter change
  useEffect(() => {
    const {
      popLabel,
      showPop: popShowing,
      titleDescription,
      dateValue,
    } = getDateDependentValues(dateRangeParam, cpodigitalPerformanceLastDataRefresh);
    setPopLabel(popLabel);
    setDateTitleDescription(titleDescription);
    setDateTitleValue(dateValue);
    setShowPop(popShowing);

    //Set default granularity based on the date range
    const startEnd = getStartEnd(dateRangeParam);
    const diff = moment(startEnd.end, "DD/MM/YYYY").diff(moment(startEnd.start, "DD/MM/YYYY"), "days");
    const granularity = dateRangeParam === "Last week" ? "weekly" : getDefaultGranularity(diff);
    setChartGranularity(granularity);
  }, [dateRangeParam, cpodigitalPerformanceLastDataRefresh]);

  // Sets geography specific values
  useEffect(() => {
    const { visitsBreakdownBy, visitsBreakdownFirstColumn, showNameplateTooltip } = getGeographyDependentValues(regionParam, marketParam);
    setBreakdownBy(visitsBreakdownBy);
    setBreakdownFirstColumn(visitsBreakdownFirstColumn);
    setNameplateTooltipVisible(showNameplateTooltip);
  }, [regionParam, marketParam]);

  // Fetch all data
  useEffect(() => {
    Promise.all([
      dispatch(fetchBrandsData("online_ce_performance")),
      dispatch(fetchRegionsData("online_ce_performance")),
      dispatch(fetchMarketsData("online_ce_performance")),
      dispatch(fetchNewModelsData("online_ce_performance")),
      dispatch(fetchChannelsData("online_ce_performance")),
      dispatch(fetchChannelBreakdownsData("online_ce_performance")),
      dispatch(fetchMetricsData("online_ce_performance")),
      dispatch(fetchOCEAnnotationsKbaTypes()),
      dispatch(fetchOCEAnnotationsLeadTypes()),

      dispatch(fetchBaselineChartAnnotations({ ...parameters, metric: "visits,kbas,leads" }, "online_ce_performance")),
    ]);
    Promise.all([
      dispatch(fetchModelTrendData()),
      dispatch(fetchModelKeyIndicatorsData()),
      dispatch(fetchCpoBreakdownTrendData()),
      dispatch(fetchCpoBreakdownTrendAllData()),
      dispatch(fetchKeyIndicatorsAllData()),
      dispatch(fetchCpoKbaLabelMapping()),
      dispatch(fetchCpoLeadLabelMapping()),
    ]);

    setTimeout(function () {
      Promise.all([dispatch(fetchCpoKbaBreakdownTableData()), dispatch(fetchCpoLeadBreakdownTableData())]);
    }, 1500);

    setTimeout(function () {
      Promise.all([dispatch(fetchCpoLeadVisitsData())]);
    }, 15000);
  }, [parameters, dispatch]);

  // Usage tracking
  useEffect(() => eventTracking(MixpanelEvents.page_view, { dashboard: "CPO", page: "CPO", activeMetric }), [location.pathname]);

  useEffect(() => {
    if (!annotationDialogOpen) {
      setAnnotationStartDate(null);
      setAnnotationEndDate(null);
    }
  }, [annotationDialogOpen]);

  useEffect(() => {
    const params = getVisitsAnnotationApiParams(parameters, visitsMarkets);
    if (params.region != "") {
      dispatch(fetchVisitsChartAnnotations(getVisitsAnnotationApiParams(parameters, visitsMarkets), "CPO"));
    }
  }, [parameters, visitsMarkets]);

  const disableSpecificMetric = useCallback(() => {
    const specificParams = ["specific_kba", "specific_lead"];
    specificParams.forEach((specificParam) => {
      dispatch(deleteSpecificParameter(specificParam));
    });
  }, [parameters]);

  const handleMetricClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const selectedMetric = dataset?.metric || activeMetric;

      disableSpecificMetric();

      setActiveMetric(selectedMetric as metricTypes);
      dispatch(setSpecificParameter("metric", selectedMetric));
    },
    [dispatch, activeMetric]
  );

  useEffect(() => {
    dispatch(setSpecificParameter("granularity", chartGranularity));
  }, [chartGranularity, dispatch]);

  const handleGranularityRadioChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { value },
      } = evt;

      setChartGranularity(value);
    },
    [setChartGranularity]
  );

  const handleSpecificMetricClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;

      const value = dataset?.value;
      const specifiedValue = dataset?.specifiedValue;
      const specificParamName = dataset?.metric === "kbas" ? "specific_kba" : "specific_lead";

      console.log(value);

      if (value === specifiedValue) {
        dispatch(deleteSpecificParameter(specificParamName));
      } else {
        value && dispatch(setSpecificParameter(specificParamName, value));
      }
    },
    [dispatch]
  );

  const toggleAnnotationsDialog = useCallback(() => {
    setAnnotationDialogOpen(!annotationDialogOpen);
  }, []);

  useEffect(() => {
    if (!annotationDialogOpen) {
      setAnnotationDefaultRegion(undefined);
      setAnnotationDefaultMarket(undefined);
      setAnnotationDefaultModel(undefined);
    }
  }, [annotationDialogOpen]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<CpoFilters />}
        dataDocumentation="cpo_data"
        lastDataRefresh={cpodigitalPerformanceLastDataRefresh ?? ""}
        showLastDataRefreshAlert={true}
        pageTitle={<PageTitle dataDocumentation="cpo_data" />}
      >
        <ErrorBoundary fallback={<ErrorMsg />}>
          <div className={`dp_sotu ${activeMetric !== "visits" ? "show_metric" : ""}`}>
            {/*Website visits variance to baseline* trend*/}
            <ErrorBoundary fallback={<ErrorMsg />}>
              <BaselineTrend
                isVolume
                isCpoDashboard={true}
                metric={activeMetric}
                granularity={chartGranularity}
                onHandleRadioClick={handleGranularityRadioChange}
                toggleAnnotationsDialog={toggleAnnotationsDialog}
                setAnnotationEndDate={setAnnotationEndDate}
                setAnnotationStartDate={setAnnotationStartDate}
                setAnnotationDefaultMetric={setAnnotationDefaultMetric}
              />
            </ErrorBoundary>
            {/*Key indicator*/}
            <ErrorBoundary fallback={<ErrorMsg />}>
              <KeyIndicators
                isCpoDashboard={true}
                activeMetric={activeMetric}
                popLabel={popLabel}
                dateTitleDescription={dateTitleDescription}
                dateTitleValue={dateTitleValue}
                showPoP={showPop}
                onHandleMetricClick={handleMetricClick}
                isVolume={isVolume}
                setIsVolume={setIsVolume}
              />
            </ErrorBoundary>
            {/*Website visits trend*/}
            <ErrorBoundary fallback={<ErrorMsg />}>
              <VisitsTrend
                isCpoDashboard={true}
                dateTitleDescription={dateTitleDescription}
                dateTitleValue={dateTitleValue}
                granularity={chartGranularity}
                breakdownTitle={breakdownBy}
                breakdownColumnTitle={breakdownFirstColumn}
                metric={activeMetric}
                showPop={showPop}
                popLabel={popLabel}
                isNameplateTooltipVisible={nameplateTooltipVisible}
                onHandleRadioClick={handleGranularityRadioChange}
                toggleAnnotationsDialog={toggleAnnotationsDialog}
                setAnnotationEndDate={setAnnotationEndDate}
                setAnnotationStartDate={setAnnotationStartDate}
                setAnnotationDefaultMetric={setAnnotationDefaultMetric}
                setAnnotationDefaultRegion={setAnnotationDefaultRegion}
                setAnnotationDefaultMarket={setAnnotationDefaultMarket}
                setAnnotationDefaultModel={setAnnotationDefaultModel}
              />
            </ErrorBoundary>

            <ErrorBoundary fallback={<ErrorMsg />}>
              <ModelsTrend
                isCpoDashboard={true}
                dateTitleDescription={dateTitleDescription}
                dateTitleValue={dateTitleValue}
                granularity={chartGranularity}
                breakdownTitle={breakdownBy}
                breakdownColumnTitle={breakdownFirstColumn}
                metric={activeMetric}
                showPop={showPop}
                popLabel={popLabel}
                isNameplateTooltipVisible={nameplateTooltipVisible}
                onHandleRadioClick={handleGranularityRadioChange}
                toggleAnnotationsDialog={toggleAnnotationsDialog}
                setAnnotationEndDate={setAnnotationEndDate}
                setAnnotationStartDate={setAnnotationStartDate}
                setAnnotationDefaultMetric={setAnnotationDefaultMetric}
                setAnnotationDefaultRegion={setAnnotationDefaultRegion}
                setAnnotationDefaultMarket={setAnnotationDefaultMarket}
                setAnnotationDefaultModel={setAnnotationDefaultModel}
              />
            </ErrorBoundary>

            {/*/!*Metric tiles *!/*/}
            {activeMetric !== "visits" ? (
              <ErrorBoundary fallback={<ErrorMsg />}>
                <MetricTrend
                  isCpoDashboard={true}
                  dateTitleDescription={dateTitleDescription}
                  dateTitleValue={dateTitleValue}
                  granularity={chartGranularity}
                  metric={activeMetric}
                  showPop={showPop}
                  specificMetric={activeMetric === "kbas" ? parameters["specific_kba"] : parameters["specific_lead"]}
                  popLabel={popLabel}
                  onHandleRadioClick={handleGranularityRadioChange}
                  onHandleSpecificMetricClick={handleSpecificMetricClick}
                  toggleAnnotationsDialog={toggleAnnotationsDialog}
                  setAnnotationEndDate={setAnnotationEndDate}
                  setAnnotationStartDate={setAnnotationStartDate}
                  setAnnotationDefaultMetric={setAnnotationDefaultMetric}
                  setAnnotationDefaultMetricType={setAnnotationDefaultMetricType}
                />
              </ErrorBoundary>
            ) : null}

            {/*/!*Annotations modal *!/*/}
            <ErrorBoundary fallback={<ErrorMsg />}>
              {annotationDialogOpen && (
                <AnnotationDialog
                  open={annotationDialogOpen}
                  setOpen={setAnnotationDialogOpen}
                  startDate={annotationStartDate}
                  endDate={annotationEndDate}
                  defaultMetric={annotationDefaultMetric}
                  defaultRegion={annotationDefaultRegion}
                  defaultMarket={annotationDefaultMarket}
                  defaultModel={annotationDefaultModel}
                  defaultMetricType={annotationDefaultMetricType}
                  dashboard="online_ce_performance"
                />
              )}
            </ErrorBoundary>
          </div>
        </ErrorBoundary>
      </DefaultPageContent>
    </DashboardLayout>
  );
});
