import React, { useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ToggleSwitch } from "../../../../components/Toogle";
import ValueTile from "./ValueTile";

const OnlineJourneyTiles = () => {
  const {
    Current: currentDigitalSpendVisitsData,
    YoY: yoyDigitalSpendVisitsData,
    PoP: popDigitalSpendVisitsData,
  } = useSelector((state: RootStateOrAny) => state.journey_excellence.digital_spend_visits);
  const digitalSpendVisitsLoading = useSelector((state: RootStateOrAny) => state.loading.digital_spend_visits);

  const [websiteVisitsToogleState, setWebsiteVisitsToogleState] = useState(true);

  const websiteVisitsToggle = (
    <ToggleSwitch
      activeToggleLabel={"All visits"}
      inactiveToggleLabel={"Qualified visits"}
      active={websiteVisitsToogleState}
      handleToggleClick={() => setWebsiteVisitsToogleState((prevState) => !prevState)}
      toggleClassName={"value-tile-toggle"}
    />
  );

  return (
    <div id="online_journey" className="section-content journey-excellence-row" data-target="online_journey">
      <div className="section-content-row">
        <ValueTile
          title="Website visits"
          mainValue={websiteVisitsToogleState ? currentDigitalSpendVisitsData?.visits : currentDigitalSpendVisitsData?.qualified_visits}
          yoyValue={websiteVisitsToogleState ? yoyDigitalSpendVisitsData?.perc_visits : yoyDigitalSpendVisitsData?.perc_qualified_visits}
          popValue={websiteVisitsToogleState ? popDigitalSpendVisitsData?.perc_visits : popDigitalSpendVisitsData?.perc_qualified_visits}
          isLoading={digitalSpendVisitsLoading}
          toggle={websiteVisitsToggle}
          tooltipText="Website visit happens every time the site has at least one page fully loaded."
        />
        <ValueTile
          title="Bounce rate"
          mainValue={currentDigitalSpendVisitsData?.bounce_rate}
          yoyValue={yoyDigitalSpendVisitsData?.bounce_rate}
          popValue={popDigitalSpendVisitsData?.bounce_rate}
          isPercentage={true}
          tooltipText=""
          isLoading={digitalSpendVisitsLoading}
        />
        <ValueTile
          title="Cost per visit"
          mainValue={currentDigitalSpendVisitsData?.cost_per_visit}
          yoyValue={yoyDigitalSpendVisitsData?.perc_cost_per_visit}
          popValue={popDigitalSpendVisitsData?.perc_cost_per_visit}
          isLoading={digitalSpendVisitsLoading}
          showCurrency={true}
          ratingInverse={true}
          ratingArrowInverse={true}
          tooltipText="Cost for each website visit based on the Media FMI allocated through digital channels"
        />
      </div>
      <div className="section-content-row">
        <ValueTile
          title="KBA visits"
          mainValue={currentDigitalSpendVisitsData?.kbas}
          yoyValue={yoyDigitalSpendVisitsData?.perc_kbas}
          popValue={popDigitalSpendVisitsData?.perc_kbas}
          isLoading={digitalSpendVisitsLoading}
          tooltipText="KBA or Key Buying Actions visits are the count of visits including at least one key buying action as Model detail compare, Saved searches views, Compare tools views and Dealer search. "
        />
        <ValueTile
          title="Website visits to KBA visits"
          mainValue={currentDigitalSpendVisitsData?.visits_to_kbas}
          yoyValue={yoyDigitalSpendVisitsData?.pts_visits_to_kbas}
          popValue={popDigitalSpendVisitsData?.pts_visits_to_kbas}
          isPercentage={true}
          isLoading={digitalSpendVisitsLoading}
          tooltipText="Percentage of visits that have performed a KBA."
        />
        <ValueTile
          title="Cost per KBA visits"
          mainValue={currentDigitalSpendVisitsData?.cost_per_kba}
          yoyValue={yoyDigitalSpendVisitsData?.perc_cost_per_kba}
          popValue={popDigitalSpendVisitsData?.perc_cost_per_kba}
          isLoading={digitalSpendVisitsLoading}
          showCurrency={true}
          ratingInverse={true}
          ratingArrowInverse={true}
          tooltipText="Cost for each KBA visit based on the Media FMI allocated through digital channels"
        />
      </div>
      <div className="section-content-row">
        <ValueTile
          title="Leads visits"
          mainValue={currentDigitalSpendVisitsData?.leads_visits}
          yoyValue={yoyDigitalSpendVisitsData?.perc_leads_visits}
          popValue={popDigitalSpendVisitsData?.perc_leads_visits}
          isLoading={digitalSpendVisitsLoading}
          tooltipText="Lead visits are the count of visits including at least one digital lead as Enquire, Test drive, Request a video and Reserve."
        />
        <ValueTile
          title="Website visits to Leads visits"
          mainValue={currentDigitalSpendVisitsData?.visits_to_leads}
          yoyValue={yoyDigitalSpendVisitsData?.pts_visits_to_leads}
          popValue={popDigitalSpendVisitsData?.pts_visits_to_leads}
          isPercentage={true}
          isLoading={digitalSpendVisitsLoading}
          tooltipText="Percentage of website visits that have performed a lead."
        />
        <ValueTile
          title="Cost per Leads visits"
          mainValue={currentDigitalSpendVisitsData?.cost_per_lead}
          yoyValue={yoyDigitalSpendVisitsData?.perc_cost_per_lead}
          popValue={popDigitalSpendVisitsData?.perc_cost_per_lead}
          isLoading={digitalSpendVisitsLoading}
          showCurrency={true}
          ratingInverse={true}
          ratingArrowInverse={true}
          tooltipText="Cost for each lead visits based on the Media FMI allocated through digital channels"
        />
      </div>
    </div>
  );
};

export default OnlineJourneyTiles;
