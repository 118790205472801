import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchProductPerformanceFilters, setParameters, setSpecificParameter } from "../../actions";
import {
  DEFAULT_DATE_RANGE,
  DEFAULT_PARAMETER_VALUES,
  DEFAULT_PRODUCT_PERFORMANCE_PARAMETERS,
  MIN_SELECTABLE_DATE,
  OCE_DATE_FILTER_OPTIONS,
} from "../../constants";
import { FilterContext } from "../../context";
import { isCustomDate, isDigitalPerformanceDate } from "../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { currentDefaultFiltersSet, defaultFiltersSet, getGranularityOptions } from "../../utils/utilityFunctions";
import { ErrorMsg } from "../AppMessages";
import { FiltersLayout } from "../Layouts";
import { DateFilter, DefaultFilter } from "./common";
import { closeFilterOptions, closeMarketOptions } from "./subs/helpers";

const ProductPerformanceFilters = withRouter((props: RouteComponentProps): JSX.Element => {
  const { history } = props;
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const {
    product: productList,
    market: marketList,
    brand: brandList,
  } = useSelector((state: RootStateOrAny) => state.filters.product_performance_filters);

  const dataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.digital_performance);
  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.parameters.date_range);
  const productParamValue = useSelector((state: RootStateOrAny) => state.parameters.product);
  const marketParamValue = useSelector((state: RootStateOrAny) => state.parameters.market);
  const granularityParamValue = useSelector((state: RootStateOrAny) => state.parameters.granularity);
  const brandParamValue = useSelector((state: RootStateOrAny) => state.parameters.brand);

  //Sets the initial start and end selectable date to be 1st April 2019 and the latest data refresh | today() - 1 (yesterday) if the last data refresh is not yet defined
  const [marketDataAvailability, setMarketDataAvailability] = useState<{ start: string; end: string }>({
    start: MIN_SELECTABLE_DATE,
    end: dataRefresh || moment().subtract(1, "days").format("DD/MM/YYYY"),
  });

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("Product Performance Dashboard");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_PRODUCT_PERFORMANCE_PARAMETERS));
      currentDefaultFiltersSet("Product Performance Dashboard");
    }
  }, []);

  useEffect(() => {
    dispatch(fetchProductPerformanceFilters());
  }, [history.location.search]);

  // Set default date range values
  useEffect(() => {
    const validDate = isDigitalPerformanceDate(dateRangeParamValue) || isCustomDate(dateRangeParamValue);

    if (!dateRangeParamValue || !validDate) dispatch(setSpecificParameter("date_range", DEFAULT_DATE_RANGE));
  }, [dateRangeParamValue, dispatch]);

  // Set default product
  useEffect(() => {
    if (productList && productList.length > 0) {
      !productList.includes(productParamValue) && dispatch(setSpecificParameter("product", productList[0]));
    }
  }, [productParamValue, productList, dispatch]);

  // Set default product
  useEffect(() => {
    if (brandList && brandList.length > 0) {
      !brandList.includes(brandParamValue) && dispatch(setSpecificParameter("brand", brandList[0]));
    }
  }, [brandParamValue, brandList, dispatch]);

  // Set default market
  useEffect(() => {
    if (marketList && marketList.length > 0) {
      !marketList.includes(marketParamValue) && dispatch(setSpecificParameter("market", marketList[0]));
    }
  }, [marketParamValue, marketList, dispatch]);

  const granularityOptions = useMemo(() => {
    if (OCE_DATE_FILTER_OPTIONS.includes(dateRangeParamValue) || dateRangeParamValue.includes("-")) {
      return getGranularityOptions(dateRangeParamValue);
    }
    return [];
  }, [dateRangeParamValue]);

  // Set default granularity
  useEffect(() => {
    !granularityOptions.includes(granularityParamValue) && dispatch(setSpecificParameter("granularity", "weekly"));
  }, [granularityParamValue, dispatch]);

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));
    }
    eventTracking(MixpanelEvents.filter_change, {
      filter: filterName,
      value: optionValue,
      dashboard: "Product Performance",
      page: "Product Performance",
    });
    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);
  };

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  const onRenderOptions = ({ list, filter }: { list: string[]; filter: string }) => {
    return (
      <>
        {list?.map((item: string) => {
          return (
            <li key={item} data-filter={filter} onClick={handleFilterOptionClick} data-value={item}>
              {item}
            </li>
          );
        })}
      </>
    );
  };

  const resetFilters = useCallback(() => {
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(DEFAULT_PARAMETER_VALUES)) {
      params.set(key, value);
    }

    history.push({ search: `?${params.toString()}` });
    window.location.reload();
  }, [history]);

  const handleGranularityRadioChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = evt;

    dispatch(setSpecificParameter("granularity", value));
  }, []);

  // Set params
  useEffect(() => {
    const params = new URLSearchParams();

    params.set("date_range", dateRangeParamValue);
    params.set("brand", brandParamValue);
    params.set("product", productParamValue);
    params.set("market", marketParamValue);
    params.set("granularity", granularityParamValue);
    history.push({ search: `?${params.toString()}` });
  }, [dateRangeParamValue, productParamValue, marketParamValue, granularityParamValue, brandParamValue]);

  return (
    <FiltersLayout resetFilters={resetFilters}>
      <>
        {/*Date Range*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DateFilter
            onFilterClick={showFilterOptionsFn}
            renderOptions={onRenderOptions}
            value={dateRangeParamValue}
            maxDate={dataRefresh}
            availableDates={marketDataAvailability}
            dateOptions={OCE_DATE_FILTER_OPTIONS}
            page="Product Performance"
          />
        </ErrorBoundary>

        {/*Brand*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={brandList}
            filterName={"brand"}
            filterLabel={"BRAND"}
            filterValue={brandParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Product*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={productList}
            filterName={"product"}
            filterLabel={"PRODUCT"}
            filterValue={productParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Market*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={marketList}
            filterName={"market"}
            filterLabel={"MARKET"}
            filterValue={marketParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/* <GranularityOptions
          onHandleRadioClick={handleGranularityRadioChange}
          granularity={granularityParamValue}
          name={"product_performance_granularities"}
          dateValue={dateRangeParamValue}
          granularitiesList={granularityOptions}
          manualDisable={true}
        /> */}
      </>
    </FiltersLayout>
  );
});

export default ProductPerformanceFilters;
