export const maxValue = (data: any) => {
  let max = -Infinity;
  let min = Infinity;
  if (data?.length)
    data?.map((val: { amount: number }) => {
      if (max < val.amount) max = val.amount;
      if (min > val.amount) min = val.amount;
    });
  return { max: max, min: min };
};
