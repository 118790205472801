import { CategoryAxis, ColumnSeries, ILineSeriesDataFields, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component, ReactNode } from "react";
import WebFont from "webfontloader";
import { BLACK, CYAN, TURQUOISE, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { configureCategoryAxis, configureValueAxis } from "../helpers/configurations";
import { formatValueAxisUnits } from "../helpers/helpers";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  theme: ThemeInt;
  isLoading: boolean;
}

export class LeadsFunnelChart extends Component<Props> {
  chartId = "leadsFunnelChart";
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { theme, data } = this.props;

    this.chart = create(this.chartId, XYChart);
    this.chart.data = data;
    oceChartConfiguration(this.chart);
    this.chart.colors.list = [color(CYAN), theme === "light" ? color(BLACK) : color(WHITE)];
    this.chart.paddingBottom = 10;

    // Create Axes
    const categoryAxis = this.chart.yAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;
    categoryAxis.renderer.minGridDistance = 10;

    const valueAxis = this.chart.xAxes.push(new ValueAxis());
    valueAxis.logarithmic = true;
    valueAxis.treatZeroAs = 1;
    configureValueAxis(valueAxis, theme);
    const label = valueAxis.renderer.labels.template;
    label.wrap = false;
    label.truncate = false;
    valueAxis.renderer.labels.template.rotation = -60;
    valueAxis.renderer.labels.template.paddingLeft = -10;
    valueAxis.renderer.labels.template.paddingTop = -10;
    valueAxis.renderer.grid.template.strokeWidth = 0;
    formatValueAxisUnits(valueAxis);

    const dataFields: Record<string, string> = {
      visits: "visits",
      categoryY: "name",
    };

    this.createSeries("visits", "visits", CYAN, dataFields);
  }

  createSeries(field: string, name: string, seriesColor: string, dataFields: Record<string, string>) {
    const series = this.chart.series.push(new ColumnSeries());
    const valueX = dataFields[field];
    series.dataFields = {
      valueX: valueX,
      ...dataFields,
    } as ILineSeriesDataFields;
    series.name = name;
    series.columns.template.tooltipText = "{name}: [bold]{valueX}[/]";
    series.columns.template.height = percent(80);
    series.sequencedInterpolation = true;
    //@ts-ignore
    series.type = "type";
    if (series.tooltip) {
      series.tooltip.getFillFromObject = false;
      series.tooltip.autoTextColor = false;
      series.tooltip.background.fill = color(WHITE);
      series.tooltip.label.fill = color(BLACK);
    }

    series.columns.template.adapter.add("fill", function (fill, target) {
      //@ts-ignore
      if (target.dataItem && target.dataItem?.dataContext.type === "green") {
        return color(TURQUOISE);
      } else {
        return fill;
      }
    });
    series.columns.template.adapter.add("stroke", function (stroke, target) {
      //@ts-ignore
      if (target.dataItem && target.dataItem?.dataContext.type === "green") {
        return color(TURQUOISE);
      } else {
        return stroke;
      }
    });

    series.columns.template.adapter.add("tooltipText", (value, target) => {
      return `[bold; font-size: var(--regular_font_size);] [/ font-size: var(--regular_font_size);] {visits.formatNumber("#,###")}`;
    });
  }

  render(): ReactNode {
    return (
      <div className={"chartWrapper"}>
        <div id={"leadsFunnelChart"} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default LeadsFunnelChart;
