import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { getEntryPointsByVisits } from "../../../../api/executiveSummaryProductPerformanceApi";
import ExecutiveSummaryCategoryBarChart from "../../../../components/Charts/ExecutiveSummary/ExecutiveSummaryCategoryBarChart";
import { ThemeContext } from "../../../../context";

const EntryPointByVisits = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["entryPointsByVisits", history.location.search],
    queryFn: getEntryPointsByVisits,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  return (
    <div className="tile">
      <div className="tile-header">
        <div className="title-container">
          <div className="title">
            <p>Entry points by visits</p>
            <span className="sub-text">
              (Please note that entry points visits numbers are currently being audit and will updated in the dashboard shortly)
            </span>
          </div>
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title={
              "Number of website visits that started with the product page as landing page. The result sums up all the markets within the selected period."
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
      </div>
      <ExecutiveSummaryCategoryBarChart
        chartName="entryPointByVisitsChart"
        data={data}
        isLoading={isFetching}
        theme={theme}
        categoryField="product"
        showDataLabel={true}
      />
    </div>
  );
});

export default EntryPointByVisits;
