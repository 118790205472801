import { ErrorBoundary } from "@sentry/react";
import React, { useMemo, useState } from "react";
import { ErrorMsg } from "../../../../../components/AppMessages";
import { SocialRow } from "../../../../../constants/interface/ariya";
import { AriyaDefaultTable } from "../../../Components";

interface Props {
  tableData: any;
  showBreakdown: boolean;
}

export const SourceOfVisitors = (props: Props) => {
  const { tableData, showBreakdown } = props;
  const [hiddenColumns] = useState([""]);

  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(
    () => [
      {
        id: "sources",
        Header: "Source",
        accessor: "name",
        defaultCanSort: false,
        sortDescFirst: true,
        sortType: "alphanumericIgnoreCase",
      },
      {
        Header: "% Share",
        accessor: `share`,
        id: "share",
        defaultCanSort: false,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: function renderVisitsType(props1: { value: string }) {
          const { value } = props1;

          return <span className="share">{value}%</span>;
        },
      },
    ],
    []
  );

  function renderRowSubComponent(props: { row: { original: { breakdown: Array<SocialRow & { breakdown?: Array<SocialRow> }> } } }) {
    const {
      row: {
        original: { breakdown: breakdownData },
      },
    } = props;

    return (
      <ErrorBoundary fallback={<ErrorMsg />}>
        {breakdownData?.length ? (
          <AriyaDefaultTable
            columns={columns}
            data={breakdownData}
            id={"social_networks_child"}
            //@ts-ignore
            isChildComponent={true}
            initialSortColId={{ id: "share", desc: true }}
            hiddenCols={hiddenColumns}
            sortable={false}
          />
        ) : (
          <></>
        )}
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      {data.length ? (
        <AriyaDefaultTable
          columns={columns}
          data={data}
          id={"social_networks"}
          data-test-id={"social_networks"}
          // @ts-ignore
          renderRowSubComponent={renderRowSubComponent}
          isChildComponent={false}
          initialSortColId={{ id: "share", desc: true }}
          hiddenCols={hiddenColumns}
          showChildRow={showBreakdown}
          sortable={false}
        />
      ) : (
        <></>
      )}
    </ErrorBoundary>
  );
};
