import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getExecutiveSummaryDetailedComparisons } from "../../../../api/executiveSummaryWebsiteApi";
import DisByMarketCurrentTable from "./DisByMarketCurrentTable";
import DisByMarketTable from "./DisByMarketTable";

const DetailedComparisons = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();

  const { lead_group: leadGroupParam } = useSelector((state: RootStateOrAny) => state.parameters);
  const { leads_source: leadsSources } = useSelector((state: RootStateOrAny) => state.filters.executive_summary_filters);

  const {
    data: {
      YoY: yoyDetailedComparison,
      Current: currentDetailedComparison,
      current_end_date,
      current_start_date,
      yoy_start_date,
      yoy_end_date,
    },
    refetch,
    isFetching: isLoading,
  } = useQuery({
    queryKey: ["detailedComparisonData"],
    queryFn: getExecutiveSummaryDetailedComparisons,
    enabled: false,
    initialData: {},
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  const showWebsiteVisits = useMemo(() => {
    if (leadsSources && leadsSources.length > 0 && leadGroupParam) {
      const leadGroupsData = leadsSources.flatMap((leadSource: any) => leadSource.lead_group);
      return (
        leadGroupParam.split(",").length == leadGroupsData.length || leadGroupParam == "All" || leadGroupParam.includes("Online - OEM")
      );
    }
    return true;
  }, [leadsSources, leadGroupParam]);

  const YoYDateLabel = useMemo(() => {
    if (yoy_start_date && yoy_end_date) {
      const startDate = moment(yoy_start_date, "YYYY-MM-DD").format("MMM YYYY");
      const endDate = moment(yoy_end_date, "YYYY-MM-DD").format("MMM YYYY");
      return `${startDate} - ${endDate}`;
    }
    return "";
  }, [yoy_start_date, yoy_end_date]);

  const currentDateLabel = useMemo(() => {
    if (current_start_date && current_end_date) {
      const startDate = moment(current_start_date, "YYYY-MM-DD").format("MMM YYYY");
      const endDate = moment(current_end_date, "YYYY-MM-DD").format("MMM YYYY");
      return `${startDate} - ${endDate}`;
    }
    return "";
  }, [current_start_date, current_end_date]);

  return (
    <>
      <DisByMarketTable
        data={yoyDetailedComparison ?? []}
        showWebsiteVisits={showWebsiteVisits}
        isLoading={isLoading}
        dateLabel={YoYDateLabel}
      />
      <DisByMarketCurrentTable
        data={currentDetailedComparison ?? []}
        showWebsiteVisits={showWebsiteVisits}
        isLoading={isLoading}
        dateLabel={currentDateLabel}
      />
    </>
  );
});

export default DetailedComparisons;
