import { AnyAction } from "redux";
import { put, retry, takeLatest } from "redux-saga/effects";
import {
  FETCH_DIFFERENCE_BY_MODEL,
  FETCH_DIS_BY_MODEL_AND_MONTH,
  FETCH_MSRP_MODEL_PERFORMANCE,
  FETCH_MSRP_OVER_TIME_BY_MODEL,
  FETCH_MSRP_PROFILE_BY_MONTH,
  FETCH_MSRP_SUMMARY,
  FETCH_MSRP_VME_DIFFERENCE,
  FETCH_SALES_BY_MODEL,
  FETCH_TOP_FIVE_MODELS,
  FETCH_TOTAL_ADDED_VALUE,
  FETCH_TOTAL_VALUE_DIS_BY_MONTH,
  FETCH_VME_DIFFERENCE_BY_MODEL,
  FETCH_VME_DISCOUNT_OVER_TIME_BY_MODEL,
} from "../actions/actionTypes/msrpActionTypes";
import { setLoading } from "../actions/loadingActions";
import {
  setDifferenceByModel,
  setDisByModelAndMonth,
  setMsrpModelPerformance,
  setMsrpOverTimeByModel,
  setMsrpProfileByMonth,
  setMsrpSummary,
  setMsrpVmeDifference,
  setSalesByModel,
  setTopFiveModels,
  setTotalAddedValue,
  setTotalValueDisByMonth,
  setVmeDifferenceByModel,
  setVmeDiscountOverTimeByModel,
} from "../actions/msrpActions";
import {
  getDifferenceByModel,
  getDisByModelAndMonth,
  getMsrpModelPerformance,
  getMsrpOverTimeByModel,
  getMsrpProfileByMonth,
  getMsrpSummary,
  getMsrpVmeDifference,
  getSalesByModel,
  getTopFiveModels,
  getTotalAddedValue,
  getTotalValueDisByMonth,
  getVmeDifferenceByModel,
  getVmeDiscountOverTimeByModel,
} from "../api/msrpApi";
import { secondsDelay } from "../api/utils";

//WATCHER SAGAS
export function* watchFetchMsrpSummarySaga() {
  yield takeLatest(FETCH_MSRP_SUMMARY, fetchMsrpSummarySaga);
}

export function* watchFetchSalesByModelSaga() {
  yield takeLatest(FETCH_SALES_BY_MODEL, fetchSalesByModelSaga);
}

export function* watchFetchDifferenceByModelSaga() {
  yield takeLatest(FETCH_DIFFERENCE_BY_MODEL, fetchDifferenceByModelSaga);
}

export function* watchFetchVmeDifferenceByModelSaga() {
  yield takeLatest(FETCH_VME_DIFFERENCE_BY_MODEL, fetchVmeDifferenceByModelSaga);
}

export function* watchFetchMsrpVmeDifferenceSaga() {
  yield takeLatest(FETCH_MSRP_VME_DIFFERENCE, fetchMsrpVmeDifferenceSaga);
}

export function* watchFetchMsrpProfileByMonthSaga() {
  yield takeLatest(FETCH_MSRP_PROFILE_BY_MONTH, fetchMsrpProfileByMonthSaga);
}

export function* watchFetchMsrpModelPerformanceSaga() {
  yield takeLatest(FETCH_MSRP_MODEL_PERFORMANCE, fetchMsrpModelPerformanceSaga);
}

export function* watchFetchMsrpOverTimeByModel() {
  yield takeLatest(FETCH_MSRP_OVER_TIME_BY_MODEL, fetchMsrpOverTimeByModelSaga);
}

export function* watchFetchVmeDiscountOverTimeByModel() {
  yield takeLatest(FETCH_VME_DISCOUNT_OVER_TIME_BY_MODEL, fetchVmeDiscountOverTimeByModelSaga);
}

export function* watchFetchTotalValueDisByMonth() {
  yield takeLatest(FETCH_TOTAL_VALUE_DIS_BY_MONTH, fetchTotalValueDisByMonthSaga);
}

export function* watchFetchDisByModelAndMonth() {
  yield takeLatest(FETCH_DIS_BY_MODEL_AND_MONTH, fetchDisByModelAndMonthSaga);
}

export function* watchFetchTotalAddedValue() {
  yield takeLatest(FETCH_TOTAL_ADDED_VALUE, fetchTotalAddedValueSaga);
}

export function* watchFetchTopFiveModels() {
  yield takeLatest(FETCH_TOP_FIVE_MODELS, fetchTopFiveModelsSaga);
}
//WORKER SAGAS
export function* fetchMsrpSummarySaga() {
  try {
    yield put(setLoading("msrp_summary", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getMsrpSummary);
    if (!("error" in response)) {
      yield put(setMsrpSummary(response));
    }
  } catch (err) {
    console.log("failed to fetch /msrp/summary");
    console.log(err);
  } finally {
    yield put(setLoading("msrp_summary", false));
  }
}

export function* fetchSalesByModelSaga() {
  try {
    yield put(setLoading("sales_by_model", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getSalesByModel);
    if (!("error" in response)) {
      yield put(setSalesByModel(response));
    }
  } catch (err) {
    console.log("failed to fetch /msrp/sales-by-model");
    console.log(err);
  } finally {
    yield put(setLoading("sales_by_model", false));
  }
}

export function* fetchDifferenceByModelSaga() {
  try {
    yield put(setLoading("difference_by_model", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getDifferenceByModel);
    if (!("error" in response)) {
      yield put(setDifferenceByModel(response));
    }
  } catch (err) {
    console.log("failed to fetch /msrp/difference-by-model");
    console.log(err);
  } finally {
    yield put(setLoading("difference_by_model", false));
  }
}

export function* fetchVmeDifferenceByModelSaga() {
  try {
    yield put(setLoading("vme_difference_by_model", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getVmeDifferenceByModel);
    if (!("error" in response)) {
      yield put(setVmeDifferenceByModel(response));
    }
  } catch (err) {
    console.log("failed to fetch /msrp/vme-difference-by-model");
    console.log(err);
  } finally {
    yield put(setLoading("vme_difference_by_model", false));
  }
}

export function* fetchTotalValueDisByMonthSaga() {
  try {
    yield put(setLoading("total_value_dis_by_month", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getTotalValueDisByMonth);
    if (!("error" in response)) {
      yield put(setTotalValueDisByMonth(response));
    }
  } catch (err) {
    console.log("failed to fetch /msrp/total-value-dis-by-month");
    console.log(err);
  } finally {
    yield put(setLoading("total_value_dis_by_month", false));
  }
}

export function* fetchMsrpVmeDifferenceSaga() {
  try {
    yield put(setLoading("msrp_vme_difference", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getMsrpVmeDifference);
    if (!("error" in response)) {
      yield put(setMsrpVmeDifference(response));
    }
  } catch (err) {
    console.log("failed to fetch /msrp/vme-difference");
    console.log(err);
  } finally {
    yield put(setLoading("msrp_vme_difference", false));
  }
}

export function* fetchMsrpProfileByMonthSaga() {
  try {
    yield put(setLoading("msrp_profile_by_month", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getMsrpProfileByMonth);
    if (!("error" in response)) {
      yield put(setMsrpProfileByMonth(response));
    }
  } catch (err) {
    console.log("failed to fetch /msrp/profile-by-month");
    console.log(err);
  } finally {
    yield put(setLoading("msrp_profile_by_month", false));
  }
}

export function* fetchMsrpModelPerformanceSaga({ payload: models }: AnyAction) {
  try {
    yield put(setLoading("msrp_model_performance", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getMsrpModelPerformance, models);
    if (!("error" in response)) {
      yield put(setMsrpModelPerformance(response));
    }
  } catch (err) {
    console.log("failed to fetch /msrp/table-by-model");
    console.log(err);
  } finally {
    yield put(setLoading("msrp_model_performance", false));
  }
}

export function* fetchMsrpOverTimeByModelSaga({ payload: models }: AnyAction) {
  try {
    yield put(setLoading("msrp_over_time_by_model", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getMsrpOverTimeByModel, models);
    if (!("error" in response)) {
      yield put(setMsrpOverTimeByModel(response));
    }
  } catch (err) {
    console.log("failed to fetch /msrp/over-time-by-model");
    console.log(err);
  } finally {
    yield put(setLoading("msrp_over_time_by_model", false));
  }
}

export function* fetchVmeDiscountOverTimeByModelSaga({ payload: models }: AnyAction) {
  try {
    yield put(setLoading("vme_discount_over_time_by_model", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getVmeDiscountOverTimeByModel, models);
    if (!("error" in response)) {
      yield put(setVmeDiscountOverTimeByModel(response));
    }
  } catch (err) {
    console.log("failed to fetch /msrp/discount-over-time-by-model");
    console.log(err);
  } finally {
    yield put(setLoading("vme_discount_over_time_by_model", false));
  }
}

export function* fetchTotalAddedValueSaga() {
  try {
    yield put(setLoading("total_added_value", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getTotalAddedValue);
    if (!("error" in response)) {
      yield put(setTotalAddedValue(response));
    }
  } catch (err) {
    console.log("failed to fetch /msrp/total-added-value");
    console.log(err);
  } finally {
    yield put(setLoading("total_added_value", false));
  }
}

export function* fetchDisByModelAndMonthSaga({ payload: models }: AnyAction) {
  try {
    yield put(setLoading("dis_by_model_and_month", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getDisByModelAndMonth);
    if (!("error" in response)) {
      yield put(setDisByModelAndMonth(response));
    }
  } catch (err) {
    console.log("failed to fetch /msrp/dis-by-model-and-month");
    console.log(err);
  } finally {
    yield put(setLoading("dis_by_model_and_month", false));
  }
}

export function* fetchTopFiveModelsSaga() {
  try {
    yield put(setLoading("top_five_models", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getTopFiveModels);
    if (!("error" in response)) {
      yield put(setTopFiveModels(response));
    }
  } catch (err) {
    console.log("failed to fetch /msrp/top-five-models");
    console.log(err);
  } finally {
    yield put(setLoading("top_five_models", false));
  }
}
