import moment from "moment";

/**
 * Function to sort and array of months in ascending order
 */
export const sortMonthYear = (arr: Array<string>) => {
  // string of
  return arr.sort(function (a, b) {
    const momentA = moment(a, "MMMM YYYY");
    const momentB = moment(b, "MMMM YYYY");
    if (momentA > momentB) return 1;
    else if (momentA < momentB) return -1;
    else return 0;
  });
};
