import { AnyAction } from "redux";
import { put, retry, takeLatest } from "redux-saga/effects";
import {
  FETCH_INFLUENCE_OF_SHOPPING_TOOLS,
  FETCH_NON_TDS_LEADS,
  FETCH_ORDERS,
  FETCH_TDS_LEADS,
  FETCH_VISITS_DROP_OFF,
} from "../actions/actionTypes/ecommerceTypes";
import { setInfluenceOfShoppingTools, setNonTdsLeads, setOrders, setTdsLeads, setVisitsDropOff } from "../actions/ecommerceAtions";
import { setLoading } from "../actions/loadingActions";
import { getInfluenceOfShoppingTools, getNonTdsLeads, getOrders, getTdsLeads, getVisitsDropOff } from "../api/ecommerce";
import { secondsDelay } from "../api/utils";

// Watcher sagas
export function* watchFetchVisitsDropOffSaga() {
  yield takeLatest(FETCH_VISITS_DROP_OFF, fetchVisitsDropOff);
}

export function* watchFetchInfluenceOfShoppingToolsSaga() {
  yield takeLatest(FETCH_INFLUENCE_OF_SHOPPING_TOOLS, fetchInfluenceOfShoppingTools);
}

export function* watchFetchOrdersSaga() {
  yield takeLatest(FETCH_ORDERS, fetchOrders);
}

export function* watchFetchTdsLeadsSaga() {
  yield takeLatest(FETCH_TDS_LEADS, fetchTdsLeads);
}

export function* watchFetchNonTdsLeadsSaga() {
  yield takeLatest(FETCH_NON_TDS_LEADS, fetchNonTdsLeads);
}

// Worker sagas
export function* fetchVisitsDropOff({ payload: segment }: AnyAction) {
  try {
    yield put(setLoading("visits_drop_off", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getVisitsDropOff, segment);
    if (!("error" in response)) {
      yield put(setVisitsDropOff(response));
    }
  } catch (err) {
    console.log("failed to fetch /ecom/visits-drop-off");
  } finally {
    yield put(setLoading("visits_drop_off", false));
  }
}

export function* fetchInfluenceOfShoppingTools() {
  try {
    yield put(setLoading("influence_of_shopping_tools", true));

    const response: Record<string, any> = yield retry(4, secondsDelay, getInfluenceOfShoppingTools);
    if (!("error" in response)) {
      yield put(setInfluenceOfShoppingTools(response));
    }
  } catch (err) {
    console.log("failed to fetch /ecom/visits-drop-off");
  } finally {
    yield put(setLoading("influence_of_shopping_tools", false));
  }
}

export function* fetchOrders() {
  try {
    yield put(setLoading("orders", true));

    const response: Record<string, any> = yield retry(4, secondsDelay, getOrders);
    if (!("error" in response)) {
      yield put(setOrders(response));
    }
  } catch (err) {
    console.log("failed to fetch /ecom/orders");
  } finally {
    yield put(setLoading("orders", false));
  }
}

export function* fetchTdsLeads() {
  try {
    yield put(setLoading("tds_leads", true));

    const response: Record<string, any> = yield retry(4, secondsDelay, getTdsLeads);
    if (!("error" in response)) {
      yield put(setTdsLeads(response));
    }
  } catch (err) {
    console.log("failed to fetch /ecom/tds-leads");
  } finally {
    yield put(setLoading("tds_leads", false));
  }
}

export function* fetchNonTdsLeads() {
  try {
    yield put(setLoading("tds_leads", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getNonTdsLeads);
    if (!("error" in response)) {
      yield put(setNonTdsLeads(response));
    }
  } catch (err) {
    console.log("failed to fetch /ecom/non-tds-leads");
  } finally {
    yield put(setLoading("tds_leads", false));
  }
}
