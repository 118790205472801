import React, { useCallback, useMemo, useState } from 'react'
import { MultiSelectFilter } from './MultiSelectFilter';
import { closeFilterOptions } from '../subs/helpers';
import { setSpecificParameter } from '../../../actions';
import { eventTracking, MixpanelEvents } from '../../../utils/userTracking';
import { useDispatch } from 'react-redux';

interface Props {
  onShowFilterOptions: (evt: React.MouseEvent<HTMLElement>) => void;
  options: any;
  parameterName: string;
  parameterValue: string;
  value: string;
  filterName: string;
  dashboard: string;
  page?: string;
}

const MultiSelectSearchFilter = (props: Props) => {
  const { onShowFilterOptions, value, options, parameterName, parameterValue, filterName, dashboard, page } = props;

  const dispatch = useDispatch()

  const [searchString, setSearchString] = useState<string>("");

  const filteredOptions = useMemo(() => {
    if (options?.length && options.length > 0 && searchString?.length) {
      return options.filter((option: string) => option.toLowerCase()?.includes(searchString?.toString()?.toLowerCase()));
    }
    return options;
  }, [options, searchString]);

  const handleFilterSubmission = useCallback(() => {
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );

    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = options?.length === selectedValues?.length ? "All" : selectedValues?.join(",");

    closeFilterOptions();
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, { filter: parameterName, value: filterValue, dashboard: dashboard, page: page });
  }, [options]);

  return (
    <div className="filter" id={`${parameterName}_filter_div`}>
      <label className="filter_header" htmlFor={`${parameterName}_filter`}>
        {filterName}
      </label>

      <div className="input_arrow" onClick={onShowFilterOptions} data-options-ul={`${parameterName}_ul`}>
        <input
          readOnly
          type="text"
          id={`${parameterName}_filter`}
          className={"filter_input"}
          // data-test-id={`${parentKey}`}
          value={value}
        />
        <span className="arrow_down" />
      </div>

      <ul className="filter_options" id={`${parameterName}_ul`} data-test-id="region_list">
        <div className="filter_search_bar" id="">
          <input value={searchString} placeholder={`Search ${parameterName.split("_").join(" ")}s`} type="search" onChange={(e) => setSearchString(e.target.value)} />
        </div>
        {filteredOptions?.length > 0 ? (
          <MultiSelectFilter
            parentKey={parameterName}
            showOptionsOnly={true}
            // @ts-ignore
            filterList={options}
            value={value}
            parameterName={parameterName}
            parameterValue={parameterValue}
            onShowFilterOptions={onShowFilterOptions}
            handleFilterSubmission={handleFilterSubmission}
            searchString={searchString}
          />
        ) : (
          <li className="no-result-option">No result found</li>
        )}
      </ul>
    </div>
  )
}

export default MultiSelectSearchFilter