export const PAGE_NAME_URL_MAPPING: { [index: string]: string } = {
  "Online CE performance": "digital_performance",
  "SEO reporting": "seo",
  "CPO Online CE performance": "cpo_digital_performance",
};

export const REGION_MARKET_CODE: { [index: string]: string } = {
  Europe: "EU",
  Japan: "JP",
  "North America": "NA",
  "United States": "US",
  Mexico: "MX",
};
