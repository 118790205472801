import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { ALL_OPTION_NO_SPACE } from "../../../constants";
import { Channel } from "../../../constants/interface/admin/common";
import { FormFields } from "../Dialogs/AnnotationDialog";

interface Props {
  control: Control<FormFields>;
  channels: Channel[];
  filteredChannelsList: string[];
  errors: FieldErrors | undefined;
}

const ChannelsField = (props: Props) => {
  const { control, channels, filteredChannelsList, errors } = props;
  return (
    <Controller
      name="channels"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          options={channels.filter(
            (channel: { channel: string }) => filteredChannelsList.includes(channel.channel) || channel.channel == ALL_OPTION_NO_SPACE
          )}
          disableClearable
          getOptionLabel={(option: Channel) => option.channel}
          getOptionSelected={(option, value) => option.channel == value.channel}
          value={value[0]}
          onChange={(event, selectedValue) => {
            onChange([selectedValue]);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Channel"
              placeholder="Channel"
              error={errors?.channels ? true : false}
              helperText={errors?.channels?.message}
            />
          )}
        />
      )}
      rules={{
        required: {
          value: true,
          message: "Please select a channel",
        },
      }}
    />
  );
};

export default ChannelsField;
