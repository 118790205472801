import {
  SET_AVERAGE_MODEL,
  SET_CUSTOMER_INTENTIONSVSSALE_POWER,
  SET_CUSTOMER_INTENTION_BY_COLOR,
  SET_CUSTOMER_INTENTION_VS_SALES,
  SET_GRADE_MIX,
  SET_LEADS_FUNNEL,
  SET_LEAD_TO_TEST_DRIVE_REQUESTS,
  SET_MARKEITNG_ACTIVITY,
  SET_MEDIA_SPEND,
  SET_MEDIA_SPEND_TOTALS,
  SET_NIM_CUSTOMER_INTENTION_VS_SALES,
  SET_NIM_GRADES,
  SET_NIM_SALES_BY_TREND,
  SET_PMO_RESPONSE_ERROR,
  SET_PRICE_VOLUME_SALES_DATA,
  SET_PRICE_VOLUME_TREND_DATA,
  SET_REGIONAL_COLOR_TREND_DATA,
  SET_RETAIL_SALES,
  SET_SALES_BY_TREND_DATA,
  SET_SEARCH_INTEREST_VS_FMI_DATA,
  SET_SOV_DATA,
  SET_TEST_DRIVE_REQUESTS,
  SET_TOTALS_VISITS_DATA,
  SET_TOTAL_LEADS,
  SET_WEB_VISITS_BY_CHANNEL,
} from "../actions/actionTypes";

const initialState = {
  customer_intention_vs_sales: [],
  price_volume_sales: {},
  search_interest_vs_fmi: {},
  media_spend: {},
  media_spend_totals: {},
  visits_totals: {},
  total_leads: {},
  leads_funnel: {},
  web_visits_by_channel: [],
  lead_to_test_drive_requests: {},
  test_drive_requests: {},
  retail_sales: [],
  sales_by_trend: {},
  price_volume_trend: [],
  grade_mix: [],
  marketing_activity: {},
  sales_color: {},
  customer_intention: {},
  sov_data: {},
  power_customer: [],
  response_error: [],
  nim_tech_customer_intention_vs_sales: [],
  nim_tech_sales_by_trend: {},
  nim_grades: [],
};

const ProductMarketingOptimizationReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_CUSTOMER_INTENTION_VS_SALES:
      return { ...state, customer_intention_vs_sales: action.payload };

    case SET_PRICE_VOLUME_SALES_DATA:
      return { ...state, price_volume_sales: action.payload };

    case SET_SEARCH_INTEREST_VS_FMI_DATA:
      return { ...state, search_interest_vs_fmi: action.payload };

    case SET_MEDIA_SPEND:
      return { ...state, media_spend: action.payload };

    case SET_MEDIA_SPEND_TOTALS:
      return { ...state, media_spend_totals: action.payload };

    case SET_TOTALS_VISITS_DATA:
      return { ...state, totals_visits: action.payload };

    case SET_TOTAL_LEADS:
      return { ...state, total_leads: action.payload };

    case SET_LEADS_FUNNEL:
      return { ...state, leads_funnel: action.payload };

    case SET_WEB_VISITS_BY_CHANNEL:
      return { ...state, web_visits_by_channel: action.payload };

    case SET_LEAD_TO_TEST_DRIVE_REQUESTS:
      return { ...state, lead_to_test_drive_requests: action.payload };

    case SET_TEST_DRIVE_REQUESTS:
      return { ...state, test_drive_requests: action.payload };

    case SET_RETAIL_SALES:
      return { ...state, retail_sales: action.payload };

    case SET_SALES_BY_TREND_DATA:
      return { ...state, sales_by_trend: action.payload };

    case SET_PRICE_VOLUME_TREND_DATA:
      return { ...state, price_volume_trend: action.payload };

    case SET_GRADE_MIX:
      return { ...state, grade_mix: action.payload };

    case SET_MARKEITNG_ACTIVITY:
      return { ...state, marketing_activity: action.payload };

    case SET_REGIONAL_COLOR_TREND_DATA:
      return { ...state, sales_color: action.payload };

    case SET_CUSTOMER_INTENTION_BY_COLOR:
      return { ...state, customer_intention: action.payload };
    case SET_SOV_DATA:
      return { ...state, sov_data: action.payload };

    case SET_AVERAGE_MODEL:
      return { ...state, average_models: action.payload };

    case SET_CUSTOMER_INTENTIONSVSSALE_POWER:
      return { ...state, power_customer: action.payload };

    case SET_PMO_RESPONSE_ERROR:
      return { ...state, response_error: action.payload };

    case SET_NIM_CUSTOMER_INTENTION_VS_SALES:
      return { ...state, nim_tech_customer_intention_vs_sales: action.payload };

    case SET_NIM_SALES_BY_TREND:
      return { ...state, nim_tech_sales_by_trend: action.payload };

    case SET_NIM_GRADES:
      return { ...state, nim_grades: action.payload };

    default:
      return state;
  }
};

export default ProductMarketingOptimizationReducer;
