import { ErrorBoundary } from "@sentry/react";
import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg, NoDataMsg } from "../AppMessages";
import LoadingEllipsis from "../Loading/LoadingEllipsis";
import DefaultTable from "./subs/DefaultTable";

interface Props {
  activeService: { paramType: string; value: string } | undefined;
  onHandleSpecificServiceClick: (evt: React.MouseEvent<HTMLElement>, paramCategory: string) => void;
}

const TopFlopServicesTable = (props: Props) => {
  const { onHandleSpecificServiceClick, activeService } = props;
  const data = useSelector((state: RootStateOrAny) => state.top_flop.services);
  const isLoading = useSelector((state: RootStateOrAny) => state.loading.top_flop_services);

  const columns = useMemo(
    () => [
      {
        id: "uid",
        Header: "UID",
        accessor: "uid",
        defaultCanSort: true,
        sortType: "alphanumericIgnoreCase",
        Cell: (cell: {
          value: string;
          row: { values: { service_name: string; service_type: string; service_trigger: string; service_group: string } };
        }) => {
          const { value, row } = cell;
          const isActiveClass = activeService
            ? activeService.value == row.values.service_name ||
              activeService.value == row.values.service_type ||
              activeService.value == row.values.service_trigger ||
              activeService.value == row.values.service_group ||
              activeService.value == value
              ? "active"
              : "inactive"
            : "";

          return (
            <span className={`${isActiveClass}`} data-value={value}>
              {value}
            </span>
          );
        },
      },
      {
        id: "service_name",
        Header: "Service name",
        accessor: "service",
        defaultCanSort: true,
        sortType: "alphanumericIgnoreCase",
        Cell: (cell: {
          value: string;
          row: { values: { service_type: string; service_group: string; service_trigger: string; uid: string } };
        }) => {
          const { value, row } = cell;
          const isActiveClass = activeService
            ? activeService.value == row.values.service_type ||
              activeService.value == row.values.service_group ||
              activeService.value == row.values.service_trigger ||
              activeService.value == row.values.uid ||
              activeService.value == value
              ? "active"
              : "inactive"
            : "";

          return (
            <span
              className={`specific_link ${isActiveClass}`}
              data-value={value}
              onClick={(e) => onHandleSpecificServiceClick(e, "specific_service")}
            >
              {value}
            </span>
          );
        },
      },
      {
        id: "service_group",
        Header: "Service group",
        accessor: "service_group",
        defaultCanSort: true,
        sortType: "alphanumericIgnoreCase",
        Cell: (cell: {
          value: string;
          row: { values: { service_name: string; service_type: string; service_trigger: string; uid: string } };
        }) => {
          const { value, row } = cell;
          const isActiveClass = activeService
            ? activeService.value == row.values.service_name ||
              activeService.value == row.values.service_type ||
              activeService.value == row.values.service_trigger ||
              activeService.value == row.values.uid ||
              activeService.value == value
              ? "active"
              : "inactive"
            : "";

          return (
            <span className={`${isActiveClass}`} data-value={value} onClick={(e) => onHandleSpecificServiceClick(e, "specific_group")}>
              {value}
            </span>
          );
        },
      },
      {
        id: "service_type",
        Header: "On/Off board",
        accessor: "service_type",
        defaultCanSort: true,
        sortType: "alphanumericIgnoreCase",
        Cell: (cell: {
          value: string;
          row: { values: { service_name: string; service_group: string; service_trigger: string; uid: string } };
        }) => {
          const { value, row } = cell;
          const isActiveClass = activeService
            ? activeService.value == row.values.service_name ||
              activeService.value == row.values.service_group ||
              activeService.value == row.values.service_trigger ||
              activeService.value == row.values.uid ||
              activeService.value == value
              ? "active"
              : "inactive"
            : "";

          return (
            <span className={`${isActiveClass}`} data-value={value} onClick={(e) => onHandleSpecificServiceClick(e, "specific_type")}>
              {value}
            </span>
          );
        },
      },
      {
        id: "service_trigger",
        Header: "Trigger",
        accessor: "service_trigger",
        defaultCanSort: true,
        sortType: "alphanumericIgnoreCase",
        Cell: (cell: {
          value: string;
          row: { values: { service_name: string; service_group: string; service_type: string; uid: string } };
        }) => {
          const { value, row } = cell;
          const isActiveClass = activeService
            ? activeService.value == row.values.service_name ||
              activeService.value == row.values.service_type ||
              activeService.value == row.values.service_group ||
              activeService.value == row.values.uid ||
              activeService.value == value
              ? "active"
              : "inactive"
            : "";

          return (
            <span
              style={{ marginRight: "10px" }}
              className={`${isActiveClass}`}
              data-value={value}
              onClick={(e) => onHandleSpecificServiceClick(e, "specific_trigger")}
            >
              {value}
            </span>
          );
        },
      },
      {
        id: "total_events",
        Header: "Total events",
        accessor: "events",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: (cell: {
          value: string;
          row: { values: { service_name: string; service_type: string; service_group: string; service_trigger: string; uid: string } };
        }) => {
          const { value, row } = cell;
          const isActiveClass = activeService
            ? activeService.value == row.values.service_name ||
              activeService.value == row.values.service_type ||
              activeService.value == row.values.service_group ||
              activeService.value == row.values.uid ||
              activeService.value == row.values.service_trigger
              ? ""
              : "inactive"
            : "";

          return (
            <span className={`${isActiveClass}`} data-value={value}>
              {value ? value?.toLocaleString() : "n/a"}
            </span>
          );
        },
      },
    ],
    [activeService]
  );

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <>
        <div className="all_services">
          <div className="header_div"></div>
          <h3 className="car_services_usage_overview" style={{ position: "sticky", top: "0" }}>
            Car services usage overview{" "}
          </h3>
          {data?.length > 0 ? (
            <>
              <DefaultTable
                columns={columns}
                data={data}
                id={"top_flop_services_table"}
                data-test-id={"top_flop_services_table"}
                isChildComponent={false}
                initialSortColId={{ id: "total_events", desc: true }}
                hiddenCols={[]}
                extraClass="topFlopCarServicesUsage"
              />
            </>
          ) : isLoading ? null : (
            <NoDataMsg />
          )}
          <LoadingEllipsis isLoading={isLoading} />
        </div>
      </>
    </ErrorBoundary>
  );
};

export default TopFlopServicesTable;
