import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import { ALL_OPTION_NO_SPACE } from "../../../constants";
import { Brand, Market, Region } from "../../../constants/interface/admin/common";
import { FormFields } from "../Dialogs/AnnotationDialog";

interface Props {
  control: Control<FormFields>;
  brands: Brand[];
  regions: Region[];
  markets: Market[];
  setValue: UseFormSetValue<FormFields>;
}

const BrandsField = (props: Props) => {
  const { control, brands, setValue, regions, markets } = props;

  return (
    <Controller
      name="brands"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          options={brands}
          disableClearable
          getOptionLabel={(option: Brand) => option.brand}
          getOptionSelected={(option, value) => option.brand == value.brand}
          value={value[0]}
          onChange={(event, selectedValue) => {
            value[0].brand != selectedValue.brand &&
              setValue(
                "regions",
                regions.filter((region: Region) => region.region == ALL_OPTION_NO_SPACE)
              );
            setValue(
              "markets",
              markets.filter((market: Market) => market.market == ALL_OPTION_NO_SPACE)
            );
            onChange([selectedValue]);
          }}
          renderInput={(params) => <TextField {...params} variant="outlined" label="Brand" placeholder="Brand" />}
        />
      )}
      rules={{
        required: {
          value: true,
          message: "Please select a brand",
        },
      }}
    />
  );
};

export default BrandsField;
