import { ErrorBoundary } from "@sentry/react";
import React, { useEffect, useMemo, useState } from "react";
import { fetchHandraisersCityBreakdownData } from "../../../../api/Ariya";
import { ErrorMsg } from "../../../../components/AppMessages";
import { ARIYA_NAE_MARKETS } from "../../../../constants";
import { plusSignNumbers } from "../../../../utils/utilityFunctions";
import { AriyaCityTable } from "../../Components";
import styles from "./handraiserByCity.module.scss";

const prefilledCountries = ["Japan", "United States", "United Kingdom", "Norway", "Canada"];

export const HandraisersByCity = () => {
  const [hiddenColumns] = useState([""]);
  const [tableData, setTableData] = useState<Array<{ city: string; total: number; increase: number; share: number }>>([]);
  const [countries, setCountries] = useState<Array<string>>([]);
  const [cities, setCities] = useState<Array<{ city: string; country: string; current: number; previous: number }>>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [cityValue, setCityValue] = useState("All");

  useEffect(() => {
    fetchHandraisersCityBreakdownData().then((response) => {
      if (response && !("error" in response)) {
        const countries = response.countries.map((row) => row.country);
        setCountries(countries);
        setCities(response.cities);
      }
    });
  }, []);

  useEffect(() => {
    if (cities.length) {
      const filterInput = cityValue === "NAE" ? ARIYA_NAE_MARKETS : [cityValue];

      const filteredCities = cityValue === "All" ? [...cities] : cities.filter((row) => filterInput.includes(row.country));

      const formattedCities = filteredCities.map((row) => ({ city: row.city, total: row.current, increase: row.current - row.previous }));
      const HighestCity = formattedCities.reduce((result, row) => Math.max(result, row.total), 0);
      setTableData(formattedCities.map((city) => ({ ...city, share: Math.round((city.total / HighestCity) * 100) })));
    }
  }, [cities, cityValue]);

  // Filter data
  const data = useMemo(() => tableData, [tableData]);
  const columns = useMemo(
    () => [
      {
        id: "city",
        accessor: "city",
        sortable: false,
        filterable: false,
      },
      {
        id: "share",
        accessor: "share",
        sortable: false,
        filterable: false,
        defaultCanSort: false,
        Cell: function renderProgressBar(props1: { value: number }) {
          return (
            <div className="progress-bar">
              <span style={{ width: `${props1.value}%` }}>&nbsp;</span>
            </div>
          );
        },
      },
      {
        id: "handraisers",
        Header: "Total hand-raisers",
        accessor: "total",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: function renderVisitsType(props1: { value: number }) {
          const { value } = props1;

          return value.toLocaleString();
        },
      },
      {
        Header: "In the last 7 days",
        accessor: `increase`,
        id: "increase",
        defaultCanSort: true,
        defaultSortDesc: true,
        sortDescFirst: true,
        sortType: "alphanumericCustom",
        Cell: function renderVisitsType(props1: { value: string }) {
          const { value } = props1;

          return plusSignNumbers(value);
        },
      },
    ],
    []
  );

  const handleFilterValueClick = (evt: React.MouseEvent<HTMLElement>) => {
    setCityValue(evt.currentTarget.dataset.value as string);
    setShowFilters(false);
  };

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className={styles.filterContainer}>
        <div className={`${styles.cityFilter} border`}>
          <div className={`${styles.filterTitle} secondaryFont`}>Geography</div>
          <input
            type="text"
            className={`${styles.inputWithArrow} primaryFont`}
            value={cityValue}
            onClick={() => setShowFilters((prevState) => !prevState)}
            readOnly
          />
        </div>
        <ul className={`${styles.filterOptions} ${showFilters ? styles.showFilters : ""}`}>
          <li className={`${styles.filterItem} ${styles.withBorderBottom}`} data-value="All" onClick={handleFilterValueClick}>
            All
          </li>
          <li className={`${styles.filterItem} ${styles.withBorderBottom}`} data-value="NAE" onClick={handleFilterValueClick}>
            NAE
          </li>

          <li className={styles.filterItem} data-value="Japan" onClick={handleFilterValueClick}>
            Japan
          </li>
          <li className={styles.filterItem} data-value="United States" onClick={handleFilterValueClick}>
            United States
          </li>
          <li className={styles.filterItem} data-value="United Kingdom" onClick={handleFilterValueClick}>
            United Kingdom
          </li>
          <li className={styles.filterItem} data-value="Norway" onClick={handleFilterValueClick}>
            Norway
          </li>
          <li className={`${styles.filterItem} ${styles.withBorderBottom}`} data-value="Canada" onClick={handleFilterValueClick}>
            Canada
          </li>

          {countries
            .filter((country) => !prefilledCountries.includes(country))
            .map((country) => (
              <li className={styles.filterItem} data-value={country} onClick={handleFilterValueClick} key={country}>
                {country}
              </li>
            ))}
        </ul>
      </div>

      {data.length ? (
        <AriyaCityTable
          columns={columns}
          data={data}
          id={"total_handraiser_table"}
          data-test-id={"total_handraiser_table"}
          // @ts-ignore
          renderRowSubComponent={() => {}}
          isChildComponent={false}
          initialSortColId={{ id: "handraisers", desc: true }}
          hiddenCols={hiddenColumns}
          sortable={false}
        />
      ) : (
        <></>
      )}
    </ErrorBoundary>
  );
};
