import { INCREASE_ERROR_VALUE, SET_ERROR_KEY } from "../actions/actionTypes";

const initialState = { key: 0 };

const errorReducer = (
  state = initialState,
  action: {
    type: string;
    value: number;
  }
) => {
  switch (action.type) {
    case SET_ERROR_KEY:
      return { ...state, key: action.value };

    case INCREASE_ERROR_VALUE:
      return { ...state, key: ++state.key };

    default:
      return state;
  }
};

export default errorReducer;
