import AddLinkRoundedIcon from "@mui/icons-material/AddLinkRounded";
import AdsClickRoundedIcon from "@mui/icons-material/AdsClickRounded";
import ChecklistRtlRoundedIcon from "@mui/icons-material/ChecklistRtlRounded";
import GroupAddRoundedIcon from "@mui/icons-material/GroupAddRounded";
import NoCrashRoundedIcon from "@mui/icons-material/NoCrashRounded";
import PercentRoundedIcon from "@mui/icons-material/PercentRounded";
import TocRoundedIcon from "@mui/icons-material/TocRounded";
import React, { useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { setPageName } from "../../../actions";
import {
  fetchOnlineAccAndEngAccountCreationAdjusted,
  fetchOnlineAccAndEngAccountCreationTrend,
  fetchOnlineAccAndEngActiveUsers,
  fetchOnlineAccAndEngAvgVocRating,
  fetchOnlineAccAndEngCompleteAccountCreation,
  fetchOnlineAccAndEngEngagementVsActiveUsers,
  fetchOnlineAccAndEngEnrolledUsersLinked,
  fetchOnlineAccAndEngEnrolledUsersTrend,
  fetchOnlineAccAndEngFirstEngagement,
  fetchOnlineAccAndEngLinkedCars,
  fetchOnlineAccAndEngPrivateSales,
  fetchOnlineAccAndEngStartAccountCreation,
  fetchOnlineAccAndEngVehiclesAdded,
} from "../../../actions/useOwnershipTypes";
import LineTrendChart from "../../../components/Charts/UseOwnership/LineTrendChart";
import MultipleBarLineTrendChart from "../../../components/Charts/UseOwnership/MultipleBarLineTrendChart";
import UseOwnershipFilters from "../../../components/Filters/UseOwnershipFilters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import { ToggleSwitch } from "../../../components/Toogle";
import { CYAN, GREEN, INDIGO, PURPLE } from "../../../constants";
import { USE_OWNERSHIP_BTN_FILTERS } from "../../../constants/useOwnershipConstants";
import { DashboardBannerContext, ThemeContext } from "../../../context";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import MetricTileWithDonutChart from "./components/MetricTileWithDonutChart";
import MetricTileWithIcon from "./components/MetricTileWithIcon";
import PrivateSalesTile from "./components/PrivateSalesTile";

export const OnlineAccountAndEngagement = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.use_ownership_last_data_refresh);
  const { setShowBanner, setBannerText } = useContext(DashboardBannerContext);

  const {
    online_acc_and_eng_private_sales: privateSales,
    online_acc_and_eng_start_account_creation: startAccCreation,
    online_acc_and_eng_complete_account_creation: completeAccCreation,
    online_acc_and_eng_first_engagement: firstEngagement,
    online_acc_and_eng_active_users: activeUsers,
    online_acc_and_eng_linked_cars: linkedCarsTrend,
    online_acc_and_eng_enrolled_users_trend: enrolledUsersTrend,
    online_acc_and_eng_account_creation_trend: accountCreationTrend,
    online_acc_and_eng_engagement_vs_active_users: firstEngagementVsActiveUsers,
    online_acc_and_eng_avg_voc_rating: avgVocRating,
    online_acc_and_eng_enrolled_users_linked: enrolleUsersLinked,
    online_acc_and_eng_vehicles_added: vehiclesAdded,
    online_acc_and_eng_account_creation_adjusted: accountCreationAdjusted,
  } = useSelector((state: RootStateOrAny) => state.use_ownership);

  const {
    online_acc_and_eng_private_sales: privateSalesLoading,
    online_acc_and_eng_start_account_creation: startAccCreationLoading,
    online_acc_and_eng_complete_account_creation: completeAccCreationLoading,
    online_acc_and_eng_first_engagement: firstEngagementLoading,
    online_acc_and_eng_active_users: activeUsersLoading,
    online_acc_and_eng_linked_cars: linkedCarsTrendLoading,
    online_acc_and_eng_enrolled_users_trend: enrolledUsersTrendLoading,
    online_acc_and_eng_account_creation_trend: accountCreationTrendLoading,
    online_acc_and_eng_engagement_vs_active_users: firstEngagementVsActiveUsersLoading,
    online_acc_and_eng_avg_voc_rating: avgVocRatingLoading,
    online_acc_and_eng_enrolled_users_linked: enrolleUsersLinkedLoading,
    online_acc_and_eng_vehicles_added: vehiclesAddedLoading,
    online_acc_and_eng_account_creation_adjusted: accountCreationAdjustedLoading,
  } = useSelector((state: RootStateOrAny) => state.loading);

  const [enrolledUsersSwitch, setEnrolledUsersSwitch] = useState(true);

  useEffect(() => {
    dispatch(setPageName("USE Ownership Dashboard - Online Account & Engagement"));
    setShowBanner(true);
    setBannerText(
      " Please note that data availability differs across markets in scope. N/A is displayed when data is not available. For more information on data availability by market view the complete data dictionary by clicking on the book sing on bottom left."
    );

    return () => {
      setShowBanner(false);
      setBannerText("");
    };
  }, []);

  useEffect(() => {
    dispatch(fetchOnlineAccAndEngPrivateSales());
    dispatch(fetchOnlineAccAndEngEnrolledUsersTrend());
    dispatch(fetchOnlineAccAndEngAccountCreationTrend());
    dispatch(fetchOnlineAccAndEngLinkedCars());
    dispatch(fetchOnlineAccAndEngEngagementVsActiveUsers());
    dispatch(fetchOnlineAccAndEngStartAccountCreation());
    dispatch(fetchOnlineAccAndEngCompleteAccountCreation());
    dispatch(fetchOnlineAccAndEngAccountCreationAdjusted());
    dispatch(fetchOnlineAccAndEngActiveUsers());
    dispatch(fetchOnlineAccAndEngFirstEngagement());
    dispatch(fetchOnlineAccAndEngAvgVocRating());
    dispatch(fetchOnlineAccAndEngEnrolledUsersLinked());
    dispatch(fetchOnlineAccAndEngVehiclesAdded());
  }, [history.location.search]);

  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Use Ownership", page: props.history.location.pathname });
  }, [window.location]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<UseOwnershipFilters filterBtns={USE_OWNERSHIP_BTN_FILTERS} page="Online Account and Engagement" />}
        dataDocumentation="use_ownership"
        lastDataRefresh={lastDataRefresh}
        pageTitle={<PageTitle dataDocumentation="use_ownership/online_account_and_engagement" />}
      >
        <div className="online-account-engagement-engagement-page">
          <div className="row">
            <div className="section-title tile">Total Cars Sold</div>
            <div className="metric-tiles">
              <PrivateSalesTile
                privateSales={privateSales?.Current?.private_sales}
                privateSalesPoP={privateSales?.PoP?.perc_private_sales}
                privateSalesYoY={privateSales?.YoY?.perc_private_sales}
                privateSalesValue={privateSales?.Current?.private_sales_value}
                privateSalesValuePoP={privateSales?.PoP?.perc_private_sales_value}
                privateSalesValueYoY={privateSales?.YoY?.perc_private_sales_value}
                isLoading={privateSalesLoading}
              />
            </div>
          </div>
          <div className="row">
            <div className="section-title tile">Account creation</div>
            <div className="metric-tiles">
              <MetricTileWithIcon
                Icon={TocRoundedIcon}
                title="Users who start account creation form"
                titleTooltipText="Users who lands on the owner portal registration form"
                mainValue={startAccCreation?.Current?.account_creation_form_start_users}
                yoyValue={startAccCreation?.YoY?.perc_account_creation_form_start_users}
                popValue={startAccCreation?.PoP?.perc_account_creation_form_start_users}
                isLoading={startAccCreationLoading}
              />

              <MetricTileWithIcon
                Icon={ChecklistRtlRoundedIcon}
                title="Users who complete account creation form"
                titleTooltipText="Users who complete the owner portal registration form"
                mainValue={completeAccCreation?.Current?.account_creation_form_complete_users}
                yoyValue={completeAccCreation?.YoY?.perc_account_creation_form_complete_users}
                popValue={completeAccCreation?.PoP?.perc_account_creation_form_complete_users}
                isLoading={completeAccCreationLoading}
              />

              <MetricTileWithIcon
                Icon={PercentRoundedIcon}
                title="Account creation adjusted "
                titleTooltipText="Account creation adjusted is the number of account activated divided by the private new car sales in the selected period"
                mainValue={accountCreationAdjusted?.Current?.account_creation_adjusted}
                yoyValue={accountCreationAdjusted?.YoY?.perc_account_creation_adjusted}
                popValue={accountCreationAdjusted?.PoP?.perc_account_creation_adjusted}
                isLoading={accountCreationAdjustedLoading}
                isPercentage={true}
              />
            </div>
            <div className="chart-tile tile">
              <div className="title-container">
                <div className="title">
                  {enrolledUsersSwitch ? "Enrolled Users Trend" : "Account creation adjusted"}
                  {!enrolledUsersSwitch && (
                    <Tooltip
                      position="top-end"
                      className={"normal_tippy"}
                      trigger="mouseenter"
                      animation="none"
                      interactive={true}
                      delay={1}
                      hideDelay={1}
                      duration={1}
                      title={
                        "Account creation adjusted is the number of account activated divided by the private new car sales in the selected month"
                      }
                      size={"small"}
                    >
                      <span className="info_tooltip" />
                    </Tooltip>
                  )}
                </div>
                <div>
                  <ToggleSwitch
                    activeToggleLabel="Enrolled Users"
                    inactiveToggleLabel="Account creation adjusted"
                    active={enrolledUsersSwitch}
                    handleToggleClick={() => setEnrolledUsersSwitch((prevState) => !prevState)}
                  />
                </div>
              </div>
              <LineTrendChart
                chartId="EnrolledUsersTrendChart"
                theme={theme}
                isLoading={enrolledUsersSwitch ? enrolledUsersTrendLoading : accountCreationTrendLoading}
                seriesColors={[CYAN, PURPLE, GREEN, INDIGO]}
                data={(enrolledUsersSwitch ? enrolledUsersTrend.data : accountCreationTrend.data) ?? []}
                seriesList={(enrolledUsersSwitch ? enrolledUsersTrend.series : accountCreationTrend.series) ?? []}
                isPercentage={enrolledUsersSwitch ? false : true}
              />
            </div>
          </div>
          <div className="row">
            <div className="section-title tile">Engagement</div>
            <div className="metric-tiles">
              <MetricTileWithIcon
                Icon={AdsClickRoundedIcon}
                title="Users with first engagement"
                titleTooltipText="Users who interacted for the first time with the ownership page after logging in"
                mainValue={firstEngagement?.Current?.first_engagement_users}
                yoyValue={firstEngagement?.YoY?.perc_first_engagement_users}
                popValue={firstEngagement?.PoP?.perc_first_engagement_users}
                isLoading={firstEngagementLoading}
              />
              <MetricTileWithIcon
                Icon={GroupAddRoundedIcon}
                title="Average Monthly Active users"
                titleTooltipText="Users who login to the Owner portal and lands on any of the owner portal pages"
                mainValue={activeUsers?.Current?.avg_active_users}
                yoyValue={activeUsers?.YoY?.perc_avg_active_users}
                popValue={activeUsers?.PoP?.perc_avg_active_users}
                isLoading={activeUsersLoading}
              />
            </div>
            <div className="chart-tile tile">
              <div className="title-container">
                <div className="title">First engagement user vs Active user</div>
              </div>
              <MultipleBarLineTrendChart
                chartId="firstEngagementChart"
                data={firstEngagementVsActiveUsers.data ?? []}
                seriesList={firstEngagementVsActiveUsers.series ?? []}
                theme={theme}
                isLoading={firstEngagementVsActiveUsersLoading}
              />
            </div>
          </div>
          <div className="row">
            <div className="section-title tile">Key Owner Actions</div>
            <div className="metric-tiles">
              <MetricTileWithIcon
                Icon={NoCrashRoundedIcon}
                title="Enrolled users who have linked a car"
                titleTooltipText="User who have successfully paired their vehicle with NissanConnect app."
                mainValue={enrolleUsersLinked?.Current?.enrolled_ccs_customers}
                yoyValue={enrolleUsersLinked?.YoY?.perc_enrolled_ccs_customers}
                popValue={enrolleUsersLinked?.PoP?.perc_enrolled_ccs_customers}
                isLoading={enrolleUsersLinkedLoading}
              />
              <MetricTileWithIcon
                Icon={AddLinkRoundedIcon}
                title="Users who added their vehicles to the Owner Portal"
                titleTooltipText="Users who went online on the Owner Portal and have added their vehicle (visits for the hits where e38 exists)"
                mainValue={vehiclesAdded?.Current?.vehicle_addition_visits}
                yoyValue={vehiclesAdded?.YoY?.perc_vehicle_addition_visits}
                popValue={vehiclesAdded?.PoP?.perc_vehicle_addition_visits}
                isLoading={vehiclesAddedLoading}
              />
              <MetricTileWithDonutChart
                data={avgVocRating?.chart ?? []}
                title="Avg VOC rating account information                "
                titleTooltipText="Average out of 5 of all ratings given by users to evaluate their experience on Ownership pages"
                mainValue={avgVocRating?.avg?.Current?.average_rating}
                yoyValue={avgVocRating?.avg?.YoY?.pts_average_rating}
                popValue={avgVocRating?.avg?.PoP?.pts_average_rating}
                isLoading={avgVocRatingLoading}
              />
            </div>
            <div className="chart-tile tile">
              <div className="title-container">
                <div className="title">Proportion of users that have linked a car per month</div>
              </div>
              <MultipleBarLineTrendChart
                chartId="linkedCarUsersChart"
                data={linkedCarsTrend?.data ?? []}
                seriesList={linkedCarsTrend?.series ?? []}
                theme={theme}
                isLoading={linkedCarsTrendLoading}
                lineAxisIsPercentage={true}
              />
            </div>
          </div>
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});
