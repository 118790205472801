import React from "react";
import { OceCheckBox } from "../../../components/Forms/common";
import { metricTypes } from "../../../constants";
interface Props {
  metric: metricTypes;
  newCarsChecked: boolean;
  setNewCarsChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CpoCheckboxes = (props: Props) => {
  const { metric, newCarsChecked, setNewCarsChecked } = props;

  return (
    <OceCheckBox
      name={metric}
      id={metric}
      labelName={`New cars ${metric === "kbas" ? "KBA visits" : metric === "leads" ? "digital lead visits" : "visits"}`}
      value={metric}
      checked={newCarsChecked}
      setChecked={setNewCarsChecked}
    />
  );
};
