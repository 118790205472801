import {
  FETCH_ACCESSIBILITY,
  FETCH_BUGS_RELEASE_BREAKDOWN,
  FETCH_BUGS_STATUS_BREAKDOWN,
  FETCH_CUSTOMER_EXPERIENCE,
  FETCH_DIGITAL_PERFORMANCE,
  FETCH_ERROR_PAGES,
  FETCH_FEATURES_ACTIVATED,
  FETCH_INFLUENCE_ON_LEADS,
  FETCH_INFLUENCE_ON_SHOPPING_TOOLS,
  FETCH_LIVE_MARKETS,
  FETCH_PAGE_PERFORMANCE,
  FETCH_PAGE_PERFORMANCE_BREAKDOWN,
  FETCH_TOTAL_BUGS,
  SET_ACCESSIBILITY,
  SET_BUGS_RELEASE_BREAKDOWN,
  SET_BUGS_STATUS_BREAKDOWN,
  SET_CUSTOMER_EXPERIENCE,
  SET_DIGITAL_PERFORMANCE,
  SET_ERROR_PAGES,
  SET_FEATURES_ACTIVATED,
  SET_INFLUENCE_ON_LEADS,
  SET_INFLUENCE_ON_SHOPPING_TOOLS,
  SET_LIVE_MARKETS,
  SET_PAGE_PERFORMANCE,
  SET_PAGE_PERFORMANCE_BREAKDOWN,
  SET_TOTAL_BUGS,
} from "./actionTypes/productPerformanceActionTypes";

export const fetchLiveMarkets = () => ({
  type: FETCH_LIVE_MARKETS,
});
export const setLiveMarkets = (data: any) => ({
  type: SET_LIVE_MARKETS,
  payload: data,
});

export const fetchFeaturesActivated = () => ({
  type: FETCH_FEATURES_ACTIVATED,
});
export const setFeaturesActivated = (data: any) => ({
  type: SET_FEATURES_ACTIVATED,
  payload: data,
});

export const fetchDigitalPerformance = () => ({
  type: FETCH_DIGITAL_PERFORMANCE,
});
export const setDigitalPerformance = (data: any) => ({
  type: SET_DIGITAL_PERFORMANCE,
  payload: data,
});

export const fetchInfluenceOnShoppingTools = () => ({
  type: FETCH_INFLUENCE_ON_SHOPPING_TOOLS,
});
export const setInfluenceOnShoppingTools = (data: any) => ({
  type: SET_INFLUENCE_ON_SHOPPING_TOOLS,
  payload: data,
});

export const fetchInfluenceOnLeads = () => ({
  type: FETCH_INFLUENCE_ON_LEADS,
});
export const setInfluenceOnLeads = (data: any) => ({
  type: SET_INFLUENCE_ON_LEADS,
  payload: data,
});

export const fetchTotalBugs = () => ({
  type: FETCH_TOTAL_BUGS,
});
export const setTotalBugs = (data: any) => ({
  type: SET_TOTAL_BUGS,
  payload: data,
});

export const fetchBugsStatusBreakdown = () => ({
  type: FETCH_BUGS_STATUS_BREAKDOWN,
});
export const setBugsStatusBreakdown = (data: any) => ({
  type: SET_BUGS_STATUS_BREAKDOWN,
  payload: data,
});

export const fetchBugsReleaseBreakdown = () => ({
  type: FETCH_BUGS_RELEASE_BREAKDOWN,
});
export const setBugsReleaseBreakdown = (data: any) => ({
  type: SET_BUGS_RELEASE_BREAKDOWN,
  payload: data,
});

export const fetchPagePerformance = () => ({
  type: FETCH_PAGE_PERFORMANCE,
});
export const setPagePerformance = (data: any) => ({
  type: SET_PAGE_PERFORMANCE,
  payload: data,
});

export const fetchPagePerformanceBreakdown = () => ({
  type: FETCH_PAGE_PERFORMANCE_BREAKDOWN,
});
export const setPagePerformanceBreakdown = (data: any) => ({
  type: SET_PAGE_PERFORMANCE_BREAKDOWN,
  payload: data,
});

export const fetchAccessibility = () => ({
  type: FETCH_ACCESSIBILITY,
});
export const setAccessibility = (data: any) => ({
  type: SET_ACCESSIBILITY,
  payload: data,
});

export const fetchCustomerExperience = () => ({
  type: FETCH_CUSTOMER_EXPERIENCE,
});
export const setCustomerExperience = (data: any) => ({
  type: SET_CUSTOMER_EXPERIENCE,
  payload: data,
});

export const fetchErrorPages = () => ({
  type: FETCH_ERROR_PAGES,
});
export const setErrorPages = (data: any) => ({
  type: SET_ERROR_PAGES,
  payload: data,
});
