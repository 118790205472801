import React, { useEffect, useState } from "react";
import "../../../../assets/styles/component/topicFilter.scss";

interface Props {
  options: Array<string>;
  handleSubmit: (values: Array<string>) => void;
  paramValue: string;
  filterValue: string;
}

const TopicFilter = (props: Props) => {
  const { options, paramValue, handleSubmit, filterValue } = props;

  const [showOptions, setShowOptions] = useState(false);
  const [value, setValue] = useState<Array<string>>([]);

  useEffect(() => {
    if (paramValue == "All") {
      setValue([...options]);
    } else {
      setValue(paramValue.split(","));
    }
  }, [paramValue, options]);

  const handleClickCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((prevState: Array<string>) => {
      let topicsList: Array<string> = [];
      //@ts-ignore
      const selectedVal = event.target.value;
      if (selectedVal == "All" && prevState.length != options.length) {
        topicsList = [...options];
      } else if (selectedVal == "All" && prevState.length == options.length) {
        topicsList = [];
      } else if (prevState.includes(selectedVal)) {
        // console.log(prevState.filter((val) => val != selectedVal));
        topicsList = prevState.filter((val) => val != selectedVal);
      } else {
        topicsList = [...prevState, selectedVal];
      }

      if (topicsList.length < options.length && topicsList.length != 0) {
        const checkbox = document.getElementById("allInputCheckbox");
        //@ts-ignore
        checkbox.indeterminate = true;
      } else {
        const checkbox = document.getElementById("allInputCheckbox");
        //@ts-ignore
        checkbox.indeterminate = false;
      }

      return topicsList;
    });
  };

  return (
    <div className="filterContainer">
      <div className="filterInputContainer border">
        <div className="filterTitle secondaryFont">TOPIC</div>
        <div className="filterDisplay" onClick={() => setShowOptions((prevState) => !prevState)}>
          <input type="text" className="inputWithArrow primaryFont" value={filterValue} readOnly />
          <span className="arrowDown" />
        </div>
      </div>
      <ul className={`filterOptions ${showOptions ? "showFilters" : ""}`}>
        <li className="filterItem">
          <input
            id="allInputCheckbox"
            type="checkbox"
            checked={value.length == options.length}
            className="dropdownCheckbox"
            value="All"
            onChange={handleClickCheckbox}
          />
          <span>All</span>
        </li>
        {options?.map((option) => (
          <li key={option} className="filterItem">
            <input
              type="checkbox"
              checked={value.includes(option)}
              onChange={handleClickCheckbox}
              className="dropdownCheckbox"
              value={option}
            />
            <span>{option}</span>
          </li>
        ))}
        <div className="filter_options__submit_area">
          <div
            className="filter_submit_btn submit_geo_filter applyBtn"
            onClick={() => {
              handleSubmit(value);
              setShowOptions((prevState) => !prevState);
            }}
          >
            Apply
          </div>
        </div>
      </ul>
    </div>
  );
};

export default TopicFilter;
