export const FETCH_VISITS_OVERVIEW = "FETCH_VISITS_OVERVIEW";
export const SET_VISITS_OVERVIEW = "SET_VISITS_OVERVIEW";

export const FETCH_VISITS_BREAKDOWN = "FETCH_VISITS_BREAKDOWN";
export const SET_VISITS_BREAKDOWN = "SET_VISITS_BREAKDOWN";

export const FETCH_IMPRESSIONS_OVERVIEW = "FETCH_IMPRESSIONS_OVERVIEW";
export const SET_IMPRESSIONS_OVERVIEW = "SET_IMPRESSIONS_OVERVIEW";

export const FETCH_IMPRESSIONS_BREAKDOWN = "FETCH_IMPRESSIONS_BREAKDOWN";
export const SET_IMPRESSIONS_BREAKDOWN = "SET_IMPRESSIONS_BREAKDOWN";

export const FETCH_VISITORS_COUNTRY = "FETCH_VISITORS_COUNTRY";
export const SET_VISITORS_COUNTRY = "SET_VISITORS_COUNTRY";
