import { Redo } from "@mui/icons-material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getExecutiveSummaryAggregatedPurchaseFunnel } from "../../../../api/executiveSummaryWebsiteApi";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";

interface PyramidProps {
  description: string;
  value: number;
  title: string;
  yoyValue: number;
  id: string;
  showLY?: boolean;
  lyPerc?: number;
  lyPts?: number;
  isPrivateSales?: boolean;
}

const marketFlags: Record<string, string> = {
  China: "China",
  "United States": "USA",
  Canada: "Canada",
  Mexico: "Mexico",
  "United Kingdom": "UK",
  Brazil: "Brazil",
  Japan: "Japan",
};

const PyramidSection = (props: PyramidProps) => {
  const { description, value, title, yoyValue, id, showLY, lyPerc, lyPts, isPrivateSales } = props;

  return (
    <div className="funnel-item">
      <div className={`funnel-item-description description-${id}`}>{description}</div>
      <div className={`pyramid-section-${id} ${isPrivateSales ? "total-private-sales" : ""}`}>
        {isPrivateSales ? <div className="section-title">{title}</div> : null}
        <div className="value">{value ? Number(value).toLocaleString() : "n/a"}</div>
        {!isPrivateSales ? <div className="section-title">{title}</div> : null}
        <div className="yoy-value">{yoyValue ? `(${yoyValue}% YOY)` : "n/a"}</div>
      </div>
      {showLY && (
        <div className={`ly-container ly-${id}`}>
          <div className="arrow-icon">
            <Redo fontSize="large" />
          </div>
          <div className="values">
            <div className="percentage-value">{lyPerc ? `${lyPerc}%` : "n/a"}</div>
            <div className="pts-value">
              {lyPts ? "(" : ""}
              {lyPts ? (
                <span className={`${lyPts && lyPts > 0 ? "positive" : lyPts && lyPts < 0 ? "negative" : " "}`}>{`${
                  lyPts > 0 ? "+" : ""
                }${lyPts}`}</span>
              ) : (
                "n/a"
              )}
              {lyPts ? " pt vs LY)" : ""}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const AggregatedPurchaseFunnel = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const { market: marketParamValue, brand: brandParamValue } = useSelector((state: RootStateOrAny) => state.parameters);

  const {
    data: funnelData,
    refetch,
    isFetching: isLoading,
  } = useQuery({
    queryKey: ["aggregatedPurchaseFunnelData"],
    queryFn: getExecutiveSummaryAggregatedPurchaseFunnel,
    enabled: false,
    initialData: {},
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  const flags = useMemo(() => {
    const res: Array<string> = [];
    const userMarketFlags: Record<string, string> = {};
    funnelData.markets?.forEach((market: string) => {
      userMarketFlags[market] = marketFlags[market];
    });
    const marketsWithFlags = Object.keys(userMarketFlags);
    if (marketParamValue == "All") {
      return Object.values(userMarketFlags);
    }
    marketParamValue
      ?.split(",")
      .forEach((marketParam: string) => marketsWithFlags.includes(marketParam) && res.push(userMarketFlags[marketParam]));
    return res;
  }, [marketFlags, marketParamValue, funnelData]);

  return isLoading ? (
    <LoadingEllipsis isLoading={true} />
  ) : (
    <div className="aggregated-purchase-funnel">
      <div className="funnel-header">
        <div className="market-flags">
          {flags.map((flag: string) => (
            <span className={`flagIcon ${flag}`} key={flag}></span>
          ))}
        </div>
        <div className="funnel-title">{brandParamValue}</div>
      </div>
      <PyramidSection
        description="Visits to Nissan Tier 1 website"
        value={funnelData?.visits}
        title="Web Visits"
        yoyValue={funnelData?.visits_yoy}
        id="one"
        showLY={true}
        lyPerc={funnelData?.visits_to_leads}
        lyPts={funnelData?.visits_to_leads_yoy}
      />

      <PyramidSection
        description="Tracked leads from selected channels"
        value={funnelData?.leads}
        title="Leads"
        yoyValue={funnelData?.leads_yoy}
        id="two"
        showLY={true}
        lyPerc={funnelData?.leads_to_sales}
        lyPts={funnelData?.leads_to_sales_yoy}
      />

      <PyramidSection
        description="Digitally Influenced Sales from selected channels"
        value={funnelData?.sales}
        title="Sales"
        yoyValue={funnelData?.sales_yoy}
        id="three"
      />

      <PyramidSection
        description="Total P/S absolute volume (from Global Sales team)"
        value={funnelData?.private_sales}
        title="TTL PS"
        yoyValue={funnelData?.private_sales_yoy}
        id="four"
        isPrivateSales={true}
      />
    </div>
  );
});

export default AggregatedPurchaseFunnel;
