import React from "react";
import Ratings from "../../../../components/Ratings/Ratings";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { isNull } from "../../../../utils/utilityFunctions";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";

interface Props {
  title: string;
  tiles: Array<{ title: string; value: number; rating: number }>;
  isLoading: boolean;
}
const CustomerJourneyVocCategoryTile = (props: Props) => {
  const { title, tiles, isLoading } = props;
  return (
    <div className="category-tile">
      <div className="title">{title}</div>
      <div className="tile-container">
        {tiles.map((tile) => (
          <div className="tile" key={tile.title}>
            <div className="title">{tile.title}</div>
            <div className="value-container">
              <div className={`value ${tile.value >= 4 ? "text-green" : tile.value > 0 ? "text-red" : ""}`}>
                {!isNull(tile.value) ? tile.value : "n/a"}
              </div>
              <Ratings value={tile.rating} isPercentageValue={false} />
            </div>
            <LoadingEllipsis isLoading={isLoading} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerJourneyVocCategoryTile;
