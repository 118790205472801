import { createContext, Dispatch, SetStateAction } from "react";

export const DashboardBannerContext = createContext<{
  bannerText: string;
  setBannerText: Dispatch<SetStateAction<string>>;
  detailText: string;
  setDetailText: Dispatch<SetStateAction<string>>;
  showBanner: boolean;
  setShowBanner: Dispatch<SetStateAction<boolean>>;
  bannerHovered: boolean;
  setBannerHovered: Dispatch<SetStateAction<boolean>>;
  region: string;
  setRegion: Dispatch<SetStateAction<string>>;
}>({
  bannerText: "",
  setBannerText(): void {},
  detailText: "",
  setDetailText(): void {},
  showBanner: false,
  setShowBanner(): void {},
  bannerHovered: false,
  setBannerHovered(): void {},
  region: "",
  setRegion(): void {},
});
