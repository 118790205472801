import { ErrorBoundary } from "@sentry/react";
import React, { useContext } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import StrategicDealerSupportChart from "../../../components/Charts/Midas/StrategicDealerSupportChart";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { ThemeContext } from "../../../context";

interface Props {
  loading: boolean;
  data: any;
  quarterTitle: string;
  seriesList: any;
  yrScenarios: string[];
  columnPercentage?: number;
  minAmount: number;
  maxAmount: number;
  hideAxis?: boolean;
}

const QuarterBreakdown = (props: Props) => {
  const themeContext = useContext(ThemeContext);
  const { loading, yrScenarios, data, quarterTitle, seriesList, maxAmount, minAmount, columnPercentage, hideAxis } = props;
  const unitParam = useSelector((state: RootStateOrAny) => state.parameters.unit);
  return (
    <div className="quarter_breakdown">
      <span className="quarter_title">{quarterTitle}</span>
      {data?.length && !loading ? (
        <ErrorBoundary fallback={<ErrorMsg />}>
          <StrategicDealerSupportChart
            maxAmount={maxAmount}
            minAmount={minAmount}
            categories={yrScenarios}
            chartName={`${quarterTitle}DealerSupport`}
            theme={themeContext.theme}
            data={data}
            seriesList={seriesList}
            isLoading={false}
            unitParam={unitParam}
            columnPercentage={columnPercentage}
            hideAxis={hideAxis}
          />
        </ErrorBoundary>
      ) : loading ? (
        <LoadingEllipsis isLoading={loading} />
      ) : (
        <NoDataMsg message="No data available" />
      )}
    </div>
  );
};

export default QuarterBreakdown;
