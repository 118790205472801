import * as am5 from "@amcharts/amcharts5";
import { Root } from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { BLACK, WHITE } from "../../../constants";

export const dateAxisConfig = (
  root: Root,
  timeCount: number,
  timeUnit: any,
  cellStartLocation: number,
  cellEndLocation: number,
  granularity?: string
) => {
  return am5xy.DateAxis.new(root, {
    maxDeviation: 0.1,
    baseInterval: {
      timeUnit: timeUnit,
      count: timeCount,
    },
    dateFormats: {
      month: granularity == "quarterly" ? "MMM yyyy" : "MMM",
    },
    renderer: am5xy.AxisRendererX.new(root, {
      cellStartLocation: cellStartLocation,
      cellEndLocation: cellEndLocation,
      minGridDistance: granularity == "quarterly" ? 60 : 10,
    }),
  });
};

export const dateAxisGridConfigRenderer = (
  dateAxis: am5xy.DateAxis<am5xy.AxisRenderer>,
  strokeOpacity?: number,
  strokeWidth?: number,
  visible?: boolean
) => {
  dateAxis.get("renderer").grid.template.setAll({
    strokeOpacity: strokeOpacity ? strokeOpacity : 1,
    strokeWidth: strokeWidth ? strokeWidth : 1,
    visible: visible ? visible : false,
  });
};

export const dateAxisLabelRendererConfig = (
  theme: string,
  dateAxis: am5xy.DateAxis<am5xy.AxisRenderer>,
  visible?: boolean,
  x?: number,
  location?: number
) => {
  dateAxis.get("renderer").labels.template.setAll({
    fill: am5.color(theme == "light" ? BLACK : WHITE),
    fontSize: "13px",
    x: x ? am5.percent(x) : am5.percent(50),
    location: location ? location : 0.5,
    visible: visible ? visible : false,
  });
};

export const configQuarterlyAxis = (dateAxis: am5xy.DateAxis<am5xy.AxisRenderer>, lastObject: any) => {
  dateAxis.setAll({
    max: lastObject,
    extraMax: 0.2,
    extraMin: 0.05,
    groupData: true,
    groupInterval: { timeUnit: "month", count: 3 },
  });
};
