import { captureException, ErrorBoundary } from "@sentry/react";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { fetchGeoScopeData } from "../../actions";
import { axiosWithBearerV2 } from "../../api/utils";
import { isEmpty, isValidEmail } from "../../utils/utilityFunctions";
import { ErrorMsg } from "../AppMessages";
import { BusinessFunctionInput, GeographicalScopeInput, TextInput } from "./common";
import { DepartmentInput } from "./common/DepartmentInput";

export const SignUpForm = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [scope, setScope] = useState<string>("");
  const [businessFunction, setBusinessFunction] = useState("");
  const [department, setDepartment] = useState("");
  const [otherDepartment, setOtherDepartment] = useState("");
  const [hiddenScopeValue, setHiddenScopeValue] = useState<string>("");
  const [validForm, setValidForm] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(true);
  const [submitError, setSubmitError] = useState<boolean>(false);

  const dispatch = useDispatch();

  //Run dispatch action to fetch the filters data
  useEffect(() => {
    dispatch(fetchGeoScopeData());
  }, []);

  //Disable submit button if any of the input state values is invalid
  useEffect(() => {
    setValidForm(
      !isEmpty(scope) &&
        !isEmpty(businessFunction) &&
        !isEmpty(firstName) &&
        !isEmpty(lastName) &&
        !isEmpty(emailAddress) &&
        !isEmpty(department) &&
        isValidEmail(emailAddress) &&
        !(department == "Other" && isEmpty(otherDepartment))
    );
  }, [scope, department, otherDepartment, businessFunction, firstName, lastName, emailAddress]);

  const handleBackToLoginClick = useCallback(
    () => () => {
      history.push({ pathname: "/login" });
    },
    [history]
  );

  const handleSubmit = useCallback(() => {
    if (!validForm) {
      toast.error("Form values not valid. Please check you input and try again");

      return;
    }

    const formattedBody = `Hello, \r\nWe have received a CEDAR Home account creation request from ${firstName} ${lastName}. \r\nRequester's details: \r\n-Scope:${hiddenScopeValue} \r\n-Business function: ${businessFunction} \r\n-Department: ${department} ${
      isEmpty(otherDepartment) ? "" : "\r\n-Other department:" + otherDepartment
    }  \r\n-Email address: ${emailAddress} \r\nCould you please confirm this account can be set up? \r\nMany thanks \r\nThe CEDAR Team`;
    const subject = "CEDAR Home account creation request";
    const domain = window.location.origin;
    const body = { message: formattedBody, subject: subject, email: emailAddress, domain };
    const url = "/authentication/signup";
    setShowForm(false);

    axiosWithBearerV2
      .post(url, body)
      .then((res) => {
        //Check if response status is 202
        if (res.status !== 200) {
          setSubmitError(true);
          captureException(res);
        }
      })
      .catch((err) => {
        setSubmitError(true);
        console.error(err);
        captureException(err);
      });
  }, [validForm, firstName, lastName, businessFunction, department, otherDepartment, emailAddress, hiddenScopeValue]);

  return (
    <ErrorBoundary fallback={<ErrorMsg class={"formError"} />}>
      <>
        <form
          id="login_form"
          className="login_form sign_up_form"
          name="login_form"
          method="POST"
          data-testid="signup_form"
          onSubmit={(evt) => {
            evt.preventDefault();
            handleSubmit();
          }}
        >
          <div className="text_area">
            <h3 className="login_head form_header_text" data-test-id="login_form--welcome">
              Sign up to CEDAR Home
            </h3>
            {showForm ? (
              <>
                <div className="form_elements">
                  <div className="row">
                    <TextInput
                      label="First name"
                      name="firstName"
                      id="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      className={"sign_up_input"}
                      pattern="[a-zA-Z]+"
                    />
                    <TextInput
                      label="Last name"
                      name="lastName"
                      id="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      className={"sign_up_input"}
                      pattern="[a-zA-Z]+"
                    />
                  </div>

                  <div className="row">
                    <TextInput
                      label="Email address"
                      name="email"
                      id="email"
                      value={emailAddress}
                      onChange={(e) => setEmailAddress(e.target.value)}
                      className={"sign_up_input"}
                      pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                    />
                    <GeographicalScopeInput
                      scope={scope}
                      setScope={setScope}
                      hiddenScopeValue={hiddenScopeValue}
                      setHiddenScopeValue={setHiddenScopeValue}
                    />
                  </div>

                  <div className="row">
                    <BusinessFunctionInput businessFunction={businessFunction} setBusinessFunction={setBusinessFunction} />
                    <DepartmentInput department={department} setDepartment={setDepartment} />
                  </div>
                  {department == "Other" && (
                    <div className="row">
                      <TextInput
                        label="Other department"
                        name="other_department"
                        id="other_department"
                        value={otherDepartment}
                        onChange={(e) => setOtherDepartment(e.target.value)}
                        className={"sign_up_input"}
                        pattern="[a-zA-Z0-9\s]+"
                      />
                    </div>
                  )}
                </div>
                <div className="button_area">
                  <input
                    className={`submit_btn ${!validForm ? "disabled" : ""}`}
                    type="submit"
                    value="Submit"
                    id="sign_up"
                    data-testid="sign_up_btn"
                  />
                </div>
              </>
            ) : (
              <div className="success_container">
                {submitError ? (
                  <p>
                    Oops, it looks like something went wrong! Kindly try again or contact <span>nissan@artefact.com</span> if the issue
                    persists.
                  </p>
                ) : (
                  <>
                    <p>
                      Thank your for signing up to CEDAR HOME! Your account creation request has been received and the CEDAR team will send
                      you a welcome email shortly.
                    </p>
                    <p>
                      Ensure you check your spam inbox in case the email has been sent there. If you haven&apos;t received the email after 2
                      business days, you can reach out to nissan@artefact.com and we&apos;ll respond asap.
                    </p>
                  </>
                )}
              </div>
            )}

            <p id="login_link" data-test-id="login_link" className="links back_button" onClick={handleBackToLoginClick()}>
              <span /> Back to login
            </p>
          </div>
        </form>
        <ToastContainer
          position="top-right"
          autoClose={4500}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick={true}
          draggable={false}
          pauseOnHover={false}
        />
      </>{" "}
    </ErrorBoundary>
  );
});
