import { DateAxis, LineSeries, ValueAxis, XYChart, CategoryAxis, ColumnSeries } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter, Sprite } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { BLACK, CCS_ANALYSIS_COLORS, MEDIA_MIX_CHART_COLORS, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import {
  columnSeriesConfiguration,
  configureCategoryAxis,
  configureDateAxis,
  configureValueAxis,
  legendConfiguration,
  lineSeriesConfiguration,
} from "../helpers/configurations";
import { formatDateAxisLabelToMonthly } from "../helpers/mtmConfigurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string | null>>;
  seriesList: Array<{ name: string; field: string }>;
  theme: ThemeInt;
  chartId: string;
  isLoading: boolean;
  currency: string;
}

class MediaFmiAndCoreModelChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, seriesList, theme, currency } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.colors.list = MEDIA_MIX_CHART_COLORS.map((colorStr: string) => color(colorStr));

    // Legend configuration
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.marginBottom = 15;

    // Create Axes
    const categoryAxis = this.chart.yAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "period";

    const valueAxis = this.chart.xAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.numberFormatter = new NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#a";
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return `${currency} ${text}`;
    });
    valueAxis.paddingRight = 10;
    valueAxis.numberFormatter.bigNumberPrefixes = [
      { number: 1e3, suffix: "K" },
      { number: 1e6, suffix: "M" },
      { number: 1e9, suffix: "B" },
    ];

    seriesList.forEach(({ name, field }, idx) => {
      const series = this.chart.series.push(new ColumnSeries());
      series.dataFields.valueX = field;
      series.dataFields.categoryY = "period";
      series.stacked = true;
      series.name = name;
      series.numberFormatter = new NumberFormatter();
      series.numberFormatter.numberFormat = "###,###";
      series.columns.template.tooltipText = `[bold font-size: var(--regular_font_size)]{name}: [bold font-size: var(--regular_font_size)]${currency} {valueX.formatNumber("#,###.#")}`;
      series.columns.template.height = 40;
      if (series.tooltip) {
        series.tooltip.getFillFromObject = false;
        series.tooltip.autoTextColor = false;
        series.tooltip.background.fill = color(WHITE);
        series.tooltip.label.fill = color(BLACK);
      }
    });
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

export default MediaFmiAndCoreModelChart;
