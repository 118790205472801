export interface ISearchCriteria {
  option: keyof typeof SEARCH_TERM_MAP;
  ranking: string;
}

export const SEARCH_TERM_MAP = {
  Contains: "contains",
  "Does not contain": "does_not_contain",

  "Starts with": "starts_with",

  "Does not start with": "does_not_start_with",

  "Matches exactly": "exact_match",
  "Does not match": "does_not_match",

  RegEx: "regex_match",
  "Regex does not match": "regex_no_match",
} as const;

export const searchCriteriaOptions: Array<ISearchCriteria> = [
  { option: "Contains", ranking: "CONTAINS" },
  { option: "Does not contain", ranking: "NOT_CONTAINS" },
  { option: "Starts with", ranking: "STARTS_WITH" },
  { option: "Does not start with", ranking: "NOT_STARTS_WITH" },
  { option: "Matches exactly", ranking: "EQUAL" },
  { option: "Does not match", ranking: "NOT_EQUAL" },
  { option: "RegEx", ranking: "REGEX" },
  { option: "Regex does not match", ranking: "REGEX_NO_MATCH" },
];
