import { ErrorBoundary } from "@sentry/react";
import React, { Dispatch, SetStateAction, useContext } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import { GranularityOptions } from "../../../components/Charts/components";
import { ModelsTrendChart } from "../../../components/Charts/DigitalPerformance";
import { ThemeContext } from "../../../context";

interface Props {
  granularity: string;
  metric: string;
  breakdownTitle: string;
  onHandleRadioClick: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  toggleAnnotationsDialog: () => void;
  setAnnotationStartDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationEndDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationDefaultMetric: Dispatch<SetStateAction<string>>;
  setAnnotationDefaultRegion: Dispatch<SetStateAction<string | undefined>>;
  setAnnotationDefaultMarket: Dispatch<SetStateAction<string | undefined>>;
  setAnnotationDefaultModel: Dispatch<SetStateAction<string | undefined>>;
  isCpoDashboard?: boolean;
}

export const ModelsTrendGraph = (props: Props) => {
  const themeContext = useContext(ThemeContext);

  const {
    granularity,
    breakdownTitle,
    metric,
    onHandleRadioClick,
    toggleAnnotationsDialog,
    setAnnotationStartDate,
    setAnnotationEndDate,
    setAnnotationDefaultMetric,
    setAnnotationDefaultRegion,
    setAnnotationDefaultMarket,
    setAnnotationDefaultModel,
    isCpoDashboard,
  } = props;

  const data = useSelector((state: RootStateOrAny) => state.cpo_digital_performance.model_trend);

  const isLoading = useSelector((state: RootStateOrAny) => state.loading.visits_breakdown_graph);
  const parameters = useSelector((state: RootStateOrAny) => state.parameters);
  const annotations = useSelector((state: RootStateOrAny) => state.digital_performance.visits_chart_annotations);

  const marketList = ["model_detail_visits"];

  return (
    <>
      <h3>Models</h3>
      <div className="main_content">
        <ErrorBoundary fallback={<ErrorMsg />}>
          {data?.length > 0 ? (
            <ModelsTrendChart
              data={data}
              granularity={granularity}
              seriesList={marketList}
              metric={metric}
              theme={themeContext.theme}
              annotations={annotations}
              breakdownTitle={breakdownTitle}
              toggleAnnotationsDialog={toggleAnnotationsDialog}
              setAnnotationEndDate={setAnnotationEndDate}
              setAnnotationStartDate={setAnnotationStartDate}
              setAnnotationDefaultMetric={setAnnotationDefaultMetric}
              setAnnotationDefaultRegion={setAnnotationDefaultRegion}
              setAnnotationDefaultMarket={setAnnotationDefaultMarket}
              setAnnotationDefaultModel={setAnnotationDefaultModel}
              isCpoDashboard={isCpoDashboard}
            />
          ) : isLoading ? null : (
            <NoDataMsg />
          )}
        </ErrorBoundary>
      </div>
      {data.length > 0 && (
        <GranularityOptions
          onHandleRadioClick={onHandleRadioClick}
          granularity={granularity}
          name={"models_trend"}
          dateValue={parameters.date_range}
        />
      )}
    </>
  );
};
