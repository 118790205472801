import { defaultApiInt, newApiFetch } from "./utils";

function getSeoParameters() {
  const parameters = new URLSearchParams(window.location.search);
  const date_range = parameters.get("date_range") ?? "";
  const hostname = parameters.get("hostname") ?? "";
  const market = parameters.get("market") ?? "";
  const mobile_device_type = parameters.get("mobile_device_type") ?? "";
  const search_engine = parameters.get("search_engine") ?? "";
  const landing_page = parameters.get("landing_page") ?? "";
  const query = parameters.get("query") ?? "";
  const visits_search_term = parameters.get("visits_search_term") ?? "";
  const visits_search_type = parameters.get("visits_search_type") ?? "";
  const impressions_search_term = parameters.get("impressions_search_term") ?? "";
  const impressions_search_type = parameters.get("impressions_search_type") ?? "";
  const visitors_country = parameters.get("visitor_country") ?? "";

  return {
    date_range,
    hostname,
    market,
    mobile_device_type,
    search_engine,
    landing_page,
    query,
    visits_search_term,
    visits_search_type,
    impressions_search_term,
    impressions_search_type,
    visitors_country,
  };
}

function getImpressionsOverviewParameters() {
  const {
    date_range,
    hostname,
    market,
    mobile_device_type,
    impressions_search_term: search_term,
    impressions_search_type: search_type,
    query,
    visitors_country,
  } = getSeoParameters();

  const visitorsCountryArr = visitors_country && visitors_country.split(",");
  const params: any = {
    date_range,
    hostname,
    market,
    mobile_device_type,
    search_term,
    search_type,
    query,
  };
  const paramsFormData = new FormData();

  for (const key in params) {
    paramsFormData.append(key, params[key]);
  }
  for (const country of visitorsCountryArr) {
    paramsFormData.append("visitor_country", country);
  }
  return paramsFormData;
}

function getImpressionsBreakdownParameters() {
  const {
    date_range,
    hostname,
    market,
    mobile_device_type,
    impressions_search_term: search_term,
    impressions_search_type: search_type,
    visitors_country,
  } = getSeoParameters();

  const visitorsCountryArr = visitors_country && visitors_country.split(",");

  const params: Record<string, string> = {
    date_range,
    hostname,
    market,
    mobile_device_type,
    search_term,
    search_type,
  };
  const paramsFormData = new FormData();

  for (const key in params) {
    paramsFormData.append(key, params[key]);
  }
  for (const country of visitorsCountryArr) {
    paramsFormData.append("visitor_country", country);
  }
  return paramsFormData;
}

function getVisitsOverviewParameters() {
  const {
    date_range,
    hostname,
    market,
    mobile_device_type,
    visits_search_term: search_term,
    visits_search_type: search_type,
    landing_page,
    search_engine,
  } = getSeoParameters();

  const params: Record<string, string> = {
    date_range,
    hostname,
    market,
    mobile_device_type,
    search_term,
    search_type,
    landing_page,
    search_engine,
  };
  return params;
}
function getVisitsBreakdownParameters() {
  const {
    date_range,
    hostname,
    market,
    mobile_device_type,
    visits_search_term: search_term,
    visits_search_type: search_type,
    search_engine,
  } = getSeoParameters();

  const params: Record<string, string> = {
    date_range,
    hostname,
    market,
    mobile_device_type,
    search_term,
    search_type,
    search_engine,
  };
  return params;
}

function getVisitorsCountryParams() {
  const { hostname, market } = getSeoParameters();
  const params: Record<string, string> = {
    market,
    hostname,
  };
  return params;
}

export const getSeoLastUpdatedData = (): defaultApiInt => newApiFetch("/seo/_last_updated", {});

export const getSeoInsights = (): defaultApiInt => newApiFetch("/admin/seo_insights/get/", {});

export const getSeoVisitorsCountry = (): defaultApiInt => newApiFetch("/seo/_visitors_filters", getVisitorsCountryParams());

export const getSeoVisitsOverviewData = (): defaultApiInt => newApiFetch("/seo/visits_overview", getVisitsOverviewParameters());

export const getSeoVisitsBreakdownData = (): defaultApiInt => newApiFetch("/seo/visits_breakdown", getVisitsBreakdownParameters());

export const getSeoImpressionsOverviewData = (): defaultApiInt =>
  newApiFetch("/seo/impressions_overview", getImpressionsOverviewParameters());

export const getSeoImpressionsBreakdownData = (): defaultApiInt =>
  newApiFetch("/seo/impressions_breakdown", getImpressionsBreakdownParameters());
