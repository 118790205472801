import { all, put, retry, takeLatest } from "redux-saga/effects";
import {
  setFixedDataByQuarter,
  setFmiByModelFmiByQuarter,
  setMediaMixDataByQuarter,
  setMidasFmiByQuarter,
  setMtpCategoryDataByQuarter,
  setMtpSpend,
  setSpendVsTrafficMarketData,
  setSpendVsTrafficModelData,
  setStrategicCEDataByQuarter,
  setStrategicDealerSupportDataByQuarter,
} from "../actions";
import {
  FETCH_FIXED_DATA_BY_QUARTER,
  FETCH_FMI_BY_MODEL_FMI_BY_QUARTER,
  FETCH_FMI_BY_QUARTER,
  FETCH_MEDIA_MIX_DATA_BY_QUARTER,
  FETCH_MTP_CATEGORY_DATA_BY_QUARTER,
  FETCH_MTP_SPEND,
  FETCH_SPEND_VS_TRAFFIC_MARKET_DATA,
  FETCH_SPEND_VS_TRAFFIC_MODEL_DATA,
  FETCH_STRATEGIC_CE_DATA_BY_QUARTER,
  FETCH_STRATEGIC_DEALER_SUPPORT_DATA_BY_QUARTER,
} from "../actions/actionTypes";
import { setLoading } from "../actions/loadingActions";
import {
  getFixedDataByQuarter,
  getMediaMixDataByQuarter,
  getMidasByModelFmiByQuarter,
  getMidasFmiByQuarter,
  getMtpCategoryDataByQuarter,
  getMtpSpend,
  getSpendVsTrafficMarketData,
  getSpendVsTrafficModelData,
  getStrategicCEDataByQuarter,
  getStrategicDealerSupportDataByQuarter,
} from "../api";
import { secondsDelay } from "../api/utils";

export function* watchMidasFetchFmiByQuarterSaga() {
  yield takeLatest(FETCH_FMI_BY_QUARTER, fetchMidasFmiByQuarterSaga);
}

export function* watchMtpSpendSaga() {
  yield takeLatest(FETCH_MTP_SPEND, fetchMtpSpendSaga);
}

export function* watchMidasFetchFmiByModelFmiByQuarterSaga() {
  yield takeLatest(FETCH_FMI_BY_MODEL_FMI_BY_QUARTER, fetchFmiByModelFmiByQuarterSaga);
}

export function* watchFetchMidasMediaMixDataByQuarterSaga() {
  yield takeLatest(FETCH_MEDIA_MIX_DATA_BY_QUARTER, fetchMediaMixDataByQuarterSaga);
}

export function* watchFetchMidasMtpCategoryDataByQuarterSaga() {
  yield takeLatest(FETCH_MTP_CATEGORY_DATA_BY_QUARTER, fetchMtpCategoryDataByQuarterSaga);
}

export function* watchFetchMidasStrategicDealerSupportDataByQuarterSaga() {
  yield takeLatest(FETCH_STRATEGIC_DEALER_SUPPORT_DATA_BY_QUARTER, fetchStrategicDealerSupportDataByQuarter);
}

export function* watchFetchMidasStrategicCEDataByQuarterSaga() {
  yield takeLatest(FETCH_STRATEGIC_CE_DATA_BY_QUARTER, fetchStrategicCEDataByQuarter);
}

export function* watchFetchMidasFixedDataByQuarterSaga() {
  yield takeLatest(FETCH_FIXED_DATA_BY_QUARTER, fetchFixedDataByQuarter);
}

export function* watchFetchMidasSpendVsTrafficMarketDataSaga() {
  yield takeLatest(FETCH_SPEND_VS_TRAFFIC_MODEL_DATA, fetchSpendVsTrafficModelData);
}

export function* watchFetchMidasSpendVsTrafficModelDataSaga() {
  yield takeLatest(FETCH_SPEND_VS_TRAFFIC_MARKET_DATA, fetchSpendVsTrafficMarketData);
}

export function* fetchMidasFmiByQuarterSaga() {
  try {
    yield put(setLoading("midas_fmi_by_quarter", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMidasFmiByQuarter, searchParams);

    if (!("error" in response)) {
      yield all([put(setMidasFmiByQuarter(response)), put(setLoading("midas_fmi_by_quarter", false))]);
    }
  } catch (err) {
    console.log(err);
    // console.log("Failed to get /fmi-by-quarter");
  }
}

export function* fetchMtpSpendSaga() {
  try {
    yield put(setLoading("midas_mtp_spend", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMtpSpend, searchParams);

    if (!("error" in response)) {
      yield all([put(setMtpSpend(response)), put(setLoading("midas_mtp_spend", false))]);
    }
  } catch (err) {
    console.log(err);
    // console.log("Failed to get /fmi-by-quarter");
  }
}

export function* fetchFmiByModelFmiByQuarterSaga() {
  try {
    yield put(setLoading("midas_fmi_by_model_fmi_by_quarter", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMidasByModelFmiByQuarter, searchParams);

    if (!("error" in response)) {
      yield all([put(setFmiByModelFmiByQuarter(response)), put(setLoading("midas_fmi_by_model_fmi_by_quarter", false))]);
    }
  } catch (err) {
    console.log(err);
    // console.log("Failed to get /fmi-by-model/fmi-by-quarter");
  }
}

export function* fetchMediaMixDataByQuarterSaga() {
  try {
    yield put(setLoading("midas_media_mix_data_by_quarter", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMediaMixDataByQuarter, searchParams);

    if (!("error" in response)) {
      yield all([put(setMediaMixDataByQuarter(response)), put(setLoading("midas_media_mix_data_by_quarter", false))]);
    }
  } catch (err) {
    console.log(err);
    // console.log("Failed to get /fmi-by-model/fmi-by-quarter");
  }
}

export function* fetchMtpCategoryDataByQuarterSaga() {
  try {
    yield put(setLoading("midas_mtp_category_data_by_quarter", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMtpCategoryDataByQuarter, searchParams);

    if (!("error" in response)) {
      yield all([put(setMtpCategoryDataByQuarter(response)), put(setLoading("midas_mtp_category_data_by_quarter", false))]);
    }
  } catch (err) {
    console.log("Failed to get /mtp-category/data-by-quarter");
  }
}

export function* fetchStrategicDealerSupportDataByQuarter() {
  try {
    yield put(setLoading("midas_strategic_dealer_support_data_by_quarter", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getStrategicDealerSupportDataByQuarter, searchParams);

    if (!("error" in response)) {
      yield all([
        put(setStrategicDealerSupportDataByQuarter(response)),
        put(setLoading("midas_strategic_dealer_support_data_by_quarter", false)),
      ]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchStrategicCEDataByQuarter() {
  try {
    yield put(setLoading("midas_strategic_ce_data_by_quarter", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getStrategicCEDataByQuarter, searchParams);

    if (!("error" in response)) {
      yield all([put(setStrategicCEDataByQuarter(response)), put(setLoading("midas_strategic_ce_data_by_quarter", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchFixedDataByQuarter() {
  try {
    console.log("here");
    yield put(setLoading("midas_fixed_data_by_quarter", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getFixedDataByQuarter, searchParams);

    if (!("error" in response)) {
      yield all([put(setFixedDataByQuarter(response)), put(setLoading("midas_fixed_data_by_quarter", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchSpendVsTrafficModelData() {
  try {
    yield put(setLoading("midas_spend_vs_traffic_model_data", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getSpendVsTrafficModelData, searchParams);

    if (!("error" in response)) {
      yield all([put(setSpendVsTrafficModelData(response)), put(setLoading("midas_spend_vs_traffic_model_data", false))]);
    } else {
      yield all([put(setLoading("midas_spend_vs_traffic_model_data", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchSpendVsTrafficMarketData() {
  try {
    yield put(setLoading("midas_spend_vs_traffic_market_data", true));
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getSpendVsTrafficMarketData, searchParams);

    if (!("error" in response)) {
      yield all([put(setSpendVsTrafficMarketData(response)), put(setLoading("midas_spend_vs_traffic_market_data", false))]);
    } else {
      yield all([put(setLoading("midas_spend_vs_traffic_market_data", false))]);
    }
  } catch (err) {
    console.log(err);
  }
}
