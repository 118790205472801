import { newApiFetchV2 } from "./utils";

export const getParams = (services?: boolean) => {
  const params = window.location.search;
  const parameters = new URLSearchParams(params);
  const specificService = parameters.get("specific_service");
  const specificGroup = parameters.get("specific_group");
  const specificType = parameters.get("specific_type");
  const specificTrigger = parameters.get("specific_trigger");
  const parameterEntries = {
    date_range: parameters.get("dates") == "" ? [] : parameters.get("dates")?.split(","),
    brand: parameters.get("brand"),
    regions: !/all/gi.test(parameters.get("region")!) ? parameters.get("region")?.split(",") : [],
    models: !/all/gi.test(parameters.get("model")!) ? parameters.get("model")?.split(",") : [],
    service_groups:
      !services && specificGroup
        ? [specificGroup]
        : !/all/gi.test(parameters.get("service_group")!)
        ? parameters.get("service_group")?.split(",")
        : [],
    on_off_boards:
      !services && specificType
        ? [specificType]
        : !/all/gi.test(parameters.get("service_type")!)
        ? parameters.get("service_type")?.split(",")
        : [],
    services:
      !services && specificService
        ? [specificService]
        : !/^all$/gi.test(parameters.get("services")!)
        ? parameters.get("services")?.split(/,(?=[^ ])/)
        : [],
    service_triggers:
      !services && specificTrigger
        ? [specificTrigger]
        : !/all/gi.test(parameters.get("service_trigger")!)
        ? parameters.get("service_trigger")?.split(",")
        : [],
    uid: !/all/gi.test(parameters.get("uid")!)
      ? parameters
          .get("uid")
          ?.split(",")
          .map((uid: string) => Number(uid))
      : [],
    granularity: parameters.get("dates")?.includes("week") ? "weekly" : "monthly",
  };

  return parameterEntries;
};

export const getTopFlopServicesData = () => newApiFetchV2("/top-flop/get-all-data", getParams(true));
export const getTopServicesData = () => newApiFetchV2("/top-flop/get-top-five-services-data?use_cache=true", getParams());
export const getFlopServicesData = () => newApiFetchV2("/top-flop/get-bottom-five-services-data?use_cache=true", getParams());
export const getTopModelsData = () => newApiFetchV2("/top-flop/get-top-five-models-data?use_cache=true", getParams());
export const getServiceGroupsData = () => newApiFetchV2("/top-flop/get-service-groups-data?use_cache=true", getParams());
export const getTopRegionsData = () => newApiFetchV2("/top-flop/get-top-five-markets-data?use_cache=true", getParams());
