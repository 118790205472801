export const FETCH_FULL_FIGURES = "FETCH_FULL_FIGURES";
export const SET_FULL_FIGURES = "SET_FULL_FIGURES";
export const SET_FULL_FIGURES_DAILY = "SET_FULL_FIGURES_DAILY";
export const SET_FULL_FIGURES_WEEKLY = "SET_FULL_FIGURES_WEEKLY";
export const SET_FULL_FIGURES_MONTHLY = "SET_FULL_FIGURES_MONTHLY";

export const FETCH_TREND_BREAKDOWN = "FETCH_TREND_BREAKDOWN";
export const SET_TREND_BREAKDOWN = "SET_TREND_BREAKDOWN";
export const SET_KBA_TREND_BREAKDOWN = "SET_KBA_TREND_BREAKDOWN";
export const SET_LEAD_TREND_BREAKDOWN = "SET_LEAD_TREND_BREAKDOWN";
export const SET_TREND_BREAKDOWN_TOTALS = "SET_TREND_BREAKDOWN_TOTALS";

export const FETCH_TRENDLINE = "FETCH_TRENDLINE";
export const SET_TRENDLINE = "SET_TRENDLINE";
export const SET_BASELINE_DATA = "SET_BASELINE_DATA";

export const FETCH_VISITS_TREND = "FETCH_VISITS_TREND";
export const SET_VISITS_TREND = "SET_VISITS_TREND";
export const SET_VISITS_GRAPH_DATA_DAILY = "SET_VISITS_GRAPH_DATA_DAILY";
export const SET_VISITS_GRAPH_DATA_WEEKLY = "SET_VISITS_GRAPH_DATA_WEEKLY";
export const SET_VISITS_GRAPH_DATA_MONTHLY = "SET_VISITS_GRAPH_DATA_MONTHLY";

export const FETCH_KBA_VISITS_TREND_DATA = "FETCH_KBA_VISITS_TREND_DATA";
export const FETCH_KBA_BREAKDOWN_DATA = "FETCH_KBA_VISITS_TREND_DATA";

export const SET_VISITS_TREND_KBAS = "SET_VISITS_TREND_KBAS";
export const SET_VISITS_KBAS_GRAPH_DATA_DAILY = "SET_VISITS_KBAS_GRAPH_DATA_DAILY";
export const SET_VISITS_KBAS_GRAPH_DATA_WEEKLY = "SET_VISITS_KBAS_GRAPH_DATA_WEEKLY";
export const SET_VISITS_KBAS_GRAPH_DATA_MONTHLY = "SET_VISITS_KBAS_GRAPH_DATA_MONTHLY";

export const FETCH_LEAD_VISITS_TREND_DATA = "FETCH_LEAD_VISITS_TREND_DATA";
export const FETCH_LEAD_BREAKDOWN_DATA = "FETCH_LEAD_BREAKDOWN_DATA";

export const SET_VISITS_TREND_LEADS = "SET_VISITS_TREND_LEADS";
export const SET_VISITS_LEADS_GRAPH_DATA_DAILY = "SET_VISITS_LEADS_GRAPH_DATA_DAILY";
export const SET_VISITS_LEADS_GRAPH_DATA_WEEKLY = "SET_VISITS_LEADS_GRAPH_DATA_WEEKLY";
export const SET_VISITS_LEADS_GRAPH_DATA_MONTHLY = "SET_VISITS_LEADS_GRAPH_DATA_MONTHLY";

export const FETCH_BASELINE_CHART_ANNOTATIONS_DATA = "FETCH_ANNOTATIONS_DATA";
export const SET_BASELINE_CHART_ANNOTATIONS_DATA = "SET_OCE_ANNOTATIONS_DATA";

export const FETCH_VISITS_CHART_ANNOTATIONS_DATA = "FETCH_VISITS_CHART_ANNOTATIONS_DATA";
export const SET_VISITS_CHART_ANNOTATIONS_DATA = "SET_VISITS_CHART_ANNOTATIONS_DATA";

export const FETCH_OCE_ANNOTATION_KBA_TYPES = "FETCH_OCE_ANNOTATION_KBA_TYPES";
export const SET_OCE_ANNOTATION_KBA_TYPES = "SET_OCE_ANNOTATION_KBA_TYPES";

export const FETCH_OCE_ANNOTATION_LEAD_TYPES = "FETCH_OCE_ANNOTATION_LEAD_TYPES";
export const SET_OCE_ANNOTATION_LEAD_TYPES = "SET_OCE_ANNOTATION_LEAD_TYPES";

//OCE refactor
export const FETCH_NEW_TREND_BREAKDOWN = "FETCH_NEW_TREND_BREAKDOWN";
export const FETCH_NEW_KEY_INDICATOR_TOTALS = "FETCH_NEW_KEY_INDICATOR_TOTALS";
export const SET_NEW_TREND_BREAKDOWN = "SET_NEW_TREND_BREAKDOWN";
export const SET_NEW_KEY_INDICATOR_TOTALS = "SET_NEW_KEY_INDICATOR_TOTALS";
export const FETCH_KBA_LABEL_MAPPING = "FETCH_KBA_LABEL_MAPPING";
export const SET_KBA_LABEL_MAPPING = "SET_KBA_LABEL_MAPPING";
export const FETCH_LEAD_LABEL_MAPPING = "FETCH_LEAD_LABEL_MAPPING";
export const SET_LEAD_LABEL_MAPPING = "SET_LEAD_LABEL_MAPPING";

export const FETCH_WEBSITE_VISITS = "FETCH_WEBSITE_VISITS";
export const SET_WEBSITE_VISITS = "SET_WEBSITE_VISITS";
