import { Button } from "@material-ui/core";
import React, { useCallback } from "react";
import { performancePages } from "../../../../constants";
interface Props {
  setPerformancePage: React.Dispatch<React.SetStateAction<performancePages>>;
  activePage: performancePages;
}
const PerfNavButtons = (props: Props) => {
  const { setPerformancePage, activePage } = props;

  const onBtnLinkClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      const {
        currentTarget: { dataset },
      } = evt;
      const pageLink = dataset?.page ?? "volume";

      setPerformancePage(pageLink as performancePages);
    },
    [history]
  );
  return (
    <div className="perf_nav_buttons primary_tile_background">
      <div className="nav_button">
        <Button
          variant={"outlined"}
          className={`filter_btn_element ${activePage == "volume" ? "active" : ""}`}
          data-page={"volume"}
          onClick={(evt) => onBtnLinkClick(evt)}
          size={"small"}
          data-test-id={"id"}
        >
          Brand Volume
        </Button>
      </div>
      <div className="nav_button">
        <Button
          variant={"outlined"}
          className={`filter_btn_element ${activePage == "oao" ? "active" : ""}`}
          data-page={"oao"}
          onClick={(evt) => onBtnLinkClick(evt)}
          size={"small"}
          data-test-id={"id"}
        >
          Brand OaO
        </Button>
      </div>
      <div className="nav_button">
        <Button
          variant={"outlined"}
          className={`filter_btn_element ${activePage == "image-attributes" ? "active" : ""}`}
          data-page={"image-attributes"}
          onClick={(evt) => onBtnLinkClick(evt)}
          size={"small"}
          data-test-id={"id"}
        >
          Brand Image Attributes
        </Button>
      </div>
    </div>
  );
};

export default PerfNavButtons;
