import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useTable } from "react-table";
import { getExecutiveSummaryChannelMixPerformance } from "../../../../api/executiveSummaryWebsiteApi";
import "../../../../assets/styles/component/channelMixPerformanceTable.scss";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { DATE_FILTER_VALUE_MAPPING } from "../../../../constants";
import { formatToThreeDigits } from "../../../../utils/numberFormatting";

const ChannelMixPerformanceTable = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const { date_range } = useSelector((state: RootStateOrAny) => state.parameters);

  const [tableData, setTableData] = useState([]);
  const [dateString, setDateString] = useState("");

  const {
    data: { data, columns, start_date, end_date },
    refetch,
    isFetching: isLoading,
  } = useQuery({
    queryKey: ["channelMixTableData"],
    queryFn: getExecutiveSummaryChannelMixPerformance,
    enabled: false,
    initialData: {},
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  useEffect(() => {
    if (data) setTableData(data);
  }, [data]);

  useEffect(() => {
    if (Object.keys(DATE_FILTER_VALUE_MAPPING).includes(date_range)) {
      setDateString(DATE_FILTER_VALUE_MAPPING[date_range]);
    } else if (start_date && end_date) {
      const startDate = moment(start_date, "YYYY-MM-DD").format("MMM YYYY");
      const endDate = moment(end_date, "YYYY-MM-DD").format("MMM YYYY");
      setDateString(`${startDate} - ${endDate}`);
    }
  }, [date_range, start_date, end_date]);

  const cols = useMemo<any>(() => {
    const response = [
      {
        Header: "Brand",
        columns: [
          {
            id: "brand_place_holder_1",
            Header: "",
            columns: [
              {
                id: "brand_place_holder_2",
                Header: "",
                accessor: "brand",
              },
            ],
          },
        ],
      },
      {
        Header: "Country",
        columns: [
          {
            id: "market_place_holder_1",
            Header: "",
            columns: [
              {
                id: "market_placeholder_2",
                Header: "",
                accessor: "market",
              },
            ],
          },
        ],
      },
      {
        Header: `Total Private Sales (${dateString})`,
        columns: [
          {
            id: "private_sales_place_holder_1",
            Header: "",
            columns: [
              {
                id: "private_sales_placeholder_2",
                Header: "",
                accessor: "private_sales",
                Cell: ({ value }: { value: number }) => {
                  return formatToThreeDigits(value);
                },
              },
            ],
          },
        ],
      },
      {
        Header: `Total Online Sales (${dateString})`,
        columns: [
          {
            id: "online_sales_place_holder_1",
            Header: "",
            columns: [
              {
                id: "online_sales_placeholder_2",
                Header: "",
                accessor: "online_sales",
                Cell: ({ value }: { value: number }) => {
                  return formatToThreeDigits(value);
                },
              },
            ],
          },
        ],
      },
      {
        Header: `Total Online Lead (${dateString})`,
        columns: [
          {
            id: "online_lead_place_holder_1",
            Header: "",
            columns: [
              {
                id: "online_lead_placeholder_2",
                Header: "",
                accessor: "online_lead",
                Cell: ({ value }: { value: number }) => {
                  return formatToThreeDigits(value);
                },
              },
            ],
          },
        ],
      },
      {
        Header: `Avg. CR% (${dateString})`,
        columns: [
          {
            id: "avg_cr_place_holder_1",
            Header: "",
            columns: [
              {
                id: "avg_cr_placeholder_2",
                Header: "",
                accessor: "avg_cr",
                Cell: ({ value }: { value: number }) => {
                  return value ? `${value}%` : value;
                },
              },
            ],
          },
        ],
      },
    ];

    if (columns && columns.length > 0) {
      const onlineLeads: any = {
        Header: "Online Leads",
        columns: [],
      };

      columns.forEach((column: any) => {
        onlineLeads.columns.push({
          Header: column.name,
          columns: column.columns.map((col: any) => ({
            Header: `${col.name}%`,
            accessor: col.field,
            Cell: ({ value }: { value: number }) => {
              return value ? `${value}%` : value;
            },
          })),
        });
      });
      response.push(onlineLeads);
    }

    return response;
  }, [columns, dateString]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: cols,
    data: tableData,
  });

  const nissanRowSpan = useMemo(() => {
    let count = 1;
    tableData.forEach((item: any) => {
      if (item.brand == "Nissan") count += 1;
    });
    return count;
  }, [tableData]);

  const infinitiRowSpan = useMemo(() => {
    let count = 1;
    tableData.forEach((item: any) => {
      if (item.brand == "Infiniti") count += 1;
    });
    return count;
  }, [tableData]);

  return isLoading ? (
    <LoadingEllipsis isLoading={isLoading} />
  ) : (
    <table {...getTableProps()} className="channel-mix-performance-table">
      <thead>
        {headerGroups.map((headerGroup) => {
          return (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
              {headerGroup.headers.map((column, idx) => {
                return typeof column.Header == "function" || column.Header == "" ? null : (
                  <th {...column.getHeaderProps()} key={column.getHeaderProps().key} rowSpan={idx <= 5 ? 3 : undefined}>
                    {column.render("Header")}
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={row.getRowProps().key}>
              {row.cells.map((cell) => {
                const className =
                  cell.value != null
                    ? cell.column.Header == "CR%"
                      ? "cr"
                      : cell.column.Header == "Lead%"
                      ? "lead"
                      : undefined
                    : undefined;

                return cell.column.id == "brand_place_holder_2" ? (
                  i == 0 ? (
                    <td
                      {...cell.getCellProps()}
                      key={cell.getCellProps().key}
                      rowSpan={cell.value == "Nissan" ? nissanRowSpan : cell.value == "Infiniti" ? infinitiRowSpan : undefined}
                    >
                      {cell.render("Cell")}
                    </td>
                  ) : null
                ) : (
                  // renderer for cell // If the cell is aggregated, use the Aggregated // For cells with repeated values, render null
                  <td {...cell.getCellProps()} key={cell.getCellProps().key} className={className}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
});

export default ChannelMixPerformanceTable;
