import { number } from "@amcharts/amcharts4/core";
import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useMemo, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import mobileIcon from "../../../../assets/images/mobile-icon.png";
import pcIcon from "../../../../assets/images/pc-icon.png";
import tabletIcon from "../../../../assets/images/tablet-icon.png";
import { ErrorMsg } from "../../../../components/AppMessages";
import VocDonutChart from "../../../../components/Charts/VOC/VocDonutChart";
import VocLineTrendChart from "../../../../components/Charts/VOC/VocLineTrendChart";
import VocStackedBarChart from "../../../../components/Charts/VOC/VocStackedBarChart";
import { UserEngagementMarketFilter } from "../../../../components/Filters/common/UserEngagementMarketFiler";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { ToggleSwitch } from "../../../../components/Toogle";
import { BLUE, LIGHT_GREEN, PEACH, PURE_ORANGE, RED, VOC_START_RATING_CHART_COLORS } from "../../../../constants";
import { ThemeContext } from "../../../../context";
import { plusSignNumbers } from "../../../../utils/utilityFunctions";

const donutColorMapping: Array<string> = [BLUE, RED, PURE_ORANGE];

const stackedTrendSeries = [
  {
    name: "Green",
    field: "green",
    seriesColor: LIGHT_GREEN,
  },
  {
    name: "Red",
    field: "red",
    seriesColor: PEACH,
  },
];

const ExitSurvey = () => {
  const themeContext = useContext(ThemeContext);

  const { objective_completion_market: objectiveCompletionMarket } = useSelector((state: RootStateOrAny) => state.parameters);
  const { Current: currentObjectiveCompletion, YoY: yoyObjectiveCompletion } = useSelector(
    (state: RootStateOrAny) => state.voc.exit_survey.objective_completion
  );
  const { Current: currentObjectiveCompletionSelectedMarkets, YoY: yoyObjectiveCompletionSelectedMarkets } = useSelector(
    (state: RootStateOrAny) => state.voc.exit_survey.objecive_completion_of_selected_markets
  );
  const objectiveCompletionTrendData = useSelector((state: RootStateOrAny) => state.voc.exit_survey.objective_completion_trend);
  const { Current: currentFeedback, YoY: yoyFeedback } = useSelector((state: RootStateOrAny) => state.voc.exit_survey.feedback);
  const nonCompletionReasonsData = useSelector((state: RootStateOrAny) => state.voc.exit_survey.non_completion_reasons);
  const deviceDistributionData = useSelector((state: RootStateOrAny) => state.voc.exit_survey.device_distribution);
  const { data: objectiveCompletionMarketTrend, series: objectiveCompletionMarketSeries } = useSelector(
    (state: RootStateOrAny) => state.voc.exit_survey.objective_completion_by_market
  );
  const { market: marketList } = useSelector((state: RootStateOrAny) => state.filters.voc_filters);
  const {
    exit_survey_objective_completion: objectiveCompletionLoading,
    exit_survey_objective_completion_trend: objectiveCompletionTrend,
    exit_survey_feedback: feedbackLoading,
    exit_survey_objective_non_completion_reasons: nonCompletionLoading,
    exit_survey_device_distribution: deviceDistributionLoading,
    exit_survey_objective_completion_by_market: completionByMarketLoading,
    exit_survey_objective_completion_selected_markets: completionBySelectedMarketsLoading,
  } = useSelector((state: RootStateOrAny) => state.loading);

  const [completionTrendIsVolume, setCompletionTrendIsVolume] = useState(true);

  const coloredNonCompletionReasonsData = useMemo(() => {
    return nonCompletionReasonsData.map((item: any, index: number) => ({
      ...item,
      color: donutColorMapping[index],
    }));
  }, [nonCompletionReasonsData]);

  const deviceRatings = useMemo(() => {
    const result: Record<string, any> = {
      current: {
        pc: null,
        tablet: null,
        mobile: null,
      },
      previous: {
        pc: null,
        tablet: null,
        mobile: null,
      },
    };
    if (deviceDistributionData && deviceDistributionData.Current) {
      const pcCurrentValue = deviceDistributionData.Current.find((item: any) => item.device_type == "Computer")?.objective_completion;
      const tabletCurrentValue = deviceDistributionData.Current.find((item: any) => item.device_type == "Tablet")?.objective_completion;
      const mobileCurrentValue = deviceDistributionData.Current.find((item: any) => item.device_type == "Mobile")?.objective_completion;
      result.current.pc = pcCurrentValue != null ? pcCurrentValue : null;
      result.current.tablet = tabletCurrentValue != null ? tabletCurrentValue : null;
      result.current.mobile = mobileCurrentValue != null ? mobileCurrentValue : null;
    }
    if (deviceDistributionData && deviceDistributionData.YoY) {
      const pcYoYValue = deviceDistributionData.YoY.find((item: any) => item.device_type == "Computer")?.pts_objective_completion;
      const tabletYoYValue = deviceDistributionData.YoY.find((item: any) => item.device_type == "Tablet")?.pts_objective_completion;
      const mobileYoYValue = deviceDistributionData.YoY.find((item: any) => item.device_type == "Mobile")?.pts_objective_completion;
      result.previous.pc = pcYoYValue != null ? pcYoYValue : null;
      result.previous.tablet = tabletYoYValue != null ? tabletYoYValue : null;
      result.previous.mobile = mobileYoYValue != null ? mobileYoYValue : null;
    }
    return result;
  }, [deviceDistributionData]);

  const formattedSeries = useMemo(() => {
    if (objectiveCompletionMarketSeries) {
      return objectiveCompletionMarketSeries.map((marketItem: any, index: number) => ({
        name: marketItem.market,
        field: marketItem.field,
        seriesColor: VOC_START_RATING_CHART_COLORS[marketItem.market as string],
      }));
    }
    return [];
  }, [objectiveCompletionMarketSeries]);

  const marketFilterValue = useMemo(() => {
    let value = "All";
    if (marketList && objectiveCompletionMarket) {
      const selectedMarketsArr = objectiveCompletionMarket.split(",");
      value =
        selectedMarketsArr?.length === marketList.length
          ? "All"
          : selectedMarketsArr?.length > 1
          ? `${selectedMarketsArr?.length} markets selected`
          : objectiveCompletionMarket;
    }
    return value;
  }, [marketList, objectiveCompletionMarket]);

  const getDeviceRatingColorClass = (value: number) => {
    let color = "";
    if (value == undefined || number == null) {
      return color;
    } else if (value >= 80) {
      color = "textGreen";
    } else if (value >= 51) {
      color = "textAmber";
    } else {
      color = "textRed";
    }

    return color;
  };

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className="row grid">
        <div className="tile min-h-20">
          <div className="tileHeader">Proportional objective completion</div>
          <div className="ratings">
            {objectiveCompletionLoading ? (
              <LoadingEllipsis isLoading={objectiveCompletionLoading} />
            ) : (
              <>
                <div className="value">
                  <p className="actualValue">
                    {currentObjectiveCompletion && currentObjectiveCompletion[0]?.objective_completion != null
                      ? `${currentObjectiveCompletion[0]?.objective_completion?.toFixed()}%`
                      : "n/a"}
                  </p>
                  <p>of users answered &apos;yes&apos;</p>
                </div>
                <div className="popValue">
                  {`${
                    yoyObjectiveCompletion && yoyObjectiveCompletion[0]?.pts_objective_completion != null
                      ? plusSignNumbers(yoyObjectiveCompletion[0]?.pts_objective_completion) + " PTS"
                      : "n/a"
                  }`}{" "}
                  vs prev. period
                </div>
              </>
            )}
          </div>
        </div>
        <div className="tile min-h-20 grid-col-span-4">
          <div className="space-between">
            <div className="tileHeader">Objective completion trendline</div>
            <ToggleSwitch
              activeToggleLabel={"Volume"}
              inactiveToggleLabel={"Proportion"}
              active={completionTrendIsVolume}
              handleToggleClick={() => setCompletionTrendIsVolume((prevState) => !prevState)}
              toggleClassName={"filter_toggle"}
            />
          </div>
          <VocStackedBarChart
            chartId="vocObjectiveCompletionTrend"
            data={
              objectiveCompletionTrendData
                ? completionTrendIsVolume
                  ? objectiveCompletionTrendData.volume
                  : objectiveCompletionTrendData.proportion
                : []
            }
            seriesList={stackedTrendSeries}
            theme={themeContext.theme}
            isVolume={completionTrendIsVolume}
            isLoading={objectiveCompletionTrend}
          />
        </div>
      </div>
      <div className="row grid">
        <div className="tile min-h-20">
          <div className="tileHeader"># feedbacks</div>
          <div className="ratings">
            {feedbackLoading ? (
              <LoadingEllipsis isLoading={feedbackLoading} />
            ) : (
              <>
                <div className="value">
                  <span className="actualValue">
                    {currentFeedback && currentFeedback[0]?.feedback != null ? currentFeedback[0]?.feedback.toLocaleString() : "n/a"}
                  </span>
                </div>
                <div className="popValue">
                  {yoyFeedback && yoyFeedback[0]?.pts_feedback != null ? plusSignNumbers(yoyFeedback[0]?.pts_feedback) : "n/a"} vs prev.
                  period
                </div>
              </>
            )}
          </div>
        </div>
        <div className="tile min-h-20 grid-col-span-2">
          <div className="tileHeader">Reason for non-completion</div>
          <VocDonutChart
            chartId="vocNonCompletionReasons"
            valueField="score"
            categoryField="reason"
            theme={themeContext.theme}
            data={coloredNonCompletionReasonsData}
            isLoading={nonCompletionLoading}
          />
        </div>
        <div className="tile h-50 grid-col-span-2">
          <div className="tileHeader">Objective completion by device</div>
          <div className="devicesContainer">
            <div className="device">
              {deviceDistributionLoading ? (
                <LoadingEllipsis isLoading={deviceDistributionLoading} />
              ) : (
                <>
                  <div className={`rating`}>
                    <span className={`${getDeviceRatingColorClass(deviceRatings?.current?.pc)}`}>
                      {deviceRatings?.current?.pc != null ? `${deviceRatings.current.pc?.toFixed()}%` : "n/a"}
                    </span>
                    <br />
                    <span className="rating-subtext">of users answered &apos;yes&apos;</span>
                  </div>
                  <img className="deviceIcon" src={pcIcon} />
                  <div className="variance">
                    {deviceRatings.previous.pc != null ? `${plusSignNumbers(deviceRatings.previous.pc)} PTS` : "n/a"} vs prev. period
                  </div>
                </>
              )}
            </div>
            <div className="device">
              {deviceDistributionLoading ? (
                <LoadingEllipsis isLoading={deviceDistributionLoading} />
              ) : (
                <>
                  <div className={`rating`}>
                    <span className={`${getDeviceRatingColorClass(deviceRatings?.current?.tablet)}`}>
                      {deviceRatings?.current?.tablet != null ? `${deviceRatings.current.tablet?.toFixed()}%` : "n/a"}
                    </span>
                    <br />
                    <span className="rating-subtext ">of users answered &apos;yes&apos;</span>
                  </div>
                  <img className="deviceIcon tableIcon" src={tabletIcon} />
                  <div className="variance">
                    {deviceRatings.previous.tablet != null ? `${plusSignNumbers(deviceRatings.previous.tablet)} PTS` : "n/a"} vs prev.
                    period
                  </div>
                </>
              )}
            </div>
            <div className="device">
              {deviceDistributionLoading ? (
                <LoadingEllipsis isLoading={deviceDistributionLoading} />
              ) : (
                <>
                  <div className={`rating`}>
                    <span className={`${getDeviceRatingColorClass(deviceRatings?.current?.mobile)}`}>
                      {deviceRatings?.current?.mobile != null ? `${deviceRatings.current.mobile?.toFixed()}%` : "n/a"}
                    </span>
                    <br />
                    <span className="rating-subtext">of users answered &apos;yes&apos;</span>
                  </div>
                  <img className="deviceIcon" src={mobileIcon} />
                  <div className="variance">
                    {deviceRatings.previous.mobile != null ? `${plusSignNumbers(deviceRatings.previous.mobile)} PTS` : "n/a"} vs prev.
                    period
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row grid">
        <div className="tile h-30 grid-col-span-5">
          <div className="space-between">
            <div className="tileHeader">
              <p>Objective completion by market</p>
              <Tooltip
                position="right"
                className={"normal_tippy"}
                trigger="mouseenter"
                animation="none"
                interactive={true}
                delay={1}
                hideDelay={1}
                duration={1}
                title={"The graphs have unique filters and won’t be impacted by the geography main filter"}
                size={"small"}
              >
                <span className="info_tooltip" />
              </Tooltip>
            </div>

            <UserEngagementMarketFilter
              filterValue={marketFilterValue}
              marketsList={marketList}
              marketParamValue={objectiveCompletionMarket}
              parameterName="objective_completion_market"
            />
          </div>

          <div className="average-star-rating-tile" style={{ display: "flex", height: "100%" }}>
            <VocLineTrendChart
              chartId="vocObjectiveCompletionTrendline"
              data={objectiveCompletionMarketTrend ? objectiveCompletionMarketTrend : []}
              seriesList={formattedSeries}
              theme={themeContext.theme}
              showLegend={true}
              isPercentage={true}
              disableLegendClick={true}
              isLoading={completionByMarketLoading || completionBySelectedMarketsLoading}
            />
            <div className="m-auto text-center">
              <div className="tileHeader">Proportion of users who reached their objective</div>
              <div className="ratings">
                <div className="value">
                  <span className={`actualValue`}>
                    {currentObjectiveCompletionSelectedMarkets && currentObjectiveCompletionSelectedMarkets[0]?.objective_completion != null
                      ? `${currentObjectiveCompletionSelectedMarkets[0]?.objective_completion?.toFixed()}%`
                      : "n/a"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default ExitSurvey;
