import React, { useCallback, useEffect, useMemo, useState } from "react";
import Ratings from "../../../components/Ratings/Ratings";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getDmeoCampaignPerformanceDetails } from "../../../api/dmeoApi";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { isNull } from "../../../utils/utilityFunctions";
import NewRatings from "../../../components/Ratings/NewRatings";

import DmeoCampaignPerformanceDetailsTable from "../../../components/Tables/DMEOCampaignPerformaceDetailsTable";

const data = [
  {
    campaign: "Camp 1",
    visits: 10,
    single_visits: 10,
    qualified_visits: 10,
    kbas: 10,
    leads: 10,
    lead_conversion: 10,
    kba_conversion: 10,
  },
  {
    campaign: "Camp 2",
    visits: 10,
    single_visits: 10,
    qualified_visits: 10,
    kbas: 10,
    leads: 10,
    lead_conversion: 10,
    kba_conversion: 10,
  },
  {
    campaign: "Camp 3",
    visits: 10,
    single_visits: 10,
    qualified_visits: 10,
    kbas: 10,
    leads: 10,
    lead_conversion: 10,
    kba_conversion: 10,
  },
  {
    campaign: "Camp 4",
    visits: 10,
    single_visits: 10,
    qualified_visits: 10,
    kbas: 10,
    leads: 10,
    lead_conversion: 10,
    kba_conversion: 10,
  },
];

const CampaignPerformanceDetailsTile = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const [filterValue, setFilterValue] = useState("");
  const [searchCriteria, setSearchCriteria] = useState("CONTAINS");
  const [tableData, setTableData] = useState<Array<any>>([]);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["campaignPerformanceDetailsData"],
    queryFn: getDmeoCampaignPerformanceDetails,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  useEffect(() => {
    Array.isArray(data) && setTableData(data);

    return () => {
      setTableData([]);
    };
  }, [data]);

  const columns = useMemo(
    () => [
      {
        id: "campaign_name",
        Header: "Campaign",
        accessor: "campaign_name",
        defaultCanSort: true,
        width: 45,
        Cell: ({ value }: { value: string }) => <div className="cell-data-campaign-name">{value}</div>,
      },
      {
        id: "visits",
        Header: "Visits",
        accessor: "visits",
        defaultCanSort: true,
        width: 40,
        Cell: ({ value }: { value: number }) => (
          <div className="cell-data">
            {value?.toLocaleString()}
            {!isNull(value) && <NewRatings value={value} />}
          </div>
        ),
      },
      {
        id: "single_page_visits",
        Header: "Single Page Visits",
        accessor: "single_page_visits",
        defaultCanSort: true,
        width: 40,
        Cell: ({ value }: { value: number }) => (
          <div className="cell-data">
            {value?.toLocaleString()}
            {!isNull(value) && <NewRatings value={value} />}
          </div>
        ),
      },
      {
        id: "qualified_visits",
        Header: "Qualified Visits",
        accessor: "qualified_visits",
        defaultCanSort: true,
        width: 40,
        Cell: ({ value }: { value: number }) => (
          <div className="cell-data">
            {value?.toLocaleString()}
            {!isNull(value) && <NewRatings value={value} />}
          </div>
        ),
      },
      {
        id: "kbas",
        Header: "KBAs",
        accessor: "kbas",
        defaultCanSort: true,
        width: 35,
        Cell: ({ value }: { value: number }) => (
          <div className="cell-data">
            {value?.toLocaleString()}
            {!isNull(value) && <NewRatings value={value} />}
          </div>
        ),
      },
      {
        id: "kba_conversion_rate",
        Header: "KBAs Conversion",
        accessor: "kba_conversion_rate",
        defaultCanSort: true,
        width: 50,
        Cell: ({ value }: { value: number }) => (
          <div className="cell-data">
            {value?.toLocaleString()}
            {!isNull(value) && "%"}
            {!isNull(value) && <NewRatings value={value} />}
          </div>
        ),
      },
      {
        id: "cost_per_kba",
        Header: "Cost per KBAs",
        accessor: "cost_per_kba",
        defaultCanSort: true,
        width: 40,
        Cell: ({ value }: { value: number }) => (
          <div className="cell-data">
            {value?.toLocaleString()}
            {!isNull(value) && <NewRatings value={value} />}
          </div>
        ),
      },
      {
        id: "leads",
        Header: "Leads",
        accessor: "leads",
        defaultCanSort: true,
        width: 35,
        Cell: ({ value }: { value: number }) => (
          <div className="cell-data">
            {value?.toLocaleString()}
            {!isNull(value) && <NewRatings value={value} />}
          </div>
        ),
      },
      {
        id: "leads_conversion_rate",
        Header: "Lead Conversion",
        accessor: "leads_conversion_rate",
        defaultCanSort: true,
        width: 45,
        Cell: ({ value }: { value: number }) => (
          <div className="cell-data">
            {value?.toLocaleString()}
            {!isNull(value) && "%"}
            {!isNull(value) && <NewRatings value={value} />}
          </div>
        ),
      },
      {
        id: "cost_per_lead",
        Header: "Cost per Leads",
        accessor: "cost_per_lead",
        defaultCanSort: true,
        width: 40,
        Cell: ({ value }: { value: number }) => (
          <div className="cell-data">
            {value?.toLocaleString()}
            {!isNull(value) && <NewRatings value={value} />}
          </div>
        ),
      },
    ],
    [data]
  );

  return (
    <div className="tile">
      <div className="tile-header">Campaign performance details</div>

      <div className="tile-content">
        {isFetching ? (
          <LoadingEllipsis isLoading={isFetching} />
        ) : (
          <DmeoCampaignPerformanceDetailsTable
            data={tableData}
            columns={columns}
            sortBy={[{ id: "visits", desc: true }]}
            showHidden={false}
            selected={"All"}
            showChildRows={false}
            canSort={true}
            filters={searchCriteria}
            filter={filterValue}
            isVisitsQuery={false}
          />
        )}
      </div>
    </div>
  );
});

export default CampaignPerformanceDetailsTile;
