import { useEffect } from "react";
import { Hooks, TableInstance } from "react-table";

export interface UseStartExpandedState<D extends Record<string, unknown>> {
  startExpanded: boolean;
}

export const useStartExpanded = <D extends Record<string, unknown>>(hooks: Hooks<D>): void => {
  hooks.useInstance.push(useInstance);
};
useStartExpanded.pluginName = "useStartExpanded";

const useInstance = <D extends Record<string, unknown>>(instance: TableInstance<D>): void => {
  const {
    state: { startExpanded },
    toggleAllRowsExpanded,
  } = instance;

  useEffect(() => {
    if (startExpanded) toggleAllRowsExpanded(true);
  }, [startExpanded, toggleAllRowsExpanded]);
};
