import React, { useEffect } from "react";
import "../../assets/styles/component/documentation.scss";
import { SwitchDocumentation } from "./common/SwitchDocumentation";

interface Props {
  isVisible: boolean;
  documentation: string;
  onCloseClick: () => void;
}

export const Documentation = (props: Props): JSX.Element => {
  const { isVisible, documentation, onCloseClick } = props;

  let modalNode: HTMLDivElement;

  const handleOutsideClick = (e: any) => {
    if (!modalNode.contains(e.target)) {
      onCloseClick();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.addEventListener("click", handleOutsideClick, true);
    }
    return () => {
      document.removeEventListener("click", handleOutsideClick, true);
    };
  }, [isVisible]);

  return (
    <div
      ref={(node) => {
        if (node) modalNode = node;
      }}
      className={`popup documentation_popup ${isVisible ? "show" : ""}`}
    >
      <div className="popupModal">
        <button className="closePopup" onClick={onCloseClick}>
          ╳
        </button>
        <div className="data_message">
          <SwitchDocumentation value={documentation} />
        </div>
      </div>
    </div>
  );
};
