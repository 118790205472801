import React, { useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  fetchMidasSpendVsTrafficFilters,
  fetchMidasSpendVsTrafficLastDataRefresh,
  fetchSpendVsTrafficMarketData,
  fetchSpendVsTrafficModelData,
  setPageName,
  setPageTitleDescription,
} from "../../../actions";
import "../../../assets/styles/pages/midasUnderConstruction.scss";
import "../../../assets/styles/pages/spendVsTraffic.scss";
import { NoDataMsg } from "../../../components/AppMessages";
import MarketingInvastmentDashboardFilters from "../../../components/Filters/MarketingInvastmentDashboardFilters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import { MIDAS_BTN_FILTERS } from "../../../constants";
import { DashboardBannerContext } from "../../../context";
import SpendVsTrafficGraph from "./components/SpendVsTrafficGraph";
const SpendVsTraffic = () => {
  const dispatch = useDispatch();
  const { setBannerText, setShowBanner } = useContext(DashboardBannerContext);

  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.midas_fmi_last_refresh_date);

  const params = useSelector((state: RootStateOrAny) => state.parameters);
  const { brand, date_range, market, granularity, model, local_currency_name, mc_group, subgroup, yr_scnr_rv } = useSelector(
    (state: RootStateOrAny) => state.parameters
  );

  const filters: any = useSelector((state: RootStateOrAny) => state.filters.midas_filters);
  const responseError = useSelector((state: RootStateOrAny) => state.midas.response_error);

  const pageName = "Spend vs Traffic";
  const [inAccessibleDashboard, setInAccessibleDashboard] = useState<boolean>(false);

  useEffect(() => {
    Promise.all([
      // Sets page name
      dispatch(setPageName("Spend vs Traffic")),
      dispatch(setPageTitleDescription("(Data: ACT only. <No FC displayed>)")),
      dispatch(fetchMidasSpendVsTrafficLastDataRefresh()),
    ]);
    setBannerText("Forex Rate based from latest BP");
    setShowBanner(false);
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(filters).length) Promise.all([dispatch(fetchSpendVsTrafficMarketData()), dispatch(fetchSpendVsTrafficModelData())]);
  }, [brand, model, mc_group, subgroup, market, date_range, granularity, local_currency_name, filters]);

  useEffect(() => {
    if (brand) dispatch(fetchMidasSpendVsTrafficFilters());
  }, [brand, model, market, date_range, yr_scnr_rv, granularity, mc_group, subgroup]);

  useEffect(() => {
    setInAccessibleDashboard(responseError?.error?.includes("invalid_scope"));
  }, [filters, responseError]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={
          <MarketingInvastmentDashboardFilters
            hasDateFilter
            hasMarketFilter
            hasModel
            filterBtns={MIDAS_BTN_FILTERS}
            hasGranularity
            noNscFilter
            noYearScenarioFilter
            isCustom
            inComplete={inAccessibleDashboard}
            showModelTotal={true}
          />
        }
        dataDocumentation="midas"
        lastDataRefresh={lastDataRefresh}
        pageTitle={<PageTitle dataDocumentation="midas" showPageTitleDescription={true} />}
      >
        {inAccessibleDashboard ? (
          <div className="under_construction_content primary_background">
            <NoDataMsg message="This dashboard page is not available for the selected geographies." />
          </div>
        ) : (
          <div className="midas_spend_vs_traffic_sotu">
            <SpendVsTrafficGraph />
          </div>
        )}
      </DefaultPageContent>
    </DashboardLayout>
  );
};

export default SpendVsTraffic;
