// Todo: Move some effects to sagas instead of useEffect
import { ErrorBoundary } from "@sentry/react";
import "moment-fquarter";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchCockpitFiltersData, setParameters, setSpecificParameter } from "../../actions";
import "../../assets/styles/component/filters.scss";
import { ALL_COUNTRIES, ALL_OPTION, ALL_OPTION_NO_SPACE, DEFAULT_CCS_PARAMETERS } from "../../constants";
import { FilterContext } from "../../context";
import { checkConsecutiveMonths } from "../../pages/CCS Vital Signs/subs/utils";
import { groupByKeys } from "../../utils/computeFunctions";
import { FY_TWENTY } from "../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { currentDefaultFiltersSet, defaultFiltersSet } from "../../utils/utilityFunctions";
import { ErrorMsg } from "../AppMessages";
import { FiltersLayout } from "../Layouts";
import { DefaultFilter, FilterBtns, ModelFilter, MultiDateRangeWithPresets, MultiSelectFilter } from "./common";
import { CockpitGeographyFilter } from "./index";
import { getGeographyList } from "./subs/filterFunctions";
import { closeFilterOptions, closeMarketOptions } from "./subs/helpers";

interface Props {
  filterBtns: Array<{ id: string; navigateTo: string; name: string }>;
}

export const CcsCockpitFilters = withRouter((props: Props & RouteComponentProps) => {
  const { history, filterBtns } = props;

  const dispatch = useDispatch();

  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.parameters.date_range);
  const regionParamValue = useSelector((state: RootStateOrAny) => state.parameters.region);
  const marketParamValue = useSelector((state: RootStateOrAny) => state.parameters.market);
  const modelParamValue = useSelector((state: RootStateOrAny) => state.parameters.model);
  const revenueTypeParamValue = useSelector((state: RootStateOrAny) => state.parameters.revenue_type);
  const { service_type: serviceTypeParamValue, trigger_type: triggerTypeParamValue } = useSelector(
    (state: RootStateOrAny) => state.parameters
  );
  const userEngagementMarket = useSelector((state: RootStateOrAny) => state.parameters.user_engagement_market);
  const enrolledUserMarket = useSelector((state: RootStateOrAny) => state.parameters.enrolled_user_market);

  const { date_range, geography_model, geography } = useSelector((state: RootStateOrAny) => state.filters.cockpit_data);
  const { geography_model: availableModels } = useSelector((state: RootStateOrAny) => state.filters.cockpit_data);

  const [marketOptionsVisible, setMarketOptionsVisible] = useState(false);

  const geographies = useMemo(() => getGeographyList({ filters: geography }), [geography]);

  const brandParamValue = useSelector((state: RootStateOrAny) => state.parameters.brand);

  const [brands, setBrands] = useState<string[]>(["All", "Nissan", "Infiniti"]);

  const [dateParam, setDateParam] = useState<string>(dateRangeParamValue);
  const [regionParam, setRegionParam] = useState<string>(regionParamValue);
  const [marketParam, setMarketParam] = useState<string>(marketParamValue);
  const [modelParam, setModelParam] = useState<string>(modelParamValue);
  const [brandParam, setBrandParam] = useState<string>(brandParamValue);

  // Filter input values
  const [modelFilterValue, setModelFilterValue] = useState<string>("");
  const [dateRangeFilterValue, setDateRangeFilterValue] = useState<string>("");

  const [geographyValue, setGeographyValue] = useState<string>(marketParamValue === ALL_COUNTRIES ? regionParamValue : marketParamValue);

  const regions = ["Japan", "Europe", "United States", "China", "UAE"];

  const [geographyList, setGeographyList] = useState<Array<any>>([]);

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("CCS vital signs");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_CCS_PARAMETERS));
      currentDefaultFiltersSet("CCS vital signs");
    }
  }, []);

  useEffect(() => {
    // dispatch(setParameters(DEFAULT_CCS_PARAMETERS));
    dispatch(fetchCockpitFiltersData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setSpecificParameter("region", ALL_OPTION));
    dispatch(setSpecificParameter("market", ALL_COUNTRIES));
  }, [brandParamValue]);

  //set brand value
  useEffect(() => {
    setBrandParam(brandParamValue);
  }, [brandParamValue]);

  useEffect(() => {
    if (!/all/gi.test(brandParamValue)) {
      const geos = [{ region: "All" }];

      for (const geo of geographies) {
        if (!/all/gi.test(geo.region)) {
          const geographyModelItem = geography_model.find((item: any) => item.region == geo.region);
          if (geographyModelItem) {
            const hasBrand = geographyModelItem.data_per_brand.find((item: any) => item.brand == brandParamValue);
            if (hasBrand) {
              geos.push(geo);
            }
          }
        }
      }
      setGeographyList(geos);
    } else {
      setGeographyList([
        { region: "All" },
        { region: "China" },
        { region: "Europe" },
        { region: "Japan" },
        { region: "North America" },
        { region: "UAE" },
      ]);
    }
  }, [geographies, geography, geography_model, geographyValue, brandParamValue]);

  // Set geography value
  useEffect(() => {
    if (
      regions.includes(regionParamValue) ||
      regions.includes(marketParamValue) ||
      regionParamValue == ALL_OPTION_NO_SPACE ||
      marketParamValue == ALL_COUNTRIES
    ) {
      setMarketParam(marketParamValue);
      setRegionParam(regionParamValue);
      setGeographyValue(
        marketParamValue === ALL_COUNTRIES ? (regionParamValue == "North America" ? "United States" : regionParamValue) : marketParamValue
      );
    } else {
      dispatch(setSpecificParameter("region", ALL_OPTION_NO_SPACE));
      dispatch(setSpecificParameter("market", ALL_OPTION_NO_SPACE));
      setMarketParam(ALL_COUNTRIES);
      setRegionParam(ALL_OPTION_NO_SPACE);
      setGeographyValue(ALL_OPTION_NO_SPACE);
    }
  }, [regionParamValue, marketParamValue, geographyValue, brandParamValue, regions]);

  const groupedDateRangeData = useMemo(() => {
    const groupedDate = groupByKeys(date_range, "year");
    return Object.keys(groupedDate).map((year) => ({ year, child: groupedDate[year] }));
  }, [date_range]);

  const models = useMemo(() => {
    if (geography_model && regions.includes(geographyValue)) {
      if (!/all/gi.test(geographyValue) && !/all/gi.test(brandParamValue)) {
        const allRe = new RegExp("all", "gi");
        const geographyModelItem = geography_model.find((item: any) => item.region == regionParamValue);
        console.log(geographyModelItem);
        if (geographyModelItem) {
          const models: Array<{ model: string; counter: number }> = allRe.test(geographyValue)
            ? []
            : geographyModelItem.data_per_brand.find((item: any) => item.brand == brandParamValue)?.data;
          const sub_model = models?.filter((model) => !/all/gi.test(model.model))?.map((model) => model.model) ?? [];
          if (sub_model.includes("Note") && sub_model.includes("Aura")) {
            sub_model.splice(sub_model.indexOf("Aura"), 1);
            const myIndex = sub_model.indexOf("Note");
            sub_model.splice(myIndex + 1, 0, "Aura");
          }

          return sub_model;
        }
      }
      return [];
    }

    return [];
  }, [geography_model, geographyValue, brandParamValue, regionParamValue]);

  useEffect(() => {
    if (models && models.length) {
      if (modelParamValue !== "All") {
        const modelsMatch = modelParamValue.split(",").every((model: string) => {
          return models.includes(model.replaceAll("\\s+", " ").toUpperCase());
        });
        if (models.length > 0 && !modelsMatch) {
          dispatch(setSpecificParameter("model", "All"));
        }
      }
    }
  }, [modelParamValue, models, dispatch]);

  // Updates date range filter value
  useEffect(() => {
    if (date_range) {
      // Todo: Updates date range values
      const selectedDateArray: Array<string> = dateRangeParamValue?.split(",");
      const availableDateOptions: Array<string> = date_range?.map(
        (dates: { year: number; month: string }) => `${dates.month} ${dates.year}`
      );

      const isFY20 = selectedDateArray.length === FY_TWENTY.length && selectedDateArray.every((dateValue) => FY_TWENTY.includes(dateValue));

      const checkConsecutive = checkConsecutiveMonths(selectedDateArray, availableDateOptions);

      const dateRangeInputValue = isFY20
        ? "FY20"
        : selectedDateArray.length > 1
          ? checkConsecutive.isConsecutive
            ? checkConsecutive.consecutiveDateRange
            : `${selectedDateArray?.length} months selected`
          : dateRangeParamValue;

      const isValidCCSDate: boolean = selectedDateArray.reduce(
        (result: boolean, date) => result && (date == ALL_OPTION_NO_SPACE ? true : availableDateOptions.includes(date)),
        true
      );

      const availableCalendarYears = Array.from(new Set(date_range.map((option: { month: string; year: number }) => option.year)));

      // Sets the default date selection to the latest available calendar year
      if (!isValidCCSDate) {
        const defaultSelection = availableDateOptions.filter((option) => {
          const calendarYearRe = new RegExp(`${availableCalendarYears[availableCalendarYears.length - 1]}`, "gi");

          return calendarYearRe.test(option);
        });

        dispatch(setSpecificParameter("date_range", defaultSelection.join(",")));
        dispatch(setSpecificParameter("dates", defaultSelection.join(",")));
      }
      setDateParam(dateRangeParamValue);
      setDateRangeFilterValue(dateRangeInputValue);
    }
  }, [dateRangeParamValue, date_range]);

  // Update model value
  useEffect(() => {
    if (!models?.length) {
      dispatch(setSpecificParameter("model", "All"));
    } else {
      if (modelParamValue && regionParamValue && marketParamValue && models) {
        if (regions.includes(regionParamValue) || regions.includes(marketParamValue)) {
          const regionMarketValue = /all/gi.test(marketParamValue) ? regionParamValue : marketParamValue;

          // const modelListArray: Array<Array<{ model: string; counter: number }>> = Object.values(
          //   availableModels[/all/gi.test(regionMarketValue) ? "" : regionMarketValue]
          // );
          // const modelList = modelListArray[0].map((availableModel: { model: string; counter: number }) => availableModel.model);

          const selectedModels: Array<string> = modelParamValue.split(",");

          const isValidModel = selectedModels.reduce((result: boolean, model) => result && models?.includes(model), true);

          if (isValidModel) dispatch(setSpecificParameter("model", modelParamValue));
        }
      }
    }
  }, [modelParamValue, models, regionParamValue, marketParamValue, availableModels]);

  // Sets model value
  useEffect(() => {
    const selectedModels =
      models?.length === modelParamValue.split(",")?.length
        ? "All"
        : modelParamValue.includes(",")
          ? `${modelParamValue.split(",")?.length} models selected`
          : modelParamValue;
    setModelParam(modelParamValue);
    setModelFilterValue(selectedModels);
  }, [modelParamValue, models]);

  useEffect(() => {
    userEngagementMarket == "undefined" && dispatch(setSpecificParameter("user_engagement_market", "All"));
  }, [userEngagementMarket]);

  // Set params
  useEffect(() => {
    const params = new URLSearchParams();

    params.set("date_range", dateParam);
    params.set("brand", brandParam);
    params.set("region", regionParam);
    params.set("market", marketParam);
    params.set("model", modelParam);
    params.set("service_type", serviceTypeParamValue);
    params.set("trigger_type", triggerTypeParamValue);
    params.set("user_engagement_market", userEngagementMarket);
    params.set("enrolled_user_market", enrolledUserMarket);
    revenueTypeParamValue && params.set("revenue_type", revenueTypeParamValue);
    history.push({ search: `?${params.toString()}` });
  }, [
    dateParam,
    regionParam,
    marketParam,
    modelParam,
    history,
    brandParam,
    serviceTypeParamValue,
    triggerTypeParamValue,
    userEngagementMarket,
    enrolledUserMarket,
    revenueTypeParamValue,
  ]);

  const showFilterOptionsFn = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const optionsId = dataset?.optionsUl;
      const shownClass = "showOptions";

      const optionsElement = optionsId ? document.getElementById(optionsId) : undefined;

      if (showFilterOptions && optionsElement?.classList.contains(shownClass)) {
        closeFilterOptions();
        closeMarketOptions();
        setShowFilterOptions(false);
      } else {
        closeFilterOptions();
        optionsElement?.classList.toggle(shownClass);
        setShowFilterOptions(!showFilterOptions);
      }
    },
    [showFilterOptions, setShowFilterOptions]
  );

  const resetFilters = useCallback(() => {
    eventTracking(MixpanelEvents.filter_reset, { dashboard: "CCS", page: location.pathname });
    dispatch(setParameters(DEFAULT_CCS_PARAMETERS));
  }, []);

  const handleFilterOptionClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const filterName = dataset?.filter;
      const optionValue = dataset?.value;

      if (filterName && optionValue) {
        eventTracking(MixpanelEvents.filter_change, { filter: filterName, value: optionValue, dashboard: "CCS" });
        if (filterName === "brand") {
          Promise.all([dispatch(setSpecificParameter(filterName, optionValue))]);
          if (marketParamValue != "United States") {
            Promise.all([dispatch(setSpecificParameter("region", ALL_OPTION)), dispatch(setSpecificParameter("market", ALL_COUNTRIES))]);
          }
        } else {
          dispatch(setSpecificParameter(filterName, optionValue));
        }
      }
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    },
    [setShowFilterOptions, marketParam, regionParamValue]
  );

  const handleMarketArrowClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { id: currentId, dataset },
    } = evt;
    const marketListId = dataset?.marketTarget as string;

    const currentElement = document.getElementById(currentId) as HTMLElement;
    const marketListElement = document.getElementById(marketListId) as HTMLElement;
    const regionFilterUlElement = document.getElementById("region_ul") as HTMLElement;

    const shownClass = "show-market";
    const marketDownClass = "down";
    const regionMarketOpenClass = "market-open";

    const downClass = currentElement.children[0].classList;
    if (!downClass.contains(marketDownClass)) closeMarketOptions();
    downClass.toggle(marketDownClass);
    marketListElement.classList.toggle(shownClass);
    regionFilterUlElement.classList.toggle(regionMarketOpenClass);
    setMarketOptionsVisible(!marketOptionsVisible);
  }, []);

  const handleGeographyOptionClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const regionValue = dataset?.region;
    const marketValue = dataset?.market;

    regionValue &&
      dispatch(setSpecificParameter("region", regionValue)) &&
      eventTracking(MixpanelEvents.filter_change, { filter: "region", value: regionValue, dashboard: "CCS" });
    marketValue && dispatch(setSpecificParameter("market", marketValue));
    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);
  }, []);

  const handleDateFilterSubmission = () => {
    const parameterName = "date_range";
    const selectedCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-parent-value]:checked`
    ) as NodeListOf<HTMLInputElement>;

    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => {
      if (!/^\d+$/.test(checkbox.value) && checkbox.value != "All") selectedValues.push(checkbox.value);
    });

    closeFilterOptions();
    const value = selectedValues.join(",");
    dispatch(setSpecificParameter(parameterName, value));
    eventTracking(MixpanelEvents.filter_change, { filter: parameterName, value, dashboard: "CCS" });
  };

  const handleModelFilterSubmission = useCallback(() => {
    const selectedModelCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="model"][data-options-type="single"]:checked`
    ) as NodeListOf<HTMLInputElement>;
    const selectedModels: Array<string> = [];
    selectedModelCheckboxes?.forEach((checkbox) => selectedModels.push(checkbox.value));

    closeFilterOptions();
    const value = models?.length === selectedModels?.length ? "All" : selectedModels.join(",");
    dispatch(setSpecificParameter("model", value));

    const selectedRegion = (document.getElementById("region_country_filter") as HTMLInputElement).value;
    eventTracking(MixpanelEvents.filter_change, { filter: "model", value, region: selectedRegion, dashboard: "CCS" });
  }, [models]);

  return (
    <FiltersLayout resetFilters={resetFilters}>
      <>
        {/* Date range */}
        <MultiDateRangeWithPresets
          parentKey="year"
          // @ts-ignore
          filterList={groupedDateRangeData}
          filterName="DATE RANGE"
          childKey="month"
          value={dateRangeFilterValue}
          parameterName="date_range"
          parameterValue={dateRangeParamValue}
          onShowFilterOptions={showFilterOptionsFn}
          handleFilterSubmission={handleDateFilterSubmission}
        />

        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={brands}
            filterName={"brand"}
            filterLabel={"BRAND"}
            filterValue={brandParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/* Geography */}
        <CockpitGeographyFilter
          geographies={geographyList}
          value={geographyValue}
          onHandleMarketArrowClick={handleMarketArrowClick}
          onShowFilterOptions={showFilterOptionsFn}
          onHandleGeographyOptionsClick={handleGeographyOptionClick}
        />

        {/* Model */}
        {models?.length > 1 ? (
          <MultiSelectFilter
            parentKey="model"
            // @ts-ignore
            filterList={models}
            filterName="MODEL"
            value={modelFilterValue}
            parameterName={"model"}
            parameterValue={modelParamValue}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleModelFilterSubmission}
          />
        ) : (
          // TODO: add models back for ccs UAE -> ["All", ...models]
          <ModelFilter filterValue={modelParamValue} list={["All"]} handleFilterOptionClick={handleFilterOptionClick} />
        )}
        {filterBtns.map((btn) => (
          <FilterBtns id={btn.id} navigateTo={btn.navigateTo} name={btn.name} key={btn.id} />
        ))}
      </>
    </FiltersLayout>
  );
});
