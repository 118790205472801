import { ErrorBoundary } from "@sentry/react";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import {
  fetchImpressionsBreakdown,
  fetchImpressionsOverview,
  fetchSeoGeography,
  fetchSeoLastDataRefresh,
  fetchVisitorsCountry,
  fetchVisitsBreakdown,
  fetchVisitsOverview,
  setPageName,
  setSpecificParameter,
} from "../../actions";
import "../../assets/styles/pages/seo.scss";
import { ErrorMsg } from "../../components/AppMessages";
import { SeoFilters } from "../../components/Filters/SeoFilters";
import { DashboardLayout } from "../../components/Layouts";
import { DefaultPageContent } from "../../components/PageContent";
import SeoPageTitle from "../../components/PageContent/subs/SeoPageTitle";
import { ToggleSwitch } from "../../components/Toogle";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { ImpressionsTrendBreakdown, Insights, PerformanceOverview, VisitsTrendBreakdown } from "./subs";

export const SeoDashboard = withRouter((props: RouteComponentProps): JSX.Element => {
  const dispatch = useDispatch();
  const { location } = props;
  const {
    date_range: dateRangeParamValue,
    hostname: siteParamValue,
    market: marketParamValue,
    mobile_device_type: deviceParamValue,
    search_engine: searchEngineParamValue,
    landing_page: landingPageParamValue,
    query: impressionsQueryParamValue,
    visits_search_term: visitsSearchTermParamValue,
    visits_search_type: visitsSearchTypeParamValue,
    impressions_search_term: impressionsSearchTermParamValue,
    impressions_search_type: impressionsSearchTypeParamValue,
    visitor_country: visitorsCountryParamValue,
  } = useSelector((state: RootStateOrAny) => state.seo_parameters);

  const dataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.seo);
  const [lastDataRefresh, setLastDataRefresh] = useState("");
  const showGoogleSearch = searchEngineParamValue === "Google";
  // Sets page name
  useEffect(() => {
    Promise.all([dispatch(setPageName("SEO reporting")), dispatch(fetchSeoLastDataRefresh()), dispatch(fetchSeoGeography())]);
    //Set mobile device type to All when the component is unmounted
    return () => {
      dispatch(setSpecificParameter("mobile_device_type", "All"));
    };
  }, [dispatch]);

  const handleToggleChange = () => {
    const searchEngineValue = showGoogleSearch ? "All" : "Google";
    dispatch(setSpecificParameter("search_engine", searchEngineValue));
  };

  //Get last data refresh
  useEffect(() => {
    setLastDataRefresh(dataRefresh);
  }, [dataRefresh]);

  //Get visits overview
  useEffect(() => {
    Promise.all([dispatch(fetchVisitsOverview()), dispatch(fetchVisitsBreakdown())]);
  }, [
    marketParamValue,
    siteParamValue,
    dateRangeParamValue,
    searchEngineParamValue,
    deviceParamValue,
    landingPageParamValue,
    visitsSearchTermParamValue,
    visitsSearchTypeParamValue,
  ]);

  //Get impressions overview
  useEffect(() => {
    Promise.all([dispatch(fetchImpressionsOverview()), dispatch(fetchImpressionsBreakdown())]);
  }, [
    marketParamValue,
    dateRangeParamValue,
    siteParamValue,
    deviceParamValue,
    impressionsQueryParamValue,
    impressionsSearchTermParamValue,
    impressionsSearchTypeParamValue,
    visitorsCountryParamValue,
  ]);

  //Get visitors countries
  useEffect(() => {
    dispatch(fetchVisitorsCountry());
  }, [marketParamValue, siteParamValue]);

  // Usage tracking
  useEffect(() => eventTracking(MixpanelEvents.page_view, { dashboard: "SEO", page: "SEO" }), [location.pathname]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<SeoFilters />}
        lastDataRefresh={lastDataRefresh}
        showLastDataRefreshAlert={true}
        dataDocumentation="seo_data"
        pageTitle={<SeoPageTitle />}
      >
        <ErrorBoundary fallback={<ErrorMsg />}>
          <div className="seo_container">
            <ToggleSwitch
              activeToggleLabel={"Google search"}
              inactiveToggleLabel={"All search engines"}
              active={showGoogleSearch}
              handleToggleClick={handleToggleChange}
              toggleTitle={"Website traffic source: "}
              toggleClassName="seo_website_traffic_toggle"
            />
            {/*PERFORMANCE OVERVIEW & INSIGHTS MODULE */}
            <div className="seo_section">
              <ErrorBoundary fallback={<ErrorMsg />}>
                <PerformanceOverview />
              </ErrorBoundary>

              <ErrorBoundary fallback={<ErrorMsg />}>
                <Insights />
              </ErrorBoundary>
            </div>

            {/* IMPRESSIONS AND VISITS TREND AND BREAKDOWN */}
            <div className="seo_section">
              <ErrorBoundary fallback={<ErrorMsg />}>
                <ImpressionsTrendBreakdown />
              </ErrorBoundary>

              <ErrorBoundary fallback={<ErrorMsg />}>
                <VisitsTrendBreakdown />
              </ErrorBoundary>
            </div>
          </div>
        </ErrorBoundary>
      </DefaultPageContent>
    </DashboardLayout>
  );
});
