import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import MarketingActivityCalendarChart from "../../../components/Charts/ProductMarketingOptimization/MarketingActivityCalendarChart";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { MARKETING_ACTIVITY_COLORS } from "../../../constants";
import { ThemeContext } from "../../../context";

const MarketingActivityCalendar = () => {
  const themeContext = useContext(ThemeContext);
  const { granularity, date_range: dateRangeParam } = useSelector((state: RootStateOrAny) => state.parameters);

  const activity_categories_data = useSelector(
    (state: RootStateOrAny) => state.product_marketing_optimization?.marketing_activity?.activity_categories_data ?? []
  );

  const lineData = useSelector((state: RootStateOrAny) => state.product_marketing_optimization?.marketing_activity?.dp_data ?? []);

  const isLoading: boolean = useSelector((state: RootStateOrAny) => state.loading.marketing_activity);

  const ganttData = useMemo(() => {
    const barChartData: any[] = [];
    activity_categories_data?.map((e: any) => {
      e?.break_down_data.map((break_down: any, index: number) => {
        barChartData.push({
          name: e.marketing_activity_category,
          activity_name: break_down.marketing_activity_name,
          fromDate: break_down.start_date,
          toDate: break_down.end_date,
          fromDateQ: break_down.start_date_quarter,
          toDateQ: break_down.end_date_quarter,
          video_link: break_down.video_link,
        });
      });
    });
    return barChartData;
  }, [activity_categories_data]);

  const colorData = useMemo(() => {
    let barChartData: any = {};
    activity_categories_data?.map((e: any, index: number) => {
      barChartData = { ...barChartData, [e.marketing_activity_category]: MARKETING_ACTIVITY_COLORS[index] };
    });
    return barChartData;
  }, [activity_categories_data]);

  const maxDate = useMemo(() => {
    const dateArr: Array<Date> = [];

    ganttData.forEach((dataItem) => {
      dateArr.push(new Date(dataItem.toDate));
    });

    lineData.forEach((dataItem: any) => {
      dateArr.push(new Date(dataItem.date));
    });

    const date = dateArr ? new Date(Math.max(...dateArr.map(Number))) : new Date();

    if (dateRangeParam != "This financial year" && dateRangeParam != "Last quarter") {
      const dateRangeArr = dateRangeParam.split("-");
      const lastDate = new Date(dateRangeArr.at(-1));
      lastDate.setMonth(lastDate.getMonth() + 1);
      lastDate.setDate(0);
      return date > lastDate ? lastDate : date;
    }
    return date;
  }, [ganttData, lineData, dateRangeParam]);

  const minDate = useMemo(() => {
    const dateArr: Array<Date> = [];

    ganttData.forEach((dataItem) => {
      dateArr.push(new Date(dataItem.fromDate));
    });

    lineData.forEach((dataItem: any) => {
      dateArr.push(new Date(dataItem.date));
    });

    return dateArr ? new Date(Math.min(...dateArr.map(Number))) : new Date();
  }, [ganttData, lineData]);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className="marketing_activity_calendar primary_tile_background">
        <div className="header">
          <div className="title_container">
            <h3>Marketing activity calendar</h3>
          </div>
        </div>

        <div className="main_content">
          {ganttData && ganttData.length > 0 ? (
            <ErrorBoundary fallback={<ErrorMsg />}>
              <MarketingActivityCalendarChart
                seriesList={[
                  { name: "Online visits", field: "online_visits" },
                  // { name: "Showroom traffic", field: "showroom_traffic" },
                ]}
                theme={themeContext.theme}
                isLoading={isLoading}
                granularity={granularity}
                ganttData={ganttData}
                lineData={lineData}
                colorsColumn={colorData}
                minDate={minDate}
                maxDate={maxDate}
              />
            </ErrorBoundary>
          ) : isLoading ? (
            <LoadingEllipsis isLoading={isLoading} />
          ) : (
            <NoDataMsg message="No data available for this market" />
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default MarketingActivityCalendar;
