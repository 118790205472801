import { createContext, Dispatch, SetStateAction } from "react";

export const FilterContext = createContext<{
  showMobileFilter: boolean;
  setShowMobileFilter: Dispatch<SetStateAction<boolean>>;
  showFilterOptions: boolean;
  setShowFilterOptions: Dispatch<SetStateAction<boolean>>;
}>({
  showMobileFilter: false,
  showFilterOptions: false,
  setShowFilterOptions(): void {},
  setShowMobileFilter(): void {},
});
