import { Box, Button, makeStyles } from "@material-ui/core";
import { ErrorBoundary } from "@sentry/react";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { deleteDataV2, fetchDataV2 } from "../../../api/utils";
import { ErrorMsg } from "../../../components/AppMessages";
import { CYAN } from "../../../constants";
import { User } from "../../../constants/interface/admin/usersData";
import ColumnVisibilityDialog from "../common/Dialogs/ColumnVisibilityDialog";
import UserSearchField from "../common/Fields/UserSearchField";
import { downloadExcelFile } from "../utils";
import DeleteUserDialog from "./subs/Dialogs/DeleteUserDialog";
import UserFormDialog from "./subs/Dialogs/UserFormDialog";
import UsersTable from "./subs/UsersTable";

export const Users = () => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [userFormDialogOpen, setUserDialogFormOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [users, setUsers] = useState<User[]>([]);
  const [userGroups, setUserGroups] = useState<Array<any>>([]);
  const [userViews, setUserViews] = useState<Array<any>>([]);
  const [userDepartments, setUserDepartments] = useState<Array<any>>([]);
  const [columnVisibilityDialogOpen, setColumnVisibilityDialogOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [visibleColumns, setVisibleColumns] = useState<string[]>(["email", "display_name", "groups", "views", "on_ga", "account_status"]);
  const filteredUsers = users.filter((user) => user.email.toLowerCase().includes(searchValue.toLowerCase()));

  const classes = makeStyles({
    button: {
      backgroundColor: CYAN,
      color: "#FFF !important",
      borderRadius: "0px !important",
      border: "none !important",
      marginRight: "10px",
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      "&:hover": {
        backgroundColor: "#5ed2ff !important",
        border: "none !important",
      },
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
  })();

  const allColumns = [
    { id: "email", title: "Email" },
    { id: "first_name", title: "First Name" },
    { id: "last_name", title: "Last Name" },
    { id: "display_name", title: "Display Name" },
    { id: "business_function", title: "Business Function" },
    { id: "role", title: "Role" },
    { id: "groups", title: "Geo Scope" },
    { id: "views", title: "User Views" },
    { id: "on_ga", title: "On GA" },
    { id: "account_status", title: "Account Status" },
    { id: "created_at", title: "Creation Date" },
    { id: "last_login", title: "Last Login" },
  ];

  useEffect(() => {
    Promise.all([
      fetchDataV2("/user_service/get-all-users").then((response) => {
        if (response && !("error" in response)) setUsers(response);
      }),
      fetchDataV2("/user_service/get-all-groups").then((response) => {
        if (response && !("error" in response)) setUserGroups(response);
      }),
      fetchDataV2("/user_service/get-all-view-groups").then((response) => {
        if (response && !("error" in response)) setUserViews(response);
      }),
      fetchDataV2("/user_service/get-all-departments").then((response) => {
        if (response && !("error" in response)) setUserDepartments(response);
      }),
    ]);
  }, []);

  const handleToogleDeleteDialog = () => {
    setDeleteDialogOpen(!deleteDialogOpen);
  };

  const handleToogleColumnVisibilityDialog = () => {
    setColumnVisibilityDialogOpen(!columnVisibilityDialogOpen);
  };

  const handleToggleUserFormDialog = () => {
    setUserDialogFormOpen(!userFormDialogOpen);
    if (userFormDialogOpen) {
      setSelectedUser(undefined);
    }
  };

  const handleDeleteUser = (email: string) => {
    deleteDataV2(`/user_service/delete-user?email=${email.replace(/[+]/g, "%2B")}`).then((res) => {
      if (!res.error) {
        setUsers((prevState) => prevState.filter((user) => user.email != email));
        setSelectedUser(undefined);
        handleToogleDeleteDialog();
      } else {
        console.log(res.error);
      }
    });
  };

  const handleExportUsers = () => {
    const columnHeaders = [
      "Email",
      "First name",
      "Last name",
      "Display name",
      "Business function",
      "Role",
      "Department",
      "Geo scope",
      "User Views",
      "On GA",
      "Account Status",
      "Creation Date",
      "Last Login",
      "Preferred url",
    ];
    const data = [
      columnHeaders,
      ...users.map((user) => [
        user.email,
        user.first_name,
        user.last_name,
        user.display_name,
        user.business_function,
        user.role,
        user.department,
        user.groups.map((group) => group.name).join(", "),
        user.views.map((view) => view.name).join(", "),
        user.on_ga ? "true" : "false",
        user.account_status,
        format(new Date(user.created_at), "d-LLL-Y"),
        format(new Date(user.last_login), "d-LLL-Y"),
        user.preferred_url,
      ]),
    ];
    downloadExcelFile(data, "users");
  };

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <Box component="div">
        <Box className={classes.buttonsContainer} component="div" mb={3}>
          <Box component="div">
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={() => {
                setSelectedUser(undefined);
                handleToggleUserFormDialog();
              }}
              data-test-id="btn-add-new-user"
            >
              New
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={handleToggleUserFormDialog}
              disabled={!selectedUser}
              data-test-id="btn-edit-user"
            >
              Edit
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={handleToogleDeleteDialog}
              disabled={!selectedUser}
              data-test-id="btn-delete-user"
            >
              Delete
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={handleToogleColumnVisibilityDialog}
              data-test-id="btn-user"
            >
              Column Visibility
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={handleExportUsers}
              data-test-id="btn-export-users"
            >
              Export to Excel
            </Button>
          </Box>
          <Box component="div">
            <UserSearchField value={searchValue} setValue={setSearchValue} />
          </Box>
        </Box>

        <ErrorBoundary fallback={<ErrorMsg />}>
          <UsersTable
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            setDeleteDialogOpen={setDeleteDialogOpen}
            users={filteredUsers}
            visibleColumns={visibleColumns}
            searchValue={searchValue}
          />
        </ErrorBoundary>

        {deleteDialogOpen && selectedUser && (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <DeleteUserDialog
              open={deleteDialogOpen}
              handleClose={handleToogleDeleteDialog}
              handleDelete={handleDeleteUser}
              user={selectedUser}
            />
          </ErrorBoundary>
        )}

        {userFormDialogOpen && (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <UserFormDialog
              open={userFormDialogOpen}
              toggleOpen={handleToggleUserFormDialog}
              setUsers={setUsers}
              selectedUser={selectedUser}
              userGroups={userGroups}
              userViews={userViews}
              userDepartments={userDepartments}
            />
          </ErrorBoundary>
        )}

        {columnVisibilityDialogOpen && (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <ColumnVisibilityDialog
              open={columnVisibilityDialogOpen}
              toggleOpen={handleToogleColumnVisibilityDialog}
              visibleColumns={visibleColumns}
              setVisibleColumns={setVisibleColumns}
              columns={allColumns}
            />
          </ErrorBoundary>
        )}
      </Box>
    </ErrorBoundary>
  );
};
