import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { getAvailableYearScenarios, getChartData, getMaxAmount, getMinAmount, getSeriesList } from "../../helpers";
import MidasSharedCharts from "../../sharedComponents/MidasSHaredCharts";

const StrategicDealerSupportGraph = () => {
  const yr_scnr_rv: any = useSelector((state: RootStateOrAny) => state.filters.midas_filters.yr_scnr_rv);
  const loading = useSelector((state: RootStateOrAny) => state.loading.midas_strategic_dealer_support_data_by_quarter);
  const data = useSelector((state: RootStateOrAny) => state.midas.midas_strategic_dealer_support_data_by_quarter);

  const yrScenarios: string[] = useMemo(() => getAvailableYearScenarios(data?.full_year, true), [data]);

  const seriesList = useMemo(() => getSeriesList(yrScenarios, yr_scnr_rv), [yrScenarios, yr_scnr_rv]);

  const q1ChartData = useMemo(() => getChartData(data?.q1, yr_scnr_rv), [data]);
  const q2ChartData = useMemo(() => getChartData(data?.q2, yr_scnr_rv), [data]);
  const q3ChartData = useMemo(() => getChartData(data?.q3, yr_scnr_rv), [data]);
  const q4ChartData = useMemo(() => getChartData(data?.q4, yr_scnr_rv), [data]);
  const fullYearChartData = useMemo(() => getChartData(data?.full_year, yr_scnr_rv), [data]);

  const quartersMaxAmount = useMemo(() => {
    const q1MaxAmount = getMaxAmount(data?.q1);
    const q2MaxAmount = getMaxAmount(data?.q2);
    const q3MaxAmount = getMaxAmount(data?.q3);
    const q4MaxAmount = getMaxAmount(data?.q4);
    return Math.max(q1MaxAmount, q2MaxAmount, q3MaxAmount, q4MaxAmount);
  }, [data]);

  const quartersMinAmount = useMemo(() => {
    const q1MinAmount = getMinAmount(data?.q1);
    const q2MinAmount = getMinAmount(data?.q2);
    const q3MinAmount = getMinAmount(data?.q3);
    const q4MinAmount = getMinAmount(data?.q4);
    return Math.min(q1MinAmount, q2MinAmount, q3MinAmount, q4MinAmount);
  }, [data]);

  const fullYearMaxAmount: number = useMemo(() => getMaxAmount(data?.full_year), [data]);

  return (
    <MidasSharedCharts
      q1ChartData={q1ChartData}
      q2ChartData={q2ChartData}
      q3ChartData={q3ChartData}
      q4ChartData={q4ChartData}
      fullYearChartData={fullYearChartData}
      q1MaxAmount={quartersMaxAmount}
      q2MaxAmount={quartersMaxAmount}
      q3MaxAmount={quartersMaxAmount}
      q4MaxAmount={quartersMaxAmount}
      fullYearMaxAmount={fullYearMaxAmount}
      loading={loading}
      seriesList={seriesList}
      yrScenarios={yrScenarios}
      minAmount={quartersMinAmount}
    />
  );
};

export default StrategicDealerSupportGraph;
