import { AnyAction } from "redux";
import { put, retry, takeLatest } from "redux-saga/effects";
import {
  FETCH_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_ADJUSTED,
  FETCH_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_TREND,
  FETCH_ONLINE_ACC_AND_ENG_ACTIVE_USERS,
  FETCH_ONLINE_ACC_AND_ENG_AVG_VOC_RATING,
  FETCH_ONLINE_ACC_AND_ENG_COMPLETE_ACCOUNT_CREATION,
  FETCH_ONLINE_ACC_AND_ENG_ENGAGEMENT_VS_ACTIVE_USERS,
  FETCH_ONLINE_ACC_AND_ENG_ENROLLED_USERS_LINKED,
  FETCH_ONLINE_ACC_AND_ENG_ENROLLED_USERS_TREND,
  FETCH_ONLINE_ACC_AND_ENG_FIRST_ENGAGEMENT,
  FETCH_ONLINE_ACC_AND_ENG_LINKED_CARS,
  FETCH_ONLINE_ACC_AND_ENG_PRIVATE_SALES,
  FETCH_ONLINE_ACC_AND_ENG_START_ACCOUNT_CREATION,
  FETCH_ONLINE_ACC_AND_ENG_VEHICLES_ADDED,
  FETCH_OVERVIEW_AVG_MONTHLY_ACTIVE_USERS,
  FETCH_OVERVIEW_AVG_VOC_RATING_ACROSS_PAGES,
  FETCH_OVERVIEW_CHURN_RATE,
  FETCH_OVERVIEW_CONNECTED_CARS_SOLD,
  FETCH_OVERVIEW_ENGAGEMENT_TREND,
  FETCH_OVERVIEW_ENROLLED_CSS_CUSTOMERS,
  FETCH_OVERVIEW_ONLINE_ACCOUNT_AND_ENGAGEMENT,
  FETCH_OVERVIEW_RENEWAL_RATE,
  FETCH_OVERVIEW_RENEWAL_TREND,
  FETCH_OVERVIEW_SERVICE_LEADS,
  FETCH_OVERVIEW_USER_ENGAGEMENT,
  FETCH_SELF_SERVICE_POPULAR_SEARCH_TERMS_VIA_GOOGLE,
  FETCH_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_AND_TIME_SPENT,
  FETCH_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_CHANNEL,
  FETCH_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_ENTRY_POINT,
  FETCH_SELF_SERVICE_TOPIC_CLASSIFICATION_DISSATISFACTION,
  FETCH_SELF_SERVICE_TOPIC_CLASSIFICATION_SATISFACTION,
  FETCH_SELF_SERVICE_TOPIC_CLASSIFICATION_VERBATIM_DATA,
  FETCH_SELF_SERVICE_USERS_VISITING_SELF_LEARNING_PAGE,
  FETCH_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE,
  FETCH_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE_OVER_TIME,
  FETCH_SERVICE_LEAD_BOOKING_FORMS_VISITS_VS_SERVICE_LEADS,
  FETCH_SERVICE_LEAD_CONVERSION_RATE,
  FETCH_SERVICE_LEAD_SERVICE_BOOKING_FORMS_OPENED,
  FETCH_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS,
  FETCH_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS_BY_CHANNEL_OR_TREND,
  FETCH_SERVICE_LEAD_SERVICE_LEADS,
  FETCH_SERVICE_LEAD_SERVICE_RECOMMENDATION_VISITS,
} from "../actions/actionTypes/useOwnershipTypes";
import { setLoading } from "../actions/loadingActions";
import {
  setOnlineAccAndEngAccountCreationAdjusted,
  setOnlineAccAndEngAccountCreationTrend,
  setOnlineAccAndEngActiveUsers,
  setOnlineAccAndEngAvgVocRating,
  setOnlineAccAndEngCompleteAccountCreation,
  setOnlineAccAndEngEngagementVsActiveUsers,
  setOnlineAccAndEngEnrolledUsersLinked,
  setOnlineAccAndEngEnrolledUsersTrend,
  setOnlineAccAndEngFirstEngagement,
  setOnlineAccAndEngLinkedCars,
  setOnlineAccAndEngPrivateSales,
  setOnlineAccAndEngStartAccountCreation,
  setOnlineAccAndEngVehiclesAdded,
  setOverviewAvgMonthlyActiveUsers,
  setOverviewAvgVocRatingAcrossPages,
  setOverviewChurnRate,
  setOverviewConnectedCarsSold,
  setOverviewEngagementTrend,
  setOverviewEnrolledCcsCustomers,
  setOverviewOnlineAccountAndEngagement,
  setOverviewRenewalRate,
  setOverviewRenewalTrend,
  setOverviewServiceLeads,
  setOverviewUserEngagement,
  setSelfServicePopularSearchTermsViaGoogle,
  setSelfServiceSelfLearningPageVisitsAndTimeSpent,
  setSelfServiceSelfLearningPageVisitsByChannel,
  setSelfServiceSelfLearningPageVisitsByEntryPoint,
  setSelfServiceTopicClassificationDissatisfaction,
  setSelfServiceTopicClassificationSatisfaction,
  setSelfServiceTopicClassificationVerbatimData,
  setSelfServiceUserVisitingSelfLearningPage,
  setSelfServiceVocRatingForSelfLearningPage,
  setSelfServiceVocRatingForSelfLearningPageOverTime,
  setServiceLeadBookingFormsVisitsVsServiceLeads,
  setServiceLeadBookingFormVisitsByChannelOrTrend,
  setServiceLeadConversionRate,
  setServiceLeadServiceBookingFormsOpened,
  setServiceLeadServiceBookingFormVisits,
  setServiceLeadServiceLeads,
  setServiceLeadServiceRecommendationVisits,
} from "../actions/useOwnershipTypes";
import {
  getOnlineAccAndEngAccountCreationAdjusted,
  getOnlineAccAndEngAccountCreationTrend,
  getOnlineAccAndEngActiveUsers,
  getOnlineAccAndEngAvgVocRating,
  getOnlineAccAndEngCompleteAccountCreation,
  getOnlineAccAndEngEngagementVsActiveUsers,
  getOnlineAccAndEngEnrolledUsersLinked,
  getOnlineAccAndEngEnrolledUsersTrend,
  getOnlineAccAndEngFirstEngagement,
  getOnlineAccAndEngLinkedCars,
  getOnlineAccAndEngPrivateSales,
  getOnlineAccAndEngStartAccountCreation,
  getOnlineAccAndEngVehiclesAdded,
  getOverviewAvgMonthlyActiveUsers,
  getOverviewAvgVocRatingAcrossPages,
  getOverviewChurnRate,
  getOverviewConnectedCarsSold,
  getOverviewEngagementTrend,
  getOverviewEnrolledCcsCustomers,
  getOverviewOnlineAccountAndEngagement,
  getOverviewRenewalRate,
  getOverviewRenewalTrend,
  getOverviewServiceLeads,
  getOverviewUserEngagement,
  getSelfServicePopularSearchTermsViaGoogle,
  getSelfServiceSelfLearningPageVisitsAndTimeSpent,
  getSelfServiceSelfLearningPageVisitsByChannel,
  getSelfServiceSelfLearningPageVisitsByEntryPoint,
  getSelfServiceTopicClassificationDissatisfaction,
  getSelfServiceTopicClassificationSatisfaction,
  getSelfServiceTopicClassificationVerbatimData,
  getSelfServiceUserVisitingSelfLearningPage,
  getSelfServiceVocRatingForSelfLearningPage,
  getSelfServiceVocRatingForSelfLearningPageOverTime,
  getServiceLeadBookingFormsVisitsVsServiceLeads,
  getServiceLeadBookingFormVisitsByChannelOrTrend,
  getServiceLeadConversionRate,
  getServiceLeadServiceBookingFormsOpened,
  getServiceLeadServiceBookingFormVisits,
  getServiceLeadServiceLeads,
  getServiceLeadServiceRecommendationVisits,
} from "../api/useOwnershipApi";
import { secondsDelay } from "../api/utils";

// Watcher sagas
export function* watchFetchOverviewAvgVocRatingAcrossPagesSaga() {
  yield takeLatest(FETCH_OVERVIEW_AVG_VOC_RATING_ACROSS_PAGES, fetchOverviewAvgVocRatingAcrossPagesSaga);
}

export function* watchFetchOverviewOnineAccountAndEngagementSaga() {
  yield takeLatest(FETCH_OVERVIEW_ONLINE_ACCOUNT_AND_ENGAGEMENT, fetchOverviewOnineAccountAndEngagementSaga);
}

export function* watchFetchOverviewServiceLeadsSaga() {
  yield takeLatest(FETCH_OVERVIEW_SERVICE_LEADS, fetchOverviewServiceLeadsSaga);
}

export function* watchFetchOverviewEnrolledCcsCustomersSaga() {
  yield takeLatest(FETCH_OVERVIEW_ENROLLED_CSS_CUSTOMERS, fetchOverviewEnrolledCcsCustomersSaga);
}

export function* watchFetchOverviewAvgMonthlyActiveUsersSaga() {
  yield takeLatest(FETCH_OVERVIEW_AVG_MONTHLY_ACTIVE_USERS, fetchOverviewAvgMonthlyActiveUsersSaga);
}

export function* watchFetchOverviewConnectedCarsSoldSaga() {
  yield takeLatest(FETCH_OVERVIEW_CONNECTED_CARS_SOLD, fetchOverviewConnectedCarsSoldSaga);
}

export function* watchFetchOverviewUserEngagementSaga() {
  yield takeLatest(FETCH_OVERVIEW_USER_ENGAGEMENT, fetchOverviewUserEngagementSaga);
}

export function* watchFetchOverviewRenewalRateSaga() {
  yield takeLatest(FETCH_OVERVIEW_RENEWAL_RATE, fetchOverviewRenewalRateSaga);
}

export function* watchFetchOverviewChurnRateSaga() {
  yield takeLatest(FETCH_OVERVIEW_CHURN_RATE, fetchOverviewChurnRateSaga);
}

export function* watchFetchOverviewEngagementTrendSaga() {
  yield takeLatest(FETCH_OVERVIEW_ENGAGEMENT_TREND, fetchOverviewEngagementTrendSaga);
}

export function* watchFetchOverviewRenewalTrendSaga() {
  yield takeLatest(FETCH_OVERVIEW_RENEWAL_TREND, fetchOverviewRenewalTrendSaga);
}

export function* watchFetchOnlineAccAndEngPrivateSalesSaga() {
  yield takeLatest(FETCH_ONLINE_ACC_AND_ENG_PRIVATE_SALES, fetchOnlineAccAndEngPrivateSalesSaga);
}

export function* watchFetchOnlineAccAndEngEnrolledUsersTrendSaga() {
  yield takeLatest(FETCH_ONLINE_ACC_AND_ENG_ENROLLED_USERS_TREND, fetchOnlineAccAndEngEnrolledUsersTrendSaga);
}

export function* watchFetchOnlineAccAndEngAccountCreationTrendSaga() {
  yield takeLatest(FETCH_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_TREND, fetchOnlineAccAndEngAccountCreationTrendSaga);
}

export function* watchFetchnlineAccAndEngLinkedCarsSaga() {
  yield takeLatest(FETCH_ONLINE_ACC_AND_ENG_LINKED_CARS, fetchOnlineAccAndEngLinkedCarsSaga);
}

export function* watchFetchOnlineAccAndEngEngagementVsActiveUsersSaga() {
  yield takeLatest(FETCH_ONLINE_ACC_AND_ENG_ENGAGEMENT_VS_ACTIVE_USERS, fetchOnlineAccAndEngEngagementVsActiveUsersSaga);
}

export function* watchFetchOnlineAccAndEngStartAccountCreationSaga() {
  yield takeLatest(FETCH_ONLINE_ACC_AND_ENG_START_ACCOUNT_CREATION, fetchOnlineAccAndEngStartAccountCreationSaga);
}

export function* watchFetchOnlineAccAndEngCompleteAccountCreationSaga() {
  yield takeLatest(FETCH_ONLINE_ACC_AND_ENG_COMPLETE_ACCOUNT_CREATION, fetchOnlineAccAndEngCompleteAccountCreationSaga);
}

export function* watchFetchOnlineAccAndEngAccountCreationAdjustedSaga() {
  yield takeLatest(FETCH_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_ADJUSTED, fetchOnlineAccAndEngAccountCreationAdjustedSaga);
}

export function* watchFetchOnlineAccAndEngActiveUsersSaga() {
  yield takeLatest(FETCH_ONLINE_ACC_AND_ENG_ACTIVE_USERS, fetchOnlineAccAndEngActiveUsersSaga);
}

export function* watchFetchOnlineAccAndEngFirstEngagementSaga() {
  yield takeLatest(FETCH_ONLINE_ACC_AND_ENG_FIRST_ENGAGEMENT, fetchOnlineAccAndEngFirstEngagementSaga);
}

export function* watchFetchOnlineAccAndEngAvgVocRatingSaga() {
  yield takeLatest(FETCH_ONLINE_ACC_AND_ENG_AVG_VOC_RATING, fetchOnlineAccAndEngAvgVocRatingSaga);
}

export function* watchFetchOnlineAccAndEngEnrolledUsersLinkedSaga() {
  yield takeLatest(FETCH_ONLINE_ACC_AND_ENG_ENROLLED_USERS_LINKED, fetchOnlineAccAndEngEnrolledUsersLinkedSaga);
}

export function* watchFetchOnlineAccAndEngVehiclesAddedSaga() {
  yield takeLatest(FETCH_ONLINE_ACC_AND_ENG_VEHICLES_ADDED, fetchOnlineAccAndEngVehiclesAddedSaga);
}

export function* watchFetchServiceLeadServiceRecommendationVisitsSaga() {
  yield takeLatest(FETCH_SERVICE_LEAD_SERVICE_RECOMMENDATION_VISITS, fetchServiceLeadServiceRecommendationVisitsSaga);
}

export function* watchFetchServiceLeadServiceBookingFormVisitsSaga() {
  yield takeLatest(FETCH_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS, fetchServiceLeadServiceBookingFormVisitsSaga);
}

export function* watchFetchServiceLeadServiceBookingFormsOpenedSaga() {
  yield takeLatest(FETCH_SERVICE_LEAD_SERVICE_BOOKING_FORMS_OPENED, fetchServiceLeadServiceBookingFormsOpenedSaga);
}

export function* watchServiceLeadConversionRateSaga() {
  yield takeLatest(FETCH_SERVICE_LEAD_CONVERSION_RATE, fetchServiceLeadConversionRateSaga);
}

export function* watchFetchServiceLeadServiceLeadsSaga() {
  yield takeLatest(FETCH_SERVICE_LEAD_SERVICE_LEADS, fetchServiceLeadServiceLeadsSaga);
}

export function* watchFetchServiceLeadBookingFormsVisitsVsServiceLeadsSaga() {
  yield takeLatest(FETCH_SERVICE_LEAD_BOOKING_FORMS_VISITS_VS_SERVICE_LEADS, fetchServiceLeadBookingFormsVisitsVsServiceLeadsSaga);
}

export function* watchFetchServiceLeadBookingFormVisitsByChannelOrTrendSaga() {
  yield takeLatest(
    FETCH_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS_BY_CHANNEL_OR_TREND,
    fetchServiceLeadBookingFormVisitsByChannelOrTrendSaga
  );
}

export function* watchFetchSelfServiceUserVisitingSelfLearningPageSaga() {
  yield takeLatest(FETCH_SELF_SERVICE_USERS_VISITING_SELF_LEARNING_PAGE, fetchSelfServiceUserVisitingSelfLearningPageSaga);
}

export function* watchFetchSelfServiceSelfLearningPageVisitsAndTimeSpentSaga() {
  yield takeLatest(FETCH_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_AND_TIME_SPENT, fetchSelfServiceSelfLearningPageVisitsAndTimeSpentSaga);
}

export function* watchFetchSelfServiceSelfLearningPageVisitsByChannelSaga() {
  yield takeLatest(FETCH_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_CHANNEL, fetchSelfServiceSelfLearningPageVisitsByChannelSaga);
}

export function* watchFetchSelfServiceSelfLearningPageVisitsByEntryPointSaga() {
  yield takeLatest(FETCH_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_ENTRY_POINT, fetchSelfServiceSelfLearningPageVisitsByEntryPointSaga);
}

export function* watchFetchSelfServiceVocRatingForSelfLearningPageSaga() {
  yield takeLatest(FETCH_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE, fetchSelfServiceVocRatingForSelfLearningPageSaga);
}

export function* watchFetchSelfServiceVocRatingForSelfLearningPageOverTimeSaga() {
  yield takeLatest(
    FETCH_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE_OVER_TIME,
    fetchSelfServiceVocRatingForSelfLearningPageOverTimeSaga
  );
}

export function* watchFetchSelfServicePopularSearchTermsViaGoogleSaga() {
  yield takeLatest(FETCH_SELF_SERVICE_POPULAR_SEARCH_TERMS_VIA_GOOGLE, fetchSelfServicePopularSearchTermsViaGoogleSaga);
}

export function* watchFetchSelfServiceTopicClassificationVerbatimDataSaga() {
  yield takeLatest(FETCH_SELF_SERVICE_TOPIC_CLASSIFICATION_VERBATIM_DATA, fetchSelfServiceTopicClassificationVerbatimDataSaga);
}

export function* watchFetchSelfServiceTopicClassificationDissatisfactionSaga() {
  yield takeLatest(FETCH_SELF_SERVICE_TOPIC_CLASSIFICATION_DISSATISFACTION, fetchSelfServiceTopicClassificationDissatisfactionSaga);
}

export function* watchFetchSelfServiceTopicClassificationSatisfactionSaga() {
  yield takeLatest(FETCH_SELF_SERVICE_TOPIC_CLASSIFICATION_SATISFACTION, fetchSelfServiceTopicClassificationSatisfactionSaga);
}

// Worker sagas
function* fetchOverviewAvgVocRatingAcrossPagesSaga() {
  try {
    yield put(setLoading("avg_voc_rating_across_pages", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOverviewAvgVocRatingAcrossPages);
    if (!("error" in response)) {
      yield put(setOverviewAvgVocRatingAcrossPages(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/overview/average-voc-rating-across-pages");
  } finally {
    yield put(setLoading("avg_voc_rating_across_pages", false));
  }
}

function* fetchOverviewOnineAccountAndEngagementSaga() {
  try {
    yield put(setLoading("overview_online_account_and_engagement", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOverviewOnlineAccountAndEngagement);
    if (!("error" in response)) {
      yield put(setOverviewOnlineAccountAndEngagement(response));
    }
  } catch (err) {
    console.log("failed to fetch use-ownership/overview/online-account-and-engagement");
  } finally {
    yield put(setLoading("overview_online_account_and_engagement", false));
  }
}

function* fetchOverviewServiceLeadsSaga() {
  try {
    yield put(setLoading("overview_service_leads", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOverviewServiceLeads);
    if (!("error" in response)) {
      yield put(setOverviewServiceLeads(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/overview/service-leads");
  } finally {
    yield put(setLoading("overview_service_leads", false));
  }
}

function* fetchOverviewEnrolledCcsCustomersSaga() {
  try {
    yield put(setLoading("overview_enrolled_ccs_customers", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOverviewEnrolledCcsCustomers);
    if (!("error" in response)) {
      yield put(setOverviewEnrolledCcsCustomers(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/overview/enrolled-ccs-customers");
  } finally {
    yield put(setLoading("overview_enrolled_ccs_customers", false));
  }
}

function* fetchOverviewAvgMonthlyActiveUsersSaga() {
  try {
    yield put(setLoading("overview_avg_monthly_active_users", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOverviewAvgMonthlyActiveUsers);
    if (!("error" in response)) {
      yield put(setOverviewAvgMonthlyActiveUsers(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/overview/avg-monthly-active-users");
  } finally {
    yield put(setLoading("overview_avg_monthly_active_users", false));
  }
}

function* fetchOverviewConnectedCarsSoldSaga() {
  try {
    yield put(setLoading("overview_connected_cars_sold", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOverviewConnectedCarsSold);
    if (!("error" in response)) {
      yield put(setOverviewConnectedCarsSold(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/overview/avg-monthly-active-users");
  } finally {
    yield put(setLoading("overview_connected_cars_sold", false));
  }
}

function* fetchOverviewUserEngagementSaga() {
  try {
    yield put(setLoading("overview_user_engagement", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOverviewUserEngagement);
    if (!("error" in response)) {
      yield put(setOverviewUserEngagement(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/overview/user-engagement");
  } finally {
    yield put(setLoading("overview_user_engagement", false));
  }
}

function* fetchOverviewRenewalRateSaga() {
  try {
    yield put(setLoading("overview_renewal_rate", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOverviewRenewalRate);
    if (!("error" in response)) {
      yield put(setOverviewRenewalRate(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/overview/renewal-rate");
  } finally {
    yield put(setLoading("overview_renewal_rate", false));
  }
}

function* fetchOverviewChurnRateSaga() {
  try {
    yield put(setLoading("overview_churn_rate", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOverviewChurnRate);
    if (!("error" in response)) {
      yield put(setOverviewChurnRate(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/overview/churn-rate");
  } finally {
    yield put(setLoading("overview_churn_rate", false));
  }
}

function* fetchOverviewEngagementTrendSaga() {
  try {
    yield put(setLoading("overview_ccs_engagement_trend", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOverviewEngagementTrend);
    if (!("error" in response)) {
      yield put(setOverviewEngagementTrend(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/overview/ccs-engagement-trend");
  } finally {
    yield put(setLoading("overview_ccs_engagement_trend", false));
  }
}

function* fetchOverviewRenewalTrendSaga() {
  try {
    yield put(setLoading("overview_ccs_renewal_trend", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOverviewRenewalTrend);
    if (!("error" in response)) {
      yield put(setOverviewRenewalTrend(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/overview/ccs-renewal-trend");
  } finally {
    yield put(setLoading("overview_ccs_renewal_trend", false));
  }
}

function* fetchOnlineAccAndEngPrivateSalesSaga() {
  try {
    yield put(setLoading("online_acc_and_eng_private_sales", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOnlineAccAndEngPrivateSales);
    if (!("error" in response)) {
      yield put(setOnlineAccAndEngPrivateSales(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/online-account-and-engagement/private-sales");
  } finally {
    yield put(setLoading("online_acc_and_eng_private_sales", false));
  }
}

function* fetchOnlineAccAndEngEnrolledUsersTrendSaga() {
  try {
    yield put(setLoading("online_acc_and_eng_enrolled_users_trend", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOnlineAccAndEngEnrolledUsersTrend);
    if (!("error" in response)) {
      yield put(setOnlineAccAndEngEnrolledUsersTrend(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/online-account-and-engagement/enrolled-users-trend");
  } finally {
    yield put(setLoading("online_acc_and_eng_enrolled_users_trend", false));
  }
}

function* fetchOnlineAccAndEngAccountCreationTrendSaga() {
  try {
    yield put(setLoading("online_acc_and_eng_account_creation_trend", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOnlineAccAndEngAccountCreationTrend);
    if (!("error" in response)) {
      yield put(setOnlineAccAndEngAccountCreationTrend(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/online-account-and-engagement/account-creation-trend");
  } finally {
    yield put(setLoading("online_acc_and_eng_account_creation_trend", false));
  }
}

function* fetchOnlineAccAndEngLinkedCarsSaga() {
  try {
    yield put(setLoading("online_acc_and_eng_linked_cars", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOnlineAccAndEngLinkedCars);
    if (!("error" in response)) {
      yield put(setOnlineAccAndEngLinkedCars(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/online-account-and-engagement/proportion-of-users-that-have-linked-a-car");
  } finally {
    yield put(setLoading("online_acc_and_eng_linked_cars", false));
  }
}

function* fetchOnlineAccAndEngEngagementVsActiveUsersSaga() {
  try {
    yield put(setLoading("online_acc_and_eng_engagement_vs_active_users", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOnlineAccAndEngEngagementVsActiveUsers);
    if (!("error" in response)) {
      yield put(setOnlineAccAndEngEngagementVsActiveUsers(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/online-account-and-engagement/first-engagement-users-vs-active-users");
  } finally {
    yield put(setLoading("online_acc_and_eng_engagement_vs_active_users", false));
  }
}

function* fetchOnlineAccAndEngStartAccountCreationSaga() {
  try {
    yield put(setLoading("online_acc_and_eng_start_account_creation", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOnlineAccAndEngStartAccountCreation);
    if (!("error" in response)) {
      yield put(setOnlineAccAndEngStartAccountCreation(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/online-account-and-engagement/users-start-account-creation");
  } finally {
    yield put(setLoading("online_acc_and_eng_start_account_creation", false));
  }
}

function* fetchOnlineAccAndEngCompleteAccountCreationSaga() {
  try {
    yield put(setLoading("online_acc_and_eng_complete_account_creation", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOnlineAccAndEngCompleteAccountCreation);
    if (!("error" in response)) {
      yield put(setOnlineAccAndEngCompleteAccountCreation(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/online-account-and-engagement/users-complete-account-creation");
  } finally {
    yield put(setLoading("online_acc_and_eng_complete_account_creation", false));
  }
}

function* fetchOnlineAccAndEngAccountCreationAdjustedSaga() {
  try {
    yield put(setLoading("online_acc_and_eng_account_creation_adjusted", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOnlineAccAndEngAccountCreationAdjusted);
    if (!("error" in response)) {
      yield put(setOnlineAccAndEngAccountCreationAdjusted(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/online-account-and-engagement/account-creation-adjusted");
  } finally {
    yield put(setLoading("online_acc_and_eng_account_creation_adjusted", false));
  }
}

function* fetchOnlineAccAndEngActiveUsersSaga() {
  try {
    yield put(setLoading("online_acc_and_eng_active_users", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOnlineAccAndEngActiveUsers);
    if (!("error" in response)) {
      yield put(setOnlineAccAndEngActiveUsers(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/online-account-and-engagement/active-users");
  } finally {
    yield put(setLoading("online_acc_and_eng_active_users", false));
  }
}

function* fetchOnlineAccAndEngFirstEngagementSaga() {
  try {
    yield put(setLoading("online_acc_and_eng_first_engagement", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOnlineAccAndEngFirstEngagement);
    if (!("error" in response)) {
      yield put(setOnlineAccAndEngFirstEngagement(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/online-account-and-engagement/users-with-first-engagement");
  } finally {
    yield put(setLoading("online_acc_and_eng_first_engagement", false));
  }
}

function* fetchOnlineAccAndEngAvgVocRatingSaga() {
  try {
    yield put(setLoading("online_acc_and_eng_avg_voc_rating", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOnlineAccAndEngAvgVocRating);
    if (!("error" in response)) {
      yield put(setOnlineAccAndEngAvgVocRating(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/online-account-and-engagement/avg-voc-rating");
  } finally {
    yield put(setLoading("online_acc_and_eng_avg_voc_rating", false));
  }
}

function* fetchOnlineAccAndEngEnrolledUsersLinkedSaga() {
  try {
    yield put(setLoading("online_acc_and_eng_avg_enrolled_users_linked", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOnlineAccAndEngEnrolledUsersLinked);
    if (!("error" in response)) {
      yield put(setOnlineAccAndEngEnrolledUsersLinked(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/online-account-and-engagement/enrolled-users-linked");
  } finally {
    yield put(setLoading("online_acc_and_eng_avg_enrolled_users_linked", false));
  }
}

function* fetchOnlineAccAndEngVehiclesAddedSaga() {
  try {
    yield put(setLoading("online_acc_and_eng_avg_vehicles_added", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getOnlineAccAndEngVehiclesAdded);
    if (!("error" in response)) {
      yield put(setOnlineAccAndEngVehiclesAdded(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/online-account-and-engagement/vehicles-added");
  } finally {
    yield put(setLoading("online_acc_and_eng_avg_vehicles_added", false));
  }
}

function* fetchServiceLeadServiceRecommendationVisitsSaga() {
  try {
    yield put(setLoading("service_lead_service_recommendation_visits", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getServiceLeadServiceRecommendationVisits);
    if (!("error" in response)) {
      yield put(setServiceLeadServiceRecommendationVisits(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/service-lead/service-recommendation-visits");
  } finally {
    yield put(setLoading("service_lead_service_recommendation_visits", false));
  }
}

function* fetchServiceLeadServiceBookingFormVisitsSaga() {
  try {
    yield put(setLoading("service_lead_service_booking_form_visits", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getServiceLeadServiceBookingFormVisits);
    if (!("error" in response)) {
      yield put(setServiceLeadServiceBookingFormVisits(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/service-lead/service-booking-form-visits");
  } finally {
    yield put(setLoading("service_lead_service_booking_form_visits", false));
  }
}

function* fetchServiceLeadServiceBookingFormsOpenedSaga() {
  try {
    yield put(setLoading("service_lead_service_booking_forms_opened", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getServiceLeadServiceBookingFormsOpened);
    if (!("error" in response)) {
      yield put(setServiceLeadServiceBookingFormsOpened(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/service-lead/service-booking-forms-opened");
  } finally {
    yield put(setLoading("service_lead_service_booking_forms_opened", false));
  }
}

function* fetchServiceLeadConversionRateSaga() {
  try {
    yield put(setLoading("service_lead_service_recommendation_visits", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getServiceLeadConversionRate);
    if (!("error" in response)) {
      yield put(setServiceLeadConversionRate(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/service-lead/conversion-rate");
  } finally {
    yield put(setLoading("service_lead_service_recommendation_visits", false));
  }
}

function* fetchServiceLeadServiceLeadsSaga() {
  try {
    yield put(setLoading("service_lead_service_leads", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getServiceLeadServiceLeads);
    if (!("error" in response)) {
      yield put(setServiceLeadServiceLeads(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/service-lead/service-leads");
  } finally {
    yield put(setLoading("service_lead_service_leads", false));
  }
}
function* fetchServiceLeadBookingFormsVisitsVsServiceLeadsSaga() {
  try {
    yield put(setLoading("service_lead_booking_forms_visits_vs_service_leads", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getServiceLeadBookingFormsVisitsVsServiceLeads);
    if (!("error" in response)) {
      yield put(setServiceLeadBookingFormsVisitsVsServiceLeads(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/service-lead/service-booking-forms-visits-vs-service-leads");
  } finally {
    yield put(setLoading("service_lead_booking_forms_visits_vs_service_leads", false));
  }
}

function* fetchServiceLeadBookingFormVisitsByChannelOrTrendSaga({ payload: { groupBy, timeline } }: AnyAction) {
  try {
    yield put(setLoading("service_lead_booking_form_visits_by_channel_or_trend", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getServiceLeadBookingFormVisitsByChannelOrTrend, groupBy, timeline);
    if (!("error" in response)) {
      yield put(setServiceLeadBookingFormVisitsByChannelOrTrend(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/service-lead/service-booking-form-visits-by-channel-or-by-trend");
  } finally {
    yield put(setLoading("service_lead_booking_form_visits_by_channel_or_trend", false));
  }
}

function* fetchSelfServiceUserVisitingSelfLearningPageSaga() {
  try {
    yield put(setLoading("self_service_users_visiting_self_learning_page", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getSelfServiceUserVisitingSelfLearningPage);
    if (!("error" in response)) {
      yield put(setSelfServiceUserVisitingSelfLearningPage(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/self-service/users-visiting-self-learning-page");
  } finally {
    yield put(setLoading("self_service_users_visiting_self_learning_page", false));
  }
}

function* fetchSelfServiceSelfLearningPageVisitsAndTimeSpentSaga() {
  try {
    yield put(setLoading("self_service_self_learning_page_visits_and_time_spent", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getSelfServiceSelfLearningPageVisitsAndTimeSpent);
    if (!("error" in response)) {
      yield put(setSelfServiceSelfLearningPageVisitsAndTimeSpent(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/self-service/self-learning-page-visits-and-time-spent");
  } finally {
    yield put(setLoading("self_service_self_learning_page_visits_and_time_spent", false));
  }
}

function* fetchSelfServiceSelfLearningPageVisitsByChannelSaga({ payload: { trend } }: AnyAction) {
  try {
    yield put(setLoading("self_service_self_learning_page_visits_by_channel", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getSelfServiceSelfLearningPageVisitsByChannel, trend);
    if (!("error" in response)) {
      yield put(setSelfServiceSelfLearningPageVisitsByChannel(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/self-service/self-learning-page-visits-by-channel-or-by-trend");
  } finally {
    yield put(setLoading("self_service_self_learning_page_visits_by_channel", false));
  }
}

function* fetchSelfServiceSelfLearningPageVisitsByEntryPointSaga({ payload: { trend } }: AnyAction) {
  try {
    yield put(setLoading("self_service_self_learning_page_visits_by_entry_point", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getSelfServiceSelfLearningPageVisitsByEntryPoint, trend);
    if (!("error" in response)) {
      yield put(setSelfServiceSelfLearningPageVisitsByEntryPoint(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/self-service/self-learning-page-visits-by-channel-or-by-trend");
  } finally {
    yield put(setLoading("self_service_self_learning_page_visits_by_entry_point", false));
  }
}

function* fetchSelfServiceVocRatingForSelfLearningPageSaga() {
  try {
    yield put(setLoading("self_service_voc_rating_for_self_learning_page", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getSelfServiceVocRatingForSelfLearningPage);
    if (!("error" in response)) {
      yield put(setSelfServiceVocRatingForSelfLearningPage(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/self-service/voc-rating-for-self-learning-page");
  } finally {
    yield put(setLoading("self_service_voc_rating_for_self_learning_page", false));
  }
}

function* fetchSelfServiceVocRatingForSelfLearningPageOverTimeSaga() {
  try {
    yield put(setLoading("self_service_voc_rating_for_self_learning_page_over_time", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getSelfServiceVocRatingForSelfLearningPageOverTime);
    if (!("error" in response)) {
      yield put(setSelfServiceVocRatingForSelfLearningPageOverTime(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/self-service/voc-rating-for-self-learning-page-over-time");
  } finally {
    yield put(setLoading("self_service_voc_rating_for_self_learning_page_over_time", false));
  }
}

function* fetchSelfServiceTopicClassificationVerbatimDataSaga() {
  try {
    yield put(setLoading("self_service_topic_classification_verbatim_data", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getSelfServiceTopicClassificationVerbatimData);
    if (!("error" in response)) {
      yield put(setSelfServiceTopicClassificationVerbatimData(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/self-service/topic-classification-verbatim-data");
  } finally {
    yield put(setLoading("self_service_topic_classification_verbatim_data", false));
  }
}

function* fetchSelfServiceTopicClassificationDissatisfactionSaga() {
  try {
    yield put(setLoading("self_service_topic_classification_dissatisfaction", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getSelfServiceTopicClassificationDissatisfaction);
    if (!("error" in response)) {
      yield put(setSelfServiceTopicClassificationDissatisfaction(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/self-service/topic-classification-dissatisfaction");
  } finally {
    yield put(setLoading("self_service_topic_classification_dissatisfaction", false));
  }
}

function* fetchSelfServiceTopicClassificationSatisfactionSaga() {
  try {
    yield put(setLoading("self_service_topic_classification_satisfaction", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getSelfServiceTopicClassificationSatisfaction);
    if (!("error" in response)) {
      yield put(setSelfServiceTopicClassificationSatisfaction(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/self-service/topic-classification-satisfaction");
  } finally {
    yield put(setLoading("self_service_topic_classification_Satisfaction", false));
  }
}

function* fetchSelfServicePopularSearchTermsViaGoogleSaga() {
  try {
    yield put(setLoading("self_service_popular_search_terms_via_google", true));
    const response: Record<string, any> = yield retry(4, secondsDelay, getSelfServicePopularSearchTermsViaGoogle);
    if (!("error" in response)) {
      yield put(setSelfServicePopularSearchTermsViaGoogle(response));
    }
  } catch (err) {
    console.log("failed to fetch /use-ownership/self-service/popular-search-terms-via-google");
  } finally {
    yield put(setLoading("self_service_popular_search_terms_via_google", false));
  }
}
