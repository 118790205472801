export const SET_FILTERS = "SET_FILTERS";

export const FETCH_GEO_SCOPE = "FETCH_GEO_SCOPE";
export const SET_GEO_SCOPE = "SET_GEO_SCOPE";

export const FETCH_EUROPE_ACTIVATION_MARKET_GROUPS = "FETCH_EUROPE_ACTIVATION_MARKET_GROUPS";
export const SET_EUROPE_ACTIVATION_MARKET_GROUPS = "SET_EUROPE_ACTIVATION_MARKET_GROUPS";

export const FETCH_CCS_FILTERS = "FETCH_CCS_FILTERS";
export const SET_CCS_FILTERS = "SET_CCS_FILTERS";

export const FETCH_APP_RATINGS_FILTERS = "FETCH_APP_RATINGS_FILTERS";
export const SET_APP_RATINGS_FILTERS = "SET_APP_RATINGS_FILTERS";

export const FETCH_EUROPE_ACTIVATION_FILTERS = "FETCH_EUROPE_ACTIVATION_FILTERS";
export const SET_EUROPE_ACTIVATION_FILTERS = "SET_EUROPE_ACTIVATION_FILTERS";

export const FETCH_PURCHASE_FUNNEL_FILTERS = "FETCH_PURCHASE_FUNNEL_FILTERS";
export const SET_PURCHASE_FUNNEL_FILTERS = "SET_PURCHASE_FUNNEL_FILTERS";

export const FETCH_USER_GROUP_MARKETS = "FETCH_USER_GROUP_MARKETS";
export const SET_USER_GROUP_MARKETS = "SET_USER_GROUP_MARKETS";

export const FETCH_MTM_MARKETS = "FETCH_MTM_MARKETS";
export const SET_MTM_MARKETS = "SET_MTM_MARKETS";

export const FETCH_SEO_GEOGRAPHY = "FETCH_SEO_GEOGRAPHY";
export const SET_SEO_GEOGRAPHY = "SET_SEO_GEOGRAPHY";

export const SET_FILTER_OPTIONS_LIST = "SET_FILTER_OPTIONS_LIST";

export const SET_HAS_ARIYA = "SET_HAS_ARIYA";
export const SET_HAS_AURA = "SET_HAS_AURA";
export const SET_HAS_SAKURA = "SET_HAS_SAKURA";

export const SET_HAS_QASHQAI = "SET_HAS_QASHQAI";
export const SET_AVAILABLE_FILTERS = "SET_AVAILABLE_FILTERS";

export const FETCH_NEW_PURCHASE_FUNNEL_FILTERS = "FETCH_NEW_PURCHASE_FUNNEL_FILTERS";
export const SET_NEW_PURCHASE_FUNNEL_FILTERS = "SET_NEW_PURCHASE_FUNNEL_FILTER";

export const FETCH_MIDAS_FILTERS = "FETCH_MIDAS_FILTERS";
export const SET_MIDAS_FILTERS = "SET_MIDAS_FILTERS";

export const FETCH_MIDAS_FMI_BY_MODEL_FILTERS = "FETCH_MIDAS_FMI_BY_MODEL_FILTERS";

export const FETCH_MIDAS_MEDIA_MIX_FILTERS = "FETCH_MIDAS_MEDIA_MIX_FILTERS";

export const FETCH_MIDAS_MTP_CATEGORY_FILTERS = "FETCH_MIDAS_MTP_CATEGORY_FILTERS";

export const FETCH_MIDAS_STRATEGIC_DEALER_SUPPORT_FILTERS = "FETCH_MIDAS_STRATEGIC_DEALER_SUPPORT_FILTERS";
export const FETCH_MIDAS_STRATEGIC_CE_FILTERS = "FETCH_MIDAS_STRATEGIC_CE_FILTERS";
export const FETCH_MIDAS_FIXED_FILTERS = "FETCH_MIDAS_FIXED_FILTERS";
export const FETCH_MIDAS_SPEND_VS_TRAFFIC_FILTERS = "FETCH_MIDAS_SPEND_VS_TRAFFIC_FILTERS";

export const SET_SPEND_VS_TRAFFIC_RESPONSE_ERROR = "SET_SPEND_VS_TRAFFIC_RESPONSE_ERROR";

export const SET_PMO_RESPONSE_ERROR = "SET_PMO_RESPONSE_ERROR";

export const SET_DFP_RESPONSE_ERROR = "SET_DFP_RESPONSE_ERROR";

export const FETCH_PRODUCT_PERFORMANCE_FILTERS = "FETCH_PRODUCT_PERFORMANCE_FILTERS";
export const SET_PRODUCT_PERFORMANCE_FILTERS = "SET_PRODUCT_PERFORMANCE_FILTERS";

export const FETCH_UAE_ACTIVATION_FILTERS = "FETCH_UAE_ACTIVATION_FILTERS";
export const SET_UAE_ACTIVATION_FILTERS = "SET_UAE_ACTIVATION_FILTERS";

export const FETCH_VOC_FILTERS = "FETCH_VOC_FILTERS";
export const SET_VOC_FILTERS = "SET_VOC_FILTERS";

export const FETCH_CPO_OPERATIONAL_FILTERS = "FETCH_CPO_OPERATIONAL_FILTERS";
export const SET_CPO_OPERATIONAL_FILTERS = "SET_CPO_OPERATIONAL_FILTERS";

export const FETCH_ECOMMERCE_FILTERS = "FETCH_ECOMMERCE_FILTERS";
export const SET_ECOMMERCE_FILTERS = "SET_ECOMMERCE_FILTERS";

export const FETCH_MSRP_FILTERS = "FETCH_MSRP_FILTERS";
export const SET_MSRP_FILTERS = "SET_MSRP_FILTERS";

export const FETCH_JOURNEY_EXCELLENCE_FILTERS = "FETCH_JOURNEY_EXCELLENCE_FILTERS";
export const SET_JOURNEY_EXCLLENCE_FILTERS = "SET_JOURNEY_EXCLLENCE_FILTERS";

export const FETCH_OCE_MODELS_BY_BRAND_FILTERS = "FETCH_OCE_MODELS_BY_BRAND_FILTERS";
export const SET_OCE_MODEL_BY_BRAND_FILTERS = "SET_OCE_MODEL_BY_BRAND_FILTERS";

export const FETCH_OCE_MARKETS_BY_BRAND_FILTERS = "FETCH_OCE_MARKETS_BY_BRAND_FILTERS";
export const SET_OCE_MARKETS_BY_BRAND_FILTERS = "SET_OCE_MARKETS_BY_BRAND_FILTERS";

export const FETCH_OCE_CHANNELS_BY_BRAND_FILTERS = "FETCH_OCE_CHANNELS_BY_BRAND_FILTERS";
export const SET_OCE_CHANNELS_BY_BRAND_FILTERS = "SET_OCE_CHANNELS_BY_BRAND_FILTERS";

export const FETCH_OCE_ALL_REGIONS = "FETCH_OCE_ALL_REGIONS";
export const SET_OCE_ALL_REGIONS = "SET_OCE_ALL_REGIONS";

export const FETCH_OCE_ALL_MARKET_REGIONS = "FETCH_OCE_ALL_MARKET_REGIONS";
export const SET_OCE_ALL_MARKET_REGIONS = "SET_OCE_ALL_MARKET_REGIONS";

export const FETCH_USE_OWNERSHIP_FILTERS = "FETCH_USE_OWNERSHIP_FILTERS";
export const SET_USE_OWNERSHIP_FILTERS = "SET_USE_OWNERSHIP_FILTERS";

export const FETCH_BRAND_PERFORMANCE_VOLUME_FILTERS = "FETCH_BRAND_PERFORMANCE_VOLUME_FILTERS";
export const FETCH_BRAND_PERFORMANCE_OAO_FILTERS = "FETCH_BRAND_PERFORMANCE_OAO_FILTERS";
export const FETCH_BRAND_PERFORMANCE_IMAGE_ATTRIBUTE_FILTERS = "FETCH_BRAND_PERFORMANCE_IMAGE_ATTRIBUTE_FILTERS";
export const SET_BRAND_PERFORMANCE_VOLUME_FILTERS = "SET_BRAND_PERFORMANCE_VOLUME_FILTERS";
export const SET_BRAND_PERFORMANCE_OAO_FILTERS = "SET_BRAND_PERFORMANCE_OAO_FILTERS";
export const SET_BRAND_PERFORMANCE_IMAGE_ATTRIBUTE_FILTERS = "SET_BRAND_PERFORMANCE_IMAGE_ATTRIBUTE_FILTERS";

export const FETCH_EXECUTIVE_SUMMARY_FILTERS = "FETCH_EXECUTIVE_SUMMARY_FILTERS";
export const SET_EXECUTIVE_SUMMARY_FILTERS = "SET_EXECUTIVE_SUMMARY_FILTERS";
