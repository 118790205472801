import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component, ReactNode } from "react";
import WebFont from "webfontloader";
import { BLACK, getCurrencyPMO, LIGHT_GREY, PURPLE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { configureDateAxis, configureValueAxis, legendConfiguration, lineSeriesConfiguration } from "../helpers/configurations";
import { formatValueAxisUnits, getTooltipTextFormat } from "../helpers/helpers";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { dateAxisLabelFormatter_QuarterlyFY, formatDateAxisLabelToQuarterlyFY } from "../helpers/pmoConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  chartId: string;
  data: any;
  theme: ThemeInt;
  isLoading: boolean;
  seriesList?: { name: string; field: string; seriesColor: string }[];
  maxAmount?: number;
  granularity?: string;
  isCurrency?: boolean;
  market?: string;
  hideLegend?: boolean;
}

class UsedCarsTrendlineChart extends Component<Props> {
  chartId = this.props.chartId;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { data, theme, seriesList, granularity, isCurrency, market, hideLegend } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;

    if (seriesList?.length && !hideLegend) this.chart.legend = legendConfiguration(theme);
    this.chart.colors.list = [color(PURPLE)];
    this.chart.paddingBottom = 10;

    // Create Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.minGridDistance = granularity == "weekly" ? 50 : 80;
    dateAxis.dateFormats.setKey("day", "dd MMM");
    dateAxis.dateFormats.setKey("week", "dd MMM");
    dateAxis.dateFormats.setKey("month", "MMM");
    dateAxis.periodChangeDateFormats.setKey("day", "dd MMM");
    dateAxis.periodChangeDateFormats.setKey("week", "dd MMM");
    dateAxis.periodChangeDateFormats.setKey("month", "MMM");

    granularity === "quarterly" && dateAxis.renderer.labels.template.adapter.add("text", dateAxisLabelFormatter_QuarterlyFY);
    dateAxis.dateFormatter.dateFormat = "MMM";

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.renderer.baseGrid.stroke = theme === "light" ? color(BLACK) : color(LIGHT_GREY);
    valueAxis.renderer.baseGrid.strokeWidth = 1;
    valueAxis.renderer.baseGrid.strokeOpacity = 1;
    valueAxis.min = 0;
    isCurrency ? formatValueAxisUnits(valueAxis, market) : formatValueAxisUnits(valueAxis);

    if (seriesList?.length) {
      seriesList?.forEach((val: { name: string; field: string; seriesColor: string }, index: number) => {
        const series = this.chart.series.push(new LineSeries());

        const bullet = lineSeriesConfiguration(series, {
          valueY: val.field,
          name: val?.name,
        });
        if (granularity === "quarterly") series.dataFields.categoryX = "quarter";
        bullet.adapter.add("tooltipText", (text, target) => {
          const data = target.dataItem?.dataContext;
          if (granularity === "quarterly") {
            //@ts-ignore
            const dateVal = formatDateAxisLabelToQuarterlyFY(data?.date);
            //@ts-ignore
            text = `[font-size: var(--regular_font_size);bold]${dateVal} : ${
              isCurrency && market ? getCurrencyPMO(market) : ""
            }{valueY.formatNumber('#,###.##')}[/] `;
          } else {
            //@ts-ignore
            text = `[font-size: var(--regular_font_size);bold]{dateX.formatDate('dd MMM YYYY')} : ${
              isCurrency && market ? getCurrencyPMO(market) : ""
            }{valueY.formatNumber('#,###.##')}[/]\n`;
            getTooltipTextFormat(granularity);
          }
          return text;
        });

        series.fill = color(val?.seriesColor);
        series.stroke = color(val?.seriesColor);
        series.dataFields.dateX = "period";
      });
    }

    const maxValue = this.getMaxValue(data);
    if (maxValue <= 1) {
      this.createGrid(valueAxis, 0.25);
      this.createGrid(valueAxis, 0.5);
      this.createGrid(valueAxis, 0.75);
      valueAxis.numberFormatter.numberFormat = "#";
    }

    if (maxValue >= 1000 && !isCurrency) {
      valueAxis.numberFormatter.numberFormat = "#.0a";
    }
  }

  getMaxValue(data: Array<Record<string, any>>) {
    const maxValue = data.reduce((res: Array<number>, item: Record<string, any>) => {
      const values = Object.values(item).filter((value) => typeof value == "number");
      return [...res, ...values];
    }, []);
    return Math.max(...maxValue);
  }

  createGrid(axis: any, value: number) {
    const range = axis.axisRanges.create();
    range.value = value;
    range.label.text = "{value}";
  }

  render(): ReactNode {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartId} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default UsedCarsTrendlineChart;
