import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchProductPerformanceLastDataRefresh, setPageName } from "../../actions";
import {
  fetchAccessibility,
  fetchBugsReleaseBreakdown,
  fetchBugsStatusBreakdown,
  fetchCustomerExperience,
  fetchDigitalPerformance,
  fetchErrorPages,
  fetchFeaturesActivated,
  fetchInfluenceOnLeads,
  fetchInfluenceOnShoppingTools,
  fetchLiveMarkets,
  fetchPagePerformance,
  fetchPagePerformanceBreakdown,
  fetchTotalBugs,
} from "../../actions/productPerformanceActions";
import "../../assets/styles/pages/productPerformance.scss";
import Collapsible from "../../components/Collapsible/Collapsible";
import ProductPerformanceFilters from "../../components/Filters/ProductPerformanceFilters";
import { DashboardLayout } from "../../components/Layouts";
import { DefaultPageContent } from "../../components/PageContent";
import { PageTitle } from "../../components/PageContent/subs";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import GlobalPerformance from "./components/GlobalPerformance";
import MarketPerformance from "./components/MarketPerformance";
import { getLastWeekDatesTitle } from "./helpers/helpers";

const ProductPerformance = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { history } = props;

  const { product, market, date_range: dateRangeParam } = useSelector((state: RootStateOrAny) => state.parameters);
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.product_performance_last_refresh_date);

  useEffect(() => {
    const titleDescription = getLastWeekDatesTitle();
    dispatch(setPageName(`Product Performance Dashboard - ${product} ${dateRangeParam == "Last week" ? titleDescription : ""}`));
  }, [product, dateRangeParam, lastDataRefresh]);

  // Usage tracking
  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Product Performance", page: "Product Performance" });
  }, [location.pathname]);

  useEffect(() => {
    Promise.all([
      dispatch(fetchLiveMarkets()),
      dispatch(fetchFeaturesActivated()),
      dispatch(fetchDigitalPerformance()),
      dispatch(fetchInfluenceOnShoppingTools()),
      dispatch(fetchInfluenceOnLeads()),
      dispatch(fetchTotalBugs()),
      dispatch(fetchBugsStatusBreakdown()),
      dispatch(fetchBugsReleaseBreakdown()),
      dispatch(fetchPagePerformance()),
      dispatch(fetchPagePerformanceBreakdown()),
      dispatch(fetchAccessibility()),
      dispatch(fetchProductPerformanceLastDataRefresh()),
      dispatch(fetchCustomerExperience()),
      dispatch(fetchErrorPages()),
    ]);
  }, [history.location.search, dispatch]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<ProductPerformanceFilters />}
        dataDocumentation="product_performance_dashboard"
        lastDataRefresh={lastDataRefresh}
        pageTitle={<PageTitle dataDocumentation="product_performance_dashboard" />}
      >
        <div className="product-performance-page">
          <Collapsible title="Global Performance" show={true}>
            <GlobalPerformance />
          </Collapsible>
          <Collapsible title={`Market Performance: ${market}`} show={true}>
            <MarketPerformance />
          </Collapsible>
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default ProductPerformance;
