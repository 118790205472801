export const FETCH_FMI_BY_QUARTER = "FETCH_FMI_BY_QUARTER";
export const SET_FMI_BY_QUARTER = "SET_FMI_BY_QUARTER";

export const FETCH_MTP_SPEND = "FETCH_MTP_SPEND";
export const SET_MTP_SPEND = "SET_MTP_SPEND";

export const FETCH_FMI_BY_MODEL_FMI_BY_QUARTER = "FETCH_FMI_BY_MODEL_FMI_BY_QUARTER";
export const SET_FMI_BY_MODEL_FMI_BY_QUARTER = "SET_FMI_BY_MODEL_FMI_BY_QUARTER";

export const FETCH_MEDIA_MIX_DATA_BY_QUARTER = "FETCH_MEDIA_MIX_DATA_BY_QUARTER";
export const SET_MEDIA_MIX_DATA_BY_QUARTER = "SET_MEDIA_MIX_DATA_BY_QUARTER";

export const FETCH_MTP_CATEGORY_DATA_BY_QUARTER = "FETCH_MTP_CATEGORY_DATA_BY_QUARTER";
export const SET_MTP_CATEGORY_DATA_BY_QUARTER = "SET_MTP_CATEGORY_DATA_BY_QUARTER";
export const SET_MTP_CATEGORY_HIDE_YR_SCNR_RV = "SET_MTP_CATEGORY_HIDE_YR_SCNR_RV:";

export const FETCH_STRATEGIC_DEALER_SUPPORT_DATA_BY_QUARTER = "FETCH_STRATEGIC_DEALER_SUPPORT_DATA_BY_QUARTER";
export const SET_STRATEGIC_DEALER_SUPPORT_DATA_BY_QUARTER = "SET_STRATEGIC_DEALER_SUPPORT_DATA_BY_QUARTER";

export const FETCH_STRATEGIC_CE_DATA_BY_QUARTER = "FETCH_STRATEGIC_CE_DATA_BY_QUARTER";
export const SET_STRATEGIC_CE_DATA_BY_QUARTER = "SET_STRATEGIC_CE_DATA_BY_QUARTER";

export const FETCH_FIXED_DATA_BY_QUARTER = "FETCH_FIXED_DATA_BY_QUARTER";
export const SET_FIXED_DATA_BY_QUARTER = "SET_FIXED_DATA_BY_QUARTER";

export const FETCH_SPEND_VS_TRAFFIC_MODEL_DATA = "FETCH_SPEND_VS_TRAFFIC_MODEL_DATA";
export const SET_SPEND_VS_TRAFFIC_MODEL_DATA = "SET_SPEND_VS_TRAFFIC_MODEL_DATA";

export const FETCH_SPEND_VS_TRAFFIC_MARKET_DATA = "FETCH_SPEND_VS_TRAFFIC_MARKET_DATA";
export const SET_SPEND_VS_TRAFFIC_MARKET_DATA = "SET_SPEND_VS_TRAFFIC_MARKET_DATA";
