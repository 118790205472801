import { CategoryAxis, ColumnSeries, ILineSeriesDataFields, LabelBullet, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component, ReactNode } from "react";
import { connect, RootStateOrAny } from "react-redux";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { BLACK, CYAN, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { configureCategoryAxis, configureValueAxis } from "../helpers/configurations";
import { formatValueAxisUnits } from "../helpers/helpers";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  chartId: string;
  data: any;
  theme: ThemeInt;
  isLoading: boolean;
}

class CustomerJourneyBarChart extends Component<Props> {
  chartId = this.props.chartId;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { theme, data } = this.props;

    this.chart = create(this.chartId, XYChart);
    this.chart.data = data;
    oceChartConfiguration(this.chart);
    this.chart.colors.list = [color(CYAN), theme === "light" ? color(BLACK) : color(WHITE)];
    this.chart.paddingBottom = 10;
    this.chart.maskBullets = false;
    this.chart.paddingRight = 50;

    // Create Axes
    const categoryAxis = this.chart.yAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;
    categoryAxis.renderer.minGridDistance = 10;

    const valueAxis = this.chart.xAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    const label = valueAxis.renderer.labels.template;
    label.wrap = false;
    label.truncate = false;
    formatValueAxisUnits(valueAxis);

    const dataFields: Record<string, string> = {
      amount: "amount",
      categoryY: "name",
    };

    this.createSeries("amount", "amount", CYAN, dataFields);
  }

  createSeries(field: string, name: string, seriesColor: string, dataFields: Record<string, string>) {
    const series = this.chart.series.push(new ColumnSeries());
    const valueX = dataFields[field];
    series.dataFields = {
      valueX: valueX,
      ...dataFields,
    } as ILineSeriesDataFields;
    series.name = name;
    series.columns.template.tooltipText = "{name}: [bold]{valueX.formatNumber('#,###.#')}[/]";
    series.columns.template.height = percent(80);
    series.sequencedInterpolation = true;
    const labelBullet = series.bullets.push(new LabelBullet());
    labelBullet.label.text = `{valueX.formatNumber("#,###.#")}`;
    labelBullet.label.fill = color(this.props.theme === "light" ? BLACK : WHITE);
    labelBullet.label.adapter.add("horizontalCenter", function (center, target) {
      return "left";
    });
    labelBullet.label.dx = 5;
    labelBullet.label.truncate = false;
    labelBullet.label.hideOversized = false;
  }

  render(): ReactNode {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartId} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

function mapStateToProps(state: RootStateOrAny) {
  const currency = state.journey_excellence.currency;
  return {
    currency,
  };
}

export default connect(mapStateToProps)(CustomerJourneyBarChart);
