import { CategoryAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { disposeChart } from "../helpers/chartHelpers";
import { configureCategoryAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  series: Array<string>;
  theme: ThemeInt;
}

export class AppRatingsSplitGeoLineGraph extends Component<Props> {
  chartId = "appRatingsBreakdownChart";
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId);
      this.initChart();
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, series, theme } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "0|#.0";

    // Creating Axes
    const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "label";

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);

    // Creating Series
    this.createSeries(series);
  }

  createSeries(seriesList: Array<string>) {
    seriesList.forEach((key) => {
      const series = this.chart.series.push(new LineSeries());
      const bullet = lineSeriesConfiguration(series, {
        name: key,
        valueY: `${key}_rating`,
      });
      series.dataFields.categoryX = "label";

      series.propertyFields.fill = `${key}_color`;
      series.propertyFields.stroke = `${key}_color`;
      series.propertyFields.strokeDasharray = `${key}_dashedArray`;
      series.tooltipText = "[b]{categoryX} {name}:[/] {valueY.formatNumber('#.')}%";

      bullet.propertyFields.fill = `${key}_color`;
      bullet.propertyFields.stroke = `${key}_color`;
      bullet.tooltipText = "[b]{name} {categoryX}:[/] {valueY.formatNumber('#.0')}";
    });
  }

  render() {
    return <div id={this.chartId} className={"metricChart"} />;
  }
}
