import { CircleBullet, ColumnSeries, DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter, percent } from "@amcharts/amcharts4/core";
import _, { isNull } from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, BRIGHT_BLUE, CYAN, DARK_ACCENT, FOREST_GREEN, LIGHT_GREEN, LIGHT_GREY, LIGHT_ORANGE, RED, WHITE } from "../../../constants";
import { formatToThreeDigits } from "../../../utils/numberFormatting";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { columnSeriesConfiguration, configureDateAxis, configureValueAxis, legendConfiguration } from "../helpers/configurations";
import { formatDateAxisLabelToMonthly } from "../helpers/mtmConfigurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  chartName: string;
  isLoading: boolean;
  theme: ThemeInt;
}

export default class AppSatisfactionChart extends Component<Props> {
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { data, chartName, theme } = this.props;

    this.chart = create(chartName, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.colors.list = [color(BRIGHT_BLUE), color(LIGHT_GREY), color(CYAN)];
    //Add legends
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.itemContainers.template.paddingTop = 2.5;
    this.chart.legend.itemContainers.template.marginBottom = 25;

    //Default x axis to show current date
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    // dateAxis.renderer.minGridDistance = 30;

    //Y Axis that is used by default
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.numberFormatter.numberFormat = "#a";

    //Y Axis that is used for the lines in the graph
    const lineValueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(lineValueAxis, theme);
    lineValueAxis.numberFormatter = new NumberFormatter();
    lineValueAxis.numberFormatter.numberFormat = "#a";
    lineValueAxis.renderer.opposite = true;
    lineValueAxis.min = 0;
    lineValueAxis.max = 100;
    lineValueAxis.strictMinMax = true;
    // lineValueAxis.syncWithAxis = valueAxis;
    lineValueAxis.renderer.labels.template.adapter.add("text", (text) => {
      return text + "%";
    });

    const series = this.chart.series.push(new ColumnSeries());
    columnSeriesConfiguration(series, {
      name: "Positive",
      valueY: "positive_review_volume",
      stacked: true,
      yAxis: valueAxis,
    });
    series.dataFields.dateX = "date";
    series.columns.template.tooltipText = "{dateX}: {valueY.formatNumber('#,###')}";
    series.columns.template.width = percent(65);
    if (series.tooltip) {
      series.tooltip.getFillFromObject = false;
      series.tooltip.autoTextColor = false;
      series.tooltip.background.fill = color(WHITE);
      series.tooltip.label.fill = color(BLACK);
    }
    series.columns.template.adapter.add("tooltipText", (value: any, target: any) => {
      const dateValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };

      let text = `[bold font-size: var(--regular_font_size)]${formatDateAxisLabelToMonthly(dateValue?.date)}[/]\n`;

      this.chart.series.each((item) => {
        if (!item.isHidden) {
          const value = item.dataFields.valueY && dateValue ? (dateValue[item.dataFields.valueY] as number) : null;

          text += `[font-size: var(--regular_font_size) ${item.realStroke}]●[/] [font-size: var(--regular_font_size)]${
            item.name
          }: [font-size: var(--regular_font_size)]${
            isNull(value)
              ? "n/a"
              : item.dataFields.valueY?.includes("percentage")
              ? formatToThreeDigits(value as number) + "%"
              : value.toLocaleString("en-US")
          }\n`;
        }
      });
      return text;
    });
    series.columns.template.fill = color(LIGHT_GREEN);
    series.columns.template.stroke = color(LIGHT_GREEN);

    const series2 = this.chart.series.push(new ColumnSeries());
    columnSeriesConfiguration(series2, {
      name: "Neutral",
      valueY: "neutral_review_volume",
      stacked: true,
      yAxis: valueAxis,
    });
    series2.dataFields.dateX = "date";
    series2.columns.template.tooltipText = "{dateX}: {valueY.formatNumber('#,###')}";
    series2.columns.template.width = percent(65);
    if (series2.tooltip) {
      series2.tooltip.getFillFromObject = false;
      series2.tooltip.autoTextColor = false;
      series2.tooltip.background.fill = color(WHITE);
      series2.tooltip.label.fill = color(BLACK);
    }
    series2.columns.template.adapter.add("tooltipText", (value: any, target: any) => {
      const dateValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };

      let text = `[bold font-size: var(--regular_font_size)]${formatDateAxisLabelToMonthly(dateValue?.date)}[/]\n`;

      this.chart.series.each((item) => {
        if (!item.isHidden) {
          const value = item.dataFields.valueY && dateValue ? (dateValue[item.dataFields.valueY] as number) : null;

          text += `[font-size: var(--regular_font_size) ${item.realStroke}]●[/] [font-size: var(--regular_font_size)]${
            item.name
          }: [font-size: var(--regular_font_size)]${
            isNull(value)
              ? "n/a"
              : item.dataFields.valueY?.includes("percentage")
              ? formatToThreeDigits(value as number) + "%"
              : value.toLocaleString("en-US")
          }\n`;
        }
      });
      return text;
    });

    const series3 = this.chart.series.push(new ColumnSeries());
    columnSeriesConfiguration(series3, {
      name: "Negative",
      valueY: "negative_review_volume",
      stacked: true,
      yAxis: valueAxis,
    });
    series3.dataFields.dateX = "date";
    series3.columns.template.tooltipText = "{dateX}: {valueY.formatNumber('#,###')}";
    series3.columns.template.width = percent(65);
    if (series3.tooltip) {
      series3.tooltip.getFillFromObject = false;
      series3.tooltip.autoTextColor = false;
      series3.tooltip.background.fill = color(WHITE);
      series3.tooltip.label.fill = color(BLACK);
    }
    series3.columns.template.adapter.add("tooltipText", (value: any, target: any) => {
      const dateValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };

      let text = `[bold font-size: var(--regular_font_size)]${formatDateAxisLabelToMonthly(dateValue?.date)}[/]\n`;

      this.chart.series.each((item) => {
        if (!item.isHidden) {
          const value = item.dataFields.valueY && dateValue ? (dateValue[item.dataFields.valueY] as number) : null;

          text += `[font-size: var(--regular_font_size) ${item.stroke}]●[/] [font-size: var(--regular_font_size)]${
            item.name
          }: [font-size: var(--regular_font_size)]${
            isNull(value)
              ? "n/a"
              : item.dataFields.valueY?.includes("percentage")
              ? formatToThreeDigits(value as number) + "%"
              : value.toLocaleString("en-US")
          }\n`;
        }
      });
      return text;
    });
    series3.columns.template.fill = color(LIGHT_ORANGE);
    series3.columns.template.stroke = color(LIGHT_ORANGE);

    //Function to create the series using the arguments given
    const lineSeries = this.chart.series.push(new LineSeries());
    lineSeries.name = "Positive percentage";
    lineSeries.dataFields.dateX = "date";
    lineSeries.dataFields.valueY = "positive_review_percentage";
    lineSeries.tooltipText = "{dateX}: [b]{valueY}[/]";
    lineSeries.strokeWidth = 2;
    lineSeries.fill = color(theme === "dark" ? WHITE : DARK_ACCENT);
    lineSeries.stroke = color(theme === "dark" ? WHITE : DARK_ACCENT);
    if (lineSeries.tooltip) {
      lineSeries.tooltip.getFillFromObject = false;
      lineSeries.tooltip.autoTextColor = false;
      lineSeries.tooltip.background.fill = color(WHITE);
      lineSeries.tooltip.label.fill = color(BLACK);
    }
    lineSeries.yAxis = lineValueAxis;
    lineSeries.stroke = color(FOREST_GREEN);

    const bullet = lineSeries.bullets.push(new CircleBullet());
    bullet.circle.radius = 2;
    bullet.fill = color(FOREST_GREEN);
    bullet.adapter.add("tooltipText", (value: any, target: any) => {
      const dateValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };

      let text = `[bold font-size: var(--regular_font_size)]${formatDateAxisLabelToMonthly(dateValue?.date)}[/]\n`;

      this.chart.series.each((item) => {
        if (!item.isHidden) {
          const value = item.dataFields.valueY && dateValue ? (dateValue[item.dataFields.valueY] as number) : null;
          text += `[font-size: var(--regular_font_size) ${item.stroke}]●[/] [font-size: var(--regular_font_size)]${
            item.name
          }: [font-size: var(--regular_font_size)]${
            isNull(value)
              ? "n/a"
              : item.dataFields.valueY?.includes("percentage")
              ? formatToThreeDigits(value as number) + "%"
              : value.toLocaleString("en-US")
          }\n`;
        }
      });
      return text;
    });

    const lineSeries2 = this.chart.series.push(new LineSeries());
    lineSeries2.name = "Negative percentage";
    lineSeries2.dataFields.dateX = "date";
    lineSeries2.dataFields.valueY = "negative_review_percentage";
    lineSeries2.tooltipText = "{dateX}: [b]{valueY}[/]";
    lineSeries2.strokeWidth = 2;
    lineSeries2.fill = color(theme === "dark" ? WHITE : DARK_ACCENT);
    lineSeries2.stroke = color(theme === "dark" ? WHITE : DARK_ACCENT);
    if (lineSeries2.tooltip) {
      lineSeries2.tooltip.getFillFromObject = false;
      lineSeries2.tooltip.autoTextColor = false;
      lineSeries2.tooltip.background.fill = color(WHITE);
      lineSeries2.tooltip.label.fill = color(BLACK);
    }
    lineSeries2.yAxis = lineValueAxis;
    lineSeries2.stroke = color(RED);

    const bullet2 = lineSeries2.bullets.push(new CircleBullet());
    bullet2.circle.radius = 2;
    bullet2.fill = color(RED);
    bullet2.adapter.add("tooltipText", (value: any, target: any) => {
      const dateValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };

      let text = `[bold font-size: var(--regular_font_size)]${formatDateAxisLabelToMonthly(dateValue?.date)}[/]\n`;

      this.chart.series.each((item) => {
        if (!item.isHidden) {
          const value = item.dataFields.valueY && dateValue ? (dateValue[item.dataFields.valueY] as number) : null;
          text += `[font-size: var(--regular_font_size) ${item.stroke}]●[/] [font-size: var(--regular_font_size)]${
            item.name
          }: [font-size: var(--regular_font_size)]${
            isNull(value)
              ? "n/a"
              : item.dataFields.valueY?.includes("percentage")
              ? formatToThreeDigits(value as number) + "%"
              : value.toLocaleString("en-US")
          }\n`;
        }
      });
      return text;
    });
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}
