import { retry, takeLatest } from "@redux-saga/core/effects";
import { put } from "redux-saga/effects";
import {
  setBaselineChartAnnotations,
  setMtmAnnotations,
  setOCEAnnotationsKbaTypes,
  setOCEAnnotationsLeadTypes,
  setPurchaseFunnelAnnotations,
  setVisitsChartAnnotations,
} from "../actions";
import {
  FETCH_BASELINE_CHART_ANNOTATIONS_DATA,
  FETCH_MTM_ANNOTATIONS,
  FETCH_OCE_ANNOTATION_KBA_TYPES,
  FETCH_OCE_ANNOTATION_LEAD_TYPES,
  FETCH_PF_ANNOTATIONS,
  FETCH_VISITS_CHART_ANNOTATIONS_DATA,
} from "../actions/actionTypes";
import { setLoading } from "../actions/loadingActions";
import { AnnotationsQueryParams, getAnnotations, getOCEKbaTypes, getOCELeadTypes } from "../api/annotationsApi";
import { secondsDelay } from "../api/utils";
import { MetricType } from "../constants/interface/admin/common";
import { Annotation } from "../pages/Admin/types";

// Watcher sagas
export function* watchFetchBaselineAnnotationsData() {
  yield takeLatest(FETCH_BASELINE_CHART_ANNOTATIONS_DATA, fetchBaselineChartAnnotations);
}

export function* watchFetchVisitsAnnotationsData() {
  yield takeLatest(FETCH_VISITS_CHART_ANNOTATIONS_DATA, fetchVisitsChartAnnotations);
}

export function* watchFetchOCEAnnotationsKbaTypes() {
  yield takeLatest(FETCH_OCE_ANNOTATION_KBA_TYPES, fetchOCEAnnotationsKbaTypes);
}

export function* watchFetchOCEAnnotationsLeadTypes() {
  yield takeLatest(FETCH_OCE_ANNOTATION_LEAD_TYPES, fetchOCEAnnotationsLeadTypes);
}

export function* watchFetchPurchaseFunnelAnnotationsLeadTypes() {
  yield takeLatest(FETCH_PF_ANNOTATIONS, fetchPurchaseFunnelAnnotations);
}

export function* watchFetchMtmAnnotationsData() {
  yield takeLatest(FETCH_MTM_ANNOTATIONS, fetchMtmAnnotations);
}

// Worker sagas
export function* fetchBaselineChartAnnotations(action: { type: string; params: AnnotationsQueryParams; dashboard: string }) {
  try {
    yield put(setLoading("oce_annotations", true));

    const response: Array<Annotation> = yield retry(4, secondsDelay, getAnnotations, action.params, action.dashboard);

    if (!("error" in response)) {
      yield put(setBaselineChartAnnotations(response));
    }
  } catch (err) {
    console.log("failed to fetch digital performance annotations data", err);
  } finally {
    yield put(setLoading("oce_annotations", false));
  }
}

export function* fetchVisitsChartAnnotations(action: { type: string; params: AnnotationsQueryParams; dashboard: string }) {
  try {
    const response: Array<Annotation> = yield retry(4, secondsDelay, getAnnotations, action.params, action.dashboard);

    if (!("error" in response)) {
      yield put(setVisitsChartAnnotations(response));
    }
  } catch (err) {
    console.log("failed to fetch digital performance annotations data", err);
  }
}

export function* fetchOCEAnnotationsKbaTypes() {
  try {
    const response: MetricType[] = yield retry(4, secondsDelay, getOCEKbaTypes);

    if (!("error" in response)) {
      yield put(setOCEAnnotationsKbaTypes(response));
    }
  } catch (err) {
    console.log("failed to fetch brands data", err);
  }
}

export function* fetchOCEAnnotationsLeadTypes() {
  try {
    const response: MetricType[] = yield retry(4, secondsDelay, getOCELeadTypes);

    if (!("error" in response)) {
      yield put(setOCEAnnotationsLeadTypes(response));
    }
  } catch (err) {
    console.log("failed to fetch brands data", err);
  }
}

export function* fetchPurchaseFunnelAnnotations(action: { type: string; params: AnnotationsQueryParams; dashboard: string }) {
  try {
    yield put(setLoading("purchase_funnel_annotations", true));

    const response: Array<Annotation> = yield retry(4, secondsDelay, getAnnotations, action.params, action.dashboard);

    if (!("error" in response)) {
      yield put(setPurchaseFunnelAnnotations(response));
    }
  } catch (err) {
    console.log("failed to fetch purchase funnel annotations data", err);
  } finally {
    yield put(setLoading("purchase_funnel_annotations", false));
  }
}

export function* fetchMtmAnnotations(action: { type: string; params: AnnotationsQueryParams; dashboard: string }) {
  try {
    yield put(setLoading("mtm_annotations", true));

    const response: Array<Annotation> = yield retry(4, secondsDelay, getAnnotations, action.params, action.dashboard);

    if (!("error" in response)) {
      yield put(setMtmAnnotations(response));
    }
  } catch (err) {
    console.log("failed to fetch mtm annotations data", err);
  } finally {
    yield put(setLoading("mtm_annotations", false));
  }
}
