import moment from "moment";
import { OCE_DATE_FILTER_OPTIONS } from "../constants";
import { isEmpty } from "../utils/utilityFunctions";
import { newApiFetchV2 } from "./utils";

export const journeyExcellenceApiParamsToObject = () => {
  const parameters = new URLSearchParams(window.location.search);

  const dateRangeParam = parameters?.get("date_range");
  const brandParameter = parameters?.get("brand");
  const marketParameter =
    parameters.get("market") == "All" || isEmpty(parameters.get("market")) ? [] : parameters.get("market")?.split(",");
  const currencyParameter = parameters?.get("currency");
  const modelParameter = parameters?.get("model");
  const leadsSourceParameter = parameters?.get("leads_source");
  const leadGroupParameter = parameters?.get("lead_group");

  const paramObj: any = {
    brand: brandParameter,
    market: marketParameter,
  };

  if (dateRangeParam) {
    if (OCE_DATE_FILTER_OPTIONS.includes(dateRangeParam)) {
      paramObj["date_range"] = dateRangeParam.split(" ").join("_").toLocaleLowerCase();
    } else {
      paramObj["date_range"] = "custom";
      paramObj["start_date"] = moment(dateRangeParam.split("-")[0], "MMMM YYYY").format("YYYY-MM-DD");
      paramObj["end_date"] = moment(dateRangeParam.split("-")[1], "MMMM YYYY").endOf("month").format("YYYY-MM-DD");
    }
  }

  if (modelParameter) {
    paramObj["model"] = modelParameter == "All" ? [] : modelParameter.split(",");
  }

  if (leadGroupParameter) {
    paramObj["lead_group"] = leadGroupParameter == "All" ? [] : leadGroupParameter.split(",");
  }

  if (leadsSourceParameter) {
    paramObj["leads_source"] = leadsSourceParameter == "All" ? [] : leadsSourceParameter.split(",");
  }

  if (currencyParameter) {
    paramObj["currency"] = currencyParameter;
  }

  return paramObj;
};

export const getDigitalSpend = (showActuals: boolean) =>
  newApiFetchV2(`/journey-excellence/fmi/digital-spend?bp=${!showActuals}`, { ...journeyExcellenceApiParamsToObject() });

export const getDigitalSpendVisitsData = (showActuals: boolean) =>
  newApiFetchV2(`/journey-excellence/fmi/digital-spend-visits?bp=${!showActuals}`, { ...journeyExcellenceApiParamsToObject() });

export const getDigitalSpendVisitsTrend = () =>
  newApiFetchV2("/journey-excellence/fmi/digital-spend-visits-trend", { ...journeyExcellenceApiParamsToObject() });

export const getConversionAndCost = (showActuals: boolean) =>
  newApiFetchV2(`/journey-excellence/lms/conversion-and-cost?bp=${!showActuals}`, { ...journeyExcellenceApiParamsToObject() });

export const getPerformanceOverview = () =>
  newApiFetchV2("/journey-excellence/product-journey/performance-overview", { ...journeyExcellenceApiParamsToObject() });

export const getWebsitePerformance = () =>
  newApiFetchV2("/journey-excellence/online-journey/website-performance", { ...journeyExcellenceApiParamsToObject() });

export const getWebsitePerformanceTrend = () =>
  newApiFetchV2("/journey-excellence/online-journey/website-performance-trend", { ...journeyExcellenceApiParamsToObject() });

export const getJourneyCost = (showActuals: boolean) =>
  newApiFetchV2(`/journey-excellence/online-journey/journey-costs?bp=${!showActuals}`, { ...journeyExcellenceApiParamsToObject() });

export const getJourneyCostTrend = () =>
  newApiFetchV2("/journey-excellence/online-journey/journey-costs-trend", { ...journeyExcellenceApiParamsToObject() });

export const getCustomerJourney = () =>
  newApiFetchV2("/journey-excellence/online-journey/customer-journey", { ...journeyExcellenceApiParamsToObject() });

export const getConversion = () =>
  newApiFetchV2("/journey-excellence/online-journey/conversion", { ...journeyExcellenceApiParamsToObject() });

export const getConversionTrend = () =>
  newApiFetchV2("/journey-excellence/online-journey/conversion-trend", { ...journeyExcellenceApiParamsToObject() });

export const getMetricChannel = () =>
  newApiFetchV2("/journey-excellence/online-journey/metric-channel", { ...journeyExcellenceApiParamsToObject() });

export const getMetricChannelTrend = (channel: boolean, metric: string) =>
  newApiFetchV2(`/journey-excellence/online-journey/metric-channel-trend?channel=${channel}&metric=${metric.toLowerCase()}`, {
    ...journeyExcellenceApiParamsToObject(),
  });

export const getCustomerExperienceDxs = () =>
  newApiFetchV2("/journey-excellence/online-journey/customer-experience-dxs", { ...journeyExcellenceApiParamsToObject() });

export const getStarRatingAverage = () =>
  newApiFetchV2("/journey-excellence/online-journey/star-rating-average", { ...journeyExcellenceApiParamsToObject() });

export const getStarRatingFeedback = () =>
  newApiFetchV2("/journey-excellence/online-journey/star-rating-feedback", { ...journeyExcellenceApiParamsToObject() });

export const getExitSurveyObjectiveCompletion = () =>
  newApiFetchV2("/journey-excellence/online-journey/exit-survey-objective-completion", { ...journeyExcellenceApiParamsToObject() });

export const getExitSurveyFeedback = () =>
  newApiFetchV2("/journey-excellence/online-journey/exit-survey-feedback", { ...journeyExcellenceApiParamsToObject() });

export const getLmsDigitallyInfluencedSales = () =>
  newApiFetchV2("/journey-excellence/lms/digitally-influenced-sales", { ...journeyExcellenceApiParamsToObject() });

export const getLmsDigitallyInfluencedSalesTrend = () =>
  newApiFetchV2("/journey-excellence/lms/digitally-influenced-sales-trend", { ...journeyExcellenceApiParamsToObject() });

export const getLmsDisAndLeads = () => newApiFetchV2("/journey-excellence/lms/dis-and-leads", { ...journeyExcellenceApiParamsToObject() });

export const getLmsSalesAndLeadsTrend = () =>
  newApiFetchV2("/journey-excellence/lms/sales-and-leads-trend", { ...journeyExcellenceApiParamsToObject() });

export const getLmsConversionAndCost = (showActuals: boolean) =>
  newApiFetchV2(`/journey-excellence/lms/conversion-and-cost?bp=${!showActuals}`, { ...journeyExcellenceApiParamsToObject() });

export const getLmsConversionAndCostTrend = () =>
  newApiFetchV2("/journey-excellence/lms/conversion-and-cost-trend", { ...journeyExcellenceApiParamsToObject() });

export const getProductJourneyPerformanceOverview = (metric: string) =>
  newApiFetchV2(`/journey-excellence/product-journey/performance-overview?metric=${metric?.toLowerCase()}`, {
    ...journeyExcellenceApiParamsToObject(),
  });

export const getProductJourneyEntryPoints = () =>
  newApiFetchV2("/journey-excellence/product-journey/entry-points", { ...journeyExcellenceApiParamsToObject() });

export const getProductJourneyExitPoints = () =>
  newApiFetchV2("/journey-excellence/product-journey/exit-points", { ...journeyExcellenceApiParamsToObject() });

export const getProductJourneyWebsiteEntryPoints = () =>
  newApiFetchV2("/journey-excellence/product-journey/website-entry-points", { ...journeyExcellenceApiParamsToObject() });

export const getFmiLastActMonth = () =>
  newApiFetchV2("/journey-excellence/fmi/last-act-month", { ...journeyExcellenceApiParamsToObject() });
