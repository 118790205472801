import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { FilterContext } from "../../../context";
import { currentDefaultFiltersSet, defaultFiltersSet } from "../../../utils/utilityFunctions";
import { setParameters, setSpecificParameter } from "../../../actions";
import {
  DEFAULT_VOC_PARAMETERS,
  DEFAULT_VOC_QUICK_AFTER_SALES_PARAMETERS,
  MIN_SELECTABLE_DATE,
  VOC_DATE_FILTER_OPTIONS,
} from "../../../constants";
import { FiltersLayout } from "../../Layouts";
import { ErrorBoundary } from "@sentry/react";
import { ErrorMsg } from "../../AppMessages";
import { DateFilter, DefaultFilter, MultiSelectFilter } from "../common";
import { closeFilterOptions, closeMarketOptions } from "../subs/helpers";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import moment from "moment";
import { Button } from "@material-ui/core";
import { useQuery } from "@tanstack/react-query";
import PurchaseFunnelDateFilter from "../common/PurchaseFunnelDateFilter";
import { format, parse } from "date-fns";

interface Props {
  filterBtns: Array<{ id: string; navigateTo: string; name: string }>;
  queryFn: () => Record<string, any>;
  pageName: string;
}

const VocQuickVocCecFilters = withRouter((props: RouteComponentProps & Props) => {
  const { filterBtns, history, queryFn, pageName } = props;
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const {
    date_range: dateRangeParamValue,
    brand: brandParamValue,
    market: marketParamValue,
  } = useSelector((state: RootStateOrAny) => state.parameters);

  const [brandFilterValue, setBrandFilterValue] = useState<string>("");
  const [marketFilterValue, setMarketFilterValue] = useState<string>("");

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet(pageName);

    if (setDefaultFilters || dateRangeParamValue !== DEFAULT_VOC_QUICK_AFTER_SALES_PARAMETERS.date_range) {
      dispatch(setParameters(DEFAULT_VOC_QUICK_AFTER_SALES_PARAMETERS));
      currentDefaultFiltersSet(pageName);
    }
  }, []);

  const { data, refetch } = useQuery({
    queryKey: ["filtersData", history.location.search],
    queryFn: queryFn,
    initialData: {},
    enabled: false,
  });

  const { start_date, end_date, brand: brands, market: markets } = data;

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  //Sets the initial start and end selectable date to be the response from the BE
  const [marketDataAvailability, setMarketDataAvailability] = useState<{ start: string; end: string }>({
    start: start_date ?? MIN_SELECTABLE_DATE,
    end: end_date || moment().subtract(1, "days").format("DD/MM/YYYY"),
  });

  useEffect(() => {
    if (!dateRangeParamValue && start_date) {
      const dateString = format(parse(start_date, "y-MM-dd", new Date()), "MMMM y");
      dispatch(setSpecificParameter("date_range", `${dateString} - ${dateString}`));
    }
  }, [dateRangeParamValue, start_date]);

  useEffect(() => {
    const selectedBrands =
      brands?.length === brandParamValue.split(",")?.length
        ? "All"
        : brandParamValue.includes(",")
        ? `${brandParamValue.split(",")?.length} brands selected`
        : brandParamValue;
    setBrandFilterValue(selectedBrands);
  }, [brandParamValue, brands]);

  useEffect(() => {
    const selectedMarkets =
      markets?.length === marketParamValue.split(",")?.length
        ? "All"
        : marketParamValue.includes(",")
        ? `${marketParamValue.split(",")?.length} markets selected`
        : marketParamValue;
    setMarketFilterValue(selectedMarkets);
  }, [marketParamValue, markets]);

  const showFilterOptionsFn = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const optionsId = dataset?.optionsUl;
      const shownClass = "showOptions";

      const optionsElement = optionsId ? document.getElementById(optionsId) : undefined;

      if (showFilterOptions && optionsElement?.classList.contains(shownClass)) {
        closeFilterOptions();
        closeMarketOptions();
        setShowFilterOptions(false);
      } else {
        closeFilterOptions();
        optionsElement?.classList.toggle(shownClass);
        setShowFilterOptions(!showFilterOptions);
      }
    },
    [showFilterOptions, setShowFilterOptions]
  );

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));
      eventTracking(MixpanelEvents.filter_change, {
        filter: filterName,
        value: optionValue,
        dashboard: "VOC",
        page: pageName,
      });
    }
    closeFilterOptions();
    setShowFilterOptions(false);
  };

  // Set params
  useEffect(() => {
    const params = new URLSearchParams();

    params.set("date_range", dateRangeParamValue);
    params.set("brand", brandParamValue);
    params.set("market", marketParamValue);
    history.push({ search: `?${params.toString()}` });
  }, [dateRangeParamValue, brandParamValue, marketParamValue]);

  const resetFilters = useCallback(() => {
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(DEFAULT_VOC_PARAMETERS)) {
      params.set(key, value);
    }

    history.push({ search: `?${params.toString()}` });
    window.location.reload();
  }, [history]);

  const renderDashboardClassificationButtons = () => {
    return (
      <>
        <Button variant={"outlined"} className={`filter_btn_element ${pageName == "VOC 360" ? "active" : ""}`} size={"small"} disabled>
          Try
        </Button>
        <Button variant={"outlined"} className={`filter_btn_element active`} size={"small"} disabled>
          Buy
        </Button>
        <Button variant={"outlined"} className={`filter_btn_element active`} size={"small"} disabled>
          Use
        </Button>
      </>
    );
  };

  const handleBrandFilterSubmission = useCallback(() => {
    const selectedBrandCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="brand"][data-options-type="single"]:checked`
    ) as NodeListOf<HTMLInputElement>;
    const selectedBrands: Array<string> = [];
    selectedBrandCheckboxes?.forEach((checkbox) => selectedBrands.push(checkbox.value));

    closeFilterOptions();
    const value = brands?.length === selectedBrands?.length ? "All" : selectedBrands.join(",");
    dispatch(setSpecificParameter("brand", value));

    eventTracking(MixpanelEvents.filter_change, { filter: "brand", value, dashboard: "VOC", page: pageName });
  }, [brands]);

  const handleMarketFilterSubmission = useCallback(() => {
    const selectedMarketCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="market"][data-options-type="single"]:checked`
    ) as NodeListOf<HTMLInputElement>;
    const selectedMarkets: Array<string> = [];
    selectedMarketCheckboxes?.forEach((checkbox) => selectedMarkets.push(checkbox.value));

    closeFilterOptions();
    const value = markets?.length === selectedMarkets?.length ? "All" : selectedMarkets.join(",");
    dispatch(setSpecificParameter("market", value));

    eventTracking(MixpanelEvents.filter_change, { filter: "market", value, dashboard: "VOC", page: pageName });
  }, [markets]);

  return (
    <FiltersLayout
      extraClass={"iframeFilters-midas"}
      resetFilters={resetFilters}
      filterBtns={filterBtns}
      renderDashboardClassificationButtons={renderDashboardClassificationButtons}
    >
      <div className="filte">
        <ErrorBoundary fallback={<ErrorMsg />}>
          <PurchaseFunnelDateFilter
            value={dateRangeParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
            onFilterClick={showFilterOptionsFn}
            presetDatesList={VOC_DATE_FILTER_OPTIONS}
            minDateValue={marketDataAvailability.start}
          />
        </ErrorBoundary>

        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="brand"
            // @ts-ignore
            filterList={brands ? brands : []}
            filterName="BRAND"
            value={brandFilterValue}
            parameterName={"brand"}
            parameterValue={brandParamValue}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleBrandFilterSubmission}
          />
        </ErrorBoundary>

        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="market"
            // @ts-ignore
            filterList={markets ? markets : []}
            filterName="MARKET"
            value={marketFilterValue}
            parameterName={"market"}
            parameterValue={marketParamValue}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleMarketFilterSubmission}
          />
        </ErrorBoundary>
      </div>
    </FiltersLayout>
  );
});

export default VocQuickVocCecFilters;
