import { ALL_OPTION, DATSUN, NISSAN_DATSUN } from "../../../constants";
import { FiltersData, stateFullGeography } from "../../../constants/interface";
import { filterDataByBrand, filterDataByBrandAndGroup } from "./helpers";

export const getBrandList = (filters: FiltersData) => {
  if (!filters) return [];
  const brandList = filters
    ?.map((filter: { brands: string }) => filter.brands)
    .filter((item: string, i: number, ar: string[]) => ar.indexOf(item) === i)
    .map((uniqueList: string) => JSON.parse(uniqueList).join(" "));

  return brandList?.length > 0 ? (brandList?.includes(NISSAN_DATSUN) ? [...brandList, DATSUN] : brandList) : [];
};

export const getGroupList = (filters: FiltersData, brand: string | string[]) => {
  if (!filters || (typeof brand == "string" && !brand) || (typeof brand == "object" && brand.length == 0)) return [];
  const groupList = filterDataByBrand(filters, brand)
    .map((filter: { group_name: string }) => filter.group_name)
    .filter((item: string, i: number, ar: string[]) => ar.indexOf(item) === i);

  return groupList?.length > 0 ? [ALL_OPTION, ...groupList] : [];
};

// Groups region and market data with their sub regions
export const getGeographyList = ({ filters, brand, group }: { filters: FiltersData; brand?: string | string[]; group?: string }) => {
  if (!filters) return [];
  const filteredGeo =
    brand && group ? filterDataByBrandAndGroup(filters, brand, group) : brand ? filterDataByBrand(filters, brand) : filters;
  const geographyList = filteredGeo
    .map(({ has_sub_region, sub_region, market, region }) => {
      const allRe = new RegExp(/^all$/, "i");
      const filteredMarket = allRe.test(market) ? region : market;

      return {
        region,
        market: filteredMarket,
        has_sub_region,
        sub_region,
      };
    })
    .reduce((result: { [index: string]: any }, row) => {
      const { region, market, has_sub_region, sub_region } = row;
      const hasSubRegion = !!has_sub_region;
      const subRegion = sub_region ? sub_region : "all";

      if (!result[region]) {
        result[region] = { market: market ? [market] : [], has_sub_region: hasSubRegion, market_split: { [subRegion]: [market] } };
      } else {
        result[region] = {
          ...result[region],
          market: market ? [...result[region].market, market] : [...result[region].market],
          market_split:
            result[region]["market_split"][sub_region]?.length > 0
              ? { ...result[region].market_split, [subRegion]: [...result[region]["market_split"][subRegion], market] }
              : { ...result[region].market_split, [subRegion]: [market] },
        };
      }
      return result;
    }, {});

  const nestedRegionList = Object.keys(geographyList)
    .map((region) => {
      const sortedMarkets = geographyList[region].market.sort();
      return {
        region,
        markets: sortedMarkets?.length === 1 && sortedMarkets[0] === region ? [] : sortedMarkets,
        has_sub_region: geographyList[region].has_sub_region,
        market_split: Object.keys(geographyList[region].market_split).map((split) => ({
          region: split,
          markets: geographyList[region].market_split[split].sort(),
        })),
      };
    })
    .sort((a, b) => a.region.localeCompare(b.region));

  return nestedRegionList?.length > 0 ? [{ region: ALL_OPTION }, ...nestedRegionList] : [];
};
interface GeoCheckState {
  checked: { [index: string]: boolean };
  indeterminate: { [index: string]: boolean };
}

// Flattens the geographies to maintain the checked and indeterminate state in a key value pair for O(1) access
export const createFlatCheckState = (cleanGeo: stateFullGeography, marketValue: string): GeoCheckState => {
  return cleanGeo.reduce(
    (result, geo) => {
      const scrappedState = { checked: { [geo.region]: geo.checked }, indeterminate: { [geo.region]: geo.indeterminate } };
      const markets: any = geo.subGeo
        ? createFlatCheckState(geo.subGeo, marketValue)
        : geo.markets?.reduce(
            (result, market) => {
              return {
                ...result,
                checked: {
                  ...result.checked,
                  [market]:
                    market === marketValue
                      ? true
                      : !geo.checked && !geo.indeterminate
                      ? false
                      : (geo.indeterminate && marketValue?.split(",")?.includes(market)) || geo.checked,
                },
              };
            },
            { checked: {}, indeterminate: {} }
          );

      return geo.subGeo
        ? {
            checked: { ...result.checked, ...scrappedState.checked, ...markets.checked, ...markets.checked.checked },
            indeterminate: { ...result.indeterminate, ...scrappedState.indeterminate, ...markets.indeterminate },
          }
        : {
            checked: { ...result.checked, ...scrappedState.checked, ...markets?.checked },
            indeterminate: { ...result.indeterminate, ...scrappedState.indeterminate },
          };
    },
    { checked: {}, indeterminate: {} }
  );
};
