// Todo: Move some effects to sagas instead of useEffect
import React from "react";
import { withRouter } from "react-router-dom";
import "../../assets/styles/component/filters.scss";
import { FiltersLayout } from "../Layouts";
import { FilterBtns } from "./common";

export const CcsDataQualityFilters = withRouter(() => {
  return (
    <FiltersLayout extraClass={"iframeFilters"}>
      <>
        <FilterBtns
          id={"ccs_cockpit_btn"}
          navigateTo={"/ccs_vital_signs/ccs_cockpit"}
          name={"Back to CCS vital signs"}
          key={"ccs_cockpit"}
          withBackArrow={true}
        />
      </>
    </FiltersLayout>
  );
});
