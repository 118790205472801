import React, { useContext, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { validateUserToken } from "../../api/authenitcationApi";
import { PasswordReset } from "../../components/Modals";
import { PasswordResetContext } from "../../context";
import { InvalidTokenPage } from "../Error Pages/Login";

export const Welcome = withRouter((props: RouteComponentProps) => {
  const { location } = props;
  const [validToken, setValidToken] = useState(true);

  const { setShowResetModal, setUserToken } = useContext(PasswordResetContext);

  useEffect(() => {
    const { search } = location;

    const searchParam = new URLSearchParams(search);

    const user_email = searchParam.get("user_email");
    const token = searchParam.get("token");

    if (!user_email || !token) {
      setValidToken(false);
    } else {
      validateUserToken({ user_email, token }).then((response) => {
        if ("error" in response) {
          setValidToken(false);
        } else {
          setShowResetModal(true);
          setUserToken(token);
        }
      });
    }
  }, [location, setShowResetModal, setUserToken]);

  return validToken ? (
    <div className={`pageWrapper`} id={"mainDashboard"}>
      <div className="dashboard" id="oceDashboard">
        <PasswordReset />
      </div>
    </div>
  ) : (
    <InvalidTokenPage />
  );
});
