import React from "react";
import "../../assets/styles/component/appMessages.scss";

interface Props {
  message?: string;
}
export const NoDataMsg = (props: Props) => {
  const { message } = props;
  return <h3 className={"error_msg no_data"}>{message ? message : "No data available"}</h3>;
};
