import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { getTouchPointNames } from "../../helpers";
import MidasShareTables from "../../sharedComponents/MidasShareTables";
import { getMediaMixTableData } from "../Helpers/helpers";

interface Props {
  yrScenarios: string[];
}

const MediaMixTables = (props: Props) => {
  const { yrScenarios } = props;
  const data = useSelector((state: RootStateOrAny) => state.midas.midas_media_mix_data_by_quarter);

  const touchPointNames: string[] = useMemo(() => getTouchPointNames(data?.full_year), [data]);
  const loading = useSelector((state: RootStateOrAny) => state.loading.midas_media_mix_data_by_quarter);
  const q1TableData = useMemo(() => getMediaMixTableData(touchPointNames, data?.q1, [1]), [data]);
  const q2TableData = useMemo(() => getMediaMixTableData(touchPointNames, data?.q2, [2]), [data]);
  const q3TableData = useMemo(() => getMediaMixTableData(touchPointNames, data?.q3, [3]), [data]);
  const q4TableData = useMemo(() => getMediaMixTableData(touchPointNames, data?.q4, [4]), [data]);
  const fullYearTableData = useMemo(() => getMediaMixTableData(touchPointNames, data?.full_year, [0, 1, 2, 3, 4]), [data]);

  const combinedData = useMemo(() => {
    return data?.q1?.concat(data?.q2?.concat(data?.q3?.concat(data?.q4?.concat(data?.full_year))));
  }, [data]);

  const combinedTableData = useMemo(
    () => getMediaMixTableData(touchPointNames, combinedData, [0, 1, 2, 3, 4]),
    [data, combinedData, touchPointNames]
  );

  const totals = useMemo(() => {
    const totals: any = {};
    totals["model"] = "Total";
    totals[`share_0_${yrScenarios[0]}`] = 100;
    totals[`share_0_${yrScenarios[1]}`] = 100;
    totals[`share_1_${yrScenarios[0]}`] = 100;
    totals[`share_1_${yrScenarios[1]}`] = 100;
    totals[`share_2_${yrScenarios[0]}`] = 100;
    totals[`share_2_${yrScenarios[1]}`] = 100;
    totals[`share_3_${yrScenarios[0]}`] = 100;
    totals[`share_3_${yrScenarios[1]}`] = 100;
    totals[`share_4_${yrScenarios[0]}`] = 100;
    totals[`share_4_${yrScenarios[1]}`] = 100;
    return totals;
  }, [data, yrScenarios]);

  return (
    <MidasShareTables
      q1TableData={q1TableData}
      q2TableData={q2TableData}
      q3TableData={q3TableData}
      q4TableData={q4TableData}
      fullYearTableData={fullYearTableData}
      combinedTableData={combinedTableData}
      q1Totals={totals}
      q2Totals={totals}
      q3Totals={totals}
      q4Totals={totals}
      fullYearTotals={totals}
      combinedTotals={totals}
      sortedYrScenarios={yrScenarios}
      loading={loading}
      extraClass="mediaMix"
    />
  );
};

export default MediaMixTables;
