import {
  Box,
  Checkbox,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  withStyles,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import * as Sentry from "@sentry/react";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import DeleteIcon from "../../../../assets/images/trash_hover.svg";
import { ErrorMsg } from "../../../../components/AppMessages";
import { CYAN, WHITE } from "../../../../constants";
import { Brand, Market, Order, Region } from "../../../../constants/interface/admin/common";
import { getComparator, stableSort } from "../../utils";

interface Props {
  userGroups: any[];
  selectedUserGroup: any;
  setSelectedUserGroup: Dispatch<SetStateAction<any>>;
  setDeleteDialogOpen: Dispatch<SetStateAction<boolean>>;
  brands: Brand[];
  regions: Region[];
  markets: Market[];
  searchValue: string;
}

const StyledTableRow = withStyles(() => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(225, 225, 225, 0.15)",
    },
    "&:hover": {
      backgroundColor: CYAN,
      "& .MuiTableCell-root img": {
        filter: "invert(0%) sepia(93%) saturate(29%) hue-rotate(53deg) brightness(101%) contrast(106%)",
      },
    },
  },
}))(TableRow);

const WhiteBackgroundCheckbox = withStyles(() => ({
  root: {
    color: "black",
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
      color: "#5ed2ff",
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      backgroundColor: "#5ed2ff",
      zIndex: -1,
    },
    "& .MuiIconButton-colorSecondary": {
      color: "#5ed2ff !important",
    },
  },
  checked: {},
}))(Checkbox);

const UserGroupsTable = (props: Props) => {
  const { userGroups, selectedUserGroup, setSelectedUserGroup, setDeleteDialogOpen, searchValue } = props;
  const [rowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("id");

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: any) => (event: any) => {
    handleRequestSort(event, property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const headCells = [
    { id: "name", numeric: false, disablePadding: false, label: "Name", sortable: true },
    { id: "brands", numeric: false, disablePadding: false, label: "Brands", sortable: true },
    { id: "regions", numeric: false, disablePadding: false, label: "Region", sortable: true },
    { id: "markets", numeric: false, disablePadding: false, label: "Markets", sortable: true },
  ];

  const classes = makeStyles({
    noBorder: {
      border: "none",
    },
    tableCell: {
      color: "#FFF",
      border: "none",
      padding: "10px !important",
    },
    tableHeadCell: {
      color: "#FFF",
      border: "none",
      fontWeight: 600,
      padding: "10px !important",
    },
    orderIcon: {
      color: "#FFF !important",
      width: "2.5rem",
      height: "2.5rem",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    deleteIcon: {
      width: "1.5rem",
      filter: "invert(52%) sepia(96%) saturate(2947%) hue-rotate(165deg) brightness(93%) contrast(102%)",
      "&:hover": {
        filter: "invert(70%) sepia(45%) saturate(2008%) hue-rotate(346deg) brightness(104%) contrast(97%) !important",
      },
    },
    tablePagination: {
      color: WHITE,
      width: "auto",
    },
  })();

  useEffect(() => {
    setPage(0);
  }, [searchValue]);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorMsg />}>
      <Box>
        <TableContainer component={Box}>
          <Table className={`${classes.noBorder}`} data-test-id="user-groups-table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} style={{ width: "50px" }}></TableCell>
                {headCells.map((headCell, index) => (
                  <TableCell
                    key={index}
                    className={classes.tableHeadCell}
                    align={headCell.numeric ? "right" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.sortable ? (
                      <TableSortLabel
                        style={{ color: WHITE, fontWeight: 600 }}
                        classes={{
                          icon: classes.orderIcon,
                        }}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                        IconComponent={ArrowDropDownIcon}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(userGroups, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((userGroup, index) => (
                  <StyledTableRow key={index}>
                    <TableCell className={`${classes.tableCell}`}>
                      <WhiteBackgroundCheckbox
                        checked={selectedUserGroup?.id == userGroup.id}
                        onChange={(e) => {
                          e.target.checked ? setSelectedUserGroup(userGroup) : setSelectedUserGroup(undefined);
                        }}
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                      />
                    </TableCell>
                    <TableCell className={`${classes.tableCell}`}>{userGroup.name}</TableCell>
                    <TableCell className={`${classes.tableCell}`}>
                      {}
                      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                      {/* @ts-ignore */}
                      {Array.isArray(userGroup.brands) && userGroup.brands.map((brand) => brand.brand).join(", ")}
                    </TableCell>
                    <TableCell className={`${classes.tableCell}`}>
                      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                      {/* @ts-ignore */}
                      {Array.isArray(userGroup.regions) && userGroup.regions.map((region) => region.region).join(", ")}
                    </TableCell>
                    <TableCell className={`${classes.tableCell}`}>
                      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                      {/* @ts-ignore */}
                      {Array.isArray(userGroup.markets) && userGroup.markets.map((market) => market.market).join(", ")}
                    </TableCell>
                    <TableCell className={`${classes.tableCell}`} align="right">
                      <img
                        src={DeleteIcon}
                        alt="Delete Icon"
                        className={classes.deleteIcon}
                        onClick={() => {
                          setSelectedUserGroup(userGroup);
                          setDeleteDialogOpen(true);
                        }}
                      />
                    </TableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={classes.tablePagination}
          rowsPerPageOptions={[]}
          component="div"
          count={userGroups.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          backIconButtonProps={{
            style: {
              color: WHITE,
            },
          }}
          nextIconButtonProps={{
            style: {
              color: WHITE,
            },
          }}
          labelDisplayedRows={({ from, to, count }) => `Showing ${from} to ${to} of ${count} entries`}
        />
      </Box>
    </Sentry.ErrorBoundary>
  );
};

export default UserGroupsTable;
