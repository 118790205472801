import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import React, { useContext, useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { DottedValueCombined } from "../../../../components/Charts/CCSCockpit";
import { LegendMarker } from "../../../../components/Charts/components/LegendMarker";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../../components/Ratings/Ratings";
import { CYAN, LIGHT_CYAN, RED } from "../../../../constants";
import { DashboardBannerContext, ThemeContext } from "../../../../context";
import { formatToThreeDigits } from "../../../../utils/numberFormatting";
import { convertToPercentage, isNull, plusSignNumbers } from "../../../../utils/utilityFunctions";

interface Props {
  title: string;
  value: number | null;
  comparisonValue: number | null;
  soldEligible: number | null;
  comparisonSoldEligible: number | null;
  isLoading: boolean;
  comparisonPercentageValue?: number | null;
  chartData?: { data: Array<any> | undefined; chartId: string; barSeriesName: string; barDataKey: string; lineDataKey: string };
  isActivation?: boolean;
  showMetric?: boolean;
  tooltip?: string;
  onClick?: () => void;
  bpComparisonValue?: number | null;
  isSingleYear?: boolean;
}

const computeSoldEligible = (soldEligible: number, value: number) => (!soldEligible || isNull(value) ? null : value / soldEligible);

export const CockpitActivationRatioTile = (props: Props) => {
  const {
    title,
    value,
    comparisonValue,
    soldEligible,
    comparisonSoldEligible,
    comparisonPercentageValue,
    isActivation,
    chartData,
    isLoading,
    showMetric,
    tooltip,
    onClick: handleClick,
    bpComparisonValue,
    isSingleYear,
  } = props;

  const themeContext = useContext(ThemeContext);

  const [producedValue, setProducedValue] = useState<number | null>(null);
  const [comparisonProducedValue, setComparisonProducedValue] = useState<number | null>(null);

  const comparisonLabel = "YoY";

  const dateRange = useSelector((state: RootStateOrAny) => state.parameters.date_range);

  const {
    region: regionParamValue,
    brand: brandParamValue,
    model: modelParamValue,
  } = useSelector((state: RootStateOrAny) => state.parameters);

  const { bannerHovered } = useContext(DashboardBannerContext);

  useEffect(() => {
    !isNull(soldEligible) &&
      !isNull(value) &&
      !isNull(comparisonValue) &&
      !isNull(comparisonSoldEligible) &&
      setProducedValue(computeSoldEligible(soldEligible as number, value as number));
    setComparisonProducedValue(computeSoldEligible(comparisonSoldEligible as number, comparisonValue as number));
  }, [soldEligible, value, comparisonSoldEligible, comparisonValue]);

  const isComparisonValueValid = !isNull(comparisonPercentageValue) && typeof comparisonPercentageValue !== "undefined";

  const [isAllNull, setIsAllNull] = useState(false);
  useEffect(() => {
    setIsAllNull(
      chartData?.data?.every((element) => isNull(element.soldEligiblePercentage) && isNull(element.totalWarrantyStart)) ? true : false
    );
  }, [chartData]);

  return (
    <div
      className={`tile engagement_tile ${regionParamValue === "Europe" && bannerHovered ? "isBlue" : ""}`}
      id={title.replaceAll(" ", "_")}
      data-test-id={title.replaceAll(" ", "_")}
      onClick={handleClick}
    >
      <Link className="dashboard-link" to="/ccs_journey/europe_activation">
        <ArrowCircleRightOutlinedIcon style={{ color: "#00a0dd" }} />
      </Link>
      {!chartData?.data && (
        <div className="tile_header">
          {title}{" "}
          {tooltip && (
            <Tooltip
              position="top"
              className={"normal_tippy"}
              trigger="mouseenter"
              animation="none"
              interactive={true}
              delay={1}
              hideDelay={1}
              duration={1}
              title={tooltip}
              size={"small"}
            >
              <span className="info_tooltip" />
            </Tooltip>
          )}
        </div>
      )}
      <div className="CCS_main_content">
        <div className="tile_content">
          {isActivation ? (
            <>
              <div className="split_value" data-test-id={"sold_eligible_split"}>
                <div className="tile_header">
                  {chartData?.data && <LegendMarker shape={"line"} color={LIGHT_CYAN} />}
                  {`${!showMetric ? "" : "G8 private activation ratio"}`}{" "}
                  {chartData?.data && tooltip && (
                    <Tooltip
                      position="top"
                      className={"normal_tippy"}
                      trigger="mouseenter"
                      animation="none"
                      interactive={true}
                      delay={1}
                      hideDelay={1}
                      duration={1}
                      title={tooltip}
                      size={"small"}
                    >
                      <span className="info_tooltip" />
                    </Tooltip>
                  )}
                </div>

                <div className="value" data-test-value={producedValue ? producedValue : "n/a"} data-test-id={"sold_eligible_value"}>
                  <span className="main">{isNull(soldEligible) ? "n/a" : `${soldEligible}%`}</span>
                </div>
                {!isNull(comparisonSoldEligible) && typeof comparisonSoldEligible !== "undefined" && (
                  <p className="period_comparison comparison">
                    <span className="main">
                      <span className="comparison_value">
                        {isNull(soldEligible) || isNull(comparisonSoldEligible)
                          ? "n/a"
                          : `${plusSignNumbers(comparisonSoldEligible as number)} PTS`}
                      </span>
                      {comparisonLabel}
                    </span>
                  </p>
                )}
              </div>
              <div className="split_value" data-test-id={"main_data_split"}>
                {chartData?.data && (
                  <div className="tile_header">
                    <LegendMarker color={CYAN} shape={"square"} />
                    {title}
                  </div>
                )}
                <div className="value" data-test-value={value ? value : "n/a"} data-test-id={"value"}>
                  <span className="main">{isNull(value) ? "n/a" : formatToThreeDigits(value as number)}</span>
                  <p className="value_note grey_info">{`${!showMetric ? "Total warranty start" : ""}`}</p>
                </div>

                {isComparisonValueValid && (
                  <p className="period_comparison comparison">
                    <span className="main">
                      <Ratings
                        value={
                          comparisonPercentageValue ? (isNull(comparisonPercentageValue) ? "na" : String(comparisonPercentageValue)) : ""
                        }
                        isPercentageValue={true}
                      />
                      <span className="comparison_value">{comparisonPercentageValue}%</span>
                      {comparisonLabel}
                    </span>
                  </p>
                )}

                {isSingleYear && !showMetric && regionParamValue === "Europe" && brandParamValue !== "Infiniti" && (
                  <p className="period_comparison comparison">
                    <span className="main">
                      <span className="comparison_value">
                        {isNull(bpComparisonValue) ? "n/a" : `${plusSignNumbers(bpComparisonValue)} PTS`}
                      </span>
                      vs BP
                    </span>
                  </p>
                )}
                {chartData?.data && regionParamValue === "Europe" && brandParamValue !== "Infiniti" && (
                  <>
                    <div className="tile_header">
                      <LegendMarker color={RED} shape={"line"} />
                      BP target
                    </div>

                    {isSingleYear && (
                      <div
                        className="value"
                        data-test-value={bpComparisonValue ? "bpComparisonValue_enrollment" : "n/a"}
                        data-test-id={"sold_eligible_value"}
                      >
                        <span className="main">
                          {modelParamValue.includes("All") ? (
                            <span className="main">{isNull(bpComparisonValue) ? "n/a" : bpComparisonValue}</span>
                          ) : (
                            <span className="main">n/a </span>
                          )}
                        </span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="split_value" data-test-id={"main_data_split"}>
                {chartData?.data && (
                  <div className="tile_header">
                    <LegendMarker color={CYAN} shape={"square"} />
                    {title}
                  </div>
                )}
                <div className="value" data-test-value={value ? value : "n/a"} data-test-id={"value"}>
                  {isNull(value) ? "n/a" : formatToThreeDigits(value as number)}
                </div>

                {isComparisonValueValid && (
                  <p className="period_comparison comparison">
                    <Ratings
                      value={
                        comparisonPercentageValue ? (isNull(comparisonPercentageValue) ? "n/a" : String(comparisonPercentageValue)) : ""
                      }
                      isPercentageValue={true}
                    />
                    <span className="comparison_value">{Math.abs(comparisonPercentageValue as number)}</span>
                    {comparisonLabel}
                  </p>
                )}
              </div>

              <div className="split_value" data-test-id={"sold_eligible_split"}>
                <div className="value" data-test-value={producedValue ? producedValue : "n/a"} data-test-id={"sold_eligible_value"}>
                  {convertToPercentage(producedValue, 0)}
                  <p className="value_note grey_info" data-test-id={"sold_eligible_title"}>
                    {chartData?.data && <LegendMarker shape={"line"} color={LIGHT_CYAN} />}
                    of connected cars sold
                  </p>
                </div>
                {!isNull(comparisonProducedValue) && typeof comparisonProducedValue !== "undefined" && (
                  <p className="period_comparison comparison">
                    <span className="comparison_value">
                      {isNull(comparisonProducedValue) ? "n/a" : `${plusSignNumbers(comparisonProducedValue as number)} PTS`}
                    </span>
                    {comparisonLabel}
                  </p>
                )}
              </div>
            </>
          )}
        </div>
        {chartData?.data && (
          <div className="metric_content">
            <DottedValueCombined
              chartId={chartData.chartId}
              barSeriesName={chartData.barSeriesName}
              barDataKey={chartData.barDataKey}
              lineSeriesName={`${isActivation ? "Activation rate" : "% of sold eligible"}`}
              lineDataKey={chartData.lineDataKey}
              data={isAllNull ? [] : chartData.data}
              theme={themeContext.theme}
              region={regionParamValue}
            />
          </div>
        )}
      </div>

      <LoadingEllipsis isLoading={isLoading} />
    </div>
  );
};
