import React, { Fragment, useCallback } from "react";
import { useDispatch } from "react-redux";
import { MultiSelectFilter } from ".";
import { setSpecificParameter } from "../../../actions";
import { FY_TWENTY } from "../../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { closeFilterOptions } from "../subs/helpers";

interface Props {
  filterValue: string;
  groupedDatesList: Array<{ year: string; child: any }>;
  showFilterOptions: (evt: React.MouseEvent<HTMLElement>) => void;
  dateRangeParamValue: string;
}

export const EuropeActivationDateFilter = (props: Props) => {
  const dispatch = useDispatch();

  const { filterValue, groupedDatesList, showFilterOptions, dateRangeParamValue } = props;

  const handleDateFilterSubmission = () => {
    const parameterName = "date_range";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-parent-value]:checked`
    );

    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    closeFilterOptions();
    dispatch(setSpecificParameter(parameterName, selectedValues.join(",")));
    eventTracking(MixpanelEvents.filter_change, {
      filter: "date_range",
      value: selectedValues.join(","),
      dashboard: "CCS",
      page: "Europe activation",
    });
  };

  const handleFilterOptionClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;
    closeFilterOptions();
    if (filterName && optionValue) {
      dispatch(setSpecificParameter("date_range", optionValue));
      eventTracking(MixpanelEvents.filter_change, {
        filter: "date_range",
        value: optionValue,
        dashboard: "CCS",
        page: "Europe activation",
      });
    }
  }, []);

  return (
    <div className={"filter"} id="DATE RANGE_filter_div">
      <label className="filter_header" htmlFor="year_month_filter">
        DATE RANGE
      </label>
      <div className="input_arrow" onClick={showFilterOptions} data-options-ul={"year_ul"}>
        <input readOnly type="text" name="date" id="year_month_filter" className={"filter_input"} data-test-id="date" value={filterValue} />
        <span className="arrow_down " />
      </div>
      <ul className="filter_options" id="year_ul" data-test-id="year_ul">
        {groupedDatesList?.length > 1 && (
          <Fragment>
            <li
              className={"filter_divider"}
              data-filter={"preset_date"}
              data-value={FY_TWENTY}
              onClick={handleFilterOptionClick}
              id="fy20_li"
              data-test-id="fy20_li"
            >
              FY20
            </li>
            <MultiSelectFilter
              parentKey="year"
              //@ts-ignore
              filterList={groupedDatesList}
              showOptionsOnly={true}
              childKey="month"
              value={filterValue}
              parameterName={"date_range"}
              parameterValue={dateRangeParamValue}
              onShowFilterOptions={showFilterOptions}
              handleFilterSubmission={handleDateFilterSubmission}
            />
          </Fragment>
        )}
      </ul>
    </div>
  );
};
