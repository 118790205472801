import { ICpoBreakdownTrend, IKeyIndicatorAll, IKeyIndicatorVariance } from "../constants/interface";
import {
  FETCH_CPO_BREAKDOWN_TREND,
  FETCH_CPO_BREAKDOWN_TREND_ALL,
  FETCH_CPO_KBA_BREAKDOWN_TABLE_DATA,
  FETCH_CPO_KBA_LABEL_MAPPING,
  FETCH_CPO_KBA_LEAD_VISITS_TREND_DATA,
  FETCH_CPO_KBA_VISITS_TREND_DATA,
  FETCH_CPO_LEAD_BREAKDOWN_TABLE_DATA,
  FETCH_CPO_LEAD_LABEL_MAPPING,
  FETCH_CPO_LEAD_VISITS_TREND_DATA,
  FETCH_KEY_INDICATOR_ALL,
  FETCH_KEY_INDICATOR_VARIANCE,
  FETCH_MODEL_KEY_INDICATORS,
  FETCH_MODEL_TREND,
  SET_CPO_BREAKDOWN_TREND,
  SET_CPO_BREAKDOWN_TREND_ALL,
  SET_CPO_CHANNEL_DATA,
  SET_CPO_GROUPED_DATA,
  SET_CPO_KBA_BREAKDOWN_TABLE_DATA,
  SET_CPO_KBA_LABEL_MAPPING,
  SET_CPO_KBA_LEAD_KEYS,
  SET_CPO_KBA_VISITS_TREND_DATA,
  SET_CPO_LEAD_BREAKDOWN_TABLE_DATA,
  SET_CPO_LEAD_KEYS,
  SET_CPO_LEAD_LABEL_MAPPING,
  SET_CPO_LEAD_VISITS_TREND_DATA,
  SET_CPO_MARKETS,
  SET_CPO_VISITS_GRAPH_DATA,
  SET_CPO_VISITS_LEADS_KBAS_GRAPH_DATA_DAILY,
  SET_CPO_VISITS_LEADS_KBAS_GRAPH_DATA_MONTHLY,
  SET_CPO_VISITS_LEADS_KBAS_GRAPH_DATA_WEEKLY,
  SET_CPO_VISITS_TREND_KBA_LEADS,
  SET_KEY_INDICATOR_ALL,
  SET_KEY_INDICATOR_VARIANCE,
  SET_MODEL_KEY_INDICATORS,
  SET_MODEL_TREND,
} from "./actionTypes";

export const fetchModelTrendData = () => {
  return { type: FETCH_MODEL_TREND };
};

export const setModelTrendData = (data: any) => {
  return { type: SET_MODEL_TREND, payload: data };
};

export const fetchModelKeyIndicatorsData = () => {
  return { type: FETCH_MODEL_KEY_INDICATORS };
};

export const setModelKeyIndicatorsData = (data: any) => {
  return { type: SET_MODEL_KEY_INDICATORS, payload: data };
};

//variance actions
export const fetchKeyIndicatorsVarianceData = () => {
  return { type: FETCH_KEY_INDICATOR_VARIANCE };
};

export const setCpoKeyIndicatorVariance = (data: Array<IKeyIndicatorVariance>) => {
  return { type: SET_KEY_INDICATOR_VARIANCE, payload: data };
};

//key-indicators all actions
export const fetchKeyIndicatorsAllData = () => {
  return { type: FETCH_KEY_INDICATOR_ALL };
};

export const setCpoKeyIndicatorAll = (data: IKeyIndicatorAll) => {
  return { type: SET_KEY_INDICATOR_ALL, payload: data };
};

//cpo breakdown trends actions
export const fetchCpoBreakdownTrendData = () => {
  return { type: FETCH_CPO_BREAKDOWN_TREND };
};

export const setCpoBreakdownTrend = (data: ICpoBreakdownTrend) => {
  return { type: SET_CPO_BREAKDOWN_TREND, payload: data };
};

//cpo breakdown trend all actions
export const fetchCpoBreakdownTrendAllData = () => {
  return { type: FETCH_CPO_BREAKDOWN_TREND_ALL };
};

export const setCpoBreakdownTrendAll = (data: any) => {
  return { type: SET_CPO_BREAKDOWN_TREND_ALL, payload: data };
};

export const setCpoMarkets = (data: any) => {
  return { type: SET_CPO_MARKETS, payload: data };
};

export const setCpoVisitsGraphData = (data: any) => {
  return { type: SET_CPO_VISITS_GRAPH_DATA, payload: data };
};

export const fetchCpoLeadVisitsData = () => {
  return { type: FETCH_CPO_LEAD_VISITS_TREND_DATA };
};

export const setCpoLeadVisitsData = (data: any) => {
  return { type: SET_CPO_LEAD_VISITS_TREND_DATA, payload: data };
};

export const fetchCpoKbaLeadVisitsData = () => {
  return { type: FETCH_CPO_KBA_LEAD_VISITS_TREND_DATA };
};

export const fetchCpoKbaVisitsData = () => {
  console.log("KBA Action dipached");
  return { type: FETCH_CPO_KBA_VISITS_TREND_DATA };
};
export const setCpoKbaVisitsData = (data: any) => {
  return { type: SET_CPO_KBA_VISITS_TREND_DATA, payload: data };
};

export const setCpoKbaLeadKeys = (data: any[]) => {
  return { type: SET_CPO_KBA_LEAD_KEYS, payload: data };
};

export const setCpoVisitsLeadsKbasGraphData_Daily = (data: any[]) => {
  return { type: SET_CPO_VISITS_LEADS_KBAS_GRAPH_DATA_DAILY, payload: data };
};

export const setCpoVisitsLeadsKbasGraphData_Weekly = (data: any[]) => {
  return { type: SET_CPO_VISITS_LEADS_KBAS_GRAPH_DATA_WEEKLY, payload: data };
};

export const setCpoVisitsLeadsKbasGraphData_Monthly = (data: any[]) => {
  return { type: SET_CPO_VISITS_LEADS_KBAS_GRAPH_DATA_MONTHLY, payload: data };
};

export const setCpoVisitsTrendLeadsKbas = (data: any[]) => {
  return { type: SET_CPO_VISITS_TREND_KBA_LEADS, payload: data };
};

export const setCpoLeadKeys = (data: any[]) => {
  return { type: SET_CPO_LEAD_KEYS, payload: data };
};

export const setCpoGroupedData = (data: any[]) => {
  return { type: SET_CPO_GROUPED_DATA, payload: data };
};

export const setCpoChannelData = (data: any[]) => {
  return { type: SET_CPO_CHANNEL_DATA, payload: data };
};

export const fetchCpoKbaBreakdownTableData = () => {
  return { type: FETCH_CPO_KBA_BREAKDOWN_TABLE_DATA };
};
export const setCpoKbaBreakdownTableData = (data: any) => {
  return { type: SET_CPO_KBA_BREAKDOWN_TABLE_DATA, payload: data };
};

export const fetchCpoLeadBreakdownTableData = () => {
  return { type: FETCH_CPO_LEAD_BREAKDOWN_TABLE_DATA };
};
export const setCpoLeadBreakdownTableData = (data: any) => {
  return { type: SET_CPO_LEAD_BREAKDOWN_TABLE_DATA, payload: data };
};

export const fetchCpoKbaLabelMapping = () => {
  return { type: FETCH_CPO_KBA_LABEL_MAPPING };
};

export const setCpoKbaLabelMapping = (data: any) => {
  return { type: SET_CPO_KBA_LABEL_MAPPING, payload: data };
};

export const fetchCpoLeadLabelMapping = () => {
  return { type: FETCH_CPO_LEAD_LABEL_MAPPING };
};

export const setCpoLeadLabelMapping = (data: any) => {
  return { type: SET_CPO_LEAD_LABEL_MAPPING, payload: data };
};
