import React from "react";
import "../../";
import "../../assets/styles/component/appMessages.scss";

interface Props {
  class?: "formError";
}

export const ErrorMsg = (props: Props) => {
  return <h3 className={`error_msg ${props.class ? props.class : ""}`}>Error: Something went wrong</h3>;
};
