import { ColumnSeries, DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter, Sprite } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, BRIGHT_BLUE, CYAN, GRAY, LIGHT_GREY, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { configureDateAxis, configureValueAxis, legendConfiguration, lineSeriesConfiguration } from "../helpers/configurations";
import { formatDateAxisLabelToMonthly } from "../helpers/mtmConfigurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  seriesList: Array<{ name: string; field: string; type: string; color?: string }>;
  chartName: string;
  isLoading: boolean;
  theme: ThemeInt;
}

const barSeriesColors = [CYAN, BRIGHT_BLUE, GRAY];

class PrivateSalesBreakdownChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.tooltipConfig = this.tooltipConfig.bind(this);
  }
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { data, chartName, theme, seriesList } = this.props;

    this.chart = create(chartName, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.colors.list = [color(CYAN), color(BRIGHT_BLUE), color(LIGHT_GREY)];
    //Add legends
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.itemContainers.template.paddingTop = 2.5;
    this.chart.legend.itemContainers.template.marginBottom = 25;
    this.chart.legend.itemContainers.template.events.on("inited", function (ev) {
      const legend = ev.target;
      //@ts-ignore
      legend.dom.setAttribute("data-test-id", legend.dataItem?.dataContext.testId);
    });

    //Default x axis to show current date
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.minGridDistance = 30;
    dateAxis.dateFormats.setKey("day", "MMM");
    dateAxis.periodChangeDateFormats.setKey("day", "MMM");

    //Y Axis that is used by default
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.numberFormatter.numberFormat = "#a";

    seriesList
      .filter((series) => series.type == "bar")
      ?.forEach(({ name, field, color: seriesColor }, idx) => {
        const series = this.chart.series.push(new ColumnSeries());
        series.dataFields.dateX = "date";
        series.name = name;
        series.stacked = true;
        series.fill = seriesColor ? color(seriesColor) : color(barSeriesColors[idx]);
        series.stroke = seriesColor ? color(seriesColor) : color(barSeriesColors[idx]);
        series.dataFields.valueY = field;
        series.xAxis = dateAxis;
        series.numberFormatter = new NumberFormatter();
        series.columns.template.tooltipText = "test";
        series.columns.template.adapter.add("tooltipText", this.tooltipConfig);
        if (series.tooltip) {
          series.tooltip.getFillFromObject = false;
          series.tooltip.autoTextColor = false;
          series.tooltip.background.fill = color(WHITE);
          series.tooltip.label.fill = color(BLACK);
          series.tooltip.fontSize = "var(--regular_font_size)";
        }
      });

    seriesList
      .filter((series) => series.type == "line")
      ?.forEach(({ name, field, color: seriesColor }, idx) => {
        const series = this.chart.series.push(new LineSeries());
        series.dataFields.dateX = "date";
        series.fill = theme == "dark" ? color(WHITE) : color(BLACK);
        series.stroke = theme == "dark" ? color(WHITE) : color(BLACK);

        const circleBullet = lineSeriesConfiguration(series, {
          valueY: field,
          name: name,
          yAxis: valueAxis,
        });
        circleBullet.tooltipText = `[bold font-size: var(--regular_font_size)]{name}:[bold font-size: var(--regular_font_size)] {valueY.formatNumber('###,###')}`;
        this.chart.series.each((series) => {
          series.bullets.getIndex(0)?.adapter.add("tooltipText", this.tooltipConfig);
        });
        if (series.tooltip) {
          series.tooltip.fontSize = "var(--regular_font_size)";
        }
      });
  }

  tooltipConfig(value: string | undefined, target: Sprite) {
    const dataValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };

    let text = `[bold]${formatDateAxisLabelToMonthly(dataValue?.date)}[/]\n`;

    this.chart.series.each((item) => {
      const value = item?.dataFields?.valueY && dataValue ? (dataValue[item.dataFields.valueY] as number) : null;
      text += `[${item.stroke}]●[/] ${item.name}: ${value ? Number(value).toLocaleString() : "n/a"}\n`;
    });

    return text;
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default PrivateSalesBreakdownChart;
