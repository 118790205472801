import { makeStyles, Menu, MenuItem } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import * as Sentry from "@sentry/react";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  fetchBrandsData,
  fetchChannelBreakdownsData,
  fetchChannelsData,
  fetchLeadSourcesData,
  fetchMarketsData,
  fetchMetricsData,
  fetchNewModelsData,
  fetchOCEAnnotationsKbaTypes,
  fetchOCEAnnotationsLeadTypes,
  fetchRegionsData,
} from "../../../actions";
import { deleteDataV2, fetchDataV2, updateDataV2 } from "../../../api/utils";
import { AnnotationDialog } from "../../../components/Annotations/Dialogs/AnnotationDialog";
import { ErrorMsg } from "../../../components/AppMessages";
import { CYAN } from "../../../constants";
import { Annotation } from "../types";
import AnnotationsTable from "./subs/AnnotationsTable";
import ApproveAnnotationDialog from "./subs/Dialogs/ApproveAnnotationDialog";
import DeleteAnnotationDialog from "./subs/Dialogs/DeleteAnnotationDialog";
import AnnotationStatusSearchField from "./subs/Fields/AnnotationStatusSearchField";

const fileDownload = require("js-file-download");

const AnnotationsAdmin = () => {
  const dispatch = useDispatch();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [annotationFormDialogOpen, setAnnotationDialogFormOpen] = useState<boolean>(false);
  const [approveDialogOpen, setApproveDialogOpen] = useState<boolean>(false);
  const [selectedAnnotation, setSelectedAnnotation] = useState<Annotation | undefined>(undefined);
  const [annotations, setAnnotations] = useState<Array<Annotation>>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | any>(null);
  const [mouseOverMenu, setMouseOverMenu] = useState<boolean>(false);
  const [mouseOverButton, setMouseOverButton] = useState<boolean>(false);
  const currentUser = useSelector((state: RootStateOrAny) => state.user.details);
  const downloadOptionsOpen = mouseOverMenu || mouseOverButton;
  const userViewsList = Cookies.get("views");
  const [statusFilterValue, setStatusFilterValue] = useState<string>("All");
  const [pfAnnotationParametersFetched, setPFAnnotationParametersFetched] = useState<boolean>(false);

  const classes = makeStyles({
    button: {
      backgroundColor: CYAN,
      color: "#FFF !important",
      borderRadius: "0px !important",
      border: "none !important",
      marginRight: "10px",
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      "&:hover": {
        backgroundColor: "#5ed2ff !important",
        border: "none !important",
      },
    },
    downloadMenuContainer: {
      border: "1px solid",
      borderWidth: 1,
      color: CYAN,
      borderColor: CYAN,
      padding: 5,
      "&:hover": {
        backgroundColor: CYAN,
        color: "rgba(44, 57, 78, 0.8)",
      },
    },
    popover: {
      pointerEvents: "none",
    },
    popoverPaper: {
      pointerEvents: "all",
    },
  })();

  useEffect(() => {
    Promise.all([
      dispatch(fetchBrandsData("purchase_funnel")),
      dispatch(fetchMarketsData("purchase_funnel")),
      dispatch(fetchMetricsData("purchase_funnel")),
      dispatch(fetchLeadSourcesData("purchase_funnel")),
    ]).then(() => {
      setPFAnnotationParametersFetched(true);
    });
  }, []);

  useEffect(() => {
    if (pfAnnotationParametersFetched) {
      setTimeout(() => {
        Promise.all([
          dispatch(fetchBrandsData("online_ce_performance")),
          dispatch(fetchRegionsData("online_ce_performance")),
          dispatch(fetchMarketsData("online_ce_performance")),
          dispatch(fetchNewModelsData("online_ce_performance")),
          dispatch(fetchChannelsData("online_ce_performance")),
          dispatch(fetchChannelBreakdownsData("online_ce_performance")),
          dispatch(fetchMetricsData("online_ce_performance")),
          dispatch(fetchOCEAnnotationsKbaTypes()),
          dispatch(fetchOCEAnnotationsLeadTypes()),
        ]);
      }, 1000);
    }
  }, [pfAnnotationParametersFetched]);

  useEffect(() => {
    fetchDataV2("/annotation/get-all-annotation").then((res) => {
      if (!res.error) {
        setAnnotations(res);
      } else {
        console.log(res.error);
      }
    });
  }, []);

  const handleToggleDeleteDialog = () => {
    if (selectedAnnotation?.user?.id == currentUser.id || userViewsList?.includes("annotation_admin")) {
      setDeleteDialogOpen(!deleteDialogOpen);
    } else {
      toast.error("You do not have permission to delete this annotation.");
    }
  };

  const handleToggleAnnotationFormDialog = () => {
    if (selectedAnnotation?.user?.id == currentUser.id || userViewsList?.includes("annotation_admin")) {
      setAnnotationDialogFormOpen(!annotationFormDialogOpen);
      if (annotationFormDialogOpen) {
        setSelectedAnnotation(undefined);
      }
    } else {
      toast.error("You do not have permission to modify this annotation.");
    }
  };

  const handleToggleApproveDialog = () => {
    if (userViewsList?.includes("annotation_admin")) {
      setApproveDialogOpen(!approveDialogOpen);
    } else {
      toast.error("You do not have permission to approve this annotation.");
    }
  };

  const handleDeleteAnnotation = (id: number) => {
    deleteDataV2(`/annotation/delete-annotation?id=${id}`).then((res) => {
      if (!res.error) {
        setAnnotations((prevState) => prevState.filter((annotation) => annotation.id != id));
        setSelectedAnnotation(undefined);
        handleToggleDeleteDialog();
      } else {
        console.log(res.error);
      }
    });
  };

  const handleApproveAnnotation = (approvedAnnotation: Annotation) => {
    if (approvedAnnotation.approval_status == "pending") {
      approvedAnnotation["approval_status"] = "approved";
    } else {
      approvedAnnotation["approval_status"] = "pending";
    }
    updateDataV2(`/annotation/update-annotation?id=${approvedAnnotation.id}`, approvedAnnotation).then((res) => {
      if (!res.error) {
        setAnnotations((prevState) => prevState.map((annotation) => (annotation.id == approvedAnnotation.id ? res : annotation)));
        setSelectedAnnotation(undefined);
        handleToggleApproveDialog();
      } else {
        console.log(res.error);
      }
    });
  };

  const handleCloseDownloadMenu = () => {
    setAnchorEl(null);
    setMouseOverMenu(false);
  };

  const handleClickDownloadButton = (event: React.MouseEvent<HTMLElement>) => {
    setMouseOverButton(true);
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleDownloadData = (format: string) => {
    fetchDataV2(
      `/annotation/export-all-annotations?format=${format}`,
      {
        contentDisposition: "attachment; filename=template.xlsx",
        contentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      "arraybuffer"
    )
      .then((response) => {
        if (format == "csv") {
          fileDownload(response, `annotations.${format}`);
        } else {
          fileDownload(response, `annotations.${format}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Sentry.ErrorBoundary fallback={<ErrorMsg />}>
      <Box component="div">
        <Box component="div" display="flex" mb={3} justifyContent="space-between">
          <Box component="div" display="flex">
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={handleToggleAnnotationFormDialog}
              disabled={!selectedAnnotation}
            >
              Edit
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={handleToggleDeleteDialog}
              disabled={!selectedAnnotation}
            >
              Delete
            </Button>

            <Box style={{ width: 200 }}>
              <AnnotationStatusSearchField value={statusFilterValue} setValue={setStatusFilterValue} />
            </Box>
          </Box>

          <Box>
            {userViewsList?.includes("annotation_admin") && (
              <IconButton
                className={classes.downloadMenuContainer}
                aria-controls="download-options-menu"
                aria-haspopup="true"
                aria-expanded={downloadOptionsOpen ? "true" : undefined}
                onMouseOver={handleClickDownloadButton}
                onMouseLeave={() => setTimeout(() => setMouseOverButton(false))}
              >
                <MenuIcon />
              </IconButton>
            )}

            <Menu
              id="download-options-menu"
              anchorEl={anchorEl}
              open={downloadOptionsOpen}
              onClose={handleCloseDownloadMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              MenuListProps={{
                onMouseEnter: () => setMouseOverMenu(true),
                onMouseLeave: handleCloseDownloadMenu,
              }}
              PaperProps={{
                style: { marginRight: 50 },
              }}
              getContentAnchorEl={null}
              PopoverClasses={{
                root: classes.popover,
                paper: classes.popoverPaper,
              }}
            >
              <MenuItem onClick={() => handleDownloadData("csv")}>Download data as CSV</MenuItem>
              <MenuItem onClick={() => handleDownloadData("xlsx")}>Download data as Excel</MenuItem>
            </Menu>
          </Box>
        </Box>

        <Sentry.ErrorBoundary fallback={<ErrorMsg />}>
          <AnnotationsTable
            selectedAnnotation={selectedAnnotation}
            setSelectedAnnotation={setSelectedAnnotation}
            setDeleteDialogOpen={setDeleteDialogOpen}
            annotations={annotations.filter((annotation) =>
              statusFilterValue == "All" ? annotation : annotation.approval_status == statusFilterValue.toLowerCase()
            )}
          />
        </Sentry.ErrorBoundary>

        {deleteDialogOpen && selectedAnnotation && (
          <Sentry.ErrorBoundary fallback={<ErrorMsg />}>
            <DeleteAnnotationDialog
              open={deleteDialogOpen}
              handleClose={handleToggleDeleteDialog}
              handleDelete={handleDeleteAnnotation}
              annotation={selectedAnnotation}
            />
          </Sentry.ErrorBoundary>
        )}

        {annotationFormDialogOpen && (
          <Sentry.ErrorBoundary fallback={<ErrorMsg />}>
            <AnnotationDialog
              open={annotationFormDialogOpen}
              setOpen={handleToggleAnnotationFormDialog}
              annotationData={selectedAnnotation}
              setAnnotations={setAnnotations}
              dashboard={selectedAnnotation?.dashboard ? selectedAnnotation.dashboard : "admin"}
            />
          </Sentry.ErrorBoundary>
        )}

        {approveDialogOpen && selectedAnnotation && (
          <Sentry.ErrorBoundary fallback={<ErrorMsg />}>
            <ApproveAnnotationDialog
              open={approveDialogOpen}
              handleClose={handleToggleApproveDialog}
              handleApprove={handleApproveAnnotation}
              annotation={selectedAnnotation}
            />
          </Sentry.ErrorBoundary>
        )}
      </Box>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        style={{ height: "unset", fontSize: "1.5em" }}
        toastStyle={{ height: "unset" }}
        hideProgressBar={true}
      />
    </Sentry.ErrorBoundary>
  );
};

export default AnnotationsAdmin;
