import {
  Box,
  Button,
  Checkbox,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  withStyles,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import * as Sentry from "@sentry/react";
import { format } from "date-fns";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { fetchDataV2 } from "../../../../api/utils";
import DeleteIcon from "../../../../assets/images/trash_hover.svg";
import { ErrorMsg } from "../../../../components/AppMessages";
import { CYAN, WHITE } from "../../../../constants";
import { Order } from "../../../../constants/interface/admin/common";
import { User } from "../../../../constants/interface/admin/usersData";
import { getComparator, stableSort } from "../../utils";
import { TokenDialog } from "./Dialogs/tokenDialog";

interface Props {
  users: any[];
  selectedUser: any;
  setSelectedUser: Dispatch<SetStateAction<any>>;
  setDeleteDialogOpen: Dispatch<SetStateAction<boolean>>;
  visibleColumns: Array<string>;
  searchValue: string;
}

const headCells = [
  { id: "email", numeric: false, disablePadding: false, label: "Email", sortable: true },
  { id: "first_name", numeric: false, disablePadding: false, label: "First Name", sortable: true },
  { id: "last_name", numeric: false, disablePadding: false, label: "Last Name", sortable: true },
  { id: "display_name", numeric: false, disablePadding: false, label: "Display Name", sortable: true },
  { id: "groups", numeric: false, disablePadding: false, label: "Geo Scope", sortable: true },
  { id: "views", numeric: false, disablePadding: false, label: "User View", sortable: true },
  { id: "business_function", numeric: false, disablePadding: false, label: "Business Function", sortable: true },
  { id: "role", numeric: false, disablePadding: false, label: "Role", sortable: true },
  { id: "on_ga", numeric: false, disablePadding: false, label: "On GA", sortable: false },
  { id: "preferred_url", numeric: false, disablePadding: false, label: "Preferred URL", sortable: true },
  { id: "account_status", numeric: false, disablePadding: false, label: "Account Status", sortable: true },
  { id: "created_at", numeric: false, disablePadding: false, label: "Creation Date", sortable: true },
  { id: "last_login", numeric: false, disablePadding: false, label: "Last Login", sortable: true },
];

const StyledTableRow = withStyles(() => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(225, 225, 225, 0.15)",
    },
    "&:hover": {
      backgroundColor: CYAN,
      "& .MuiTableCell-root img": {
        filter: "invert(0%) sepia(93%) saturate(29%) hue-rotate(53deg) brightness(101%) contrast(106%)",
      },
      "& .MuiButton-outlined": {
        borderColor: `${WHITE} !important`,
        "& .MuiButton-label": {
          color: `${WHITE} !important`,
        },
      },
    },
  },
}))(TableRow);

const WhiteBackgroundCheckbox = withStyles(() => ({
  root: {
    color: "black",
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
      color: "#5ed2ff",
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      backgroundColor: "#5ed2ff",
      zIndex: -1,
    },
    "& .MuiIconButton-colorSecondary": {
      color: "#5ed2ff !important",
    },
  },
  checked: {},
}))(Checkbox);

const UsersTable = (props: Props) => {
  const { users, selectedUser, setSelectedUser, setDeleteDialogOpen, visibleColumns, searchValue } = props;
  const [rowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("id");
  const [tokenLink, setTokenLink] = useState<string>("");
  const [tokenDialogOpen, setTokenDialogOpen] = useState<boolean>(false);

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: any) => (event: any) => {
    handleRequestSort(event, property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleToogleTokenDialog = () => {
    setTokenDialogOpen(!tokenDialogOpen);
  };

  const handleGenerateToken = (user: User) => {
    fetchDataV2(`/authentication/reset-url?email=${user.email}&domain=${window.location.origin}`).then((response) => {
      if (typeof response == "object" && "error" in response) {
        toast.error("Error generating token. Please try again.");
      } else {
        setTokenLink(response);
        handleToogleTokenDialog();
      }
    });
  };

  const classes = makeStyles({
    noBorder: {
      border: "none",
    },
    tableCell: {
      color: "#FFF",
      border: "none",
      padding: "10px !important",
    },
    tableHeadCell: {
      color: "#FFF",
      border: "none",
      fontWeight: 600,
      padding: "10px !important",
    },
    orderIcon: {
      color: "#FFF !important",
      width: "2.5rem",
      height: "2.5rem",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    deleteIcon: {
      width: "1.5rem",
      filter: "invert(52%) sepia(96%) saturate(2947%) hue-rotate(165deg) brightness(93%) contrast(102%)",
      "&:hover": {
        filter: "invert(70%) sepia(45%) saturate(2008%) hue-rotate(346deg) brightness(104%) contrast(97%) !important",
      },
    },
    tablePagination: {
      color: WHITE,
      width: "auto",
    },
  })();

  useEffect(() => {
    setPage(0);
  }, [searchValue]);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorMsg />}>
      <Box>
        <TableContainer component={Box}>
          <Table className={`${classes.noBorder}`} data-test-id="users-table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} style={{ width: "50px" }}></TableCell>
                {headCells.map(
                  (headCell) =>
                    visibleColumns.includes(headCell.id) && (
                      <TableCell
                        key={headCell.id}
                        className={classes.tableHeadCell}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        {headCell.sortable ? (
                          <TableSortLabel
                            style={{ color: WHITE, fontWeight: 600 }}
                            classes={{
                              icon: classes.orderIcon,
                            }}
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                            IconComponent={ArrowDropDownIcon}
                          >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                              <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          headCell.label
                        )}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(users, getComparator(order, orderBy, "users"))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((user) => {
                  return (
                    <StyledTableRow key={user.email as string} data-test-id={user.email as string}>
                      <TableCell className={`${classes.tableCell}`}>
                        <WhiteBackgroundCheckbox
                          checked={selectedUser?.id == user.id}
                          onChange={(e) => {
                            e.target.checked ? setSelectedUser(user) : setSelectedUser(undefined);
                          }}
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                        />
                      </TableCell>
                      {visibleColumns.includes("email") && <TableCell className={`${classes.tableCell}`}>{user.email}</TableCell>}
                      {visibleColumns.includes("first_name") && <TableCell className={`${classes.tableCell}`}>{user.first_name}</TableCell>}
                      {visibleColumns.includes("last_name") && <TableCell className={`${classes.tableCell}`}>{user.last_name}</TableCell>}
                      {visibleColumns.includes("display_name") && (
                        <TableCell className={`${classes.tableCell}`}>{user.display_name}</TableCell>
                      )}
                      {visibleColumns.includes("groups") && (
                        <TableCell className={`${classes.tableCell}`}>
                          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                          {/* @ts-ignore */}
                          {Array.isArray(user.groups) && user.groups.map((group) => group.name).join(", ")}
                        </TableCell>
                      )}
                      {visibleColumns.includes("views") && (
                        <TableCell className={`${classes.tableCell}`}>
                          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                          {/* @ts-ignore */}
                          {Array.isArray(user.views) && user.views.map((view) => view.name).join(", ")}
                        </TableCell>
                      )}
                      {visibleColumns.includes("business_function") && (
                        <TableCell className={`${classes.tableCell}`}>{user.business_function}</TableCell>
                      )}
                      {visibleColumns.includes("role") && <TableCell className={`${classes.tableCell}`}>{user.role}</TableCell>}
                      {visibleColumns.includes("on_ga") && (
                        <TableCell className={`${classes.tableCell}`}>
                          {user.on_ga ? <CheckIcon style={{ fill: "green" }} /> : <CloseIcon style={{ fill: "red" }} />}
                        </TableCell>
                      )}
                      {visibleColumns.includes("preferred_url") && (
                        <TableCell className={`${classes.tableCell}`}>{user.preferred_url}</TableCell>
                      )}
                      {visibleColumns.includes("account_status") && (
                        <TableCell className={`${classes.tableCell}`}>{user.account_status}</TableCell>
                      )}
                      {/* @ts-ignore */}
                      {visibleColumns.includes("created_at") && (
                        <TableCell className={`${classes.tableCell}`}>
                          {typeof user.created_at == "string" && format(new Date(user.created_at), "d-LLL-Y")}
                        </TableCell>
                      )}
                      {/* @ts-ignore */}
                      {visibleColumns.includes("last_login") && (
                        <TableCell className={`${classes.tableCell}`}>
                          {typeof user.last_login == "string" && format(new Date(user.last_login), "d-LLL-Y")}
                        </TableCell>
                      )}
                      <TableCell className={`${classes.tableCell}`}>
                        <Button variant="outlined" disableElevation onClick={() => handleGenerateToken(user as User)}>
                          Generate Token
                        </Button>
                      </TableCell>
                      <TableCell className={`${classes.tableCell}`} align="right">
                        <img
                          src={DeleteIcon}
                          alt="Delete Icon"
                          className={classes.deleteIcon}
                          onClick={() => {
                            setSelectedUser(user);
                            setDeleteDialogOpen(true);
                          }}
                        />
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={classes.tablePagination}
          rowsPerPageOptions={[]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          backIconButtonProps={{
            style: {
              color: WHITE,
            },
          }}
          nextIconButtonProps={{
            style: {
              color: WHITE,
            },
          }}
          labelDisplayedRows={({ from, to, count }) => `Showing ${from} to ${to} of ${count} entries`}
        />
        <TokenDialog open={tokenDialogOpen} link={tokenLink} onCloseClick={handleToogleTokenDialog} />
      </Box>
    </Sentry.ErrorBoundary>
  );
};

export default UsersTable;
