import Cookies from "js-cookie";
import { IUserDetails } from "../constants/interface";
import { FETCH_USER_DETAILS, GET_CXO_TOKEN, SET_CXO_TOKEN, SET_USER, SET_USER_DETAILS } from "./actionTypes";

export const setUser = (data: any) => ({ type: SET_USER, payload: data });

export const fetchCurrentUserDetails = () => ({ type: FETCH_USER_DETAILS, payload: Cookies.get("email") });
export const setCurrentUserDetails = (payload: IUserDetails) => ({ type: SET_USER_DETAILS, payload });
// Fetch CXO token
export const fetchCXOToken = () => ({ type: GET_CXO_TOKEN });
export const setCXOToken = (payload: string) => ({ type: SET_CXO_TOKEN, payload });
