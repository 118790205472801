import moment from "moment";
import { DIS_PRESET_DATE_PARAMS } from "../constants";
import { newApiFetchV2 } from "./utils";

export const getParams = () => {
  const parameters = new URLSearchParams(window.location.search);

  const dateRangeParam = parameters.get("date_range");
  const brandParam = parameters.get("brand") == "All" ? [] : parameters.get("brand")?.split(",");
  const regionParam = /all/gi?.test(parameters.get("region") as string) ? [] : parameters.get("region")?.split(",");
  const marketParam = parameters.get("market") == "All countries" ? [] : parameters.get("market")?.split(",");
  const productParam = parameters.get("product") == "All" ? [] : parameters.get("product")?.split(",");
  const productCategoryParam = parameters.get("product_category") == "All" ? [] : parameters.get("product_category")?.split(",");

  const paramObj: any = {
    brand: brandParam,
    region: regionParam,
    market: marketParam,
    product_category: productCategoryParam,
    product: productParam,
  };

  if (dateRangeParam) {
    if (DIS_PRESET_DATE_PARAMS.includes(dateRangeParam)) {
      paramObj["date_range"] = dateRangeParam.split(" ").join("_").toLocaleLowerCase();
    } else {
      paramObj["date_range"] = "custom";
      paramObj["start_date"] = moment(dateRangeParam.split("-")[0], "MMMM YYYY").format("YYYY-MM-DD");
      paramObj["end_date"] = moment(dateRangeParam.split("-")[1], "MMMM YYYY").endOf("month").format("YYYY-MM-DD");
    }
  }

  return paramObj;
};

export const getNoUniqueProductsLive = () => newApiFetchV2("/executive-summary/product-performance/unique-products-live", getParams());

export const getTotalProductsLive = () => newApiFetchV2("/executive-summary/product-performance/total-products-live", getParams());

export const getTotalLiveMarkets = () => newApiFetchV2("/executive-summary/product-performance/total-live-markets", getParams());

export const getTopFiveProductsVisited = () =>
  newApiFetchV2("/executive-summary/product-performance/top-five-products-visited", getParams());

export const getTopFiveFeaturesUsed = () => newApiFetchV2("/executive-summary/product-performance/top-five-features-used", getParams());

export const getPerformanceByMarketMap = (metric: string) =>
  newApiFetchV2(`/executive-summary/product-performance/performance-by-market-map?metric=${metric}`, getParams());

export const getPerformanceByMarketTable = (metric: string) =>
  newApiFetchV2(`/executive-summary/product-performance/performance-by-market-table?metric=${metric}`, getParams());

export const getProductsActivatedByMarket = (filterBy: string, crSelection: string) =>
  newApiFetchV2(
    `/executive-summary/product-performance/products-activated-by-market?filter_by=${filterBy.replace(/[ /]/g, "_")}&cr_selection=${crSelection}`,
    getParams()
  );

export const getEntryPointsByVisits = () => newApiFetchV2("/executive-summary/product-performance/entry-points-by-visits", getParams());

export const getExitPointsByVisits = () => newApiFetchV2("/executive-summary/product-performance/exit-points-by-visits", getParams());
