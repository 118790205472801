import React from "react";

interface Props {
  yrScenarios: string[];
}

const CombinedTitles = (props: Props) => {
  const { yrScenarios } = props;
  return (
    <div className={`quarter_titles ${yrScenarios?.length == 1 ? "one_yr_scnr" : ""}`}>
      <div className="quarter_title"></div>
      <div className="quarter_title">
        <span className="quarter_title">Q1</span>
      </div>
      <div className="quarter_title">
        <span className="quarter_title">Q2</span>
      </div>
      <div className="quarter_title">
        <span className="quarter_title">Q3</span>
      </div>
      <div className="quarter_title">
        <span className="quarter_title">Q4</span>
      </div>
      <div className="quarter_title">
        <span className="quarter_title">Full year</span>
      </div>
    </div>
  );
};

export default CombinedTitles;
