import { format } from "date-fns";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import {
  fetchLmsConversionAndCost,
  fetchLmsConversionAndCostTrend,
  fetchLmsDigitallyInfluencedSales,
  fetchLmsDigitallyInfluencedSalesTrend,
  fetchLmsDisAndLeads,
  fetchLmsSalesAndLeadsTrend,
} from "../../../../actions/journeyExcellenceActions";
import DigitallyInfluencedSalesChart from "../../../../components/Charts/JourneyExcellence/DigitallyInfluencesSalesChart";
import FmiBarTrendChart from "../../../../components/Charts/JourneyExcellence/FmiBarTrendChart";
import JourneyExcellenceLineTrendChart from "../../../../components/Charts/JourneyExcellence/JourneyExcellenceLineTrendChart";
import Collapsible from "../../../../components/Collapsible/Collapsible";
import { ToggleSwitch } from "../../../../components/Toogle";
import { BROWN, CYAN, FOREST_GREEN, GRAY, HUMMING_BIRD, LIGHT_GREEN, PINK, RED } from "../../../../constants";
import { ThemeContext } from "../../../../context";
import { eventTracking, MixpanelEvents } from "../../../../utils/userTracking";
import JourneyExcellenceKeyIndicator from "../components/KeyIndicator";
import DetailedValueTile from "../tiles/DetailedValueTile";

const DIS_SERIES_COLORS = [CYAN, GRAY, RED, FOREST_GREEN];

const CONVERSION_AND_COST_SERIES_COLORS: Record<string, string> = {
  ["Cost per Lead ACT"]: CYAN,
  ["Cost per Lead BP"]: HUMMING_BIRD,
  ["Cost per DIS BP"]: LIGHT_GREEN,
  ["Cost per DIS ACT"]: FOREST_GREEN,
  ["Lead to DIS ACT"]: PINK,
  ["Lead to DIS BP"]: PINK,
  ["Website visits to Leads BP"]: BROWN,
  ["Website visits to Leads ACT"]: BROWN,
};

const LeadsManagementSystemContent = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);

  const {
    Current: currentDisKeyIndicatorsData,
    PoP: popDisKeyIndicatorsData,
    YoY: yoyDisKeyIndicatorsData,
    start_date,
    end_date,
  } = useSelector((state: RootStateOrAny) => state.journey_excellence.lms_digitally_influenced_sales);
  const { series: digitallyInfluencedSalesTrendSeries, data: digitallyInfluencedSalesTrendData } = useSelector(
    (state: RootStateOrAny) => state.journey_excellence.lms_digitally_influenced_sales_trend
  );
  const {
    Current: currentDisAndLeadsKeyIndicatorsData,
    PoP: popDisAndLeadsKeyIndicatorsData,
    YoY: yoyDisAndLeadsKeyIndicatorsData,
  } = useSelector((state: RootStateOrAny) => state.journey_excellence.lms_dis_and_leads);
  const {
    Current: currentConversionAndCost,
    PoP: popConversionAndCost,
    YoY: yoyConversionAndCost,
    last_three_months: lastThreeMonthsConversionAndCost,
  } = useSelector((state: RootStateOrAny) => state.journey_excellence.lms_conversion_and_cost);
  const { series: salesAndLeadsTrendSeries, data: salesAndLeadsTrendData } = useSelector(
    (state: RootStateOrAny) => state.journey_excellence.lms_sales_and_leads_trend
  );
  const { series: conversionAndCostTrendSeries, data: conversionAndCostTrendData } = useSelector(
    (state: RootStateOrAny) => state.journey_excellence.lms_conversion_and_cost_trend
  );

  const conversionAndCostLoading = useSelector((state: RootStateOrAny) => state.loading.lms_conversion_and_cost);
  const salesAndLeadsTrendLoading = useSelector((state: RootStateOrAny) => state.loading.lms_sales_and_leads_trend);
  const digitallyInfluencedSalesLoadng = useSelector((state: RootStateOrAny) => state.loading.lms_digitally_influenced_sales_trend);
  const digitallyInfluencedSalesTrendLoading = useSelector((state: RootStateOrAny) => state.loading.lms_digitally_influenced_sales_trend);

  const dateRangeParameter = useSelector((state: RootStateOrAny) => state.parameters.date_range);
  const marketParameter = useSelector((state: RootStateOrAny) => state.parameters.market);

  const [toggleActive, setToggleActive] = useState(true);

  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Journey Excellence", page: "Leads Management System" });
  }, []);

  useEffect(() => {
    dispatch(fetchLmsDigitallyInfluencedSales());
    dispatch(fetchLmsDigitallyInfluencedSalesTrend());
    dispatch(fetchLmsDisAndLeads());
    dispatch(fetchLmsSalesAndLeadsTrend());
    dispatch(fetchLmsConversionAndCostTrend());
  }, [history.location.search]);

  useEffect(() => {
    dispatch(fetchLmsConversionAndCost(toggleActive));
  }, [history.location.search, toggleActive]);

  const disKeyIndicatorsData = useMemo(() => {
    const data: any = [];
    if (currentDisKeyIndicatorsData && yoyDisKeyIndicatorsData) {
      data.push({
        title: "% Digitally influenced sales",
        titleTooltipText: "Sum of Digitally Influenced Sales divide by the Private sales. The result is given in percentage",
        yoyValue: currentDisKeyIndicatorsData?.dis_percentage,
        yoyTargetValue: currentDisKeyIndicatorsData?.target,
        yoyComparisonValue: yoyDisKeyIndicatorsData?.pts_dis_percentage,
        popComparisonValue: popDisKeyIndicatorsData?.pts_dis_percentage,
        yoyTargetComparisonValue: currentDisKeyIndicatorsData?.pts_target,
        latestMonthValue: currentDisKeyIndicatorsData?.last_month_dis_percentage,
        latestMonthTargetValue: currentDisKeyIndicatorsData?.last_month_target,
        latestMonthYoyComparisonValue: yoyDisKeyIndicatorsData?.pts_last_month_dis_percentage,
        latestMonthPopComparisonValue: currentDisKeyIndicatorsData?.pts_last_month_dis_percentage,
        latestMonthTargetComparisonValue: currentDisKeyIndicatorsData?.pts_last_month_target,
        isPercentage: true,
      });
      data.push({
        title: "Digitally influenced sales",
        titleTooltipText: "Number of confirmed new car sales that can be attributed to OEM managed digital activity",
        yoyValue: currentDisKeyIndicatorsData?.dis,
        yoyComparisonValue: yoyDisKeyIndicatorsData?.perc_dis,
        popComparisonValue: popDisKeyIndicatorsData?.perc_dis,
        latestMonthValue: currentDisKeyIndicatorsData?.last_month_dis,
        latestMonthYoyComparisonValue: yoyDisKeyIndicatorsData?.perc_last_month_dis,
        latestMonthPopComparisonValue: currentDisKeyIndicatorsData?.perc_last_month_dis,
      });
    }
    return data;
  }, [currentDisKeyIndicatorsData, popDisKeyIndicatorsData, yoyDisKeyIndicatorsData]);

  const leadsKeyIndicatorsData = useMemo(() => {
    const data: any = [];
    if (currentDisAndLeadsKeyIndicatorsData && yoyDisAndLeadsKeyIndicatorsData) {
      data.push({
        title: "Lead Volume",
        titleTooltipText: "Visitors who express interest (lead-type actions) and leave PII data",
        yoyValue: currentDisAndLeadsKeyIndicatorsData?.leads,
        yoyComparisonValue: yoyDisAndLeadsKeyIndicatorsData?.perc_leads,
        popComparisonValue: popDisAndLeadsKeyIndicatorsData?.perc_leads,
        latestMonthValue: currentDisAndLeadsKeyIndicatorsData?.last_month_leads,
        latestMonthYoyComparisonValue: yoyDisAndLeadsKeyIndicatorsData?.perc_last_month_leads,
        latestMonthPopComparisonValue: currentDisAndLeadsKeyIndicatorsData?.perc_last_month_leads,
      });

      data.push({
        title: "Private Sales",
        titleTooltipText: "Sum of all sales in the market ( Digitally Influenced Sales + Dealer Walk ins + Other)",
        yoyValue: currentDisAndLeadsKeyIndicatorsData?.private_sales_actual,
        yoyComparisonValue: yoyDisAndLeadsKeyIndicatorsData?.perc_private_sales_actual,
        popComparisonValue: popDisAndLeadsKeyIndicatorsData?.perc_private_sales_actual,
        latestMonthValue: currentDisAndLeadsKeyIndicatorsData?.last_month_private_sales_actual,
        latestMonthYoyComparisonValue: yoyDisAndLeadsKeyIndicatorsData?.perc_last_month_private_sales_actual,
        latestMonthPopComparisonValue: currentDisAndLeadsKeyIndicatorsData?.perc_last_month_private_sales_actual,
      });
    }
    return data;
  }, [currentDisAndLeadsKeyIndicatorsData, popDisAndLeadsKeyIndicatorsData, yoyDisAndLeadsKeyIndicatorsData]);

  const yoyLabel = useMemo(() => {
    if (start_date && end_date) {
      return `${format(new Date(start_date), "LLL y")} - ${format(new Date(end_date), "LLL y")}`;
    }
    return "";
  }, [start_date, end_date]);

  const latestMonthLabel = useMemo(() => {
    if (end_date) {
      return `${format(new Date(end_date), "LLL y")}`;
    }
    return "";
  }, [end_date]);

  const formattedDisTrendData = useMemo(() => {
    if (digitallyInfluencedSalesTrendData) {
      return digitallyInfluencedSalesTrendData?.map((dataItem: any) => {
        if (dataItem.period != "Current") {
          const date = dataItem.date;
          const data = { ...dataItem };
          data["date1"] = date;
          delete data["date"];
          return data;
        }
        return dataItem;
      });
    }
    return [];
  }, [digitallyInfluencedSalesTrendData]);

  const formattedDisSeriesData = useMemo(() => {
    if (digitallyInfluencedSalesTrendSeries) {
      return digitallyInfluencedSalesTrendSeries?.map((series: any, idx: number) => {
        return { ...series, color: DIS_SERIES_COLORS[idx] };
      });
    }
    return [];
  }, [digitallyInfluencedSalesTrendSeries, marketParameter]);

  const formattedConversionAndCostTrendSeries = useMemo(() => {
    if (conversionAndCostTrendSeries) {
      return conversionAndCostTrendSeries?.map((series: any, idx: number) => {
        return { ...series, color: CONVERSION_AND_COST_SERIES_COLORS[series.name] };
      });
    }
    return [];
  }, [conversionAndCostTrendSeries, CONVERSION_AND_COST_SERIES_COLORS]);

  const handleBtnClick = useCallback(() => {
    history.push("/purchase_funnel_report");
  }, []);

  return (
    <div className="journey-excellence-leads-management-system">
      <Collapsible title="Digitally Influenced Sales" show={true}>
        <div className="row">
          <div className="tile">
            <JourneyExcellenceKeyIndicator
              yoyLabel={yoyLabel}
              latestMonthLabel={latestMonthLabel}
              indicatorsData={disKeyIndicatorsData}
              isLoading={digitallyInfluencedSalesLoadng}
            />
          </div>
          <div className="tile">
            <div className="tile-header">
              <p>% Digitally influenced sales</p>
              <Tooltip
                position="top"
                className={"normal_tippy"}
                trigger="mouseenter"
                animation="none"
                interactive={true}
                delay={1}
                hideDelay={1}
                duration={1}
                title={"Sum of Digitally Influenced Sales divide by the Private sales. The result is given in percentage"}
                size={"small"}
              >
                <span className="info_tooltip" />
              </Tooltip>
            </div>
            <div className="tile-content">
              <DigitallyInfluencedSalesChart
                seriesList={formattedDisSeriesData}
                data={formattedDisTrendData}
                chartId="digitallyInfluencedSalesTrend"
                theme={theme}
                isLoading={digitallyInfluencedSalesTrendLoading}
                dateValue={dateRangeParameter}
              />
            </div>
          </div>
        </div>
      </Collapsible>
      <Collapsible title="Private Sales and Leads" show={true}>
        <div className="row">
          <div className="tile">
            <JourneyExcellenceKeyIndicator
              yoyLabel={yoyLabel}
              latestMonthLabel={latestMonthLabel}
              indicatorsData={leadsKeyIndicatorsData}
              isLoading={digitallyInfluencedSalesLoadng}
            />
          </div>
          <div className="tile">
            <div className="tile-header">
              <p>Sales and leads trend</p>{" "}
              <Tooltip
                position="top"
                className={"normal_tippy"}
                trigger="mouseenter"
                animation="none"
                interactive={true}
                delay={1}
                hideDelay={1}
                duration={1}
                html={
                  <>
                    <p>
                      Digitally influenced sales: Number of confirmed new car sales that can be attributed to OEM managed digital activity{" "}
                    </p>
                    <br />
                    <p>Leads Volume: Visitors who express interest (lead-type actions) and leave PII data</p>
                    <br />
                    <p>Private Sales: Sum of all sales in the market ( Digitally Influenced Sales + Dealer Walk ins + Other)</p>
                  </>
                }
                size={"small"}
              >
                <span className="info_tooltip" />
              </Tooltip>
            </div>
            <div className="tile-content">
              <JourneyExcellenceLineTrendChart
                seriesList={salesAndLeadsTrendSeries}
                data={salesAndLeadsTrendData}
                chartId="salesAndLeadsTrend"
                theme={theme}
                isLoading={salesAndLeadsTrendLoading}
              />
            </div>
          </div>
        </div>
      </Collapsible>
      <Collapsible title="Conversion and cost" show={true}>
        <div className="row">
          <div className="conversion-and-cost">
            <div className="section-toggle">
              <ToggleSwitch
                activeToggleLabel="Show only ACT"
                inactiveToggleLabel="Show ACT + BP"
                handleToggleClick={() => setToggleActive((prevState) => !prevState)}
                active={toggleActive}
                toggleClassName="toggle-justify-end"
              />
            </div>
            <div className="conversion-and-cost-grid">
              <DetailedValueTile
                title="Website visits to Leads"
                mainValue={currentConversionAndCost?.web_visits_to_leads}
                yoyValue={yoyConversionAndCost?.pts_web_visits_to_leads}
                popValue={popConversionAndCost?.pts_web_visits_to_leads}
                lastThreeMonthsValue={lastThreeMonthsConversionAndCost?.web_visits_to_leads}
                lastThreeMonthsStartDate={lastThreeMonthsConversionAndCost?.start_date}
                lastThreeMonthsEndDate={lastThreeMonthsConversionAndCost?.end_date}
                isLoading={conversionAndCostLoading}
                isPercentage={true}
                tooltipText="Conversion from website visits to leads that express interest (lead-type-actions) and leave PII data. Please note that Website visits data is only available when All or Online - OEM are selected in Lead Source filter."
              />
              <DetailedValueTile
                title="Cost per Leads"
                mainValue={currentConversionAndCost?.cost_per_lead}
                yoyValue={yoyConversionAndCost?.perc_cost_per_lead}
                popValue={popConversionAndCost?.perc_cost_per_lead}
                lastThreeMonthsValue={lastThreeMonthsConversionAndCost?.cost_per_lead}
                lastThreeMonthsStartDate={lastThreeMonthsConversionAndCost?.start_date}
                lastThreeMonthsEndDate={lastThreeMonthsConversionAndCost?.end_date}
                isLoading={conversionAndCostLoading}
                showCurrency={true}
                ratingArrowInverse={true}
                ratingInverse={true}
                tooltipText="Cost for lead visits based on the Media FMI allocated through digital channels"
              />
              <DetailedValueTile
                title="Leads to DIS"
                mainValue={currentConversionAndCost?.leads_to_dis}
                yoyValue={yoyConversionAndCost?.pts_leads_to_dis}
                popValue={popConversionAndCost?.pts_leads_to_dis}
                lastThreeMonthsValue={lastThreeMonthsConversionAndCost?.leads_to_dis}
                lastThreeMonthsStartDate={lastThreeMonthsConversionAndCost?.start_date}
                lastThreeMonthsEndDate={lastThreeMonthsConversionAndCost?.end_date}
                isLoading={conversionAndCostLoading}
                isPercentage={true}
                tooltipText="Conversion from Visitors who express interest (lead-type actions) and leave PII data into car sales that can be atributed to OEM managed digital actiivty"
              />
              <DetailedValueTile
                title="Cost per DIS"
                mainValue={currentConversionAndCost?.cost_per_dis}
                yoyValue={yoyConversionAndCost?.perc_cost_per_dis}
                popValue={popConversionAndCost?.perc_cost_per_dis}
                lastThreeMonthsValue={lastThreeMonthsConversionAndCost?.cost_per_dis}
                lastThreeMonthsStartDate={lastThreeMonthsConversionAndCost?.start_date}
                lastThreeMonthsEndDate={lastThreeMonthsConversionAndCost?.end_date}
                isLoading={conversionAndCostLoading}
                showCurrency={true}
                ratingArrowInverse={true}
                ratingInverse={true}
                tooltipText="Cost per DIS based on the Media FMI allocated through digital channels"
              />
            </div>
          </div>
          <div className="tile">
            <div className="tile-content">
              <FmiBarTrendChart
                data={conversionAndCostTrendData}
                seriesList={formattedConversionAndCostTrendSeries}
                theme={theme}
                chartId="lmsConversionAndCostTrend"
                isLoading={false}
                barAxisIsCurrency={true}
                lineAxisIsPercentage={true}
              />
            </div>
          </div>
        </div>
      </Collapsible>
      <div className="more-info">
        <div>For more DIS information access:</div>
        <button className="more-info-btn" onClick={handleBtnClick}>
          DIS Dashboard
        </button>
      </div>
    </div>
  );
});

export default LeadsManagementSystemContent;
