import { ErrorBoundary } from "@sentry/react";
import React from "react";
import { ErrorMsg, NoDataMsg } from "../AppMessages";
import { CommentsDefaultTable } from "./subs";

const BrandPerformanceTable = (props: { data: any; tableId: string; columns: any; isLoading: boolean; initialSortCol?: any }) => {
  const { data, tableId, columns, isLoading, initialSortCol } = props;

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <>
        {data?.length > 0 ? (
          <>
            <CommentsDefaultTable
              columns={columns}
              data={data}
              id={tableId}
              data-test-id={tableId}
              initialSortColId={initialSortCol ? initialSortCol : { id: "model", desc: true }}
              hiddenCols={[]}
              extraClass="appRatingsComments"
            />
          </>
        ) : isLoading ? null : (
          <NoDataMsg />
        )}
      </>
    </ErrorBoundary>
  );
};

export default BrandPerformanceTable;
