import { ErrorBoundary } from "@sentry/react";
import React from "react";
import { BusinessRevenueMetric, CustomerQualityMetric, EngagementMetric, OperationalMetric } from ".";
import { ErrorMsg } from "../../../../components/AppMessages";

interface Props {
  metric: string;
}

export const MetricContent = (props: Props) => {
  const { metric } = props;

  switch (metric) {
    case "Business revenue":
      return (
        <ErrorBoundary fallback={<ErrorMsg />}>
          <BusinessRevenueMetric />
        </ErrorBoundary>
      );

    case "Customer quality":
      return (
        <ErrorBoundary fallback={<ErrorMsg />}>
          <CustomerQualityMetric />
        </ErrorBoundary>
      );

    case "Engagement":
      return (
        <ErrorBoundary fallback={<ErrorMsg />}>
          <EngagementMetric />
        </ErrorBoundary>
      );
    case "Operational":
      return (
        <ErrorBoundary fallback={<ErrorMsg />}>
          <OperationalMetric />{" "}
        </ErrorBoundary>
      );

    default:
      return (
        <ErrorBoundary fallback={<ErrorMsg />}>
          <BusinessRevenueMetric />
        </ErrorBoundary>
      );
  }
};
