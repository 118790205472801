import { ErrorBoundary } from "@sentry/react";
import React, { useEffect } from "react";
import { ErrorMsg } from "../../components/AppMessages";
import { FilterBtns } from "../../components/Filters/common";
import { FiltersLayout, IframeLayout } from "../../components/Layouts";
import { IframePageContent } from "../../components/PageContent";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";

export const MobilityInsightsReport = (): JSX.Element => {
  const iframeUrlValue = "https://datastudio.google.com/embed/u/0/reporting/9e170542-fedd-415b-b4d6-bea87ee7d63f/page/95HWB";

  // Usage tracking
  useEffect(
    () => eventTracking(MixpanelEvents.page_view, { dashboard: "C-19 Mobility Restrictions", page: "Mobility Insights Report" }),
    []
  );

  return (
    <IframeLayout hasFilter={true}>
      <ErrorBoundary fallback={<ErrorMsg />}>
        <IframePageContent
          frameId="mobility_insights_report"
          filter={
            <FiltersLayout extraClass={"iframeFilters"}>
              <FilterBtns
                id="mobility_insghts_btn"
                navigateTo="/mobility_report"
                name="Back to C-19 mobility restrictions"
                withBackArrow={true}
              />
            </FiltersLayout>
          }
          iframeUrl={iframeUrlValue}
        />
      </ErrorBoundary>
    </IframeLayout>
  );
};
