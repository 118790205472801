import { AnnotationsQueryParams } from "../api/annotationsApi";
import { FullFigureInt, trendBreakdownData, trendNewBreakdownData } from "../constants/interface";
import { MetricType } from "../constants/interface/admin/common";
import {
  FETCH_BASELINE_CHART_ANNOTATIONS_DATA,
  FETCH_FULL_FIGURES,
  FETCH_KBA_BREAKDOWN_DATA,
  FETCH_KBA_LABEL_MAPPING,
  FETCH_KBA_VISITS_TREND_DATA,
  FETCH_LEAD_BREAKDOWN_DATA,
  FETCH_LEAD_LABEL_MAPPING,
  FETCH_LEAD_VISITS_TREND_DATA,
  FETCH_NEW_KEY_INDICATOR_TOTALS,
  FETCH_NEW_TREND_BREAKDOWN,
  FETCH_OCE_ANNOTATION_KBA_TYPES,
  FETCH_OCE_ANNOTATION_LEAD_TYPES,
  FETCH_TRENDLINE,
  FETCH_TREND_BREAKDOWN,
  FETCH_VISITS_CHART_ANNOTATIONS_DATA,
  FETCH_VISITS_TREND,
  FETCH_WEBSITE_VISITS,
  SET_BASELINE_CHART_ANNOTATIONS_DATA,
  SET_FULL_FIGURES_DAILY,
  SET_FULL_FIGURES_MONTHLY,
  SET_FULL_FIGURES_WEEKLY,
  SET_KBA_LABEL_MAPPING,
  SET_KBA_TREND_BREAKDOWN,
  SET_LEAD_LABEL_MAPPING,
  SET_LEAD_TREND_BREAKDOWN,
  SET_NEW_KEY_INDICATOR_TOTALS,
  SET_NEW_TREND_BREAKDOWN,
  SET_OCE_ANNOTATION_KBA_TYPES,
  SET_OCE_ANNOTATION_LEAD_TYPES,
  SET_TREND_BREAKDOWN,
  SET_VISITS_CHART_ANNOTATIONS_DATA,
  SET_VISITS_GRAPH_DATA_DAILY,
  SET_VISITS_GRAPH_DATA_MONTHLY,
  SET_VISITS_GRAPH_DATA_WEEKLY,
  SET_VISITS_KBAS_GRAPH_DATA_DAILY,
  SET_VISITS_KBAS_GRAPH_DATA_MONTHLY,
  SET_VISITS_KBAS_GRAPH_DATA_WEEKLY,
  SET_VISITS_LEADS_GRAPH_DATA_DAILY,
  SET_VISITS_LEADS_GRAPH_DATA_MONTHLY,
  SET_VISITS_LEADS_GRAPH_DATA_WEEKLY,
  SET_VISITS_TREND,
  SET_VISITS_TREND_KBAS,
  SET_VISITS_TREND_LEADS,
  SET_WEBSITE_VISITS,
} from "./actionTypes";

export const fetchTrendBreakdownData = () => {
  return { type: FETCH_TREND_BREAKDOWN };
};

export const fetchTrendlineData = () => {
  return { type: FETCH_TRENDLINE };
};
export const fetchVisitsTrendAllData = () => {
  return { type: FETCH_VISITS_TREND };
};
export const fetchFullFiguresData = () => {
  return { type: FETCH_FULL_FIGURES };
};

export const fetchLeadVisitsData = () => {
  return { type: FETCH_LEAD_VISITS_TREND_DATA };
};

export const fetchLeadBreakdownData = () => {
  return { type: FETCH_LEAD_BREAKDOWN_DATA };
};

export const fetchKbaVisitsData = () => {
  return { type: FETCH_KBA_VISITS_TREND_DATA };
};

export const fetchKbaBreakdownData = () => {
  return { type: FETCH_KBA_BREAKDOWN_DATA };
};

export const fetchBaselineChartAnnotations = (params: AnnotationsQueryParams, dashboard: string) => {
  return { type: FETCH_BASELINE_CHART_ANNOTATIONS_DATA, params, dashboard };
};

export const fetchVisitsChartAnnotations = (params: AnnotationsQueryParams, dashboard: string) => {
  return { type: FETCH_VISITS_CHART_ANNOTATIONS_DATA, params, dashboard };
};

export const fetchOCEAnnotationsKbaTypes = () => {
  return { type: FETCH_OCE_ANNOTATION_KBA_TYPES };
};

export const fetchOCEAnnotationsLeadTypes = () => {
  return { type: FETCH_OCE_ANNOTATION_LEAD_TYPES };
};

export const setTrendBreakdown = (data: trendBreakdownData) => {
  return { type: SET_TREND_BREAKDOWN, payload: data };
};
export const setFullFigures_Daily = (data: Array<FullFigureInt>) => {
  return { type: SET_FULL_FIGURES_DAILY, payload: data };
};

export const setFullFigures_Weekly = (data: Array<FullFigureInt>) => {
  return { type: SET_FULL_FIGURES_WEEKLY, payload: data };
};

export const setFullFigures_Monthly = (data: Array<FullFigureInt>) => {
  return { type: SET_FULL_FIGURES_MONTHLY, payload: data };
};

export const setKbaTrendBreakdown = (data: any) => {
  return { type: SET_KBA_TREND_BREAKDOWN, payload: data };
};

export const setLeadTrendBreakdown = (data: any) => {
  return { type: SET_LEAD_TREND_BREAKDOWN, payload: data };
};

export const setVisitsTrendAll = (data: any) => {
  return { type: SET_VISITS_TREND, payload: data };
};

export const setVisitsGraphData_Daily = (data: any[]) => {
  return { type: SET_VISITS_GRAPH_DATA_DAILY, payload: data };
};

export const setVisitsGraphData_Weekly = (data: any[]) => {
  return { type: SET_VISITS_GRAPH_DATA_WEEKLY, payload: data };
};

export const setVisitsGraphData_Monthly = (data: any[]) => {
  return { type: SET_VISITS_GRAPH_DATA_MONTHLY, payload: data };
};

export const setVisitsTrendKbas = (data: any) => {
  return { type: SET_VISITS_TREND_KBAS, payload: data };
};

export const setVisitsKbasGraphData_Daily = (data: any[]) => {
  return { type: SET_VISITS_KBAS_GRAPH_DATA_DAILY, payload: data };
};

export const setVisitsKbasGraphData_Weekly = (data: any[]) => {
  return { type: SET_VISITS_KBAS_GRAPH_DATA_WEEKLY, payload: data };
};

export const setVisitsKbasGraphData_Monthly = (data: any[]) => {
  return { type: SET_VISITS_KBAS_GRAPH_DATA_MONTHLY, payload: data };
};

export const setVisitsTrendLeads = (data: any) => {
  return { type: SET_VISITS_TREND_LEADS, payload: data };
};

export const setVisitsLeadsGraphData_Daily = (data: any[]) => {
  return { type: SET_VISITS_LEADS_GRAPH_DATA_DAILY, payload: data };
};

export const setVisitsLeadsGraphData_Weekly = (data: any[]) => {
  return { type: SET_VISITS_LEADS_GRAPH_DATA_WEEKLY, payload: data };
};

export const setVisitsLeadsGraphData_Monthly = (data: any[]) => {
  return { type: SET_VISITS_LEADS_GRAPH_DATA_MONTHLY, payload: data };
};

export const setBaselineChartAnnotations = (data: any[]) => {
  return { type: SET_BASELINE_CHART_ANNOTATIONS_DATA, payload: data };
};

export const setVisitsChartAnnotations = (data: any[]) => {
  return { type: SET_VISITS_CHART_ANNOTATIONS_DATA, payload: data };
};

export const setOCEAnnotationsKbaTypes = (data: MetricType[]) => {
  return { type: SET_OCE_ANNOTATION_KBA_TYPES, payload: data };
};

export const setOCEAnnotationsLeadTypes = (data: MetricType[]) => {
  return { type: SET_OCE_ANNOTATION_LEAD_TYPES, payload: data };
};

//refactored endpoint
export const fetchNewTrendBreakdownData = () => {
  return { type: FETCH_NEW_TREND_BREAKDOWN };
};

export const fetchNewKeyIndicatorTotalsData = () => {
  return { type: FETCH_NEW_KEY_INDICATOR_TOTALS };
};

export const setNewTrendBreakdown = (data: trendNewBreakdownData) => {
  return { type: SET_NEW_TREND_BREAKDOWN, payload: data };
};

export const setNewKeyIndicatorTotals = (data: trendNewBreakdownData) => {
  return { type: SET_NEW_KEY_INDICATOR_TOTALS, payload: data };
};

export const fetchKbaLabelMapping = () => {
  return { type: FETCH_KBA_LABEL_MAPPING };
};

export const setKbaLabelMapping = (data: Record<string, string>) => {
  return { type: SET_KBA_LABEL_MAPPING, payload: data };
};

export const fetchLeadLabelMapping = () => {
  return { type: FETCH_LEAD_LABEL_MAPPING };
};

export const setLeadLabelMapping = (data: Record<string, string>) => {
  return { type: SET_LEAD_LABEL_MAPPING, payload: data };
};

export const setWebsiteVisits = (data: any) => {
  return { type: SET_WEBSITE_VISITS, payload: data };
};

export const fetchWebsiteVisits = () => {
  return { type: FETCH_WEBSITE_VISITS };
};
