export const WHITE = "rgb(255,255,255)";
export const LIGHT_GREY = "#a4a4a4";
export const TRANSPARENT = "rgba(0,0,0,0)";
export const CYAN = "#00a0dd";
export const PURPLE = "#bb86fc";
export const TURQUOISE = "#03dac6";
export const GREEN = "#03dac6";
export const BLACK = "rgb(0,0,0)";
export const DARK_ACCENT = "#2c394e";
export const DARK_GREY = "#626262";
export const RED = "#e74c3c";
export const ORANGE = "#f5a623";
export const BLUE = "#3498db";
export const DARK_PURPLE = "#8e44ad";
export const LIME_GREEN = "#7ed321";
export const ORANGE_RED = "#1abc9c";
export const VISITS_ORANGE = "#f39c12";
export const LIGHT_CYAN = "#98E2EC";
export const LIGHT_PURPLE = "#D4B1E1";
export const ACCENT_BROWN = "#95685b";
export const BRIGHT_BLUE = "#0000FF";
export const FOREST_GREEN = "#228B22";
export const LIGHT_ORANGE = "#D99D2D";
export const LIGHT_GREEN = "#79C43D";
export const GRAY = "#A4A4A4";
export const LIGHT_YELLOW = "#EBDE35";
export const BRIGHT_YELLOW = "#FF0";
export const INDIGO = "#EA7ACD";
export const BROWN = "#ED7D31";
export const LIGHT_BROWN = "#FFB064";
export const LIGHT_LIGHT_YELLOW = "#FFFF9B";
export const WINE = "#4E0707";
export const CANDY = "#D21404";
export const AMBER = "#FFBF00";
export const PURE_ORANGE = "#FFA500";
export const MAROON = "#aa0f43";
export const PINK = "#f83eb2";
export const NAVY_BLUE = "#120a37";
export const TEAL = "#186a5f";
export const CREAM = "#857291";
export const JUNGLE_GREEN = "#414c32";
export const DEEP_PINK = "#ed1eb6";
export const DARK_TEAL = "#15314c";
export const RUST = "#ba0e1a";
export const GOLD = "#f1dd5e";
export const ROYAL_PURPLE = "#872075";
export const LIGHT_ROYAL_PURPLE = "#615395";
export const LIGHT_GOLD = "#eafb60";
export const GREEN_HOLD = "#2e583c";
export const LIGHT_CREAM = "#d5bff3";
export const BASIL = "#32612D";
export const MINT = "#99EDC3";
export const SERPIA = "#E3B778";
export const DARK_CYAN = "#0067dd";
export const DARK_GRAY = "#8a8888";
export const HUMMING_BIRD = "#dcf0f7";

export const RACING_GREEN = "#82AB0C";
export const GOLDEN_YELLOW = "#FFC000";
export const PEACH = "#fc9483";
export const ARIYA_BLUE = "rgb(0,160,221)";
export const ARIYA_PINK = "rgb(255,0,128)";
export const ARIYA_GREEN = "rgb(51,204,51)";
export const ARIYA_PURPLE = "rgb(142,68,173)";
export const WEBSITE_VISITS_COLORS = [ORANGE, BLUE, DARK_PURPLE, RED, LIME_GREEN, ORANGE_RED, LIGHT_CYAN, WHITE];
export const CHART_COLORS: { [index: string]: Array<string> } = { dark: [CYAN, WHITE], light: [CYAN, DARK_ACCENT] };
export const FULL_FIGURE_CHART_COLORS: { [index: string]: Array<string> } = { dark: [CYAN, WHITE], light: [CYAN, DARK_ACCENT] };
export const CALL_CENTER_COLORS = { positive: LIME_GREEN, negative: ORANGE };
export const OCE_CHART_COLORS = [LIME_GREEN, DARK_PURPLE, ORANGE];
export const PMO_CHART_COLORS = [
  BLUE,
  DARK_PURPLE,
  TURQUOISE,
  ORANGE,
  RED,
  GREEN,
  INDIGO,
  ARIYA_PINK,
  ACCENT_BROWN,
  LIGHT_YELLOW,
  BRIGHT_BLUE,
];
export const MEDIA_MIX_CHART_COLORS = [LIGHT_YELLOW, ORANGE, GREEN, RED, BROWN, INDIGO];
export const CCS_BUSINESS_REVENUE_AND_SUBSCRIPTION_COLORS: Array<string> = [CYAN, LIGHT_CYAN, ORANGE, GREEN];
export const DIGITAL_LEADS_SOURCE_COLORS = [LIGHT_GREY, CYAN, BRIGHT_BLUE, TURQUOISE, RED, ORANGE, PURPLE, LIME_GREEN, ORANGE_RED];
export const CCS_ANALYSIS_COLORS = [ORANGE, BLUE, DARK_PURPLE, RED, GREEN, INDIGO, BROWN, LIME_GREEN, LIGHT_YELLOW, PURPLE, GRAY];

export const GRADE_MIX_COLORS = [
  BLUE,
  DARK_PURPLE,
  ORANGE,
  RED,
  GREEN,
  INDIGO,
  BROWN,
  LIME_GREEN,
  BRIGHT_YELLOW,
  LIGHT_GREY,
  FOREST_GREEN,
  PURPLE,
  GRAY,
  TURQUOISE,
  VISITS_ORANGE,
];

export const REGIONAL_COLORS = [
  BLUE,
  DARK_PURPLE,
  ORANGE,
  RED,
  GREEN,
  INDIGO,
  BROWN,
  LIME_GREEN,
  BRIGHT_YELLOW,
  LIGHT_GREY,
  FOREST_GREEN,
  PURPLE,
  GRAY,
  TURQUOISE,
  VISITS_ORANGE,
  ARIYA_BLUE,
  LIGHT_YELLOW,
  LIGHT_PURPLE,
];

export const SOV_MIX_COLORS = [
  BLUE,
  DARK_PURPLE,
  ORANGE,
  RED,
  GREEN,
  NAVY_BLUE,
  INDIGO,
  BROWN,
  GRAY,
  PURPLE,
  CYAN,
  MAROON,
  PINK,
  TEAL,
  CREAM,
  LIGHT_PURPLE,
  LIGHT_CYAN,
  LIGHT_ROYAL_PURPLE,
  LIGHT_ORANGE,
  LIGHT_GREY,
  LIGHT_YELLOW,
  JUNGLE_GREEN,
  BRIGHT_BLUE,
  DARK_TEAL,
  BRIGHT_YELLOW,
  FOREST_GREEN,
  DARK_GREY,
  DEEP_PINK,
  DARK_PURPLE,
  ACCENT_BROWN,
  ARIYA_BLUE,
  LIGHT_GOLD,
  ARIYA_PINK,
  ARIYA_GREEN,
  RUST,
  ARIYA_PURPLE,
  VISITS_ORANGE,
  ORANGE_RED,
  ROYAL_PURPLE,
  TURQUOISE,
  GOLD,
  GREEN_HOLD,
  LIGHT_CREAM,
];

export const ALL_COLOURS = [
  WHITE,
  LIGHT_GREY,
  TRANSPARENT,
  CYAN,
  PURPLE,
  TURQUOISE,
  GREEN,
  BLACK,
  DARK_ACCENT,
  DARK_GREY,
  RED,
  ORANGE,
  BLUE,
  DARK_PURPLE,
  LIME_GREEN,
  ORANGE_RED,
  VISITS_ORANGE,
  LIGHT_CYAN,
  LIGHT_PURPLE,
  ACCENT_BROWN,
  BRIGHT_BLUE,
  FOREST_GREEN,
  LIGHT_ORANGE,
  LIGHT_GREEN,
  GRAY,
  LIGHT_YELLOW,
  BRIGHT_YELLOW,
  INDIGO,
  BROWN,
  ARIYA_BLUE,
  ARIYA_PINK,
  ARIYA_GREEN,
  ARIYA_PURPLE,
];

export const MARKETING_ACTIVITY_COLORS = [
  BLUE,
  DARK_PURPLE,
  ORANGE,
  RED,
  GREEN,
  INDIGO,
  BROWN,
  LIME_GREEN,
  BRIGHT_YELLOW,
  TURQUOISE,
  FOREST_GREEN,
  PURPLE,
];

export const AVG_APP_RATINGS_COLORS = [
  "#556b2f",
  "#228b22",
  "#7f0000",
  "#483d8b",
  "#b8860b",
  "#008b8b",
  "#9acd32",
  "#00008b",
  "#8fbc8f",
  "#800080",
  "#ff0000",
  "#ff8c00",
  "#ffd700",
  "#7cfc00",
  "#8a2be2",
  "#00ff7f",
  "#00ffff",
  "#00bfff",
  "#0000ff",
  "#ff6347",
  "#da70d6",
  "#b0c4de",
  "#ff00ff",
  "#1e90ff",
  "#db7093",
  "#90ee90",
  "#ff1493",
  "#7b68ee",
  "#ffa07a",
  "#ffdead",
  "#ffc0cb",
  "#8b4513",
  "#ffff00",
  "#ff4500",
  "#9932CC",
  "#00FFFF",
  "#FF1493",
  "#DC143C",
  "#00CED1",
  "#9400D3",
  "#FFD700",
  "#8B008B",
  "#008B8B",
  "#8A2BE2",
  "#A52A2A",
  "#DEB887",
  "#5F9EA0",
  "#7FFF00",
  "#D2691E",
  "#FF7F50",
  "#6495ED",
  "#FFF8DC",
  "#DCDCDC",
  "#00008B",
  "#008B8B",
  "#B8860B",
  "#A9A9A9",
  "#006400",
  "#BDB76B",
  "#8B008B",
  "#556B2F",
  "#FF8C00",
  "#9932CC",
  "#8B0000",
  "#E9967A",
  "#8FBC8F",
  "#483D8B",
  "#2F4F4F",
  "#00CED1",
  "#9400D3",
  "#FF1493",
  "#00BFFF",
  "#696969",
  "#696969",
  "#1E90FF",
  "#B22222",
  "#FFFAF0",
  "#228B22",
  "#FF00FF",
  "#DCDCDC",
  "#F8F8FF",
  "#FFD700",
  "#DAA520",
  "#808080",
  "#008000",
  "#ADFF2F",
  "#808080",
  "#F0FFF0",
  "#FF69B4",
  "#CD5C5C",
  "#4B0082",
  "#FFFFF0",
  "#F0E68C",
  "#E6E6FA",
  "#FFF0F5",
  "#7CFC00",
  "#FFFACD",
  "#ADD8E6",
  "#F08080",
  "#E0FFFF",
  "#FAFAD2",
  "#90EE90",
  "#D3D3D3",
  "#FFB6C1",
  "#FFA07A",
  "#20B2AA",
  "#87CEFA",
  "#778899",
  "#B0C4DE",
  "#FFFFE0",
  "#00FF00",
  "#32CD32",
  "#FAF0E6",
  "#FF00FF",
  "#800000",
  "#66CDAA",
  "#0000CD",
  "#BA55D3",
  "#9370DB",
  "#3CB371",
  "#7B68EE",
  "#00FA9A",
  "#48D1CC",
  "#C71585",
  "#191970",
  "#F5FFFA",
  "#FFE4E1",
  "#FFE4B5",
  "#FFDEAD",
  "#000080",
  "#FDF5E6",
  "#808000",
  "#6B8E23",
  "#FFA500",
  "#FF4500",
  "#DA70D6",
  "#EEE8AA",
  "#98FB98",
  "#AFEEEE",
  "#DB7093",
  "#FFEFD5",
  "#FFDAB9",
  "#CD853F",
  "#FFC0CB",
  "#DDA0DD",
  "#B0E0E6",
  "#800080",
  "#FF0000",
  "#BC8F8F",
  "#4169E1",
  "#8B4513",
  "#FA8072",
  "#FAA460",
  "#2E8B57",
  "#FFF5EE",
  "#A0522D",
  "#C0C0C0",
  "#87CEEB",
  "#6A5ACD",
  "#708090",
];

export const VOC_START_RATING_CHART_COLORS: Record<string, string> = {
  "Abu Dhabi": "#FF0010",
  Italy: "#0000FF",
  Norway: "#FFFF00",
  Australia: "#00FF00",
  India: "#FF00FF",
  Dubai: "#00FFFF",
  Germany: "#A9A9A9",
  Netherlands: "#000000",
  Denmark: "#FFD700",
  Finland: "#9A6324",
  Egypt: "#E6194B",
  Sweden: "#3CB44B",
  "South Africa": "#FFE119",
  "Saudi Arabia": "#4363D8",
  "United Kingdom": "#F58231",
  Kuwait: "#911EB4",
  Spain: "#46F0F0",
  France: FOREST_GREEN,
  Belgium: "#BCF60C",
};

export const VOC_TOPIC_CLASSIFICATION_COLORS: Record<string, string> = {
  Content: BLUE,
  Tools: RED,
  Experience: DARK_PURPLE,
  Contact: LIME_GREEN,
};

export const TOP_FIVE_MODEL_COLORS = [DARK_PURPLE, BLUE, LIGHT_YELLOW, GREEN, ORANGE, INDIGO];
export const TOP_FIVE_MODEL_EXTRA_COLOR = [
  LIGHT_PURPLE,
  ACCENT_BROWN,
  FOREST_GREEN,
  "#eb4b07",
  "#074beb",
  "#5d5c7a",
  "#b5b882",
  "#f0b989",
  "#cfffe1",
];

export const MOST_POPULAR_ENTRY_POINTS_COLORS: Record<string, string> = {
  ["Finance Calculator"]: BLUE,
  ["Personal Showroom"]: DARK_PURPLE,
  ["Configurator V2"]: ORANGE,
  ["Offers Hub"]: RED,
  ["Interactive Owner's Manual"]: INDIGO,
  ["VLP/VLP+"]: TURQUOISE,
  ["EV Calculator"]: FOREST_GREEN,
};
