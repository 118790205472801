// Todo: Move some effects to sagas instead of useEffect
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "../../assets/styles/component/filters.scss";
import { FiltersLayout } from "../Layouts";

interface Props {
  filterBtns: Array<{ id: string; navigateTo: string; name: string }>;
}

export const LeadManagementReportFilters = withRouter((props: Props & RouteComponentProps) => {
  const { filterBtns } = props;

  return (
    <FiltersLayout extraClass={"iframeFilters"} filterBtns={filterBtns}>
      <>
      </>
    </FiltersLayout>
  );
});
