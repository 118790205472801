import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useExpanded, useTable } from "react-table";
import { fetchExecutiveSummaryAggregatedPurchaseFunnelTable } from "../../../../actions/executiveSummaryActions";
import { getExecutiveSummaryAggregatedPurchaseFunnelTable } from "../../../../api/executiveSummaryWebsiteApi";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";

const AggregatedPurchaseFunnelTable = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();

  const [currentDateLabel, setCurrentDateLabel] = useState("Current Period");
  const [latestMonthLabel, setLatestMonthLabel] = useState("Single Month");

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(fetchExecutiveSummaryAggregatedPurchaseFunnelTable());
  }, [history.location.search]);

  const {
    data: aggregatedPurchaseFunnelTableData,
    refetch,
    isFetching: isLoading,
  } = useQuery({
    queryKey: ["aggregatedPurchaseFunnelTableData"],
    queryFn: getExecutiveSummaryAggregatedPurchaseFunnelTable,
    enabled: false,
    initialData: {},
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  useEffect(() => {
    if (aggregatedPurchaseFunnelTableData?.data) setData(aggregatedPurchaseFunnelTableData.data);
  }, [aggregatedPurchaseFunnelTableData?.data]);

  useEffect(() => {
    if (aggregatedPurchaseFunnelTableData?.start_date && aggregatedPurchaseFunnelTableData?.end_date) {
      const startDate = moment(aggregatedPurchaseFunnelTableData.start_date, "YYYY-MM-DD").format("MMM YYYY");
      const endDate = moment(aggregatedPurchaseFunnelTableData.end_date, "YYYY-MM-DD").format("MMM YYYY");
      setCurrentDateLabel(`${startDate} - ${endDate}`);
      setLatestMonthLabel(moment(aggregatedPurchaseFunnelTableData.end_date, "YYYY-MM-DD").format("MMMM YYYY"));
    }
  }, [aggregatedPurchaseFunnelTableData]);

  const getValueCellContent = (value: number | null, row: any, isPercentage?: boolean) => {
    if (row.original.market == "vs LY" || row.original.market == "vs BP") {
      const className = value && value > 0 ? "positive" : value && value < 0 ? "negative" : undefined;
      return <span className={className}>{value == null ? "" : `${value}${isPercentage ? "PTS" : "%"}`}</span>;
    }
    return <span>{value == null ? "" : `${value.toLocaleString()}${isPercentage ? "%" : ""}`}</span>;
  };

  const cols = useMemo<any>(
    () => [
      {
        Header: "",
        accessor: "market",
      },
      {
        Header: currentDateLabel,
        columns: [
          {
            Header: "DIS %",
            accessor: "fytd_perc",
            Cell: ({ value, row }: { value: number | null; row: any }) => {
              return getValueCellContent(value, row, true);
            },
          },
          {
            Header: "DIS Vol.",
            accessor: "fytd_dis_vol",
            Cell: ({ value, row }: { value: number | null; row: any }) => {
              return getValueCellContent(value, row);
            },
          },
          {
            Header: "PS Vol.",
            accessor: "fytd_private_sales_vol",
            Cell: ({ value, row }: { value: number | null; row: any }) => {
              return getValueCellContent(value, row);
            },
          },
        ],
      },
      {
        Header: latestMonthLabel,
        columns: [
          {
            Header: "DIS %",
            accessor: "single_month_perc",
            Cell: ({ value, row }: { value: number | null; row: any }) => {
              return getValueCellContent(value, row, true);
            },
          },
          {
            Header: "DIS Vol.",
            accessor: "single_month_dis_vol",
            Cell: ({ value, row }: { value: number | null; row: any }) => {
              return getValueCellContent(value, row);
            },
          },
          {
            Header: "PS Vol.",
            accessor: "single_month_private_sales_vol",
            Cell: ({ value, row }: { value: number | null; row: any }) => {
              return getValueCellContent(value, row);
            },
          },
        ],
      },
    ],
    [data, latestMonthLabel, currentDateLabel]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsExpanded,
    state: { expanded },
  } = useTable(
    {
      columns: cols,
      data: data,
    },
    useExpanded
  );

  useEffect(() => {
    toggleAllRowsExpanded(true);
  }, [data]);

  return isLoading ? (
    <LoadingEllipsis isLoading={isLoading} />
  ) : (
    <table {...getTableProps()} className="dis-table aggregated-purchase-funnel-table">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} key={column.getHeaderProps().key}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={row.getRowProps().key} className={row.depth == 0 ? "parent-row" : undefined}>
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} key={cell.getCellProps().key}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
});

export default AggregatedPurchaseFunnelTable;
