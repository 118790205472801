import { Box, Dialog, DialogContent, FormControl, makeStyles, Typography } from "@material-ui/core";
import React, { Dispatch, SetStateAction } from "react";
import { Control, FieldErrors, UseFormSetValue, useWatch } from "react-hook-form";
import { RootStateOrAny, useSelector } from "react-redux";
import { ALL_OPTION_NO_SPACE } from "../../../constants";
import { Channel, Market, Tag } from "../../../constants/interface/admin/common";
import { convertToMetricType } from "../../../utils/annotationFunctions";
import BrandsField from "../Fields/BrandsField";
import ChannelBreakdownsField from "../Fields/ChannelBreakdownsField";
import ChannelsField from "../Fields/ChannelsField";
import LeadSourcesField from "../Fields/LeadSourcesField";
import MarketsField from "../Fields/MarketsField";
import MetricsField from "../Fields/MetricsField";
import MetricTypeField from "../Fields/MetricTypeField";
import ModelsField from "../Fields/ModelsField";
import RegionsField from "../Fields/RegionsField";
import TagsField from "../Fields/TagsField";
import { FormFields } from "./AnnotationDialog";

interface Props {
  paramsSelectionDialogOpen: boolean;
  setParamsSelectionDialogOpen: Dispatch<SetStateAction<boolean>>;
  tags: Tag[];
  filteredRegionsList: string[];
  availableMarkets: Market[];
  filteredModelsList: string[];
  filteredChannelsList: string[];
  filteredChannelBreakdownsList: string[];
  filteredLeadSources: string[];
  fields: string[];
  setValue: UseFormSetValue<FormFields>;
  control: Control<FormFields>;
  errors: FieldErrors | undefined;
  setTags: Dispatch<SetStateAction<Array<Tag>>>;
  dashboard: string;
}

const useStyles = makeStyles({
  bold: {
    fontWeight: 450,
  },
  paramSelectioncontainer: {
    width: "100%",
    maxWidth: "500px",
  },
});

const AnnotationParamsDialog = (props: Props) => {
  const {
    tags,
    paramsSelectionDialogOpen,
    setParamsSelectionDialogOpen,
    filteredRegionsList,
    availableMarkets,
    filteredModelsList,
    filteredChannelsList,
    filteredChannelBreakdownsList,
    filteredLeadSources,
    fields,
    setValue,
    control,
    errors,
    setTags,
    dashboard,
  } = props;

  const classes = useStyles();
  const brands = useSelector((state: RootStateOrAny) => state.annotationParameters[dashboard].brands) ?? [];
  const regions = useSelector((state: RootStateOrAny) => state.annotationParameters[dashboard].regions) ?? [];
  const markets = useSelector((state: RootStateOrAny) => state.annotationParameters[dashboard].markets) ?? [];
  const models = useSelector((state: RootStateOrAny) => state.annotationParameters[dashboard].models) ?? [];
  const channels = useSelector((state: RootStateOrAny) => state.annotationParameters[dashboard].channels) ?? [];
  const channelBreakdowns = useSelector((state: RootStateOrAny) => state.annotationParameters[dashboard].channelBreakdowns) ?? [];
  const metrics = useSelector((state: RootStateOrAny) => state.annotationParameters[dashboard].metrics) ?? [];
  const leadSources = useSelector((state: RootStateOrAny) => state.annotationParameters[dashboard].lead_sources) ?? [];
  const kbaTypes = useSelector((state: RootStateOrAny) => state.digital_performance.kba_types);
  const leadTypes = useSelector((state: RootStateOrAny) => state.digital_performance.lead_types);

  const selectedChannels = useWatch({ control: control, name: "channels" });
  const selectedMarkets = useWatch({ control: control, name: "markets" });
  const selectedMetrics = useWatch({ control: control, name: "metrics" });

  const selectedMetricsList = selectedMetrics.map((metric) => metric.metric);

  const metricTypes =
    selectedMetricsList.includes("kbas") && selectedMetricsList.includes("leads")
      ? [...convertToMetricType(kbaTypes), ...convertToMetricType(leadTypes)]
      : selectedMetricsList.includes("kbas")
      ? [...convertToMetricType(kbaTypes)]
      : selectedMetricsList.includes("leads")
      ? [...convertToMetricType(leadTypes)]
      : [];

  return (
    <Dialog onClose={() => setParamsSelectionDialogOpen(false)} open={paramsSelectionDialogOpen} maxWidth={"md"}>
      <DialogContent className={classes.paramSelectioncontainer}>
        <Box component="div">
          <Typography className={classes.bold}>Edit Params:</Typography>

          {fields.includes("brands") && (
            <FormControl fullWidth style={{ marginBottom: 15 }}>
              <BrandsField control={control} brands={brands} markets={markets} regions={regions} setValue={setValue} />
            </FormControl>
          )}

          {fields.includes("regions") && (
            <FormControl fullWidth style={{ marginBottom: 15 }}>
              <RegionsField
                control={control}
                name="regions"
                regions={regions}
                filteredRegionsList={filteredRegionsList}
                availableMarkets={availableMarkets}
                setValue={setValue}
                error={errors?.regions}
                rules={{
                  required: "Please select a region",
                }}
              />
            </FormControl>
          )}

          {fields.includes("markets") && (
            <FormControl fullWidth style={{ marginBottom: 15 }}>
              <MarketsField control={control} availableMarkets={availableMarkets} models={models} setValue={setValue} errors={errors} />
            </FormControl>
          )}

          {fields.includes("models") && (
            <FormControl fullWidth style={{ marginBottom: 15 }}>
              <ModelsField control={control} models={models} filteredModelsList={filteredModelsList} setValue={setValue} errors={errors} />
            </FormControl>
          )}

          {fields.includes("channels") && (
            <FormControl fullWidth style={{ marginBottom: 15 }}>
              <ChannelsField control={control} channels={channels} filteredChannelsList={filteredChannelsList} errors={errors} />
            </FormControl>
          )}

          {fields.includes("channel_breakdowns") &&
            selectedChannels.find((channel: Channel) => channel.channel == ALL_OPTION_NO_SPACE) == undefined &&
            selectedMarkets.find((market: Market) => market.market == ALL_OPTION_NO_SPACE) == undefined && (
              <FormControl fullWidth style={{ marginBottom: 15 }}>
                <ChannelBreakdownsField
                  control={control}
                  channelBreakdowns={channelBreakdowns}
                  filteredChannelBreakdownsList={filteredChannelBreakdownsList}
                  errors={errors}
                />
              </FormControl>
            )}

          {fields.includes("metrics") && (
            <FormControl fullWidth style={{ marginBottom: 15 }}>
              <MetricsField control={control} metrics={metrics} errors={errors} />
            </FormControl>
          )}

          {fields.includes("metric_types") && (selectedMetricsList.includes("leads") || selectedMetricsList.includes("kbas")) && (
            <FormControl fullWidth style={{ marginBottom: 15 }}>
              <MetricTypeField control={control} metricTypes={metricTypes} errors={errors} />
            </FormControl>
          )}

          {fields.includes("tags") && (
            <FormControl fullWidth style={{ marginBottom: 15 }}>
              <TagsField control={control} tags={tags} setTags={setTags} errors={errors} />
            </FormControl>
          )}

          {fields.includes("lead_sources") && (
            <FormControl fullWidth style={{ marginBottom: 15 }}>
              <LeadSourcesField control={control} leadSources={leadSources} filteredLeadSources={filteredLeadSources} errors={errors} />
            </FormControl>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AnnotationParamsDialog;
