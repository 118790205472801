import { performancePages } from "../constants";
import { IMarketDataRefresh, IPurchaseFunnelDataRefresh } from "../constants/interface";
import {
  FETCH_ACTIVATION_DATA_REFRESH,
  FETCH_BRAND_PERFORMANCE_LAST_DATA_REFRESH,
  FETCH_CPO_OPERATIONAL_LAST_REFRESH_DATE,
  FETCH_ECOMMERCE_LAST_REFRESH_DATE,
  FETCH_EXECUTIVE_SUMMARY_LAST_DATA_REFRESH,
  FETCH_JOURNEY_EXCELLENCE_LAST_DATA_REFRESH,
  FETCH_LAST_DATA_REFRESH,
  FETCH_LAST_DATA_REFRESH_CPO,
  FETCH_MARKET_DATA_REFRESH,
  FETCH_MIDAS_FIXED_LAST_REFRESH_DATE,
  FETCH_MIDAS_FMI_BY_MODEL_LAST_REFRESH_DATE,
  FETCH_MIDAS_FMI_LAST_REFRESH_DATE,
  FETCH_MIDAS_MEDIA_MIX_LAST_REFRESH_DATE,
  FETCH_MIDAS_MTP_CATEGORY_LAST_REFRESH_DATE,
  FETCH_MIDAS_SPEND_VS_TRAFFIC_LAST_REFRESH_DATE,
  FETCH_MIDAS_STRATEGIC_CE_LAST_REFRESH_DATE,
  FETCH_MIDAS_STRATEGIC_DEALER_SUPPORT_LAST_REFRESH_DATE,
  FETCH_MSRP_LAST_DATA_REFRESH,
  FETCH_MTM_LAST_DATA_REFRESH,
  FETCH_OCE_LAST_REFRESH_DATE,
  FETCH_PRODUCT_MARKETING_OPTIMIZATION_LAST_DATA_REFRESH,
  FETCH_PRODUCT_PERFORMANCE_LAST_REFRESH_DATE,
  FETCH_PURCHASE_FUNNEL_LAST_DATA_REFRESH,
  FETCH_SEO_LAST_DATA_REFRESH,
  FETCH_USE_OWNERSHIP_LAST_DATA_REFRESH,
  FETCH_VOC_LAST_DATA_REFRESH,
  SET_BRAND_PERFORMANCE_LAST_DATA_REFRESH,
  SET_CPO_OPERATIONAL_LAST_REFRESH_DATE,
  SET_ECOMMERCE_LAST_REFRESH_DATE,
  SET_EXECUTIVE_SUMMARY_LAST_DATA_REFRESH,
  SET_JOURNEY_EXCELLENCE_LAST_DATA_REFRESH,
  SET_LAST_DATA_REFRESH,
  SET_LAST_DATA_REFRESH_CPO,
  SET_MARKET_DATA_REFRESH,
  SET_MIDAS_FMI_LAST_REFRESH_DATE,
  SET_MSRP_LAST_DATA_REFRESH,
  SET_OCE_LAST_REFRESH_DATE,
  SET_PRODUCT_PERFORMANCE_LAST_REFRESH_DATE,
  SET_PURCHASE_FUNNEL_LAST_DATA_REFRESH,
  SET_USE_OWNERSHIP_LAST_DATA_REFRESH,
  SET_VOC_LAST_DATA_REFRESH,
} from "./actionTypes";

export const fetchLastDataRefresh = () => {
  return { type: FETCH_LAST_DATA_REFRESH };
};

export const setLastDataRefresh = (page_name: string, lastDataRefresh: string) => {
  return { type: SET_LAST_DATA_REFRESH, payload: lastDataRefresh, page_name };
};

export const fetchCpoLastDataRefresh = () => {
  return { type: FETCH_LAST_DATA_REFRESH_CPO };
};

export const setCpoLastDataRefresh = (page_name: string, lastDataRefresh: string) => {
  return { type: SET_LAST_DATA_REFRESH_CPO, payload: lastDataRefresh, page_name };
};

export const fetchOceLastRefreshDate = () => {
  return { type: FETCH_OCE_LAST_REFRESH_DATE };
};

export const setOceLastRefreshDate = (data: any) => {
  return { type: SET_OCE_LAST_REFRESH_DATE, payload: data };
};

export const fetchMarketDataRefresh = () => {
  return { type: FETCH_MARKET_DATA_REFRESH };
};

export const setMarketDataRefresh = (lastDataRefresh: IMarketDataRefresh, market: string) => {
  return { type: SET_MARKET_DATA_REFRESH, payload: lastDataRefresh, market };
};

export const fetchActivationDataRefresh = () => {
  return { type: FETCH_ACTIVATION_DATA_REFRESH };
};

export const fetchMtmLastDataRefresh = () => {
  return { type: FETCH_MTM_LAST_DATA_REFRESH };
};

export const fetchSeoLastDataRefresh = () => {
  return { type: FETCH_SEO_LAST_DATA_REFRESH };
};

export const fetchPurchaseFunnelLastDataRefresh = () => {
  return { type: FETCH_PURCHASE_FUNNEL_LAST_DATA_REFRESH };
};

export const setPurchaseFunnelDataRefresh = (data: IPurchaseFunnelDataRefresh) => ({
  type: SET_PURCHASE_FUNNEL_LAST_DATA_REFRESH,
  payload: data,
});

export const fetchProductMarketingOptimizationLastDataRefresh = () => {
  return { type: FETCH_PRODUCT_MARKETING_OPTIMIZATION_LAST_DATA_REFRESH };
};

export const setCpoOperationalDataRefresh = (data: any) => ({
  type: SET_CPO_OPERATIONAL_LAST_REFRESH_DATE,
  payload: data,
});

export const fetchCpoOperationalLastDataRefresh = () => {
  return { type: FETCH_CPO_OPERATIONAL_LAST_REFRESH_DATE };
};

//Midas
export const fetchMidasFmiLastDataRefresh = () => {
  return { type: FETCH_MIDAS_FMI_LAST_REFRESH_DATE };
};

export const fetchMidasFmiByModelLastDataRefresh = () => {
  return { type: FETCH_MIDAS_FMI_BY_MODEL_LAST_REFRESH_DATE };
};

export const fetchMidasMediaMixLastDataRefresh = () => {
  return { type: FETCH_MIDAS_MEDIA_MIX_LAST_REFRESH_DATE };
};

export const setMidasFmiLastDataRefresh = (data: string) => ({
  type: SET_MIDAS_FMI_LAST_REFRESH_DATE,
  payload: data,
});

export const fetchMidasMtpCategoryLastDataRefresh = () => {
  return { type: FETCH_MIDAS_MTP_CATEGORY_LAST_REFRESH_DATE };
};

export const fetchMidasStrategicDealerSupportLastDataRefresh = () => {
  return { type: FETCH_MIDAS_STRATEGIC_DEALER_SUPPORT_LAST_REFRESH_DATE };
};

export const fetchMidasStrategicCELastDataRefresh = () => {
  return { type: FETCH_MIDAS_STRATEGIC_CE_LAST_REFRESH_DATE };
};

export const fetchMidasFixedLastDataRefresh = () => {
  return { type: FETCH_MIDAS_FIXED_LAST_REFRESH_DATE };
};

export const fetchMidasSpendVsTrafficLastDataRefresh = () => {
  return { type: FETCH_MIDAS_SPEND_VS_TRAFFIC_LAST_REFRESH_DATE };
};

export const fetchProductPerformanceLastDataRefresh = () => {
  return { type: FETCH_PRODUCT_PERFORMANCE_LAST_REFRESH_DATE };
};
export const setProductPerformanceLastDataRefresh = (data: any) => {
  return { type: SET_PRODUCT_PERFORMANCE_LAST_REFRESH_DATE, payload: data };
};

export const fetchEcommerceLastDataRefresh = () => {
  return { type: FETCH_ECOMMERCE_LAST_REFRESH_DATE };
};
export const setEcommerceLastDataRefresh = (data: string) => {
  return { type: SET_ECOMMERCE_LAST_REFRESH_DATE, payload: data };
};

export const fetchMsrpLastDataRefresh = () => {
  return { type: FETCH_MSRP_LAST_DATA_REFRESH };
};
export const setMsrpLastDataRefresh = (data: string) => {
  return { type: SET_MSRP_LAST_DATA_REFRESH, payload: data };
};

export const fetchJourneyExcellenceLastDataRefresh = (metric: string) => ({
  type: FETCH_JOURNEY_EXCELLENCE_LAST_DATA_REFRESH,
  payload: metric,
});
export const setJourneyExcellenceLastDataRefresh = (data: any) => ({ type: SET_JOURNEY_EXCELLENCE_LAST_DATA_REFRESH, payload: data });

export const fetchVocLastDataRefresh = () => {
  return { type: FETCH_VOC_LAST_DATA_REFRESH };
};
export const setVocLastDataRefresh = (data: string) => {
  return { type: SET_VOC_LAST_DATA_REFRESH, payload: data };
};

export const fetchUseOwnershipLastDataRefresh = (page: string) => {
  return { type: FETCH_USE_OWNERSHIP_LAST_DATA_REFRESH, payload: page };
};
export const setUseOwnershipLastDataRefresh = (data: string) => {
  return { type: SET_USE_OWNERSHIP_LAST_DATA_REFRESH, payload: data };
};

export const fetchBrandPerformanceLastDataRefresh = (metric: performancePages) => {
  return { type: FETCH_BRAND_PERFORMANCE_LAST_DATA_REFRESH, payload: metric };
};
export const setBrandPerformanceLastDataRefresh = (data: string) => {
  return { type: SET_BRAND_PERFORMANCE_LAST_DATA_REFRESH, payload: data };
};

export const fetchExecutiveSummaryLastDataRefresh = () => ({ type: FETCH_EXECUTIVE_SUMMARY_LAST_DATA_REFRESH });
export const setExecutiveSummaryLastDataRefresh = (data: string) => ({ type: SET_EXECUTIVE_SUMMARY_LAST_DATA_REFRESH, payload: data });
