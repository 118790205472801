import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  fetchConversion,
  fetchConversionTrend,
  fetchCustomerExperienceDxs,
  fetchCustomerJourney,
  fetchExitSurveyFeedback,
  fetchExitSurveyObjectiveCompletion,
  fetchJourneyCostTrend,
  fetchStarRatingAverage,
  fetchStarRatingFeedback,
  fetchWebsitePerformance,
  fetchWebsitePerformanceTrend,
} from "../../../../actions/journeyExcellenceActions";
import Collapsible from "../../../../components/Collapsible/Collapsible";
import { eventTracking, MixpanelEvents } from "../../../../utils/userTracking";
import CustomerExperience from "../subs/CustomerExperience";
import JourneyConversion from "../subs/JourneyConversion";
import JourneyOverview from "../subs/JourneyOverview";
import WebsiteVisitSource from "../subs/WebsiteVisitSource";

const OnlineJourneyContent = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Journey Excellence", page: "Online Journey" });
  }, []);

  useEffect(() => {
    dispatch(fetchWebsitePerformance());
    dispatch(fetchWebsitePerformanceTrend());
    dispatch(fetchJourneyCostTrend());
    dispatch(fetchCustomerJourney());
    dispatch(fetchConversion());
    dispatch(fetchConversionTrend());
    dispatch(fetchCustomerExperienceDxs());
    dispatch(fetchStarRatingAverage());
    dispatch(fetchStarRatingFeedback());
    dispatch(fetchExitSurveyObjectiveCompletion());
    dispatch(fetchExitSurveyFeedback());
  }, [history.location.search]);

  const handleOceBtnClick = useCallback(() => {
    history.push("/digital_performance");
  }, []);

  const handleVocBtnClick = useCallback(() => {
    history.push("/voc/website");
  }, []);

  const handleProductPerformanceBtnClick = useCallback(() => {
    history.push("/product_performance");
  }, []);

  return (
    <div className="journey-excellence-online-content">
      <Collapsible title="Journey Overview" show={true}>
        <JourneyOverview />
      </Collapsible>
      <Collapsible title="Journey Conversion" show={true}>
        <JourneyConversion />
      </Collapsible>
      <Collapsible title="Website visit source" show={true}>
        <WebsiteVisitSource />
      </Collapsible>
      <Collapsible title="Customer experience and Voice of the Customer" show={true}>
        <CustomerExperience />
      </Collapsible>
      <div className="more-info">
        <div>For more information access:</div>
        <button className="more-info-btn" onClick={handleOceBtnClick}>
          Online CE Performance
        </button>
        <button className="more-info-btn" onClick={handleVocBtnClick}>
          VOC Dashboard
        </button>
        <button className="more-info-btn" onClick={handleProductPerformanceBtnClick}>
          Product Performance Dashboard
        </button>
      </div>
    </div>
  );
});

export default OnlineJourneyContent;
