// import moment from "moment";
import "moment-fquarter";
import moment from "moment-timezone";
import { OCE_DATE_FILTER_OPTIONS } from "../constants";

export const getFiscalYearTimestamps = (date: moment.MomentInput) => {
  date = date || undefined;
  const startMonthName = "April";
  const endMonthName = "March";

  const momentDate = date ? moment(date, "DD/MM/YYYY") : moment();

  // @ts-ignore
  if (momentDate.fquarter(4).quarter === 4) {
    return {
      current: {
        start: momentDate.subtract(1, "year").month(startMonthName).startOf("month").format("DD/MM/YYYY"),
        end: momentDate.add(1, "years").month(endMonthName).endOf("month").format("DD/MM/YYYY"),
      },
      last: {
        start: momentDate.subtract(2, "years").month(startMonthName).startOf("month").format("DD/MM/YYYY"),
        end: momentDate.add(1, "years").month(endMonthName).endOf("month").format("DD/MM/YYYY"),
      },
    };
  }

  return {
    current: {
      start: momentDate.month(startMonthName).startOf("month").format("DD/MM/YYYY"),
      end: momentDate.add(1, "years").month(endMonthName).endOf("month").format("DD/MM/YYYY"),
    },
    last: {
      start: momentDate.subtract(2, "year").month(startMonthName).startOf("month").format("DD/MM/YYYY"),
      end: momentDate.add(1, "year").month(endMonthName).endOf("month").format("DD/MM/YYYY"),
    },
  };
};

export const isDigitalPerformanceDate = (dateString: string) => OCE_DATE_FILTER_OPTIONS.includes(dateString);

export const isCustomDate = (dateValue: string): boolean => {
  if (!dateValue) return false;
  const customDateRe =
    /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4} - ([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i;
  return customDateRe.test(dateValue);
};

export const isMonthlyCustomDate = (dateString: string): boolean => {
  if (!dateString) return false;
  const monthlyCustomDateRe =
    /(January|February|March|April|May|June|July|August|September|October|November|December)\s+\d{4} - (January|February|March|April|May|June|July|August|September|October|November|December)\s+\d{4}/i;
  return monthlyCustomDateRe.test(dateString);
};

export const DATE_START_END_VALUES: {
  [index: string]: { start: string; end: string };
} = {
  // Last week describes the last 52 weeks
  "Last week": {
    start: moment().subtract(52, "weeks").startOf("week").format("DD/MM/YYYY"),
    end: moment().subtract(1, "weeks").endOf("week").format("DD/MM/YYYY"),
  },
  "This month": {
    start: moment().startOf("month").format("DD/MM/YYYY"),
    end: moment().format("DD/MM/YYYY"),
  },
  "Last month": {
    start: moment().subtract(1, "months").startOf("month").format("DD/MM/YYYY"),
    end: moment().subtract(1, "months").endOf("month").format("DD/MM/YYYY"),
  },
  "This quarter": {
    start: moment(moment().fquarter().start, "YYYY-MM-DD").format("DD/MM/YYYY"),
    end: moment().format("DD/MM/YYYY"),
  },
  "Last quarter": {
    start: moment().subtract(1, "quarter").startOf("quarter").format("DD/MM/YYYY"),
    end: moment().subtract(1, "quarter").endOf("quarter").format("DD/MM/YYYY"),
  },
  "This financial year": {
    start: getFiscalYearTimestamps(undefined).current.start,
    end: moment().format("DD/MM/YYYY"),
  },
  "Last financial year": getFiscalYearTimestamps(undefined).last,
};

export const getStartEnd = (dateValue: string | null): { start: string; end: string } => {
  const dateValueToUse = dateValue && Object.keys(DATE_START_END_VALUES).includes(dateValue) ? dateValue : "Last week";

  return dateValue && isCustomDate(dateValue)
    ? { start: dateValue.split(" - ")[0], end: dateValue.split(" - ")[1] }
    : DATE_START_END_VALUES[dateValueToUse];
};

export const getFYQuarter = (dateString: string) =>
  `Q${moment(dateString, "MMMM YYYY").fquarter().quarter} FY${moment(dateString, "MMMM YYYY").fquarter().year.toString().substr(-2)}`;

//Returns an ordinal suffix of a number
export const ordinal_suffix_of = (i: number) => {
  const j = i % 10;
  const k = i % 100;
  if (j === 1 && k !== 11) {
    return "st";
  }
  if (j === 2 && k !== 12) {
    return "nd";
  }
  if (j === 3 && k !== 13) {
    return "rd";
  }
  return "th";
};

export const missingDatesString = (dateValue: string | number): string => {
  const formattedDateValue = Number(dateValue);
  return dateValue ? `${dateValue}<sup>${ordinal_suffix_of(formattedDateValue)}</sup>` : "";
};

//Generates dates between two dates
export const enumerateDaysBetweenDates = ({
  startDate,
  endDate,
  dateFormat,
  valueToIncrement,
}: {
  startDate: string | moment.MomentInput;
  endDate: string | moment.MomentInput;
  dateFormat: string;
  valueToIncrement: "months" | "days" | "years";
}) => {
  const now = moment(startDate, "YYYY-MM-DD").clone(),
    dates = [];
  // const dateFormat = monthFormat ? "MMMM YYYY" : "DD MMM";

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format(dateFormat));
    now.add(1, valueToIncrement);
  }
  return dates;
};

//Compares the last data refresh with the date expected to have data then returns the missing dates
export const getMissingDates = (lastDataRefresh: string, numOfDaysToSubtract: number) => {
  const currentUKTime = moment().tz("Europe/London").format("HH:mm");
  //If the current Uk time is not yet 3 the data expected should be from 2 days before else a day before as data is updated at noon
  const daysToSubtract = moment(currentUKTime, "HH:mm").isBefore(moment("15:00", "HH:mm")) ? numOfDaysToSubtract + 1 : numOfDaysToSubtract;
  const yesterdayDate = moment().subtract(daysToSubtract, "days");
  const isLastDataRefreshAccurate = moment(lastDataRefresh, "YYYY-MM-DD").isSameOrAfter(yesterdayDate, "day");
  let missingDates = "";
  let alertMessage = "";

  if (!isLastDataRefreshAccurate) {
    const daysBetween = enumerateDaysBetweenDates({
      startDate: lastDataRefresh,
      endDate: yesterdayDate,
      dateFormat: "D MMM",
      valueToIncrement: "days",
    })?.slice(1);

    const startDate = missingDatesString(daysBetween[0]?.split(" ")[0]);
    const endDate = missingDatesString(daysBetween[daysBetween?.length - 1]?.split(" ")[0]);

    if (daysBetween?.length === 1) {
      alertMessage = `Oops, data for the ${startDate} hasn't come through yet but don't worry we're looking into it`;
    } else {
      if (daysBetween?.length === 2) {
        missingDates = `${startDate} and ${endDate}`;
      } else if (daysBetween?.length > 2) {
        const startDateMonth = daysBetween[0]?.split(" ")[1];
        const endDateMonth = daysBetween[daysBetween?.length - 1]?.split(" ")[1];
        const showMonth = startDateMonth !== endDateMonth;

        missingDates = `${startDate} ${showMonth ? startDateMonth : ""} to the ${endDate} ${showMonth ? endDateMonth : ""}`;
      }

      alertMessage = `Oops, data for the ${missingDates} hasn't come through but don't worry we're looking into it`;
    }
  }

  return { isLastDataRefreshAccurate, alertMessage };
};

export const FY_TWENTY = enumerateDaysBetweenDates({
  startDate: "2020-04-01",
  endDate: "2021-03-31",
  dateFormat: "MMMM YYYY",
  valueToIncrement: "months",
});

export const getLast6Months = (isG5Market = false) => {
  const firstMonth = isG5Market ? moment().subtract(6, "months").format("MMM YY") : moment().subtract(5, "months").format("MMM YY");
  const lastMonth = isG5Market ? moment().subtract(1, "month").format("MMM YY") : moment().format("MMM YY");
  return `${firstMonth} - ${lastMonth}`;
};

export const getLast4quarters = () => {
  const quarterMonthEnd = moment(moment().fquarter().end).format("MMMM");
  const currentMonth = moment().format("MMMM");

  const firstQuarter = `${
    moment()
      .subtract(quarterMonthEnd === currentMonth ? 2 : 3, "quarter")
      .fquarter()
      .toString()
      .split(" ")[0]
  } ${moment().subtract(4, "quarter").fquarter().toString().split("/")[0].substr(-2)}`;

  const lastQuarter =
    quarterMonthEnd === currentMonth
      ? `${moment().add(1, "quarter").fquarter().toString().split(" ")[0]} ${moment()
          .add(1, "quarter")
          .fquarter()
          .toString()
          .split("/")[0]
          .substr(-2)}`
      : `${moment().fquarter().toString().split(" ")[0]} ${moment().fquarter().toString().split("/")[0].substr(-2)}`;
  return `${firstQuarter} - ${lastQuarter}`;
};

export const getLatestMonth = (dateString: string | null, dateFormat = "MMMM") => {
  if (!dateString) return "";

  if (isMonthlyCustomDate(dateString)) {
    const startEndValues = dateString.split("-");
    return moment(startEndValues[1], "MMMM YYYY").startOf("month").format(dateFormat);
  }

  const startEndValues = DATE_START_END_VALUES[dateString];
  if (startEndValues) {
    const { end } = DATE_START_END_VALUES[dateString];
    return dateString === "This financial year"
      ? moment(end, "DD/MM/YYYY").subtract(1, "month").startOf("month").format(dateFormat)
      : moment(end, "DD/MM/YYYY").startOf("month").format(dateFormat);
  }

  return "";
};
