import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg, NoDataMsg } from "../../../../components/AppMessages";
import TopModelsChart from "../../../../components/Charts/TopFlop/TopModelsChart";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { ThemeContext } from "../../../../context";

const TopModelsGraph = () => {
  const themeContext = useContext(ThemeContext);

  const data = useSelector((state: RootStateOrAny) => state.top_flop.top_models);
  const isLoading = useSelector((state: RootStateOrAny) => state.loading.top_flop_top_models);

  const graphData = useMemo(() => {
    const groupedData: any[] = [];
    const modelsDataGroupByDate: Record<string, any> = {};
    if (data) {
      data.forEach((model: any) => {
        const date = `${model?.week ? `Week ${model.week} ` : ""}${model.month_name} ${model.year}`;
        if (date in modelsDataGroupByDate) {
          modelsDataGroupByDate[date]["date"] = date;
          modelsDataGroupByDate[date][model.model] = model.events;
        } else {
          modelsDataGroupByDate[date] = {};
          modelsDataGroupByDate[date]["date"] = date;
          modelsDataGroupByDate[date][model.model] = model.events;
        }
      });
    }
    Object.values(modelsDataGroupByDate).forEach((item) => groupedData.push(item));
    return groupedData;
  }, [data]);

  const modelList: Array<string> = useMemo(() => {
    if (data) {
      return Array.from(new Set(data.flatMap((model: { model: string }) => model.model)));
    }
    return [];
  }, [data]);

  return (
    <>
      <div className="top_models">
        <h3>Top 5 models</h3>
        <div className="main_content">
          <ErrorBoundary fallback={<ErrorMsg />}>
            {graphData && graphData.length > 0 ? (
              <TopModelsChart data={graphData} seriesList={modelList} theme={themeContext.theme} />
            ) : isLoading ? null : (
              <NoDataMsg />
            )}
          </ErrorBoundary>
        </div>
        <LoadingEllipsis isLoading={isLoading} />
      </div>
    </>
  );
};

export default TopModelsGraph;
