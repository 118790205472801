import Cookies from "js-cookie";
import { updateHomePageUrl } from "../../../api";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";

export const setHomePage = () => {
  const pageName = document.getElementById("pageTitle")?.innerText;
  // 1. Send new url to backend
  const homePage = `${window.location.pathname}${window.location.search ? window.location.search : ""}`;
  const data = { home_page: homePage };
  updateHomePageUrl(data);

  eventTracking(MixpanelEvents.save_as_home_page, { homepage: homePage, page: pageName, dashboard: pageName });

  // 2. Set new homepage value in cookie
  Cookies.set("default_url", homePage, { secure: true, sameSite: "strict" });
  Cookies.set("default_params", window.location.search ? window.location.search : "", { secure: true, sameSite: "strict" });
};
