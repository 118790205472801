import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setSpecificParameter } from "../../../actions";
import { ErrorMsg } from "../../../components/AppMessages";
import { MultiSelectFilter } from "../../../components/Filters/common";
import { closeFilterOptions, closeMarketOptions } from "../../../components/Filters/subs/helpers";
import { FilterContext } from "../../../context";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import PmoTooltip from "../components/common/PmoTooltip";
import VolumeToggle from "../components/common/VolumeToggle";
import CustomerIntentionCluster from "../components/CustomerIntentionCluster";
import CustomerIntentionComparison from "../components/CustomerIntentionComparison";

interface Props {
  showVolumes: boolean;
  setShowVolumes: React.Dispatch<React.SetStateAction<boolean>>;
}

const NimTechSection = (props: Props) => {
  const [showVolumes, setShowVolumes] = useState<boolean>(true);
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);
  const dispatch = useDispatch();

  const gradeParamValue = useSelector((state: RootStateOrAny) => state.parameters.grade);
  const grades = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.nim_grades.grades);
  const [gradeParam, setGradeParam] = useState<string>(gradeParamValue);
  const [gradeFilterValue, setGradeFilterValue] = useState<string>("");

  useEffect(() => {
    const selectedGrades =
      grades?.length === gradeParamValue.split(",")?.length
        ? "All"
        : gradeParamValue.includes(",")
        ? `${gradeParamValue.split(",")?.length} grades selected`
        : gradeParamValue;
    setGradeParam(gradeParamValue);
    setGradeFilterValue(selectedGrades);
  }, [gradeParamValue, grades]);

  const showFilterOptionsFn = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const optionsId = dataset?.optionsUl;
      const shownClass = "showOptions";

      const optionsElement = optionsId ? document.getElementById(optionsId) : undefined;

      if (showFilterOptions && optionsElement?.classList.contains(shownClass)) {
        closeFilterOptions();
        closeMarketOptions();
        setShowFilterOptions(false);
      } else {
        closeFilterOptions();
        optionsElement?.classList.toggle(shownClass);
        setShowFilterOptions(!showFilterOptions);
      }
    },
    [showFilterOptions, setShowFilterOptions]
  );

  const handleGradesFilterSubmission = useCallback(() => {
    const selectedGradesCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="grade"][data-options-type="single"]:checked`
    ) as NodeListOf<HTMLInputElement>;
    const selectedGrades: Array<string> = [];
    selectedGradesCheckboxes?.forEach((checkbox) => selectedGrades.push(checkbox.value));

    closeFilterOptions();
    const value = grades?.length === selectedGrades?.length ? "All" : selectedGrades.join(",");
    dispatch(setSpecificParameter("grade", value));

    eventTracking(MixpanelEvents.filter_change, { filter: "grade", value, dashboard: "PMO" });
  }, [grades, gradeParam, gradeFilterValue]);

  const TooltipParagraphs = () => (
    <>
      <p>
        <span style={{ fontWeight: "bold" }}>Customer intention</span> is based on the volume of configurations completed by visitors
        online.
      </p>
      <p>
        <u>Note</u>: visitors are able to complete more than one configuration, and can add to the volume of completed configurations.
      </p>
      <p>
        <u>Source</u>: Nissan website data from Adobe Analytics
      </p>
    </>
  );

  return (
    <div className="nim_tech primary_tile_background">
      <div className="header" style={{ width: "42%", padding: "1.5em" }}>
        <div className="title_container">
          <h3>NIM tech item Customer intention</h3>
          <PmoTooltip paragraphs={<TooltipParagraphs />} />
        </div>

        <VolumeToggle showVolumes={showVolumes} setShowVolumes={setShowVolumes} nimTech />
      </div>

      <div className="chart_content">
        <div className="model_filter" style={{ position: "absolute", right: "20px", marginTop: "-3.8em" }}>
          <ErrorBoundary fallback={<ErrorMsg />}>
            <MultiSelectFilter
              parentKey="grade"
              // @ts-ignore
              filterList={grades?.length ? grades : []}
              filterName="Select grades"
              value={gradeFilterValue}
              parameterName={"grade"}
              parameterValue={gradeParamValue}
              onShowFilterOptions={showFilterOptionsFn}
              handleFilterSubmission={handleGradesFilterSubmission}
            />
          </ErrorBoundary>
        </div>

        <ErrorBoundary fallback={<ErrorMsg />}>
          <CustomerIntentionCluster nimTech={true} showVolumes={showVolumes} />
        </ErrorBoundary>
        {showVolumes ? <CustomerIntentionComparison nimTech={true} showVolume={true} /> : null}
      </div>
    </div>
  );
};

export default NimTechSection;
