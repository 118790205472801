import React from "react";
import { NoDataMsg } from "../../../components/AppMessages";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { MediaMixShareTable } from "../../../components/Tables";
import StrategicDSTable from "../../../components/Tables/StrategicDSTable";

interface Props {
  loading: boolean;
  data: any;
  quarterTitle: string;
  yrScenarios: string[];
  totals: any;
  quarters: number[];
  extraClass?: string;
}

const TableBreakDown = (props: Props) => {
  const { loading, data, extraClass, quarterTitle, totals, yrScenarios, quarters } = props;
  return (
    <div className={`${extraClass} quarter_breakdown`}>
      {quarterTitle?.includes("1") && <div className="divider strategic"></div>}
      {data?.length && !loading ? (
        <>
          <span className="quarter_title" style={{ marginRight: `${quarterTitle?.includes("1") ? "-55%" : ""}` }}>
            {quarterTitle}
          </span>
          {extraClass?.includes("mediaMix") ? (
            <MediaMixShareTable
              tableId={`mediaMix${quarterTitle?.length ? quarterTitle : "Full combined strategic"}`}
              tableData={[totals, ...data]}
              keys={yrScenarios}
              quarters={quarters}
              tableClass={extraClass}
            />
          ) : (
            <StrategicDSTable
              tableId={`mediaMix${quarterTitle?.length ? quarterTitle : "Full combined strategic"} `}
              tableData={[totals, ...data]}
              keys={yrScenarios}
              quarters={quarters}
              tableClass={extraClass}
            />
          )}
        </>
      ) : loading ? (
        <LoadingEllipsis isLoading={loading} />
      ) : (
        <NoDataMsg message="No data available" />
      )}
    </div>
  );
};

export default TableBreakDown;
