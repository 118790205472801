import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { Dispatch, SetStateAction, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { DigitalLeadsTrendGraph, KbaVisitsTrendGraph } from ".";
import "../../../assets/styles/component/baselineTrend.scss";
import { ErrorMsg } from "../../../components/AppMessages";
import { KbaBreakdownTable, LeadBreakdownTable } from "../../../components/Tables";
interface Props {
  dateTitleValue: string;
  dateTitleDescription: string;
  granularity: string;
  metric: string;
  showPop: boolean;
  popLabel: string;
  specificMetric: string;
  isCpoDashboard?: boolean;
  onHandleRadioClick: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onHandleSpecificMetricClick: (evt: React.MouseEvent<HTMLElement>) => void;
  toggleAnnotationsDialog: () => void;
  setAnnotationStartDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationEndDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationDefaultMetric: Dispatch<SetStateAction<string>>;
  setAnnotationDefaultMetricType: Dispatch<SetStateAction<string | undefined>>;
}

export const MetricTrend = (props: Props): JSX.Element => {
  const {
    dateTitleValue,
    dateTitleDescription,
    granularity,
    metric,
    isCpoDashboard,
    onHandleRadioClick,
    showPop,
    popLabel,
    onHandleSpecificMetricClick,
    specificMetric,
    toggleAnnotationsDialog,
    setAnnotationEndDate,
    setAnnotationStartDate,
    setAnnotationDefaultMetric,
    setAnnotationDefaultMetricType,
  } = props;

  const kbaGraphIsLoading = useSelector((state: RootStateOrAny) => state.loading.kba_trend_graph);
  const leadGraphIsLoading = useSelector((state: RootStateOrAny) => state.loading.lead_trend_graph);
  const kbaTableIsLoading = useSelector((state: RootStateOrAny) => state.loading.kba_trend_table);
  const leadTableIsLoading = useSelector((state: RootStateOrAny) => state.loading.lead_trend_table);

  const newTrendBreakdownTotal = useSelector((state: RootStateOrAny) => state.digital_performance.new_key_indicator_totals.data);
  const cpoBreakdownTotal = useSelector((state: RootStateOrAny) => state.cpo_digital_performance.key_indicator_all);

  const period = useMemo(() => {
    if (dateTitleValue == "last week") {
      const startDate = moment(isCpoDashboard ? cpoBreakdownTotal?.start_date : newTrendBreakdownTotal?.start_date).format("DD-MMM");
      const endDate = moment(isCpoDashboard ? cpoBreakdownTotal?.end_date : newTrendBreakdownTotal?.end_date).format("DD-MMM");

      return `(${startDate} to ${endDate} )`;
    }
    return dateTitleDescription;
  }, [newTrendBreakdownTotal, cpoBreakdownTotal, dateTitleValue]);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className="metric_tiles">
        <div id="visits_trend_graph" data-test-id={"visits_trend_graph"} className={"graphArea"}>
          <ErrorBoundary fallback={<ErrorMsg />}>
            {metric === "kbas" ? (
              <KbaVisitsTrendGraph
                isCpoDashboard={isCpoDashboard}
                granularity={granularity}
                onHandleRadioClick={onHandleRadioClick}
                specificMetric={specificMetric}
                metric={metric}
                toggleAnnotationsDialog={toggleAnnotationsDialog}
                setAnnotationEndDate={setAnnotationEndDate}
                setAnnotationStartDate={setAnnotationStartDate}
                setAnnotationDefaultMetric={setAnnotationDefaultMetric}
                setAnnotationDefaultMetricType={setAnnotationDefaultMetricType}
              />
            ) : (
              <DigitalLeadsTrendGraph
                isCpoDashboard={isCpoDashboard}
                granularity={granularity}
                onHandleRadioClick={onHandleRadioClick}
                specificMetric={specificMetric}
                metric={metric}
                toggleAnnotationsDialog={toggleAnnotationsDialog}
                setAnnotationEndDate={setAnnotationEndDate}
                setAnnotationStartDate={setAnnotationStartDate}
                setAnnotationDefaultMetric={setAnnotationDefaultMetric}
                setAnnotationDefaultMetricType={setAnnotationDefaultMetricType}
              />
            )}
          </ErrorBoundary>
        </div>

        <div id="metric_trend_table" className={"tableArea"}>
          <div className="metric_title">
            <h3>
              Breakdown for {dateTitleValue} <span className="period_updatable">{period}</span>
            </h3>
          </div>

          <div className="main_content">
            <ErrorBoundary fallback={<ErrorMsg />}>
              {metric === "kbas" ? (
                <KbaBreakdownTable
                  isCpoDashboard={isCpoDashboard}
                  showPop={showPop}
                  popLabel={popLabel}
                  onClickSpecificMetricClick={onHandleSpecificMetricClick}
                  specificMetric={specificMetric}
                />
              ) : (
                <LeadBreakdownTable
                  isCpoDashboard={isCpoDashboard}
                  showPop={showPop}
                  popLabel={popLabel}
                  onClickSpecificMetricClick={onHandleSpecificMetricClick}
                  specificMetric={specificMetric}
                />
              )}
            </ErrorBoundary>
          </div>
        </div>
        {/* <LoadingEllipsis isLoading={kbaTableIsLoading || leadTableIsLoading} className={"metric_table_overlay"} />
        <LoadingEllipsis isLoading={kbaGraphIsLoading || leadGraphIsLoading} className={"metric_graph_overlay"} /> */}
      </div>
    </ErrorBoundary>
  );
};
