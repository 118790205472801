import { Box, Button, Grid } from "@material-ui/core";
import React, { useCallback } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { formatToThreeDigits } from "../../../../../utils/numberFormatting";
import { convertToPercentage, isNull, plusSignNumbers } from "../../../../../utils/utilityFunctions";
import commonStyles from "../../../ariya.module.scss";
import styles from "./card.module.scss";

export interface MonitorCardProps {
  cardTitle?: string;
  metric: { title: string; value: number | null; lastDayValue: number | null; legend?: "dotted" | "solid" };
  metric2?: { title: string; value: number | null; lastDayValue: number | null; legend?: "dotted" | "solid" };
  split?: Array<{
    title: string;
    value: number | null;
    percentageTrend?: number | null;
    market?: string;
    btn?: { page: string; text: string; onClick: (evt: React.MouseEvent<HTMLElement>) => void };
  }>;
  description?: string;
  chart: React.ReactElement;
  extraClass?: any;
  isMarketSplit?: boolean;
  clickable?: { page: string; onClick: (evt: React.MouseEvent<HTMLElement>) => void };
  marketBreakdownPath?: string;
  hideMore?: boolean;
  spanBy?: number;
  withChartHeight?: boolean;
}

interface TitleProps {
  text: string;
  value?: number | null;
  lastDay?: number | null;
  legend?: string;
}

const marketStyles: Record<string, string> = {
  GB: styles.GB,
  DE: styles.DE,
  NO: styles.NO,
  JP: styles.JP,
  ITA: styles.ITA,
  SWE: styles.SWE,
  BE: styles.BE,
};

export const CardTitle = (titleProps: TitleProps) => {
  const { lastDay: tempLastDay, text, legend, value } = titleProps;
  const lastDay = tempLastDay ?? 0;
  return (
    <div className={styles.titleContent}>
      <div className={styles.headerContent}>
        <h2 data-test-id="title" className={text}>
          {text}
        </h2>
        {legend && <span className={styles[legend]} />}
      </div>

      {(value || value === 0) && (
        <div className="mainNumberContainer">
          <p data-test-id="main_number" className={styles.mainNumber}>
            {isNull(value) ? "-" : Number(value).toLocaleString()}
          </p>
        </div>
      )}

      {(lastDay || lastDay === 0) && (
        <div className="trendContainer">
          <p data-test-id="trend" className={`${styles.trend} secondaryFont`}>
            {isNull(lastDay) ? "-" : plusSignNumbers(Number(lastDay))} in the last day
          </p>
        </div>
      )}
    </div>
  );
};

export const Card = withRouter((props: MonitorCardProps & RouteComponentProps) => {
  const {
    metric,
    metric2,
    split,
    chart,
    description,
    extraClass,
    isMarketSplit,
    clickable,
    marketBreakdownPath,
    history,
    hideMore,
    cardTitle,
    spanBy,
    withChartHeight = false,
  } = props;

  const pageNavigation = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      const {
        currentTarget: { dataset },
      } = evt;
      const pageLink = dataset?.page;

      history.push({ pathname: pageLink, search: "" });
    },
    [history]
  );

  return (
    <Box
      gridColumn={`span ${spanBy ? spanBy : 4}`}
      className={`${styles.card}  ${extraClass ? extraClass : ""}`}
      onClick={clickable?.onClick}
      data-page={clickable?.page}
    >
      {cardTitle && <h2 className={styles.cardTitle}>{cardTitle}</h2>}

      <div className={styles.titleContainer}>
        <CardTitle text={metric.title} value={metric.value} lastDay={metric.lastDayValue} legend={metric.legend} />
        {metric2 && <CardTitle text={metric2.title} value={metric2.value} lastDay={metric2.lastDayValue} legend={metric2.legend} />}
      </div>

      <div className={`${withChartHeight ? styles.chartHeight : styles.chart}`}>{chart}</div>

      {split && (
        <div className={styles.regionSplitContainer} data-test-id={isMarketSplit ? "marketSplit" : "regionSplit"}>
          <h3 className={`${styles.regionSplitHeader} secondaryFont`}>{isMarketSplit ? "Market split" : "Region split"}</h3>

          <Grid container spacing={3} justifyContent="space-evenly">
            {split.map(({ title, value, percentageTrend, market, btn }) => (
              <Grid item xs={"auto"} data-test-id={title} key={title}>
                {isMarketSplit && market ? (
                  <div className={`${styles.marketFlag} ${market in marketStyles ? marketStyles[market] : ""}`} />
                ) : (
                  <div className={styles.regionSplitTitle}>{title}</div>
                )}
                <div className={styles.regionSplitValue}>
                  {isNull(value) ? "-" : Number(value) > 100000 ? formatToThreeDigits(Number(value)) : Number(value).toLocaleString()}
                </div>
                {percentageTrend && (
                  <div className={styles.regionSplitTrend}>({isNull(percentageTrend) ? "-" : convertToPercentage(percentageTrend, 0)})</div>
                )}

                {btn && (
                  <Button
                    variant={"outlined"}
                    className={`${commonStyles.btn} ${styles.btn}`}
                    onClick={btn.onClick}
                    size={"small"}
                    data-page={btn.page}
                  >
                    {btn.text}
                  </Button>
                )}
              </Grid>
            ))}

            {isMarketSplit && !hideMore && (
              <Grid item xs={3} data-test-id="flagIcon" data-page={marketBreakdownPath} onClick={pageNavigation}>
                <div className={styles.moreIcon} />
                <div className={styles.moreText}>more</div>
              </Grid>
            )}
          </Grid>
        </div>
      )}
      {description && (
        <div className={styles.cardDescriptionContainer}>
          <p className={`${styles.cardDescription} secondaryFont`}>{description}</p>
        </div>
      )}
    </Box>
  );
});

export default Card;
