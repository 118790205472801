import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useMemo, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg } from "../../../../components/AppMessages";
import { InAppMultiSelectFilter } from "../../../../components/Filters/common/InAppMultiselectFilter";
import { closeFilterOptions } from "../../../../components/Filters/subs/helpers";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { DefaultTable } from "../../../../components/Tables/subs";
import { ToggleSwitch } from "../../../../components/Toogle";

const verbatimLoading = false;

const VerbatimTable = () => {
  const { self_service_topic_classification_verbatim_data } = useSelector((state: RootStateOrAny) => state.use_ownership);
  const [toggleValue, setToggleValue] = useState(true);

  const [touchpointFilterValue, setTouchpointFilterValue] = useState("All");
  const [topicFilterValue, setTopicFilterValue] = useState("All");

  const columns = useMemo(
    () => [
      {
        id: "market",
        Header: "Market",
        accessor: "market",
      },
      {
        id: "date",
        Header: "Date",
        accessor: "date",
      },
      {
        id: "topic",
        Header: "Topic",
        accessor: "topic",
      },
      {
        id: "touchpoint",
        Header: "Touchpoints",
        accessor: "touchpoint",
      },
      {
        id: "subcategory",
        Header: "Subcategory",
        accessor: "sub_category",
        Cell: (cell: { value: string }) => {
          const { value } = cell;
          return value ? value : "n/a";
        },
      },
      {
        id: "url",
        Header: "URL",
        accessor: "url",
        Cell: (cell: { value: string }) => {
          const { value } = cell;
          return (
            <a href={value} target="_blank" rel="noreferrer" className="link">
              {value}
            </a>
          );
        },
      },
      {
        id: "devicetype",
        Header: "Device type",
        accessor: "device_type",
      },
      {
        id: "comment",
        Header: "Comment",
        accessor: `${toggleValue ? "verbatim_concat_en" : "verbatim_concat"}`,
      },
    ],
    [toggleValue]
  );

  const touchpointList = useMemo(() => {
    if (self_service_topic_classification_verbatim_data) {
      const touchpoints: Set<string> = new Set(
        self_service_topic_classification_verbatim_data.map((item: { touchpoint: string }) => item.touchpoint)
      );
      return Array.from(touchpoints);
    }
    return [];
  }, [self_service_topic_classification_verbatim_data]);

  const topicList = useMemo(() => {
    if (self_service_topic_classification_verbatim_data) {
      const topics: Set<string> = new Set(self_service_topic_classification_verbatim_data.map((item: { topic: string }) => item.topic));
      return Array.from(topics);
    }
    return [];
  }, [self_service_topic_classification_verbatim_data]);

  const totalVerbatimCount = useMemo(() => {
    if (self_service_topic_classification_verbatim_data && self_service_topic_classification_verbatim_data.length > 0) {
      return self_service_topic_classification_verbatim_data[0].verbatim_concat_count;
    }
    return null;
  }, [self_service_topic_classification_verbatim_data]);

  const handleTouchpointMultiselectSubmit = useCallback(() => {
    const parameterName = "verbatim_touchpoint";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = touchpointList?.length === selectedValues?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    setTouchpointFilterValue(filterValue);
  }, [touchpointList]);

  const handleTopicMultiselectSubmit = useCallback(() => {
    const parameterName = "verbatim_topic";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = topicList?.length === selectedValues?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    setTopicFilterValue(filterValue);
  }, [topicList]);

  const filteredTopicVerbatimData = useMemo(() => {
    if (self_service_topic_classification_verbatim_data) {
      let data = [...self_service_topic_classification_verbatim_data];
      data = data.filter(
        (item: any) =>
          (touchpointFilterValue == "All" ? true : touchpointFilterValue.includes(item.touchpoint)) &&
          (topicFilterValue == "All" ? true : topicFilterValue.includes(item.topic))
      );
      return data;
    }
    return [];
  }, [touchpointFilterValue, topicFilterValue, self_service_topic_classification_verbatim_data]);

  return (
    <div className="tile verbatim-tile">
      <div className="space-between">
        <div className="tileHeader">
          <p>Verbatim</p>
        </div>
        <div className="flex-row">
          <div className="verbatim-count">Total number of verabtims: {totalVerbatimCount ? totalVerbatimCount : "n/a"}</div>
          <ErrorBoundary fallback={<ErrorMsg />}>
            <InAppMultiSelectFilter
              filterLabel="Select Topics"
              optionsList={topicList}
              filterValue={topicFilterValue}
              parameterName={`verbatim_topic`}
              parameterValue={topicFilterValue}
              handleFilterSubmission={handleTopicMultiselectSubmit}
            />
          </ErrorBoundary>
          <ErrorBoundary fallback={<ErrorMsg />}>
            <InAppMultiSelectFilter
              filterLabel="Select Touchpoints"
              optionsList={touchpointList}
              filterValue={touchpointFilterValue}
              parameterName={`verbatim_touchpoint`}
              parameterValue={touchpointFilterValue}
              handleFilterSubmission={handleTouchpointMultiselectSubmit}
            />
          </ErrorBoundary>
          <ToggleSwitch
            activeToggleLabel={"English"}
            inactiveToggleLabel={"Native"}
            active={toggleValue}
            handleToggleClick={() => setToggleValue((prevState) => !prevState)}
          />
        </div>
      </div>
      <div className="table-container">
        {verbatimLoading ? (
          <LoadingEllipsis isLoading={verbatimLoading} />
        ) : (
          <DefaultTable
            columns={columns}
            data={filteredTopicVerbatimData}
            id={"topicVerbatimTable"}
            isChildComponent={false}
            initialSortColId={{ id: "market", desc: false }}
            hiddenCols={[]}
            extraClass="verbatimTable"
            showPagination={true}
            pageSize={50}
          />
        )}
      </div>
    </div>
  );
};

export default VerbatimTable;
