import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg, NoDataMsg } from "../../../../components/AppMessages";
import RegionsChart from "../../../../components/Charts/TopFlop/RegionsChart";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { ThemeContext } from "../../../../context";

const RegionsGraph = () => {
  const themeContext = useContext(ThemeContext);

  const data = useSelector((state: RootStateOrAny) => state.top_flop.top_regions);
  const isLoading = useSelector((state: RootStateOrAny) => state.loading.top_flop_top_regions);

  const graphData = useMemo(() => {
    const groupedData: any[] = [];
    const regionsDataGroupByDate: Record<string, any> = {};
    if (data) {
      data.forEach((market: any) => {
        const date = `${market?.week ? `Week ${market.week} ` : ""}${market.month_name} ${market.year}`;
        if (date in regionsDataGroupByDate) {
          regionsDataGroupByDate[date]["date"] = date;
          regionsDataGroupByDate[date][market.market] = market.events;
        } else {
          regionsDataGroupByDate[date] = {};
          regionsDataGroupByDate[date]["date"] = date;
          regionsDataGroupByDate[date][market.market] = market.events;
        }
      });
    }
    Object.values(regionsDataGroupByDate).forEach((item) => groupedData.push(item));
    groupedData.sort((a, b) => {
      if ((a.month < b.month && a.year == b.year) || a.year < b.year) {
        return -1;
      }
      return 0;
    });
    return groupedData;
  }, [data]);

  const regionList: Array<string> = useMemo(() => {
    if (data) {
      return Array.from(new Set(data.flatMap((region: { market: string }) => region.market)));
    }
    return [];
  }, [data]);

  return (
    <>
      <div className="regions_chart">
        <h3>Usage by region</h3>
        <div className="main_content">
          <ErrorBoundary fallback={<ErrorMsg />}>
            {graphData && graphData.length > 0 ? (
              <RegionsChart data={graphData} seriesList={regionList} theme={themeContext.theme} />
            ) : isLoading ? null : (
              <NoDataMsg />
            )}
          </ErrorBoundary>
        </div>
        <LoadingEllipsis isLoading={isLoading} />
      </div>
    </>
  );
};

export default RegionsGraph;
