import {
  FETCH_AVERAGE_MODEL,
  FETCH_CUSTOMER_INTENTIONSVSSALE_POWER,
  FETCH_CUSTOMER_INTENTION_BY_COLOR,
  FETCH_CUSTOMER_INTENTION_VS_SALES,
  FETCH_GRADE_MIX,
  FETCH_LEADS_FUNNEL,
  FETCH_LEAD_TO_TEST_DRIVE_REQUESTS,
  FETCH_MARKEITNG_ACTIVITY,
  FETCH_MEDIA_SPEND,
  FETCH_MEDIA_SPEND_TOTALS,
  FETCH_NIM_CUSTOMER_INTENTION_VS_SALES,
  FETCH_NIM_GRADES,
  FETCH_NIM_SALES_BY_TREND,
  FETCH_PRICE_VOLUME_SALES_DATA,
  FETCH_PRICE_VOLUME_TREND_DATA,
  FETCH_PRODUCT_MARKETING_OPTIMIZATION_FILTERS,
  FETCH_REGIONAL_COLOR_TREND_DATA,
  FETCH_RETAIL_SALES,
  FETCH_SALES_BY_TREND_DATA,
  FETCH_SEARCH_INTEREST_VS_FMI_DATA,
  FETCH_SOV_DATA,
  FETCH_TEST_DRIVE_REQUESTS,
  FETCH_TOTALS_VISITS_DATA,
  FETCH_TOTAL_LEADS,
  FETCH_WEB_VISITS_BY_CHANNEL,
  SET_AVERAGE_MODEL,
  SET_CUSTOMER_INTENTIONSVSSALE_POWER,
  SET_CUSTOMER_INTENTION_BY_COLOR,
  SET_CUSTOMER_INTENTION_VS_SALES,
  SET_GRADE_MIX,
  SET_LEADS_FUNNEL,
  SET_LEAD_TO_TEST_DRIVE_REQUESTS,
  SET_MARKEITNG_ACTIVITY,
  SET_MEDIA_SPEND,
  SET_MEDIA_SPEND_TOTALS,
  SET_NIM_CUSTOMER_INTENTION_VS_SALES,
  SET_NIM_GRADES,
  SET_NIM_SALES_BY_TREND,
  SET_PRICE_VOLUME_SALES_DATA,
  SET_PRICE_VOLUME_TREND_DATA,
  SET_PRODUCT_MARKETING_OPTIMIZATION_FILTERS,
  SET_REGIONAL_COLOR_TREND_DATA,
  SET_RETAIL_SALES,
  SET_SALES_BY_TREND_DATA,
  SET_SEARCH_INTEREST_VS_FMI_DATA,
  SET_SOV_DATA,
  SET_TEST_DRIVE_REQUESTS,
  SET_TOTALS_VISITS_DATA,
  SET_TOTAL_LEADS,
  SET_WEB_VISITS_BY_CHANNEL,
} from "./actionTypes";

export const fetchProductMarketingOptimizationFilters = (brand: string) => ({
  type: FETCH_PRODUCT_MARKETING_OPTIMIZATION_FILTERS,
  payload: brand,
});
export const setProductMarketingOptimizationFilters = (data: any) => ({ type: SET_PRODUCT_MARKETING_OPTIMIZATION_FILTERS, payload: data });

export const fetchCustomerIntentionVsSalesData = () => ({ type: FETCH_CUSTOMER_INTENTION_VS_SALES });
export const setCustomerIntentionVsSalesData = (data: any) => ({ type: SET_CUSTOMER_INTENTION_VS_SALES, payload: data });

export const fetchSovData = (mediaGroups?: string) => ({ type: FETCH_SOV_DATA, payload: mediaGroups });
export const setSovData = (data: any) => ({ type: SET_SOV_DATA, payload: data });

export const fetchPriceVolumeSalesData = (priceType: string) => ({ type: FETCH_PRICE_VOLUME_SALES_DATA, payload: priceType });
export const setPriceVolumeSalesData = (data: any) => ({ type: SET_PRICE_VOLUME_SALES_DATA, payload: data });

export const fetchAverageModel = () => ({ type: FETCH_AVERAGE_MODEL });
export const setAverageModel = (data: any) => ({ type: SET_AVERAGE_MODEL, payload: data });

export const fetchSearchInterestVsFMIData = () => ({ type: FETCH_SEARCH_INTEREST_VS_FMI_DATA });
export const setSearchInterestVsFMIData = (data: any) => ({ type: SET_SEARCH_INTEREST_VS_FMI_DATA, payload: data });

export const fetchSalesByTrendData = () => ({ type: FETCH_SALES_BY_TREND_DATA });
export const setSalesByTrendData = (data: any) => ({ type: SET_SALES_BY_TREND_DATA, payload: data });

export const fetchPriceVolumeTrendData = () => ({ type: FETCH_PRICE_VOLUME_TREND_DATA });
export const setPriceVolumeTrendData = (data: any) => ({ type: SET_PRICE_VOLUME_TREND_DATA, payload: data });

export const fetchMediaSpendData = () => ({ type: FETCH_MEDIA_SPEND });
export const setMediaSpendData = (data: any) => ({ type: SET_MEDIA_SPEND, payload: data });

export const fetchMediaSpendTotalsData = () => ({ type: FETCH_MEDIA_SPEND_TOTALS });
export const setMediaSpendTotalsData = (data: any) => ({ type: SET_MEDIA_SPEND_TOTALS, payload: data });

export const fetchTotalsVisitsData = () => ({ type: FETCH_TOTALS_VISITS_DATA });
export const setTotalsVisitsData = (data: any) => ({ type: SET_TOTALS_VISITS_DATA, payload: data });

export const fetchLeadsFunnelData = () => ({ type: FETCH_LEADS_FUNNEL });
export const setLeadsFunnelData = (data: any) => ({ type: SET_LEADS_FUNNEL, payload: data });

export const fetchTotalLeadsData = () => ({ type: FETCH_TOTAL_LEADS });
export const setTotalLeadsData = (data: any) => ({ type: SET_TOTAL_LEADS, payload: data });

export const fetchWebVisitsByChannelData = () => ({ type: FETCH_WEB_VISITS_BY_CHANNEL });
export const setWebVisitsByChannelData = (data: any) => ({ type: SET_WEB_VISITS_BY_CHANNEL, payload: data });

export const fetchLeadToTestDriveRequestsData = () => ({ type: FETCH_LEAD_TO_TEST_DRIVE_REQUESTS });
export const setLeadToTestDriveRequestsData = (data: any) => ({ type: SET_LEAD_TO_TEST_DRIVE_REQUESTS, payload: data });

export const fetchTestDriveRequestsData = () => ({ type: FETCH_TEST_DRIVE_REQUESTS });
export const setTestDriveRequestsData = (data: any) => ({ type: SET_TEST_DRIVE_REQUESTS, payload: data });

export const fetchRetailSalesData = () => ({ type: FETCH_RETAIL_SALES });
export const setRetailSalesData = (data: any) => ({ type: SET_RETAIL_SALES, payload: data });

export const fetchGradeMixData = () => ({ type: FETCH_GRADE_MIX });
export const setGradeMixData = (data: any) => ({ type: SET_GRADE_MIX, payload: data });

export const fetchMarketingActivityData = () => ({ type: FETCH_MARKEITNG_ACTIVITY });
export const setMarketingActivityData = (data: any) => ({ type: SET_MARKEITNG_ACTIVITY, payload: data });

export const fetchRegionalColorTrendData = () => ({ type: FETCH_REGIONAL_COLOR_TREND_DATA });
export const setRegionalColorTrendData = (data: any) => ({ type: SET_REGIONAL_COLOR_TREND_DATA, payload: data });

export const fetchCustomerIntentionByColorData = () => ({ type: FETCH_CUSTOMER_INTENTION_BY_COLOR });
export const setCustomerIntentionByColorData = (data: any) => ({ type: SET_CUSTOMER_INTENTION_BY_COLOR, payload: data });

export const fetchCustomerIntentionsVsSalePower = () => ({ type: FETCH_CUSTOMER_INTENTIONSVSSALE_POWER });
export const setCustomerIntentionsVsSalePower = (data: any) => ({ type: SET_CUSTOMER_INTENTIONSVSSALE_POWER, payload: data });

export const fetchNimSalesByTrend = () => ({ type: FETCH_NIM_SALES_BY_TREND });
export const setNimSalesByTrend = (data: any) => ({ type: SET_NIM_SALES_BY_TREND, payload: data });

export const fetchNimCustomerIntentionVsSales = () => ({ type: FETCH_NIM_CUSTOMER_INTENTION_VS_SALES });
export const setNimCustomerIntentionVsSales = (data: any) => ({ type: SET_NIM_CUSTOMER_INTENTION_VS_SALES, payload: data });

export const fetchNimGrades = () => ({ type: FETCH_NIM_GRADES });
export const setNimGrades = (data: any) => ({ type: SET_NIM_GRADES, payload: data });
