export const DEFAULT_ANNOTATION_PARAMETERS = {
  online_ce_performance: {
    brands: [],
    markets: [],
    regions: [],
    models: [],
    channels: [],
    channelBreakdowns: [],
    metrics: [],
  },
  purchase_funnel: {
    brands: [],
    markets: [],
    lead_sources: [],
    metrics: [],
  },
  mtm_metrics_that_matter: {
    brands: [],
    markets: [],
    metrics: [],
  },
  admin: {
    brands: [],
    markets: [],
    regions: [],
    models: [],
    channels: [],
    channelBreakdowns: [],
    metrics: [],
  },
};

export const ANNOTATION_QUERY_PARAMS = {
  online_ce_performance: [
    "date_range",
    "brand",
    "group",
    "region",
    "market",
    "model",
    "last_touch_marketing_channel",
    "last_touch_marketing_channel_breakdown",
    "mobile_device_type",
    "metric",
    "app_store",
    "tag",
    "app",
    "granularity",
  ],
  purchase_funnel: ["date_range", "brand", "market", "lead_source"],
  mtm_metrics_that_matter: ["date_range", "market", "model"],
};

export const DASHBOARD_URLS: Record<string, string> = {
  online_ce_performance: "/digital_performance",
  purchase_funnel: "/purchase_funnel",
  mtm_metrics_that_matter: "/mtm_metrics",
};
