export const TOTAL_VEHICLE_PRODUCED_DEFINITION = [
  "Total number of vehicles produced (connectivity grade). Only current Nissan AvK models.",
];
export const PRODUCED_ELIGIBLE_CONNECTED_CARS_DEFINITION = ["Total number of connected eligible vehicle produced."];
export const BOOTSTRAPPED_DEFINITION = [
  "All the steps of validation between Vnext & Kamereon are successfully achieved for IVC & IVI. Vehicle ready for services activation. This is mainly done in dealers today.",
];
export const CONNECTIVITY_READY_DEFINITION = [
  "Services considered as activated when all the steps of validation are successfully achieved. This is mainly done during vehicle preparation before being sold.",
  "In the CCS Cockpit view this metric is referred to as 'Connected cars activated'",
];
export const CONNECTED_SOLD_ELIGIBLE_DEFINITION = [
  "Total connected vehicle sold. The number can be higher than the number of cars produced due to the stock effect.",
];
export const ENROLLED_CUSTOMERS_DEFINITION = ["Users successfully paired his vehicle with NissanConnect app."];
export const SERVICES_ACTIVATED_DEFINITION = ["Services activated after user pairing with NissanConnect app."];

//STRING VALUES
export const TOTAL_VEHICLE_PRODUCED_DEFINITION_STRING = [
  "Total number of vehicles produced of models that have atleast one IVC connected eligible trim level.",
  "Total number of vehicles produced regardless if equipped with IVC or not.",
];
export const PRODUCED_ELIGIBLE_CONNECTED_CARS_DEFINITION_STRING = [
  "Production volume of IVC connected eligible vehicles.",
  "Production volume of IVC connected eligible vehicles.",
];
export const BOOTSTRAPPED_DEFINITION_STRING =
  "All the steps of validation between Vnext & Kamereon are successfully achieved for IVC & IVI. Vehicle ready for services activation. This is mainly done in dealers today.";
export const CONNECTIVITY_READY_DEFINITION_STRING =
  "Services considered as activated when all the steps of validation are successfully achieved. This is mainly done during vehicle preparation before being sold. In the CCS Cockpit view this metric is referred to as 'Connected cars activated'";

export const CONNECTED_SOLD_ELIGIBLE_DEFINITION_STRING =
  "Total connected vehicle sold. The number can be higher than the number of cars produced due to the stock effect.";
export const TOTAL_CARS_SOLD_STRING = [
  "Total number of vehicles sold of models that have atleast one IVC connected eligible trim level.",
  "Total number of vehicles sold regardless if equipped with IVC or not.",
];
export const ENROLLED_CUSTOMERS_DEFINITION_STRING = "Users successfully paired his vehicle with NissanConnect app.";
export const SERVICES_ACTIVATED_DEFINITION_STRING = "Services activated after user pairing with NissanConnect app.";
export const WARRANTY_START_DATE_DEFINITION_STRING =
  "Number of eligible cars (equipped with A-IVC) with Warranty Start date (WSD) during period";
