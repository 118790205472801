import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import ShowRoomChart from "../../../components/Charts/DfpDashboard/ShowRoomChart";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { ThemeContext } from "../../../context";

const GradeMixGraph = () => {
  const themeContext = useContext(ThemeContext);

  const { date_range: dateRangeParam, granularity } = useSelector((state: RootStateOrAny) => state.parameters);
  const loading = useSelector((state: RootStateOrAny) => state.loading.grade_mix);
  const data = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.grade_mix);

  const { market: marketParamValue } = useSelector((state: RootStateOrAny) => state.parameters);
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const ref = useRef<any>(null);
  const [highestValue, setHighestValue] = useState<number>(0);

  const seriesList: string[] = useMemo(() => {
    if (data?.length) return Array.from(new Set(data?.map((val: { grade: string }) => val.grade)));
    return [];
  }, [data]);

  const chartData = useMemo(() => {
    let max = -Infinity;
    if (data?.length)
      return data?.map((val: { grade: string; quarter: string; date: string; configuration_volume: string }) => {
        const result: any = { date: val.date, grade: val.grade, quarter: val.quarter };
        result[`${val.grade}_volume`] = val.configuration_volume;
        if (Number(val.configuration_volume) > max) max = Number(val.configuration_volume);
        setHighestValue(max);
        return result;
      });
    return [];
  }, [data]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (tooltipVisible && ref.current != null && !ref.current.contains(e.target)) {
        setTooltipVisible(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [tooltipVisible]);

  return (
    <div className="grade_mix primary_background">
      <div className="dfp_tile_header">
        <div className="title">
          <h3>Customer intention by grade</h3>
          <Tooltip
            position="right"
            className="customer_intention_tooltip"
            html={
              <div ref={ref}>
                <p>
                  <span style={{ fontWeight: "bold" }}>Customer intention</span> is based on the volume of the configurations completed by
                  visitors online.
                </p>
                <p>
                  <u>Note</u>: visitors are able to complete more than one configuration, and can add to the volume of complete
                  configurations.
                </p>
                <p>
                  <u>Source</u>: Nissan website data from Adobe Analytics
                </p>
                <br />
              </div>
            }
            trigger="click"
            animation="none"
            theme="dark"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            open={tooltipVisible}
            //@ts-ignore
            onShown={() => setTooltipVisible(!tooltipVisible)}
          >
            <span
              className="info_tooltip hide_on_mobile"
              onClick={(evt: React.MouseEvent<HTMLElement>) => {
                evt.stopPropagation();
                setTooltipVisible(!tooltipVisible);
              }}
            />
          </Tooltip>
        </div>
      </div>
      <div className="main_content ">
        {data?.length ? (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <ShowRoomChart
              chartId={"gradeMixChart"}
              data={chartData}
              theme={themeContext.theme}
              isLoading={loading}
              seriesList={seriesList}
              market={marketParamValue}
              granularity={granularity}
              highestValue={highestValue}
            />
          </ErrorBoundary>
        ) : loading ? (
          <LoadingEllipsis isLoading={loading} />
        ) : (
          <NoDataMsg message="No data available" />
        )}
      </div>
    </div>
  );
};

export default GradeMixGraph;
