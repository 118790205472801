import { format, parse } from "date-fns";
import { VOC_DATE_FILTER_OPTIONS } from "../constants";
import { fetchDataV2, newApiFetchV2 } from "./utils";

export const getParams = () => {
  const params = window.location.search;
  const parameters = new URLSearchParams(params);

  const dateRangeParam = parameters.get("date_range");
  const brand = parameters.get("brand") == "All" ? [] : parameters.get("brand")?.split(",") ?? [];
  const market = parameters.get("market") == "All" ? [] : parameters.get("market")?.split(",") ?? [];
  const dealer = parameters.get("dealer") == "All" ? [] : parameters.get("dealer")?.split(",") ?? [];
  const model = parameters.get("model") == "All" ? [] : parameters.get("model")?.split(",") ?? [];
  const model_type = parameters.get("model_type") == "All" ? [] : parameters.get("model_type")?.split(",") ?? [];

  const date = dateRangeParam
    ? VOC_DATE_FILTER_OPTIONS.includes(dateRangeParam)
      ? dateRangeParam.split(" ").join("_").toLocaleLowerCase()
      : "custom"
    : "";

  const parameterEntries: Record<string, string | null | Array<string> | undefined> = {
    date_range: date,
    brand: brand,
    market: market,
    dealer: dealer,
    model: model,
    vehicle_type: model_type,
  };

  if (date == "custom") {
    parameterEntries["start_date"] = dateRangeParam
      ? format(parse(dateRangeParam.split("-")[0].trim(), "MMMM yyyy", new Date()), "y-MM-dd")
      : "";
    parameterEntries["end_date"] = dateRangeParam
      ? format(parse(dateRangeParam.split("-")[1].trim(), "MMMM yyyy", new Date()), "y-MM-dd")
      : "";
  }

  return parameterEntries;
};

export const getQuickVocSalesFilters = () => newApiFetchV2("/quick-voc-sales/filters", getParams());

export const getQuickVocSalesLastRefreshDate = () => fetchDataV2("/quick-voc-sales/last-refresh-date");

export const getPerformanceByMarketMap = () => newApiFetchV2(`/quick-voc-sales/performance-by-market`, getParams());

export const getMarketDeepDiveTable = () => newApiFetchV2(`/quick-voc-sales/market-deep-dive-table`, getParams());

export const getMarketDeepDiveTrendline = () => newApiFetchV2(`/quick-voc-sales/satisfaction-score-by-market`, getParams());
