import { CategoryAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { CYAN, DARK_CYAN, LIGHT_CYAN, TURQUOISE } from "../../../constants";
import { disposeChart } from "../helpers/chartHelpers";
import { configureCategoryAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<{ title: string; value: number; color: string }>;
  region: string;
  brand: string;
  theme: ThemeInt;
}

class AppRatingsLineGraph extends Component<Props> {
  chartId = "star_ratingsChart";
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId);
      this.initChart();
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, theme, region, brand } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "0|#.0";

    // Creating Axes
    const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "label";

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);

    // Creating Series
    const series = this.chart.series.push(new LineSeries());
    const bullet = lineSeriesConfiguration(series, {
      valueY: "NissanConnect_EV",
      name: "NissanConnect EV",
    });
    series.dataFields.categoryX = "label";
    series.stroke = color(CYAN);
    series.fill = color(CYAN);

    bullet.tooltipText = "{name} [b]{categoryX}:[/] {valueY.formatNumber('#.0')}";

    const series2 = this.chart.series.push(new LineSeries());
    const bullet2 = lineSeriesConfiguration(series2, {
      valueY: "NissanConnect_Services",
      name: "NissanConnect Services",
    });
    series2.dataFields.categoryX = "label";
    series2.stroke = color(LIGHT_CYAN);
    series2.fill = color(LIGHT_CYAN);

    bullet2.tooltipText = "{name} [b]{categoryX}:[/] {valueY.formatNumber('#.0')}";

    if (region == "North America") {
      if (brand == "Nissan" || brand == "All") {
        const series3 = this.chart.series.push(new LineSeries());
        const bullet3 = lineSeriesConfiguration(series3, {
          valueY: "MyNISSAN",
          name: "MyNISSAN",
        });
        series3.dataFields.categoryX = "label";
        series3.stroke = color(TURQUOISE);
        series3.fill = color(TURQUOISE);

        bullet3.tooltipText = "{name} [b]{categoryX}:[/] {valueY.formatNumber('#.0')}";
      }
    }

    if (region == "North America" || region == "UAE") {
      if (brand == "Infiniti" || brand == "All") {
        const series4 = this.chart.series.push(new LineSeries());
        const bullet4 = lineSeriesConfiguration(series4, {
          valueY: "MyINFINITI",
          name: "MyInfiniti",
        });
        series4.dataFields.categoryX = "label";
        series4.stroke = color(DARK_CYAN);
        series4.fill = color(DARK_CYAN);

        bullet4.tooltipText = "{name} [b]{categoryX}:[/] {valueY.formatNumber('#.0')}";
      }
    }
  }
  render() {
    return <div id={this.chartId} className={"metricChart"} />;
  }
}

export default AppRatingsLineGraph;
