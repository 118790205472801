import {
  IGlobalPaidOrganicData,
  IGlobalTrafficData,
  IPopularData,
  ISearchInterestData,
  ISocialNetworks,
} from "../../constants/interface/ariya";
import { fetchData, postData } from "../utils";
import { apiPrefix } from "./constants";

export const fetchSearchValuesData = (): Promise<ISearchInterestData> => postData(`${apiPrefix}/searchValues`, {});
export const fetchSearchVolumesData = (): Promise<Array<{ country: string; date: string; search_term: string; search_volume: number }>> =>
  fetchData(`${apiPrefix}/search_volume`);
export const fetchVLPData = (): Promise<IGlobalTrafficData> => postData(`${apiPrefix}/globalTrafficVLP_Visits_for_testing`, {});
export const fetchVLP_PreviousData = (): Promise<IGlobalTrafficData> => postData(`${apiPrefix}/globalTrafficVLP_VisitsPrevious`, {});
export const fetchVCData = (): Promise<IGlobalTrafficData> => postData(`${apiPrefix}/globalTrafficVCVisits_for_testing`, {});
export const fetchVC_PreviousData = (): Promise<IGlobalTrafficData> =>
  postData(`${apiPrefix}/globalTrafficVCVisitsPrevious_for_testing`, {});
export const fetchWPData = (): Promise<IGlobalTrafficData> => postData(`${apiPrefix}/globalTrafficWPVisits_for_testing`, {});
export const fetchWP_PreviousData = (): Promise<IGlobalTrafficData> =>
  postData(`${apiPrefix}/globalTrafficWPVisitsPrevious_for_testing`, {});
export const fetchPaidOrganicData = (): Promise<IGlobalPaidOrganicData> =>
  postData(`${apiPrefix}/globalTrafficPaidOrganicVisits_for_testing`, {});

export const fetchPopularContent = (): Promise<IPopularData> => postData(`${apiPrefix}/mostPopularFramesVlp_for_testing`, {});
export const fetchPopularMoments = (): Promise<IPopularData> => postData(`${apiPrefix}/mostPopularMomentsVc_for_testing`, {});
export const fetchSocialNetworks = (): Promise<ISocialNetworks> => postData(`${apiPrefix}/socialNetworksDrillDown_for_testing`, {});
