import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getUseOwnershipDataDictionary } from "../../../../api/useOwnershipApi";

interface DataUpdate {
  KPI: string;
  KPI_definition: string;
  Source: string;
  ["Markets available"]: string;
}

const UseOwnershipData = (props: { page: string }) => {
  const { page } = props;
  const [dataUpdates, setDataUpdates] = useState<Array<DataUpdate>>();

  useEffect(() => {
    getUseOwnershipDataDictionary(page).then((response: any) => {
      if (response && "error" in response) {
        toast.error("Error fetching data updates");
      } else {
        setDataUpdates(response);
      }
    });
  }, []);

  return (
    <div className="data_documentation">
      <h3>USE OWNERSHIP - OVERVIEW - DATA OVERVIEW</h3>

      <br />

      <table className="docs_table">
        <thead>
          <tr>
            <th>KPI</th>
            <th>Definition</th>
            <th>Source</th>
            <th>Markets available</th>
          </tr>
        </thead>
        <tbody>
          {dataUpdates?.map((data, index) => (
            <tr key={index}>
              <td>{data.KPI}</td>
              <td>{data.KPI_definition}</td>
              <td>{data.Source}</td>
              <td>{data["Markets available"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <br />
    </div>
  );
};

export default UseOwnershipData;
