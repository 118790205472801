export const FETCH_MSRP_SUMMARY = "FETCH_MSRP_SUMMARY";
export const SET_MSRP_SUMMARY = "SET_MSRP_SUMMARY";

export const FETCH_SALES_BY_MODEL = "FETCH_SALES_BY_MODEL";
export const SET_SALES_BY_MODEL = "SET_SALES_BY_MODEL";

export const FETCH_DIFFERENCE_BY_MODEL = "FETCH_DIFFERENCE_BY_MODEL";
export const SET_DIFFERENCE_BY_MODEL = "SET_DIFFERENCE_BY_MODEL";

export const FETCH_VME_DIFFERENCE_BY_MODEL = "FETCH_VME_DIFFERENCE_BY_MODEL";
export const SET_VME_DIFFERENCE_BY_MODEL = "SET_VME_DIFFERENCE_BY_MODEL";

export const FETCH_MSRP_VME_DIFFERENCE = "FETCH_MSRP_VME_DIFFERENCE";
export const SET_MSRP_VME_DIFFERENCE = "SET_MSRP_VME_DIFFERENCE";

export const FETCH_MSRP_PROFILE_BY_MONTH = "FETCH_MSRP_PROFILE_BY_MONTH";
export const SET_MSRP_PROFILE_BY_MONTH = "SET_MSRP_PROFILE_BY_MONTH";

export const FETCH_MSRP_MODEL_PERFORMANCE = "FETCH_MSRP_MODEL_PERFORMANCE";
export const SET_MSRP_MODEL_PERFORMANCE = "SET_MSRP_MODEL_PERFORMANCE";

export const FETCH_MSRP_OVER_TIME_BY_MODEL = "FETCH_MSRP_OVER_TIME_BY_MODEL";
export const SET_MSRP_OVER_TIME_BY_MODEL = "SET_MSRP_OVER_TIME_BY_MODEL";

export const FETCH_VME_DISCOUNT_OVER_TIME_BY_MODEL = "FETCH_VME_DISCOUNT_OVER_TIME_BY_MODEL";
export const SET_VME_DISCOUNT_OVER_TIME_BY_MODEL = "SET_VME_DISCOUNT_OVER_TIME_BY_MODEL";

export const FETCH_TOTAL_VALUE_DIS_BY_MONTH = "FETCH_TOTAL_VALUE_DIS_BY_MONTH";
export const SET_TOTAL_VALUE_DIS_BY_MONTH = "SET_TOTAL_VALUE_DIS_BY_MONTH";

export const FETCH_DIS_BY_MODEL_AND_MONTH = "FETCH_DIS_BY_MODEL_AND_MONTH";
export const SET_DIS_BY_MODEL_AND_MONTH = "SET_DIS_BY_MODEL_AND_MONTH";

export const SET_CURRENCY = "SET_CURRENCY";

export const FETCH_TOTAL_ADDED_VALUE = "FETCH_TOTAL_ADDED_VALUE";
export const SET_TOTAL_ADDED_VALUE = "SET_TOTAL_ADDED_VALUE";

export const FETCH_TOP_FIVE_MODELS = "FETCH_TOP_FIVE_MODELS";
export const SET_TOP_FIVE_MODELS = "SET_TOP_FIVE_MODELS";
