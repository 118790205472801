import { SET_HAS_ARIYA, SET_HAS_AURA, SET_HAS_QASHQAI, SET_MODELS, SET_MODELS_DATA } from "../actions/actionTypes";

const initialState = { data: {}, activeList: [], hasAriya: false, hasQashqai: false, hasAura: false };

const modelsReducer = (
  state = initialState,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case SET_MODELS_DATA:
      return { ...state, data: action.payload };
    case SET_MODELS:
      return { ...state, activeList: action.payload };

    case SET_HAS_QASHQAI:
      return { ...state, hasQashqai: action.payload };

    case SET_HAS_ARIYA:
      return { ...state, hasAriya: action.payload };

    case SET_HAS_AURA:
      return { ...state, hasAura: action.payload };

    default:
      return state;
  }
};
export default modelsReducer;
