import { ErrorBoundary } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { setParameters, setSpecificParameter } from "../../../actions";
import { getExecutiveSummaryProductPerformanceFilters } from "../../../api";
import "../../../assets/styles/component/filters.scss";
import { ALL_COUNTRIES, ALL_OPTION, ALL_OPTION_NO_SPACE, DEFAULT_EXECUTIVE_SUMMARY_DIS, DEFAULT_EXECUTIVE_SUMMARY_PRODUCT_PERFORMANCE, DEFAULT_PURCHASE_FUNNEL_PARAMETERS, DIS_PRESET_DATE_PARAMS } from "../../../constants";
import { FilterContext } from "../../../context";
import { isMonthlyCustomDate } from "../../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { ErrorMsg } from "../../AppMessages";
import { FiltersLayout } from "../../Layouts";
import { DefaultFilter, MultiSelectFilter, NewGeographyFilter } from "../common";
import PurchaseFunnelDateFilter from "../common/PurchaseFunnelDateFilter";
import { closeFilterOptions, closeMarketOptions } from "../subs/helpers";
import { currentDefaultFiltersSet, defaultFiltersSet } from "../../../utils/utilityFunctions";

interface Props {
  filterBtns: Array<{ id: string; navigateTo: string; name: string }>;
  inComplete?: boolean;
}

const ExecutiveSummaryProductPerformanceFilters = withRouter((props: RouteComponentProps & Props) => {
  const { history, filterBtns } = props;
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const {
    date_range: dateRangeParamValue,
    region: regionParamValue,
    market: marketParamValue,
    brand: brandParamValue,
    product_category: productCategoryParamValue,
    product: productParamValue,
  } = useSelector((state: RootStateOrAny) => state.parameters);
  const [productFilterValue, setProductFilterValue] = useState(productParamValue);
  const [productCategoryFilterValue, setProductCategoryFilterValue] = useState(productCategoryParamValue);
  const [regionCountryValue, setRegionCountryValue] = useState(regionParamValue);


  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("Executive Summary - Product Performance");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_EXECUTIVE_SUMMARY_PRODUCT_PERFORMANCE));
      currentDefaultFiltersSet("Executive Summary - Product Performance");
    }
  }, []);

  const { data, refetch } = useQuery({
    queryKey: ["executiveSummaryProductPerformanceFiltersData", history.location.search],
    queryFn: getExecutiveSummaryProductPerformanceFilters,
    initialData: {},
    enabled: false,
  });

  const { brand, geographies, product: products, product_category: productCategories } = data;

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  //reset filter button functionality
  const resetFilters = useCallback(() => {
    dispatch(setParameters(DEFAULT_EXECUTIVE_SUMMARY_PRODUCT_PERFORMANCE));
  }, []);

  //Reset date range param value
  useEffect(() => {
    if (dateRangeParamValue) {
      const isValidDate = DIS_PRESET_DATE_PARAMS.includes(dateRangeParamValue) || isMonthlyCustomDate(dateRangeParamValue);
      if (!isValidDate) dispatch(setSpecificParameter("date_range", "This financial year"));
    } else {
      dispatch(setSpecificParameter("date_range", "This financial year"));
    }
  }, [dateRangeParamValue]);

  //Reset market param if "All"
  useEffect(() => {
    if (marketParamValue == ALL_OPTION_NO_SPACE) {
      dispatch(setSpecificParameter("market", ALL_COUNTRIES))
    }
  }, [marketParamValue])

  //Set geography input value
  useEffect(() => {
    if (geographies && geographies?.length > 0) {
      const marketAllRe = new RegExp(ALL_COUNTRIES, "gi");

      const geographyValue = !marketAllRe.test(marketParamValue)
        ? marketParamValue.includes(",")
          ? `${marketParamValue.split(",")?.length} markets selected`
          : marketParamValue
        : regionParamValue.includes(",")
          ? regionParamValue.split(",")?.length === geographies?.length
            ? ALL_OPTION
            : `${regionParamValue.split(",")?.length} regions selected`
          : regionParamValue;
      setRegionCountryValue(geographyValue);
    }
  }, [geographies, regionParamValue, marketParamValue]);

  //validate geo and market
  useEffect(() => {
    if (geographies && regionParamValue != ALL_OPTION) {
      const regions = geographies.map((geo: any) => geo.region)
      const selectedRegions = regionParamValue.split(",")
      if (selectedRegions.every((region: string) => regions.includes(region))) return;
      dispatch(setSpecificParameter("region", ALL_OPTION))
      dispatch(setSpecificParameter("market", ALL_COUNTRIES))
    }
  }, [geographies, regionParamValue])

  //Validate product param
  useEffect(() => {
    if (products && products.length > 0 && productParamValue != "All") {
      const selectedProducts = productParamValue.split(",");
      if (selectedProducts.every((product: string) => products.includes(product))) return;
      const validProducts: Array<string> = [];
      selectedProducts.forEach((product: string) => products.includes(product) && validProducts.push("product"));
      validProducts.length > 0
        ? dispatch(setSpecificParameter("product", validProducts.join(",")))
        : dispatch(setSpecificParameter("product", "All"));
    }
  }, [productParamValue, products]);

  //Validate product category param
  useEffect(() => {
    if (productCategories && productCategories.length > 0 && productCategoryParamValue != "All") {
      const selectedProductCategories = productCategoryParamValue.split(",");
      if (selectedProductCategories.every((productCategory: string) => productCategories.includes(productCategory))) return;
      const validProductCategories: Array<string> = [];
      selectedProductCategories.forEach((productCategory: string) => productCategories.includes(productCategory) && validProductCategories.push("product_category"));
      validProductCategories.length > 0
        ? dispatch(setSpecificParameter("product_category", validProductCategories.join(",")))
        : dispatch(setSpecificParameter("product_category", "All"));
    }
  }, [productCategoryParamValue, productCategories]);

  // Set product filter value
  useEffect(() => {
    if (products && productParamValue) {
      const selectedProductsArr = productParamValue.split(",");
      const productSourceValue =
        selectedProductsArr?.length === products.length
          ? "All"
          : selectedProductsArr?.length > 1
            ? `${selectedProductsArr?.length} product selected`
            : productParamValue;

      setProductFilterValue(productSourceValue);
    }
  }, [products, productParamValue]);

  // Set product category filter value
  useEffect(() => {
    if (productCategories && productCategoryParamValue) {
      const selectedProductCategoriesArr = productCategoryParamValue.split(",");
      const productSourceValue =
        selectedProductCategoriesArr?.length === productCategories.length
          ? "All"
          : selectedProductCategoriesArr?.length > 1
            ? `${selectedProductCategoriesArr?.length} product categories selected`
            : productCategoryParamValue;

      setProductCategoryFilterValue(productSourceValue);
    }
  }, [productCategories, productCategoryParamValue]);

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));

      eventTracking(MixpanelEvents.filter_change, {
        filter: filterName,
        value: optionValue,
        dashboard: "Executive Summary",
        page: "Product Performance",
      });
    }
    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);
  };

  const handleProductFilterSubmission = useCallback(() => {
    const parameterName = "product";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = selectedValues?.length === products?.length ? "All" : selectedValues?.join(",");
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, {
      filter: parameterName,
      value: filterValue,
      dashboard: "Executive Summary",
      page: "Product Performance",
    });
    closeFilterOptions();
  }, [products]);

  const handleProductCategoryFilterSubmission = useCallback(() => {
    const parameterName = "product_category";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = selectedValues?.length === productCategories?.length ? "All" : selectedValues?.join(",");
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, {
      filter: parameterName,
      value: filterValue,
      dashboard: "Executive Summary",
      page: "Product Performance",
    });
    closeFilterOptions();
  }, [productCategories]);

  const formatedGeos = useMemo(() => {
    if (geographies && geographies.length > 0) {
      return [
        {
          "region": "All ",
          "indeterminate": false,
          "checked": regionParamValue == ALL_OPTION && marketParamValue == ALL_COUNTRIES
        },
        ...geographies.map((geo: any) => ({ checked: marketParamValue.split(",") == geo.markets ? true : regionParamValue.includes(geo.region) || (regionParamValue == ALL_OPTION && marketParamValue == ALL_COUNTRIES), indeterminate: marketParamValue.split(",").some((market: string) => geo.markets.includes(market)), ...geo }))]
    }
    return [
      {
        "region": "All ",
        "indeterminate": false,
        "checked": regionParamValue == ALL_OPTION
      },
    ]
  }, [geographies, marketParamValue, regionParamValue])

  // Set params
  useEffect(() => {
    const params = new URLSearchParams();

    params.set("date_range", dateRangeParamValue);
    params.set("brand", brandParamValue);
    params.set("region", regionParamValue);
    params.set("market", marketParamValue);
    params.set("product_category", productCategoryParamValue)
    params.set("product", productParamValue);
    history.push({ search: `?${params.toString()}` });
  }, [dateRangeParamValue, brandParamValue, regionParamValue, marketParamValue, productParamValue, productCategoryParamValue]);

  return (
    <FiltersLayout extraClass={"iframeFilters-midas"} resetFilters={resetFilters} filterBtns={filterBtns}>
      <div className="filte">
        {/*Date range*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <PurchaseFunnelDateFilter
            value={dateRangeParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
            onFilterClick={showFilterOptionsFn}
          />
        </ErrorBoundary>

        {/*Brand*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            filterName="brand"
            filterLabel="BRAND"
            filterValue={brandParamValue}
            list={brand ?? []}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Geography*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          {/* @ts-ignore */}
          <NewGeographyFilter geographies={formatedGeos} onShowFilterOptions={showFilterOptionsFn} value={regionCountryValue} />
        </ErrorBoundary>

        {/* Product Category */}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="product_category"
            filterName={"PRODUCT CATEGORY"}
            // @ts-ignore
            filterList={productCategories ?? []}
            value={productCategoryFilterValue}
            parameterName={"product_category"}
            parameterValue={productCategoryParamValue}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleProductCategoryFilterSubmission}
          />
        </ErrorBoundary>

        {/* Product */}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="product"
            filterName={"PRODUCT"}
            // @ts-ignore
            filterList={products ?? []}
            value={productFilterValue}
            parameterName={"product"}
            parameterValue={productParamValue}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleProductFilterSubmission}
          />
        </ErrorBoundary>
      </div>
    </FiltersLayout>
  );
});

export default ExecutiveSummaryProductPerformanceFilters;
