import React, { useState } from "react";

interface DataUpdate {
  view: string;
  kpi: string;
  KPI_definition: string;
  Source: string;
}

export const BrandPerformanceData = (): JSX.Element => {
  const [dataUpdates, setDataUpdates] = useState<Array<DataUpdate>>();

  // useEffect(() => {
  //   getBrandPerformanceDataDefinitions().then((response: any) => {
  //     if (response && "error" in response) {
  //       toast.error("Error fetching data updates");
  //     } else {
  //       setDataUpdates(response);
  //     }
  //   });
  // }, []);

  return (
    <div className="data_documentation">
      <h3>BRAND PERFORMANCE - DATA OVERVIEW</h3>

      <br />

      <table className="docs_table">
        <thead>
          <tr>
            <th>View</th>
            <th>KPI</th>
            <th>Description</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Brand volume</td>
            <td>Volume</td>
            <td style={{ whiteSpace: "pre-wrap" }}>
              Volume represents the total sales of a brand or car model in the selected geography in the select period of time. Competitors
              corporate and model data are shown based on the selected Nissan brand/model.
            </td>
            <td>Total (Retail & Fleet) Sales Data by PAI</td>
          </tr>
          <tr>
            <td>Brand volume</td>
            <td>Volume Average</td>
            <td style={{ whiteSpace: "pre-wrap" }}>
              Volume average considers the sum of all brand/model sales in the select period dividided by the number of months in the
              period.
            </td>
            <td>Total (Retail & Fleet) Sales Data by PAI</td>
          </tr>
          <tr>
            <td>Brand OaO</td>
            <td>OaO</td>
            <td style={{ whiteSpace: "pre-wrap" }}>
              Overall opinion shows the percentage of &#34;Excellent&#34; given as raiting as result of the following question: &#34;What is
              your overall oponion of each make/model (based on what you know or have heard)?&#34;
            </td>
            <td>GfK Imagery and Awareness</td>
          </tr>
          <tr>
            <td>Brand OaO</td>
            <td>OaO Average</td>
            <td style={{ whiteSpace: "pre-wrap" }}>
              Overall opinion considers the average score given to a make/model in the selected period. Please note that OaO average is
              calculated by the sum of all scores divided by the number of months selected.
            </td>
            <td>GfK Imagery and Awareness</td>
          </tr>
          <tr>
            <td>Brand Image Attributes</td>
            <td>Brand Image Attribute</td>
            <td style={{ whiteSpace: "pre-wrap" }}>
              Brand Image considers the ratings given by public opinion to car models and brands based on their attributes. Please note that
              because the data collection methos is different between each country, the figure can not be compared as absolute value.
            </td>
            <td>GfK Imagery and Awareness</td>
          </tr>
          {/* {dataUpdates?.map((data, index) => (
            <tr key={index}>
              <td>{data.view}</td>
              <td>{data.kpi}</td>
              <td style={{ whiteSpace: "pre-wrap" }}>{data.KPI_definition}</td>
              <td>{data.Source}</td>
            </tr>
          ))} */}
        </tbody>
      </table>
      <br />
      <br />
    </div>
  );
};
