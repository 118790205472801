import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg, NoDataMsg } from "../../../../components/AppMessages";
import MediaMixBarChart from "../../../../components/Charts/Midas/MediaMixBarChart";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { GRADE_MIX_COLORS } from "../../../../constants";
import { ThemeContext } from "../../../../context";
import { getMaxAmount, getMediaMixChartData, getMinAmount } from "../Helpers/helpers";

interface Props {
  yrScenarios: string[];
}

const MediaMixGraph = (props: Props) => {
  const { yrScenarios } = props;
  const themeContext = useContext(ThemeContext);
  const loading = useSelector((state: RootStateOrAny) => state.loading.midas_media_mix_data_by_quarter);
  const data = useSelector((state: RootStateOrAny) => state.midas.midas_media_mix_data_by_quarter);

  const seriesList = useMemo(() => {
    const services: string[] = Array.from(new Set(data?.full_year?.map((val: { tch_pnt_nm: string }) => val?.tch_pnt_nm)));

    return Array.from(services).map((service, idx: number) => {
      const key = service.replace(/[^a-zA-Z]/g, "_").replace(/\s+/g, "_");

      return {
        name: key,
        volumeField: `${key}_amount`,
        shareField: `${key}_share`,
        seriesColor: GRADE_MIX_COLORS[idx + 2],
      };
    });
  }, [data]);

  const q1ChartData = useMemo(() => getMediaMixChartData(yrScenarios, data?.q1), [data]);

  const q2ChartData = useMemo(() => getMediaMixChartData(yrScenarios, data?.q2), [data]);

  const q3ChartData = useMemo(() => getMediaMixChartData(yrScenarios, data?.q3), [data]);

  const q4ChartData = useMemo(() => getMediaMixChartData(yrScenarios, data?.q4), [data]);

  const fullYearChartData = useMemo(() => getMediaMixChartData(yrScenarios, data?.full_year), [data]);

  const quartersMaxAmount = useMemo(() => {
    const q1MaxAmount = getMaxAmount(data?.q1, yrScenarios);
    const q2MaxAmount = getMaxAmount(data?.q2, yrScenarios);
    const q3MaxAmount = getMaxAmount(data?.q3, yrScenarios);
    const q4MaxAmount = getMaxAmount(data?.q4, yrScenarios);
    return Math.max(q1MaxAmount, q2MaxAmount, q3MaxAmount, q4MaxAmount);
  }, [data, yrScenarios]);

  const quartersMinAmount = useMemo(() => {
    const q1MinAmount = getMinAmount(data?.q1, yrScenarios);
    const q2MinAmount = getMinAmount(data?.q2, yrScenarios);
    const q3MinAmount = getMinAmount(data?.q3, yrScenarios);
    const q4MinAmount = getMinAmount(data?.q4, yrScenarios);
    return Math.min(q1MinAmount, q2MinAmount, q3MinAmount, q4MinAmount);
  }, [data, yrScenarios]);

  const fullYearMaxAmount = useMemo(() => getMaxAmount(data?.full_year, yrScenarios), [data, yrScenarios]);

  return (
    <div className="media_mix_charts primary_background">
      <div className="legend">
        {seriesList?.map(({ name, seriesColor }) => (
          <div className="legend_item" key={name}>
            <div className="legend_box" style={{ backgroundColor: seriesColor }}></div>
            {name.replace(new RegExp("_", "g"), " ")}
          </div>
        ))}
      </div>

      <div className="charts">
        <div className="quarter_breakdown">
          <span className="quarter_title">Q1</span>
          {q1ChartData?.length && !loading ? (
            <ErrorBoundary fallback={<ErrorMsg />}>
              <MediaMixBarChart
                maxAmount={quartersMaxAmount}
                categories={yrScenarios}
                chartName="q1MediaMix"
                theme={themeContext.theme}
                data={q1ChartData}
                seriesList={seriesList}
                isLoading={false}
                isQ1={true}
                minAmount={quartersMinAmount}
              />
            </ErrorBoundary>
          ) : loading ? (
            <LoadingEllipsis isLoading={loading} />
          ) : (
            <NoDataMsg message="No data available" />
          )}
        </div>
        <div className="quarter_breakdown">
          <span className="quarter_title">Q2</span>
          {q2ChartData?.length && !loading ? (
            <ErrorBoundary fallback={<ErrorMsg />}>
              <MediaMixBarChart
                maxAmount={quartersMaxAmount}
                categories={yrScenarios}
                chartName="q2MediaMix"
                theme={themeContext.theme}
                data={q2ChartData}
                seriesList={seriesList}
                isLoading={false}
                minAmount={quartersMinAmount}
                hideAxis={true}
              />
            </ErrorBoundary>
          ) : loading ? (
            <LoadingEllipsis isLoading={loading} />
          ) : (
            <NoDataMsg message="No data available" />
          )}
        </div>
        <div className="quarter_breakdown">
          <span className="quarter_title">Q3</span>
          {q3ChartData?.length && !loading ? (
            <ErrorBoundary fallback={<ErrorMsg />}>
              <MediaMixBarChart
                maxAmount={quartersMaxAmount}
                categories={yrScenarios}
                chartName="q3MediaMix"
                theme={themeContext.theme}
                data={q3ChartData}
                seriesList={seriesList}
                isLoading={false}
                minAmount={quartersMinAmount}
                hideAxis={true}
              />
            </ErrorBoundary>
          ) : loading ? (
            <LoadingEllipsis isLoading={loading} />
          ) : (
            <NoDataMsg message="No data available" />
          )}
        </div>
        <div className="quarter_breakdown">
          <span className="quarter_title">Q4</span>
          {q4ChartData?.length && !loading ? (
            <ErrorBoundary fallback={<ErrorMsg />}>
              <MediaMixBarChart
                maxAmount={quartersMaxAmount}
                categories={yrScenarios}
                chartName="q4MediaMix"
                theme={themeContext.theme}
                data={q4ChartData}
                seriesList={seriesList}
                isLoading={false}
                minAmount={quartersMinAmount}
                hideAxis={true}
              />
            </ErrorBoundary>
          ) : loading ? (
            <LoadingEllipsis isLoading={loading} />
          ) : (
            <NoDataMsg message="No data available" />
          )}
        </div>
        <div className="quarter_breakdown">
          <span className="quarter_title">Full year</span>
          {fullYearChartData?.length && !loading ? (
            <ErrorBoundary fallback={<ErrorMsg />}>
              <MediaMixBarChart
                chartName="fullYearMediaMix"
                categories={yrScenarios}
                theme={themeContext.theme}
                data={fullYearChartData}
                seriesList={seriesList}
                isLoading={false}
                minAmount={quartersMinAmount}
                maxAmount={fullYearMaxAmount}
              />
            </ErrorBoundary>
          ) : loading ? (
            <LoadingEllipsis isLoading={loading} />
          ) : (
            <NoDataMsg message="No data available" />
          )}
        </div>
      </div>
    </div>
  );
};

export default MediaMixGraph;
