import moment from "moment";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getAllReleaseNotes } from "../../api/releaseNotesApi";
import "../../assets/styles/pages/info.scss";
import { InfoLayout } from "../../components/Layouts";
import { IReleaseNote } from "../../constants/interface/admin/releaseNotes";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { AllReleaseNotesContainer } from "./common";

interface IReleaseNotes {
  version_number: string;
  release_notes: string;
  release_date: string;
}

export const AllReleaseNotes = withRouter((props: RouteComponentProps): JSX.Element => {
  const { history, location } = props;
  const [documentation, setDocumentation] = useState("");
  const [releaseDate, setReleaseDate] = useState("");
  const [allReleaseNotes, setAllReleaseNotes] = useState<Array<IReleaseNote>>([]);
  const [visibleReleaseNote, setVisibleReleaseNote] = useState("");

  // Usage tracking
  useEffect(() => eventTracking(MixpanelEvents.page_view, { dashboard: "INFO", page: "Release Notes" }), [location.pathname]);

  //Fetch release notes
  useEffect(() => {
    getAllReleaseNotes().then((response) => {
      const allReleaseNotesData: Array<IReleaseNote> = response;
      if (allReleaseNotesData) {
        setAllReleaseNotes(allReleaseNotesData);
      }
    });
  }, []);

  //Set the value of the current selected release note
  useEffect(() => {
    if (visibleReleaseNote && allReleaseNotes) {
      const params = new URLSearchParams(history.location.search);
      params.set("version", visibleReleaseNote);

      const selectedReleaseNote = allReleaseNotes.find((releaseNote) => releaseNote.version_number === visibleReleaseNote);

      if (selectedReleaseNote) {
        const selectedReleaseDate = `${moment(selectedReleaseNote.release_date).format("MMMM DD")}, ${moment(
          selectedReleaseNote.release_date
        ).format("YYYY")}`;

        setReleaseDate(selectedReleaseDate);
        setDocumentation(selectedReleaseNote.release_note);
      }

      history.push({ search: `?${params.toString()}` });
    }
  }, [visibleReleaseNote, allReleaseNotes, history]);

  //Reset value of selected release note
  useEffect(() => {
    if (allReleaseNotes.length > 0) {
      const params = new URLSearchParams(history.location.search);
      const versionParam = params.get("version");
      const latestReleaseNote = allReleaseNotes[0]?.version_number;
      const defaultReleaseNote = versionParam ? versionParam : latestReleaseNote;
      setVisibleReleaseNote(defaultReleaseNote);
    }
  });
  return (
    <InfoLayout
      menu={
        <ul className="info_section info_options" id="release_notes_menu">
          {allReleaseNotes.map((menuItem) => (
            <li
              key={menuItem.version_number}
              className={`kpi_option ${visibleReleaseNote === menuItem.version_number ? "active" : ""}`}
              onClick={() => setVisibleReleaseNote(menuItem.version_number)}
            >
              {`V${menuItem.version_number}`}
            </li>
          ))}
        </ul>
      }
      content={<AllReleaseNotesContainer releaseNotes={documentation} releaseDate={releaseDate} versionNumber={visibleReleaseNote} />}
    />
  );
});
