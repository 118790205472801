import { CategoryAxis, ColumnSeries, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { AMBER, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureCategoryAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  theme: ThemeInt;
  chartId: string;
  isLoading: boolean;
}

class InfluenceOfShoppingToolsChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, theme, chartId } = this.props;
    this.chart = create(chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    // this.chart.numberFormatter.numberFormat = "#.";

    // Create Axes
    const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.renderer.labels.template.wrap = true;
    categoryAxis.renderer.labels.template.maxWidth = 200;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.extraMax = 0.1;
    valueAxis.min = 0;
    valueAxis.title.text = "Visits";

    const precentageValueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(precentageValueAxis, theme);
    precentageValueAxis.extraMax = 0.1;
    precentageValueAxis.min = 0;
    precentageValueAxis.renderer.opposite = true;
    precentageValueAxis.renderer.labels.template.adapter.add("text", (text) => {
      return text + "%";
    });
    precentageValueAxis.syncWithAxis = valueAxis;
    precentageValueAxis.title.text = "Purchase conversion rate";

    const series = this.chart.series.push(new ColumnSeries());
    series.numberFormatter.numberFormat = "#.0a";
    series.dataFields.valueY = "visits";
    series.dataFields.categoryX = "name";
    series.columns.template.width = percent(65);
    series.columns.template.tooltipText = `[bold; font-size: var(--regular_font_size);]{categoryX}: {valueY}%`;
    if (series.tooltip) {
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = color(WHITE);
    }
    series.columns.template.adapter.add("tooltipText", (value: any, target: any) => {
      const dataValue = target?.tooltipDataItem?.dataContext as { name: string; visits: number; purchase_conversion_rate: number };
      let text = "";
      text += `[bold; font-size: var(--regular_font_size);]Visits: ${dataValue.visits}\n`;
      text += `[bold; font-size: var(--regular_font_size);]Purchase conversion rate: ${dataValue.purchase_conversion_rate}%\n`;
      return text;
    });

    const percentageSeries = this.chart.series.push(new LineSeries());
    percentageSeries.dataFields.categoryX = "name";
    percentageSeries.strokeOpacity = 0;

    const bullet = lineSeriesConfiguration(percentageSeries, {
      valueY: "purchase_conversion_rate",
      name: "Purchase conversion rate",
      yAxis: precentageValueAxis,
    });
    bullet.circle.radius = 5;
    bullet.fill = color(AMBER);
    bullet.stroke = color(AMBER);
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default InfluenceOfShoppingToolsChart;
