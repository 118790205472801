import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import { Color, color, colors, Container, create, LinearGradient, percent, Sprite, type } from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, FOREST_GREEN, LIGHT_YELLOW, RED, WHITE } from "../../../constants";
import { isNull } from "../../../utils/utilityFunctions";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { ThemeInt } from "../helpers/types";

interface Props {
  chartId: string;
  data: any;
  metric: string;
  currentPeriodLabel: string;
  isLoading: boolean;
  theme: ThemeInt;
  showVolume?: boolean;
}

const heatColors = [color(RED), color(LIGHT_YELLOW), color(FOREST_GREEN)];

const percentageMetrics = ["CR - visits to KBA", "CR - visits to Leads"];

class PerformanceByMarketMapChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: am4maps.MapChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, theme, metric } = this.props;

    this.chart = create(this.chartId, am4maps.MapChart);

    // Set map definition
    this.chart.geodata = am4geodata_worldLow;

    // Set projection
    this.chart.projection = new am4maps.projections.Miller();

    this.chart.zoomControl = new am4maps.ZoomControl();
    this.chart.chartContainer.wheelable = false;

    // Series for World map
    const worldSeries = this.chart.series.push(new am4maps.MapPolygonSeries());
    worldSeries.exclude = ["AQ"];
    worldSeries.useGeodata = true;
    worldSeries.data = data;
    worldSeries;

    data.length == 1 && (
      this.chart.events.on("ready", (ev) => {
        this.chart.zoomToMapObject(worldSeries.getPolygonById(data[0].id), 3)
      })
    )

    // Emulate heatRule but with 2 color ranges instead of 1
    worldSeries.mapPolygons.template.adapter.add("fill", function (fill, mapPolygon) {
      const workingValue = mapPolygon.dataItem.values["value"].workingValue;
      const minValue = 0;
      const maxValue = 5;
      const percent = (workingValue - minValue) / (maxValue - minValue);
      // This may run before workingValue is even a thing. Let's only do our thing
      // if workingValue and ergo percent are a thing.
      if (type.isNumber(percent)) {
        if (percent > 0.5) {
          return new Color(colors.interpolate(heatColors[1].rgb, heatColors[2].rgb, (percent - 0.5) * 2));
        } else {
          return new Color(colors.interpolate(heatColors[0].rgb, heatColors[1].rgb, percent * 2));
        }
      }
      return fill;
    });

    // Set up heat legend
    const legendContainer = create("legenddiv", Container);
    legendContainer.width = percent(100);
    legendContainer.height = percent(100);

    const heatLegend = legendContainer.createChild(am4maps.HeatLegend);
    heatLegend.id = "heatLegend";
    heatLegend.series = worldSeries;
    heatLegend.align = "right";
    heatLegend.valign = "middle";
    heatLegend.width = percent(50);
    heatLegend.orientation = "vertical";
    heatLegend.minColor = heatColors[0];
    heatLegend.maxColor = heatColors[2];
    heatLegend.valueAxis.renderer.labels.template.fill = theme == "dark" ? color(WHITE) : color(BLACK);
    heatLegend.marginRight = 100;
    heatLegend.minValue = 0;
    heatLegend.maxValue = 5;
    heatLegend.valueAxis.renderer.labels.template.adapter.add("text", (text) => {
      return text + this.getTotalLabel();
    });
    heatLegend.events.on("inited", function () {
      heatLegend.markers.each(function (marker, markerIndex) {
        // Gradient colors!
        if (markerIndex < heatLegend.markerCount / 2) {
          marker.fill = new Color(colors.interpolate(heatColors[0].rgb, heatColors[1].rgb, (markerIndex / heatLegend.markerCount) * 2));
        } else {
          marker.fill = new Color(
            colors.interpolate(
              heatColors[1].rgb,
              heatColors[2].rgb,
              ((markerIndex - heatLegend.markerCount / 2) / heatLegend.markerCount) * 2
            )
          );
        }
      });
    });
    heatLegend.markerCount = 5;

    const polygonTemplate = worldSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}: {value}";
    polygonTemplate.adapter.add("tooltipText", (value: string | undefined, target: Sprite) => {
      const dataValue = target?.tooltipDataItem?.dataContext as { date: string;[index: string]: string | number };
      let text = ``;
      if ("value" in dataValue) {
        text = `${dataValue.name} - ${this.props.metric ? this.props.metric : this.props.currentPeriodLabel} \n ${this.props.metric ? this.props.currentPeriodLabel : "Satisfaction Score"
          }: ${"value" in dataValue && !isNull(dataValue.value) ? Number(dataValue.value).toLocaleString() : ""} \n ${this.props.showVolume ? "Volume: " + Number(dataValue.volume).toLocaleString() : ""
          }`;
      }
      return text;
    });
    polygonTemplate.events.on("hit", function (ev) {
      ev.target.series.chart.zoomToMapObject(ev.target);
    });
  }

  getVarianceLabel() {
    const metric = this.props.metric;
    if (percentageMetrics.includes(metric)) {
      return "PTS";
    }
    return "%";
  }

  getTotalLabel() {
    const metric = this.props.metric;
    if (percentageMetrics.includes(metric)) {
      return "%";
    }
    return "";
  }

  render() {
    return (
      <>
        <div className={"mapWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"map"} />
          <div id="legenddiv"></div>
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

export default PerformanceByMarketMapChart;
