import React, { useContext, useEffect } from "react";
import { ThemeContext } from "../../../context";
import DmeoStackedBarLineChart from "../../../components/Charts/Dmeo/DmeoStackedBarChart";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getDmeoDigitalSpendByCampaignObjectiveTrend } from "../../../api/dmeoApi";

const DigitalSpendByCampaignObjectiveTrend = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["digitalSpendByCampaignObjectiveTrendData"],
    queryFn: getDmeoDigitalSpendByCampaignObjectiveTrend,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  return (
    <div className="tile">
      <div className="tile-headre">Digital Spend by Campaign Objective</div>
      <div className="tile-content">
        <DmeoStackedBarLineChart
          chartId="digitalSpendByCampaignStachedBarChart"
          data={data?.data ? data.data : []}
          seriesList={data?.series ? data.series : []}
          isLoading={isFetching}
          theme={theme}
        />
      </div>
    </div>
  );
});

export default DigitalSpendByCampaignObjectiveTrend;
