import { CategoryAxis, LabelBullet, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { BLACK, CYAN, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureCategoryAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  seriesList: Array<{ name: string; field: string; color?: string }>;
  theme: ThemeInt;
  chartId: string;
  isLoading: boolean;
  isPercentage?: boolean;
}

class ExecutiveSummaryBulletChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, seriesList, theme, isPercentage } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#.0";

    // Legend configuration
    this.chart.colors.list = [color(CYAN)];

    // Create Axes
    const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.dataFields.category = "market";
    categoryAxis.startLocation = 0.3;
    categoryAxis.renderer.labels.template.wrap = true;
    categoryAxis.renderer.labels.template.maxWidth = 122;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text + "%";
    });
    valueAxis.extraMax = 0.1;

    seriesList?.forEach(({ name, field }, idx) => {
      const series = this.chart.series.push(new LineSeries());
      series.dataFields.categoryX = "market";
      const bullet = lineSeriesConfiguration(series, {
        valueY: field,
        name: name,
      });
      series.strokeWidth = 0;

      const labelBullet = series.bullets.push(new LabelBullet());
      labelBullet.label.text = "{valueY.formatNumber('#')}%";
      labelBullet.label.dy = -10;
      labelBullet.label.dx = 10;
      labelBullet.label.fill = theme == "dark" ? color(WHITE) : color(BLACK);
    });
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

export default ExecutiveSummaryBulletChart;
