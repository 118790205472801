import { format, parse } from "date-fns";
import { VOC_DATE_FILTER_OPTIONS } from "../constants";
import { fetchDataV2, newApiFetchV2 } from "./utils";

export const getParams = () => {
  const params = window.location.search;
  const parameters = new URLSearchParams(params);

  const dateRangeParam = parameters.get("date_range");
  const brand = parameters.get("brand") == "All" ? [] : parameters.get("brand")?.split(",") ?? [];
  const market = parameters.get("market") == "All" ? [] : parameters.get("market")?.split(",") ?? [];

  const date = dateRangeParam
    ? VOC_DATE_FILTER_OPTIONS.includes(dateRangeParam)
      ? dateRangeParam.split(" ").join("_").toLocaleLowerCase()
      : "custom"
    : "";

  const parameterEntries: Record<string, string | null | Array<string> | undefined> = {
    date_range: date,
    brand: brand,
    market: market,
  };

  if (date == "custom") {
    parameterEntries["start_date"] = dateRangeParam
      ? format(parse(dateRangeParam.split("-")[0].trim(), "MMMM yyyy", new Date()), "y-MM-dd")
      : "";
    parameterEntries["end_date"] = dateRangeParam
      ? format(parse(dateRangeParam.split("-")[1].trim(), "MMMM yyyy", new Date()), "y-MM-dd")
      : "";
  }

  return parameterEntries;
};

export const getCustomerJourneyVocFilters = () => newApiFetchV2("/voc-customer-journey/filters", getParams());

export const getCustomerJourneyVocLastRefreshDate = () => fetchDataV2("/voc-customer-journey/last-refresh-date");

export const getCustomerJourneyVocTry = () => newApiFetchV2("/voc-customer-journey/try", getParams());

export const getCustomerJourneyVocBuy = () => newApiFetchV2("/voc-customer-journey/buy", getParams());

export const getCustomerJourneyVocUse = () => newApiFetchV2("/voc-customer-journey/use", getParams());

export const getCustomerJourneyVocPerformanceByMarket = (metric: string | undefined) =>
  newApiFetchV2(`/voc-customer-journey/performance-by-market?metric=${metric}`, getParams());
