import React from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { deleteSpecificParameter } from "../../../actions";

const MetricBreadcrumbPill = () => {
  const dispatch = useDispatch();
  const specificLead = useSelector((state: RootStateOrAny) => state.parameters.specific_lead);
  const specificKba = useSelector((state: RootStateOrAny) => state.parameters.specific_kba);
  const specificService = useSelector((state: RootStateOrAny) => state.parameters.specific_service);
  const specificGroup = useSelector((state: RootStateOrAny) => state.parameters.specific_group);
  const specificType = useSelector((state: RootStateOrAny) => state.parameters.specific_type);
  const specificTrigger = useSelector((state: RootStateOrAny) => state.parameters.specific_trigger);

  const resetMetric = () => {
    const specificParams = ["specific_kba", "specific_lead", "specific_service", "specific_group", "specific_type", "specific_trigger"];
    specificParams.map((specificParam) => dispatch(deleteSpecificParameter(specificParam)));
  };

  return specificKba || specificLead || specificService || specificGroup || specificType || specificTrigger ? (
    <div id="specific_metric_breadcrumb" className={"metric_pill"}>
      <span className="update_metric">
        {specificKba || specificLead || specificService || specificGroup || specificType || specificTrigger}
      </span>
      <span className="remove_specific_metric" onClick={resetMetric}>
        X
      </span>
    </div>
  ) : null;
};

export default MetricBreadcrumbPill;
