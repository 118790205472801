import React from "react";
import { Tooltip } from "react-tippy";

interface Props {
  title: string;
  tooltipText?: string;
  toggle?: JSX.Element;
  chart?: JSX.Element;
}

export const ChartTile = (props: Props) => {
  const { title, toggle, chart, tooltipText } = props;
  return (
    <div className="pf_tile pf_chart_container">
      <div className="header">
        <h3>{title}</h3>
        {tooltipText && (
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title={tooltipText}
            size={"small"}
          >
            <span className="info_tooltip"></span>
          </Tooltip>
        )}
        {toggle}
      </div>
      {chart}
    </div>
  );
};
