import React, { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setSpecificParameter } from "../../../actions";
import { useVisible } from "../../../hooks";
import { getDateDependentValues } from "../../../pages/Online CE Performance/subs/helpers";
import { computeComparison, percentageShareCalculation, periodComparison } from "../../../pages/SEO/helpers/helpers";
import { move } from "../../../utils/utilityFunctions";
import { ToggleSwitch } from "../../Toogle";
import { getOrganicVisitsColumns } from "./subs/columns";
import { searchCriteriaOptions, SEARCH_TERM_MAP } from "./subs/seoConstants";
import SeoDefaultTable from "./subs/seoDefaultTable";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const VisitsBreakdownTable = (): JSX.Element => {
  const dispatch = useDispatch();
  //Param values
  const visits_search_term = useSelector((state: RootStateOrAny) => state.seo_parameters.visits_search_term);
  const visitsDisabled = useSelector((state: RootStateOrAny) => state.seo_parameters.visits_disabled);
  const landingPage = useSelector((state: RootStateOrAny) => state.seo_parameters.landing_page);
  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.seo_parameters.date_range);

  const visits_breakdown = useSelector((state: RootStateOrAny) => state.seo_data.visits_breakdown);
  const [showVisitsHidden, setShowVisitsHidden] = useState(false);
  const [showVisitsShare, setShowVisitsShare] = useState(false);
  const [tableData, setTableData] = useState([]);

  const { popLabel } = getDateDependentValues(dateRangeParamValue);
  const metric = "organic_visits";
  // Search variables
  const [filter, setFilter] = useState<string>(visits_search_term);
  const [searchCriteria, setSearchCriteria] = useState<any>("CONTAINS");
  const { wrapperRef, isVisible, setIsVisible } = useVisible(false);
  const [searchCriteriaInputValue, setSearchCriteriaInputValue] = useState<keyof typeof SEARCH_TERM_MAP>("Contains");

  // Resets filter selection on mount
  useEffect(() => {
    setFilter("");
    dispatch(setSpecificParameter("visits_search_term", ""));
    dispatch(setSpecificParameter("visits_search_type", ""));
  }, [dispatch]);

  //Data manipulation REFACTOR
  useEffect(() => {
    if (visits_breakdown.length > 0) {
      const { organic_visits: totalVisits } = visits_breakdown[0].totals[0].current_data;

      const myData = visits_breakdown[0].data.map((data: any) => {
        const {
          current_data: { organic_visits: organicVisits },
        } = data;

        const yoy_data = data.yoy_data;
        const pop_data = data.pop_data;

        const popValue = typeof pop_data?.[metric] !== "number" ? 0 : pop_data?.[metric];
        const yoyValue = typeof yoy_data?.[metric] !== "number" ? 0 : yoy_data?.[metric];

        const popComparison = periodComparison(organicVisits, popValue);
        const yoyComparison = periodComparison(organicVisits, yoyValue);

        const popPercentage = parseFloat(computeComparison(organicVisits, popValue));
        const yoyPercentage = parseFloat(computeComparison(organicVisits, yoyValue));

        const percentageShare = percentageShareCalculation(organicVisits, totalVisits);

        return { ...data, popPercentage, yoyPercentage, popComparison, yoyComparison, percentageShare };
      });
      //Remove row with All query on the visits breakdown table
      const visitsData = myData?.filter(({ hostname }: { hostname: string }) => hostname !== "All");
      const selectedRow = visitsData?.filter((row: any) => row.hostname === landingPage)[0];
      const formattedData = selectedRow ? move(visitsData, visitsData.indexOf(selectedRow), 0) : visitsData;
      setTableData(formattedData);
    } else {
      setTableData([]);
    }
  }, [visits_breakdown, landingPage]);

  //Handle click on landing page
  const handleVisitsClick = (event: any) => {
    event.preventDefault();
    const { target } = event;
    const query = target.getAttribute("data-text");
    if (query === landingPage) {
      dispatch(setSpecificParameter("landing_page", "All"));
    } else {
      dispatch(setSpecificParameter("landing_page", query));
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setFilter(value);
  };

  const tableSortBy = landingPage === "All" ? [{ id: "volumeColumn", desc: true }] : [];

  useEffect(() => {
    const selectedSearchCriteria = searchCriteriaOptions.filter(
      (searchCriteriaOption) => searchCriteriaOption.ranking === searchCriteria
    )[0];
    setSearchCriteriaInputValue(selectedSearchCriteria.option);
  }, [searchCriteria]);

  const handleApplyToTrendline = () => {
    dispatch(setSpecificParameter("visits_search_term", filter));
    dispatch(setSpecificParameter("visits_search_type", SEARCH_TERM_MAP[searchCriteriaInputValue]));
  };

  return (
    <div className={`breakdown-table ${metric}-breakdown ${visitsDisabled ? "disabled" : ""}`}>
      {tableData.length === 0 ? (
        <p>No table data</p>
      ) : (
        <>
          <>
            <div className="d-flex table-menu">
              <ToggleSwitch
                activeToggleLabel={""}
                inactiveToggleLabel={"Show % share"}
                active={!showVisitsShare}
                handleToggleClick={() => setShowVisitsShare(!showVisitsShare)}
                toggleClassName={"seo_table_toggle"}
              />

              <div className="hidden-columns" data-test-id="visits-hidden-column">
                <span onClick={() => setShowVisitsHidden(!showVisitsHidden)}>
                  {showVisitsHidden ? "Hide variations in volume" : "Show variations in volume"}
                </span>
              </div>
            </div>

            <div className="search_container search_bar" data-test-id="visits_search_container">
              {/*SEARCH CRITERIA */}
              <div className="search_criteria " data-test-id="visits_search_criteria_div">
                <div className="filter no-border" ref={wrapperRef}>
                  <label className="filter_header">SEARCH QUERY</label>
                  <div className="input_arrow" onClick={() => setIsVisible(!isVisible)}>
                    <span className="arrow_down" />
                    <input
                      className={"filter_input"}
                      value={searchCriteriaInputValue}
                      readOnly
                      data-test-id="visits_search_criteria_input"
                    />
                  </div>
                  <ul className={`filter_menu ${isVisible ? "showOptions" : ""}`} data-test-id="visits_search_criteria_ul">
                    {searchCriteriaOptions.map((option) => {
                      return (
                        <li className="filter__option" key={Math.random()} onClick={() => setSearchCriteria(option.ranking)}>
                          {option.option}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              {/* SEARCH BAR*/}
              <div className="search_bar" data-test-id="visits_search_bar_div">
                <div className="input_arrow">
                  <input
                    className={"filter_input"}
                    value={filter}
                    onChange={handleInputChange}
                    placeholder="Search..."
                    data-test-id="visits_search_bar_input"
                  />
                  {!filter ? (
                    <span className="search_bar_icon search" />
                  ) : (
                    <span
                      className="search_bar_icon cancel"
                      onClick={() => {
                        setFilter("");
                        dispatch(setSpecificParameter("visits_search_term", ""));
                        dispatch(setSpecificParameter("visits_search_type", ""));
                      }}
                    />
                  )}
                </div>
              </div>
              {filter && (
                <div className="chart_apply_btn">
                  <div className="btn" onClick={handleApplyToTrendline}>
                    Apply to trendline
                  </div>
                </div>
              )}
            </div>
          </>
          <SeoDefaultTable
            data={tableData}
            columns={getOrganicVisitsColumns({
              handleVisitsClick: handleVisitsClick,
              popLabel: popLabel,
              showVisitsShare: showVisitsShare,
            })}
            sortBy={tableSortBy}
            showHidden={showVisitsHidden}
            selected={landingPage}
            showChildRows={false}
            canSort={!visitsDisabled}
            key={Math.random()}
            filter={filter}
            filters={searchCriteria}
            isVisitsQuery={true}
          />
        </>
      )}
    </div>
  );
};

export default VisitsBreakdownTable;
