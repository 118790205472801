import { SOV_MIX_COLORS } from "../../../constants";

export const getCustomerIntentionChartData = (data: any, nimTech?: boolean) => {
  if (data?.length) {
    const res: any = [];

    data?.map((row: any) => {
      const key = nimTech ? row?.nim?.replace(/\s+/g, "_")?.toLowerCase() : row.grade.replace(/\s+/g, "_").toLowerCase();
      const dates = Array.from(new Set(res.map((val: { date: string }) => val.date)));
      if (dates.includes(row.date)) {
        res?.map((val: { date: any }, idx: number) => {
          if (val.date === row.date) {
            const value: any = { ...val };
            value[`${key}_customer_intention`] = nimTech ? row?.nim_tech_customer_intention : row.customer_intention;
            value[`${key}_customer_intention_ratio`] = nimTech ? row?.nim_tech_customer_intention_ratio : row.customer_intention_ratio;
            value[`${key}_actual_sales`] = nimTech ? row?.nim_tech_actual_sales : row.actual_sales;
            value[`${key}_actual_sales_ratio`] = nimTech ? row?.nim_tech_actual_sales_ratio : row.actual_sales_ratio;
            res.splice(idx, 1, value);
          }
        });
      } else {
        const result: any = { date: row.date };
        result[`${key}_customer_intention`] = nimTech ? row?.nim_tech_customer_intention : row.customer_intention;
        result[`${key}_customer_intention_ratio`] = nimTech ? row?.nim_tech_customer_intention_ratio : row.customer_intention_ratio;
        result[`${key}_actual_sales`] = nimTech ? row?.nim_tech_actual_sales : row.actual_sales;
        result[`${key}_actual_sales_ratio`] = nimTech ? row?.nim_tech_actual_sales_ratio : row.actual_sales_ratio;
        result["quarter"] = row.quarter;
        res.push(result);
      }
    });
    return res;
  }
  return [];
};

export const getCustomerIntentionSeriesList = (data: any, integrationData: any, nimTech?: boolean) => {
  if (data?.length && integrationData?.length) {
    const order = Array.from(new Set(integrationData?.map((val: { grade: string; nim?: string }) => (nimTech ? val?.nim : val.grade))));

    const services: Set<string> = new Set(data?.map((row: any) => (nimTech ? row?.nim : row.grade)));
    return Array.from(services)
      .map((service) => {
        const key = service?.replace(/\s+/g, "_")?.toLowerCase();
        return {
          name: service,
          volumeField: `${key}_customer_intention`,
          color: `${SOV_MIX_COLORS[order?.indexOf(service)]}`,
          gradeField: `${key}_customer_intention_ratio`,
        };
      })
      .sort((a, b) => order?.indexOf(a.name) - order?.indexOf(b.name))
      .reverse();
  }
  return [];
};

export const getSalesByGradeSeriesList = (data: any, integrationData: any, nimTech?: boolean) => {
  if (data?.length && integrationData?.length) {
    const order = Array.from(new Set(integrationData?.map((val: { grade: string; nim?: string }) => (nimTech ? val?.nim : val.grade))));
    const services: Set<string> = new Set(data?.map((row: any) => (nimTech ? row?.nim : row.grade)));

    return Array.from(services)
      .map((service) => {
        const key = service?.replace(/\s+/g, "_")?.toLowerCase();
        return {
          name: service,
          volumeField: `${key}_actual_sales`,
          color: `${SOV_MIX_COLORS[order?.indexOf(service)]}`,
          gradeField: `${key}_actual_sales_ratio`,
        };
      })
      ?.sort((a, b) => order?.indexOf(a?.name) - order?.indexOf(b?.name))
      ?.reverse();
  }
  return [];
};
