import React from "react";

interface Props {
  name: string;
  value: string | number;
}

export const HiddenInput = (props: Props) => {
  const { name, value } = props;

  return <input type="hidden" name={name} value={value} id={name} />;
};
