import { put, retry, takeLatest } from "redux-saga/effects";
import {
  FETCH_ACTIVATION_COUNTRY_DATA,
  FETCH_ACTIVATION_DEALER_DATA,
  FETCH_ACTIVATION_MODEL_DATA,
  FETCH_ACTIVATION_OVERALL_DATA,
  FETCH_COCKPIT_ACTIVATION_DATA,
  FETCH_COCKPIT_ACTIVATION_OVERALL_DATA,
} from "../actions/actionTypes";
import {
  setActivationCountryData,
  setActivationDealerData,
  setActivationModelData,
  setActivationOverallData,
  setCockpitActivation,
} from "../actions/europeActivationActions";
import { setLoading } from "../actions/loadingActions";
import { getActivationData, getCockpitActivationData, getCockpitActivationDataComparison } from "../api/europeActivationApi";
import { secondsDelay } from "../api/utils";
import {
  IActivationCountryData,
  IActivationDealerData,
  IActivationModelData,
  IActivationOverallData,
} from "../constants/interface/europeActivation/activationData";

export function* watchFetchActivationOverallDataSaga() {
  yield takeLatest(FETCH_ACTIVATION_OVERALL_DATA, fetchActivationOverallDataSaga);
}

export function* watchFetchCockpitActivationOverallDataSaga() {
  yield takeLatest(FETCH_COCKPIT_ACTIVATION_OVERALL_DATA, fetchCockpitActivationOverallDataSaga);
}

export function* watchFetchActivationCountryDataSaga() {
  yield takeLatest(FETCH_ACTIVATION_COUNTRY_DATA, fetchActivationCountryDataSaga);
}

export function* watchFetchActivationModelDataSaga() {
  yield takeLatest(FETCH_ACTIVATION_MODEL_DATA, fetchActivationModelDataSaga);
}

export function* watchFetchCockpitActivationData() {
  yield takeLatest(FETCH_COCKPIT_ACTIVATION_DATA, fetchCockpitActivationData);
}

export function* watchFetchActivationDealerDataSaga() {
  yield takeLatest(FETCH_ACTIVATION_DEALER_DATA, fetchActivationDealerDataSaga);
}

export function* fetchActivationOverallDataSaga() {
  try {
    yield put(setLoading("activation_overall_data", true));

    const response: IActivationOverallData = yield retry(4, secondsDelay, getCockpitActivationData, "overall");
    if (!("error" in response)) {
      yield put(setActivationOverallData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("activation_overall_data", false));
  }
}

export function* fetchCockpitActivationOverallDataSaga() {
  try {
    yield put(setLoading("activation_overall_data", true));

    const response: IActivationOverallData = yield retry(4, secondsDelay, getCockpitActivationDataComparison, "overall");
    if (!("error" in response)) {
      yield put(setActivationOverallData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("activation_overall_data", false));
  }
}

export function* fetchActivationCountryDataSaga({ payload: cumulative }: any) {
  try {
    yield put(setLoading("activation_country_data", true));

    const response: IActivationCountryData = yield retry(
      4,
      secondsDelay,
      getActivationData,

      "country",
      cumulative
    );
    if (!("error" in response)) {
      yield put(setActivationCountryData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("activation_country_data", false));
  }
}

export function* fetchActivationModelDataSaga({ payload: cumulative }: any) {
  try {
    yield put(setLoading("activation_model_data", true));

    const response: IActivationModelData = yield retry(4, secondsDelay, getActivationData, "model", cumulative);
    if (!("error" in response)) {
      yield put(setActivationModelData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("activation_model_data", false));
  }
}

export function* fetchCockpitActivationData({ payload: cumulative }: any) {
  try {
    yield put(setLoading("cockpit_activation", true));

    const response: IActivationCountryData = yield retry(4, secondsDelay, getCockpitActivationData, "total", cumulative);

    if (!("error" in response)) {
      yield put(setCockpitActivation(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("cockpit_activation", false));
  }
}

export function* fetchActivationDealerDataSaga({ payload: cumulative }: any) {
  try {
    yield put(setLoading("activation_dealer_data", true));

    const response: IActivationDealerData = yield retry(
      4,
      secondsDelay,
      getActivationData,

      "dealer",
      cumulative
    );
    if (!("error" in response)) {
      yield put(setActivationDealerData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("activation_dealer_data", false));
  }
}
