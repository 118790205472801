import React, { useCallback, useContext, useEffect, useState } from "react";
import { FiltersLayout } from "../Layouts";
import { ErrorBoundary } from "@sentry/react";
import { ErrorMsg } from "../AppMessages";
import PurchaseFunnelDateFilter from "./common/PurchaseFunnelDateFilter";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { DateFilter, DefaultFilter, MultiSelectFilter, DMEOCampaignNameFilter } from "./common";
import { closeFilterOptions, closeMarketOptions } from "./subs/helpers";
import { setParameters, setSpecificParameter } from "../../actions";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { FilterContext } from "../../context";
import { ALL_COUNTRIES, DEFAULT_DMEO_PARAMETERES, MIN_SELECTABLE_DATE, SEO_DATE_FILTER_OPTIONS } from "../../constants";
import { RouteComponentProps, withRouter } from "react-router-dom";
import moment from "moment";
import { currentDefaultFiltersSet, defaultFiltersSet } from "../../utils/utilityFunctions";
import { useQuery } from "@tanstack/react-query";
import { getDmeoFilters } from "../../api";

interface Props {
  hasLandingPage?: boolean;
  metric?: string;
}

const DmeoFilters = withRouter((props: RouteComponentProps & Props) => {
  const { history, hasLandingPage, metric = "" } = props;
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const {
    date_range: dateRangeParamValue,
    market: marketParamValue,
    channel: channelParamValue,
    campaign_objective: campaignObjectiveParamValue,
    model: modelParamValue,
    landing_page: landingPageParamValue,
    campaign_name: campaignNameParamValue,
  } = useSelector((state: RootStateOrAny) => state.parameters);

  const [modelFilterValue, setModelFilterValue] = useState(modelParamValue);

  const dataRefresh = undefined;

  //Sets the initial start and end selectable date to be 1st April 2019 and the latest data refresh | today() - 1 (yesterday) if the last data refresh is not yet defined
  const [marketDataAvailability, setMarketDataAvailability] = useState<{ start: string; end: string }>({
    start: MIN_SELECTABLE_DATE,
    end: dataRefresh || moment().subtract(1, "days").format("DD/MM/YYYY"),
  });

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("Dmeo dashboard");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_DMEO_PARAMETERES));
      currentDefaultFiltersSet("Dmeo dashboard");
    }
  }, []);

  const { data, refetch } = useQuery({
    queryKey: ["dmeoFiltersData", history.location.search],
    queryFn: getDmeoFilters,
    initialData: {},
    enabled: false,
  });

  const {
    channel: channels,
    market: markets,
    objective: campaignObjectives,
    model: models,
    landing_page: landingPages,
    campaign_name: campaignName,
  } = data;

  useEffect(() => {
    if (!SEO_DATE_FILTER_OPTIONS.includes(dateRangeParamValue)) {
      dispatch(setSpecificParameter("date_range", SEO_DATE_FILTER_OPTIONS[3]));
    }
  }, []);

  useEffect(() => {
    refetch()
  }, [history.location.search])

  useEffect(() => {
    if (marketParamValue == ALL_COUNTRIES || (markets && marketParamValue != "All" && !markets.includes(marketParamValue))) {
      dispatch(setSpecificParameter("market", "All"));
    }
  }, [marketParamValue, markets]);

  useEffect(() => {
    if (channels && channelParamValue != "All" && !channels.includes(channelParamValue)) {
      dispatch(setSpecificParameter("channel", "All"));
    }
  }, [channelParamValue, channels]);

  useEffect(() => {
    if (campaignObjectives && campaignObjectiveParamValue != "All" && !campaignObjectives.includes(campaignObjectiveParamValue)) {
      dispatch(setSpecificParameter("campaign_objective", "All"));
    }
  }, [campaignObjectiveParamValue, campaignObjectives]);

  useEffect(() => {
    if (models?.length && modelParamValue) {
      const selectedModels =
        models?.length === modelParamValue.split(",")?.length
          ? "All"
          : modelParamValue.includes(",")
            ? `${modelParamValue.split(",")?.length} models selected`
            : modelParamValue;
      setModelFilterValue(selectedModels);
    }
  }, [modelParamValue, models]);

  useEffect(() => {
    if (modelParamValue != "All" && models) {
      const selectedModel = modelParamValue?.split(",");
      const modelsAreValid = selectedModel?.every((model: string) => models.includes(model));
      !modelsAreValid && dispatch(setSpecificParameter("model", "All"));
    }
  }, [modelParamValue, models]);

  useEffect(() => {
    if (landingPages && landingPageParamValue != "All" && !landingPages.includes(landingPageParamValue)) {
      dispatch(setSpecificParameter("landing_page", "All"));
    }
  }, [landingPageParamValue, landingPages]);

  useEffect(() => {
    if (campaignName && campaignNameParamValue != "All" && !campaignName.includes(campaignNameParamValue)) {
      dispatch(setSpecificParameter("campaign_name", "All"));
    }
  }, [campaignNameParamValue, campaignName]);

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));
    }
    eventTracking(MixpanelEvents.filter_change, {
      filter: filterName,
      value: optionValue,
      dashboard: "DMEO",
    });
    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);
  };

  const handleModelFilterSubmission = useCallback(() => {
    const parameterName = "model";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = selectedValues?.length === models?.length ? "All" : selectedValues?.join(",");
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, {
      filter: parameterName,
      value: filterValue,
      dashboard: "DMEO",
    });
    closeFilterOptions();
  }, [models]);

  //reset filter button functionality
  const resetFilters = useCallback(() => {
    dispatch(setParameters(DEFAULT_DMEO_PARAMETERES));
  }, []);

  // Set params
  useEffect(() => {
    const params = new URLSearchParams();

    dateRangeParamValue && params.set("date_range", dateRangeParamValue);
    marketParamValue && params.set("market", marketParamValue);
    channelParamValue && params.set("channel", channelParamValue);
    campaignObjectiveParamValue && params.set("campaign_objective", campaignObjectiveParamValue);
    modelParamValue && params.set("model", modelParamValue);
    hasLandingPage && landingPageParamValue && params.set("landing_page", landingPageParamValue);
    metric == "Campaign Performance" && campaignNameParamValue && params.set("campaign_name", campaignNameParamValue);

    history.push({ search: `?${params.toString()}` });
  }, [
    dateRangeParamValue,
    marketParamValue,
    channelParamValue,
    campaignObjectiveParamValue,
    modelParamValue,
    landingPageParamValue,
    hasLandingPage,
    metric,
    campaignNameParamValue,
  ]);

  return (
    <FiltersLayout resetFilters={resetFilters}>
      <>
        {/*Date Range*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <PurchaseFunnelDateFilter
            value={dateRangeParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
            onFilterClick={showFilterOptionsFn}
          />
        </ErrorBoundary>

        {/*Channel*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={markets ? ["All", ...markets] : ["All"]}
            filterName={"market"}
            filterLabel={"MARKET"}
            filterValue={marketParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Channel*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={channels ? ["All", ...channels] : ["All"]}
            filterName={"channel"}
            filterLabel={"CHANNEL"}
            filterValue={channelParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Campaign Objective*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={campaignObjectives ? ["All", ...campaignObjectives] : ["All"]}
            filterName={"campaign_objective"}
            filterLabel={"CAMPAIGN OBJECTIVE"}
            filterValue={campaignObjectiveParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Model*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="model"
            filterName={"MODEL"}
            // @ts-ignore
            filterList={models ?? []}
            value={modelFilterValue}
            parameterName={"model"}
            parameterValue={modelParamValue}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleModelFilterSubmission}
          />
        </ErrorBoundary>

        {hasLandingPage && (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <DefaultFilter
              list={landingPages ? ["All", ...landingPages] : ["All"]}
              filterName={"landing_page"}
              filterLabel={"LANDING PAGE"}
              filterValue={landingPageParamValue}
              handleFilterOptionClick={handleFilterOptionClick}
            />
          </ErrorBoundary>
        )}
        {metric == "Campaign Performance" && (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <DMEOCampaignNameFilter
              list={campaignName ? ["All", ...campaignName] : ["All"]}
              filterName={"campaign_name"}
              filterLabel={"CAMPAIGN NAME"}
              filterValue={campaignNameParamValue}
              handleFilterOptionClick={handleFilterOptionClick}
            />
          </ErrorBoundary>
        )}
      </>
    </FiltersLayout>
  );
});

export default DmeoFilters;
