import { put, retry, takeLatest } from "redux-saga/effects";
import { setFlopServices, setServiceGroups, setTopFlopServices, setTopModels, setTopRegions, setTopServices } from "../actions";
import {
  FETCH_FLOP_SERVICES,
  FETCH_SERVICE_GROUPS,
  FETCH_TOP_FLOP_SERVICES,
  FETCH_TOP_MODELS,
  FETCH_TOP_REGIONS,
  FETCH_TOP_SERVICES,
} from "../actions/actionTypes";
import { setLoading } from "../actions/loadingActions";
import {
  getFlopServicesData,
  getServiceGroupsData,
  getTopFlopServicesData,
  getTopModelsData,
  getTopRegionsData,
  getTopServicesData,
} from "../api/topFlopApi";
import { secondsDelay } from "../api/utils";

// Watcher sagas
export function* watchFetchTopFlopServicesDataSaga() {
  yield takeLatest(FETCH_TOP_FLOP_SERVICES, fetchTopFlopServicesData);
}

export function* watchFetchTopServicesDataSaga() {
  yield takeLatest(FETCH_TOP_SERVICES, fetchTopServicesData);
}

export function* watchFetchFlopServicesDataSaga() {
  yield takeLatest(FETCH_FLOP_SERVICES, fetchFlopServicesData);
}

export function* watchFetchTopModelsDataSaga() {
  yield takeLatest(FETCH_TOP_MODELS, fetchTopModelsData);
}

export function* watchFetchServiceGroupsDataSaga() {
  yield takeLatest(FETCH_SERVICE_GROUPS, fetchServiceGroupsData);
}

export function* watchFetchTopRegionsDataSaga() {
  yield takeLatest(FETCH_TOP_REGIONS, fetchTopRegionsData);
}
// Worker sagas
export function* fetchTopFlopServicesData() {
  try {
    yield put(setLoading("top_flop_services", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getTopFlopServicesData);
    if (!("error" in response)) {
      yield put(setTopFlopServices(response));
    }
  } catch (err) {
    console.log("failed to fetch top flop services");
  } finally {
    yield put(setLoading("top_flop_services", false));
  }
}

export function* fetchTopServicesData() {
  try {
    yield put(setLoading("top_flop_top_services", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getTopServicesData);
    if (!("error" in response)) {
      yield put(setTopServices(response));
    }
  } catch (err) {
    console.log("failed to fetch top services");
  } finally {
    yield put(setLoading("top_flop_top_services", false));
  }
}

export function* fetchFlopServicesData() {
  try {
    yield put(setLoading("top_flop_flop_services", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getFlopServicesData);
    if (!("error" in response)) {
      yield put(setFlopServices(response));
    }
  } catch (err) {
    console.log("failed to fetch flop services");
  } finally {
    yield put(setLoading("top_flop_flop_services", false));
  }
}

export function* fetchTopModelsData() {
  try {
    yield put(setLoading("top_flop_top_models", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getTopModelsData);
    if (!("error" in response)) {
      yield put(setTopModels(response));
    }
  } catch (err) {
    console.log("failed to fetch top models");
  } finally {
    yield put(setLoading("top_flop_top_models", false));
  }
}

export function* fetchServiceGroupsData() {
  try {
    yield put(setLoading("top_flop_service_groups", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getServiceGroupsData);
    if (!("error" in response)) {
      yield put(setServiceGroups(response));
    }
  } catch (err) {
    console.log("failed to fetch service groups");
  } finally {
    yield put(setLoading("top_flop_service_groups", false));
  }
}

export function* fetchTopRegionsData() {
  try {
    yield put(setLoading("top_flop_top_regions", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getTopRegionsData);
    if (!("error" in response)) {
      yield put(setTopRegions(response));
    }
  } catch (err) {
    console.log("failed to fetch top regions");
  } finally {
    yield put(setLoading("top_flop_top_regions", false));
  }
}
