import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { fetchMsrpOverTimeByModel, fetchVmeDiscountOverTimeByModel } from "../../../actions";
import { ErrorMsg } from "../../../components/AppMessages";
import MsrpOverTimeChart from "../../../components/Charts/MSRP/MsrpOverTimeChart";
import { InAppMultiSelectFilter } from "../../../components/Filters/common/InAppMultiselectFilter";
import { closeFilterOptions } from "../../../components/Filters/subs/helpers";
import { ThemeContext } from "../../../context";

const OverTimeGraphs = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { data: msrpData, series: msrpSeries } = useSelector((state: RootStateOrAny) => state.msrp.msrp_over_time_by_model);
  const { data: vmeData, series: vmeSeries } = useSelector((state: RootStateOrAny) => state.msrp.vme_discount_over_time_by_model);
  const { model: modelsList } = useSelector((state: RootStateOrAny) => state.filters.msrp_filters);
  const { msrp_over_time_by_model: msrpOverTimeByModelLoading } = useSelector((state: RootStateOrAny) => state.loading);
  const { vme_discount_over_time_by_model: vmeDiscountOverTimeByModelLoading } = useSelector((state: RootStateOrAny) => state.loading);

  const [modelFilterValue, setModelFilterValue] = useState("All");

  useEffect(() => {
    dispatch(fetchMsrpOverTimeByModel(modelFilterValue));
    dispatch(fetchVmeDiscountOverTimeByModel(modelFilterValue));
  }, [history.location.search, modelFilterValue]);

  const isSingleModel = useMemo(() => {
    return modelFilterValue == "All" || modelFilterValue.split(",").length > 1 ? false : true;
  }, [modelFilterValue]);

  const handleModelMultiselectSubmit = useCallback(() => {
    const parameterName = "msrp_over_time_model";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = modelsList?.length === selectedValues?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    setModelFilterValue(filterValue);
  }, [modelsList]);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className="full-width-row">
        <div className="msrp-tile">
          <div className="full-width-row">
            <div className="msrp-tile">
              <div className="tile-header">
                MSRP Over Time by Model
                <Tooltip
                  position="top-end"
                  className={"normal_tippy"}
                  trigger="mouseenter"
                  animation="none"
                  interactive={true}
                  distance={50}
                  delay={1}
                  hideDelay={1}
                  duration={1}
                  title={
                    "This graph has a unique filter to select 1 or multiple models. When multiple models are selected, the lines are aggregated together."
                  }
                  size={"small"}
                >
                  <span className="info_tooltip" />
                </Tooltip>
                <div className="in-app-filter">
                  <InAppMultiSelectFilter
                    filterLabel="Select Models"
                    optionsList={modelsList}
                    filterValue={modelFilterValue}
                    parameterName={`msrp_over_time_model`}
                    parameterValue={modelFilterValue}
                    handleFilterSubmission={handleModelMultiselectSubmit}
                  />
                </div>
              </div>
              <MsrpOverTimeChart
                data={msrpData}
                chartName="msrpOverTimeByModelChart"
                theme={themeContext.theme}
                seriesList={msrpSeries}
                isLoading={msrpOverTimeByModelLoading}
                isSingleModel={isSingleModel}
              />
            </div>
          </div>

          <div className="full-width-row">
            <div className="msrp-tile">
              <div className="tile-header">
                VME Discount Over Time by Model
                <Tooltip
                  position="top-end"
                  className={"normal_tippy"}
                  trigger="mouseenter"
                  animation="none"
                  interactive={true}
                  distance={50}
                  delay={1}
                  hideDelay={1}
                  duration={1}
                  title={
                    "This graph has a unique filter to select 1 or multiple models. When multiple models are selected, the lines are aggregated together."
                  }
                  size={"small"}
                >
                  <span className="info_tooltip" />
                </Tooltip>
              </div>
              <MsrpOverTimeChart
                data={vmeData}
                chartName="vmeDiscountOverTimeByModelChart"
                theme={themeContext.theme}
                seriesList={vmeSeries}
                isLoading={vmeDiscountOverTimeByModelLoading}
                isSingleModel={isSingleModel}
                isPercentage={true}
              />
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
});

export default OverTimeGraphs;
