import { AnyAction } from "redux";
import { put, retry, takeLatest } from "redux-saga/effects";
import {
  setBrandPerformanceLastDataRefresh,
  setCpoOperationalDataRefresh,
  setEcommerceLastDataRefresh,
  setExecutiveSummaryLastDataRefresh,
  setJourneyExcellenceLastDataRefresh,
  setLastDataRefresh,
  setMarketDataRefresh,
  setMidasFmiLastDataRefresh,
  setMsrpLastDataRefresh,
  setOceLastRefreshDate,
  setProductPerformanceLastDataRefresh,
  setUseOwnershipLastDataRefresh,
  setVocLastDataRefresh,
} from "../actions";
import {
  FETCH_ACTIVATION_DATA_REFRESH,
  FETCH_BRAND_PERFORMANCE_LAST_DATA_REFRESH,
  FETCH_CPO_OPERATIONAL_LAST_REFRESH_DATE,
  FETCH_ECOMMERCE_LAST_REFRESH_DATE,
  FETCH_EXECUTIVE_SUMMARY_LAST_DATA_REFRESH,
  FETCH_JOURNEY_EXCELLENCE_LAST_DATA_REFRESH,
  FETCH_LAST_DATA_REFRESH,
  FETCH_LAST_DATA_REFRESH_CPO,
  FETCH_MARKET_DATA_REFRESH,
  FETCH_MIDAS_FIXED_LAST_REFRESH_DATE,
  FETCH_MIDAS_FMI_BY_MODEL_LAST_REFRESH_DATE,
  FETCH_MIDAS_FMI_LAST_REFRESH_DATE,
  FETCH_MIDAS_MEDIA_MIX_LAST_REFRESH_DATE,
  FETCH_MIDAS_MTP_CATEGORY_LAST_REFRESH_DATE,
  FETCH_MIDAS_SPEND_VS_TRAFFIC_LAST_REFRESH_DATE,
  FETCH_MIDAS_STRATEGIC_CE_LAST_REFRESH_DATE,
  FETCH_MIDAS_STRATEGIC_DEALER_SUPPORT_LAST_REFRESH_DATE,
  FETCH_MSRP_LAST_DATA_REFRESH,
  FETCH_MTM_LAST_DATA_REFRESH,
  FETCH_OCE_LAST_REFRESH_DATE,
  FETCH_PRODUCT_MARKETING_OPTIMIZATION_LAST_DATA_REFRESH,
  FETCH_PRODUCT_PERFORMANCE_LAST_REFRESH_DATE,
  FETCH_SEO_LAST_DATA_REFRESH,
  FETCH_USE_OWNERSHIP_LAST_DATA_REFRESH,
  FETCH_VOC_LAST_DATA_REFRESH,
} from "../actions/actionTypes";
import {
  getBrandPerformanceLastRefreshDate,
  getCpoOperationalLastRefreshDate,
  getExecutiveSummaryLastRefreshData,
  getJourneyExcellenceLastRefreshDate,
  getLastDataRefresh,
  getMarketDataRefresh,
  getMidasFixedLastRefreshDate,
  getMidasFmiByModelLastRefreshDate,
  getMidasFmiLastRefreshDate,
  getMidasMediaMixLastRefreshDate,
  getMidasMtpCategoryLastRefreshDate,
  getMidasSpendVsTrafficLastRefreshDate,
  getMidasStrategicCELastRefreshDate,
  getMidasStrategicDealerSupportLastRefreshDate,
  getMsrpLastDataRefresh,
  getMtmLastDataRefresh,
  getOCELastDataRefresh,
  getProductMarketingOptimizationLastDataRefresh,
  getProductPerformanceLastRefreshDate,
  getUseOwnershipLastRefreshDate,
  getVocLastRefreshDate,
} from "../api";
import { getCpoLastDataRefresh } from "../api/cpoDashboardApi";
import { getEcommerceLastDataRefresh } from "../api/ecommerce";
import { getActivationLastDataRefresh } from "../api/europeActivationApi";
import { getSeoLastUpdatedData } from "../api/seoApi";
import { secondsDelay } from "../api/utils";
import { ALL_COUNTRIES, ALL_OPTION } from "../constants";
import { IMarketDataRefresh } from "../constants/interface";

//Watcher sagas
export function* watchFetchLastDataRefresh() {
  yield takeLatest(FETCH_LAST_DATA_REFRESH, fetchLastDataRefresh);
}

export function* watchMtmFetchLastDataRefresh() {
  yield takeLatest(FETCH_MTM_LAST_DATA_REFRESH, fetchMtmLastDataRefreshData);
}

export function* watchActivationFetchLastDataRefresh() {
  yield takeLatest(FETCH_ACTIVATION_DATA_REFRESH, fetchActivationLastDataRefreshData);
}

export function* watchFetchMarketSpecificDataRefresh() {
  yield takeLatest(FETCH_MARKET_DATA_REFRESH, fetchMarketDataRefreshData);
}

export function* watchFetchSeoLastDataRefreshSaga() {
  yield takeLatest(FETCH_SEO_LAST_DATA_REFRESH, fetchSeoLastDataRefreshSaga);
}

export function* watchFetchCpoLastDataRefresh() {
  yield takeLatest(FETCH_LAST_DATA_REFRESH_CPO, fetchCpoLastDataRefresh);
}

export function* watchFetchProductMarketingOptimizationLastDataRefresh() {
  yield takeLatest(FETCH_PRODUCT_MARKETING_OPTIMIZATION_LAST_DATA_REFRESH, fetchProductMarketingOptimizationLastDataRefreshSaga);
}

export function* watchFetchOCELastDataRefresh() {
  yield takeLatest(FETCH_OCE_LAST_REFRESH_DATE, fetchOceLastDataRefreshSaga);
}

export function* watchFetchMidasFmiLastRefreshDate() {
  yield takeLatest(FETCH_MIDAS_FMI_LAST_REFRESH_DATE, fetchMidasFmiLastRefreshDateSaga);
}

export function* watchFetchMidasFmiByModelLastRefreshDate() {
  yield takeLatest(FETCH_MIDAS_FMI_BY_MODEL_LAST_REFRESH_DATE, fetchMidasFmiByModelLastRefreshDateSaga);
}

export function* watchFetchMidasMediaMixLastRefreshDate() {
  yield takeLatest(FETCH_MIDAS_MEDIA_MIX_LAST_REFRESH_DATE, fetchMidasMediaMixLastRefreshDateSaga);
}

export function* watchFetchMidasMtpCategoryLastRefreshDate() {
  yield takeLatest(FETCH_MIDAS_MTP_CATEGORY_LAST_REFRESH_DATE, fetchMidasMtpCategoryLastRefreshDateSaga);
}

export function* watchFetchMidasStrategicDealerSupportLastRefreshDate() {
  yield takeLatest(FETCH_MIDAS_STRATEGIC_DEALER_SUPPORT_LAST_REFRESH_DATE, fetchMidasStrategicDealerSupportLastRefreshDateSaga);
}

export function* watchFetchMidasStrategicCELastRefreshDate() {
  yield takeLatest(FETCH_MIDAS_STRATEGIC_CE_LAST_REFRESH_DATE, fetchMidasStrategicCELastRefreshDateSaga);
}

export function* watchFetchMidasFixedLastRefreshDate() {
  yield takeLatest(FETCH_MIDAS_FIXED_LAST_REFRESH_DATE, fetchMidasFixedLastRefreshDateSaga);
}

export function* watchFetchMidasSpendVsTrafficLastRefreshDate() {
  yield takeLatest(FETCH_MIDAS_SPEND_VS_TRAFFIC_LAST_REFRESH_DATE, fetchMidasSpendVsTrafficLastRefreshDateSaga);
}

export function* watchFetchCpoOperationalLastRefreshDate() {
  yield takeLatest(FETCH_CPO_OPERATIONAL_LAST_REFRESH_DATE, fetchCpoOperationalLastRefreshDateSaga);
}

export function* watchFetchProductPerformanceLastRefreshDate() {
  yield takeLatest(FETCH_PRODUCT_PERFORMANCE_LAST_REFRESH_DATE, fetchProductPerformanceLastRefreshDateSaga);
}

export function* watchFetchEcommerceLastRefreshDate() {
  yield takeLatest(FETCH_ECOMMERCE_LAST_REFRESH_DATE, fetchEcommerceLastRefreshDateSaga);
}

export function* watchFetchMsrpLastRefreshDate() {
  yield takeLatest(FETCH_MSRP_LAST_DATA_REFRESH, fetchMsrpLastDataRefresh);
}

export function* watchFetchJourneyExcellenceLastRefreshDate() {
  yield takeLatest(FETCH_JOURNEY_EXCELLENCE_LAST_DATA_REFRESH, fetchJourneyExcellenceLastDataRefresh);
}

export function* watchFetchVocLastRefreshDate() {
  yield takeLatest(FETCH_VOC_LAST_DATA_REFRESH, fetchVocLastDataRefresh);
}

export function* watchFetchUseOwnershipLastRefreshDate() {
  yield takeLatest(FETCH_USE_OWNERSHIP_LAST_DATA_REFRESH, fetchUseOwnershipLastDataRefresh);
}

export function* watchFetchBrandPerformacneLastRefreshDate() {
  yield takeLatest(FETCH_BRAND_PERFORMANCE_LAST_DATA_REFRESH, fetchBrandPerformanceLastDataRefresh);
}

export function* watchFetchExecutiveSummaryLastRefreshDate() {
  yield takeLatest(FETCH_EXECUTIVE_SUMMARY_LAST_DATA_REFRESH, fetchExecutiveSummaryLastDataRefresh);
}

// Worker saga
export function* fetchLastDataRefresh() {
  try {
    const response: { data: string } = yield retry(4, secondsDelay, getLastDataRefresh, `digital_performance`);

    if (!("error" in response)) {
      const { data: digitalPerformanceLastDataRefresh } = response;
      yield put(setLastDataRefresh("digital_performance", digitalPerformanceLastDataRefresh));
    }
  } catch (error) {
    console.log("Failed to get last data refresh data");
  }
}

export function* fetchCpoLastDataRefresh() {
  try {
    const response: { data: { date: string } } = yield retry(4, secondsDelay, getCpoLastDataRefresh);

    if (!("error" in response)) {
      const last_refresh_date = response.data.date;
      yield put(setLastDataRefresh("cpo_digital_performance", last_refresh_date));
    }
  } catch (error) {
    console.log("Failed to get last data refresh data");
  }
}

export function* fetchMtmLastDataRefreshData() {
  try {
    const response: { last_update_date: string } = yield retry(4, secondsDelay, getMtmLastDataRefresh);

    if (!("error" in response)) {
      const { last_update_date } = response;
      yield put(setLastDataRefresh("mtm", last_update_date));
    }
  } catch (error) {
    console.log("Failed to get mtm last data refresh data");
  }
}

export function* fetchActivationLastDataRefreshData() {
  try {
    const response: { last_refresh_date: string } = yield retry(4, secondsDelay, getActivationLastDataRefresh);

    if (!("error" in response)) {
      const { last_refresh_date } = response;
      yield put(setLastDataRefresh("activation", last_refresh_date));
    }
  } catch (error) {
    console.log("Failed to get mtm last data refresh data");
  }
}

export function* fetchMarketDataRefreshData() {
  // Fetch market specific date refresh
  const searchParams = new URLSearchParams(window.location.search);
  const brandValue = searchParams.get("brand") || "";
  const marketValue = searchParams.get("market") || "";

  const market = marketValue && marketValue !== ALL_COUNTRIES && !marketValue.includes(",") ? marketValue : ALL_OPTION;

  const data = {
    table: "digital_performance",
    brand: brandValue,
    market,
  };

  const dateRange: IMarketDataRefresh = yield retry(4, secondsDelay, getMarketDataRefresh, data);
  if (!("error" in dateRange)) yield put(setMarketDataRefresh(dateRange, marketValue));
}

export function* fetchSeoLastDataRefreshSaga() {
  try {
    const response: { last_updated: string } = yield retry(4, secondsDelay, getSeoLastUpdatedData);
    if (!("error" in response)) {
      const { last_updated } = response;
      yield put(setLastDataRefresh("seo", last_updated));
    }
  } catch (error) {
    console.log("Failed to get seo last data refresh data");
  }
}

export function* fetchProductMarketingOptimizationLastDataRefreshSaga() {
  try {
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getProductMarketingOptimizationLastDataRefresh);
    if (!("error" in response)) {
      yield put(setLastDataRefresh("product_marketing_optimization", response));
    }
  } catch (err) {
    console.log("Failed to get product marketing optimization last data refresh");
  }
}

export function* fetchOceLastDataRefreshSaga() {
  try {
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getOCELastDataRefresh);
    if (!("error" in response)) {
      yield put(setOceLastRefreshDate(response?.data?.date));
    }
  } catch (err) {
    console.log("Failed to get OCE last data refresh");
  }
}

export function* fetchMidasFmiLastRefreshDateSaga() {
  try {
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMidasFmiLastRefreshDate);
    if (!("error" in response)) {
      yield put(setMidasFmiLastDataRefresh(response?.date));
    }
  } catch (err) {
    console.log("Failed to get Midas last data refresh");
  }
}

export function* fetchMidasFmiByModelLastRefreshDateSaga() {
  try {
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMidasFmiByModelLastRefreshDate);
    if (!("error" in response)) {
      yield put(setMidasFmiLastDataRefresh(response?.date));
    }
  } catch (err) {
    console.log("Failed to get Midas fmi by model last data refresh");
  }
}

export function* fetchMidasMediaMixLastRefreshDateSaga() {
  try {
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMidasMediaMixLastRefreshDate);
    if (!("error" in response)) {
      yield put(setMidasFmiLastDataRefresh(response?.date));
    }
  } catch (err) {
    console.log("Failed to get Midas media mix last data refresh");
  }
}

export function* fetchMidasMtpCategoryLastRefreshDateSaga() {
  try {
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMidasMtpCategoryLastRefreshDate);
    if (!("error" in response)) {
      yield put(setMidasFmiLastDataRefresh(response?.date));
    }
  } catch (err) {
    console.log("Failed to get Midas mtp category last data refresh");
  }
}

export function* fetchMidasStrategicDealerSupportLastRefreshDateSaga() {
  try {
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMidasStrategicDealerSupportLastRefreshDate);
    if (!("error" in response)) {
      yield put(setMidasFmiLastDataRefresh(response?.date));
    }
  } catch (err) {
    console.log("Failed to get Midas strategic dealer support last data refresh");
  }
}

export function* fetchMidasStrategicCELastRefreshDateSaga() {
  try {
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMidasStrategicCELastRefreshDate);
    if (!("error" in response)) {
      yield put(setMidasFmiLastDataRefresh(response?.date));
    }
  } catch (err) {
    console.log("Failed to get Midas strategic ce last data refresh");
  }
}

export function* fetchMidasFixedLastRefreshDateSaga() {
  try {
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMidasFixedLastRefreshDate);
    if (!("error" in response)) {
      yield put(setMidasFmiLastDataRefresh(response?.date));
    }
  } catch (err) {
    console.log("Failed to get Midas fixed last data refresh");
  }
}

export function* fetchMidasSpendVsTrafficLastRefreshDateSaga() {
  try {
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMidasSpendVsTrafficLastRefreshDate);
    if (!("error" in response)) {
      yield put(setMidasFmiLastDataRefresh(response?.date));
    }
  } catch (err) {
    console.log("Failed to get Midas spend vs traffic last data refresh");
  }
}

export function* fetchCpoOperationalLastRefreshDateSaga() {
  try {
    const response: Record<string, any> = yield retry(4, secondsDelay, getCpoOperationalLastRefreshDate);
    if (!("error" in response)) {
      yield put(setCpoOperationalDataRefresh(response?.date));
    }
  } catch (err) {
    console.log("Failed to get used cars last data refresh");
  }
}

export function* fetchProductPerformanceLastRefreshDateSaga() {
  try {
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getProductPerformanceLastRefreshDate);
    if (!("error" in response)) {
      yield put(setProductPerformanceLastDataRefresh(response?.date));
    }
  } catch (err) {
    console.log("Failed to get Product Performance last data refresh");
  }
}

export function* fetchEcommerceLastRefreshDateSaga() {
  try {
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getEcommerceLastDataRefresh);
    if (!("error" in response)) {
      yield put(setEcommerceLastDataRefresh(response?.date));
    }
  } catch (err) {
    console.log("Failed to get Ecommerce last data refresh");
  }
}

export function* fetchMsrpLastDataRefresh() {
  try {
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMsrpLastDataRefresh);
    if (!("error" in response)) {
      yield put(setMsrpLastDataRefresh(response?.date));
    }
  } catch (err) {
    console.log("Failed to get MSRP last data refresh");
  }
}

export function* fetchJourneyExcellenceLastDataRefresh({ payload }: AnyAction) {
  try {
    const response: Record<string, any> = yield retry(4, secondsDelay, getJourneyExcellenceLastRefreshDate, payload);
    if (!("error" in response)) {
      yield put(setJourneyExcellenceLastDataRefresh(response?.date));
    }
  } catch (err) {
    console.log("Failed to get Journey Excellence last data refresh");
  }
}

export function* fetchVocLastDataRefresh() {
  try {
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getVocLastRefreshDate);
    if (!("error" in response)) {
      yield put(setVocLastDataRefresh(response?.date));
    }
  } catch (err) {
    console.log("Failed to get VOC last data refresh");
  }
}

export function* fetchUseOwnershipLastDataRefresh({ payload: page }: AnyAction) {
  try {
    const response: Record<string, any> = yield retry(4, secondsDelay, getUseOwnershipLastRefreshDate, page);
    if (!("error" in response)) {
      yield put(setUseOwnershipLastDataRefresh(response?.last_data_refresh));
    }
  } catch (err) {
    console.log("Failed to get Use Ownership last data refresh");
  }
}

export function* fetchBrandPerformanceLastDataRefresh({ payload }: AnyAction) {
  try {
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getBrandPerformanceLastRefreshDate, payload);
    if (!("error" in response)) {
      yield put(setBrandPerformanceLastDataRefresh(response?.date));
    }
  } catch (err) {
    console.log("Failed to get brand performacne last data refresh");
  }
}

export function* fetchExecutiveSummaryLastDataRefresh() {
  try {
    const response: Record<string, any> = yield retry(4, secondsDelay, getExecutiveSummaryLastRefreshData);
    if (!("error" in response)) {
      yield put(setExecutiveSummaryLastDataRefresh(response?.last_refresh_date));
    }
  } catch (err) {
    console.log("Failed to get EXECUTIVE_SUMMARY last data refresh");
  }
}
