import { FunnelSeries, LabelBullet, SlicedChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { BLACK, DIGITAL_LEADS_SOURCE_COLORS, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  theme: ThemeInt;
  chartId: string;
  isLoading: boolean;
}

class DmeoFunnelChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: SlicedChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, theme } = this.props;
    this.chart = create(this.chartId, SlicedChart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#,###.0";
    this.chart.colors.list = DIGITAL_LEADS_SOURCE_COLORS.map((item) => color(item));

    const series = this.chart.series.push(new FunnelSeries());

    series.colors.step = 2;
    series.dataFields.value = "value";
    series.dataFields.category = "name";
    series.alignLabels = true;
    series.labelsOpposite = false;
    series.labels.template.text = "{category}";
    series.labels.template.fill = theme == "dark" ? color(WHITE) : color(BLACK);
    series.labels.template.stroke = theme == "dark" ? color(WHITE) : color(BLACK);

    series.slices.template.tooltipText = "{category} : {value}";

    series.labelsContainer.paddingLeft = 15;
    series.labelsContainer.width = 120;
    series.labelsContainer.stroke = theme == "dark" ? color(WHITE) : color(BLACK);

    series.ticks.template.disabled = true;

    const labelBullet = series.bullets.push(new LabelBullet());
    labelBullet.label.text = "{value}";
    labelBullet.locationX = 0.5;
    labelBullet.label.fill = theme == "dark" ? color(WHITE) : color(BLACK);
    labelBullet.dx = 140;
    labelBullet.dy = -30;
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

export default DmeoFunnelChart;
