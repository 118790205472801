import { combineReducers } from "redux";
import activation_ratio from "./activationRatioReducer";
import admin from "./adminReducer";
import annotationParameters from "./annotationParametersReducer";
import ariya from "./ariyaReducer";
import brand_performance from "./brandPerformanceReducer";
import ccs_analysis from "./ccsAnalysisReducer";
import ccs_cockpit from "./ccsCockpitReducer";
import cpo_digital_performance from "./cpoDashboardReducer";
import cpo_operational from "./cpoOperationalReducer";
import ecommerce from "./ecommerceReducer";
import error from "./errorReducer";
import activation_parameters from "./europeActivationParametersReducer";
import europe_activation from "./europeActivationReducer";
import executive_summary from "./executiveSummaryReducer";
import filters from "./filtersReducer";
import journey_excellence from "./journeyExcellenceReducer";
import data_refresh from "./lastDataRefreshReducer";
import loading from "./loadingReducer";
import marketing_channels from "./marketingChannelsReducer";
import midas from "./midasReducer";
import models from "./modelsReducer";
import msrp from "./msrpReducer";
import mtm_parameters from "./mtmParametersReducer";
import mtm_data from "./mtmReportReducer";
import digital_performance from "./onlineCePerformanceReducer";
import page from "./pageReducer";
import parameters from "./parametersReducer";
import product_marketing_optimization from "./productMarketingOptimizationReducer";
import product_performance from "./productPerformanceReducer";
import purchase_funnel_parameters from "./purchaseFunnelParametersReducer";
import purchase_funnel from "./purchaseFunnelReducer";
import release_notes from "./releaseNotesReducer";
import seo_parameters from "./seoParameters";
import seo_data from "./seoReducer";
import tags from "./tagsReducer";
import top_flop_parameters from "./topFlopParametersReducer";
import top_flop from "./topFlopReducer";
import use_ownership from "./useOwnershipReducer";
import user from "./usersReducer";
import voc from "./vocReducer";

export default combineReducers({
  error,
  midas,
  loading,
  user,
  data_refresh,
  filters,
  parameters,
  models,
  marketing_channels,
  page,
  release_notes,
  digital_performance,
  cpo_digital_performance,
  ccs_analysis,
  ccs_cockpit,
  europe_activation,
  activation_parameters,
  mtm_data,
  cpo_operational,
  mtm_parameters,
  admin,
  seo_parameters,
  seo_data,
  ariya,
  tags,
  purchase_funnel_parameters,
  purchase_funnel,
  top_flop_parameters,
  top_flop,
  product_marketing_optimization,
  annotationParameters,
  product_performance,
  activation_ratio,
  voc,
  ecommerce,
  msrp,
  journey_excellence,
  use_ownership,
  brand_performance,
  executive_summary,
});
