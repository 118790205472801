import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { getAvailableYearScenarios, getSortedYrScenarios, getTableData, getTotals, getTouchPointNames } from "../../helpers";
import MidasShareTables from "../../sharedComponents/MidasShareTables";

const StrategicCETables = () => {
  const yr_scnr_rv = useSelector((state: RootStateOrAny) => state.filters.midas_filters.yr_scnr_rv);

  const data = useSelector((state: RootStateOrAny) => state.midas.midas_strategic_ce_data_by_quarter);
  const loading = useSelector((state: RootStateOrAny) => state.loading.midas_strategic_ce_data_by_quarter);

  const yrScenarios: string[] = useMemo(() => getAvailableYearScenarios(data?.full_year), [data]);

  const sortedYrScenarios = useMemo(() => getSortedYrScenarios(yr_scnr_rv, yrScenarios), [yrScenarios, yr_scnr_rv]);

  const touchPointNames: string[] = useMemo(() => getTouchPointNames(data?.full_year), [data]);

  const q1TableData = useMemo(() => getTableData(data?.q1, touchPointNames, "tch_pnt_nm", [1]), [data, touchPointNames]);
  const q2TableData = useMemo(() => getTableData(data?.q2, touchPointNames, "tch_pnt_nm", [2]), [data, touchPointNames]);
  const q3TableData = useMemo(() => getTableData(data?.q3, touchPointNames, "tch_pnt_nm", [3]), [data, touchPointNames]);
  const q4TableData = useMemo(() => getTableData(data?.q4, touchPointNames, "tch_pnt_nm", [4]), [data, touchPointNames]);
  const fullYearTableData = useMemo(
    () => getTableData(data?.full_year, touchPointNames, "tch_pnt_nm", [0, 1, 2, 3, 4]),
    [data, touchPointNames]
  );

  const q1Totals = useMemo(() => getTotals(q1TableData, sortedYrScenarios, [1]), [sortedYrScenarios, data, yrScenarios]);
  const q2Totals = useMemo(() => getTotals(q2TableData, sortedYrScenarios, [2]), [sortedYrScenarios, data, yrScenarios]);
  const q3Totals = useMemo(() => getTotals(q3TableData, sortedYrScenarios, [3]), [sortedYrScenarios, data, yrScenarios]);
  const q4Totals = useMemo(() => getTotals(q4TableData, sortedYrScenarios, [4]), [sortedYrScenarios, data, yrScenarios]);
  const fullYearTotals = useMemo(
    () => getTotals(fullYearTableData, sortedYrScenarios, [1, 2, 3, 4, 0]),
    [sortedYrScenarios, data, yrScenarios]
  );

  const combinedData = useMemo(() => {
    return data?.q1?.concat(data?.q2?.concat(data?.q3?.concat(data?.q4?.concat(data?.full_year))));
  }, [data]);

  const combinedTableData = useMemo(
    () => getTableData(combinedData, touchPointNames, "tch_pnt_nm", [0, 1, 2, 3, 4]),
    [combinedData, touchPointNames]
  );

  const combinedTotals = useMemo(
    () => getTotals(combinedTableData, sortedYrScenarios, [0, 1, 2, 3, 4]),
    [sortedYrScenarios, data, yrScenarios]
  );

  return (
    <MidasShareTables
      q1TableData={q1TableData}
      q2TableData={q2TableData}
      q3TableData={q3TableData}
      q4TableData={q4TableData}
      fullYearTableData={fullYearTableData}
      combinedTableData={combinedTableData}
      q1Totals={q1Totals}
      q2Totals={q2Totals}
      q3Totals={q3Totals}
      q4Totals={q4Totals}
      fullYearTotals={fullYearTotals}
      combinedTotals={combinedTotals}
      sortedYrScenarios={sortedYrScenarios}
      loading={loading}
      hasCategoryToggle
    />
  );
};

export default StrategicCETables;
