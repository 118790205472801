import { CategoryAxis, ColumnSeries, DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component, ReactNode } from "react";
import WebFont from "webfontloader";
import { ARIYA_PINK, PURPLE, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import {
  configureCategoryAxis,
  configureDateAxis,
  configureValueAxis,
  legendConfiguration,
  lineSeriesConfiguration,
} from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { dateAxisLabelFormatter_QuarterlyFY, formatDateAxisLabelToQuarterlyFY } from "../helpers/pmoConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  theme: ThemeInt;
  isLoading: boolean;
  seriesList: any;
  granularity: string;
  ganttData: Array<Record<string, number | string>>;
  lineData: Array<Record<string, number | string>>;
  colorsColumn: any;
  maxDate: Date;
  minDate: Date;
}

const lineSeriesColors = [ARIYA_PINK];

class MarketingActivityCalendarChart extends Component<Props> {
  chartId = "marketingActivityCalendar";
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { theme, seriesList, granularity, ganttData, lineData, colorsColumn, minDate, maxDate } = this.props;

    // const firstDateLine = lineData[0]?.date;
    // const lastDateLine = lineData[lineData?.length - 1]?.date;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = ganttData;
    this.chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.itemContainers.template.paddingBottom = 30;
    this.chart.legend.itemContainers.template.paddingTop = 0;
    this.chart.legend.markers.template.strokeWidth = 2;
    this.chart.paddingBottom = 0;
    if (this.chart.logo) {
      this.chart.logo.disabled = true;
    }

    // Create date chart axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme, true);
    dateAxis.baseInterval = { count: 31, timeUnit: "day" };
    dateAxis.renderer.minGridDistance = 60;
    dateAxis.strictMinMax = true;
    dateAxis.renderer.labels.template.disabled = true;
    dateAxis.renderer.cellStartLocation = 0.1;
    dateAxis.renderer.cellEndLocation = 0.9;
    dateAxis.min = minDate.getTime();
    dateAxis.max = maxDate.getTime();

    const lineDateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(lineDateAxis, theme, true);
    lineDateAxis.baseInterval = { count: 1, timeUnit: "month" };
    lineDateAxis.renderer.minGridDistance = 30;
    // lineDateAxis.strictMinMax = true;
    lineDateAxis.renderer.cellStartLocation = 0.5;
    lineDateAxis.renderer.cellEndLocation = 0.5;
    lineDateAxis.renderer.grid.template.strokeWidth = 0;
    lineDateAxis.min = minDate.getTime();
    lineDateAxis.max = maxDate.getTime();
    // dateAxis.renderer.labels.template.visible = false;

    granularity === "quarterly" && dateAxis.renderer.labels.template.adapter.add("text", dateAxisLabelFormatter_QuarterlyFY);
    granularity === "quarterly" && lineDateAxis.renderer.labels.template.adapter.add("text", dateAxisLabelFormatter_QuarterlyFY);

    // Creates value axis
    const categoryAxis = this.chart.yAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.inversed = true;
    // categoryAxis.renderer.opposite = true;
    categoryAxis.renderer.labels.template.adapter.add("html", function (html, target) {
      const data: any = target.dataItem?.dataContext;
      if (data?.name) {
        return `
        <div class="market_label">
        <div>{category}</div>
        <div style="background-color: ${colorsColumn[data.name]};width:1rem;height:1rem;margin-left:1rem;margin-top:0.3rem"></div>
        </div>
        
        `;
      }
      return html;
    });

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.renderer.opposite = true;
    valueAxis.renderer.minGridDistance = 100;

    const series = this.chart.series.push(new ColumnSeries());
    series.dataFields.openDateX = "fromDate";
    series.dataFields.dateX = "toDate";
    series.dataFields.categoryY = "name";
    series.yAxis.fill = color(PURPLE);

    series.xAxis = dateAxis;
    series.hiddenInLegend = false;
    series.legendSettings.labelText = "Marketing activity";
    series.columns.template.maxHeight = 20;

    const tooltipHTML = `<center><strong class="tclass">{activity_name}</strong></center>
        <table>
          {video_link && (<tr>
            <th>
            <iframe src=video_link frameborder="0" allowfullscreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen" 
        webkitallowfullscreen="webkitallowfullscreen"></iframe>
            </th>
          </tr>)}
          
          <tr>
            <th>
            <p style="text-align:center"><span class="tclass">{openDateX.formatDate('MMM YYYY')}</span> - <span  class="tclass">{dateX.formatDate('MMM YYYY')}</span></p>
            </th>
          </tr>
        </table>
        `;

    series.columns.template.tooltipHTML = tooltipHTML;
    series.columns.template.adapter.add("tooltipHTML", (html: any, target: any) => {
      const video_link = target?.dataItem?.dataContext?.video_link;
      html = `<center><strong class="tclass">{activity_name}</strong></center>
      <table>
        ${
          video_link ? (
            <tr>
              <th>
                <iframe src={video_link} frameBorder="0" allowFullScreen={true}></iframe>
              </th>
            </tr>
          ) : (
            ""
          )
        }
        <tr>
          <th>
          <p style="text-align:center"><span class="tclass">{openDateX.formatDate('MMM YYYY')}</span> - <span  class="tclass">{dateX.formatDate('MMM YYYY')}</span></p>
          </th>
        </tr>
      </table>
      `;
      return html;
    });
    //@ts-ignore
    if (series.tooltip) series.tooltip.keepTargetHover = true;
    //@ts-ignore
    if (series.tooltip.label) series.tooltip.label.interactionsEnabled = true;

    series.columns.template.adapter.add("fill", (fill, target) => {
      const data: any = target.dataItem?.dataContext;
      if (data?.name) {
        return color(colorsColumn[data.name]);
      }
      return theme == "light" ? color({ r: 255, g: 255, b: 255 }) : color({ r: 44, g: 57, b: 78 });
    });

    series.columns.template.adapter.add("stroke", (stroke, target) => {
      const data: any = target.dataItem?.dataContext;
      if (data?.name) {
        return color(colorsColumn[data.name]);
      }
      return color(WHITE);
    });

    seriesList?.map((item: { name: string; field: string }, index: number) => {
      const series = this.chart.series.push(new LineSeries());
      series.data = lineData;
      series.xAxis = lineDateAxis;
      //series.xAxis = dateAxisWeekly;
      series.dataFields.dateX = "date";
      series.yAxis = valueAxis;
      series.stroke = color(lineSeriesColors[index]);
      series.fill = color(lineSeriesColors[index]);
      const bullet = lineSeriesConfiguration(series, {
        valueY: item.field,
        name: item.name,
      });

      bullet.adapter.add("tooltipText", (text, target) => {
        const data = target.dataItem?.dataContext;
        if (granularity === "quarterly") {
          //@ts-ignore
          const dateVal = formatDateAxisLabelToQuarterlyFY(data?.date);
          text = `[bold; font-size: var(--regular_font_size);] ${dateVal}[/ font-size: var(--regular_font_size);]: {valueY.formatNumber("#,###.")}`;
        } else {
          text = `[bold; font-size: var(--regular_font_size);]{dateX.formatDate('MMM YYYY')} [/ font-size: var(--regular_font_size);]: {valueY.formatNumber("#,###.")}`;
        }
        return text;
      });
    });
  }

  render(): ReactNode {
    return (
      <div className={"chartWrapper"}>
        <div id={"marketingActivityCalendar"} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default MarketingActivityCalendarChart;
