import React, { Fragment } from "react";
import { ALL_OPTION } from "../../../constants";

const ChannelFilter = (props: {
  filterValue: string;
  list: { channel: string; breakdown: string[] }[];
  showFilterOptions: (evt: React.MouseEvent<HTMLElement>) => void;
  handleChannelOptionsClick: (evt: React.MouseEvent<HTMLElement>) => void;
}) => {
  const { filterValue, list, showFilterOptions, handleChannelOptionsClick } = props;

  return (
    <div className={"filter"} id="channel_merge">
      <label className="filter_header" htmlFor="channel_merge_filter">
        CHANNEL
      </label>
      <div className="input_arrow" onClick={showFilterOptions} data-options-ul={"channel_merge_ul"}>
        <input
          readOnly
          type="text"
          name="last_touch_marketing_channel_merge"
          className={"filter_input"}
          id="channel_merge_filter"
          data-test-id="channel"
          value={filterValue}
        />
        <span className="arrow_down " />
      </div>
      <ul className="filter_options" id="channel_merge_ul" data-test-id="channel_list">
        {list?.map(({ channel, breakdown }) => (
          <Fragment key={channel}>
            <li key={channel} onClick={handleChannelOptionsClick} data-channel={channel} data-channel-breakdown={ALL_OPTION}>
              {channel}
            </li>
            {breakdown?.length > 0 ? (
              <ul className={"sub_filter_options"} key={`breakdown${channel}`}>
                {breakdown?.map((breakdown) => (
                  <li key={breakdown} onClick={handleChannelOptionsClick} data-channel={channel} data-channel-breakdown={breakdown}>
                    {breakdown}
                  </li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </Fragment>
        ))}
      </ul>
    </div>
  );
};

export default ChannelFilter;
