import React from "react";
import "../../../assets/styles/main.scss";
import "../../../assets/styles/pages/login.scss";
import { PageNotFound } from "../../../components/ErrorTemplates";
import { LoginErrorTemplate } from "./subs";

export const Page404 = () => {
  return (
    <LoginErrorTemplate>
      <PageNotFound />
    </LoginErrorTemplate>
  );
};
