import React, { useContext, useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { DashboardBannerContext } from "../../../../context";

interface Props {
  title: string;
  disableMessage: string;
  onClick?: () => void;
}

export const DisabledTile = (props: Props) => {
  const { title, disableMessage, onClick: handleClick } = props;
  const [highLight, setHighLight] = useState(false);

  const { region: regionParamValue } = useSelector((state: RootStateOrAny) => state.parameters);

  const { bannerHovered } = useContext(DashboardBannerContext);

  useEffect(() => {
    if (regionParamValue === "Japan" && title === "Renewal ratio" && bannerHovered) {
      setHighLight(true);
    } else {
      setHighLight(false);
    }
  }, [regionParamValue, bannerHovered, title]);

  return (
    <div
      className={`tile disabled ${highLight ? "isBlue" : ""}`}
      id={title.replaceAll(" ", "_")}
      data-test-id={title.replaceAll(" ", "_")}
      onClick={handleClick}
    >
      <div className="tile_header">{title}</div>
      <div className="tile_content">
        <p className="grey_info">{disableMessage}</p>
      </div>
    </div>
  );
};
