import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import * as Sentry from "@sentry/react";
import React, { useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { deleteDataV2, fetchDataV2 } from "../../../api/utils";
import { ErrorMsg } from "../../../components/AppMessages";
import { CYAN } from "../../../constants";
import UserSearchField from "../common/Fields/UserSearchField";
import { downloadExcelFile } from "../utils";
import DeleteUserGroupDialog from "./subs/Dialogs/DeleteUserGroupDialog";
import UserGroupFormDialog from "./subs/Dialogs/UserGroupFormDialog";
import UserGroupsTable from "./subs/UserGroupsTable";

const UserGroups = () => {
  const { brands, regions, markets } = useSelector((state: RootStateOrAny) => state.admin);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [userGroupFormDialogOpen, setUserGroupDialogFormOpen] = useState<boolean>(false);
  const [selectedUserGroup, setSelectedUserGroup] = useState<any>(undefined);
  const [userGroups, setUserGroups] = useState<Array<any>>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const filteredUserGroups = userGroups.filter((userGroup) => userGroup.name.toLowerCase().includes(searchValue.toLowerCase()));

  const classes = makeStyles({
    button: {
      backgroundColor: CYAN,
      color: "#FFF !important",
      borderRadius: "0px !important",
      border: "none !important",
      marginRight: "10px",
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      "&:hover": {
        backgroundColor: "#5ed2ff !important",
        border: "none !important",
      },
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
  })();

  const handleExportUsers = () => {
    const columnHeaders = ["Name", "Brands", "Region", "Markets"];
    const data = [
      columnHeaders,
      ...userGroups.map((user) => [
        user.name,
        user.brands.map((brand: { brand: any }) => brand.brand).join(", "),
        user.regions.map((region: { region: any }) => region.region).join(", "),
        user.markets.map((market: { market: any }) => market.market).join(", "),
      ]),
    ];
    downloadExcelFile(data, "geoscope");
  };

  useEffect(() => {
    fetchDataV2("/user_service/get-all-groups").then((res) => {
      if (!res.error) {
        setUserGroups(res);
      } else {
        console.log(res.error);
      }
    });
  }, []);

  const handleToogleDeleteDialog = () => {
    setDeleteDialogOpen(!deleteDialogOpen);
  };

  const handleToggleUserGroupFormDialog = () => {
    setUserGroupDialogFormOpen(!userGroupFormDialogOpen);
    if (userGroupFormDialogOpen) {
      setSelectedUserGroup(undefined);
    }
  };

  const handleDeleteUserGroup = (id: number) => {
    deleteDataV2(`/user_service/delete-group?id=${id}`).then((res) => {
      if (!res.error) {
        setUserGroups((prevState) => prevState.filter((user) => user.id != id));
        setSelectedUserGroup(undefined);
        handleToogleDeleteDialog();
      } else {
        console.log(res.error);
      }
    });
  };

  return (
    <Sentry.ErrorBoundary fallback={<ErrorMsg />}>
      <Box component="div">
        <Box className={classes.buttonsContainer} component="div" mb={3}>
          <Box component="div">
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={() => {
                setSelectedUserGroup(undefined);
                handleToggleUserGroupFormDialog();
              }}
              data-test-id="btn-add-new-user-group"
            >
              New
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={handleToggleUserGroupFormDialog}
              disabled={!selectedUserGroup}
              data-test-id="btn-edit-user-group"
            >
              Edit
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={handleToogleDeleteDialog}
              disabled={!selectedUserGroup}
              data-test-id="btn-delete-user-group"
            >
              Delete
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={handleExportUsers}
              data-test-id="btn-export-users"
            >
              Export to Excel
            </Button>
          </Box>
          <Box component="div">
            <UserSearchField value={searchValue} setValue={setSearchValue} />
          </Box>
        </Box>

        <Sentry.ErrorBoundary fallback={<ErrorMsg />}>
          <UserGroupsTable
            selectedUserGroup={selectedUserGroup}
            setSelectedUserGroup={setSelectedUserGroup}
            setDeleteDialogOpen={setDeleteDialogOpen}
            userGroups={filteredUserGroups}
            brands={brands}
            regions={regions}
            markets={markets}
            searchValue={searchValue}
          />
        </Sentry.ErrorBoundary>

        {deleteDialogOpen && selectedUserGroup && (
          <Sentry.ErrorBoundary fallback={<ErrorMsg />}>
            <DeleteUserGroupDialog
              open={deleteDialogOpen}
              handleClose={handleToogleDeleteDialog}
              handleDelete={handleDeleteUserGroup}
              userGroup={selectedUserGroup}
            />
          </Sentry.ErrorBoundary>
        )}

        {userGroupFormDialogOpen && (
          <Sentry.ErrorBoundary fallback={<ErrorMsg />}>
            <UserGroupFormDialog
              open={userGroupFormDialogOpen}
              toggleOpen={handleToggleUserGroupFormDialog}
              setUserGroups={setUserGroups}
              selectedUserGroup={selectedUserGroup}
              brands={brands}
              regions={regions}
              markets={markets}
            />
          </Sentry.ErrorBoundary>
        )}
      </Box>
    </Sentry.ErrorBoundary>
  );
};

export default UserGroups;
