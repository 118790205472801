import { DEFAULT_PRECISION } from "../constants";
import { isNull, toFixed } from "./utilityFunctions";

export const computeMetricConversion = (initialMetric: number, finalMetric: number, precision = DEFAULT_PRECISION): number | null => {
  let result;

  if (
    isNaN(initialMetric) ||
    isNaN(finalMetric) ||
    isNull(initialMetric) ||
    isNull(finalMetric) ||
    (finalMetric > 0 && initialMetric === 0)
  )
    return null;

  if (initialMetric !== 0 && finalMetric !== 0) result = finalMetric / initialMetric;
  else result = 0;

  result = parseFloat(result.toFixed(precision + 2));

  return result;
};

export const computePtsValues = (currentConversionValue: number | null, comparisonConversionValue: number | null): number | "n/a" => {
  if (isNull(currentConversionValue) || isNull(comparisonConversionValue)) return "n/a";

  const pts_value = toFixed(((currentConversionValue as number) - (comparisonConversionValue as number)) * 100, DEFAULT_PRECISION);

  if (isNull(pts_value)) {
    return "n/a";
  }

  return pts_value as number;
};

export const sumByKeys = (data: Array<Record<string, any>>, keys: Array<string>): { [index: string]: number } => {
  if (!data || !data?.length)
    return keys.reduce((result: { [index: string]: number }, key) => {
      result[key] = 0;
      return result;
    }, {});

  return data.reduce((result: { [index: string]: number }, row: { [index: string]: any }) => {
    keys.forEach((key) => {
      if (key in result) {
        result[key] = typeof row[key] === "number" ? result[key] + row[key] : result[key];
      } else {
        result[key] = typeof row[key] === "number" ? row[key] : 0;
      }
    });
    return result;
  }, {});
};

export const groupByKeys = (data: Array<{ [index: string]: any }>, key: string) => {
  if (!data || !key) return {};

  return data.reduce((result, row) => {
    const { [key]: value, ...rest } = row;

    if (value in result) {
      result[value].push(rest);
    } else {
      result[value] = [rest];
    }

    return result;
  }, {});
};
