import { ColumnSeries, DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { BLACK, CCS_BUSINESS_REVENUE_AND_SUBSCRIPTION_COLORS, RED, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  seriesList: Array<{ name: string; field: string; type: string; color?: string }>;
  theme: ThemeInt;
  chartId: string;
  isLoading: boolean;
}

const lineSeriesColors = [RED];

class BusinessRevenueStackedBarLineChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, seriesList, theme } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#.0a";

    // Create Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.minGridDistance = 40;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.numberFormatter = new NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#a";

    const lineValueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(lineValueAxis, theme);
    lineValueAxis.renderer.opposite = true;
    lineValueAxis.syncWithAxis = valueAxis;
    valueAxis.numberFormatter = new NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#a";

    this.chart.data.sort(function (a, b) {
      //@ts-ignore
      return new Date(a.date) - new Date(b.date);
    });

    seriesList
      .filter((series) => series.type == "bar")
      ?.forEach(({ name, field, color: seriesColor }, idx) => {
        const series = this.chart.series.push(new ColumnSeries());
        series.dataFields.dateX = "date";
        series.name = name;
        series.fill = seriesColor ? color(seriesColor) : color(CCS_BUSINESS_REVENUE_AND_SUBSCRIPTION_COLORS[idx]);
        series.stroke = seriesColor ? color(seriesColor) : color(CCS_BUSINESS_REVENUE_AND_SUBSCRIPTION_COLORS[idx]);
        series.dataFields.valueY = field;
        series.xAxis = dateAxis;
        series.numberFormatter = new NumberFormatter();
        series.numberFormatter.numberFormat = "#.0a";
        series.columns.template.tooltipText =
          "[bold font-size: var(--regular_font_size)]{dateX.formatDate('MMM YYYY')}: [/ font-size: var(--regular_font_size)]{valueY.formatNumber('###,###')}";
        series.stacked = true;
        if (series.tooltip) {
          series.tooltip.getFillFromObject = false;
          series.tooltip.autoTextColor = false;
          series.tooltip.background.fill = color(WHITE);
          series.tooltip.label.fill = color(BLACK);
        }
        series.columns.template.adapter.add("strokeOpacity", (value, target) => {
          //@ts-ignore
          if (target.dataItem && target.dataItem.valueY == 0) {
            return 0;
          }
          return value;
        });
        series.columns.template.adapter.add("fillOpacity", (value, target) => {
          //@ts-ignore
          if (target.dataItem && target.dataItem.valueY == 0) {
            return 0;
          }
          return value;
        });
      });

    seriesList
      .filter((series) => series.type == "line")
      ?.forEach(({ name, field, color: seriesColor }, idx) => {
        const series = this.chart.series.push(new LineSeries());
        series.dataFields.dateX = "date";
        series.fill = seriesColor ? color(seriesColor) : color(lineSeriesColors[idx]);
        series.stroke = seriesColor ? color(seriesColor) : color(lineSeriesColors[idx]);

        if (field.includes("bp")) {
          series.strokeDasharray = "2,4";
        }

        const circleBullet = lineSeriesConfiguration(series, {
          valueY: field,
          name: name,
          yAxis: lineValueAxis,
        });
        circleBullet.tooltipText =
          "[bold font-size: var(--regular_font_size)]{dateX.formatDate('MMM YYYY')}: [/ font-size: var(--regular_font_size)]{valueY.formatNumber('###,###')}";

        if (series.tooltip) {
          series.tooltip.getFillFromObject = false;
          series.tooltip.autoTextColor = false;
          series.tooltip.background.fill = color(WHITE);
          series.tooltip.label.fill = color(BLACK);
        }
      });
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

export default BusinessRevenueStackedBarLineChart;
