import { useEffect, useRef, useState } from "react";

export function useDropdownVisible(initialIsVisible: boolean): any {
  const wrapperRef = useRef<HTMLElement>(null);
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: Event) => {
    const currentElement = event.target as Element;
    if (currentElement.localName !== "span") {
      setIsVisible(true);
    }
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node) && currentElement.localName !== "span") {
      setIsVisible(false);
    }
  };

  return { wrapperRef, isVisible, setIsVisible };
}
export function useMetricVisible(initialIsVisible: boolean): any {
  const metricRef = useRef<HTMLElement>(null);
  const [isMetricVisible, setIsMetricVisible] = useState(initialIsVisible);
  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: Event) => {
    const clickedElement = event.target as Element;
    if (metricRef.current && !metricRef.current.contains(event.target as Node) && clickedElement.className !== "metric-option") {
      setIsMetricVisible(false);
    }
  };

  return { metricRef, isMetricVisible, setIsMetricVisible };
}

export function useMultiselectVisible(initialIsVisible: boolean): any {
  const wrapperRef = useRef<HTMLElement>(null);
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: Event) => {
    const clickedElement = event.target as Element;
    const classNameValue = clickedElement.className;
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      if (typeof classNameValue === "string" && classNameValue.includes("multiselect")) {
        setIsVisible(true);
      }
      setIsVisible(false);
    }
  };

  return { wrapperRef, isVisible, setIsVisible };
}
