import {
  FETCH_EXIT_SURVEY_DEVICE_DISTRIBUTION,
  FETCH_EXIT_SURVEY_FEEDBACK,
  FETCH_EXIT_SURVEY_NON_COMPLETION_REASONS,
  FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION,
  FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION_BY_MARKET,
  FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION_SELECTED_MARKETS,
  FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION_TREND,
  FETCH_FEEDBACK_COMMENTS,
  FETCH_FEEDBACK_COMMENTS_FILTERS,
  FETCH_MARKET_OVERVIEW,
  FETCH_RATING_PER_TOUCHPOINT_COMPLETION_RATE,
  FETCH_RATING_PER_TOUCHPOINT_EDITORIAL,
  FETCH_RATING_PER_TOUCHPOINT_ELECTRIFICATION,
  FETCH_RATING_PER_TOUCHPOINT_MARKET_BREAKDOWN,
  FETCH_RATING_PER_TOUCHPOINT_MEDIA_CAMPAIGNS,
  FETCH_RATING_PER_TOUCHPOINT_OTHER_TOUCHPOINTS,
  FETCH_RATING_PER_TOUCHPOINT_OWNERSHIP,
  FETCH_RATING_PER_TOUCHPOINT_VLP_FOCUS,
  FETCH_STAR_RATING_AVERAGE_RATING,
  FETCH_STAR_RATING_AVERAGE_RATING_BY_MARKET,
  FETCH_STAR_RATING_AVERAGE_RATING_SELECTED_MARKETS,
  FETCH_STAR_RATING_AVERAGE_RATING_TREND,
  FETCH_STAR_RATING_DEVICE_DISTRIBUTION,
  FETCH_STAR_RATING_FEEDBACK,
  FETCH_STAR_RATING_SCORE_DISTRIBUTION,
  FETCH_TOPIC_CLASSIFICATION_DISSATISFACTION,
  FETCH_TOPIC_CLASSIFICATION_SATISFACTION,
  SET_CSV_EXPERIENCE_RATING,
  SET_EXIT_SURVEY_DEVICE_DISTRIBUTION,
  SET_EXIT_SURVEY_FEEDBACK,
  SET_EXIT_SURVEY_NON_COMPLETION_REASONS,
  SET_EXIT_SURVEY_OBJECTIVE_COMPLETION,
  SET_EXIT_SURVEY_OBJECTIVE_COMPLETION_BY_MARKET,
  SET_EXIT_SURVEY_OBJECTIVE_COMPLETION_SELECTED_MARKETS,
  SET_EXIT_SURVEY_OBJECTIVE_COMPLETION_TREND,
  SET_FEEDBACK_COMMENTS,
  SET_FEEDBACK_COMMENTS_FILTERS,
  SET_MARKET_OVERVIEW,
  SET_RATING_PER_TOUCHPOINT_COMPLETION_RATE,
  SET_RATING_PER_TOUCHPOINT_EDITORIAL,
  SET_RATING_PER_TOUCHPOINT_ELECTRIFICATION,
  SET_RATING_PER_TOUCHPOINT_MARKET_BREAKDOWN,
  SET_RATING_PER_TOUCHPOINT_MEDIA_CAMPAIGNS,
  SET_RATING_PER_TOUCHPOINT_OTHER_TOUCHPOINTS,
  SET_RATING_PER_TOUCHPOINT_OWNERSHIP,
  SET_RATING_PER_TOUCHPOINT_VLP_FOCUS,
  SET_STAR_RATING_AVERAGE_RATING,
  SET_STAR_RATING_AVERAGE_RATING_BY_MARKET,
  SET_STAR_RATING_AVERAGE_RATING_SELECTED_MARKETS,
  SET_STAR_RATING_AVERAGE_RATING_TREND,
  SET_STAR_RATING_DEVICE_DISTRIBUTION,
  SET_STAR_RATING_FEEDBACK,
  SET_STAR_RATING_SCORE_DISTRIBUTION,
  SET_TOPIC_CLASSIFICATION_DISSATISFACTION,
  SET_TOPIC_CLASSIFICATION_SATISFACTION,
} from "./actionTypes/vocActionTypes";

export const fetchMarketOverview = () => ({ type: FETCH_MARKET_OVERVIEW });
export const setMarketOverview = (data: any) => ({ type: SET_MARKET_OVERVIEW, payload: data });

export const fetchStarRatingAverageRating = () => ({ type: FETCH_STAR_RATING_AVERAGE_RATING });
export const setStarRatingAverageRating = (data: any) => ({ type: SET_STAR_RATING_AVERAGE_RATING, payload: data });

export const fetchStarRatingAverageRatingTrend = () => ({ type: FETCH_STAR_RATING_AVERAGE_RATING_TREND });
export const setStarRatingAverageRatingTrend = (data: any) => ({ type: SET_STAR_RATING_AVERAGE_RATING_TREND, payload: data });

export const fetchStarRatingFeedback = () => ({ type: FETCH_STAR_RATING_FEEDBACK });
export const setStarRatingFeedback = (data: any) => ({ type: SET_STAR_RATING_FEEDBACK, payload: data });

export const fetchStarRatingScoreDistribution = () => ({ type: FETCH_STAR_RATING_SCORE_DISTRIBUTION });
export const setStarRatingScoreDistribution = (data: any) => ({ type: SET_STAR_RATING_SCORE_DISTRIBUTION, payload: data });

export const fetchStarRatingDeviceDistribution = () => ({ type: FETCH_STAR_RATING_DEVICE_DISTRIBUTION });
export const setStarRatingDeviceDistribution = (data: any) => ({ type: SET_STAR_RATING_DEVICE_DISTRIBUTION, payload: data });

export const fetchStarRatingAverageRatingByMarket = (markets: string) => ({
  type: FETCH_STAR_RATING_AVERAGE_RATING_BY_MARKET,
  payload: markets,
});
export const setStarRatingAverageRatingByMarket = (data: any) => ({ type: SET_STAR_RATING_AVERAGE_RATING_BY_MARKET, payload: data });

export const fetchStarRatingAverageRatingSelectedMarkets = (markets: string) => ({
  type: FETCH_STAR_RATING_AVERAGE_RATING_SELECTED_MARKETS,
  payload: markets,
});
export const setStarRatingAverageRatingSelectedMarkets = (data: any) => ({
  type: SET_STAR_RATING_AVERAGE_RATING_SELECTED_MARKETS,
  payload: data,
});

export const fetchExitSurveyObjectiveCompletion = () => ({ type: FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION });
export const setExitSurveyObjectiveCompletion = (data: any) => ({ type: SET_EXIT_SURVEY_OBJECTIVE_COMPLETION, payload: data });

export const fetchExitSurveyFeedback = () => ({ type: FETCH_EXIT_SURVEY_FEEDBACK });
export const setExitSurveyFeedback = (data: any) => ({ type: SET_EXIT_SURVEY_FEEDBACK, payload: data });

export const fetchExitSurveyObjectiveCompletionTrend = () => ({ type: FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION_TREND });
export const setExitSurveyObjectiveCompletionTrend = (data: any) => ({ type: SET_EXIT_SURVEY_OBJECTIVE_COMPLETION_TREND, payload: data });

export const fetchExitSurveyNonCompletionReasons = () => ({ type: FETCH_EXIT_SURVEY_NON_COMPLETION_REASONS });
export const setExitSurveyNonCompletionReasons = (data: any) => ({ type: SET_EXIT_SURVEY_NON_COMPLETION_REASONS, payload: data });

export const fetchExitSurveyDeviceDistribution = () => ({ type: FETCH_EXIT_SURVEY_DEVICE_DISTRIBUTION });
export const setExitSurveyDeviceDistribution = (data: any) => ({ type: SET_EXIT_SURVEY_DEVICE_DISTRIBUTION, payload: data });

export const fetchExitSurveyObjectiveCompletionByMarket = (markets: string) => ({
  type: FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION_BY_MARKET,
  payload: markets,
});
export const setExitSurveyObjectiveCompletionByMarket = (data: any) => ({
  type: SET_EXIT_SURVEY_OBJECTIVE_COMPLETION_BY_MARKET,
  payload: data,
});

export const fetchExitSurveyObjectiveCompletionSelectedMarkets = (markets: string) => ({
  type: FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION_SELECTED_MARKETS,
  payload: markets,
});
export const setExitSurveyObjectiveCompletionSelectedMarkets = (data: any) => ({
  type: SET_EXIT_SURVEY_OBJECTIVE_COMPLETION_SELECTED_MARKETS,
  payload: data,
});

export const fetchRatingPerTouchpointMarketBreakdown = () => ({
  type: FETCH_RATING_PER_TOUCHPOINT_MARKET_BREAKDOWN,
});
export const setRatingPerTouchpointMarketBreakdown = (data: any) => ({
  type: SET_RATING_PER_TOUCHPOINT_MARKET_BREAKDOWN,
  payload: data,
});

export const fetchRatingPerTouchpointVlpFocus = () => ({
  type: FETCH_RATING_PER_TOUCHPOINT_VLP_FOCUS,
});
export const setRatingPerTouchpointVlpFocus = (data: any) => ({
  type: SET_RATING_PER_TOUCHPOINT_VLP_FOCUS,
  payload: data,
});

export const fetchRatingPerTouchpointElectrification = () => ({
  type: FETCH_RATING_PER_TOUCHPOINT_ELECTRIFICATION,
});
export const setRatingPerTouchpointElectrification = (data: any) => ({
  type: SET_RATING_PER_TOUCHPOINT_ELECTRIFICATION,
  payload: data,
});

export const fetchRatingPerTouchpointOtherTouchpoints = () => ({
  type: FETCH_RATING_PER_TOUCHPOINT_OTHER_TOUCHPOINTS,
});
export const setRatingPerTouchpointOtherTouchpoints = (data: any) => ({
  type: SET_RATING_PER_TOUCHPOINT_OTHER_TOUCHPOINTS,
  payload: data,
});

export const fetchRatingPerTouchpointMediaCampaigns = () => ({
  type: FETCH_RATING_PER_TOUCHPOINT_MEDIA_CAMPAIGNS,
});
export const setRatingPerTouchpointMediaCampaigns = (data: any) => ({
  type: SET_RATING_PER_TOUCHPOINT_MEDIA_CAMPAIGNS,
  payload: data,
});

export const fetchRatingPerTouchpointCompletionRate = () => ({
  type: FETCH_RATING_PER_TOUCHPOINT_COMPLETION_RATE,
});
export const setRatingPerTouchpointCompletionRate = (data: any) => ({
  type: SET_RATING_PER_TOUCHPOINT_COMPLETION_RATE,
  payload: data,
});

export const fetchTopicClassificationDissatisfaction = () => ({
  type: FETCH_TOPIC_CLASSIFICATION_DISSATISFACTION,
});
export const setTopicClassificationDissatisfaction = (data: any) => ({
  type: SET_TOPIC_CLASSIFICATION_DISSATISFACTION,
  payload: data,
});

export const fetchTopicClassificationSatisfaction = () => ({
  type: FETCH_TOPIC_CLASSIFICATION_SATISFACTION,
});
export const setTopicClassificationSatisfaction = (data: any) => ({
  type: SET_TOPIC_CLASSIFICATION_SATISFACTION,
  payload: data,
});

export const fetchFeedbackComments = (experience_rating: any, topics: any, touchpoints: any) => ({
  type: FETCH_FEEDBACK_COMMENTS,
  experience_rating,
  topics,
  touchpoints,
});
export const setFeedbackComments = (data: any) => ({
  type: SET_FEEDBACK_COMMENTS,
  payload: data,
});

export const fetchRatingPerTouchpointOwnership = () => ({ type: FETCH_RATING_PER_TOUCHPOINT_OWNERSHIP });
export const setRatingPerTouchPointOwnership = (data: any) => ({ type: SET_RATING_PER_TOUCHPOINT_OWNERSHIP, payload: data });

export const fetchRatingPerTouchPointEditorial = () => ({ type: FETCH_RATING_PER_TOUCHPOINT_EDITORIAL });
export const setRatingPerTouchPointEditorial = (data: any) => ({ type: SET_RATING_PER_TOUCHPOINT_EDITORIAL, payload: data });

export const setCsvExperienceRating = (data: any) => ({ type: SET_CSV_EXPERIENCE_RATING, payload: data });

export const fetchFeedbackCommentsFilters = () => ({ type: FETCH_FEEDBACK_COMMENTS_FILTERS });
export const setFeedbackCommentsFilters = (data: any) => ({ type: SET_FEEDBACK_COMMENTS_FILTERS, payload: data });
