import React from "react";

interface Props {
  id: string;
  label: React.ReactNode;
  name: string;
  value?: string | number | null;
  readonly?: true;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: any;
  className?: string;
  pattern?: string;
}

export const TextInput = (props: Props): JSX.Element => {
  let wrapperClass = "input_field";

  if (props.error?.length > 0) {
    wrapperClass += " has-error";
  }

  return (
    <div className={wrapperClass}>
      <label htmlFor={props.id}>{props.label}</label>
      <div className="field">
        {props.readonly ? (
          <input
            type="text"
            name={props.name}
            value={props.value ? props.value : ""}
            onChange={props.onChange}
            id={props.id}
            readOnly
            className={props.className ? props.className : ""}
          />
        ) : (
          <input
            type="text"
            name={props.name}
            value={props.value ? props.value : ""}
            onChange={props.onChange}
            id={props.id}
            className={props.className ? props.className : ""}
            pattern={props.pattern}
          />
        )}
      </div>
      {props.error && <div className="alert alert-danger">{props.error}</div>}
    </div>
  );
};
