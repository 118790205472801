import { PieChart, PieSeries } from "@amcharts/amcharts4/charts";
import { color, ColorSet, create, Label, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { disposeChart } from "../../../../../components/Charts/helpers/chartHelpers";
import { legendConfiguration } from "../../../../../components/Charts/helpers/configurations";
import { ThemeInt } from "../../../../../components/Charts/helpers/types";
import { ACCENT_BROWN, BLACK, WHITE } from "../../../../../constants";
import { IGlobalViewDonutData } from "../../../../../constants/interface/ariya";
import style from "./globalViewDonut.module.scss";

interface Props {
  data: IGlobalViewDonutData;
  theme: ThemeInt;
}

export class GlobalViewsDonut extends Component<Props> {
  chartId = "globalViewsDonut";

  chart!: PieChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId);
      this.initChart();
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, theme } = this.props;

    const colorSet = new ColorSet();
    colorSet.list = [theme === "dark" ? color(WHITE) : color(BLACK), color(ACCENT_BROWN)];

    this.chart = create(this.chartId, PieChart);
    this.chart.data = data;
    this.chart.innerRadius = percent(30);
    this.chart.radius = percent(65);

    // Configure Legend
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.position = "bottom";
    this.chart.legend.align = "right";
    this.chart.legend.labels.template.text = "[font-size: 0.75rem;]{name}[/]";
    this.chart.legend.valueLabels.template.disabled = true;
    this.chart.legend.paddingTop = 15;

    // Creating Series
    const pieSeries = this.chart.series.push(new PieSeries());
    pieSeries.colors = colorSet;
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "name";
    pieSeries.slices.template.tooltipText =
      "[font-size: 0.75rem; bold;]{category}:[/font-size: 0.75rem;] {value.percent.formatNumber('#.')}%[/]";

    pieSeries.ticks.template.disabled = true;
    pieSeries.alignLabels = false;
    pieSeries.labels.template.text = "{value.percent.formatNumber('#.')}%";
    pieSeries.labels.template.fontSize = "0.75rem";
    pieSeries.labels.template.radius = percent(-25);
    pieSeries.labels.template.fill = theme === "light" ? color(WHITE) : color(BLACK);

    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    const hoverState = pieSeries.slices.template.states.getKey("hover");
    if (hoverState) hoverState.properties.scale = 1.015;

    const activeState = pieSeries.slices.template.states.getKey("active");
    if (activeState) activeState.properties.shiftRadius = 0;

    const label = pieSeries.createChild(Label);
    label.text = "[bold]{values.value.sum}[/]";
    label.horizontalCenter = "middle";
    label.verticalCenter = "middle";
    label.fill = theme === "light" ? color(BLACK) : color(WHITE);
    label.fontSize = "0.95rem";

    this.chart.events.on("sizechanged", function () {
      let scale = (pieSeries.pixelInnerRadius * 1.75) / label.bbox.width;
      if (scale > 1) {
        scale = 1;
      }
      label.scale = scale;
    });
  }
  render() {
    return <div id="globalViewsDonut" className={style.chart} />;
  }
}
