import { Box, Button, makeStyles } from "@material-ui/core";
import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getSeoFilters } from "../../../api";
import { deleteSeoInsight, getSeoInsights } from "../../../api/adminApi";
import { ErrorMsg } from "../../../components/AppMessages";
import { CYAN } from "../../../constants";
import { ISeoInsight } from "../../../constants/interface/admin/seoInsights";
import ColumnVisibilityDialog from "../common/Dialogs/ColumnVisibilityDialog";
import UserSearchField from "../common/Fields/UserSearchField";
import { CreateUpdateDialogue } from "./subs/Dialogs/CreateUpdateDialogue";
import { DeleteDialogue } from "./subs/Dialogs/DeleteDialogue";
import { InsightsTable } from "./subs/InsightsTable";

export const AdminSeoInsights = () => {
  const [deleteDialogueOpen, setDeleteDialogueOpen] = useState<boolean>(false);
  const [formDialogueOpen, setFormDialogueOpen] = useState<boolean>(false);
  const [selectedInsight, setSelectedInsight] = useState<ISeoInsight | undefined>(undefined);
  const [insights, setInsights] = useState<Array<ISeoInsight>>([]);
  const [availableMarkets, setAvailableMarkets] = useState<Array<string>>([]);
  const [columnVisibilityDialogOpen, setColumnVisibilityDialogOpen] = useState<boolean>(false);
  const [visibleColumns, setVisibleColumns] = useState<string[]>(["country", "month", "year", "show_insights"]);
  const [searchValue, setSearchValue] = useState<string>("");
  const filteredSeoInsights = insights.filter((insight) => insight.country.toLowerCase().includes(searchValue.toLowerCase()));

  const classes = makeStyles({
    button: {
      backgroundColor: CYAN,
      color: "#FFF !important",
      borderRadius: "0px !important",
      border: "none !important",
      marginRight: "10px",
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      "&:hover": {
        backgroundColor: "#5ed2ff !important",
        border: "none !important",
      },
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
  })();

  const allColumns = [
    { id: "country", title: "Country" },
    { id: "month", title: "Month" },
    { id: "year", title: "Year" },
    { id: "insights", title: "Insights" },
    { id: "show_insights", title: "Show" },
  ];

  useEffect(() => {
    Promise.all([
      getSeoInsights().then((response) => {
        if (response && !("error" in response)) setInsights(response.data);
      }),
      getSeoFilters().then((response) => {
        if (response && !("error" in response)) {
          setAvailableMarkets(response.geography.map((geo) => geo.market).sort());
        }
      }),
    ]);
  }, []);

  const handleToggleDeleteDialog = () => setDeleteDialogueOpen(!deleteDialogueOpen);

  const handleToggleFlashFormDialogue = useCallback(() => {
    setFormDialogueOpen((prevState) => !prevState);
    if (formDialogueOpen) {
      setSelectedInsight(undefined);
    }
  }, [formDialogueOpen]);

  const handleDeleteFlashMsg = (id: number) => {
    deleteSeoInsight(id).then((response) => {
      if (response && response === "success") {
        setInsights((prevState) => prevState.filter((insight) => insight.seo_insights_id !== id));
        setSelectedInsight(undefined);
        handleToggleDeleteDialog();
      } else {
        toast.error("Failed to delete seo insight");
      }
    });
  };

  const handleToogleColumnVisibilityDialog = () => {
    setColumnVisibilityDialogOpen(!columnVisibilityDialogOpen);
  };

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <Box component="div">
        <Box className={classes.buttonsContainer} component="div" mb={3}>
          <Box component="div">
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={() => {
                setSelectedInsight(undefined);
                handleToggleFlashFormDialogue();
              }}
              data-test-id="btn-add-new-user"
            >
              New
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={handleToggleFlashFormDialogue}
              disabled={!selectedInsight}
              data-test-id="btn-edit-user"
            >
              Edit
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={handleToggleDeleteDialog}
              disabled={!selectedInsight}
              data-test-id="btn-delete-user"
            >
              Delete
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={handleToogleColumnVisibilityDialog}
              data-test-id="btn-user"
            >
              Column Visibility
            </Button>
          </Box>
          <Box component="div">
            <UserSearchField value={searchValue} setValue={setSearchValue} />
          </Box>
        </Box>

        <ErrorBoundary fallback={<ErrorMsg />}>
          <InsightsTable
            data={filteredSeoInsights}
            selectedInsight={selectedInsight}
            setSelectedInsights={setSelectedInsight}
            setDeleteDialogOpen={setDeleteDialogueOpen}
            visibleColumns={visibleColumns}
          />
        </ErrorBoundary>

        {deleteDialogueOpen && selectedInsight && (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <DeleteDialogue
              insight={selectedInsight}
              open={deleteDialogueOpen}
              handleClose={handleToggleDeleteDialog}
              handleDelete={handleDeleteFlashMsg}
            />
          </ErrorBoundary>
        )}

        {formDialogueOpen && (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <CreateUpdateDialogue
              markets={availableMarkets}
              selectedInsight={selectedInsight}
              open={formDialogueOpen}
              toggleOpen={handleToggleFlashFormDialogue}
              setInsights={setInsights}
            />
          </ErrorBoundary>
        )}

        {columnVisibilityDialogOpen && (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <ColumnVisibilityDialog
              open={columnVisibilityDialogOpen}
              toggleOpen={handleToogleColumnVisibilityDialog}
              visibleColumns={visibleColumns}
              setVisibleColumns={setVisibleColumns}
              columns={allColumns}
            />
          </ErrorBoundary>
        )}
      </Box>
    </ErrorBoundary>
  );
};
