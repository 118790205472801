import React, { Dispatch, useState } from "react";
import "../../../assets/styles/component/dropDown.scss";
import { businessFunctionsOptions } from "../../../constants";
import { useDropdownVisible } from "../../../hooks";

interface BusinessFunctionInputProps {
  businessFunction: string;
  setBusinessFunction: Dispatch<string>;
}

export const BusinessFunctionInput = (Props: BusinessFunctionInputProps): JSX.Element => {
  const { businessFunction, setBusinessFunction } = Props;
  const {
    wrapperRef: businesssFunctionsRef,
    isVisible: showBusinessFunctions,
    setIsVisible: setShowBusinessFunctions,
  } = useDropdownVisible(false);

  const [customBusinessInput, setCustomBusinessInput] = useState<boolean>(false);

  const handleBusinessSelect = (option: string) => {
    setCustomBusinessInput(false);
    setBusinessFunction(option);
    setShowBusinessFunctions(false);
  };

  //Allow users to type the business function when they select the option Other
  const handleOtherOptionSelect = () => {
    setBusinessFunction("");
    setCustomBusinessInput(true);
    setShowBusinessFunctions(false);
  };

  return (
    <div className="input_field" id="businessFunction_input" ref={businesssFunctionsRef}>
      <label htmlFor="businessFunction">Business function</label>
      <div className="field">
        <div className="dropdown" onClick={() => setShowBusinessFunctions(!showBusinessFunctions)}>
          {customBusinessInput ? (
            <input
              type="text"
              name="customBusinessFunction"
              id="customBusinessFunction"
              value={businessFunction}
              onChange={(e) => setBusinessFunction(e.target.value)}
              placeholder="Type"
              data-test-id="business_function"
              pattern="[a-zA-Z0-9\s]+"
              title="Business function should only contain letters and numbers"
            />
          ) : (
            <input
              type="text"
              name="businessFunction"
              id="businessFunction"
              readOnly
              value={businessFunction}
              onChange={(e) => setBusinessFunction(e.target.value)}
              data-test-id="business_function"
            />
          )}
          <span className="arrow_down"></span>
        </div>
        {showBusinessFunctions && (
          <ul className="dropdown-options" id="businessFunction_options">
            {businessFunctionsOptions.map((option: string) => (
              <li key={option} onClick={() => handleBusinessSelect(option)}>
                {option}
              </li>
            ))}
            <li onClick={handleOtherOptionSelect}>Other</li>
          </ul>
        )}
      </div>
    </div>
  );
};
