import React, { ReactNode, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../../components/Ratings/Ratings";
import { getPoPLabel, isNull } from "../../../../utils/utilityFunctions";

interface Props {
  title: string;
  mainValue: number | null | string;
  isPercentage?: boolean;
  subText?: string;
  yoyValue?: number | string;
  popValue?: number | string;
  targetValue?: number | string;
  isLoading?: boolean;
  toggle?: ReactNode;
  showCurrency?: boolean;
  showSign?: boolean;
  ratingInverse?: boolean;
  ratingArrowInverse?: boolean;
  tooltipText: string;
  hideRating?: boolean;
}

const ValueTile = (props: Props) => {
  const {
    title,
    mainValue,
    isPercentage,
    subText,
    yoyValue,
    popValue,
    isLoading,
    toggle,
    showCurrency,
    showSign,
    ratingInverse,
    ratingArrowInverse,
    tooltipText,
    targetValue,
    hideRating,
  } = props;

  const currency = useSelector((state: RootStateOrAny) => state.journey_excellence.currency);
  const dateRangeParam = useSelector((state: RootStateOrAny) => state.parameters.date_range);

  const popLabel = useMemo(() => {
    return getPoPLabel(dateRangeParam);
  }, [dateRangeParam]);

  return (
    <div className="tile value-tile">
      {isLoading ? (
        <LoadingEllipsis isLoading={isLoading} />
      ) : (
        <>
          {toggle ? toggle : null}
          <div className="tile-header">
            <p>{title}</p>
            <Tooltip
              position="top"
              className={"normal_tippy"}
              trigger="mouseenter"
              animation="none"
              interactive={true}
              delay={1}
              hideDelay={1}
              duration={1}
              title={tooltipText}
              size={"small"}
            >
              <span className="info_tooltip" />
            </Tooltip>
          </div>
          <div className="tile-content">
            {!isNull(mainValue) ? (
              <div className="main-value">
                {showSign ? (Number(mainValue) > 0 ? "+" : "") : ""}
                {showCurrency ? currency : ""}
                {isPercentage ? mainValue : Number(mainValue).toLocaleString()}
                {isPercentage ? "%" : ""}
              </div>
            ) : (
              <div className="main-value">n/a</div>
            )}

            {subText ? <div className="sub-text">{subText}</div> : null}
            {yoyValue ? (
              <div className="sub-text">
                {hideRating ? (
                  Number(yoyValue) > 0 ? (
                    "+"
                  ) : (
                    ""
                  )
                ) : (
                  <Ratings
                    value={Number(yoyValue)}
                    testValue={false}
                    isPercentageValue={false}
                    ratingIsInverse={ratingInverse}
                    arrowIsInverse={ratingArrowInverse}
                  />
                )}
                {Number(yoyValue) > 0 ? "+" : ""}
                {yoyValue}
                {isPercentage ? "PTS" : "%"} YoY
              </div>
            ) : null}
            {popValue ? (
              <div className="sub-text">
                {hideRating ? (
                  Number(popValue) > 0 ? (
                    "+"
                  ) : (
                    ""
                  )
                ) : (
                  <Ratings
                    value={Number(popValue)}
                    testValue={false}
                    isPercentageValue={false}
                    ratingIsInverse={ratingInverse}
                    arrowIsInverse={ratingArrowInverse}
                  />
                )}
                {Number(popValue) > 0 ? "+" : ""}
                {popValue}
                {isPercentage ? "PTS" : "%"} {popLabel}
              </div>
            ) : null}
            {targetValue ? (
              <div className="sub-text">
                {hideRating ? (
                  Number(targetValue) > 0 ? (
                    "+"
                  ) : (
                    ""
                  )
                ) : (
                  <Ratings
                    value={Number(targetValue)}
                    testValue={false}
                    isPercentageValue={false}
                    ratingIsInverse={ratingInverse}
                    arrowIsInverse={ratingArrowInverse}
                  />
                )}
                {Number(targetValue) > 0 ? "+" : ""}
                {targetValue}
                {isPercentage ? "PTS" : "%"} vs target
              </div>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default ValueTile;
