import TextField from "@material-ui/core/TextField";
import React from "react";
import { Controller, FieldValues, UseControllerProps, UseFormSetValue } from "react-hook-form";
import { FormFields } from "./Dialogs/AnnotationDialog";

interface Props<T> extends UseControllerProps<any> {
  setValue: UseFormSetValue<FormFields>;
}

const StatusNoteField = <T extends FieldValues>({ name, control }: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <>
          <TextField value={value} variant="outlined" label="Status Note" onChange={onChange} />
        </>
      )}
    />
  );
};

export default StatusNoteField;
