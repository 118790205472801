import React, { useContext } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { MetricBreadcrumbPill } from ".";
import { DocumentationContext } from "../../../context";
import { useVisible } from "../../../hooks";

const SeoPageTitle = () => {
  const pageName = useSelector((state: RootStateOrAny) => state.page.pageName);
  const { wrapperRef: tooltipRef, isVisible: isTooltipVisible, setIsVisible: setIsTooltipVisible } = useVisible(false);
  const { setIsVisible, setDocumentation } = useContext(DocumentationContext);

  return (
    <ul className={"dashboard_options seo_dashboard_title"}>
      <li className={"active"} id={"pageTitle"}>
        {pageName} <MetricBreadcrumbPill />
        <Tooltip
          position="top-end"
          className={"normal_tippy"}
          trigger="mouseenter"
          animation="none"
          interactive={true}
          distance={50}
          delay={1}
          hideDelay={1}
          duration={1}
          size={"small"}
          html={
            <p>
              This view combines Google Search Results engagements performance metrics (Impressions and Clicks) extracted from Google Search
              Console and Natural Search metrics (Visits, KBA Visits and Leads) extracted from Adobe Analytics to give an overview on the
              SEO performance and impact on the website traffic.
              <span
                onClick={() => {
                  setIsVisible(true);
                  setDocumentation("seo_data");
                }}
              >
                (See more)
              </span>
            </p>
          }
        >
          <span className="info_tooltip" />
        </Tooltip>
      </li>
    </ul>
  );
};

export default SeoPageTitle;
