import React, { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import UseOwnershipDonutChart from "../../../../components/Charts/UseOwnership/UseOwnershipDonutChart";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../../components/Ratings/Ratings";
import { FOREST_GREEN, GOLDEN_YELLOW, PURE_ORANGE, RACING_GREEN, RED } from "../../../../constants";
import { ThemeContext } from "../../../../context";
import { getGranularityFromDate } from "../../../../utils/utilityFunctions";

interface Props {
  data: any;
  title: string;
  titleTooltipText: string;
  mainValue: number;
  yoyValue: number;
  popValue: number;
  isLoading: boolean;
}

const donutColorMapping: Record<number, string> = {
  1: RED,
  2: PURE_ORANGE,
  3: GOLDEN_YELLOW,
  4: RACING_GREEN,
  5: FOREST_GREEN,
};

const PoPLabelMapping: Record<string, string> = {
  monthly: "MoM",
  quarterly: "QoQ",
};

const MetricTileWithDonutChart = (props: Props) => {
  const { data, title, titleTooltipText, mainValue, yoyValue, popValue, isLoading } = props;
  const { theme } = useContext(ThemeContext);
  const dateRangeParam = useSelector((state: RootStateOrAny) => state.parameters.date_range);

  const coloredScoreDistributionData = useMemo(() => {
    return data
      .sort((a: any, b: any) => b.experience_rating - a.experience_rating)
      .map((item: { experience_rating: number }) => ({
        ...item,
        color: donutColorMapping[item.experience_rating],
      }));
  }, [data]);

  const PoPLabel = useMemo(() => {
    const dateGranularity = getGranularityFromDate(dateRangeParam, "monthly");
    return PoPLabelMapping[dateGranularity];
  }, [dateRangeParam]);

  return (
    <div className="tile metric-tile">
      <div className="chart">
        <UseOwnershipDonutChart
          chartId="avgVocRatingDonutChart"
          valueField="rating_percentage"
          categoryField="experience_rating"
          theme={theme}
          data={coloredScoreDistributionData}
          isLoading={false}
          totalAvg={mainValue?.toLocaleString()}
          hideLabels={true}
        />
      </div>
      <div className="tile-content">
        <div className="title-container">
          <div className="title">{title}</div>
          <Tooltip
            position="top-end"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title={titleTooltipText}
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>

        <div className="value">{mainValue ? `${mainValue?.toLocaleString()}/5` : "n/a"}</div>

        <div className="variance">
          <div className="yoy-variance">
            <div>
              <Ratings value={yoyValue} isPercentageValue={false} testValue={false} />
            </div>
            {yoyValue ? `${yoyValue} YoY` : "n/a"}
          </div>
          {popValue && (
            <div className="pop-variance">
              <Ratings value={popValue} isPercentageValue={false} testValue={false} />
              {popValue} {PoPLabel}
            </div>
          )}
        </div>
      </div>
      <LoadingEllipsis isLoading={isLoading} />
    </div>
  );
};

export default MetricTileWithDonutChart;
