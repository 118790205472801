import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setSpecificParameter } from "../../../actions";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import ComparsionPriceVolumeChart from "../../../components/Charts/ProductMarketingOptimization/ComparsionPriceVolumeChart";
import { MultiSelectFilterSerena } from "../../../components/Filters/common/MulitiSelectFIlterSerena";
import { closeFilterOptions, closeMarketOptions } from "../../../components/Filters/subs/helpers";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { currencies } from "../../../constants/currenctConstants";
import { FilterContext, ThemeContext } from "../../../context";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { isEmpty } from "../../../utils/utilityFunctions";

interface Props {
  seriesList: any[];
}

const ComparisonPriceVolume = (props: Props) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const { seriesList } = props;
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);
  const data = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.price_volume_trend?.data);
  const loading = useSelector((state: RootStateOrAny) => state.loading.price_volume_trend);
  const granularity = useSelector((state: RootStateOrAny) => state.parameters.granularity);

  const currency = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.price_volume_sales?.currency ?? "");
  const newAverageModels = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.average_models ?? []);
  const marketParam = useSelector((state: RootStateOrAny) => state.parameters.market);
  const modelParam = useSelector((state: RootStateOrAny) => state.parameters.model);

  const averageModelsParam = useSelector((state: RootStateOrAny) => state.parameters.average_models);

  const competitorModels = useSelector(
    (state: RootStateOrAny) => state.product_marketing_optimization.price_volume_sales?.competitor_models ?? []
  );

  const [selectedAverageModels, setSelectedAverageModels] = useState<string[]>([]);
  const [averageModelFilterValue, setAverageModelFilterValue] = useState<string>("");
  const [modelController, setModelController] = useState(false);

  //Checks competetior models
  useEffect(() => {
    if (!modelController) {
      const averageModel: any = [];
      let averageModelObject = "";
      if (newAverageModels.length > 0) {
        newAverageModels.map((e: any) => {
          const models = e.competitor_models.filter((dt: any) => {
            if (!dt.includes("NISSAN")) return dt;
          });

          averageModel.push({ Segment_name_BIG3: e.Segment_name_BIG3, average_models: models });
        });

        averageModelObject = JSON.stringify(averageModel);
      }

      if (!(averageModelsParam === averageModelObject)) {
        dispatch(setSpecificParameter("average_models", averageModelObject));
      }
    }
  }, [newAverageModels]);

  useEffect(() => {
    if (modelController) setModelController(false);
  }, [marketParam, modelParam]);

  const fLIst = useMemo(() => {
    return competitorModels.map((e: any) => {
      const child = e?.competitor_models?.map((d: any) => {
        return { model: d, group: e?.Segment_name_BIG3?.replace(/ /g, "-") };
      });
      return { group: e?.Segment_name_BIG3?.replace(/ /g, "-"), child };
    });
  }, [competitorModels]);

  useEffect(() => {
    let selectedModels = "";
    let count = 0;

    if (averageModelsParam.length === 0 || averageModelsParam == "Nissan Qashqai") {
      selectedModels = `${count} models selected`;
    } else {
      const avmObj = JSON.parse(averageModelsParam);
      let fListSum = 0;
      avmObj.map((e: any) => (count += e.average_models.length));
      fLIst.map((e: any) => (fListSum += e.child.length));

      selectedModels = fListSum === count ? `All` : `${count} models selected`;
    }

    setAverageModelFilterValue(selectedModels);
  }, [averageModelsParam, selectedAverageModels, fLIst]);

  const currencySymbol = useMemo(() => {
    //@ts-ignore
    if (currencies[currency] != undefined) {
      //@ts-ignore
      return currencies[currency]["symbol"];
    }
  }, [currency]);

  const paramValueArray = useMemo(() => {
    return fLIst.map((e: any) => {
      return e?.child?.map((dt: any) => {
        if (!dt.model.includes("NISSAN")) return `${dt.model} ${e.group}`;
      });
    });
  }, [fLIst]);

  const handleDateFilterSubmission = (data: string[], parents: string[]) => {
    const parameterName = "average_models";

    const filterd_data: any[] = [];
    parents.map((e: any) => {
      const ave_models_array: any[] = [];
      data.map((dt: any) => {
        if (dt.includes(`${" "}${e}`)) {
          ave_models_array.push(dt.replace(`${" "}${e}`, ""));
        }
      });
      filterd_data.push({ Segment_name_BIG3: e, average_models: ave_models_array });
    });

    setSelectedAverageModels(data);

    const value = JSON.stringify(filterd_data);
    setModelController(true);

    dispatch(setSpecificParameter(parameterName, value));

    eventTracking(MixpanelEvents.filter_change, { filter: "average_models", value, dashboard: "PMO" });
  };

  const showFilterOptionsFn = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const optionsId = dataset?.optionsUl;
      const shownClass = "showOptions";

      const optionsElement = optionsId ? document.getElementById(optionsId) : undefined;

      if (showFilterOptions && optionsElement?.classList.contains(shownClass)) {
        closeFilterOptions();
        closeMarketOptions();
        setShowFilterOptions(false);
      } else {
        closeFilterOptions();
        optionsElement?.classList.toggle(shownClass);
        setShowFilterOptions(!showFilterOptions);
      }
    },
    [showFilterOptions, setShowFilterOptions]
  );

  return (
    <div className="comparison_price_volume primary_tile_background">
      <div className="header">
        <div className="title_container">
          <h3>Price sales volume curve trend</h3>
        </div>
        <div className="model_filter" style={{ position: "absolute", right: 0, marginTop: "-1.8em" }}>
          <ErrorBoundary fallback={<ErrorMsg />}>
            <MultiSelectFilterSerena
              parentKey="group"
              // @ts-ignore
              filterList={fLIst}
              filterName="Select average models"
              childKey="model"
              value={averageModelFilterValue}
              parameterName={"average_models"}
              parameterValue={modelController ? selectedAverageModels.toString() : paramValueArray.toString()}
              onShowFilterOptions={showFilterOptionsFn}
              handleFilterSubmission={handleDateFilterSubmission}
            />
          </ErrorBoundary>
        </div>
      </div>

      <div className="main_content">
        {!isEmpty(data) ? (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <ComparsionPriceVolumeChart
              granularity={granularity}
              data={isEmpty(data) ? {} : data}
              isLoading={false}
              seriesList={seriesList}
              theme={themeContext.theme}
              currency={currencySymbol}
              market={marketParam}
            />
          </ErrorBoundary>
        ) : loading ? (
          <LoadingEllipsis isLoading={loading} />
        ) : (
          <NoDataMsg message="No data available for this market" />
        )}
      </div>
    </div>
  );
};

export default ComparisonPriceVolume;
