import { CategoryAxis, ColumnSeries, LabelBullet, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, WHITE } from "../../../constants";
import { toLocaleFloat } from "../../../utils/utilityFunctions";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { configureCategoryAxis, configureValueAxis } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  theme: ThemeInt;
  isLoading: boolean;
  seriesList: any;
  chartName: string;
  categories: string[];
  unitParam?: string;
  columnPercentage?: number;
  minAmount: number;
  maxAmount: number;
  hideAxis?: boolean;
}

const unitParamMultipliers: Record<string, number> = {
  K: 1e3,
  M: 1e6,
  B: 1e9
}

class StrategicDealerSupportChart extends Component<Props> {
  chartId = this.props.chartName;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { theme, seriesList, data, maxAmount, unitParam, minAmount, hideAxis } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.paddingBottom = 10;

    const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.renderer.cellStartLocation = 0;
    categoryAxis.renderer.cellEndLocation = 1;
    categoryAxis.renderer.minGridDistance = 1;
    categoryAxis.dataFields.category = "quarter";
    categoryAxis.renderer.labels.template.fontSize = "13px";
    categoryAxis.renderer.labels.template.disabled = true;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.numberFormatter.numberFormat = "#a";
    valueAxis.renderer.labels.template.fontSize = "13px";
    valueAxis.renderer.minGridDistance = 200;
    valueAxis.min = minAmount;
    valueAxis.max = maxAmount;
    valueAxis.renderer.labels.template.disabled = hideAxis ? true : false;

    seriesList.forEach((val: { name: string; seriesColor: string }) => {
      const series1 = this.chart.series.push(new ColumnSeries());
      series1.name = val.name;
      series1.dataFields.valueY = `total_${val.name}`;
      series1.dataFields.valueX = `total_${val.name}`;
      series1.dataFields.categoryX = `quarter`;
      series1.fill = color(val.seriesColor);
      series1.stroke = color(val.seriesColor);
      series1.yAxis = valueAxis;
      series1.xAxis = categoryAxis;

      if (this.props.columnPercentage) {
        series1.columns.template.width = percent(this.props.columnPercentage);
      }

      const labelBullet = series1.bullets.push(new LabelBullet());
      labelBullet.label.text = `{valueY.formatNumber("#,###.0a")}`;
      labelBullet.label.fill = color(this.props.theme === "light" ? BLACK : WHITE);
      labelBullet.label.truncate = false;
      labelBullet.label.fontSize = "13px";
      labelBullet.label.hideOversized = false;
      labelBullet.label.adapter.add("verticalCenter", () => "bottom");

      series1.columns.template.events.on("sizechanged", function (ev) {
        //    @ts-ignore
        const data = ev?.target?.dataItem?.dataContext[`total_${val.name}`];
        //    @ts-ignore
        if (ev.target?.dataItem && ev.target?.dataItem?.bullets) {
          //    @ts-ignore
          ev.target?.dataItem?.bullets.each((id, bullet) => {
            bullet.label.text = `${toLocaleFloat(unitParam ? labelNumberConversion(Number(data), unitParam) : Number(data), 1)}${unitParam}`;
          });
        }
      });
    });

    const labelNumberConversion = (value: number, unitParam: string) => {
      return value / unitParamMultipliers[unitParam]
    }
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default StrategicDealerSupportChart;
