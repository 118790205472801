export const getMaxAmount = (data: any) => {
  let max = 0;
  if (data?.length)
    data?.map((val: { raw_amount: number }) => {
      if (max < val.raw_amount) max = val.raw_amount;
    });
  return max;
};

export const getMinAmount = (data: any) => {
  let min = Number.POSITIVE_INFINITY;
  if (data?.length)
    data?.map((val: { raw_amount: number }) => {
      if (min > val.raw_amount) min = val.raw_amount;
    });
  return min;
};

export const getHasNegative = (data: any) => {
  if (data?.length) {
    return data?.some((val: { raw_amount: number }) => {
      val.raw_amount < 0;
    });
  }
};

export const getChartData = (data: any, modelOrder: any, fullYearMax: number) => {
  if (data?.length)
    return data
      ?.map((val: { yr_scnr_rv: string; model: string; amount: number; raw_amount: number; unit: string }) => {
        const result: any = { yr_scnr_rv: val.yr_scnr_rv, mdl_nm_rev: val.model };
        result[`${val.yr_scnr_rv}_amount`] = val.amount;
        result[`${val.yr_scnr_rv}_raw_amount`] = val.raw_amount;
        result[`unit`] = val.unit;
        return result;
      })
      .sort(
        (a: { mdl_nm_rev: string }, b: { mdl_nm_rev: string }) => modelOrder?.indexOf(a.mdl_nm_rev) - modelOrder?.indexOf(b.mdl_nm_rev)
      );

  return [];
};
