import { all, call, spawn } from "redux-saga/effects";
import { watchFetchActivationRatioDataSaga } from "./activationRatioSaga";
import {
  watchFetchAdminBrandsData,
  watchFetchAdminChannelBreakdownsData,
  watchFetchAdminChannelsData,
  watchFetchAdminMarketsData,
  watchFetchAdminMetricsData,
  watchFetchAdminModelsData,
  watchFetchAdminRegionsData,
  watchFetchAdminViewsData,
} from "./adminSaga";
import {
  watchFetchBrandsData,
  watchFetchChannelBreakdownsData,
  watchFetchChannelsData,
  watchFetchLeadSourcesData,
  watchFetchMarketsData,
  watchFetchMetricsData,
  watchFetchNewModelsData,
  watchFetchRegionsData,
} from "./annotationParmetersSaga";
import {
  watchFetchBaselineAnnotationsData,
  watchFetchMtmAnnotationsData,
  watchFetchOCEAnnotationsKbaTypes,
  watchFetchOCEAnnotationsLeadTypes,
  watchFetchVisitsAnnotationsData,
} from "./annotationsSaga";
import {
  watchBrandImageAttributesAverageSaga,
  watchBrandImageAttributesTrendSaga,
  watchBrandOaOAverageSaga,
  watchBrandOaOTrendSaga,
  watchBrandVolumeAverageSaga,
  watchBrandVolumeTrendSaga,
} from "./brandPerformanceSaga";
import { watchFetchCCSAnalysisEnrollmentRateData, watchFetchCCSAnalysisUserEngagementRateData } from "./ccsAnalysisSaga";
import {
  watchFetchAppAnalysisFeatureUsage,
  watchFetchAppAnalysisWebUsage,
  watchFetchAppOpenRate,
  watchFetchAppRatingAvg,
  watchFetchAppRatings,
  watchFetchAppRatingsByMarket,
  watchFetchAppRatingsByMarketChartData,
  watchFetchAppRatingsChartData,
  watchFetchAppRatingsDownloads,
  watchFetchAppReviewBreakdown,
  watchFetchAppReviewByTopic,
  watchFetchAppReviewSatisfactionData,
  watchFetchCCSBusinessRevenueChartData,
  watchFetchCCSBusinessRevenueData,
  watchFetchCCSConnectedCarsChartData,
  watchFetchCCSConnectedCarsData,
  watchFetchCCSJourneyBannerDataSaga,
  watchFetchCcsJourneyData,
  watchFetchCCSSalesRevenueChartData,
  watchFetchCCSSalesRevenueData,
  watchFetchEngagementAppDownloadsChartData,
  watchFetchEngagementAppDownloadsData,
  watchFetchEnrolledCustomersChartData,
  watchFetchEnrolledCustomersData,
  watchFetchEuropeAppRatingsAvg,
  watchFetchEuropeAppRatingsAvgTotal,
  watchFetchEuropeAppRatingsTrend,
  watchFetchG8PrivateActivationRatioChartData,
  watchFetchG8PrivateActivationRatioData,
  watchFetchRenewalChurnRateChartData,
  watchFetchRenewalChurnRateData,
  watchFetchSalesRevenueSource,
  watchFetchTopUsedServices,
  watchFetchWarrantyStartDateSaga,
  watchFetch_AllReviews,
  watchFetch_UserEngagementChartData,
  watchFetch_UserEngagementData,
  watchVOC_CallCentre,
  watchVOC_CallCentreBreakdown,
} from "./ccsCockpitSaga";
import { watchFetchChannels } from "./channelsSaga";
import {
  watchFetchCpoBreakdownTrend,
  watchFetchCpoBreakdownTrendAll,
  watchFetchCpoKbaBreakdownTable,
  watchFetchCpoKbaLabelMapping,
  watchFetchCpoLeadBreakdownTable,
  watchFetchCpoLeadLabelMapping,
  watchFetchCpoVisitsKba,
  watchFetchCpoVisitsLeads,
  watchFetchCpoVisitsTrendLeads,
  watchFetchKeyIndicatorAll,
  watchFetchKeyIndicatorVariance,
  watchFetchModelKeyIndiators,
  watchFetchModelTrend,
} from "./cpoDashboardSaga";
import {
  watchCpoOperationalAverageDaysInStockTrend,
  watchCpoOperationalAverageListPriceTrend,
  watchCpoOperationalAverageSalesPriceTrend,
  watchCpoOperationalBaseTrend,
  watchCpoOperationalDealerTrend,
  watchCpoOperationalInventoryByAgeTrend,
  watchCpoOperationalInventoryRatioTrend,
  watchCpoOperationalInventoryVolumeTrend,
  watchCpoOperationalLastWeekDates,
  watchCpoOperationalLeadSalesConversionTrend,
  watchCpoOperationalListPriceTrend,
  watchCpoOperationalPerformancePerUnitTrend,
  watchCpoOperationalSalesRevenueTrend,
  watchCpoOperationalSalesVolumeTrend,
  watchCpoOperationalTotalValuesTrend,
  watchCpoOperationalTurnoverRatioTrend,
} from "./cpoOperationalSaga";
import {
  watchFetchInfluenceOfShoppingToolsSaga,
  watchFetchNonTdsLeadsSaga,
  watchFetchOrdersSaga,
  watchFetchTdsLeadsSaga,
  watchFetchVisitsDropOffSaga,
} from "./ecommerceSaga";
import {
  watchFetchActivationCountryDataSaga,
  watchFetchActivationDealerDataSaga,
  watchFetchActivationModelDataSaga,
  watchFetchActivationOverallDataSaga,
  watchFetchCockpitActivationData,
  watchFetchCockpitActivationOverallDataSaga,
} from "./europeActivationSaga";
import {
  watchExecutiveSummaryAggregatedPurchaseFunnelSaga,
  watchExecutiveSummaryAggregatedPurchaseFunnelTableSaga,
  watchExecutiveSummaryChannelMixPerformanceSaga,
  watchExecutiveSummaryCloseRateByDateSaga,
  watchExecutiveSummaryCloseRateByMarketSaga,
  watchExecutiveSummaryDetailedComparisonsSaga,
  watchExecutiveSummaryDisPercentageByDateSaga,
  watchExecutiveSummaryDisPercentageByMarketSaga,
  watchExecutiveSummaryDisSummarySaga,
  watchExecutiveSummaryKeyIndicatorsSaga,
  watchExecutiveSummaryPrivateSalesByDateSaga,
  watchExecutiveSummaryPrivateSalesByMarketSaga,
} from "./executiveSummarySagas";
import {
  watchCpoOperationalFiltersSaga,
  watchEcommerceFiltersSaga,
  watchFetchAppRatingsFilters,
  watchFetchBrandPerformanceImageAttributeFiltersSaga,
  watchFetchBrandPerformanceOaOFiltersSaga,
  watchFetchBrandPerformanceVolumeFiltersSaga,
  watchFetchCCSFilters,
  // watchFetchCpoFilters,
  watchFetchEuropeActivationFilters,
  watchFetchEuropeActivationMarketGroups,
  watchFetchExecutiveSummaryFiltersSaga,
  watchFetchGeoScope,
  watchFetchMtmMarkets,
  watchFetchMtmModels,
  watchFetchProductPerformanceFiltersSaga,
  watchFetchSeoGeography,
  watchFetchUserGroupMarkets,
  watchJourneyExcellenceFiltersSaga,
  watchMidasFiltersSaga,
  watchMidasFixedFIltersSaga,
  watchMidasFmiByModelFiltersSaga,
  watchMidasMediaMixltersSaga,
  watchMidasMtpCategoryltersSaga,
  watchMidasSpendVsTrafficFiltersSaga,
  watchMidasStrategicDealerSupportFiltersSaga,
  watchMidasStrategicDealerSupportFIltersSaga,
  watchMsrpFiltersSaga,
  watchOCEAllMarketRegionsFiltersSaga,
  watchOCEAllRegionsFiltersSaga,
  watchOCEChannelsByBrandFiltersSaga,
  watchOCEMarketsByBrandFiltersSaga,
  watchOCEModelsByBrandFiltersSaga,
  watchProductMarketingOptimizationFiltersSaga,
  watchTopFlopFiltersSaga,
  watchUaeActivationFiltersSaga,
  watchUseOwnershipFiltersSaga,
  watchVocFiltersSaga,
} from "./filterSaga";
import {
  watchConversionAndCostSaga,
  watchConversionSaga,
  watchConversionTrendSaga,
  watchCustomerExperienceDxsSaga,
  watchCustomerJourneySaga,
  watchDigitalSpendSaga,
  watchDigitalSpendVisitsSaga,
  watchDigitalSpendVisitsTrendSaga,
  watchExitSurveyFeedbackSaga,
  watchExitSurveyObjectiveCompletionSaga,
  watchFmiLastActMonthSaga,
  watchJourneyCostSaga,
  watchJourneyCostTrendSaga,
  watchLmsConversionAndCostSaga,
  watchLmsConversionAndCostTrendSaga,
  watchLmsDigitallyInfluencedSalesSaga,
  watchLmsDigitallyInfluencedSalesTrendSaga,
  watchLmsDisAndLeadsSaga,
  watchLmsSalesAndLeadsTrendSaga,
  watchMetricChannelSaga,
  watchMetricChannelTrendSaga,
  watchPerformanceOverviewSaga,
  watchProductJourneyEntryPointsSaga,
  watchProductJourneyExitPointsSaga,
  watchProductJourneyPerformanceOverviewSaga,
  watchProductJourneyWebsiteEntryPointsSaga,
  watchStarRatingAverageSaga,
  watchStarRatingFeedbackSaga,
  watchWebsitePerformanceSaga,
  watchWebsitePerformanceTrendSaga,
} from "./journeyExcellenceSaga";
import {
  watchActivationFetchLastDataRefresh,
  watchFetchBrandPerformacneLastRefreshDate,
  watchFetchCpoLastDataRefresh,
  watchFetchCpoOperationalLastRefreshDate,
  watchFetchEcommerceLastRefreshDate,
  watchFetchExecutiveSummaryLastRefreshDate,
  watchFetchJourneyExcellenceLastRefreshDate,
  watchFetchLastDataRefresh,
  watchFetchMarketSpecificDataRefresh,
  watchFetchMidasFixedLastRefreshDate,
  watchFetchMidasFmiByModelLastRefreshDate,
  watchFetchMidasFmiLastRefreshDate,
  watchFetchMidasMediaMixLastRefreshDate,
  watchFetchMidasMtpCategoryLastRefreshDate,
  watchFetchMidasSpendVsTrafficLastRefreshDate,
  watchFetchMidasStrategicCELastRefreshDate,
  watchFetchMidasStrategicDealerSupportLastRefreshDate,
  watchFetchMsrpLastRefreshDate,
  watchFetchOCELastDataRefresh,
  watchFetchProductMarketingOptimizationLastDataRefresh,
  watchFetchProductPerformanceLastRefreshDate,
  watchFetchSeoLastDataRefreshSaga,
  watchFetchUseOwnershipLastRefreshDate,
  watchFetchVocLastRefreshDate,
  watchMtmFetchLastDataRefresh,
} from "./lastDataRefreshSaga";
import {
  watchFetchMidasFixedDataByQuarterSaga,
  watchFetchMidasMediaMixDataByQuarterSaga,
  watchFetchMidasMtpCategoryDataByQuarterSaga,
  watchFetchMidasSpendVsTrafficMarketDataSaga,
  watchFetchMidasSpendVsTrafficModelDataSaga,
  watchFetchMidasStrategicCEDataByQuarterSaga,
  watchFetchMidasStrategicDealerSupportDataByQuarterSaga,
  watchMidasFetchFmiByModelFmiByQuarterSaga,
  watchMidasFetchFmiByQuarterSaga,
  watchMtpSpendSaga,
} from "./midasSaga";
import { watchFetchModels } from "./modelSaga";
import {
  watchFetchDifferenceByModelSaga,
  watchFetchDisByModelAndMonth,
  watchFetchMsrpModelPerformanceSaga,
  watchFetchMsrpOverTimeByModel,
  watchFetchMsrpProfileByMonthSaga,
  watchFetchMsrpSummarySaga,
  watchFetchMsrpVmeDifferenceSaga,
  watchFetchSalesByModelSaga,
  watchFetchTopFiveModels,
  watchFetchTotalAddedValue,
  watchFetchTotalValueDisByMonth,
  watchFetchVmeDifferenceByModelSaga,
  watchFetchVmeDiscountOverTimeByModel,
} from "./msrpSaga";
import {
  watchFetchFutureMarketBrandSearch,
  watchFetchFutureMarketMediaMlc,
  watchFetchFutureMarketPaidImpressions,
  watchFetchFutureMarketReach,
  watchFetchInMarketLeadVolume,
  watchFetchInMarketMediaMlc,
  watchFetchInMarketTotalSales,
  watchFetchJapanInMarketLeadVolume,
  watchFetchMtmCurrency,
  watchFetchNearMarketKbaVisits,
  watchFetchNearMarketMediaMlc,
  watchFetchNearMarketUniqueVisits,
  watchFetchNearMarketVisits,
} from "./mtmReportSaga";
import {
  watchFetchKbaBreakdownData,
  watchFetchKbaLabelMapping,
  watchFetchKbaVisitsData,
  watchFetchLeadBreakdownData,
  watchFetchLeadLabelMapping,
  watchFetchLeadVisitsData,
  watchFetchVisitsTrend,
  watchFetchWebsiteVisits,
  watchNewFetchKeyIndicatorTotals,
  watchNewFetchTrendBreakdown,
} from "./onlineCePerformanceSaga";
import { parameterSaga } from "./parameterSaga";
import {
  watchFetchAverageModel,
  watchFetchCustomerIntentionByColorSaga,
  watchFetchCustomerIntentionsVsSalePowerSaga,
  watchFetchCustomerIntentionVsSalesSaga,
  watchFetchGradeMixSaga,
  watchFetchLeadsFunnelSaga,
  watchFetchLeadToTestDriveRequestSaga,
  watchFetchMarketingActivitySaga,
  watchFetchMediaSpendSaga,
  watchFetchMediaSpendTotalsSaga,
  watchFetchNimCustomerIntentionVsSalesSaga,
  watchFetchNimGradesSaga,
  watchFetchNimSalesByTrendSaga,
  watchFetchPriceVolumeSalesSaga,
  watchFetchPriceVolumeTrendSaga,
  watchFetchRegionalColorTrendDataSaga,
  watchFetchRetailSalesSaga,
  watchFetchSalesByTrendSaga,
  watchFetchSearchInterestVsFMISaga,
  watchFetchSovSaga,
  watchFetchTestDriveRequestSaga,
  watchFetchTotalLeadsSaga,
  watchFetchVisitsTotalsSaga,
  watchFetchWebVisitsByChannelSaga,
} from "./productMarketingOptimizationSaga";
import {
  watchFetchAccessibilitySaga,
  watchFetchBugsReleaseBreakdownSaga,
  watchFetchBugsStatusBreakdownSaga,
  watchFetchCustomerExperienceSaga,
  watchFetchDigitalPerformanceSaga,
  watchFetchErrorPagesSaga,
  watchFetchFeaturesActivatedSaga,
  watchFetchInfluenceOnLeadsSaga,
  watchFetchInfluenceOnShoppingToolsSaga,
  watchFetchLiveMarketsSaga,
  watchFetchPagePerformanceBreakdownSaga,
  watchFetchPagePerformanceSaga,
  watchFetchTotalBugsSaga,
} from "./productPerformanceSaga";
import { watchFetchReleaseNotes } from "./releaseNotesSaga";
import {
  watchFetchImpressionsBreakdownSaga,
  watchFetchImpressionsOverviewSaga,
  watchFetchVisitorsCountrySaga,
  watchFetchVisitsBreakdownSaga,
  watchFetchVisitsOverviewSaga,
} from "./seoSaga";
import { watchFetchTags } from "./tagsSaga";
import {
  watchFetchFlopServicesDataSaga,
  watchFetchServiceGroupsDataSaga,
  watchFetchTopFlopServicesDataSaga,
  watchFetchTopModelsDataSaga,
  watchFetchTopRegionsDataSaga,
  watchFetchTopServicesDataSaga,
} from "./topFlopSaga";
import {
  watchFetchnlineAccAndEngLinkedCarsSaga,
  watchFetchOnlineAccAndEngAccountCreationAdjustedSaga,
  watchFetchOnlineAccAndEngAccountCreationTrendSaga,
  watchFetchOnlineAccAndEngActiveUsersSaga,
  watchFetchOnlineAccAndEngAvgVocRatingSaga,
  watchFetchOnlineAccAndEngCompleteAccountCreationSaga,
  watchFetchOnlineAccAndEngEngagementVsActiveUsersSaga,
  watchFetchOnlineAccAndEngEnrolledUsersLinkedSaga,
  watchFetchOnlineAccAndEngEnrolledUsersTrendSaga,
  watchFetchOnlineAccAndEngFirstEngagementSaga,
  watchFetchOnlineAccAndEngPrivateSalesSaga,
  watchFetchOnlineAccAndEngStartAccountCreationSaga,
  watchFetchOnlineAccAndEngVehiclesAddedSaga,
  watchFetchOverviewAvgMonthlyActiveUsersSaga,
  watchFetchOverviewAvgVocRatingAcrossPagesSaga,
  watchFetchOverviewChurnRateSaga,
  watchFetchOverviewConnectedCarsSoldSaga,
  watchFetchOverviewEngagementTrendSaga,
  watchFetchOverviewEnrolledCcsCustomersSaga,
  watchFetchOverviewOnineAccountAndEngagementSaga,
  watchFetchOverviewRenewalRateSaga,
  watchFetchOverviewRenewalTrendSaga,
  watchFetchOverviewServiceLeadsSaga,
  watchFetchOverviewUserEngagementSaga,
  watchFetchSelfServicePopularSearchTermsViaGoogleSaga,
  watchFetchSelfServiceSelfLearningPageVisitsAndTimeSpentSaga,
  watchFetchSelfServiceSelfLearningPageVisitsByChannelSaga,
  watchFetchSelfServiceSelfLearningPageVisitsByEntryPointSaga,
  watchFetchSelfServiceTopicClassificationDissatisfactionSaga,
  watchFetchSelfServiceTopicClassificationSatisfactionSaga,
  watchFetchSelfServiceTopicClassificationVerbatimDataSaga,
  watchFetchSelfServiceUserVisitingSelfLearningPageSaga,
  watchFetchSelfServiceVocRatingForSelfLearningPageOverTimeSaga,
  watchFetchSelfServiceVocRatingForSelfLearningPageSaga,
  watchFetchServiceLeadBookingFormsVisitsVsServiceLeadsSaga,
  watchFetchServiceLeadBookingFormVisitsByChannelOrTrendSaga,
  watchFetchServiceLeadServiceBookingFormsOpenedSaga,
  watchFetchServiceLeadServiceBookingFormVisitsSaga,
  watchFetchServiceLeadServiceLeadsSaga,
  watchFetchServiceLeadServiceRecommendationVisitsSaga,
  watchServiceLeadConversionRateSaga,
} from "./useOwnershipSaga";
import { watchFetchCXOToken, watchFetchUserDetails } from "./userSaga";
import {
  watchFetchExitSurveyDeviceDistributionSaga,
  watchFetchExitSurveyFeedbackSaga,
  watchFetchExitSurveyNonCompletionReasonsSaga,
  watchFetchExitSurveyObjectiveCompletionByMarketSaga,
  watchFetchExitSurveyObjectiveCompletionSaga,
  watchFetchExitSurveyObjectiveCompletionSelectedMarketsSaga,
  watchFetchExitSurveyObjectiveCompletionTrendSaga,
  watchFetchFeedbackCommentsFiltersSaga,
  watchFetchFeedbackCommentsSaga,
  watchFetchMarketOverviewSaga,
  watchFetchRatingPerTouchpointCompletionRateSaga,
  watchFetchRatingPerTouchpointElectrificationSaga,
  watchFetchRatingPerTouchpointMarketBreakdownSaga,
  watchFetchRatingPerTouchpointMediaCampaignsSaga,
  watchFetchRatingPerTouchpointOtherTouchpointsSaga,
  watchFetchRatingPerTouchpointVlpFocusSaga,
  watchFetchRptEditorialSaga,
  watchFetchRptOwnershipSaga,
  watchFetchStarRatingAverageRatingByMarketSaga,
  watchFetchStarRatingAverageRatingSaga,
  watchFetchStarRatingAverageRatingSelectedMarketsSaga,
  watchFetchStarRatingAverageRatingTrendSaga,
  watchFetchStarRatingDeviceDistributionSaga,
  watchFetchStarRatingFeedbackSaga,
  watchFetchStarRatingScoreDistributionSaga,
  watchFetchTopicClassificationDissatisfactionSaga,
  watchFetchTopicClassificationSatisfactionSaga,
} from "./vocSaga";

export default function* rootSaga() {
  const OCESagas = [
    watchFetchReleaseNotes,
    watchFetchGeoScope,
    watchFetchModels,
    parameterSaga,
    watchFetchChannels,
    watchFetchVisitsTrend,
    watchFetchKbaVisitsData,
    watchFetchKbaBreakdownData,
    watchFetchLeadVisitsData,
    watchFetchLeadBreakdownData,
    watchFetchUserDetails,
    watchFetchCXOToken,
    watchFetchBaselineAnnotationsData,
    watchFetchVisitsAnnotationsData,
    watchFetchOCEAnnotationsKbaTypes,
    watchFetchOCEAnnotationsLeadTypes,
    watchFetchTags,
    watchNewFetchTrendBreakdown,
    watchNewFetchKeyIndicatorTotals,
    watchFetchKbaLabelMapping,
    watchFetchLeadLabelMapping,
    watchFetchWebsiteVisits,
    watchOCEModelsByBrandFiltersSaga,
    watchOCEMarketsByBrandFiltersSaga,
    watchOCEChannelsByBrandFiltersSaga,
    watchOCEAllMarketRegionsFiltersSaga,
    watchOCEAllRegionsFiltersSaga,
  ];

  const ccsAnalysisSagas = [watchFetchCCSAnalysisEnrollmentRateData, watchFetchCCSAnalysisUserEngagementRateData];

  const cockpitSagas = [
    watchFetchCCSFilters,
    watchFetchCCSBusinessRevenueData,
    watchFetchCCSBusinessRevenueChartData,
    watchFetchTopUsedServices,
    watchFetchAppRatings,
    watchFetchAppRatingsChartData,
    watchFetchAppReviewBreakdown,
    watchVOC_CallCentre,
    watchFetch_UserEngagementData,
    watchFetchWarrantyStartDateSaga,
    watchTopFlopFiltersSaga,
    watchFetchAppReviewByTopic,
    watchFetchAppReviewSatisfactionData,
    watchFetchAppAnalysisFeatureUsage,
    watchFetchAppAnalysisWebUsage,
    watchFetchAppRatingsDownloads,
    watchFetchAppOpenRate,
    watchFetchCCSBusinessRevenueChartData,
    watchFetchCCSConnectedCarsData,
    watchFetchCCSConnectedCarsChartData,
    watchVOC_CallCentreBreakdown,
    watchFetchRenewalChurnRateData,
    watchFetchRenewalChurnRateChartData,
    watchFetchAppRatingsByMarket,
    watchFetchAppRatingsByMarketChartData,
    watchFetchEngagementAppDownloadsData,
    watchFetchEngagementAppDownloadsChartData,
    watchFetchCcsJourneyData,
    watchFetch_UserEngagementChartData,
    watchFetchG8PrivateActivationRatioData,
    watchFetchG8PrivateActivationRatioChartData,
    watchFetchEnrolledCustomersData,
    watchFetchEnrolledCustomersChartData,
    watchFetchEuropeAppRatingsAvg,
    watchFetchEuropeAppRatingsTrend,
    watchFetchEuropeAppRatingsAvgTotal,
    watchFetchAppRatingAvg,
    watchFetchCCSJourneyBannerDataSaga,
    watchFetchSalesRevenueSource,
    watchFetchCCSSalesRevenueData,
    watchFetchCCSSalesRevenueChartData,
  ];

  const appreviewSagas = [watchFetchAppRatingsFilters, watchFetch_AllReviews];

  const europeActivationSagas = [
    watchFetchEuropeActivationFilters,
    watchFetchEuropeActivationMarketGroups,
    watchFetchActivationOverallDataSaga,
    watchFetchCockpitActivationOverallDataSaga,
    watchFetchActivationCountryDataSaga,
    watchFetchActivationModelDataSaga,
    watchFetchActivationDealerDataSaga,
  ];

  const mtmReportSaga = [
    watchFetchFutureMarketBrandSearch,
    watchFetchFutureMarketMediaMlc,
    watchFetchFutureMarketPaidImpressions,
    watchFetchFutureMarketReach,
    watchFetchNearMarketKbaVisits,
    watchFetchNearMarketMediaMlc,
    watchFetchNearMarketUniqueVisits,
    watchFetchNearMarketVisits,
    watchFetchInMarketLeadVolume,
    watchFetchInMarketMediaMlc,
    watchFetchInMarketTotalSales,
    watchFetchMtmCurrency,
    watchFetchMtmModels,
    watchFetchCockpitActivationData,
    watchFetchMtmMarkets,
    watchFetchMtmAnnotationsData,
    watchFetchJapanInMarketLeadVolume,
  ];

  const dataRefresh = [
    watchActivationFetchLastDataRefresh,
    watchMtmFetchLastDataRefresh,
    watchFetchLastDataRefresh,
    watchFetchMarketSpecificDataRefresh,
    watchFetchSeoLastDataRefreshSaga,
    watchFetchCpoLastDataRefresh,
    watchFetchProductMarketingOptimizationLastDataRefresh,
    watchFetchOCELastDataRefresh,
    watchFetchProductPerformanceLastRefreshDate,
    watchFetchMsrpLastRefreshDate,
    watchFetchJourneyExcellenceLastRefreshDate,
    watchFetchVocLastRefreshDate,
    watchFetchBrandPerformacneLastRefreshDate,
    watchFetchExecutiveSummaryLastRefreshDate,
  ];

  const userGroupMarketsSaga = [watchFetchUserGroupMarkets];

  const annotationParametersSaga = [
    watchFetchBrandsData,
    watchFetchMarketsData,
    watchFetchRegionsData,
    watchFetchNewModelsData,
    watchFetchChannelsData,
    watchFetchChannelBreakdownsData,
    watchFetchMetricsData,
    watchFetchLeadSourcesData,
  ];

  const seoSaga = [
    watchFetchSeoGeography,
    watchFetchVisitsOverviewSaga,
    watchFetchVisitsBreakdownSaga,
    watchFetchImpressionsOverviewSaga,
    watchFetchImpressionsBreakdownSaga,
    watchFetchVisitorsCountrySaga,
  ];

  const topFlopSagas = [
    watchFetchTopFlopServicesDataSaga,
    watchFetchTopServicesDataSaga,
    watchFetchFlopServicesDataSaga,
    watchFetchTopModelsDataSaga,
    watchFetchServiceGroupsDataSaga,
    watchFetchTopRegionsDataSaga,
  ];

  const midasSagas = [
    watchMidasFiltersSaga,
    watchMidasFetchFmiByQuarterSaga,
    watchMtpSpendSaga,
    watchFetchMidasFmiLastRefreshDate,
    watchFetchMidasFmiByModelLastRefreshDate,
    watchMidasFmiByModelFiltersSaga,
    watchMidasFetchFmiByModelFmiByQuarterSaga,
    watchFetchMidasMediaMixLastRefreshDate,
    watchFetchMidasMediaMixDataByQuarterSaga,
    watchMidasMediaMixltersSaga,
    watchFetchMidasMtpCategoryLastRefreshDate,
    watchFetchMidasMtpCategoryDataByQuarterSaga,
    watchMidasMtpCategoryltersSaga,
    watchFetchMidasStrategicDealerSupportDataByQuarterSaga,
    watchMidasStrategicDealerSupportFIltersSaga,
    watchFetchMidasStrategicDealerSupportLastRefreshDate,
    watchFetchMidasFixedDataByQuarterSaga,
    watchFetchMidasStrategicCEDataByQuarterSaga,
    watchFetchMidasFixedLastRefreshDate,
    watchFetchMidasStrategicCELastRefreshDate,
    watchFetchMidasSpendVsTrafficLastRefreshDate,
    watchMidasStrategicDealerSupportFiltersSaga,
    watchMidasFixedFIltersSaga,
    watchMidasSpendVsTrafficFiltersSaga,
    watchFetchMidasSpendVsTrafficMarketDataSaga,
    watchFetchMidasSpendVsTrafficModelDataSaga,
  ];

  const adminSagas = [
    watchFetchAdminBrandsData,
    watchFetchAdminMarketsData,
    watchFetchAdminRegionsData,
    watchFetchAdminViewsData,
    watchFetchAdminModelsData,
    watchFetchAdminChannelsData,
    watchFetchAdminChannelBreakdownsData,
    watchFetchAdminMetricsData,
  ];
  const cpoSagas = [
    watchFetchModelTrend,
    watchFetchModelKeyIndiators,
    watchFetchKeyIndicatorVariance,
    watchFetchKeyIndicatorAll,
    watchFetchCpoBreakdownTrend,
    watchFetchCpoBreakdownTrendAll,
    watchFetchCpoVisitsTrendLeads,
    watchFetchCpoVisitsLeads,
    watchFetchCpoVisitsKba,
    watchFetchCpoVisitsLeads,
    watchFetchCpoVisitsTrendLeads,
    watchFetchCpoKbaBreakdownTable,
    watchFetchCpoLeadBreakdownTable,
    watchFetchCpoKbaLabelMapping,
    watchFetchCpoLeadLabelMapping,
  ];

  const productMarketingOptimizationSagas = [
    watchProductMarketingOptimizationFiltersSaga,
    watchFetchCustomerIntentionVsSalesSaga,
    watchFetchPriceVolumeSalesSaga,
    watchFetchSearchInterestVsFMISaga,
    watchFetchMediaSpendSaga,
    watchFetchMediaSpendTotalsSaga,
    watchFetchVisitsTotalsSaga,
    watchFetchTotalLeadsSaga,
    watchFetchLeadsFunnelSaga,
    watchFetchWebVisitsByChannelSaga,
    watchFetchLeadToTestDriveRequestSaga,
    watchFetchTestDriveRequestSaga,
    watchFetchRetailSalesSaga,
    watchFetchSalesByTrendSaga,
    watchFetchPriceVolumeTrendSaga,
    watchFetchGradeMixSaga,
    watchFetchRegionalColorTrendDataSaga,
    watchFetchCustomerIntentionByColorSaga,
    watchFetchSovSaga,
    watchFetchAverageModel,
    watchFetchNimCustomerIntentionVsSalesSaga,
    watchFetchNimSalesByTrendSaga,
    watchFetchNimGradesSaga,
    watchFetchCustomerIntentionsVsSalePowerSaga,
    watchFetchMarketingActivitySaga,
  ];

  const productPerformanceSagas = [
    watchFetchProductPerformanceFiltersSaga,
    watchFetchLiveMarketsSaga,
    watchFetchFeaturesActivatedSaga,
    watchFetchDigitalPerformanceSaga,
    watchFetchInfluenceOnShoppingToolsSaga,
    watchFetchInfluenceOnLeadsSaga,
    watchFetchTotalBugsSaga,
    watchFetchBugsStatusBreakdownSaga,
    watchFetchBugsReleaseBreakdownSaga,
    watchFetchPagePerformanceSaga,
    watchFetchPagePerformanceBreakdownSaga,
    watchFetchAccessibilitySaga,
    watchFetchCustomerExperienceSaga,
    watchFetchErrorPagesSaga,
  ];

  const activationRatioSagas = [watchUaeActivationFiltersSaga, watchFetchActivationRatioDataSaga];

  const ecommerceSagas = [
    watchEcommerceFiltersSaga,
    watchFetchVisitsDropOffSaga,
    watchFetchInfluenceOfShoppingToolsSaga,
    watchFetchOrdersSaga,
    watchFetchTdsLeadsSaga,
    watchFetchNonTdsLeadsSaga,
    watchFetchEcommerceLastRefreshDate,
  ];

  const vocSagas = [
    watchFetchMarketOverviewSaga,
    watchVocFiltersSaga,
    watchFetchStarRatingAverageRatingSaga,
    watchFetchStarRatingAverageRatingTrendSaga,
    watchFetchStarRatingFeedbackSaga,
    watchFetchStarRatingScoreDistributionSaga,
    watchFetchStarRatingDeviceDistributionSaga,
    watchFetchStarRatingAverageRatingByMarketSaga,
    watchFetchExitSurveyObjectiveCompletionSaga,
    watchFetchExitSurveyFeedbackSaga,
    watchFetchExitSurveyObjectiveCompletionTrendSaga,
    watchFetchExitSurveyNonCompletionReasonsSaga,
    watchFetchExitSurveyDeviceDistributionSaga,
    watchFetchExitSurveyObjectiveCompletionByMarketSaga,
    watchFetchRatingPerTouchpointMarketBreakdownSaga,
    watchFetchRatingPerTouchpointOtherTouchpointsSaga,
    watchFetchRatingPerTouchpointMediaCampaignsSaga,
    watchFetchRatingPerTouchpointVlpFocusSaga,
    watchFetchRatingPerTouchpointElectrificationSaga,
    watchFetchStarRatingAverageRatingSelectedMarketsSaga,
    watchFetchExitSurveyObjectiveCompletionSelectedMarketsSaga,
    watchFetchRatingPerTouchpointCompletionRateSaga,
    watchFetchTopicClassificationDissatisfactionSaga,
    watchFetchTopicClassificationSatisfactionSaga,
    watchFetchFeedbackCommentsSaga,
    watchFetchRptOwnershipSaga,
    watchFetchRptEditorialSaga,
    watchFetchFeedbackCommentsFiltersSaga,
  ];

  const cpoOperationalSagas = [
    watchCpoOperationalBaseTrend,
    watchCpoOperationalFiltersSaga,
    watchFetchCpoOperationalLastRefreshDate,
    watchCpoOperationalDealerTrend,
    watchCpoOperationalInventoryByAgeTrend,
    watchCpoOperationalPerformancePerUnitTrend,
    watchCpoOperationalTotalValuesTrend,
    watchCpoOperationalListPriceTrend,
    watchCpoOperationalInventoryRatioTrend,
    watchCpoOperationalTurnoverRatioTrend,
    watchCpoOperationalLeadSalesConversionTrend,
    watchCpoOperationalInventoryVolumeTrend,
    watchCpoOperationalAverageDaysInStockTrend,
    watchCpoOperationalSalesVolumeTrend,
    watchCpoOperationalSalesRevenueTrend,
    watchCpoOperationalAverageListPriceTrend,
    watchCpoOperationalAverageSalesPriceTrend,
    watchCpoOperationalLastWeekDates,
  ];

  const msrpSagas = [
    watchMsrpFiltersSaga,
    watchFetchMsrpSummarySaga,
    watchFetchSalesByModelSaga,
    watchFetchDifferenceByModelSaga,
    watchFetchVmeDifferenceByModelSaga,
    watchFetchMsrpVmeDifferenceSaga,
    watchFetchMsrpProfileByMonthSaga,
    watchFetchMsrpModelPerformanceSaga,
    watchFetchMsrpOverTimeByModel,
    watchFetchVmeDiscountOverTimeByModel,
    watchFetchTotalValueDisByMonth,
    watchFetchDisByModelAndMonth,
    watchFetchTotalAddedValue,
    watchFetchTopFiveModels,
  ];

  const journeyExcellenceSagas = [
    watchJourneyExcellenceFiltersSaga,
    watchDigitalSpendSaga,
    watchDigitalSpendVisitsSaga,
    watchConversionAndCostSaga,
    watchPerformanceOverviewSaga,
    watchDigitalSpendVisitsTrendSaga,
    watchWebsitePerformanceSaga,
    watchWebsitePerformanceTrendSaga,
    watchJourneyCostSaga,
    watchJourneyCostTrendSaga,
    watchCustomerJourneySaga,
    watchConversionSaga,
    watchConversionTrendSaga,
    watchMetricChannelSaga,
    watchMetricChannelTrendSaga,
    watchCustomerExperienceDxsSaga,
    watchStarRatingAverageSaga,
    watchStarRatingFeedbackSaga,
    watchExitSurveyObjectiveCompletionSaga,
    watchExitSurveyFeedbackSaga,
    watchLmsDigitallyInfluencedSalesSaga,
    watchLmsDigitallyInfluencedSalesTrendSaga,
    watchLmsDisAndLeadsSaga,
    watchLmsSalesAndLeadsTrendSaga,
    watchLmsConversionAndCostSaga,
    watchLmsConversionAndCostTrendSaga,
    watchProductJourneyPerformanceOverviewSaga,
    watchFmiLastActMonthSaga,
    watchProductJourneyEntryPointsSaga,
    watchProductJourneyExitPointsSaga,
    watchProductJourneyWebsiteEntryPointsSaga,
  ];

  const brandPerformanceSagas = [
    watchFetchBrandPerformanceVolumeFiltersSaga,
    watchFetchBrandPerformanceOaOFiltersSaga,
    watchFetchBrandPerformanceImageAttributeFiltersSaga,
    watchBrandImageAttributesTrendSaga,
    watchBrandImageAttributesAverageSaga,
    watchBrandOaOAverageSaga,
    watchBrandOaOTrendSaga,
    watchBrandVolumeTrendSaga,
    watchBrandVolumeAverageSaga,
  ];

  const useOwnershipSagas = [
    watchUseOwnershipFiltersSaga,
    watchFetchOverviewOnineAccountAndEngagementSaga,
    watchFetchOverviewServiceLeadsSaga,
    watchFetchOverviewEnrolledCcsCustomersSaga,
    watchFetchOverviewAvgMonthlyActiveUsersSaga,
    watchFetchOverviewConnectedCarsSoldSaga,
    watchFetchOverviewUserEngagementSaga,
    watchFetchOverviewRenewalRateSaga,
    watchFetchOverviewChurnRateSaga,
    watchFetchOverviewEngagementTrendSaga,
    watchFetchOverviewRenewalTrendSaga,
    watchFetchOverviewAvgVocRatingAcrossPagesSaga,
    watchFetchUseOwnershipLastRefreshDate,
    watchFetchOnlineAccAndEngPrivateSalesSaga,
    watchFetchOnlineAccAndEngEnrolledUsersTrendSaga,
    watchFetchOnlineAccAndEngAccountCreationTrendSaga,
    watchFetchnlineAccAndEngLinkedCarsSaga,
    watchFetchOnlineAccAndEngEngagementVsActiveUsersSaga,
    watchFetchOnlineAccAndEngStartAccountCreationSaga,
    watchFetchOnlineAccAndEngCompleteAccountCreationSaga,
    watchFetchOnlineAccAndEngActiveUsersSaga,
    watchFetchOnlineAccAndEngFirstEngagementSaga,
    watchFetchOnlineAccAndEngAvgVocRatingSaga,
    watchFetchOnlineAccAndEngEnrolledUsersLinkedSaga,
    watchFetchOnlineAccAndEngVehiclesAddedSaga,
    watchFetchServiceLeadServiceBookingFormVisitsSaga,
    watchFetchServiceLeadServiceLeadsSaga,
    watchFetchServiceLeadBookingFormsVisitsVsServiceLeadsSaga,
    watchFetchServiceLeadBookingFormVisitsByChannelOrTrendSaga,
    watchFetchServiceLeadServiceBookingFormsOpenedSaga,
    watchFetchSelfServiceUserVisitingSelfLearningPageSaga,
    watchFetchSelfServiceSelfLearningPageVisitsAndTimeSpentSaga,
    watchFetchSelfServiceSelfLearningPageVisitsByChannelSaga,
    watchFetchSelfServiceSelfLearningPageVisitsByEntryPointSaga,
    watchFetchSelfServiceVocRatingForSelfLearningPageSaga,
    watchFetchSelfServiceVocRatingForSelfLearningPageOverTimeSaga,
    watchFetchSelfServiceTopicClassificationVerbatimDataSaga,
    watchFetchServiceLeadServiceRecommendationVisitsSaga,
    watchServiceLeadConversionRateSaga,
    watchFetchSelfServicePopularSearchTermsViaGoogleSaga,
    watchFetchSelfServiceTopicClassificationDissatisfactionSaga,
    watchFetchSelfServiceTopicClassificationSatisfactionSaga,
    watchFetchOnlineAccAndEngAccountCreationAdjustedSaga,
  ];

  const executiveSummarySagas = [
    watchFetchExecutiveSummaryFiltersSaga,
    watchExecutiveSummaryDisSummarySaga,
    watchExecutiveSummaryAggregatedPurchaseFunnelSaga,
    watchExecutiveSummaryAggregatedPurchaseFunnelTableSaga,
    watchExecutiveSummaryDetailedComparisonsSaga,
    watchExecutiveSummaryChannelMixPerformanceSaga,
    watchExecutiveSummaryPrivateSalesByMarketSaga,
    watchExecutiveSummaryPrivateSalesByDateSaga,
    watchExecutiveSummaryDisPercentageByMarketSaga,
    watchExecutiveSummaryDisPercentageByDateSaga,
    watchExecutiveSummaryCloseRateByMarketSaga,
    watchExecutiveSummaryCloseRateByDateSaga,
    watchExecutiveSummaryKeyIndicatorsSaga,
  ];

  const allSagas = [
    ...OCESagas,
    ...ccsAnalysisSagas,
    ...cockpitSagas,
    ...appreviewSagas,
    ...europeActivationSagas,
    ...userGroupMarketsSaga,
    ...mtmReportSaga,
    ...annotationParametersSaga,
    ...dataRefresh,
    ...seoSaga,
    ...topFlopSagas,
    ...adminSagas,
    ...cpoSagas,
    ...productMarketingOptimizationSagas,
    ...midasSagas,
    ...productPerformanceSagas,
    ...activationRatioSagas,
    ...vocSagas,
    ...cpoOperationalSagas,
    ...ecommerceSagas,
    ...msrpSagas,
    ...journeyExcellenceSagas,
    ...useOwnershipSagas,
    ...brandPerformanceSagas,
    ...executiveSummarySagas,
  ];

  yield all(
    allSagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (err) {
            console.log(err);
          }
        }
      })
    )
  );
}
