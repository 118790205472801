import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { getMediaFmiAndRetailSales } from "../../../../api/JourneyExcellenceMarketPerformanceSummary";
import MediaFmiAndRetailSalesChart from "../../../../components/Charts/MarketPerformanceSummary/MediaFmiAndRetailSalesChart";
import { ThemeContext } from "../../../../context";
import { currencies } from "../../../../constants/currenctConstants";

const MediaFMIAndRetailSales = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const { theme } = useContext(ThemeContext);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["mediaFmiAndRetailSalesData", history.location.search],
    queryFn: getMediaFmiAndRetailSales,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  return (
    <div className="tile">
      <div className="tile-header">
        <div className="title">
          <p>Media FMI and Retail Sales</p>
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            html={
              <div>
                <u>Media FMI BP:</u>
                <p>
                  BP of Media FMI that is planned to be allocated to reach its marketing goals or promote the brand messaging. BP amount is
                  based on the most recent year scenario available for the selected FY and Geography.
                </p>
                <p>Source: MIDAS</p>
                <br />
                <u>Media FMI:</u>
                <p>
                  Media FMI allocated to reach its marketing goals or promote the brand messaging. Actual amount is based on the most recent
                  year scenario available for the selected FY and Geography.
                </p>
                <p>Source: MIDAS</p>
                <br />
                <u>Retail Sales: </u>
                <p>The total number of private and digital influenced sales</p>
                <p>Source: Global Sales Report</p>
              </div>
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
      </div>
      <div className="chart-container">
        <MediaFmiAndRetailSalesChart
          chartId="mediaFmiAndRetailSalesChart"
          data={data?.data ?? []}
          seriesList={data?.series ?? []}
          theme={theme}
          isLoading={isFetching}
          currency={data?.currency ? currencies[data.currency].symbol : ""}
        />
      </div>
    </div>
  );
});

export default MediaFMIAndRetailSales;
