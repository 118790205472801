import React from "react";
import "../../../../assets/styles/component/lineBulletSeries.scss";

interface Props {
  backgroundColor: string;
  label?: string;
  isLine?: boolean;
}

const LineBulletSeries = (props: Props) => {
  const { backgroundColor, isLine, label } = props;
  return (
    <div className="series_item">
      <div className="parent_div">
        <div className={`${isLine ? "circle" : "square"}`} style={{ backgroundColor: `${backgroundColor}` }}></div>
        {isLine ? <div className="line" style={{ backgroundColor: `${backgroundColor}` }}></div> : null}
      </div>
      <div className="label_name">{label}</div>
    </div>
  );
};

export default LineBulletSeries;
