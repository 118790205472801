import React from "react";
import { computeMetricConversion } from "../../../utils/computeFunctions";
import { convertToPercentage, isNull } from "../../../utils/utilityFunctions";
import Ratings from "../../Ratings/Ratings";

const ConversionWithRating = ({ currentValue, comparisonValue }: { currentValue: number; comparisonValue: number }) => {
  const conversion = computeMetricConversion(comparisonValue, currentValue - comparisonValue);

  return isNull(conversion) ? (
    <p className={"td_comparison"}>
      <Ratings value={"na"} isPercentageValue={false} />
      <span className="td_comparison_value">n/a</span>
    </p>
  ) : (
    <p className={"td_comparison"}>
      <Ratings value={conversion ? (isNaN(conversion) ? "na" : String(conversion)) : ""} isPercentageValue={false} />
      <span className="td_comparison_value">
        {conversion
          ? isNaN(Math.abs(+parseFloat(convertToPercentage(conversion)).toFixed(0)))
            ? "n/a"
            : `${Math.abs(+parseFloat(convertToPercentage(conversion)).toFixed(0))}%`
          : "n/a"}
      </span>
    </p>
  );
};

export default ConversionWithRating;
