import React from "react";
import "../../../assets/styles/docs/seoData.scss";

export const SEOData = (): JSX.Element => (
  <div className="data_documentation">
    <h3>SEO Dashboard Data Overview</h3>
    <h4>Intro</h4>
    <p>
      The SEO Report combines and visualises search result engagement metrics (impressions and clicks) and natural search visits for 19
      Nissan markets based on their main websites.
    </p>
    <p>This dashboard allows Nissan markets to quickly identify SEO performance issues for their sites.</p>
    <h4>Scope</h4>
    <p>
      The dashboard covers Nissan 17 CP2022 markets, as well as India and Indonesia, based on their main websites (the 24 websites in scope
      are visible in the ‘Site(s)’ filter), from the start of FY19.
    </p>
    <h4>Data sources:</h4>
    <p>The dashboard currently uses two sources of data:</p>
    <ul>
      <li>
        <i>Adobe Analytics: </i>
        This source is used to extract on site data like visits, KBA and Leads.
      </li>
      <li>
        <i>Google Search Console: </i>
        This source is used to extract search data like impressions and clicks.
      </li>
    </ul>

    <p>All data is extracted daily and aggregated based on the date range selected. </p>
    <p>Note: Additional data sources such as Rank Ranger will be included in future releases.</p>
    <h4>Data recency:</h4>
    <p>
      The data is being pulled from both sources daily but Google Search Console data lags behind Adobe Analytics data by 2-3 days, so data
      consistency and unable comparisons between Adobe Analytics data and Google Search Console data the dashboard only displays data up to
      the latest day available in both data sources. This date is always displayed in the top right corner of the view after ’Last data
      refresh’.
    </p>
    <h4>Metrics:</h4>
    <ul>
      <li>
        <i>Natural search visits:</i>
        Number of website visits coming from natural searches (natural searches are identified through the Adobe dimensions ‘Last touch
        marketing channel’ and can be called ‘Natural search’ or ‘Organic search’ in Adobe depending on the market set up)
      </li>
      <li>
        <i>Impressions:</i>
        How many times a link to the site appeared in search results
        <span> </span>
        <a href="https://support.google.com/webmasters/answer/7042828?hl=en#impressions" target="_blank" rel="noreferrer">
          (more details here)
        </a>
      </li>
      <li>
        <i>Clicks:</i>
        How many times a user clicked through to the site
        <span> </span>
        <a href="https://support.google.com/webmasters/answer/7042828?hl=en#click" target="_blank" rel="noreferrer">
          (more details here)
        </a>
      </li>
    </ul>
    <h4>Filters:</h4>
    <p>Filters in the filter bar apply to the whole dashboard:</p>
    <ul>
      <li>
        <i>Date range:</i>
        Allows you to look at the performance for pre-set date ranges (weeks, quarters, financial years) or custom ones, from the start of
        FY19 until the latest date available across all data sources (see ‘Last data refresh’ mention in the top right corner of the screen)
      </li>
      <li>
        <i>Site(s):</i>
        Allows you to choose the appropriate market domain, including any other language domains/subdomains.
      </li>
      <li>
        <i>Device:</i>
        Allows you to view data across all device or for one of the main ones (desktop, mobile and tablet)
      </li>
    </ul>
    <p>Other filters are specific to the data source and allow you to explore the Search Console data or the Adobe Analytics data:</p>
    <ul>
      <li>
        <i>Country filter (Google Search Console data):</i>
        Filter impressions and clicks data by where the user is searching from.
      </li>
      <li>
        <i>Query (Google Search Console data):</i>
        Click on a query (in blue in the impressions breakdown table) to see impressions and clicks data for a specific query.
      </li>
      <li>
        <i>Landing page (Adobe Analytics data):</i>
        Click on à landing page (in blue in the natural search visits breakdown table) to see website visits data for a specific landing
        page.
      </li>
      <li>
        <i>Search Engine toggle (Adobe Analytics data):</i>
        Switch between a view of natural search visits across all search engines (to see the websites overall SEO performance) or a view of
        natural search visits from Google Search engine only (to be able to compare the Google Search visits variations with the Google
        Search impressions and clicks)
      </li>
    </ul>
    <h4>Features:</h4>
    <ul>
      <li>
        <i>Insights:</i>
        At the end of each month, for each market, the SEO team will identify the main insights to get from the data and make it available
        to you in the insights module.
      </li>
      <li>
        <i>Trendline legend selection: </i>
        On the trendline chart you can unselect and reselect legend items if you want to focus on one item specifically, the chart axis will
        update accordingly.
      </li>
      <li>
        <i>Show table hidden columns:</i>
        On the tables you can access the period on period and year on year variation in volume (instead of percentage) by clicking on ’Show
        variations in volume’
      </li>
      <li>
        <i>Table column ordering:</i>
        Clicking on a table column heading allows you to order the table by this column, from best to worst performer if you click once, and
        from worst to best if you click twice.
      </li>
    </ul>
    <h4>FAQ:</h4>
    <ul>
      <li>Why doesn’t the number of Google Search clicks match the number of Google Search visits?</li>
    </ul>
    <p>
      To be able to show the natural search visits associated with Google search engine only the Adobe Analytics data is extracted using the
      dimension ‘Search Engine’ and à Google search engine segment is then created to group all visits coming from search engines associated
      with Google (ie. “Google”, “Google - Algeria”, “Google Search App”, etc.). In some corner cases Adobe associates a visit to more than
      one search engine which means by pulling and aggregating this dimension the number of visits might be slightly inflated. In addition
      to this the two data sources Google Search Console and Adobe Analytics each have their own specificities in terms of tracking and
      reporting which means there isn’t a 100% match between the two datasets but the trends are comparable.
    </p>
  </div>
);
