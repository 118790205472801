import React from "react";

export const DigitalData = (): JSX.Element => (
  <div className="data_documentation">
    <h3>Online CE performance – Data overview</h3>
    <h4>Scope</h4>
    <p>The Online CE dashboard covers 93 Nissan markets, 4 Datsun markets and 26 Infiniti markets.</p>
    <p>
      Note: Data is unavailable for the following markets prior to their PACE launch date: Australia (August 27th 2019), Mexico (October
      16th 2019), Canada Nissan and Infiniti (March 24th 2020), Puerto Rico (June 1st 2020).
    </p>
    <br />

    <h4>Source and extraction method:</h4>
    <p>Data is extracted from Adobe Analytics daily from each market’s reporting suite of reference using the reporting API 1.4.</p>
    <p>
      The KPIs extracted are based on each market’s KPI framework. This means that looking at the KBA visits for Europe will show you data
      based on the Europe definition and calculation of a KBA visit which is different from the definition and calculation used in the US or
      in the Japan for instance. It also means that depending on the region selected different KBA and Digital lead types will be displayed
      in the KBA and Digital lead types breakdown tables.
    </p>
    <p>The regional and global views simply sum up the data for all markets that fall under their scope.</p>
    <p>
      Note: Because data is extracted daily and aggregated to show monthly, quarterly, yearly figures you might find some small
      discrepancies between the data you see in the dashboard and the data available in the Adobe WorkSpace which is deduplicated. For
      instance, in the Adobe WorkSpace if a visit starts on the 1st of January at 23:58 and finishes on the 2nd at 00:05, when you select a
      date range that includes these 2 days (for instance the whole January month) this visit will be counted as one but if you look at the
      2 days independently it will be counted in both. Because we extract the data daily, in the dashboard this visit will be counted twice
      which might cause a small over-reporting.
    </p>
    <br />

    <h4>Model filter and tables:</h4>
    <p>
      The data is extracted using different Adobe classifications: Generic model classification (prop5) for the US, Generic model vehicle
      (prop5) for Canada, © Nameplate (evar6) for other Nissan and Datsun markets and © Model name (evar5) or model category (evar5) for
      other Infiniti markets. The values displayed at market levels are the ones extracted from Adobe, but at regional level a mapping is
      sometimes applied (for instance if we get the model ‘Micra’ for one market and ‘Micra/March’ for another. at regional level we will
      group them both under ‘Micra/March’).{" "}
    </p>
    <p>
      For additional information on the model data please click <a href="/data_info">here</a>.
    </p>
    <br />

    <h4>Channel filter:</h4>
    <p>
      The channel filter is using Adobe ‘Last Touch Marketing Channel’ values. These values are all accessible when a specific market is
      selected. At regional or global level only the categories ‘organic’ and ‘paid’ are available.{" "}
    </p>
    <br />

    <h4>Device filter:</h4>
    <p>
      The device filter is using Adobe out of the box ‘Mobile Device Type’ values. Desktop data is based on the Adobe value ‘Other’. All
      devices include other types of devices than desktop, mobile and tablet.
    </p>
    <br />

    <h4>YoY variation:</h4>
    <p>
      For all date ranges apart from the pre-set date range ‘Last week’ the YoY variation is calculated comparing the data for the date
      range selected versus the exact same date range the year before.
    </p>
    <p>
      For ‘Last week’, the YoY variation is calculated against a baseline. The baseline is the 5-week rolling average from the year before,
      based on financial week numbers. Please note that the standard CEDAR week starts on a Sunday and the first week of the financial year
      is the week that includes the 1st of April. Therefore, W1 FY20 starts on Sunday 29th March and ends on Saturday 4th April and the
      baseline for this week is the weekly average from W51 FY18 to W3 FY19. Similarly, for W25 FY20 the YoY variation would be calculated
      based on the weekly average from W23 to W28 FY19.
    </p>
  </div>
);
