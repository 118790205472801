import React from "react";
import "../../assets/styles/component/ratingsIndicator.scss";
import { getRating, getRatingInverse } from "../../pages/SEO/helpers/helpers";
import { getRatingsClass } from "../../utils/utilityFunctions";

const Ratings = (props: {
  value: number | string;
  isPercentageValue: boolean;
  testValue?: boolean;
  ratingIsInverse?: boolean;
  arrowIsInverse?: boolean;
}) => {
  const { value, isPercentageValue, ratingIsInverse, arrowIsInverse, testValue = true } = props;
  const rating =
    value === "n/a"
      ? "na"
      : testValue
      ? getRatingsClass(parseFloat(value as string), isPercentageValue)
      : ratingIsInverse
      ? getRatingInverse(parseFloat(value as string))
      : getRating(parseFloat(value as string));

  return <span className={`indicator ${rating} ${arrowIsInverse ? "rotate-180" : ""}`} data-test-rating={rating} />;
};

export default Ratings;
