import { ErrorBoundary } from "@sentry/react";
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { fetchCpoLeadVisitsData } from "../../../actions";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import { GranularityOptions } from "../../../components/Charts/components";
import { MetricTrendChart } from "../../../components/Charts/DigitalPerformance";
import { ThemeContext } from "../../../context";
import { attachAnnotationsToChartData } from "../../../utils/annotationFunctions";

interface Props {
  granularity: string;
  specificMetric: string;
  metric: string;
  onHandleRadioClick: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  toggleAnnotationsDialog: () => void;
  setAnnotationStartDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationEndDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationDefaultMetric: Dispatch<SetStateAction<string>>;
  setAnnotationDefaultMetricType: Dispatch<SetStateAction<string | undefined>>;
  isCpoDashboard?: boolean;
}

export const DigitalLeadsTrendGraph = (props: Props) => {
  const dispatch = useDispatch();

  const themeContext = useContext(ThemeContext);
  const parameters = useSelector((state: RootStateOrAny) => state.parameters);

  const {
    granularity,
    onHandleRadioClick,
    specificMetric,
    toggleAnnotationsDialog,
    setAnnotationDefaultMetric,
    setAnnotationEndDate,
    setAnnotationStartDate,
    setAnnotationDefaultMetricType,
    isCpoDashboard,
    metric,
  } = props;

  const oceData = useSelector(
    (state: RootStateOrAny) =>
      (isCpoDashboard
        ? state.cpo_digital_performance.cpo_breakdown_trend_lead?.Current
        : state.digital_performance.visits_trend_leads?.all?.Current) ?? []
  );
  const breakdowns = useSelector((state: RootStateOrAny) => state.digital_performance.trend_breakdown.leads?.Current?.data ?? []);
  const isLoading = useSelector((state: RootStateOrAny) => state.loading.lead_trend_graph);
  const annotations = useSelector((state: RootStateOrAny) => state.digital_performance.baseline_chart_annotations);
  const leadLabels = useSelector((state: RootStateOrAny) =>
    isCpoDashboard ? state.cpo_digital_performance.cpo_lead_label_mapping : state.digital_performance.lead_label_mapping
  );

  useEffect(() => {
    if (isCpoDashboard) {
      dispatch(fetchCpoLeadVisitsData());
    }
  }, [parameters, dispatch, specificMetric, metric]);

  const breakdownsList: Array<{ key: string; name: string; visits: number | null }> = useMemo(
    () =>
      breakdowns
        ?.filter((breakdown: { metric_type: string; metric_visits: number | null }) =>
          specificMetric && breakdown.metric_visits ? leadLabels[breakdown.metric_type] === specificMetric : breakdown.metric_visits
        )
        ?.map((breakdown: { metric_type: string; metric_visits: number | null }) => {
          const obj = {} as { key: string; name: string; visits: number | null };
          obj["name"] = leadLabels[breakdown.metric_type];
          obj["key"] = breakdown.metric_type;
          obj["visits"] = breakdown.metric_visits;
          return obj;
        }),
    [breakdowns, specificMetric]
  );

  const cpoBreakdownsList: Array<{ key: string; name: string }> = useMemo(
    () =>
      Object.entries(leadLabels)
        ?.filter(([key, value]) => (specificMetric ? value === specificMetric : true))
        ?.map(([key, value]) => {
          const obj = {} as { key: string; name: string };
          obj["name"] = value as string;
          obj["key"] = key;
          return obj;
        }),
    [leadLabels, specificMetric]
  );

  const graphData = useMemo(() => {
    const result: any = [];

    oceData.map((item: { row_date: string; data: Array<{ metric_type: string; metric_visits: number }> }) => {
      const obj: Record<string, string | number> = { date: item.row_date };
      item.data.forEach((metric: { metric_type: string; metric_visits: number }) => {
        obj[metric.metric_type] = metric.metric_visits;
      });
      result.push(obj);
    });

    return result;
  }, [oceData, granularity]);

  const graphDataWithAnnotations = useMemo(() => {
    if (annotations && graphData) {
      return attachAnnotationsToChartData(annotations, graphData, granularity, "baseline");
    } else {
      return graphData;
    }
  }, [graphData, annotations, granularity]);

  return (
    <>
      <h3>Digital lead visits trend by type</h3>
      <div className="main_content">
        <ErrorBoundary fallback={<ErrorMsg />}>
          {graphData.length > 0 ? (
            <MetricTrendChart
              isCpoDashboard={isCpoDashboard}
              data={graphData}
              granularity={granularity}
              seriesList={isCpoDashboard ? cpoBreakdownsList : breakdownsList}
              theme={themeContext.theme}
              metric={"leads"}
              toggleAnnotationsDialog={toggleAnnotationsDialog}
              setAnnotationEndDate={setAnnotationEndDate}
              setAnnotationStartDate={setAnnotationStartDate}
              setAnnotationDefaultMetric={setAnnotationDefaultMetric}
              setAnnotationDefaultMetricType={setAnnotationDefaultMetricType}
            />
          ) : isLoading ? null : (
            <NoDataMsg />
          )}
        </ErrorBoundary>
      </div>
      {graphData.length > 0 && (
        <GranularityOptions
          onHandleRadioClick={onHandleRadioClick}
          granularity={granularity}
          name={"lead_trend"}
          dateValue={parameters.date_range}
        />
      )}
    </>
  );
};
