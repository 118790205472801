import React from "react";
import KpisPerformanceTile from "../tiles/KpisPerformanceTile";
import DigitalPerformanceEvolutionTile from "../tiles/DigitalPerformanceEvolutionTile";
import PerformanceAsPerObjective from "../tiles/PerformanceAsPerObjective";
import CampaignPerformanceDetailsTile from "../tiles/CampaignPerformanceDetailsTile";

const CampaignPerformanceContent = () => {
  return (
    <div className="campaign-performance-content">
      <div className="row-2">
        <KpisPerformanceTile />
        <DigitalPerformanceEvolutionTile />
      </div>
      <div className="row-2 campaign-performance-grid">
        <PerformanceAsPerObjective />
        <CampaignPerformanceDetailsTile />
      </div>
    </div>
  );
};

export default CampaignPerformanceContent;
