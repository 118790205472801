import { BIG_NUMBER_ABBREVIATION } from "../constants";

export const formatToThreeDigits = (n: number, decimals?: number) => {
  const abbreviationRef = [...BIG_NUMBER_ABBREVIATION].reverse();
  for (let i = 0; i < abbreviationRef?.length; i++) {
    if (n >= abbreviationRef[i].number) {
      const value = (n / abbreviationRef[i].number).toFixed(2).toString().split(".");
      let valueWithDecimals = "";
      if (decimals) valueWithDecimals = (n / abbreviationRef[i].number).toFixed(decimals).toString();
      let quotient = value[0];
      let quotient_length = value[0]?.length;
      let remainder = value[1];
      let float = "";

      if (quotient_length == 3) {
        quotient = `${Number(quotient) + (Number(remainder) > 50 ? 1 : 0)}`;
      }

      if (quotient_length == 2) {
        if (Number(remainder) >= 95) {
          quotient = `${Number(quotient) + 1}`;
          remainder = `0`;
        } else {
          remainder = `${Math.round(Number(remainder) / 10)}`;
        }
      }

      for (let i = 0; quotient_length < 3; i += 1) {
        float += remainder[i];
        quotient_length += 1;
      }
      if (decimals) return `${valueWithDecimals}${abbreviationRef[i].suffix}`;
      if (float) return `${quotient}.${float}${abbreviationRef[i].suffix}`;
      return `${quotient}${abbreviationRef[i].suffix}`;
    }
  }
  return Math.round(n).toString();
};

export const returnNaOrVal = (num: number | null) => {
  if (typeof num === "undefined") return null;
  return num && (num > 9.99 || num < -9.99) ? null : num;
};

export const abbreviateNumMaxMillion = (value: number) => {
  if (value == null || value == undefined) {
    return "n/a";
  }

  let newValue: any = value;
  const suffixes = ["", "K", "M"];
  let suffixNum = 0;
  if (newValue >= 1000000) {
    suffixNum = 2;
    newValue /= 1000000;
  } else {
    while (newValue >= 1000) {
      newValue /= 1000;
      suffixNum++;
    }
  }

  newValue = newValue >= 1000 ? parseInt(newValue).toLocaleString() : newValue.toPrecision(3);

  newValue += suffixes[suffixNum];
  return newValue;
};
