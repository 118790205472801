import React from "react";

const MsrpData = () => {
  return (
    <div className="data_documentation">
      <h3>MSRP - DATA OVERVIEW</h3>

      <h4>Data Definitions and Sources</h4>
      <br />

      <table className="docs_table">
        <thead>
          <tr>
            <th>Category</th>
            <th>KPI</th>
            <th>Description</th>
            <th>Data Source</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>DIS / MSRP</td>
            <td>DIS % Sales</td>
            <td>This is the number of trackable digital leads from Tier 1 only source as a percentage of total private sales.</td>
            <td>Salesnavi Tableau Report</td>
          </tr>
          <tr>
            <td>DIS / MSRP</td>
            <td>Non DIS % Sales</td>
            <td>This is remaining private sales once Tier 1 trackable leads have been removed as a percentage.</td>
            <td>X360 / MAPPIE</td>
          </tr>
          <tr>
            <td>DIS / MSRP</td>
            <td>DIS MSRP</td>
            <td>
              Tier 1 manufacturer&apos;s suggested retail price. This is recommended price for item to be sold for at the point of sale.
            </td>
            <td>X360 / MAPPIE</td>
          </tr>
          <tr>
            <td>DIS / MSRP</td>
            <td>Non DIS MSRP</td>
            <td>
              Private sales non Tier 1 manufacturer&apos;s suggested retail price. This is recommended price for item to be sold for at the
              point of sale.
            </td>
            <td>X360 / MAPPIE</td>
          </tr>
          <tr>
            <td>DIS / MSRP</td>
            <td>DIS Volume</td>
            <td>Tier 1 trackable leads sales volume for period</td>
            <td>X360 / MAPPIE</td>
          </tr>
          <tr>
            <td>DIS / MSRP</td>
            <td>Non DIS Volume</td>
            <td>NonTier 1 leads sales volume for period</td>
            <td>X360 / MAPPIE</td>
          </tr>
          <tr>
            <td>DIS / MSRP</td>
            <td>DIS VME</td>
            <td>
              Tier 1 variable marketing expenses expressed as discount percentage vs the recommended price for item to be sold i.e. discount
              customer received.
            </td>
            <td>X360 / MAPPIE</td>
          </tr>
          <tr>
            <td>DIS / MSRP</td>
            <td>Non DIS VME</td>
            <td>
              Private sales non Tier 1 variable marketing expenses expressed as discount percentage vs the recommended price for item to be
              sold i.e. discount customer received.
            </td>
            <td>X360 / MAPPIE</td>
          </tr>
          <tr>
            <td>DIS / MSRP</td>
            <td>MSRP difference by model</td>
            <td>
              This a calculation based on (MSRP Tier 1 - MSRP Non Tier 1) which indicates the car spec value differences between tier 1 vs
              non tier 1.
            </td>
            <td>X360 / MAPPIE</td>
          </tr>
          <tr>
            <td>DIS / MSRP</td>
            <td>VME difference by model</td>
            <td>
              This a calculation based on (VME% Tier 1 - VME% Non Tier 1) which indicates the discount differences a customer receives
              between tier 1 vs non tier 1.
            </td>
            <td>X360 / MAPPIE</td>
          </tr>
          <tr>
            <td>DIS / MSRP</td>
            <td>VME added value</td>
            <td>
              The added value based on reduced VME discount applied to all tier 1 sales for period (VME difference by model * DIS Volume)
            </td>
            <td>X360 / MAPPIE</td>
          </tr>
          <tr>
            <td>DIS / MSRP</td>
            <td>MSRP added value</td>
            <td>
              The added value based on car spec differences applied to all tier 1 sales for period (MSRP difference by model * DIS Volume)
            </td>
            <td>X360 / MAPPIE</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MsrpData;
