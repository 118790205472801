import {
  SET_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_ADJUSTED,
  SET_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_TREND,
  SET_ONLINE_ACC_AND_ENG_ACTIVE_USERS,
  SET_ONLINE_ACC_AND_ENG_AVG_VOC_RATING,
  SET_ONLINE_ACC_AND_ENG_COMPLETE_ACCOUNT_CREATION,
  SET_ONLINE_ACC_AND_ENG_ENGAGEMENT_VS_ACTIVE_USERS,
  SET_ONLINE_ACC_AND_ENG_ENROLLED_USERS_LINKED,
  SET_ONLINE_ACC_AND_ENG_ENROLLED_USERS_TREND,
  SET_ONLINE_ACC_AND_ENG_FIRST_ENGAGEMENT,
  SET_ONLINE_ACC_AND_ENG_LINKED_CARS,
  SET_ONLINE_ACC_AND_ENG_PRIVATE_SALES,
  SET_ONLINE_ACC_AND_ENG_START_ACCOUNT_CREATION,
  SET_ONLINE_ACC_AND_ENG_VEHICLES_ADDED,
  SET_OVERVIEW_AVG_MONTHLY_ACTIVE_USERS,
  SET_OVERVIEW_AVG_VOC_RATING_ACROSS_PAGES,
  SET_OVERVIEW_CHURN_RATE,
  SET_OVERVIEW_CONNECTED_CARS_SOLD,
  SET_OVERVIEW_ENGAGEMENT_TREND,
  SET_OVERVIEW_ENROLLED_CSS_CUSTOMERS,
  SET_OVERVIEW_ONLINE_ACCOUNT_AND_ENGAGEMENT,
  SET_OVERVIEW_RENEWAL_RATE,
  SET_OVERVIEW_RENEWAL_TREND,
  SET_OVERVIEW_SERVICE_LEADS,
  SET_OVERVIEW_USER_ENGAGEMENT,
  SET_SELF_SERVICE_POPULAR_SEARCH_TERMS_VIA_GOOGLE,
  SET_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_AND_TIME_SPENT,
  SET_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_CHANNEL,
  SET_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_ENTRY_POINT,
  SET_SELF_SERVICE_TOPIC_CLASSIFICATION_DISSATISFACTION,
  SET_SELF_SERVICE_TOPIC_CLASSIFICATION_SATISFACTION,
  SET_SELF_SERVICE_TOPIC_CLASSIFICATION_VERBATIM_DATA,
  SET_SELF_SERVICE_USERS_VISITING_SELF_LEARNING_PAGE,
  SET_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE,
  SET_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE_OVER_TIME,
  SET_SERVICE_LEAD_BOOKING_FORMS_VISITS_VS_SERVICE_LEADS,
  SET_SERVICE_LEAD_CONVERSION_RATE,
  SET_SERVICE_LEAD_SERVICE_BOOKING_FORMS_OPENED,
  SET_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS,
  SET_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS_BY_CHANNEL_OR_TREND,
  SET_SERVICE_LEAD_SERVICE_LEADS,
  SET_SERVICE_LEAD_SERVICE_RECOMMENDATION_VISITS,
} from "../actions/actionTypes/useOwnershipTypes";

const initialState = {
  overview_avg_voc_rating_across_pages: {},
  overview_online_account_and_engagement: {},
  overview_service_leads: {},
  overview_enrolled_ccs_customers: {},
  overview_avg_monthly_active_users: {},
  overview_connected_cars_sold: {},
  overview_user_engagement: {},
  overview_renewal_rate: {},
  overview_churn_rate: {},
  overview_ccs_engagement_trend: {},
  overview_ccs_renewal_trend: {},
  online_acc_and_eng_private_sales: {},
  online_acc_and_eng_enrolled_users_trend: {},
  online_acc_and_eng_account_creation_trend: {},
  online_acc_and_eng_linked_cars: {},
  online_acc_and_eng_engagement_vs_active_users: {},
  online_acc_and_eng_start_account_creation: {},
  online_acc_and_eng_complete_account_creation: {},
  online_acc_and_eng_account_creation_adjusted: {},
  online_acc_and_eng_active_users: {},
  online_acc_and_eng_first_engagement: {},
  online_acc_and_eng_avg_voc_rating: {},
  online_acc_and_eng_enrolled_users_linked: {},
  online_acc_and_eng_vehicles_added: {},
  service_lead_service_recommendation_visits: {},
  service_lead_service_booking_form_visits: {},
  service_lead_service_booking_forms_opened: {},
  service_lead_conversion_rate: {},
  service_lead_service_leads: {},
  service_lead_booking_forms_visits_vs_service_leads: {},
  service_lead_booking_form_visits_by_channel_or_trend: {},
  self_service_users_visiting_self_learning_page: {},
  self_service_self_learning_page_visits_and_time_spent: {},
  self_service_self_learning_page_visits_by_channel: {},
  self_service_self_learning_page_visits_by_entry_point: {},
  self_service_voc_rating_for_self_learning_page: {},
  self_service_voc_rating_for_self_learning_page_over_time: {},
  self_service_topic_classification_verbatim_data: [],
  self_service_topic_classification_dissatisfaction: [],
  self_service_topic_classification_satisfaction: [],
  self_service_popular_search_terms_via_google: [],
};

const useOwnershipReducer = (
  state = initialState,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case SET_OVERVIEW_AVG_VOC_RATING_ACROSS_PAGES:
      return { ...state, overview_avg_voc_rating_across_pages: action.payload };

    case SET_OVERVIEW_ONLINE_ACCOUNT_AND_ENGAGEMENT:
      return { ...state, overview_online_account_and_engagement: action.payload };

    case SET_OVERVIEW_SERVICE_LEADS:
      return { ...state, overview_service_leads: action.payload };

    case SET_OVERVIEW_ENROLLED_CSS_CUSTOMERS:
      return { ...state, overview_enrolled_ccs_customers: action.payload };

    case SET_OVERVIEW_AVG_MONTHLY_ACTIVE_USERS:
      return { ...state, overview_avg_monthly_active_users: action.payload };

    case SET_OVERVIEW_CONNECTED_CARS_SOLD:
      return { ...state, overview_connected_cars_sold: action.payload };

    case SET_OVERVIEW_USER_ENGAGEMENT:
      return { ...state, overview_user_engagement: action.payload };

    case SET_OVERVIEW_RENEWAL_RATE:
      return { ...state, overview_renewal_rate: action.payload };

    case SET_OVERVIEW_CHURN_RATE:
      return { ...state, overview_churn_rate: action.payload };

    case SET_OVERVIEW_ENGAGEMENT_TREND:
      return { ...state, overview_ccs_engagement_trend: action.payload };

    case SET_OVERVIEW_RENEWAL_TREND:
      return { ...state, overview_ccs_renewal_trend: action.payload };

    case SET_ONLINE_ACC_AND_ENG_PRIVATE_SALES:
      return { ...state, online_acc_and_eng_private_sales: action.payload };

    case SET_ONLINE_ACC_AND_ENG_ENROLLED_USERS_TREND:
      return { ...state, online_acc_and_eng_enrolled_users_trend: action.payload };

    case SET_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_TREND:
      return { ...state, online_acc_and_eng_account_creation_trend: action.payload };

    case SET_ONLINE_ACC_AND_ENG_LINKED_CARS:
      return { ...state, online_acc_and_eng_linked_cars: action.payload };

    case SET_ONLINE_ACC_AND_ENG_ENGAGEMENT_VS_ACTIVE_USERS:
      return { ...state, online_acc_and_eng_engagement_vs_active_users: action.payload };

    case SET_ONLINE_ACC_AND_ENG_START_ACCOUNT_CREATION:
      return { ...state, online_acc_and_eng_start_account_creation: action.payload };

    case SET_ONLINE_ACC_AND_ENG_COMPLETE_ACCOUNT_CREATION:
      return { ...state, online_acc_and_eng_complete_account_creation: action.payload };

    case SET_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_ADJUSTED:
      return { ...state, online_acc_and_eng_account_creation_adjusted: action.payload };

    case SET_ONLINE_ACC_AND_ENG_ACTIVE_USERS:
      return { ...state, online_acc_and_eng_active_users: action.payload };

    case SET_ONLINE_ACC_AND_ENG_FIRST_ENGAGEMENT:
      return { ...state, online_acc_and_eng_first_engagement: action.payload };

    case SET_ONLINE_ACC_AND_ENG_AVG_VOC_RATING:
      return { ...state, online_acc_and_eng_avg_voc_rating: action.payload };

    case SET_ONLINE_ACC_AND_ENG_ENROLLED_USERS_LINKED:
      return { ...state, online_acc_and_eng_enrolled_users_linked: action.payload };

    case SET_ONLINE_ACC_AND_ENG_VEHICLES_ADDED:
      return { ...state, online_acc_and_eng_vehicles_added: action.payload };

    case SET_SERVICE_LEAD_SERVICE_RECOMMENDATION_VISITS:
      return { ...state, service_lead_service_recommendation_visits: action.payload };

    case SET_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS:
      return { ...state, service_lead_service_booking_form_visits: action.payload };

    case SET_SERVICE_LEAD_SERVICE_BOOKING_FORMS_OPENED:
      return { ...state, service_lead_service_booking_forms_opened: action.payload };

    case SET_SERVICE_LEAD_CONVERSION_RATE:
      return { ...state, service_lead_conversion_rate: action.payload };

    case SET_SERVICE_LEAD_SERVICE_LEADS:
      return { ...state, service_lead_service_leads: action.payload };

    case SET_SERVICE_LEAD_BOOKING_FORMS_VISITS_VS_SERVICE_LEADS:
      return { ...state, service_lead_booking_forms_visits_vs_service_leads: action.payload };

    case SET_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS_BY_CHANNEL_OR_TREND:
      return { ...state, service_lead_booking_form_visits_by_channel_or_trend: action.payload };

    case SET_SELF_SERVICE_USERS_VISITING_SELF_LEARNING_PAGE:
      return { ...state, self_service_users_visiting_self_learning_page: action.payload };

    case SET_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_AND_TIME_SPENT:
      return { ...state, self_service_self_learning_page_visits_and_time_spent: action.payload };

    case SET_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_CHANNEL:
      return { ...state, self_service_self_learning_page_visits_by_channel: action.payload };

    case SET_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_ENTRY_POINT:
      return { ...state, self_service_self_learning_page_visits_by_entry_point: action.payload };

    case SET_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE:
      return { ...state, self_service_voc_rating_for_self_learning_page: action.payload };

    case SET_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE_OVER_TIME:
      return { ...state, self_service_voc_rating_for_self_learning_page_over_time: action.payload };

    case SET_SELF_SERVICE_TOPIC_CLASSIFICATION_VERBATIM_DATA:
      return { ...state, self_service_topic_classification_verbatim_data: action.payload };

    case SET_SELF_SERVICE_TOPIC_CLASSIFICATION_DISSATISFACTION:
      return { ...state, self_service_topic_classification_dissatisfaction: action.payload };

    case SET_SELF_SERVICE_TOPIC_CLASSIFICATION_SATISFACTION:
      return { ...state, self_service_topic_classification_satisfaction: action.payload };

    case SET_SELF_SERVICE_POPULAR_SEARCH_TERMS_VIA_GOOGLE:
      return { ...state, self_service_popular_search_terms_via_google: action.payload };

    default:
      return state;
  }
};
export default useOwnershipReducer;
