import { Button, FormControlLabel, makeStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { Dispatch, SetStateAction, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { createReleaseNote, updateReleaseNote } from "../../../../../api/adminApi";
import { ErrorMsg } from "../../../../../components/AppMessages";
import { CYAN } from "../../../../../constants";
import { IReleaseNote } from "../../../../../constants/interface/admin/releaseNotes";

interface Props {
  open: boolean;
  toggleOpen: () => void;
  selectedReleaseNote: IReleaseNote | undefined;
  setReleaseNotes: Dispatch<SetStateAction<Array<IReleaseNote>>>;
}

interface IReleaseFormData extends FormData, IReleaseNote {}

export const CreateUpdateDialogue = (props: Props) => {
  const { open, selectedReleaseNote: selectedMsg, toggleOpen, setReleaseNotes } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IReleaseNote>({
    defaultValues: { ...selectedMsg },
  });

  const classes = makeStyles({
    button: {
      backgroundColor: CYAN,
      color: "#FFF !important",
      borderRadius: "0px !important",
      border: "none !important",
      marginRight: "10px",
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      "&:hover": {
        backgroundColor: "#5ed2ff !important",
        border: "none !important",
      },
    },
  })();

  const submitHandler = useCallback(
    (values: IReleaseFormData) => (selectedMsg?.id ? handleUpdateReleaseNote(values) : handleCreateReleaseNote(values)),
    [selectedMsg]
  );

  const handleCreateReleaseNote = (values: IReleaseFormData) => {
    createReleaseNote(values).then((response: any) => {
      if (response && !("error" in response)) {
        setReleaseNotes((prevState) => [...prevState, response]);
        toggleOpen();
      } else {
        toast.error(response?.error);
      }
    });
  };

  const handleUpdateReleaseNote = (values: IReleaseFormData) => {
    updateReleaseNote(values).then((response: any) => {
      if (response && !("error" in response)) {
        setReleaseNotes((prevState) => prevState.map((releaseNote) => (releaseNote.id === response.id ? response : releaseNote)));
        toggleOpen();
      } else {
        toast.error(response?.error);
      }
    });
  };

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <Dialog onClose={toggleOpen} open={open} scroll="body" fullWidth>
        <DialogTitle>{selectedMsg?.id ? "Update entry" : "Create new entry"}</DialogTitle>
        <DialogContent dividers>
          {/* Version Number */}
          <Grid container spacing={2}>
            <Grid item md={6}>
              <FormControl fullWidth>
                <Controller
                  name="major"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Major"
                      id="major"
                      type="number"
                      value={value}
                      onChange={onChange}
                      variant="outlined"
                      error={Boolean(errors.major)}
                      helperText={errors?.major?.message}
                      required
                      inputProps={{
                        "data-test-id": "major-input",
                      }}
                    />
                  )}
                  defaultValue=""
                />
              </FormControl>
            </Grid>

            <Grid item md={6}>
              <FormControl fullWidth>
                <Controller
                  name="minor"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Minor"
                      id="minor"
                      type="number"
                      value={value}
                      onChange={onChange}
                      variant="outlined"
                      error={Boolean(errors.major)}
                      helperText={errors?.major?.message}
                      required
                      inputProps={{
                        "data-test-id": "major-input",
                      }}
                    />
                  )}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* Popup message */}
          <Grid container spacing={2}>
            <Grid item md={12}>
              <FormControl fullWidth>
                <Controller
                  name="popup_note"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Popup message"
                      id="popup_note"
                      type="string"
                      value={value}
                      onChange={onChange}
                      variant="outlined"
                      error={Boolean(errors.popup_note)}
                      helperText={errors?.popup_note?.message}
                      required
                      inputProps={{
                        "data-test-id": "patch_notes-input",
                      }}
                    />
                  )}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* Release note */}
          <Grid container spacing={2}>
            <Grid item md={12}>
              <FormControl fullWidth>
                <Controller
                  name="release_note"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Release note"
                      id="release_note"
                      type="string"
                      value={value}
                      onChange={onChange}
                      variant="outlined"
                      error={Boolean(errors.release_note)}
                      helperText={errors?.release_note?.message}
                      required
                      inputProps={{
                        "data-test-id": "release_notes-input",
                      }}
                    />
                  )}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* Release date */}
          {!selectedMsg?.id && (
            <Grid container spacing={2}>
              <Grid item md={12}>
                <FormControl fullWidth>
                  <Controller
                    name="release_date"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label="Release date"
                        id="release_date"
                        type="datetime"
                        value={value}
                        onChange={onChange}
                        variant="outlined"
                        error={Boolean(errors.release_date)}
                        helperText={errors?.release_date?.message}
                        required
                        inputProps={{
                          "data-test-id": "release_date-input",
                        }}
                      />
                    )}
                    defaultValue={moment().format("YYYY-MM-DDTHH:mm")}
                  />
                </FormControl>
              </Grid>
            </Grid>
          )}
          {/* Show popup? */}
          <Grid container>
            <Grid item md={12}>
              <FormControl>
                <Controller
                  name="valid"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      value="valid"
                      control={
                        <Checkbox
                          color="primary"
                          checked={!!value}
                          onChange={onChange}
                          inputProps={{
                            id: "valid-checkbox",
                          }}
                        />
                      }
                      label="Show popup"
                      labelPlacement="start"
                      style={{ margin: 0 }}
                    />
                  )}
                  defaultValue={false}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ justifyContent: `${selectedMsg?.id ? "flex-end" : "space-between"}` }}>
          <Button
            className={classes.button}
            variant="outlined"
            type="submit"
            onClick={handleSubmit(submitHandler)}
            disableElevation
            data-test-id={`${selectedMsg?.id ? "btn-update-release" : "btn-create-release"}`}
          >
            {selectedMsg?.id ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </ErrorBoundary>
  );
};
