import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useMemo, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import ComparisonBarChart from "../../../components/Charts/ProductMarketingOptimization/ComparisonBarChart";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { ThemeContext } from "../../../context";
import { getCustomerIntentionChartData, getCustomerIntentionSeriesList, getSalesByGradeSeriesList } from "../Helpers/helpers";
import ComparisonLegend from "./common/ComparisonLegend";

interface Props {
  showVolume: boolean;
  target?: string;
  nimTech?: boolean;
}

const CustomerIntentionComparison = (props: Props) => {
  const { showVolume, nimTech, target } = props;
  const themeContext = useContext(ThemeContext);
  const data = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.sales_by_trend ?? []);
  const nimTechData = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.nim_tech_sales_by_trend ?? []);
  const customerIntegrationData = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.customer_intention_vs_sales);

  const nimTechCustomerIntegrationData = useSelector(
    (state: RootStateOrAny) => state.product_marketing_optimization.nim_tech_sales_by_trend
  );

  const sales_by_trend_loading = useSelector((state: RootStateOrAny) => state.loading.sales_by_trend);
  const nim_tech_loading = useSelector((state: RootStateOrAny) => state.loading.nim_tech_sales_by_trend);
  const granularity = useSelector((state: RootStateOrAny) => state.parameters.granularity);

  const [loading] = useState<boolean>(nimTech ? nim_tech_loading : sales_by_trend_loading);

  const chartData = useMemo(() => getCustomerIntentionChartData(nimTech ? nimTechData : data, nimTech), [data, nimTech, nimTechData]);

  const customerIntentionSeriesList = useMemo(
    () =>
      getCustomerIntentionSeriesList(
        nimTech ? nimTechData : data,
        nimTech ? nimTechCustomerIntegrationData : customerIntegrationData,
        nimTech
      ),
    [data, customerIntegrationData, nimTechCustomerIntegrationData, nimTech, nimTechData]
  );

  const salesByGradeSeriesList = useMemo(
    () =>
      getSalesByGradeSeriesList(nimTech ? nimTechData : data, nimTech ? nimTechCustomerIntegrationData : customerIntegrationData, nimTech),
    [data, customerIntegrationData, nimTechData, nimTechCustomerIntegrationData, nimTech]
  );

  const legendList = useMemo(() => {
    const list = [...salesByGradeSeriesList];
    return list.reverse();
  }, [salesByGradeSeriesList]);

  return (
    <div className={`customer_intention_comparison ${nimTech ? "nim" : ""}`}>
      <div className="customer_intention_trend primary_tile_background">
        <div className="header">
          <div className="title_container">
            <h3>Customer intention trend</h3>
          </div>
        </div>

        {(data?.length && !nimTech) || (nimTech && nimTechData?.length) ? (
          <ComparisonLegend legendList={legendList} seriesList={salesByGradeSeriesList} />
        ) : null}

        <div className="main_content ">
          {(data?.length && !nimTech) || (nimTech && nimTechData?.length) ? (
            <ErrorBoundary fallback={<ErrorMsg />}>
              <ComparisonBarChart
                chartName={
                  nimTech ? "nimTechcustomerIntentionTrend" : target ? `customerIntentionTrend${target}` : "customerIntentionTrend"
                }
                data={chartData}
                granularity={granularity}
                isLoading={loading}
                showVolume={showVolume}
                seriesList={customerIntentionSeriesList}
                theme={themeContext.theme}
              />
            </ErrorBoundary>
          ) : loading ? (
            <LoadingEllipsis isLoading={loading} />
          ) : (
            <NoDataMsg message="No data available for this market" />
          )}
        </div>
      </div>
      {!nimTech ? (
        <div className="sales_by_grade primary_tile_background">
          <div className="header">
            <div className="title_container">
              <h3>{`Sales ${nimTech ? "" : "by grade"} trend`}</h3>
            </div>
          </div>

          {(data?.length && !nimTech) || (nimTech && nimTechData?.length) ? (
            <ComparisonLegend legendList={legendList} seriesList={salesByGradeSeriesList} />
          ) : null}

          <div className="main_content">
            {(data?.length && !nimTech) || (nimTech && nimTechData?.length) ? (
              <ErrorBoundary fallback={<ErrorMsg />}>
                <ComparisonBarChart
                  chartName={nimTech ? "nimTechsalesByGrade" : target ? `salesByGrade${target}` : "salesByGrade"}
                  data={chartData}
                  granularity={granularity}
                  isLoading={loading}
                  showVolume={showVolume}
                  seriesList={salesByGradeSeriesList}
                  theme={themeContext.theme}
                />
              </ErrorBoundary>
            ) : loading ? (
              <LoadingEllipsis isLoading={loading} />
            ) : (
              <NoDataMsg message="No data available for this market" />
            )}
          </div>
        </div>
      ) : null}
      {data?.length && (
        <div className={`trend-legend hideOnMobileOnly ${nimTech ? "nim_tech_legend" : ""}`}>
          {legendList?.map((series: { name: string; color: string }, index: number) => {
            return (
              <div className="legend-item" key={series.name}>
                <div className="legend-box" style={{ backgroundColor: series.color }}></div>
                {` ${series.name}`}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CustomerIntentionComparison;
