import { Box, Button, makeStyles } from "@material-ui/core";
import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { deleteKPILog, getKPIs } from "../../../api/adminApi";
import { ErrorMsg } from "../../../components/AppMessages";
import { CYAN } from "../../../constants";
import { KPILog } from "../../../constants/interface/admin/kpiUpdate";
import UserSearchField from "../common/Fields/UserSearchField";
import { CreateUpdateDialogue } from "./subs/Dialogs/CreateUpdateDialogue";
import { DeleteDialogue } from "./subs/Dialogs/DeleteDialogue";
import { KpiTable } from "./subs/KpiTable";

export const AdminKpiUpdate = () => {
  const [deleteDialogueOpen, setDeleteDialogueOpen] = useState<boolean>(false);
  const [formDialogueOpen, setFormDialogueOpen] = useState<boolean>(false);
  const [selectedKpi, setSelectedKpi] = useState<KPILog | undefined>(undefined);
  const [kpis, setKpis] = useState<Array<KPILog>>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const filteredKpi = kpis.filter((kpi) => kpi.element_name.toLowerCase().includes(searchValue.toLowerCase()));

  const classes = makeStyles({
    button: {
      backgroundColor: CYAN,
      color: "#FFF !important",
      borderRadius: "0px !important",
      border: "none !important",
      marginRight: "10px",
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      "&:hover": {
        backgroundColor: "#5ed2ff !important",
        border: "none !important",
      },
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
  })();

  useEffect(() => {
    getKPIs().then((response) => {
      if (response && !("error" in response)) setKpis(response.data);
    });
  }, []);

  const handleToggleDeleteDialog = () => setDeleteDialogueOpen(!deleteDialogueOpen);

  const handleToggleFlashFormDialogue = useCallback(() => {
    setFormDialogueOpen((prevState) => !prevState);
    if (formDialogueOpen) {
      setSelectedKpi(undefined);
    }
  }, [formDialogueOpen]);

  const handleDeleteFlashMsg = (id: number) => {
    deleteKPILog(id).then((response) => {
      if (response && response === "success") {
        setKpis((prevState) => prevState.filter((kpi) => kpi.kpi_id !== id));
        setSelectedKpi(undefined);
        handleToggleDeleteDialog();
      } else {
        toast.error("Failed to delete KPI Log");
      }
    });
  };

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <Box component="div">
        <Box className={classes.buttonsContainer} component="div" mb={3}>
          <Box component="div">
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={() => {
                setSelectedKpi(undefined);
                handleToggleFlashFormDialogue();
              }}
              data-test-id="btn-add-new-user"
            >
              New
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={handleToggleFlashFormDialogue}
              disabled={!selectedKpi}
              data-test-id="btn-edit-user"
            >
              Edit
            </Button>
            <Button
              className={classes.button}
              variant="outlined"
              disableTouchRipple
              disableFocusRipple
              onClick={handleToggleDeleteDialog}
              disabled={!selectedKpi}
              data-test-id="btn-delete-user"
            >
              Delete
            </Button>
          </Box>
          <Box component="div">
            <UserSearchField value={searchValue} setValue={setSearchValue} />
          </Box>
        </Box>

        <ErrorBoundary fallback={<ErrorMsg />}>
          <KpiTable
            data={filteredKpi}
            selectedMsg={selectedKpi}
            setSelectedMsg={setSelectedKpi}
            setDeleteDialogOpen={setDeleteDialogueOpen}
          />
        </ErrorBoundary>

        {deleteDialogueOpen && selectedKpi && (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <DeleteDialogue
              kpi={selectedKpi}
              open={deleteDialogueOpen}
              handleClose={handleToggleDeleteDialog}
              handleDelete={handleDeleteFlashMsg}
            />
          </ErrorBoundary>
        )}

        {formDialogueOpen && (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <CreateUpdateDialogue
              selectedKpi={selectedKpi}
              open={formDialogueOpen}
              toggleOpen={handleToggleFlashFormDialogue}
              setKpis={setKpis}
            />
          </ErrorBoundary>
        )}
      </Box>
    </ErrorBoundary>
  );
};
