import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { BLUE, DATE_FILTER_VALUE_MAPPING, PMO_CHART_COLORS, PURPLE } from "../../../constants";
import { getLegendDfp } from "../../../pages/dfp/helpers/helpers";
import { getFiscalYearTimestamps, getFYQuarter, getStartEnd } from "../../../utils/dateFunctions";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { formatValueAxisUnits, getTooltipDateFormat, getTooltipTextFormat } from "../helpers/helpers";
import { legendConfigurationOCE, oceChartConfiguration } from "../helpers/oceConfigurations";
import { dateAxisLabelFormatter_QuarterlyFY, formatDateAxisLabelToQuarterlyFY } from "../helpers/pmoConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  chartId: string;
  data: any;
  theme: ThemeInt;
  seriesList: Array<string>;
  dateValue: string;
  isLoading: boolean;
  granularity: string;
}

class MetricComparisonsChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chartId = this.props.chartId;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId);
      setTimeout(() => this.initChart(), 1500);
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, granularity, theme, seriesList, dateValue } = this.props;

    // Global chart configuration
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.numberFormatter.numberFormat = "#a";
    this.chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    this.chart.data = data;
    // Creates Legend
    this.chart.legend = legendConfigurationOCE(theme);
    this.chart.colors.list = PMO_CHART_COLORS.map((chartColor) => color(chartColor));

    const isMultiFY =
      getFiscalYearTimestamps(moment(data?.Current?.[0]?.date).format("DD/MM/YYYY")).current.start.substr(-2, 2) !==
      getFiscalYearTimestamps(moment(data?.Current?.[data?.Current?.length - 1]?.date).format("DD/MM/YYYY")).current.start.substr(-2, 2);

    // Current and previous chart labels
    const previousPeriod = moment(getStartEnd(dateValue).end, "DD/MM/YYYY").subtract(1, "years").format("MMMM YYYY");

    const currentLabel = isMultiFY
      ? "Selected period"
      : dateValue.includes("quarter")
        ? DATE_FILTER_VALUE_MAPPING[dateValue]
        : dateValue.includes("-")
          ? getLegendDfp(dateValue)
          : `FY${getFiscalYearTimestamps(getStartEnd(dateValue).start).current.start.substr(-2, 2)}`;

    const previousLabel = isMultiFY
      ? "Previous year"
      : dateValue.includes("quarter")
        ? getFYQuarter(previousPeriod)
        : dateValue.includes("-")
          ? getLegendDfp(dateValue, true)
          : `FY${getFiscalYearTimestamps(getStartEnd(dateValue).end).last.start.substr(-2, 2)}`;

    // Create data chart axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.cellStartLocation = 0.5;
    dateAxis.renderer.labels.template.disabled = true;
    dateAxis.renderer.cellEndLocation = 0.8;
    dateAxis.tooltipDateFormat = getTooltipDateFormat(granularity);
    dateAxis.renderer.minGridDistance = this.props.granularity === "quarterly" || this.props.granularity == "weekly" ? 38 : 20;

    // 2nd date axis for comparison data
    const dateAxis2 = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis2, theme);
    // dateAxis2.renderer.labels.template.disabled = true;
    dateAxis2.renderer.cellStartLocation = 0.2;
    dateAxis2.renderer.cellEndLocation = 0.5;
    dateAxis2.tooltipDateFormat = getTooltipDateFormat(granularity);
    granularity === "quarterly" && dateAxis2.renderer.labels.template.adapter.add("text", dateAxisLabelFormatter_QuarterlyFY);
    dateAxis2.renderer.minGridDistance = this.props.granularity === "quarterly" || this.props.granularity == "weekly" ? 38 : 20;

    // Creates value axis
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.min = 0;
    valueAxis.renderer.grid.template.strokeWidth = 0;
    if (this.props.chartId === "leadsToBookingChart") valueAxis.max = 100;
    if (this.props.chartId === "leadsToBookingChart")
      valueAxis.renderer.labels.template.adapter.add("text", function (text) {
        return text + "%";
      });
    formatValueAxisUnits(valueAxis);
    seriesList?.forEach((metric, idx) => {
      const series = this.chart.series.push(new LineSeries());
      series.data = !metric.includes("yoy") ? data?.Current : data?.YoY;
      const bullet = lineSeriesConfiguration(series, {
        valueY:
          this.props.chartId === "testDriveRequestChart"
            ? "leads_book_a_test_drive"
            : this.props.chartId === "retailSalesChart"
              ? "volume_sold"
              : metric.split("_")[0],
        name: metric.includes("yoy") ? previousLabel : currentLabel,
      });
      series.xAxis = metric.includes("yoy") ? dateAxis2 : dateAxis;
      series.dataFields.dateX = "date";
      series.fill = color(metric.includes("yoy") ? PURPLE : BLUE);
      series.stroke = color(metric.includes("yoy") ? PURPLE : BLUE);

      if (granularity === "quarterly") series.dataFields.categoryX = "quarter";

      bullet.adapter.add("tooltipText", (text, target) => {
        const data = target.dataItem?.dataContext;
        if (granularity === "quarterly") {
          //@ts-ignore
          const dateVal = formatDateAxisLabelToQuarterlyFY(data?.date);
          text =
            this.props.chartId === "leadsToBookingChart"
              ? `[bold; font-size: var(--regular_font_size);] ${dateVal}[/ font-size: var(--regular_font_size);]: {valueY.formatNumber("#.")}%`
              : `[bold; font-size: var(--regular_font_size);] ${dateVal}[/ font-size: var(--regular_font_size);]: {valueY.formatNumber("#,###.")}`;
        } else {
          text =
            this.props.chartId === "retailSalesChart" && this.props.granularity === "weekly"
              ? getTooltipTextFormat("monthly")
              : getTooltipTextFormat(granularity);
        }
        return text;
      });
    });
  }
  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default MetricComparisonsChart;
