import React, { useState } from "react";
import { AriyaLayout } from "..";
import ariyaFull from "../../../assets/images/Ariya/ARIYA_car_fullbleed.png";
import ariyaMobile from "../../../assets/images/Ariya/ARIYA_car_mobile.png";
import MenuOptions from "../../MenuOptions/MenuOptions";
import { handlePDFDownload_Ariya } from "../../MenuOptions/subs";
import { SaveHomePage } from "../../Modals";
import homeStyles from "./styles/homeLayout.module.scss";

interface Props {
  children: React.ReactChild;
  title?: string;
  lastDataRefresh: string;
  lastCanadaUpdate?: string;
}

export const HomeLayout = (props: Props) => {
  const { title, children, lastDataRefresh, lastCanadaUpdate } = props;

  const [showSaveHomePage, setShowSaveHomePage] = useState<boolean>(false);

  const handleSaveHomePageClick = () => {
    setShowSaveHomePage(true);
  };

  const handleCloseSaveHomePage = () => {
    setShowSaveHomePage(false);
  };

  return (
    <>
      <AriyaLayout>
        <main className={homeStyles.main} id="ariyaContent">
          <div className={`dashboard_banner hide_on_mobile`}>
            <p>
              This dashboard has been discontinued and archived. Therefore, the data will no longer be updated and might contain
              discrepancies moving forward. Please contact us if you want to have more information.
            </p>
          </div>
          <div id="ariyaContentDiv">
            <MenuOptions
              onHandleCSVDownload={() => {}}
              onHandlePDFDownload={handlePDFDownload_Ariya}
              onHandleSaveHomePageClick={handleSaveHomePageClick}
              lastDataRefresh={lastDataRefresh}
              dataDocumentation={""}
              showLastDataRefreshAlert={true}
              isAriya={true}
              canadaUpdate={lastCanadaUpdate}
            />
            <div>
              <div className={homeStyles.titleContainer}>
                <h1 className={homeStyles.title} id="pageTitle">
                  {title}
                </h1>
                <img src={ariyaMobile} className={`${homeStyles.titleImg} ${homeStyles.hide_on_desktop} `} />
                <img src={ariyaFull} className={`${homeStyles.titleImg}  ${homeStyles.hide_on_mobile} `} />
              </div>

              <div className={homeStyles.content}>{children}</div>
            </div>
            <SaveHomePage isVisible={showSaveHomePage} onCloseClick={handleCloseSaveHomePage} />
          </div>
        </main>
      </AriyaLayout>
    </>
  );
};
