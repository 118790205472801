import { ErrorBoundary } from "@sentry/react";
import React, { useState } from "react";
import { ErrorMsg } from "../../../components/AppMessages";
import Collapsible from "../../../components/Collapsible/Collapsible";
import CustomerIntentionByColor from "../components/CustomerIntentionsByColor";
import SalesByColor from "../components/SalesByColor";

const RegionalColorSection = () => {
  const [regionalColorSortOrder, setRegionalColorSortOrder] = useState<Array<string>>([]);
  return (
    <Collapsible show={true} title="Regional color trend">
      <div className="regional_sales_colour primary_tile_background">
        <ErrorBoundary fallback={<ErrorMsg />}>
          <SalesByColor setRegionalColorSortOrder={setRegionalColorSortOrder} />
        </ErrorBoundary>
        <ErrorBoundary fallback={<ErrorMsg />}>
          <CustomerIntentionByColor regionalColorSortOrder={regionalColorSortOrder} />
        </ErrorBoundary>
      </div>
    </Collapsible>
  );
};

export default RegionalColorSection;
