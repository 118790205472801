import { fetchDataV2 } from "./utils";

export const getBrandsData = (dashboard: string) => fetchDataV2(`/user_service/get-all-brands?view=${dashboard}`);
export const getMarketsData = (dashboard: string) => fetchDataV2(`/annotation/get-all-markets?view=${dashboard}`);
export const getRegionsData = (dashboard: string) => fetchDataV2(`/user_service/get-all-regions?view=${dashboard}`);
export const getViewsData = (dashboard: string) => fetchDataV2(`/user_service/get-all-views?view=${dashboard}`);
export const getModelsData = (dashboard: string) => fetchDataV2(`/annotation/get-all-models?view=${dashboard}`);
export const getChannelsData = (dashboard: string) => fetchDataV2(`/annotation/get-all-channels?view=${dashboard}`);
export const getChannelBreakdownsData = (dashboard: string) => fetchDataV2(`/annotation/get-all-channel-breakdowns?view=${dashboard}`);
export const getMetricsData = (dashboard: string) => fetchDataV2(`/annotation/get-all-metrics?view=${dashboard}`);
export const getLeadSourcesData = (dashboard: string) => fetchDataV2(`/annotation/get-all-lead-sources?view=${dashboard}`);
