import {
  FETCH_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_ADJUSTED,
  FETCH_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_TREND,
  FETCH_ONLINE_ACC_AND_ENG_ACTIVE_USERS,
  FETCH_ONLINE_ACC_AND_ENG_AVG_VOC_RATING,
  FETCH_ONLINE_ACC_AND_ENG_COMPLETE_ACCOUNT_CREATION,
  FETCH_ONLINE_ACC_AND_ENG_ENGAGEMENT_VS_ACTIVE_USERS,
  FETCH_ONLINE_ACC_AND_ENG_ENROLLED_USERS_LINKED,
  FETCH_ONLINE_ACC_AND_ENG_ENROLLED_USERS_TREND,
  FETCH_ONLINE_ACC_AND_ENG_FIRST_ENGAGEMENT,
  FETCH_ONLINE_ACC_AND_ENG_LINKED_CARS,
  FETCH_ONLINE_ACC_AND_ENG_PRIVATE_SALES,
  FETCH_ONLINE_ACC_AND_ENG_START_ACCOUNT_CREATION,
  FETCH_ONLINE_ACC_AND_ENG_VEHICLES_ADDED,
  FETCH_OVERVIEW_AVG_MONTHLY_ACTIVE_USERS,
  FETCH_OVERVIEW_AVG_VOC_RATING_ACROSS_PAGES,
  FETCH_OVERVIEW_CHURN_RATE,
  FETCH_OVERVIEW_CONNECTED_CARS_SOLD,
  FETCH_OVERVIEW_ENGAGEMENT_TREND,
  FETCH_OVERVIEW_ENROLLED_CSS_CUSTOMERS,
  FETCH_OVERVIEW_ONLINE_ACCOUNT_AND_ENGAGEMENT,
  FETCH_OVERVIEW_RENEWAL_RATE,
  FETCH_OVERVIEW_RENEWAL_TREND,
  FETCH_OVERVIEW_SERVICE_LEADS,
  FETCH_OVERVIEW_USER_ENGAGEMENT,
  FETCH_SELF_SERVICE_POPULAR_SEARCH_TERMS_VIA_GOOGLE,
  FETCH_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_AND_TIME_SPENT,
  FETCH_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_CHANNEL,
  FETCH_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_ENTRY_POINT,
  FETCH_SELF_SERVICE_TOPIC_CLASSIFICATION_DISSATISFACTION,
  FETCH_SELF_SERVICE_TOPIC_CLASSIFICATION_SATISFACTION,
  FETCH_SELF_SERVICE_TOPIC_CLASSIFICATION_VERBATIM_DATA,
  FETCH_SELF_SERVICE_USERS_VISITING_SELF_LEARNING_PAGE,
  FETCH_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE,
  FETCH_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE_OVER_TIME,
  FETCH_SERVICE_LEAD_BOOKING_FORMS_VISITS_VS_SERVICE_LEADS,
  FETCH_SERVICE_LEAD_CONVERSION_RATE,
  FETCH_SERVICE_LEAD_SERVICE_BOOKING_FORMS_OPENED,
  FETCH_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS,
  FETCH_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS_BY_CHANNEL_OR_TREND,
  FETCH_SERVICE_LEAD_SERVICE_LEADS,
  FETCH_SERVICE_LEAD_SERVICE_RECOMMENDATION_VISITS,
  SET_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_ADJUSTED,
  SET_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_TREND,
  SET_ONLINE_ACC_AND_ENG_ACTIVE_USERS,
  SET_ONLINE_ACC_AND_ENG_AVG_VOC_RATING,
  SET_ONLINE_ACC_AND_ENG_COMPLETE_ACCOUNT_CREATION,
  SET_ONLINE_ACC_AND_ENG_ENGAGEMENT_VS_ACTIVE_USERS,
  SET_ONLINE_ACC_AND_ENG_ENROLLED_USERS_LINKED,
  SET_ONLINE_ACC_AND_ENG_ENROLLED_USERS_TREND,
  SET_ONLINE_ACC_AND_ENG_FIRST_ENGAGEMENT,
  SET_ONLINE_ACC_AND_ENG_LINKED_CARS,
  SET_ONLINE_ACC_AND_ENG_PRIVATE_SALES,
  SET_ONLINE_ACC_AND_ENG_START_ACCOUNT_CREATION,
  SET_ONLINE_ACC_AND_ENG_VEHICLES_ADDED,
  SET_OVERVIEW_AVG_MONTHLY_ACTIVE_USERS,
  SET_OVERVIEW_AVG_VOC_RATING_ACROSS_PAGES,
  SET_OVERVIEW_CHURN_RATE,
  SET_OVERVIEW_CONNECTED_CARS_SOLD,
  SET_OVERVIEW_ENGAGEMENT_TREND,
  SET_OVERVIEW_ENROLLED_CSS_CUSTOMERS,
  SET_OVERVIEW_ONLINE_ACCOUNT_AND_ENGAGEMENT,
  SET_OVERVIEW_RENEWAL_RATE,
  SET_OVERVIEW_RENEWAL_TREND,
  SET_OVERVIEW_SERVICE_LEADS,
  SET_OVERVIEW_USER_ENGAGEMENT,
  SET_SELF_SERVICE_POPULAR_SEARCH_TERMS_VIA_GOOGLE,
  SET_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_AND_TIME_SPENT,
  SET_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_CHANNEL,
  SET_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_ENTRY_POINT,
  SET_SELF_SERVICE_TOPIC_CLASSIFICATION_DISSATISFACTION,
  SET_SELF_SERVICE_TOPIC_CLASSIFICATION_SATISFACTION,
  SET_SELF_SERVICE_TOPIC_CLASSIFICATION_VERBATIM_DATA,
  SET_SELF_SERVICE_USERS_VISITING_SELF_LEARNING_PAGE,
  SET_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE,
  SET_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE_OVER_TIME,
  SET_SERVICE_LEAD_BOOKING_FORMS_VISITS_VS_SERVICE_LEADS,
  SET_SERVICE_LEAD_CONVERSION_RATE,
  SET_SERVICE_LEAD_SERVICE_BOOKING_FORMS_OPENED,
  SET_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS,
  SET_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS_BY_CHANNEL_OR_TREND,
  SET_SERVICE_LEAD_SERVICE_LEADS,
  SET_SERVICE_LEAD_SERVICE_RECOMMENDATION_VISITS,
} from "./actionTypes/useOwnershipTypes";

export const fetchOverviewAvgVocRatingAcrossPages = () => ({ type: FETCH_OVERVIEW_AVG_VOC_RATING_ACROSS_PAGES });
export const setOverviewAvgVocRatingAcrossPages = (data: any) => ({ type: SET_OVERVIEW_AVG_VOC_RATING_ACROSS_PAGES, payload: data });

export const fetchOverviewOnlineAccountAndEngagement = () => ({ type: FETCH_OVERVIEW_ONLINE_ACCOUNT_AND_ENGAGEMENT });
export const setOverviewOnlineAccountAndEngagement = (data: any) => ({ type: SET_OVERVIEW_ONLINE_ACCOUNT_AND_ENGAGEMENT, payload: data });

export const fetchOverviewServiceLeads = () => ({ type: FETCH_OVERVIEW_SERVICE_LEADS });
export const setOverviewServiceLeads = (data: any) => ({ type: SET_OVERVIEW_SERVICE_LEADS, payload: data });

export const fetchOverviewEnrolledCcsCustomers = () => ({ type: FETCH_OVERVIEW_ENROLLED_CSS_CUSTOMERS });
export const setOverviewEnrolledCcsCustomers = (data: any) => ({ type: SET_OVERVIEW_ENROLLED_CSS_CUSTOMERS, payload: data });

export const fetchOverviewAvgMonthlyActiveUsers = () => ({ type: FETCH_OVERVIEW_AVG_MONTHLY_ACTIVE_USERS });
export const setOverviewAvgMonthlyActiveUsers = (data: any) => ({ type: SET_OVERVIEW_AVG_MONTHLY_ACTIVE_USERS, payload: data });

export const fetchOverviewConnectedCarsSold = () => ({ type: FETCH_OVERVIEW_CONNECTED_CARS_SOLD });
export const setOverviewConnectedCarsSold = (data: any) => ({ type: SET_OVERVIEW_CONNECTED_CARS_SOLD, payload: data });

export const fetchOverviewUserEngagement = () => ({ type: FETCH_OVERVIEW_USER_ENGAGEMENT });
export const setOverviewUserEngagement = (data: any) => ({ type: SET_OVERVIEW_USER_ENGAGEMENT, payload: data });

export const fetchOverviewRenewalRate = () => ({ type: FETCH_OVERVIEW_RENEWAL_RATE });
export const setOverviewRenewalRate = (data: any) => ({ type: SET_OVERVIEW_RENEWAL_RATE, payload: data });

export const fetchOverviewChurnRate = () => ({ type: FETCH_OVERVIEW_CHURN_RATE });
export const setOverviewChurnRate = (data: any) => ({ type: SET_OVERVIEW_CHURN_RATE, payload: data });

export const fetchOverviewEngagementTrend = () => ({ type: FETCH_OVERVIEW_ENGAGEMENT_TREND });
export const setOverviewEngagementTrend = (data: any) => ({ type: SET_OVERVIEW_ENGAGEMENT_TREND, payload: data });

export const fetchOverviewRenewalTrend = () => ({ type: FETCH_OVERVIEW_RENEWAL_TREND });
export const setOverviewRenewalTrend = (data: any) => ({ type: SET_OVERVIEW_RENEWAL_TREND, payload: data });

export const fetchOnlineAccAndEngPrivateSales = () => ({ type: FETCH_ONLINE_ACC_AND_ENG_PRIVATE_SALES });
export const setOnlineAccAndEngPrivateSales = (data: any) => ({ type: SET_ONLINE_ACC_AND_ENG_PRIVATE_SALES, payload: data });

export const fetchOnlineAccAndEngEnrolledUsersTrend = () => ({ type: FETCH_ONLINE_ACC_AND_ENG_ENROLLED_USERS_TREND });
export const setOnlineAccAndEngEnrolledUsersTrend = (data: any) => ({ type: SET_ONLINE_ACC_AND_ENG_ENROLLED_USERS_TREND, payload: data });

export const fetchOnlineAccAndEngAccountCreationTrend = () => ({ type: FETCH_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_TREND });
export const setOnlineAccAndEngAccountCreationTrend = (data: any) => ({
  type: SET_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_TREND,
  payload: data,
});

export const fetchOnlineAccAndEngLinkedCars = () => ({ type: FETCH_ONLINE_ACC_AND_ENG_LINKED_CARS });
export const setOnlineAccAndEngLinkedCars = (data: any) => ({ type: SET_ONLINE_ACC_AND_ENG_LINKED_CARS, payload: data });

export const fetchOnlineAccAndEngEngagementVsActiveUsers = () => ({ type: FETCH_ONLINE_ACC_AND_ENG_ENGAGEMENT_VS_ACTIVE_USERS });
export const setOnlineAccAndEngEngagementVsActiveUsers = (data: any) => ({
  type: SET_ONLINE_ACC_AND_ENG_ENGAGEMENT_VS_ACTIVE_USERS,
  payload: data,
});

export const fetchOnlineAccAndEngStartAccountCreation = () => ({ type: FETCH_ONLINE_ACC_AND_ENG_START_ACCOUNT_CREATION });
export const setOnlineAccAndEngStartAccountCreation = (data: any) => ({
  type: SET_ONLINE_ACC_AND_ENG_START_ACCOUNT_CREATION,
  payload: data,
});

export const fetchOnlineAccAndEngCompleteAccountCreation = () => ({ type: FETCH_ONLINE_ACC_AND_ENG_COMPLETE_ACCOUNT_CREATION });
export const setOnlineAccAndEngCompleteAccountCreation = (data: any) => ({
  type: SET_ONLINE_ACC_AND_ENG_COMPLETE_ACCOUNT_CREATION,
  payload: data,
});

export const fetchOnlineAccAndEngAccountCreationAdjusted = () => ({ type: FETCH_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_ADJUSTED });
export const setOnlineAccAndEngAccountCreationAdjusted = (data: any) => ({
  type: SET_ONLINE_ACC_AND_ENG_ACCOUNT_CREATION_ADJUSTED,
  payload: data,
});

export const fetchOnlineAccAndEngActiveUsers = () => ({ type: FETCH_ONLINE_ACC_AND_ENG_ACTIVE_USERS });
export const setOnlineAccAndEngActiveUsers = (data: any) => ({ type: SET_ONLINE_ACC_AND_ENG_ACTIVE_USERS, payload: data });

export const fetchOnlineAccAndEngFirstEngagement = () => ({ type: FETCH_ONLINE_ACC_AND_ENG_FIRST_ENGAGEMENT });
export const setOnlineAccAndEngFirstEngagement = (data: any) => ({ type: SET_ONLINE_ACC_AND_ENG_FIRST_ENGAGEMENT, payload: data });

export const fetchOnlineAccAndEngAvgVocRating = () => ({ type: FETCH_ONLINE_ACC_AND_ENG_AVG_VOC_RATING });
export const setOnlineAccAndEngAvgVocRating = (data: any) => ({ type: SET_ONLINE_ACC_AND_ENG_AVG_VOC_RATING, payload: data });

export const fetchOnlineAccAndEngEnrolledUsersLinked = () => ({ type: FETCH_ONLINE_ACC_AND_ENG_ENROLLED_USERS_LINKED });
export const setOnlineAccAndEngEnrolledUsersLinked = (data: any) => ({ type: SET_ONLINE_ACC_AND_ENG_ENROLLED_USERS_LINKED, payload: data });

export const fetchOnlineAccAndEngVehiclesAdded = () => ({ type: FETCH_ONLINE_ACC_AND_ENG_VEHICLES_ADDED });
export const setOnlineAccAndEngVehiclesAdded = (data: any) => ({ type: SET_ONLINE_ACC_AND_ENG_VEHICLES_ADDED, payload: data });

export const fetchServiceLeadServiceRecommendationVisits = () => ({ type: FETCH_SERVICE_LEAD_SERVICE_RECOMMENDATION_VISITS });
export const setServiceLeadServiceRecommendationVisits = (data: any) => ({
  type: SET_SERVICE_LEAD_SERVICE_RECOMMENDATION_VISITS,
  payload: data,
});

export const fetchServiceLeadServiceBookingFormVisits = () => ({ type: FETCH_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS });
export const setServiceLeadServiceBookingFormVisits = (data: any) => ({
  type: SET_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS,
  payload: data,
});

export const fetchServiceLeadConversionRate = () => ({ type: FETCH_SERVICE_LEAD_CONVERSION_RATE });
export const setServiceLeadConversionRate = (data: any) => ({ type: SET_SERVICE_LEAD_CONVERSION_RATE, payload: data });

export const fetchServiceLeadServiceLeads = () => ({ type: FETCH_SERVICE_LEAD_SERVICE_LEADS });
export const setServiceLeadServiceLeads = (data: any) => ({ type: SET_SERVICE_LEAD_SERVICE_LEADS, payload: data });

export const fetchServiceLeadBookingFormsVisitsVsServiceLeads = () => ({ type: FETCH_SERVICE_LEAD_BOOKING_FORMS_VISITS_VS_SERVICE_LEADS });
export const setServiceLeadBookingFormsVisitsVsServiceLeads = (data: any) => ({
  type: SET_SERVICE_LEAD_BOOKING_FORMS_VISITS_VS_SERVICE_LEADS,
  payload: data,
});

export const fetchServiceLeadBookingFormVisitsByChannelOrTrend = (groupBy: string, timeline: boolean) => ({
  type: FETCH_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS_BY_CHANNEL_OR_TREND,
  payload: { groupBy, timeline },
});
export const setServiceLeadBookingFormVisitsByChannelOrTrend = (data: any) => ({
  type: SET_SERVICE_LEAD_SERVICE_BOOKING_FORM_VISITS_BY_CHANNEL_OR_TREND,
  payload: data,
});

export const fetchServiceLeadServiceBookingFormsOpened = () => ({ type: FETCH_SERVICE_LEAD_SERVICE_BOOKING_FORMS_OPENED });
export const setServiceLeadServiceBookingFormsOpened = (data: any) => ({
  type: SET_SERVICE_LEAD_SERVICE_BOOKING_FORMS_OPENED,
  payload: data,
});

export const fetchSelfServiceUserVisitingSelfLearningPage = () => ({ type: FETCH_SELF_SERVICE_USERS_VISITING_SELF_LEARNING_PAGE });
export const setSelfServiceUserVisitingSelfLearningPage = (data: any) => ({
  type: SET_SELF_SERVICE_USERS_VISITING_SELF_LEARNING_PAGE,
  payload: data,
});

export const fetchSelfServiceSelfLearningPageVisitsAndTimeSpent = () => ({
  type: FETCH_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_AND_TIME_SPENT,
});
export const setSelfServiceSelfLearningPageVisitsAndTimeSpent = (data: any) => ({
  type: SET_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_AND_TIME_SPENT,
  payload: data,
});

export const fetchSelfServiceSelfLearningPageVisitsByChannel = (trend: boolean) => ({
  type: FETCH_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_CHANNEL,
  payload: { trend },
});
export const setSelfServiceSelfLearningPageVisitsByChannel = (data: any) => ({
  type: SET_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_CHANNEL,
  payload: data,
});

export const fetchSelfServiceSelfLearningPageVisitsByEntryPoint = (trend: boolean) => ({
  type: FETCH_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_ENTRY_POINT,
  payload: { trend },
});
export const setSelfServiceSelfLearningPageVisitsByEntryPoint = (data: any) => ({
  type: SET_SELF_SERVICE_SELF_LEARNING_PAGE_VISITS_BY_ENTRY_POINT,
  payload: data,
});

export const fetchSelfServiceVocRatingForSelfLearningPage = () => ({ type: FETCH_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE });
export const setSelfServiceVocRatingForSelfLearningPage = (data: any) => ({
  type: SET_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE,
  payload: data,
});

export const fetchSelfServiceVocRatingForSelfLearningPageOverTime = () => ({
  type: FETCH_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE_OVER_TIME,
});
export const setSelfServiceVocRatingForSelfLearningPageOverTime = (data: any) => ({
  type: SET_SELF_SERVICE_VOC_RATING_FOR_SELF_LEARNING_PAGE_OVER_TIME,
  payload: data,
});

export const fetchSelfServiceTopicClassificationVerbatimData = () => ({ type: FETCH_SELF_SERVICE_TOPIC_CLASSIFICATION_VERBATIM_DATA });
export const setSelfServiceTopicClassificationVerbatimData = (data: any) => ({
  type: SET_SELF_SERVICE_TOPIC_CLASSIFICATION_VERBATIM_DATA,
  payload: data,
});

export const fetchSelfServiceTopicClassificationDissatisfaction = () => ({ type: FETCH_SELF_SERVICE_TOPIC_CLASSIFICATION_DISSATISFACTION });
export const setSelfServiceTopicClassificationDissatisfaction = (data: any) => ({
  type: SET_SELF_SERVICE_TOPIC_CLASSIFICATION_DISSATISFACTION,
  payload: data,
});

export const fetchSelfServiceTopicClassificationSatisfaction = () => ({ type: FETCH_SELF_SERVICE_TOPIC_CLASSIFICATION_SATISFACTION });
export const setSelfServiceTopicClassificationSatisfaction = (data: any) => ({
  type: SET_SELF_SERVICE_TOPIC_CLASSIFICATION_SATISFACTION,
  payload: data,
});

export const fetchSelfServicePopularSearchTermsViaGoogle = () => ({ type: FETCH_SELF_SERVICE_POPULAR_SEARCH_TERMS_VIA_GOOGLE });
export const setSelfServicePopularSearchTermsViaGoogle = (data: any) => ({
  type: SET_SELF_SERVICE_POPULAR_SEARCH_TERMS_VIA_GOOGLE,
  payload: data,
});
