import React, { useState } from "react";
import { Tooltip } from "react-tippy";
import down from "../../assets/images/down-chevron.png";
import right from "../../assets/images/right-chevron1.png";
import "../../assets/styles/component/collapsible.scss";

interface Props {
  myStyle?: string;
  show?: boolean;
  title: string;
  tooltipText?: string;
  tooltipHtml?: JSX.Element;
  toggle?: JSX.Element;
  keepHidden?: boolean;
  children: JSX.Element[] | JSX.Element;
}

const Collapsible = (props: Props) => {
  const [openPage, setOpenPage] = useState<boolean>(props.show!);

  const togglePage = () => {
    if (props.keepHidden) return;
    setOpenPage(!openPage);
  };

  return (
    <div className="cluster_wrapper">
      <div className="cluster_text collapsible_container">
        <img
          src={openPage ? down : right}
          alt="Down Arrow"
          style={{ maxWidth: openPage ? "20px" : "8px", marginRight: "5px" }}
          onClick={togglePage}
        />{" "}
        <p onClick={togglePage}>{props.title}</p>
        {props.tooltipText || props.tooltipHtml ? (
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            html={props.tooltipHtml}
            title={props.tooltipText}
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        ) : (
          ""
        )}
        {props.toggle ? props.toggle : null}
      </div>
      <div className={openPage ? props.myStyle : "hidden"}>{props.children}</div>
    </div>
  );
};

export default Collapsible;
