// Todo: Move some effects to sagas instead of useEffect
import { Button } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "../../assets/styles/component/filters.scss";
import { FiltersLayout } from "../Layouts";
import { ToggleSwitch } from "../Toogle";
import { DefaultFilter } from "./common";
import { closeFilterOptions } from "./subs/helpers";

interface Props {
  marketsList: any;
  brandList: Array<string>;
}

export const SearchInterestFilters = withRouter((props: Props & RouteComponentProps) => {
  const { marketsList, brandList } = props;
  const [marketFilterValue, setMarketFilterValue] = useState("");
  const [brandFilterValue, setBrandFilterValue] = useState("");
  const [reportType, setReportType] = useState("");
  const { history } = props;

  //Update the params whenever the market filter changes
  useEffect(() => {
    if (brandFilterValue) {
      const params = new URLSearchParams();
      params.set("brand", brandFilterValue);
      params.set("market", marketFilterValue);
      params.set("report_type", reportType);
      history.push({ search: `?${params.toString()}` });
    }
  }, [brandFilterValue, marketFilterValue, reportType]);

  //Reset the brand value if the brand passed is not valid
  useEffect(() => {
    if (brandList.length > 0) {
      const params = new URLSearchParams();
      const brandParamValue = params.get("brand");
      !brandList.includes(brandParamValue as string) && setBrandFilterValue(brandList[0]);
    }
  }, [brandList]);

  //Reset the market value if the market passed is not valid
  useEffect(() => {
    if (marketsList.length > 0) {
      const params = new URLSearchParams(history.location.search);
      const marketParamValue = params.get("market");
      !marketsList.includes(marketParamValue as string) && setMarketFilterValue(marketsList[0]);
    }
  }, [marketsList, history.location.search]);

  //Reset the report type
  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const reportParamValue = params.get("report_type");
    const defaultReport = reportParamValue ? reportParamValue : "brand";
    setReportType(defaultReport);
  }, []);

  //Update the market filter value when an option is clicked
  const handleMarketSelect = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionValue = dataset?.value;
    if (optionValue) setMarketFilterValue(optionValue);
    closeFilterOptions();
  }, []);

  //Update the brand filter value when an option is clicked
  const handleBrandSelect = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionValue = dataset?.value;
    if (optionValue) setBrandFilterValue(optionValue);
    closeFilterOptions();
  }, []);

  return (
    <FiltersLayout extraClass={"iframeFilters"}>
      <>
        <DefaultFilter
          filterName={"brand"}
          list={brandList}
          filterValue={brandFilterValue}
          filterLabel={"BRAND"}
          handleFilterOptionClick={handleBrandSelect}
        />
        <DefaultFilter
          filterName={"market"}
          list={marketsList}
          filterValue={marketFilterValue}
          filterLabel={"CP2022 MARKET"}
          handleFilterOptionClick={handleMarketSelect}
        />
        {reportType !== "insights" ? (
          <>
            <div className="filter filter_toggle_div">
              <label className="filter_header">REPORT TYPE</label>
              <ToggleSwitch
                activeToggleLabel={"Brand & core model"}
                inactiveToggleLabel={"Competitor & segment"}
                active={reportType === "brand"}
                handleToggleClick={() => setReportType(reportType === "brand" ? "competitor" : "brand")}
                toggleClassName={"filter_toggle"}
              />
            </div>

            <div className="filter filter_btn hide_on_mobile">
              <Button variant={"outlined"} className={"filter_btn_element"} onClick={() => setReportType("insights")} size={"small"}>
                See insights
              </Button>
            </div>
          </>
        ) : (
          <div className="filter filter_btn hide_on_mobile">
            <Button variant={"outlined"} className={"filter_btn_element"} onClick={() => setReportType("brand")} size={"small"}>
              <span className="back_filter_arrow"></span>
              Back to search reports
            </Button>
          </div>
        )}
      </>
    </FiltersLayout>
  );
});
