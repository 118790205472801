import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component, ReactNode } from "react";
import WebFont from "webfontloader";
import { BLUE, DARK_PURPLE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { configureDateAxis, configureValueAxis, legendConfiguration, lineSeriesConfiguration } from "../helpers/configurations";
import { formatValueAxisUnits, getTooltipTextFormat } from "../helpers/helpers";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { dateAxisLabelFormatter_QuarterlyFY, formatDateAxisLabelToQuarterlyFY } from "../helpers/pmoConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  theme: ThemeInt;
  isLoading: boolean;
  seriesList: any;
  currency: string;
  granularity: string;
  market: string;
}

class ComparisonPriceVolumeChart extends Component<Props> {
  chartId = "comparisonPriceVolumeChart";
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { theme, market, seriesList, data, granularity, currency } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    // this.chart.data = data;

    this.chart.legend = legendConfiguration(theme);
    this.chart.colors.list = [color(DARK_PURPLE), color(BLUE)];
    this.chart.legend.itemContainers.template.paddingBottom = 30;

    // Create data chart axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme, true);
    dateAxis.renderer.cellStartLocation = 0.05;
    dateAxis.renderer.cellEndLocation = 0.55;

    dateAxis.renderer.grid.template.strokeWidth = 0;
    dateAxis.renderer.minGridDistance = 30;
    granularity === "quarterly" && dateAxis.renderer.labels.template.adapter.add("text", dateAxisLabelFormatter_QuarterlyFY);

    let highRounded = 0;
    let highPrice = 0;
    const keys = Object.keys(data);

    keys?.map((key: string) => {
      data[key].map((price_rounded: number, price: number) => {
        if (price_rounded > highRounded) highRounded = price_rounded;
        if (price > highPrice) highPrice = price;
      });
    });

    // Creates value axis
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.min = 0;
    valueAxis.numberFormatter.numberFormat = "#a";
    formatValueAxisUnits(valueAxis, market);

    const createSeries = (metric: string, seriesColor: string) => {
      const series = this.chart.series.push(new LineSeries());
      series.data = data[metric];

      const bullet = lineSeriesConfiguration(series, {
        valueY: `price_volume_avg`,
        name: metric?.split("_").join(" "),
      });
      //@ts-ignore
      series.volume = "price_volume";
      series.xAxis = dateAxis;
      series.dataFields.dateX = "date";
      series.tensionY = 1;
      series.yAxis = valueAxis;
      series.stroke = color(seriesColor);
      series.fill = color(seriesColor);
      if (highRounded > 1000000) {
        series.yAxis.numberFormatter.numberFormat = "#.0a";
        series.xAxis.numberFormatter = new NumberFormatter();
        series.xAxis.numberFormatter.numberFormat = "#";
      }
      series.dataFields.categoryY = "price_volume";

      if (granularity === "quarterly") series.dataFields.categoryX = "quarter";
      bullet.adapter.add("tooltipText", (text, target) => {
        const data = target.dataItem?.dataContext;
        if (granularity === "quarterly") {
          //@ts-ignore
          const dateVal = formatDateAxisLabelToQuarterlyFY(data?.date);
          //@ts-ignore
          text = `[font-size: var(--regular_font_size);bold]{name}[/]\n[font-size: var(--regular_font_size)]Price: ${currency}{valueY.formatNumber('#,###.')}[/]\n[font-size: var(--regular_font_size)]Volume: {categoryY.formatNumber('#,###')}[/]\n `;
        } else {
          //@ts-ignore
          text = `[font-size: var(--regular_font_size);bold]{name}[/]\n[font-size: var(--regular_font_size)]Price: ${currency}{valueY.formatNumber('#,###.')}[/]\n[font-size: var(--regular_font_size)]Volume: {categoryY.formatNumber('#,###')}[/]\n `;
          getTooltipTextFormat(granularity);
        }
        return text;
      });
    };
    seriesList?.forEach((val: { name: string; seriesColor: string }) => {
      createSeries(val?.name, val?.seriesColor);
    });
  }

  render(): ReactNode {
    return (
      <div className={"chartWrapper"}>
        <div id={"comparisonPriceVolumeChart"} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default ComparisonPriceVolumeChart;
