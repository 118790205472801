import moment from "moment";
import { ALL_COUNTRIES, ALL_OPTION, ONE_MARKET_REGIONS } from "../../../constants";
import { isCustomDate } from "../../../utils/dateFunctions";

// Todo: Update test to add custom date
export const getDateDependentValues = (
  date_value: string,
  lastDataRefresh?: Date
): { popLabel: string; titleDescription: string; dateValue: string; showPop: boolean } => {
  const isCustomDateString = isCustomDate(date_value);

  const dateCaseString = isCustomDateString ? "Custom date" : date_value;
  const startDate = isCustomDateString
    ? moment(date_value.split(" - ")[0], "DD/MM/YYYY").format("DD-MMM YYYY")
    : lastDataRefresh
    ? moment(lastDataRefresh).startOf("week").format("DD-MMM")
    : moment().subtract(1, "weeks").startOf("week").format("DD-MMM");
  const endDate = isCustomDateString
    ? moment(date_value.split(" - ")[1], "DD/MM/YYYY").format("DD-MMM YYYY")
    : lastDataRefresh
    ? moment(lastDataRefresh).endOf("week").format("DD-MMM")
    : moment().subtract(1, "weeks").endOf("week").format("DD-MMM");

  switch (dateCaseString) {
    case "Last week":
      return {
        popLabel: "WoW",
        titleDescription: `(${startDate} to ${endDate})`,
        dateValue: `last week`,
        showPop: true,
      };

    case "This month":
      return { popLabel: "MoM", titleDescription: "", dateValue: "this month", showPop: true };

    case "Last month":
      return { popLabel: "MoM", titleDescription: "", dateValue: "last month", showPop: true };

    case "This quarter":
      return { popLabel: "QoQ", titleDescription: "", dateValue: "this quarter", showPop: true };

    case "Last quarter":
      return { popLabel: "QoQ", titleDescription: "", dateValue: "last quarter", showPop: true };

    case "This financial year":
      return { popLabel: "", titleDescription: "", dateValue: "this financial year", showPop: false };

    case "Last financial year":
      return { popLabel: "", titleDescription: "", dateValue: "last financial year", showPop: false };

    case "Custom date":
      return { popLabel: "", titleDescription: "", dateValue: `${startDate} - ${endDate}`, showPop: false };

    default:
      return {
        popLabel: "WoW",
        titleDescription: `(${startDate} to ${endDate})`,
        dateValue: `this week`,
        showPop: true,
      };
  }
};

export const getGeographyDependentValues = (
  region: string,
  market: string
): { visitsBreakdownBy: string; showNameplateTooltip: boolean; visitsBreakdownFirstColumn: string } => {
  region = region || ALL_OPTION;
  market = market || ALL_COUNTRIES;
  if (region in ONE_MARKET_REGIONS || (market !== ALL_COUNTRIES && !market.includes(","))) {
    return { visitsBreakdownBy: "model", visitsBreakdownFirstColumn: "Top models", showNameplateTooltip: true };
  } else if ((region !== ALL_OPTION && !region.includes(",")) || market.includes(",")) {
    return { visitsBreakdownBy: "market", visitsBreakdownFirstColumn: "Countries", showNameplateTooltip: false };
  }
  return { visitsBreakdownBy: "region", visitsBreakdownFirstColumn: "Regions", showNameplateTooltip: false };
};
