import { CategoryAxis, ColumnSeries, ILineSeriesDataFields, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, CYAN, PURPLE, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { configureCategoryAxis, configureValueAxis, legendConfiguration } from "../helpers/configurations";
import { formatValueAxisUnits } from "../helpers/helpers";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  theme: ThemeInt;
  chartName: string;
  isLoading: boolean;
  showVolumes: boolean;
  nimTech?: boolean;
}

export class CustomerIntentionClusterChart extends Component<Props> {
  chartId = this.props.chartName;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { data, theme, nimTech, showVolumes } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;

    this.chart.legend = legendConfiguration(theme);
    // Create Axes
    const categoryAxis = this.chart.yAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = nimTech ? "nim" : "grade";
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;
    categoryAxis.renderer.minGridDistance = 10;

    const valueAxis = this.chart.xAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.renderer.opposite = true;
    valueAxis.renderer.labels.template.minWidth = 80;
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text + (showVolumes ? "" : "%");
    });
    if (nimTech && !showVolumes) valueAxis.max = 100;

    const valueAxis1 = this.chart.xAxes.push(new ValueAxis());
    configureValueAxis(valueAxis1, theme);
    valueAxis1.renderer.labels.template.minWidth = 80;
    valueAxis1.renderer.labels.template.adapter.add("text", function (text) {
      return text;
      // + (showVolumes ? "" : "%");
    });
    formatValueAxisUnits(valueAxis);
    if (showVolumes) valueAxis1.numberFormatter.numberFormat = "#.0a";

    const dataFields: Record<string, string> = {
      customerIntention: showVolumes
        ? nimTech
          ? "nim_tech_customer_intention"
          : "customer_intention"
        : nimTech
        ? "nim_tech_customer_intention_ratio"
        : "percentage_intention_take_rate",
      actualSales: showVolumes
        ? nimTech
          ? "nim_tech_actual_sales"
          : "actual_sales"
        : nimTech
        ? "nim_tech_percentage_sales_take_rate"
        : "percentage_sales_take_rate",
      percentage: showVolumes
        ? nimTech
          ? "nim_tech_percentage_sales_vs_intention_volume"
          : "percentage_sales_vs_intention_volume"
        : nimTech
        ? "nim_tech_percentage_sales_vs_intention_take_rate"
        : "percentage_sales_vs_intention_take_rate",
      categoryY: nimTech ? "nim" : "grade",
    };

    this.createSeries("customerIntention", "Customer intention", theme == "dark" ? WHITE : PURPLE, dataFields);
    if (!nimTech) this.createSeries("actualSales", "Actual sales", CYAN, dataFields);
  }

  createSeries(field: string, name: string, seriesColor: string, dataFields: Record<string, string>) {
    const series = this.chart.series.push(new ColumnSeries());
    const valueX = dataFields[field];
    series.dataFields = {
      valueX: valueX,
      ...dataFields,
    } as ILineSeriesDataFields;
    series.name = name;
    series.columns.template.tooltipText = "{name}: [bold]{valueX}[/]";
    series.columns.template.height = percent(80);
    series.sequencedInterpolation = true;
    series.stroke = color(seriesColor);
    series.fill = color(seriesColor);
    if (series.tooltip) {
      series.tooltip.getFillFromObject = false;
      series.tooltip.autoTextColor = false;
      series.tooltip.background.fill = color(WHITE);
      series.tooltip.label.fill = color(BLACK);
    }

    series.columns.template.adapter.add("tooltipText", (value, target) => {
      const data = target?.tooltipDataItem?.dataContext as { [index: string]: string | number };

      let text = `[font-size: var(--regular_font_size);bold]{categoryY}[/]\n`;
      text += `[font-size: var(--regular_font_size)]Customer intention: {customerIntention.formatNumber('#,###.')}${
        this.props.showVolumes ? "" : "%"
      }\n`;
      if (!this.props.nimTech) {
        text += `Actual sales: {actualSales.formatNumber('#,###.')}${this.props.showVolumes ? "" : "%"}\n`;
        text += `Sales vs Intention difference: ${
          this.props.showVolumes && data.percentage_sales_vs_intention_volume > 0
            ? "+"
            : !this.props.showVolumes && data.percentage_sales_vs_intention_take_rate > 0
            ? "+"
            : ""
        }{percentage.formatNumber('#,###.')} ${this.props.showVolumes ? "%" : "PTS"}`;
      }
      return text;
    });
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}
