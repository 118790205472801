import React, { useCallback, useEffect, useState } from "react";
import TableSearchFilter from "../../../../components/Tables/subs/TableSearchFilter";
import DmeoLandingPageTable from "../../../../components/Tables/DmeoLandingPageTable";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getLandingPageEvaluationMetrics } from "../../../../api/dmeoApi";

const LandingPageEvaluationMetrics = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const [filterValue, setFilterValue] = useState("");
  const [searchCriteria, setSearchCriteria] = useState("CONTAINS");

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["landingPageEvaluationMetricsData", history.location.search],
    queryFn: getLandingPageEvaluationMetrics,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  const handleSelectSeachCriteria = useCallback((value: string) => {
    setSearchCriteria(value.toUpperCase());
  }, []);

  const handlefilterValueChange = useCallback((value: string) => {
    setFilterValue(value);
  }, []);

  return (
    <div className="tile">
      <div className="tile-header">Landing Page Evaluation metrics</div>
      <div className="tile-content">
        <TableSearchFilter handleSelectSeachCriteria={handleSelectSeachCriteria} handlefilterValueChange={handlefilterValueChange} />
        <DmeoLandingPageTable filters={searchCriteria} filter={filterValue} data={data ?? []} />
      </div>
    </div>
  );
});

export default LandingPageEvaluationMetrics;
