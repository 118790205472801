import React from "react";

export const TokenDialog = (props: { open: boolean; onCloseClick: () => void; link: string }) => {
  const { open, onCloseClick, link } = props;
  return (
    <div className={`popup release_popup ${open ? "show" : ""}`}>
      <div className="popupModal ">
        <button className="closePopup" onClick={onCloseClick}>
          ╳
        </button>

        <div className="data_message">{link}</div>
      </div>
    </div>
  );
};
