import {
  SET_ARIYA_DEPOSIT_TOTALS,
  SET_ARIYA_HANDRAISERS,
  SET_ARIYA_PREORDER,
  SET_ARIYA_VISITS,
  SET_GLOBAL_PAID_ORGANIC,
  SET_GLOBAL_VC,
  SET_GLOBAL_VC_PREVIOUS,
  SET_GLOBAL_VLP,
  SET_GLOBAL_VLP_PREVIOUS,
  SET_GLOBAL_WP,
  SET_GLOBAL_WP_PREVIOUS,
  SET_HANDRAISERS_NAE,
  SET_ONLINE_PREORDER,
  SET_POPULAR_CONTENT,
  SET_POPULAR_MOMENTS,
  SET_SEARCH_INTEREST,
  SET_SEARCH_INTEREST_VOLUME,
  SET_SOCIAL_NETWORKS,
} from "../actions/actionTypes";

const initialState = {
  visits: { chart_data: {}, count_data: {} },
  handraisers: { chart_data: {}, count_data: {} },
  preorder: { chart_data: {}, count_data: {} },
  deposit_total: { chart_data: {}, count_data: {} },
  search_interest: { data: [], updated: "" },
  search_interest_volume: { data: [], updated: "" },
  global_traffic: { vlp: {}, vlp_previous: {}, vc: {}, vc_previous: {}, wp: {}, wp_previous: {}, paid_organic: {} },
  popular: { content: {}, moments: {} },
  social_networks: {},
  handraisers_nae: {},
  online_preorder: {},
};

const ariyaReducer = (
  state = initialState,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case SET_ARIYA_VISITS:
      return { ...state, visits: action.payload };

    case SET_ARIYA_HANDRAISERS:
      return { ...state, handraisers: action.payload };

    case SET_ARIYA_PREORDER:
      return { ...state, preorder: action.payload };

    case SET_ARIYA_DEPOSIT_TOTALS:
      return { ...state, deposit_total: action.payload };

    case SET_SEARCH_INTEREST:
      return { ...state, search_interest: action.payload };

    case SET_SEARCH_INTEREST_VOLUME:
      return { ...state, search_interest_volume: action.payload };

    case SET_GLOBAL_VLP:
      return { ...state, global_traffic: { ...state.global_traffic, vlp: action.payload } };

    case SET_GLOBAL_VLP_PREVIOUS:
      return { ...state, global_traffic: { ...state.global_traffic, vlp_previous: action.payload } };

    case SET_GLOBAL_VC:
      return { ...state, global_traffic: { ...state.global_traffic, vc: action.payload } };

    case SET_GLOBAL_VC_PREVIOUS:
      return { ...state, global_traffic: { ...state.global_traffic, vc_previous: action.payload } };

    case SET_GLOBAL_WP:
      return { ...state, global_traffic: { ...state.global_traffic, wp: action.payload } };

    case SET_GLOBAL_WP_PREVIOUS:
      return { ...state, global_traffic: { ...state.global_traffic, wp_previous: action.payload } };

    case SET_GLOBAL_PAID_ORGANIC:
      return { ...state, global_traffic: { ...state.global_traffic, paid_organic: action.payload } };

    case SET_POPULAR_CONTENT:
      return { ...state, popular: { ...state.popular, content: action.payload } };

    case SET_POPULAR_MOMENTS:
      return { ...state, popular: { ...state.popular, moments: action.payload } };

    case SET_SOCIAL_NETWORKS:
      return { ...state, social_networks: action.payload };

    case SET_HANDRAISERS_NAE:
      return { ...state, handraisers_nae: action.payload };

    case SET_ONLINE_PREORDER:
      return { ...state, online_preorder: action.payload };

    default:
      return state;
  }
};

export default ariyaReducer;
