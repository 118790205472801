import { getEuropeActivationParameters } from "./europeActivationParameters";
import { fetchDataV2, newApiFetchV2, newPostDataV2 } from "./utils";

export const getEuropeActivationV2Params = () => {
  const activationParams = getEuropeActivationParameters();

  return {
    start_date: activationParams.start_date,
    end_date: activationParams.end_date,
    market: activationParams.market,
    market_groups: activationParams.market_groups,
    region: activationParams.region,
    brand: activationParams.brand,
    model: activationParams.model,
    sales_channel: activationParams.sales_channel,
    dealers: activationParams.dealers,
  };
};

export const getActivationLastDataRefresh = () => fetchDataV2("/europe-activation-ratio-reporting/get-last-refresh-date", {});

export const getActivationData = (activationBreakdown: string, cumulative = false) =>
  newPostDataV2(
    `/europe-activation-ratio-reporting/get-activation-data`,
    { ...getEuropeActivationParameters(), activation_breakdown: activationBreakdown, cumulative },
    `Europe_activation_${activationBreakdown}`
  );

export const getCockpitActivationData = (activationBreakdown: string, cumulative = false) => {
  const activationParams = getEuropeActivationParameters();
  const isCockpit = window.location.href.includes("ccs_cockpit");

  return newPostDataV2(
    `/europe-activation-ratio-reporting/get-activation-data`,
    isCockpit
      ? {
          ...activationParams,
          activation_breakdown: activationBreakdown,
          cumulative,
          sales_channel: ["Private"],
          market_groups: activationParams.region[0] === "Europe" ? ["G8"] : [],
          region: activationParams.region[0] === "Japan" || activationParams.region[0] === "North America" ? [] : activationParams.region,
          comparison_type: "year",
          market:
            activationParams.region[0] === "Japan"
              ? [activationParams.region[0]]
              : activationParams.market[0] === "North America"
              ? [activationParams.market[0]]
              : activationParams.market,
        }
      : {
          ...activationParams,
          cumulative,
          activation_breakdown: activationBreakdown,
        },
    `Europe_activation_${activationBreakdown}`
  );
};

export const getCockpitActivationDataComparison = (activationBreakdown: string, cumulative = false) => {
  const activationParams = getEuropeActivationParameters();
  const isCockpit = window.location.href.includes("ccs_cockpit");

  return newPostDataV2(
    `/europe-activation-ratio-reporting/get-activation-comparison-data`,
    isCockpit
      ? {
          ...activationParams,
          activation_breakdown: activationBreakdown,
          cumulative,
          sales_channel: ["Private"],
          market_groups: activationParams.region[0] === "Europe" ? ["G8"] : [],
          region: activationParams.region[0] === "Japan" || activationParams.region[0] === "North America" ? [] : activationParams.region,
          comparison_type: "year",
          market:
            activationParams.region[0] === "Japan"
              ? [activationParams.region[0]]
              : activationParams.market[0] === "North America"
              ? [activationParams.market[0]]
              : activationParams.market,
        }
      : {
          ...activationParams,
          cumulative,
          activation_breakdown: activationBreakdown,
        },
    `Europe_activation_${activationBreakdown}`
  );
};

export const getEuropeActivationOverall = (isGraph: boolean, cumulative: boolean) => {
  return newApiFetchV2(
    `/europe-activation-ratio-reporting/get-overall-and-total-activation-breakdown-data?is_graph=${isGraph}&cumulative=${cumulative}`,
    getEuropeActivationV2Params()
  );
};

export const getEuropeActivationByCountry = (isGraph: boolean, cumulative: boolean) => {
  return newApiFetchV2(
    `/europe-activation-ratio-reporting/get-country-activation-breakdown-data?is_graph=${isGraph}&cumulative=${cumulative}`,
    getEuropeActivationV2Params()
  );
};

export const getEuropeActivationByModel = (isGraph: boolean, cumulative: boolean) => {
  return newApiFetchV2(
    `/europe-activation-ratio-reporting/get-model-activation-breakdown-data?is_graph=${isGraph}&cumulative=${cumulative}`,
    getEuropeActivationV2Params()
  );
};

export const getEuropeActivationByDealer = (isGraph: boolean, cumulative: boolean) => {
  return newApiFetchV2(
    `/europe-activation-ratio-reporting/get-dealer-activation-breakdown-data?is_graph=${isGraph}&cumulative=${cumulative}`,
    getEuropeActivationV2Params()
  );
};
