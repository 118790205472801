import {
  SET_FIXED_DATA_BY_QUARTER,
  SET_FMI_BY_MODEL_FMI_BY_QUARTER,
  SET_FMI_BY_QUARTER,
  SET_MEDIA_MIX_DATA_BY_QUARTER,
  SET_MTP_CATEGORY_DATA_BY_QUARTER,
  SET_MTP_CATEGORY_HIDE_YR_SCNR_RV,
  SET_MTP_SPEND,
  SET_SPEND_VS_TRAFFIC_MARKET_DATA,
  SET_SPEND_VS_TRAFFIC_MODEL_DATA,
  SET_SPEND_VS_TRAFFIC_RESPONSE_ERROR,
  SET_STRATEGIC_CE_DATA_BY_QUARTER,
  SET_STRATEGIC_DEALER_SUPPORT_DATA_BY_QUARTER,
} from "../actions/actionTypes";

const initialState: {
  midas_fmi_by_quarter: any[];
  midas_mtp_spend: any[];
  midas_fmi_by_model_fmi_by_quarter: any[];
  midas_mtp_category_data_by_quarter: any[];
  midas_strategic_dealer_support_data_by_quarter: any[];
  midas_strategic_ce_data_by_quarter: any[];
  midas_fixed_data_by_quarter: any[];
  midas_mtp_category_hide_yr_scnr_rv: boolean;
  midas_spend_vs_traffic_market_data: any[];
  midas_spend_vs_traffic_model_data: any[];
  response_error: any[];
} = {
  midas_fmi_by_quarter: [],
  midas_mtp_spend: [],
  midas_fmi_by_model_fmi_by_quarter: [],
  midas_mtp_category_data_by_quarter: [],
  midas_strategic_dealer_support_data_by_quarter: [],
  midas_strategic_ce_data_by_quarter: [],
  midas_fixed_data_by_quarter: [],
  midas_spend_vs_traffic_market_data: [],
  midas_spend_vs_traffic_model_data: [],
  midas_mtp_category_hide_yr_scnr_rv: false,
  response_error: [],
};

const midasReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_FMI_BY_QUARTER:
      return { ...state, midas_fmi_by_quarter: action.payload };

    case SET_MTP_SPEND:
      return { ...state, midas_mtp_spend: action.payload };

    case SET_FMI_BY_MODEL_FMI_BY_QUARTER:
      return { ...state, midas_fmi_by_model_fmi_by_quarter: action.payload };

    case SET_MEDIA_MIX_DATA_BY_QUARTER:
      return { ...state, midas_media_mix_data_by_quarter: action.payload };

    case SET_MTP_CATEGORY_DATA_BY_QUARTER:
      return { ...state, midas_mtp_category_data_by_quarter: action.payload };

    case SET_STRATEGIC_DEALER_SUPPORT_DATA_BY_QUARTER:
      return { ...state, midas_strategic_dealer_support_data_by_quarter: action.payload };

    case SET_STRATEGIC_CE_DATA_BY_QUARTER:
      return { ...state, midas_strategic_ce_data_by_quarter: action.payload };

    case SET_FIXED_DATA_BY_QUARTER:
      return { ...state, midas_fixed_data_by_quarter: action.payload };

    case SET_SPEND_VS_TRAFFIC_MODEL_DATA:
      return { ...state, midas_spend_vs_traffic_model_data: action.payload };

    case SET_SPEND_VS_TRAFFIC_MARKET_DATA:
      return { ...state, midas_spend_vs_traffic_market_data: action.payload };

    case SET_MTP_CATEGORY_HIDE_YR_SCNR_RV:
      return { ...state, midas_mtp_category_hide_yr_scnr_rv: action.payload };

    case SET_SPEND_VS_TRAFFIC_RESPONSE_ERROR:
      return { ...state, response_error: action.payload };

    default:
      return state;
  }
};

export default midasReducer;
