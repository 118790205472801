import React, { useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { setPageName } from "../../../actions";
import {
  fetchServiceLeadBookingFormsVisitsVsServiceLeads,
  fetchServiceLeadBookingFormVisitsByChannelOrTrend,
  fetchServiceLeadConversionRate,
  fetchServiceLeadServiceBookingFormsOpened,
  fetchServiceLeadServiceBookingFormVisits,
  fetchServiceLeadServiceLeads,
  fetchServiceLeadServiceRecommendationVisits,
} from "../../../actions/useOwnershipTypes";
import { NoDataMsg } from "../../../components/AppMessages";
import LineTrendChart from "../../../components/Charts/UseOwnership/LineTrendChart";
import UseOwnershipDonutChart from "../../../components/Charts/UseOwnership/UseOwnershipDonutChart";
import UseOwnershipStackedBarChart from "../../../components/Charts/UseOwnership/UseOwnershipStackedBarChart";
import UseOwnershipFilters from "../../../components/Filters/UseOwnershipFilters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import { ToggleSwitch } from "../../../components/Toogle";
import { GRADE_MIX_COLORS, LIGHT_YELLOW, PURE_ORANGE } from "../../../constants";
import { USE_OWNERSHIP_BTN_FILTERS } from "../../../constants/useOwnershipConstants";
import { DashboardBannerContext, ThemeContext } from "../../../context";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import MetricTile from "../components/MetricTile";
import RadioOptions from "./components/RadioOptions";

const ServiceLead = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { theme } = useContext(ThemeContext);
  const { history } = props;
  const { setShowBanner, setBannerText } = useContext(DashboardBannerContext);
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.use_ownership_last_data_refresh);
  const {
    service_lead_service_recommendation_visits: serviceRecommendationVisits,
    service_lead_service_booking_form_visits: serviceBookingFormVisits,
    service_lead_conversion_rate: conversionRate,
    service_lead_service_leads: serviceLeads,
    service_lead_booking_forms_visits_vs_service_leads: bookingFormsVisitsVsServiceLeads,
    service_lead_booking_form_visits_by_channel_or_trend: bookingFormVisitsByChannelOrTrend,
    service_lead_service_booking_forms_opened: bookingFormsOpened,
  } = useSelector((state: RootStateOrAny) => state.use_ownership);
  const {
    service_lead_service_recommendation_visits: serviceRecommendationVisitsLoading,
    service_lead_service_booking_form_visits: serviceBookingFormVisitsLoading,
    service_lead_conversion_rate: conversionRateLoading,
    service_lead_service_leads: serviceLeadsLoading,
    service_lead_booking_forms_opened_vs_service_leads: bookingFormsVisitsVsServiceLeadsLoading,
    service_lead_booking_form_visits_by_channel_or_trend: bookingFormVisitsByChannelOrTrendLoading,
  } = useSelector((state: RootStateOrAny) => state.loading);

  const [selectedRadioOption, setSelectedRadioOption] = useState("By channel");
  const [toogleActive, setToggleActive] = useState(true);

  useEffect(() => {
    dispatch(setPageName("USE Ownership Dashboard - Service Lead"));
    setShowBanner(true);
    setBannerText(
      " Please note that data availability differs across markets in scope. N/A is displayed when data is not available. For more information on data availability by market view the complete data dictionary by clicking on the book sing on bottom left."
    );

    return () => {
      setShowBanner(false);
      setBannerText("");
    };
  }, []);

  useEffect(() => {
    dispatch(fetchServiceLeadServiceBookingFormVisits());
    dispatch(fetchServiceLeadServiceLeads());
    dispatch(fetchServiceLeadBookingFormsVisitsVsServiceLeads());
    dispatch(fetchServiceLeadServiceRecommendationVisits());
    dispatch(fetchServiceLeadConversionRate());
    dispatch(fetchServiceLeadServiceBookingFormsOpened());
  }, [history.location.search]);

  useEffect(() => {
    const groupBy = selectedRadioOption == "By channel" ? "channel" : "entry_page";
    const timeline = toogleActive;
    dispatch(fetchServiceLeadBookingFormVisitsByChannelOrTrend(groupBy, timeline));
  }, [history.location.search, selectedRadioOption, toogleActive]);

  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Use Ownership", page: props.history.location.pathname });
  }, [window.location]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<UseOwnershipFilters filterBtns={USE_OWNERSHIP_BTN_FILTERS} page="Service Lead" />}
        dataDocumentation="use_ownership"
        lastDataRefresh={lastDataRefresh}
        pageTitle={<PageTitle dataDocumentation="use_ownership/service_lead" />}
      >
        <div className="service-lead-page">
          <div className="row">
            <div className="section-title tile">
              <div>Service booking</div>
            </div>
            <div className="service-booking-content">
              <div className="service-booking-content-row">
                <div className="metric-chart-container">
                  <div className="gap-10">
                    <MetricTile
                      title="Service recommendation visits"
                      titleTooltipText={""}
                      mainValue={serviceRecommendationVisits?.Current?.service_recommendation_page_visits}
                      yoyValue={serviceRecommendationVisits?.YoY?.perc_service_recommendation_page_visits}
                      popValue={serviceRecommendationVisits?.PoP?.perc_service_recommendation_page_visits}
                      showPoP={true}
                      isLoading={serviceRecommendationVisitsLoading}
                    />
                    <MetricTile
                      title="Service booking form visits"
                      titleTooltipText={"Visits on Service Booking Form"}
                      mainValue={serviceBookingFormVisits?.Current?.visits}
                      yoyValue={serviceBookingFormVisits?.YoY?.perc_visits}
                      popValue={serviceBookingFormVisits?.PoP?.perc_visits}
                      showPoP={true}
                      isLoading={serviceBookingFormVisitsLoading}
                    />
                    <MetricTile
                      title="Service booking forms opened"
                      titleTooltipText={"Users who initiate the service booking form"}
                      mainValue={bookingFormsOpened?.Current?.service_booking_forms_opened}
                      yoyValue={bookingFormsOpened?.YoY?.perc_service_booking_forms_opened}
                      popValue={bookingFormsOpened?.PoP?.perc_service_booking_forms_opened}
                      showPoP={true}
                      isLoading={serviceBookingFormVisitsLoading}
                    />
                    <MetricTile
                      title="Service leads"
                      titleTooltipText={"Users who complete the service booking form"}
                      mainValue={serviceLeads?.Current?.leads}
                      yoyValue={serviceLeads?.YoY?.perc_leads}
                      popValue={serviceLeads?.PoP?.perc_leads}
                      showPoP={true}
                      isLoading={serviceLeadsLoading}
                    />
                  </div>
                </div>
                <div className="service-chart-tile tile service-booking-form">
                  <div className="title-container">
                    <div className="title">Service booking form visits {selectedRadioOption.toLocaleLowerCase()}</div>
                    <Tooltip
                      position="top-end"
                      className={"normal_tippy"}
                      trigger="mouseenter"
                      animation="none"
                      interactive={true}
                      delay={1}
                      hideDelay={1}
                      duration={1}
                      title={
                        selectedRadioOption == "By channel"
                          ? "Visits on Service Booking Form with the breakdown by channel. Please note that channels vary across diferent markets."
                          : "Visits on Service Booking Form with the breakdown by channel. Entry point is considered from which part of the website the users came before landing on the Service Booking form. Please note that if entry point is Service Booking it means that the form was the first contact of the users with the website.s"
                      }
                      size={"small"}
                    >
                      <span className="info_tooltip" />
                    </Tooltip>
                  </div>
                  <div className="toggles-container">
                    <RadioOptions
                      selectedOption={selectedRadioOption}
                      options={["By channel", "By entry page"]}
                      onHandleRadioClick={(e) => setSelectedRadioOption(e.target.value)}
                    />
                    <ToggleSwitch
                      activeToggleLabel="Timeline"
                      inactiveToggleLabel="Cumulative"
                      active={toogleActive}
                      handleToggleClick={() => setToggleActive((prevState) => !prevState)}
                    />
                  </div>
                  <div className="chart">
                    {toogleActive ? (
                      <LineTrendChart
                        chartId="serviceBookingFormVisitsByChannelChart"
                        isLoading={bookingFormVisitsByChannelOrTrendLoading}
                        theme={theme}
                        data={bookingFormVisitsByChannelOrTrend?.data ?? []}
                        seriesList={bookingFormVisitsByChannelOrTrend?.series ?? []}
                        seriesColors={GRADE_MIX_COLORS}
                      />
                    ) : (
                      <UseOwnershipDonutChart
                        chartId="cumulativeServiceBookingFormVisitChart"
                        valueField="percentage"
                        categoryField={selectedRadioOption == "By channel" ? "channel" : "entry_page"}
                        theme={theme}
                        data={bookingFormVisitsByChannelOrTrend?.data}
                        isLoading={bookingFormVisitsByChannelOrTrendLoading}
                        showLegend={true}
                        hideLabels={true}
                        innerRadius={30}
                        radius={100}
                        showTotalValueInTooltip={true}
                        totalValueInTooltipKey="visits"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="service-booking-content-row">
                <div className="metric-chart-container">
                  <div className="tile">
                    <MetricTile
                      title="Service booking form visits to Service Leads"
                      titleTooltipText={"Conversion rate is given by the Service Leads divided by the service booking form visits."}
                      mainValue={conversionRate?.Current?.conversion_rate}
                      yoyValue={conversionRate?.YoY?.perc_conversion_rate}
                      popValue={conversionRate?.PoP?.perc_conversion_rate}
                      showPoP={true}
                      isLoading={conversionRateLoading}
                      isPercentage={true}
                    />
                  </div>
                  <div className="service-chart-tile tile service-booking-vs-leads">
                    <div className="title-container">
                      <div className="title">Service booking vs Service Leads</div>
                    </div>
                    <div className="chart">
                      <UseOwnershipStackedBarChart
                        theme={theme}
                        chartId="serviceBookingVsServiceLeadsChart"
                        isLoading={bookingFormsVisitsVsServiceLeadsLoading}
                        seriesList={bookingFormsVisitsVsServiceLeads?.series ?? []}
                        data={bookingFormsVisitsVsServiceLeads?.data ?? []}
                        seriesColors={[PURE_ORANGE, LIGHT_YELLOW]}
                      />
                    </div>
                  </div>
                </div>
                <div className="metric-chart-container">
                  <div className="tile">
                    <MetricTile title="Services" mainValue={null} yoyValue={null} popValue={null} showPoP={true} isLoading={false} />
                  </div>
                  <div className="service-chart-tile tile service-booking-vs-leads">
                    <div className="title">No Services vs No Leads</div>
                    <div className="chart">
                      <NoDataMsg />
                      {/* <MultipleBarLineTrendChart
                        chartId="noServicesVsLeadsChart"
                        data={noServicesVsLeadsData.data}
                        seriesList={noServicesVsLeadsData.series}
                        theme={theme}
                        isLoading={false}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="section-title">
              <div className="tile">Service booking conversion</div>
              <div className="tile">Revenue</div>
            </div>
            <div className="metrics-container">
              <MetricTile
                title="Show up rates to service"
                mainValue={null}
                yoyValue={null}
                popValue={null}
                showPoP={true}
                isPercentage={true}
                isLoading={false}
              />
              <MetricTile
                title="Total service YTD/CPRO"
                mainValue={null}
                yoyValue={null}
                popValue={null}
                showPoP={true}
                isLoading={false}
              />
            </div>
            <div className="service-chart-tile tile">
              <div className="title">Service Leads vs Show ups</div>
              <div className="chart">
                <NoDataMsg />
                {/* <UseOwnershipDonutChart
                  chartId="ratingAccountInfoChart"
                  valueField="score"
                  categoryField="name"
                  theme={theme}
                  data={dummyDonutData}
                  isLoading={false}
                  showLegend={true}
                  hideLabels={true}
                  innerRadius={30}
                /> */}
              </div>
            </div>

            <div className="service-chart-tile tile">
              <div className="title">CPRO Revenue Trend</div>
              <div className="chart">
                <NoDataMsg />
                {/* <LineTrendChart
                  chartId="cproRevenueTrendChart"
                  isLoading={false}
                  theme={theme}
                  data={cproData.data}
                  seriesList={cproData.series}
                  seriesColors={[WHITE]}
                /> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="section-title">
              <div className="tile">Service retention</div>
            </div>
            <div className="metrics-container">
              <MetricTile
                title="Users who have at least 2 services within 24 months"
                mainValue={null}
                yoyValue={null}
                popValue={null}
                showPoP={true}
                isLoading={false}
              />
              <MetricTile title="QVOC for service" mainValue={null} yoyValue={null} popValue={null} showPoP={true} isLoading={false} />
            </div>
            <div className="service-chart-tile tile">
              <div className="title">Service booked by engagement period</div>
              <div className="chart">
                <NoDataMsg />
                {/* <UseOwnershipStackedBarChart
                  theme={theme}
                  chartId="serviceBookedByEngagementPeriodChart"
                  isLoading={false}
                  seriesList={serviceBookedByEngagementPeriodData.series}
                  data={serviceBookedByEngagementPeriodData.data}
                  seriesColors={[PURPLE, CYAN, PURE_ORANGE]}
                /> */}
              </div>
            </div>
            <div className="service-chart-tile tile">
              <div className="title">VOC at service vs VOC service form</div>
              <div className="metric-and-chart">
                <div className="chart-metric">
                  <div>
                    {/* <div>4.1/5</div> */}
                    <div>n/a</div>
                    <div className="legend-container">
                      <div className="legend-line-container">
                        <div className="circle"></div>
                        <div className="line"></div>
                      </div>
                      <div className="legend-text">VOC service form</div>
                    </div>
                  </div>
                  <div>
                    {/* <div>4.1/5</div> */}
                    <div>n/a</div>
                    <div className="legend-container">
                      <div className="legend-line-container">
                        <div className="circle"></div>
                        <div className="line"></div>
                      </div>
                      <div className="legend-text">VOC at service</div>
                    </div>
                  </div>
                </div>
                <div className="chart">
                  <NoDataMsg />
                  {/* <LineTrendChart
                    theme={theme}
                    chartId="vocAtServiceChart"
                    isLoading={false}
                    seriesList={VOCAtServiceDataData.series}
                    data={VOCAtServiceDataData.data}
                    seriesColors={[PURE_ORANGE, CYAN]}
                    hideLegend={true}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default ServiceLead;
