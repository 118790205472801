import { ErrorBoundary } from "@sentry/react";
import { format, parse } from "date-fns";
import _ from "lodash";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { deleteSpecificParameter, setParameters, setSpecificParameter } from "../../actions";
import { ALL_COUNTRIES, ALL_OPTION, ALL_OPTION_NO_SPACE, DEFAULT_CCS_ANALYSIS_PARAMETERS } from "../../constants";
import { FilterContext } from "../../context";
import { checkConsecutiveMonths } from "../../pages/CCS Vital Signs/subs/utils";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { currentDefaultFiltersSet, defaultFiltersSet } from "../../utils/utilityFunctions";
import { ErrorMsg } from "../AppMessages";
import { FiltersLayout } from "../Layouts";
import { DefaultFilter, ModelFilter, MultiDateRangeWithPresets, MultiSelectFilter } from "./common";
import { closeFilterOptions } from "./subs/helpers";

const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November"];

const convertModeltoUpercase = (obj: Record<string, string>) => {
  if (obj["model"] != ALL_OPTION_NO_SPACE) {
    obj["model"] = obj["model"].toUpperCase();
  }
  return obj;
};

export const TopFlopFilters = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { history } = props;
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);
  const filters = useSelector((state: RootStateOrAny) => state.filters.top_flop_filters);
  const params = useSelector((state: RootStateOrAny) => state.top_flop_parameters);
  const {
    dates: dateRangeParamValue,
    region: regionParamValue,
    brand: brandParamValue,
    market: marketParamValue,
    services: serviceNameParamValue,
    service_group: serviceGroupParamValue,
    service_type: serviceTypeParamValue,
    service_trigger: serviveTriggerParamValue,
    specific_service: specificServiceParamValue,
    specific_group: specificGroupParamValue,
    specific_type: specificTypeParamValue,
    specific_trigger: specificTriggerParamValue,
    uid: uidParamValue,
  } = convertModeltoUpercase(params);
  const { brand: brandParam, model: modelParamValue } = useSelector((state: RootStateOrAny) => state.parameters);
  const [marketList, setRegionList] = useState<Array<string>>([]);
  const [modelList, setModelList] = useState<Array<string>>([]);
  const [serviceGroupList, setServiceGroupList] = useState<Array<string>>([]);
  const [serviceNameList, setServiceNameList] = useState<Array<string>>([]);
  const [serviceTypeList, setServiceTypeList] = useState<Array<string>>([]);
  const [serviceTriggerList, setServiceTriggerList] = useState<Array<string>>([]);
  const [uidList, setUidList] = useState<Array<string>>([]);
  const [modelFilterValue, setModelFilterValue] = useState<string>("");
  const [serviceNameFilterValue, setServiceNameFilterValue] = useState<string>("");
  const [uidFilterValue, setUidFilterValue] = useState<string>("");
  const [dateRangeFilterValue, setDateRangeFilterValue] = useState<string>("");
  const [brandFilterValue, setBrandFilterValue] = useState<string>(brandParam);
  const [regionParam, setRegionParam] = useState<string>(regionParamValue);
  const [marketParam, setMarketParam] = useState<string>(marketParamValue);
  const [modelParam, setModelParam] = useState<string>(modelParamValue);

  const brands = ["All", "Nissan", "Infiniti"];

  const [geographyValue, setGeographyValue] = useState<string>("");

  const [showMonthyDatesToggleState, setShowMonthyDatesToggleState] = useState(true);

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("Top/Flop connected car services");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_CCS_ANALYSIS_PARAMETERS));
      currentDefaultFiltersSet("Top/Flop connected car services");
    }
  }, []);

  useEffect(() => {
    if (filters.dates) {
      const selectedDatesArr = dateRangeParamValue && dateRangeParamValue != "" ? dateRangeParamValue.split(",") : [];
      const invalidDates: any = [];
      const selectableMonths = Array.from(
        new Set(
          filters.dates.map((date: string) => {
            const dateArr = date.split(" ");
            const month = dateArr.at(-2);
            const year = dateArr.at(-1);
            const dateObj = new Date(`${month} 01, ${year}`);
            if (dateObj >= new Date("May 01, 2022") && filters.dates.includes(`${month} ${year}`)) {
              return `${month} ${year}`;
            }
            return null;
          })
        )
      ).filter((value: any) => value != null);
      const selectableDates = Array.from(new Set([...filters.dates, ...selectableMonths.filter((item: any) => item != null)]));
      selectedDatesArr.forEach((date) => {
        !selectableDates.includes(date) && invalidDates.push(date);
      });
      (selectedDatesArr.length == 0 || invalidDates.length > 0) &&
        dispatch(setSpecificParameter("dates", selectableMonths.slice(-2).join(",")));
    }
  }, [dateRangeParamValue, filters]);

  // Usage tracking
  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "CCS", page: "Top/Flop" });
  }, [location.pathname]);

  //set brand value
  useEffect(() => {
    setBrandFilterValue(brandParamValue);
  }, [brandParamValue]);

  useEffect(() => {
    dispatch(setSpecificParameter("model", modelParamValue));
  }, [modelParamValue]);

  const groupedDateRangeData = useMemo(() => {
    if (filters.dates) {
      const years: Array<string> = Array.from(new Set(filters.dates.map((date: string) => date.split(" ").at(-1))));
      const monthsObj: any = {};
      const datesData = showMonthyDatesToggleState
        ? filters.dates.filter((date: string) => !date.includes("week"))
        : filters.dates.filter((date: string) => date.includes("week"));
      // get months and children
      datesData.map((date: string) => {
        if (!date.includes("week")) {
          const dateObj = parse(date, "MMMM y", new Date(2018, 0, 1));
          const month = format(dateObj, "MMMM");
          const year = String(dateObj.getFullYear());
          if (month && year && !(`${month} ${year}` in monthsObj)) {
            monthsObj[`${month} ${year}`] = { child: [], month: month, hideCheckbox: false };
          } else {
            monthsObj[`${month} ${year}`] = { ...monthsObj[`${month} ${year}`], hideCheckbox: false };
          }
        } else {
          const dateArr = date.split(" ");
          const month = dateArr.at(-2);
          const year = dateArr.at(-1);
          const dateObj = new Date(`${month} ${year}`);
          if (month && year && `${month} ${year}` in monthsObj) {
            monthsObj[`${month} ${year}`] = {
              ...monthsObj[`${month} ${year}`],
              child: [...monthsObj[`${month} ${year}`]["child"], { week: dateArr.slice(0, 2).join(" "), month: month, year: year }],
            };
          } else if (month && year && !(`${month} ${year}` in monthsObj)) {
            monthsObj[`${month} ${year}`] = {
              child: [{ week: dateArr.slice(0, 2).join(" "), month: month, year: year }],
              month: month,
              hideCheckbox: true,
            };
          }
        }
      });

      const dataObjArr: any = [];

      years.map((year: string) => {
        const children = [];
        for (const [key, value] of Object.entries(monthsObj)) {
          if (key.includes(year)) {
            children.push(value);
          }
        }
        dataObjArr.push({ year: year, child: children, hideCheckbox: true });
      });

      return dataObjArr;
    }
    return [];
  }, [filters.dates, showMonthyDatesToggleState]);

  // Updates date range filter value
  useEffect(() => {
    if (filters.dates) {
      const selectedDateArray: Array<string> =
        dateRangeParamValue === ""
          ? _.range(11).map((number) => `${MONTHS[number]} ${new Date().getFullYear()}`)
          : dateRangeParamValue?.split(",");
      const isWeeks = selectedDateArray[0].includes("week");
      const availableDateOptions: Array<string> = filters.dates?.map((date: string) => {
        if (date.includes("week")) {
          const month_year = date.split(" ").slice(2).join(" ");
          return parse(month_year, "MMMM y", new Date(2018, 0, 1));
        } else {
          return format(parse(date, "MMMM y", new Date(2018, 0, 1)), "MMMM yyyy");
        }
      });
      const checkConsecutive = checkConsecutiveMonths(selectedDateArray, availableDateOptions);
      const dateRangeInputValue =
        (!isWeeks && selectedDateArray.length > 1) || (isWeeks && selectedDateArray.length > 2)
          ? checkConsecutive.isConsecutive
            ? checkConsecutive.consecutiveDateRange
            : `${selectedDateArray?.length} ${isWeeks ? "weeks" : "months"} selected`
          : dateRangeParamValue;

      setDateRangeFilterValue(dateRangeInputValue);
    }
  }, [dateRangeParamValue, filters.dates]);

  //If brand doest exist in list set filter value to ALL
  useEffect(() => {
    if (brands.length && !brands.includes(brandParam)) dispatch(setSpecificParameter("brand", ALL_OPTION_NO_SPACE));
  }, [brands, brandParam, filters]);

  // Set geography value
  useEffect(() => {
    setMarketParam(marketParamValue);
    setRegionParam(regionParamValue);
    setGeographyValue(marketParamValue === ALL_COUNTRIES ? regionParamValue : marketParamValue);
  }, [regionParamValue, marketParamValue]);

  useEffect(() => {
    if (filters?.market?.length > 1 && regionParamValue != "All" && !filters?.market?.includes(regionParamValue)) {
      dispatch(setSpecificParameter("region", "All"));
    }
  }, [regionParamValue, filters?.market]);

  useEffect(() => {
    if (filters?.market?.length > 1 && marketParamValue != "All countries" && !filters?.market?.includes(marketParamValue)) {
      dispatch(setSpecificParameter("market", "All countries"));
    }
  }, [marketParamValue, filters?.market]);

  //load models for the brand and geography value
  useEffect(() => {
    if (filters.model) {
      setModelList(filters.model);
    }
  }, [filters, geographyValue, brandParam]);

  useEffect(() => {
    if (filters) {
      if (filters.services) {
        const serviceNameFilters = filters.services.sort();
        setServiceNameList([...serviceNameFilters]);
      }
    }
  }, [filters, serviceNameParamValue]);

  useEffect(() => {
    if (filters) {
      if (filters.service_group) {
        const serviceGroupFilters = filters.service_group.sort();
        setServiceGroupList([ALL_OPTION_NO_SPACE, ...serviceGroupFilters]);
      }
    }
  }, [filters, serviceGroupParamValue]);

  useEffect(() => {
    if (filters) {
      if (filters.service_type) {
        const serviceTypeFilters = filters.service_type;
        setServiceTypeList([ALL_OPTION_NO_SPACE, ...serviceTypeFilters]);
      }
    }
  }, [filters, serviceTypeParamValue]);

  useEffect(() => {
    if (filters) {
      if (filters.service_trigger) {
        const serviceTriggerFilters = filters.service_trigger;
        setServiceTriggerList([ALL_OPTION_NO_SPACE, ...serviceTriggerFilters]);
      }
    }
  }, [filters, serviceTypeParamValue]);

  useEffect(() => {
    if (filters) {
      if (filters.uid) {
        const uidFilters = filters.uid;
        setUidList(uidFilters.map((uid: number) => String(uid)));
      }
    }
  }, [filters, uidParamValue]);

  //if selected models do not exist in model list reset to all
  useEffect(() => {
    if (modelList && modelList.length) {
      if (modelParamValue !== ALL_OPTION_NO_SPACE) {
        const modelsMatch = modelParamValue.split(",").every((model: string) => {
          return modelList.includes(model);
        });
        if (modelList.length > 0 && !modelsMatch) {
          dispatch(setSpecificParameter("model", ALL_OPTION_NO_SPACE));
        }
      }
    }
  }, [modelList, modelParamValue, dispatch]);

  //if selected models do not exist in model list reset to all
  useEffect(() => {
    if (
      regionParamValue == "undefined" ||
      marketParamValue == "undefined" ||
      regionParamValue == undefined ||
      marketParamValue == undefined
    ) {
      dispatch(setSpecificParameter("region", ALL_OPTION_NO_SPACE));
      dispatch(setSpecificParameter("market", ALL_COUNTRIES));
    }
  }, [filters?.market, regionParamValue, marketParamValue, dispatch]);

  //Set model filter value
  useEffect(() => {
    if (modelParamValue) {
      const selectedLeadsArr = modelParamValue.split(",");
      const modelValue =
        selectedLeadsArr?.length === modelParamValue.length
          ? "All"
          : selectedLeadsArr?.length > 1
            ? `${selectedLeadsArr?.length} models selected`
            : modelParamValue;
      setModelParam(modelParamValue);
      setModelFilterValue(modelValue);
    } else {
      setModelFilterValue("All");
    }
  }, [modelList, modelParamValue, brandParam]);

  //Set service name filter value
  useEffect(() => {
    if (serviceNameList.length > 0 && serviceNameParamValue) {
      const selectedLeadsArr = serviceNameParamValue.split(",").filter((serviceName) => serviceNameList.includes(serviceName));
      const serviceNameValue =
        selectedLeadsArr?.length === serviceNameList.length
          ? "All"
          : selectedLeadsArr?.length > 1
            ? `${selectedLeadsArr?.length} service names selected`
            : serviceNameParamValue;

      setServiceNameFilterValue(serviceNameValue);
    }
  }, [serviceNameList, serviceNameParamValue]);

  //Set uid filter value
  useEffect(() => {
    if (uidList.length > 0 && uidParamValue && uidParamValue != ALL_OPTION_NO_SPACE) {
      const selectedUidsArr = uidParamValue.split(",");
      const uidsAreValid = selectedUidsArr.every((uid: string) => uidList.includes(uid));
      if (!uidsAreValid) dispatch(setSpecificParameter("uid", ALL_OPTION_NO_SPACE));
    }
  }, [uidList, uidParamValue]);

  //Set uid filter value
  useEffect(() => {
    if (uidList.length > 0 && uidParamValue) {
      const selectedUidsArr = uidParamValue.split(",").filter((uid) => uidList.includes(uid));
      const uidValue =
        selectedUidsArr?.length === uidList.length
          ? "All"
          : selectedUidsArr?.length > 1
            ? `${selectedUidsArr?.length} uids selected`
            : uidParamValue;

      setUidFilterValue(uidValue);
    }
  }, [uidList, uidParamValue]);

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      if (filterName === "brand") {
        Promise.all([
          dispatch(setSpecificParameter(filterName, optionValue)),
          dispatch(setSpecificParameter("region", ALL_OPTION_NO_SPACE)),
          dispatch(setSpecificParameter("market", ALL_OPTION)),
          dispatch(setSpecificParameter("model", ALL_OPTION_NO_SPACE)),
          dispatch(setSpecificParameter("service_group", ALL_OPTION_NO_SPACE)),
          dispatch(setSpecificParameter("service_type", ALL_OPTION_NO_SPACE)),
          dispatch(setSpecificParameter("services", ALL_OPTION_NO_SPACE)),
        ]);
      } else if (filterName === "market") {
        if (optionValue === "United States") {
          dispatch(setSpecificParameter("region", optionValue));
          dispatch(setSpecificParameter(filterName, optionValue));
        } else {
          dispatch(setSpecificParameter("region", optionValue));
          dispatch(setSpecificParameter(filterName, ALL_COUNTRIES));
        }
        eventTracking(MixpanelEvents.filter_change, { filter: filterName, value: optionValue, dashboard: "CCS", page: "Top/Flop" });
      } else {
        dispatch(setSpecificParameter(filterName, optionValue));
        eventTracking(MixpanelEvents.filter_change, { filter: filterName, value: optionValue, dashboard: "CCS", page: "Top/Flop" });
      }
    }
    closeFilterOptions();
    setShowFilterOptions(false);
  };

  const handleDateFilterSubmission = () => {
    const parameterName = "dates";
    const selectedCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-parent-value]:checked`
    ) as NodeListOf<HTMLInputElement>;

    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    closeFilterOptions();
    const value = selectedValues.join(",");
    dispatch(setSpecificParameter(parameterName, value));
    eventTracking(MixpanelEvents.filter_change, { filter: parameterName, value, dashboard: "CCS", page: "Top/Flop" });
  };

  const handleModelSubmit = useCallback(() => {
    const parameterName = "model";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = selectedValues?.length === modelList?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, { filter: parameterName, value: filterValue, dashboard: "CCS", page: "Top/Flop" });
  }, [modelList]);

  const handleServiceSubmit = useCallback(() => {
    const parameterName = "services";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = selectedValues?.length === serviceNameList?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    dispatch(setSpecificParameter(parameterName, filterValue));
    dispatch(deleteSpecificParameter("specific_service"));
    eventTracking(MixpanelEvents.filter_change, { filter: parameterName, value: filterValue, dashboard: "CCS", page: "Top/Flop" });
  }, [serviceNameList]);

  const handleUidSubmit = useCallback(() => {
    const parameterName = "uid";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = selectedValues?.length === uidList?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, { filter: parameterName, value: filterValue, dashboard: "CCS", page: "Top/Flop" });
  }, [uidList]);

  //Set param values
  useEffect(() => {
    const params = new URLSearchParams();
    params.set("dates", dateRangeParamValue);
    params.set("brand", brandFilterValue);
    params.set("region", regionParam);
    params.set("market", marketParam);
    params.set("model", modelParam);
    params.set("service_group", serviceGroupParamValue);
    params.set("service_type", serviceTypeParamValue);
    params.set("services", serviceNameParamValue);
    params.set("service_trigger", serviveTriggerParamValue);
    specificServiceParamValue && params.set("specific_service", specificServiceParamValue);
    specificGroupParamValue && params.set("specific_group", specificGroupParamValue);
    specificTypeParamValue && params.set("specific_type", specificTypeParamValue);
    specificTriggerParamValue && params.set("specific_trigger", specificTriggerParamValue);
    uidParamValue && params.set("uid", uidParamValue);
    history.push({ search: `?${params.toString()}` });
  }, [
    dateRangeParamValue,
    marketParam,
    regionParam,
    modelParam,
    serviceGroupParamValue,
    serviceTypeParamValue,
    serviceNameParamValue,
    brandFilterValue,
    specificServiceParamValue,
    specificGroupParamValue,
    specificTypeParamValue,
    serviveTriggerParamValue,
    specificTriggerParamValue,
    uidParamValue,
  ]);

  return (
    <FiltersLayout>
      <>
        {/* Date range */}
        <MultiDateRangeWithPresets
          parentKey="year"
          // @ts-ignore
          filterList={groupedDateRangeData}
          filterName="DATE RANGE"
          childKey="month"
          grandChildKey="week"
          value={dateRangeFilterValue}
          parameterName="dates"
          parameterValue={dateRangeParamValue}
          onShowFilterOptions={showFilterOptionsFn}
          handleFilterSubmission={handleDateFilterSubmission}
          page="Top/Flop"
          showOptionsOnly={true}
          weeklyOptions={true}
          toggleState={showMonthyDatesToggleState}
          setToogleState={setShowMonthyDatesToggleState}
        />

        {/*Brand*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={brands}
            filterName={"brand"}
            filterLabel={"BRAND"}
            filterValue={brandFilterValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Geography*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            key="market_filter"
            filterName={"market"}
            list={filters?.market ? [ALL_OPTION_NO_SPACE, ...filters.market] : [ALL_OPTION_NO_SPACE]}
            filterValue={geographyValue}
            filterLabel={"GEOGRAPHY"}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Model*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          {!modelList || !modelList.length ? (
            <ModelFilter filterValue={"All"} list={["All"]} handleFilterOptionClick={handleFilterOptionClick} />
          ) : (
            <MultiSelectFilter
              parentKey="model_filter"
              parameterName="model"
              onShowFilterOptions={showFilterOptionsFn}
              filterName="MODEL"
              //@ts-ignore
              filterList={modelList}
              handleFilterSubmission={handleModelSubmit}
              value={modelFilterValue}
              parameterValue={modelParamValue.includes("All") ? modelList?.join(",") : modelParamValue}
            />
          )}
        </ErrorBoundary>

        {/*Service Group*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="uid_filter"
            parameterName="uid"
            onShowFilterOptions={showFilterOptionsFn}
            filterName="UID"
            //@ts-ignore
            filterList={uidList}
            handleFilterSubmission={handleUidSubmit}
            value={uidFilterValue}
            parameterValue={uidParamValue.includes("All") ? uidList?.join(",") : uidParamValue}
          />
        </ErrorBoundary>

        {/*Service Name*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="service_filter"
            parameterName="services"
            onShowFilterOptions={showFilterOptionsFn}
            filterName="SERVICE NAME"
            //@ts-ignore
            filterList={serviceNameList}
            handleFilterSubmission={handleServiceSubmit}
            value={serviceNameFilterValue}
            parameterValue={serviceNameParamValue}
          />
        </ErrorBoundary>

        {/*Service Group*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            key="service_group_filter"
            filterName={"service_group"}
            list={serviceGroupList}
            filterValue={serviceGroupParamValue}
            filterLabel={"SERVICE GROUP"}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Service Group*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            key="service_type_filter"
            filterName={"service_type"}
            list={serviceTypeList}
            filterValue={serviceTypeParamValue}
            filterLabel={"ON/OFF BOARD"}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Service Trigger*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={serviceTriggerList}
            filterName={"service_trigger"}
            filterLabel={"SERVICE TRIGGER"}
            filterValue={serviveTriggerParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>
      </>
    </FiltersLayout>
  );
});
