import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import { LeadsFunnelChart } from "../../../components/Charts/DfpDashboard/LeadsFunnelChart";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { ThemeContext } from "../../../context";
import { isNull, toLocaleInteger } from "../../../utils/utilityFunctions";

const LeadsFunnelGraph = () => {
  const themeContext = useContext(ThemeContext);
  const loading = useSelector((state: RootStateOrAny) => state.loading.leads_funnel);
  const data = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.leads_funnel);
  const { total_leads: totalLeads } = useSelector((state: RootStateOrAny) => state.product_marketing_optimization.total_leads);
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const ref = useRef<any>(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (tooltipVisible && ref.current != null && !ref.current.contains(e.target)) {
        setTooltipVisible(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [tooltipVisible]);

  const leadsFunnelChartData = useMemo(() => {
    if (!isNull(data)) {
      const keys = Object.keys(data);

      const chartData = keys.map((key) => {
        let nameval = "";
        if (key.includes("kba")) {
          nameval += key.replace("kba_", "");
        } else if (key.includes("leads")) {
          nameval += key.replace("leads_", "");
        } else {
          nameval += key;
        }
        nameval = nameval.split("_").join(" ").charAt(0).toUpperCase() + nameval.split("_").join(" ").slice(1);

        return {
          name: nameval,
          visits: data[key],
          type: key?.includes("visits") || key.includes("sales") ? "blue" : "green",
        };
      });
      return chartData;
    }
  }, [data]);

  return (
    <div className="leads_funnel">
      <div className="dfp_tile_header kpi-tile primary_background">
        <h3>Lead visits</h3>
        <p className="period_comparison" id={`period_yoy_comparison`} data-test-id={`period_yoy_comparison`}>
          <span className="main_value">
            {totalLeads ? (
              <span className="main_value_number" id={`$yoy_value`} data-test-id={`yoy_value`}>
                {toLocaleInteger(totalLeads)}
              </span>
            ) : (
              <span className="main_value_number" id={`$yoy_value`} data-test-id={`yoy_value`}>
                n/a
              </span>
            )}
          </span>
        </p>
        <br />
      </div>
      <div className="main_content primary_background">
        <div className="dfp_tile_header">
          <div className="title">
            <h3>Customer journey</h3>
            <Tooltip
              position="left"
              className="customer_intention_tooltip"
              html={
                <div ref={ref}>
                  <p>
                    <span style={{ fontWeight: "bold" }}>Website visit</span> happens every time the site has at least one page fully
                    loaded.
                  </p>
                  <br />
                  <p>
                    <span style={{ fontWeight: "bold" }}>KBA or Key Buying Actions</span> visits are the count of visits including at least
                    one key buying action as Model detail compare, Saved searches views, Compare tools views and Dealer search.
                  </p>
                  <br />
                  <p>
                    <span style={{ fontWeight: "bold" }}>KBA downloads</span> include visitors that downloaded a brochure on Nissan website.
                  </p>
                  <br />
                  <p>
                    <span style={{ fontWeight: "bold" }}>KBA configurator completions</span> is based on the volume of the configurations
                    completed by visitors online.
                  </p>
                  <br />
                  <p>
                    <span style={{ fontWeight: "bold" }}>Lead visits</span> are the count of visits including at least one digital lead as
                    Enquire, Test drive, Request a video and Reserve.
                  </p>
                  <br />
                  <p>
                    <span style={{ fontWeight: "bold" }}>Lead visits request a quote</span> is the count of visits where the lead requests a
                    quote on Nissan website.
                  </p>
                  <br />
                  <p>
                    <span style={{ fontWeight: "bold" }}>Test drive requests</span> include visitors that requested a test drive on Nissan
                    website.
                  </p>
                  <br />
                  <p>
                    <u>Source</u>: Adobe Analytics
                  </p>
                  <br />
                  <p>
                    <span style={{ fontWeight: "bold" }}>Retail sales</span> is the volume of completed sales
                  </p>
                  <br />
                  <p>
                    <u>Source</u>: sales data sourced from IDEA database.
                  </p>
                  <br />
                </div>
              }
              trigger="click"
              animation="none"
              theme="dark"
              interactive={true}
              delay={1}
              hideDelay={1}
              duration={1}
              open={tooltipVisible}
              //@ts-ignore
              onShown={() => setTooltipVisible(!tooltipVisible)}
            >
              <span
                className="info_tooltip hide_on_mobile"
                onClick={(evt: React.MouseEvent<HTMLElement>) => {
                  evt.stopPropagation();
                  setTooltipVisible(!tooltipVisible);
                }}
              />
            </Tooltip>
          </div>
        </div>

        {leadsFunnelChartData?.length ? (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <LeadsFunnelChart data={leadsFunnelChartData} theme={themeContext.theme} isLoading={loading} />
          </ErrorBoundary>
        ) : loading ? (
          <LoadingEllipsis isLoading={loading} />
        ) : (
          <NoDataMsg message="No data available" />
        )}
      </div>
    </div>
  );
};

export default LeadsFunnelGraph;
