import { Box } from "@material-ui/core";
import React, { ReactElement } from "react";
import style from "./style.module.scss";

interface Props {
  id: string;
  title?: string;
  titleDescription?: string;
  content: Array<{ id: string; component: ReactElement }>;
  className?: string;
  marketBreakdownPath?: string;
}

export const PreorderCard = (props: Props) => {
  const { id, title, titleDescription, className, content } = props;

  return (
    <Box className={`${style.card} ${className ? className : ""}`} data-test-id={id}>
      {title && (
        <div className={`${style.headerContainer} border`}>
          <h2 className={style.header}>{title}</h2>
          {titleDescription && <h3 className={style.headerDescription}>{titleDescription}</h3>}
        </div>
      )}

      <div className={style.contentBox}>
        {content &&
          content.map(({ id, component }, idx) => {
            const contentLength = content.length;
            return (
              <div
                className={`${style.content}  ${
                  contentLength % 2 === 0
                    ? idx >= contentLength - 2
                      ? style.noBottom
                      : ""
                    : idx === contentLength - 1
                    ? style.noBottom
                    : ""
                }`}
                key={id}
                data-test-id={id}
              >
                {component}
              </div>
            );
          })}
      </div>
    </Box>
  );
};
