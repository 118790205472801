import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import ShowRoomChart from "../../../components/Charts/DfpDashboard/ShowRoomChart";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { ThemeContext } from "../../../context";
import { isNull } from "../../../utils/utilityFunctions";

const ShowRoomGraph = () => {
  const themeContext = useContext(ThemeContext);
  const { test_drive_request: loading } = useSelector((state: RootStateOrAny) => state.loading);
  const { test_drive_requests: testDriveData } = useSelector((state: RootStateOrAny) => state.product_marketing_optimization);

  const { market: marketParamValue, granularity } = useSelector((state: RootStateOrAny) => state.parameters);

  const data = useMemo(() => {
    if (!isNull(testDriveData)) return testDriveData?.Current;
    return [];
  }, [testDriveData]);
  const highestValue = useMemo(() => {
    if (!isNull(testDriveData)) return 0;
    let max = -Infinity;
    testDriveData?.map((row: { leads_book_a_test_drive: number }) => {
      if (row.leads_book_a_test_drive > max) max = row.leads_book_a_test_drive;
    });
    return max;
  }, []);

  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const ref = useRef<any>(null);
  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (tooltipVisible && ref.current != null && !ref.current.contains(e.target)) {
        setTooltipVisible(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [tooltipVisible]);

  return (
    <div className="show_room primary_background">
      <div className="dfp_tile_header">
        <div className="title">
          <h3>Test drive requests</h3>
          <Tooltip
            position="right"
            className="customer_intention_tooltip"
            html={
              <div ref={ref}>
                <p>
                  <span style={{ fontWeight: "bold" }}>Test drive requests</span> include visitors that requested a test drive on Nissan
                  website.
                </p>
                <p>
                  <u>Source</u>: Adobe Analytics
                </p>
                <br />
              </div>
            }
            trigger="click"
            animation="none"
            theme="dark"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            open={tooltipVisible}
            //@ts-ignore
            onShown={() => setTooltipVisible(!tooltipVisible)}
          >
            <span
              className="info_tooltip hide_on_mobile"
              onClick={(evt: React.MouseEvent<HTMLElement>) => {
                evt.stopPropagation();
                setTooltipVisible(!tooltipVisible);
              }}
            />
          </Tooltip>
        </div>
      </div>
      <div className="main_content ">
        {data?.length ? (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <ShowRoomChart
              chartId={"showRoomChart"}
              data={data}
              theme={themeContext.theme}
              isLoading={loading}
              seriesList={["leads_book_a_test_drive"]}
              market={marketParamValue}
              granularity={granularity}
              highestValue={highestValue}
            />
          </ErrorBoundary>
        ) : loading ? (
          <LoadingEllipsis isLoading={loading} />
        ) : (
          <NoDataMsg message="No data available" />
        )}
      </div>
    </div>
  );
};

export default ShowRoomGraph;
