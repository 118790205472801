import React, { useContext } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import EcommerceOrdersChart from "../../../components/Charts/Ecommerce/OrdersChart";
import { ThemeContext } from "../../../context";

const data = [
  {
    date: "2023-01-01",
    "United States_visits": 1294742,
    Mexico_visits: 491408,
    Brazil_visits: 210202,
    Canada_visits: 192335,
    Argentina_visits: 92347,
    Peru_visits: 28310,
    Chile_visits: 46535,
  },
  {
    date: "2023-02-01",
    "United States_visits": 1294742,
    Mexico_visits: 491408,
    Brazil_visits: 210202,
    Canada_visits: 192335,
    Argentina_visits: 92347,
    Peru_visits: 28310,
    Chile_visits: 46535,
  },
  {
    date: "2023-03-01",
    "United States_visits": 1294742,
    Mexico_visits: 491408,
    Brazil_visits: 210202,
    Canada_visits: 192335,
    Argentina_visits: 92347,
    Peru_visits: 28310,
    Chile_visits: 46535,
  },
  {
    date: "2023-04-01",
    "United States_visits": 1294742,
    Mexico_visits: 491408,
    Brazil_visits: 210202,
    Canada_visits: 192335,
    Argentina_visits: 92347,
    Peru_visits: 28310,
    Chile_visits: 46535,
  },
  {
    date: "2023-05-01",
    "United States_visits": 1294742,
    Mexico_visits: 491408,
    Brazil_visits: 210202,
    Canada_visits: 192335,
    Argentina_visits: 92347,
    Peru_visits: 28310,
    Chile_visits: 46535,
  },
  {
    date: "2023-06-01",
    "United States_visits": 1294742,
    Mexico_visits: 491408,
    Brazil_visits: 210202,
    Canada_visits: 192335,
    Argentina_visits: 92347,
    Peru_visits: 28310,
    Chile_visits: 46535,
  },
];

const seriesList = [
  { name: "United States", field: "United States_visits" },
  { name: "Mexico", field: "Mexico_visits" },
  { name: "Brazil", field: "Brazil_visits" },
  { name: "Canada", field: "Canada_visits" },
  { name: "Argentina", field: "Argentina_visits" },
  { name: "Peru", field: "Peru_visits" },
  { name: "Chile", field: "Chile_visits" },
];

const EcommerceOrders = () => {
  const themeContext = useContext(ThemeContext);

  const { data, series } = useSelector((state: RootStateOrAny) => state.ecommerce.orders);
  const { market } = useSelector((state: RootStateOrAny) => state.parameters);
  const loading = useSelector((state: RootStateOrAny) => state.loading.orders);

  return (
    <div className="ecommerce-tile">
      <div className="tile-header">
        <div className="header-title">
          <p>{market == "All" ? "Ecommerce orders by region" : "Ecommerce orders by nameplate"}</p>
          <Tooltip
            position="top-end"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            distance={50}
            delay={1}
            hideDelay={1}
            duration={1}
            title={
              market == "All" ? "Number of ecommerce orders broken down per region" : "Number of ecommerce orders broken down per nameplate"
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
      </div>
      <EcommerceOrdersChart data={data} seriesList={series} chartId="EcommerceOrdersChart" theme={themeContext.theme} isLoading={loading} />
    </div>
  );
};

export default EcommerceOrders;
