import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  getExecutiveSummaryAggregatedCloseRateByDate,
  getExecutiveSummaryAggregatedDisPercentageByDate,
  getExecutiveSummaryAggregatedPrivateSalesByDate,
} from "../../../../api/executiveSummaryWebsiteApi";
import ExecutiveSummaryLineChart from "../../../../components/Charts/ExecutiveSummary/ExecutiveSummaryLineChart";
import ExecutiveSummaryStackedBarLineChart from "../../../../components/Charts/ExecutiveSummary/ExecutiveSummaryStackedBarLineChart";
import { CYAN, FOREST_GREEN, GRAY, RED } from "../../../../constants";
import { ThemeContext } from "../../../../context";
import { Tooltip } from "react-tippy";

const TimeSeriesAggregatedData = withRouter((props: RouteComponentProps) => {
  const { theme } = useContext(ThemeContext);
  const { history } = props;

  const {
    data: privateSalesByDateData,
    refetch: refetchPrivateSalesByDate,
    isFetching: privateSalesBreakdownLoading,
  } = useQuery({
    queryKey: ["privateSalesByDateData"],
    queryFn: getExecutiveSummaryAggregatedPrivateSalesByDate,
    enabled: false,
    initialData: {},
  });

  const { data: privateSalesBreakdownData, series: privateSalesBreakdownSeries } = privateSalesByDateData;

  const {
    data: disPercentageBreakdownByDateData,
    refetch: refetchDisPercentageBreakdownByDate,
    isFetching: disPercentageBreakdownLoading,
  } = useQuery({
    queryKey: ["disPercentageBreakdownByDateData"],
    queryFn: getExecutiveSummaryAggregatedDisPercentageByDate,
    enabled: false,
    initialData: {},
  });

  const { data: disPercentageBreakdownData, series: disPercentageBreakdownSeries } = disPercentageBreakdownByDateData;

  const {
    data: closeRateBreakdownByDateData,
    refetch: refetchCloseRateBreakdownByDate,
    isFetching: closeRateBreakdownLoading,
  } = useQuery({
    queryKey: ["closeRateBreakdownByDateData"],
    queryFn: getExecutiveSummaryAggregatedCloseRateByDate,
    enabled: false,
    initialData: {},
  });

  const { data: closeRateBreakdownData, series: closeRateBreakdownSeries } = closeRateBreakdownByDateData;

  useEffect(() => {
    refetchPrivateSalesByDate();
    refetchDisPercentageBreakdownByDate();
    refetchCloseRateBreakdownByDate();
  }, [history.location.search]);

  return (
    <>
      <div className="breakdown-chart tile">
        <div className="tile-header">
          <p>Private Sales Breakdown</p>
          <Tooltip
            position="right"
            className={"normal_tippy"}
            trigger="mouseenter"
            interactive={true}
            distance={220}
            delay={1}
            hideDelay={1}
            duration={1}
            html={
              <div>
                <p>Private Sales: Digitally influenced sales + Others (dealer walk ins + other)</p>
                <p>DIS: Number of confirmed new car sales that can be attributed to OEM managed digital activity</p>
                <p>Non-DIS: Sales tracked by market - Digitally influenced sales</p>
                <p>Untracked: Total private sales - (Non-DIS + DIS)</p>
              </div>
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        <ExecutiveSummaryStackedBarLineChart
          chartName="privateSalesBreakdownByDateChart"
          theme={theme}
          seriesList={privateSalesBreakdownSeries ?? []}
          data={privateSalesBreakdownData ?? []}
          isLoading={privateSalesBreakdownLoading}
        />
      </div>
      <div className="breakdown-chart tile">
        <div className="tile-header">
          <p>Digitally Influenced Sales %</p>
          <Tooltip
            position="right"
            className={"normal_tippy"}
            trigger="mouseenter"
            interactive={true}
            distance={220}
            delay={1}
            hideDelay={1}
            duration={1}
            title="Digitally Influence Sales / Private Sales"
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        <ExecutiveSummaryLineChart
          chartId="disPercentageBreakdownByDateChart"
          theme={theme}
          seriesList={disPercentageBreakdownSeries ?? []}
          isLoading={disPercentageBreakdownLoading}
          seriesColors={[CYAN, GRAY, RED, FOREST_GREEN]}
          data={disPercentageBreakdownData ?? []}
          isPercentage={true}
        />
      </div>
      <div className="breakdown-chart tile">
        <div className="tile-header">
          <p>Close Rate</p>
          <Tooltip
            position="right"
            className={"normal_tippy"}
            trigger="mouseenter"
            interactive={true}
            distance={220}
            delay={1}
            hideDelay={1}
            duration={1}
            title="Digitally Influenced Sales / Leads"
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        <ExecutiveSummaryLineChart
          chartId="closeRateBreakdownByDateChart"
          theme={theme}
          seriesList={closeRateBreakdownSeries ?? []}
          isLoading={closeRateBreakdownLoading}
          seriesColors={[CYAN, GRAY, RED]}
          data={closeRateBreakdownData ?? []}
          isPercentage={true}
        />
      </div>
    </>
  );
});

export default TimeSeriesAggregatedData;
