import { AnyAction } from "redux";
import { put, retry, takeEvery, takeLatest } from "redux-saga/effects";
import { setCurrentUserDetails, setCXOToken } from "../actions";
import { FETCH_USER_DETAILS, GET_CXO_TOKEN } from "../actions/actionTypes";
import { fetchTokenCXOPage, fetchUserDetailsData } from "../api";
import { secondsDelay } from "../api/utils";
import { IUserDetails } from "../constants/interface";

// Watcher Sagas
export function* watchFetchCXOToken() {
  yield takeEvery(GET_CXO_TOKEN, fetchCxoPageToken);
}

export function* watchFetchUserDetails() {
  yield takeLatest(FETCH_USER_DETAILS, getUserDetails);
}

export function* getUserDetails({ payload: email }: AnyAction) {
  if (email) {
    const response: IUserDetails = yield retry(4, secondsDelay, fetchUserDetailsData, email);
    yield put(setCurrentUserDetails(response));
  }
}

export function* fetchCxoPageToken() {
  try {
    const response: string = yield retry(4, secondsDelay, fetchTokenCXOPage);

    //@ts-ignore
    if (!("error" in response)) {
      yield put(setCXOToken(response));
    }
  } catch (err) {
    console.log("Failed to fetch cxo token");
  }
}
