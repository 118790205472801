import React from "react";
import { QuarterMonths } from "./QuarterMonths";
import YrScenarioRows from "./YrScnarioRows";

interface Props {
  periodClass: string;
  yrScnrClass: string;
  verts: boolean;
  period: string;
  yrScenarios: string[];
  handleScroll: (event: { currentTarget: any }) => void;
  width: number;
}

const ScrollableTitles = (props: Props) => {
  const { period, width, handleScroll, periodClass, verts, yrScenarios, yrScnrClass } = props;
  return (
    <div className={` ${verts ? "verts" : ""} ${periodClass} ${yrScnrClass} titles primary_background`}>
      <div className={`titles1 flipped primary_background ${periodClass} ${yrScnrClass}`}>
        {verts && (
          <div className="quarter_content page_title">
            <p style={{ fontSize: "var(--page_title)" }}>MTP Category</p>
          </div>
        )}
      </div>
      <div className={`quarter_titles1 flipped ${yrScnrClass} ${periodClass}`} onScroll={handleScroll}>
        <div className="quarter_content all_titles">
          <div style={{ width: `${width}px` }} className={`quarter_titles ${periodClass} ${yrScnrClass}`}>
            <div className="quarter_title">
              <span className="quarter_title">Q1</span>
              {period == "FY Month" && <QuarterMonths yrScenarios={yrScenarios} quarter="q1" combined />}
              {period == "FY Quarter" && <YrScenarioRows yrScenarios={yrScenarios} />}
            </div>
            <div className="quarter_title">
              <span className="quarter_title">Q2</span>
              {period == "FY Month" && <QuarterMonths quarter="q2" combined yrScenarios={yrScenarios} />}
              {period == "FY Quarter" && <YrScenarioRows yrScenarios={yrScenarios} />}
            </div>
            <div className="quarter_title">
              <span className="quarter_title">Q3</span>
              {period == "FY Month" && <QuarterMonths quarter="q3" combined yrScenarios={yrScenarios} />}
              {period == "FY Quarter" && <YrScenarioRows yrScenarios={yrScenarios} />}
            </div>
            <div className="quarter_title">
              <span className="quarter_title">Q4</span>
              {period == "FY Month" && <QuarterMonths quarter="q4" combined yrScenarios={yrScenarios} />}
              {period == "FY Quarter" && <YrScenarioRows yrScenarios={yrScenarios} />}
            </div>
            <div className="quarter_title">
              <span style={{ display: `${period == "FY Month" ? "inline-block" : "inline"}`, height: "2.9em" }} className="quarter_title">
                Full year
              </span>
              <br />
              {<YrScenarioRows yrScenarios={yrScenarios} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrollableTitles;
