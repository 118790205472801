import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import {
  AngListPricetrendParagrahphs,
  AngSalesPricetrendParagrahphs,
  AvgDaysInStockParagrahphs,
  InventoryVolumeTrendParagraphs,
  SalesRevenueTrendParagrahphs,
  SalesVolumeTrendParagrahphs,
} from "../helpers/tooltiptexts";
import TrendlineGraphs from "./TrendlineGraphs";

const BaseTrends = () => {
  const { data: inventoryVolumeData, series: inventoryVolumeSeries } = useSelector(
    (state: RootStateOrAny) => state.cpo_operational.inventory_volume_data
  );
  const { data: avgDaysInStockData, series: avgDaysInStockSeries } = useSelector(
    (state: RootStateOrAny) => state.cpo_operational.avg_days_in_stock_data
  );
  const { data: salesVolumeData, series: salesVolumeSeries } = useSelector(
    (state: RootStateOrAny) => state.cpo_operational.sales_volume_data
  );
  const { data: salesRevenueData, series: salesRevenueSeries } = useSelector(
    (state: RootStateOrAny) => state.cpo_operational.sales_revenue_data
  );
  const { data: avgListPriceData, series: avgListPriceSeries } = useSelector(
    (state: RootStateOrAny) => state.cpo_operational.avg_list_price_data
  );
  const { data: avgSalesPriceData, series: avgSalesPriceSeries } = useSelector(
    (state: RootStateOrAny) => state.cpo_operational.avg_sales_price_data
  );

  const {
    inventory_volume: inventoryVolumeLoading,
    avg_days_in_stock: AvgDaysInStockLoading,
    sales_volume: salesVolumeLoading,
    sales_revenue: salesRevenueLoading,
    avg_list_price: avgListPriceLoading,
    avg_sales_price: avgSalesPriceLoading,
  } = useSelector((state: RootStateOrAny) => state.loading);

  return (
    <>
      <div className="trendline_graphs">
        <TrendlineGraphs
          toolTipParagraph={<InventoryVolumeTrendParagraphs />}
          title="Inventory volume trend"
          chartData={inventoryVolumeData}
          series={inventoryVolumeSeries}
          loading={inventoryVolumeLoading}
          hideLegend={true}
        />
        <TrendlineGraphs
          toolTipParagraph={<AvgDaysInStockParagrahphs />}
          title="Average days in stock trend"
          chartData={avgDaysInStockData}
          series={avgDaysInStockSeries}
          loading={AvgDaysInStockLoading}
          hideLegend={true}
        />
        <TrendlineGraphs
          toolTipParagraph={<SalesVolumeTrendParagrahphs />}
          title="Sales volume trend"
          chartData={salesVolumeData}
          series={salesVolumeSeries}
          loading={salesVolumeLoading}
          hideLegend={true}
        />
        <TrendlineGraphs
          toolTipParagraph={<SalesRevenueTrendParagrahphs />}
          isCurrency
          title="Sales revenue trend"
          chartData={salesRevenueData}
          series={salesRevenueSeries}
          loading={salesRevenueLoading}
          hideLegend={true}
        />
        <TrendlineGraphs
          toolTipParagraph={<AngListPricetrendParagrahphs />}
          isCurrency
          title="Average list price trend"
          chartData={avgListPriceData}
          series={avgListPriceSeries}
          loading={avgListPriceLoading}
          hideLegend={true}
        />
        <TrendlineGraphs
          toolTipParagraph={<AngSalesPricetrendParagrahphs />}
          title="Average sales price trend"
          chartData={avgSalesPriceData}
          series={avgSalesPriceSeries}
          loading={avgSalesPriceLoading}
          hideLegend={true}
          isCurrency
        />
      </div>
    </>
  );
};

export default BaseTrends;
