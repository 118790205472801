import { ErrorBoundary } from "@sentry/react";
import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg } from "../../../components/AppMessages";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../components/Ratings/Ratings";
import { isNull } from "../../../utils/utilityFunctions";

interface Props {
  title: string;
  value: number | undefined;
  yoyValue: number;
  isPercentage: boolean;
  isLoading: boolean;
  hidePts?: boolean;
}

const MetricTile = (props: Props) => {
  const { title, value, yoyValue, isPercentage, isLoading, hidePts } = props;

  const currency = useSelector((state: RootStateOrAny) => state.msrp.currency);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className="msrp-tile metric-tile">
        <div className="tile-header">{title}</div>
        <div className="value">
          {value && isPercentage ? "" : currency}
          {value ? value.toLocaleString() : "n/a"}
          {value && isPercentage ? "%" : ""}
        </div>
        <div className="yoy-value">
          <Ratings
            value={yoyValue ? (isNull(yoyValue) ? "n/a" : yoyValue) : ""}
            isPercentageValue={isPercentage}
            testValue={isPercentage}
          />
          <p>
            {yoyValue && isPercentage ? "" : currency}
            {yoyValue ? yoyValue.toLocaleString() : "n/a"}
            {yoyValue && isPercentage ? "%" : ""}
          </p>
          <p className="label">{hidePts ? "YoY" : "PTS YoY"}</p>
        </div>
        <LoadingEllipsis isLoading={isLoading} />
      </div>
    </ErrorBoundary>
  );
};

export default MetricTile;
