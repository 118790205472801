import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import dark_burger_menu from "../../assets/images/Menu/dark-theme/white_burger_menu.svg";
import dark_cedar_logo from "../../assets/images/Menu/dark-theme/white_mobile_cedar_logo.svg";
import light_burger_menu from "../../assets/images/Menu/light-theme/white_burger_menu.svg";
import light_cedar_logo from "../../assets/images/Menu/light-theme/white_mobile_cedar_logo.svg";
import "../../assets/styles/component/menu.scss";
import { CXO_DASHBOARD_URL } from "../../constants";
import { ThemeContext } from "../../context";
import { defaultUrl } from "../../utils/authentication";
import MenuItems from "./MenuItems/MenuItems";
import { closeAllChildElements } from "./subs/menuFunctions";

export const DashboardMenu = withRouter((props: RouteComponentProps) => {
  const { theme } = useContext(ThemeContext);

  const { history, location } = props;
  const cxoToken = useSelector((state: RootStateOrAny) => state.user.cxoToken.token);
  const userDetails = useSelector((state: RootStateOrAny) => state.user.details);
  const [cxoUrl, setCxoUrl] = useState(CXO_DASHBOARD_URL);

  const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);

  const expandedClassName = "expanded";

  useEffect(() => {
    setCxoUrl(`${CXO_DASHBOARD_URL}${cxoToken}`);
  }, [cxoToken]);

  useEffect(() => {
    const headerElement = document.getElementById("header_wrapper");
    const ulElement = document.getElementById("profile");
    if (mobileMenuExpanded) {
      headerElement?.classList.add(expandedClassName);
      ulElement?.classList.add(expandedClassName);
    } else {
      headerElement?.classList.remove(expandedClassName);
      ulElement?.classList.remove(expandedClassName);
    }
  }, [mobileMenuExpanded]);

  const onBurgerMenuClick = useCallback(() => setMobileMenuExpanded(true), [setMobileMenuExpanded]);

  const onCloseMenuClick = useCallback(() => {
    closeAllChildElements();
    setMobileMenuExpanded(false);
  }, [setMobileMenuExpanded]);

  const homeRedirect = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation();

      const pageLink = "/home";

      history.push({ pathname: pageLink, search: "" });
      window.location.reload();
    },
    [history]
  );

  return (
    <ErrorBoundary>
      <header id="oce_header">
        <div id={"header_wrapper"} className="main_header" onMouseLeave={closeAllChildElements}>
          <div className="cedar_logo home_navigation hide_on_mobile" id="cedar_logo_desktop" data-page={defaultUrl} onClick={homeRedirect}>
            <div id="tree" />
            <div id="text" />
          </div>
          <div id="cedar_logo" data-page={defaultUrl} onClick={homeRedirect} className="home_navigation hide_on_desktop cedar_home_logo">
            <img className="hide_on_desktop" src={theme === "light" ? light_cedar_logo : dark_cedar_logo} alt="Cedar Logo" />
          </div>
          <div className="burger_div hide_on_desktop">
            <img id="burger_icon" src={theme === "light" ? light_burger_menu : dark_burger_menu} alt="menu" onClick={onBurgerMenuClick} />
          </div>

          <MenuItems
            onClickCloseMenuHandler={onCloseMenuClick}
            activePage={location?.pathname}
            firstName={userDetails?.first_name}
            lastName={userDetails?.last_name}
            email={userDetails?.email}
            cxoUrl={cxoUrl}
          />
        </div>
      </header>
    </ErrorBoundary>
  );
});
