import React from "react";
import { ToastContainer } from "react-toastify";
import "../../assets/styles/main.scss";
import { DashboardMenu } from "../Menu";

interface Props {
  children: JSX.Element;
  hasFilter: boolean;
  extraClass?: string;
}

export const IframeLayout = (props: Props): JSX.Element => {
  const { children, hasFilter, extraClass } = props;

  return (
    <div className={`pageWrapper ${extraClass}`} id={"mainDashboard"}>
      <div className={`dashboard hide_on_mobile ${hasFilter ? "" : "noPageFilter"} iframe_dashboard`} id="iframeDashboard">
        <DashboardMenu />
        {children}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick={true}
        draggable={false}
        pauseOnHover={false}
      />
    </div>
  );
};
