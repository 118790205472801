import { CategoryAxis, ColumnSeries, LabelBullet, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureCategoryAxis, configureValueAxis } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  theme: ThemeInt;
  chartId: string;
  isLoading: boolean;
}

class DmeoDigitalSpendByChannelChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, theme } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#.0a";
    this.chart.paddingRight = 50;

    // Create Axes
    const categoryAxis = this.chart.yAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 40;
    categoryAxis.renderer.inversed = true;

    const valueAxis = this.chart.xAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.renderer.opposite = true;
    valueAxis.min = 0;
    valueAxis.renderer.minGridDistance = 60;
    valueAxis.numberFormatter = new NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#a";
    valueAxis.paddingRight = 30;
    valueAxis.paddingRight = 50;

    const series = this.chart.series.push(new ColumnSeries());
    series.dataFields.valueX = "value";
    series.dataFields.categoryY = "category";
    series.columns.template.tooltipText = `[bold; font-size: var(--regular_font_size);]{categoryY}: {valueX}`;
    if (series.tooltip) {
      series.tooltip.autoTextColor = false;
      series.tooltip.label.fill = color(WHITE);
    }

    const labelBullet = series.bullets.push(new LabelBullet());
    labelBullet.label.paddingLeft = 5;
    labelBullet.label.text = '${valueX.formatNumber("#.0a")}';
    labelBullet.label.fill = theme === "light" ? color(BLACK) : color(WHITE);
    labelBullet.label.horizontalCenter = "left";
    labelBullet.label.truncate = false;
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default DmeoDigitalSpendByChannelChart;
