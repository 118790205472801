import { CategoryAxis, ColumnSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, BRIGHT_YELLOW, CYAN, GRAY, ORANGE, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureCategoryAxis, configureValueAxis, legendConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  seriesList: Array<{ Name: string; field: string }>;
  theme: ThemeInt;
  chartId: string;
  isLoading: boolean;
}

const BAR_COLORS = [CYAN, ORANGE, GRAY, BRIGHT_YELLOW];

class DmeoDigitalPerformanceEvolutionChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, seriesList, theme } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#.0a";
    this.chart.paddingRight = 50;
    this.chart.colors.list = BAR_COLORS.map((item) => color(item));

    // Legend configuration
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.marginLeft = 0;
    this.chart.legend.position = "bottom";
    // this.chart.legend.itemContainers.template.paddingTop = 10;
    // this.chart.legend.itemContainers.template.paddingRight = 10;

    // Create Axes
    const categoryAxis = this.chart.yAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.labels.template.marginRight = 5;
    categoryAxis.renderer.labels.template.truncate = true;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.inversed = true;

    const valueAxis = this.chart.xAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.renderer.minGridDistance = 45;
    valueAxis.numberFormatter = new NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#a";
    valueAxis.paddingRight = 30;
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return `${text}%`;
    });

    seriesList.forEach(({ Name, field }, idx) => {
      const series = this.chart.series.push(new ColumnSeries());
      series.name = Name;
      series.dataFields.valueX = field;
      series.dataFields.categoryY = "category";
      series.stacked = true;
      series.columns.template.tooltipText = "[bold font-size: var(--regular_font_size)]{name}: {valueX.formatNumber('###,###')}%";
    });
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default DmeoDigitalPerformanceEvolutionChart;
