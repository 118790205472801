export const FETCH_FUTURE_MARKET_MEDIA_MLC = "FETCH_FUTURE_MARKET_MEDIA_MLC";
export const FETCH_FUTURE_MARKET_PAID_IMPRESSIONS = "FETCH_FUTURE_MARKET_PAID_IMPRESSIONS";
export const FETCH_FUTURE_MARKET_REACH = "FETCH_FUTURE_MARKET_REACH";
export const FETCH_FUTURE_MARKET_BRAND_SEARCH = "FETCH_FUTURE_MARKET_BRAND_SEARCH";

export const FETCH_NEAR_MARKET_MEDIA_MLC = "FETCH_NEAR_MARKET_MEDIA_MLC";
export const FETCH_NEAR_MARKET_VISITS = "FETCH_NEAR_MARKET_VISITS";
export const FETCH_NEAR_MARKET_UNIQUE_VISITS = "FETCH_NEAR_MARKET_UNIQUE_VISITS";
export const FETCH_NEAR_MARKET_KBA_VISITS = "FETCH_NEAR_MARKET_KBA_VISITS";

export const FETCH_IN_MARKET_MEDIA_MLC = "FETCH_IN_MARKET_MEDIA_MLC";
export const FETCH_IN_MARKET_LEAD_VOLUME = "FETCH_IN_MARKET_LEAD_VOLUME";
export const FETCH_IN_MARKET_CLOSE_RATE_SALES = "FETCH_IN_MARKET_CLOSE_RATE_SALES";
export const FETCH_IN_MARKET_TOTAL_SALES = "FETCH_IN_MARKET_TOTAL_SALES";
export const FETCH_IN_MARKET_LEAD_JAPAN_VOLUME = "FETCH_IN_MARKET_LEAD_JAPAN_VOLUME";

export const SET_FUTURE_MARKET_MEDIA_MLC = "SET_FUTURE_MARKET_MEDIA_MLC";
export const SET_FUTURE_MARKET_PAID_IMPRESSIONS = "SET_FUTURE_MARKET_PAID_IMPRESSIONS";
export const SET_FUTURE_MARKET_REACH = "SET_FUTURE_MARKET_REACH";
export const SET_FUTURE_MARKET_BRAND_SEARCH = "SET_FUTURE_MARKET_BRAND_SEARCH";

export const SET_NEAR_MARKET_MEDIA_MLC = "SET_NEAR_MARKET_MEDIA_MLC";
export const SET_NEAR_MARKET_VISITS = "SET_NEAR_MARKET_VISITS";
export const SET_NEAR_MARKET_UNIQUE_VISITS = "SET_NEAR_MARKET_UNIQUE_VISITS";
export const SET_NEAR_MARKET_KBA_VISITS = "SET_NEAR_MARKET_KBA_VISITS";

export const SET_IN_MARKET_MEDIA_MLC = "SET_IN_MARKET_MEDIA_MLC";
export const SET_IN_MARKET_LEAD_VOLUME = "SET_IN_MARKET_LEAD_VOLUME";
export const SET_IN_MARKET_CLOSE_RATE_SALES = "SET_IN_MARKET_CLOSE_RATE_SALES";
export const SET_IN_MARKET_TOTAL_SALES = "SET_IN_MARKET_TOTAL_SALES";

export const FETCH_MTM_CURRENCY = "FETCH_MTM_CURRENCY";
export const SET_MTM_CURRENCY = "SET_MTM_CURRENCY";

export const FETCH_MTM_MODELS = "FETCH_MTM_MODELS";
export const SET_MTM_MODELS = "SET_MTM_MODELS";

export const FETCH_MTM_ANNOTATIONS = "FETCH_MTM_ANNOTATIONS";
export const SET_MTM_ANNOTATIONS = "SET_MTM_ANNOTATIONS";
