import {
  SET_ANNOTATION_BRANDS,
  SET_ANNOTATION_CHANNELS,
  SET_ANNOTATION_CHANNEL_BREAKDOWNS,
  SET_ANNOTATION_LEAD_SOURCES,
  SET_ANNOTATION_MARKETS,
  SET_ANNOTATION_METRICS,
  SET_ANNOTATION_NEW_MODELS,
  SET_ANNOTATION_REGIONS,
} from "../actions/actionTypes";
import { DEFAULT_ANNOTATION_PARAMETERS } from "../constants";

const annotationParametersReducer = (
  state = DEFAULT_ANNOTATION_PARAMETERS,
  action: {
    type: string;
    dashboard: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case SET_ANNOTATION_BRANDS:
      //@ts-ignore
      return { ...state, [action.dashboard]: { ...state[action.dashboard], brands: action.payload } };

    case SET_ANNOTATION_MARKETS:
      //@ts-ignore
      return { ...state, [action.dashboard]: { ...state[action.dashboard], markets: action.payload } };

    case SET_ANNOTATION_REGIONS:
      //@ts-ignore
      return { ...state, [action.dashboard]: { ...state[action.dashboard], regions: action.payload } };

    case SET_ANNOTATION_NEW_MODELS:
      //@ts-ignore
      return { ...state, [action.dashboard]: { ...state[action.dashboard], models: action.payload } };

    case SET_ANNOTATION_CHANNELS:
      //@ts-ignore
      return { ...state, [action.dashboard]: { ...state[action.dashboard], channels: action.payload } };

    case SET_ANNOTATION_CHANNEL_BREAKDOWNS:
      //@ts-ignore
      return { ...state, [action.dashboard]: { ...state[action.dashboard], channelBreakdowns: action.payload } };

    case SET_ANNOTATION_METRICS:
      //@ts-ignore
      return { ...state, [action.dashboard]: { ...state[action.dashboard], metrics: action.payload } };

    case SET_ANNOTATION_LEAD_SOURCES:
      //@ts-ignore
      return { ...state, [action.dashboard]: { ...state[action.dashboard], lead_sources: action.payload } };

    default:
      return state;
  }
};

export default annotationParametersReducer;
