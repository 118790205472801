export const USE_OWNERSHIP_BTN_FILTERS = [
  { id: "oveview_btn", name: "Overview", navigateTo: "/use_ownership/overview" },
  {
    id: "online_account_and_engagement_btn",
    name: "Online Account & Engagement",
    navigateTo: "/use_ownership/online_account_and_engagement",
  },
  { id: "service_lead_btn", name: "Service Lead", navigateTo: "/use_ownership/service_lead" },
  { id: "self_service_btn", name: "Self Service", navigateTo: "/use_ownership/self_service" },
  // { id: "customer_satisfaction_btn", name: "Customer Satisfaction", navigateTo: "/use_ownership/customer_satisfaction" },
  { id: "ccs_vital_signs_btn", name: "CCS Vital Signs", navigateTo: "/ccs_vital_signs" },
];
