import React, { useEffect, useState } from "react";
import { getPowerBiReportToken } from "../../api";
import { IframeLayout } from "../../components/Layouts";
import { PowerBiContent } from "../../components/PageContent";
import { powerBIUrlKeys, POWER_BI_URL_MAPPING } from "../../constants";
import { IPowerBiToken } from "../../constants/interface";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";

interface Props {
  id: string;
  tokenPathKey: powerBIUrlKeys;
  pageTracking: { pathname: string; searchString: "" };
  showReportPagePane: boolean;
  filter?: JSX.Element;
  multiPageTracking?: { mainPageName: string; pageName: string };
}

export const PowerBiTemplate = (props: Props) => {
  const { id, tokenPathKey, showReportPagePane, filter, pageTracking } = props;

  const [powerBiToken, setPowerBiToken] = useState<IPowerBiToken>({
    embed_token: "",
    embed_url: "",
    report_id: "",
    token_id: "",
    token_expiration: "",
    report_name: "",
  });

  const dashboardName: Record<string, string> = {
    "/beta/configurator-g5": "Configurator Explorer G5",
    "/beta/car_configurator": "Configurator Explorer JP",
    "/beta/configurator-nibu": "Configurator Explorer NIBU",
    "/online_to_physical": "Online To Physical",
  };

  useEffect(() => {
    getPowerBiReportToken(POWER_BI_URL_MAPPING[tokenPathKey]).then((token) => setPowerBiToken(token));
  }, []);

  // Usage tracking
  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: dashboardName[pageTracking.pathname], page: pageTracking.pathname });
  }, [pageTracking.pathname]);

  return (
    <IframeLayout hasFilter={!!filter}>
      <PowerBiContent reportId={id} token={powerBiToken} showPagePane={showReportPagePane} filter={filter} />
    </IframeLayout>
  );
};
