import { SET_CXO_TOKEN, SET_USER, SET_USER_DETAILS } from "../actions/actionTypes";

const initialState = {
  metric: "",
  _gid: "",
  token: "",
  admin: false,
  ccs_plus: false,
  ccs_purchase_funnel_online_to_physical: false,
  ccs: false,
  beta: false,
  purchase_funnel: false,
  car_config_beta: false,
  ccs_car_config: false,
  _ga: "",
  normal: false,
  user_email: "",
  default_params: "",
  has_seen_notice: false,
  ccs_purchase_funnel: false,
  dashboard_version: "",
  default_url: "",
  on_ga: "",
  profile:
    "/digital_performance/?date_range=Last+week&group=All+&region=All+&market=All+&brand=Nissan&last_touch_marketing_channel=All+&mobile_device_type=All+",
  car_config: false,
  token_expiry: "",
  details: { dark_mode: true },
  cxoToken: {},
};

const userReducer = (
  state = initialState,
  action: {
    type: string;
    payload: any;
  }
) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, ...action.payload };

    case SET_USER_DETAILS:
      return { ...state, details: { ...state.details, ...action.payload } };
    case SET_CXO_TOKEN:
      return { ...state, cxoToken: action.payload };

    default:
      return state;
  }
};
export default userReducer;
