import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import * as React from "react";
import { useContext, useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { setParameters, setSpecificParameter } from "../../actions";
import {
  ALL_COUNTRIES,
  ALL_OPTION_NO_SPACE,
  CPO_OPERATIONAL_DATE_FILTER_OPTIONS,
  DEFAULT_CPO_OPERATIONAL_PARAMS,
  NISSAN,
} from "../../constants";
import { FilterContext } from "../../context";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { currentDefaultFiltersSet, defaultFiltersSet } from "../../utils/utilityFunctions";
import { ErrorMsg } from "../AppMessages";
import { FiltersLayout } from "../Layouts";
import { DateFilter, DefaultFilter, UsedCarsGeoFilter, UsedCarsModelFilter } from "./common";
import UCDealerFilter from "./common/UCDealerFilter";
import { closeFilterOptions, closeMarketOptions } from "./subs/helpers";

interface Props {
  name?: string;
  granularityOptions?: JSX.Element | null;
}

export const UsedCarsFilters = withRouter((props: RouteComponentProps & Props) => {
  const { history, granularityOptions } = props;
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);
  const {
    date_range: dateParam,
    brand: brandParam,
    region: regionParam,
    model: modelParam,
    year: modelYearParam,
    price: modelPriceParam,
    mileage: modelMileageParam,
    market: marketParam,
    dealer: dealerParam,
    granularity: granularityParam,
    certified: certifiedParam,
    outlet: outletParam,
    territory: territoryParam,
    district: districtParam,
    area: areaParam,
  } = useSelector((state: RootStateOrAny) => state.parameters);
  const params = useSelector((state: RootStateOrAny) => state.parameters);
  const filters = useSelector((state: RootStateOrAny) => state.filters.cpo_operational_filters);
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.cpo_operational_last_refresh_date);

  const dispatch = useDispatch();

  //Sets the initial start and end selectable date to be 1st April 2019 and the latest data refresh | today() - 1 (yesterday) if the last data refresh is not yet defined
  const [marketDataAvailability, setMarketDataAvailability] = React.useState<{ start: string; end: string }>({
    start: "01/08/2023",
    end: moment().subtract(1, "days").format("DD/MM/YYYY"),
  });

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("CPO Operational Dashboard");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_CPO_OPERATIONAL_PARAMS));
      currentDefaultFiltersSet("CPO Operational Dashboard");
    }
  }, []);

  useEffect(() => {
    if (filters?.brand && brandParam) {
      !filters.brand.includes(brandParam) && dispatch(setSpecificParameter("brand", filters.brand[0]));
    }
  }, [brandParam, filters?.brand]);

  useEffect(() => {
    if (!dateParam) {
      dispatch(setSpecificParameter("date_range", "Last week"));
    }
    if (!territoryParam) {
      dispatch(setSpecificParameter("territory", ALL_OPTION_NO_SPACE));
    }
    if (!areaParam) {
      dispatch(setSpecificParameter("area", ALL_OPTION_NO_SPACE));
    }
    if (!districtParam) {
      dispatch(setSpecificParameter("district", ALL_OPTION_NO_SPACE));
    }
    if (!modelYearParam) {
      dispatch(setSpecificParameter("year", ALL_OPTION_NO_SPACE));
    }
    if (!modelPriceParam) {
      dispatch(setSpecificParameter("price", ALL_OPTION_NO_SPACE));
    }
    if (!modelMileageParam) {
      dispatch(setSpecificParameter("mileage", ALL_OPTION_NO_SPACE));
    }
    if (!dealerParam) {
      dispatch(setSpecificParameter("dealer", ALL_OPTION_NO_SPACE));
    }
    if (!outletParam) {
      dispatch(setSpecificParameter("outlet", ALL_OPTION_NO_SPACE));
    }
    if (!certifiedParam) {
      dispatch(setSpecificParameter("certified", ALL_OPTION_NO_SPACE));
    }
  }, [territoryParam, areaParam, districtParam, modelYearParam, modelPriceParam, modelMileageParam, dealerParam, certifiedParam]);

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      eventTracking(MixpanelEvents.filter_change, {
        filter: filterName,
        value: optionValue,
        dashboard: "CPO Operational",
        page: "CPO Operational",
      });

      if (filterName === "brand") {
        const brandValue = optionValue.toLowerCase().includes("datsun") ? NISSAN : optionValue; //Resets datsun to Nissan

        Promise.all([
          dispatch(setSpecificParameter(filterName, brandValue)),
          dispatch(setSpecificParameter("region", ALL_OPTION_NO_SPACE)),
          dispatch(setSpecificParameter("market", ALL_COUNTRIES)),
        ]);
      } else {
        dispatch(setSpecificParameter(filterName, optionValue));
      }
    }
    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);
  };

  const resetFilters = React.useCallback(() => {
    eventTracking(MixpanelEvents.filter_reset, { dashboard: "CPO Operational", page: location.pathname });

    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(DEFAULT_CPO_OPERATIONAL_PARAMS)) {
      params.set(key, value);
    }

    history.push({ search: `?${params.toString()}` });
    window.location.reload();
  }, [history]);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("brand", brandParam);
    params.set("date_range", dateParam);
    params.set("market", marketParam);
    params.set("region", regionParam);
    params.set("territory", territoryParam);
    params.set("area", areaParam);
    params.set("district", districtParam);
    params.set("year", modelYearParam);
    params.set("price", modelPriceParam);
    params.set("mileage", modelMileageParam);
    params.set("model", modelParam);
    params.set("dealer", dealerParam);
    params.set("outlet", outletParam);
    params.set("certified", certifiedParam);
    params.set("granularity", granularityParam);
    history.push({ search: `?${params.toString()}` });
  }, [params]);

  const onRenderOptions = ({ list, filter }: { list: string[]; filter: string }) => {
    return (
      <>
        {list?.map((item: string) => {
          return (
            <li key={item} data-filter={filter} onClick={handleFilterOptionClick} data-value={item}>
              {item}
            </li>
          );
        })}
      </>
    );
  };

  return (
    <FiltersLayout resetFilters={resetFilters} extraClass={"digital_filters"}>
      <>
        {/*Date Range*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DateFilter
            onFilterClick={showFilterOptionsFn}
            renderOptions={onRenderOptions}
            value={dateParam}
            maxDate={""}
            availableDates={marketDataAvailability}
            dateOptions={CPO_OPERATIONAL_DATE_FILTER_OPTIONS}
            page="CPO Operational"
          />
        </ErrorBoundary>

        {/*Brand*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            list={filters?.brand ? filters?.brand : []}
            filterName={"brand"}
            filterLabel={"BRAND"}
            filterValue={brandParam}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        <ErrorBoundary fallback={<ErrorMsg />}>
          <UsedCarsGeoFilter availableMarkets={filters?.geography} onShowFilterOptions={showFilterOptionsFn} />
        </ErrorBoundary>
        <ErrorBoundary fallback={<ErrorMsg />}>
          <UsedCarsModelFilter onShowFilterOptions={showFilterOptionsFn} />
        </ErrorBoundary>
        <ErrorBoundary fallback={<ErrorMsg />}>
          <UCDealerFilter
            value={dealerParam}
            parameterName={"DEALER"}
            onShowFilterOptions={showFilterOptionsFn}
            dealers={filters?.dealer ?? []}
            topFiveDealers={true}
            bottomFiveDealers={true}
          />
        </ErrorBoundary>
        {granularityOptions}
      </>
    </FiltersLayout>
  );
});
