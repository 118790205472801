import React, { useCallback, useEffect, useMemo, useState } from "react";
import SeoDefaultTable from "../../../components/Tables/SEO/subs/seoDefaultTable";
import TableSearchFilter from "../../../components/Tables/subs/TableSearchFilter";
// import Ratings from "../../../components/Ratings/Ratings";
import Ratings from "../../../components/Ratings/NewRatings";
import "../../../assets/styles/component/tables.scss";
import { getLandingPagePerformance } from "../../../api/dmeoApi";
import { useQuery } from "@tanstack/react-query";
import { RouteComponentProps, withRouter } from "react-router-dom";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

const LandingPagePerformanceTable = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const [filterValue, setFilterValue] = useState("");
  const [searchCriteria, setSearchCriteria] = useState("CONTAINS");

  const [tableData, setTableData] = useState<Array<any>>([]);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["landingPagePerformanceData"],
    queryFn: getLandingPagePerformance,
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  useEffect(() => {
    Array.isArray(data) && setTableData(data);

    return () => {
      setTableData([]);
    };
  }, [data]);

  const columns = useMemo(
    () => [
      {
        id: "queryColumn",
        Header: "Landing page",
        accessor: "landing_url",
        defaultCanSort: true,
        Cell: (cell: { value: string }) => {
          const { value } = cell;
          return (
            <div className="table-cell">
              <a className="specific_link" href={value} target="blank">
                {value}
              </a>
            </div>
          );
        },
      },
      {
        id: "volumeColumn",
        Header: "Volume",
        accessor: "visits",
        defaultCanSort: true,
        Cell: (cell: { value: number }) => {
          const { value } = cell;
          return value ? value.toLocaleString() : "n/a";
        },
      },
      {
        id: "pop",
        Header: `MoM`,
        accessor: "perc_visits_mom",
        defaultCanSort: true,
        Cell: (cell: { value: string }) => {
          const { value } = cell;
          return (
            <div className="table-cell">
              {value ? <Ratings value={Number(value)} /> : null} {value ? `${Math.abs(Number(value))}%` : "n/a"}
            </div>
          );
        },
      },
      {
        id: "yoy",
        Header: "YoY",
        accessor: "perc_visits_yoy",
        defaultCanSort: true,
        Cell: (cell: { value: string }) => {
          const { value } = cell;
          return (
            <div className="table-cell">
              {value ? <Ratings value={Number(value)} /> : null} {value ? `${Math.abs(Number(value))}%` : "n/a"}
            </div>
          );
        },
      },
    ],
    [data]
  );

  const handleSelectSeachCriteria = useCallback((value: string) => {
    setSearchCriteria(value.toUpperCase());
  }, []);

  const handlefilterValueChange = useCallback((value: string) => {
    setFilterValue(value);
  }, []);

  return (
    <div className="tile">
      <div className="tile-header">
        Landing Page Performance
        <a href="dmeo/landing-page-performance">
          <ArrowCircleRightOutlinedIcon style={{ color: "#00a0dd", cursor: "pointer" }} />
        </a>
      </div>
      <div className="tile-content">
        <TableSearchFilter handleSelectSeachCriteria={handleSelectSeachCriteria} handlefilterValueChange={handlefilterValueChange} />
        <SeoDefaultTable
          data={tableData}
          columns={columns}
          sortBy={[{ id: "volumeColumn", desc: true }]}
          showHidden={false}
          selected={"All"}
          showChildRows={false}
          canSort={true}
          filters={searchCriteria}
          filter={filterValue}
          pageSize={5}
          isVisitsQuery={false}
        />
      </div>
      <LoadingEllipsis isLoading={isFetching} />
    </div>
  );
});

export default LandingPagePerformanceTable;
