import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPageName } from "../../../actions";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { VOC_BTN_FILTERS } from "../../../constants/vocConstants";
import { PageTitle } from "../../../components/PageContent/subs";
import VocQuickAfterSalesFilters from "../../../components/Filters/Voc/VocQuickAfterSalesFilters";
import "../../../assets/styles/pages/quickVocSales.scss";
import PerformanceByMarket from "./components/PerformanceByMarket";
import MarketDeepDive from "./components/MarketDeepDive";
import {
  getMarketDeepDiveTable,
  getMarketDeepDiveTrendline,
  getPerformanceByMarketMap,
  getQuickVocSalesFilters,
  getQuickVocSalesLastRefreshDate,
} from "../../../api/quickVocSalesApi";
import { useQuery } from "@tanstack/react-query";
import { DashboardBannerContext } from "../../../context";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";

const QuickVocSales = () => {
  const dispatch = useDispatch();

  const { setBannerText, setShowBanner } = useContext(DashboardBannerContext);

  useEffect(() => {
    setShowBanner(true);
    setBannerText(
      "Please note that figures presented in the dashboard are a sample of FY24 data. CEDAR and AMIEO are collaborating to make full figures and extended date ranges available shortly."
    );
    return () => {
      setShowBanner(false);
      setBannerText("");
    };
  }, []);

  const { data: lastDataRefreshData, refetch: refetchLastDataRefresh } = useQuery({
    queryKey: ["quickVocSalesLastDataRefresh"],
    queryFn: getQuickVocSalesLastRefreshDate,
    enabled: false,
  });

  useEffect(() => {
    dispatch(setPageName("Quick VOC Sales"));
    refetchLastDataRefresh();
    eventTracking(MixpanelEvents.page_view, { dashboard: "VOC", page: "Quick VOC Sales" });
  }, [dispatch]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<VocQuickAfterSalesFilters filterBtns={VOC_BTN_FILTERS} queryFn={getQuickVocSalesFilters} pageName="Quick VOC Sales" />}
        dataDocumentation="voc"
        lastDataRefresh={lastDataRefreshData?.date ?? ""}
        pageTitle={<PageTitle dataDocumentation="voc" />}
      >
        <div className="quick-voc-sales">
          <PerformanceByMarket queryFn={getPerformanceByMarketMap} />
          <MarketDeepDive tableQueryFn={getMarketDeepDiveTable} trendlineQueryFn={getMarketDeepDiveTrendline} />
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
};

export default QuickVocSales;
