import {
  SET_BRAND_IMAGE_ATTRIBUTES_AVERAGE,
  SET_BRAND_IMAGE_ATTRIBUTES_TREND,
  SET_BRAND_OAO_AVERAGE,
  SET_BRAND_OAO_TREND,
  SET_BRAND_VOLUME_AVERAGE,
  SET_BRAND_VOLUME_TREND,
} from "../actions/actionTypes";

const initialState: {
  brand_volume_trend: any[];
  brand_volume_average: any[];
  brand_oao_trend: any[];
  brand_oao_average: any[];
  brand_image_attributes_trend: any[];
  brand_image_attributes_average: any[];
} = {
  brand_volume_trend: [],
  brand_volume_average: [],
  brand_oao_trend: [],
  brand_oao_average: [],
  brand_image_attributes_trend: [],
  brand_image_attributes_average: [],
};

const brandPerformanceReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_BRAND_OAO_TREND:
      return { ...state, brand_oao_trend: action.payload };

    case SET_BRAND_OAO_AVERAGE:
      return { ...state, brand_oao_average: action.payload };

    case SET_BRAND_IMAGE_ATTRIBUTES_TREND:
      return { ...state, brand_image_attributes_trend: action.payload };

    case SET_BRAND_IMAGE_ATTRIBUTES_AVERAGE:
      return { ...state, brand_image_attributes_average: action.payload };

    case SET_BRAND_VOLUME_TREND:
      return { ...state, brand_volume_trend: action.payload };

    case SET_BRAND_VOLUME_AVERAGE:
      return { ...state, brand_volume_average: action.payload };

    default:
      return state;
  }
};

export default brandPerformanceReducer;
