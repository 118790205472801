import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { MtmContent, MtmTitle } from ".";
import { fetchNearMarketKbaVisits, fetchNearMarketMediaMlc, fetchNearMarketUniqueVisits, fetchNearMarketVisits } from "../../../actions";
import { AnnotationDialog } from "../../../components/Annotations/Dialogs/AnnotationDialog";
import { ErrorMsg } from "../../../components/AppMessages";
import { MTMActualTargetForecastChart } from "../../../components/Charts/MTMReport";
import { MtmInvestmentContext, ThemeContext } from "../../../context";
import { attachAnnotationsMtm } from "../../../utils/annotationFunctions";
import { convertToAbsolutePercentage } from "../../../utils/utilityFunctions";
import { updateForecastData } from "../helpers/dataFormatting";

type data = {
  date: string;
  quarter: number;
  visits_actual: number;
  visits_target: number;
  visits_forecast: number | null;
  temp_visits_forecast: number | null;
  unique_visitors_actual: number | null;
  Returning_visits_actual: number | null;
  unique_visitors_target: number | null;
};

export const NearMarket = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();

  const themeContext = useContext(ThemeContext);

  const [unitedData, setunitedData] = useState<data[]>([]);
  const [mediastat, setmediastat] = useState<number[]>([]);
  const [periodTitle, setPeriodTitle] = useState("");

  const { setNearMarket, nearMarket, inMarket, futureMarket } = useContext(MtmInvestmentContext);
  const {
    date_range: dateRangeParam,
    model: modelParam,
    market: marketParam,
    granularity: granularityParam,
  } = useSelector((state: RootStateOrAny) => state.mtm_parameters);

  const mediaMlcData = useSelector((state: RootStateOrAny) => state.mtm_data.near_market_media_mlc);
  const visitsData = useSelector((state: RootStateOrAny) => state.mtm_data.near_market_visits);
  const uniqueVisitsData = useSelector((state: RootStateOrAny) => state.mtm_data.near_market_unique_visits);
  const kbaData = useSelector((state: RootStateOrAny) => state.mtm_data.near_market_kba_visits);

  const isMediaMlcLoading = useSelector((state: RootStateOrAny) => state.loading.near_market_media_mlc);
  const isVisitsLoading = useSelector((state: RootStateOrAny) => state.loading.near_market_visits);
  const isUniqueVisitsLoading = useSelector((state: RootStateOrAny) => state.loading.near_market_unique_visits);
  const isKbaVisitsLoading = useSelector((state: RootStateOrAny) => state.loading.near_market_kba_visits);

  const chartGranularity = useSelector((state: RootStateOrAny) => state.mtm_parameters.granularity);
  const currency = useSelector((state: RootStateOrAny) => state.mtm_data.currency);

  const [momLegendTooltipText, setMomLegendTooltipText] = useState<Record<string, string>>({});
  const [yoyLegendTooltipText, setYoyLegendTooltipText] = useState<Record<string, string>>({});

  const annotations = useSelector((state: RootStateOrAny) => state.mtm_data.annotations);

  // Annotations
  const [annotationDialogOpen, setAnnotationDialogOpen] = useState<boolean>(false);
  const [annotationStartDate, setAnnotationStartDate] = useState<Date | null>(null);
  const [annotationEndDate, setAnnotationEndDate] = useState<Date | null>(null);
  const [annotationDefaultMetric, setAnnotationDefaultMetric] = useState<string>("visits");

  // forecast updated data
  const forecastVisitsData = useMemo(() => {
    const data =
      visitsData?.data?.length > 1
        ? // ? dateFormattedForecast(
          updateForecastData(visitsData.data, [
            { actualKey: "visits_actual", forecastKey: "visits_forecast", tempForecastKey: "temp_visits_forecast" },
          ])
        : // )
          [];
    //@ts-ignore
    return attachAnnotationsMtm(annotations, data, chartGranularity, ["visits"]);
  }, [visitsData, annotations]);

  const forecastVisitsDataLegend = useMemo(() => {
    const data_impression_mom = granularityParam == "quarterly" ? "visits_QoQ" : "visits_MoM";
    const data_impression_yoy = "visits_YoY";
    const myValueMom = visitsData[data_impression_mom] && visitsData[data_impression_mom].pop_value;
    const myValueYoy = visitsData[data_impression_yoy] && visitsData[data_impression_yoy].yoy_value;
    const myTextMom = visitsData[data_impression_mom] && visitsData[data_impression_mom].pop_text;
    const myTextYoy = visitsData[data_impression_yoy] && visitsData[data_impression_yoy].yoy_text;
    const signMom = myValueMom && String(myValueMom).includes("-") ? "-" : "+";
    const signYoy = myValueYoy && String(myValueYoy).includes("-") ? "-" : "+";
    const popLabel = granularityParam == "monthly" ? "MoM" : "QoQ";
    const varianceMoM = myValueMom == null ? "" : `${signMom}${convertToAbsolutePercentage(myValueMom, 2)}`;
    const varianceYoy = myValueYoy == null ? "" : `${signYoy}${convertToAbsolutePercentage(myValueYoy, 2)}`;
    myTextMom && setMomLegendTooltipText((prevState) => ({ ...prevState, visits_actual: `${popLabel}: ${myTextMom}` }));
    myTextYoy && setYoyLegendTooltipText((prevState) => ({ ...prevState, visits_actual: `YoY: ${myTextYoy}` }));
    return `Visits 
    ${varianceMoM ? `${popLabel}: ${varianceMoM}` : ""} ${varianceYoy ? `YoY: ${varianceYoy}` : ""}`;
  }, [visitsData, granularityParam]);

  const forecastKbasData = useMemo(() => {
    const data =
      kbaData?.data?.length > 1
        ? // ? dateFormattedForecast(
          updateForecastData(kbaData.data, [
            { actualKey: "kbas_actual", forecastKey: "kba_forecast", tempForecastKey: "temp_kba_forecast" },
          ])
        : // )
          [];
    //@ts-ignore
    return attachAnnotationsMtm(annotations, data, chartGranularity, ["kbas"]);
  }, [kbaData, annotations]);

  const forecastKbasDataLegend = useMemo(() => {
    const data_impression_mom = granularityParam == "quarterly" ? "kbas_QoQ" : "kbas_MoM";
    const data_impression_yoy = "kbas_YoY";
    const myValueMom = kbaData[data_impression_mom] && kbaData[data_impression_mom].pop_value;
    const myValueYoy = kbaData[data_impression_yoy] && kbaData[data_impression_yoy].yoy_value;
    const myTextMom = kbaData[data_impression_mom] && kbaData[data_impression_mom].pop_text;
    const myTextYoy = kbaData[data_impression_yoy] && kbaData[data_impression_yoy].yoy_text;
    const signMom = myValueMom && String(myValueMom).includes("-") ? "-" : "+";
    const signYoy = myValueYoy && String(myValueYoy).includes("-") ? "-" : "+";
    const popLabel = granularityParam == "monthly" ? "MoM" : "QoQ";
    const varianceMom = myValueMom == null ? "" : `${signMom}${convertToAbsolutePercentage(myValueMom, 2)}`;
    const varianceYoy = myValueYoy == null ? "" : `${signYoy}${convertToAbsolutePercentage(myValueYoy, 2)}`;
    myTextMom && setMomLegendTooltipText((prevState) => ({ ...prevState, kbas_actual: `${popLabel}: ${myTextMom}` }));
    myTextYoy && setYoyLegendTooltipText((prevState) => ({ ...prevState, kbas_actual: `YoY: ${myTextYoy}` }));
    return `Actual 
    ${varianceMom ? `${popLabel}: ${varianceMom}` : ""} ${varianceYoy ? `YoY: ${varianceYoy}` : ""}`;
  }, [kbaData, granularityParam]);

  useEffect(() => {
    Promise.all([
      dispatch(fetchNearMarketKbaVisits()),
      dispatch(fetchNearMarketMediaMlc()),
      dispatch(fetchNearMarketUniqueVisits()),
      dispatch(fetchNearMarketVisits()),
    ]);
  }, [history.location.search, dispatch]);

  // Set investments total and share
  useEffect(() => {
    if (mediaMlcData && Array.isArray(mediaMlcData.data)) {
      const period = chartGranularity === "monthly" ? "month" : "quarter";
      const periodData = mediaMlcData.data.find((row: { date: string; fmi_spend_near_market: number; fmi_near_share: number }) =>
        period === "month"
          ? moment().subtract(1, "months").startOf(period).format("YYYY-MM-DD") === row.date
          : moment().startOf(period).format("YYYY-MM-DD") === row.date
      );

      if (periodData) {
        const { fmi_spend_near_market, fmi_near_share } = periodData;
        setNearMarket({ value: fmi_spend_near_market, share: fmi_near_share });
        setPeriodTitle(chartGranularity === "monthly" ? `${moment().subtract(1, "months").format("MMM")}` : `${periodData.quarter}`);
      } else {
        setNearMarket({ value: 0, share: 0 });
      }
    }
  }, [mediaMlcData, chartGranularity]);

  const toggleAnnotationsDialog = useCallback(() => {
    setAnnotationDialogOpen(!annotationDialogOpen);
  }, []);

  const mediaMlcDataWithAnnotations = useMemo(() => {
    if (annotations && annotations.length > 0 && Array.isArray(mediaMlcData.data)) {
      return attachAnnotationsMtm(annotations, mediaMlcData.data, chartGranularity, [
        "Near market online media investment",
        "Near market offline media investment",
      ]);
    }
    return mediaMlcData;
  }, [mediaMlcData, annotations]);

  const uniqueVisitsDataWithAnnotations = useMemo(() => {
    if (uniqueVisitsData?.data && annotations && annotations.length > 0 && uniqueVisitsData?.data.length > 0) {
      const annotationMetrics = ["Unique visitors", "Returning visitors"];
      return attachAnnotationsMtm(annotations, uniqueVisitsData.data, chartGranularity, annotationMetrics);
    }
    return uniqueVisitsData.data;
  }, [uniqueVisitsData, annotations]);

  const uniqueVisitsDataLegend = useMemo(() => {
    const data_impression_mom = granularityParam == "quarterly" ? "qualified_visits_QoQ" : "qualified_visits_MoM";
    const data_impression_yoy = "qualified_visits_YoY";
    const myValueMom = uniqueVisitsData[data_impression_mom] && uniqueVisitsData[data_impression_mom].pop_value;
    const myValueYoy = uniqueVisitsData[data_impression_yoy] && uniqueVisitsData[data_impression_yoy].yoy_value;
    const myTextMom = uniqueVisitsData[data_impression_mom] && uniqueVisitsData[data_impression_mom].pop_text;
    const myTextYoy = uniqueVisitsData[data_impression_yoy] && uniqueVisitsData[data_impression_yoy].yoy_text;
    const signMom = myValueMom && String(myValueMom).includes("-") ? "-" : "+";
    const signYoy = myValueYoy && String(myValueYoy).includes("-") ? "-" : "+";
    const popLabel = granularityParam == "monthly" ? "MoM" : "QoQ";
    const varianceMom = myValueMom == null ? "" : `${signMom}${convertToAbsolutePercentage(myValueMom, 2)}`;
    const varianceYoy = myValueYoy == null ? "" : `${signYoy}${convertToAbsolutePercentage(myValueYoy, 2)}`;
    myTextMom && setMomLegendTooltipText((prevState) => ({ ...prevState, qualified_visits_actual: `${popLabel}: ${myTextMom}` }));
    myTextYoy && setYoyLegendTooltipText((prevState) => ({ ...prevState, qualified_visits_actual: `YoY: ${myTextYoy}` }));
    return `Qualified Visits 
    ${varianceMom ? `${popLabel}: ${varianceMom}` : ""} ${varianceYoy ? `YoY: ${varianceYoy}` : ""}`;
  }, [uniqueVisitsData, granularityParam]);

  useEffect(() => {
    let onlineTotal = 0;
    let offlineTotal = 0;

    mediaMlcData &&
      Array.isArray(mediaMlcData.data) &&
      mediaMlcData.data.forEach((element: any) => {
        onlineTotal += element.near_market_online_fmi;
      });
    mediaMlcData &&
      Array.isArray(mediaMlcData.data) &&
      mediaMlcData.data.forEach((element: any) => {
        offlineTotal += element.near_market_offline_fmi;
      });

    setmediastat(
      onlineTotal && offlineTotal ? [onlineTotal / (onlineTotal + offlineTotal), offlineTotal / (onlineTotal + offlineTotal)] : []
    );
  }, [modelParam, marketParam, dateRangeParam, mediaMlcData]);

  useEffect(() => {
    if (
      forecastVisitsData &&
      uniqueVisitsDataWithAnnotations &&
      forecastVisitsData.length > 0 &&
      uniqueVisitsDataWithAnnotations.length > 0
    ) {
      setunitedData(
        forecastVisitsData.map((con, i) => {
          return Object.assign(con, uniqueVisitsDataWithAnnotations[i]);
        })
      );
    }
  }, [modelParam, marketParam, dateRangeParam, forecastVisitsData, uniqueVisitsDataWithAnnotations]);

  return (
    <div className="mtm_row">
      <ErrorBoundary fallback={<ErrorMsg />}>
        <MtmTitle
          title={"Near market"}
          currency={mediaMlcData.currency}
          value={nearMarket.value}
          view={granularityParam}
          onofTotal={mediastat}
          titleHelper={periodTitle}
          total={nearMarket.share}
        />
      </ErrorBoundary>

      <div className="mtm_container">
        <ErrorBoundary fallback={<ErrorMsg />}>
          <MtmContent
            title={"Visits"}
            chart={
              <MTMActualTargetForecastChart
                data={unitedData ? unitedData : []}
                chartName="visitsChart"
                isLoading={isVisitsLoading}
                actualField="visits_actual"
                actualName={forecastVisitsDataLegend}
                targetField="visits_target"
                forecastField="temp_visits_forecast"
                tooltipForecastField="visits_forecast"
                returningVisitors={"qualified_visits_actual"}
                returningVisitorsName={uniqueVisitsDataLegend}
                secondForecastField="qualified_visits_forecast"
                secondTooltipForecastField="qualified_visits_forecast"
                isQuarterly={chartGranularity === "quarterly"}
                theme={themeContext.theme}
                toggleAnnotationsDialog={toggleAnnotationsDialog}
                setAnnotationEndDate={setAnnotationEndDate}
                setAnnotationStartDate={setAnnotationStartDate}
                setAnnotationDefaultMetric={setAnnotationDefaultMetric}
                annotationMetric="visits"
                momLegendTooltipText={momLegendTooltipText}
                yoyLegendTooltipText={yoyLegendTooltipText}
              />
            }
          />
        </ErrorBoundary>

        <ErrorBoundary fallback={<ErrorMsg />}>
          <MtmContent
            title={"KBA visits"}
            chart={
              <MTMActualTargetForecastChart
                data={forecastKbasData ? forecastKbasData : []}
                chartName="kbaChart"
                isLoading={isKbaVisitsLoading}
                actualField="kbas_actual"
                actualName={forecastKbasDataLegend}
                targetField="kba_target"
                targetName="Target"
                isQuarterly={chartGranularity === "quarterly"}
                theme={themeContext.theme}
                toggleAnnotationsDialog={toggleAnnotationsDialog}
                setAnnotationEndDate={setAnnotationEndDate}
                setAnnotationStartDate={setAnnotationStartDate}
                setAnnotationDefaultMetric={setAnnotationDefaultMetric}
                annotationMetric="kbas"
                momLegendTooltipText={momLegendTooltipText}
                yoyLegendTooltipText={yoyLegendTooltipText}
              />
            }
          />
        </ErrorBoundary>

        {/*/!*Annotations modal *!/*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          {annotationDialogOpen && (
            <AnnotationDialog
              open={annotationDialogOpen}
              setOpen={setAnnotationDialogOpen}
              startDate={annotationStartDate}
              endDate={annotationEndDate}
              defaultMetric={annotationDefaultMetric}
              dashboard="mtm_metrics_that_matter"
            />
          )}
        </ErrorBoundary>
      </div>
    </div>
  );
});
