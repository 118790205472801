import { ErrorBoundary } from "@sentry/react";
import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getSearchInterestData } from "../../api/searchInterestApi";
import { ErrorMsg } from "../../components/AppMessages";
import { SearchInterestFilters } from "../../components/Filters/SearchInterestFilters";
import { IframeLayout } from "../../components/Layouts";
import { IframePageContent } from "../../components/PageContent";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";

export const SearchInterest = withRouter((props: RouteComponentProps): JSX.Element => {
  const { history } = props;
  const [brandsList, setBrandsList] = useState<Array<string>>([]);
  const [allMarketReports, setAllMarketReports] = useState<Array<any>>([]);
  const [iframeUrl, setIframeUrl] = useState("");
  const params = new URLSearchParams(history.location.search);
  const brandParamValue = params.get("brand");

  //Fetch the search interest data
  useEffect(() => {
    getSearchInterestData().then((response: any) => {
      if (response && !("error" in response)) {
        const searchInterestReports = [...response.data];
        const brands = new Set(searchInterestReports.map((report: any) => report?.brand));
        setAllMarketReports(searchInterestReports);
        setBrandsList(Array.from(brands));
      }
    });
  }, []);

  //Filter the reports when the market and report type changes
  useEffect(() => {
    if (allMarketReports) {
      const brandParamValue = params.get("brand");
      const marketParamValue = params.get("market");
      const reportTypeParam = params.get("report_type");
      const marketReports = allMarketReports?.filter(
        (report: any) => report?.market === marketParamValue && report?.brand === brandParamValue
      )[0];
      const reportToShow = marketReports ? marketReports[`${reportTypeParam}_report_link`] : "";
      setIframeUrl(reportToShow);
    }
  }, [history.location.search, allMarketReports]);

  const reportTypes: Record<string, string> = {
    brand: "Search Interest Report",
    insights: "Search Interest Insights",
    competitor: "Competitor & Segment Search Interest",
  };

  // Usage tracking
  useEffect(() => {
    const reportTypeParam = params.get("report_type") || "brand";
    params.get("report_type") &&
      eventTracking(MixpanelEvents.page_view, { dashboard: "Search Interest", page: reportTypes[reportTypeParam] });
  }, [params.get("report_type")]);

  // Usage tracking
  useEffect(() => {
    const reportTypeParam = params.get("report_type") || "brand";
    const marketParamValue = params.get("market");
    params.get("market") &&
      eventTracking(MixpanelEvents.filter_change, {
        filter: "market",
        value: marketParamValue,
        dashboard: "Search Interest",
        page: reportTypes[reportTypeParam],
      });
  }, [params.get("market")]);

  const marketsList = useMemo(() => {
    if (allMarketReports) {
      const brandMarketReports = allMarketReports.filter((report: any) => report.brand == brandParamValue);
      return brandMarketReports.map((report: any) => report.market);
    }
    return [];
  }, [allMarketReports, brandParamValue]);

  return (
    <IframeLayout hasFilter={true}>
      <ErrorBoundary fallback={<ErrorMsg />}>
        <IframePageContent
          frameId="search_interest_report"
          filter={<SearchInterestFilters marketsList={marketsList} brandList={brandsList} />}
          iframeUrl={iframeUrl}
        />
      </ErrorBoundary>
    </IframeLayout>
  );
});
