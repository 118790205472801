import { isNull } from "../../../../utils/utilityFunctions";

export const getMediaMixChartData = (categories: string[], data: any[]) => {
  return categories.map((category: string) => {
    const result: any = {};

    data
      ?.filter((row: any) => category.replace(/ /g, "_") == row.yr_scnr_rv)
      .map((row: any) => {
        const key = row.tch_pnt_nm.replace(/[^a-zA-Z]/g, "_").replace(/\s+/g, "_");
        result[`${key}_amount`] = row.amount == 0 ? null : row.amount;
        result[`${key}_share`] = row.share;
        result["yr_scnr_rv"] = row.yr_scnr_rv.replace(/_/g, " ");
        result["yr_scnr_rv_total"] = row.yr_scnr_rv_total;
      });

    return result;
  });
};

export const getMediaMixTableData = (categories: string[], data: any[], quarters: number[]) => {
  return categories
    .map((category: string) => {
      const result: any = { model: category };
      quarters?.map((quarter: number) => {
        data?.map((row: any) => {
          if (category == row.tch_pnt_nm && Number(row.quarter) == quarter) {
            result[`share_${Number(row.quarter)}_${row?.yr_scnr_rv?.replace(/_/g, " ")}`] = isNull(row?.share) ? 0 : row?.share?.toFixed(1);
          }
        });
      });
      return result;
    })
    .sort((a, b) => b?.model?.localeCompare(a?.model));
};

export const getMaxAmount = (data: any, yrScenarios: any) => {
  const maxAll: number[] = [];

  if (data?.length)
    data.map((yrScenario: any) => {
      maxAll.push(yrScenario.amount);
    });

  return Math.max(...maxAll);
};

export const getMinAmount = (data: any, yrScenarios: any) => {
  const minAll: number[] = [];

  if (data?.length)
    data.map((yrScenario: any) => {
      minAll.push(yrScenario.amount);
    });

  return Math.min(...minAll);
};
