import { trendBreakdownData } from "../../../constants/interface";
import { isNull } from "../../../utils/utilityFunctions";

// Todo: Write tests
export const filterVisitsTableData = (data: any, metric: string) => {
  const myData = data;

  return myData.filter((row: { Current: { [x: string]: any } }) => {
    const value = row.Current[metric];
    const valueIsNull = isNull(value);
    const valueIsZero = !value;

    return !valueIsNull && !valueIsZero;
  });
};

export const filterVisitsCpoTableData = (data: trendBreakdownData, metric: string, modelList: string[]) => {
  let myData = data;
  if (data?.length > 0 && "model" in data[0]) {
    myData = data.filter((item) => modelList?.includes(<string>item?.model));
  }
  if (myData.length > 0)
    return myData.filter((row) => {
      const value = row[metric];

      const valueIsNull = isNull(value);
      const valueIsZero = !value;

      return !valueIsNull && !valueIsZero;
    });

  return [];
};
//hjdejk
export const filterVisitsTableDataMidas = (data: any, metric: string) => {
  const myData = data;

  return myData.filter((row: { [x: string]: any }) => {
    const value = row[metric];
    const valueIsNull = isNull(value);
    const valueIsZero = !value;

    return !valueIsNull && !valueIsZero;
  });
};

// Todo: Write tests
export const filterVisitsBreakdownTableData = (
  data: trendBreakdownData,
  metric: string,
  modelList: string[],
  filterValues: { region: string; market: string; model: string }
) => {
  let myData = data;
  const { region, market, model } = filterValues;
  if (data?.length > 0 && "model" in data[0]) {
    const isRegionAll = /all/gi.test(region);
    const isMarketAll = /all/gi.test(market);
    const isModelAll = /all/gi.test(model);

    const isRegionSelected = !isRegionAll && isMarketAll;
    const isModelSelected = !isModelAll;

    // If both region and model are selected. Filter the data to show the selected model in the child rows
    if (!(isRegionSelected && isModelSelected)) {
      myData = data?.filter((item) => {
        // @ts-ignore
        const model_re = new RegExp(item.model, "gi");
        return isModelAll || model_re.test(model);
      });
    } else {
      myData = data?.filter((item) => {
        return modelList?.includes(<string>item?.model);
      });
    }
  }

  return myData?.filter((row) => {
    const value = row[metric];

    const valueIsNull = isNull(value);
    const valueIsZero = !value;

    return !valueIsNull && !valueIsZero;
  });
};

export const filterCpoVisitsBreakdownTableData = (
  data: trendBreakdownData,
  metric: string,
  modelList: string[],
  filterValues: { region: string; market: string; model: string }
) => {
  let myData = data;
  const { region, market, model } = filterValues;
  if (data?.length > 0 && "model" in data[0]) {
    const isRegionAll = /all/gi.test(region);
    const isMarketAll = /all/gi.test(market);
    const isModelAll = /all/gi.test(model);

    const isRegionSelected = !isRegionAll && isMarketAll;
    const isModelSelected = !isModelAll;

    // If both region and model are selected. Filter the data to show the selected model in the child rows
    if (!(isRegionSelected && isModelSelected)) {
      myData = data?.filter((item) => {
        // @ts-ignore
        const model_re = new RegExp(item.model, "gi");
        return isModelAll || model_re.test(model);
      });
    } else {
      myData = data?.filter((item) => {
        return modelList?.includes(<string>item?.model);
      });
    }
  }

  return myData?.filter((row) => {
    const value = row[metric];

    const valueIsNull = isNull(value);
    const valueIsZero = !value;

    return !valueIsNull && !valueIsZero;
  });
};
