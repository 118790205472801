import React, { useEffect, useState } from "react";
import "../../assets/styles/pages/info.scss";
import { InfoLayout } from "../../components/Layouts";
import { InfoSideMenu } from "../../components/Menu/InfoSideMenu";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { SwitchDataDocumentation } from "./common";

export const DataInfo = (): JSX.Element => {
  const [documentation, setDocumentation] = useState("kpi_definition");
  const menuItems: Array<{ name: string; docName: string }> = [
    { name: "Data dictionary", docName: "kpi_definition" },
    { name: "Data update log", docName: "kpi_update" },
    { name: "Nameplate classification", docName: "model_data" },
  ];

  // Usage tracking
  useEffect(() => eventTracking(MixpanelEvents.page_view, { dashboard: "INFO", page: "Data Info" }), [documentation]);

  return (
    <InfoLayout
      menu={<InfoSideMenu documentation={documentation} setDocumentation={setDocumentation} menuItems={menuItems} />}
      content={<SwitchDataDocumentation value={documentation} />}
    />
  );
};
