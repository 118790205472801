import React, { useContext } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import LeadsChart from "../../../components/Charts/Ecommerce/LeadsChart";
import { ThemeContext } from "../../../context";

const Leads = () => {
  const themeContext = useContext(ThemeContext);

  const tdsLeadsData = useSelector((state: RootStateOrAny) => state.ecommerce.tds_leads);
  const nonTdsLeadsData = useSelector((state: RootStateOrAny) => state.ecommerce.non_tds_leads);
  const tdsLeadsLoading = useSelector((state: RootStateOrAny) => state.loading.tds_leads);
  const nonTdsLeadsLoading = useSelector((state: RootStateOrAny) => state.loading.non_tds_leads);

  return (
    <div className="leads-container">
      <div className="ecommerce-tile">
        <div className="tile-header">
          <div className="header-title">
            <p>TD/S Leads</p>
            <Tooltip
              position="top"
              className={"normal_tippy"}
              trigger="mouseenter"
              animation="none"
              interactive={true}
              delay={1}
              hideDelay={1}
              duration={1}
              title={"Transacted Digital Sales indicates the lead that comes with payment to reserve a car"}
              size={"small"}
            >
              <span className="info_tooltip" />
            </Tooltip>
          </div>
        </div>
        <LeadsChart data={tdsLeadsData} theme={themeContext.theme} chartId="tdsChart" isLoading={tdsLeadsLoading} />
      </div>
      <div className="ecommerce-tile">
        <div className="tile-header">
          <div className="header-title">
            <p>Non-TD/S Leads</p>
            <Tooltip
              position="top"
              className={"normal_tippy"}
              trigger="mouseenter"
              animation="none"
              interactive={true}
              delay={1}
              hideDelay={1}
              duration={1}
              title={"The leads that comes from Ecommerce journey with out payment (data is not available for now)"}
              size={"small"}
            >
              <span className="info_tooltip" />
            </Tooltip>
          </div>
        </div>
        <LeadsChart data={nonTdsLeadsData} theme={themeContext.theme} chartId="nonTdsChart" isLoading={nonTdsLeadsLoading} />
      </div>
    </div>
  );
};

export default Leads;
