export const FETCH_KEY_INDICATORS_PERCENTAGE = "FETCH_KEY_INDICATORS_PERCENTAGE";
export const SET_KEY_INDICATORS_PERCENTAGE = "SET_KEY_INDICATORS_PERCENTAGE";

export const FETCH_MONTHLY_KEY_INDICATORS_PERCENTAGE = "FETCH_MONTHLY_KEY_INDICATORS_PERCENTAGE";
export const SET_MONTHLY_KEY_INDICATORS_PERCENTAGE = "SET_MONTHLY_KEY_INDICATORS_PERCENTAGE";

export const FETCH_KEY_INDICATORS_CUMULATIVE = "FETCH_KEY_INDICATORS_CUMULATIVE";
export const SET_KEY_INDICATORS_CUMULATIVE = "SET_KEY_INDICATORS_CUMULATIVE";

export const FETCH_MONTHLY_KEY_INDICATORS_CUMULATIVE = "FETCH_MONTHLY_KEY_INDICATORS_CUMULATIVE";
export const SET_MONTHLY_KEY_INDICATORS_CUMULATIVE = "SET_MONTHLY_KEY_INDICATORS_CUMULATIVE";

export const FETCH_DIGITAL_SALES_PERCENTAGE = "FETCH_DIGITAL_SALES_PERCENTAGE";
export const SET_DIGITAL_SALES_PERCENTAGE = "SET_DIGITAL_SALES_PERCENTAGE";

export const FETCH_PRIVATE_SALES_BREAKDOWN = "FETCH_PRIVATE_SALES_BREAKDOWN";
export const SET_PRIVATE_SALES_BREAKDOWN = "SET_PRIVATE_SALES_BREAKDOWN";

export const FETCH_DIGITAL_CLOSE_RATE = "FETCH_DIGITAL_CLOSE_RATE";
export const SET_DIGITAL_CLOSE_RATE = "SET_DIGITAL_CLOSE_RATE";

export const FETCH_LEADS_SALES_TREND = "FETCH_LEADS_SALES_TREND";
export const SET_LEADS_SALES_TREND = "SET_LEADS_SALES_TREND";

export const FETCH_DIGITAL_LEADS_BREAKDOWN = "FETCH_DIGITAL_LEADS_BREAKDOWN";
export const SET_DIGITAL_LEADS_BREAKDOWN = "SET_DIGITAL_LEADS_BREAKDOWN";

export const FETCH_DIGITAL_SALES_BREAKDOWN = "FETCH_DIGITAL_SALES_BREAKDOWN";
export const SET_DIGITAL_SALES_BREAKDOWN = "SET_DIGITAL_SALES_BREAKDOWN";

export const FETCH_PF_ANNOTATIONS = "FETCH_PF_ANNOTATIONS";
export const SET_PF_ANNOTATIONS = "SET_PF_ANNOTATIONS";

export const FETCH_PURCHASE_FUNNEL_VOLUME_BY_MODEL = "FETCH_PURCHASE_FUNNEL_VOLUME_BY_MODEL";
export const SET_PURCHASE_FUNNEL_VOLUME_BY_MODEL = "SET_PURCHASE_FUNNEL_VOLUME_BY_MODEL";

export const FETCH_PURCHASE_FUNNEL_MODEL_PERFORMANCE_VARIANCE = "FETCH_PURCHASE_FUNNEL_MODEL_PERFORMANCE_VARIANCE";
export const SET_PURCHASE_FUNNEL_MODEL_PERFORMANCE_VARIANCE = "SET_PURCHASE_FUNNEL_MODEL_PERFORMANCE_VARIANCE";

export const FETCH_PURCHASE_FUNNEL_TOP_RANKED_MODELS = "FETCH_PURCHASE_FUNNEL_TOP_RANKED_MODELS";
export const SET_PURCHASE_FUNNEL_TOP_RANKED_MODELS = "SET_PURCHASE_FUNNEL_TOP_RANKED_MODELS";

export const FETCH_PURCHASE_FUNNEL_BOTTOM_RANKED_MODELS = "FETCH_PURCHASE_FUNNEL_BOTTOM_RANKED_MODELS";
export const SET_PURCHASE_FUNNEL_BOTTOM_RANKED_MODELS = "SET_PURCHASE_FUNNEL_BOTTOM_RANKED_MODELS";
