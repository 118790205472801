import React, { useCallback, useMemo, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import Ratings from "../../../../components/Ratings/Ratings";
import SeoDefaultTable from "../../../../components/Tables/SEO/subs/seoDefaultTable";
import TableSearchFilter from "../../../../components/Tables/subs/TableSearchFilter";
import { ToggleSwitch } from "../../../../components/Toogle";
import { getDateDependentValues } from "../../../Online CE Performance/subs/helpers";

interface Props {
  title: string;
  id: string;
  data: Array<Record<string, any>>;
  tooltipText: string;
}

const PopularSearchTable = (props: Props) => {
  const { id, data, title, tooltipText } = props;
  const [showPercentageShare, setShowPercentageShare] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [searchCriteria, setSearchCriteria] = useState("CONTAINS");
  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.parameters.date_range);

  const { popLabel } = getDateDependentValues(dateRangeParamValue);

  const columns = useMemo(
    () => [
      {
        id: "queryColumn",
        Header: "Query",
        accessor: "query",
        defaultCanSort: true,
      },
      {
        id: "volumeColumn",
        Header: showPercentageShare ? "% Share" : "Volume",
        accessor: showPercentageShare ? "share" : "visits",
        defaultCanSort: true,
        Cell: (cell: { value: number }) => {
          const { value } = cell;
          return value ? value.toLocaleString() : "n/a";
        },
      },
      {
        id: "pop",
        Header: `% ${popLabel}`,
        accessor: showPercentageShare ? "share_pop" : "visits_pop",
        defaultCanSort: true,
        Cell: (cell: { value: string }) => {
          const { value } = cell;
          return (
            <div className="table-cell">
              <Ratings value={value} isPercentageValue={true} /> {value ? `${value}%` : "n/a"}
            </div>
          );
        },
      },
      {
        id: "yoy",
        Header: "% YoY",
        accessor: showPercentageShare ? "share_yoy" : "visits_yoy",
        defaultCanSort: true,
        Cell: (cell: { value: string }) => {
          const { value } = cell;
          return (
            <div className="table-cell">
              <Ratings value={value} isPercentageValue={true} /> {value ? `${value}%` : "n/a"}
            </div>
          );
        },
      },
    ],
    [showPercentageShare, popLabel]
  );

  const handleSelectSeachCriteria = useCallback((value: string) => {
    setSearchCriteria(value.toUpperCase());
  }, []);

  const handlefilterValueChange = useCallback((value: string) => {
    setFilterValue(value);
  }, []);

  return (
    <div className="tile popular-search-tile">
      <div className="title-container">
        <div className="title">
          {title}
          <Tooltip
            position="top-end"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title={tooltipText}
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        <ToggleSwitch
          activeToggleLabel={""}
          inactiveToggleLabel={"Show % share"}
          active={!showPercentageShare}
          handleToggleClick={() => setShowPercentageShare(!showPercentageShare)}
          toggleClassName={"use_ownership_table_toggle"}
        />
      </div>
      <div>
        <TableSearchFilter handleSelectSeachCriteria={handleSelectSeachCriteria} handlefilterValueChange={handlefilterValueChange} />
      </div>
      <div>
        <SeoDefaultTable
          data={data}
          columns={columns}
          sortBy={[{ id: "volumeColumn", desc: true }]}
          showHidden={false}
          selected={"All"}
          showChildRows={false}
          canSort={true}
          filters={searchCriteria}
          filter={filterValue}
          isVisitsQuery={false}
        />
      </div>
    </div>
  );
};

export default PopularSearchTable;
