import React, { useEffect, useState } from "react";
import { getMarketPerformanceSummaryDataDefinitions } from "../../../api/JourneyExcellenceMarketPerformanceSummary";
import { toast } from "react-toastify";

const MarketPerformanceSummeryData = () => {
  const [data, setData] = useState<Array<any>>();

  useEffect(() => {
    getMarketPerformanceSummaryDataDefinitions().then((response: any) => {
      if (response && "error" in response) {
        toast.error("Error fetching data updates");
      } else {
        setData(response);
      }
    });
  }, []);

  return (
    <div className="data_documentation">
      <h3>MARKETING PERFORMANCE SUMMARY - DATA OVERVIEW</h3>

      <br />

      <table className="docs_table">
        <thead>
          <tr>
            <th>KPI</th>
            <th>KPI definition</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((dataItem, index) => (
            <tr key={index}>
              <td>{dataItem.KPI}</td>
              <td>{dataItem.KPI_definition}</td>
              <td>{dataItem.Source}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <br />
    </div>
  );
};

export default MarketPerformanceSummeryData;
