import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getVocWebsiteDataDefinitions } from "../../../api/vocApi";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface DataUpdate {
  section: string;
  KPI: string;
  Definition: string;
  prompt: string;
}

const VocData = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const [dataUpdates, setDataUpdates] = useState<Array<DataUpdate>>();
  const [page, setPage] = useState<string>();

  useEffect(() => {
    const page = history.location.pathname.split("/").at(-1);
    setPage(page);
    getVocWebsiteDataDefinitions(page as string).then((response: any) => {
      if (response && "error" in response) {
        toast.error("Error fetching data updates");
      } else {
        setDataUpdates(response);
      }
    });
  }, [history]);

  return (
    <div className="data_documentation">
      <h3>{`${page} - DATA OVERVIEW` ?? "VOC - DATA OVERVIEW"}</h3>

      <br />

      <table className="docs_table">
        <thead>
          <tr>
            <th>Section</th>
            <th>KPI</th>
            <th>Definition</th>
          </tr>
        </thead>
        <tbody>
          {dataUpdates?.map((data, index) => (
            <tr key={index}>
              <td>{data.section}</td>
              <td>{data.KPI}</td>
              <td>{data.Definition}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <br />
    </div>
  );
});

export default VocData;
