import { captureException } from "@sentry/minimal";
import axios from "axios";
import Cookies from "js-cookie";
import mixpanel from "mixpanel-browser";
import { postDataNoTokenV2 } from "../api/utils";
import { __prod__ } from "../constants";

const linkToPage: Record<string, string> = {
  "/ccs_vital_signs/ccs_cockpit": "CCS Cockpit",
  "/ccs_vital_signs/ccs_jouney": "CCS Journey",

  "/ccs_vital_signs/app_ratings": "CCS App ratings",

  "/ccs_vital_signs/pdca": "CCS PDCA",
  "/ccs_vital_signs/data_quality": "CCS Data quality",
  "/ccs_vital_signs/ccs_journey": "CCS Journey",
  "/ccs_vital_signs/ccs_journey/europe_activation": "Europe activation",

  "/ariya/monitor": "Ariya Monitor",
  "/ariya/experience": "Ariya Experience Insights",
  "/ariya/handraisers": "Ariya Handraisers",
  "/ariya/handraisers/market-split": "Ariya Handraisers - Market split",
  "/ariya/preorder": "Ariya Preorder",
  "/ariya/preorder/japan": "Ariya Preorder - Japan",
  "/ariya/preorder/norway": "Ariya Preorder - Norway",
  "/ariya/preorder/netherlands": "Ariya Preorder - Netherlands",
  "/ariya/preorder/united-states": "Ariya Preorder - USA",
  "/ariya/preorder/united-kingdom": "Ariya Preorder - UK",
  "/mtm_metrics": "MTM",

  "/product_marketing_optimization": "Product Marketing Optimization",
  "/digital_performance": "OCE",
  "/cpo_digital_performance": "CPO",
  "/seo_dashboard": "SEO",
  "/beta/configurator-g5": "Configurator Explorer G5",
  "/beta/car_configurator": "Configurator Explorer JP",
  "/online_to_physical": "Online To Physical",
};

export enum MixpanelEvents {
  login = "Login",
  logout = "Logout",
  menu_click = "Menu Click",
  cxo_access = "CXO Access",
  filter_reset = "Filter Reset",
  filter_change = "Filter Change",
  pdf_download = "PDF Download",
  csv_download = "CSV Download",
  dashboard_access_request = "Dashboard Access Request",
  data_info_click = "Data Info Click",
  volume_coversion_toggle = "Volume Conversion Toggle",
  save_as_home_page = "Save As Home Page",
  page_view = "Page View",
  metric_toggle = "Metric Toggle",
}

export const eventTracking = (event: MixpanelEvents, eventProperties: Record<string, any> = {}) => {
  if ("page" in eventProperties) {
    eventProperties.page = linkToPage[eventProperties.page] ?? eventProperties.page;
  }

  if (__prod__ && Cookies.get("on_ga") === "true") {
    const track = (ip = "") => {
      try {
        eventProperties.ip = ip;
        if (event === MixpanelEvents.filter_change) {
          if ("value" in eventProperties) {
            eventProperties.value = eventProperties.value.split(",");
          }
        }
        // @ts-ignore
        const mixpanelProperties = mixpanel["_"].info.properties();
        // eslint-disable-next-line
        const { mp_lib, $lib_version, $insert_id, $current_url, time, ...defaultProperties } = mixpanelProperties;
        postDataNoTokenV2(`/usage-tracking?event=${event}`, { ...defaultProperties, ...eventProperties }, Cookies.get("access_token"));
      } catch (err) {
        captureException(err);
      }
    };

    const userIp = sessionStorage.getItem("ip");

    if (userIp) {
      track(userIp);
    } else {
      axios
        .get(`https://api.ipify.org?format=json`)
        .then((res) => {
          sessionStorage.setItem("ip", res.data.ip);
          track(res.data.ip);
        })
        .catch(() => {
          // Couldn't get ip
          track();
        });
    }
  }
};
