import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useExpanded, useSortBy, useTable } from "react-table";
import { getExecutiveSummaryDisSummary } from "../../../../api/executiveSummaryWebsiteApi";
import "../../../../assets/styles/component/digitallyInfluencedSalesSummaryTable.scss";
import { NoDataMsg } from "../../../../components/AppMessages";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import customSortTypes from "../../../../components/Tables/subs/sortType";

const DigitallyInfluencedSalesSummaryTable = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const [currentDateLabel, setCurrentDateLabel] = useState("Current Period");
  const [latestMonthLabel, setLatestMonthLabel] = useState("Single Month");

  const [data, setData] = useState([]);

  const {
    data: disSummaryData,
    refetch,
    isFetching: isLoading,
  } = useQuery({
    queryKey: ["keyIndicatorsData"],
    queryFn: getExecutiveSummaryDisSummary,
    enabled: false,
    initialData: {},
  });

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  useEffect(() => {
    if (disSummaryData?.data) setData(disSummaryData.data);
  }, [disSummaryData?.data]);

  useEffect(() => {
    if (disSummaryData?.start_date && disSummaryData?.end_date) {
      const startDate = moment(disSummaryData.start_date, "YYYY-MM-DD").format("MMM YYYY");
      const endDate = moment(disSummaryData.end_date, "YYYY-MM-DD").format("MMM YYYY");
      setCurrentDateLabel(`${startDate} - ${endDate}`);
      setLatestMonthLabel(moment(disSummaryData.end_date, "YYYY-MM-DD").format("MMM YYYY"));
    }
  }, [disSummaryData]);

  const getValueCellContent = (value: number | null, row: any, isPercentage?: boolean) => {
    if (row.original.market == "vs LY" || row.original.market == "vs BP") {
      const className = value && value > 0 ? "positive" : value && value < 0 ? "negative" : undefined;
      return <span className={className}>{value == null ? "" : `${value}${isPercentage ? "PTS" : "%"}`}</span>;
    }
    return <span>{value == null ? "" : `${value.toLocaleString()}${isPercentage ? "%" : ""}`}</span>;
  };

  const cols = useMemo<any>(
    () => [
      {
        id: "emptyHeaderCol",
        Header: "",
        disableSortBy: true,
        columns: [
          {
            Header: "",
            accessor: "market",
            sortType: "expandableRowCustom",
            disableSortBy: true,
            Cell: ({ value }: { value: number | null }) => {
              return <span>{value == null ? "" : value}</span>;
            },
          },
        ],
      },
      {
        Header: currentDateLabel,
        disableSortBy: true,
        columns: [
          {
            Header: "DIS %",
            accessor: "fytd_perc",
            disableSortBy: true,
            Cell: ({ value, row }: { value: number | null; row: any }) => {
              return getValueCellContent(value, row, true);
            },
          },
          {
            Header: "DIS Vol.",
            accessor: "fytd_dis_vol",
            disableSortBy: true,
            Cell: ({ value, row }: { value: number | null; row: any }) => {
              return getValueCellContent(value, row);
            },
          },
          {
            Header: "PS Vol.",
            accessor: "fytd_private_sales_vol",
            disableSortBy: true,
            Cell: ({ value, row }: { value: number | null; row: any }) => {
              return getValueCellContent(value, row);
            },
          },
        ],
      },
      {
        Header: latestMonthLabel,
        columns: [
          {
            Header: "DIS %",
            accessor: "single_month_perc",
            disableSortBy: true,
            Cell: ({ value, row }: { value: number | null; row: any }) => {
              return getValueCellContent(value, row, true);
            },
          },
          {
            Header: "DIS Vol.",
            accessor: "single_month_dis_vol",
            disableSortBy: true,
            Cell: ({ value, row }: { value: number | null; row: any }) => {
              return getValueCellContent(value, row);
            },
          },
          {
            Header: "PS Vol.",
            accessor: "single_month_private_sales_vol",
            disableSortBy: true,
            Cell: ({ value, row }: { value: number | null; row: any }) => {
              return getValueCellContent(value, row);
            },
          },
        ],
      },
    ],
    [data, currentDateLabel, latestMonthLabel]
  );

  const sortTypes = useMemo(() => customSortTypes(), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsExpanded,
    state: { expanded },
  } = useTable(
    {
      columns: cols,
      data: data,
      sortTypes,
    },
    useSortBy,
    useExpanded
  );

  useEffect(() => {
    toggleAllRowsExpanded(true);
  }, [data]);

  return data && data?.length > 0 ? (
    isLoading ? (
      <LoadingEllipsis isLoading={true} />
    ) : (
      <table {...getTableProps()} className="dis-table">
        <thead>
          {headerGroups.map((headerGroup) => {
            return (
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
                {headerGroup.headers.map((column) => {
                  const sortClass = column?.isSorted ? (column.isSortedDesc ? "down" : "up") : "";
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={column.getHeaderProps().key}
                      className={`${column.canSort ? "sortable" : ""} ${sortClass}`}
                    >
                      {column.render("Header")}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                key={row.getRowProps().key}
                className={
                  row.depth == 0
                    ? //@ts-ignore
                      `parent-row ${"market" in row.original && row.original.market == "Summary" ? "summary-row" : ""}`
                    : undefined
                }
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} key={cell.getCellProps().key}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    )
  ) : (
    <NoDataMsg />
  );
});

export default DigitallyInfluencedSalesSummaryTable;
