import { ErrorBoundary } from "@sentry/react";
import React, { Fragment, useMemo } from "react";
import { useExpanded, useSortBy, useTable } from "react-table";
import "../../../assets/styles/component/tables.scss";
import { ErrorMsg } from "../../../components/AppMessages";
import customSortTypes from "../../../components/Tables/subs/sortType";
import style from "./defaultTable.module.scss";

interface Props {
  data: any;
  columns: any;
  id: string;
  extraClass?: string;
  hiddenCols: string[];
  hideHeader?: boolean;
  renderRowSubComponent?: (props: { row: any }) => JSX.Element;
  isChildComponent: boolean;
  initialSortColId: { id: string; desc: boolean };
  setSortInformation?: (id: string, desc: boolean) => void;
  showChildRow?: boolean;
  sortable: boolean;
}

const DefaultTable = ({
  data,
  columns: chartColumns,
  id: chartId,
  extraClass,
  hideHeader,
  hiddenCols,
  renderRowSubComponent,
  isChildComponent,
  initialSortColId,
  setSortInformation,
  showChildRow,
  sortable,
}: Props): JSX.Element => {
  const sortTypes = useMemo(() => customSortTypes(), []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, visibleColumns } = useTable(
    {
      columns: chartColumns,
      data,
      initialState: { hiddenColumns: [...hiddenCols], sortBy: [initialSortColId] },
      sortTypes,
      disableSortBy: sortable,
    },
    useSortBy,
    useExpanded
  );

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <table id={chartId} {...getTableProps()} className={extraClass}>
        {isChildComponent
          ? ""
          : !hideHeader && (
              <thead>
                {headerGroups.map((headerGroup) => {
                  const { key: headerGroupKey, ...otherHeaderGroupProps } = headerGroup.getHeaderGroupProps();
                  return (
                    <tr {...otherHeaderGroupProps} key={headerGroupKey}>
                      {headerGroup.headers.map((column) => {
                        const sortClass =
                          //@ts-ignore
                          column.canSort ? (column?.isSorted ? (column.isSortedDesc ? "down" : "up") : "") : "disabled";

                        const sortedId = column.id;
                        const isSorted = column.isSorted;
                        const isSortedDesc = !!column.isSortedDesc;

                        // Updates sort id and order for child component
                        isSorted && setSortInformation && setSortInformation(sortedId, isSortedDesc);

                        const { key: headerKey, ...otherHeaderProps } = column.getHeaderProps(column.getSortByToggleProps());
                        const headerPropsToUse =
                          // @ts-ignore
                          typeof column.sortable === "undefined" || column.sortable
                            ? otherHeaderProps
                            : { ...otherHeaderProps, onClick: () => {} };
                        return (
                          <th {...headerPropsToUse} key={headerKey} className={`sortable ${sortClass} ${column.Header}`}>
                            <span>{column.render("Header")}</span>
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
            )}

        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            const rowClass = (index + 1) % 2 === 0 ? "even" : "odd";
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
                <tr {...row.getRowProps()} className={rowClass}>
                  {row.cells.map((cell) => {
                    const { key: cellKey, ...otherCellProps } = cell.getCellProps();

                    return (
                      <td {...otherCellProps} key={cellKey} className={isChildComponent ? style.childCell : ""}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
                {showChildRow || row.isExpanded ? (
                  <tr className={rowClass}>
                    <td colSpan={visibleColumns.length}>{renderRowSubComponent && renderRowSubComponent({ row })}</td>
                  </tr>
                ) : null}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </ErrorBoundary>
  );
};

export default DefaultTable;
