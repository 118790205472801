import moment from "moment";

export function getEuropeActivationParameters() {
  const parameters = new URLSearchParams(window.location.search);

  const dateRangeParam = parameters.get("date_range");
  const brandParam = parameters.get("brand");
  const marketParam = parameters.get("market");
  const regionParam = parameters.get("region");

  const marketGroupParam = parameters.get("market_group");
  const salesChannelParam = parameters.get("sales_channel");
  const modelParam = parameters.get("model");
  const dealerParam = parameters.get("dealer");
  const activationFormatParam = parameters.get("activation_format");
  const allRegPattern = new RegExp("All", "gi");
  const dateParam = dateRangeParam ? dateRangeParam?.split(",") : [];
  const bottomFiveDealersParam = parameters.get("bottom_five_dealers");
  const topFiveDealersParam = parameters.get("top_five_dealers");
  const start_date =
    dateParam && moment(dateParam[0], "MMMM YYYY").isValid() ? moment(dateParam[0], "MMMM YYYY")?.format("YYYY-MM-DD") : undefined;

  const end_date =
    dateParam && moment(dateParam[dateParam.length - 1], "MMMM YYYY").isValid()
      ? moment(dateParam[dateParam.length - 1], "MMMM YYYY")
          ?.endOf("month")
          .format("YYYY-MM-DD")
      : undefined;

  return {
    start_date,
    end_date,
    brand: brandParam && !allRegPattern.test(brandParam) ? brandParam?.split(",") : [],
    market: marketParam && !allRegPattern.test(marketParam) ? marketParam?.split(",") : [],
    region: regionParam && !allRegPattern.test(regionParam) ? regionParam?.split(",") : [],
    market_groups: marketGroupParam ? marketGroupParam?.split(",") : [],
    model: modelParam && !allRegPattern.test(modelParam) ? modelParam?.split(",") : [],
    sales_channel: salesChannelParam && !allRegPattern.test(salesChannelParam) ? salesChannelParam?.split(",") : [],
    dealers:
      dealerParam && !allRegPattern.test(dealerParam)
        ? topFiveDealersParam === "true" || bottomFiveDealersParam === "true"
          ? []
          : dealerParam?.split(",")
        : [],
    activation_format: activationFormatParam ? activationFormatParam : "rates",
    top_five_dealers: topFiveDealersParam === "true",
    bottom_five_dealers: bottomFiveDealersParam === "true",
    all_models_selected: parameters.get("all_models_selected") === "true",
  };
}

export function getEuropeActivationParametersDownload() {
  return {
    ...getEuropeActivationParameters(),
    download: false,
    cumulative: false,
  };
}

export function getEuropeActivationCsvFIlter() {
  const parameters = new URLSearchParams(window.location.search);

  const dateRangeParam = parameters.get("date_range");
  const marketGroupParam = parameters.get("market_group");
  const salesChannelParam = parameters.get("sales_channel");
  const modelParam = parameters.get("model");
  // const dealerParam = parameters.get("dealer");
  const allRegPattern = new RegExp("All", "gi");
  const dateParam = dateRangeParam ? dateRangeParam?.split(",") : [];
  const start_date =
    dateParam && moment(dateParam[0], "MMMM YYYY").isValid() ? moment(dateParam[0], "MMMM YYYY")?.format("YYYY-MM-DD") : undefined;

  const end_date =
    dateParam && moment(dateParam[dateParam.length - 1], "MMMM YYYY").isValid()
      ? moment(dateParam[dateParam.length - 1], "MMMM YYYY")
          ?.endOf("month")
          .format("YYYY-MM-DD")
      : undefined;

  return {
    start_date,
    end_date,
    market_groups: marketGroupParam ? marketGroupParam?.split(",") : [],
    model: modelParam && !allRegPattern.test(modelParam) ? modelParam?.split(",") : [],
    sales_channel: salesChannelParam && !allRegPattern.test(salesChannelParam) ? salesChannelParam?.split(",") : [],
    // dealers: dealerParam?  dealerParam?.split(",") :[],
  };
}
