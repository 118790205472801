import { CircleBullet, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter, Tooltip } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component, ReactNode } from "react";
import WebFont from "webfontloader";
import { BLACK, PURPLE, TURQUOISE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { configureValueAxis, legendConfiguration } from "../helpers/configurations";
import { formatValueAxisUnits } from "../helpers/helpers";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  theme: ThemeInt;
  isLoading: boolean;
  currency: string;
  showRoundedPrice: boolean;
  modelList?: string[];
  seriesList: any;
  market: string;
}

class PriceSalesVolumeChart extends Component<Props> {
  chartId = "priceSalesVolumeChart";
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { data, theme, currency, showRoundedPrice, modelList, seriesList, market } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;

    this.chart.legend = legendConfiguration(theme);
    this.chart.colors.list = [color(PURPLE), color(TURQUOISE)];
    this.chart.paddingBottom = 10;

    // Create Axes
    const yAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(yAxis, theme);
    // yAxis.renderer.inversed = true;
    yAxis.renderer.grid.template.location = 0;
    yAxis.renderer.cellStartLocation = 0.1;
    yAxis.renderer.cellEndLocation = 0.9;

    let highRounded = 0;
    let highPrice = 0;
    const keys = Object.keys(data);
    yAxis.numberFormatter.numberFormat = "#.0a";

    keys?.map((key: string) => {
      data[key].map((val: { price_rounded: number; price: number }) => {
        if (val.price_rounded > highRounded) highRounded = val.price_rounded;
        if (val.price > highPrice) highPrice = val.price;
      });
    });
    formatValueAxisUnits(yAxis, market);
    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.baseGrid.disabled = true;
    formatValueAxisUnits(yAxis);

    const xAxis = this.chart.xAxes.push(new ValueAxis());
    configureValueAxis(xAxis, theme);
    xAxis.renderer.opposite = true;
    xAxis.renderer.labels.template.paddingBottom = 0;

    seriesList?.forEach((val: { name: string; seriesColor: string }, index: number) => {
      const series = this.chart.series.push(new LineSeries());
      series.data = data[val?.name];
      series.xAxis = xAxis;
      series.yAxis = yAxis;
      //series.name = val?.name?.toLowerCase().replace(/(^\w{1})|(\s+\w{1})|([/](\w{1}))/g, (letter) => letter.toUpperCase());
      series.name = val?.name;
      series.dataFields.valueX = "price_volume";
      series.dataFields.valueY = showRoundedPrice ? "price_rounded" : "price";
      series.strokeWidth = 3;
      series.zIndex = index == 0 ? 10 : 0;
      series.stroke = color(val?.seriesColor);
      series.fill = color(val?.seriesColor);
      series.tensionY = showRoundedPrice ? 0.7 : 1;
      if (highRounded > 1000000) {
        series.yAxis.numberFormatter.numberFormat = "#.0a";
        series.xAxis.numberFormatter = new NumberFormatter();
        series.xAxis.numberFormatter.numberFormat = "#";
      }
      const circleBullet = series.bullets.push(new CircleBullet());
      circleBullet.circle.radius = 2.5;
      circleBullet.tooltip = new Tooltip();
      circleBullet.tooltipText = `[font-size: var(--regular_font_size);bold]{name}[/]\n[font-size: var(--regular_font_size)]Price: ${currency}{valueY.formatNumber('#,###')}[/]\n[font-size: var(--regular_font_size)]Volume: {valueX.formatNumber('#,###')}[/]\n `;
      circleBullet.tooltip.getFillFromObject = false;
      circleBullet.tooltip.background.fill = color(val.seriesColor);
      circleBullet.tooltip.label.fill = color(BLACK);
    });
  }

  render(): ReactNode {
    return (
      <div className={"chartWrapper"}>
        <div id={"priceSalesVolumeChart"} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default PriceSalesVolumeChart;
