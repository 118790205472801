import moment from "moment";
import { DIS_PRESET_DATE_PARAMS } from "../constants";
import { fetchDataV2, newApiFetchV2 } from "./utils";

export const msrpApiParamsToObject = (models?: string) => {
  const parameters = new URLSearchParams(window.location.search);

  const dateRangeParam = parameters?.get("date_range");
  const brandParameter = parameters?.get("brand");
  const marketParameter = parameters?.get("market") == "All" ? ["All"] : parameters?.get("market")?.split(",");
  const currencyParameter = parameters?.get("currency");

  const paramObj: any = {
    brand: brandParameter,
    market: marketParameter,
    currency: currencyParameter,
  };

  if (models) {
    paramObj["model"] = models == "All" ? [] : models.split(",");
  }

  if (dateRangeParam) {
    if (DIS_PRESET_DATE_PARAMS.includes(dateRangeParam)) {
      paramObj["date_range"] = dateRangeParam.split(" ").join("_").toLocaleLowerCase();
    } else {
      paramObj["date_range"] = "custom";
      paramObj["start_date"] = moment(dateRangeParam.split("-")[0], "MMMM YYYY").format("YYYY-MM-DD");
      paramObj["end_date"] = moment(dateRangeParam.split("-")[1], "MMMM YYYY").endOf("month").format("YYYY-MM-DD");
    }
  }

  return paramObj;
};

export const getMsrpSummary = () => newApiFetchV2("/msrp/summary", { ...msrpApiParamsToObject() });
export const getSalesByModel = () => newApiFetchV2("/msrp/sales-by-model", { ...msrpApiParamsToObject() });
export const getDifferenceByModel = () => newApiFetchV2("/msrp/difference-by-model", { ...msrpApiParamsToObject() });
export const getVmeDifferenceByModel = () => newApiFetchV2("/msrp/vme-difference-by-model", { ...msrpApiParamsToObject() });
export const getTotalValueDisByMonth = () => newApiFetchV2("/msrp/total-value-dis-by-month", { ...msrpApiParamsToObject() });
export const getMsrpVmeDifference = () => newApiFetchV2("/msrp/vme-difference", { ...msrpApiParamsToObject() });
export const getMsrpProfileByMonth = () => newApiFetchV2("/msrp/profile-by-month", { ...msrpApiParamsToObject() });
export const getMsrpModelPerformance = (models: string) => newApiFetchV2("/msrp/table-by-model", { ...msrpApiParamsToObject(models) });
export const getMsrpOverTimeByModel = (models: string) => newApiFetchV2("/msrp/over-time-by-model", { ...msrpApiParamsToObject(models) });
export const getVmeDiscountOverTimeByModel = (models: string) =>
  newApiFetchV2("/msrp/discount-over-time-by-model", { ...msrpApiParamsToObject(models) });
export const getDisByModelAndMonth = () => newApiFetchV2("/msrp/dis-by-model-and-month", { ...msrpApiParamsToObject() });
export const getTotalAddedValue = () => newApiFetchV2("/msrp/total-added-value", { ...msrpApiParamsToObject() });
export const getTopFiveModels = () => newApiFetchV2("/msrp/top-five-models", { ...msrpApiParamsToObject() });
export const getMsrpLastDataRefresh = () => {
  return fetchDataV2(`/msrp/last-refresh-date`);
};
