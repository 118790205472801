import { useEffect, useRef, useState } from "react";

export function useVisible(initialIsVisible: boolean): any {
  const wrapperRef = useRef<HTMLElement>(null);
  const [isVisible, setIsVisible] = useState(initialIsVisible);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event: Event) => {
    const currentElement = event.target as Element;
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node) && !currentElement.localName.includes("summary")) {
      setIsVisible(false);
    }
  };

  return { wrapperRef, isVisible, setIsVisible };
}
