import { ErrorBoundary } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { setParameters, setSpecificParameter } from "../../actions";
import { getDisFilters } from "../../api";
import { ALL_OPTION_NO_SPACE, DEFAULT_PURCHASE_FUNNEL_PARAMETERS, PURCHASE_FUNNEL_DATE_FILTER_OPTIONS } from "../../constants";
import { FilterContext } from "../../context";
import { isMonthlyCustomDate } from "../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { currentDefaultFiltersSet, defaultFiltersSet } from "../../utils/utilityFunctions";
import { ErrorMsg } from "../AppMessages";
import { FiltersLayout } from "../Layouts";
import { DefaultFilter, FilterBtns, MultiSelectFilter } from "./common";
import { DigitalLeadsSourcesFilter } from "./common/DigitalLeadsSourcesFilter";
import PurchaseFunnelDateFilter from "./common/PurchaseFunnelDateFilter";
import { closeFilterOptions, closeMarketOptions } from "./subs/helpers";

const FILTER_BTNS = [
  {
    id: "msrp_dashboard_btn",
    name: "See MSRP",
    navigateTo: "/purchase_funnel_report/msrp",
  },
];

export const PurchaseFunnelReportFilters = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { history } = props;
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);
  const params = useSelector((state: RootStateOrAny) => state.purchase_funnel_parameters);
  const {
    date_range: dateRangeParamValue,
    market: marketParamValue,
    brand: brandParamValue,
    lead_group: leadGroupParamValue,
    leads_source: leadsSourceParamValue,
  } = params;
  const [marketFilterValue, setMarketFilterValue] = useState(marketParamValue);
  const [leadsSourceFilterValue, setLeadsSourceFilterValue] = useState(leadsSourceParamValue);

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("DIS reporting");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_PURCHASE_FUNNEL_PARAMETERS));
      currentDefaultFiltersSet("DIS reporting");
    }
  }, []);

  const { data, refetch } = useQuery({
    queryKey: ["disFiltersData"],
    queryFn: getDisFilters,
    enabled: false,
    initialData: {
      brand: [],
      market: [],
      leads_source: [],
    },
  });

  const { brand, market, leads_source } = data;

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  useEffect(() => {
    if (brand?.length && !brand?.includes(brandParamValue)) {
      dispatch(setSpecificParameter("brand", brand[0]));
    }
  }, [params, brand]);

  useEffect(() => {
    const selectedMarketsList = marketParamValue.split(",");
    if (market?.length == 1) {
      dispatch(setSpecificParameter("market", market[0]));
    } else if (
      selectedMarketsList.length == 1 &&
      marketParamValue != "All" &&
      market.length > 0 &&
      !market.includes(marketParamValue.trim())
    ) {
      dispatch(setSpecificParameter("market", "All"));
    }
  }, [marketParamValue, market]);

  //Reset date range param value
  useEffect(() => {
    if (dateRangeParamValue) {
      const isValidDate = PURCHASE_FUNNEL_DATE_FILTER_OPTIONS.includes(dateRangeParamValue) || isMonthlyCustomDate(dateRangeParamValue);
      if (!isValidDate) dispatch(setSpecificParameter("date_range", "This financial year"));
    }
  }, [dateRangeParamValue]);

  // Set market filter value
  useEffect(() => {
    if (market && marketParamValue) {
      const selectedMarketsArr = marketParamValue.split(",");
      const marketSourceValue =
        selectedMarketsArr?.length === market.length
          ? "All"
          : selectedMarketsArr?.length > 1
            ? `${selectedMarketsArr?.length} markets selected`
            : marketParamValue;

      setMarketFilterValue(marketSourceValue);
    } else {
      setMarketFilterValue(ALL_OPTION_NO_SPACE);
    }
  }, [market, marketParamValue]);

  // Set leads source filter value
  useEffect(() => {
    const leadSourcesOptions = leads_source?.flatMap((item: any) => item.lead_source);

    if (leadSourcesOptions && leadsSourceParamValue) {
      const selectedLeadsSourcesArr = leadsSourceParamValue.split(",");
      const leadsSourceSourceValue =
        selectedLeadsSourcesArr?.length === leadSourcesOptions.length
          ? "All"
          : selectedLeadsSourcesArr?.length > 1
            ? `${selectedLeadsSourcesArr?.length} leads sources selected`
            : leadsSourceParamValue;

      setLeadsSourceFilterValue(leadsSourceSourceValue);
    }
  }, [leads_source, leadsSourceParamValue, marketParamValue, brandParamValue]);

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));

      eventTracking(MixpanelEvents.filter_change, {
        filter: filterName,
        value: optionValue,
        dashboard: "Purchase Funnel",
        page: "Purchase Funnel",
      });
    }
    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);
  };

  const handleMarketFilterSubmission = useCallback(() => {
    const parameterName = "market";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = selectedValues?.length === market?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    if (selectedValues.includes("Global")) {
      history.push({ pathname: "purchase_funnel", search: `?market=$Global` });
    }
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, {
      filter: parameterName,
      value: filterValue,
      dashboard: "Purchase Funnel",
      page: "New purchase funnel",
    });
    setShowFilterOptions(false);
  }, [market]);

  // Set params
  useEffect(() => {
    const params = new URLSearchParams();

    params.set("date_range", dateRangeParamValue);
    params.set("brand", brandParamValue);
    params.set("market", marketParamValue);
    params.set("lead_group", leadGroupParamValue);
    params.set("leads_source", leadsSourceParamValue);

    history.push({ search: `?${params.toString()}` });
  }, [dateRangeParamValue, brandParamValue, marketParamValue, leadGroupParamValue, leadsSourceParamValue]);

  //reset filter button functionality
  const resetFilters = useCallback(() => {
    dispatch(setParameters(DEFAULT_PURCHASE_FUNNEL_PARAMETERS));
  }, []);

  const digitalLeadsSourceOptions = useMemo(() => {
    if (leads_source) {
      const options: any = [{ value: "All", checked: false }];
      leads_source.forEach((leadsGroup: any) => {
        options.push({
          value: leadsGroup.lead_group,
          checked: false,
          indeterminate: false,
          options: leadsGroup.lead_source.map((item: string) => ({
            value: item,
            checked: false,
          })),
        });
      });
      return options;
    }
    return [];
  }, [leads_source]);

  return (
    <FiltersLayout resetFilters={resetFilters}>
      <>
        {/*Date range*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <PurchaseFunnelDateFilter
            value={dateRangeParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
            onFilterClick={showFilterOptionsFn}
          />
        </ErrorBoundary>

        {/*Brand*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            filterName={"brand"}
            list={brand}
            filterValue={brandParamValue}
            filterLabel={"BRAND"}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Geography*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          {market?.length > 1 ? (
            <MultiSelectFilter
              parentKey="market"
              filterName={"GEOGRAPHY"}
              // @ts-ignore
              filterList={market}
              value={marketFilterValue}
              parameterName={"market"}
              parameterValue={marketParamValue}
              onShowFilterOptions={showFilterOptionsFn}
              handleFilterSubmission={handleMarketFilterSubmission}
            />
          ) : (
            <DefaultFilter
              filterName={"market"}
              list={market}
              filterValue={marketParamValue}
              filterLabel={"GEOGRAPHY"}
              handleFilterOptionClick={handleFilterOptionClick}
            />
          )}
        </ErrorBoundary>

        <ErrorBoundary fallback={<ErrorMsg />}>
          {/*Digital leads source*/}
          <DigitalLeadsSourcesFilter
            parentKey="leads_source"
            filterList={digitalLeadsSourceOptions}
            filterName="LEAD SOURCE"
            value={leadsSourceFilterValue}
            parameterName={"leads_source"}
            parameterValue={leadsSourceParamValue}
            onShowFilterOptions={showFilterOptionsFn}
            dashboard="Purchase Funnel"
          />
        </ErrorBoundary>
        {marketParamValue === "United States" && (
          <FilterBtns id="purchase_funnel_btn" navigateTo="/purchase_funnel/diagnostic" name="See diagnostic view" />
        )}

        {FILTER_BTNS.map((btn) => (
          <FilterBtns id={btn.id} navigateTo={btn.navigateTo} name={btn.name} key={btn.id} />
        ))}
      </>
    </FiltersLayout>
  );
});
