import React, { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { NoDataMsg } from "../../../../components/AppMessages";
import SpendVsTrafficChart from "../../../../components/Charts/Midas/SpendVsTrafficChart";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { currencies } from "../../../../constants/currenctConstants";
import { ThemeContext } from "../../../../context";

interface Props {
  metric: string;
  loading: boolean;
  data: any;
  isModelChart?: boolean;
  model?: string;
}

const SpendVsTrafficBreakdown = (props: Props) => {
  const themeContext = useContext(ThemeContext);

  const { metric, loading, data, isModelChart, model } = props;

  const granularity = useSelector((state: RootStateOrAny) => state.parameters.granularity);
  const currency = useSelector((state: RootStateOrAny) => state.parameters.local_currency_name);

  const currencySymbol = useMemo(() => {
    //@ts-ignore
    if (currencies[currency] != undefined) {
      //@ts-ignore
      return currencies[currency]["symbol"];
    }
  }, [currency]);

  const modelChartData = useMemo(() => {
    if (model && isModelChart && data?.length) {
      return data?.filter((value: { model: string }) => value.model === model);
    }
    return data;
  }, [model, isModelChart, data]);

  return (
    <div className="module_chart primary_background">
      <div className="chart_content">
        {data?.length && !loading ? (
          <SpendVsTrafficChart
            data={isModelChart ? modelChartData : data}
            chartId={`${isModelChart ? `${model}model` : ""}${metric}MidasChart`}
            theme={themeContext.theme}
            isLoading={loading}
            seriesList={["metric_yoy", "metric"]}
            metric={metric == "visits" ? "Web" : metric}
            granularity={granularity}
            currency={currencySymbol}
          />
        ) : loading ? (
          <LoadingEllipsis isLoading={loading} />
        ) : (
          <NoDataMsg message="No data available" />
        )}
      </div>
    </div>
  );
};

export default SpendVsTrafficBreakdown;
