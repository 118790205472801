import React from "react";
import { BusinessRevenueTiles, CockpitTitle } from "../normalSections";

export const BusinessRevenueMetric = () => {
  return (
    <>
      <CockpitTitle title={"Business revenue"} hide_on_desktop={true} />
      <BusinessRevenueTiles showMetric={true} />

      <CockpitTitle title={"Customer quality"} hide_on_desktop={true} />
      <CockpitTitle title={"Engagement"} hide_on_desktop={true} />
      <CockpitTitle title={"Operational"} hide_on_desktop={true} />
    </>
  );
};
