import React, { useCallback, useContext, useState, useEffect, useMemo } from "react";
import { FilterContext } from "../../../context";
import { closeFilterOptions } from "../subs/helpers";

export const DMEOCampaignNameFilter = (props: {
  list: string[];
  filterName: string;
  filterLabel: string;
  filterValue: string;
  hidden?: boolean;
  extraClass?: string;
  handleFilterOptionClick: (evt: React.MouseEvent<HTMLElement>) => void;
}) => {
  const { list, filterName, filterLabel, filterValue, hidden, handleFilterOptionClick, extraClass } = props;
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const [searchString, setSearchString] = useState<string>("");

  useEffect(() => {
    setSearchString("");
  }, []);

  const filteredCampaignName = useMemo(() => {
    if (list?.length && list.length > 0 && searchString?.length) {
      return list.filter((campaignName: string) => campaignName.toLowerCase()?.includes(searchString?.toString()?.toLowerCase()));
    }
    return list;
  }, [list, searchString]);

  const showFilterOptionsFn = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const optionsId = dataset?.optionsUl;
      const shownClass = "showOptions";

      const optionsElement = optionsId ? document.getElementById(optionsId) : undefined;

      if (showFilterOptions && optionsElement?.classList.contains(shownClass)) {
        closeFilterOptions();
        setShowFilterOptions(false);
      } else {
        closeFilterOptions();
        optionsElement?.classList.toggle(shownClass);
        setShowFilterOptions(!showFilterOptions);
      }
    },
    [showFilterOptions, setShowFilterOptions]
  );

  //Displays options
  const onRenderOptions = ({ list, filter }: { list: Array<string>; filter: string }): JSX.Element => (
    <>
      {filteredCampaignName?.length > 0 ? (
        list?.map((item: string) => (
          <li className="default-filter-option" key={item} data-filter={filter} onClick={handleFilterOptionClick} data-value={item}>
            {item}
          </li>
        ))
      ) : (
        <li className="default-filter-option" key="No Data">
          No result found
        </li>
      )}
    </>
  );

  return (
    <div className={`filter ${hidden ? "hidden" : ""}`} id={`${filterName}_filter_div`}>
      <label className="filter_header" htmlFor={`${filterName}_filter`}>
        {filterLabel}
      </label>
      <div className="input_arrow" onClick={showFilterOptionsFn} data-options-ul={`${filterName}_ul`}>
        <input
          readOnly
          type="text"
          name={filterName}
          id={`${filterName}_filter`}
          className={"filter_input"}
          data-test-id="group"
          value={filterValue}
        />
        <span className="arrow_down " />
      </div>

      <ul className={`${extraClass} filter_options`} id={`${filterName}_ul`} data-test-id={`${filterName}_list`}>
        <>
          <div className="filter_search_bar" id="">
            <input
              value={searchString}
              placeholder="Search campaign name"
              type="search"
              onChange={(e) => setSearchString(e.target.value)}
            />
          </div>
        </>

        {onRenderOptions({ list: filteredCampaignName, filter: filterName })}
      </ul>
    </div>
  );
};
