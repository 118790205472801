import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchEcommerceLastDataRefresh, setPageName } from "../../actions";
import { fetchInfluenceOfShoppingTools, fetchNonTdsLeads, fetchOrders, fetchTdsLeads } from "../../actions/ecommerceAtions";
import "../../assets/styles/pages/ecommerce.scss";
import EcommerceFilters from "../../components/Filters/EcommerceFilters";
import { DashboardLayout } from "../../components/Layouts";
import { DefaultPageContent } from "../../components/PageContent/DefaultPageContent";
import { PageTitle } from "../../components/PageContent/subs";
import EcommerceOrders from "./components/EcommerceOrders";
import InfluenceShoppingTools from "./components/InfluenceShoppingTools";
import Leads from "./components/Leads";
import VisistsDropoff from "./components/VisistsDropoff";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";

const Ecommerce = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();

  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.ecommerce_last_data_refresh);

  useEffect(() => {
    dispatch(setPageName("Ecommerce Performance"));
    dispatch(fetchEcommerceLastDataRefresh());
    eventTracking(MixpanelEvents.page_view, { dashboard: "Ecommerce" });
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchInfluenceOfShoppingTools());
    dispatch(fetchOrders());
    dispatch(fetchTdsLeads());
    dispatch(fetchNonTdsLeads());
  }, [history.location.search, dispatch]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<EcommerceFilters />}
        dataDocumentation="ecommerce"
        lastDataRefresh={lastDataRefresh}
        pageTitle={<PageTitle dataDocumentation="ecommerce" />}
      >
        <div className="ecommerce-page-container">
          <div className="row">
            <VisistsDropoff />
            <InfluenceShoppingTools />
          </div>
          <div className="row">
            <EcommerceOrders />
            <Leads />
          </div>
          {/* <div className="row">
            <FinancePenetration />
            <FinanceOffering />
          </div>
          <div className="row"></div> */}
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default Ecommerce;
