import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import MetricTile from "../tiles/MetricTile";
import ModelComparison from "./ModelComparison";
import TotalAddedValueTile from "./TotalAddedValueTile";

const MsrpSummary = () => {
  const { Current: CurrentMsrpSummary, YoY: YoyMsrpSummary } = useSelector((state: RootStateOrAny) => state.msrp.msrp_summary);
  const { msrp_summary: msrpSummaryLoading } = useSelector((state: RootStateOrAny) => state.loading);

  return (
    <>
      <div className="summary-row">
        <div className="summary-tiles">
          <TotalAddedValueTile />
          <div className="metric-tiles-wrapper">
            <MetricTile
              title="DIS % Sales"
              value={CurrentMsrpSummary?.perc_dis_sales}
              yoyValue={YoyMsrpSummary?.perc_dis_sales}
              isPercentage={true}
              isLoading={msrpSummaryLoading}
            />
            <MetricTile
              title="AVG DIS MSRP"
              value={CurrentMsrpSummary?.dis_msrp}
              yoyValue={YoyMsrpSummary?.dis_msrp}
              isPercentage={false}
              isLoading={msrpSummaryLoading}
              hidePts={true}
            />
            <MetricTile
              title="DIS VME"
              value={CurrentMsrpSummary?.perc_dis_vme}
              yoyValue={YoyMsrpSummary?.perc_dis_vme}
              isPercentage={true}
              isLoading={msrpSummaryLoading}
            />
            <MetricTile
              title="Non DIS % Sales"
              value={CurrentMsrpSummary?.perc_non_dis_sales}
              yoyValue={YoyMsrpSummary?.perc_non_dis_sales}
              isPercentage={true}
              isLoading={msrpSummaryLoading}
            />
            <MetricTile
              title="AVG Non DIS MSRP"
              value={CurrentMsrpSummary?.non_dis_msrp}
              yoyValue={YoyMsrpSummary?.non_dis_msrp}
              isPercentage={false}
              isLoading={msrpSummaryLoading}
              hidePts={true}
            />
            <MetricTile
              title="Non DIS VME"
              value={CurrentMsrpSummary?.perc_non_dis_vme}
              yoyValue={YoyMsrpSummary?.perc_non_dis_vme}
              isPercentage={true}
              isLoading={msrpSummaryLoading}
            />
          </div>
        </div>
        <ModelComparison />
      </div>
    </>
  );
};

export default MsrpSummary;
