import {
  SET_CSV_EXPERIENCE_RATING,
  SET_EXIT_SURVEY_DEVICE_DISTRIBUTION,
  SET_EXIT_SURVEY_FEEDBACK,
  SET_EXIT_SURVEY_NON_COMPLETION_REASONS,
  SET_EXIT_SURVEY_OBJECTIVE_COMPLETION,
  SET_EXIT_SURVEY_OBJECTIVE_COMPLETION_BY_MARKET,
  SET_EXIT_SURVEY_OBJECTIVE_COMPLETION_SELECTED_MARKETS,
  SET_EXIT_SURVEY_OBJECTIVE_COMPLETION_TREND,
  SET_FEEDBACK_COMMENTS,
  SET_FEEDBACK_COMMENTS_FILTERS,
  SET_MARKET_OVERVIEW,
  SET_RATING_PER_TOUCHPOINT_COMPLETION_RATE,
  SET_RATING_PER_TOUCHPOINT_EDITORIAL,
  SET_RATING_PER_TOUCHPOINT_ELECTRIFICATION,
  SET_RATING_PER_TOUCHPOINT_MARKET_BREAKDOWN,
  SET_RATING_PER_TOUCHPOINT_MEDIA_CAMPAIGNS,
  SET_RATING_PER_TOUCHPOINT_OTHER_TOUCHPOINTS,
  SET_RATING_PER_TOUCHPOINT_OWNERSHIP,
  SET_RATING_PER_TOUCHPOINT_VLP_FOCUS,
  SET_STAR_RATING_AVERAGE_RATING,
  SET_STAR_RATING_AVERAGE_RATING_BY_MARKET,
  SET_STAR_RATING_AVERAGE_RATING_SELECTED_MARKETS,
  SET_STAR_RATING_AVERAGE_RATING_TREND,
  SET_STAR_RATING_DEVICE_DISTRIBUTION,
  SET_STAR_RATING_FEEDBACK,
  SET_STAR_RATING_SCORE_DISTRIBUTION,
  SET_TOPIC_CLASSIFICATION_DISSATISFACTION,
  SET_TOPIC_CLASSIFICATION_SATISFACTION,
} from "../actions/actionTypes/vocActionTypes";

const initialState = {
  market_overview: [],
  star_rating: {
    average_rating: {},
    average_rating_trend: [],
    feedback: {},
    score_distribution: [],
    device_distribution: [],
    average_rating_by_market: {},
    average_of_selected_markets: {},
  },
  exit_survey: {
    objective_completion: {},
    feedback: {},
    objective_completion_trend: [],
    non_completion_reasons: [],
    device_distribution: [],
    objective_completion_by_market: {},
    objecive_completion_of_selected_markets: {},
  },
  rating_per_touchpoint: {
    market_breakdown: [],
    vlp_focus: [],
    electrification: [],
    other_touchpoints: [],
    media_campaigns: [],
    completion_rate: [],
    ownership: [],
    editorial: [],
  },
  topic_classification_dissatisfaction: {},
  topic_classification_satisfaction: {},
  feedback_comments: [],
  csv_experience_rating: [],
  feedback_comments_filters: {},
};

const vocReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_MARKET_OVERVIEW:
      return { ...state, market_overview: action.payload };

    case SET_STAR_RATING_AVERAGE_RATING:
      return { ...state, star_rating: { ...state.star_rating, average_rating: action.payload } };

    case SET_STAR_RATING_AVERAGE_RATING_TREND:
      return { ...state, star_rating: { ...state.star_rating, average_rating_trend: action.payload } };

    case SET_STAR_RATING_FEEDBACK:
      return { ...state, star_rating: { ...state.star_rating, feedback: action.payload } };

    case SET_STAR_RATING_SCORE_DISTRIBUTION:
      return { ...state, star_rating: { ...state.star_rating, score_distribution: action.payload } };

    case SET_STAR_RATING_DEVICE_DISTRIBUTION:
      return { ...state, star_rating: { ...state.star_rating, device_distribution: action.payload } };

    case SET_STAR_RATING_AVERAGE_RATING_BY_MARKET:
      return { ...state, star_rating: { ...state.star_rating, average_rating_by_market: action.payload } };

    case SET_STAR_RATING_AVERAGE_RATING_SELECTED_MARKETS:
      return { ...state, star_rating: { ...state.star_rating, average_of_selected_markets: action.payload } };

    case SET_EXIT_SURVEY_OBJECTIVE_COMPLETION:
      return { ...state, exit_survey: { ...state.exit_survey, objective_completion: action.payload } };

    case SET_EXIT_SURVEY_FEEDBACK:
      return { ...state, exit_survey: { ...state.exit_survey, feedback: action.payload } };

    case SET_EXIT_SURVEY_OBJECTIVE_COMPLETION_TREND:
      return { ...state, exit_survey: { ...state.exit_survey, objective_completion_trend: action.payload } };

    case SET_EXIT_SURVEY_NON_COMPLETION_REASONS:
      return { ...state, exit_survey: { ...state.exit_survey, non_completion_reasons: action.payload } };

    case SET_EXIT_SURVEY_DEVICE_DISTRIBUTION:
      return { ...state, exit_survey: { ...state.exit_survey, device_distribution: action.payload } };

    case SET_EXIT_SURVEY_OBJECTIVE_COMPLETION_BY_MARKET:
      return { ...state, exit_survey: { ...state.exit_survey, objective_completion_by_market: action.payload } };

    case SET_EXIT_SURVEY_OBJECTIVE_COMPLETION_SELECTED_MARKETS:
      return { ...state, exit_survey: { ...state.exit_survey, objecive_completion_of_selected_markets: action.payload } };

    case SET_RATING_PER_TOUCHPOINT_MARKET_BREAKDOWN:
      return { ...state, rating_per_touchpoint: { ...state.rating_per_touchpoint, market_breakdown: action.payload } };

    case SET_RATING_PER_TOUCHPOINT_VLP_FOCUS:
      return { ...state, rating_per_touchpoint: { ...state.rating_per_touchpoint, vlp_focus: action.payload } };

    case SET_RATING_PER_TOUCHPOINT_ELECTRIFICATION:
      return { ...state, rating_per_touchpoint: { ...state.rating_per_touchpoint, electrification: action.payload } };

    case SET_RATING_PER_TOUCHPOINT_OTHER_TOUCHPOINTS:
      return { ...state, rating_per_touchpoint: { ...state.rating_per_touchpoint, other_touchpoints: action.payload } };

    case SET_RATING_PER_TOUCHPOINT_MEDIA_CAMPAIGNS:
      return { ...state, rating_per_touchpoint: { ...state.rating_per_touchpoint, media_campaigns: action.payload } };

    case SET_RATING_PER_TOUCHPOINT_COMPLETION_RATE:
      return { ...state, rating_per_touchpoint: { ...state.rating_per_touchpoint, completion_rate: action.payload } };

    case SET_TOPIC_CLASSIFICATION_DISSATISFACTION:
      return { ...state, topic_classification_dissatisfaction: action.payload };

    case SET_TOPIC_CLASSIFICATION_SATISFACTION:
      return { ...state, topic_classification_satisfaction: action.payload };

    case SET_FEEDBACK_COMMENTS:
      return { ...state, feedback_comments: action.payload };

    case SET_RATING_PER_TOUCHPOINT_OWNERSHIP:
      return { ...state, rating_per_touchpoint: { ...state.rating_per_touchpoint, ownership: action.payload } };

    case SET_RATING_PER_TOUCHPOINT_EDITORIAL:
      return { ...state, rating_per_touchpoint: { ...state.rating_per_touchpoint, editorial: action.payload } };

    case SET_CSV_EXPERIENCE_RATING:
      return { ...state, csv_experience_rating: action.payload };

    case SET_FEEDBACK_COMMENTS_FILTERS:
      return { ...state, feedback_comments_filters: action.payload };

    default:
      return state;
  }
};

export default vocReducer;
