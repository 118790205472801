import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { setSpecificParameter } from "../../../../actions";
import { fetchAppRatings, fetchAppRatingsChartData, fetchAppReviewBreakdown } from "../../../../actions/ccsCockpitActions";
import { ErrorMsg } from "../../../../components/AppMessages";
import { AppRatingsLineGraph } from "../../../../components/Charts/CCSCockpit";
import { LegendMarker } from "../../../../components/Charts/components/LegendMarker";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../../components/Ratings/Ratings";
import { ALL_OPTION_NO_SPACE, CYAN, DARK_CYAN, LIGHT_CYAN, TURQUOISE } from "../../../../constants";
import {
  allAppRatingsServicesTitle,
  defaultAppRatingsServicesTitle,
  europeAppRatingsServicesTitle,
} from "../../../../constants/ccsConstants";
import { DashboardBannerContext, ThemeContext } from "../../../../context";
import { computeMetricConversion } from "../../../../utils/computeFunctions";
import { convertToAbsolutePercentage, isNull } from "../../../../utils/utilityFunctions";

interface Props {
  myNissanRating: number | null;
  comparisonMyNissanRating: number | null;
  myInfinitiRating: number | null;
  comparisonMyInfinitiRating: number | null;
  servicesRating: number | null;
  comparisonServicesRating: number | null;
  evRating: number | null;
  comparisonEvRating: number | null;
  isLoading: boolean;
  chartData?: Array<any>;
  onClick?: () => void;
}

export const AppReviewTile = (props: Props) => {
  const {
    myNissanRating,
    comparisonMyNissanRating,
    myInfinitiRating,
    comparisonMyInfinitiRating,
    servicesRating,
    evRating,
    comparisonEvRating,
    comparisonServicesRating,
    chartData,
    isLoading,
    onClick: handleClick,
  } = props;
  const themeContext = useContext(ThemeContext);

  const dispatch = useDispatch();

  const [androidChecked, setAndroidChecked] = useState(true);
  const [iosChecked, setIosChecked] = useState(true);

  const {
    date_range: dateParamValue,
    region: regionParamValue,
    model: modelParamValue,
    app_store: appStoreParamValue,
    brand: brandParamValue,
  } = useSelector((state: RootStateOrAny) => state.parameters);

  const comparisonLabel = "YoY";
  const [servicesTitle, setServicesTitle] = useState(defaultAppRatingsServicesTitle);
  const { bannerHovered } = useContext(DashboardBannerContext);

  useEffect(() => {
    if (regionParamValue) {
      /all/gi.test(regionParamValue)
        ? setServicesTitle(allAppRatingsServicesTitle)
        : regionParamValue === "Europe"
        ? setServicesTitle(europeAppRatingsServicesTitle)
        : setServicesTitle(defaultAppRatingsServicesTitle);
    }
  }, [regionParamValue]);

  useEffect(() => {
    Promise.all([
      dispatch(fetchAppReviewBreakdown(appStoreParamValue)),
      dispatch(fetchAppRatings(appStoreParamValue)),
      dispatch(fetchAppRatingsChartData(appStoreParamValue)),
    ]);
  }, [dispatch, appStoreParamValue]);

  useEffect(() => {
    if (iosChecked && androidChecked) {
      dispatch(setSpecificParameter("app_store", ALL_OPTION_NO_SPACE));
    } else if (iosChecked) {
      dispatch(setSpecificParameter("app_store", "iOS"));
    } else {
      dispatch(setSpecificParameter("app_store", "Android"));
    }
  }, [iosChecked, androidChecked, dispatch]);

  const [isAllNull, setIsAllNull] = useState(false);
  useEffect(() => {
    setIsAllNull(chartData?.every((element) => isNull(element.evRating) && isNull(element.servicesRating)) ? true : false);
  }, [chartData]);

  const updateSelectedAppStore = (app_store: string, appChecked: boolean) => {
    if (app_store === "iOS") {
      if (!androidChecked && appChecked) {
        setAndroidChecked(true);
        setIosChecked(false);
      } else {
        setIosChecked(!iosChecked);
      }
    }
    if (app_store === "Android") {
      if (!iosChecked && appChecked) {
        setAndroidChecked(false);
        setIosChecked(true);
      } else {
        setAndroidChecked(!androidChecked);
      }
    }
  };

  const isEvComparisonValid = comparisonEvRating && typeof comparisonEvRating !== "undefined" && !isNull(comparisonEvRating);
  const isServiceComparisonValid =
    comparisonServicesRating && typeof comparisonServicesRating !== "undefined" && !isNull(comparisonServicesRating);
  const isyNissanComparisonValid =
    comparisonMyNissanRating && typeof comparisonMyNissanRating !== "undefined" && !isNull(comparisonMyNissanRating);

  return (
    <div className={`tile star_divs`} data-test-id={"app_ratings"} id={"app_ratings"} onClick={handleClick}>
      <Link className="dashboard-link" to="/ccs_vital_signs/app_ratings">
        <ArrowCircleRightOutlinedIcon style={{ color: "#00a0dd" }} />
      </Link>
      <div className="tile_header">
        App ratings
        <Tooltip
          position="top-end"
          className={"normal_tippy"}
          trigger="mouseenter"
          animation="none"
          interactive={true}
          distance={50}
          delay={1}
          hideDelay={1}
          duration={1}
          title={"The app ratings are based on the latest month in the date range selected"}
          size={"small"}
        >
          <span className="info_tooltip" />
        </Tooltip>
      </div>

      <div className="CCS_main_content">
        <div className="tile_content">
          {chartData && (
            <div className="ratings_select">
              <div className="appstore_selection">
                <div className="store_selection">
                  <input
                    type="checkbox"
                    id="ios"
                    data-test-id="ios_radio_btn"
                    value="iOS"
                    checked={iosChecked}
                    onChange={() => updateSelectedAppStore("iOS", iosChecked)}
                  />
                  <label htmlFor="ios">iOS</label>
                </div>
                <div className="store_selection">
                  <input
                    type="checkbox"
                    id="android"
                    data-test-id="android_radio_btn"
                    value="Android"
                    checked={androidChecked}
                    onChange={() => updateSelectedAppStore("Android", androidChecked)}
                  />
                  <label htmlFor="android">Android</label>
                </div>
              </div>
            </div>
          )}

          {(regionParamValue == "North America" || regionParamValue == "UAE") && (
            <>
              {regionParamValue == "North America" && (
                <div id="my_nissan" className="child_content">
                  <div className="tile_header">
                    {chartData && <LegendMarker color={TURQUOISE} shape={"line"} />}
                    MyNissan
                  </div>
                  <div className="tile_content">
                    {myNissanRating && modelParamValue === "All" ? (
                      <>
                        <div className="value ratings">
                          <div className="rating_text_value">{myNissanRating.toFixed(1)}/5</div>
                        </div>

                        {isyNissanComparisonValid && (
                          <p className="period_comparison comparison">
                            <span className="main">
                              <Ratings value={String(comparisonMyNissanRating)} isPercentageValue={false} />
                              <span className="comparison_value">
                                {convertToAbsolutePercentage(comparisonMyNissanRating as number) === "n/a"
                                  ? "n/a"
                                  : convertToAbsolutePercentage(
                                      computeMetricConversion(
                                        comparisonMyNissanRating as number,
                                        myNissanRating - (comparisonMyNissanRating as number)
                                      ) as number,
                                      0
                                    )}
                              </span>{" "}
                              {comparisonLabel}
                            </span>
                          </p>
                        )}
                      </>
                    ) : regionParamValue != "All " && !myNissanRating ? (
                      <div className="value ratings">
                        <p className="value is_na_text">n/a</p>
                      </div>
                    ) : (
                      <p className="value is_na_text">n/a</p>
                    )}
                  </div>
                </div>
              )}

              {brandParamValue != "Nissan" && (
                <div id="my_infinity" className="child_content">
                  <div className="tile_header">
                    {chartData && <LegendMarker color={DARK_CYAN} shape={"line"} />}
                    MyInfinity
                  </div>
                  <div className="tile_content">
                    {myInfinitiRating && modelParamValue === "All" ? (
                      <>
                        <div className="value ratings">
                          <div className="rating_text_value">{myInfinitiRating.toFixed(1)}/5</div>
                        </div>

                        {isyNissanComparisonValid && (
                          <p className="period_comparison comparison">
                            <span className="main">
                              <Ratings value={String(comparisonMyInfinitiRating)} isPercentageValue={false} />
                              <span className="comparison_value">
                                {convertToAbsolutePercentage(comparisonMyInfinitiRating as number) === "n/a"
                                  ? "n/a"
                                  : convertToAbsolutePercentage(
                                      computeMetricConversion(
                                        comparisonMyInfinitiRating as number,
                                        myInfinitiRating - (comparisonMyInfinitiRating as number)
                                      ) as number,
                                      0
                                    )}
                              </span>{" "}
                              {comparisonLabel}
                            </span>
                          </p>
                        )}
                      </>
                    ) : regionParamValue != "All " && !myInfinitiRating ? (
                      <div className="value ratings">
                        <p className="value is_na_text">n/a</p>
                      </div>
                    ) : (
                      <p className="value is_na_text">n/a</p>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          <div id="EV_rating" className="child_content">
            <div className="tile_header">{chartData && <LegendMarker color={CYAN} shape={"line"} />}NissanConnect EV</div>
            <div className="tile_content">
              {evRating && modelParamValue === "All" ? (
                <>
                  <div className="value ratings">
                    <div className="rating_text_value">{evRating.toFixed(1)}/5</div>
                  </div>

                  {isEvComparisonValid && (
                    <p className="period_comparison comparison">
                      <span className="main">
                        <Ratings value={String(comparisonEvRating)} isPercentageValue={false} />
                        <span className="comparison_value">
                          {convertToAbsolutePercentage(
                            computeMetricConversion(comparisonEvRating as number, evRating - (comparisonEvRating as number)) as number,
                            0
                          )}
                        </span>{" "}
                        {comparisonLabel}
                      </span>
                    </p>
                  )}
                </>
              ) : regionParamValue != "All " && !evRating ? (
                <div className="value ratings">
                  <p className="value is_na_text">n/a</p>
                </div>
              ) : (
                <p className="value is_na_text">n/a</p>
              )}
            </div>
          </div>
          <div id="services_rating" className="child_content">
            <div className="tile_header">
              {chartData && <LegendMarker color={LIGHT_CYAN} shape={"line"} />}
              {servicesTitle}
            </div>
            <div className="tile_content">
              {servicesRating && modelParamValue === "All" ? (
                <>
                  <div className="value ratings">
                    <div className="rating_text_value">{servicesRating.toFixed(1)}/5</div>
                  </div>

                  {isServiceComparisonValid && (
                    <p className="period_comparison comparison">
                      <span className="main">
                        <Ratings value={String(comparisonServicesRating)} isPercentageValue={false} />
                        <span className="comparison_value">
                          {convertToAbsolutePercentage(comparisonServicesRating as number) === "n/a"
                            ? "n/a"
                            : convertToAbsolutePercentage(
                                computeMetricConversion(
                                  comparisonServicesRating as number,
                                  servicesRating - (comparisonServicesRating as number)
                                ) as number,
                                0
                              )}
                        </span>{" "}
                        {comparisonLabel}
                      </span>
                    </p>
                  )}
                </>
              ) : regionParamValue != "All " && !servicesRating ? (
                <div className="value ratings">
                  <p className="value is_na_text">n/a</p>
                </div>
              ) : (
                <p className="value is_na_text">n/a</p>
              )}
            </div>
          </div>
        </div>
        {chartData && (
          <div className={"metric_content"}>
            <ErrorBoundary fallback={<ErrorMsg />}>
              <AppRatingsLineGraph
                data={isAllNull ? [] : chartData}
                theme={themeContext.theme}
                region={regionParamValue}
                brand={brandParamValue}
              />
            </ErrorBoundary>
          </div>
        )}
      </div>
      <LoadingEllipsis isLoading={isLoading} />
    </div>
  );
};
