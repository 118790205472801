import React, { useContext, useEffect, useState } from "react";
import { ToggleSwitch } from "../../../components/Toogle";
import DmeoCostPerAqusitionLineChart from "../../../components/Charts/Dmeo/DmeoCostPerAquisitionLineChart";
import { ThemeContext } from "../../../context";
import { getCostPerAquisition } from "../../../api/dmeoApi";
import { useQuery } from "@tanstack/react-query";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Tooltip } from "react-tippy";

const CostPerAqusitionTile = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const { theme } = useContext(ThemeContext);

  const [isLeads, setIsLeads] = useState(true);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["costPerAqusitionData", isLeads],
    queryFn: () => getCostPerAquisition(isLeads),
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search, isLeads]);

  return (
    <div className="tile cost-per-aquisition-tile">
      <div className="tile-header">
        <div className="title">
          Cost per Aquisition
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            size={"small"}
            html={
              <div>
                <div>
                  <p>
                    <b>Leads:</b> Number of sessions where the user completes a lead after landing on the platform through a specific
                    campaign.
                  </p>
                  <p>
                    <b>KBAs:</b> Number of sessions where the user completes a Key Buying action after landing on the platform through a
                    specific campaign.
                  </p>
                </div>
              </div>
            }
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        <ToggleSwitch
          active={isLeads}
          activeToggleLabel="Leads"
          inactiveToggleLabel="KBAs"
          handleToggleClick={() => setIsLeads((prevState) => !prevState)}
        />
      </div>
      <div className="tile-content">
        <DmeoCostPerAqusitionLineChart
          chartId="costPerAquisitionLineChart"
          data={data?.data}
          seriesList={data?.series}
          isLoading={isFetching}
          theme={theme}
        />
      </div>
    </div>
  );
});

export default CostPerAqusitionTile;
