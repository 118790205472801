import * as React from "react";

interface Props {
  onHandleRadioClick: any;
  nissanCompetitor: string;
}

const CompetitorSelect = (props: Props) => {
  const { onHandleRadioClick, nissanCompetitor } = props;
  return (
    <div className="competitor_radios">
      <div className="trend" data-radio-value="nissan_competitor">
        <input
          type="radio"
          className="dp_radio "
          id={`nissan_competitor`}
          name={"nissan_competitor"}
          value="nissan_competitor"
          onChange={onHandleRadioClick}
          checked={nissanCompetitor === "nissan_competitor"}
        />
        <label htmlFor={`nissan_competitor`} className="dp_label trend_label" data-value="nissan_competitor">
          Nissan&apos;s competitor set
        </label>
        <br />
      </div>
      <div className="trend" data-radio-value="all_competitors">
        <input
          type="radio"
          className="dp_radio"
          id={`all_competitors`}
          name={"all_competitors"}
          value="all_competitors"
          onChange={onHandleRadioClick}
          checked={nissanCompetitor !== "nissan_competitor"}
        />
        <label htmlFor={`all_competitors`} className="dp_label trend_label" data-value="all_competitors">
          All competitors
        </label>
        <br />
      </div>
    </div>
  );
};

export default CompetitorSelect;
