import { ErrorBoundary } from "@sentry/react";
import React, { useEffect, useState } from "react";
import { ErrorMsg, NoDataMsg } from "../../../../components/AppMessages";
interface Props {
  isLoading: boolean;
  data: {
    region: string;
    marketRow: Array<string>;
    goLiveRow: Array<string>;
    lastThreeMonthsRow: Array<number | string | null>;
    previousQuarterRow: Array<number | string | null>;
    sinceSurveyLaunchRow: Array<number | string | null>;
  };
  isRating: boolean;
}
const MarketOverviewTable = (props: Props) => {
  const { isLoading, data, isRating } = props;
  const [tableData, setTableData] = useState<any>([]);

  useEffect(() => {
    setTableData(data);
  }, [data]);
  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className="tableContainer">
        {tableData ? (
          <table>
            <thead>
              <tr>
                <th>{tableData.region}</th>
                {tableData.marketRow &&
                  tableData.marketRow.map((market: any, index: number) => <th key={`market-${market}-${index}`}>{market}</th>)}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="italic">Go live</td>
                {tableData.goLiveRow &&
                  tableData.goLiveRow.map((rowText: any, index: number) => (
                    <td key={`go-live${rowText}-${index}`} className="italic">
                      {rowText}
                    </td>
                  ))}
              </tr>
              <tr>
                <td className="italic">Selected period</td>
                {tableData.lastThreeMonthsRow &&
                  tableData.lastThreeMonthsRow.map((rowData: any, index: number) => (
                    <td
                      key={`selected-period${rowData}-${index}`}
                      className={`${
                        isRating
                          ? typeof rowData == "number" && rowData >= 4
                            ? "textGreen"
                            : typeof rowData == "number" && rowData >= 3.9
                            ? "textAmber"
                            : typeof rowData == "number"
                            ? "textRed"
                            : ""
                          : ""
                      }`}
                    >
                      {rowData ? (
                        typeof rowData == "number" ? (
                          isRating ? (
                            rowData.toFixed(1)
                          ) : (
                            rowData.toLocaleString()
                          )
                        ) : (
                          rowData
                        )
                      ) : (
                        <p>&#8725;</p>
                      )}
                    </td>
                  ))}
              </tr>
              <tr>
                <td className="italic">Previous period</td>
                {tableData.previousQuarterRow &&
                  tableData.previousQuarterRow.map((rowData: any, index: number) => (
                    <td
                      key={`previous-period${rowData}-${index}`}
                      className={`${
                        isRating
                          ? typeof rowData == "number" && rowData >= 4
                            ? "textGreen"
                            : typeof rowData == "number" && rowData >= 3.9
                            ? "textAmber"
                            : typeof rowData == "number"
                            ? "textRed"
                            : ""
                          : ""
                      }`}
                    >
                      {rowData ? (
                        typeof rowData == "number" ? (
                          isRating ? (
                            rowData.toFixed(1)
                          ) : (
                            rowData.toLocaleString()
                          )
                        ) : (
                          rowData
                        )
                      ) : (
                        <p>&#8725;</p>
                      )}
                    </td>
                  ))}
              </tr>
              <tr>
                <td className="italic">Since survey launch</td>
                {tableData.sinceSurveyLaunchRow &&
                  tableData.sinceSurveyLaunchRow.map((rowData: any, index: number) => (
                    <td
                      key={`since-survey${rowData}-${index}`}
                      className={`${
                        isRating
                          ? typeof rowData == "number" && rowData >= 4
                            ? "textGreen"
                            : typeof rowData == "number" && rowData >= 3.9
                            ? "textAmber"
                            : typeof rowData == "number"
                            ? "textRed"
                            : ""
                          : ""
                      }`}
                    >
                      {rowData ? (
                        typeof rowData == "number" ? (
                          isRating ? (
                            rowData.toFixed(1)
                          ) : (
                            rowData.toLocaleString()
                          )
                        ) : (
                          rowData
                        )
                      ) : (
                        <p>&#8725;</p>
                      )}
                    </td>
                  ))}
              </tr>
            </tbody>
          </table>
        ) : isLoading ? null : (
          <NoDataMsg />
        )}
      </div>
    </ErrorBoundary>
  );
};
export default MarketOverviewTable;
