import { ColumnSeries, DateAxis, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, percent } from "@amcharts/amcharts4/core";
import _, { isNull } from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, DIGITAL_LEADS_SOURCE_COLORS, WHITE } from "../../../constants";
import { formatToThreeDigits } from "../../../utils/numberFormatting";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { columnSeriesConfiguration, configureDateAxis, configureValueAxis, legendConfiguration } from "../helpers/configurations";
import { formatDateAxisLabelToMonthly } from "../helpers/mtmConfigurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  chartName: string;
  isLoading: boolean;
  theme: ThemeInt;
  topics: any;
}

interface ISeries {
  name: string;
  field: string;
  seriesId: any;
  index: number;
}

class AppRatingsBarStackedChart extends Component<Props> {
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { data, chartName, theme, topics } = this.props;

    this.chart = create(chartName, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.colors.list = DIGITAL_LEADS_SOURCE_COLORS.map((chartColor: string) => color(chartColor));
    //Add legends
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.itemContainers.template.paddingTop = 2.5;
    this.chart.legend.itemContainers.template.marginBottom = 25;

    //Default x axis to show current date
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.minGridDistance = 30;

    //Y Axis that is used by default
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.numberFormatter.numberFormat = "#a";

    const createSeries = ({ name, field, seriesId, index }: ISeries) => {
      seriesId = this.chart.series.push(new ColumnSeries());
      columnSeriesConfiguration(seriesId, {
        name: name,
        valueY: field,
        stacked: true,
      });
      seriesId.dataFields.dateX = "date";
      seriesId.columns.template.tooltipText = "{dateX} {name}: {valueY.formatNumber('#,###')}";
      seriesId.columns.template.width = percent(65);
      seriesId.tooltip.getFillFromObject = false;
      seriesId.tooltip.autoTextColor = false;
      seriesId.tooltip.background.fill = color(WHITE);
      seriesId.tooltip.label.fill = color(BLACK);

      seriesId.columns.template.adapter.add("tooltipText", (value: any, target: any) => {
        const dateValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };
        let text = `[bold font-size: var(--regular_font_size)]${formatDateAxisLabelToMonthly(dateValue?.date)}[/]\n`;
        this.chart.series.each((item) => {
          if (!item.isHidden) {
            const value = item.dataFields.valueY && dateValue ? (dateValue[item.dataFields.valueY] as number) : null;

            text += `[font-size: var(--regular_font_size) ${item.stroke}]●[/] [font-size: var(--regular_font_size)]${
              item.name
            }: [font-size: var(--regular_font_size)]${isNull(value) ? "n/a" : formatToThreeDigits(value as number)}\n`;
          }
        });
        return text;
      });
    };

    topics?.map((topic: any, ind: number) => {
      return createSeries({ name: topic?.name, field: topic?.field, seriesId: `series${ind + 1}`, index: ind });
    });
  }
  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default AppRatingsBarStackedChart;
