// Todo: Move some effects to sagas instead of useEffect
import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchNewPurchaseFunnelFilters, setParameters, setSpecificParameter } from "../../actions";
import "../../assets/styles/component/filters.scss";
import { DEFAULT_PURCHASE_FUNNEL_PARAMETERS, NEW_PURCHASE_FUNNEL_MARKETS } from "../../constants";
import { IBrandSource, IUserGroupMarkets } from "../../constants/interface";
import { FilterContext } from "../../context";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { ErrorMsg } from "../AppMessages";
import { FiltersLayout } from "../Layouts";
import { DefaultFilter, FilterBtns, MultiSelectFilter } from "./common";
import { closeFilterOptions, closeMarketOptions } from "./subs/helpers";

export const PurchaseFunnelFilters = withRouter((props: RouteComponentProps) => {
  const userGroupMarkets: Array<IUserGroupMarkets> = useSelector((state: RootStateOrAny) => state.filters.user_group_markets);
  const [marketFilterValue, setMarketFilterValue] = useState("");
  const [marketsList, setMarketsList] = useState<Array<string>>([]);
  const [brandsList, setBrandsList] = useState<Array<string>>([]);
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const params = useSelector((state: RootStateOrAny) => state.purchase_funnel_parameters);
  const { date_range: dateRangeParamValue, market: marketParamValue, brand: brandParamValue, lead_source: leadsSourceParamValue } = params;

  const { history } = props;
  const dispatch = useDispatch();
  const allFilters = useSelector((state: RootStateOrAny) => state.filters.purchase_funnel_filters);

  useEffect(() => {
    const selectedMarketsList = marketParamValue.split(",");
    if (selectedMarketsList.length > 1 || NEW_PURCHASE_FUNNEL_MARKETS.includes(marketParamValue.trim())) {
      history.push({ pathname: "purchase_funnel_report", search: `?market=${marketParamValue}` });
    }
  }, [marketParamValue, marketsList]);

  useEffect(() => {
    Promise.all([dispatch(fetchNewPurchaseFunnelFilters())]);
  }, [dispatch]);

  useEffect(() => {
    if (brandsList.length && !brandsList.includes(brandParamValue)) {
      if (brandsList.includes("Nissan")) {
        dispatch(setSpecificParameter("brand", "Nissan"));
      } else {
        dispatch(setSpecificParameter("brand", brandsList[0]));
      }
    }
  }, [params, brandsList]);

  //Update brandlist with brands from user group markets
  useEffect(() => {
    const list: string[] = [];
    for (const val of userGroupMarkets) {
      if (!list.includes(val.brand)) {
        list.push(val.brand);
      }
    }
    setBrandsList(list);
  }, [userGroupMarkets]);

  //Update Market list with markets for selected brand
  useEffect(() => {
    const list: any = [];
    for (const marketFilters of allFilters) {
      marketFilters["brand_source"].map((brandSource: IBrandSource) => {
        if (brandSource.brand === brandParamValue) {
          list.push(marketFilters.market);
        }
      });
    }
    setMarketsList(list);
  }, [allFilters, brandParamValue, brandsList]);

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      eventTracking(MixpanelEvents.filter_change, { filter: filterName, value: optionValue, dashboard: "Purchase Funnel" });
      dispatch(setSpecificParameter(filterName, optionValue));
    }
    closeFilterOptions();
  };

  //Update the params whenever the market filter changes
  useEffect(() => {
    if (marketsList && marketParamValue) {
      const params = new URLSearchParams();
      params.set("market", marketParamValue);
      history.push({ search: `?${params.toString()}` });
      const selectedMarketsArr = marketParamValue.split(",");
      const marketSourceValue =
        selectedMarketsArr?.length === marketsList.length
          ? "All"
          : selectedMarketsArr?.length > 1
          ? `${selectedMarketsArr?.length} markets selected`
          : marketParamValue;

      setMarketFilterValue(marketSourceValue);
    }
  }, [marketsList, marketParamValue]);

  //Reset the market value if the market passed is not valid
  useEffect(() => {
    if (marketsList) {
      const params = new URLSearchParams(history.location.search);
      const marketParamValue = params.get("market");
      const defaultMarket = marketParamValue && marketsList.includes(marketParamValue) ? marketParamValue : "All";
      setMarketFilterValue(defaultMarket);
    }
  }, [marketsList]);

  const resetFilters = useCallback(() => {
    dispatch(setParameters(DEFAULT_PURCHASE_FUNNEL_PARAMETERS));
  }, []);

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  const handleMarketFilterSubmission = useCallback(() => {
    const parameterName = "market";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = selectedValues?.length === marketsList?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, {
      filter: parameterName,
      value: filterValue,
      dashboard: "Purchase Funnel",
      page: "New purchase funnel",
    });
  }, [marketsList]);

  return (
    <FiltersLayout extraClass={"iframeFilters"} resetFilters={resetFilters}>
      <>
        {/*Brand*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            filterName={"brand"}
            list={brandsList}
            filterValue={brandParamValue}
            filterLabel={"BRAND"}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Geography*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <MultiSelectFilter
            parentKey="market"
            filterName={"GEOGRAPHY"}
            // @ts-ignore
            filterList={marketsList}
            value={marketFilterValue}
            parameterName={"market"}
            parameterValue={marketParamValue ? marketParamValue : ""}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleMarketFilterSubmission}
          />
        </ErrorBoundary>

        {marketFilterValue === "United States" && (
          <FilterBtns id="purchase_funnel_btn" navigateTo="/purchase_funnel/diagnostic" name="See diagnostic view" />
        )}
      </>
    </FiltersLayout>
  );
});
