import { newApiFetchV2 } from "./utils";

const getParams = () => {
  const params = window.location.search;
  const parameters = new URLSearchParams(params);

  const date_range = parameters.get("date_range")?.split(",") || parameters.get("date_range");
  const region = parameters.get("region") ? parameters.get("region")?.split(",") : [];
  const market =
    parameters.get("market") === "All" || parameters.get("market") === "All countries" ? [] : parameters.get("market")?.split(",");
  const model = parameters.get("model") === "All" ? [] : parameters.get("model")?.split(",");
  const brand = parameters.get("brand") ? (parameters.get("brand") == "All" ? [] : parameters.get("brand")?.split(",")) : [];
  const topFiveDealers = parameters.get("top_five_dealers") ? parameters.get("top_five_dealers") : false;
  const bottomFiveDealers = parameters.get("bottom_five_dealers") ? parameters.get("bottom_five_dealers") : false;
  const dealer = topFiveDealers || bottomFiveDealers ? [] : parameters.get("dealer") ? parameters.get("dealer")?.split(",") : [];
  const salesChannel = parameters.get("sales_channel") ? parameters.get("sales_channel") : "All";
  const marketGroup = parameters.get("market_group") ? parameters.get("market_group") : [];

  const parameterEntries: Record<string, string | null | Array<string> | undefined | boolean> = {
    date_range,
    brand,
    market,
    region,
    model,
    sales_channel: salesChannel,
    dealer,
    market_group: marketGroup,
    bottom_five_dealers: topFiveDealers,
    top_five_dealers: bottomFiveDealers,
  };

  return parameterEntries;
};

export const getActivationRatioData = () => newApiFetchV2("/ccs-activation-ratio/get-activation-ratio-data", { ...getParams() });
