import {
  FETCH_FLOP_SERVICES,
  FETCH_SERVICE_GROUPS,
  FETCH_TOP_FLOP_FILTERS,
  FETCH_TOP_FLOP_SERVICES,
  FETCH_TOP_MODELS,
  FETCH_TOP_REGIONS,
  FETCH_TOP_SERVICES,
  SET_FLOP_SERVICES,
  SET_SERVICE_GROUPS,
  SET_TOP_FLOP_FILTERS,
  SET_TOP_FLOP_SERVICES,
  SET_TOP_MODELS,
  SET_TOP_REGIONS,
  SET_TOP_SERVICES,
} from "./actionTypes";

export const fetchTopFlopFilters = () => ({ type: FETCH_TOP_FLOP_FILTERS });
export const setTopFlopFilters = (data: any) => ({ type: SET_TOP_FLOP_FILTERS, payload: data });

export const fetchTopFlopServices = () => ({ type: FETCH_TOP_FLOP_SERVICES });
export const setTopFlopServices = (data: any) => ({ type: SET_TOP_FLOP_SERVICES, payload: data });

export const fetchTopServices = () => ({ type: FETCH_TOP_SERVICES });
export const setTopServices = (data: any) => ({ type: SET_TOP_SERVICES, payload: data });

export const fetchFlopServices = () => ({ type: FETCH_FLOP_SERVICES });
export const setFlopServices = (data: any) => ({ type: SET_FLOP_SERVICES, payload: data });

export const fetchTopModels = () => ({ type: FETCH_TOP_MODELS });
export const setTopModels = (data: any) => ({ type: SET_TOP_MODELS, payload: data });

export const fetchServiceGroups = () => ({ type: FETCH_SERVICE_GROUPS });
export const setServiceGroups = (data: any) => ({ type: SET_SERVICE_GROUPS, payload: data });

export const fetchTopRegions = () => ({ type: FETCH_TOP_REGIONS });
export const setTopRegions = (data: any) => ({ type: SET_TOP_REGIONS, payload: data });
