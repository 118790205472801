import { SET_LOADING } from "../actions/actionTypes";

const initialState = {
  key_indicator: false,
  visits_breakdown_table: false,
  visits_breakdown_graph: false,
  baseline_graph: false,
  full_figures_graph: false,
  kba_trend_graph: false,
  kba_trend_table: false,
  lead_trend_graph: false,
  lead_trend_table: false,
  ccs_main_data: false,
  app_review: false,
  app_review_by_topic: false,
  app_review_satisfaction: false,
  business_revenue_data: false,
  ccs_subscription: false,
  call_centre: false,
  top_used_services: false,
  oce_annotations: false,
  top_flop_services: false,
  top_flop_top_services: false,
  top_flop_flop_services: false,
  top_flop_models_services: false,
  top_flop_service_groups: false,
  top_flop_top_regions: false,
  customer_intention_vs_sales: false,
  sov_data: false,
  price_volume_sales: false,
  feature_usage: false,
  downloads: false,
  open_rate: false,
  ccs_analysis_enrollment_rate: false,
  ccs_analysis_user_engagement_rate: false,
  renewal_and_churn_rate: false,
  app_rating_by_market: false,
  ccs_journey: false,
  enrolled_customers: false,
  marketing_activity: false,
  digital_sales_breakdown: false,
  power_trains: false,
  live_markets: false,
  total_bugs: false,
  digital_performance: false,
  influence_on_shopping_tools: false,
  influence_on_leads: false,
  star_rating_average_rating: false,
  star_rating_average_rating_trend: false,
  star_rating_feedback: false,
  star_rating_score_distribution: false,
  star_rating_device_distribution: false,
  star_rating_average_rating_by_market: false,
  exit_survey_objective_completion: false,
  exit_survey_feedback: false,
  exit_survey_objective_completion_trend: false,
  exit_survey_non_completion_reasons: false,
  exit_survey_device_distribution: false,
  exit_survey_objective_completion_by_market: false,
  rating_per_touchpoint_market_breakdown: false,
  rating_per_touchpoint_vlp_focus: false,
  rating_per_touchpoint_electrification: false,
  topic_classification: false,
  topic_verbatim: false,
  base_trend: false,
  dealer_trend: false,
  inventory_by_age: false,
  performance_per_unit: false,
};

const loadingReducer = (
  state = initialState,
  action: {
    type: string;
    payload: boolean;
    name: string;
  }
) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, [action.name]: action.payload };

    default:
      return state;
  }
};

export default loadingReducer;
