import { ColumnSeries, DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, Sprite } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import { connect, RootStateOrAny } from "react-redux";
import WebFont from "webfontloader";
import { BIG_NUMBER_ABBREVIATION, BLACK, BRIGHT_YELLOW, CYAN, FOREST_GREEN, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { columnSeriesConfiguration, configureDateAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { formatDateAxisLabelToMonthly } from "../helpers/mtmConfigurations";
import { legendConfigurationOCE, oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  seriesList: Array<{ name: string; field: string; type: string }>;
  theme: ThemeInt;
  chartName: string;
  isLoading: boolean;
  currency: string;
}

interface ISeries {
  name: string;
  color: string;
}

const CHART_COLORS = [BRIGHT_YELLOW, CYAN, FOREST_GREEN];

class TotalValueDisByMonthChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.tooltipConfig = this.tooltipConfig.bind(this);
  }

  chartId = this.props.chartName;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { data, seriesList, theme, currency } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.colors.list = CHART_COLORS.map((colorMix: string) => color(colorMix));

    // Creates Legend
    this.chart.legend = legendConfigurationOCE(theme);
    this.chart.legend.reverseOrder = false;

    // Create current period date chart axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.minGridDistance = 10;
    dateAxis.dateFormats.setKey("month", "MMM yy");
    dateAxis.periodChangeDateFormats.setKey("month", "MMM yy");

    // Creates value axis
    const barValueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(barValueAxis, theme);
    barValueAxis.renderer.numberFormatter.numberFormat = "#.0a";
    barValueAxis.renderer.numberFormatter.bigNumberPrefixes = BIG_NUMBER_ABBREVIATION;
    barValueAxis.renderer.labels.template.adapter.add("text", function (text) {
      const prefix = text?.charAt(text.length - 1);
      if (text && prefix && isNaN(+prefix)) {
        text = `${Number(text.slice(0, text.length - 1)).toLocaleString()}${prefix}`;
      }
      return `${currency}` + text;
    });

    const lineValueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(lineValueAxis, theme);
    lineValueAxis.renderer.opposite = true;
    lineValueAxis.syncWithAxis = barValueAxis;
    lineValueAxis.renderer.numberFormatter.numberFormat = "#.0a";
    lineValueAxis.renderer.numberFormatter.bigNumberPrefixes = BIG_NUMBER_ABBREVIATION;

    seriesList?.forEach(({ name, field, type }, idx) => {
      const series = type == "bar" ? this.chart.series.push(new ColumnSeries()) : this.chart.series.push(new LineSeries());
      series.data = data;
      series.xAxis = dateAxis;
      series.dataFields.dateX = "date";
      if (type == "bar" && series instanceof ColumnSeries) {
        columnSeriesConfiguration(series, {
          name: name,
          valueY: field,
        });
        series.stacked = true;
        series.yAxis = barValueAxis;
        series.columns.template.tooltipText = "test";
        //@ts-ignore
        series.columns.template.adapter.add("tooltipText", this.tooltipConfig);
      } else if (series instanceof LineSeries) {
        lineSeriesConfiguration(series, {
          valueY: field,
          name: name,
        });
        series.yAxis = lineValueAxis;
      }

      if (series.tooltip) {
        series.tooltip.getFillFromObject = false;
        series.tooltip.autoTextColor = false;
        series.tooltip.background.fill = color(WHITE);
        series.tooltip.label.fill = color(BLACK);
      }
    });

    this.chart.series.each((series) => {
      series.bullets.getIndex(0)?.adapter.add("tooltipText", this.tooltipConfig);
    });
  }

  tooltipConfig(value: string | undefined, target: Sprite) {
    const dateValue = target?.tooltipDataItem?.dataContext as { date: string; [index: string]: string | number };

    let text = `[bold]${formatDateAxisLabelToMonthly(dateValue?.date)}[/]\n`;

    this.chart.series.each((item) => {
      const value = item?.dataFields?.valueY && dateValue ? (dateValue[item.dataFields.valueY] as number) : null;
      text += `[${item.stroke}]●[/] ${item.name}: ${value ? Number(value).toLocaleString() : "n/a"}\n`;
    });

    return text;
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

function mapStateToProps(state: RootStateOrAny) {
  const currency = state.msrp.currency;
  return {
    currency,
  };
}

export default connect(mapStateToProps)(TotalValueDisByMonthChart);
