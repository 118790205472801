import { BrowserTracing, init } from "@sentry/react";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { App } from "./components/App/App";
import { __environment__ } from "./constants";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";

init({
  dsn: "https://8cbdbe6119b14a26b94c4f7473e76f6f@o319449.ingest.sentry.io/4504954898022400",
  integrations: [new BrowserTracing()],
  environment: __environment__,
  release: `V${process.env.npm_package_version}`,
});

const container: HTMLElement = document.getElementById("root") as HTMLElement;

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
