import React from "react";
import "../../../../assets/styles/component/granularityOptions.scss";

interface Props {
  selectedOption: string;
  onHandleRadioClick: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  options: Array<string>;
}

const RadioOptions = (props: Props) => {
  const { options, selectedOption, onHandleRadioClick } = props;
  return (
    <div className="radio">
      {options.map((option: string) => (
        <div className="trend" key={option}>
          <input
            type="radio"
            className="dp_radio trend_period"
            id={option}
            value={option}
            onChange={onHandleRadioClick}
            checked={selectedOption == option}
          />
          <label htmlFor={option} className="dp_label trend_label" data-value={option}>
            {option}
          </label>
        </div>
      ))}
    </div>
  );
};

export default RadioOptions;
