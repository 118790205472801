import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import "../../../assets/styles/component/legend.scss";
import { GRADE_MIX_COLORS } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { formatValueAxisUnits, getTooltipDateFormat, getTooltipTextFormat } from "../helpers/helpers";
import { legendConfigurationOCE, oceChartConfiguration } from "../helpers/oceConfigurations";
import { dateAxisLabelFormatter_QuarterlyFY, formatDateAxisLabelToQuarterlyFY } from "../helpers/pmoConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  chartId: string;
  data: any;
  theme: ThemeInt;
  seriesList: Array<string>;
  isLoading: boolean;
  market: string;
  granularity: string;
  multipleValueAxis?: boolean;
  highestValue: number;
}

class ShowRoomChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chartId = this.props.chartId;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId);
      setTimeout(() => this.initChart(), 1500);
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, theme, seriesList, highestValue, multipleValueAxis, granularity } = this.props;

    // Global chart configuration
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.numberFormatter.numberFormat = "#a";
    this.chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    this.chart.data = data;
    // Creates Legend
    this.chart.legend = legendConfigurationOCE(theme);
    this.chart.colors.list = GRADE_MIX_COLORS.map((chartColor) => color(chartColor));
    this.chart.legend.maxHeight = 200;
    this.chart.legend.scrollable = true;
    this.chart.legend.marginBottom = 15;

    // Create data chart axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.cellStartLocation = 0.5;
    dateAxis.renderer.cellEndLocation = 0.8;
    dateAxis.tooltipDateFormat = getTooltipDateFormat(granularity);
    dateAxis.renderer.minGridDistance = this.props.granularity === "quarterly" ? 50 : 30;

    // 2nd date axis for comparison data
    const dateAxis2 = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis2, theme);
    dateAxis2.renderer.labels.template.disabled = true;
    dateAxis2.renderer.cellStartLocation = 0.2;
    dateAxis2.renderer.cellEndLocation = 0.5;

    dateAxis2.tooltipDateFormat = getTooltipDateFormat(granularity);
    dateAxis2.renderer.minGridDistance = this.props.granularity === "quarterly" ? 50 : 30;
    granularity === "quarterly" && dateAxis.renderer.labels.template.adapter.add("text", dateAxisLabelFormatter_QuarterlyFY);

    // Creates value axis
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.min = 0;
    valueAxis.renderer.minGridDistance = 20;
    valueAxis.numberFormatter.numberFormat = "#a";
    valueAxis.renderer.grid.template.strokeWidth = 0;

    const valueAxis1 = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis1, theme);
    valueAxis.min = 0;
    valueAxis1.renderer.opposite = true;
    valueAxis1.syncWithAxis = valueAxis;
    valueAxis1.numberFormatter.numberFormat = "#a";
    valueAxis1.renderer.grid.template.strokeWidth = 0;
    formatValueAxisUnits(valueAxis);
    formatValueAxisUnits(valueAxis1);

    seriesList?.forEach((metric, idx) => {
      const series = this.chart.series.push(new LineSeries());
      const bullet = lineSeriesConfiguration(series, {
        valueY: this.props.chartId === "gradeMixChart" ? `${metric}_volume` : "leads_book_a_test_drive",
        name:
          this.props.chartId === "gradeMixChart"
            ? metric.charAt(0).toUpperCase() + metric.split("_").join(" ").slice(1)
            : "Test drive requests",
      });
      series.xAxis = dateAxis;
      if (granularity === "quarterly") series.dataFields.categoryX = "quarter";
      series.yAxis = seriesList.length > 1 && metric === "test_drive" ? valueAxis1 : valueAxis;
      series.dataFields.dateX = "date";

      bullet.adapter.add("tooltipText", (text, target) => {
        const data = target.dataItem?.dataContext;
        if (granularity === "quarterly") {
          //@ts-ignore
          const dateVal = formatDateAxisLabelToQuarterlyFY(data?.date);
          text = `[bold; font-size: var(--regular_font_size);] ${dateVal}[/ font-size: var(--regular_font_size);]: {valueY.formatNumber("#,###")}`;
        } else {
          text = getTooltipTextFormat(
            granularity === "weekly" && this.props.chartId === "gradeMixChart" ? `${granularity}_dfp` : granularity
          );
        }
        return text;
      });
    });
  }
  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default ShowRoomChart;
