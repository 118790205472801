import React from "react";

interface Props {
  name: string;
  label: React.ReactNode;
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const PasswordInput = (props: Props) => {
  return (
    <div className="input_field">
      <label htmlFor="password">{props.label}</label>
      <div className="field">
        <input type="password" name={props.name} value={props.value} onChange={props.onChange} id={props.name} />
      </div>
    </div>
  );
};
