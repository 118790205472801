import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  getExecutiveSummaryAggregatedCloseRateByMarket,
  getExecutiveSummaryAggregatedDisPercentageByMarket,
  getExecutiveSummaryAggregatedPrivateSalesByMarket,
} from "../../../../api/executiveSummaryWebsiteApi";
import ExecutiveSummaryBulletChart from "../../../../components/Charts/ExecutiveSummary/ExecutiveSummaryBulletChart";
import ExecutiveSummaryStackedBarLineChart from "../../../../components/Charts/ExecutiveSummary/ExecutiveSummaryStackedBarLineChart";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { ThemeContext } from "../../../../context";
import { Tooltip } from "react-tippy";

const AggregatedDataByMarket = withRouter((props: RouteComponentProps) => {
  const { theme } = useContext(ThemeContext);
  const { history } = props;

  const {
    data: privateSalesByMarketData,
    refetch: refetchPrivateSalesByMarket,
    isFetching: privateSalesBreakdownLoading,
  } = useQuery({
    queryKey: ["privateSalesByMarketData"],
    queryFn: getExecutiveSummaryAggregatedPrivateSalesByMarket,
    enabled: false,
    initialData: {},
  });

  const { data: privateSalesBreakdownData, series: privateSalesBreakdownSeries } = privateSalesByMarketData;

  const {
    data: disPercentageBreakdownByMarketData,
    refetch: refetchDisPercentageBreakdownByMarket,
    isFetching: disPercentageBreakdownLoading,
  } = useQuery({
    queryKey: ["disPercentageBreakdownByMarketData"],
    queryFn: getExecutiveSummaryAggregatedDisPercentageByMarket,
    enabled: false,
    initialData: {},
  });

  const { data: disPercentageBreakdownData, series: disPercentageBreakdownSeries } = disPercentageBreakdownByMarketData;

  const {
    data: closeRateBreakdownByMarketData,
    refetch: refetchCloseRateBreakdownByMarket,
    isFetching: closeRateBreakdownLoading,
  } = useQuery({
    queryKey: ["closeRateBreakdownByMarketData"],
    queryFn: getExecutiveSummaryAggregatedCloseRateByMarket,
    enabled: false,
    initialData: {},
  });

  const { data: closeRateBreakdownData, series: closeRateBreakdownSeries } = closeRateBreakdownByMarketData;

  useEffect(() => {
    refetchPrivateSalesByMarket();
    refetchDisPercentageBreakdownByMarket();
    refetchCloseRateBreakdownByMarket();
  }, [history.location.search]);

  return (
    <>
      <div className="breakdown-chart tile">
        <div className="tile-header">
          <p>Private Sales Breakdown</p>
          <Tooltip
            position="right"
            className={"normal_tippy"}
            trigger="mouseenter"
            interactive={true}
            distance={220}
            delay={1}
            hideDelay={1}
            duration={1}
            html={
              <div>
                <p>Private Sales: Digitally influenced sales + Others (dealer walk ins + other)</p>
                <p>DIS: Number of confirmed new car sales that can be attributed to OEM managed digital activity</p>
                <p>Non-DIS: Sales tracked by market - Digitally influenced sales</p>
                <p>Untracked: Total private sales - (Non-DIS + DIS)</p>
              </div>
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        {privateSalesBreakdownLoading ? (
          <LoadingEllipsis isLoading={privateSalesBreakdownLoading} />
        ) : (
          <ExecutiveSummaryStackedBarLineChart
            chartName="privateSalesBreakdown"
            theme={theme}
            seriesList={privateSalesBreakdownSeries}
            isLoading={privateSalesBreakdownLoading}
            data={privateSalesBreakdownData}
            isCategoryAxis={true}
            categoryAxisField="market"
          />
        )}
      </div>
      <div className="breakdown-chart tile">
        <div className="tile-header">
          <p>Digitally Influenced Sales %</p>
          <Tooltip
            position="right"
            className={"normal_tippy"}
            trigger="mouseenter"
            interactive={true}
            distance={220}
            delay={1}
            hideDelay={1}
            duration={1}
            title="Digitally Influence Sales / Private Sales"
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        <ExecutiveSummaryBulletChart
          chartId="disBulletChart"
          theme={theme}
          seriesList={disPercentageBreakdownSeries ?? []}
          isLoading={disPercentageBreakdownLoading}
          data={disPercentageBreakdownData ?? []}
          isPercentage={true}
        />
      </div>
      <div className="breakdown-chart tile">
        <div className="tile-header">
          <p>Close Rate</p>
          <Tooltip
            position="right"
            className={"normal_tippy"}
            trigger="mouseenter"
            interactive={true}
            distance={220}
            delay={1}
            hideDelay={1}
            duration={1}
            title="Digitally Influenced Sales / Leads"
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        <ExecutiveSummaryBulletChart
          chartId="closeRateBulletChart"
          theme={theme}
          seriesList={closeRateBreakdownSeries ?? []}
          isLoading={closeRateBreakdownLoading}
          data={closeRateBreakdownData ?? []}
          isPercentage={true}
        />
      </div>
    </>
  );
});

export default AggregatedDataByMarket;
