import { matchSorter } from "match-sorter";
import React, { useCallback, useEffect, useMemo } from "react";
import { Column, useFilters, useFlexLayout, useGlobalFilter, useSortBy, useTable } from "react-table";
import "../../../assets/styles/component/tables.scss";

interface TableProps {
  columns: Column[];
  data: any[];
  filters?: any[];
  filter?: string;
  hasColumnFilters: boolean;
}

const DefaultTableWithColumnFilter = (Props: TableProps): JSX.Element => {
  const { data, columns: kpiColumns, filter, filters, hasColumnFilters } = Props;
  const customGlobalFilterFunction = useCallback(
    (rows: any, ids: any, query: string) => {
      return matchSorter(rows, query, {
        keys: filters,
      });
    },
    [filters]
  );
  const defaultColumn = useMemo(
    () => ({
      minWidth: 10,
      width: 10,
      maxWidth: 10,
    }),
    []
  );
  // @ts-ignore
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setAllFilters, setGlobalFilter } = useTable(
    {
      columns: kpiColumns,
      data,
      defaultColumn,
      // @ts-ignore
      globalFilter: customGlobalFilterFunction,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout
  );

  useEffect(() => {
    setGlobalFilter(filter); // Set the Global Filter to the filter prop.
  }, [filter, setGlobalFilter]);

  //Variable to check if a user has filtered records that do not exist
  const noMatchingRecords = data?.length > 1 && rows?.length === 0;

  return (
    <table className="kpi_table" {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => {
          const { key: headerGroupKey, ...otherHeaderGroupProps } = headerGroup.getHeaderGroupProps();

          return (
            <tr {...otherHeaderGroupProps} key={headerGroupKey}>
              {headerGroup.headers.map((column) => {
                const sortClass = column?.isSorted ? (column.isSortedDesc ? "down" : "up") : "";
                const sortableClass = column?.disableSortBy ? "" : "sortable";

                const { key: headerKey, ...otherHeaderProps } = column.getHeaderProps();
                return (
                  <th {...otherHeaderProps} key={headerKey}>
                    <div className="sort_filter_container">
                      <span {...column.getSortByToggleProps()} className={`${sortableClass} ${sortClass}`}>
                        {column.render("Header")}
                      </span>
                      {hasColumnFilters && column?.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {hasColumnFilters && noMatchingRecords ? (
          <div>
            <p className={"filter_reset"}>
              There are no records matching this criteria. Kindly update the filter selection or
              <span onClick={() => setAllFilters([])}> reset filters</span>
            </p>
          </div>
        ) : (
          rows.map((row, index) => {
            const rowClass = (index + 1) % 2 === 0 ? "even" : "odd";
            prepareRow(row);
            const { key: rowKey, ...otherRowProps } = row.getRowProps();
            return (
              <tr {...otherRowProps} key={rowKey} className={rowClass}>
                {row.cells.map((cell) => {
                  const { key: cellKey, ...otherCellProps } = cell.getCellProps();

                  return (
                    <td {...otherCellProps} key={cellKey}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })
        )}
      </tbody>
    </table>
  );
};

export default DefaultTableWithColumnFilter;
