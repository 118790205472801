// Todo: Add missing dates for last data refresh alert
import { Box, Button, Grid } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { setLastDataRefresh } from "../../../actions";
import {
  fetchColoursData,
  fetchGradesData,
  fetchHandraiserNAEData,
  fetchHandraiserNAEData_Total,
  fetchHandraisersConversionData,
  fetchHandraisersConversionData_Nov_2021,
  fetchVisitsData,
} from "../../../api/Ariya";
import { DrilldownLayout } from "../../../components/Layouts/Ariya";
import { IChartData, IMetricTotal, IRegionSplitState, popularState } from "../../../constants/interface/ariya";
import { ThemeContext } from "../../../context";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { isNull } from "../../../utils/utilityFunctions";
import commonStyles from "../ariya.module.scss";
import { ExperienceCard } from "../Experience Insight/components/Card";
import { ProgressTable } from "../Experience Insight/components/Tables";
import { getGraphData } from "../helpers/charts";
import { extractMonitorData, extractProgressTableDataFromResponse } from "../helpers/dataFunctions";
import { MonitorCard } from "../Monitor/components/Card";
import { MonitorTrendline } from "../Monitor/components/Charts";
import { MajorCard } from "./Cards";
import styles from "./styles.module.scss";
import { HandraisersByCity, TotalHandRaisers } from "./Tables";

export const HandraisersInsight = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const themeContext = useContext(ThemeContext);

  const dispatch = useDispatch();
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.ariya_monitor);

  const [handraisersNAE, setHandraisersNAE] = useState<IMetricTotal>({ current: null, previous: null });
  const [britainRegionSplit, setBritainRegionSplit] = useState<IRegionSplitState>({ current: null, percentage: null });
  const [germanyRegionSplit, setGermanyRegionSplit] = useState<IRegionSplitState>({ current: null, percentage: null });
  const [norwayRegionSplit, setNorwayRegionSplit] = useState<IRegionSplitState>({ current: null, percentage: null });
  const [handRaiserChartData, setHandRaiserChartData] = useState<Array<IChartData>>([]);
  const [topVersions, setTopVersions] = useState<popularState>({ records: [] });
  const [topColours, setTopColours] = useState<popularState>({ records: [] });
  const [topColoursToShow, setTopColoursToShow] = useState<popularState>({ records: [] });

  const [handraisersTableData, setHandRaisersTableData] = useState<Array<{ market: string; total: number; share: number }>>([]);
  const [handraisersTableDataToShow, setHandRaisersTableDataToShow] = useState<Array<{ market: string; total: number; share: number }>>([]);
  const [handRaisersTotal, setHandraisersTotal] = useState<number | null>(null);

  const [showMoreColours, setShowMoreColours] = useState(true);
  const [showMoreHandraiser, setShowMoreHandraisers] = useState(true);

  const pageNavigation = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      const {
        currentTarget: { dataset },
      } = evt;
      const pageLink = dataset?.page;

      history.push({ pathname: pageLink, search: "" });
    },
    [history]
  );

  // Usage tracking
  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Ariya", page: "Ariya Handraisers Insights" });
  }, []);

  // Last data refresh plus Visits trend
  useEffect(() => {
    fetchVisitsData().then((response) => {
      if (response && !("error" in response)) {
        const {
          chart_data: { year, month, day },
        } = extractMonitorData(response);
        dispatch(setLastDataRefresh("ariya_monitor", `${year}-${month}-${day}`));
      }
    });
  }, []);

  // Handraisers NAE
  useEffect(() => {
    Promise.all([
      fetchHandraiserNAEData().then((response) => {
        if (response && !("error" in response)) {
          const data = response.report.data;
          const lastDay = +data[data.length - 1].counts[0];

          setHandraisersNAE((prevState) => ({ ...prevState, previous: lastDay }));
          setHandRaiserChartData(getGraphData(data));
        }
      }),

      fetchHandraiserNAEData_Total().then((response) => {
        if (response && !("error" in response)) {
          const total = response.count_data.data.find((row) => row.name === "AMIEO");

          setHandraisersNAE((prevState) => ({ ...prevState, current: total ? total.counts[0] : null }));
        }
      }),

      fetchHandraisersConversionData().then((response) => {
        if (response && !("error" in response)) {
          const britainData = response.report.data.find((row) => row.name === "United Kingdom");
          const germanyData = response.report.data.find((row) => row.name === "Germany");
          const norwayData = response.report.data.find((row) => row.name === "Norway");

          setBritainRegionSplit((prevState) => ({ ...prevState, current: britainData?.counts[0] ? +britainData?.counts[0] : null }));
          setGermanyRegionSplit((prevState) => ({ ...prevState, current: germanyData?.counts[0] ? +germanyData?.counts[0] : null }));
          setNorwayRegionSplit((prevState) => ({ ...prevState, current: norwayData?.counts[0] ? +norwayData?.counts[0] : null }));
        }
      }),

      fetchHandraisersConversionData_Nov_2021().then((response) => {
        if (response && !("error" in response)) {
          setHandraisersTotal(+response.report.totals[0]);
          setHandRaisersTableData(
            response.report.data
              .filter((row) => !row.name.includes("unspecified"))
              .map((row) => ({
                market: row.name === "United States of America" ? "USA" : row.name,
                total: +row.counts[0],
                share: +row.counts[1],
              }))
          );
        }
      }),
    ]);
  }, []);

  //Updates Region Split percentages
  useEffect(() => {
    if (isNull(handraisersNAE.current) || isNull(britainRegionSplit.current)) {
      setBritainRegionSplit((prevState) => ({ ...prevState, percentage: null }));
    } else {
      setBritainRegionSplit((prevState) => ({
        ...prevState,
        percentage: (britainRegionSplit.current as number) / (handraisersNAE.current as number),
      }));
    }
  }, [handraisersNAE.current, britainRegionSplit.current]);

  useEffect(() => {
    if (isNull(handraisersNAE.current) || isNull(germanyRegionSplit.current)) {
      setGermanyRegionSplit((prevState) => ({ ...prevState, percentage: null }));
    } else {
      setGermanyRegionSplit((prevState) => ({
        ...prevState,
        percentage: (germanyRegionSplit.current as number) / (handraisersNAE.current as number),
      }));
    }
  }, [handraisersNAE.current, germanyRegionSplit.current]);

  useEffect(() => {
    if (isNull(handraisersNAE.current) || isNull(norwayRegionSplit.current)) {
      setNorwayRegionSplit((prevState) => ({ ...prevState, percentage: null }));
    } else {
      setNorwayRegionSplit((prevState) => ({
        ...prevState,
        percentage: (norwayRegionSplit.current as number) / (handraisersNAE.current as number),
      }));
    }
  }, [handraisersNAE.current, norwayRegionSplit.current]);

  // Grades data
  useEffect(() => {
    fetchGradesData().then(
      (response) => response && !("error" in response) && setTopVersions({ records: extractProgressTableDataFromResponse(response) })
    );
  }, []);

  // Colours data
  useEffect(() => {
    fetchColoursData().then(
      (response) => response && !("error" in response) && setTopColours({ records: extractProgressTableDataFromResponse(response) })
    );
  }, []);

  //Set top colours row
  useEffect(() => {
    if (topColours.records.length) {
      const allRecords = [...topColours.records];
      if (showMoreColours) {
        setTopColoursToShow({ records: allRecords.slice(0, 6) });
      } else {
        setTopColoursToShow({ records: allRecords });
      }
    }
  }, [showMoreColours, topColours]);

  //Set top handraiser row
  useEffect(() => {
    if (handraisersTableData.length) {
      const tableRows = [...handraisersTableData];
      if (showMoreHandraiser) {
        setHandRaisersTableDataToShow([...tableRows.slice(0, 5)]);
      } else {
        setHandRaisersTableDataToShow(tableRows);
      }
    }
  }, [showMoreHandraiser, handraisersTableData]);

  return (
    <DrilldownLayout title="Hand-raisers insights" lastDataRefresh={lastDataRefresh} previousPagePath="/ariya/monitor">
      <>
        <Box display={"grid"} gridGap={15} className={styles.cardContainer}>
          <MajorCard
            id="version_colours_insights"
            title="Versions &amp; Colours Insights (AMIEO)"
            titleDescription="(Email capture with version &amp; colour preferences)"
            content1={
              <MonitorCard
                metric={{
                  title: "Hand-raisers (AMIEO)",
                  lastDayValue: handraisersNAE.previous,
                  value: handraisersNAE.current,
                }}
                split={[
                  { title: "GB", value: britainRegionSplit.current, percentageTrend: britainRegionSplit.percentage, market: "GB" },
                  { title: "DE", value: germanyRegionSplit.current, percentageTrend: germanyRegionSplit.percentage, market: "DE" },
                  { title: "NO", value: norwayRegionSplit.current, percentageTrend: norwayRegionSplit.percentage, market: "NO" },
                ]}
                chart={
                  <MonitorTrendline data={handRaiserChartData} chartId={"handRaisers"} series={["handRaiser"]} theme={themeContext.theme} />
                }
                extraClass={styles.monitorCardReset}
                isMarketSplit={true}
                marketBreakdownPath="/ariya/handraisers/market-split"
              />
            }
            content2={<ProgressTable id="top_versions" title="Top versions (AMIEO)" data={topVersions} />}
            content3={
              <>
                {topColours.records.length && (
                  <>
                    <ProgressTable id="top_colours" title="Top colours (AMIEO)" data={topColoursToShow} />
                    <div className={styles.show_more_container}>
                      <span className={styles.show_more} onClick={() => setShowMoreColours((prevState) => !prevState)}>
                        {showMoreColours ? "Show more" : "Show less"}
                      </span>
                    </div>
                  </>
                )}
              </>
            }
            className={styles.majorCard}
            marketBreakdownPath="/ariya/handraisers/market-split"
          />

          <ExperienceCard title="Total hand-raisers" id="total_handraisers" className={styles.totalHandraisers}>
            <>
              <TotalHandRaisers total={handRaisersTotal} data={handraisersTableDataToShow} />
              {handraisersTableDataToShow.length > 0 && (
                <div className={styles.show_more_container}>
                  <span className={styles.show_more} onClick={() => setShowMoreHandraisers((prevState) => !prevState)}>
                    {showMoreHandraiser ? "Show more" : "Show less"}
                  </span>
                </div>
              )}
            </>
          </ExperienceCard>

          <ExperienceCard title="Hand-raisers by city across the world" id="handraisers_city" className={styles.handraisersCity}>
            <HandraisersByCity />
          </ExperienceCard>
        </Box>

        <Grid container spacing={10} justifyContent="space-evenly">
          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page="/ariya/monitor"
              data-test-id="ariyaHome"
            >
              Ariya home
            </Button>
          </Grid>

          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page="/ariya/experience"
              data-test-id="ariyaExperience"
            >
              Experience insights
            </Button>
          </Grid>

          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page="/ariya/preorder"
              data-test-id="ariyaPreorder"
            >
              Online pre-order insights
            </Button>
          </Grid>
        </Grid>
      </>
    </DrilldownLayout>
  );
});
