export const __url__ = process.env.REACT_APP_API_URL;
export const __environment__ = process.env.REACT_APP_ENVIRONMENT;
export const __sentry__ = process.env.SENTRY_CREDENTIALS;
export const __chart_license__ = "CH250554441";
export const __v2Url__ = process.env.REACT_APP_API_V2_URL;

export const __mixpanel_key__ = "0f653b0d18b9253114d9396a5998e179";

export const __local__ = process.env.REACT_APP_ENVIRONMENT === "local";
export const __prod__ = process.env.REACT_APP_ENVIRONMENT === "prod";

export const CONTACT_US_EMAIL = "cedar.performance.dashboard@artefact.com";
export const DASHBOARD_VERSION = "2.7.0";

// CXO Dashboard link prefix less required token
export const CXO_DASHBOARD_URL = "https://www.drivetheexperience.com/analyse/velocity-library?wf_auth=";
