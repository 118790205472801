import React from "react";
import "../../../assets/styles/docs/ccsData.scss";

export const CcsVitalSignsData = (): JSX.Element => (
  <div className="data_documentation">
    <h3>CCS VITAL SIGNS - DATA OVERVIEW</h3>

    <h4>Data Availability and Recency</h4>

    <p>
      The data for this dashboard is sourced from the ACV reports which is circulated weekly. The ACV reports were first prepared for W28
      202 (w/c 05/07/2020). For this reason, selection forJune data in this dashboard is an aggregation of data from Jan-Jun 2020.
    </p>
    <br />

    <h4>CCS cockpit Data Definitions and Sources</h4>
    <br />

    <table className="docs_table">
      <thead>
        <tr>
          <th>Category</th>
          <th>KPI</th>
          <th>Description</th>
          <th>Data Source</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Business revenue</td>
          <td>CCS subscription sales</td>
          <td>Sum of CCS subscription package sales</td>
          <td>Regions</td>
        </tr>
        <tr>
          <td>Business revenue</td>
          <td>CCS services purchased by customer - Average value</td>
          <td>Value of services purchased or subscribed by customers (outside NissanConnect subscription)</td>
          <td>Regional Billing system, CarWings</td>
        </tr>

        <tr>
          <td>Business revenue</td>
          <td>CCS business revenue</td>
          <td>Sum of CCS subscription package sales, CCS services, and B2B revenue</td>
          <td>Regions</td>
        </tr>

        <tr>
          <td>Business revenue</td>
          <td>Connected car sold</td>
          <td>Total connected AvK vehicles sold.</td>
          <td>Sales Team</td>
        </tr>

        <tr>
          <td>Business revenue</td>
          <td>G8 private activation ratio</td>
          <td>
            Europe Specific: Number of cars with APP activation during period/ Number of eligible cars (equipped with A-IVC) with Warranty
            Start date (WSD) during period
          </td>
          <td>TCS team</td>
        </tr>

        <tr>
          <td>Customer quality</td>
          <td>App ratings</td>
          <td>NissanConnect application rating from AppleStore and PlayStore</td>
          <td>Apple Store, Google Store, Data.AI</td>
        </tr>

        <tr>
          <td>Customer quality</td>
          <td>Satisfaction (call center)</td>
          <td>Japan Specific: Customer contact to call center classified by machine learning</td>
          <td>Angel Data</td>
        </tr>

        <tr>
          <td>Customer quality</td>
          <td>NissanConnect churn rate</td>
          <td>Rate in which customers quit NissanConnect subscribed services.</td>
          <td>CarWings, Kamereon, Sales Team, JF0, VCS Team</td>
        </tr>

        <tr>
          <td>Customer quality</td>
          <td>Renewal rate</td>
          <td>Rate of customers who renew NissanConnect subscribed services.</td>
          <td>CarWings, Kamereon, Sales Team, JF0, VCS team</td>
        </tr>

        <tr>
          <td>Engagement</td>
          <td>App downloads</td>
          <td># of NissanConnect application download</td>
          <td>XU3 GBQ</td>
        </tr>

        <tr>
          <td>Engagement</td>
          <td>Average monthly active users </td>
          <td>Avg of latest 3-month in selected period of unique NissanConnect users who use services at least one time per month.</td>
          <td>Kamereon, JF0, VCS Team</td>
        </tr>

        <tr>
          <td>Engagement</td>
          <td>Service activated default </td>
          <td>
            Services considered as activated when all the steps of validation are successfully achieved. This is mainly done during vehicle
            preparation before being sold.
          </td>
          <td>Sales Team, CarWings, vNEXT, Kamereon</td>
        </tr>

        <tr>
          <td>Engagement</td>
          <td>Enrolled Customers</td>
          <td>User who have successfully paired their vehicle with NissanConnect app.</td>
          <td>CarWings, KUA Database, VCS Team</td>
        </tr>

        <tr>
          <td>Engagement</td>
          <td>User engagement </td>
          <td>% of Average monthly active users from latest 3-month total enrolled users in selected period of NissanConnect app</td>
          <td>CCS Vital Signs</td>
        </tr>

        <tr>
          <td>Operational</td>
          <td>AVK data quality out</td>
          <td># of AvK quality data out, which refers to data sent out w/ expectation to be eligible for monetization</td>
          <td>Kamereon, vNEXT, GAMMA</td>
        </tr>

        <tr>
          <td>Operational</td>
          <td>Top/Flop connected services</td>
          <td>Usage information for CCS Off-board & On-board services</td>
          <td>Kamereon</td>
        </tr>
      </tbody>
    </table>
    <br />

    <h4>CCS journey Data Definitions and Sources</h4>
    <br />

    <table className="docs_table">
      <thead>
        <tr>
          <th>Category</th>
          <th>KPI</th>
          <th>Description</th>
          <th>Data Source</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Pre-sales</td>
          <td>Total cars produced</td>
          <td>Total number of vehicles produced (connectivity grade capable). Only current AvK models.</td>
          <td>Factory, QIDEA, VCS Team</td>
        </tr>

        <tr>
          <td>Pre-sales</td>
          <td>Produced eligible connected cars</td>
          <td>Total number of connected eligible vehicles produced.</td>
          <td>Factory, QIDEA, VCS Team</td>
        </tr>

        <tr>
          <td>Pre-sales</td>
          <td>Bootstrapped cars</td>
          <td>
            All the steps of validation between Vnext & Kamereon are successfully achieved for IVC &IVI. Vehicle ready for services
            activation. This is mainly done in dealers today.
          </td>
          <td>Kamereon (correlated with vNEXT Data), VCS Team</td>
        </tr>

        <tr>
          <td>Pre-sales</td>
          <td>Service Activated Default </td>
          <td>
            Services considered as activated when all the steps of validation are successfully achieved. This is mainly done during vehicle
            preparation before being sold.
          </td>
          <td>Kamereon (correlated with vNEXT Data)</td>
        </tr>

        <tr>
          <td>Post-sales</td>
          <td>Connected cars sold</td>
          <td>Total sales of vehicles equipped w.AvK connectivity</td>
          <td>Sales team</td>
        </tr>

        <tr>
          <td>Post-sales</td>
          <td>Warranty start date</td>
          <td>Europe Specific: Number of eligible cars (equipped with A-IVC) with Warranty Start date (WSD) during period</td>
          <td>TCS team</td>
        </tr>

        <tr>
          <td>Post-sales</td>
          <td>Enrolled customers</td>
          <td>User who have successfully paired their vehicle with NissanConnect app.</td>
          <td>CarWings, KUA Database, VCS Team</td>
        </tr>
      </tbody>
    </table>
    <br />
  </div>
);
