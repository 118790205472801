import { ErrorBoundary } from "@sentry/react";
import React, { useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { setPageName, setSpecificParameter } from "../../../actions";
import { fetchActivationRatio } from "../../../actions/activationRatioActions";
import "../../../assets/styles/pages/ccsEuropeActivation.scss";
import { ErrorMsg } from "../../../components/AppMessages";
import { CcsUaeActivationFilters } from "../../../components/Filters/UaeActivationFilters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { BackToCockpitButton, PageTitle } from "../../../components/PageContent/subs";
import { ToggleSwitch } from "../../../components/Toogle";
import { ActivationOverview } from "../EuropeActivation/subs";

export const UAEActivation = withRouter((props: RouteComponentProps): JSX.Element => {
  const { history } = props;

  const dispatch = useDispatch();
  // Last data refresh
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.activation);
  const activationFormatParamValue = useSelector((state: RootStateOrAny) => state.activation_parameters.activation_format);
  const { breakdown: activationData, total: activationTotals } = useSelector(
    (state: RootStateOrAny) => state.activation_ratio.activation_ratio
  );

  const showRates = activationFormatParamValue === "rates";

  const [showCumulativeData, setShowCumulativeData] = useState(false);
  const [currentActive, setCurrentActive] = useState("");

  useEffect(() => {
    showRates ? setCurrentActive("ratio") : setCurrentActive("volume");
  }, [showRates]);

  useEffect(() => {
    dispatch(setPageName("UAE activation ratio reporting"));
  }, [dispatch]);

  useEffect(() => {
    Promise.all([dispatch(fetchActivationRatio())]);
  }, [dispatch, history.location.search]);

  const handleToggleChange = () => {
    const activationFormatValue = showRates ? "volumes" : "rates";
    dispatch(setSpecificParameter("activation_format", activationFormatValue));
  };

  const chartData = useMemo(() => {
    const data: any = [];
    if (activationData) {
      activationData.forEach((dataItem: any) => {
        data.push({
          date: dataItem.date,
          volume: showCumulativeData ? dataItem.cumulative_service_activated : dataItem.service_activated,
          total: showCumulativeData ? dataItem.cumulative_car_sold : dataItem.car_sold,
          rate: showCumulativeData ? dataItem.cumulative_activation_ratio : dataItem.activation_ratio,
        });
      });
    }
    return data;
  }, [activationData, showCumulativeData]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<CcsUaeActivationFilters />}
        lastDataRefresh={lastDataRefresh}
        dataDocumentation="ccs_data"
        pageTitle={
          <PageTitle
            dataDocumentation="ccs_data"
            showPageTitleDescription={true}
            backButton={<BackToCockpitButton id={"uae_activation"} />}
          />
        }
      >
        <ErrorBoundary fallback={<ErrorMsg />}>
          <div className="ccs_europe_activation_container">
            <ToggleSwitch
              activeToggleLabel={"Ratio"}
              inactiveToggleLabel={"Volumes"}
              active={showRates}
              handleToggleClick={handleToggleChange}
              toggleTitle={"See activation: "}
              toggleClassName="activation_cumulative_toggle"
            />
            <ToggleSwitch
              inactiveToggleLabel={"Cumulative"}
              activeToggleLabel={"Non-cumulative"}
              active={!showCumulativeData}
              handleToggleClick={() => setShowCumulativeData(!showCumulativeData)}
              toggleTitle={"See chart data: "}
              toggleClassName="activation_form"
            />

            <ErrorBoundary fallback={<ErrorMsg />}>
              <ActivationOverview
                title={"Activation " + currentActive + " overview"}
                rate={activationTotals ? activationTotals.activation_ratio : "n/a"}
                volume={activationTotals ? activationTotals.service_activated : "n/a"}
                warrantyStart={activationTotals ? activationTotals.car_sold : "n/a"}
                isLoading={false}
                chartData={chartData}
                cumulative={showCumulativeData}
                isActivationPage={true}
                isUae={true}
              />
            </ErrorBoundary>
          </div>
        </ErrorBoundary>
      </DefaultPageContent>
    </DashboardLayout>
  );
});
