import { CategoryAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { WEBSITE_VISITS_COLORS } from "../../../constants";
import { disposeChart } from "../helpers/chartHelpers";
import { configureCategoryAxis, configureValueAxis, legendConfiguration } from "../helpers/configurations";
import { configureOCELineSeries, oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  granularity: string;
  seriesList: Array<string>;
  theme: ThemeInt;
}

class TopModelsChart extends Component<Props> {
  static defaultProps = {
    granularity: "monthly",
  };

  chart!: XYChart;
  chartId = "topModelsGraph";

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId);
      this.initChart();
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, granularity, seriesList, theme } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#a";
    this.chart.colors.list = WEBSITE_VISITS_COLORS.map((chartColor) => color(chartColor));
    this.chart.paddingRight = 30;

    // Legend configuration
    this.chart.legend = legendConfiguration(theme);

    // Create Axes
    const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "date";
    categoryAxis.renderer.minGridDistance = 160;

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.numberFormatter.numberFormat = "#.0a";
    valueAxis.min = 0;

    // Creating series
    this.createSeries(seriesList?.slice(0, 6), granularity);

    this.chart.data.sort(function (a, b) {
      //@ts-ignore
      return new Date(a.date) - new Date(b.date);
    });
  }

  createSeries(SeriesList: string[], granularity: string) {
    SeriesList?.forEach((service) => {
      const series = this.chart.series.push(new LineSeries());
      series.dataFields.categoryX = "date";
      const bullet = configureOCELineSeries(series, {
        name: service,
        valueY: service,
        granularity,
      });
      bullet.tooltipText =
        "[bold font-size: var(--regular_font_size)]{categoryX}:[bold font-size: var(--regular_font_size)] {valueY.formatNumber('#.')}";
    });
  }
  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={"topModelsGraph"} data-test-id={"topModelsGraph"} className={"graph"} />
      </div>
    );
  }
}

export default TopModelsChart;
