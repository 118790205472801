import React from "react";

interface Props {
  id: string;
  name: string;
  value: string | number;
  labelName: string;
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

export const OceCheckBox = (props: Props) => {
  const { id, name, value, labelName, checked, setChecked } = props;

  return (
    <label htmlFor={name} className="metric_selection">
      <input
        type="checkbox"
        id={id}
        data-test-id={`${id}_radio_btn`}
        value={value}
        checked={checked}
        onChange={() => {
          setChecked(!checked);
        }}
      />
      <span className="checkmark"></span>
      <span className="description">{labelName}</span>
    </label>
  );
};
