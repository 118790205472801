import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchJourneyExcellenceLastDataRefresh, setPageName } from "../../../actions";
import { fetchFmiLastActMonth } from "../../../actions/journeyExcellenceActions";
import { ErrorMsg } from "../../../components/AppMessages";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import { BackgroundContext, DashboardBannerContext } from "../../../context";
import "../../../assets/styles/pages/journeyExcellenceDeepDive.scss";
import JourneyExcellenceTitle from "./components/JourneyExcellenceTitle";
import FmiContent from "./content/FmiContent";
import LeadsManagementSystemContent from "./content/LeadsManagementSystemContent";
import MainDashboardContent from "./content/MainDashboardContent";
import OnlineJourneyContent from "./content/OnlineJourneyContent";
import ProductJourneyContent from "./content/ProductJourneyContent";
import { JourneyExcellenceContext } from "./context/JourneyExcellenceContext";
import JourneyExcellenceDeepDiveFilters from "../../../components/Filters/JourneyExcellence/DeepDiveFilters";
import { JOURNEY_EXCELLENCE_BTN_FILTERS } from "../../../constants/journeyExcellenceConstants";

const JourneyExcellenceDeepDive = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const dispatch = useDispatch();
  const { setHideBackgroundImage } = useContext(BackgroundContext);
  const { setBannerText, setShowBanner } = useContext(DashboardBannerContext);
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.journey_excellence_last_data_refresh);
  const { date: lastActDate } = useSelector((state: RootStateOrAny) => state.journey_excellence.fmi_last_act_month);

  const [metric, setMetric] = useState<string>("");

  useEffect(() => {
    dispatch(setPageName("Journey Excellence Dashboard"));
    setHideBackgroundImage(true);
  }, []);

  useEffect(() => {
    dispatch(fetchJourneyExcellenceLastDataRefresh(metric));
  }, [metric, history.location.search]);

  useEffect(() => {
    if (metric != "Product Journey") {
      setBannerText(`Please note that Marketing Investment ACT data is only available for the selected filters until (${lastActDate}).`);
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
    return () => setShowBanner(false);
  }, [metric, lastActDate]);

  useEffect(() => {
    dispatch(fetchFmiLastActMonth());
  }, [history.location.search]);

  return (
    <JourneyExcellenceContext.Provider value={{ metric, setMetric }}>
      <DashboardLayout>
        <DefaultPageContent
          filter={<JourneyExcellenceDeepDiveFilters filterBtns={JOURNEY_EXCELLENCE_BTN_FILTERS} />}
          dataDocumentation="journey_excellence"
          lastDataRefresh={lastDataRefresh}
          pageTitle={<PageTitle dataDocumentation="journey_excellence" />}
        >
          <div className="journey-excellence-layout">
            <div className="title-menu hide_on_mobile">
              <div id="fmi_title" className="title_row journey-excellence-row" data-target="fmi">
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <JourneyExcellenceTitle title={"FMI"} />
                </ErrorBoundary>
              </div>

              <div id="online_journey_title" className="title_row journey-excellence-row" data-target="online_journey">
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <JourneyExcellenceTitle title={"Online Journey"} />
                </ErrorBoundary>
              </div>

              <div id="lead_management_title" className="title_row journey-excellence-row" data-target="lead_management">
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <JourneyExcellenceTitle title={"Leads Management System"} />
                </ErrorBoundary>
              </div>

              <div id="product_journey_title" className="title_row journey-excellence-row" data-target="product_journey">
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <JourneyExcellenceTitle title={"Product Journey"} />
                </ErrorBoundary>
              </div>
            </div>

            {metric == "" && <MainDashboardContent />}
            {metric == "FMI" && <FmiContent />}
            {metric == "Online Journey" && <OnlineJourneyContent />}
            {metric == "Leads Management System" && <LeadsManagementSystemContent />}
            {metric == "Product Journey" && <ProductJourneyContent />}
          </div>
        </DefaultPageContent>
      </DashboardLayout>
    </JourneyExcellenceContext.Provider>
  );
});

export default JourneyExcellenceDeepDive;
