import { put, retry, takeLatest } from "redux-saga/effects";
import {
  setAverageModel,
  setCustomerIntentionByColorData,
  setCustomerIntentionsVsSalePower,
  setCustomerIntentionVsSalesData,
  setGradeMixData,
  setLeadsFunnelData,
  setLeadToTestDriveRequestsData,
  setMarketingActivityData,
  setMediaSpendData,
  setMediaSpendTotalsData,
  setNimCustomerIntentionVsSales,
  setNimGrades,
  setNimSalesByTrend,
  setPriceVolumeSalesData,
  setPriceVolumeTrendData,
  setRegionalColorTrendData,
  setRetailSalesData,
  setSalesByTrendData,
  setSearchInterestVsFMIData,
  setSovData,
  setTestDriveRequestsData,
  setTotalLeadsData,
  setTotalsVisitsData,
  setWebVisitsByChannelData,
} from "../actions";
import {
  FETCH_AVERAGE_MODEL,
  FETCH_CUSTOMER_INTENTIONSVSSALE_POWER,
  FETCH_CUSTOMER_INTENTION_BY_COLOR,
  FETCH_CUSTOMER_INTENTION_VS_SALES,
  FETCH_GRADE_MIX,
  FETCH_LEADS_FUNNEL,
  FETCH_LEAD_TO_TEST_DRIVE_REQUESTS,
  FETCH_MARKEITNG_ACTIVITY,
  FETCH_MEDIA_SPEND,
  FETCH_MEDIA_SPEND_TOTALS,
  FETCH_NIM_CUSTOMER_INTENTION_VS_SALES,
  FETCH_NIM_GRADES,
  FETCH_NIM_SALES_BY_TREND,
  FETCH_PRICE_VOLUME_SALES_DATA,
  FETCH_PRICE_VOLUME_TREND_DATA,
  FETCH_REGIONAL_COLOR_TREND_DATA,
  FETCH_RETAIL_SALES,
  FETCH_SALES_BY_TREND_DATA,
  FETCH_SEARCH_INTEREST_VS_FMI_DATA,
  FETCH_SOV_DATA,
  FETCH_TEST_DRIVE_REQUESTS,
  FETCH_TOTALS_VISITS_DATA,
  FETCH_TOTAL_LEADS,
  FETCH_WEB_VISITS_BY_CHANNEL,
} from "../actions/actionTypes";
import { setLoading } from "../actions/loadingActions";
import {
  getAverageModel,
  getCustomerIntentionByColor,
  getCustomerIntentionsVsSalePower,
  getCustomerIntentionVsSalesData,
  getGradeMixData,
  getLeadsFunnelData,
  getLeadsToTestDriveRequestData,
  getMarketingActivityData,
  getMediaSpendData,
  getMediaSpendTotalsData,
  getNimCustomerIntentionVsSalesTrend,
  getNimGrades,
  getNimSalesByTrend,
  getPriceVolumeSalesData,
  getPriceVolumeTrendData,
  getRegionalColorTrendData,
  getRetailSalesData,
  getSalesByTrendData,
  getSearchInterestVsFMIData,
  getSovData,
  getTestDriveRequestData,
  getTotalLeadsData,
  getVisitsTotalsData,
  getWebVisitsByChannelData,
} from "../api";
import { secondsDelay } from "../api/utils";

//WATCHER SAGAS
export function* watchFetchCustomerIntentionVsSalesSaga() {
  yield takeLatest(FETCH_CUSTOMER_INTENTION_VS_SALES, fetchCustomerIntentionVsSalesSaga);
}

export function* watchFetchPriceVolumeSalesSaga() {
  yield takeLatest(FETCH_PRICE_VOLUME_SALES_DATA, fetchPriceVolumeSalesSaga);
}

export function* watchFetchSearchInterestVsFMISaga() {
  yield takeLatest(FETCH_SEARCH_INTEREST_VS_FMI_DATA, fetchSearchInterestVsFMISaga);
}

export function* watchFetchMediaSpendSaga() {
  yield takeLatest(FETCH_MEDIA_SPEND, fetchMediaSpendSaga);
}

export function* watchFetchMediaSpendTotalsSaga() {
  yield takeLatest(FETCH_MEDIA_SPEND_TOTALS, fetchMediaSpendTotalsSaga);
}

export function* watchFetchVisitsTotalsSaga() {
  yield takeLatest(FETCH_TOTALS_VISITS_DATA, fetchVisitsTotalsSaga);
}

export function* watchFetchTotalLeadsSaga() {
  yield takeLatest(FETCH_TOTAL_LEADS, fetchTotalLeadsSaga);
}

export function* watchFetchLeadsFunnelSaga() {
  yield takeLatest(FETCH_LEADS_FUNNEL, fetchLeadsFunnelSaga);
}

export function* watchFetchWebVisitsByChannelSaga() {
  yield takeLatest(FETCH_WEB_VISITS_BY_CHANNEL, fetchWebVisitsByChannelSaga);
}

export function* watchFetchLeadToTestDriveRequestSaga() {
  yield takeLatest(FETCH_LEAD_TO_TEST_DRIVE_REQUESTS, fetchLeadsToTestDriveRequestsSaga);
}

export function* watchFetchTestDriveRequestSaga() {
  yield takeLatest(FETCH_TEST_DRIVE_REQUESTS, fetchTestDriveRequestSaga);
}

export function* watchFetchRetailSalesSaga() {
  yield takeLatest(FETCH_RETAIL_SALES, fetchRetailSalesSaga);
}

export function* watchFetchSalesByTrendSaga() {
  yield takeLatest(FETCH_SALES_BY_TREND_DATA, fetchSalesByTrendSaga);
}

export function* watchFetchPriceVolumeTrendSaga() {
  yield takeLatest(FETCH_PRICE_VOLUME_TREND_DATA, fetchPriceVolumeTrendSaga);
}

export function* watchFetchGradeMixSaga() {
  yield takeLatest(FETCH_GRADE_MIX, fetchGradeMixSaga);
}

export function* watchFetchRegionalColorTrendDataSaga() {
  yield takeLatest(FETCH_REGIONAL_COLOR_TREND_DATA, fetchRegionalColorTrendDataSaga);
}

export function* watchFetchCustomerIntentionByColorSaga() {
  yield takeLatest(FETCH_CUSTOMER_INTENTION_BY_COLOR, fetchCustomerIntentionByColorSaga);
}
export function* watchFetchSovSaga() {
  yield takeLatest(FETCH_SOV_DATA, fetchSovData);
}

export function* watchFetchAverageModel() {
  yield takeLatest(FETCH_AVERAGE_MODEL, fetchAverageModel);
}

export function* watchFetchCustomerIntentionsVsSalePowerSaga() {
  yield takeLatest(FETCH_CUSTOMER_INTENTIONSVSSALE_POWER, fetchgetCustomerIntentionsVsSalePowerSaga);
}

export function* watchFetchNimCustomerIntentionVsSalesSaga() {
  yield takeLatest(FETCH_NIM_CUSTOMER_INTENTION_VS_SALES, fetchNimCustomerIntentionVsSalesSaga);
}

export function* watchFetchNimSalesByTrendSaga() {
  yield takeLatest(FETCH_NIM_SALES_BY_TREND, fetchNimSalesByTrendSaga);
}

export function* watchFetchNimGradesSaga() {
  yield takeLatest(FETCH_NIM_GRADES, fetchNimGradesSaga);
}

export function* watchFetchMarketingActivitySaga() {
  yield takeLatest(FETCH_MARKEITNG_ACTIVITY, fetchMarketingActivitySaga);
}
//WORKER SAGAS
export function* fetchCustomerIntentionVsSalesSaga() {
  try {
    yield put(setLoading("customer_intention_vs_sales", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getCustomerIntentionVsSalesData);
    if (!("error" in response)) {
      yield put(setCustomerIntentionVsSalesData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("customer_intention_vs_sales", false));
  }
}

export function* fetchSovData({ payload: mediaGroups }: any) {
  try {
    yield put(setLoading("sov_data", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getSovData, mediaGroups);
    if (!("error" in response)) {
      yield put(setSovData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("sov_data", false));
  }
}

//WORKER SAGAS
export function* fetchPriceVolumeSalesSaga({ payload: priceType }: any) {
  try {
    yield put(setLoading("price_volume_sales", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getPriceVolumeSalesData, priceType);
    if (!("error" in response)) {
      yield put(setPriceVolumeSalesData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("price_volume_sales", false));
  }
}

export function* fetchAverageModel() {
  try {
    yield put(setLoading("price_volume_sales", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getAverageModel);
    if (!("error" in response)) {
      yield put(setAverageModel(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("price_volume_sales", false));
  }
}

export function* fetchSearchInterestVsFMISaga({ payload: priceType }: any) {
  try {
    yield put(setLoading("search_interest_vs_fmi", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getSearchInterestVsFMIData, priceType);
    if (!("error" in response)) {
      yield put(setSearchInterestVsFMIData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("search_interest_vs_fmi", false));
  }
}

export function* fetchMediaSpendSaga() {
  try {
    yield put(setLoading("media_spend", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMediaSpendData);
    if (!("error" in response)) {
      yield put(setMediaSpendData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("media_spend", false));
  }
}

export function* fetchRegionalColorTrendDataSaga() {
  try {
    yield put(setLoading("media_spend", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getRegionalColorTrendData);
    if (!("error" in response)) {
      yield put(setRegionalColorTrendData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("media_spend", false));
  }
}

export function* fetchCustomerIntentionByColorSaga() {
  try {
    yield put(setLoading("media_spend", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getCustomerIntentionByColor);
    if (!("error" in response)) {
      yield put(setCustomerIntentionByColorData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("media_spend", false));
  }
}

export function* fetchMediaSpendTotalsSaga() {
  try {
    yield put(setLoading("media_spend_totals", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMediaSpendTotalsData);
    if (!("error" in response)) {
      yield put(setMediaSpendTotalsData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("media_spend_totals", false));
  }
}

export function* fetchVisitsTotalsSaga() {
  try {
    yield put(setLoading("visits_totals", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getVisitsTotalsData);
    if (!("error" in response)) {
      yield put(setTotalsVisitsData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("visits_totals", false));
  }
}

export function* fetchTotalLeadsSaga() {
  try {
    yield put(setLoading("total_leads", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getTotalLeadsData);
    if (!("error" in response)) {
      yield put(setTotalLeadsData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("total_leads", false));
  }
}

export function* fetchLeadsFunnelSaga() {
  try {
    yield put(setLoading("leads_funnel", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getLeadsFunnelData);
    if (!("error" in response)) {
      yield put(setLeadsFunnelData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("leads_funnel", false));
  }
}

export function* fetchWebVisitsByChannelSaga() {
  try {
    yield put(setLoading("visits_totals_by_channel", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getWebVisitsByChannelData);
    if (!("error" in response)) {
      yield put(setWebVisitsByChannelData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("visits_totals_by_channel", false));
  }
}

export function* fetchLeadsToTestDriveRequestsSaga() {
  try {
    yield put(setLoading("lead_to_test_drive_request", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getLeadsToTestDriveRequestData);
    if (!("error" in response)) {
      yield put(setLeadToTestDriveRequestsData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("lead_to_test_drive_request", false));
  }
}

export function* fetchTestDriveRequestSaga() {
  try {
    yield put(setLoading("test_drive_request", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getTestDriveRequestData);
    if (!("error" in response)) {
      yield put(setTestDriveRequestsData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("test_drive_request", false));
  }
}

export function* fetchRetailSalesSaga() {
  try {
    yield put(setLoading("retail_sales", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getRetailSalesData);
    if (!("error" in response)) {
      yield put(setRetailSalesData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("retail_sales", false));
  }
}

export function* fetchSalesByTrendSaga() {
  try {
    yield put(setLoading("sales_by_trend", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getSalesByTrendData);
    if (!("error" in response)) {
      yield put(setSalesByTrendData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("sales_by_trend", false));
  }
}

export function* fetchPriceVolumeTrendSaga() {
  try {
    yield put(setLoading("price_volume_trend", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getPriceVolumeTrendData);
    if (!("error" in response)) {
      yield put(setPriceVolumeTrendData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("price_volume_trend", false));
  }
}

export function* fetchGradeMixSaga() {
  try {
    yield put(setLoading("grade_mix", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getGradeMixData);
    if (!("error" in response)) {
      yield put(setGradeMixData(response.data));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("grade_mix", false));
  }
}

export function* fetchNimCustomerIntentionVsSalesSaga() {
  try {
    yield put(setLoading("nim_tech_customer_intention_vs_sales", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getNimCustomerIntentionVsSalesTrend);
    if (!("error" in response)) {
      yield put(setNimCustomerIntentionVsSales(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("nim_tech_customer_intention_vs_sales", false));
  }
}

export function* fetchNimSalesByTrendSaga() {
  try {
    yield put(setLoading("nim_tech_sales_by_trend", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getNimSalesByTrend);
    if (!("error" in response)) {
      yield put(setNimSalesByTrend(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("nim_tech_sales_by_trend", false));
  }
}

export function* fetchNimGradesSaga() {
  try {
    yield put(setLoading("nim_grades", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getNimGrades);
    if (!("error" in response)) {
      yield put(setNimGrades(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("nim_grades", false));
  }
}

export function* fetchgetCustomerIntentionsVsSalePowerSaga() {
  try {
    yield put(setLoading("power_trains", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getCustomerIntentionsVsSalePower);
    if (!("error" in response)) {
      yield put(setCustomerIntentionsVsSalePower(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("power_trains", false));
  }
}

export function* fetchMarketingActivitySaga() {
  try {
    yield put(setLoading("marketing_activity", true));
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMarketingActivityData);
    if (!("error" in response)) {
      yield put(setMarketingActivityData(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("marketing_activity", false));
  }
}
