import React, { useContext, useMemo } from "react";
import {
  cockpitDataDefinition,
  fmiDataDefinition,
  leadsManagementSystemDataDefinition,
  onlineJourneyDataDefinition,
  productJourneyDataDefinition,
} from "../../../constants/journeyExcellenceConstants";
import { JourneyExcellenceContext } from "../../../pages/Journey Excellence/DeepDive/context/JourneyExcellenceContext";

const JourneyExcellenceData = () => {
  const { metric } = useContext(JourneyExcellenceContext);

  const data = useMemo(() => {
    if (metric == "FMI") {
      return fmiDataDefinition;
    } else if (metric == "Online Journey") {
      return onlineJourneyDataDefinition;
    } else if (metric == "Leads Management System") {
      return leadsManagementSystemDataDefinition;
    } else if (metric == "Product Journey") {
      return productJourneyDataDefinition;
    }
    return cockpitDataDefinition;
  }, [metric]);

  return (
    <div className="data_documentation">
      <h3>JOURNEY EXCELLENCE - DATA OVERVIEW</h3>

      <br />

      <table className="docs_table">
        <thead>
          <tr>
            <th>KPI</th>
            <th>KPI definition</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((data, index) => (
            <tr key={index}>
              <td>{data.kpi}</td>
              <td>{data.definition}</td>
              <td>{data.source}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />
      <br />
    </div>
  );
};

export default JourneyExcellenceData;
