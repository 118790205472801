import { ErrorBoundary } from "@sentry/react";
import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg } from "../../../../components/AppMessages";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { formatToThreeDigits } from "../../../../utils/numberFormatting";
interface Props {
  title: string;
  mainValue: number;
  percentageValue?: number | string;
  percentageDefinition?: string;
  moduleDefinition?: string;
  level: string;
  pyramidId?: string;
  disabled?: boolean;
  isLoading: boolean;
}

export const JourneyPyramid = (props: Props): JSX.Element => {
  const { title, mainValue, percentageValue, moduleDefinition, level, percentageDefinition, pyramidId, disabled, isLoading } = props;
  const { region: regionParamValue } = useSelector((state: RootStateOrAny) => state.parameters);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className="journey_pyramid_section">
        <div id={`${pyramidId ? pyramidId : `journey_pyramid_${level}`}`} className={`journey_pyramid ${disabled ? "disabled" : ""}`}>
          <p className="title">{!disabled && title}</p>
          <div className="values">
            {!disabled && (
              <>
                <p className="main_value">{mainValue ? formatToThreeDigits(mainValue, regionParamValue == "China" ? 1 : 0) : "n/a"}</p>
                {percentageValue && <p className="percentage_value">{percentageValue ? `${percentageValue}%` : ""}</p>}
                {percentageDefinition && <p className="small_text">{percentageDefinition}</p>}
              </>
            )}
            {disabled && (
              <p className="small_text" style={{ marginTop: "3rem" }}>
                Not available for China market
              </p>
            )}
          </div>
          <LoadingEllipsis isLoading={isLoading} />
        </div>

        <div className="module_definition">
          <p className={`hide_on_mobile definition_${level}`}>{disabled ? "" : moduleDefinition}</p>
        </div>
      </div>
    </ErrorBoundary>
  );
};
