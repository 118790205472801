import React, { Fragment, useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { MultiSelectFilter } from ".";
import { setSpecificParameter } from "../../../actions";
import { closeFilterOptions } from "../subs/helpers";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { FilterContext } from "../../../context";

interface Props {
  filterValue: string;
  modelList: string[];
  showFilterOptionsFn: (evt: React.MouseEvent<HTMLElement>) => void;
  modelParamValue: string;
}

export const MarketPerformanceSummaryModelFilter = (props: Props) => {
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const { filterValue, modelList, showFilterOptionsFn, modelParamValue } = props;

  const handleMarketMultiselectSubmit = useCallback(() => {
    const parameterName = "model";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = selectedValues?.join(",");
    if (filterValue) {
      closeFilterOptions();
      dispatch(setSpecificParameter(parameterName, filterValue));
      eventTracking(MixpanelEvents.filter_change, {
        filter: parameterName,
        value: filterValue,
        dashboard: "Journey Excellence",
        page: "Market Performance Summary",
      });
    }
    setShowFilterOptions(false);
  }, []);

  const handleFilterOptionClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;
    closeFilterOptions();
    if (filterName && optionValue) {
      dispatch(setSpecificParameter("model", optionValue));
    }
    setShowFilterOptions(false);
  }, []);

  return (
    <div className={"filter"} id="market_filter_div">
      <label className="filter_header" htmlFor="market_filter">
        BRAND/MODEL
      </label>
      <div className="input_arrow" onClick={showFilterOptionsFn} data-options-ul={"model_ul"}>
        <input readOnly type="text" name="model" id="model_filter" className={"filter_input"} data-test-id="model" value={filterValue} />
        <span className="arrow_down " />
      </div>
      <ul className="filter_options" id="model_ul" data-test-id="model_list">
        <Fragment>
          <li className="filter_divider" data-filter={"model_group"} data-value={"Brand"} onClick={handleFilterOptionClick} key={"Brand"}>
            Brand
          </li>
          <MultiSelectFilter
            parentKey="model_filter"
            showOptionsOnly={true}
            // @ts-ignore
            filterList={modelList}
            value={filterValue}
            parameterName={"model"}
            parameterValue={modelParamValue.includes("All") ? modelList?.join(",") : modelParamValue}
            onShowFilterOptions={showFilterOptionsFn}
            handleFilterSubmission={handleMarketMultiselectSubmit}
          />
        </Fragment>
      </ul>
    </div>
  );
};
