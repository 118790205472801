import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { setParameters, setSpecificParameter } from "../../actions";
import { ALL_COUNTRIES, DEFAULT_MTM_PARAMETERS, MTM_DATE_OPTIONS } from "../../constants";
import { GeographyRowWithoutSubRegion } from "../../constants/interface";
import { FilterContext } from "../../context";
import { getLast4quarters } from "../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { currentDefaultFiltersSet, defaultFiltersSet } from "../../utils/utilityFunctions";
import { ErrorMsg } from "../AppMessages";
import { FiltersLayout } from "../Layouts";
import { DefaultFilter, GeographyFilter } from "./common";
import { MTMModelFilter } from "./common/MTMModelFilter";
import { closeFilterOptions, closeMarketOptions, objectToQuerystring } from "./subs/helpers";

export const MtmReportFilters = withRouter((props: RouteComponentProps): JSX.Element => {
  const { history } = props;
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);
  const [marketOptionsVisible, setMarketOptionsVisible] = useState(false);

  const markets = useSelector((state: RootStateOrAny) => state.filters.mtm_markets);
  const models = useSelector((state: RootStateOrAny) => state.mtm_data.models);
  const usersGroups = useSelector((state: RootStateOrAny) => state.user.details.groups);

  const [modelList, setModelList] = useState<Array<string>>([]);
  const [marketsList, setMarketsList] = useState<Array<string>>([]);
  const [isNNA, setIsNNA] = useState<boolean>(false);

  //Parameter values
  const params = useSelector((state: RootStateOrAny) => state.mtm_parameters);
  const {
    date_range: dateRangeParam,
    market: marketParam,
    model: modelParamValue,
  } = useSelector((state: RootStateOrAny) => state.mtm_parameters);

  //Filter values
  const [marketFilterValue, setMarketFilterValue] = useState("");
  const [dateFilterValue, setDateFilterValue] = useState("");
  const [modelFilterValue, setModelFilterValue] = useState("");

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("MTM metrics that matter");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_MTM_PARAMETERS));
      currentDefaultFiltersSet("MTM metrics that matter");
    }
  }, []);

  //Format the markets to get an array of the markets and store them in markets list
  useEffect(() => {
    if (markets) {
      const marketsListArr = markets.map((market: GeographyRowWithoutSubRegion) => market.region);
      setMarketsList(marketsListArr);
    }
  }, [markets]);

  //Check if user has NNA Geo Scope
  useEffect(() => {
    if (usersGroups) {
      for (const group of usersGroups) {
        if (group.name.includes("NNA")) {
          setIsNNA(true);
          break;
        }
      }
    }
  }, [usersGroups]);

  useEffect(() => {
    if (models) {
      setModelList(models);
    }
  }, [models]);

  //Update the market filter value whenever the market parameter is updated.
  useEffect(() => {
    if (marketParam) {
      setMarketFilterValue(marketParam);
    }
  }, [marketParam]);

  //Update the date filter value whenever the date range param value is updated.
  useEffect(() => {
    if (dateRangeParam && marketParam) {
      if (dateRangeParam.includes("month")) {
        setDateFilterValue("Monthly view");
        dispatch(setSpecificParameter("granularity", "monthly"));
      } else {
        setDateFilterValue("Quarterly view");
        dispatch(setSpecificParameter("granularity", "quarterly"));
      }
    }
  }, [dateRangeParam, marketParam]);

  //Reset market
  useEffect(() => {
    if (marketsList.length) {
      if (!marketsList.includes(marketParam) || marketParam === "All")
        dispatch(setSpecificParameter("market", isNNA ? "United States" : marketsList[0]));
    }
  }, [dispatch, marketsList, marketParam]);

  // Reset model filter
  useEffect(() => {
    if (modelList.length && modelParamValue) {
      const tempList = ["Nissan", ...modelList];

      const selectedModels = modelParamValue.split(",");

      const hasInvalidModels = selectedModels.filter((model: string) => !tempList.includes(model));

      if (hasInvalidModels.length > 0) dispatch(setSpecificParameter("model", modelList.join(",")));
    }
  }, [modelList, modelParamValue, dispatch]);

  //Set url parameters when we dispatch the filter changes to redux
  useEffect(() => {
    if (Object.keys(params).length > 0) {
      history.push({ search: `?${objectToQuerystring(params)}` });
    }
  }, [params]);

  const handleFilterSelection = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      if (filterName === "date_range" && optionValue === "Monthly view") {
        dispatch(setSpecificParameter(filterName, "Last 6 months"));
        eventTracking(MixpanelEvents.filter_change, { filter: filterName, value: "Last 6 months", page: "MTM", dashboard: "MTM" });
      } else if (filterName === "date_range" && optionValue === "Quarterly view") {
        dispatch(setSpecificParameter(filterName, getLast4quarters()));
        eventTracking(MixpanelEvents.filter_change, { filter: filterName, value: getLast4quarters(), page: "MTM", dashboard: "MTM" });
      } else {
        dispatch(setSpecificParameter(filterName, optionValue));
        eventTracking(MixpanelEvents.filter_change, { filter: filterName, value: optionValue, page: "MTM", dashboard: "MTM" });
      }
    }
    closeFilterOptions();
  }, []);

  //Reset filters to default filter options
  const resetFilters = useCallback(() => {
    eventTracking(MixpanelEvents.filter_reset, { page: "MTM", dashboard: "MTM" });

    dispatch(setParameters(DEFAULT_MTM_PARAMETERS));
  }, []);

  const handleMarketArrowClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { id: currentId, dataset },
      } = evt;
      const marketListId = dataset?.marketTarget;

      const currentElement = currentId ? document.getElementById(currentId) : undefined;
      const marketListElement = marketListId ? document.getElementById(marketListId) : undefined;
      const regionFilterUlElement = document.getElementById("region_ul");

      const shownClass = "show-market";
      const marketDownClass = "down";
      const regionMarketOpenClass = "market-open";

      const downClass = currentElement?.children[0]?.classList;
      if (!downClass?.contains(marketDownClass)) closeMarketOptions();
      downClass?.toggle(marketDownClass);
      marketListElement?.classList.toggle(shownClass);
      regionFilterUlElement?.classList.toggle(regionMarketOpenClass);
      setMarketOptionsVisible(!marketOptionsVisible);
    },
    [marketOptionsVisible]
  );

  const handleGeographyOptionClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const regionValue = dataset?.region;
    const marketValue = dataset?.market;

    regionValue && dispatch(setSpecificParameter("market", regionValue));
    marketValue && marketValue !== ALL_COUNTRIES && dispatch(setSpecificParameter("market", marketValue));
    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);
    eventTracking(MixpanelEvents.filter_change, {
      filter: "market",
      value: regionValue || marketValue || "",
      page: "MTM",
      dashboard: "MTM",
    });
  }, []);

  const showFilterOptionsFn = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const optionsId = dataset?.optionsUl;
      const shownClass = "showOptions";

      const optionsElement = optionsId ? document.getElementById(optionsId) : undefined;

      if (showFilterOptions && optionsElement?.classList.contains(shownClass)) {
        closeFilterOptions();
        closeMarketOptions();
        setShowFilterOptions(false);
      } else {
        closeFilterOptions();
        optionsElement?.classList.toggle(shownClass);
        setShowFilterOptions(!showFilterOptions);
      }
    },
    [showFilterOptions, setShowFilterOptions]
  );

  //Set model filter value
  useEffect(() => {
    if (modelList.length > 0 && modelParamValue) {
      const selectedLeadsArr = modelParamValue.split(",");
      const modelNameValue =
        selectedLeadsArr?.length === modelList.length && modelParamValue != "Nissan"
          ? "All"
          : selectedLeadsArr?.length > 1
          ? `${selectedLeadsArr?.length} models selected`
          : modelParamValue;

      setModelFilterValue(modelNameValue);
      eventTracking(MixpanelEvents.filter_change, { filter: "model", value: selectedLeadsArr, dashboard: "MTM", page: "MTM" });
  }
  }, [modelList, modelParamValue]);

  const handleModelSubmit = useCallback(() => {
    const parameterName = "model";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = selectedValues?.length === modelList?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    dispatch(setSpecificParameter(parameterName, filterValue));
  }, [modelList]);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <FiltersLayout resetFilters={resetFilters}>
        <>
          <DefaultFilter
            filterName="date_range"
            list={MTM_DATE_OPTIONS}
            filterValue={dateFilterValue}
            filterLabel={"DATE RANGE"}
            handleFilterOptionClick={handleFilterSelection}
          />

          {/* Geography */}
          <GeographyFilter
            geographies={markets}
            value={marketFilterValue}
            onHandleMarketArrowClick={handleMarketArrowClick}
            onShowFilterOptions={showFilterOptionsFn}
            onHandleGeographyOptionsClick={handleGeographyOptionClick}
          />

          <MTMModelFilter
            filterValue={modelFilterValue}
            modelList={modelList}
            showFilterOptions={showFilterOptionsFn}
            modelParamValue={modelParamValue}
          />
        </>
      </FiltersLayout>
    </ErrorBoundary>
  );
});
