import React, { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import MsrpDisByModelAndMonthChart from "../../../components/Charts/MSRP/MsrpDisByModelAndMonth";
import { INDIGO, TOP_FIVE_MODEL_COLORS, TOP_FIVE_MODEL_EXTRA_COLOR } from "../../../constants";
import { ThemeContext } from "../../../context";

const MsrpDisByModelAndMonth = () => {
  const themeContext = useContext(ThemeContext);

  const { data, series } = useSelector((state: RootStateOrAny) => state.msrp.dis_by_model_and_month);
  const { dis_by_model_and_month: disByModelAndMonthLoading } = useSelector((state: RootStateOrAny) => state.loading);
  const topFiveModels = useSelector((state: RootStateOrAny) => state.msrp.top_five_models);

  const modelColorsMapping = useMemo(() => {
    const res: Record<string, string> = {};
    topFiveModels.map((model: string, idx: number) => {
      res[`${model}`] = TOP_FIVE_MODEL_COLORS[idx];
    });
    res["Others"] = INDIGO;
    series &&
      series
        .filter((item: any) => !topFiveModels.includes(item.name))
        .map(({ name }: any, idx: number) => {
          if (!topFiveModels.includes(name) && name != "Others") {
            res[`${name}`] = TOP_FIVE_MODEL_EXTRA_COLOR[idx];
          }
        });
    return res;
  }, [topFiveModels, series]);

  return (
    <div className="full-width-row">
      <div className="msrp-tile">
        <div className="tile-header">
          MSRP DIS by Model and by Month
          <Tooltip
            position="top-end"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            distance={50}
            delay={1}
            hideDelay={1}
            duration={1}
            html={
              <div>
                <p>MSRP DIS by Model and by Month displays the top 5 models based on the DIS number per month.</p>
                <p>MSRP DIS by model: proportion by model in total DIS in the market within the selected month.</p>
                <p>Sales volume: Sum of DIS for the selected model within the selected month.</p>
              </div>
            }
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        <MsrpDisByModelAndMonthChart
          data={data}
          seriesList={series}
          chartName="msrpDisByModelAndMonthChart"
          theme={themeContext.theme}
          isLoading={disByModelAndMonthLoading}
          colorMapping={modelColorsMapping}
        />
      </div>
    </div>
  );
};

export default MsrpDisByModelAndMonth;
