// Todo: Add missing dates for last data refresh alert
import { Box, Button, Grid } from "@material-ui/core";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { setAriyaVisits, setLastDataRefresh, setSearchInterestVolume } from "../../../actions";
import {
  fetchPaidOrganicData,
  fetchPopularContent,
  fetchPopularMoments,
  fetchSearchVolumesData,
  fetchSocialNetworks,
  fetchVCData,
  fetchVC_PreviousData,
  fetchVisitsData,
  fetchVLPData,
  fetchVLP_PreviousData,
  fetchWPData,
  fetchWP_PreviousData,
} from "../../../api/Ariya";
import { HomeLayout } from "../../../components/Layouts/Ariya";
import { ARIYA_COUNTRY_INITIALS } from "../../../constants";
import {
  FormattedSocialRow,
  IChartData,
  IGlobalViewDonutData,
  IMetricTotal,
  popularState,
  SocialRow,
} from "../../../constants/interface/ariya";
import { ThemeContext } from "../../../context";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { isNull, plusSignNumbers } from "../../../utils/utilityFunctions";
import commonStyles from "../ariya.module.scss";
import { getGraphData } from "../helpers/charts";
import { extractMonitorData, extractProgressTableDataFromResponse } from "../helpers/dataFunctions";
import { ExperienceCard } from "./components/Card";
import { GlobalViewsDonut, SearchInterestLineGraph, VisitsLineChart } from "./components/Charts";
import { ProgressRow, SourceOfVisitors } from "./components/Tables";
import style from "./experienceInsight.module.scss";

const popularDescriptionMapping: Record<string, string> = {
  serenely: "(Interior lounge)",
  smartly: "(Connected Car, App, Home)",
  effortlessly: "(ProPILOT and ProPILOT Park)",
  powerfully: "(E-4ORCE)",
};

const referrers_rename: Record<string, string> = {
  "Other Web Sites": "Nissan brand websites",
  "Typed/Bookmarked": "Direct*",
  "Search Engines": "Internet search",
  "facebook.com": "Facebook",
  "instagram.com": "Instagram",
  "t.co": "Twitter",
  "youtube.com": "YouTube",
  "linkedin.com": "LinkedIn",
  "Social Networks": "Social networks",
};

export const ExperienceInsight = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.ariya_monitor);

  const [searchInterestChartData, setSearchInterestChartData] = useState<
    Array<{ country: string; date: string; search_term: string; search_volume: number }>
  >([]);
  const [visitsChartData, setVisitsChartData] = useState<Array<IChartData>>([]);
  const [globalVLP, setGlobalVLP] = useState<IMetricTotal>({ current: null, previous: null });
  const [globalVC, setGlobalVC] = useState<IMetricTotal>({ current: null, previous: null });
  const [globalWP, setGlobalWP] = useState<IMetricTotal>({ current: null, previous: null });
  const [globalPaidOrganic, setGlobalPaidOrganic] = useState<IGlobalViewDonutData>([]);
  const [popularMoments, setPopularMoments] = useState<popularState>({
    records: [],
  });
  const [popularContent, setPopularContent] = useState<popularState>({
    records: [],
  });
  const [socialDrilldownData, setSocialDrilldownData] = useState<Array<FormattedSocialRow & { breakdown: Array<FormattedSocialRow> }>>([]);

  const [showingMore, setShowingMore] = useState(false);

  const pageNavigation = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      const {
        currentTarget: { dataset },
      } = evt;
      const pageLink = dataset?.page;

      history.push({ pathname: pageLink, search: "" });
    },
    [history]
  );

  // Usage tracking
  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Ariya", page: "Ariya Experience Insights" });
  }, []);

  // Last data refresh plus Visits trend
  useEffect(() => {
    fetchVisitsData().then((response) => {
      if (response && !("error" in response)) {
        dispatch(setAriyaVisits(response));
        const {
          chart_data: { year, month, day, data },
        } = extractMonitorData(response);
        Promise.all([dispatch(setLastDataRefresh("ariya_monitor", `${year}-${month}-${day}`)), setVisitsChartData(getGraphData(data))]);
      }
    });
  }, []);

  // Search Interest Index Data
  useEffect(() => {
    fetchSearchVolumesData().then((response) => {
      if (response && !("error" in response)) {
        dispatch(setSearchInterestVolume(response));

        setSearchInterestChartData(
          response.map((row) => {
            const { country, search_volume } = row;
            const newCountry = country in ARIYA_COUNTRY_INITIALS ? ARIYA_COUNTRY_INITIALS[country] : country;
            return { ...row, country: newCountry, [newCountry]: Math.round(search_volume) };
          })
        );
      }
    });
  }, []);

  // Global views
  useEffect(() => {
    Promise.all([
      fetchVLPData().then((response) => {
        if (response && !("error" in response)) {
          setGlobalVLP((prevState) => ({ ...prevState, current: +response.report.totals[0] }));
        }
      }),

      fetchVLP_PreviousData().then((response) => {
        if (response && !("error" in response)) {
          setGlobalVLP((prevState) => ({ ...prevState, previous: +response.report.totals[0] }));
        }
      }),

      fetchVCData().then((response) => {
        if (response && !("error" in response)) {
          setGlobalVC((prevState) => ({ ...prevState, current: +response.report.totals[0] }));
        }
      }),

      fetchVC_PreviousData().then((response) => {
        if (response && !("error" in response)) {
          setGlobalVC((prevState) => ({ ...prevState, previous: +response.report.totals[0] }));
        }
      }),

      fetchWPData().then((response) => {
        if (response && !("error" in response)) {
          setGlobalWP((prevState) => ({ ...prevState, current: +response.report.totals[0] }));
        }
      }),

      fetchWP_PreviousData().then((response) => {
        if (response && !("error" in response)) {
          setGlobalWP((prevState) => ({ ...prevState, previous: +response.report.totals[0] }));
        }
      }),

      fetchPaidOrganicData().then((response) => {
        if (response && !("error" in response)) {
          const totals = response.report.totals;
          setGlobalPaidOrganic([
            { name: "Paid traffic", value: +totals[0] },
            { name: "Organic traffic", value: +totals[1] },
          ]);
        }
      }),
    ]);
  }, []);

  // Popular moments
  useEffect(() => {
    Promise.all([
      fetchPopularMoments().then((response) => {
        if (response && !("error" in response)) {
          setPopularMoments({ records: extractProgressTableDataFromResponse(response).sort((a, b) => a.percentage - b.percentage) });
        }
      }),

      fetchPopularContent().then((response) => {
        if (response && !("error" in response)) {
          setPopularContent({ records: extractProgressTableDataFromResponse(response).sort((a, b) => a.percentage - b.percentage) });
        }
      }),
    ]);
  }, []);

  const formatSocialNetworkData = (
    data: Array<SocialRow & { breakdown?: Array<SocialRow> }>,
    total: number
  ): Array<FormattedSocialRow & { breakdown: Array<FormattedSocialRow> }> => {
    if (!data) return data;

    return data.map((row) => {
      const rename = row.name in referrers_rename ? referrers_rename[row.name] : row.name;
      return {
        name: rename,
        share: Math.round((+row.counts[0] / total) * 100),
        breakdown: row.breakdown && rename === "Social networks" ? formatSocialNetworkData(row.breakdown.slice(0, 5), +row.counts[0]) : [],
      };
    });
  };

  // Social Networks
  useEffect(() => {
    fetchSocialNetworks().then((response) => {
      if (response && !("error" in response)) {
        const totalCount = response.report.data.reduce((res, item) => {
          res += parseInt(item.counts[0]);

          return res;
        }, 0);

        setSocialDrilldownData(formatSocialNetworkData(response.report.data, totalCount));
      }
    });
  }, []);

  return (
    <HomeLayout title="ARIYA experience insights" lastDataRefresh={lastDataRefresh}>
      <>
        <Box display={"grid"} gridGap={15} className={style.cardContainer}>
          {/* Search interest */}
          <ExperienceCard
            id="search_interest_index"
            title="Search interest volume"
            footnote="Click on market to change the chart"
            className={style.search}
          >
            <SearchInterestLineGraph
              data={searchInterestChartData}
              series={["JP", "US", "UK", "DE", "NO"]}
              chartName="searchInterestVolume"
              theme={themeContext.theme}
            />
          </ExperienceCard>

          {/* Visitor */}
          <ExperienceCard
            id="source_of_visitors"
            title="Source of visitors"
            footnote="*Visitors who bookmarked or typed the URL"
            className={style.visitors}
          >
            <div className={style.tableWrapper}>
              <SourceOfVisitors tableData={socialDrilldownData} showBreakdown={showingMore} />
              {socialDrilldownData.length && (
                <span className={style.showMore} onClick={() => setShowingMore((prevState) => !prevState)}>
                  {showingMore ? "Show less" : "Show more"}
                </span>
              )}
            </div>
          </ExperienceCard>

          {/* Visits trend */}
          <ExperienceCard id="visits_trend" title="Visits trend" className={style.visits}>
            <VisitsLineChart chartId="visitsTrend" data={visitsChartData} theme={themeContext.theme} />
          </ExperienceCard>

          {/* Popular moments */}
          <ExperienceCard
            id="popular_moments"
            title="Most popular moments on virtual car"
            titleDescription="(% share of views)"
            className={style.moments}
          >
            <>
              {popularMoments.records.map((record) => (
                <ProgressRow
                  key={record.name}
                  name={record.name}
                  value={record.percentage}
                  description={
                    record.name.toLowerCase() in popularDescriptionMapping ? popularDescriptionMapping[record.name.toLowerCase()] : ""
                  }
                />
              ))}
            </>
          </ExperienceCard>

          {/* Popular content */}
          <ExperienceCard
            id="popular_content"
            title="Most popular content on vehicle landing"
            titleDescription="(% share of views)"
            className={style.content}
          >
            <>
              {popularContent.records.map((record) => (
                <ProgressRow
                  key={record.name}
                  name={record.name}
                  value={record.percentage}
                  description={
                    record.name.toLowerCase() in popularDescriptionMapping ? popularDescriptionMapping[record.name.toLowerCase()] : ""
                  }
                />
              ))}
            </>
          </ExperienceCard>

          {/* Global views */}
          <ExperienceCard id="global_views" title="Global views as of yesterday" className={style.views}>
            <div className={style.viewsWrapper}>
              <div className={"viewsBreakdown"}>
                {/* Landing page */}
                <div className={style.globalSection} data-test-id="landing_page">
                  <h3 className={style.sectionTitle}>Vehicle landing page views</h3>
                  <p className={style.sectionValue}>{isNull(globalVLP.current) ? "-" : globalVLP.current?.toLocaleString()}</p>
                  <p className={style.sectionLastDayValue}>
                    {isNull(globalVLP.previous) ? "-" : plusSignNumbers(globalVLP.previous)}{" "}
                    <span className={style.lastDayText}>in the last day</span>
                  </p>
                </div>

                {/* Virtual car */}
                <div className={style.globalSection} data-test-id="virtual_car_page">
                  <h3 className={style.sectionTitle}>Virtual car page views</h3>
                  <p className={style.sectionValue}>{isNull(globalVC.current) ? "-" : globalVC.current?.toLocaleString()}</p>
                  <p className={style.sectionLastDayValue}>
                    {isNull(globalVC.previous) ? "-" : plusSignNumbers(globalVC.previous)}{" "}
                    <span className={style.lastDayText}>in the last day</span>
                  </p>
                </div>

                {/* Next page */}
                <div className={style.globalSection} data-test-id="next_page">
                  <h3 className={style.sectionTitle}>The nissan next page views</h3>
                  <p className={style.sectionValue}>{isNull(globalWP.current) ? "-" : globalWP.current?.toLocaleString()}</p>
                  <p className={style.sectionLastDayValue}>
                    {isNull(globalWP.previous) ? "-" : plusSignNumbers(globalWP.previous)}{" "}
                    <span className={style.lastDayText}>in the last day</span>
                  </p>
                </div>
              </div>
              <div className={"viewsChart"}>
                <GlobalViewsDonut data={globalPaidOrganic} theme={themeContext.theme} />
              </div>
            </div>
          </ExperienceCard>
        </Box>

        <Grid container spacing={10} justifyContent={"space-evenly"}>
          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page="/ariya/monitor"
              data-test-id="ariyaHome"
            >
              Ariya home
            </Button>
          </Grid>

          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page="/ariya/handraisers"
              data-test-id="ariyaHandraisers"
            >
              Hand-raisers insights
            </Button>
          </Grid>

          <Grid item xl={4}>
            <Button
              variant={"outlined"}
              className={commonStyles.btn}
              onClick={pageNavigation}
              size={"small"}
              data-page="/ariya/preorder"
              data-test-id="ariyaPreorder"
            >
              Online pre-order insights
            </Button>
          </Grid>
        </Grid>
      </>
    </HomeLayout>
  );
});
