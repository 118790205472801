import { Box, LinearProgress, LinearProgressProps } from "@material-ui/core";
import React from "react";
import style from "./progressRow.module.scss";

interface Props {
  name: string;
  description?: string;
  value: number;
}

function LinearProgressWithLabel(props: LinearProgressProps & Props) {
  return (
    <Box data-test-id={props.name}>
      <Box sx={{ display: "flex", alignItems: "center" }} className={style.headerContainer}>
        <h4 className={style.header}>
          {props.name} {props.description && <span className={style.headerDescription}>{props.description}</span>}
        </h4>

        <Box sx={{ margin: "auto", marginRight: 0 }}>
          <p className={style.header}>{`${Math.round(props.value)}%`}</p>
        </Box>
      </Box>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          className={style.progress}
          classes={{ barColorPrimary: style.barColor, colorPrimary: style.progressBackground, bar: style.progress }}
        />
      </Box>
    </Box>
  );
}

export function ProgressRow(props: Props) {
  return (
    <Box sx={{ width: "100%", marginBottom: "20px" }}>
      <LinearProgressWithLabel {...props} />
    </Box>
  );
}
