import React from "react";
import { getValueClass, plusSignNumbers } from "../../../../utils/utilityFunctions";

interface Props {
  columnHeaders: Array<string>;
  columnDataKeys: Array<{ key: string; type: string }>;
  data: Array<Record<string, any>>;
}

const MarketPerformanceSummaryTable = (props: Props) => {
  const { columnHeaders, columnDataKeys, data } = props;

  // TODO: confirm with Max if this function already exists in the codebase
  // make the table numbers compact. e.g. 1,000,000 -> 1M
  const formatter = Intl.NumberFormat("en", { notation: "compact" });

  return (
    <table className="market-performance-summary-table">
      {data && data.length > 0 && (
        <thead>
          <tr>
            {columnHeaders.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {data &&
          data.length > 0 &&
          data.map((dataItem, idx) => (
            <tr key={Object.keys(dataItem).join("") + idx}>
              {columnDataKeys.map((dataKey, idx) => (
                <td
                  key={`${dataKey.key}_${idx}`}
                  className={`${dataKey.key.includes("yoy") && dataItem[dataKey.key] ? getValueClass(dataItem[dataKey.key]) : ""}`}
                >
                  {dataItem[dataKey.key] && dataKey.key.includes("yoy")
                    ? `${plusSignNumbers(dataItem[dataKey.key])}`
                    : dataKey.type == "number"
                    ? formatter.format(dataItem[dataKey.key])
                    : dataItem[dataKey.key]}
                  {dataItem[dataKey.key] ? (dataKey.type == "percentage" ? "%" : dataKey.type == "pts" ? "PTS" : "") : ""}
                </td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default MarketPerformanceSummaryTable;
