import React from "react";
import { CockpitTitle, EngagementTiles } from "../normalSections";

export const EngagementMetric = () => {
  return (
    <>
      <CockpitTitle title={"Business revenue"} hide_on_desktop={true} />
      <CockpitTitle title={"Customer quality"} hide_on_desktop={true} />
      <CockpitTitle title={"Engagement"} hide_on_desktop={true} />
      <EngagementTiles showMetric={true} />

      <CockpitTitle title={"Operational"} hide_on_desktop={true} />
    </>
  );
};
