// Monitor
export const SET_ARIYA_VISITS = "SET_ARIYA_VISITS";
export const SET_ARIYA_HANDRAISERS = "SET_ARIYA_HANDRAISERS";
export const SET_ARIYA_PREORDER = "SET_ARIYA_PREORDER";
export const SET_ARIYA_DEPOSIT_TOTALS = "SET_ARIYA_DEPOSIT_TOTALS";

// Experience InSights
export const SET_SEARCH_INTEREST = "SET_SEARCH_INTEREST";
export const SET_SEARCH_INTEREST_VOLUME = "SET_SEARCH_INTEREST_VOLUME";
export const SET_GLOBAL_VLP = "SET_GLOBAL_VLP";
export const SET_GLOBAL_VLP_PREVIOUS = "SET_GLOBAL_VLP_PREVIOUS";
export const SET_GLOBAL_VC = "SET_GLOBAL_VC";
export const SET_GLOBAL_VC_PREVIOUS = "SET_GLOBAL_VC_PREVIOUS";
export const SET_GLOBAL_WP = "SET_GLOBAL_WP";
export const SET_GLOBAL_WP_PREVIOUS = "SET_GLOBAL_WP_PREVIOUS";
export const SET_GLOBAL_PAID_ORGANIC = "SET_GLOBAL_PAID_ORGANIC";
export const SET_POPULAR_CONTENT = "SET_POPULAR_FRAMES";
export const SET_POPULAR_MOMENTS = "SET_POPULAR_MOMENTS";
export const SET_SOCIAL_NETWORKS = "SET_SOCIAL_NETWORKS";

// HandraiserInsights
export const SET_HANDRAISERS_NAE = "SET_HANDRAISERS_NAE";
export const SET_ONLINE_PREORDER = "SET_ONLINE_PREORDER";
