import { AnyAction } from "redux";
import { all, put, retry, takeEvery, takeLatest } from "redux-saga/effects";
import {
  setAppRatingsFiltersData,
  setBrandPerformanceImageAttributeFilters,
  setBrandPerformanceOaOFilters,
  setBrandPerformanceVolumeFilters,
  setCCSFiltersData,
  setCpoOperationalFilters,
  setEcommerceFilters,
  setEuropeActivationFiltersData,
  setEuropeActivationMarketGroups,
  setExecutiveSummaryFilters,
  setGeoScope,
  setJourneyExcellenceFilters,
  setLastDataRefresh,
  setMidasFilters,
  setMidasSpendVsTrafficResponseError,
  setMsrpFilters,
  setMTMMarketsData,
  setMtmModels,
  setNewPurchaseFunnelFilters,
  setOCEAllMarketRegions,
  setOCEAllRegions,
  setOCEChannelsByBrandFilters,
  setOCEMarketsByBrandFilters,
  setOCEModelsByBrandFilters,
  setPmoResponseError,
  setProductMarketingOptimizationFilters,
  setProductPerformanceFilters,
  setSeoGeography,
  setTopFlopFilters,
  setUaeActivationFilters,
  setUseOwnershipFilters,
  setUserGroupMarkets,
  setVocFilters,
} from "../actions";
import {
  FETCH_APP_RATINGS_FILTERS,
  FETCH_BRAND_PERFORMANCE_IMAGE_ATTRIBUTE_FILTERS,
  FETCH_BRAND_PERFORMANCE_OAO_FILTERS,
  FETCH_BRAND_PERFORMANCE_VOLUME_FILTERS,
  FETCH_CCS_FILTERS,
  FETCH_CPO_OPERATIONAL_FILTERS,
  FETCH_ECOMMERCE_FILTERS,
  FETCH_EUROPE_ACTIVATION_FILTERS,
  FETCH_EUROPE_ACTIVATION_MARKET_GROUPS,
  FETCH_EXECUTIVE_SUMMARY_FILTERS,
  FETCH_GEO_SCOPE,
  FETCH_JOURNEY_EXCELLENCE_FILTERS,
  FETCH_MIDAS_FILTERS,
  FETCH_MIDAS_FIXED_FILTERS,
  FETCH_MIDAS_FMI_BY_MODEL_FILTERS,
  FETCH_MIDAS_MEDIA_MIX_FILTERS,
  FETCH_MIDAS_MTP_CATEGORY_FILTERS,
  FETCH_MIDAS_SPEND_VS_TRAFFIC_FILTERS,
  FETCH_MIDAS_STRATEGIC_CE_FILTERS,
  FETCH_MIDAS_STRATEGIC_DEALER_SUPPORT_FILTERS,
  FETCH_MSRP_FILTERS,
  FETCH_MTM_MARKETS,
  FETCH_MTM_MODELS,
  FETCH_NEW_PURCHASE_FUNNEL_FILTERS,
  FETCH_OCE_ALL_MARKET_REGIONS,
  FETCH_OCE_ALL_REGIONS,
  FETCH_OCE_CHANNELS_BY_BRAND_FILTERS,
  FETCH_OCE_MARKETS_BY_BRAND_FILTERS,
  FETCH_OCE_MODELS_BY_BRAND_FILTERS,
  FETCH_PRODUCT_MARKETING_OPTIMIZATION_FILTERS,
  FETCH_PRODUCT_PERFORMANCE_FILTERS,
  FETCH_SEO_GEOGRAPHY,
  FETCH_TOP_FLOP_FILTERS,
  FETCH_UAE_ACTIVATION_FILTERS,
  FETCH_USER_GROUP_MARKETS,
  FETCH_USE_OWNERSHIP_FILTERS,
  FETCH_VOC_FILTERS,
} from "../actions/actionTypes";
import {
  getAppRatingsFilterData,
  getBrandPerformanceImageAttributesFilters,
  getBrandPerformanceOaOFilters,
  getBrandPerformanceVolumeFilters,
  getCcsCockpitFilterData,
  getCpoOperationalFilters,
  getDisFilters,
  getEcommerceFilters,
  getEuropeActivationFilterData,
  getEuropeActivationMarketGroups,
  getExecutiveSummaryDisFilters,
  getGeoScopeData,
  getJourneyExcellenceFilters,
  getMidasFilters,
  getMidasFixedFilters,
  getMidasFmiByModelFilters,
  getMidasMediaMixFilters,
  getMidasMtpCategoryFilters,
  getMidasSpendVsTrafficFilters,
  getMidasStrategicCEFilters,
  getMidasStrategicDealerSupportFilters,
  getMsrpFilters,
  getMtmMarketsData,
  getOCEAllMarketRegions,
  getOCEAllRegionsFilters,
  getOCEChannelsByBrandFilters,
  getOCEMarketsByBrandFilters,
  getOCEModelsByBrandFilters,
  getProductMarketingOptimizationFilters,
  getProductPerformanceFilters,
  getSeoFilters,
  getTopFlopFilters,
  getUaeFilters,
  getUseOwnershipFilters,
  getUserGroupMarkets,
  getVocFilters,
} from "../api";
import { getMtmModelFilterData } from "../api/mtmReportApi";
import { secondsDelay } from "../api/utils";
import {
  FiltersData,
  IAppRatingsFilters,
  ICockpitFiltersData,
  IEuropeActivationFiltersData,
  IMtmMarkets,
  ISeoFilters,
  IUserGroupMarkets,
} from "../constants/interface";

// Watcher Sagas
export function* watchFetchGeoScope() {
  yield takeEvery(FETCH_GEO_SCOPE, fetchGeoScope);
}

export function* watchFetchCCSFilters() {
  yield takeEvery(FETCH_CCS_FILTERS, fetchCCSFiltersData);
}

export function* watchFetchAppRatingsFilters() {
  yield takeLatest(FETCH_APP_RATINGS_FILTERS, fetchAppRatings_FiltersData);
}

export function* watchFetchEuropeActivationFilters() {
  yield takeEvery(FETCH_EUROPE_ACTIVATION_FILTERS, fetchEuropeActivationFilterDataSaga);
}

export function* watchFetchEuropeActivationMarketGroups() {
  yield takeEvery(FETCH_EUROPE_ACTIVATION_MARKET_GROUPS, fetchEuropeActivationMarketGroupsDataSaga);
}

export function* watchFetchUserGroupMarkets() {
  yield takeEvery(FETCH_USER_GROUP_MARKETS, fetchUserGroupMarketsSaga);
}

export function* watchFetchMtmMarkets() {
  yield takeEvery(FETCH_MTM_MARKETS, fetchMtmMarketsSaga);
}

export function* watchFetchMtmModels() {
  yield takeEvery(FETCH_MTM_MODELS, fetchMtmModelsData);
}

export function* watchFetchSeoGeography() {
  yield takeEvery(FETCH_SEO_GEOGRAPHY, fetchSeoGeographySaga);
}

export function* watchFetchPurchaseFunnelFiltersSaga() {
  yield takeEvery(FETCH_NEW_PURCHASE_FUNNEL_FILTERS, fetchPurchaseFunnelFiltersSaga);
}

export function* watchTopFlopFiltersSaga() {
  yield takeEvery(FETCH_TOP_FLOP_FILTERS, fetchTopFlopFiltersSaga);
}

export function* watchProductMarketingOptimizationFiltersSaga() {
  yield takeEvery(FETCH_PRODUCT_MARKETING_OPTIMIZATION_FILTERS, fetchProductMarketingOptimizationFiltersSaga);
}

export function* watchMidasFiltersSaga() {
  yield takeEvery(FETCH_MIDAS_FILTERS, fetchMidasFiltersSaga);
}

export function* watchMidasFmiByModelFiltersSaga() {
  yield takeEvery(FETCH_MIDAS_FMI_BY_MODEL_FILTERS, fetchMidasFmiByModelFiltersSaga);
}

export function* watchMidasMediaMixltersSaga() {
  yield takeEvery(FETCH_MIDAS_MEDIA_MIX_FILTERS, fetchMidasMediaMixFiltersSaga);
}

export function* watchMidasMtpCategoryltersSaga() {
  yield takeEvery(FETCH_MIDAS_MTP_CATEGORY_FILTERS, fetchMidasMtpCategoryFiltersSaga);
}

export function* watchMidasStrategicDealerSupportFIltersSaga() {
  yield takeEvery(FETCH_MIDAS_STRATEGIC_DEALER_SUPPORT_FILTERS, fetchMidasStrategicDealerSupportFiltersSaga);
}

export function* watchMidasStrategicDealerSupportFiltersSaga() {
  yield takeEvery(FETCH_MIDAS_STRATEGIC_CE_FILTERS, fetchMidasStrategicCEFiltersSaga);
}

export function* watchMidasFixedFIltersSaga() {
  yield takeEvery(FETCH_MIDAS_FIXED_FILTERS, fetchMidasFixedFiltersSaga);
}

export function* watchMidasSpendVsTrafficFiltersSaga() {
  yield takeEvery(FETCH_MIDAS_SPEND_VS_TRAFFIC_FILTERS, fetchMidasSpendVsTrafficFiltersSaga);
}

export function* watchFetchProductPerformanceFiltersSaga() {
  yield takeEvery(FETCH_PRODUCT_PERFORMANCE_FILTERS, fetchProductPerformanceFiltersSaga);
}

export function* watchUaeActivationFiltersSaga() {
  yield takeEvery(FETCH_UAE_ACTIVATION_FILTERS, fetchUaeFiltersSaga);
}

export function* watchVocFiltersSaga() {
  yield takeEvery(FETCH_VOC_FILTERS, fetchVocFiltersSaga);
}

export function* watchCpoOperationalFiltersSaga() {
  yield takeEvery(FETCH_CPO_OPERATIONAL_FILTERS, fetchCpoOperationalFiltersSaga);
}

export function* watchEcommerceFiltersSaga() {
  yield takeEvery(FETCH_ECOMMERCE_FILTERS, fetchEcommerceFiltersSaga);
}

export function* watchMsrpFiltersSaga() {
  yield takeEvery(FETCH_MSRP_FILTERS, fetchMsrpFiltersSaga);
}

export function* watchJourneyExcellenceFiltersSaga() {
  yield takeEvery(FETCH_JOURNEY_EXCELLENCE_FILTERS, fetchJourneyExcellenceFiltersSaga);
}

// Worker Sagas

export function* watchOCEModelsByBrandFiltersSaga() {
  yield takeEvery(FETCH_OCE_MODELS_BY_BRAND_FILTERS, fetchOCEModelsByBrandFiltersSaga);
}

export function* watchOCEMarketsByBrandFiltersSaga() {
  yield takeEvery(FETCH_OCE_MARKETS_BY_BRAND_FILTERS, fetchOCEMarketsByBrandFiltersSaga);
}

export function* watchOCEChannelsByBrandFiltersSaga() {
  yield takeEvery(FETCH_OCE_CHANNELS_BY_BRAND_FILTERS, fetchOCEChannelsByBrandFiltersSaga);
}

export function* watchOCEAllRegionsFiltersSaga() {
  yield takeEvery(FETCH_OCE_ALL_REGIONS, fetchOCEAllRegionsSaga);
}

export function* watchOCEAllMarketRegionsFiltersSaga() {
  yield takeEvery(FETCH_OCE_ALL_MARKET_REGIONS, fetchOCEAllMarketRegionsSaga);
}

export function* watchUseOwnershipFiltersSaga() {
  yield takeEvery(FETCH_USE_OWNERSHIP_FILTERS, fetchUseOwnershipFiltersSaga);
}

export function* watchFetchBrandPerformanceVolumeFiltersSaga() {
  yield takeEvery(FETCH_BRAND_PERFORMANCE_VOLUME_FILTERS, fetchBrandPerformanceVolumeFiltersSaga);
}

export function* watchFetchBrandPerformanceOaOFiltersSaga() {
  yield takeEvery(FETCH_BRAND_PERFORMANCE_OAO_FILTERS, fetchBrandPerformanceOaOFiltersSaga);
}

export function* watchFetchBrandPerformanceImageAttributeFiltersSaga() {
  yield takeEvery(FETCH_BRAND_PERFORMANCE_IMAGE_ATTRIBUTE_FILTERS, fetchBrandPerformanceImageAttributeFiltersSaga);
}

export function* watchFetchExecutiveSummaryFiltersSaga() {
  yield takeEvery(FETCH_EXECUTIVE_SUMMARY_FILTERS, fetchExecutiveSummaryFiltersSaga);
}

// Worker Sagas

export function* fetchGeoScope() {
  try {
    const data: FiltersData = yield retry(4, secondsDelay, getGeoScopeData);

    if (!("error" in data)) {
      yield put(setGeoScope(data));

      // Set brandList
      // const brandList: string[] = yield call(getBrandList, data);
      // yield put(setOptionsList(brandList, "brand"));
    }
  } catch (err) {
    console.log("Failed to get GeoScope Data");
  }
}

export function* fetchMtmModelsData() {
  try {
    const data: Array<string> = yield retry(4, secondsDelay, getMtmModelFilterData);

    if (!("error" in data)) {
      yield Array.isArray(data) ? put(setMtmModels(data)) : put(setMtmModels([]));
    }
  } catch (err) {
    console.log("Failed to fetch mtm model data");
  }
}

export function* fetchCCSFiltersData() {
  try {
    const data: ICockpitFiltersData = yield retry(4, secondsDelay, getCcsCockpitFilterData);

    if (!("error" in data)) {
      const { date_range } = data;
      const { month, year } = date_range[date_range?.length - 1];
      const lastDataRefresh = `${month} ${year}`;

      yield all([put(setCCSFiltersData(data)), put(setLastDataRefresh("ccs_cockpit", lastDataRefresh))]);
    }
  } catch (err) {
    console.log("Failed to get CCS Filters data");
  }
}

export function* fetchAppRatings_FiltersData() {
  try {
    const response: IAppRatingsFilters = yield retry(4, secondsDelay, getAppRatingsFilterData);

    if (!("error" in response)) {
      const { date_range } = response;
      const { month, year } = date_range[date_range?.length - 1];
      const lastDataRefresh = `${month} ${year}`;

      yield all([put(setAppRatingsFiltersData(response)), put(setLastDataRefresh("ccs_appreview", lastDataRefresh))]);
    }
  } catch (err) {
    console.log("Failed to get App Ratings filters data");
  }
}

export function* fetchEuropeActivationFilterDataSaga() {
  try {
    const data: IEuropeActivationFiltersData = yield retry(4, secondsDelay, getEuropeActivationFilterData);

    if (data && !("error" in data)) {
      yield put(setEuropeActivationFiltersData(data));
    }
  } catch (err) {
    console.log("Failed to get Europe Activation filters data", err);
  }
}

export function* fetchEuropeActivationMarketGroupsDataSaga() {
  try {
    const data: { market_groups: Array<any> } = yield retry(4, secondsDelay, getEuropeActivationMarketGroups);
    if (data && !("error" in data)) {
      yield put(setEuropeActivationMarketGroups(data.market_groups));
    }
  } catch (err) {
    console.log("Failed to get europe market groups", err);
  }
}

export function* fetchUserGroupMarketsSaga() {
  try {
    const data: Array<IUserGroupMarkets> = yield retry(4, secondsDelay, getUserGroupMarkets);
    if (!("error" in data) && data.length > 0) {
      yield put(setUserGroupMarkets(data));
    }
  } catch (err) {
    console.log("Failed to fetch user group markets", err);
  }
}

export function* fetchMtmMarketsSaga() {
  try {
    const data: IMtmMarkets = yield retry(4, secondsDelay, getMtmMarketsData);
    const allMarkets = Array.from(
      new Set([
        ...Object.entries(data)
          .flatMap((entry) => [entry[0], ...entry[1]])
          .sort(),
      ])
    );
    const geographyFilterFormatted = allMarkets.map((market) => ({ region: market, has_sub_region: false, markets: [] }));

    if (!("error" in data)) {
      yield put(setMTMMarketsData(geographyFilterFormatted));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* fetchSeoGeographySaga() {
  try {
    const data: ISeoFilters = yield retry(4, secondsDelay, getSeoFilters);
    if (!("error" in data) && data?.geography.length > 0) {
      yield put(setSeoGeography(data?.geography));
    }
  } catch (err) {
    console.log("Failed to get seo geography filters", err);
  }
}

export function* fetchPurchaseFunnelFiltersSaga() {
  try {
    // @ts-ignore
    const response: any = yield retry(4, secondsDelay, getDisFilters);
    if (!("error" in response)) {
      yield put(setNewPurchaseFunnelFilters(response));
    }
  } catch (err) {
    console.log("Failed to get purchase funnel filters", err);
  }
}

export function* fetchTopFlopFiltersSaga() {
  try {
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getTopFlopFilters);
    if (response && !("error" in response)) {
      yield put(setTopFlopFilters(response));
    }
  } catch (err) {
    console.log("Failed to get top flop filters", err);
  }
}

export function* fetchProductMarketingOptimizationFiltersSaga({ payload: brand }: any) {
  try {
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getProductMarketingOptimizationFilters, brand);
    if (!("error" in response)) {
      yield put(setProductMarketingOptimizationFilters(response));
    } else {
      yield all([put(setPmoResponseError(response))]);
    }
  } catch (err) {
    console.log("Failed to get product marketing optimization filters", err);
  }
}

function onlyUnique(value: any, index: any, self: string | any[]) {
  return self.indexOf(value) === index;
}

export function* fetchMidasFiltersSaga() {
  try {
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMidasFilters, searchParams);
    if (!("error" in response)) {
      yield put(setMidasFilters(response));
    }
  } catch (err) {
    console.log("Failed to get /midas/fmi-run-rate/filters");
  }
}

export function* fetchMidasFmiByModelFiltersSaga() {
  try {
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMidasFmiByModelFilters, searchParams);
    if (!("error" in response)) {
      yield put(setMidasFilters(response));
    }
  } catch (err) {
    console.log("Failed to get /midas/fmi-by-model/filters");
  }
}

export function* fetchMidasMediaMixFiltersSaga() {
  try {
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMidasMediaMixFilters, searchParams);
    if (!("error" in response)) {
      yield put(setMidasFilters(response));
    }
  } catch (err) {
    console.log("Failed to get /midas/media-mix/filters");
  }
}

export function* fetchMidasMtpCategoryFiltersSaga() {
  try {
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMidasMtpCategoryFilters, searchParams);
    if (!("error" in response)) {
      yield put(setMidasFilters(response));
    }
  } catch (err) {
    console.log("Failed to get /midas/mtp-category/filters");
  }
}

export function* fetchMidasStrategicDealerSupportFiltersSaga() {
  try {
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMidasStrategicDealerSupportFilters, searchParams);
    if (!("error" in response)) {
      yield put(setMidasFilters(response));
    }
  } catch (err) {
    console.log("Failed to get /midas/strategic-dealer-support/filters");
  }
}

export function* fetchMidasStrategicCEFiltersSaga() {
  try {
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMidasStrategicCEFilters, searchParams);
    if (!("error" in response)) {
      yield put(setMidasFilters(response));
    }
  } catch (err) {
    console.log("Failed to get /midas/strategic-ce/filters");
  }
}

export function* fetchMidasFixedFiltersSaga() {
  try {
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMidasFixedFilters, searchParams);
    if (!("error" in response)) {
      yield put(setMidasFilters(response));
    }
  } catch (err) {
    console.log("Failed to get /midas/fixed/filters");
  }
}

export function* fetchMidasSpendVsTrafficFiltersSaga() {
  try {
    const searchParams = window.location.search;
    //@ts-ignore
    const response: any = yield retry(4, secondsDelay, getMidasSpendVsTrafficFilters, searchParams);
    if (!("error" in response)) {
      yield put(setMidasFilters(response));
    } else {
      yield all([put(setMidasSpendVsTrafficResponseError(response))]);
    }
  } catch (err) {
    console.log("Failed to get /midas/spend-vs-traffic/filters");
  }
}

export function* fetchProductPerformanceFiltersSaga() {
  try {
    const response: Record<any, any> = yield retry(4, secondsDelay, getProductPerformanceFilters);
    if (!("error" in response)) {
      yield put(setProductPerformanceFilters(response));
    }
  } catch (err) {
    console.log("Failed to get /product-performance/filters");
  }
}

export function* fetchUaeFiltersSaga() {
  try {
    const response: Record<any, any> = yield retry(4, secondsDelay, getUaeFilters);
    if (!("error" in response)) {
      yield put(setUaeActivationFilters(response));
    }
  } catch (err) {
    console.log("Failed to get /ccs-activation-ratio/get-uae-filters");
  }
}

export function* fetchVocFiltersSaga() {
  try {
    const response: Record<string, any> = yield retry(4, secondsDelay, getVocFilters);
    if (!("error" in response)) {
      yield put(setVocFilters(response));
    }
  } catch (err) {
    console.log("Failed to get voc filters");
  }
}

export function* fetchCpoOperationalFiltersSaga() {
  try {
    const searchParams = window.location.search;

    const response: Record<string, any> = yield retry(4, secondsDelay, getCpoOperationalFilters, searchParams);
    if (!("error" in response)) {
      yield put(setCpoOperationalFilters(response));
    }
  } catch (err) {
    console.log("Failed to get used cars filters");
  }
}

export function* fetchEcommerceFiltersSaga() {
  try {
    const response: Record<string, any> = yield retry(4, secondsDelay, getEcommerceFilters);
    if (!("error" in response)) {
      yield put(setEcommerceFilters(response));
    }
  } catch (err) {
    console.log("Failed to get ecommerce filters");
  }
}

export function* fetchMsrpFiltersSaga() {
  try {
    const response: Record<string, any> = yield retry(4, secondsDelay, getMsrpFilters);
    if (!("error" in response)) {
      yield put(setMsrpFilters(response));
    }
  } catch (err) {
    console.log("Failed to get msrp filters");
  }
}

export function* fetchJourneyExcellenceFiltersSaga({ payload: metric }: AnyAction) {
  try {
    const response: Record<string, any> = yield retry(4, secondsDelay, getJourneyExcellenceFilters, metric);
    if (!("error" in response)) {
      yield put(setJourneyExcellenceFilters(response));
    }
  } catch (err) {
    console.log("Failed to get journey excellence filters");
  }
}

export function* fetchUseOwnershipFiltersSaga({ payload: page }: AnyAction) {
  try {
    const response: Record<string, any> = yield retry(4, secondsDelay, getUseOwnershipFilters, page);
    if (!("error" in response)) {
      yield put(setUseOwnershipFilters(response));
    }
  } catch (err) {
    console.log("Failed to get use ownership filters");
  }
}

export function* fetchOCEModelsByBrandFiltersSaga() {
  try {
    const response: Record<string, any> = yield retry(4, secondsDelay, getOCEModelsByBrandFilters);
    if (!("error" in response)) {
      yield put(setOCEModelsByBrandFilters(response));
    }
  } catch (err) {
    console.log("Failed to get oce models by brand filters");
  }
}

export function* fetchOCEMarketsByBrandFiltersSaga() {
  try {
    const response: Record<string, any> = yield retry(4, secondsDelay, getOCEMarketsByBrandFilters);
    if (!("error" in response)) {
      yield put(setOCEMarketsByBrandFilters(response));
    }
  } catch (err) {
    console.log("Failed to get oce markets by brand filters");
  }
}

export function* fetchOCEChannelsByBrandFiltersSaga() {
  try {
    const response: Record<string, any> = yield retry(4, secondsDelay, getOCEChannelsByBrandFilters);
    if (!("error" in response)) {
      yield put(setOCEChannelsByBrandFilters(response));
    }
  } catch (err) {
    console.log("Failed to get oce channels by brand filters");
  }
}

export function* fetchOCEAllMarketRegionsSaga() {
  try {
    const response: Record<string, any> = yield retry(4, secondsDelay, getOCEAllMarketRegions);
    if (!("error" in response)) {
      yield put(setOCEAllMarketRegions(response));
    }
  } catch (err) {
    console.log("Failed to get oce all market regions");
  }
}

export function* fetchOCEAllRegionsSaga() {
  try {
    const response: Record<string, any> = yield retry(4, secondsDelay, getOCEAllRegionsFilters);
    if (!("error" in response)) {
      yield put(setOCEAllRegions(response));
    }
  } catch (err) {
    console.log("Failed to get oce all regions");
  }
}

export function* fetchBrandPerformanceVolumeFiltersSaga() {
  try {
    const searchParams = window.location.search;
    const response: Record<string, any> = yield retry(4, secondsDelay, getBrandPerformanceVolumeFilters, searchParams);
    if (!("error" in response)) {
      yield put(setBrandPerformanceVolumeFilters(response));
    }
  } catch (err) {
    console.log("Failed to get brand performance volume filters");
  }
}

export function* fetchBrandPerformanceOaOFiltersSaga() {
  try {
    const searchParams = window.location.search;
    const response: Record<string, any> = yield retry(4, secondsDelay, getBrandPerformanceOaOFilters, searchParams);
    if (!("error" in response)) {
      yield put(setBrandPerformanceOaOFilters(response));
    }
  } catch (err) {
    console.log("Failed to get brand performance oao filters");
  }
}

export function* fetchBrandPerformanceImageAttributeFiltersSaga() {
  try {
    const searchParams = window.location.search;
    const response: Record<string, any> = yield retry(4, secondsDelay, getBrandPerformanceImageAttributesFilters, searchParams);
    if (!("error" in response)) {
      yield put(setBrandPerformanceImageAttributeFilters(response));
    }
  } catch (err) {
    console.log("Failed to get brand performance image attribute filters");
  }
}

export function* fetchExecutiveSummaryFiltersSaga() {
  try {
    const response: Record<string, any> = yield retry(4, secondsDelay, getExecutiveSummaryDisFilters);
    if (!("error" in response)) {
      yield put(setExecutiveSummaryFilters(response));
    }
  } catch (err) {
    console.log("Failed to get EXECUTIVE_SUMMARY filters");
  }
}
