import * as React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { WEBSITE_VISITS_COLORS } from "../../../constants";
import { InvByAge, InvByListedParagrahphs } from "../helpers/tooltiptexts";
import InventoryByGraphs from "./InventoryByGraphs";
import PerformancePerUnit from "./PerformancePerUnit";

const InventoryGraphs = () => {
  const data = useSelector((state: RootStateOrAny) => state.cpo_operational.inventory_by_age_data);
  const loading = useSelector((state: RootStateOrAny) => state.loading.inventory_by_age);

  const ageByListedData = React.useMemo(() => {
    return data?.age_by_listed?.map((val: { age_category: { toString: () => any } }) => ({
      ...val,
      age_category: `${val?.age_category} days`,
    }));
  }, [data]);

  const ageByVehicleData = React.useMemo(() => {
    return data?.age_by_vehicle?.map((val: { year: { toString: () => any } }) => ({ ...val, year: val?.year?.toString() }));
  }, [data]);

  return (
    <div className="inventory_graphs ">
      {/* <ListPriceGraph /> */}
      <InventoryByGraphs
        toolTipParagraph={<InvByListedParagrahphs />}
        dataKey="age_category"
        title="Inventory by age from listed day"
        data={ageByListedData}
        loading={loading}
        colors={WEBSITE_VISITS_COLORS}
      />
      <InventoryByGraphs
        toolTipParagraph={<InvByAge />}
        dataKey="year"
        title="Inventory by vehicle age"
        data={ageByVehicleData}
        loading={loading}
      />
      <PerformancePerUnit />
    </div>
  );
};

export default InventoryGraphs;
