import {
  Box,
  Checkbox,
  Icon,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  withStyles,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import Launch from "@material-ui/icons/Launch";
import * as Sentry from "@sentry/react";
import { format, parseISO } from "date-fns";
import React, { Dispatch, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setParameters } from "../../../../actions";
import DeleteIcon from "../../../../assets/images/trash_hover.svg";
import { ErrorMsg } from "../../../../components/AppMessages";
import { CYAN, DASHBOARD_URLS, WHITE } from "../../../../constants";
import { Brand, Channel, Market, Metric, Model, Order, Region, Tag } from "../../../../constants/interface/admin/common";
import { convertParamsToQueryParams } from "../../../../utils/annotationFunctions";
import { getComparator, stableSort } from "../../utils";

interface Props {
  annotations: any[];
  selectedAnnotation: any;
  setSelectedAnnotation: Dispatch<SetStateAction<any>>;
  setDeleteDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const StyledTableRow = withStyles(() => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(225, 225, 225, 0.15)",
    },
    "&:hover": {
      backgroundColor: CYAN,
      "& .MuiTableCell-root img": {
        filter: "invert(0%) sepia(93%) saturate(29%) hue-rotate(53deg) brightness(101%) contrast(106%)",
      },
      "& .MuiTableCell-root #launch-btn": {
        fill: WHITE,
      },
    },
  },
}))(TableRow);

const WhiteBackgroundCheckbox = withStyles(() => ({
  root: {
    color: "black",
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
      color: "#5ed2ff",
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      backgroundColor: "#5ed2ff",
      zIndex: -1,
    },
    "& .MuiIconButton-colorSecondary": {
      color: "#5ed2ff !important",
    },
  },
  checked: {},
}))(Checkbox);

const AnnotationsTable = (props: Props) => {
  const { annotations, selectedAnnotation, setSelectedAnnotation, setDeleteDialogOpen } = props;
  const [rowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("id");

  const dispatch = useDispatch();

  const history = useHistory();

  const handleRequestSort = (event: any, property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property: any) => (event: any) => {
    handleRequestSort(event, property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const headCells = [
    { id: "owner", numeric: false, disablePadding: false, label: "Owner", sortable: true },
    { id: "created_at", numeric: false, disablePadding: false, label: "Creation Date", sortable: true },
    { id: "approval_status", numeric: false, disablePadding: false, label: "Status", sortable: true },
    { id: "dashboard", numeric: false, disablePadding: false, label: "Dashboard", sortable: true },
    { id: "start_date", numeric: false, disablePadding: false, label: "Date", sortable: true },
    { id: "brands", numeric: false, disablePadding: false, label: "Brands", sortable: true },
    { id: "regions", numeric: false, disablePadding: false, label: "Regions", sortable: true },
    { id: "markets", numeric: false, disablePadding: false, label: "Markets", sortable: true },
    { id: "models", numeric: false, disablePadding: false, label: "Models", sortable: true },
    { id: "channels", numeric: false, disablePadding: false, label: "Channels", sortable: true },
    { id: "metrics", numeric: false, disablePadding: false, label: "Metrics", sortable: true },
    { id: "tags", numeric: false, disablePadding: false, label: "Tags", sortable: true },
    { id: "scope", numeric: false, disablePadding: false, label: "Scope", sortable: true },
    { id: "insight", numeric: false, disablePadding: false, label: "Insight", sortable: true },
  ];

  const classes = makeStyles({
    noBorder: {
      border: "none",
    },
    tableCell: {
      color: "#FFF",
      border: "none",
      padding: "10px !important",
    },
    tableHeadCell: {
      color: "#FFF",
      border: "none",
      fontWeight: 600,
      padding: "10px !important",
    },
    orderIcon: {
      color: "#FFF !important",
      width: "2.5rem",
      height: "2.5rem",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    deleteIcon: {
      width: "1.5rem",
      filter: "invert(52%) sepia(96%) saturate(2947%) hue-rotate(165deg) brightness(93%) contrast(102%)",
      "&:hover": {
        filter: "invert(70%) sepia(45%) saturate(2008%) hue-rotate(346deg) brightness(104%) contrast(97%) !important",
      },
    },
    tablePagination: {
      color: WHITE,
      width: "auto",
      "& .MuiTablePagination-spacer": {
        flex: "none",
      },
    },
    launchIcon: {
      fill: CYAN,
    },
  })();

  return (
    <Sentry.ErrorBoundary fallback={<ErrorMsg />}>
      <Box>
        <TableContainer component={Box}>
          <Table className={`${classes.noBorder}`}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} style={{ width: "50px" }}></TableCell>
                {headCells.map((headCell, index) => (
                  <TableCell
                    key={index}
                    className={classes.tableHeadCell}
                    align={headCell.numeric ? "right" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.sortable ? (
                      <TableSortLabel
                        style={{ color: WHITE, fontWeight: 600 }}
                        classes={{
                          icon: classes.orderIcon,
                        }}
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                        IconComponent={ArrowDropDownIcon}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <span className={classes.visuallyHidden}>{order === "desc" ? "sorted descending" : "sorted ascending"}</span>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      headCell.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(annotations, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((annotation: any, index) => (
                  <StyledTableRow key={index}>
                    <TableCell className={`${classes.tableCell}`}>
                      <WhiteBackgroundCheckbox
                        checked={selectedAnnotation?.id == annotation.id}
                        onChange={(e) => {
                          e.target.checked ? setSelectedAnnotation(annotation) : setSelectedAnnotation(undefined);
                        }}
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                      />
                    </TableCell>
                    <TableCell className={`${classes.tableCell}`}>{annotation.user.email}</TableCell>
                    <TableCell className={`${classes.tableCell}`}>{format(parseISO(annotation.created_at), "y-LL-dd")}</TableCell>
                    <TableCell className={`${classes.tableCell}`}>
                      {annotation.approval_status == "unapproved" ? (
                        <Tooltip title="Not approved">
                          <Close style={{ fill: "red" }} />
                        </Tooltip>
                      ) : (
                        <Tooltip title={annotation.approval_status == "approved" ? "Approved" : "Pending"}>
                          <Check style={{ fill: annotation.approval_status == "approved" ? "green" : "grey" }} />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell className={`${classes.tableCell}`}>{annotation.dashboard}</TableCell>
                    <TableCell className={`${classes.tableCell}`}>
                      {annotation.start_date} - {annotation.end_date}
                    </TableCell>
                    <TableCell className={`${classes.tableCell}`}>
                      {Array.isArray(annotation.brands) && annotation.brands.map((brand: Brand) => brand.brand).join(", ")}
                    </TableCell>
                    <TableCell className={`${classes.tableCell}`}>
                      {Array.isArray(annotation.regions) && annotation.regions.map((region: Region) => region.region).join(", ")}
                    </TableCell>
                    <TableCell className={`${classes.tableCell}`}>
                      {Array.isArray(annotation.markets) && annotation.markets.map((market: Market) => market.market).join(", ")}
                    </TableCell>
                    <TableCell className={`${classes.tableCell}`}>
                      {Array.isArray(annotation.models) && annotation.models.map((model: Model) => model.model).join(", ")}
                    </TableCell>
                    <TableCell className={`${classes.tableCell}`}>
                      {Array.isArray(annotation.channels) && annotation.channels.map((channel: Channel) => channel.channel).join(", ")}
                    </TableCell>
                    <TableCell className={`${classes.tableCell}`}>
                      {Array.isArray(annotation.metrics) && annotation.metrics.map((metric: Metric) => metric.metric).join(", ")}
                    </TableCell>
                    <TableCell className={`${classes.tableCell}`}>
                      {Array.isArray(annotation.tags) && annotation.tags.map((tag: Tag) => tag.tag).join(", ")}
                    </TableCell>
                    <TableCell className={`${classes.tableCell}`}>
                      {annotation.applies_to == "filters_only" ? "these tags only" : "these tags and levels above"}
                    </TableCell>
                    <TableCell className={`${classes.tableCell}`}>{annotation.insight}</TableCell>
                    <TableCell className={`${classes.tableCell}`}>
                      <Icon
                        id="launch-btn"
                        className={classes.launchIcon}
                        component={Launch}
                        onClick={() => {
                          const params = convertParamsToQueryParams(annotation);
                          const url = DASHBOARD_URLS[annotation.dashboard];
                          dispatch(setParameters(params));
                          history.push(url);
                        }}
                      />
                    </TableCell>
                    <TableCell className={`${classes.tableCell}`} align="right">
                      <img
                        src={DeleteIcon}
                        alt="Delete Icon"
                        className={classes.deleteIcon}
                        onClick={() => {
                          setSelectedAnnotation(annotation);
                          setDeleteDialogOpen(true);
                        }}
                      />
                    </TableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          className={classes.tablePagination}
          rowsPerPageOptions={[]}
          component="div"
          count={annotations.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          backIconButtonProps={{
            style: {
              color: WHITE,
            },
          }}
          nextIconButtonProps={{
            style: {
              color: WHITE,
            },
          }}
          labelDisplayedRows={({ from, to, count }) => `Showing ${from} to ${to} of ${count} entries`}
        />
      </Box>
    </Sentry.ErrorBoundary>
  );
};

export default AnnotationsTable;
