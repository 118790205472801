import { Button } from "@material-ui/core";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setSpecificParameter } from "../../../actions";
import { ALL_OPTION_NO_SPACE } from "../../../constants";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { Breadcrumb } from "../../Breadcrumb/Breadcrumb";
import { closeFilterOptions } from "../subs/helpers";

type checkboxState = { value: string; checked: boolean };

type listTypes = "model" | "year" | "price" | "mileage" | "certified";

interface Props {
  onShowFilterOptions: any;
}

export const UsedCarsModelFilter = (props: Props) => {
  const { onShowFilterOptions } = props;

  const dispatch = useDispatch();

  const filters = useSelector((state: RootStateOrAny) => state.filters.cpo_operational_filters);
  const modelParam = useSelector((state: RootStateOrAny) => state.parameters.model);
  const yearParam = useSelector((state: RootStateOrAny) => state.parameters.year);
  const mileageParam = useSelector((state: RootStateOrAny) => state.parameters.mileage);
  const priceParam = useSelector((state: RootStateOrAny) => state.parameters.price);
  const certifiedParam = useSelector((state: RootStateOrAny) => state.parameters.certified);

  const [activeListState, setActiveList] = useState<listTypes>("model");
  const [selectionValue, setSelectionValue] = useState<string>("");

  const [availableModels, setAvailableModels] = useState<
    {
      model: string;
      year: string[];
      mileage: string[];
      price: string[];
      certified: string[];
    }[]
  >([]);

  const [certified, setCertified] = useState<Array<checkboxState>>([]);
  const [models, setModels] = useState<Array<checkboxState>>([]);
  const [years, setYears] = useState<Array<checkboxState>>([]);
  const [prices, setPrices] = useState<Array<checkboxState>>([]);
  const [milages, setMilages] = useState<Array<checkboxState>>([]);

  const mileageSortOrder = ["<50000", "50000-100000", ">100000"];
  const priceSortOrder = ["<5000", "5000-10000", "10000 - 25000", "> 25000"];

  const getCheckedSubList = (
    modelData: {
      model: string;
      year: string[];
      mileage: string[];
      price: string[];
      certified: string[];
    }[],
    key: listTypes
  ) => {
    const list: string[] = [];
    const models = /all/gi.test(modelParam) ? [] : modelParam?.split(",");
    modelData?.map((val) => {
      if (key !== "model") {
        list.push(...val[`${key}`]);
      } else {
        list.push(val?.model);
      }
    });
    const sets = Array.from(new Set(list));
    const uniqueItems =
      key == "year"
        ? sets?.sort((a, b) => Number(a) - Number(b))
        : key == "price"
        ? sets?.sort((a, b) => priceSortOrder?.indexOf(a) - priceSortOrder?.indexOf(b))
        : key == "mileage"
        ? sets?.sort((a, b) => mileageSortOrder?.indexOf(a) - mileageSortOrder?.indexOf(b))
        : sets;

    return key == "model"
      ? [
          { value: ALL_OPTION_NO_SPACE, checked: models?.length == 0 },
          ...uniqueItems.map((val) => ({ value: val, checked: models?.length == 0 ? true : models?.includes(val) })),
        ]
      : [{ value: ALL_OPTION_NO_SPACE, checked: false }, ...uniqueItems.map((val) => ({ value: val?.toString(), checked: false }))];
  };

  useEffect(() => {
    setAvailableModels(filters?.model);
  }, [filters]);

  useEffect(() => {
    const models = availableModels?.map((val) => val?.model);
    const paramModels = /all/gi.test(modelParam) ? [] : modelParam?.split(",");
    const isAvailable = paramModels?.every((val: string) => models?.includes(val));
    if (!isAvailable) dispatch(setSpecificParameter("model", ALL_OPTION_NO_SPACE));
  }, [availableModels, modelParam, dispatch]);
  //update Models
  useEffect(() => {
    setModels(getCheckedSubList(availableModels, "model"));
  }, [availableModels, modelParam]);

  //update years
  useEffect(() => {
    const checkedModels = models?.filter((val) => val.checked)?.map((val) => val.value);
    const modelObjects = availableModels?.filter((val) => checkedModels?.includes(val?.model));
    if (yearParam && yearParam == ALL_OPTION_NO_SPACE) {
      setYears(getCheckedSubList(modelObjects, "year").map((year) => ({ ...year, checked: true })));
    } else if (yearParam) {
      setYears(getCheckedSubList(modelObjects, "year").map((year) => ({ ...year, checked: yearParam.includes(year.value) })));
    }
  }, [models, yearParam]);

  //update mileage
  useEffect(() => {
    const checkedModels = models?.filter((val) => val.checked)?.map((val) => val.value);
    const checkedYears = years
      ?.filter((val) => val.checked)
      ?.map((val) => val.value)
      ?.sort((a, b) => Number(a) - Number(b))
      ?.toString();
    const modelObjects = availableModels?.filter(
      (val) => checkedModels?.includes(val?.model) && checkedYears?.includes(val?.year?.sort((a, b) => Number(a) - Number(b))?.toString())
    );
    if (mileageParam && mileageParam == ALL_OPTION_NO_SPACE) {
      setMilages(getCheckedSubList(modelObjects, "mileage").map((mileage) => ({ ...mileage, checked: true })));
    } else if (mileageParam) {
      setMilages(
        getCheckedSubList(modelObjects, "mileage").map((mileage) => ({ ...mileage, checked: mileageParam.includes(mileage.value) }))
      );
    }
  }, [models, years, mileageParam]);

  //update prices
  useEffect(() => {
    const checkedModels = models?.filter((val) => val.checked)?.map((val) => val.value);
    const checkedYears = years
      ?.filter((val) => val.checked)
      ?.map((val) => val.value)
      ?.sort((a, b) => Number(a) - Number(b))
      ?.toString();
    const modelObjects = availableModels?.filter(
      (val) => checkedModels?.includes(val?.model) && checkedYears?.includes(val?.year?.sort((a, b) => Number(a) - Number(b))?.toString())
    );
    if (priceParam && priceParam == ALL_OPTION_NO_SPACE) {
      setPrices(getCheckedSubList(modelObjects, "price").map((price) => ({ ...price, checked: true })));
    } else if (priceParam) {
      setPrices(getCheckedSubList(modelObjects, "price").map((price) => ({ ...price, checked: priceParam.includes(price.value) })));
    }
  }, [models, years, priceParam]);

  //update certified
  useEffect(() => {
    const checkedModels = models?.filter((val) => val.checked)?.map((val) => val.value);
    const checkedYears = years
      ?.filter((val) => val.checked)
      ?.map((val) => val.value)
      ?.sort((a, b) => Number(a) - Number(b))
      ?.toString();
    const modelObjects = availableModels?.filter(
      (val) => checkedModels?.includes(val?.model) && checkedYears?.includes(val?.year?.sort((a, b) => Number(a) - Number(b))?.toString())
    );
    if (certifiedParam && certifiedParam == ALL_OPTION_NO_SPACE) {
      setCertified(getCheckedSubList(modelObjects, "certified").map((certified) => ({ ...certified, checked: true })));
    } else if (certifiedParam) {
      setCertified(
        getCheckedSubList(modelObjects, "certified").map((certified) => ({
          ...certified,
          checked: certifiedParam.includes(certified.value),
        }))
      );
    }
  }, [models, years]);

  const showAllOptions = useCallback(() => {
    setModels((prevRegion) => prevRegion.map((region) => ({ ...region, checked: true })));
    setYears((prevRegion) => prevRegion.map((region) => ({ ...region, checked: true })));
    setMilages((prevRegion) => prevRegion.map((region) => ({ ...region, checked: true })));
    setPrices((prevRegion) => prevRegion.map((region) => ({ ...region, checked: true })));
    setCertified((prevItem) => prevItem?.map((item) => ({ ...item, checked: true })));
  }, []);

  const breadcrumbSelection = useCallback(
    () => (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;

      const breadcrumbValue = dataset?.value;
      setActiveList(breadcrumbValue as listTypes);
    },
    [setActiveList]
  );

  const resetSelection = useCallback(
    (listType?: listTypes) => {
      const updatedModels = models.map((model) => ({ ...model, checked: false }));
      const updatedYears = years.map((year) => ({ ...year, checked: false }));
      const updatedMileages = milages.map((mileage) => ({ ...mileage, checked: false }));
      const updatedPrices = prices.map((price) => ({ ...price, checked: false }));
      const updatedCertifiedList = certified.map((certificate) => ({ ...certificate, checked: false }));

      if (!listType) {
        setPrices(updatedPrices);
        setMilages(updatedMileages);
        setYears(updatedYears);
        setModels(updatedModels);
        setCertified(updatedCertifiedList);
      } else {
        listType === "model"
          ? setModels(updatedModels)
          : listType === "year"
          ? setYears(updatedYears)
          : listType === "mileage"
          ? setMilages(updatedMileages)
          : listType == "price"
          ? setPrices(updatedPrices)
          : setCertified(updatedCertifiedList);
      }
    },
    [models, prices, milages, years, certified]
  );

  const handleCheckboxClick = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value: checkboxValue, checked: isChecked, dataset },
      } = evt;

      // If All is deselected, clear all selections
      if (checkboxValue === ALL_OPTION_NO_SPACE) {
        if (!isChecked) {
          resetSelection(dataset?.listType as listTypes);
        } else {
          showAllOptions();
        }
        return;
      }

      const listType = dataset?.listType as listTypes;
      const index = Number(dataset.key);
      const newArr: checkboxState[] =
        listType === "model"
          ? [...models]
          : listType === "year"
          ? [...years]
          : listType === "mileage"
          ? [...milages]
          : listType == "price"
          ? [...prices]
          : [...certified]; // copying the old datas array
      newArr[index] = { value: checkboxValue, checked: isChecked };
      const listWithoutAll: checkboxState[] = newArr.filter((val) => val.value !== ALL_OPTION_NO_SPACE);

      const isAllElementsChecked: boolean = listWithoutAll.every((val) => val.checked);
      const allElement: checkboxState = { value: ALL_OPTION_NO_SPACE, checked: isAllElementsChecked };

      const updatedList: checkboxState[] = [allElement, ...listWithoutAll];

      listType === "model"
        ? setModels(updatedList)
        : listType === "year"
        ? setYears(updatedList)
        : listType === "mileage"
        ? setMilages(updatedList)
        : listType === "price"
        ? setPrices(updatedList)
        : setCertified(updatedList);
    },
    [models, prices, years, milages, certified]
  );

  const handleOnlyBtnClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;

      const list = dataset.listtype as listTypes;

      const index = Number(dataset.key);
      const tempArray =
        list === "model"
          ? [...models]
          : list === "year"
          ? [...years]
          : list === "mileage"
          ? [...milages]
          : list === "price"
          ? [...prices]
          : [...certified];
      const updatedArray = tempArray.map((val) => ({ ...val, checked: false }));
      updatedArray[index] = { value: dataset.value!, checked: true };

      list === "model"
        ? setModels(updatedArray)
        : list === "year"
        ? setYears(updatedArray)
        : list === "mileage"
        ? setMilages(updatedArray)
        : list === "price"
        ? setPrices(updatedArray)
        : setCertified(updatedArray);
    },
    [models, milages, prices, certified, years]
  );

  const handleSubmission = useCallback(
    (evt) => {
      evt.preventDefault();

      if (selectionValue === "") return;
      const selectedModels = models?.filter((model) => model.checked)?.map((model) => model.value);
      const selectedYears = years?.filter((year) => year.checked)?.map((year) => year.value);
      const selectedMileages = milages?.filter((mileage) => mileage.checked).map((mileage) => mileage.value);
      const selectedPrices = prices?.filter((price) => price.checked).map((price) => price.value);
      const selectedCertified = certified?.filter((price) => price.checked).map((certifiedItem) => certifiedItem.value);

      const allModelsSelected = availableModels?.length + 1 === selectedModels?.length;
      const allYearsSelected = years?.length + 1 === selectedYears?.length;
      const allMileagesSelected = milages?.length + 1 === selectedMileages?.length;
      const allPricesSelected = prices?.length + 1 === selectedPrices?.length;

      dispatch(setSpecificParameter("model", allModelsSelected ? ALL_OPTION_NO_SPACE : selectedModels?.toString()));
      dispatch(
        setSpecificParameter("year", allYearsSelected || selectedYears?.length === 0 ? ALL_OPTION_NO_SPACE : selectedYears?.toString())
      );
      dispatch(
        setSpecificParameter(
          "mileage",
          allMileagesSelected || selectedMileages?.length === 0 ? ALL_OPTION_NO_SPACE : selectedMileages?.toString()
        )
      );
      dispatch(
        setSpecificParameter("price", allPricesSelected || selectedPrices?.length === 0 ? ALL_OPTION_NO_SPACE : selectedPrices?.toString())
      );

      dispatch(
        setSpecificParameter(
          "certified",
          selectedCertified?.length === 0 || selectedCertified?.length === 2 ? ALL_OPTION_NO_SPACE : selectedCertified?.toString()
        )
      );

      eventTracking(MixpanelEvents.filter_change, { filter: "model", value: selectedModels, dashboard: "Used Cars", page: "Used Cars" });

      closeFilterOptions();
    },
    [models, prices, milages, years, selectionValue, certified]
  );

  useEffect(() => {
    const numberOfCheckedModels = models?.filter((val) => val.checked).length;
    if (availableModels?.length + 1 === numberOfCheckedModels) {
      setSelectionValue("All models selected");
    } else if (numberOfCheckedModels > 0) {
      setSelectionValue(`${numberOfCheckedModels} models selected`);
    }
  }, [models]);

  const renderList = (list: Array<checkboxState>, listType: listTypes) =>
    list.map(({ value, checked }, index) => (
      <div className={"multiselect_container geography_list"} key={value}>
        <input
          type="checkbox"
          className={`multiselect-checkbox ${listType}-checkbox geography_checkbox`}
          id={`${listType}_${value?.replace(" ", "_")}`}
          name={`${listType}_${value.replace(" ", "_")}`}
          value={value}
          data-list-type={listType}
          data-key={index}
          checked={checked}
          onChange={handleCheckboxClick}
        />
        <label className="multiselect-label geography_label" htmlFor={`${listType}_${value.replace(" ", "_")}`}>
          {value}
        </label>

        {!/all/gi.test(value) && (
          <Button
            onClick={handleOnlyBtnClick}
            className={"geographyOptionBtn"}
            size={"small"}
            variant={"outlined"}
            data-listtype={listType}
            data-value={value}
            data-key={index}
          >
            ONLY
          </Button>
        )}
      </div>
    ));

  return (
    <div className="filter" id="geography_filter_div">
      <label className="filter_header" htmlFor={"region_country_filter"}>
        MODEL
      </label>

      <div className="input_arrow" onClick={onShowFilterOptions} data-options-ul={"model_selector"}>
        <input readOnly type="text" id="region_country_filter" className={"filter_input"} data-test-id="region" value={selectionValue} />
        <span className="arrow_down " />
      </div>

      <div className="filter_options" id="model_selector" data-test-id="geography_selector">
        <div className="search_div">
          <div className="selection_label hide_on_mobile">
            <p className={"selection_value"}>{selectionValue}</p>
          </div>

          <div className="search_clear_div">
            <div className="clear_btn" onClick={() => resetSelection()}>
              {" "}
              Clear all{" "}
            </div>
          </div>
        </div>
        <div className="breadcrumb_container hide_on_desktop">
          <Breadcrumb
            list={["Model", "Year", "Mileage", "Price", "Certified"]}
            onBreadcrumbClick={breadcrumbSelection()}
            activeValue={activeListState}
          />
        </div>
        <div className="geography_options_title hide_on_mobile">
          {milages?.length > 1 ? (
            <div className="geography_type">
              <p className="title">Model</p>
            </div>
          ) : null}
          {years?.length > 1 ? (
            <div className="geography_type">
              <p className="title">Year</p>
            </div>
          ) : null}
          {milages?.length > 1 ? (
            <div className="geography_type">
              <p className="title">Mileage</p>
            </div>
          ) : null}

          {prices?.length > 1 ? (
            <div className="geography_type">
              <p className="title">Price</p>
            </div>
          ) : null}

          {certified?.length > 1 ? (
            <div className="geography_type">
              <p className="title">Certified</p>
            </div>
          ) : null}
        </div>
        <div className="geography_options">
          {models?.length ? (
            <>
              {/* Models */}
              <div className={`marketList ${activeListState === "model" ? "active" : ""}`} id="model_list" data-test-id={"model_list"}>
                {renderList(models, "model")}
              </div>
              {/* Years */}
              {years?.length > 1 ? (
                <div className={`marketList ${activeListState === "year" ? "active" : ""}`} id="year_list" data-test-id={"year_list"}>
                  {renderList(years, "year")}
                </div>
              ) : null}

              {/* Mileage */}
              {milages?.length > 1 ? (
                <div className={`marketList ${activeListState === "mileage" ? "active" : ""}`} id="year" data-test-id={"mileage_list"}>
                  {renderList(milages, "mileage")}
                </div>
              ) : null}
              {/* Price */}
              {prices?.length > 1 ? (
                <div className={`marketList ${activeListState === "price" ? "active" : ""}`} id="price_list" data-test-id={"price_list"}>
                  {renderList(prices, "price")}
                </div>
              ) : null}
              {/* Certified */}
              {certified?.length > 1 ? (
                <div
                  className={`marketList ${activeListState === "certified" ? "active" : ""}`}
                  id="certified_list"
                  data-test-id={"certified_list"}
                >
                  {renderList(certified, "certified")}
                </div>
              ) : null}
            </>
          ) : (
            <ul>
              <li className={"error_msg"} style={{ fontSize: "0.9rem" }}>
                No models available for the selected filters.
              </li>
            </ul>
          )}
        </div>
        {/* Apply btn */}
        <div className="geography_options__submit_area">
          <button className="filter_button no_icon" onClick={handleSubmission} data-test-id={"geography_apply_btn"}>
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};
