import { ErrorBoundary } from "@sentry/react";
import React, { Dispatch, SetStateAction, useContext } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ModelsTrendGraph } from ".";
import "../../../assets/styles/component/baselineTrend.scss";
import { ErrorMsg } from "../../../components/AppMessages";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import Ratings from "../../../components/Ratings/Ratings";
import { DocumentationContext } from "../../../context";
import { toLocaleInteger } from "../../../utils/utilityFunctions";

interface Props {
  dateTitleValue: string;
  dateTitleDescription: string;
  granularity: string;
  breakdownTitle: string;
  breakdownColumnTitle: string;
  metric: string;
  showPop: boolean;
  popLabel: string;
  isNameplateTooltipVisible: boolean;
  isCpoDashboard?: boolean;
  onHandleRadioClick: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  toggleAnnotationsDialog: () => void;
  setAnnotationStartDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationEndDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationDefaultMetric: Dispatch<SetStateAction<string>>;
  setAnnotationDefaultRegion: Dispatch<SetStateAction<string | undefined>>;
  setAnnotationDefaultMarket: Dispatch<SetStateAction<string | undefined>>;
  setAnnotationDefaultModel: Dispatch<SetStateAction<string | undefined>>;
}

export const ModelsTrend = (props: Props): JSX.Element => {
  const {
    dateTitleValue,
    dateTitleDescription,
    granularity,
    breakdownTitle,
    metric,
    breakdownColumnTitle,
    showPop,
    popLabel,
    isNameplateTooltipVisible,
    isCpoDashboard,
    onHandleRadioClick,
    toggleAnnotationsDialog,
    setAnnotationStartDate,
    setAnnotationEndDate,
    setAnnotationDefaultMetric,
    setAnnotationDefaultRegion,
    setAnnotationDefaultMarket,
    setAnnotationDefaultModel,
  } = props;

  const { setDocumentation, setIsVisible } = useContext(DocumentationContext);

  const visitsGraphIsLoading = useSelector((state: RootStateOrAny) => state.loading.visits_breakdown_graph);
  const visitsTableIsLoading = useSelector((state: RootStateOrAny) => state.loading.visits_breakdown_table);
  const cpoModelKeyIndicators = useSelector((state: RootStateOrAny) => state.cpo_digital_performance.model_key_indicators);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className="model_trend">
        <div id="model_trend_graph" data-test-id={"models_trend_graph"} className={"graphArea"}>
          <ModelsTrendGraph
            isCpoDashboard={isCpoDashboard}
            granularity={granularity}
            breakdownTitle={breakdownTitle}
            onHandleRadioClick={onHandleRadioClick}
            metric={metric}
            toggleAnnotationsDialog={toggleAnnotationsDialog}
            setAnnotationEndDate={setAnnotationEndDate}
            setAnnotationStartDate={setAnnotationStartDate}
            setAnnotationDefaultMetric={setAnnotationDefaultMetric}
            setAnnotationDefaultRegion={setAnnotationDefaultRegion}
            setAnnotationDefaultMarket={setAnnotationDefaultMarket}
            setAnnotationDefaultModel={setAnnotationDefaultModel}
          />
        </div>

        <div id="model_trend_table" data-test-id={"models_trend_table"} className={"content"}>
          <div className="main_content">
            <h4>Model detail visits</h4>
            <p className="main" data-test-id={`_main`}>
              {cpoModelKeyIndicators?.Current &&
                cpoModelKeyIndicators?.Current[0]?.model_detail_visits &&
                toLocaleInteger(cpoModelKeyIndicators?.Current[0]?.model_detail_visits)}
            </p>

            <div className="comparison_values" data-test-id={``}>
              <p className="period_comparison" data-test-id={`period_comparison`}>
                <Ratings
                  value={
                    cpoModelKeyIndicators?.PoP && cpoModelKeyIndicators?.PoP[0]?.perc_model_detail_visits
                      ? isNaN(cpoModelKeyIndicators?.PoP[0]?.perc_model_detail_visits)
                        ? "na"
                        : String(cpoModelKeyIndicators?.PoP[0]?.perc_model_detail_visits)
                      : ""
                  }
                  isPercentageValue={true}
                />
                <span className="comparison_value" data-test-id={"visits_period_comparison_value"}>
                  {cpoModelKeyIndicators?.PoP && cpoModelKeyIndicators?.PoP[0]?.perc_model_detail_visits
                    ? cpoModelKeyIndicators?.PoP[0]?.perc_model_detail_visits
                    : "n/a"}
                </span>
                {popLabel}
              </p>

              <p className="year_comparison" data-test-id={`year_comparison`}>
                <Ratings
                  value={
                    cpoModelKeyIndicators?.YoY && cpoModelKeyIndicators?.YoY[0]?.perc_model_detail_visits
                      ? isNaN(cpoModelKeyIndicators?.YoY[0]?.perc_model_detail_visits)
                        ? "na"
                        : String(cpoModelKeyIndicators?.YoY[0]?.perc_model_detail_visits)
                      : ""
                  }
                  isPercentageValue={true}
                />
                <span className="comparison_value" data-test-id={"visits_period_comparison_value"}>
                  {cpoModelKeyIndicators?.YoY && cpoModelKeyIndicators?.YoY[0]?.perc_model_detail_visits
                    ? cpoModelKeyIndicators?.YoY[0]?.perc_model_detail_visits
                    : "n/a"}
                </span>
                YoY
              </p>
            </div>
          </div>
        </div>

        <LoadingEllipsis isLoading={visitsTableIsLoading} className={"metric_table_overlay"} />
        <LoadingEllipsis isLoading={visitsGraphIsLoading} className={"metric_graph_overlay"} />
      </div>
    </ErrorBoundary>
  );
};
