import { Button } from "@material-ui/core";
import { ErrorBoundary } from "@sentry/react";
import "moment-fquarter";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { setPageName, setSpecificParameter } from "../../../actions";
import { fetchCcsJourneyBannerData, fetchCcsJourneyData } from "../../../actions/ccsCockpitActions";
import "../../../assets/styles/pages/ccsJourney.scss";
import { ErrorMsg } from "../../../components/AppMessages";
import { CcsCockpitFilters } from "../../../components/Filters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import { ToggleSwitch } from "../../../components/Toogle";
import { ALL_COUNTRIES, ALL_OPTION } from "../../../constants";
import { BackgroundContext, DashboardBannerContext } from "../../../context";
import { isCustomDate, isDigitalPerformanceDate } from "../../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import {
  BOOTSTRAPPED_DEFINITION_STRING,
  CONNECTED_SOLD_ELIGIBLE_DEFINITION_STRING,
  CONNECTIVITY_READY_DEFINITION_STRING,
  ENROLLED_CUSTOMERS_DEFINITION_STRING,
  PRODUCED_ELIGIBLE_CONNECTED_CARS_DEFINITION_STRING,
  TOTAL_CARS_SOLD_STRING,
  TOTAL_VEHICLE_PRODUCED_DEFINITION_STRING,
  WARRANTY_START_DATE_DEFINITION_STRING,
} from "./helpers";
import { JourneyPyramid } from "./subs";

export const Journey = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();

  const { history, location } = props;

  const { setBannerText, setShowBanner } = useContext(DashboardBannerContext);

  const ccsCockpitLastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.ccs_cockpit);

  const { geography: availableGeographies } = useSelector((state: RootStateOrAny) => state.filters.cockpit_data);

  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.parameters.date_range);
  const { region: regionParamValue, market: marketParamValue } = useSelector((state: RootStateOrAny) => state.parameters);

  const {
    bootstrapped_cars,
    bootstrapped_cars_perc,
    enrolled_customers_perc,
    produced_all_models,
    produced_avk,
    produced_eligible,
    produced_eligible_avk_perc,
    produced_eligible_perc,
    services_activated_default,
    services_activated_default_perc,
    sold_all_models,
    sold_avk,
    sold_eligible,
    total_enrolled,
    total_warranty_start,
    warranty_start_date_perc,
  } = useSelector((state: RootStateOrAny) => state.ccs_cockpit.ccs_journey_data);
  const bannerDate = useSelector((state: RootStateOrAny) => state.ccs_cockpit.ccs_journey_banner_date);
  const loading = useSelector((state: RootStateOrAny) => state.loading.ccs_journey_data);

  const { setHideBackgroundImage } = useContext(BackgroundContext);

  const [isAllModelsPreSales, setIsAllModelsPreSales] = useState<boolean>(true);
  const [isAllModelsPostSales, setIsAllModelsPostSales] = useState<boolean>(true);

  //show banner for China
  useEffect(() => {
    if (bannerDate) {
      setShowBanner(true);
      setBannerText(`For the selected filters, KPIs are available in totality from ${bannerDate}.`);
    } else {
      setShowBanner(false);
    }
    return () => {
      setShowBanner(false);
      setBannerText("");
    };
  }, [bannerDate]);

  // Usage tracking
  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "CCS", page: "CCS Journey" });
  }, [location.pathname]);

  // Reset geography filter if invalid
  useEffect(() => {
    if (availableGeographies && availableGeographies.length) {
      const isRegionValid =
        regionParamValue && availableGeographies.filter((geo: { region: string }) => geo.region === regionParamValue).length !== 0;
      const isMarketValid =
        marketParamValue && availableGeographies.filter((geo: { market: string }) => geo.market === marketParamValue).length !== 0;
      !isMarketValid && dispatch(setSpecificParameter("market", ALL_COUNTRIES));
      !isRegionValid &&
        Promise.all([dispatch(setSpecificParameter("region", ALL_OPTION)), dispatch(setSpecificParameter("market", ALL_COUNTRIES))]);
    }
  }, [regionParamValue, marketParamValue, availableGeographies, dispatch]);

  // Sets page name
  useEffect(() => {
    Promise.all([setHideBackgroundImage(true), dispatch(setPageName("CCS journey"))]);

    return () => setHideBackgroundImage(false);
  }, [dispatch, setHideBackgroundImage]);

  // Fetching main data on filter change
  useEffect(() => {
    (!isDigitalPerformanceDate(dateRangeParamValue) || !isCustomDate(dateRangeParamValue)) &&
      Promise.all([dispatch(fetchCcsJourneyData()), dispatch(fetchCcsJourneyBannerData())]);
  }, [history.location.search, dateRangeParamValue, dispatch]);

  useEffect(() => {
    if (regionParamValue == "North America") {
      setIsAllModelsPreSales(false);
      setIsAllModelsPostSales(false);
    } else {
      setIsAllModelsPreSales(true);
      setIsAllModelsPostSales(true);
    }
  }, [regionParamValue]);

  const onBtnLinkClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      const {
        currentTarget: { dataset },
      } = evt;
      const pageLink = dataset?.page;

      history.push({ pathname: pageLink, search: "" });
    },
    [history]
  );

  const handlePreSalesToggleClick = () => {
    setIsAllModelsPreSales(!isAllModelsPreSales);
  };

  const handlePostSalesToggleClick = () => {
    setIsAllModelsPostSales(!isAllModelsPostSales);
  };

  useEffect(() => {
    if (regionParamValue == "North America") {
      setIsAllModelsPreSales(false);
      setIsAllModelsPostSales(false);
    } else if (regionParamValue == "Japan") {
      setIsAllModelsPostSales(false);
      setIsAllModelsPreSales(true);
    } else {
      setIsAllModelsPostSales(true);
      setIsAllModelsPreSales(true);
    }
  }, [regionParamValue]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={
          <ErrorBoundary fallback={<ErrorMsg />}>
            <CcsCockpitFilters
              filterBtns={[{ id: "ccs_cockpit_btn", name: "See CCS vital signs", navigateTo: "/ccs_vital_signs/ccs_cockpit" }]}
            />
          </ErrorBoundary>
        }
        lastDataRefresh={ccsCockpitLastDataRefresh}
        dataDocumentation="ccs_data"
        pageTitle={<PageTitle dataDocumentation="ccs_data" />}
      >
        <ErrorBoundary fallback={<ErrorMsg />}>
          <div className="ccs_journey_container">
            <div className="ccs_journey_section">
              <div className="journey_header">
                Pre-sales{" "}
                {marketParamValue === "United States" && (
                  <ToggleSwitch
                    toggleTitle={"See data for:"}
                    activeToggleLabel={"All models"}
                    inactiveToggleLabel={"Avk models"}
                    active={isAllModelsPreSales}
                    handleToggleClick={handlePreSalesToggleClick}
                    toggleClassName={"dp_toggle"}
                  />
                )}
              </div>

              <div className="journey_pyramid_container">
                {/*Total cars produced */}
                <JourneyPyramid
                  title="Total cars produced"
                  mainValue={isAllModelsPreSales ? produced_all_models : produced_avk}
                  level="one"
                  moduleDefinition={
                    isAllModelsPreSales ? TOTAL_VEHICLE_PRODUCED_DEFINITION_STRING[1] : TOTAL_VEHICLE_PRODUCED_DEFINITION_STRING[0]
                  }
                  disabled={regionParamValue == "China" ? true : false}
                  isLoading={loading}
                />

                {/*Produced eligible*/}
                <JourneyPyramid
                  title="Produced eligible connected cars"
                  mainValue={produced_eligible}
                  level="two"
                  percentageDefinition="of cars produced"
                  moduleDefinition={
                    isAllModelsPreSales
                      ? PRODUCED_ELIGIBLE_CONNECTED_CARS_DEFINITION_STRING[1]
                      : PRODUCED_ELIGIBLE_CONNECTED_CARS_DEFINITION_STRING[0]
                  }
                  percentageValue={isAllModelsPreSales ? produced_eligible_perc : produced_eligible_avk_perc}
                  disabled={regionParamValue == "China" ? true : false}
                  isLoading={loading}
                />

                {/*Bootstrapped cars */}
                <JourneyPyramid
                  title="Bootstrapped cars"
                  mainValue={bootstrapped_cars}
                  level="three"
                  percentageDefinition="of produced eligible connected cars"
                  moduleDefinition={BOOTSTRAPPED_DEFINITION_STRING}
                  percentageValue={bootstrapped_cars_perc}
                  disabled={regionParamValue == "China" ? true : false}
                  isLoading={loading}
                />
                {/*Service activated */}
                <JourneyPyramid
                  title="Service activated default"
                  mainValue={services_activated_default}
                  level="four"
                  percentageDefinition="of bootstrapped connected cars"
                  moduleDefinition={CONNECTIVITY_READY_DEFINITION_STRING}
                  percentageValue={services_activated_default_perc}
                  disabled={regionParamValue == "China" ? true : false}
                  isLoading={loading}
                />
              </div>
            </div>

            <div className="ccs_journey_section">
              <div className="journey_header">
                Post-sales{" "}
                {(marketParamValue === "United States" || regionParamValue === "Japan") && (
                  <ToggleSwitch
                    toggleTitle={"See data for:"}
                    activeToggleLabel={"All models"}
                    inactiveToggleLabel={"Avk models"}
                    active={isAllModelsPostSales}
                    handleToggleClick={handlePostSalesToggleClick}
                    toggleClassName={"dp_toggle"}
                  />
                )}
              </div>
              <div className="journey_pyramid_container">
                {/*Connected sold eligible */}
                {(regionParamValue == "Japan" || regionParamValue == "North America" || regionParamValue == "China") && (
                  <JourneyPyramid
                    title="Total cars sold"
                    mainValue={isAllModelsPostSales ? sold_all_models : sold_avk}
                    level="one"
                    moduleDefinition={isAllModelsPostSales ? TOTAL_CARS_SOLD_STRING[1] : TOTAL_CARS_SOLD_STRING[0]}
                    isLoading={loading}
                  />
                )}

                {/*Connected sold eligible */}
                <JourneyPyramid
                  title="Connected cars sold"
                  mainValue={sold_eligible}
                  level={`${
                    regionParamValue == "Japan" || regionParamValue == "North America" || regionParamValue == "China" ? "two" : "one"
                  }`}
                  moduleDefinition={CONNECTED_SOLD_ELIGIBLE_DEFINITION_STRING}
                  isLoading={loading}
                />
                {/*Warranty start date */}
                {regionParamValue != "China" && (
                  <JourneyPyramid
                    title="Warranty start date"
                    mainValue={total_warranty_start}
                    percentageValue={warranty_start_date_perc}
                    level={`${regionParamValue == "Japan" || regionParamValue == "North America" ? "three" : "two"}`}
                    percentageDefinition="of connected cars sold"
                    moduleDefinition={WARRANTY_START_DATE_DEFINITION_STRING}
                    isLoading={loading}
                  />
                )}

                {/*Enrolled customers */}
                <JourneyPyramid
                  title="Enrolled customer"
                  mainValue={total_enrolled}
                  percentageValue={marketParamValue == "United States" || regionParamValue == "Japan" ? "n/a" : enrolled_customers_perc}
                  level={`${regionParamValue == "Japan" || regionParamValue == "North America" ? "four" : "three"}`}
                  percentageDefinition="of warranty start date"
                  moduleDefinition={ENROLLED_CUSTOMERS_DEFINITION_STRING}
                  isLoading={loading}
                />
              </div>
            </div>
          </div>

          <div className="container_footer hide_on_desktop">
            <Button variant={"outlined"} data-page={"/ccs_vital_signs/ccs_cockpit"} onClick={(evt) => onBtnLinkClick(evt)}>
              See CCS vital signs
            </Button>
          </div>
        </ErrorBoundary>
      </DefaultPageContent>
    </DashboardLayout>
  );
});
