import moment from "moment";
import { isCustomDate } from "../utils/dateFunctions";
import { fetchDataV2, newApiFetchV2 } from "./utils";

export const getQueryParams = () => {
  const parameters = new URLSearchParams(window.location.search);

  const dateRangeParam = parameters.get("date_range");
  const marketParam = parameters.get("market");
  const productParam = parameters.get("product");
  const granularityParam = parameters.get("granularity");
  const brandParam = parameters.get("brand");

  const paramObj: any = {
    market: marketParam,
    product: productParam,
    brand: brandParam,
    // granularity: granularityParam,
  };

  if (dateRangeParam && isCustomDate(dateRangeParam as string)) {
    paramObj["date_range"] = "custom";
    paramObj["start_date"] = moment(dateRangeParam.split("-")[0], "DD/MM/YYYY").format("YYYY-MM-DD");
    paramObj["end_date"] = moment(dateRangeParam.split("-")[1], "DD/MM/YYYY").format("YYYY-MM-DD");
  } else if (dateRangeParam) {
    paramObj["date_range"] = dateRangeParam.split(" ").join("_").toLocaleLowerCase();
  }

  return paramObj;
};

export const getLiveMarkets = () => newApiFetchV2("/product-performance/live-markets", { ...getQueryParams() });
export const getFeaturesActivated = () => newApiFetchV2("/product-performance/features-activated", { ...getQueryParams() });
export const getDigitalPerformance = () => newApiFetchV2("/product-performance/digital-performance", { ...getQueryParams() });
export const getInfluenceOnShoppingTools = () => newApiFetchV2("/product-performance/influence-on-shopping-tools", { ...getQueryParams() });
export const getInfluenceOnLeads = () => newApiFetchV2("/product-performance/influence-on-leads", { ...getQueryParams() });
export const getTotalBugs = () => newApiFetchV2("/product-performance/total-bugs", { ...getQueryParams() });
export const getBugsStatusBreakdown = () => newApiFetchV2("/product-performance/bugs-status-breakdown", { ...getQueryParams() });
export const getBugsReleaseBreakdown = () => newApiFetchV2("/product-performance/bugs-release-breakdown", { ...getQueryParams() });
export const getPagePerformance = () => newApiFetchV2("/product-performance/page-performance", { ...getQueryParams() });
export const getPagePerformanceBreakdown = () => newApiFetchV2("/product-performance/page-performance-breakdown", { ...getQueryParams() });
export const getAccessibility = () => newApiFetchV2("/product-performance/accessibility", { ...getQueryParams() });
export const getProductPerformanceDataDefinitions = () => fetchDataV2(`/product-performance/get-data-definition`);
export const getCustomerExperience = () => newApiFetchV2("/product-performance/customer-experience-dxs", { ...getQueryParams() });
export const getErrorPages = () => newApiFetchV2("/product-performance/error-pages", { ...getQueryParams() });
