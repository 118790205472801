import { put, retry, takeLatest } from "redux-saga/effects";
import { setReleaseNotes } from "../actions";
import { GET_RELEASE_NOTES } from "../actions/actionTypes";
import { getReleaseNotesData } from "../api/releaseNotesApi";
import { secondsDelay } from "../api/utils";

// Watcher sagas
export function* watchFetchReleaseNotes() {
  yield takeLatest(GET_RELEASE_NOTES, getReleaseNotes);
}

// Worker sagas
export function* getReleaseNotes() {
  try {
    const response: string = yield retry(4, secondsDelay, getReleaseNotesData);

    //@ts-ignore
    if (!("error" in response)) {
      yield put(setReleaseNotes(response));
    }
  } catch (err) {
    console.log("Failed to fetch release notes");
  }
}
