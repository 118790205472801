import { format, parse } from "date-fns";
import { OCE_DATE_FILTER_OPTIONS } from "../constants";
import { apiFetchV2, fetchDataV2 } from "./utils";

export const cpoOperationalParams = (params: string) => {
  const parameters = new URLSearchParams(params);
  const paramObj = Object.fromEntries(parameters.entries());

  const dateRangeParam = parameters?.get("date_range");
  const marketParameter = /all/gi.test(paramObj?.market) ? [] : paramObj?.market?.split(",");
  const regionParameter = /all/gi.test(paramObj?.region) ? [] : paramObj?.region?.split(",");
  const territoryParameter = /all/gi.test(paramObj?.territory) ? [] : paramObj?.territory?.split(",");
  const areaParameter = /all/gi.test(paramObj?.area) ? [] : paramObj?.area?.split(",");
  const districtParameter = /all/gi.test(paramObj?.district) ? [] : paramObj?.district?.split(",");

  const modelParameter = /all/gi.test(paramObj?.model) ? [] : paramObj?.model?.split(",");
  const yearParameter = /all/gi.test(paramObj?.year) ? [] : paramObj?.year?.split(",").map(Number);
  const certifiedParameter = /all/gi.test(paramObj?.certified) ? [] : paramObj?.certified?.split(",");

  const dealerParameter = /all/gi.test(paramObj?.dealer)
    ? []
    : paramObj?.dealer?.includes("Top")
    ? ["top_five_dealers"]
    : paramObj?.dealer?.includes("Bottom")
    ? ["bottom_five_dealers"]
    : paramObj?.dealer?.split(",");
  const outletParameter = /all/gi.test(paramObj?.outlet) ? [] : paramObj?.outlet?.split(",").map(Number);
  const mileageParam = /all/gi.test(paramObj?.mileage) ? [] : paramObj?.mileage?.split(",").map(Number);
  const priceParam = /all/gi.test(paramObj?.price) ? [] : paramObj?.price?.split(",");

  const date = dateRangeParam
    ? OCE_DATE_FILTER_OPTIONS.includes(dateRangeParam)
      ? dateRangeParam == "Last 12 months"
        ? "last_year"
        : dateRangeParam == "Last 6 months"
        ? "last_half_year"
        : dateRangeParam.split(" ").join("_").toLocaleLowerCase()
      : "custom"
    : "";

  delete paramObj?.region;
  delete paramObj?.territory;
  delete paramObj?.model;
  delete paramObj?.mileage;
  delete paramObj?.year;
  delete paramObj?.price;
  delete paramObj?.certified;
  delete paramObj?.metric;
  delete paramObj?.date_range;
  delete paramObj?.market;

  if (date == "custom") {
    paramObj["start_date"] = dateRangeParam ? format(parse(dateRangeParam.split("-")[0].trim(), "dd/MM/yyyy", new Date()), "y-MM-dd") : "";
    paramObj["end_date"] = dateRangeParam ? format(parse(dateRangeParam.split("-")[1].trim(), "dd/MM/yyyy", new Date()), "y-MM-dd") : "";
  }

  return {
    ...paramObj,
    date_range: date,
    region: regionParameter,
    territory: territoryParameter,
    model: modelParameter,
    year: yearParameter,
    certified: certifiedParameter,
    dealer: dealerParameter,
    outlet: outletParameter,
    area: areaParameter,
    district: districtParameter,
    market: marketParameter,
    price: priceParam,
    mileage: mileageParam,
  };
};

export const getBaseTrendsData = (params: string) =>
  apiFetchV2("/cpo-operational/base-trends?use_cache=true", cpoOperationalParams(params));

export const getDealerTrendData = (params: string) =>
  apiFetchV2("/cpo-operational/dealer-trends?use_cache=true", cpoOperationalParams(params));

export const getInventoryByAgeData = (params: string) =>
  apiFetchV2("/cpo-operational/inv-by-age?use_cache=true", cpoOperationalParams(params));

export const getPerformancePerUnitData = (params: string) =>
  apiFetchV2("/cpo-operational/sales-funnel?use_cache=true", cpoOperationalParams(params));

export const getTotalValuesData = (params: string) => apiFetchV2("/cpo-operational/total-values", cpoOperationalParams(params));

export const getListPriceData = (params: string) => apiFetchV2("/cpo-operational/list-price", cpoOperationalParams(params));

export const getInventoryRatioData = (params: string) => apiFetchV2("/cpo-operational/inventory-ratio", cpoOperationalParams(params));

export const getTurnoverRatioData = (params: string) => apiFetchV2("/cpo-operational/turnover-ratio", cpoOperationalParams(params));

export const getLeadSalesConversionData = (params: string) =>
  apiFetchV2("/cpo-operational/leads-sales-conversion", cpoOperationalParams(params));

export const getInventoryVolumeTrendData = (params: string) =>
  apiFetchV2("/cpo-operational/inventory-volume-trend", cpoOperationalParams(params));

export const getAverageDaysInStockTrendData = (params: string) =>
  apiFetchV2("/cpo-operational/average-days-in-stock-trend", cpoOperationalParams(params));

export const getSalesVolumeTrendData = (params: string) => apiFetchV2("/cpo-operational/sales-volume-trend", cpoOperationalParams(params));

export const getSalesRevenueTrendData = (params: string) =>
  apiFetchV2("/cpo-operational/sales-revenue-trend", cpoOperationalParams(params));

export const getAverageListPriceTrendData = (params: string) =>
  apiFetchV2("/cpo-operational/average-list-price-trend", cpoOperationalParams(params));

export const getAverageSalesPriceTrendData = (params: string) =>
  apiFetchV2("/cpo-operational/average-sales-price-trend", cpoOperationalParams(params));

export const getUsedCarsDataDefinitions = () => fetchDataV2(`/cpo-operational/data-definition`);

export const getLastWeekDates = () => fetchDataV2(`/cpo-operational/last-week-dates`);

const extractMileage = (mileage: string) => {
  if (/all/gi.test(mileage)) return { lowest_mileage: "", highest_mileage: "" };
  if (mileage?.includes("<") && mileage?.includes(">")) return { lowest_mileage: "", highest_mileage: "" };
  if (mileage?.includes("<") && mileage?.split(",")?.length == 2) return { lowest_mileage: "", highest_mileage: "50000" };
  if (mileage?.includes(">") && mileage?.split(",")?.length == 2) return { lowest_mileage: "100000", highest_mileage: "" };
  if (mileage?.includes("<") && mileage?.includes("100,000")) return { lowest_mileage: "", highest_mileage: "100000" };
  if (mileage?.includes(">") && mileage?.includes("50,000")) return { highest_mileage: "", lowest_mileage: "50000" };
  if (mileage?.includes("50,000 - 100,000") && mileage?.includes("<")) return { lowest_mileage: "", highest_mileage: "100000" };
  if (mileage?.includes("50,000 - 100,000") && !mileage?.includes("<") && !mileage?.includes(">"))
    return { lowest_mileage: "50000", highest_mileage: "100000" };
  return { lowest_mileage: "", highest_mileage: "" };
};

const extractPrice = (price: string) => {
  if (/all/gi.test(price)) return { lowest_price: "", highest_price: "" };
  if (price?.includes("<") && price?.includes(">")) return { lowest_price: "", highest_price: "" };
  if (price?.includes("<") && price?.split(",")?.length == 2) return { lowest_price: "", highest_price: "5000" };
  if (price?.includes(">") && price?.split(",")?.length == 2) return { lowest_price: "25000", highest_price: "" };

  if (price?.includes(">") && price?.includes("5,000 - 10,000")) return { highest_price: "", lowest_price: "5000" };
  if (price?.includes(">") && price?.includes("10,000 - 25,000")) return { lowest_price: "10000", highest_price: "" };

  if (price?.includes("<") && price?.includes("25,000")) return { lowest_price: "", highest_price: "25000" };
  if (price?.includes("<") && price?.includes("10,000")) return { lowest_price: "", highest_price: "10000" };

  if (price?.includes("5,000") && price?.includes("25,000")) return { lowest_price: "5000", highest_price: "25000" };
  if (price?.includes("5,000") && price?.includes("10,000")) return { lowest_price: "5000", highest_price: "10000" };

  if (price?.includes("50,000 - 100,000") && price?.includes("<")) return { lowest_price: "", highest_price: "100000" };
  if (price?.includes("50,000 - 100,000") && !price?.includes("<") && !price?.includes(">"))
    return { lowest_price: "50000", highest_price: "100000" };
  return { lowest_price: "", highest_price: "" };
};
