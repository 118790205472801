import React, { Dispatch, SetStateAction, useCallback, useContext } from "react";
import { FilterContext } from "../../../context";
import { closeFilterOptions, closeMarketOptions } from "../subs/helpers";

const InAppSingleSelectNestedFilter = (props: {
  options: Array<Record<string, any>>;
  filterName: string;
  filterLabel: string;
  filterValue: string;
  setValue: Dispatch<SetStateAction<string>>;
}) => {
  const { options, filterName, filterLabel, filterValue, setValue } = props;
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const showFilterOptionsFn = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const optionsId = dataset?.optionsUl;
      const shownClass = "showOptions";

      const optionsElement = optionsId ? document.getElementById(optionsId) : undefined;

      if (showFilterOptions && optionsElement?.classList.contains(shownClass)) {
        closeFilterOptions();
        setShowFilterOptions(false);
      } else {
        closeFilterOptions();
        optionsElement?.classList.toggle(shownClass);
        setShowFilterOptions(!showFilterOptions);
      }
    },
    [showFilterOptions, setShowFilterOptions]
  );

  const handleMarketArrowClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { id: currentId, dataset },
    } = evt;
    const marketListId = dataset?.marketTarget as string;

    const currentElement = document.getElementById(currentId) as HTMLElement;
    const marketListElement = document.getElementById(marketListId) as HTMLElement;

    const parent = currentElement.parentElement as HTMLElement;
    const shownClass = "show-market";
    const marketDownClass = "down";
    const downClass = currentElement?.children[0]?.classList || parent.children[0].children[0].classList;
    if (!downClass.contains(marketDownClass)) closeMarketOptions();
    downClass.toggle(marketDownClass);
    marketListElement.classList.toggle(shownClass);
  }, []);

  const handleOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    evt.stopPropagation();
    if (dataset?.hasChildren) return;
    dataset?.value && setValue(dataset.value as string);
    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);
  };

  //Displays options
  const onRenderOptions = ({ options, filter }: { options: Array<Record<string, any>>; filter: string }): JSX.Element => (
    <>
      {options?.map((item: Record<string, any>, index: number) => {
        const hasMarket = item?.children?.length > 0 ? `region${index}` : "region0";
        const marketUlId = `market_ul${index}`;

        return (
          <>
            <div className={"region-list"} key={item.region}>
              <span
                id={`market-arrow${index}`}
                className={`region-span ${hasMarket}`}
                data-market-target={marketUlId}
                onClick={handleMarketArrowClick}
              >
                <span className={"market-arrow"} data-market-target={marketUlId} />
              </span>
              <li
                id={item.value}
                className="default-filter-option"
                key={item.value}
                data-filter={filter}
                onClick={(evt) => (item?.children?.length > 0 ? handleMarketArrowClick(evt) : handleOptionClick(evt))}
                data-value={item.value}
                data-has-children={item?.children?.length}
                data-market-target={marketUlId}
              >
                {item.value}
              </li>
            </div>

            {item?.children && item?.children?.length > 0 && (
              <ul className={"region_country_filter"} id={marketUlId} style={{ paddingLeft: ".5em" }}>
                {item.children.map((child: Record<string, any>) => (
                  <li key={child.value} data-value={child.value} onClick={handleOptionClick} onMouseDown={(e) => e.stopPropagation()}>
                    {child.value}
                  </li>
                ))}
              </ul>
            )}
          </>
        );
      })}
    </>
  );

  return (
    <div className={`filter`} id={`${filterName}_filter_div`}>
      <label className="filter_header" htmlFor={`${filterName}_filter`}>
        {filterLabel}
      </label>
      <div className="input_arrow" onClick={showFilterOptionsFn} data-options-ul={`${filterName}_ul`}>
        <input
          readOnly
          type="text"
          name={filterName}
          id={`${filterName}_filter`}
          className={"filter_input"}
          data-test-id="group"
          value={filterValue}
        />
        <span className="arrow_down " />
      </div>
      <ul className={`filter_options`} id={`${filterName}_ul`} data-test-id={`${filterName}_list`}>
        {onRenderOptions({ options, filter: filterName })}
      </ul>
    </div>
  );
};

export default InAppSingleSelectNestedFilter;
