import React, { Fragment, useCallback } from "react";
import { useDispatch } from "react-redux";
import { MultiSelectFilter } from ".";
import { setSpecificParameter } from "../../../actions";
import { closeFilterOptions } from "../subs/helpers";

interface Props {
  filterValue: string;
  modelList: string[];
  showFilterOptions: (evt: React.MouseEvent<HTMLElement>) => void;
  modelParamValue: string;
}

export const MTMModelFilter = (props: Props) => {
  const dispatch = useDispatch();

  const { filterValue, modelList, showFilterOptions, modelParamValue } = props;

  const handleMarketMultiselectSubmit = useCallback(() => {
    const parameterName = "model";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = selectedValues?.join(",");
    if (filterValue) {
      closeFilterOptions();
      dispatch(setSpecificParameter(parameterName, filterValue));
    }
  }, []);

  const handleFilterOptionClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;
    closeFilterOptions();
    if (filterName && optionValue) {
      dispatch(setSpecificParameter("model", optionValue));
    }
  }, []);

  return (
    <div className={"filter"} id="market_filter_div">
      <label className="filter_header" htmlFor="market_filter">
        BRAND/MODEL
      </label>
      <div className="input_arrow" onClick={showFilterOptions} data-options-ul={"market_ul"}>
        <input readOnly type="text" name="market" id="market_filter" className={"filter_input"} data-test-id="market" value={filterValue} />
        <span className="arrow_down " />
      </div>
      <ul className="filter_options" id="market_ul" data-test-id="market_list">
        <Fragment>
          <li className="filter_divider" data-filter={"model_group"} data-value={"Nissan"} onClick={handleFilterOptionClick} key={"Nissan"}>
            Nissan
          </li>
          <MultiSelectFilter
            parentKey="model_filter"
            showOptionsOnly={true}
            // @ts-ignore
            filterList={modelList}
            value={filterValue}
            parameterName={"model"}
            parameterValue={modelParamValue.includes("All") ? modelList?.join(",") : modelParamValue}
            onShowFilterOptions={showFilterOptions}
            handleFilterSubmission={handleMarketMultiselectSubmit}
          />
        </Fragment>
      </ul>
    </div>
  );
};
