import { CategoryAxis, ColumnSeries, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component, ReactNode } from "react";
import WebFont from "webfontloader";
import { CYAN, LIGHT_CYAN, PURPLE, RED } from "../../../constants";
import { disposeChart } from "../helpers/chartHelpers";
import {
  columnSeriesConfiguration,
  configureCategoryAxis,
  configureValueAxis,
  legendConfiguration,
  lineSeriesConfiguration,
} from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  chartId: string;
  theme: ThemeInt;
  barSeriesList: Array<{ name: string; dataKey: string }>;
  lineSeriesList: Array<{ name: string; dataKey: string }>;
  data: any;
}

class ValueCombinedChartNew extends Component<Props> {
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.props.chartId);
      this.initChart();
    }
  }

  componentWillUnmount() {
    disposeChart(this.props.chartId);
  }

  initChart() {
    const { chartId, data, theme, barSeriesList, lineSeriesList } = this.props;

    this.chart = create(chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#.#a";
    this.chart.colors.list = [color(CYAN), color(PURPLE), color(LIGHT_CYAN), color(RED)];

    // Legend configuration
    this.chart.legend = legendConfiguration(theme);

    // Creating Axes
    const categoryAxis = this.chart.xAxes.push(new CategoryAxis());
    configureCategoryAxis(categoryAxis, theme);
    categoryAxis.dataFields.category = "label";
    categoryAxis.renderer.cellStartLocation = 0.2;
    categoryAxis.renderer.cellEndLocation = 0.8;

    const percentageAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(percentageAxis, theme);
    percentageAxis.renderer.opposite = true;
    percentageAxis.min = 0;
    percentageAxis.strictMinMax = true;
    percentageAxis.renderer.labels.template.adapter.add("text", (text) => {
      return text + "%";
    });
    percentageAxis.renderer.grid.template.disabled = true;
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.min = 0;
    valueAxis.numberFormatter.numberFormat = "#.#a";
    valueAxis.renderer.minGridDistance = 30;
    percentageAxis.syncWithAxis = valueAxis;
    percentageAxis.renderer.minGridDistance = 30;

    barSeriesList.forEach((barSeries) => {
      // Creating Series
      const series = this.chart.series.push(new ColumnSeries());
      columnSeriesConfiguration(series, {
        name: barSeries.name,
        valueY: barSeries.dataKey,
        yAxis: valueAxis,
      });
      series.dataFields.categoryX = "label";
      series.columns.template.tooltipText = "{name} {categoryX}: {valueY.formatNumber('###,###')}";
      series.columns.template.width = percent(100);
    });

    lineSeriesList.forEach((lineSeries) => {
      const series = this.chart.series.push(new LineSeries());
      const bullet = lineSeriesConfiguration(series, {
        name: lineSeries.name,
        valueY: lineSeries.dataKey,
        yAxis: percentageAxis,
      });
      series.dataFields.categoryX = "label";
      series.strokeWidth = 2;
      bullet.tooltipText = "{name} {categoryX}: {valueY.formatNumber('#.')}%";
    });
  }

  render(): ReactNode {
    return <div id={this.props.chartId} className={"metricChart"} />;
  }
}

export default ValueCombinedChartNew;
