import { PieChart, PieSeries } from "@amcharts/amcharts4/charts";
import { color, create, percent } from "@amcharts/amcharts4/core";
import { ErrorBoundary } from "@sentry/react";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { WHITE } from "../../../constants";
import { ErrorMsg } from "../../AppMessages";
import { disposeChart } from "../helpers/chartHelpers";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<{ title: string; value: number; color: string }>;
  theme: ThemeInt;
}

class CallCentreDonutChart extends Component<Props> {
  chartId = "callCenterChart";

  static defaultProps = {
    metric: "visits",
  };

  chart!: PieChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId);
      this.initChart();
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, theme } = this.props;
    this.chart = create(this.chartId, PieChart);
    this.chart.data = data;
    this.chart.innerRadius = percent(65);
    this.chart.radius = percent(90);

    // Creating Series
    const pieSeries = this.chart.series.push(new PieSeries());
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "title";
    pieSeries.slices.template.propertyFields.fill = "color";

    pieSeries.labels.template.text = "{value.percent.formatNumber('#.')}%\n{category}";
    pieSeries.labels.template.fill = theme === "light" ? color("#000000") : color(WHITE);
    pieSeries.labels.template.radius = percent(20);

    pieSeries.ticks.template.disabled = true;
    pieSeries.slices.template.tooltipText = "";
    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    // Responsive
    this.chart.responsive.enabled = true;
    this.chart.responsive.rules.push({
      relevant: function (target: any) {
        return target.pixelWidth <= 400;
      },
      state: function state(target: any, stateId: string) {
        if (target instanceof PieSeries) {
          const state = target.states.create(stateId);
          state.properties.scale = -0.5;
        }
        return target;
      },
    });

    const hoverState = pieSeries.slices.template.states.getKey("hover");
    if (hoverState) hoverState.properties.scale = 1;

    const activeState = pieSeries.slices.template.states.getKey("active");
    if (activeState) activeState.properties.shiftRadius = 0;
  }
  render() {
    return (
      <ErrorBoundary fallback={<ErrorMsg />}>
        <div id="callCenterChart" />
      </ErrorBoundary>
    );
  }
}

export default CallCentreDonutChart;
