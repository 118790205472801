import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Tooltip } from "react-tippy";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { getGranularityFromDate } from "../../../utils/utilityFunctions";
import DigitalPerformanceTable from "./DigitalPerformanceTable";
import InfluenceOnLeadsTable from "./InfluenceOnLeadsTable";
import InfluenceShopppingToolsTable from "./InfluenceShopppingToolsTable";
import MetricTile from "./MetricTile";
import PageOverallPerformance from "./PageOverallPerformance";

const PoPLabelMapping: Record<string, string> = {
  weekly: "WoW",
  monthly: "MoM",
  quarterly: "QoQ",
};

const MarketPerformance = () => {
  const { granularity: granularityParam, date_range: dateRangeParam } = useSelector((state: RootStateOrAny) => state.parameters);

  const {
    Current: accessibilityCurrent,
    PoP: accessibilityPoP,
    YoY: accessibilityYoY,
  } = useSelector((state: RootStateOrAny) => state.product_performance.accessibility);
  const {
    Current: customerExperienceCurrent,
    PoP: customerExperiencePoP,
    YoY: customerExperienceYoY,
  } = useSelector((state: RootStateOrAny) => state.product_performance.customer_experience);
  const {
    accessibility: accessibilityLoading,
    digital_performance: digitalPerformanceLoading,
    influence_on_shopping_tools: influenceOnShoppingToolsLoading,
    influence_on_leads: influenceOnLeadsLoading,
    customer_experience: customerExperienceLoading,
  } = useSelector((state: RootStateOrAny) => state.loading);

  const PoPLabel = useMemo(() => {
    const dateGranularity = getGranularityFromDate(dateRangeParam);
    return PoPLabelMapping[dateGranularity];
  }, [dateRangeParam]);

  const showPoP = useMemo(() => {
    if (dateRangeParam.includes("-") || dateRangeParam.includes("year")) {
      return false;
    }
    return true;
  }, [dateRangeParam]);

  return (
    <div>
      <div className="grid-5 h-20">
        <MetricTile
          title="CUSTOMER EXPERIENCE - DXS"
          isLoading={customerExperienceLoading}
          currentData={customerExperienceCurrent}
          PoPData={customerExperiencePoP}
          YoYData={customerExperienceYoY}
          PoPLabel={PoPLabel}
          paramKey="customer_experience"
          showPoP={showPoP}
        />
        <div className="grid-col-span-3 pp-tile">
          <PageOverallPerformance />
        </div>
        <MetricTile
          title="ACCESSIBILITY"
          isLoading={accessibilityLoading}
          currentData={accessibilityCurrent}
          PoPData={accessibilityPoP}
          YoYData={accessibilityYoY}
          PoPLabel={PoPLabel}
          paramKey="accessibility_score"
          showPoP={showPoP}
        />
      </div>
      <div className="grid-6 mt-1">
        <div className="grid-col-span-2 pp-tile">
          <div className="tile-header">DIGITAL PERFORMANCE</div>
          <DigitalPerformanceTable />
          <LoadingEllipsis isLoading={digitalPerformanceLoading} />
        </div>
        <div className="grid-col-span-2 pp-tile">
          <div className="tile-header">
            INFLUENCE ON SHOPPING TOOLS{" "}
            <Tooltip
              position="top"
              className={"normal_tippy"}
              trigger="mouseenter"
              animation="none"
              interactive={true}
              delay={1}
              hideDelay={1}
              duration={1}
              title={"Number of visits interacting with at least one tool"}
              size={"small"}
            >
              <span className="info_tooltip" />
            </Tooltip>
          </div>
          <InfluenceShopppingToolsTable />
          <LoadingEllipsis isLoading={influenceOnShoppingToolsLoading} />
        </div>
        <div className="grid-col-span-2 pp-tile">
          <div className="tile-header">
            INFLUENCE ON LEADS{" "}
            <Tooltip
              position="top"
              className={"normal_tippy"}
              trigger="mouseenter"
              animation="none"
              interactive={true}
              delay={1}
              hideDelay={1}
              duration={1}
              title={"Number of visits submitting at least one prospect lead"}
              size={"small"}
            >
              <span className="info_tooltip" />
            </Tooltip>
          </div>
          <InfluenceOnLeadsTable />
          <LoadingEllipsis isLoading={influenceOnLeadsLoading} />
        </div>
      </div>
    </div>
  );
};

export default MarketPerformance;
