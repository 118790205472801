import { ErrorBoundary } from "@sentry/react";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg, NoDataMsg } from "../../../../components/AppMessages";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { MtpCategoryTable } from "../../../../components/Tables";
import { getAvailableYearScenarios } from "../../helpers";
import { getMtpCategoryTableData, getTotalsRow } from "../Helpers/helpers";
import PdfView from "./PdfView";
import ScrollableTitles from "./ScrollableTitles";
import TablesMobile from "./TablesMobile";

const MtpCategoryTables = () => {
  const data = useSelector((state: RootStateOrAny) => state.midas.midas_mtp_category_data_by_quarter);
  const period = useSelector((state: RootStateOrAny) => state.parameters.period);
  const loading = useSelector((state: RootStateOrAny) => state.loading.midas_mtp_category_data_by_quarter);

  const verts = useSelector((state: RootStateOrAny) => state.parameters.verticalScroll);

  const screenWidth = window.matchMedia("(max-width: 768px)");
  const periodClass: string = useMemo(() => (period == "FY Month" ? "month_period" : "quarter_period"), [period]);

  const allFmiCategories: string[] = useMemo(() => {
    if (data?.full_year?.length) {
      const allBreakdowns = data?.full_year.map((val: { break_down_data: any }) => val?.break_down_data).flat();
      return Array.from(new Set(allBreakdowns.map((val: { fmi_category: any }) => val?.fmi_category)));
    }
    return [];
  }, [data]);

  const categories: string[] = useMemo(() => {
    if (data?.full_year?.length) {
      return Array.from(new Set(data?.full_year?.map((val: { mtp_category: string }) => val?.mtp_category)));
    }
    return [];
  }, [data]);

  const yrScenarios: string[] = useMemo(() => getAvailableYearScenarios(data?.full_year), [data]);

  const combinedData = useMemo(() => {
    return data?.q1?.concat(data?.q2?.concat(data?.q3?.concat(data?.q4?.concat(data?.full_year))));
  }, [data, period]);

  const combinedTableData = useMemo(
    () => getMtpCategoryTableData(categories, combinedData, allFmiCategories, period),
    [data, period, combinedData, allFmiCategories]
  );

  const quarters: number[] = useMemo(() => {
    return Array.from(new Set(combinedData?.map((val: { quarter: string | null }) => Number(val.quarter))));
  }, [combinedData]);

  const months: number[] = useMemo(() => {
    return Array.from(new Set(combinedData?.map((val: { month: string | null }) => Number(val?.month))));
  }, [combinedData]);

  //Extract Row to display as totals
  const totalsCombined = useMemo(() => {
    const totalsRow = getTotalsRow(combinedTableData, yrScenarios, period, quarters, months);
    return [totalsRow, ...combinedTableData];
  }, [combinedTableData, period, months, yrScenarios, quarters]);

  const yrScnrClass = useMemo(() => (yrScenarios?.length == 1 ? "one_yr_scnr_no_var" : ""), [yrScenarios]);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    const elem = document.getElementsByClassName("modelsTable")[0];
    setHeight(elem?.clientHeight);
  }, [document]);

  const handleScroll = (event: { currentTarget: any }) => {
    const wrapper = document.getElementsByClassName("quarter_titles1")[0];
    const tables = document.getElementsByClassName("table_2")[0];

    if (event.currentTarget?.className?.includes("table_2")) {
      wrapper.scrollLeft = event?.currentTarget?.scrollLeft;
    } else {
      tables.scrollLeft = event?.currentTarget?.scrollLeft;
    }
  };

  const ref = useRef<any>(null);
  const modelTableRef = useRef<any>(null);

  const [width, setWidth] = useState(0);
  useEffect(() => {
    const modelWidth = modelTableRef.current ? modelTableRef.current.offsetWidth : 0;
    const dataTableWidth = ref.current ? ref.current.offsetWidth : 0;

    setWidth(dataTableWidth - modelWidth);
  });

  return (
    <div className={`mtp_category_tables primary_background ${yrScnrClass} ${!screenWidth.matches ? "combined" : ""} ${periodClass}`}>
      {screenWidth.matches ? (
        <TablesMobile tableData={totalsCombined} loading={loading} period={period} yrScenarios={yrScenarios} />
      ) : (
        <div className={`combinedBreakdown ${periodClass} ${yrScnrClass}  quarter_breakdown primary_background`}>
          {combinedTableData?.length && !loading ? (
            <>
              <ErrorBoundary fallback={<ErrorMsg />}>
                <ScrollableTitles
                  width={width}
                  period={period}
                  periodClass={periodClass}
                  yrScenarios={yrScenarios}
                  handleScroll={handleScroll}
                  yrScnrClass={yrScnrClass}
                  verts={verts}
                />
              </ErrorBoundary>
              <ErrorBoundary fallback={<ErrorMsg />}>
                <PdfView
                  period={period}
                  periodClass={periodClass}
                  totalsCombined={totalsCombined}
                  yrScnrClass={yrScnrClass}
                  yrScenarios={yrScenarios}
                  quarters={[1, 2, 3, 4]}
                />
              </ErrorBoundary>
              <div
                className={`${periodClass} ${verts ? "verts" : ""} tablescombined`}
                style={{ height: `calc(${height}px + .6rem)` }}
                onScroll={handleScroll}
              >
                <MtpCategoryTable
                  selectedref={modelTableRef}
                  tableId={`mtpModels`}
                  tableData={totalsCombined}
                  keys={yrScenarios}
                  months={period == "FY Month" ? months : quarters}
                />
                <div className={`table_2 ${periodClass} ${yrScnrClass}`} style={{ height: "calc(99% + .40rem)" }} onScroll={handleScroll}>
                  <MtpCategoryTable
                    selectedref={ref}
                    tableId={`mtpCategorycombined  ${yrScnrClass} ${periodClass}`}
                    tableData={totalsCombined}
                    keys={yrScenarios}
                    months={period == "FY Month" ? months : quarters}
                  />
                </div>
              </div>
            </>
          ) : loading ? (
            <LoadingEllipsis isLoading={loading} />
          ) : (
            <NoDataMsg message="No data available" />
          )}
        </div>
      )}
    </div>
  );
};

export default MtpCategoryTables;
