import React, { useCallback, useEffect, useMemo } from "react";
import { useExpanded, useFilters, useGlobalFilter, useTable } from "react-table";
import AddIcon from "@mui/icons-material/Add";
import { CYAN } from "../../constants";
import RemoveIcon from "@mui/icons-material/Remove";

interface Props {
  filters?: any;
  filter?: string;
  data: Array<any>;
}

const DmeoLandingPageTable = (props: Props) => {
  const { filter, filters, data } = props;

  const customGlobalFilterFunction = useCallback(
    (rows: Array<{ values: { queryColumn: string } }>, ids, query: string) => {
      if (!query) return rows;

      const expression = new RegExp(query);
      const startsWithExp = new RegExp(`^${query}`);

      switch (filters) {
        case "CONTAINS":
          return rows.filter((row) => expression.test(row.values.queryColumn));

        case "STARTS_WITH":
          return rows.filter((row) => startsWithExp.test(row.values.queryColumn));

        case "EQUAL":
          return rows.filter((row) => row.values.queryColumn === query);

        case "NOT_CONTAINS":
          return rows.filter((row) => !expression.test(row.values.queryColumn));

        case "NOT_STARTS_WITH":
          return rows.filter((row) => !startsWithExp.test(row.values.queryColumn));

        case "NOT_EQUAL":
          return rows.filter((row) => row.values.queryColumn !== query);

        case "REGEX":
          return rows.filter((row) => expression.test(row.values.queryColumn));

        case "REGEX_NO_MATCH":
          return rows.filter((row) => !expression.test(row.values.queryColumn));

        default:
          return rows;
      }
    },

    [filters]
  );

  // if column is a number, format it with commas
  const formatNumberWithCommas = (value: string) => {
    return value.toLocaleString();
  };

  const columns = useMemo(
    () => [
      {
        id: "queryColumn",
        Header: "Landing Page URL",
        accessor: "landing_page_url",
        Cell: ({ row, value }: { row: any; value: string }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <div>
              <span
                {...row.getToggleRowExpandedProps({
                  style: {
                    // We can even use the row.depth property
                    // and paddingLeft to indicate the depth
                    // of the row
                    paddingLeft: `${row.depth * 1}em`,
                  },
                })}
              >
                {row.isExpanded ? (
                  <RemoveIcon sx={{ color: CYAN, fontSize: "15px", display: "flex" }} />
                ) : (
                  <AddIcon sx={{ color: CYAN, fontSize: "15px", display: "flex" }} />
                )}
              </span>
              <div>{value}</div>
            </div>
          ) : (
            <div style={{ paddingLeft: `${row.depth * 1.5}em` }}>{value}</div>
          ),
      },
      {
        Header: "Visits",
        accessor: "visits",
        Cell: ({ value }: { value: string }) => formatNumberWithCommas(value),
      },
      {
        Header: "Single Page Visits",
        accessor: "single_page_visits",
        Cell: ({ value }: { value: string }) => formatNumberWithCommas(value),
      },
      {
        Header: "Qualified Visits",
        accessor: "qualified_visits",
        Cell: ({ value }: { value: string }) => formatNumberWithCommas(value),
      },
      {
        Header: "KBAs",
        accessor: "kbas",
        Cell: ({ value }: { value: string }) => formatNumberWithCommas(value),
      },
      {
        Header: "Leads",
        accessor: "leads",
        Cell: ({ value }: { value: string }) => formatNumberWithCommas(value),
      },
      {
        Header: "Bounce Rate",
        accessor: "bounce_rate",
        Cell: ({ value }: { value: string }) => (value ? `${value}%` : value),
      },
      {
        Header: "KBA Conversion",
        accessor: "kba_conversion_rate",
        Cell: ({ value }: { value: string }) => (value ? `${value}%` : value),
      },
      {
        Header: "Lead Conversion",
        accessor: "leads_conversion_rate",
        Cell: ({ value }: { value: string }) => (value ? `${value}%` : value),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state: { expanded },
  } = useTable(
    {
      // @ts-ignore
      columns: columns,
      data: data,
      // @ts-ignore
      globalFilter: customGlobalFilterFunction,
      getSubRows: (row: any) => row.subRows,
    },
    useFilters,
    useGlobalFilter,
    useExpanded // Use the useExpanded plugin hook
  );

  useEffect(() => {
    setGlobalFilter(filter); // Set the Global Filter to the filter prop.
  }, [filter, setGlobalFilter]);

  //Variable to check if a user has filtered records that do not exist
  const noMatchingRecords = data?.length > 1 && rows?.length === 0;

  return data.length === 0 ? (
    <p>No table data</p>
  ) : noMatchingRecords ? (
    <div>
      <p className={"filter_reset"}>There are no records matching this search criteria. Kindly update the filter selection.</p>
    </div>
  ) : (
    <table {...getTableProps()} className="landing-page-table">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} key={column.getHeaderProps().key}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={row.getRowProps().key}>
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} key={cell.getCellProps().key}>
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default DmeoLandingPageTable;
