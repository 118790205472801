import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getUsedCarsDataDefinitions } from "../../../api";

export const UsedCarsData = (): JSX.Element => {
  const [dataUpdates, setDataUpdates] = useState<Array<any>>([]);

  useEffect(() => {
    getUsedCarsDataDefinitions().then((response: any) => {
      if (response && "error" in response) {
        toast.error("Error fetching data updates");
      } else {
        setDataUpdates(response);
      }
    });
  }, []);

  return (
    <div className="data_documentation">
      <h3>CPO OPERATIONAL - DATA OVERVIEW</h3>

      <br />

      <table className="docs_table">
        <thead>
          <tr>
            <th>KPI</th>
            <th>Description</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          {dataUpdates?.map((data, index) => (
            <tr key={index}>
              <td>{data.kpi}</td>
              <td style={{ whiteSpace: "pre-wrap" }}>{data.kpi_definition}</td>
              <td>{data.source}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <br />

      <h4>
        Please note that some fields are not mandatory for dealers to fill in when registering Used Cars data. This may impact the data
        availability in CEDAR Home. For more details about the mandatory fields per market check the below table.
      </h4>
      <br />

      <table className="docs_table">
        <thead>
          <tr>
            <th>Field</th>
            <th>United Kingdom</th>
            <th>United States</th>
            <th>Thailand</th>
            <th>Belgium</th>
            <th>Canada</th>
            <th>France</th>
            <th>Germany</th>
            <th>Italy</th>
            <th>Mexico</th>
            <th>Spain</th>
            <th>Norway</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Manufacturer</td>
            <td>Y</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>Y</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>Y</td>
          </tr>
          <tr>
            <td>Model</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>Y</td>
          </tr>
          <tr>
            <td>Transmission</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>Y</td>
          </tr>
          <tr>
            <td>Primary colour</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>Y</td>
          </tr>
          <tr>
            <td>Vehicle type</td>
            <td>Y</td>
            <td>N</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>Y</td>
            <td>Y</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
          </tr>
          <tr>
            <td>First registration</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>Y</td>
          </tr>
          <tr>
            <td>Retail price</td>
            <td>Y</td>
            <td>Y</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>Y</td>
            <td>Y</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>Y</td>
          </tr>
          <tr>
            <td>Gurantee program</td>
            <td>Y</td>
            <td>N</td>
            <td>N</td>
            <td>Y</td>
            <td>Y</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>Y</td>
          </tr>
          <tr>
            <td>Mileage</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>Y</td>
            <td>Y</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>Y</td>
          </tr>
          <tr>
            <td>Images (1 image)</td>
            <td>Y</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
          </tr>
          <tr>
            <td>Registration plate number</td>
            <td>Y</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
          </tr>
          <tr>
            <td>Fuel</td>
            <td>N</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>Y</td>
          </tr>
          <tr>
            <td>Model version</td>
            <td>N</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>Y</td>
          </tr>
          <tr>
            <td>Drive train</td>
            <td>N</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>Y</td>
            <td>Y</td>
          </tr>
          <tr>
            <td>Model year</td>
            <td>N</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
          </tr>
          <tr>
            <td>Engine output</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
          </tr>
          <tr>
            <td>Model range</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
          </tr>
          <tr>
            <td>Trim line</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>Y</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
            <td>N</td>
          </tr>
        </tbody>
      </table>

      <br />
    </div>
  );
};
