import { ErrorBoundary } from "@sentry/react";
import React, { useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { getCurrencyPMO } from "../../constants";
import { ErrorMsg } from "../AppMessages";
import { DefaultTable } from "./subs";

const UsedCarsVolumeTable = (props: { tableData: any; tableId: string; selectedref?: React.MutableRefObject<any> }) => {
  const { tableData, tableId, selectedref } = props;

  const dealerParam = useSelector((state: RootStateOrAny) => state.parameters.dealer);
  const marketParam = useSelector((state: RootStateOrAny) => state.parameters.market);
  const brandParam = useSelector((state: RootStateOrAny) => state.parameters.brand);

  const extraClass = useMemo(() => {
    const text = "cpo_operational_table";
    return text;
  }, [tableId]);

  const cols: any[] | undefined = useMemo(() => {
    return [
      {
        id: "dealer",
        Header: dealerParam == "All" && (marketParam == "Canada" || marketParam == "United States") ? "Region" : "Dealer",
        accessor: `${
          dealerParam == "All" && (marketParam == "Canada" || (marketParam == "United States" && brandParam == "Nissan"))
            ? "region"
            : dealerParam == "All"
            ? "market"
            : "dealer"
        }`,
        canSort: false,
        defaultCanSort: false,
        sortType: "alphanumericIgnoreCase",
      },
      {
        id: `inventory_volume`,
        Header: `Inventory volume`,
        accessor: `inventory_volume`,
        defaultCanSort: true,
        defaultSortDesc: true,
        sortType: "alphanumericCustom",
        Cell: (cell: { value: number }) => {
          const { value } = cell;

          return value?.toLocaleString();
        },
      },
      {
        id: `days_in_stock`,
        Header: "Avg days in stock",
        accessor: `avg_days_in_stock`,
        defaultCanSort: true,
        defaultSortDesc: true,
        sortType: "alphanumericCustom",
        Cell: (cell: { value: number }) => {
          const { value } = cell;

          return value?.toLocaleString();
        },
      },
      {
        id: `sales_volume`,
        Header: "Sales volume",
        accessor: `sales_volume`,
        defaultCanSort: true,
        defaultSortDesc: true,
        sortType: "alphanumericCustom",
        Cell: (cell: { value: number }) => {
          const { value } = cell;

          return value?.toLocaleString();
        },
      },
      {
        id: `sales_revenue`,
        Header: "Sales revenue",
        accessor: `sales_revenue`,
        defaultCanSort: true,
        defaultSortDesc: true,
        sortType: "alphanumericCustom",
        Cell: (cell: { value: number }) => {
          const { value } = cell;

          return value ? `${getCurrencyPMO(marketParam)}${value?.toLocaleString()}` : "";
        },
      },
      {
        id: `average_list_price`,
        Header: "Avg list price",
        accessor: `avg_list_price`,
        defaultCanSort: true,
        defaultSortDesc: true,
        sortType: "alphanumericCustom",
        Cell: (cell: { value: number }) => {
          const { value } = cell;

          return value ? `${getCurrencyPMO(marketParam)}${value?.toLocaleString()}` : "";
        },
      },
      {
        id: `avg_sales_price`,
        Header: "Avg sales price",
        accessor: `avg_sales_price`,
        defaultCanSort: true,
        defaultSortDesc: true,
        sortType: "alphanumericCustom",
        Cell: (cell: { value: number }) => {
          const { value } = cell;

          return value ? `${getCurrencyPMO(marketParam)}${value?.toLocaleString()}` : "";
        },
      },
    ];
  }, [dealerParam]);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <>
        {tableData?.length && (
          <>
            <DefaultTable
              columns={cols}
              data={tableData}
              id={"dealer_breakdown"}
              // @ts-ignore
              data-test-id={"dealer_breakdown"}
              isChildComponent={false}
              initialSortColId={{ id: "", desc: false }}
              hiddenCols={[]}
              extraClass={extraClass}
              parentColumns={[]}
              selectedref={selectedref}
              manualSortBy={true}
            />
          </>
        )}
      </>
    </ErrorBoundary>
  );
};

export default UsedCarsVolumeTable;
