import React, { useCallback, useContext, useEffect, useState } from "react";
import { DefaultFilter } from "../../../../components/Filters/common";
import { eventTracking, MixpanelEvents } from "../../../../utils/userTracking";
import { closeFilterOptions } from "../../../../components/Filters/subs/helpers";
import { Tooltip } from "react-tippy";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getPerformanceByMarketMap } from "../../../../api/quickVocSalesApi";
import { RootStateOrAny, useSelector } from "react-redux";
import { ThemeContext } from "../../../../context";
import { NoDataMsg } from "../../../../components/AppMessages";
import moment from "moment";
import PerformanceByMarketMapChart from "../../../../components/Charts/QuickVocSales/PerformanceByMarketMapChart";
import { DATE_FILTER_VALUE_MAPPING } from "../../../../constants";

interface Props {
  queryFn: (metric?: string) => Record<string, any>;
  metrics?: Array<string>;
  metricMapping?: Record<string, string>;
}

const PerformanceByMarket = withRouter((props: RouteComponentProps & Props) => {
  const { history, queryFn, metrics, metricMapping } = props;
  const { theme } = useContext(ThemeContext);
  const { date_range } = useSelector((state: RootStateOrAny) => state.parameters);
  const [metricFilterValue, setMetricFilterValue] = useState(metrics ? metrics[0] : "");

  const [dateString, setDateString] = useState("");

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["performanceByMarketMapData", metricFilterValue, history.location.search],
    queryFn: () => (metrics && metricMapping ? queryFn(metricMapping[metricFilterValue]) : queryFn()),
    enabled: false,
  });

  useEffect(() => {
    refetch();
  }, [history.location.search, metricFilterValue]);

  useEffect(() => {
    if (Object.keys(DATE_FILTER_VALUE_MAPPING).includes(date_range)) {
      setDateString(DATE_FILTER_VALUE_MAPPING[date_range]);
    } else {
      const [start, end] = date_range.split("-");
      const startDate = moment(start, "MMMM YYYY").format("MMM YYYY");
      const endDate = moment(end, "MMMM YYYY").format("MMM YYYY");
      setDateString(`${startDate} - ${endDate}`);
    }
  }, [date_range]);

  const handleFilterOptionClick = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      setMetricFilterValue(optionValue);
      eventTracking(MixpanelEvents.filter_change, { filter: filterName, value: optionValue, dashboard: "Quick VOC Sales" });
    }
    closeFilterOptions();
  }, []);

  return (
    <div className="tile performance-by-market">
      <div className="tile-header">
        <div className="title-container">
          <div className="title">
            <span>Performance by market</span>
            {metrics && <span>(for selected metric)</span>}
          </div>
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title="Performance by market shows the performance for each market based on the metric selected and on top filters as well."
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        </div>
        <div className="filters-container">
          {metrics && (
            <DefaultFilter
              list={metrics}
              filterName={"metric"}
              filterLabel={"METRIC FILTER BY"}
              filterValue={metricFilterValue}
              handleFilterOptionClick={handleFilterOptionClick}
            />
          )}
        </div>
      </div>
      {data && data.length > 0 ? (
        <PerformanceByMarketMapChart
          chartId="performanceByMarketMapChart"
          data={data}
          isLoading={isFetching}
          currentPeriodLabel={dateString}
          metric={metricFilterValue}
          theme={theme}
          showVolume={true}
        />
      ) : (
        <NoDataMsg />
      )}
    </div>
  );
});

export default PerformanceByMarket;
