import { ErrorBoundary } from "@sentry/react";
import _ from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FETCH_USER_DETAILS } from "../../actions/actionTypes";
import { updateUserDetails } from "../../api";
import { IUserDetails } from "../../constants/interface";
import { ThemeContext } from "../../context";
import { ErrorMsg } from "../AppMessages";
import { BusinessFunctionInput, FormsToggleSwitch, TextInput } from "./common";
import { SubmitBtn } from "./common/Buttons";
import { DepartmentInput } from "./common/DepartmentInput";

interface Props {
  user: IUserDetails;
}

export const UserProfileForm = (props: Props) => {
  const dispatch = useDispatch();

  const { user } = props;

  const { setTheme } = useContext(ThemeContext);

  const [formKey, setFormKey] = useState(0);

  const [userDraft, setuserDraft] = useState(user);
  const [updateDisabled, setupdateDisabled] = useState(true);
  const [businessFunction, setBusinessFunction] = useState("");
  const [department, setDepartment] = useState("");

  useEffect(() => {
    setupdateDisabled(_.isEqual(user, userDraft));
  }, [userDraft, user]);

  useEffect(() => {
    setuserDraft(user);
    setBusinessFunction(user.business_function ? user.business_function : "");
    setDepartment(user.department ? user.department : "");
  }, [user]);

  useEffect(
    () => setuserDraft((prevState) => ({ ...prevState, business_function: businessFunction, department: department })),
    [businessFunction, department]
  );

  const handleUserProfileFormSubmit = useCallback(
    () =>
      updateUserDetails(user.email, userDraft).then((response) => {
        if (typeof response !== "string")
          if ("error" in response) {
            toast.error(response.error);
          } else {
            toast.success("Profile updated successfull");
            if ("dark_mode" in response) response.dark_mode ? setTheme("dark") : setTheme("light");

            dispatch({ type: FETCH_USER_DETAILS, payload: response.email });
            setFormKey(formKey + 1);
          }
      }),
    [user, userDraft]
  );

  const handleInputChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = evt;
    setuserDraft({ ...userDraft, [name]: value });
  };

  const handleToggleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name },
    } = evt;

    setuserDraft((prevState) => ({ ...userDraft, [name]: !prevState[name] }));
  };

  return (
    <ErrorBoundary fallback={<ErrorMsg class={"formError"} />}>
      <form
        id="profile_form"
        className="profile_settings"
        name="profile_form"
        method="POST"
        data-test-id="profile_form"
        onSubmit={(event) => {
          event.preventDefault();
          return handleUserProfileFormSubmit();
        }}
        key={formKey}
      >
        <div className="profile_body">
          <div className="profile_section" id="myProfile">
            <div className="profile_section__header">
              <div className="profile_section__header--icon"></div>
              <div className="profile_section__header--title">MY PROFILE</div>
            </div>
            <div className="profile_section__body">
              <TextInput
                label="Firstname"
                name="first_name"
                value={userDraft.first_name}
                id={"firstname"}
                pattern="[a-zA-Z]+"
                onChange={handleInputChange}
              />
              <TextInput
                label="Lastname"
                name="last_name"
                value={userDraft.last_name}
                id={"lastname"}
                pattern="[a-zA-Z]+"
                onChange={handleInputChange}
              />
              <BusinessFunctionInput businessFunction={businessFunction} setBusinessFunction={setBusinessFunction} />
              <DepartmentInput department={department} setDepartment={setDepartment} />
              {department == "Other" && (
                <TextInput
                  label="Other department"
                  name="other_department"
                  onChange={handleInputChange}
                  value={userDraft.other_department}
                  pattern="[a-zA-Z]+"
                  id="other_department"
                />
              )}
              <TextInput label="Role" name="role" onChange={handleInputChange} value={userDraft.role} pattern="[a-zA-Z]+" id="role" />
              {/* <GeographicalScopeInput scope={scope} setScope={setScope} /> */}
            </div>
          </div>
          <div className="profile_section_gap" />

          <div className="profile_section" id="myPreferences">
            <div className="profile_section__header">
              <div className="profile_section__header--icon"></div>
              <div className="profile_section__header--title">MY PREFERENCES</div>
            </div>
            <div className="profile_section__body">
              {/* @ts-ignore */}
              <input type="text" name="dark_mode" id="dark_mode" value={userDraft.dark_mode} onChange={() => {}} hidden />
              <FormsToggleSwitch
                label="Theme"
                name="dark_mode"
                onChange={handleToggleChange}
                checked={userDraft.dark_mode}
                id="dark_mode"
                leftLabel={"Light"}
                rightLabel={"Dark"}
              />
            </div>
          </div>
        </div>
        <SubmitBtn id="updateProfile" value="update" disabled={updateDisabled} />
      </form>
    </ErrorBoundary>
  );
};
