import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setSpecificParameter } from "../../../actions";
import { ToggleSwitch } from "../../../components/Toogle";
import CombinedTitles from "../SrategicDealerSupport/components/CombinedTItles";
import TableBreakDown from "./TableBreakDown";

interface Props {
  q1TableData: any;
  q2TableData: any;
  q3TableData: any;
  q4TableData: any;
  fullYearTableData: any;
  combinedTableData: any;
  q1Totals: any;
  q2Totals: any;
  q3Totals: any;
  q4Totals: any;
  fullYearTotals: any;
  combinedTotals: any;
  loading: boolean;
  sortedYrScenarios: string[];
  extraClass?: string;
  hasCategoryToggle?: boolean;
}

const MidasShareTables = (props: Props) => {
  const screenWidth = window.matchMedia("(max-width: 768px)");
  const {
    q1TableData,
    q2TableData,
    q3TableData,
    q4TableData,
    fullYearTableData,
    combinedTableData,
    q1Totals,
    q2Totals,
    q3Totals,
    q4Totals,
    fullYearTotals,
    combinedTotals,
    loading,
    sortedYrScenarios,
    extraClass,
    hasCategoryToggle,
  } = props;

  const [showTouchPoints, setShowTouchpoints] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSpecificParameter("show_touchpoints", showTouchPoints));
  }, [showTouchPoints]);

  return (
    <div className={`media_mix_tables ${extraClass} strategic_tables primary_background ${screenWidth.matches ? "" : "combined"}`}>
      {hasCategoryToggle && (
        <div className="toogle_container">
          <p>See:</p>
          <ToggleSwitch
            activeToggleLabel={"FMI category"}
            inactiveToggleLabel={"Touchpoint"}
            active={!showTouchPoints}
            handleToggleClick={() => setShowTouchpoints(!showTouchPoints)}
            toggleClassName={"midas_toggle"}
          />
        </div>
      )}
      {screenWidth.matches ? (
        <>
          <TableBreakDown
            quarters={[1]}
            quarterTitle="Q1"
            data={q1TableData}
            loading={loading}
            yrScenarios={sortedYrScenarios}
            totals={q1Totals}
            extraClass={extraClass}
          />
          <TableBreakDown
            quarters={[2]}
            quarterTitle="Q2"
            data={q2TableData}
            loading={loading}
            yrScenarios={sortedYrScenarios}
            totals={q2Totals}
            extraClass={extraClass}
          />
          <TableBreakDown
            quarters={[3]}
            quarterTitle="Q3"
            data={q3TableData}
            loading={loading}
            yrScenarios={sortedYrScenarios}
            totals={q3Totals}
            extraClass={extraClass}
          />
          <TableBreakDown
            quarters={[4]}
            quarterTitle="Q4"
            data={q4TableData}
            loading={loading}
            yrScenarios={sortedYrScenarios}
            totals={q4Totals}
            extraClass={extraClass}
          />
          <TableBreakDown
            quarterTitle="Full Year"
            data={fullYearTableData}
            loading={loading}
            yrScenarios={sortedYrScenarios}
            totals={fullYearTotals}
            quarters={[0]}
            extraClass={extraClass}
          />
        </>
      ) : (
        <div className={`combinedBreakdown quarter_breakdown primary_background ${sortedYrScenarios?.length == 1 ? "one_yr_scnr" : ""}`}>
          {!loading && <CombinedTitles yrScenarios={sortedYrScenarios} />}
          <TableBreakDown
            quarterTitle=""
            data={combinedTableData}
            loading={loading}
            yrScenarios={sortedYrScenarios}
            totals={combinedTotals}
            quarters={[1, 2, 3, 4, 0]}
            extraClass={extraClass}
          />
        </div>
      )}
    </div>
  );
};

export default MidasShareTables;
