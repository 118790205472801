import React, { useCallback, useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import PageOverallPerformanceChart from "../../../components/Charts/ProductPerformance/PageOverallPerformanceChart";
import Ratings from "../../../components/Ratings/Ratings";
import { ThemeContext } from "../../../context";
import { getGranularityFromDate, getValueOrNa, getValueOrNull } from "../../../utils/utilityFunctions";

const PoPLabelMapping: Record<string, string> = {
  weekly: "WoW",
  monthly: "MoM",
  quarterly: "QoQ",
  yearly: "YoY",
};

const devices = ["desktop", "mobile"];

const PageOverallPerformance = () => {
  const themeContext = useContext(ThemeContext);

  const pageOverallPerformanceBreakdownData = useSelector((state: RootStateOrAny) => state.product_performance.page_performance_breakdown);
  const pageOverallPerformanceAvgData = useSelector((state: RootStateOrAny) => state.product_performance.page_performance);
  const { page_performance_breakdown: isLoading } = useSelector((state: RootStateOrAny) => state.loading);
  const granularityParam = useSelector((state: RootStateOrAny) => state.parameters.granularity);
  const { date_range: dateRangeParam } = useSelector((state: RootStateOrAny) => state.parameters);

  const PoPLabel = useMemo(() => {
    const dateGranularity = getGranularityFromDate(dateRangeParam);
    return PoPLabelMapping[dateGranularity];
  }, [dateRangeParam]);

  const getDeviceData = useCallback(
    (device) => {
      if (
        pageOverallPerformanceBreakdownData.Current &&
        pageOverallPerformanceBreakdownData.YoY &&
        pageOverallPerformanceBreakdownData.PoP
      ) {
        const currentData = pageOverallPerformanceBreakdownData.Current.filter((data: any) => data.viewport == device);
        const yoyData = pageOverallPerformanceBreakdownData.YoY.filter((data: any) => data.viewport == device);
        const popData = pageOverallPerformanceBreakdownData.PoP.filter((data: any) => data.viewport == device);

        return {
          Current: currentData,
          YoY: PoPLabel == "YoY" ? yoyData : popData,
        };
      }

      return {
        Current: [],
        YoY: [],
      };
    },
    [pageOverallPerformanceBreakdownData, PoPLabel]
  );

  const getAvgFilteredData = useCallback(
    (device) => {
      if (pageOverallPerformanceAvgData.Current && pageOverallPerformanceAvgData.YoY && pageOverallPerformanceAvgData.PoP) {
        const currentData = pageOverallPerformanceAvgData.Current.find((data: any) => data.viewport == device);
        const yoyData = pageOverallPerformanceAvgData.YoY.find((data: any) => data.viewport == device);
        const popData = pageOverallPerformanceAvgData.PoP.find((data: any) => data.viewport == device);

        return {
          Current: currentData,
          YoY: PoPLabel == "YoY" ? yoyData : popData,
        };
      }

      return {
        Current: null,
        YoY: null,
      };
    },
    [pageOverallPerformanceAvgData, PoPLabel]
  );

  return (
    <>
      <div className="tile-header justify-content-left">PAGE OVERALL PERFORMANCE</div>
      {devices.map((device) => {
        const avgData = getAvgFilteredData(device);
        const chartData = getDeviceData(device);

        return (
          <div className="page-overall-performance" key={device}>
            <div className="device-container">
              <p className="device">{device.toLocaleUpperCase()}</p>
              <p>({getGranularityFromDate(dateRangeParam)} average)</p>
            </div>
            <div className="comparison-container">
              <div className="avg-value">{getValueOrNa(avgData.Current, "overall_performance")}</div>
              <div className="comparison">
                <Ratings value={getValueOrNull(avgData.YoY, "overall_performance")} isPercentageValue={false} />
                <span className="comparison_value">{getValueOrNa(avgData.YoY, "pts_overall_performance")}</span>
                <span className="comparison_label">{PoPLabel}</span>
              </div>
            </div>
            <div className="full-width hull-height">
              <PageOverallPerformanceChart
                chartId={`${device}PageOverallPerformanceChart`}
                theme={themeContext.theme}
                data={chartData}
                isLoading={isLoading}
                granularity={granularityParam}
                seriesList={["Selected period", "Previous period"]}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default PageOverallPerformance;
