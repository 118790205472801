export const VOC_BTN_FILTERS = [
  { id: "website_btn", name: "Website", navigateTo: "/voc/website" },
  { id: "ccs_btn", name: "CCS Vital Signs", navigateTo: "/ccs_vital_signs/app_ratings" },
  { id: "quick_voc_sales_btn", name: "Quick VOC Sales", navigateTo: "/voc/quick_voc_sales" },
  { id: "quick_voc_after_sales_btn", name: "Quick VOC After Sales", navigateTo: "/voc/quick_voc_after_sales" },
  { id: "cec_voc_btn", name: "CEC VOC", navigateTo: "/voc/cec_voc" },
  { id: "voc_rsa_btn", name: "VOC RSA", navigateTo: "/voc/voc_rsa" },
  { id: "google_rating_btn", name: "Google Rating", navigateTo: "/voc/google_rating" },
  { id: "some_btn", name: "SoMe", navigateTo: "/voc/some" },
  { id: "customer_journey_voc_btn", name: "Customer Journey VOC", navigateTo: "/voc/customer_journey_voc" },
];

export const TOP_CATEGORIES = ["overall rating", "people stated they reached their objective on the website", "vlp"];
