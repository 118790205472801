import { Brand, Channel, ChannelBreakdown, Market, Metric, Model, Region, View } from "../constants/interface/admin/common";
import {
  FETCH_ADMIN_BRANDS,
  FETCH_ADMIN_CHANNELS_DATA,
  FETCH_ADMIN_CHANNEL_BREAKDOWNS_DATA,
  FETCH_ADMIN_MARKETS,
  FETCH_ADMIN_METRICS_DATA,
  FETCH_ADMIN_NEW_MODELS,
  FETCH_ADMIN_REGIONS,
  FETCH_ADMIN_VIEWS,
  SET_ADMIN_BRANDS,
  SET_ADMIN_CHANNELS_DATA,
  SET_ADMIN_CHANNEL_BREAKDOWNS_DATA,
  SET_ADMIN_MARKETS,
  SET_ADMIN_METRICS_DATA,
  SET_ADMIN_NEW_MODELS,
  SET_ADMIN_REGIONS,
  SET_ADMIN_VIEWS,
} from "./actionTypes";

export const fetchAdminBrandsData = () => ({ type: FETCH_ADMIN_BRANDS });
export const setAdminBrandsData = (data: Brand) => ({ type: SET_ADMIN_BRANDS, payload: data });

export const fetchAdminRegionsData = () => ({ type: FETCH_ADMIN_REGIONS });
export const setAdminRegionsData = (data: Region) => ({ type: SET_ADMIN_REGIONS, payload: data });

export const fetchAdminMarketsData = () => ({ type: FETCH_ADMIN_MARKETS });
export const setAdminMarketsData = (data: Market) => ({ type: SET_ADMIN_MARKETS, payload: data });

export const fetchAdminViewsData = () => ({ type: FETCH_ADMIN_VIEWS });
export const setAdminViewsData = (data: View) => ({ type: SET_ADMIN_VIEWS, payload: data });

export const fetchAdminModelsData = () => ({ type: FETCH_ADMIN_NEW_MODELS });
export const setAdminModelsData = (data: Model) => ({ type: SET_ADMIN_NEW_MODELS, payload: data });

export const fetchAdminChannelsData = () => ({ type: FETCH_ADMIN_CHANNELS_DATA });
export const setAdminChannelsData = (data: Channel) => ({ type: SET_ADMIN_CHANNELS_DATA, payload: data });

export const fetchAdminChannelBreakdownsData = () => ({ type: FETCH_ADMIN_CHANNEL_BREAKDOWNS_DATA });
export const setAdminChannelBreakdownsData = (data: ChannelBreakdown) => ({ type: SET_ADMIN_CHANNEL_BREAKDOWNS_DATA, payload: data });

export const fetchAdminMetricsData = () => ({ type: FETCH_ADMIN_METRICS_DATA });
export const setAdminMetricsData = (data: Metric) => ({ type: SET_ADMIN_METRICS_DATA, payload: data });
