import { put, retry, takeLatest } from "@redux-saga/core/effects";
import {
  FETCH_IMPRESSIONS_BREAKDOWN,
  FETCH_IMPRESSIONS_OVERVIEW,
  FETCH_VISITORS_COUNTRY,
  FETCH_VISITS_BREAKDOWN,
  FETCH_VISITS_OVERVIEW,
} from "../actions/actionTypes";
import { setLoading } from "../actions/loadingActions";
import {
  setImpressionsBreakdown,
  setImpressionsOverview,
  setVisitorsCountry,
  setVisitsBreakdown,
  setVisitsOverview,
} from "../actions/seoActions";
import {
  getSeoImpressionsBreakdownData,
  getSeoImpressionsOverviewData,
  getSeoVisitorsCountry,
  getSeoVisitsBreakdownData,
  getSeoVisitsOverviewData,
} from "../api/seoApi";
import { secondsDelay } from "../api/utils";

export function* watchFetchVisitsOverviewSaga() {
  yield takeLatest(FETCH_VISITS_OVERVIEW, fetchVisitsOverviewSaga);
}

export function* watchFetchVisitsBreakdownSaga() {
  yield takeLatest(FETCH_VISITS_BREAKDOWN, fetchVisitsBreakdownSaga);
}

export function* watchFetchImpressionsOverviewSaga() {
  yield takeLatest(FETCH_IMPRESSIONS_OVERVIEW, fetchImpressionsOverviewSaga);
}

export function* watchFetchImpressionsBreakdownSaga() {
  yield takeLatest(FETCH_IMPRESSIONS_BREAKDOWN, fetchImpressionsBreakdownSaga);
}

export function* watchFetchVisitorsCountrySaga() {
  yield takeLatest(FETCH_VISITORS_COUNTRY, fetchVisitorsCountrySaga);
}

export function* fetchVisitsOverviewSaga() {
  try {
    yield put(setLoading("visits_overview", true));
    const response: Array<Record<string | number, string>> = yield retry(4, secondsDelay, getSeoVisitsOverviewData);
    if (!("error" in response)) {
      yield put(setVisitsOverview(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("visits_overview", false));
  }
}

export function* fetchVisitsBreakdownSaga() {
  try {
    yield put(setLoading("visits_breakdown", true));
    const response: Array<Record<string | number, string>> = yield retry(4, secondsDelay, getSeoVisitsBreakdownData);
    if (!("error" in response)) {
      yield put(setVisitsBreakdown(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("visits_breakdown", false));
  }
}

export function* fetchImpressionsOverviewSaga() {
  try {
    yield put(setLoading("impressions_overview", true));
    const response: Array<Record<string | number, string>> = yield retry(4, secondsDelay, getSeoImpressionsOverviewData);
    if (!("error" in response)) {
      yield put(setImpressionsOverview(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("impressions_overview", false));
  }
}

export function* fetchImpressionsBreakdownSaga() {
  try {
    yield put(setLoading("impressions_breakdown", true));
    const response: Array<Record<string | number, string>> = yield retry(4, secondsDelay, getSeoImpressionsBreakdownData);
    if (!("error" in response)) {
      yield put(setImpressionsBreakdown(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("impressions_breakdown", false));
  }
}
export function* fetchVisitorsCountrySaga() {
  try {
    yield put(setLoading("visitors_country", true));
    const response: Array<Record<string, string>> = yield retry(4, secondsDelay, getSeoVisitorsCountry);
    if (!("error" in response)) {
      yield put(setVisitorsCountry(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("visitors_country", false));
  }
}
