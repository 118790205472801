import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { fetchEngagementAppDownloadsChartData, fetchEngagementAppDownloadsData } from "../../../../actions/ccsCockpitActions";
import { ErrorMsg } from "../../../../components/AppMessages";
import { ToggleSwitch } from "../../../../components/Toogle";
import { CockpitContext } from "../../subs/CockpitContext";
import { ActiveUsersTile, CockpitActivationRatioTile, DisabledTile, SoldEligibleTile, ValueTile } from "../tiles";

interface Props extends RouteComponentProps {
  showMetric?: boolean;
}

export const EngagementTiles = withRouter((props: Props) => {
  const { showMetric, history } = props;
  const dispatch = useDispatch();
  const { setMetric } = useContext(CockpitContext);

  const [title, setTitle] = useState("");

  const {
    date_range: dateParamValue,
    region: regionParamValue,
    market: marketParamValue,
    brand: brandParamValue,
    model: modelParamValue,
  } = useSelector((state: RootStateOrAny) => state.parameters);
  const { overall } = useSelector((state: RootStateOrAny) => state.europe_activation.overall_data);
  const { g8_private_activation_ratio: cockpitActivationLoading } = useSelector((state: RootStateOrAny) => state.loading);
  const { Current: currentAppDownloads, YoY: yoyAppDownloads } = useSelector(
    (state: RootStateOrAny) => state.ccs_cockpit.engagement_downloads
  );
  const { Current: currentAppDownloadsChartData } = useSelector(
    (state: RootStateOrAny) => state.ccs_cockpit.engagement_downloads_chart_data
  );

  const { Current: currentUserEngagementData, YoY: yoyUserEngagementData } = useSelector(
    (state: RootStateOrAny) => state.ccs_cockpit.user_engagement
  );

  const { Current: currentUserEngagementChartData } = useSelector((state: RootStateOrAny) => state.ccs_cockpit.user_engagement_chart_data);
  const {
    user_engagement: userEngagemntDataLoading,
    enrolled_customers: enrolledCustomersLoading,
    engagement_downloads: appDownloadsLoading,
  } = useSelector((state: RootStateOrAny) => state.loading);
  const { Current: currentEnrolledCustomersData, YoY: yoyEnrolledCustomersData } = useSelector(
    (state: RootStateOrAny) => state.ccs_cockpit.enrolled_customers
  );
  const { Current: currentEnrolledCustomersChartData } = useSelector(
    (state: RootStateOrAny) => state.ccs_cockpit.enrolled_customers_chart_data
  );
  const { Current: currentG8PrivateActivationData, YoY: yoyG8PrivateActivationData } = useSelector(
    (state: RootStateOrAny) => state.ccs_cockpit.g8_private_activation_ratio_data
  );
  const { Current: currentG8PrivateActivationChartData } = useSelector(
    (state: RootStateOrAny) => state.ccs_cockpit.g8_private_activation_ratio_chart_data
  );

  // const [comparisonTotals, setComparisonTotals] = useState(totalData);
  const [cumulativeEnrolledToggleActive, setCumulativeEnrolledToggleActive] = useState(true);
  const [cumulativeAppDownloadsToggleActive, setCumulativeAppDownloadsToggleActive] = useState(true);

  useEffect(() => {
    Promise.all([dispatch(fetchEngagementAppDownloadsData()), dispatch(fetchEngagementAppDownloadsChartData())]);
  }, [history.location.search]);

  useEffect(() => {
    setTitle("G8 private activation ratio");
  }, [regionParamValue, marketParamValue]);

  const [isSingleYear, setIsSingleYear] = useState<boolean>(false);
  const [isTotal, setIsTotal] = useState(regionParamValue === "North America" ? false : true);
  useEffect(() => {
    setIsTotal(regionParamValue === "North America" ? false : true);
  }, [regionParamValue, marketParamValue]);

  const formattedAppDownloadsChartData = useMemo(() => {
    if (currentAppDownloadsChartData) {
      return currentAppDownloadsChartData.reduce((result: Array<any>, row: any) => {
        const { date } = row;
        const label = moment(date).format("MMM YY");

        return [...result, { label, ...row }];
      }, []);
    }
    return [];
  }, [currentAppDownloadsChartData]);

  const formattedEnrolledCustomersChartData = useMemo(() => {
    if (currentEnrolledCustomersChartData) {
      return currentEnrolledCustomersChartData.reduce((result: Array<any>, row: any) => {
        const { date } = row;
        const label = moment(date).format("MMM YY");

        return [...result, { label, ...row }];
      }, []);
    }
    return [];
  }, [currentEnrolledCustomersChartData]);

  const formattedG8ActivationPrivateActivationChartData = useMemo(() => {
    if (currentG8PrivateActivationChartData) {
      return currentG8PrivateActivationChartData.reduce((result: Array<any>, row: any) => {
        const { date } = row;
        const label = moment(date).format("MMM YY");

        return [...result, { label, ...row }];
      }, []);
    }
    return [];
  }, [currentG8PrivateActivationChartData]);

  const renderCumulativeEnrolledToggle = (toggleState: boolean, setToogleState: Dispatch<SetStateAction<boolean>>) => (
    <ToggleSwitch
      activeToggleLabel={"New enrolled customers"}
      inactiveToggleLabel={"Cumulative enrolled customers"}
      active={toggleState}
      handleToggleClick={() => setToogleState(!toggleState)}
      toggleClassName={"dp_toggle cockpit_toggle"}
    />
  );

  const renderCumulativeAppDownloadsToggle = (toggleState: boolean, setToogleState: Dispatch<SetStateAction<boolean>>) => (
    <ToggleSwitch
      activeToggleLabel={"New app downloads"}
      inactiveToggleLabel={"Cumulative app downloads"}
      active={toggleState}
      handleToggleClick={() => setToogleState(!toggleState)}
      toggleClassName={"dp_toggle cockpit_toggle"}
    />
  );

  return (
    <div className={`section_content ${showMetric ? "isMetric" : ""}`}>
      {regionParamValue == "China" ? (
        <DisabledTile title={"App downloads"} disableMessage={""} onClick={() => setMetric("Customer quality")} />
      ) : (
        <ErrorBoundary fallback={<ErrorMsg />}>
          <ValueTile
            title={"App downloads"}
            value={
              currentAppDownloads
                ? cumulativeAppDownloadsToggleActive
                  ? currentAppDownloads[0]?.app_downloads
                  : currentAppDownloads[0]?.app_downloads_cumulative
                : null
            }
            isLoading={appDownloadsLoading}
            comparisonPercentageValue={
              yoyAppDownloads
                ? cumulativeAppDownloadsToggleActive
                  ? yoyAppDownloads[0]?.perc_app_downloads
                  : yoyAppDownloads[0]?.perc_app_downloads_cumulative
                : null
            }
            chartData={showMetric ? formattedAppDownloadsChartData : undefined}
            chartDataKey={cumulativeAppDownloadsToggleActive ? "app_downloads" : "app_downloads_cumulative"}
            data-target="engagement"
            onClick={() => setMetric("Engagement")}
            toggle={renderCumulativeAppDownloadsToggle}
            toggleActive={cumulativeAppDownloadsToggleActive}
            setToggleActive={setCumulativeAppDownloadsToggleActive}
          />
        </ErrorBoundary>
      )}

      <ErrorBoundary fallback={<ErrorMsg />}>
        <ActiveUsersTile
          value={
            currentUserEngagementData
              ? isTotal
                ? currentUserEngagementData[0]?.active_user_avg
                : currentUserEngagementData[0]?.ppu_active_user_avg
              : null
          }
          isLoading={userEngagemntDataLoading}
          comparisonValue={
            yoyUserEngagementData
              ? isTotal
                ? yoyUserEngagementData[0]?.active_user_avg
                : yoyUserEngagementData[0]?.ppu_active_user_avg
              : null
          }
          comparisonPercentageValue={
            yoyUserEngagementData
              ? isTotal
                ? yoyUserEngagementData[0]?.perc_active_user_avg
                : yoyUserEngagementData[0]?.perc_ppu_active_user_avg
              : null
          }
          bpComparisonValue={!isTotal || regionParamValue == "Europe" ? null : null}
          userEngagement={
            currentUserEngagementData
              ? isTotal
                ? currentUserEngagementData[0].user_engagement_avg
                : currentUserEngagementData[0]?.ppu_user_engagement_avg
              : null
          }
          totalEnrollment={
            currentUserEngagementData
              ? isTotal
                ? currentUserEngagementData[0]?.cumulative_enrolled_avg
                : currentUserEngagementData[0]?.ppu_cumulative_enrolled_avg
              : null
          }
          comparisonUserEngagement={
            yoyUserEngagementData
              ? isTotal
                ? yoyUserEngagementData[0]?.pts_user_engagement_avg
                : yoyUserEngagementData[0]?.pts_ppu_user_engagement_avg
              : null
          }
          chartData={{
            data: showMetric && currentUserEngagementChartData ? currentUserEngagementChartData : undefined,
            chartId: "active_userChart",
          }}
          data-target="engagement"
          onClick={() => setMetric("Engagement")}
          isTotal={isTotal}
          setIsTotal={setIsTotal}
          showMetric={showMetric}
          isSingleYear={isSingleYear}
        />
      </ErrorBoundary>

      {regionParamValue == "China" ? (
        <DisabledTile
          title={showMetric ? "Total warranty start" : title}
          disableMessage={""}
          onClick={() => setMetric("Customer quality")}
        />
      ) : (
        <ErrorBoundary fallback={<ErrorMsg />}>
          <CockpitActivationRatioTile
            isActivation={true}
            showMetric={showMetric}
            title={showMetric ? "Total warranty start" : title}
            value={currentG8PrivateActivationData ? currentG8PrivateActivationData[0]?.total_warranty_start : null}
            bpComparisonValue={
              currentG8PrivateActivationData ? currentG8PrivateActivationData[0]?.bp_g8_private_activation_ratio_total : null
            }
            isLoading={cockpitActivationLoading}
            comparisonValue={overall ? overall[0]?.total_warranty_start_comparison : null}
            soldEligible={currentG8PrivateActivationData ? currentG8PrivateActivationData[0]?.rate : null}
            comparisonSoldEligible={yoyG8PrivateActivationData ? yoyG8PrivateActivationData[0]?.pts_rate : null}
            comparisonPercentageValue={yoyG8PrivateActivationData ? yoyG8PrivateActivationData[0]?.perc_total_warranty_start : null}
            chartData={{
              data: showMetric ? formattedG8ActivationPrivateActivationChartData : undefined,
              chartId: "activation_ratio_chart",
              barSeriesName: "Total Warranty Start",
              barDataKey: "total",
              lineDataKey: "rate",
            }}
            tooltip={"The last 3 months data represents the activation ratio based on the latest report available and is likely to change"}
            data-target="engagement"
            onClick={() => setMetric("Engagement")}
            isSingleYear={isSingleYear}
          />
        </ErrorBoundary>
      )}

      <ErrorBoundary fallback={<ErrorMsg />}>
        <SoldEligibleTile
          title={"Enrolled customers"}
          value={
            currentEnrolledCustomersData
              ? cumulativeEnrolledToggleActive
                ? currentEnrolledCustomersData[0]?.total_enrolled
                : currentEnrolledCustomersData[0]?.cumulative_enrolled_avg
              : null
          }
          bpComparisonValue={currentEnrolledCustomersData ? currentEnrolledCustomersData[0]?.bp_enrollement_rate_total : null}
          isLoading={enrolledCustomersLoading}
          comparisonValue={
            yoyEnrolledCustomersData
              ? cumulativeEnrolledToggleActive
                ? yoyEnrolledCustomersData[0]?.total_enrolled
                : yoyEnrolledCustomersData[0]?.cumulative_enrolled_avg
              : null
          }
          soldEligible={currentEnrolledCustomersData ? currentEnrolledCustomersData[0]?.total_connected_cars : null}
          comparisonSoldEligible={
            yoyEnrolledCustomersData
              ? cumulativeEnrolledToggleActive
                ? yoyEnrolledCustomersData[0]?.pts_total_connected_cars
                : yoyEnrolledCustomersData[0]?.total_cumulative_connected_cars
              : null
          }
          comparisonPercentageValue={
            yoyEnrolledCustomersData
              ? cumulativeEnrolledToggleActive
                ? yoyEnrolledCustomersData[0]?.perc_total_enrolled
                : yoyEnrolledCustomersData[0]?.perc_cumulative_enrolled_avg
              : null
          }
          chartData={{
            data: showMetric ? formattedEnrolledCustomersChartData : undefined,
            chartId: "enrolled_customersChart",
            barSeriesName: "Enrolled customers",
            barDataKey: `${cumulativeEnrolledToggleActive ? "enrolled_users" : "enrolled_customer_cumulative"}`,
            lineDataKey: `${cumulativeEnrolledToggleActive ? "connected_cars" : "cumulative_connected_cars"}`,
          }}
          tooltip={
            regionParamValue === "Europe"
              ? "Enrolled customers is equivalent to services activated in the Europe region on monthly basis"
              : regionParamValue === "All "
              ? "China not included in Geography = 'All' calculations"
              : ""
          }
          data-target="engagement"
          onClick={() => setMetric("Engagement")}
          showMetric={showMetric}
          isSingleYear={isSingleYear}
          toggle={renderCumulativeEnrolledToggle}
          toggleActive={cumulativeEnrolledToggleActive}
          setToggleActive={setCumulativeEnrolledToggleActive}
        />
      </ErrorBoundary>
    </div>
  );
});
