import moment from "moment";
import "moment-fquarter";
import { newApiFetch } from "./utils";

function getMtmReportParameters() {
  const parameters = new URLSearchParams(window.location.search);
  const marketParam = parameters.get("market");
  const granularityParam = parameters.get("granularity");
  const modelParam = parameters.get("model");

  let dateRangeParam = {
    start_date: moment().subtract(3, "quarter").startOf("quarter").format("YYYY-MM-DD"),
    end_date: moment().endOf("month").format("YYYY-MM-DD"),
  };

  if (parameters.get("date_range") === "Last 6 months") {
    dateRangeParam =
      marketParam === "G5"
        ? (dateRangeParam = {
            start_date: moment().subtract(6, "month").startOf("month").format("YYYY-MM-DD"),
            end_date: moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
          })
        : (dateRangeParam = {
            start_date: moment().subtract(5, "month").startOf("month").format("YYYY-MM-DD"),
            end_date: moment().endOf("month").format("YYYY-MM-DD"),
          });
  }

  const paramObj: Record<string, string | Array<string> | null | undefined> = {
    ...dateRangeParam,
    market: marketParam ? marketParam.split(",") : [],
    granularity: granularityParam === "monthly" ? "month" : "quarter",
    model: modelParam && modelParam == "All" ? [] : modelParam?.split(","),
  };

  if (!modelParam) delete paramObj["model"];

  return { ...paramObj };
}

function getModelFilterParams() {
  const { start_date, end_date, market } = getMtmReportParameters();

  return { start_date, end_date, market };
}

//FUTURE MARKET API CALLS
export const getFutureMarketMediaMlcData = () => newApiFetch("/mtm_api/fmi/future", getMtmReportParameters());
export const getFutureMarketPaidImpressionsData = () => newApiFetch("/mtm_api/impressions", getMtmReportParameters());
export const getFutureMarketReachData = () => newApiFetch("/mtm_api/reach", getMtmReportParameters());
export const getFutureMarketBrandSearchData = () => newApiFetch("/mtm_api/search", getMtmReportParameters());

//NEAR MARKET API CALLS
export const getNearMarketMediaMlcData = () => newApiFetch("/mtm_api/fmi/near_market", getMtmReportParameters());
export const getNearMarketVisitsData = () => newApiFetch("/mtm_api/visits", getMtmReportParameters());
export const getNearMarketUniqueVisitsData = () => newApiFetch("/mtm_api/unique_visitors", getMtmReportParameters());
export const getNearMarketKbaVisits = () => newApiFetch("/mtm_api/kbas", getMtmReportParameters());

//In MARKET API CALLS
export const getInMarketMediaMlcData = () => newApiFetch("/mtm_api/fmi/in_market", getMtmReportParameters());
export const getInMarketLeadVolumeData = () => newApiFetch("/mtm_api/leads", getMtmReportParameters());
export const getInMarketTotalSalesData = () => newApiFetch("/mtm_api/sales", getMtmReportParameters());
export const getJapanInMarketLeadVolumeData = () => newApiFetch("/mtm_api/tracked_leads", getMtmReportParameters());

// Mtm currency
export const getMtmCurrency = () => newApiFetch("/mtm_api/currency", getMtmReportParameters());
export const getMtmModelFilterData = () => newApiFetch("/mtm_api/filters/models", getModelFilterParams());
