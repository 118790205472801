import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  fetchMarketsData,
  fetchMetricsData,
  fetchMtmAnnotations,
  fetchMtmCurrency,
  fetchMtmLastDataRefresh,
  fetchMTMMarketsData,
  fetchMtmModels,
  fetchNewModelsData,
  setPageName,
  setSpecificParameter,
} from "../../actions";
import "../../assets/styles/component/pageContainer.scss";
import "../../assets/styles/pages/mtmReport.scss";
import { ErrorMsg } from "../../components/AppMessages";
import { MtmReportFilters } from "../../components/Filters";
import { DashboardLayout } from "../../components/Layouts";
import { DefaultPageContent } from "../../components/PageContent";
import { PageTitle } from "../../components/PageContent/subs";
import { ALL_COUNTRIES } from "../../constants";
import { BackgroundContext, DashboardBannerContext, MtmInvestmentContext } from "../../context";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { FutureMarket, InMarket, NearMarket } from "./subs";

export const MTMReport = withRouter((props: RouteComponentProps): JSX.Element => {
  const { history, location } = props;
  const dispatch = useDispatch();

  const { setHideBackgroundImage } = useContext(BackgroundContext);

  const { setBannerText, setShowBanner } = useContext(DashboardBannerContext);

  const params = useSelector((state: RootStateOrAny) => state.mtm_parameters);

  const marketParamValue = useSelector((state: RootStateOrAny) => state.mtm_parameters.market);
  const dateParamValue = useSelector((state: RootStateOrAny) => state.mtm_parameters.date_range);
  const modelParamValue = useSelector((state: RootStateOrAny) => state.mtm_parameters.model);
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.mtm);

  const [futureMarket, setFutureMarket] = useState<{ value: number; share: number }>({ value: 0, share: 0 });
  const [inMarket, setInMarket] = useState<{ value: number; share: number }>({ value: 0, share: 0 });
  const [nearMarket, setNearMarket] = useState<{ value: number; share: number }>({ value: 0, share: 0 });

  // Usage tracking
  useEffect(() => eventTracking(MixpanelEvents.page_view, { dashboard: "MTM", page: "MTM" }), [location.pathname]);

  // Sets page name
  useEffect(() => {
    if (marketParamValue === ALL_COUNTRIES) dispatch(setSpecificParameter("market", "Argentina"));
  }, [marketParamValue]);

  useEffect(() => {
    setHideBackgroundImage(true);
    dispatch(setPageName("MTM metrics that matter"));
    setShowBanner(true);
    setBannerText(
      "Please use new template of “Media Performance” slide for QRM/PDCA reporting. Please contact QMA: Global Brand Engagement team for the detail."
    );
    return () => {
      setHideBackgroundImage(false);
    };
  }, [dispatch, setHideBackgroundImage]);

  useEffect(() => {
    Promise.all([
      dispatch(fetchMTMMarketsData()),
      dispatch(fetchMtmLastDataRefresh()),
      dispatch(fetchNewModelsData("mtm_metrics_that_matter")),
      dispatch(fetchMarketsData("mtm_metrics_that_matter")),
      dispatch(fetchMetricsData("mtm_metrics_that_matter")),
    ]);
  }, []);

  useEffect(() => {
    Promise.all([dispatch(fetchMtmModels()), dispatch(fetchMtmCurrency())]);
  }, [history.location.search, dispatch]);

  useEffect(() => {
    dispatch(fetchMtmAnnotations({ ...params }, "mtm_metrics_that_matter"));
  }, [marketParamValue, dateParamValue, modelParamValue]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<MtmReportFilters />}
        lastDataRefresh={lastDataRefresh}
        dataDocumentation="mtm_data"
        pageTitle={
          <PageTitle
            dataDocumentation="mtm_data"
            tooltipText={`
            All QoQ / MoM calculations are based on the latest available quarterly / monthly actual data per module and compared to the previous quarter / month. <br/>
            The model filter “Nissan” refers to the statistics for all available models in the selected market. The model filter “All” produces a summation of all individually available models in the market which are listed in the filter dropdown. <br/>
            Please note, KBA numbers may vary from local reports and CEDAR Home, as they KBA definition represented in CEDAR Home does not consider Leads visits as key buy action.
            `}
          />
        }
        isMTM={true}
      >
        <MtmInvestmentContext.Provider value={{ futureMarket, setFutureMarket, setInMarket, nearMarket, setNearMarket, inMarket }}>
          <ErrorBoundary fallback={<ErrorMsg />}>
            <FutureMarket />
            <NearMarket />
            <InMarket />
          </ErrorBoundary>
        </MtmInvestmentContext.Provider>
      </DefaultPageContent>
    </DashboardLayout>
  );
});
