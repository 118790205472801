import {
  SET_INFLUENCE_OF_SHOPPING_TOOLS,
  SET_NON_TDS_LEADS,
  SET_ORDERS,
  SET_TDS_LEADS,
  SET_VISITS_DROP_OFF,
} from "../actions/actionTypes/ecommerceTypes";

const initialState = {
  visits_drop_off: {},
  influence_of_shopping_tools: [],
  orders: {},
  tds_leads: [],
  non_tds_leads: [],
};

const ecommerceReducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_VISITS_DROP_OFF:
      return { ...state, visits_drop_off: action.payload };

    case SET_INFLUENCE_OF_SHOPPING_TOOLS:
      return { ...state, influence_of_shopping_tools: action.payload };

    case SET_ORDERS:
      return { ...state, orders: action.payload };

    case SET_TDS_LEADS:
      return { ...state, tds_leads: action.payload };

    case SET_NON_TDS_LEADS:
      return { ...state, non_tds_leads: action.payload };

    default:
      return state;
  }
};

export default ecommerceReducer;
