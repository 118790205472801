import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { ALL_OPTION_NO_SPACE } from "../../../constants";
import { useMultiselectVisible } from "../../../hooks/dropdownVisibleHook";

type FilterListInt = {
  value: string;
  checked: boolean;
  indeterminate: boolean;
  child?: Array<{ [index: string]: { value: string; checked: boolean; indeterminate: boolean } }>;
  [index: string]: any;
};

interface Props {
  filterName?: string;
  parentKey: string;
  childKey?: string;
  filterList: Array<FilterListInt>;
  value: string;
  parameterName: string;
  parameterValue: string;
  handleFilterSubmission: () => void;
  showOptionsOnly?: boolean;
}

interface RenderProps {
  filterList: Array<FilterListInt>;
  parentValue?: string;
}

export const VisitorCountryFilter = (props: Props) => {
  const { filterName, parentKey, childKey, filterList, value, parameterValue, parameterName, handleFilterSubmission } = props;

  const [checkedState, setCheckedState] = useState<{ [index: string]: { checked: boolean; indeterminate: boolean } }>({});
  const [draftCheckedState, setDraftCheckedState] = useState<{ [index: string]: { checked: boolean; indeterminate: boolean } }>({});
  const { wrapperRef, isVisible, setIsVisible } = useMultiselectVisible(false);

  const filterState = useMemo(() => {
    const isAll = parameterValue === "All";
    const parameterList = parameterValue.split(",");

    const result = filterList?.map((item) => {
      const isString = typeof item === "string";
      //@ts-ignore
      if (isString) return { value: item, checked: isAll || parameterList.includes(item), indeterminate: false };

      const childResult = childKey
        ? item?.child?.map((childItem) => ({
            value: childItem[childKey],
            checked: parameterList.includes(`${childItem[childKey]} ${item[parentKey]}`),
            indeterminate: false,
          }))
        : [];

      return {
        value: item[parentKey],
        child: childResult,
        checked:
          parameterList.filter((paramItem) => {
            const parentRe = new RegExp(item[parentKey], "gi");
            return parentRe.test(paramItem);
          })?.length === childResult?.length,
        indeterminate:
          parameterList.filter((paramItem) => {
            const parentRe = new RegExp(item[parentKey], "gi");
            return parentRe.test(paramItem);
            //@ts-ignore
          })?.length < childResult?.length &&
          parameterList.filter((paramItem) => {
            const parentRe = new RegExp(item[parentKey], "gi");
            return parentRe.test(paramItem);
          })?.length > 0,
      };
    });

    return [{ value: "All", checked: result?.length === result.filter((item) => item.checked)?.length, indeterminate: false }, ...result];
  }, [filterList, parameterValue]);

  useEffect(() => {
    const stateResult = filterState.reduce((result, row) => {
      //@ts-ignore
      const { value: parentValue, checked: parentChecked, indeterminate: parentIndeterminate } = row;

      return { ...result, [parentValue]: { checked: parentChecked, indeterminate: parentIndeterminate } };
    }, {});
    setCheckedState(stateResult);
  }, [filterState]);

  useEffect(() => setDraftCheckedState(checkedState), [checkedState]);

  const handleMultiSelection = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value, checked, dataset },
      } = evt;
      const parentValue = dataset?.parentValue;

      const allChildCheckbox: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
        `[type="checkbox"][data-filter-name="${parameterName}"][data-parent-value="${parentValue}"]`
      );
      const selectedChildCheckbox: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
        `[type="checkbox"][data-filter-name="${parameterName}"][data-parent-value="${parentValue}"]:checked`
      );

      const availableNonAllValues: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
        `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]`
      );

      const selectedAvailableNonAllValues: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
        `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
      );

      // Sets current value
      let draftState = {
        ...draftCheckedState,
        [value]: { checked, indeterminate: false },
      };

      if (value === ALL_OPTION_NO_SPACE) {
        Object.keys(draftState).map((key) => {
          draftState = {
            ...draftState,
            [key]: { checked, indeterminate: false },
          };
        });

        setDraftCheckedState(draftState);
        return;
      }

      //Updates current checkbox state
      if (parentValue) {
        draftState = {
          ...draftState,
          [parentValue]: {
            checked: allChildCheckbox?.length === selectedChildCheckbox?.length,
            indeterminate: selectedChildCheckbox?.length > 0 && selectedChildCheckbox?.length < allChildCheckbox?.length,
          },
        };
      } else {
        // Updates all child elements if present
        const childCheckboxes: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
          `[type="checkbox"][data-parent-value="${value}"]`
        );

        childCheckboxes?.forEach((childElement) => {
          const childValue = childElement.value;
          draftState = {
            ...draftState,
            [childValue]: { checked, indeterminate: false },
          };
        });

        draftState = {
          ...draftState,
          All: {
            checked: selectedAvailableNonAllValues?.length === availableNonAllValues?.length,
            indeterminate:
              selectedAvailableNonAllValues?.length !== 0 && selectedAvailableNonAllValues?.length < availableNonAllValues?.length,
          },
        };
      }

      setDraftCheckedState(draftState);
    },
    [draftCheckedState]
  );

  const handleFilterApply = () => {
    setIsVisible(false);
    handleFilterSubmission();
  };

  const renderCheckedOptions = useCallback(
    (renderProp: RenderProps) => {
      const { filterList, parentValue } = renderProp;

      return filterList.map((filterItem) => {
        const { value, child } = filterItem;
        const checkValue = parentValue ? `${value} ${parentValue}` : value;

        return (
          <Fragment key={value}>
            <div className={"multiselect_container"}>
              <input
                type="checkbox"
                className={"multiselect-checkbox"}
                id={parentValue ? `${parentKey}_${value}_${parentValue}` : `${parentKey}_${value}`}
                value={checkValue}
                checked={draftCheckedState[checkValue]?.checked ? draftCheckedState[checkValue].checked : false}
                onChange={handleMultiSelection}
                data-parent-value={parentValue}
                data-filter-name={parameterName}
                data-options-type={!parentValue && value !== ALL_OPTION_NO_SPACE ? "single" : "grouped"}
                data-relationship={parentValue && !child && value !== ALL_OPTION_NO_SPACE ? "child" : "parent"}
                ref={(input) => {
                  if (input) {
                    input.indeterminate = draftCheckedState[checkValue]?.indeterminate;
                  }
                }}
              />

              <label className="multiselect-label" htmlFor={parentValue ? `${parentKey}_${value}_${parentValue}` : `${parentKey}_${value}`}>
                {value}
              </label>
            </div>
          </Fragment>
        );
      });
    },
    [draftCheckedState]
  );

  return (
    <Fragment>
      <div className="filter" id={`${filterName}_filter_div`} ref={wrapperRef}>
        <label className="filter_header" htmlFor={`visitor_country_filter`}>
          {filterName}
        </label>

        <div className="input_arrow" onClick={() => setIsVisible(!isVisible)} data-options-ul={`${parentKey}_ul`}>
          <input
            readOnly
            type="text"
            id={`visitor_country_filter`}
            className={"filter_input"}
            data-test-id={`${parentKey}`}
            value={value}
          />
          <span className="arrow_down " />
        </div>

        <ul
          className={`filter_menu metrics_list ${isVisible ? "showOptions" : ""}`}
          id={`${parentKey}_ul`}
          data-test-id="visitor_country_ul"
        >
          {/* @ts-ignore */}
          {renderCheckedOptions({ filterList: filterState, parentKey, childKey })}
          <div className="filter_options__submit_area">
            <div className="filter_submit_btn submit_geo_filter" onClick={handleFilterApply}>
              Apply
            </div>
          </div>
        </ul>
      </div>
    </Fragment>
  );
};
