import React from "react";
import blueCedarLogo from "../../assets/images/blue_cedar_logo.svg";

export const PageNotFound = () => {
  return (
    <div className="form_body" id="login_form_body">
      <div className="header_section">
        <img src={blueCedarLogo} alt="Cedar Logo" />
      </div>
      <form className="error_form">
        <h3>This page doesn&apos;t exist</h3>
        <label>It looks like you&apos;ve tried to access a page that doesn&apos;t exist.</label>
        <a href="/">Return to homepage.</a>
      </form>
    </div>
  );
};
