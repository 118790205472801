import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setSpecificParameter } from "../../../actions";
import { ALL_OPTION_NO_SPACE } from "../../../constants";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { closeFilterOptions } from "../subs/helpers";
import { MultiSelectFilter } from "./MultiSelectFilter";

interface Props {
  onShowFilterOptions: (evt: React.MouseEvent<HTMLElement>) => void;
  dealers: any;
  parameterName: string;
  value: string;
  parentKey?: string;
  childKey?: string;
  topFiveDealers?: boolean;
  bottomFiveDealers?: boolean;
}

const UCDealerFilter = (props: Props) => {
  const { onShowFilterOptions, value, dealers, parameterName, parentKey, childKey, topFiveDealers, bottomFiveDealers } = props;
  const dispatch = useDispatch();
  const marketParam = useSelector((state: RootStateOrAny) => state.parameters.market);

  const [searchString, setSearchString] = useState<string>("");
  const [dealersList, setDealersList] = useState<Array<any>>([]);

  useEffect(() => {
    setSearchString("");
  }, []);

  const handleFilterApply = useCallback((evt) => {
    evt.preventDefault();
    const selectedParents = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    ) as NodeListOf<HTMLInputElement>;
    const selectedCheckboxes = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="grouped"]:checked`
    ) as NodeListOf<HTMLInputElement>;
    const selectedParent: Array<string> = [];
    selectedParents?.forEach((checkbox) => selectedParent.push(checkbox.value));
    const selectedChild: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedChild.push(checkbox.value));
    if (selectedParent.includes(ALL_OPTION_NO_SPACE)) {
      dispatch(setSpecificParameter("dealer", ALL_OPTION_NO_SPACE));
      dispatch(setSpecificParameter("outlet", ALL_OPTION_NO_SPACE));
    } else {
      dispatch(setSpecificParameter("dealer", selectedParent.join(",")));
      dispatch(setSpecificParameter("outlet", selectedChild.join(",")));
    }

    closeFilterOptions();
  }, []);

  const resetSelection = useCallback(() => {
    const dealersList = dealers?.map((val: { outlet: any[]; name: any }) => {
      const outlet = val?.outlet?.map((o: any) => ({ name: o, parent: val?.name, checked: false }));
      return { name: val.name, checked: false, child: outlet };
    });
    setDealersList([{ name: "All", checked: false, child: [] }, ...dealersList]);
  }, [dealers]);

  const handleCheckboxClick = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value, checked, dataset },
      } = evt;

      const optionsType = dataset?.optionsType;
      const index = optionsType == "single" ? String(dataset.key) : Number(dataset.key);

      let dealersListCopy = [...dealersList];
      const checkedElems = dealersList?.filter((val) => val.checked);

      if (value == ALL_OPTION_NO_SPACE) {
        dealersListCopy = dealersListCopy.map((dealer) => {
          const child = dealer.child.map((childData: { name: string; checked?: boolean; parent?: string }) => ({
            ...childData,
            checked: true,
          }));
          return { ...dealer, checked: true, child: child };
        });
        setDealersList(dealersListCopy);
      } else {
        if (optionsType == "single") {
          // if checked --> check self and all related outlets
          //if unchecked --> uncheck self and related outlets
          const indexOfElem = dealersListCopy
            ?.map(function (x) {
              return x.name;
            })
            .indexOf(index);
          let res: any = {};
          const modified: { name: string; checked?: boolean; child?: any[] } = dealersListCopy
            .filter((val) => val?.name == value)
            ?.map((val) => ({ ...val, checked: checked }))[0];
          res = { ...modified };
          res["child"] = modified?.child?.map((val) => ({ ...val, checked: checked }));
          if (checkedElems?.length == 5 && checked) {
            //check self and children uncheck all other dealers and their children
            dealersListCopy[indexOfElem] = res;
            setDealersList(
              dealersListCopy?.map((val: { child: any[]; name: any }) => {
                if (val !== res) {
                  const outlet = val?.child?.map((o: any) => ({ name: o?.name, parent: val?.name, checked: false }));
                  return { name: val.name, checked: false, child: outlet };
                } else {
                  return res;
                }
              })
            );
          } else {
            dealersListCopy[indexOfElem] = res;
            setDealersList(dealersListCopy);
          }
        } else {
          //if checked --> check self and parent
          //if unchecked --> uncheck self -->  check if all unchecked and unchecck parent
          const parentValue = dataset?.parentValue?.replaceAll("_", " ");
          let parentElement: { name: string; checked?: boolean; child?: any[] } = dealersList.filter((val) => val.name == parentValue)[0];
          const parentIdx = dealersListCopy?.indexOf(parentElement);
          if (parentElement?.child?.length) {
            const currentElement = parentElement.child.filter((val) => val?.name == value)[0];
            const currentIdx = parentElement.child.indexOf(currentElement);
            parentElement.child[currentIdx] = { ...currentElement, checked: checked };
            const someChecked = parentElement?.child?.some((val) => val?.checked);
            parentElement = { ...parentElement, checked: someChecked };
            dealersListCopy[parentIdx] = parentElement;
            setDealersList(dealersListCopy);
          }
        }
      }
    },
    [dealersList, searchString]
  );

  //reset selection
  useEffect(() => {
    resetSelection();
  }, [dealers]);

  const handleFilterOptionClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;

      const filterName = dataset?.filter;
      const optionValue = dataset?.value;

      closeFilterOptions();
      if (filterName && optionValue) {
        dispatch(setSpecificParameter(filterName, optionValue));
        eventTracking(MixpanelEvents.filter_change, { filter: filterName, value: optionValue, dashboard: "Used Cars", page: "Used Cars" });
      }
    },
    [dealersList, searchString]
  );

  const handleFilterSubmission = useCallback(() => {
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );

    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = dealers?.length === selectedValues?.length ? "All" : selectedValues?.join(",");

    closeFilterOptions();
    dispatch(setSpecificParameter("dealer", filterValue));
    eventTracking(MixpanelEvents.filter_change, { filter: "dealer", value: filterValue, dashboard: "Used Cars", page: "Used Cars" });
  }, [dealers]);

  const filteredDealers = useMemo(() => {
    if (dealers?.length && dealers.length > 0 && searchString?.length) {
      return dealers.filter((dealer: string) => dealer.toLowerCase()?.includes(searchString?.toString()?.toLowerCase()));
    }
    return dealers;
  }, [dealers, searchString]);

  return (
    <div className="filter" id={`dealer_filter_div`}>
      <label className="filter_header" htmlFor={`dealer_filter`}>
        {parameterName}
      </label>

      <div className="input_arrow" onClick={onShowFilterOptions} data-options-ul={`dealer_ul`}>
        <input
          readOnly
          type="text"
          id={`dealer_filter`}
          className={"filter_input"}
          // data-test-id={`${parentKey}`}
          value={value}
        />
        <span className="arrow_down" />
      </div>

      <ul className="filter_options" id={`dealer_ul`} data-test-id="region_list">
        <>
          <div className="filter_search_bar" id="">
            <input value={searchString} placeholder="Search dealers" type="search" onChange={(e) => setSearchString(e.target.value)} />
          </div>
          {topFiveDealers && (
            <li data-filter={"dealer"} data-value={"Top five Dealers"} onClick={handleFilterOptionClick}>
              Top five Dealers
            </li>
          )}
          {bottomFiveDealers && (
            <li className={"filter_divider"} data-filter={"dealer"} data-value={"Bottom five Dealers"} onClick={handleFilterOptionClick}>
              Bottom five Dealers
            </li>
          )}
        </>
        {filteredDealers?.length > 0 ? (
          <MultiSelectFilter
            parentKey={parameterName}
            showOptionsOnly={true}
            // @ts-ignore
            filterList={filteredDealers}
            value={value}
            parameterName={parameterName}
            parameterValue={value}
            onShowFilterOptions={onShowFilterOptions}
            handleFilterSubmission={handleFilterSubmission}
          />
        ) : (
          <li className="no-result-option">No result found</li>
        )}
      </ul>
    </div>
  );
};

export default UCDealerFilter;
