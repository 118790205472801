import React, { useEffect, useRef, useState } from "react";
import { Position, Tooltip } from "react-tippy";

interface Props {
  ref?: React.MutableRefObject<any>;
  paragraphs: JSX.Element;
  tooltipPosition?: Position;
  tooltipVisible?: boolean;
  setTooltipVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}

const PmoTooltip = (props: Props) => {
  const { paragraphs, tooltipPosition } = props;

  const ref = useRef<any>(null);

  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (tooltipVisible && ref.current != null && !ref.current.contains(e.target)) {
        setTooltipVisible(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [tooltipVisible]);

  return (
    <Tooltip
      position={tooltipPosition ? tooltipPosition : "left"}
      className="customer_intention_tooltip"
      html={<div ref={ref}>{paragraphs}</div>}
      trigger="click"
      animation="none"
      theme="dark"
      interactive={true}
      delay={1}
      hideDelay={1}
      duration={1}
      open={tooltipVisible}
      distance={0}
      //@ts-ignore
      onShown={() => setTooltipVisible(!tooltipVisible)}
    >
      <span
        className="info_tooltip hide_on_mobile"
        onClick={(evt: React.MouseEvent<HTMLElement>) => {
          evt.stopPropagation();
          setTooltipVisible(!tooltipVisible);
        }}
      />
    </Tooltip>
  );
};

export default PmoTooltip;
