import { ColumnSeries, DateAxis, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, percent } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, WHITE } from "../../../constants";
import { columnSeriesConfiguration, configureDateAxis, configureValueAxis, legendConfiguration } from "../helpers/configurations";
import { formatValueAxisUnits } from "../helpers/helpers";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { dateAxisLabelFormatter_QuarterlyFY, formatDateAxisLabelToQuarterlyFY } from "../helpers/pmoConfigurations";
import { ThemeInt } from "../helpers/types";
interface Props {
  data: any;
  chartName: string;
  theme: ThemeInt;
  seriesList: any;
  showVolume: boolean;
  granularity: string;
  isLoading: boolean;
}
interface ISeries {
  name: string;
  field: string;
  seriesId: any;
  index: number;
  seriesColor: string;
  hide?: boolean;
}
class ComparsionBarChart extends Component<Props> {
  chart!: XYChart;
  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }
  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }
  componentWillUnmount() {
    this.chart.dispose();
  }
  initChart() {
    const { data, chartName, theme, seriesList, showVolume, granularity } = this.props;
    this.chart = create(chartName, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.marginRight = 0;
    this.chart.paddingRight = 0;

    this.chart.marginTop = 30;

    if (chartName.includes("powertrains")) {
      this.chart.legend = legendConfiguration(theme);
      this.chart.legend.paddingBottom = 30;
    }

    this.chart.data.sort(function (a, b) {
      //@ts-ignore
      return new Date(a.date) - new Date(b.date);
    });

    //Default x axis to show current date
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.minGridDistance = granularity === "quarterly" ? 60 : 30;
    dateAxis.periodChangeDateFormats.setKey("day", "MMM");

    granularity === "quarterly" && dateAxis.renderer.labels.template.adapter.add("text", dateAxisLabelFormatter_QuarterlyFY);
    //Y Axis that is used by default
    const valueAxis1 = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis1, theme);
    valueAxis1.min = 0;
    if (!this.props.showVolume) valueAxis1.max = 100;
    valueAxis1.numberFormatter.numberFormat = "#";
    valueAxis1.renderer.labels.template.adapter.add("text", function (text) {
      return text + (showVolume ? "" : "%");
    });
    formatValueAxisUnits(valueAxis1);

    const createSeries = ({ name, field, seriesId, index, seriesColor, hide }: ISeries) => {
      seriesId = this.chart.series.push(new ColumnSeries());
      columnSeriesConfiguration(seriesId, {
        name: name,
        valueY: field,
      });
      seriesId.stacked = true;
      seriesId.dataFields.dateX = "date";
      if (granularity === "quarterly") seriesId.dataFields.categoryX = "quarter";
      seriesId.columns.template.tooltipText = "{name}: [bold]{valueX}[/]";
      seriesId.columns.template.width = percent(65);
      seriesId.fill = color(seriesColor);
      seriesId.stroke = color(seriesColor);
      seriesId.tooltip.getFillFromObject = false;
      seriesId.tooltip.autoTextColor = false;
      seriesId.tooltip.background.fill = color(WHITE);
      seriesId.tooltip.label.fill = color(BLACK);
      seriesId.hidden = hide;
      //@ts-ignore
      seriesId.legendIdx = seriesList.length - (index + 1);
      seriesId.columns.template.adapter.add("tooltipText", (text: string, target: { dataItem: { dataContext: any } }) => {
        const data = target.dataItem?.dataContext;
        if (granularity === "quarterly") {
          //@ts-ignore
          const dateVal = formatDateAxisLabelToQuarterlyFY(data?.date);
          return (text = `[bold; font-size: var(--regular_font_size);] {name}\n ${dateVal}[/ font-size: var(--regular_font_size);]: {valueY.formatNumber("#,###.")} ${
            showVolume ? "" : "%"
          }`);
        } else {
          return (text = `[bold; font-size: var(--regular_font_size);]{name}\n{dateX.formatDate('MMM YYYY')} [/ font-size: var(--regular_font_size);]: {valueY.formatNumber("#,###.")} ${
            showVolume ? "" : "%"
          }`);
        }
      });
    };
    seriesList?.map((series: { name: string; color: string; volumeField: string; gradeField: string; hide: boolean }, ind: number) => {
      return createSeries({
        name: series.name,
        field: showVolume ? series.volumeField : series.gradeField,
        seriesId: `series${ind + 1}`,
        index: ind,
        seriesColor: series.color,
        hide: series.hide,
      });
    });
  }
  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.chartName} className={"graph"} />
      </div>
    );
  }
}
export default ComparsionBarChart;
