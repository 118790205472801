import React from "react";

export const InventoryVolumeTrendParagraphs = () => (
  <>
    <p>
      <span style={{ fontWeight: "bold" }}>Inventory volume trend</span> displays the count of all vehicles that are available in stock per
      data point in the select date range and granularity. The selection of different models, year, mileage, price and certification, as
      well as distinct dealers and outlets will affect the inventory volume.
    </p>
    <p>
      <u>Source</u>: Modix
    </p>
  </>
);

export const AvgDaysInStockParagrahphs = () => (
  <>
    <p>
      <span style={{ fontWeight: "bold" }}>Average days in stock trend</span> considers the average days that a model or a combination of
      models stays in dealers and outlet stock before it&apos;s sold.
    </p>
    <p>
      <u>Source</u>: Modix
    </p>
  </>
);

export const SalesVolumeTrendParagrahphs = () => (
  <>
    <p>
      <span style={{ fontWeight: "bold" }}>Sales volume trend</span> is the count of all the units that were sold for the selected model(s)
      on the selected geography, model and dealer. If a vehicle (identified by a unique identifier) was present in stock in the previous day
      and it&apos;s no longer in the stock list it is considered as sold.
    </p>
    <p>
      <u>Source</u>: Modix
    </p>
  </>
);

export const SalesRevenueTrendParagrahphs = () => (
  <>
    <p>
      <span style={{ fontWeight: "bold" }}>Sales revenue</span> is a sum of the retail price for all the vehicles that were sold in the
      selected date range and geography, model and dealer filters.
    </p>
    <p>
      <u>Source</u>: Modix
    </p>
  </>
);

export const AngListPricetrendParagrahphs = () => (
  <>
    <p>
      <span style={{ fontWeight: "bold" }}>Average list price</span> is given by the sum of total list price for the model(s) selected in
      the filter divided by the total listed volume for the same model(s) in the selected date range.
    </p>
    <p>
      <u>Source</u>: Modix
    </p>
  </>
);

export const AngSalesPricetrendParagrahphs = () => (
  <>
    <p>
      <span style={{ fontWeight: "bold" }}>Average sales price</span> is given by sales revenue (sum of the retail price for all the
      vehicles that were sold in the selected date range) divided by sales volume (count of all the units that were sold for the selected
      model(s))
    </p>
    <p>
      <u>Source</u>: Modix
    </p>
  </>
);

export const TotalValuesInPeriodParagrahphs = () => (
  <>
    <p>
      This section displays the total values for the following metrics in the select date range. A breakdown by dealer and outlet is also
      available.
    </p>
    <br />
    <InventoryVolumeTrendParagraphs />
    <br />
    <AvgDaysInStockParagrahphs />
    <br />
    <SalesVolumeTrendParagrahphs />
    <br />
    <SalesRevenueTrendParagrahphs />
    <br />
    <AngListPricetrendParagrahphs />
    <br />
    <AngSalesPricetrendParagrahphs />
  </>
);

export const InvByListedParagrahphs = () => (
  <>
    <p>
      <span style={{ fontWeight: "bold" }}>Inventory by age from the listed day</span> represents how long the vehicle stays in
      dealers&apos; stock. This metric is given by the listed date subtracted from the current date and the vehicles are grouped in time
      frames (0-30 days, 31-60 days, 61-90 days and more than 90 days)
    </p>
    <p>
      <u>Source</u>: Modix
    </p>
  </>
);

export const ListPriceParagrahphs = () => (
  <>
    <p>
      <span style={{ fontWeight: "bold" }}>List price</span> is given by the sum of total list price for the model(s) selected in the chosen
      date range.
    </p>
    <p>
      <u>Source</u>: Modix
    </p>
  </>
);

export const InvByAge = () => (
  <>
    <p>
      <span style={{ fontWeight: "bold" }}>Inventory by age of vehicle </span> is a breakdown by vehicle year and shows the portion that
      each year of the selected model(s) represent in total dealers&apos; stock.
    </p>
    <p>
      <u>Source</u>: Modix
    </p>
  </>
);

export const PerfPerUnit = () => (
  <>
    <p>
      Sales funnel numbers are impacted by the models selected in the filters. Please note that dealers different selection only affects the
      sales number.
    </p>
    <br />
    <p>Website visits: total number of websites that the given model(s) generated on the Nissan website. Source: Adobe Analytics</p>
    <br />
    <p>
      KBA visits: count of visits including at least one key buying action as Dealer search, Stock views and Compare tools views. Source:
      Adobe Analytics.
    </p>
    <br />
    <p>Leads visits: count of visits including at least one digital lead as Enquire, Test Drive and Reserve. Source: Adobe Analytics</p>
    <br />
    <p>
      Sales: Count of all the units that were sold for the selected model(s) on the selected geography, model and dealer. If a vehicle
      (identified by a unique identifier) was present in stock in the previous day and it&apos;s no longer in the stock list it is
      considered as sold. Source: Modix
    </p>
  </>
);

export const LeadsPerfPerUnit = () => (
  <>
    <p>
      <span style={{ fontWeight: "bold" }}>Leads performance by unit </span> is given by the total number <br />
      of websites that the given model(s) generated on the <br />
      Nissan website divided by the total inventory volume for <br />
      the same model(s) in the selected dealers/outlets.
    </p>
    <br />
    <p>
      <u>Inventory source</u>: Modix
    </p>
    <p>
      <u>Leads visits source</u>: Adobe Analytics
    </p>
  </>
);

export const SalesPerfPerUnit = () => (
  <>
    <p>
      <span style={{ fontWeight: "bold" }}>Sales performance by unit </span> is given by the total sales <br />
      volume divided by the inventory volume in the selected <br />
      date range.
    </p>
    <br />
    <p>
      <u>Source</u>: Modix
    </p>
  </>
);

export const InvRatio = () => (
  <>
    <p>
      <span style={{ fontWeight: "bold" }}>Inventory ratio </span> aims to show how many sales happened when comparing with the total
      inventory. This result is given by the inventory number divided by the number of sales.{" "}
    </p>
    <p>
      <u>Source</u>: Modix
    </p>
  </>
);

export const TurnoverRatio = () => (
  <>
    <p>
      <span style={{ fontWeight: "bold" }}>Turnover ratio </span> shows the amount of vehicles that were sold when comparing with the total
      inventory. This metric is given by the sales volume in the selected period divided by the inventory in the selected period.
    </p>
    <p>
      <u>Source</u>: Modix
    </p>
  </>
);

export const LeadSalesConversion = () => (
  <>
    <p>
      This metric calculates the conversion rate from leads generated in the website to the sales in the dealers. It&apos;s given by the
      total sales volume divided by the number of leads generated by the selected model(s) on the Nissan website.
    </p>
    <p>
      <u>Leads visits source</u>: Adobe Analytics
    </p>
    <p>
      <u>Sales source</u>: Modix
    </p>
  </>
);

export const TrendlineParagraphs = () => (
  <>
    <>
      <p>
        <span style={{ fontWeight: "bold" }}>Customer intention</span> is based on the volume of configurations completed by visitors
        online.
      </p>
      <p>
        <u>Note</u>: visitors are able to complete more than one configuration, and can add to the volume of completed configurations.
      </p>
      <p>
        <u>Source</u>: Nissan website data from Adobe Analytics
      </p>
    </>
  </>
);
