import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import Cookies from "js-cookie";
import _ from "lodash";
import React, { Component, Dispatch, SetStateAction } from "react";
import WebFont from "webfontloader";
import { BLACK, BLUE, metricTypes, OCE_CHART_COLORS, OCE_SERIES, varianceMetricTypes, WHITE } from "../../../constants";
import { baselineData } from "../../../constants/interface";
import { Annotation } from "../../../pages/Admin/types";
import ShareAnnotationModal from "../../Annotations/Dialogs/ShareAnnotationModal";
import { createAnnotationTooltips } from "../helpers/annotationTooltips";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, legendConfiguration, lineSeriesConfiguration } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: baselineData;
  toggleAnnotationsDialog: () => void;
  setAnnotationStartDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationEndDate: Dispatch<SetStateAction<Date | null>>;
  setAnnotationDefaultMetric: Dispatch<SetStateAction<string>>;
  annotations: Annotation[];
  metric: metricTypes;
  theme: ThemeInt;
  checkedOptions: Array<metricTypes>;
  isCpoDashboard?: boolean;
  newCarsChecked?: boolean;
}

interface State {
  highlightDataStartDate: string | undefined;
  highlightDataEndDate: string | undefined;
  shareModalOpen: boolean;
  annotations: Annotation[] | undefined;
}

class BaselineTrendChart extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { highlightDataStartDate: undefined, highlightDataEndDate: undefined, shareModalOpen: false, annotations: undefined };

    this.toogleShareModal = this.toogleShareModal.bind(this);
  }

  static defaultProps = {
    metric: "visits",
  };
  chartId = "baselineTrendGraph";
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  setHighlightDataDates(startDate: string | undefined, endDate: string | undefined) {
    this.setState({
      highlightDataStartDate: startDate,
      highlightDataEndDate: endDate,
    });
  }

  toogleShareModal() {
    this.setState({
      shareModalOpen: !this.state.shareModalOpen,
    });
  }

  handleShareAnnotation(annotations: Annotation[]) {
    this.setState({
      annotations: annotations,
    });
    this.toogleShareModal();
  }

  initChart() {
    const { data, metric, theme, checkedOptions, isCpoDashboard, newCarsChecked } = this.props;
    const metric_variance: varianceMetricTypes = `${metric}_variance`;
    const cpoCheckedOptions: Array<varianceMetricTypes> = checkedOptions.map((el): varianceMetricTypes => `${el}_variance`);
    const seriesToRender = [metric_variance, ...cpoCheckedOptions];
    newCarsChecked && seriesToRender.push("new_cars_variance" as varianceMetricTypes);
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.colors.list = OCE_CHART_COLORS.map((chartColor) => color(chartColor));
    this.chart.data = data;

    this.chart.legend = legendConfiguration(theme);
    // Create Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.minGridDistance = 100;
    dateAxis.dateFormats.setKey("day", "dd MMM");
    dateAxis.dateFormats.setKey("week", "dd MMM");
    dateAxis.dateFormats.setKey("month", "MMM");
    dateAxis.periodChangeDateFormats.setKey("day", "dd MMM");
    dateAxis.periodChangeDateFormats.setKey("week", "dd MMM");
    dateAxis.periodChangeDateFormats.setKey("month", "MMM");

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.renderer.baseGrid.stroke = theme === "light" ? color(BLACK) : color(WHITE);
    valueAxis.renderer.baseGrid.strokeWidth = 1.5;
    valueAxis.renderer.baseGrid.strokeOpacity = 1;
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text + "%";
    });
    valueAxis.extraMax = 0.2;
    valueAxis.extraMin = 0.2;

    seriesToRender.map((metric) => this.createSeries(metric));
  }

  createSeries(seriesName: varianceMetricTypes) {
    const userViewsList = Cookies.get("views");
    const { toggleAnnotationsDialog, setAnnotationStartDate, setAnnotationEndDate, setAnnotationDefaultMetric } = this.props;

    const series = this.chart.series.push(new LineSeries());

    const bullet = lineSeriesConfiguration(series, {
      valueY: seriesName,
      name: OCE_SERIES[seriesName].seriesName,
    });
    series.dataFields.dateX = "row_date";

    if (seriesName === this.props.metric || seriesName === `${this.props.metric}_variance`) {
      series.stroke = color(BLUE);
      series.fill = color(BLUE);
    }
    bullet.tooltipText =
      "[bold; font-size: var(--regular_font_size);]w/c {dateX.formatDate('dd MMM YYYY')} [/ font-size: var(--regular_font_size);]: {valueY.formatNumber('#.')}%";

    if (!this.props.isCpoDashboard && userViewsList?.includes("annotations")) {
      bullet.events.on("hit", (ev: any) => {
        const startDate = new Date(ev.target.dataItem.dateX);
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);
        setAnnotationStartDate(startDate);
        setAnnotationEndDate(endDate);
        setAnnotationDefaultMetric(seriesName);
        toggleAnnotationsDialog();
      });
    }

    // series.adapter.add("stroke", (currentColor, target) => {
    //   const startDate = this.state.highlightDataStartDate;
    //   const endDate = this.state.highlightDataEndDate;
    //   const dataStartDate = target?.tooltip?.dataItem?.dates.dateX;
    //   console.log(target);
    //   console.log(startDate, endDate, dataStartDate);
    //   if (startDate && endDate && dataStartDate) {
    //     console.log("test");
    //     if (dataStartDate >= new Date(startDate) && dataStartDate <= new Date(endDate)) return color(WHITE);
    //   }
    //   return currentColor;
    // });

    // @ts-ignore
    let annotationBullet = createAnnotationTooltips.bind(this);
    annotationBullet = annotationBullet(series);
    // @ts-ignore
    annotationBullet.adapter.add("disabled", function (disabled: any, target: any) {
      if (!target.dataItem) {
        return disabled;
      }
      if (target.dataItem?.dataContext?.annotations) {
        const metricAnnotations: Annotation[] = target.dataItem?.dataContext?.annotations.filter((annotation: Annotation) =>
          annotation.metrics.flatMap((metric) => metric.metric).includes(seriesName)
        );
        if (metricAnnotations.length > 0) {
          return disabled;
        }
        return true;
      } else {
        return true;
      }
    });
    // @ts-ignore
    annotationBullet.events.on("hit", (ev: any) => {
      let startDate = ev.target.dataItem.annotations[0].start_date;
      let endDate = ev.target.dataItem.annotations[0].end_date;
      ev.target.dataItem.annotations.map((annotation: Annotation) => {
        if (new Date(annotation.start_date) < new Date(startDate)) {
          startDate = annotation.start_date;
        }
        if (new Date(annotation.end_date) > new Date(endDate)) {
          endDate = annotation.end_date;
        }
      });
      if (this.state.highlightDataStartDate == startDate && this.state.highlightDataEndDate == endDate) {
        this.setHighlightDataDates(undefined, undefined);
      } else {
        this.setHighlightDataDates(startDate, endDate);
      }
    });
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={"baselineTrendGraph"} data-test-id={"baselineTrendGraph"} className={"graph"} />
        </div>
        <p className={"baseline_definition"} data-test-id={"baseline_definition"}>
          *The baseline is based on the 5 week rolling average YoY
        </p>
        <ShareAnnotationModal
          open={this.state.shareModalOpen}
          handleToogleDialog={this.toogleShareModal}
          annotations={this.state.annotations}
        />
      </>
    );
  }
}

export default BaselineTrendChart;
