import { ColumnSeries, DateAxis, LabelBullet, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLACK, WHITE } from "../../../constants";
import { formatToThreeDigits } from "../../../utils/numberFormatting";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis } from "../helpers/configurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<Record<string, number | string>>;
  seriesList: Array<{ name: string; field: string; seriesColor: string }>;
  theme: ThemeInt;
  chartId: string;
  isVolume: boolean;
  isLoading: boolean;
}

class VocStackedBarChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, seriesList, theme, isVolume } = this.props;
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.data = data;
    this.chart.numberFormatter.numberFormat = "#a";

    // Create Axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);

    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.renderer.minGridDistance = 20;
    valueAxis.extraMax = 0.1;

    valueAxis.numberFormatter.numberFormat = "#.#a";
    if (!isVolume) {
      valueAxis.extraMax = 0;
      valueAxis.strictMinMax;
      valueAxis.max = 100;
      valueAxis.min = 0;
      valueAxis.numberFormatter.numberFormat = "#a";
    }

    this.chart.data.sort(function (a, b) {
      //@ts-ignore
      return new Date(a.date) - new Date(b.date);
    });

    seriesList?.forEach(({ name, field, seriesColor }, idx) => {
      const series = this.chart.series.push(new ColumnSeries());
      series.dataFields.dateX = "date";
      series.fill = color(seriesColor);
      series.stroke = color(seriesColor);
      series.stacked = true;
      series.dataFields.valueY = field;
      series.xAxis = dateAxis;

      const bullet = series.bullets.push(new LabelBullet());
      bullet.label.text = `{valueY}${isVolume ? "" : "%"}`;
      bullet.locationY = 0.5;

      if (field == "red" && isVolume) {
        const sumBullet = series.bullets.push(new LabelBullet());
        sumBullet.label.text = "{valueY}";
        sumBullet.verticalCenter = "bottom";
        sumBullet.dy = -10;
        sumBullet.label.adapter.add("text", (value, target) => {
          //@ts-ignore
          const value1 = target?.dataItem?.dataContext?.red || 0;
          //@ts-ignore
          const value2 = target?.dataItem?.dataContext?.green || 0;
          return formatToThreeDigits(value1 + value2);
        });
        sumBullet.label.fill = color(theme == "light" ? BLACK : WHITE);
      }
    });
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
          <LoadingEllipsis isLoading={this.props.isLoading} />
        </div>
      </>
    );
  }
}

export default VocStackedBarChart;
