import React, { useContext } from "react";
import { Tooltip } from "react-tippy";
import JourneyExcellenceDonutChart from "../../../../components/Charts/JourneyExcellence/JourneyExcellenceDonutChart";
import { ThemeContext } from "../../../../context";

interface Props {
  title: string;
  tooltipText?: string;
  chartId: string;
  data: Array<Record<string, number | string>>;
  valueField: string;
  categoryField: string;
}

const DonutChartTile = (props: Props) => {
  const { title, tooltipText, chartId, data, valueField, categoryField } = props;
  const { theme } = useContext(ThemeContext);

  return (
    <div className="tile tile-15">
      <div className="tile-header">
        <div className="title">{title}</div>
        {tooltipText && (
          <Tooltip
            position="top"
            className={"normal_tippy"}
            trigger="mouseenter"
            animation="none"
            interactive={true}
            delay={1}
            hideDelay={1}
            duration={1}
            title={tooltipText}
            size={"small"}
          >
            <span className="info_tooltip" />
          </Tooltip>
        )}
      </div>
      <div className="tile-content">
        <JourneyExcellenceDonutChart chartId={chartId} theme={theme} data={data} valueField={valueField} categoryField={categoryField} />
      </div>
    </div>
  );
};

export default DonutChartTile;
