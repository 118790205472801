import "moment-fquarter";
import moment from "moment-timezone";
import { DEFAULT_LANGUAGE, format_percent_options } from "../../../constants";
import { isCustomDate, isMonthlyCustomDate } from "../../../utils/dateFunctions";

export const getRating = (value: number): string => {
  if (value > 0) {
    return "up";
  }

  if (value < 0) {
    return "down";
  }

  return "na";
};

export const getRatingInverse = (value: number): string => {
  if (value > 0) {
    return "down";
  }

  if (value < 0) {
    return "up";
  }

  return "na";
};

export const computeComparison = (current: number, comparison: number): string => {
  if (comparison === 0 || comparison === null || current === null) return "n/a";
  return (((current - comparison) * 100) / comparison).toFixed(0);
};

export const comparisonToPercentage = (current: number, comparison: number): string => {
  if (comparison === 0 || comparison === null || current === null) return "n/a";
  return ((current - comparison) / comparison).toLocaleString(DEFAULT_LANGUAGE, format_percent_options);
};

export const periodComparison = (current: number, comparison: number): any => {
  if (comparison === 0 || comparison === null || current === null) return "n/a";
  const result = current - comparison;
  return result;
};

export const percentageShareCalculation = (volume: number, totalVolume: number): any => {
  if (!volume || !totalVolume) return "n/a";
  return volume / totalVolume;
};

export const dataConversion = (data: any[], metric: string) => {
  if (data) {
    const myData = data?.map((data: any) => {
      const {
        current_data: { [metric]: currentValue },
      } = data;

      const yoy_data = data.yoy_data;
      const pop_data = data.pop_data;

      const popValue = typeof pop_data?.[metric] !== "number" ? 0 : pop_data?.[metric];
      const yoyValue = typeof yoy_data?.[metric] !== "number" ? 0 : yoy_data?.[metric];
      const popPercentage = parseFloat(computeComparison(currentValue, popValue));
      const yoyPercentage = parseFloat(computeComparison(currentValue, yoyValue));
      return { ...data, popPercentage, yoyPercentage };
    });
    return myData;
  } else return [];
};

/**
 *
 * @param myString [string] checks if a string contains a number or not
 */
export function hasNumber(myString: string): any {
  return /\d/.test(myString);
}

const getMonths = (start: any, end: any) =>
  Array.from({ length: end.diff(start, "month") + 1 }).map((_, index) => moment(start).add(index, "month").format("MMMM YYYY"));

export function getMonthsArray(date_range: string) {
  let months: any = [];

  if (date_range === "This month") {
    months.push(moment().format("MMMM YYYY").toString());
  } else if (date_range === "Last month") {
    months.push(moment().subtract(1, "month").format("MMMM YYYY").toString());
  } else if (date_range === "This quarter") {
    const dateStart = moment(moment().fquarter().start.toString());
    const dateEnd = moment(moment().fquarter().end.toString());
    const interim = dateStart.clone();
    while (dateEnd > interim || interim.format("M") === dateEnd.format("M")) {
      months.push(interim.format("MMMM YYYY"));
      interim.add(1, "month");
    }
  } else if (date_range === "Last quarter") {
    const dateStart = moment(moment().subtract(1, "quarter").fquarter().start.toString());
    const dateEnd = moment(moment().subtract(1, "quarter").fquarter().end.toString());
    const interim = dateStart.clone();
    while (dateEnd > interim || interim.format("M") === dateEnd.format("M")) {
      months.push(interim.format("MMMM YYYY"));
      interim.add(1, "month");
    }
  } else if (date_range === "This financial year") {
    const dateStart = moment(`${moment().fquarter().year.toString()}-04-01`);
    const dateEnd = moment(`${moment().fquarter().nextYear.toString()}-03-31`);
    const interim = dateStart.clone();
    while (dateEnd > interim || interim.format("M") === dateEnd.format("M")) {
      months.push(interim.format("MMMM YYYY"));
      interim.add(1, "month");
    }
  } else if (date_range === "Last financial year") {
    const dateStart = moment(`${moment().subtract(1, "year").fquarter().year.toString()}-04-01`);
    const dateEnd = moment(`${moment().subtract(1, "year").fquarter().nextYear.toString()}-03-31`);
    const interim = dateStart.clone();
    while (dateEnd > interim || interim.format("M") === dateEnd.format("M")) {
      months.push(interim.format("MMMM YYYY"));
      interim.add(1, "month");
    }
  } else if (hasNumber(date_range)) {
    const dateStart = date_range.split("-")[0];
    const dateEnd = date_range.split("-")[1];
    months = getMonths(moment(dateStart, "DD/MM/YYYY"), moment(dateEnd, "DD/MM/YYYY"));
    months.push(moment(dateEnd, "DD/MM/YYYY").format("MMMM YYYY").toString());
  } else {
    months = [];
  }

  return months;
}
interface IObjectKeys {
  [key: string]: string;
}

/**
 * @param customDate string value from the date filter selection
 * The function generates custom dates values from the string value passed using moment and fquarter
 */
export function generateDateString(customDate: string): any {
  const lastFinancialYear = `FY${moment().subtract(1, "year").fquarter().year.toString().substr(-2)}`;
  const thisFinancialYear = `FY${moment().fquarter().year.toString().substr(-2)}`;
  const lastQuarter = `${moment().subtract(1, "quarter").fquarter().toString().split(" ")[0]} FY${moment()
    .subtract(1, "quarter")
    .fquarter()
    .toString()
    .split("/")[0]
    .substr(-2)}`;
  const thisQuarter = `${moment().fquarter().toString().split(" ")[0]} FY${moment().fquarter().toString().split("/")[0].substr(-2)}`;
  const customDateString: IObjectKeys = {
    "This month": moment().format("MMMM YYYY").toString(),
    "Last month": moment().subtract(1, "month").format("MMMM YYYY").toString(),
    "This quarter": thisQuarter,
    "Last quarter": lastQuarter,
    "This financial year": thisFinancialYear,
    "Last financial year": lastFinancialYear,
  };

  return customDateString[customDate];
}

/**
 *
 * @param currentDateString [string] date field selected
 * The fucntion returns an object with the current date string and previous range formatted
 * For example if a user selects This month, the function formats the string to the name of the month and generates the previous month as well
 */
export function generatePreviousRange(currentDateString: string): any {
  let dateRange: any = {};
  if (currentDateString === "This month") {
    dateRange = { current: moment().format("MMM YYYY").toString(), previous: moment().subtract(1, "month").format("MMM YYYY").toString() };
  } else if (currentDateString === "Last month") {
    dateRange = {
      current: moment().subtract(1, "month").format("MMM YYYY").toString(),
      previous: moment().subtract(2, "month").format("MMM YYYY").toString(),
    };
  } else if (currentDateString === "This quarter") {
    dateRange = { current: generateDateString(currentDateString), previous: generateDateString("Last quarter") };
  } else if (currentDateString === "Last quarter") {
    dateRange = {
      current: generateDateString(currentDateString),
      previous: `${moment().subtract(2, "quarter").fquarter().toString().split(" ")[0]} FY${moment()
        .subtract(2, "quarter")
        .fquarter()
        .toString()
        .split("/")[0]
        .substr(-2)}`,
    };
  } else if (currentDateString === "This financial year") {
    dateRange = { current: generateDateString(currentDateString), previous: generateDateString("Last financial year") };
  } else if (currentDateString === "Last financial year") {
    dateRange = {
      current: generateDateString(currentDateString),
      previous: `FY${moment().subtract(2, "year").fquarter().year.toString().substr(-2)}`,
    };
  } else {
    if (isCustomDate(currentDateString)) {
      const start_date = moment(currentDateString.split("-")[0], "DD/MM/YYYY");
      const end_date = moment(currentDateString.split("-")[1], "DD/MM/YYYY");
      const diff = end_date.diff(start_date, "days");
      dateRange = { current: "Selected period", previous: `Previous ${diff + 1} days` };
    } else if (isMonthlyCustomDate(currentDateString)) {
      const startEnd = currentDateString.split("-");
      const startDateFinancialYear = moment(startEnd[0], "MMMM YYYY").fquarter().year.toString().substr(-2);
      const endDateFinancialYear = moment(startEnd[1], "MMMM YYYY").fquarter().year.toString().substr(-2);
      const previousFinancialYear = moment(startEnd[0], "MMMM YYYY").subtract(1, "year").fquarter().year.toString().substr(-2);

      const isMultipleFy = startDateFinancialYear !== endDateFinancialYear;

      dateRange = {
        current: isMultipleFy ? "Selected period" : `FY${startDateFinancialYear}`,
        previous: isMultipleFy ? "Previous period" : `FY${previousFinancialYear}`,
      };
    }
  }
  return dateRange;
}
