export const businessFunctionsOptions = [
  "After sales",
  "Sales",
  "Customer experience",
  "Sales finance",
  "Product portfolio management",
  "Marketing",
  "MI",
  "IS/IT",
  "R&D",
];
