import { ErrorBoundary } from "@sentry/react";
import * as React from "react";
import { useCallback, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import UsedCarsBarChart from "../../../components/Charts/UsedCars/UsedCarsBarChart";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import { ThemeContext } from "../../../context";
import { isNull } from "../../../utils/utilityFunctions";
import PmoTooltip from "../../ProductMarketingOptimization/components/common/PmoTooltip";
import { PerfPerUnit } from "../helpers/tooltiptexts";

const PerformancePerUnit = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const themeContext = React.useContext(ThemeContext);

  const data = useSelector((state: RootStateOrAny) => state.cpo_operational.performance_per_unit_data);
  const loading = useSelector((state: RootStateOrAny) => state.loading.performance_per_unit);

  const chartData = useMemo(() => {
    if (data) {
      const keys = Object.keys(data);

      const chartData = keys.map((key) => {
        let nameval = "";
        nameval += `${key}`;
        return {
          name: nameval,
          amount: data[key]?.toFixed(2),
        };
      });
      return chartData;
    }
  }, [data]);

  const hasData = useMemo(() => {
    return chartData?.some((val) => !isNull(val.amount));
  }, [chartData]);

  const handleBtnClick = useCallback(() => {
    history.push("/cpo_digital_performance");
  }, []);

  return (
    <div className="list_price_graph inventory_graph primary_background">
      <div className="header">
        <h3 className="trendline_title tile_title">Sales funnel</h3>
        <PmoTooltip paragraphs={<PerfPerUnit />} />
      </div>
      <div className="main_content ">
        {hasData && !loading ? (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <UsedCarsBarChart chartId="performance_per_unit" data={chartData} isLoading={loading} theme={themeContext.theme} />
          </ErrorBoundary>
        ) : loading ? (
          <LoadingEllipsis isLoading={loading} />
        ) : (
          <NoDataMsg message="No data available" />
        )}
        <div className="more-info">
          For more information access:{" "}
          <button className="more-info-btn" onClick={handleBtnClick}>
            CPO Online CE performance
          </button>
        </div>
      </div>
    </div>
  );
});

export default PerformancePerUnit;
