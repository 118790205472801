// Todo: Move some effects to sagas instead of useEffect

import React, { useContext, useEffect, useRef } from "react";
import "../../assets/styles/component/filters.scss";
import { FilterContext } from "../../context";
import { FilterBtns } from "../Filters/common";
import { closeFilterOptions, closeMarketOptions } from "../Filters/subs/helpers";
import { Button } from "@material-ui/core";

interface Props {
  children: JSX.Element;
  extraClass?: "digital_filters" | "iframeFilters" | "iframeFilters-midas";
  resetFilters?: () => void;
  filterBtns?: Array<{ id: string; navigateTo: string; name: string }>;
  renderDashboardClassificationButtons?: () => JSX.Element;
}

export const FiltersLayout = (props: Props): JSX.Element => {
  const { children, resetFilters, extraClass, filterBtns, renderDashboardClassificationButtons } = props;

  const filtersRef = useRef<any>();

  const { showMobileFilter, setShowMobileFilter, setShowFilterOptions } = useContext(FilterContext);

  const currentUrl = window.location.href;

  // On load set eventlistener to handle clicks outside filter options
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (e: { target: any }) => {
    //Close iframe filter options when clicking any part of the filter bar
    if (e.target?.classList?.contains("iframeFilters")) {
      closeFilterOptions();
    }

    // @ts-ignore
    if (filtersRef && !filtersRef.current?.contains(e.target)) {
      if (
        !(
          e.target?.classList?.contains("calendar-table") ||
          e.target?.parentElement?.classList?.contains("calendar-table") ||
          e.target?.classList?.contains("drp-calendar") ||
          e.target?.parentElement?.classList?.contains("drp-calendar") ||
          e.target?.classList?.contains("prev") ||
          e.target?.parentElement?.classList?.contains("prev") ||
          e.target?.classList?.contains("daterangepicker") ||
          e.target?.parentElement?.classList?.contains("daterangepicker") ||
          e.target?.classList?.contains("next") ||
          e.target?.parentElement?.classList?.contains("next") ||
          e.target?.parentElement?.classList.contains("multiselect_container") ||
          e.target?.parentElement?.classList.contains("input_arrow") ||
          e.target?.parentElement?.classList.contains("filter_options__submit_area") ||
          e.target?.classList.contains("user_engagement_market_group") ||
          e.target?.classList.contains("enrolled_user_market_group") ||
          e.target?.classList?.contains("competitormodels") ||
          e.target?.parentElement?.classList?.contains("competitormodels") ||
          e.target?.classList?.contains("midas-unit") ||
          e.target?.parentElement?.classList?.contains("midas-unit") ||
          e.target?.classList?.contains("market-arrow") ||
          e.target?.classList?.contains("filter_options") ||
          e.target?.classList?.contains("default-filter-option") ||
          e.target?.classList?.contains("marketList")
        )
      ) {
        closeFilterOptions();
        closeMarketOptions();
        setShowFilterOptions(false);
      }
    }
  };

  return (
    <div ref={filtersRef} className={"filtersWrapper"}>
      <form id="filters" className={`${extraClass} ${showMobileFilter ? "expanded" : ""}`}>
        {filterBtns && (
          <div className="nav_btns">
            {filterBtns?.map((btn) => (
              <FilterBtns
                id={btn.id}
                navigateTo={btn.navigateTo}
                name={btn.name}
                extraBtnClass={currentUrl.includes(btn.navigateTo) ? "active" : ""}
                key={btn.id}
              />
            ))}
          </div>
        )}

        {filterBtns ? (
          <div className="filte">
            <button
              id="closeFilters"
              className="hide_on_desktop"
              onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
                evt.preventDefault();
                setShowMobileFilter(false);
              }}
            >
              &#x2573;
            </button>

            {children}

            {renderDashboardClassificationButtons ? (
              <div className="dashboard-classification-buttons">{renderDashboardClassificationButtons()}</div>
            ) : null}

            {/*Reset filter*/}
            {resetFilters && <div className={"reset_filters"} onClick={resetFilters} />}
            {/*Apply btn*/}
            <div className="filter_float hide_on_desktop">
              <button
                className="filter_button submit_filters"
                id="save"
                data-test-id="submit"
                onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
                  evt.preventDefault();
                  setShowMobileFilter(false);
                }}
              >
                APPLY
              </button>
            </div>
          </div>
        ) : (
          <>
            <button
              id="closeFilters"
              className="hide_on_desktop"
              onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
                evt.preventDefault();
                setShowMobileFilter(false);
              }}
            >
              &#x2573;
            </button>

            {children}

            {/*Reset filter*/}
            {resetFilters && <div className={"reset_filters"} onClick={resetFilters} />}
            {/*Apply btn*/}
            <div className="filter_float hide_on_desktop">
              <button
                className="filter_button submit_filters"
                id="save"
                data-test-id="submit"
                onClick={(evt: React.MouseEvent<HTMLButtonElement>) => {
                  evt.preventDefault();
                  setShowMobileFilter(false);
                }}
              >
                APPLY
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};
