import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import { Controller, FieldValues, UseControllerProps, UseFormSetValue } from "react-hook-form";
import { FormFields } from "../Dialogs/AnnotationDialog";

interface Props<T> extends UseControllerProps<any> {
  setValue: UseFormSetValue<FormFields>;
}

const StatusField = <T extends FieldValues>({ name, control, setValue }: Props<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value } }) => (
        <>
          <InputLabel id="annotation-status-select-label">Status</InputLabel>
          <Select
            labelId="annotation-status-select-label"
            value={value}
            variant="outlined"
            label="Status"
            onChange={(event) => setValue("approval_status", event.target.value as string)}
          >
            <MenuItem value={"pending"}>Pending review</MenuItem>
            <MenuItem value={"approved"}>Approved</MenuItem>
            <MenuItem value={"unapproved"}>Not approved</MenuItem>
          </Select>
        </>
      )}
    />
  );
};

export default StatusField;
