import React from "react";
import { isNull } from "../../../../utils/utilityFunctions";

interface Props {
  ratingValue: number | null;
  ratingCount: number | null;
  isDownloads?: boolean;
}

export const RatingsMarketBreakdown = (props: Props) => {
  const { ratingValue, ratingCount, isDownloads } = props;

  return (
    <>
      {/* @ts-ignore */}
      <p className="main">{isNull(ratingValue) || ratingValue == undefined ? "n/a" : `${ratingValue.toFixed(1)} / 5`}</p>
      {!isNull(ratingCount) && ratingCount !== undefined && (
        <div className="count">
          {ratingCount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {isDownloads ? "downloads" : "ratings"}
        </div>
      )}
    </>
  );
};
