import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import YrScenarioRows from "./YrScnarioRows";

interface Props {
  combined?: boolean;
  quarter?: string;
  yrScenarios?: string[];
}
export const QuarterMonths = (props: Props) => {
  const { quarter, combined, yrScenarios } = props;
  const marketParam = useSelector((state: RootStateOrAny) => state.parameters.market);

  if ((marketParam == "China" && quarter == "q1") || (marketParam != "China" && quarter == "q4")) {
    return (
      <div className={`${yrScenarios?.length == 1 ? "one_yr_scnr_no_var" : ""} quarter_title_monthly primary_background`}>
        {!combined && <span className="quarter_title"></span>}
        <div className="quarter_title_yrscnr">
          <span className="quarter_title">January</span>
          <YrScenarioRows yrScenarios={yrScenarios} />
        </div>
        <div className="quarter_title_yrscnr">
          <span className="quarter_title">February</span>
          <YrScenarioRows yrScenarios={yrScenarios} />
        </div>

        <div className="quarter_title_yrscnr">
          <span className="quarter_title">March</span>
          <YrScenarioRows yrScenarios={yrScenarios} />
        </div>
      </div>
    );
  }
  if ((marketParam == "China" && quarter == "q4") || (marketParam != "China" && quarter == "q3")) {
    return (
      <div className={`${yrScenarios?.length == 1 ? "one_yr_scnr_no_var" : ""} quarter_title_monthly primary_background`}>
        {!combined && <span className="quarter_title"></span>}
        <div className="quarter_title_yrscnr">
          <span className="quarter_title">October</span>
          <YrScenarioRows yrScenarios={yrScenarios} />
        </div>
        <div className="quarter_title_yrscnr">
          <span className="quarter_title">November</span>
          <YrScenarioRows yrScenarios={yrScenarios} />
        </div>
        <div className="quarter_title_yrscnr">
          <span className="quarter_title">December</span>
          <YrScenarioRows yrScenarios={yrScenarios} />
        </div>
      </div>
    );
  }

  if ((marketParam == "China" && quarter == "q3") || (marketParam != "China" && quarter == "q2")) {
    return (
      <div className={`${yrScenarios?.length == 1 ? "one_yr_scnr_no_var" : ""} quarter_title_monthly primary_background`}>
        {!combined && <span className="quarter_title"></span>}
        <div className="quarter_title_yrscnr">
          <span className="quarter_title">July</span>
          <YrScenarioRows yrScenarios={yrScenarios} />
        </div>
        <div className="quarter_title_yrscnr">
          <span className="quarter_title">August</span>
          <YrScenarioRows yrScenarios={yrScenarios} />
        </div>
        <div className="quarter_title_yrscnr">
          <span className="quarter_title">September</span>
          <YrScenarioRows yrScenarios={yrScenarios} />
        </div>
      </div>
    );
  }

  return (
    <div className={`${yrScenarios?.length == 1 ? "one_yr_scnr_no_var" : ""} quarter_title_monthly primary_background`}>
      {!combined && <span className="quarter_title"></span>}

      <div className="quarter_title_yrscnr">
        <span className="quarter_title" style={{ marginRight: `${combined} ? "":"-120%"` }}>
          April
        </span>
        <YrScenarioRows yrScenarios={yrScenarios} />
      </div>

      <div className="quarter_title_yrscnr">
        <span className="quarter_title" style={{ marginLeft: `${combined} ? "":"14%"` }}>
          May
        </span>
        <YrScenarioRows yrScenarios={yrScenarios} />
      </div>

      <div className="quarter_title_yrscnr">
        <span className="quarter_title" style={{ marginLeft: `${combined} ? "":"23%"` }}>
          June
        </span>{" "}
        <YrScenarioRows yrScenarios={yrScenarios} />
      </div>
    </div>
  );
};
