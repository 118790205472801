import * as am4charts from "@amcharts/amcharts4/charts";
import { ILineSeriesDataFields, XYChart } from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { color, IComponentDataFields } from "@amcharts/amcharts4/core";
import _ from "lodash";
import moment from "moment";
import React, { Component } from "react";
import "../../../assets/styles/component/legend.scss";
import { BLACK, CYAN, LIGHT_CYAN, LIGHT_GREY, RED, WHITE } from "../../../constants";
import { configureDateAxis, configureValueAxis, legendConfiguration } from "../helpers/configurations";
import { configureMTMColumnSeries } from "../helpers/mtmConfigurations";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: Array<any>;
  isCumulative: { cumulative: boolean; startDate: string };
  legendTop?: boolean;
  theme: ThemeInt;
  isActivationPage?: boolean;
  region?: string;
  marketGroup?: string;
  isUae?: boolean;
}

export class EuropeActivationChart extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.tooltipAdapter = this.tooltipAdapter.bind(this);
  }

  chartId = "cockpitActivationRatioChart";
  chart!: XYChart;

  componentDidMount() {
    this.initChart();
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      this.disposeChart();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.disposeChart();
  }

  disposeChart() {
    this.chart?.dispose();
  }

  initChart() {
    const { data, legendTop, theme, region, marketGroup, isActivationPage, isUae } = this.props;

    const startDateOfDash = moment().subtract(2, "months").startOf("month");

    const chartData = data.map((row) => ({
      ...row,
      dash: !isUae && moment(row.date, "YYYY-MM-DD").isSameOrAfter(startDateOfDash) ? 5 : 0,
      fillOpacity: !isUae && moment(row.date, "YYYY-MM-DD").isSameOrAfter(startDateOfDash) ? 0 : 1,
    }));

    const isMobile = window.innerWidth <= 666;

    this.chart = am4core.create(this.chartId, am4charts.XYChart);
    oceChartConfiguration(this.chart);
    this.chart.colors.list = [am4core.color(CYAN)];
    this.chart.data = chartData;
    this.chart.numberFormatter.numberFormat = "#a";

    //Add legends
    this.chart.legend = legendConfiguration(theme);
    this.chart.legend.position = legendTop || isMobile ? "top" : "right";
    this.chart.legend.valign = "top";
    this.chart.legend.labels.template.truncate = false;
    this.chart.legend.labels.template.wrap = false;
    this.chart.legend.itemContainers.template.paddingBottom = legendTop || isMobile ? 15 : 10;
    if (!legendTop) {
      this.chart.legend.itemContainers.template.width = 450;
    }
    if (isMobile) {
      this.chart.legend.itemContainers.template.width = 150;
    }

    // Create Axes
    const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.minGridDistance = 50;

    const alternateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    configureDateAxis(alternateAxis, theme);
    alternateAxis.renderer.minGridDistance = 50;
    alternateAxis.renderer.labels.template.disabled = true;
    alternateAxis.syncWithAxis = dateAxis;

    const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    configureValueAxis(valueAxis, theme);

    const percentageAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    configureValueAxis(percentageAxis, theme);
    percentageAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text + "%";
    });
    percentageAxis.renderer.opposite = true;
    percentageAxis.syncWithAxis = valueAxis;

    // Line Series
    const series = this.chart.series.push(new am4charts.LineSeries());

    series.dataFields = {
      ...series.dataFields,
      valueY: `rate`,
      dateX: "date",
      servicesY: `volume`,
      warrantyY: `total`,
      ratioY: "rate",
      targetY: `bp_g8_private_activation_ratio`,
    } as ILineSeriesDataFields;

    series.strokeWidth = 2;
    series.yAxis = percentageAxis;
    series.stroke = color(LIGHT_CYAN);
    series.fill = color(LIGHT_CYAN);
    series.name = "Activation ratio";
    series.propertyFields.strokeDasharray = "dash";

    if (series.tooltip) {
      series.tooltip.getFillFromObject = false;
      series.tooltip.autoTextColor = false;
      series.tooltip.background.fill = color(WHITE);
      series.tooltip.label.fill = color(BLACK);
    }

    const bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.circle.radius = 3.5;

    //Bullet adapter
    bullet.adapter.add("tooltipText", this.tooltipAdapter);

    // Bar series configuration
    const totalSeries = this.chart.series.push(new am4charts.ColumnSeries());
    totalSeries.dataFields = {
      ...series.dataFields,
      valueY: `rate`,
      dateX: "date",
      servicesY: `volume`,
      warrantyY: `total`,
      ratioY: "rate",
      targetY: `bp_g8_private_activation_ratio`,
    } as am4charts.IColumnSeriesDataFields;

    configureMTMColumnSeries(totalSeries, {
      name: "Services activated",
      valueY: "volume",
      color: CYAN,
      xAxis: alternateAxis,
      yAxis: valueAxis,
    });
    totalSeries.columns.template.propertyFields.strokeDasharray = "dash";
    totalSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
    totalSeries.strokeWidth = 2;

    totalSeries.columns.template.adapter.add("tooltipText", this.tooltipAdapter);

    const volumeSeries = this.chart.series.push(new am4charts.ColumnSeries());

    volumeSeries.dataFields = {
      ...series.dataFields,
      valueY: `rate`,
      dateX: "date",
      servicesY: `volume`,
      warrantyY: `total`,
      ratioY: "rate",
      targetY: `bp_g8_private_activation_ratio`,
    } as am4charts.IColumnSeriesDataFields;

    configureMTMColumnSeries(volumeSeries, {
      name: isUae ? "Connected Car Sold" : "Total warranty start",
      valueY: "total",
      color: LIGHT_GREY,
      xAxis: dateAxis,
      yAxis: valueAxis,
    });
    volumeSeries.columns.template.propertyFields.strokeDasharray = "dash";
    volumeSeries.columns.template.propertyFields.fillOpacity = "fillOpacity";
    volumeSeries.strokeWidth = 2;

    volumeSeries.columns.template.adapter.add("tooltipText", this.tooltipAdapter);

    if ((region === "Europe" && !isActivationPage) || (isActivationPage && marketGroup === "G8")) {
      const bpSeries = this.chart.series.push(new am4charts.LineSeries());

      bpSeries.dataFields = {
        ...series.dataFields,
        valueY: `bp_g8_private_activation_ratio`,
        dateX: "date",
        servicesY: `volume`,
        warrantyY: `total`,
        ratioY: "rate",
        targetY: `bp_g8_private_activation_ratio`,
      } as ILineSeriesDataFields;

      bpSeries.strokeWidth = 2;
      bpSeries.yAxis = percentageAxis;
      bpSeries.stroke = color(RED);
      bpSeries.fill = color(RED);
      bpSeries.name = "BP Target";
      bpSeries.propertyFields.strokeDasharray = "dash";

      if (bpSeries.tooltip) {
        bpSeries.tooltip.getFillFromObject = false;
        bpSeries.tooltip.autoTextColor = false;
        bpSeries.tooltip.background.fill = color(WHITE);
        bpSeries.tooltip.label.fill = color(BLACK);
      }

      const bpBullet = bpSeries.bullets.push(new am4charts.CircleBullet());
      bpBullet.circle.radius = 3.5;
    }

    //Bullet adapter
    bullet.adapter.add("tooltipText", this.tooltipAdapter);
  }

  tooltipAdapter(text: string | undefined, target: am4charts.Column | am4charts.CircleBullet) {
    const cumulativeDateString = this.props.isCumulative?.cumulative
      ? `${moment(this.props.isCumulative?.startDate, "MMMM YYYY").format("MMM YYYY")} to {dateX.formatDate('MMM YYYY')}`
      : `{dateX.formatDate('MMM YYYY')}`;

    const data = target.dataItem?.dataContext as Record<string, number | string>;
    const dataFields = target.parent?.dataItem?.component?.dataFields as IComponentDataFields & { warrantyY: string };

    const warrantyKey: string = dataFields?.warrantyY;
    const cumulativeStartDateMatchesStartDate = moment(data?.date, "YYYY-MM-DD").format("MMMM YYYY") === this.props.isCumulative?.startDate;

    const warrantyValue = warrantyKey && data?.[warrantyKey] ? `{warrantyY.formatNumber('#,###')}` : "0";

    return `[bold]${
      this.props.isCumulative?.cumulative && cumulativeStartDateMatchesStartDate ? `{dateX.formatDate('MMM YYYY')}` : cumulativeDateString
    }[/]
      Activation ratio: {ratioY.formatNumber('#.')}%
      Services activated: {servicesY.formatNumber('#,###')}
      ${this.props.isUae ? "Connected Cars Sold" : "Total warranty start"}: ${warrantyValue}
      BP Target: {targetY.formatNumber('#.')}%`;
  }

  render() {
    return (
      <div className={"chartWrapper"}>
        <div id={this.chartId} className={"graph"} data-test-id={this.chartId} />
      </div>
    );
  }
}
