import React, { Fragment } from "react";
import { ALL_COUNTRIES } from "../../constants";

interface Props {
  onShowFilterOptions: any;
  geographies: any;
  onHandleMarketArrowClick?: any;
  onHandleGeographyOptionsClick: any;
  value: any;
}

export const CockpitGeographyFilter = (props: Props) => {
  const { onShowFilterOptions, geographies, onHandleMarketArrowClick, onHandleGeographyOptionsClick, value } = props;

  return (
    <div className="filter" id="geography_filter_div">
      <label className="filter_header" htmlFor={"region_country_filter"}>
        GEOGRAPHY
      </label>

      <div className="input_arrow" onClick={onShowFilterOptions} data-options-ul={"region_ul"}>
        <input readOnly type="text" id="region_country_filter" className={"filter_input"} data-test-id="region" value={value} />
        <span className="arrow_down " />
      </div>

      <ul className="filter_options cropped_region_ul" id="region_ul" data-test-id="region_list">
        {geographies?.map(({ region, markets }: { region: string; markets: string[] }, index: number) => {
          const hasMarket = markets?.length > 0 ? `region${index}` : "region0";
          const marketUlId = `market_ul${index}`;

          if (region === "North America") {
            return (
              <Fragment key={region}>
                <div className={"region-list"} key={region}>
                  <span
                    id={`market-arrow${index}`}
                    className={`region-span ${hasMarket}`}
                    data-market-target={marketUlId}
                    onClick={onHandleMarketArrowClick}
                  ></span>
                  <li onClick={onHandleGeographyOptionsClick} data-region={region} data-market={"United States"}>
                    {"United States"}
                  </li>
                </div>
              </Fragment>
            );
          } else {
            return (
              <Fragment key={region}>
                <div className={"region-list"} key={region}>
                  <span
                    id={`market-arrow${index}`}
                    className={`region-span ${hasMarket}`}
                    data-market-target={marketUlId}
                    onClick={onHandleMarketArrowClick}
                  >
                    <span className={"market-arrow"} data-market-target={marketUlId} />
                  </span>
                  <li onClick={onHandleGeographyOptionsClick} data-region={region} data-market={ALL_COUNTRIES}>
                    {region}
                  </li>
                </div>
                {markets && markets?.length > 0 ? (
                  <ul className={"region_country_filter"} id={marketUlId}>
                    {markets.map((market) => (
                      <li key={market} data-region={region} data-market={market} onClick={onHandleGeographyOptionsClick}>
                        {market}
                      </li>
                    ))}
                  </ul>
                ) : (
                  ""
                )}
              </Fragment>
            );
          }
        })}
      </ul>
    </div>
  );
};
