import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { setSpecificParameter } from "../../actions";
import { ALL_OPTION_NO_SPACE, DEFAULT_EUROPE_ACTIVATION_PARAMETERS } from "../../constants";
import { FilterContext } from "../../context";
import { checkConsecutiveMonths } from "../../pages/CCS Vital Signs/subs/utils";
import { groupByKeys } from "../../utils/computeFunctions";
import { enumerateDaysBetweenDates, FY_TWENTY } from "../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../utils/userTracking";
import { ErrorMsg } from "../AppMessages";
import { FiltersLayout } from "../Layouts";
import { DefaultFilter, EuropeActivationMarketFilter, MultiSelectFilter } from "./common";
import DealerFilter from "./common/DealerFilter";
import { EuropeActivationDateFilter } from "./common/EuropeActivationDateFilter";
import { closeFilterOptions, closeMarketOptions, objectToQuerystring } from "./subs/helpers";

export const CcsEuropeActivationFilters = withRouter((props: RouteComponentProps): JSX.Element => {
  const { history } = props;
  const dispatch = useDispatch();
  const {
    models,
    geographies,
    dealers,
    sales_channels: channels,
    date: availableDates,
  } = useSelector((state: RootStateOrAny) => state.filters.europe_activation_filters);
  const market_groups = useSelector((state: RootStateOrAny) => state.filters.europe_activation_market_groups);
  //Filter options
  const [modelsList, setModelsList] = useState<string[]>([]);
  const [marketsList, setMarketsList] = useState<string[]>([]);
  const [marketGroupsList, setMarketGroupsList] = useState<Array<string>>([]);
  const [salesChannelList, setSalesChannelList] = useState<string[]>([]);
  const [dealersList, setDealersList] = useState<string[]>([]);
  const [topFiveDealers, setTopFiveDealers] = useState<string[]>([]);
  const [bottomFiveDealers, setBottomFiveDealers] = useState<string[]>([]);
  const [datesList, setDatesList] = useState<any>([]);
  const [dealersFilterOptions, setDealersFilterOptions] = useState<string[]>([]);
  const [allOptionList] = useState<Array<string>>(["All"]);

  //Parameter values
  const params = useSelector((state: RootStateOrAny) => state.activation_parameters);
  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.activation_parameters.date_range);
  const marketParamValue = useSelector((state: RootStateOrAny) => state.activation_parameters.market);
  const marketGroupParamValue = useSelector((state: RootStateOrAny) => state.activation_parameters.market_group);
  const modelParamValue = useSelector((state: RootStateOrAny) => state.activation_parameters.model);
  const salesChannelParamValue = useSelector((state: RootStateOrAny) => state.activation_parameters.sales_channel);
  const dealerParamValue = useSelector((state: RootStateOrAny) => state.activation_parameters.dealer);

  //Filter input values
  const [dateRangeFilterValue, setDateRangeFilterValue] = useState<string>("");
  const [modelFilterValue, setModelFilterValue] = useState<string>("");
  const [marketFilterValue, setMarketFilterValue] = useState<string>("");
  const [dealerFilterValue, setDealerFilterValue] = useState<string>("");
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  useEffect(() => {
    if (availableDates) {
      const startDate = "2019-04-01";
      const { max_date } = availableDates;
      const formattedMaxDate = moment(max_date, "YYYY-MM-DD");
      const dateDifference = moment().diff(formattedMaxDate, "months");
      const endDate = dateDifference > 0 ? formattedMaxDate : moment().format("YYYY-MM-DD");
      const datesArr = enumerateDaysBetweenDates({
        startDate: startDate,
        endDate: endDate,
        dateFormat: "MMMM YYYY",
        valueToIncrement: "months",
      });
      setDatesList(datesArr);
    }
  }, [availableDates]);

  const groupedDateRange = useMemo(() => {
    if (datesList) {
      const nestedDates = datesList.map((dateValue: string) => {
        const monthValue = dateValue?.split(" ")[0];
        const yearValue = dateValue?.split(" ")[1];
        return { year: Number(yearValue), month: monthValue };
      });
      const groupedDates = groupByKeys(nestedDates, "year");
      return Object.keys(groupedDates).map((year) => ({ year, child: groupedDates[year] }));
    }
    return [];
  }, [datesList]);

  //Manipulate filters lists to get markets, models, and channels options
  useEffect(() => {
    if (geographies) {
      const formattedMarkets = geographies.sort((a: string, b: string) => a.localeCompare(b));

      setMarketsList(formattedMarkets);
    }
  }, [geographies]);

  useEffect(() => {
    if (models) {
      const formattedModels = models.sort((a: string, b: string) => a.localeCompare(b));

      setModelsList(formattedModels);
    }
  }, [models]);

  useEffect(() => {
    if (channels) {
      const formattedChannels = channels.sort((a: string, b: string) => a.localeCompare(b));

      setSalesChannelList([...allOptionList, ...formattedChannels]);
    }
  }, [channels]);

  useEffect(() => {
    if (salesChannelParamValue && salesChannelList.length > 0 && !salesChannelList.includes(salesChannelParamValue)) {
      dispatch(setSpecificParameter("sales_channel", "All"));
    }
  }, [salesChannelParamValue, salesChannelList]);

  //Manipulate dealers filter data to get dealers filter options, top and bottom five dealers
  useEffect(() => {
    const allDealers = dealers?.map((dealer: any) => dealer.dealer);
    const sortedDealers = allDealers ? [...allDealers].sort((a: string, b: string) => a.localeCompare(b)) : [];
    const topFiveDealers = allDealers ? [...allDealers].slice(0, 5) : [];
    const bottomFiveDealers = allDealers ? [...allDealers].slice(Math.max(allDealers.length - 5, 0)) : [];

    setDealersList(sortedDealers);
    setTopFiveDealers(topFiveDealers);
    setBottomFiveDealers(bottomFiveDealers);
  }, [marketFilterValue, dealers]);

  //Update the market groups list when market groups are fetched
  useEffect(() => {
    if (market_groups) {
      const sortedMarketGroups: Array<string> = market_groups?.sort((a: string, b: string) => a.localeCompare(b));
      setMarketGroupsList(sortedMarketGroups);
    }
  }, [market_groups]);

  //Set date range filter value
  useEffect(() => {
    if (datesList && dateRangeParamValue) {
      const selectedDateArray: Array<string> = dateRangeParamValue?.split(",");
      const checkConsecutive = checkConsecutiveMonths(selectedDateArray, datesList);
      const isFinancialYearTwenty =
        selectedDateArray?.length === FY_TWENTY.length && selectedDateArray?.every((dateValue) => FY_TWENTY.includes(dateValue));

      let dateRangeInputValue: string;
      if (isFinancialYearTwenty) {
        dateRangeInputValue = "FY20";
      } else {
        dateRangeInputValue =
          dateRangeParamValue?.split(",").length > 1
            ? checkConsecutive.isConsecutive
              ? checkConsecutive.consecutiveDateRange
              : `${selectedDateArray.length} months selected`
            : dateRangeParamValue;
      }

      setDateRangeFilterValue(dateRangeInputValue);
    }
  }, [dateRangeParamValue, datesList]);

  //Set model filter value
  useEffect(() => {
    if (modelsList && modelParamValue) {
      const selectedModelsArr = modelParamValue?.split(",");
      const modelValue =
        selectedModelsArr?.length === modelsList?.length
          ? "All"
          : selectedModelsArr?.length > 1
          ? `${selectedModelsArr?.length} models selected`
          : modelParamValue;
      setModelFilterValue(modelValue);
      dispatch(setSpecificParameter("all_models_selected", modelValue.includes("All") ? true : false));
    }
  }, [modelParamValue, modelsList]);

  //Set geo filter value
  useEffect(() => {
    if (marketsList && marketParamValue) {
      const selectedMarketsArr = marketParamValue?.split(",");
      const marketValue =
        selectedMarketsArr?.length === marketsList?.length
          ? "All"
          : selectedMarketsArr?.length > 1
          ? `${selectedMarketsArr?.length} markets selected`
          : marketParamValue;
      setMarketFilterValue(marketValue);
    } else {
      setMarketFilterValue(ALL_OPTION_NO_SPACE);
    }
  }, [marketParamValue, marketsList, marketGroupParamValue]);

  //Set dealer filter value
  useEffect(() => {
    const selectedDealersArr = dealerParamValue.split(",");
    const topFiveDealersSelected =
      selectedDealersArr.length === 5 && selectedDealersArr?.every((dealer: string) => topFiveDealers?.includes(dealer));
    const bottomFiveDealersSelected =
      selectedDealersArr.length === 5 && selectedDealersArr?.every((dealer: string) => bottomFiveDealers?.includes(dealer));
    const dealerValue =
      selectedDealersArr?.length === dealersList?.length
        ? "All"
        : topFiveDealersSelected
        ? "Top 5 dealers selected"
        : bottomFiveDealersSelected
        ? "Bottom 5 dealers selected"
        : selectedDealersArr.length > 1
        ? `${selectedDealersArr.length} dealers selected`
        : dealerParamValue;
    setDealerFilterValue(dealerValue);
  }, [dealerParamValue, topFiveDealers, bottomFiveDealers]);

  //Set param values
  useEffect(() => {
    if (Object.keys(params).length > 0) {
      history.push({ search: `?${objectToQuerystring(params)}` });
    }
  }, [params]);

  //Reset dates if they are invalid
  useEffect(() => {
    if (datesList?.length > 1) {
      const dateParamValuesArr = dateRangeParamValue?.split(",");
      const datesAreValid = dateParamValuesArr?.every((dateValue: string) => datesList?.includes(dateValue));
      const availableCalendarYears = Array.from(new Set(datesList.map((option: string) => option.split(" ")[1])));

      if (!datesAreValid) {
        const defaultSelection = datesList.filter((option: any) => {
          const calendarYearRe = new RegExp(`${availableCalendarYears[availableCalendarYears.length - 1]}`, "gi");

          return calendarYearRe.test(option);
        });
        dispatch(setSpecificParameter("date_range", defaultSelection.join(",")));
      }
    }
  }, [datesList, dateRangeParamValue]);

  //Reset the market filter param if the param value is not in the markets array
  useEffect(() => {
    if (marketGroupParamValue && marketGroupParamValue !== "") {
      setMarketFilterValue(marketGroupParamValue);
      dispatch(setSpecificParameter("market", ""));
    } else {
      if (marketsList?.length > 1) {
        const marketParamValuesArr = marketParamValue?.split(",");
        const marketsAreValid = marketParamValuesArr?.every((market: string) => marketsList?.includes(market));
        if (!marketsAreValid || marketParamValue?.includes("countries")) {
          dispatch(setSpecificParameter("market", marketsList?.join(",")));
        }
      }
    }
  }, [marketsList, marketParamValue, marketGroupParamValue]);

  //Reset the model parameter value if the value is invalid
  useEffect(() => {
    if (modelsList?.length > 1) {
      const modelParamValuesArr = modelParamValue?.split(",");
      const modelsAreValid = modelParamValuesArr?.every((market: string) => modelsList?.includes(market));
      if ((modelsList?.length > 1 && !modelsAreValid) || modelParamValue?.includes(ALL_OPTION_NO_SPACE)) {
        dispatch(setSpecificParameter("model", modelsList?.join(",")));
      }
    }
  }, [modelsList, modelParamValue]);

  //Reset dealers if value is not accurate
  useEffect(() => {
    if (marketFilterValue?.includes(ALL_OPTION_NO_SPACE) || dealersList?.length === 0) {
      setDealersFilterOptions([]);
      dispatch(setSpecificParameter("dealer", ALL_OPTION_NO_SPACE));
    } else {
      setDealersFilterOptions(dealersList);
    }

    if (dealersList?.length > 1) {
      const dealerParamValueArr = dealerParamValue?.split(",");
      const selectedDelearsAreValid = dealerParamValueArr?.every((dealer: string) => dealersList?.includes(dealer));
      if (!selectedDelearsAreValid) {
        dispatch(setSpecificParameter("dealer", ALL_OPTION_NO_SPACE));
      }
    }
  }, [dealersList, dealerParamValue, marketFilterValue]);

  const showFilterOptionsFn = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const optionsId = dataset?.optionsUl;
      const shownClass = "showOptions";

      const optionsElement = optionsId ? document.getElementById(optionsId) : undefined;

      if (showFilterOptions && optionsElement?.classList.contains(shownClass)) {
        closeFilterOptions();
        closeMarketOptions();
        setShowFilterOptions(false);
      } else {
        closeFilterOptions();
        optionsElement?.classList.toggle(shownClass);
        setShowFilterOptions(!showFilterOptions);
      }
    },
    [showFilterOptions, setShowFilterOptions]
  );

  const handleModelFilterSubmission = useCallback(() => {
    const parameterName = "model";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );

    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = modelsList.length === selectedValues.length ? "All" : selectedValues.join(",");
    dispatch(setSpecificParameter("all_models_selected", filterValue.includes("All") ? true : false));

    closeFilterOptions();
    dispatch(setSpecificParameter("model", filterValue));
    eventTracking(MixpanelEvents.filter_change, { filter: "model", value: filterValue, dashboard: "CCS", page: "Europe activation" });
  }, []);

  const handleDefaultFilterSubmission = useCallback((evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;
    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));
      eventTracking(MixpanelEvents.filter_change, { filter: filterName, value: optionValue, dashboard: "CCS", page: "Europe activation" });
    }

    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);
  }, []);

  const resetFilters = useCallback(() => {
    eventTracking(MixpanelEvents.filter_reset, { dashboard: "CCS", page: location.pathname });

    const params = new URLSearchParams();

    for (const [key, value] of Object.entries(DEFAULT_EUROPE_ACTIVATION_PARAMETERS)) {
      params.set(key, value);
    }

    history.push({ search: `?${params.toString()}` });

    window.location.reload();
  }, []);

  // Set params
  useEffect(() => {
    if (Object.keys(params).length > 0) {
      history.push({ search: `?${objectToQuerystring(params)}` });
    }
  }, [params]);

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <FiltersLayout resetFilters={resetFilters}>
        <>
          {/**DATE FILTER */}
          <EuropeActivationDateFilter
            filterValue={dateRangeFilterValue}
            groupedDatesList={groupedDateRange}
            dateRangeParamValue={dateRangeParamValue}
            showFilterOptions={showFilterOptionsFn}
          />

          {/* GEOGRAPHY FILTER */}
          {marketsList?.length > 1 ? (
            <EuropeActivationMarketFilter
              filterValue={marketFilterValue}
              marketsList={marketsList}
              marketGroupsList={marketGroupsList}
              showFilterOptions={showFilterOptionsFn}
              marketParamValue={marketParamValue}
              marketGroupParamValue={marketGroupParamValue}
            />
          ) : (
            <DefaultFilter
              filterName={"market"}
              list={allOptionList}
              filterValue="All"
              filterLabel={"GEOGRAPHY"}
              handleFilterOptionClick={handleDefaultFilterSubmission}
            />
          )}
          {/* MODEL FILTER */}
          {modelsList?.length > 1 ? (
            <MultiSelectFilter
              parentKey="model"
              // @ts-ignore
              filterList={modelsList}
              filterName="MODEL"
              value={modelFilterValue}
              parameterName={"model"}
              parameterValue={modelParamValue}
              onShowFilterOptions={showFilterOptionsFn}
              handleFilterSubmission={handleModelFilterSubmission}
            />
          ) : (
            <DefaultFilter
              filterName={"model"}
              list={allOptionList}
              filterValue={"All"}
              filterLabel={"MODEL"}
              handleFilterOptionClick={handleDefaultFilterSubmission}
            />
          )}
          {/* SALES CHANNEL FILTER */}
          {salesChannelList?.length > 1 && (
            <DefaultFilter
              filterName={"sales_channel"}
              list={salesChannelList}
              filterValue={salesChannelParamValue}
              filterLabel={"SALES CHANNEL"}
              handleFilterOptionClick={handleDefaultFilterSubmission}
            />
          )}
          <DealerFilter
            filterValue={dealerFilterValue}
            list={dealersFilterOptions}
            showFilterOptions={showFilterOptionsFn}
            dealerParamValue={dealerParamValue}
            topFiveDealers={topFiveDealers}
            bottomFiveDealers={bottomFiveDealers}
            marketFilterValue={marketFilterValue}
            page="Europe activation"
          />
        </>
      </FiltersLayout>
    </ErrorBoundary>
  );
});
