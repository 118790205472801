import {
  IAriyaMonitorData,
  IHandraisersByCity,
  IHandraisersConversion,
  IHandraisersNAE,
  IHandraisersRegionSplit,
  IPopularData,
} from "../../constants/interface/ariya";
import { postData } from "../utils";
import { apiPrefix, apiPrefix_Dp, ariyaPrefix } from "./constants";

export const fetchHandraiserNAEData = (): Promise<IHandraisersNAE> => postData(`${apiPrefix}/NAEHandRaisersTrendGraph_for_testing`, {});
export const fetchHandraiserNAEData_Total = (): Promise<IAriyaMonitorData> => postData(`${apiPrefix_Dp}/ariya_hand_raisers`, {});
export const fetchGradesData = (): Promise<IPopularData> => postData(`${apiPrefix}/NAETopThreeGrades_for_testing`, {});
export const fetchColoursData = (): Promise<IPopularData> => postData(`${apiPrefix}/NAETopThreeColors_for_testing`, {});
export const fetchHandraisersConversionData = (): Promise<IHandraisersConversion> =>
  postData(`${apiPrefix}/HandraisersConversion_for_testing`, {});
export const fetchHandraisersConversionData_Nov_2021 = (): Promise<IHandraisersConversion> =>
  postData(`${apiPrefix}/HandraisersConversion_for_testing_nov_2021`, {});
export const fetchHandraisersCityBreakdownData = (): Promise<IHandraisersByCity> => postData(`${apiPrefix}/handraisersGeoCountryAll`, {});
export const fetchHandraisersRegionSplitData = (): Promise<IHandraisersRegionSplit> =>
  postData(`${ariyaPrefix}/handRaisersRegionSplitOverallNumber_for_testing`, {});
