import React, { useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchJourneyCost } from "../../../../actions/journeyExcellenceActions";
import JourneyExcellenceLineTrendChart from "../../../../components/Charts/JourneyExcellence/JourneyExcellenceLineTrendChart";
import { ToggleSwitch } from "../../../../components/Toogle";
import { BLUE, DARK_PURPLE, LIGHT_YELLOW } from "../../../../constants";
import { ThemeContext } from "../../../../context";
import DetailedValueTile from "../tiles/DetailedValueTile";

const JOURNEY_COST_KPI_COLORS: Record<string, string> = {
  ["Visits ACT"]: LIGHT_YELLOW,
  ["Visits BP"]: LIGHT_YELLOW,
  ["KBA visits ACT"]: BLUE,
  ["KBA visits BP"]: BLUE,
  ["Digital leads visits ACT"]: DARK_PURPLE,
  ["Digital leads visits BP"]: DARK_PURPLE,
};

const JourneyOverview = withRouter((props: RouteComponentProps) => {
  const { history } = props;
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();

  const {
    Current: currentWebsitePerformanceData,
    YoY: yoyWebsitePerformanceData,
    PoP: popWebsitePerformanceData,
    last_three_months: lastThreeMonthsWebsitePerformanceData,
  } = useSelector((state: RootStateOrAny) => state.journey_excellence.website_performance);
  const websitePerformanceLoading = useSelector((state: RootStateOrAny) => state.loading.website_performance);
  const { series: websitePerformanceTrendSeries, data: websitePerformanceTrendData } = useSelector(
    (state: RootStateOrAny) => state.journey_excellence.website_performance_trend
  );
  const websitePerformanceTrendLoading = useSelector((state: RootStateOrAny) => state.loading.website_performance_trend);
  const {
    Current: currentJourneyCostData,
    YoY: yoyJourneyCostData,
    PoP: popJourneyCostData,
    last_three_months: lastThreeMonthsJourneyCostData,
  } = useSelector((state: RootStateOrAny) => state.journey_excellence.journey_cost);
  const journeyCostLoading = useSelector((state: RootStateOrAny) => state.loading.journey_cost);
  const { series: journeyCostTrendSeries, data: journeyCostTrendData } = useSelector(
    (state: RootStateOrAny) => state.journey_excellence.journey_cost_trend
  );
  const journeyCostTrendLoading = useSelector((state: RootStateOrAny) => state.loading.journey_cost_trend);

  const [toggleActive, setToggleActive] = useState(true);

  useEffect(() => {
    dispatch(fetchJourneyCost(toggleActive));
  }, [history.location.search, toggleActive]);

  const formattedJourneyCostTrendSeries = useMemo(() => {
    if (journeyCostTrendSeries) {
      return journeyCostTrendSeries.map((series: any) => {
        return { ...series, color: JOURNEY_COST_KPI_COLORS[series.name as string] };
      });
    }
    return [];
  }, [journeyCostTrendSeries, JOURNEY_COST_KPI_COLORS]);

  return (
    <>
      <div className="metric-comparison-row">
        <div className="tiles-container">
          <div>Website performance</div>
          <div className="metric-comparison-tiles ">
            <DetailedValueTile
              title="Website visits"
              mainValue={currentWebsitePerformanceData?.visits}
              yoyValue={yoyWebsitePerformanceData?.perc_visits}
              popValue={popWebsitePerformanceData?.perc_visits}
              lastThreeMonthsValue={lastThreeMonthsWebsitePerformanceData?.visits}
              lastThreeMonthsStartDate={lastThreeMonthsWebsitePerformanceData?.start_date}
              lastThreeMonthsEndDate={lastThreeMonthsWebsitePerformanceData?.end_date}
              isLoading={websitePerformanceLoading}
              tooltipText="Website visit happens every time the site has at least one page fully loaded."
            />
            <DetailedValueTile
              title="KBA visits"
              mainValue={currentWebsitePerformanceData?.kbas}
              yoyValue={yoyWebsitePerformanceData?.perc_kbas}
              popValue={popWebsitePerformanceData?.perc_kbas}
              lastThreeMonthsValue={lastThreeMonthsWebsitePerformanceData?.kbas}
              lastThreeMonthsStartDate={lastThreeMonthsWebsitePerformanceData?.start_date}
              lastThreeMonthsEndDate={lastThreeMonthsWebsitePerformanceData?.end_date}
              isLoading={websitePerformanceLoading}
              tooltipText="KBA or Key Buying Actions visits are the count of visits including at least one key buying action as Model detail compare, Saved searches views, Compare tools views and Dealer search. "
            />
            <DetailedValueTile
              title="Leads visits"
              mainValue={currentWebsitePerformanceData?.leads}
              yoyValue={yoyWebsitePerformanceData?.perc_leads}
              popValue={popWebsitePerformanceData?.perc_leads}
              lastThreeMonthsValue={lastThreeMonthsWebsitePerformanceData?.leads}
              lastThreeMonthsStartDate={lastThreeMonthsWebsitePerformanceData?.start_date}
              lastThreeMonthsEndDate={lastThreeMonthsWebsitePerformanceData?.end_date}
              isLoading={websitePerformanceLoading}
              tooltipText="Lead visits are the count of visits including at least one digital lead as Enquire, Test drive, Request a video and Reserve."
            />
          </div>
        </div>

        <div className="chart-container">
          <div>Website performance trend</div>
          <div className="metric-comparison-chart">
            <JourneyExcellenceLineTrendChart
              theme={themeContext.theme}
              seriesList={websitePerformanceTrendSeries}
              data={websitePerformanceTrendData}
              chartId="websitePerformanceChart"
              isLoading={websitePerformanceTrendLoading}
            />
          </div>
        </div>
      </div>
      <div className="metric-comparison-row">
        <div className="tiles-container">
          <div className="outer-tile-header">
            <div>Journey costs</div>
            <ToggleSwitch
              activeToggleLabel="Show only ACT"
              inactiveToggleLabel="Show ACT + BP"
              handleToggleClick={() => setToggleActive((prevState) => !prevState)}
              active={toggleActive}
            />
          </div>
          <div className="metric-comparison-tiles ">
            <DetailedValueTile
              title="Cost per visit"
              mainValue={currentJourneyCostData?.cost_per_visit}
              yoyValue={yoyJourneyCostData?.perc_cost_per_visit}
              popValue={popJourneyCostData?.perc_cost_per_visit}
              lastThreeMonthsValue={lastThreeMonthsJourneyCostData?.cost_per_visit}
              lastThreeMonthsStartDate={lastThreeMonthsWebsitePerformanceData?.start_date}
              lastThreeMonthsEndDate={lastThreeMonthsWebsitePerformanceData?.end_date}
              isLoading={journeyCostLoading}
              showCurrency={true}
              ratingInverse={true}
              ratingArrowInverse={true}
              tooltipText="Website visit happens every time the site has at least one page fully loaded."
            />
            <DetailedValueTile
              title="Cost per KBA visits"
              mainValue={currentJourneyCostData?.cost_per_kba}
              yoyValue={yoyJourneyCostData?.perc_cost_per_kba}
              popValue={popJourneyCostData?.perc_cost_per_kba}
              lastThreeMonthsValue={lastThreeMonthsJourneyCostData?.cost_per_kba}
              lastThreeMonthsStartDate={lastThreeMonthsWebsitePerformanceData?.start_date}
              lastThreeMonthsEndDate={lastThreeMonthsWebsitePerformanceData?.end_date}
              isLoading={journeyCostLoading}
              showCurrency={true}
              ratingInverse={true}
              ratingArrowInverse={true}
              tooltipText="KBA or Key Buying Actions visits are the count of visits including at least one key buying action as Model detail compare, Saved searches views, Compare tools views and Dealer search. "
            />
            <DetailedValueTile
              title="Cost per Leads visits"
              mainValue={currentJourneyCostData?.cost_per_lead}
              yoyValue={yoyJourneyCostData?.perc_cost_per_lead}
              popValue={popJourneyCostData?.perc_cost_per_lead}
              lastThreeMonthsValue={lastThreeMonthsJourneyCostData?.cost_per_lead}
              lastThreeMonthsStartDate={lastThreeMonthsWebsitePerformanceData?.start_date}
              lastThreeMonthsEndDate={lastThreeMonthsWebsitePerformanceData?.end_date}
              isLoading={journeyCostLoading}
              showCurrency={true}
              ratingInverse={true}
              ratingArrowInverse={true}
              tooltipText="Lead visits are the count of visits including at least one digital lead as Enquire, Test drive, Request a video and Reserve."
            />
          </div>
        </div>

        <div className="chart-container">
          <div>Journey costs trend</div>
          <div className="metric-comparison-chart">
            <JourneyExcellenceLineTrendChart
              theme={themeContext.theme}
              seriesList={formattedJourneyCostTrendSeries}
              data={journeyCostTrendData}
              chartId="journeyCostChart"
              isLoading={journeyCostTrendLoading}
              showCurrency={true}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default JourneyOverview;
