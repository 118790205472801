import { ErrorBoundary } from "@sentry/react";
import * as React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import LoadingEllipsis from "../../../components/Loading/LoadingEllipsis";
import UsedCarsVolumeTable from "../../../components/Tables/UsedCarsVolumeTable";
import PmoTooltip from "../../ProductMarketingOptimization/components/common/PmoTooltip";
import { TotalValuesInPeriodParagrahphs } from "../helpers/tooltiptexts";

const DealerSalesDataTable = () => {
  const data = useSelector((state: RootStateOrAny) => state.cpo_operational.total_values_data);
  const loading = useSelector((state: RootStateOrAny) => state.loading.total_values);

  return (
    <div className="dealer_table">
      <div className="tables">
        <div className="header">
          <h3 className="trendline_title tile_title">Total values in the period</h3>
          <PmoTooltip paragraphs={<TotalValuesInPeriodParagrahphs />} />
        </div>
        {data?.length && !loading ? (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <UsedCarsVolumeTable tableData={data} tableId="volumeTable" />
          </ErrorBoundary>
        ) : loading ? (
          <LoadingEllipsis isLoading={loading} />
        ) : (
          <NoDataMsg message="No data available" />
        )}
      </div>
    </div>
  );
};

export default DealerSalesDataTable;
