export const FETCH_EXECUTIVE_SUMMARY_DIS_SUMMARY = "FETCH_EXECUTIVE_SUMMARY_DIS_SUMMARY";
export const SET_EXECUTIVE_SUMMARY_DIS_SUMMARY = "SET_EXECUTIVE_SUMMARY_DIS_SUMMARY";

export const FETCH_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL = "FETCH_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL";
export const SET_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL = "SET_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL";

export const FETCH_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL_TABLE = "FETCH_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL_TABLE";
export const SET_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL_TABLE = "SET_EXECUTIVE_SUMMARY_AGGREGATED_PURCHASE_FUNNEL_TABLE";

export const FETCH_EXECUTIVE_SUMMARY_DETAILED_COMPARISONS = "FETCH_EXECUTIVE_SUMMARY_DETAILED_COMPARISONS";
export const SET_EXECUTIVE_SUMMARY_DETAILED_COMPARISONS = "SET_EXECUTIVE_SUMMARY_DETAILED_COMPARISONS";

export const FETCH_EXECUTIVE_SUMMARY_CHANNEL_MIX_PERFORMANCE = "FETCH_EXECUTIVE_SUMMARY_CHANNEL_MIX_PERFORMANCE";
export const SET_EXECUTIVE_SUMMARY_CHANNEL_MIX_PERFORMANCE = "SET_EXECUTIVE_SUMMARY_CHANNEL_MIX_PERFORMANCE";

export const FETCH_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_MARKET = "FETCH_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_MARKET";
export const SET_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_MARKET = "SET_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_MARKET";

export const FETCH_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_DATE = "FETCH_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_DATE";
export const SET_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_DATE = "SET_EXECUTIVE_SUMMARY_PRIVATE_SALES_BY_DATE";

export const FETCH_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_MARKET = "FETCH_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_MARKET";
export const SET_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_MARKET = "SET_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_MARKET";

export const FETCH_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_DATE = "FETCH_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_DATE";
export const SET_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_DATE = "SET_EXECUTIVE_SUMMARY_DIS_PERCENTAGE_BY_DATE";

export const FETCH_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_MARKET = "FETCH_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_MARKET";
export const SET_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_MARKET = "SET_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_MARKET";

export const FETCH_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_DATE = "FETCH_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_DATE";
export const SET_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_DATE = "SET_EXECUTIVE_SUMMARY_CLOSE_RATE_BY_DATE";

export const FETCH_EXECUTIVE_SUMMARY_KEY_INDICATORS = "FETCH_EXECUTIVE_SUMMARY_KEY_INDICATORS";
export const SET_EXECUTIVE_SUMMARY_KEY_INDICATORS = "SET_EXECUTIVE_SUMMARY_KEY_INDICATORS";
