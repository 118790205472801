export const FETCH_MARKET_OVERVIEW = "FETCH_MARKET_OVERVIEW";
export const SET_MARKET_OVERVIEW = "SET_MARKET_OVERVIEW";

export const FETCH_STAR_RATING_AVERAGE_RATING = "FETCH_STAR_RATING_AVERAGE_RATING";
export const SET_STAR_RATING_AVERAGE_RATING = "SET_STAR_RATING_AVERAGE_RATING";

export const FETCH_STAR_RATING_AVERAGE_RATING_TREND = "FETCH_STAR_RATING_AVERAGE_RATING_TREND";
export const SET_STAR_RATING_AVERAGE_RATING_TREND = "SET_STAR_RATING_AVERAGE_RATING_TREND";

export const FETCH_STAR_RATING_FEEDBACK = "FETCH_STAR_RATING_FEEDBACK";
export const SET_STAR_RATING_FEEDBACK = "SET_STAR_RATING_FEEDBACK";

export const FETCH_STAR_RATING_SCORE_DISTRIBUTION = "FETCH_STAR_RATING_SCORE_DISTRIBUTION";
export const SET_STAR_RATING_SCORE_DISTRIBUTION = "SET_STAR_RATING_SCORE_DISTRIBUTION";

export const FETCH_STAR_RATING_DEVICE_DISTRIBUTION = "FETCH_STAR_RATING_DEVICE_DISTRIBUTION";
export const SET_STAR_RATING_DEVICE_DISTRIBUTION = "SET_STAR_RATING_DEVICE_DISTRIBUTION";

export const FETCH_STAR_RATING_AVERAGE_RATING_BY_MARKET = "FETCH_STAR_RATING_AVERAGE_RATING_BY_MARKET";
export const SET_STAR_RATING_AVERAGE_RATING_BY_MARKET = "SET_STAR_RATING_AVERAGE_RATING_BY_MARKET";

export const FETCH_STAR_RATING_AVERAGE_RATING_SELECTED_MARKETS = "FETCH_STAR_RATING_AVERAGE_RATING_SELECTED_MARKETS";
export const SET_STAR_RATING_AVERAGE_RATING_SELECTED_MARKETS = "SET_STAR_RATING_AVERAGE_RATING_SELECTED_MARKETS";

export const FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION = "FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION";
export const SET_EXIT_SURVEY_OBJECTIVE_COMPLETION = "SET_EXIT_SURVEY_OBJECTIVE_COMPLETION";

export const FETCH_EXIT_SURVEY_FEEDBACK = "FETCH_EXIT_SURVEY_FEEDBACK";
export const SET_EXIT_SURVEY_FEEDBACK = "SET_EXIT_SURVEY_FEEDBACK";

export const FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION_TREND = "FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION_TREND";
export const SET_EXIT_SURVEY_OBJECTIVE_COMPLETION_TREND = "SET_EXIT_SURVEY_OBJECTIVE_COMPLETION_TREND";

export const FETCH_EXIT_SURVEY_NON_COMPLETION_REASONS = "FETCH_EXIT_SURVEY_NON_COMPLETION_REASONS";
export const SET_EXIT_SURVEY_NON_COMPLETION_REASONS = "SET_EXIT_SURVEY_NON_COMPLETION_REASONS";

export const FETCH_EXIT_SURVEY_DEVICE_DISTRIBUTION = "FETCH_EXIT_SURVEY_DEVICE_DISTRIBUTION";
export const SET_EXIT_SURVEY_DEVICE_DISTRIBUTION = "SET_EXIT_SURVEY_DEVICE_DISTRIBUTION";

export const FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION_BY_MARKET = "FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION_BY_MARKET";
export const SET_EXIT_SURVEY_OBJECTIVE_COMPLETION_BY_MARKET = "SET_EXIT_SURVEY_OBJECTIVE_COMPLETION_BY_MARKET";

export const FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION_SELECTED_MARKETS = "FETCH_EXIT_SURVEY_OBJECTIVE_COMPLETION_SELECTED_MARKETS";
export const SET_EXIT_SURVEY_OBJECTIVE_COMPLETION_SELECTED_MARKETS = "SET_EXIT_SURVEY_OBJECTIVE_COMPLETION_SELECTED_MARKETS";

export const FETCH_RATING_PER_TOUCHPOINT_MARKET_BREAKDOWN = "FETCH_RATING_PER_TOUCHPOINT_MARKET_BREAKDOWN";
export const SET_RATING_PER_TOUCHPOINT_MARKET_BREAKDOWN = "SET_RATING_PER_TOUCHPOINT_MARKET_BREAKDOWN";

export const FETCH_RATING_PER_TOUCHPOINT_VLP_FOCUS = "FETCH_RATING_PER_TOUCHPOINT_VLP_FOCUS";
export const SET_RATING_PER_TOUCHPOINT_VLP_FOCUS = "SET_RATING_PER_TOUCHPOINT_VLP_FOCUS";

export const FETCH_RATING_PER_TOUCHPOINT_ELECTRIFICATION = "FETCH_RATING_PER_TOUCHPOINT_ELECTRIFICATION";
export const SET_RATING_PER_TOUCHPOINT_ELECTRIFICATION = "SET_RATING_PER_TOUCHPOINT_ELECTRIFICATION";

export const FETCH_RATING_PER_TOUCHPOINT_OTHER_TOUCHPOINTS = "FETCH_RATING_PER_TOUCHPOINT_OTHER_TOUCHPOINTS";
export const SET_RATING_PER_TOUCHPOINT_OTHER_TOUCHPOINTS = "SET_RATING_PER_TOUCHPOINT_OTHER_TOUCHPOINTS";

export const FETCH_RATING_PER_TOUCHPOINT_MEDIA_CAMPAIGNS = "FETCH_RATING_PER_TOUCHPOINT_MEDIA_CAMPAIGNS";
export const SET_RATING_PER_TOUCHPOINT_MEDIA_CAMPAIGNS = "SET_RATING_PER_TOUCHPOINT_MEDIA_CAMPAIGNS";

export const FETCH_RATING_PER_TOUCHPOINT_COMPLETION_RATE = "FETCH_RATING_PER_TOUCHPOINT_COMPLETION_RATE";
export const SET_RATING_PER_TOUCHPOINT_COMPLETION_RATE = "SET_RATING_PER_TOUCHPOINT_COMPLETION_RATE";

export const FETCH_TOPIC_CLASSIFICATION_DISSATISFACTION = "FETCH_TOPIC_CLASSIFICATION_DISSATISFACTION";
export const SET_TOPIC_CLASSIFICATION_DISSATISFACTION = "SET_TOPIC_CLASSIFICATION_DISSATISFACTION";

export const FETCH_TOPIC_CLASSIFICATION_SATISFACTION = "FETCH_TOPIC_CLASSIFICATION_SATISFACTION";
export const SET_TOPIC_CLASSIFICATION_SATISFACTION = "SET_TOPIC_CLASSIFICATION_SATISFACTION";

export const FETCH_FEEDBACK_COMMENTS = "FETCH_FEEDBACK_COMMENTS";
export const SET_FEEDBACK_COMMENTS = "SET_FEEDBACK_COMMENTS";

export const FETCH_RATING_PER_TOUCHPOINT_OWNERSHIP = "FETCH_RATING_PER_TOUCHPOINT_OWNERSHIP";
export const SET_RATING_PER_TOUCHPOINT_OWNERSHIP = "SET_RATING_PER_TOUCHPOINT_OWNERSHIP";

export const FETCH_RATING_PER_TOUCHPOINT_EDITORIAL = "FETCH_RATING_PER_TOUCHPOINT_EDITORIAL";
export const SET_RATING_PER_TOUCHPOINT_EDITORIAL = "SET_RATING_PER_TOUCHPOINT_EDITORIAL";

export const SET_CSV_EXPERIENCE_RATING = "SET_CSV_EXPERIENCE_RATING";

export const FETCH_FEEDBACK_COMMENTS_FILTERS = "FETCH_FEEDBACK_COMMENTS_FILTERS";
export const SET_FEEDBACK_COMMENTS_FILTERS = "SET_FEEDBACK_COMMENTS_FILTERS";
