import { BLUE } from "./colors";
import { DARK_PURPLE, GREEN, ORANGE } from "./index";

export type metricTypes = "leads" | "visits" | "kbas";

export type varianceMetricTypes =
  | "leads"
  | "visits"
  | "kbas"
  | "leads_oce"
  | "visits_oce"
  | "kbas_oce"
  | "visits_variance"
  | "kbas_variance"
  | "leads_variance"
  | "kbas_variance_oce"
  | "new_cars_variance"
  | "leads_variance_oce";

export const OCE_SERIES: Record<varianceMetricTypes, { seriesName: string; color: string }> = {
  leads: {
    seriesName: "Digital lead visits",
    color: DARK_PURPLE,
  },
  visits: {
    seriesName: "Visits",
    color: BLUE,
  },
  kbas: {
    seriesName: "KBA visits",
    color: ORANGE,
  },
  leads_oce: {
    seriesName: "New cars digital lead visits",
    color: GREEN,
  },
  visits_oce: { seriesName: "New cars visits", color: GREEN },
  kbas_oce: { seriesName: "New cars KBA visits", color: GREEN },
  leads_variance: {
    seriesName: "Digital lead visits",
    color: DARK_PURPLE,
  },
  visits_variance: {
    seriesName: "Visits",
    color: BLUE,
  },
  kbas_variance: {
    seriesName: "KBA visits",
    color: ORANGE,
  },
  leads_variance_oce: {
    seriesName: "New cars digital lead visits",
    color: GREEN,
  },
  new_cars_variance: { seriesName: "New cars visits", color: GREEN },
  kbas_variance_oce: { seriesName: "New cars KBA visits", color: GREEN },
};

export const OCE_METRIC_CONVERSIONS: Record<string, string> = {
  visits: "visits_to_kbas",
  kbas: "kbas_to_leads",
  leads: "visits_to_leads",
};
export const OCE_METRIC_CONVERSION_SERIES_NAMES: Record<string, string> = {
  leads: "Visits to Digital lead visits",
  visits: "Visits to KBA visits",
  kbas: "KBA visits to Digital lead visits",
};

export type performancePages = "volume" | "oao" | "image-attributes";
