import { CategoryAxis, CircleBullet, ColumnSeries, DateAxis, Legend, LineSeries, ValueAxis } from "@amcharts/amcharts4/charts";
import { color, DateFormatter } from "@amcharts/amcharts4/core";
import "moment-fquarter";
import { BLACK, LIGHT_GREY, WHITE } from "../../../constants";
import { SeriesConfigInt, ThemeInt } from "./types";

export const lineStrokeWidth = 2;
export const circleBulletRadius = 3;

export const legendConfiguration = (theme: ThemeInt) => {
  const legend = new Legend();
  legend.position = "top";
  legend.contentAlign = "center";

  legend.labels.template.fill = theme === "light" ? color(BLACK) : color(WHITE);
  legend.valueLabels.template.fill = theme === "light" ? color(BLACK) : color(WHITE);

  legend.itemContainers.template.paddingTop = 0;
  legend.itemContainers.template.paddingRight = 0;
  legend.itemContainers.template.paddingBottom = 2.5;
  legend.itemContainers.template.paddingLeft = 0;
  legend.itemContainers.template.marginBottom = 5;

  legend.labels.template.fontSize = "var(--regular_font_size)";

  legend.markers.template.width = 17.5;
  legend.markers.template.height = 17.5;

  return legend;
};

export const configureDateAxis = (dateAxis: DateAxis, theme: ThemeInt, searchInterestChart?: boolean) => {
  dateAxis.dateFormatter = new DateFormatter();
  dateAxis.dateFormatter.firstDayOfWeek = 0;
  dateAxis.dateFormatter.dateFormat = "MMM";
  dateAxis.renderer.grid.template.strokeWidth = 0;
  dateAxis.dateFormats.setKey("day", "dd MMM");
  dateAxis.dateFormats.setKey("week", "dd MMM");
  dateAxis.dateFormats.setKey("month", "MMM");
  dateAxis.periodChangeDateFormats.setKey("day", searchInterestChart ? "MMM" : "dd MMM");
  dateAxis.periodChangeDateFormats.setKey("week", "dd MMM");
  dateAxis.periodChangeDateFormats.setKey("month", "MMM");
  dateAxis.renderer.labels.template.fill = theme === "light" ? color(BLACK) : color(WHITE);
  dateAxis.renderer.labels.template.location = 0.5;
  dateAxis.skipEmptyPeriods = true;
};

export const configureCategoryAxis = (categoryAxis: CategoryAxis, theme: ThemeInt) => {
  categoryAxis.renderer.labels.template.fill = theme === "light" ? color(BLACK) : color(WHITE);
  categoryAxis.renderer.grid.template.disabled = true;
  // categoryAxis.startLocation = 0.2;
  // categoryAxis.endLocation = 0.8;
};

export const configureValueAxis = (valueAxis: ValueAxis, theme: ThemeInt, disabled = false) => {
  valueAxis.renderer.labels.template.fill = disabled ? color(LIGHT_GREY) : theme === "light" ? color(BLACK) : color(WHITE);
  valueAxis.renderer.grid.template.stroke = disabled ? color(LIGHT_GREY) : theme === "light" ? color(BLACK) : color(WHITE);
  valueAxis.title.fill = disabled ? color(LIGHT_GREY) : theme === "light" ? color(BLACK) : color(WHITE);
  valueAxis.numberFormatter.numberFormat = "#.a";

  valueAxis.maxPrecision = 0;
};

export const lineSeriesConfiguration = (lineSeries: LineSeries, options: SeriesConfigInt) => {
  lineSeries.name = options.name;
  lineSeries.dataFields.valueY = options.valueY;
  lineSeries.strokeWidth = lineStrokeWidth;

  if (lineSeries.tooltip) {
    lineSeries.tooltip.defaultState.transitionDuration = 0;
    lineSeries.tooltip.hiddenState.transitionDuration = 0;
  }

  if (options.xAxis) lineSeries.xAxis = options.xAxis;
  if (options.yAxis) lineSeries.yAxis = options.yAxis;

  const bullet: CircleBullet = lineSeries.bullets.push(new CircleBullet());
  bullet.circle.radius = options.granularity == "daily" ? 1 : options.granularity == "weekly" ? 2.2 : circleBulletRadius;

  return bullet;
};

export const lineSeriesConfigurationWithoutBullets = (lineSeries: LineSeries, options: SeriesConfigInt) => {
  lineSeries.name = options.name;
  lineSeries.dataFields.valueY = options.valueY;
  lineSeries.strokeWidth = lineStrokeWidth;

  if (lineSeries.tooltip) {
    lineSeries.tooltip.defaultState.transitionDuration = 0;
    lineSeries.tooltip.hiddenState.transitionDuration = 0;
  }

  if (options.xAxis) lineSeries.xAxis = options.xAxis;
  if (options.yAxis) lineSeries.yAxis = options.yAxis;
};

export const columnSeriesConfiguration = (columnSeries: ColumnSeries, options: SeriesConfigInt) => {
  columnSeries.name = options.name;
  columnSeries.dataFields.valueY = options.valueY;
  columnSeries.strokeWidth = 1;

  columnSeries.toBack();

  if (options.stacked) columnSeries.stacked = options.stacked;

  if (options.xAxis) columnSeries.xAxis = options.xAxis;
  if (options.yAxis) columnSeries.yAxis = options.yAxis;
  if (columnSeries.tooltip) {
    columnSeries.tooltip.defaultState.transitionDuration = 0;
    columnSeries.tooltip.hiddenState.transitionDuration = 0;
  }
};
