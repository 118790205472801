import React, { useContext } from "react";
import DigitalSpendTile from "./DigitalSpendTile";
import { ThemeContext } from "../../../context";
import DmeoDonutChart from "../../../components/Charts/Dmeo/DmeoDonutChart";
import DigitalSpendByCampaignObjectiveTile from "./DigitalSpendByCampaignObjectiveTile";
import DigitalSpendByChannel from "./DigitalSpendByChannel";

const DigitalMarketingSpendTiles = () => {
  return (
    <div className="digital-marketing-spend-tiles">
      <DigitalSpendTile />
      <DigitalSpendByCampaignObjectiveTile />
      <DigitalSpendByChannel />
    </div>
  );
};

export default DigitalMarketingSpendTiles;
