import { applyMiddleware, createStore, StoreEnhancer } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { __environment__ } from "../constants";
import reducer from "../reducers";
import rootSaga from "../sagas";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);

const composedEnhancers = composeWithDevTools({ trace: false });
const enhancers = [middlewareEnhancer];
let configuredStore = createStore(reducer, composedEnhancers(...enhancers) as StoreEnhancer<unknown, unknown>);

// Removes devtools config for UAT and prod
if (__environment__ === "production" || __environment__ === "uat") {
  configuredStore = createStore(reducer, middlewareEnhancer);
}

sagaMiddleware.run(rootSaga);
export const store = configuredStore;
