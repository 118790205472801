import React from "react";
import { Link } from "react-router-dom";
interface Props {
  id: string;
  link?: string;
}

const BackButton = (props: Props) => {
  const { id, link } = props;
  return (
    <div id={`${id}_ccs_back_button`} className="back_button" data-test-id={`${id}_back_button`}>
      <Link to={link ? link : "/ccs_vital_signs/ccs_cockpit"}>
        &lt;
        <span className="back_text">Back</span>
      </Link>
    </div>
  );
};

export default BackButton;
