import { Box } from "@material-ui/core";
import React, { ReactElement } from "react";
import style from "./style.module.scss";

interface Props {
  id: string;
  title?: string;
  titleDescription?: string;
  content1: ReactElement;
  content2: ReactElement;
  content3?: ReactElement;
  className?: string;
  marketBreakdownPath?: string;
}

export const MajorCard = (props: Props) => {
  const { id, title, titleDescription, content1, content2, content3, className } = props;

  return (
    <Box className={`${style.card} ${className ? className : ""}`} data-test-id={id}>
      {title && (
        <div className={`${style.headerContainer} border`}>
          <h2 className={style.header}>{title}</h2>
          {titleDescription && <h3 className={style.headerDescription}>{titleDescription}</h3>}
        </div>
      )}

      <div className={style.contentBox}>
        <div className={style.content}>{content1}</div>
        <div className={style.content}>{content2}</div>
        {content3 && <div className={style.content}>{content3}</div>}
      </div>
    </Box>
  );
};
