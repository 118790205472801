import React, { Dispatch, Fragment, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Tooltip } from "react-tippy";
import { setSpecificParameter } from "../../../actions";
import { ALL_OPTION_NO_SPACE } from "../../../constants";
import { FY_TWENTY } from "../../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { ToggleSwitch } from "../../Toogle";
import { closeFilterOptions, closeSubFilterOptions } from "../subs/helpers";

type FilterListInt = Array<{
  value: string;
  checked: boolean;
  indeterminate: boolean;
  child?: Array<{ [index: string]: { value: string; checked: boolean; indeterminate: boolean } }>;
  [index: string]: any;
}>;

interface Props {
  filterName?: string;
  parentKey: string;
  childKey?: string;
  grandChildKey?: string;
  filterList: FilterListInt;
  value: string;
  parameterName: string;
  parameterValue: string;
  onShowFilterOptions: (evt: React.MouseEvent<HTMLElement>) => void;
  handleFilterSubmission: () => void;
  showOptionsOnly?: boolean;
  page: string;
  weeklyOptions?: boolean;
  toggleState?: boolean;
  setToogleState?: Dispatch<SetStateAction<boolean>>;
}

interface RenderProps {
  filterList: FilterListInt;
  parentValue?: string;
}

export const MultiDateRangeWithPresets = (props: Props) => {
  const {
    filterName,
    parentKey,
    childKey,
    grandChildKey,
    filterList,
    value,
    parameterValue,
    onShowFilterOptions,
    parameterName,
    handleFilterSubmission,
    showOptionsOnly,
    page,
    weeklyOptions,
    toggleState,
    setToogleState,
  } = props;

  const dispatch = useDispatch();

  const [checkedState, setCheckedState] = useState<{ [index: string]: { checked: boolean; indeterminate: boolean } }>({});
  const [draftCheckedState, setDraftCheckedState] = useState<{ [index: string]: { checked: boolean; indeterminate: boolean } }>({});
  const filterState = useMemo(() => {
    const isAll = parameterValue === "All";
    const parameterList = parameterValue.split(",");

    const createChildObj: any = (childItem: any, parentKeyValue: any, childKey: string) => {
      return {
        value: childItem[childKey],
        checked: parameterList.includes(`${childItem[childKey]} ${parentKeyValue} ${childItem?.year ? childItem.year : ""}`.trim()),
        indeterminate: false,
        child: childItem?.child
          ? childItem.child.map((item: any) => createChildObj(item, childItem[childKey], grandChildKey ? grandChildKey : ""))
          : [],
        year: childItem?.year,
        month: childItem?.month,
        hideCheckbox: childItem?.hideCheckbox ? childItem.hideCheckbox : false,
      };
    };

    const result = filterList?.map((item) => {
      const isString = typeof item === "string";
      //@ts-ignore
      if (isString) return { value: item, checked: isAll || parameterList.includes(item), indeterminate: false };

      const childResult = childKey ? item?.child?.map((childItem) => createChildObj(childItem, item[parentKey], childKey)) : [];

      return {
        value: item[parentKey],
        child: childResult,
        checked:
          parameterList.filter((paramItem) => {
            const parentRe = new RegExp(item[parentKey], "gi");
            return parentRe.test(paramItem);
          })?.length === childResult?.length,
        indeterminate:
          parameterList.filter((paramItem) => {
            const parentRe = new RegExp(item[parentKey], "gi");
            return parentRe.test(paramItem);
            //@ts-ignore
          })?.length < childResult?.length &&
          parameterList.filter((paramItem) => {
            const parentRe = new RegExp(item[parentKey], "gi");
            return parentRe.test(paramItem);
          })?.length > 0,
        hideCheckbox: item?.hideCheckbox ? item.hideCheckbox : false,
      };
    });

    if (weeklyOptions) {
      return [...result];
    }
    return [{ value: "All", checked: result?.length === result.filter((item) => item.checked)?.length, indeterminate: false }, ...result];
  }, [filterList, parameterValue]);

  useEffect(() => {
    const stateResult = filterState.reduce((result, row) => {
      //@ts-ignore
      const { value: parentValue, checked: parentChecked, indeterminate: parentIndeterminate, child } = row;

      if (child) {
        const childResult = child.reduce(
          (
            result: { [index: string]: { checked: boolean; indeterminate: boolean } },
            childRow: { value: string; checked: boolean; indeterminate: boolean }
          ) => {
            const { value, checked, indeterminate } = childRow;
            const childValue = `${value} ${parentValue}`;

            return { ...result, [childValue]: { checked, indeterminate } };
          },
          {}
        );

        let grandChildResult: any = {};

        child.forEach((chld: any) => {
          if (chld?.child) {
            chld.child.map((item: any) => {
              const { value, checked, indeterminate, year, month } = item;

              grandChildResult[`${value} ${month} ${year}`] = { checked, indeterminate };
            });
          }
        });
        if (child?.child) {
          grandChildResult = child.child.reduce(
            (
              result: { [index: string]: { checked: boolean; indeterminate: boolean } },
              childRow: { value: string; checked: boolean; indeterminate: boolean }
            ) => {
              const { value, checked, indeterminate } = childRow;
              const childValue = `${value} ${parentValue}`;

              return { ...result, [childValue]: { checked, indeterminate } };
            },
            {}
          );
        }

        return {
          ...result,
          [parentValue]: { checked: parentChecked, indeterminate: parentIndeterminate },
          ...childResult,
          ...grandChildResult,
        };
      }

      return { ...result, [parentValue]: { checked: parentChecked, indeterminate: parentIndeterminate } };
    }, {});
    setCheckedState(stateResult);
  }, [filterState]);

  useEffect(() => setDraftCheckedState(checkedState), [checkedState]);

  const handleMultiSelection = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const {
        currentTarget: { value, checked, dataset },
      } = evt;
      const parentValue = dataset?.parentValue;
      if (weeklyOptions) {
        const childCheckboxes: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
          `[type="checkbox"][data-parent-value="${value}"]`
        );

        // Sets current value
        let draftState = {
          ...draftCheckedState,
          [value]: { checked, indeterminate: false },
        };

        if (value.includes("week")) {
          Object.entries(draftState).forEach(([key, value]) => {
            if (!key.includes("week")) {
              draftState = {
                ...draftState,
                [key]: { ...value, checked: false },
              };
            }
          });
        } else {
          Object.entries(draftState).forEach(([key, value]) => {
            if (key.includes("week")) {
              draftState = {
                ...draftState,
                [key]: { ...value, checked: false },
              };
            }
          });
        }

        setDraftCheckedState(draftState);
      } else {
        const parentValue = dataset?.parentValue;

        const allChildCheckbox: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
          `[type="checkbox"][data-filter-name="${parameterName}"][data-parent-value="${parentValue}"]`
        );
        const selectedChildCheckbox: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
          `[type="checkbox"][data-filter-name="${parameterName}"][data-parent-value="${parentValue}"]:checked`
        );

        const availableNonAllValues: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
          `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]`
        );

        const selectedAvailableNonAllValues: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
          `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
        );

        // Sets current value
        let draftState = {
          ...draftCheckedState,
          [value]: { checked, indeterminate: false },
        };

        if (value === ALL_OPTION_NO_SPACE) {
          Object.keys(draftState).map((key) => {
            draftState = {
              ...draftState,
              [key]: { checked, indeterminate: false },
            };
          });

          setDraftCheckedState(draftState);
          return;
        }

        if (parentValue == undefined) {
          draftState = {
            ...draftState,
            [value]: {
              checked: allChildCheckbox?.length === selectedChildCheckbox?.length,
              indeterminate: selectedChildCheckbox?.length > 0 && selectedChildCheckbox?.length < allChildCheckbox?.length,
            },
          };
        } else {
          // Updates all child elements if present
          const childCheckboxes: NodeListOf<HTMLInputElement> | null = document.querySelectorAll(
            `[type="checkbox"][data-parent-value="${value}"]`
          );

          childCheckboxes?.forEach((childElement) => {
            const childValue = childElement.value;
            draftState = {
              ...draftState,
              [childValue]: { checked, indeterminate: false },
            };
          });

          draftState = {
            ...draftState,
            [parentValue]: {
              checked,
              indeterminate: selectedChildCheckbox?.length > 0 && selectedChildCheckbox?.length < allChildCheckbox?.length,
            },
            All: {
              checked: selectedAvailableNonAllValues?.length === availableNonAllValues?.length,
              indeterminate:
                selectedAvailableNonAllValues?.length !== 0 && selectedAvailableNonAllValues?.length < availableNonAllValues?.length,
            },
          };
        }
        setDraftCheckedState(draftState);
      }
    },
    [draftCheckedState]
  );

  const handleFilterApply = () => {
    closeSubFilterOptions();
    handleFilterSubmission();
  };

  const handleFilterOptionClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const filterName = dataset?.filter;
      const optionValue = dataset?.value;
      closeSubFilterOptions();
      closeFilterOptions();
      if (filterName && optionValue) {
        dispatch(setSpecificParameter("date_range", optionValue));
        eventTracking(MixpanelEvents.filter_change, { filter: "date_range", value: optionValue, page, dashboard: page });
      }
    },
    [page]
  );

  const renderCheckedOptions = useCallback(
    (renderProp: RenderProps) => {
      const { filterList, parentValue } = renderProp;

      const handleMarketArrowClick = (evt: React.MouseEvent<HTMLElement>) => {
        const {
          currentTarget: { id: currentId, dataset },
        } = evt;
        const subCheckListId = dataset?.marketTarget;

        const arrowDownClass = "down";
        const showSubListClass = "show";

        const currentElement = currentId ? document.getElementById(currentId) : undefined;
        const targetUlElement = subCheckListId ? document.getElementById(subCheckListId) : undefined;

        // if (!currentElement?.classList.contains(arrowDownClass)) closeSubFilterOptions();
        currentElement?.classList.toggle(arrowDownClass);
        targetUlElement?.classList.toggle(showSubListClass);
      };

      return filterList.map((filterItem) => {
        const { value, child, hideCheckbox, year } = filterItem;
        const showArrow = child && child?.length ? "show" : "hidden";
        const checkValue = parentValue ? `${value} ${parentValue} ${year ? year : ""}`.trim() : value;

        return (
          <Fragment key={value}>
            <div className={"multiselect_container"}>
              <span
                id={parentValue ? `${parentKey}_${value}_${parentValue}_arrow` : `${parentKey}_${value}_arrow`}
                className={`arrow-span ${showArrow}`}
                data-market-target={`${parentValue ? `${parentValue}_` : ""}${value}_ul`}
                onClick={handleMarketArrowClick}
              >
                <span className={"market-arrow"} />
              </span>
              {!hideCheckbox && (
                <input
                  type="checkbox"
                  className={"multiselect-checkbox"}
                  id={parentValue ? `${parentKey}_${value}_${parentValue}${year ? `_${year}` : ""}` : `${parentKey}_${value}`}
                  value={checkValue}
                  checked={draftCheckedState[checkValue]?.checked ? draftCheckedState[checkValue].checked : false}
                  onChange={handleMultiSelection}
                  data-parent-value={`${parentValue}${year ? ` ${year}` : ""}`}
                  data-filter-name={parameterName}
                  data-options-type={!parentValue && value !== ALL_OPTION_NO_SPACE ? "single" : "grouped"}
                  data-relationship={parentValue && !child && value !== ALL_OPTION_NO_SPACE ? "child" : "parent"}
                  ref={(input) => {
                    if (input) {
                      input.indeterminate = draftCheckedState[checkValue]?.indeterminate;
                    }
                  }}
                />
              )}

              <label
                className="multiselect-label"
                htmlFor={parentValue ? `${parentKey}_${value}_${parentValue}${year ? `_${year}` : ""}` : `${parentKey}_${value}`}
                data-market-target={`${parentValue ? `${parentValue}_` : ""}${value}_ul`}
                onClick={hideCheckbox ? handleMarketArrowClick : undefined}
              >
                {value}
              </label>
            </div>
            {child && (
              <ul
                className="multicheck_sub_filter_options"
                id={`${parentValue ? `${parentValue}_` : ""}${value}_ul`}
                data-parent-key={value}
              >
                {/* @ts-ignore */}
                {renderCheckedOptions({ filterList: child, parentValue: value })}
              </ul>
            )}
          </Fragment>
        );
      });
    },
    [draftCheckedState]
  );
  /* @ts-ignore */

  return (
    <Fragment>
      {showOptionsOnly ? (
        <>
          <div className="filter" id={`${filterName}_filter_div`}>
            <div className="flex justify-space-between width-full" style={{ paddingRight: "15px" }}>
              <label className="filter_header" htmlFor={`${parentKey}_${childKey}_filter`}>
                {filterName}
              </label>
              {weeklyOptions && toggleState != undefined && setToogleState && (
                <div className="row width-full">
                  <ToggleSwitch
                    activeToggleLabel="Monthly"
                    inactiveToggleLabel="Weekly"
                    active={toggleState}
                    handleToggleClick={() => setToogleState(!toggleState)}
                  />
                  <Tooltip
                    position="right"
                    className={"normal_tippy"}
                    trigger="mouseenter"
                    interactive={true}
                    distance={220}
                    delay={1}
                    hideDelay={1}
                    duration={1}
                    title={"Select a new date range after clicking on the toggle"}
                    size={"small"}
                    style={{ width: "100%" }}
                  >
                    <span className="info_tooltip" />
                  </Tooltip>
                </div>
              )}
            </div>

            <div className="input_arrow" onClick={onShowFilterOptions} data-options-ul={`${parentKey}_ul`}>
              <input
                readOnly
                type="text"
                id={`${parentKey}_${childKey}_filter`}
                className={"filter_input"}
                data-test-id={`${parentKey}`}
                value={value}
              />
              <span className="arrow_down " />
            </div>

            <ul className="filter_options" id={`${parentKey}_ul`} data-test-id="region_list">
              {/* @ts-ignore */}
              {renderCheckedOptions({ filterList: filterState, parentKey, childKey })}
              <div className="filter_options__submit_area">
                <div className="filter_submit_btn submit_geo_filter" onClick={handleFilterApply}>
                  Apply
                </div>
              </div>
            </ul>
          </div>
        </>
      ) : (
        <div className="filter" id={`${filterName}_filter_div`}>
          <label className="filter_header" htmlFor={`${parentKey}_${childKey}_filter`}>
            {filterName}
          </label>

          <div className="input_arrow" onClick={onShowFilterOptions} data-options-ul={`${parentKey}_ul`}>
            <input
              readOnly
              type="text"
              id={`${parentKey}_${childKey}_filter`}
              className={"filter_input"}
              data-test-id={`${parentKey}`}
              value={value}
            />
            <span className="arrow_down " />
          </div>

          <ul className="filter_options" id={`${parentKey}_ul`} data-test-id="region_list">
            {/* preset */}
            <li
              className={"filter_divider"}
              data-filter={"preset_date"}
              data-value={FY_TWENTY}
              onClick={handleFilterOptionClick}
              id="fy20_li"
              data-test-id="fy20_li"
            >
              FY20
            </li>

            {/* @ts-ignore */}
            {renderCheckedOptions({ filterList: filterState, parentKey, childKey })}
            <div className="filter_options__submit_area">
              <div className="filter_submit_btn submit_geo_filter" onClick={handleFilterApply}>
                Apply
              </div>
            </div>
          </ul>
        </div>
      )}
    </Fragment>
  );
};
