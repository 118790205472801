import React from "react";
import blueCedarLogo from "../../assets/images/blue_cedar_logo.svg";

export const AccessDenied = () => {
  return (
    <div className="form_body" id="login_form_body">
      <div className="header_section">
        <img src={blueCedarLogo} alt="Cedar Logo" />
      </div>
      <form className="error_form">
        <h3>Access denied</h3>
        <label>Sorry, you are not authorized to view this page.</label>
        <a href="/">Return to homepage.</a>
      </form>
    </div>
  );
};
