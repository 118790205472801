import { Button, makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { Dispatch, SetStateAction, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { createKPILog, updateKPILog } from "../../../../../api/adminApi";
import { ErrorMsg } from "../../../../../components/AppMessages";
import { CYAN } from "../../../../../constants";
import { KPILog } from "../../../../../constants/interface/admin/kpiUpdate";

interface Props {
  open: boolean;
  toggleOpen: () => void;
  selectedKpi: KPILog | undefined;
  setKpis: Dispatch<SetStateAction<Array<KPILog>>>;
}

interface IKPIFormData extends FormData, KPILog {}

export const CreateUpdateDialogue = (props: Props) => {
  const { open, selectedKpi, toggleOpen, setKpis } = props;
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<KPILog>({
    defaultValues: { ...selectedKpi },
  });

  const classes = makeStyles({
    button: {
      backgroundColor: CYAN,
      color: "#FFF !important",
      borderRadius: "0px !important",
      border: "none !important",
      marginRight: "10px",
      paddingTop: "8px !important",
      paddingBottom: "8px !important",
      "&:hover": {
        backgroundColor: "#5ed2ff !important",
        border: "none !important",
      },
    },
  })();

  const submitHandler = useCallback(
    (values: IKPIFormData) => (selectedKpi?.kpi_id ? updateKpiLog(values) : createKpiLog(values)),
    [selectedKpi]
  );

  const createKpiLog = (values: IKPIFormData) => {
    createKPILog(values).then((response) => {
      if (response && !("error" in response)) {
        setKpis((prevState) => [...prevState, response]);
        toggleOpen();
      } else {
        toast.error("Flash creation failed");
      }
    });
  };

  const updateKpiLog = (values: IKPIFormData) => {
    updateKPILog(values).then((response) => {
      if (response && !("error" in response)) {
        setKpis((prevState) => prevState.map((kpi) => (kpi.kpi_id === response.kpi_id ? response : kpi)));
        toggleOpen();
      } else {
        toast.error("Kpi update failed");
      }
    });
  };

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <Dialog onClose={toggleOpen} open={open} scroll="body" fullWidth>
        <DialogTitle>{selectedKpi?.kpi_id ? "Update entry" : "Create new entry"}</DialogTitle>
        <DialogContent dividers>
          {/* Element name */}
          <Grid container spacing={2}>
            <Grid item md={12}>
              <FormControl fullWidth>
                <Controller
                  name="element_name"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Element name"
                      id="kpi-name"
                      type="string"
                      value={value}
                      onChange={onChange}
                      variant="outlined"
                      error={Boolean(errors.element_name)}
                      helperText={errors?.element_name?.message}
                      required
                      inputProps={{
                        "data-test-id": "kpi-name-input",
                      }}
                    />
                  )}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* Update */}
          <Grid container spacing={2}>
            <Grid item md={12}>
              <FormControl fullWidth>
                <Controller
                  name="update_text"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Update"
                      id="kpi-text"
                      type="text"
                      value={value}
                      onChange={onChange}
                      variant="outlined"
                      error={Boolean(errors.update_text)}
                      helperText={errors?.update_text?.message}
                      required
                      inputProps={{
                        "data-test-id": "kpi-text-input",
                      }}
                    />
                  )}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* Markets impacted */}
          <Grid container spacing={2}>
            <Grid item md={12}>
              <FormControl fullWidth>
                <Controller
                  name="market_impacted"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Markets impacted"
                      id="impacted-markets"
                      type="text"
                      value={value}
                      onChange={onChange}
                      variant="outlined"
                      error={Boolean(errors.market_impacted)}
                      helperText={errors?.market_impacted?.message}
                      required
                      inputProps={{
                        "data-test-id": "impacted-markets-input",
                      }}
                    />
                  )}
                  defaultValue=""
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* Update date */}
          <Grid container spacing={2}>
            <Grid item md={12}>
              <FormControl fullWidth>
                <Controller
                  name="update_date"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      label="Updated date"
                      id="update-date"
                      type="date"
                      value={moment(value).format("YYYY-MM-DD")}
                      onChange={onChange}
                      variant="outlined"
                      error={Boolean(errors.update_date)}
                      helperText={errors?.update_date?.message}
                      required
                      inputProps={{
                        "data-test-id": "update-date-input",
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions style={{ justifyContent: `${selectedKpi?.kpi_id ? "flex-end" : "space-between"}` }}>
          <Button
            className={classes.button}
            variant="outlined"
            type="submit"
            onClick={handleSubmit(submitHandler)}
            disableElevation
            data-test-id={`${selectedKpi?.kpi_id ? "btn-update-user" : "btn-create-user"}`}
          >
            {selectedKpi?.kpi_id ? "Update" : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
    </ErrorBoundary>
  );
};
