import { ErrorBoundary } from "@sentry/react";
import { format } from "date-fns";
import { capitalize } from "lodash";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  deleteSpecificParameter,
  fetchFlopServices,
  fetchServiceGroups,
  fetchTopFlopFilters,
  fetchTopFlopServices,
  fetchTopModels,
  fetchTopRegions,
  fetchTopServices,
  setPageName,
  setSpecificParameter,
} from "../../../actions";
import "../../../assets/styles/pages/topFlop.scss";
import { ErrorMsg } from "../../../components/AppMessages";
import { TopFlopFilters } from "../../../components/Filters/TopFlopFilters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import TopFlopServicesTable from "../../../components/Tables/TopFlopServicesTable";
import { DashboardBannerContext } from "../../../context";
import FlopServicesGraph from "./components/FlopServicesGraph";
import RegionsGraph from "./components/RegionsGraph";
import ServiceGroupsGraph from "./components/ServiceGroupsGraph";
import TopModelsGraph from "./components/TopModelsGraph";
import TopServicesGraph from "./components/TopServicesGraph";

interface Props {
  id: string;
  handleClick: () => void;
}

const BackButton = (props: Props) => {
  const { id, handleClick } = props;
  return (
    <div id={id} onClick={handleClick} className="back_button" data-test-id={`${id}_back_button`}>
      &lt;
      <span className="back_text">Back</span>
    </div>
  );
};

export const TopFlop = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { history } = props;
  const { setShowBanner, setBannerText } = useContext(DashboardBannerContext);
  const parameters = useSelector((state: RootStateOrAny) => state.top_flop_parameters);
  const filters = useSelector((state: RootStateOrAny) => state.filters.top_flop_filters);
  const [lastRefreshDate, setLastRefreshDate] = useState<string>("");
  const [activeService, setActiveService] = useState<{ paramType: string; value: string } | undefined>(undefined);

  // Sets page name and get filters data
  useEffect(() => {
    Promise.all([dispatch(setPageName("Top/Flop connected car services"))]);
    setShowBanner(true);
    setBannerText(
      "Selecting a certain UID in the filter will show all the corresponding Service Names and Service Groups associated to it in the respective filters."
    );

    return () => {
      setShowBanner(false);
      setBannerText("");
    };
  }, [dispatch]);

  // Fetch filters and chart data
  useEffect(() => {
    Promise.all([
      dispatch(fetchTopFlopFilters()),
      dispatch(fetchTopFlopServices()),
      dispatch(fetchTopServices()),
      dispatch(fetchFlopServices()),
      dispatch(fetchTopModels()),
      dispatch(fetchServiceGroups()),
      dispatch(fetchTopRegions()),
    ]);
  }, [parameters, history.location.search]);

  // set last refresh date
  useEffect(() => {
    if (filters.dates) {
      let date = "";
      if (!filters.dates.at(-1).includes("week")) {
        date = format(new Date(filters.dates.at(-1)), "MMMM yyyy");
      } else {
        date = filters.dates.at(-1).split(" ").slice(-2).join(" ");
      }

      setLastRefreshDate(date);
    }
  }, [filters.dates]);

  const handleClickBackButton = () => {
    const selectedModels = parameters.model
      .split(",")
      .map((model: string) => capitalize(model))
      .join(",");
    dispatch(setSpecificParameter("model", selectedModels));
    activeService && dispatch(deleteSpecificParameter(activeService.paramType));
    history.push({ pathname: "/ccs_vital_signs/ccs_cockpit" });
  };

  const disableSpecificService = useCallback(
    (paramCategory: string) => {
      dispatch(deleteSpecificParameter(paramCategory));
      setActiveService(undefined);
    },
    [parameters]
  );

  const handleServiceClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>, paramCategory: string) => {
      const {
        currentTarget: { dataset },
      } = evt;
      const selectedService = dataset?.value;

      if (activeService && selectedService == activeService?.value) {
        disableSpecificService(activeService?.paramType);
      } else if (selectedService) {
        activeService && disableSpecificService(activeService?.paramType);
        dispatch(setSpecificParameter(paramCategory, selectedService));
        setActiveService({ paramType: paramCategory, value: selectedService });
      }
    },
    [dispatch, activeService]
  );

  useEffect(() => {
    const paramsKeys = Object.keys(parameters);
    if (
      !paramsKeys.includes("specific_service") &&
      !paramsKeys.includes("specific_group") &&
      !paramsKeys.includes("specific_type") &&
      !paramsKeys.includes("specific_trigger")
    ) {
      setActiveService(undefined);
    }
  }, [parameters]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<TopFlopFilters />}
        lastDataRefresh={lastRefreshDate}
        showLastDataRefreshAlert={false}
        dataDocumentation="ccs_data"
        pageTitle={
          <PageTitle
            dataDocumentation="ccs_data"
            backButton={<BackButton id={"top_flop_back_button"} handleClick={handleClickBackButton} />}
            tooltipText="Data can be displayed as unique users on a monthly or weekly level, total events is showing the sum of the selected date ranges"
          />
        }
      >
        <ErrorBoundary fallback={<ErrorMsg />}>
          <div className="top_flop_sotu">
            <div className="services_tile">
              <ErrorBoundary fallback={<ErrorMsg />}>
                <TopFlopServicesTable onHandleSpecificServiceClick={handleServiceClick} activeService={activeService} />
              </ErrorBoundary>

              <div className="services_charts">
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <TopServicesGraph />
                </ErrorBoundary>

                <ErrorBoundary fallback={<ErrorMsg />}>
                  <FlopServicesGraph />
                </ErrorBoundary>

                <ErrorBoundary fallback={<ErrorMsg />}>
                  <TopModelsGraph />
                </ErrorBoundary>
              </div>
            </div>

            <div className="service_group_region_tile">
              <ErrorBoundary fallback={<ErrorMsg />}>
                <ServiceGroupsGraph />
              </ErrorBoundary>

              <ErrorBoundary fallback={<ErrorMsg />}>
                <RegionsGraph />
              </ErrorBoundary>
            </div>
          </div>
        </ErrorBoundary>
      </DefaultPageContent>
    </DashboardLayout>
  );
});
