import { Brand, Channel, ChannelBreakdown, LeadSource, Market, Metric, Model, Region } from "../constants/interface/admin/common";
import {
  FETCH_ANNOTATION_BRANDS,
  FETCH_ANNOTATION_CHANNELS,
  FETCH_ANNOTATION_CHANNEL_BREAKDOWNS,
  FETCH_ANNOTATION_LEAD_SOURCES,
  FETCH_ANNOTATION_MARKETS,
  FETCH_ANNOTATION_METRICS,
  FETCH_ANNOTATION_NEW_MODELS,
  FETCH_ANNOTATION_REGIONS,
  SET_ANNOTATION_BRANDS,
  SET_ANNOTATION_CHANNELS,
  SET_ANNOTATION_CHANNEL_BREAKDOWNS,
  SET_ANNOTATION_LEAD_SOURCES,
  SET_ANNOTATION_MARKETS,
  SET_ANNOTATION_METRICS,
  SET_ANNOTATION_NEW_MODELS,
  SET_ANNOTATION_REGIONS,
} from "./actionTypes";

export const fetchBrandsData = (dashboard: string) => ({ type: FETCH_ANNOTATION_BRANDS, payload: dashboard });
export const setBrandsData = (data: Brand, dashboard: string) => ({ type: SET_ANNOTATION_BRANDS, dashboard: dashboard, payload: data });

export const fetchRegionsData = (dashboard: string) => ({ type: FETCH_ANNOTATION_REGIONS, payload: dashboard });
export const setRegionsData = (data: Region, dashboard: string) => ({ type: SET_ANNOTATION_REGIONS, dashboard: dashboard, payload: data });

export const fetchMarketsData = (dashboard: string) => ({ type: FETCH_ANNOTATION_MARKETS, payload: dashboard });
export const setMarketsData = (data: Market, dashboard: string) => ({ type: SET_ANNOTATION_MARKETS, dashboard: dashboard, payload: data });

export const fetchNewModelsData = (dashboard: string) => ({ type: FETCH_ANNOTATION_NEW_MODELS, payload: dashboard });
export const setNewModelsData = (data: Model, dashboard: string) => ({
  type: SET_ANNOTATION_NEW_MODELS,
  dashboard: dashboard,
  payload: data,
});

export const fetchChannelsData = (dashboard: string) => ({ type: FETCH_ANNOTATION_CHANNELS, payload: dashboard });
export const setChannelsData = (data: Channel, dashboard: string) => ({
  type: SET_ANNOTATION_CHANNELS,
  dashboard: dashboard,
  payload: data,
});

export const fetchChannelBreakdownsData = (dashboard: string) => ({ type: FETCH_ANNOTATION_CHANNEL_BREAKDOWNS, payload: dashboard });
export const setChannelBreakdownsData = (data: ChannelBreakdown, dashboard: string) => ({
  type: SET_ANNOTATION_CHANNEL_BREAKDOWNS,
  dashboard: dashboard,
  payload: data,
});

export const fetchMetricsData = (dashboard: string) => ({ type: FETCH_ANNOTATION_METRICS, payload: dashboard });
export const setMetricsData = (data: Metric, dashboard: string) => ({ type: SET_ANNOTATION_METRICS, dashboard: dashboard, payload: data });

export const fetchLeadSourcesData = (dashboard: string) => ({ type: FETCH_ANNOTATION_LEAD_SOURCES, payload: dashboard });
export const setLeadSourcesData = (data: LeadSource, dashboard: string) => ({
  type: SET_ANNOTATION_LEAD_SOURCES,
  dashboard: dashboard,
  payload: data,
});
