/* eslint-disable react/display-name */
import React from "react";
import { DEFAULT_LANGUAGE, format_percent_options } from "../../../../constants";
import { formatComparisonNumbers, renderComparisonCell } from "./formatCellValue";

const width = window.innerWidth < 700 ? 15 : 30;
export const getOrganicVisitsColumns = ({ handleVisitsClick, popLabel, showVisitsShare }: any) => {
  return [
    {
      id: "queryColumn",
      Header: "Landing page",
      accessor: "hostname",
      defaultCanSort: true,
      sortDescFirst: true,
      minWidth: width,
      width: width,
      maxWidth: width,
      Cell: (props: { value: string }) => (
        <>
          <div className="table_link_div">
            <a
              className="specific_link"
              href={props.value}
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleVisitsClick}
              data-text={props.value}
            >
              {props.value.substring(8).replace("www.", "")}
            </a>
          </div>
          <span className="tooltip-cell">{props.value}</span>
        </>
      ),
    },

    showVisitsShare
      ? {
          id: "volumeColumn",
          Header: "% Share",
          accessor: "percentageShare",
          defaultCanSort: true,
          defaultSortDesc: true,
          sortDescFirst: true,
          sortType: "basicCustom",
          Cell: (props: { value: any }) =>
            isNaN(props.value) ? props.value : props.value.toLocaleString(DEFAULT_LANGUAGE, format_percent_options),
        }
      : {
          id: "volumeColumn",
          Header: "Volume",
          accessor: `current_data.organic_visits`,
          defaultCanSort: true,
          defaultSortDesc: true,
          sortDescFirst: true,
          sortType: "alphanumericCustom",
          Cell: (props: { value: number }) => formatComparisonNumbers({ value: props.value, hideSign: true }),
        },
    {
      id: "popComparisonColumn",
      Header: popLabel,
      accessor: "popComparison",
      defaultCanSort: true,
      sortDescFirst: true,
      sortType: "alphanumericFalsyLast",
      Cell: formatComparisonNumbers,
    },
    {
      id: "popPercentageColumn",
      Header: `% ${popLabel}`,
      accessor: "popPercentage",
      defaultCanSort: true,
      sortDescFirst: true,
      sortType: "alphanumericFalsyLast",
      Cell: renderComparisonCell,
    },
    {
      id: "yoyColumn",
      Header: "YoY",
      accessor: "yoyComparison",
      defaultCanSort: true,
      sortDescFirst: true,
      sortType: "alphanumericFalsyLast",
      Cell: formatComparisonNumbers,
    },
    {
      id: "yoyPercentageColumn",
      Header: "% YoY",
      accessor: "yoyPercentage",
      defaultCanSort: true,
      sortDescFirst: true,
      sortType: "alphanumericFalsyLast",
      Cell: renderComparisonCell,
    },
  ];
};

export const getClicksColumns = ({ showPercentageShare, popLabel, handleImpressionsClick, showImpressions }: any) => {
  return [
    {
      id: "queryColumn",
      Header: "Query",
      accessor: "hostname",
      // eslint-disable-next-line react/display-name
      Cell: (props: { value: string }) =>
        !showImpressions ? (
          <>
            <div className="table_link_div">
              <a className="specific_link" onClick={handleImpressionsClick} data-text={props.value}>
                {props.value}
              </a>
            </div>
            <span className="tooltip-cell">{props.value}</span>
          </>
        ) : (
          ""
        ),
      minWidth: 15, // minWidth is only used as a limit for resizing
      width: 15, // width is used for both the flex-basis and flex-grow
      maxWidth: 15, // maxWidth is only used as a limit for resizing
    },
    {
      id: "metricColor",
      defaultCanSort: false,
      disableSortBy: true,
      minWidth: 2,
      width: 2,
      maxWidth: 2,
      // eslint-disable-next-line react/display-name
      Cell: () =>
        showImpressions ? (
          <div className="metric_column">
            <span className="metric_column__bar green"></span>
            <span className="tooltip-cell">Clicks</span>
          </div>
        ) : (
          ""
        ),
    },
    showPercentageShare
      ? {
          id: "volumeColumn",
          Header: "% Share",
          accessor: "clicksPercentageShare",
          defaultCanSort: true,
          defaultSortDesc: true,
          sortDescFirst: true,
          sortType: "basic",
          // eslint-disable-next-line react/display-name
          Cell: (props: { value: any }) =>
            isNaN(props.value) ? props.value : props.value.toLocaleString(DEFAULT_LANGUAGE, format_percent_options),
        }
      : {
          id: "volumeColumn",
          Header: "Volume",
          accessor: "clicks",
          defaultCanSort: true,
          defaultSortDesc: true,
          sortDescFirst: true,
          sortType: "alphanumericCustom",
          // eslint-disable-next-line react/display-name
          Cell: (props: { value: number }) => formatComparisonNumbers({ value: props.value, hideSign: true }),
        },
    {
      id: "popComparisonColumn",
      Header: popLabel,
      accessor: "clicksPopComparison",
      defaultCanSort: true,
      sortDescFirst: true,
      sortType: "alphanumericFalsyLast",
      // eslint-disable-next-line react/display-name
      Cell: formatComparisonNumbers,
    },
    {
      id: "popPercentageColumn",
      Header: `% ${popLabel}`,
      accessor: "clicksPopPercentage",
      defaultCanSort: true,
      sortDescFirst: true,
      sortType: "alphanumericFalsyLast",
      Cell: renderComparisonCell,
    },
    {
      id: "yoyColumn",
      Header: "YoY",
      accessor: "clicksYoyComparison",
      defaultCanSort: true,
      sortDescFirst: true,
      sortType: "alphanumericFalsyLast",
      Cell: formatComparisonNumbers,
    },
    {
      id: "yoyPercentageColumn",
      Header: "% YoY",
      accessor: "clicksYoyPercentage",
      defaultCanSort: true,
      sortDescFirst: true,
      sortType: "alphanumericFalsyLast",
      Cell: renderComparisonCell,
    },
  ];
};

export const getImpressionsColumns = ({ handleImpressionsClick, popLabel, showPercentageShare, clicksChecked }: any) => {
  return [
    {
      id: "queryColumn",
      Header: "Query",
      accessor: "hostname",
      defaultCanSort: true,
      sortDescFirst: true,
      minWidth: 15,
      width: 15,
      maxWidth: 15,
      // eslint-disable-next-line react/display-name
      Cell: (props: { value: string }) => (
        <>
          <div className="table_link_div">
            <a className="specific_link" onClick={handleImpressionsClick} data-text={props.value}>
              {props.value}
            </a>
          </div>
          <span className="tooltip-cell">{props.value}</span>
        </>
      ),
    },

    {
      id: "metricColor",
      defaultCanSort: false,
      minWidth: 2,
      width: 2,
      maxWidth: 2,
      // eslint-disable-next-line react/display-name
      Cell: () =>
        clicksChecked ? (
          <div className="metric_column">
            <span className="metric_column__bar purple"></span>
            <span className="tooltip-cell">Impressions</span>
          </div>
        ) : (
          ""
        ),
    },
    showPercentageShare
      ? {
          id: "volumeColumn",
          Header: "% Share",
          accessor: "percentageShare",
          defaultCanSort: true,
          defaultSortDesc: true,
          sortDescFirst: true,
          sortType: "basicCustom",
          // eslint-disable-next-line react/display-name
          Cell: (props: { value: any }) =>
            isNaN(props.value) ? props.value : props.value.toLocaleString(DEFAULT_LANGUAGE, format_percent_options),
        }
      : {
          id: "volumeColumn",
          Header: "Volume",
          accessor: "current_data.impressions",
          defaultCanSort: true,
          defaultSortDesc: true,
          sortDescFirst: true,
          sortType: "alphanumericCustom",
          // eslint-disable-next-line react/display-name
          Cell: (props: { value: number }) => formatComparisonNumbers({ value: props.value, hideSign: true }),
        },
    {
      id: "popComparisonColumn",
      Header: popLabel,
      accessor: "popComparison",
      defaultCanSort: true,
      sortDescFirst: true,
      sortType: "alphanumericFalsyLast",
      // eslint-disable-next-line react/display-name
      Cell: formatComparisonNumbers,
    },
    {
      id: "popPercentageColumn",
      Header: `% ${popLabel}`,
      accessor: "popPercentage",
      defaultCanSort: true,
      sortDescFirst: true,
      sortType: "alphanumericFalsyLast",
      Cell: renderComparisonCell,
    },
    {
      id: "yoyColumn",
      Header: "YoY",
      accessor: "yoyComparison",
      defaultCanSort: true,
      sortDescFirst: true,
      sortType: "alphanumericFalsyLast",
      Cell: formatComparisonNumbers,
    },
    {
      id: "yoyPercentageColumn",
      Header: "% YoY",
      accessor: "yoyPercentage",
      defaultCanSort: true,
      sortDescFirst: true,
      sortType: "alphanumericFalsyLast",
      Cell: renderComparisonCell,
    },
  ];
};

interface ChildTableColumns {
  showPercentageShare: boolean;
  color: string;
  metric: string;
}
//TO BE USED: Refactoring the tables need to  use one value to render the columns
export const getChildTableColumns = ({ showPercentageShare, color, metric }: ChildTableColumns) => {
  return [
    {
      minWidth: 30,
      width: 30,
      maxWidth: 30,
    },
    {
      id: "metricColor",
      minWidth: 2,
      width: 2,
      maxWidth: 2,
      Cell: () => (
        <div className="metric_column">
          <span className={`metric_column__bar ${color}`}></span>
        </div>
      ),
    },
    showPercentageShare
      ? {
          id: `${metric}Visits`,
          accessor: `${metric}PercentageShare`,
          defaultCanSort: true,
          defaultSortDesc: true,
          sortDescFirst: true,
          sortType: "basic",
          Cell: (props: { value: any }) =>
            isNaN(props.value) ? props.value : props.value.toLocaleString(DEFAULT_LANGUAGE, format_percent_options),
        }
      : {
          Header: "",
          id: `${metric}Visits`,
          accessor: `${metric}Visits`,
          defaultCanSort: true,
          defaultSortDesc: true,
          sortDescFirst: true,
          Cell: (props: { value: number }) => formatComparisonNumbers({ value: props.value, hideSign: true }),
        },
    {
      id: `${metric}PopActual`,
      accessor: `${metric}PopComparison`,
      defaultCanSort: true,
      sortDescFirst: true,
      sortType: "alphanumericFalsyLast",
      Cell: formatComparisonNumbers,
    },
    {
      id: `${metric}PopComparison`,
      accessor: `${metric}PopPercentage`,
      defaultCanSort: true,
      sortDescFirst: true,
      sortType: "alphanumericFalsyLast",
      Cell: renderComparisonCell,
    },
    {
      id: `${metric}Yoy`,
      accessor: `${metric}YoyComparison`,
      defaultCanSort: true,
      sortDescFirst: true,
      sortType: "alphanumericFalsyLast",
      Cell: formatComparisonNumbers,
    },
    {
      accessor: `${metric}YoyPercentage`,
      defaultCanSort: true,
      sortDescFirst: true,
      sortType: "alphanumericFalsyLast",
      Cell: renderComparisonCell,
    },
  ];
};
