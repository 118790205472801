import { ErrorBoundary } from "@sentry/react";
import React, { useContext } from "react";
import "../../assets/styles/component/documentation.scss";
import { PasswordResetContext } from "../../context";
import { ErrorMsg } from "../AppMessages";
import { SetPasswordForm } from "../Forms";

export const PasswordReset = () => {
  const { showResetModal, token } = useContext(PasswordResetContext);

  return (
    <ErrorBoundary fallback={<ErrorMsg class={"formError"} />}>
      <div className={`popup ${showResetModal ? "show" : ""}`}>
        <div className="popupModal withForm">
          <SetPasswordForm token={token} />
        </div>
      </div>
    </ErrorBoundary>
  );
};
