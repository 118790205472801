import React, { useContext } from "react";
import "../../../assets/styles/component/documentation.scss";
import "../../../assets/styles/component/releaseNotes.scss";
import { PasswordResetContext } from "../../../context";

export const SuccessfulPasswordReset = () => {
  const { showSuccessMessage, setShowSuccessMessage } = useContext(PasswordResetContext);
  return (
    <div className={`popup release_popup ${showSuccessMessage ? "show" : ""}`}>
      <div className="popupModal ">
        <button className="closePopup" onClick={() => setShowSuccessMessage(false)}>
          ╳
        </button>

        <div className="data_message">
          <p>Thank you, your password has been updated successfully</p>
        </div>
      </div>
    </div>
  );
};
