import { all, put, retry, takeLatest } from "redux-saga/effects";
import {
  setFutureMarketBrandSearch,
  setFutureMarketMediaMlc,
  setFutureMarketPaidImpressions,
  setFutureMarketReach,
  setInMarketLeadVolume,
  setInMarketMediaMlc,
  setInMarketTotalSales,
  setMtmCurrency,
  setNearMarketKbaVisits,
  setNearMarketMediaMlc,
  setNearMarketUniqueVisits,
  setNearMarketVisits,
} from "../actions";
import {
  FETCH_FUTURE_MARKET_BRAND_SEARCH,
  FETCH_FUTURE_MARKET_MEDIA_MLC,
  FETCH_FUTURE_MARKET_PAID_IMPRESSIONS,
  FETCH_FUTURE_MARKET_REACH,
  FETCH_IN_MARKET_LEAD_JAPAN_VOLUME,
  FETCH_IN_MARKET_LEAD_VOLUME,
  FETCH_IN_MARKET_MEDIA_MLC,
  FETCH_IN_MARKET_TOTAL_SALES,
  FETCH_MTM_CURRENCY,
  FETCH_NEAR_MARKET_KBA_VISITS,
  FETCH_NEAR_MARKET_MEDIA_MLC,
  FETCH_NEAR_MARKET_UNIQUE_VISITS,
  FETCH_NEAR_MARKET_VISITS,
} from "../actions/actionTypes";
import { setLoading } from "../actions/loadingActions";
import {
  getFutureMarketBrandSearchData,
  getFutureMarketMediaMlcData,
  getFutureMarketPaidImpressionsData,
  getFutureMarketReachData,
  getInMarketLeadVolumeData,
  getInMarketMediaMlcData,
  getInMarketTotalSalesData,
  getJapanInMarketLeadVolumeData,
  getMtmCurrency,
  getNearMarketKbaVisits,
  getNearMarketMediaMlcData,
  getNearMarketUniqueVisitsData,
  getNearMarketVisitsData,
} from "../api/mtmReportApi";
import { secondsDelay } from "../api/utils";
import {
  FMIFutureData,
  FMIInMarketData,
  FMINearMarketData,
  MTMCloseRateData,
  MTMCurrency,
  MTMImpressionsData,
  MTMKbasData,
  MTMLeadsData,
  MTMReachData,
  MTMSalesData,
  MTMSearchData,
  MTMVisitsData,
} from "../constants/interface/mtmReport/data";

//WATCHER SAGAS
export function* watchFetchMtmCurrency() {
  yield takeLatest(FETCH_MTM_CURRENCY, fetchMtmCurrencyData);
}

export function* watchFetchFutureMarketMediaMlc() {
  yield takeLatest(FETCH_FUTURE_MARKET_MEDIA_MLC, fetchFutureMarketMediaMlcData);
}

export function* watchFetchFutureMarketPaidImpressions() {
  yield takeLatest(FETCH_FUTURE_MARKET_PAID_IMPRESSIONS, fetchFutureMarketPaidImpressionsData);
}

export function* watchFetchFutureMarketReach() {
  yield takeLatest(FETCH_FUTURE_MARKET_REACH, fetchFutureMarketReachData);
}

export function* watchFetchFutureMarketBrandSearch() {
  yield takeLatest(FETCH_FUTURE_MARKET_BRAND_SEARCH, fetchFutureMarketBrandSearchData);
}

export function* watchFetchInMarketMediaMlc() {
  yield takeLatest(FETCH_IN_MARKET_MEDIA_MLC, fetchInMarketMediaMlcData);
}

export function* watchFetchInMarketLeadVolume() {
  yield takeLatest(FETCH_IN_MARKET_LEAD_VOLUME, fetchInMarketLeadVolumeData);
}

export function* watchFetchJapanInMarketLeadVolume() {
  yield takeLatest(FETCH_IN_MARKET_LEAD_JAPAN_VOLUME, fetchJapanInMarketLeadVolumeData);
}

export function* watchFetchInMarketTotalSales() {
  yield takeLatest(FETCH_IN_MARKET_TOTAL_SALES, fetchInMarketTotalSalesData);
}

export function* watchFetchNearMarketMediaMlc() {
  yield takeLatest(FETCH_NEAR_MARKET_MEDIA_MLC, fetchNearMarketMediaMlcData);
}
export function* watchFetchNearMarketVisits() {
  yield takeLatest(FETCH_NEAR_MARKET_VISITS, fetchNearMarketVisitsData);
}
export function* watchFetchNearMarketUniqueVisits() {
  yield takeLatest(FETCH_NEAR_MARKET_UNIQUE_VISITS, fetchNearMarketUniqueVisitsData);
}
export function* watchFetchNearMarketKbaVisits() {
  yield takeLatest(FETCH_NEAR_MARKET_KBA_VISITS, fetchNearMarketKbaVisitsData);
}

//WORKER SAGAS
function* fetchMtmCurrencyData() {
  try {
    const response: MTMCurrency = yield retry(4, secondsDelay, getMtmCurrency);
    if (!("error" in response)) {
      yield all([put(setMtmCurrency(response.currency))]);
    }
  } catch (err) {
    console.log(err);
  }
}

function* fetchFutureMarketMediaMlcData() {
  try {
    yield put(setLoading("future_market_media_mlc", true));
    const response: FMIFutureData = yield retry(4, secondsDelay, getFutureMarketMediaMlcData);
    if (!("error" in response)) {
      yield put(setFutureMarketMediaMlc(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("future_market_media_mlc", false));
  }
}

function* fetchFutureMarketPaidImpressionsData() {
  try {
    yield put(setLoading("future_market_paid_impressions", true));
    const response: MTMImpressionsData = yield retry(4, secondsDelay, getFutureMarketPaidImpressionsData);
    if (!("error" in response)) {
      yield put(setFutureMarketPaidImpressions(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("future_market_paid_impressions", false));
  }
}

function* fetchFutureMarketReachData() {
  try {
    yield put(setLoading("future_market_reach", true));
    const response: MTMReachData = yield retry(4, secondsDelay, getFutureMarketReachData);
    if (!("error" in response)) {
      yield put(setFutureMarketReach(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("future_market_reach", false));
  }
}

function* fetchFutureMarketBrandSearchData() {
  try {
    yield put(setLoading("future_market_brand_search", true));
    const response: MTMSearchData = yield retry(4, secondsDelay, getFutureMarketBrandSearchData);
    if (!("error" in response)) {
      yield put(setFutureMarketBrandSearch(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("future_market_brand_search", false));
  }
}

function* fetchInMarketMediaMlcData() {
  try {
    yield put(setLoading("in_market_media_mlc", true));
    const response: FMINearMarketData = yield retry(4, secondsDelay, getInMarketMediaMlcData);
    if (!("error" in response)) {
      yield put(setInMarketMediaMlc(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("in_market_media_mlc", false));
  }
}

function* fetchInMarketLeadVolumeData() {
  try {
    yield put(setLoading("in_market_lead_volume", true));
    const response: MTMVisitsData = yield retry(4, secondsDelay, getInMarketLeadVolumeData);
    if (!("error" in response)) {
      yield put(setInMarketLeadVolume(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("in_market_lead_volume", false));
  }
}

function* fetchJapanInMarketLeadVolumeData() {
  try {
    yield put(setLoading("in_market_lead_volume", true));
    const response: MTMVisitsData = yield retry(4, secondsDelay, getJapanInMarketLeadVolumeData);
    if (!("error" in response)) {
      yield put(setInMarketLeadVolume(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("in_market_lead_volume", false));
  }
}

function* fetchInMarketTotalSalesData() {
  try {
    yield put(setLoading("in_market_total_sales", true));
    const response: MTMKbasData = yield retry(4, secondsDelay, getInMarketTotalSalesData);
    if (!("error" in response)) {
      yield put(setInMarketTotalSales(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("in_market_total_sales", false));
  }
}

function* fetchNearMarketMediaMlcData() {
  try {
    yield put(setLoading("near_market_media_mlc", true));
    const response: FMIInMarketData = yield retry(4, secondsDelay, getNearMarketMediaMlcData);
    if (!("error" in response)) {
      yield put(setNearMarketMediaMlc(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("near_market_media_mlc", false));
  }
}

function* fetchNearMarketVisitsData() {
  try {
    yield put(setLoading("near_market_visits", true));
    const response: MTMLeadsData = yield retry(4, secondsDelay, getNearMarketVisitsData);
    if (!("error" in response)) {
      yield put(setNearMarketVisits(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("near_market_visits", false));
  }
}

function* fetchNearMarketUniqueVisitsData() {
  try {
    yield put(setLoading("near_market_unique_visits", true));
    const response: MTMCloseRateData = yield retry(4, secondsDelay, getNearMarketUniqueVisitsData);
    if (!("error" in response)) {
      yield put(setNearMarketUniqueVisits(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("near_market_unique_visits", false));
  }
}

function* fetchNearMarketKbaVisitsData() {
  try {
    yield put(setLoading("near_market_kba_visits", true));
    const response: MTMSalesData = yield retry(4, secondsDelay, getNearMarketKbaVisits);
    if (!("error" in response)) {
      yield put(setNearMarketKbaVisits(response));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setLoading("near_market_kba_visits", false));
  }
}
