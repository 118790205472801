import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useMemo } from "react";
import { ErrorMsg, NoDataMsg } from "../../../../components/AppMessages";
import CumulativeTrendLineChart from "../../../../components/Charts/Midas/CumulativeTrendLineChart";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { LIGHT_GREEN, RED } from "../../../../constants";
import { ThemeContext } from "../../../../context";

interface Props {
  chartData: any;
  loading: boolean;
  seriesList: { name: string; seriesColor: string }[];
  maxAmount: number;
  minAmount: number;
}

const CumulativeTrendline = (props: Props) => {
  const themeContext = useContext(ThemeContext);
  const { chartData, loading, seriesList, maxAmount, minAmount } = props;

  const labelChartData = useMemo(() => {
    if (seriesList?.length > 1) {
      const result: any = [];
      chartData?.map((val: { [x: string]: number }) => {
        const highest =
          val[`${seriesList[0].name}_cumulative_amount`] > val[`${seriesList[1].name}_cumulative_amount`]
            ? seriesList[0].name
            : seriesList[1].name;
        const lowest = highest?.includes(seriesList[0].name) ? seriesList[1].name : seriesList[0].name;
        const newRow = { ...val };
        newRow[`highest_cumulative_amount`] = val[`${highest}_cumulative_amount`];
        newRow[`lowest_cumulative_amount`] = val[`${lowest}_cumulative_amount`];
        result.push(newRow);
      });
      return result;
    }
    return chartData;
  }, [chartData]);

  const updatedSeriesList = useMemo(() => {
    const sortRows: Array<{ name: string; seriesColor: string }> = [
      { name: "highest", seriesColor: RED },
      { name: "lowest", seriesColor: LIGHT_GREEN },
    ];

    return [...seriesList, ...sortRows];
  }, [seriesList, labelChartData]);

  return (
    <div className="cumulative_trend primary_background">
      <div className="fmi_tile_header">
        <div className="title">
          <h3>FMI cumulative overview</h3>
        </div>
        {/* Legend on Mtp Section */}
        <div className="legend">
          {seriesList?.map(({ name, seriesColor }, index: number) => (
            <div className="legend_item" key={name}>
              <div className="legend_box" style={{ backgroundColor: seriesColor }}></div>
              {name.replace(new RegExp("_", "g"), " ")}
            </div>
          ))}
        </div>
      </div>
      <div className="main_content ">
        {chartData?.length && !loading ? (
          <ErrorBoundary fallback={<ErrorMsg />}>
            <CumulativeTrendLineChart
              minAmount={minAmount}
              data={labelChartData}
              isLoading={loading}
              chartId="fmiCumulativeChart"
              theme={themeContext.theme}
              seriesList={updatedSeriesList}
              maxAmount={maxAmount}
            />
          </ErrorBoundary>
        ) : loading ? (
          <LoadingEllipsis isLoading={loading} />
        ) : (
          <NoDataMsg message="No data available" />
        )}
      </div>
    </div>
  );
};

export default CumulativeTrendline;
