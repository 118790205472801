import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { setSpecificParameter } from "../../../../actions";
import { fetchFeedbackComments, fetchFeedbackCommentsFilters } from "../../../../actions/vocActions";
import { ErrorMsg } from "../../../../components/AppMessages";
import { InAppMultiSelectFilter } from "../../../../components/Filters/common/InAppMultiselectFilter";
import { closeFilterOptions } from "../../../../components/Filters/subs/helpers";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { DefaultTable } from "../../../../components/Tables/subs";
import { ToggleSwitch } from "../../../../components/Toogle";

const VerbatimTable = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const dispatch = useDispatch();

  const data = useSelector((state: RootStateOrAny) => state.voc.feedback_comments);
  const { experience_ratings, topics, touchpoints } = useSelector((state: RootStateOrAny) => state.voc.feedback_comments_filters);

  const loading = useSelector((state: RootStateOrAny) => state.loading.feedback_comments);

  const [touchpointFilterValue, setTouchpointFilterValue] = useState("All");
  const [topicFilterValue, setTopicFilterValue] = useState("All");
  const [experienceRatingFilterValue, setExperienceRatingFilterValue] = useState("All");
  const [toggleValue, setToggleValue] = useState(true);
  const touchpointParam = "touchpoint";
  const topicParam = "topic";
  const experienceRatingParam = "experience_rating";

  useEffect(() => {
    const experienceRating = experienceRatingFilterValue == "All" ? [] : experienceRatingFilterValue.split(",");
    const topics = topicFilterValue == "All" ? [] : topicFilterValue.split(",");
    const touchpoints = touchpointFilterValue == "All" ? [] : touchpointFilterValue.split(",");
    dispatch(fetchFeedbackComments(experienceRating, topics, touchpoints));
  }, [history.location.search, touchpointFilterValue, topicFilterValue, experienceRatingFilterValue]);

  useEffect(() => {
    dispatch(fetchFeedbackCommentsFilters());
  }, [history.location.search]);

  const columns = useMemo(
    () => [
      {
        id: "market",
        Header: "Market",
        accessor: "market",
      },
      {
        id: "date",
        Header: "Date",
        accessor: "date",
      },
      {
        id: "experienceRating",
        Header: "Experience rating",
        accessor: "experience_rating",
      },
      {
        id: "topic",
        Header: "Topic",
        accessor: "topic",
      },
      {
        id: "touchpoint",
        Header: "Touchpoints",
        accessor: "touchpoint",
      },
      {
        id: "subcategory",
        Header: "Subcategory",
        accessor: "sub_category",
        Cell: (cell: { value: string }) => {
          const { value } = cell;
          return value ? value : "n/a";
        },
      },
      {
        id: "url",
        Header: "URL",
        accessor: "url",
        Cell: (cell: { value: string }) => {
          const { value } = cell;
          return (
            <a href={value} target="_blank" rel="noreferrer" className="link">
              {value}
            </a>
          );
        },
      },
      {
        id: "devicetype",
        Header: "Device type",
        accessor: "device_type",
      },
      {
        id: "comment",
        Header: "Comment",
        accessor: `${toggleValue ? "verbatim_concat_en" : "verbatim_concat"}`,
      },
    ],
    [data, toggleValue]
  );

  const handleTouchpointMultiselectSubmit = useCallback(() => {
    const parameterName = touchpointParam;
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = touchpoints?.length === selectedValues?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    setTouchpointFilterValue(filterValue);
    dispatch(setSpecificParameter(parameterName, filterValue));
  }, [touchpoints]);

  const handleTopicMultiselectSubmit = useCallback(() => {
    const parameterName = topicParam;
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = topics?.length === selectedValues?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    setTopicFilterValue(filterValue);
    dispatch(setSpecificParameter(parameterName, filterValue));
  }, [topics]);

  const handleExperienceRatingMultiselectSubmit = useCallback(() => {
    const parameterName = experienceRatingParam;
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));

    const filterValue = experience_ratings?.length === selectedValues?.length ? "All" : selectedValues?.join(",");
    closeFilterOptions();
    setExperienceRatingFilterValue(filterValue);
    dispatch(setSpecificParameter(parameterName, filterValue));
  }, [experience_ratings]);

  const filteredTopicVerbatimData = useMemo(() => {
    if (data) {
      let dataCopy = [...data];
      dataCopy = data.filter(
        (item: any) =>
          (touchpointFilterValue == "All" ? true : touchpointFilterValue.includes(item.touchpoint)) &&
          (topicFilterValue == "All" ? true : topicFilterValue.includes(item.topic)) &&
          (experienceRatingFilterValue == "All" ? true : experienceRatingFilterValue.includes(String(item.experience_rating)))
      );
      return dataCopy;
    }
    return [];
  }, [touchpointFilterValue, topicFilterValue, data]);

  const totalVerbatimCount = useMemo(() => {
    if (data && data.length > 0) {
      return data[0].verbatim_concat_count;
    }
    return null;
  }, [data]);

  return (
    <div>
      <div className="row grid">
        <div className="tile h-30 grid-col-span-6 verbatimTile">
          <div className="space-between verbatim-tile-header">
            <div className="tileHeader">
              <p>Verbatim</p>
            </div>
            <div className="flex-row">
              <div className="verbatim-count">Total number of verbatims: {totalVerbatimCount ? totalVerbatimCount : "n/a"}</div>
              <ErrorBoundary fallback={<ErrorMsg />}>
                <InAppMultiSelectFilter
                  filterLabel="Select Experience Rating"
                  optionsList={experience_ratings?.map((rating: number) => String(rating)) ?? []}
                  filterValue={experienceRatingFilterValue}
                  parameterName={experienceRatingParam}
                  parameterValue={experienceRatingFilterValue}
                  handleFilterSubmission={handleExperienceRatingMultiselectSubmit}
                />
              </ErrorBoundary>
              <ErrorBoundary fallback={<ErrorMsg />}>
                <InAppMultiSelectFilter
                  filterLabel="Select Topics"
                  optionsList={topics ?? []}
                  filterValue={topicFilterValue}
                  parameterName={topicParam}
                  parameterValue={topicFilterValue}
                  handleFilterSubmission={handleTopicMultiselectSubmit}
                />
              </ErrorBoundary>
              <ErrorBoundary fallback={<ErrorMsg />}>
                <InAppMultiSelectFilter
                  filterLabel="Select Touchpoints"
                  optionsList={touchpoints ?? []}
                  filterValue={touchpointFilterValue}
                  parameterName={touchpointParam}
                  parameterValue={touchpointFilterValue}
                  handleFilterSubmission={handleTouchpointMultiselectSubmit}
                />
              </ErrorBoundary>
              <ToggleSwitch
                activeToggleLabel={"English"}
                inactiveToggleLabel={"Native"}
                active={toggleValue}
                handleToggleClick={() => setToggleValue((prevState) => !prevState)}
              />
            </div>
          </div>
          {loading ? (
            <LoadingEllipsis isLoading={loading} />
          ) : (
            <DefaultTable
              columns={columns}
              data={filteredTopicVerbatimData}
              id={"topicVerbatimTable"}
              isChildComponent={false}
              initialSortColId={{ id: "market", desc: false }}
              hiddenCols={[]}
              extraClass="verbatimTable"
              showPagination={true}
              pageSize={50}
            />
          )}
        </div>
      </div>
    </div>
  );
});

export default VerbatimTable;
