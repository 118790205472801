import React from "react";

interface Props {
  documentation: string;
  setDocumentation: any;
  menuItems: Array<{ name: string; docName: string }>;
}

export const InfoSideMenu = (props: Props) => {
  const { menuItems, documentation, setDocumentation } = props;

  return (
    <ul className="info_section info_options" data-test-id="kpi_menu">
      {menuItems.map((menuItem) => (
        <li
          key={menuItem.docName}
          className={`kpi_option ${documentation === menuItem.docName ? "active" : ""}`}
          onClick={() => setDocumentation(menuItem.docName)}
        >
          {menuItem.name}
        </li>
      ))}
    </ul>
  );
};
