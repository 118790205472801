export const REGION_CODES: { [index: string]: string } = {
  "Latin America": "NLAC",
  "Asia & Oceania": "A&O",
  "Africa, Middle East and India": "AMI",
  "Africa, Middle East & India": "AMI",
  Europe: "NAE",
  "North America": "NNA",
  "Nissan South America": "NSAM",
};

export const METRIC_TITLES: { [index: string]: string } = {
  kbas: "KBA visits",
  visits: "Website visits",
  leads: "Digital lead visits",
};
