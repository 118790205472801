import React from "react";
import { CockpitTitle, CustomerQualityTiles } from "../normalSections";

export const CustomerQualityMetric = () => {
  return (
    <>
      <CockpitTitle title={"Business revenue"} hide_on_desktop={true} />
      <CockpitTitle title={"Customer quality"} hide_on_desktop={true} />
      <CustomerQualityTiles showMetric={true} />

      <CockpitTitle title={"Engagement"} hide_on_desktop={true} />
      <CockpitTitle title={"Operational"} hide_on_desktop={true} />
    </>
  );
};
