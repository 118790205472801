export const FETCH_ANNOTATION_BRANDS = "FETCH_ANNOTATION_BRANDS";
export const SET_ANNOTATION_BRANDS = "SET_ANNOTATION_BRANDS";
export const FETCH_ANNOTATION_REGIONS = "FETCH_ANNOTATION_REGIONS";
export const SET_ANNOTATION_REGIONS = "SET_ANNOTATION_REGIONS";
export const FETCH_ANNOTATION_MARKETS = "FETCH_ANNOTATION_MARKETS";
export const SET_ANNOTATION_MARKETS = "SET_ANNOTATION_MARKETS";
export const FETCH_ANNOTATION_NEW_MODELS = "FETCH_ANNOTATION_NEW_MODELS";
export const SET_ANNOTATION_NEW_MODELS = "SET_ANNOTATION_NEW_MODELS";
export const FETCH_ANNOTATION_CHANNELS = "FETCH_ANNOTATION_CHANNELS";
export const SET_ANNOTATION_CHANNELS = "SET_ANNOTATION_CHANNELS";
export const FETCH_ANNOTATION_CHANNEL_BREAKDOWNS = "FETCH_ANNOTATION_CHANNEL_BREAKDOWNS";
export const SET_ANNOTATION_CHANNEL_BREAKDOWNS = "SET_ANNOTATION_CHANNEL_BREAKDOWNS";
export const FETCH_ANNOTATION_METRICS = "FETCH_ANNOTATION_METRICS";
export const SET_ANNOTATION_METRICS = "SET_ANNOTATION_METRICS";
export const FETCH_ANNOTATION_LEAD_SOURCES = "FETCH_ANNOTATION_LEAD_SOURCES";
export const SET_ANNOTATION_LEAD_SOURCES = "SET_ANNOTATION_LEAD_SOURCES";
