import React, { useEffect, useRef, useState } from "react";
import { MtpCategoryTable } from "../../../../components/Tables";
import { QuarterMonths } from "./QuarterMonths";
import YrScenarioRows from "./YrScnarioRows";

interface Props {
  periodClass: string;
  period: string;
  yrScnrClass: string;
  yrScenarios: string[];
  totalsCombined: any;
  quarters: number[];
}

const PdfView = (props: Props) => {
  const { periodClass, quarters, totalsCombined, period, yrScnrClass, yrScenarios } = props;

  const months = [
    [4, 5, 6],
    [7, 8, 9],
    [10, 11, 12],
    [1, 2, 3],
  ];

  const monthTableRef = useRef<any>(null);
  const quarterTableRef = useRef<any>(null);
  const fyRef = useRef<any>(null);

  const [monthTableWidth, setMonthTableWidth] = useState(0);
  const [quarterTableWidth, setQuarterTableWidth] = useState(0);
  const [yearTableWidth, setYearTableWidth] = useState(0);

  useEffect(() => {
    const monthTableWidth = monthTableRef.current ? monthTableRef.current.offsetWidth : 0;
    const quarterTableWidth = quarterTableRef.current ? quarterTableRef.current.offsetWidth : 0;
    const yearTableWidth = fyRef.current ? fyRef.current.offsetWidth : 0;

    setMonthTableWidth(monthTableWidth);
    setQuarterTableWidth(quarterTableWidth);
    setYearTableWidth(yearTableWidth);
  }, [monthTableRef, quarterTableRef, fyRef, period, yrScenarios, yrScnrClass, periodClass, totalsCombined]);

  return (
    <div className={`pdf_tables ${periodClass} ${yrScnrClass}`}>
      {periodClass?.includes("month") ? (
        <>
          {yrScnrClass?.length ? (
            <>
              <div className={`q1_to_q3 ${periodClass} ${yrScnrClass}`}>
                <div style={{ width: `${monthTableWidth}px` }} className={`pdf_titles quarter_content ${periodClass} ${yrScnrClass}`}>
                  <div
                    style={{ width: `${monthTableWidth - 288}px` }}
                    className={`quarter_titles pdf_download ${periodClass} ${yrScnrClass}`}
                  >
                    <div className="quarter_title">
                      <span className="quarter_title">Q1</span>
                      {period == "FY Month" && <QuarterMonths yrScenarios={yrScenarios} quarter={`q1`} combined />}
                      {period == "FY Quarter" && <YrScenarioRows yrScenarios={yrScenarios} />}
                    </div>
                    <div className="quarter_title">
                      <span className="quarter_title">Q2</span>
                      {period == "FY Month" && <QuarterMonths yrScenarios={yrScenarios} quarter={`q2`} combined />}
                      {period == "FY Quarter" && <YrScenarioRows yrScenarios={yrScenarios} />}
                    </div>
                  </div>
                </div>

                <MtpCategoryTable
                  selectedref={monthTableRef}
                  tableId={`mtpCategorycombined12  ${yrScnrClass} ${periodClass}`}
                  tableData={totalsCombined}
                  keys={yrScenarios}
                  months={[4, 5, 6, 7, 8, 9]}
                />
              </div>
              <div className={`q1_to_q3 ${periodClass} ${yrScnrClass}`}>
                <div style={{ width: `${monthTableWidth}px` }} className={`pdf_titles quarter_content ${periodClass} ${yrScnrClass}`}>
                  <div
                    style={{ width: `${monthTableWidth - 288}px` }}
                    className={`quarter_titles pdf_download ${periodClass} ${yrScnrClass}`}
                  >
                    <div className="quarter_title">
                      <span className="quarter_title">Q3</span>
                      {period == "FY Month" && <QuarterMonths yrScenarios={yrScenarios} quarter={`q3`} combined />}
                      {period == "FY Quarter" && <YrScenarioRows yrScenarios={yrScenarios} />}
                    </div>
                    <div className="quarter_title">
                      <span className="quarter_title">Q4</span>
                      {period == "FY Month" && <QuarterMonths yrScenarios={yrScenarios} quarter={`q4`} combined />}
                      {period == "FY Quarter" && <YrScenarioRows yrScenarios={yrScenarios} />}
                    </div>
                  </div>
                </div>

                <MtpCategoryTable
                  tableId={`mtpCategorycombined34  ${yrScnrClass} ${periodClass}`}
                  tableData={totalsCombined}
                  keys={yrScenarios}
                  months={[10, 11, 12, 1, 2, 3]}
                />
              </div>
              ;
            </>
          ) : (
            <>
              {quarters.map((quarter: number) => {
                return (
                  <div key={quarter} className={`q1_to_q3 ${periodClass} ${yrScnrClass}`}>
                    <div style={{ width: `${monthTableWidth}px` }} className={`pdf_titles quarter_content ${periodClass} ${yrScnrClass}`}>
                      <div
                        style={{ width: `${monthTableWidth - 288}px` }}
                        className={`quarter_titles pdf_download ${periodClass} ${yrScnrClass}`}
                      >
                        <div className="quarter_title">
                          <span className="quarter_title">Q{quarter}</span>
                          {period == "FY Month" && <QuarterMonths yrScenarios={yrScenarios} quarter={`q${quarter}`} combined />}
                          {period == "FY Quarter" && <YrScenarioRows yrScenarios={yrScenarios} />}
                        </div>
                      </div>
                    </div>

                    <MtpCategoryTable
                      selectedref={monthTableRef}
                      tableId={`mtpCategorycombined${quarter}  ${yrScnrClass} ${periodClass}`}
                      tableData={totalsCombined}
                      keys={yrScenarios}
                      months={period == "FY Month" ? [...months[quarters?.indexOf(quarter)]] : [quarter]}
                    />
                  </div>
                );
              })}
            </>
          )}
        </>
      ) : (
        <>
          <div className={`q1_to_q3 ${periodClass} ${yrScnrClass}`}>
            <div style={{ width: `${quarterTableWidth}px` }} className={`pdf_titles quarter_content ${periodClass} ${yrScnrClass}`}>
              <div
                style={{ width: `${quarterTableWidth - 288}px` }}
                className={`quarter_titles pdf_download ${periodClass} ${yrScnrClass}`}
              >
                <div className="quarter_title">
                  <span className="quarter_title">Q1</span>
                  {period == "FY Month" && <QuarterMonths yrScenarios={yrScenarios} quarter="q1" combined />}
                  {period == "FY Quarter" && <YrScenarioRows yrScenarios={yrScenarios} />}
                </div>
                <div className="quarter_title">
                  <span className="quarter_title">Q2</span>
                  {period == "FY Month" && <QuarterMonths yrScenarios={yrScenarios} quarter="q2" combined />}
                  {period == "FY Quarter" && <YrScenarioRows yrScenarios={yrScenarios} />}
                </div>
                {yrScnrClass?.length && period !== "FY Month" ? (
                  <>
                    <div className="quarter_title">
                      <span className="quarter_title">Q3</span>
                      {period == "FY Month" && <QuarterMonths yrScenarios={yrScenarios} quarter="q3" combined />}
                      {period == "FY Quarter" && <YrScenarioRows yrScenarios={yrScenarios} />}
                    </div>
                    <div className="quarter_title">
                      <span className="quarter_title">Q4</span>
                      {period == "FY Month" && <QuarterMonths yrScenarios={yrScenarios} quarter="q4" combined />}
                      {period == "FY Quarter" && <YrScenarioRows yrScenarios={yrScenarios} />}
                    </div>
                    <div className="quarter_title">
                      <span className="quarter_title">Full year</span>
                      {period == "FY Quarter" && <YrScenarioRows yrScenarios={yrScenarios} />}
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            <MtpCategoryTable
              selectedref={quarterTableRef}
              tableId={`mtpCategorycombined2  ${yrScnrClass} ${periodClass}`}
              tableData={totalsCombined}
              keys={yrScenarios}
              months={period == "FY Month" ? [4, 5, 6, 7, 8, 9] : yrScnrClass?.length ? [1, 2, 3, 4, 0] : [1, 2]}
            />
          </div>

          {period == "FY Month" || (period !== "FY Month" && !yrScnrClass?.length) ? (
            <div className={`q1_to_q3 ${periodClass} ${yrScnrClass}`}>
              <div style={{ width: `${quarterTableWidth}px` }} className={`pdf_titles quarter_content ${periodClass} ${yrScnrClass}`}>
                <div
                  style={{ width: `${quarterTableWidth - 288}px` }}
                  className={`quarter_titles pdf_download ${periodClass} ${yrScnrClass}`}
                >
                  {" "}
                  <div className="quarter_title">
                    <span className="quarter_title">Q3</span>
                    {period == "FY Month" && <QuarterMonths yrScenarios={yrScenarios} quarter="q3" combined />}
                    {period == "FY Quarter" && <YrScenarioRows yrScenarios={yrScenarios} />}
                  </div>
                  <div className="quarter_title">
                    <span className="quarter_title">Q4</span>
                    {period == "FY Month" && <QuarterMonths yrScenarios={yrScenarios} quarter="q4" combined />}
                    {period == "FY Quarter" && <YrScenarioRows yrScenarios={yrScenarios} />}
                  </div>
                </div>
              </div>

              <MtpCategoryTable
                tableId={`mtpCategorycombined3  ${yrScnrClass} ${periodClass}`}
                tableData={totalsCombined}
                keys={yrScenarios}
                months={period == "FY Month" ? [10, 11, 12, 1, 2, 3] : [3, 4]}
              />
            </div>
          ) : null}
        </>
      )}
      {period == "FY Month" || (period !== "FY Month" && !yrScnrClass?.length) ? (
        <div className={`q4_to_full ${periodClass} ${yrScnrClass}`}>
          <div style={{ width: `${yearTableWidth - 288}px` }} className={`${periodClass} ${yrScnrClass} pdf_titles quarter_content`}>
            <div style={{ width: `${yearTableWidth - 288}px` }} className={`quarter_titles pdf_download ${periodClass} ${yrScnrClass}`}>
              <div className="quarter_title">
                <span className="quarter_title">Full year</span>
                {period == "FY Quarter" && <YrScenarioRows yrScenarios={yrScenarios} />}
              </div>
            </div>
          </div>
          <MtpCategoryTable
            selectedref={fyRef}
            tableId={`mtpCategorycombined  ${yrScnrClass} ${periodClass}`}
            tableData={totalsCombined}
            keys={yrScenarios}
            months={[0]}
          />
        </div>
      ) : null}
    </div>
  );
};

export default PdfView;
