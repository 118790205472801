import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "../../assets/styles/main.scss";
import "../../assets/styles/pages/login.scss";
import { SetPasswordForm } from "../../components/Forms";
import { setAppHeight } from "./subs/helpers";

export const SetPassword = withRouter((props: RouteComponentProps) => {
  const { location } = props;

  const searchParams = new URLSearchParams(location.search);

  const tokenParam = searchParams.get("token") as string;

  const dispatch = useDispatch();

  useEffect(() => {
    setAppHeight();
  }, [dispatch]);

  return (
    <div className={"pageWrapper"} id={"loginPage"}>
      <div className="dashboard" id="login_dashboard">
        <div id="container">
          <SetPasswordForm token={tokenParam} />
        </div>
      </div>
    </div>
  );
});
