import React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { InvRatio, LeadSalesConversion, TurnoverRatio } from "../helpers/tooltiptexts";
import TrendlineGraphs from "./TrendlineGraphs";

const RatioCharts = () => {
  const {
    data: InventoryRatioTrendData,
    series: InventoryRatioTrendSeries,
    avg: InventoryRatioTrendAvg,
  } = useSelector((state: RootStateOrAny) => state.cpo_operational.inventory_ratio_data);
  const {
    data: turnoverRatioTrendData,
    series: turnoverRatioTrendSeries,
    avg: turnoverRatioTrendAvg,
  } = useSelector((state: RootStateOrAny) => state.cpo_operational.turnover_ratio_data);
  const {
    data: leadSalesConversionRatioTrendData,
    series: leadSalesConversionRatioTrendSeries,
    avg: leadSalesConversionRatioTrendAvg,
  } = useSelector((state: RootStateOrAny) => state.cpo_operational.lead_sales_conversion_data);

  const {
    inventory_ratio: inventoryRatioTrendLoading,
    turnover_ratio: turnoverRatioTrendLoading,
    lead_sales_conversion: leadSalesConversionTrendLoading,
  } = useSelector((state: RootStateOrAny) => state.loading);

  return (
    <div className="trendline_graphs">
      <TrendlineGraphs
        toolTipParagraph={<InvRatio />}
        total={InventoryRatioTrendAvg}
        title="Inventory ratio"
        series={InventoryRatioTrendSeries}
        chartData={InventoryRatioTrendData}
        loading={inventoryRatioTrendLoading}
      />
      <TrendlineGraphs
        toolTipParagraph={<TurnoverRatio />}
        total={turnoverRatioTrendAvg}
        title="Turnover ratio"
        series={turnoverRatioTrendSeries}
        chartData={turnoverRatioTrendData}
        loading={turnoverRatioTrendLoading}
      />
      <TrendlineGraphs
        toolTipParagraph={<LeadSalesConversion />}
        total={leadSalesConversionRatioTrendAvg}
        series={leadSalesConversionRatioTrendSeries}
        title="Leads sales conversion"
        chartData={leadSalesConversionRatioTrendData}
        loading={leadSalesConversionTrendLoading}
      />
    </div>
  );
};

export default RatioCharts;
