import { ColumnSeries, DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create, NumberFormatter } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component, ReactNode } from "react";
import WebFont from "webfontloader";
import { BLACK, CYAN, WHITE } from "../../../constants";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import {
  columnSeriesConfiguration,
  configureDateAxis,
  configureValueAxis,
  legendConfiguration,
  lineSeriesConfiguration,
} from "../helpers/configurations";
import { formatValueAxisUnits, getTooltipDateFormat } from "../helpers/helpers";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { dateAxisLabelFormatter_QuarterlyFY, formatDateAxisLabelToQuarterlyFY } from "../helpers/pmoConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  data: any;
  theme: ThemeInt;
  isLoading: boolean;
  granularity: string;
  market: string;
  hasOaoData?: boolean;
}

class MediaSpendingChart extends Component<Props> {
  chartId = "mediaSpendingChart";
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { theme, hasOaoData, market, data, granularity } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);

    this.chart.legend = legendConfiguration(theme);
    this.chart.colors.list = [color(CYAN), theme === "light" ? color(BLACK) : color(WHITE)];
    this.chart.paddingBottom = 10;
    this.chart.legend.itemContainers.template.paddingBottom = 30;

    // Create data chart axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme, true);
    dateAxis.renderer.minGridDistance = 30;
    dateAxis.tooltipDateFormat = getTooltipDateFormat(granularity);
    granularity === "quarterly" && dateAxis.renderer.labels.template.adapter.add("text", dateAxisLabelFormatter_QuarterlyFY);

    // Creates value axis
    const valueAxis1 = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis1, theme);
    valueAxis1.min = 0;

    const valueAxis2 = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis2, theme);
    valueAxis2.renderer.opposite = true;
    valueAxis2.numberFormatter = new NumberFormatter();
    valueAxis2.renderer.grid.template.disabled = true;
    valueAxis2.renderer.labels.template.disabled = true;
    valueAxis2.max = 1;
    valueAxis2.strictMinMax = true;
    formatValueAxisUnits(valueAxis2, market);

    function createGrid(value: number) {
      const range = valueAxis2.axisRanges.create();
      range.value = value;
      range.label.text = "{value}";
    }

    if (hasOaoData) {
      createGrid(0);
      createGrid(0.5);
      createGrid(1);
    }

    const columnSeries = this.chart.series.push(new ColumnSeries());
    columnSeries.data = data.column_chart;
    columnSeriesConfiguration(columnSeries, {
      valueY: "media_spending",
      name: "Media spending",
    });
    columnSeries.xAxis = dateAxis;
    columnSeries.dataFields.dateX = "date";
    columnSeries.yAxis = valueAxis1;
    formatValueAxisUnits(valueAxis1, market);
    valueAxis1.numberFormatter.numberFormat = `#a`;

    if (granularity === "quarterly") columnSeries.dataFields.categoryX = "quarter";
    columnSeries.stroke = color(CYAN);
    columnSeries.fill = color(CYAN);
    columnSeries.columns.template.tooltipText = "{name}: [bold]{valueX}[/]";

    columnSeries.columns.template.adapter.add("tooltipText", (text, target) => {
      const data = target.dataItem?.dataContext;
      if (granularity === "quarterly") {
        //@ts-ignore
        const dateVal = formatDateAxisLabelToQuarterlyFY(data?.date);
        text = `[bold; font-size: var(--regular_font_size);] ${dateVal}[/ font-size: var(--regular_font_size);]: ${market} {valueY.formatNumber("#,###.")}`;
      } else {
        text = `[bold; font-size: var(--regular_font_size);]{dateX.formatDate('MMM YYYY')} [/ font-size: var(--regular_font_size);]: ${market} {valueY.formatNumber("#,###.")}`;
      }
      return text;
    });

    const lineSeries = this.chart.series.push(new LineSeries());
    lineSeries.data = data.column_chart;
    const bullet = lineSeriesConfiguration(lineSeries, {
      name: "OaO",
      valueY: "oao",
    });
    lineSeries.dataFields.dateX = "date";
    lineSeries.yAxis = valueAxis2;
    bullet.adapter.add("tooltipText", (text, target) => {
      if (granularity === "quarterly") {
        //@ts-ignore
        const dateVal = formatDateAxisLabelToQuarterlyFY(data?.date);
        text = `[bold; font-size: var(--regular_font_size);] ${dateVal}[/ font-size: var(--regular_font_size);]: {valueY.formatNumber("0.000")}`;
      } else {
        text = `[bold; font-size: var(--regular_font_size);]{dateX.formatDate('MMM YYYY')} [/ font-size: var(--regular_font_size);]: {valueY.formatNumber("#.###")}`;
      }
      return text;
    });
  }

  render(): ReactNode {
    return (
      <div className={"chartWrapper"}>
        <div id={"mediaSpendingChart"} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default MediaSpendingChart;
