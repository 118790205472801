import React from "react";
import "../../assets/styles/component/ratingsIndicator.scss";
import { getNewRatingsClass, isNull } from "../../utils/utilityFunctions";

const NewRatings = (props: { value: number }) => {
  const { value } = props;
  const rating = !isNull(value) ? getNewRatingsClass(value) : "na";

  return <span className={`indicator ${rating}`} data-test-rating={rating} />;
};

export default NewRatings;
