export const MTM_DATA_DEFINITIONS = [
  {
    market: "Overview",
    kpi: "Future market",
    definition: "People who are in the awareness stage. Might consider buying a new car in the future 1 year or more ",
    source_data: "PlanIt",
    upload_destination: "Box",
  },
  {
    market: "Overview",
    kpi: "Near market",
    definition: "People who are in the consideration stage. Will consider buying a car in the near 3 to 12 months ",
    source_data: "PlanIt",
    upload_destination: "Box",
  },
  {
    market: "Overview",
    kpi: "In market",
    definition: "People who are in the purchase stage. Have decided to buy a car in 3 months and are in the decision-making process",
    source_data: "PlanIt",
    upload_destination: "Box",
  },
  {
    market: "Overview",
    kpi: "FMI spend ",
    definition: "Media spend allocation across the customer journey: Future - Near - In",
    source_data: "PlanIt",
    upload_destination: "Box",
  },
  {
    market: "Future Market",
    kpi: "Impression_actual",
    definition: "Number of times an ad (display, video, etc) has been shown on the screen ",
    source_data: "Nissan United ",
    upload_destination: "Box",
  },
  {
    market: "Future Market",
    kpi: "Impression_forecast",
    definition: "Number of times an ad (display, video, etc) has been shown on the screen (Forecast) ",
    source_data: "Nissan United ",
    upload_destination: "Box",
  },
  {
    market: "Future Market",
    kpi: "Reach_actual",
    definition: "Number of people who may have seen Nissan content",
    source_data: "Nissan United ",
    upload_destination: "Box",
  },
  {
    market: "Future Market",
    kpi: "Reach_forecast",
    definition: "Number of people who may have seen Nissan content (Forecast) ",
    source_data: "Nissan United ",
    upload_destination: "Box",
  },
  {
    market: "Future Market",
    kpi: "Search interest ",
    definition:
      'The number of exact searches for the keyword "Nissan" (or a particular model if selected in the filter) within a given month',
    source_data: "Google Search",
    upload_destination: "CEDAR",
  },
  {
    market: "Near Market",
    kpi: "Visits_actual",
    definition: "The number of sessions across all visitors on your site",
    source_data: "Market Input",
    upload_destination: "CEDAR",
  },
  {
    market: "Near Market",
    kpi: "Visits_forecast",
    definition: "The number of sessions across all visitors on your site (Forecast)",
    source_data: "Market Input",
    upload_destination: "Box",
  },
  {
    market: "Near Market",
    kpi: "Qualified_visits_actual",
    definition: "= Visits - Bounce ",
    source_data: "Adobe",
    upload_destination: "CEDAR",
  },
  {
    market: "Near Market",
    kpi: "Qualified_visits_forecast",
    definition: "= Visits - Bounce (Forecast)",
    source_data: "Market Input",
    upload_destination: "Box",
  },
  {
    market: "Near Market",
    kpi: "KBA_actual",
    definition: "The number of events where a visitor engages with a buying tool or performs a key action as defined by the market",
    source_data: "Adobe",
    upload_destination: "CEDAR",
  },
  {
    market: "Near Market",
    kpi: "KBA_forecast",
    definition:
      "The number of events where a visitor engages with a buying tool or performs a key action as defined by the market (Forecast)",
    source_data: "Market Input",
    upload_destination: "Box",
  },
  {
    market: "Near Market",
    kpi: "KBA_target",
    definition:
      "The number of events where a visitor engages with a buying tool or performs a key action as defined by the market (Target)",
    source_data: "Market Input",
    upload_destination: "Box",
  },
  {
    market: "In market",
    kpi: "Tier1_leads_actual",
    definition: "The number of leads (sharing contact information) from the brand wesbite to a dealership",
    source_data: "Adobe",
    upload_destination: "CEDAR",
  },
  {
    market: "In market",
    kpi: "Tier1_leads_forecast",
    definition: "The number of leads (sharing contact information) from the brand wesbite to a dealership (Forecast)",
    source_data: "Market Input",
    upload_destination: "Box",
  },
  {
    market: "In market",
    kpi: "3rd_party_leads_actual",
    definition: "The number of leads (sharing contact information) from a third party (e.g dealership or social media)",
    source_data: "Market Input",
    upload_destination: "CEDAR",
  },
  {
    market: "In market",
    kpi: "3rd_party_leads_forecast",
    definition: "The number of leads (sharing contact information) from a third party (e.g dealership or social media) (Forecast)",
    source_data: "Market Input",
    upload_destination: "Box",
  },

  {
    market: "In market",
    kpi: "DIS_actual",
    definition: "The number of new car sales that can be attributed to OEM managed digital activity",
    source_data: "Market Input",
    upload_destination: "CEDAR",
  },
  {
    market: "In market",
    kpi: "DIS_forecast",
    definition: "The number of new car sales that can be attributed to OEM managed digital activity (Forecast)",
    source_data: "Market Input",
    upload_destination: "Box",
  },
  {
    market: "In market",
    kpi: "Retail_sales_actual",
    definition: "The total number of private and digital influenced sales ",
    source_data: "Market Input",
    upload_destination: "Box",
  },
  {
    market: "In market",
    kpi: "Retail_sales_forecast",
    definition: "The total number of private and digital influenced sales (Forecast)",
    source_data: "Market Input",
    upload_destination: "Box",
  },
  {
    market: "In market",
    kpi: "Retail_sales_target",
    definition: "The total number of private and digital influenced sales (Target) ",
    source_data: "Market Input",
    upload_destination: "Box",
  },
  {
    market: "In market",
    kpi: "Tracked leads - lead (JPN only )",
    definition: "The number of customers that signed up to Nissan’s online email distribution",
    source_data: "NJPN",
    upload_destination: "Box",
  },
  {
    market: "In market",
    kpi: "Tracked leads - sales (JPN only)",
    definition: "The number of new car sales that can be attributed to OEM managed digital activity",
    source_data: "NJPN",
    upload_destination: "Box",
  },
  {
    market: "In market",
    kpi: "Customer DataBase (DB) leads - sales (JPN only)",
    definition: "The number of sales from customers that signed up to Nissan’s online email distribution",
    source_data: "NJPN",
    upload_destination: "Box",
  },
  {
    market: "In market",
    kpi: "Private sales - sales (JPN only)",
    definition: "The number of sales excluding fleet and commercial (not tied to online or offline sources)",
    source_data: "NJPN",
    upload_destination: "Box",
  },
];
