import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component } from "react";
import WebFont from "webfontloader";
import { BLUE, PMO_CHART_COLORS, PURPLE } from "../../../constants";
import { disposeChart } from "../helpers/chartHelpers";
import { configureDateAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { legendConfigurationOCE, oceChartConfiguration } from "../helpers/oceConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  chartId: string;
  data: any;
  theme: ThemeInt;
  seriesList: Array<string>;
  isLoading: boolean;
  granularity: string;
}

class PageOverallPerformanceChart extends Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  chart!: XYChart;
  chartId = this.props.chartId;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    //Handle refreshing the chart when the dataset changes
    if (!_.isEqual(prevProps, this.props)) {
      disposeChart(this.chartId).then((res) => {
        this.initChart();
      });
    }
  }

  componentWillUnmount() {
    disposeChart(this.chartId);
  }

  initChart() {
    const { data, granularity, theme, seriesList } = this.props;

    // Global chart configuration
    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    this.chart.numberFormatter.numberFormat = "#a";
    this.chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
    this.chart.data = data;
    // Creates Legend
    this.chart.legend = legendConfigurationOCE(theme);
    this.chart.colors.list = PMO_CHART_COLORS.map((chartColor) => color(chartColor));

    // Create current period date chart axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme);
    dateAxis.renderer.cellStartLocation = 0.5;
    dateAxis.renderer.cellEndLocation = 0.8;

    // 2nd date axis for comparison data
    const dateAxis2 = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis2, theme);
    dateAxis2.renderer.labels.template.disabled = true;
    dateAxis2.renderer.cellStartLocation = 0.2;
    dateAxis2.renderer.cellEndLocation = 0.5;

    // Creates value axis
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.min = 0;

    seriesList?.forEach((metric, idx) => {
      const series = this.chart.series.push(new LineSeries());
      series.data = !metric.includes("Previous") ? data?.Current : data?.YoY;
      const bullet = lineSeriesConfiguration(series, {
        valueY: "overall_performance",
        name: metric,
      });
      series.xAxis = metric.includes("Previous") ? dateAxis2 : dateAxis;
      series.dataFields.dateX = "date";
      series.fill = color(metric.includes("Previous") ? PURPLE : BLUE);
      series.stroke = color(metric.includes("Previous") ? PURPLE : BLUE);
      bullet.tooltipText =
        "[bold; font-size: var(--regular_font_size);]w/c {dateX.formatDate('dd MMM YYYY')} [/ font-size: var(--regular_font_size);]: {valueY.formatNumber('#.')}";
    });
  }

  render() {
    return (
      <>
        <div className={"chartWrapper"}>
          <div id={this.props.chartId} data-test-id={this.props.chartId} className={"graph"} />
        </div>
      </>
    );
  }
}

export default PageOverallPerformanceChart;
