import { ErrorBoundary } from "@sentry/react";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { MtmContent, MtmTitle } from ".";
import {
  fetchInMarketCloseRateSales,
  fetchInMarketLeadVolume,
  fetchInMarketMediaMlc,
  fetchInMarketTotalSales,
  fetchJapanInMarketLeadVolume,
} from "../../../actions";
import { AnnotationDialog } from "../../../components/Annotations/Dialogs/AnnotationDialog";
import { ErrorMsg, NoDataMsg } from "../../../components/AppMessages";
import { MTMActualTargetForecastChart } from "../../../components/Charts/MTMReport";
import { MTMMultipleActualTargetForecastChart } from "../../../components/Charts/MTMReport/MTMMultipleActualTargetForecastChart";
import { MtmInvestmentContext, ThemeContext } from "../../../context";
import { attachAnnotationsMtm } from "../../../utils/annotationFunctions";
import { convertToAbsolutePercentage, isNullArrayKey } from "../../../utils/utilityFunctions";
import { updateForecastData, updatePercentageData } from "../helpers/dataFormatting";

const defaultCloseRateTitle = "Digitally influenced close rate";
const defaultLeadsTitle = "Leads";

export const InMarket = withRouter((props: RouteComponentProps) => {
  const { history } = props;

  const themeContext = useContext(ThemeContext);

  const dispatch = useDispatch();

  const { setInMarket, inMarket, futureMarket, nearMarket } = useContext(MtmInvestmentContext);

  const [mediastat, setmediastat] = useState<number[]>([]);
  const [periodTitle, setPeriodTitle] = useState("");

  const {
    date_range: dateRangeParam,
    model: modelParam,
    market: marketParam,
    granularity: granularityParam,
  } = useSelector((state: RootStateOrAny) => state.mtm_parameters);

  const marketParamValue = useSelector((state: RootStateOrAny) => state.mtm_parameters.market);

  const mediaMlcData = useSelector((state: RootStateOrAny) => state.mtm_data.in_market_media_mlc);
  const leadVolumeData = useSelector((state: RootStateOrAny) => state.mtm_data.in_market_lead_volume);
  const closeRateSalesData = useSelector((state: RootStateOrAny) => state.mtm_data.in_market_close_rate_sales);
  const totalSalesData = useSelector((state: RootStateOrAny) => state.mtm_data.in_market_total_sales);

  const isMediaMlcLoading = useSelector((state: RootStateOrAny) => state.loading.in_market_media_mlc);
  const isLeadVolumeLoading = useSelector((state: RootStateOrAny) => state.loading.in_market_lead_volume);
  const isCloseRateSalesLoading = useSelector((state: RootStateOrAny) => state.loading.in_market_close_rate_sales);
  const isTotalSalesLoading = useSelector((state: RootStateOrAny) => state.loading.in_market_total_sales);

  const chartGranularity = useSelector((state: RootStateOrAny) => state.mtm_parameters.granularity);
  const currency = useSelector((state: RootStateOrAny) => state.mtm_data.currency);

  const annotations = useSelector((state: RootStateOrAny) => state.mtm_data.annotations);

  // Annotations
  const [annotationDialogOpen, setAnnotationDialogOpen] = useState<boolean>(false);
  const [annotationStartDate, setAnnotationStartDate] = useState<Date | null>(null);
  const [annotationEndDate, setAnnotationEndDate] = useState<Date | null>(null);
  const [annotationDefaultMetric, setAnnotationDefaultMetric] = useState<string>("visits");

  const closeRateKeys = [
    "brand_close_rate_actual",
    "brand_close_rate_forecast",
    "brand_close_rate_target",
    "third_party_close_rate_actual",
    "third_party_close_rate_forecast",
    "third_party_close_rate_target",
  ];

  const alternateCloseRateKeys = ["digital_close_rate_actual", "digital_close_rate_forecast", "digital_close_rate_target"];
  const japanCloseRateKeys = ["track_leads_close_rate_actual", "track_leads_close_rate_target", "track_leads_close_rate_forecast"];

  const alternateCloseRateMarkets = ["Philippines", "Thailand"];
  const alternateLeadsMarkets = ["Philippines"];

  const isAlternateCloseRateMarket = useMemo(() => alternateCloseRateMarkets.includes(marketParamValue), [marketParamValue]);
  const isAlternateLeadsMarket = useMemo(() => alternateLeadsMarkets.includes(marketParamValue), [marketParamValue]);

  const [momLegendTooltipText, setMomLegendTooltipText] = useState<Record<string, string>>({});
  const [yoyLegendTooltipText, setYoyLegendTooltipText] = useState<Record<string, string>>({});

  const closeRateTitle = useMemo(
    () => (marketParamValue === "Japan" ? "Tracked leads close rate" : defaultCloseRateTitle),
    [isAlternateCloseRateMarket, marketParamValue]
  );
  const leadsTitle = useMemo(
    () =>
      isAlternateLeadsMarket || marketParamValue === "Thailand"
        ? "Leads"
        : marketParamValue === "Japan"
        ? "Tracked leads"
        : defaultLeadsTitle,
    [isAlternateLeadsMarket, marketParamValue]
  );

  // Forecast formatted data
  const forecastLeadVolumeData = useMemo(() => {
    const data =
      leadVolumeData?.data?.length > 0
        ? marketParamValue === "Japan"
          ? updateForecastData(leadVolumeData.data, [
              {
                actualKey: "tracked_leads_actual",
                forecastKey: "tracked_leads_forecast",
                tempForecastKey: "temp_tracked_leads_forecast",
              },
            ])
          : updateForecastData(leadVolumeData.data, [
              { actualKey: "tier1_leads_actual", forecastKey: "tier1_leads_forecast", tempForecastKey: "temp_brand_lead_forecast" },
              {
                actualKey: "third_party_leads_actual",
                forecastKey: "third_party_leads_forecast",
                tempForecastKey: "temp_third_party_lead_forecast",
              },
            ])
        : [];
    const annotationMetrics =
      marketParamValue === "Japan"
        ? ["Tracked leads"]
        : alternateCloseRateMarkets.includes(marketParamValue)
        ? ["Total leads"]
        : ["Digital leads Tier 1 actual", "Digital leads 3rd party actual target"];
    //@ts-ignore
    return attachAnnotationsMtm(annotations, data, chartGranularity, annotationMetrics);
  }, [leadVolumeData, marketParamValue, annotations]);

  const forecastLeadVolumeDataLegend = useMemo(() => {
    const data_impression_mom = granularityParam == "quarterly" ? "tier1_leads_QoQ" : "tier1_leads_MoM";
    const data_impression_yoy = "tier1_leads_YoY";
    const myValueMom = leadVolumeData[data_impression_mom] && leadVolumeData[data_impression_mom].pop_value;
    const myValueYoy = leadVolumeData[data_impression_yoy] && leadVolumeData[data_impression_yoy].yoy_value;
    const myTextMom = leadVolumeData[data_impression_mom] && leadVolumeData[data_impression_mom].pop_text;
    const myTextYoy = leadVolumeData[data_impression_yoy] && leadVolumeData[data_impression_yoy].yoy_text;
    const signMom = myValueMom && String(myValueMom).includes("-") ? "-" : "+";
    const signYoy = myValueYoy && String(myValueYoy).includes("-") ? "-" : "+";
    const popLabel = granularityParam == "monthly" ? "MoM" : "QoQ";
    const varianceMom = myValueMom == null ? "" : `${signMom}${convertToAbsolutePercentage(myValueMom, 2)}`;
    const varianceYoy = myValueYoy == null ? "" : `${signYoy}${convertToAbsolutePercentage(myValueYoy, 2)}`;
    myTextMom && setMomLegendTooltipText((prevState) => ({ ...prevState, tier1_leads_actual: `${popLabel}: ${myTextMom}` }));
    myTextYoy && setYoyLegendTooltipText((prevState) => ({ ...prevState, tier1_leads_actual: `YoY: ${myTextYoy}` }));
    return `Tier 1 leads 
    ${varianceMom ? `${popLabel}: ${varianceMom}` : ""} ${varianceYoy ? `YoY: ${varianceYoy}` : ""}`;
  }, [leadVolumeData, granularityParam]);

  const forecastThridDataLegend = useMemo(() => {
    const data_impression_mom = granularityParam == "quarterly" ? "third_party_leads_QoQ" : "third_party_leads_MoM";
    const data_impression_yoy = "third_party_leads_YoY";
    const myValueMom = leadVolumeData[data_impression_mom] && leadVolumeData[data_impression_mom].pop_value;
    const myValueYoy = leadVolumeData[data_impression_yoy] && leadVolumeData[data_impression_yoy].yoy_value;
    const myTextMom = leadVolumeData[data_impression_mom] && leadVolumeData[data_impression_mom].pop_text;
    const myTextYoy = leadVolumeData[data_impression_yoy] && leadVolumeData[data_impression_yoy].yoy_text;
    const signMom = myValueMom && String(myValueMom).includes("-") ? "-" : "+";
    const signYoy = myValueYoy && String(myValueYoy).includes("-") ? "-" : "+";
    const popLabel = granularityParam == "monthly" ? "MoM" : "QoQ";
    const varianceMom = myValueMom == null ? "" : `${signMom}${convertToAbsolutePercentage(myValueMom, 2)}`;
    const varianceYoy = myValueYoy == null ? "" : `${signYoy}${convertToAbsolutePercentage(myValueYoy, 2)}`;
    myTextMom && setMomLegendTooltipText((prevState) => ({ ...prevState, third_party_leads_actual: `${popLabel}: ${myTextMom}` }));
    myTextYoy && setYoyLegendTooltipText((prevState) => ({ ...prevState, third_party_leads_actual: `YoY: ${myTextYoy}` }));
    return `3rd party leads 
    ${varianceMom ? `${popLabel}: ${varianceMom}` : ""} ${varianceYoy ? `YoY: ${varianceYoy}` : ""}`;
  }, [leadVolumeData, granularityParam]);

  const forecastExpLegend = useMemo(() => {
    const data_impression_mom = granularityParam == "quarterly" ? "tracked_leads_QoQ" : "tracked_leads_MoM";
    const data_impression_yoy = "tracked_leads_YoY";
    const myValueMom = leadVolumeData[data_impression_mom] && leadVolumeData[data_impression_mom].pop_value;
    const myValueYoy = leadVolumeData[data_impression_yoy] && leadVolumeData[data_impression_yoy].yoy_value;
    const signMom = myValueMom && String(myValueMom).includes("-") ? "-" : "+";
    const signYoy = myValueYoy && String(myValueYoy).includes("-") ? "-" : "+";
    const popLabel = granularityParam == "monthly" ? "MoM" : "QoQ";
    const varianceMom = myValueMom == null ? "" : `${signMom}${convertToAbsolutePercentage(myValueMom, 2)}`;
    const varianceYoy = myValueYoy == null ? "" : `${signYoy}${convertToAbsolutePercentage(myValueYoy, 2)}`;
    return `Visits 
    ${varianceMom ? `${popLabel}: ${varianceMom}` : ""} ${varianceYoy ? `YoY: ${varianceYoy}` : ""}`;
  }, [leadVolumeData, granularityParam]);

  const forecastCloseRate = useMemo(() => {
    const keys = isAlternateCloseRateMarket ? alternateCloseRateKeys : marketParamValue === "Japan" ? japanCloseRateKeys : closeRateKeys;
    const forecastKeys = [
      {
        actualKey: keys[0],
        forecastKey: keys[1],
        tempForecastKey: `temp_${keys[1]}`,
      },
    ];

    if (marketParamValue !== "Japan" && !isAlternateCloseRateMarket)
      forecastKeys.push({
        actualKey: "third_party_close_rate_actual",
        forecastKey: "third_party_close_rate_forecast",
        tempForecastKey: "temp_third_party_close_rate_forecast",
      });

    const data = closeRateSalesData?.length > 0 ? updateForecastData(updatePercentageData(closeRateSalesData, keys), forecastKeys) : [];

    const annotationMetrics =
      marketParamValue === "Japan"
        ? ["Tracked leads close rate"]
        : alternateCloseRateMarkets.includes(marketParamValue)
        ? ["Leads to sales close rate"]
        : ["Digitally influenced close rate Tier 1 actual", "Digitally influenced close rate 3rd party actual"];

    //@ts-ignore
    return attachAnnotationsMtm(annotations, data, chartGranularity, annotationMetrics);
  }, [closeRateSalesData, marketParamValue, isAlternateCloseRateMarket, annotations]);

  const forecastSalesData = useMemo(() => {
    const data =
      totalSalesData?.data?.length > 0
        ? marketParamValue === "Japan"
          ? updateForecastData(totalSalesData.data, [
              {
                actualKey: "total_private_sales_actual",
                forecastKey: "tracked_leads_sales_actual",
                tempForecastKey: "tracked_leads_forecast",
              },
            ])
          : updateForecastData(totalSalesData.data, [
              {
                actualKey: "digitally_influenced_sales_actual",
                forecastKey: "digitally_influenced_sales_target",
                tempForecastKey: "temp_digitally_influenced_sales_forecast",
              },
              {
                actualKey: "total_private_sales_actual",
                forecastKey: "total_private_sales_forecast",
                tempForecastKey: "temp_total_private_sales_forecast",
              },
            ])
        : [];
    const annotationMetrics =
      marketParamValue === "Japan"
        ? ["Sales Tracked leads actual", "Sales Customer DB actual", "Sales Private actual"]
        : ["Sales Digital actual", "Sales Private actual"];
    //@ts-ignore
    return attachAnnotationsMtm(annotations, data, chartGranularity, annotationMetrics);
  }, [totalSalesData, marketParamValue]);

  const forecastSalesDataLegend = useMemo(() => {
    const data_impression_mom = granularityParam == "quarterly" ? "total_private_sales_QoQ" : "total_private_sales_MoM";
    const data_impression_yoy = "total_private_sales_YoY";
    const myValueMom = totalSalesData[data_impression_mom] && totalSalesData[data_impression_mom].pop_value;
    const myValueYoy = totalSalesData[data_impression_yoy] && totalSalesData[data_impression_yoy].yoy_value;
    const myTextMom = totalSalesData[data_impression_mom] && totalSalesData[data_impression_mom].pop_text;
    const myTextYoy = totalSalesData[data_impression_yoy] && totalSalesData[data_impression_yoy].yoy_text;
    const signMom = myValueMom && String(myValueMom).includes("-") ? "-" : "+";
    const signYoy = myValueYoy && String(myValueYoy).includes("-") ? "-" : "+";
    const popLabel = granularityParam == "monthly" ? "MoM" : "QoQ";
    const varianceMom = myValueMom == null ? "" : `${signMom}${convertToAbsolutePercentage(myValueMom, 2)}`;
    const varianceYoy = myValueYoy == null ? "" : `${signYoy}${convertToAbsolutePercentage(myValueYoy, 2)}`;
    myTextMom && setMomLegendTooltipText((prevState) => ({ ...prevState, total_private_sales_actual: `${popLabel}: ${myTextMom}` }));
    myTextYoy && setYoyLegendTooltipText((prevState) => ({ ...prevState, total_private_sales_actual: `YoY: ${myTextYoy}` }));
    return `Retail sales 
    ${varianceMom ? `${popLabel}: ${varianceMom}` : ""} ${varianceYoy ? `YoY: ${varianceYoy}` : ""}`;
  }, [totalSalesData, granularityParam]);

  const forecastDigitalInfluncedLegend = useMemo(() => {
    const data_impression_mom = granularityParam == "quarterly" ? "digitally_influenced_sales_QoQ" : "digitally_influenced_sales_MoM";
    const data_impression_yoy = "digitally_influenced_sales_YoY";
    const myValueMom = totalSalesData[data_impression_mom] && totalSalesData[data_impression_mom].pop_value;
    const myValueYoy = totalSalesData[data_impression_yoy] && totalSalesData[data_impression_yoy].yoy_value;
    const myTextMom = totalSalesData[data_impression_mom] && totalSalesData[data_impression_mom].pop_text;
    const myTextYoy = totalSalesData[data_impression_yoy] && totalSalesData[data_impression_yoy].yoy_text;
    const signMom = myValueMom && String(myValueMom).includes("-") ? "-" : "+";
    const signYoy = myValueYoy && String(myValueYoy).includes("-") ? "-" : "+";
    const popLabel = granularityParam == "monthly" ? "MoM" : "QoQ";
    const varianceMom = myValueMom == null ? "" : `${signMom}${convertToAbsolutePercentage(myValueMom, 2)}`;
    const varianceYoy = myValueYoy == null ? "" : `${signYoy}${convertToAbsolutePercentage(myValueYoy, 2)}`;
    myTextMom && setMomLegendTooltipText((prevState) => ({ ...prevState, digitally_influenced_sales_actual: `${popLabel}: ${myTextMom}` }));
    myTextYoy && setYoyLegendTooltipText((prevState) => ({ ...prevState, digitally_influenced_sales_actual: `YoY: ${myTextYoy}` }));
    return `Digital sales
    ${varianceMom ? `${popLabel}: ${varianceMom}` : ""} ${varianceYoy ? `YoY: ${varianceYoy}` : ""}
    `;
  }, [totalSalesData, granularityParam]);

  const forecastSalesJapanLegend = useMemo(() => {
    const data_impression_mom = granularityParam == "quarterly" ? "total_private_sales_QoQ" : "total_private_sales_MoM";
    const data_impression_yoy = "total_private_sales_YoY";
    const myValueMom = totalSalesData[data_impression_mom] && totalSalesData[data_impression_mom].pop_value;
    const myValueYoy = totalSalesData[data_impression_yoy] && totalSalesData[data_impression_yoy].yoy_value;
    const myTextMom = totalSalesData[data_impression_mom] && totalSalesData[data_impression_mom].pop_text;
    const myTextYoy = totalSalesData[data_impression_yoy] && totalSalesData[data_impression_yoy].yoy_text;
    const signMom = myValueMom && String(myValueMom).includes("-") ? "-" : "+";
    const signYoy = myValueYoy && String(myValueYoy).includes("-") ? "-" : "+";
    const popLabel = granularityParam == "monthly" ? "MoM" : "QoQ";
    const varianceMom = myValueMom == null ? "" : `${signMom}${convertToAbsolutePercentage(myValueMom, 2)}`;
    const varianceYoy = myValueYoy == null ? "" : `${signYoy}${convertToAbsolutePercentage(myValueYoy, 2)}`;
    myTextMom && setMomLegendTooltipText((prevState) => ({ ...prevState, total_private_sales_actual: `${popLabel}: ${myTextMom}` }));
    myTextYoy && setYoyLegendTooltipText((prevState) => ({ ...prevState, total_private_sales_actual: `YoY: ${myTextYoy}` }));
    return `Private sales 
    ${varianceMom ? `${popLabel}: ${varianceMom}` : ""} ${varianceYoy ? `YoY: ${varianceYoy}` : ""}`;
  }, [totalSalesData, granularityParam]);

  const forecastTrackedSalesJapanLegend = useMemo(() => {
    const data_impression_mom = granularityParam == "quarterly" ? "tracked_leads_sales_QoQ" : "tracked_leads_sales_MoM";
    const data_impression_yoy = "tracked_leads_sales_YoY";
    const myValueMom = totalSalesData[data_impression_mom] && totalSalesData[data_impression_mom].pop_value;
    const myValueYoy = totalSalesData[data_impression_yoy] && totalSalesData[data_impression_yoy].yoy_value;
    const myTextMom = totalSalesData[data_impression_mom] && totalSalesData[data_impression_mom].pop_text;
    const myTextYoy = totalSalesData[data_impression_yoy] && totalSalesData[data_impression_yoy].yoy_text;
    const signMom = myValueMom && String(myValueMom).includes("-") ? "-" : "+";
    const signYoy = myValueYoy && String(myValueYoy).includes("-") ? "-" : "+";
    const popLabel = granularityParam == "monthly" ? "MoM" : "QoQ";
    const varianceMom = myValueMom == null ? "" : `${signMom}${convertToAbsolutePercentage(myValueMom, 2)}`;
    const varianceYoy = myValueYoy == null ? "" : `${signYoy}${convertToAbsolutePercentage(myValueYoy, 2)}`;
    myTextMom && setMomLegendTooltipText((prevState) => ({ ...prevState, tracked_leads_sales_actual: `${popLabel}: ${myTextMom}` }));
    myTextYoy && setYoyLegendTooltipText((prevState) => ({ ...prevState, tracked_leads_sales_actual: `YoY: ${myTextYoy}` }));
    return `Tracked leads sales 
    ${varianceMom ? `${popLabel}: ${varianceMom}` : ""} ${varianceYoy ? `YoY: ${varianceYoy}` : ""}`;
  }, [totalSalesData, granularityParam]);

  const forecastCustomerDBSalesJapanLegend = useMemo(() => {
    const data_impression_mom = granularityParam == "quarterly" ? "customer_db_QoQ" : "customer_db_MoM";
    const data_impression_yoy = "customer_db_YoY";
    const myValueMom = totalSalesData[data_impression_mom] && totalSalesData[data_impression_mom].pop_value;
    const myValueYoy = totalSalesData[data_impression_yoy] && totalSalesData[data_impression_yoy].yoy_value;
    const myTextMom = totalSalesData[data_impression_mom] && totalSalesData[data_impression_mom].pop_text;
    const myTextYoy = totalSalesData[data_impression_yoy] && totalSalesData[data_impression_yoy].yoy_text;
    const signMom = myValueMom && String(myValueMom).includes("-") ? "-" : "+";
    const signYoy = myValueYoy && String(myValueYoy).includes("-") ? "-" : "+";
    const popLabel = granularityParam == "monthly" ? "MoM" : "QoQ";
    const varianceMom = myValueMom == null ? "" : `${signMom}${convertToAbsolutePercentage(myValueMom, 2)}`;
    const varianceYoy = myValueYoy == null ? "" : `${signYoy}${convertToAbsolutePercentage(myValueYoy, 2)}`;
    myTextMom && setMomLegendTooltipText((prevState) => ({ ...prevState, customer_db_actual: `${popLabel}: ${myTextMom}` }));
    myTextYoy && setYoyLegendTooltipText((prevState) => ({ ...prevState, customer_db_actual: `YoY: ${myTextYoy}` }));
    return `Customer DB sales 
    ${varianceMom ? `${popLabel}: ${varianceMom}` : ""} ${varianceYoy ? `YoY: ${varianceYoy}` : ""}`;
  }, [totalSalesData, granularityParam]);

  const forecastTrackedLeadsJapanLegend = useMemo(() => {
    const data_impression_mom = granularityParam == "quarterly" ? "tracked_leads_QoQ" : "tracked_leads_MoM";
    const data_impression_yoy = "tracked_leads_YoY";
    const myValueMom = leadVolumeData[data_impression_mom] && leadVolumeData[data_impression_mom].pop_value;
    const myValueYoy = leadVolumeData[data_impression_yoy] && leadVolumeData[data_impression_yoy].yoy_value;
    const myTextMom = leadVolumeData[data_impression_mom] && leadVolumeData[data_impression_mom].pop_text;
    const myTextYoy = leadVolumeData[data_impression_yoy] && leadVolumeData[data_impression_yoy].yoy_text;
    const signMom = myValueMom && String(myValueMom).includes("-") ? "-" : "+";
    const signYoy = myValueYoy && String(myValueYoy).includes("-") ? "-" : "+";
    const popLabel = granularityParam == "monthly" ? "MoM" : "QoQ";
    const varianceMom = myValueMom == null ? "" : `${signMom}${convertToAbsolutePercentage(myValueMom, 2)}`;
    const varianceYoy = myValueYoy == null ? "" : `${signYoy}${convertToAbsolutePercentage(myValueYoy, 2)}`;
    setTimeout(() => {
      myTextMom && setMomLegendTooltipText((prevState) => ({ ...prevState, tracked_leads_actual: `${popLabel}: ${myTextMom}` }));
      myTextYoy && setYoyLegendTooltipText((prevState) => ({ ...prevState, tracked_leads_actual: `YoY: ${myTextYoy}` }));
    }, 2000);
    return `Actual 
    ${varianceMom ? `${popLabel}: ${varianceMom}` : ""} ${varianceYoy ? `YoY: ${varianceYoy}` : ""}`;
  }, [leadVolumeData, granularityParam]);

  useEffect(() => {
    Promise.all(
      marketParamValue === "Japan"
        ? [
            dispatch(fetchInMarketMediaMlc()),
            dispatch(fetchInMarketCloseRateSales()),
            dispatch(fetchJapanInMarketLeadVolume()),
            dispatch(fetchInMarketTotalSales()),
          ]
        : [
            dispatch(fetchInMarketMediaMlc()),
            dispatch(fetchInMarketCloseRateSales()),
            dispatch(fetchInMarketLeadVolume()),
            dispatch(fetchInMarketTotalSales()),
          ]
    );
  }, [history.location.search, dispatch]);

  // Set investments total and share
  useEffect(() => {
    if (mediaMlcData && Array.isArray(mediaMlcData.data)) {
      const period = chartGranularity === "monthly" ? "month" : "quarter";

      const periodData = mediaMlcData.data.find((row: { date: string; fmi_spend_in_market: number; fmi_in_share: number }) =>
        period === "month"
          ? moment().subtract(1, "months").startOf(period).format("YYYY-MM-DD") === row.date
          : moment().startOf(period).format("YYYY-MM-DD") === row.date
      );

      if (periodData) {
        const { fmi_spend_in_market, fmi_in_share } = periodData;
        setInMarket({ value: fmi_spend_in_market, share: fmi_in_share });

        setPeriodTitle(chartGranularity === "monthly" ? `${moment().subtract(1, "months").format("MMM")}` : `${periodData.quarter}`);
      } else {
        setInMarket({ value: 0, share: 0 });
      }
    }
  }, [mediaMlcData, chartGranularity]);

  const toggleAnnotationsDialog = useCallback(() => {
    setAnnotationDialogOpen(!annotationDialogOpen);
  }, []);

  const mediaMlcDataWithAnnotations = useMemo(() => {
    if (annotations && annotations.length > 0 && Array.isArray(mediaMlcData.data)) {
      return attachAnnotationsMtm(annotations, mediaMlcData.data, chartGranularity, [
        "In market online media investment",
        "In market offline media investment",
      ]);
    }
    return mediaMlcData;
  }, [mediaMlcData, annotations]);

  useEffect(() => {
    let onlineTotal = 0;
    let offlineTotal = 0;

    mediaMlcData &&
      Array.isArray(mediaMlcData.data) &&
      mediaMlcData.data.forEach((element: any) => {
        onlineTotal += element.in_market_online_fmi;
      });

    mediaMlcData &&
      Array.isArray(mediaMlcData.data) &&
      mediaMlcData.data.forEach((element: any) => {
        offlineTotal += element.in_market_offline_fmi;
      });

    setmediastat(
      onlineTotal && offlineTotal ? [onlineTotal / (onlineTotal + offlineTotal), offlineTotal / (onlineTotal + offlineTotal)] : []
    );
  }, [modelParam, marketParam, dateRangeParam, mediaMlcData]);

  return (
    <div className="mtm_row">
      <ErrorBoundary fallback={<ErrorMsg />}>
        <MtmTitle
          title={"In market"}
          value={inMarket.value}
          currency={mediaMlcData.currency}
          view={granularityParam}
          onofTotal={mediastat}
          titleHelper={periodTitle}
          total={inMarket.share}
        />
      </ErrorBoundary>

      <div className="mtm_container">
        <ErrorBoundary fallback={<ErrorMsg />}>
          <MtmContent
            title={leadsTitle}
            chart={
              marketParamValue === "Japan" ? (
                <MTMActualTargetForecastChart
                  data={forecastLeadVolumeData ? forecastLeadVolumeData : []}
                  chartName="leadVolumeChart"
                  isLoading={isLeadVolumeLoading}
                  actualField={"tracked_leads_actual"}
                  actualName={forecastTrackedLeadsJapanLegend}
                  targetField={"tracked_leads_target"}
                  forecastField={"tracked_leads_forecast"}
                  tooltipForecastField={"tracked_leads_forecast"}
                  isQuarterly={chartGranularity === "quarterly"}
                  theme={themeContext.theme}
                  toggleAnnotationsDialog={toggleAnnotationsDialog}
                  setAnnotationEndDate={setAnnotationEndDate}
                  setAnnotationStartDate={setAnnotationStartDate}
                  setAnnotationDefaultMetric={setAnnotationDefaultMetric}
                  annotationMetric={leadsTitle}
                  momLegendTooltipText={momLegendTooltipText}
                  yoyLegendTooltipText={yoyLegendTooltipText}
                />
              ) : !isAlternateLeadsMarket ? (
                <MTMMultipleActualTargetForecastChart
                  data={forecastLeadVolumeData ? forecastLeadVolumeData : []}
                  chartName="leadVolumeChart"
                  isLoading={isLeadVolumeLoading}
                  firstActualField="tier1_leads_actual"
                  firstForecastField="tier1_leads_forecast"
                  firstTooltipForecastField="tier1_leads_forecast"
                  firstForecastFieldName="Tier 1 leads forecast"
                  secondActualField="third_party_leads_actual"
                  secondForecastField="third_party_leads_forecast"
                  secondForecastFieldName="3rd party leads forecast"
                  secondTooltipForecastField="third_party_leads_forecast"
                  blueLegendName={forecastLeadVolumeDataLegend}
                  pinkLegendName={forecastThridDataLegend}
                  isQuarterly={chartGranularity === "quarterly"}
                  theme={themeContext.theme}
                  toggleAnnotationsDialog={toggleAnnotationsDialog}
                  setAnnotationEndDate={setAnnotationEndDate}
                  setAnnotationStartDate={setAnnotationStartDate}
                  setAnnotationDefaultMetric={setAnnotationDefaultMetric}
                  annotationMetric={leadsTitle}
                  momLegendTooltipText={momLegendTooltipText}
                  yoyLegendTooltipText={yoyLegendTooltipText}
                />
              ) : (
                <MTMActualTargetForecastChart
                  data={forecastLeadVolumeData ? forecastLeadVolumeData : []}
                  chartName="leadVolumeChart"
                  isLoading={isLeadVolumeLoading}
                  actualField={"tier1_leads_actual"}
                  actualName={forecastLeadVolumeDataLegend}
                  targetField={"brand_lead_target"}
                  forecastField={"temp_brand_lead_forecast"}
                  tooltipForecastField={"tier1_leads_forecast"}
                  isQuarterly={chartGranularity === "quarterly"}
                  toggleAnnotationsDialog={toggleAnnotationsDialog}
                  setAnnotationEndDate={setAnnotationEndDate}
                  setAnnotationStartDate={setAnnotationStartDate}
                  setAnnotationDefaultMetric={setAnnotationDefaultMetric}
                  theme={themeContext.theme}
                  annotationMetric={leadsTitle}
                  momLegendTooltipText={momLegendTooltipText}
                  yoyLegendTooltipText={yoyLegendTooltipText}
                />
              )
            }
          />
        </ErrorBoundary>

        <ErrorBoundary fallback={<ErrorMsg />}>
          <MtmContent
            title={"Digitally influenced sales"}
            chart={
              marketParamValue === "Japan" ? (
                isNullArrayKey("total_private_sales_actual", forecastSalesData) &&
                isNullArrayKey("total_private_sales_actual", forecastSalesData) &&
                isNullArrayKey("customer_db_actual", forecastSalesData) ? (
                  <NoDataMsg />
                ) : (
                  <MTMMultipleActualTargetForecastChart
                    data={forecastSalesData ? forecastSalesData : []}
                    chartName="influencedSalesChart"
                    isLoading={isTotalSalesLoading}
                    firstActualField="total_private_sales_actual"
                    secondActualField="tracked_leads_sales_actual"
                    thirdActualField="customer_db_actual"
                    pinkLegendName={forecastTrackedSalesJapanLegend}
                    blueLegendName={forecastSalesJapanLegend}
                    lightCyanLegendName={forecastCustomerDBSalesJapanLegend}
                    isQuarterly={chartGranularity === "quarterly"}
                    theme={themeContext.theme}
                    isJapan={true}
                    isRateChart={false}
                    toggleAnnotationsDialog={toggleAnnotationsDialog}
                    setAnnotationEndDate={setAnnotationEndDate}
                    setAnnotationStartDate={setAnnotationStartDate}
                    setAnnotationDefaultMetric={setAnnotationDefaultMetric}
                    annotationMetric="Sales"
                    momLegendTooltipText={momLegendTooltipText}
                    yoyLegendTooltipText={yoyLegendTooltipText}
                  />
                )
              ) : isNullArrayKey("total_private_sales_actual", forecastSalesData) &&
                isNullArrayKey("digitally_influenced_sales_actual", forecastSalesData) &&
                isNullArrayKey("total_private_sales_target", forecastSalesData) ? (
                <NoDataMsg />
              ) : (
                <MTMMultipleActualTargetForecastChart
                  data={forecastSalesData ? forecastSalesData : []}
                  chartName="influencedSalesChart"
                  isLoading={isTotalSalesLoading}
                  firstActualField="total_private_sales_actual"
                  blueLegendName={forecastSalesDataLegend}
                  secondActualField="digitally_influenced_sales_actual"
                  pinkLegendName={forecastDigitalInfluncedLegend}
                  firstForecastField="total_private_sales_forecast"
                  firstTooltipForecastField="total_private_sales_forecast"
                  firstForecastFieldName={"Retail sales forecast"}
                  secondForecastField="digitally_influenced_sales_forecast"
                  secondTooltipForecastField="digitally_influenced_sales_forecast"
                  secondForecastFieldName="Digitally influenced sales forecast"
                  secondTargetField={"total_private_sales_target"}
                  isQuarterly={chartGranularity === "quarterly"}
                  theme={themeContext.theme}
                  isJapan={false}
                  toggleAnnotationsDialog={toggleAnnotationsDialog}
                  setAnnotationEndDate={setAnnotationEndDate}
                  setAnnotationStartDate={setAnnotationStartDate}
                  setAnnotationDefaultMetric={setAnnotationDefaultMetric}
                  annotationMetric="Sales"
                  momLegendTooltipText={momLegendTooltipText}
                  yoyLegendTooltipText={yoyLegendTooltipText}
                />
              )
            }
          />
        </ErrorBoundary>

        {/*/!*Annotations modal *!/*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          {annotationDialogOpen && (
            <AnnotationDialog
              open={annotationDialogOpen}
              setOpen={setAnnotationDialogOpen}
              startDate={annotationStartDate}
              endDate={annotationEndDate}
              defaultMetric={annotationDefaultMetric}
              dashboard="mtm_metrics_that_matter"
            />
          )}
        </ErrorBoundary>
      </div>
    </div>
  );
});
