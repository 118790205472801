import { DateAxis, LineSeries, ValueAxis, XYChart } from "@amcharts/amcharts4/charts";
import { color, create } from "@amcharts/amcharts4/core";
import _ from "lodash";
import React, { Component, ReactNode } from "react";
import WebFont from "webfontloader";
import LoadingEllipsis from "../../Loading/LoadingEllipsis";
import { configureDateAxis, configureValueAxis, lineSeriesConfiguration } from "../helpers/configurations";
import { formatValueAxisUnits } from "../helpers/helpers";
import { oceChartConfiguration } from "../helpers/oceConfigurations";
import { dateAxisLabelFormatter_QuarterlyFY } from "../helpers/pmoConfigurations";
import { ThemeInt } from "../helpers/types";

interface Props {
  theme: ThemeInt;
  isLoading: boolean;
  seriesList: any;
  id: string;
  granularity: string;
}

class SalesByColorChart extends Component<Props> {
  chartId = this.props.id;
  chart!: XYChart;

  componentDidMount() {
    WebFont.load({
      custom: {
        families: ["nissan"],
        urls: ["../../../assets/fonts/fonts.css"],
      },
      // @ts-ignore
      active: this.initChart(),
      timeout: 8000,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (!_.isEqual(prevProps, this.props)) {
      this.chart.dispose();
      this.initChart();
    }
  }

  componentWillUnmount() {
    this.chart.dispose();
  }

  initChart() {
    const { theme, seriesList, granularity } = this.props;

    this.chart = create(this.chartId, XYChart);
    oceChartConfiguration(this.chart);
    // this.chart.data = data;
    this.chart.marginRight = 0;
    this.chart.paddingRight = 0;

    // Create data chart axes
    const dateAxis = this.chart.xAxes.push(new DateAxis());
    configureDateAxis(dateAxis, theme, true);
    dateAxis.renderer.cellStartLocation = 0.05;
    dateAxis.renderer.cellEndLocation = 0.55;

    dateAxis.renderer.grid.template.strokeWidth = 0;
    dateAxis.renderer.minGridDistance = granularity === "quarterly" ? 60 : 30;
    granularity === "quarterly" && dateAxis.renderer.labels.template.adapter.add("text", dateAxisLabelFormatter_QuarterlyFY);

    // Creates value axis
    const valueAxis = this.chart.yAxes.push(new ValueAxis());
    configureValueAxis(valueAxis, theme);
    valueAxis.min = 0;
    valueAxis.numberFormatter.numberFormat = "#a";
    valueAxis.renderer.labels.template.adapter.add("text", function (text) {
      return text;
    });
    formatValueAxisUnits(valueAxis);

    const createSeries = (metric: string, data: any[], hide: boolean, colors: string) => {
      const series = this.chart.series.push(new LineSeries());
      series.data = data;

      series.data.sort(function (a, b) {
        //@ts-ignore
        return new Date(a.date) - new Date(b.date);
      });

      const bullet = lineSeriesConfiguration(series, {
        valueY: `amount`,
        name: metric,
      });
      //@ts-ignore
      series.volume = "amount";
      series.xAxis = dateAxis;
      series.dataFields.dateX = "date";

      if (granularity === "quarterly") series.dataFields.categoryX = "quarter";
      series.tensionY = 1;
      series.yAxis = valueAxis;
      series.dataFields.categoryY = "amount";
      series.fill = color(colors);
      series.stroke = color(colors);
      series.hidden = hide;

      if (this.props.id === "SalesByColorChart") series.strokeDasharray = "5,5";

      const tooltiptitle = this.props.id === "SalesByColorChart" ? "Sales" : "Configuration completion";

      bullet.adapter.add("tooltipText", (text, target) => {
        text = `[font-size: var(--regular_font_size);bold]{name}[/]\n[font-size: var(--regular_font_size)]{dateX.formatDate('MMM')} [font-size: var(--regular_font_size)]${tooltiptitle} : {valueY.formatNumber('#,###.')}`;
        return text;
      });
    };
    seriesList?.forEach((val: { name: string; data: any[]; hide: boolean; color: string }) => {
      createSeries(val?.name, val?.data, val?.hide, val?.color);
    });
  }

  render(): ReactNode {
    return (
      <div className={"chartWrapper"}>
        <div id={this.props.id} className={"graph"} />
        <LoadingEllipsis isLoading={this.props.isLoading} />
      </div>
    );
  }
}

export default SalesByColorChart;
