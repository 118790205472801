import { ErrorBoundary } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { setParameters, setSpecificParameter } from "../../../actions";
import { getExecutiveSummaryDisFilters } from "../../../api";
import "../../../assets/styles/component/filters.scss";
import { DEFAULT_EXECUTIVE_SUMMARY_DIS, DEFAULT_PURCHASE_FUNNEL_PARAMETERS, DIS_PRESET_DATE_PARAMS } from "../../../constants";
import { FilterContext } from "../../../context";
import { isMonthlyCustomDate } from "../../../utils/dateFunctions";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { ErrorMsg } from "../../AppMessages";
import { FiltersLayout } from "../../Layouts";
import { DefaultFilter, MultiSelectFilter } from "../common";
import { DigitalLeadsSourcesFilter } from "../common/DigitalLeadsSourcesFilter";
import PurchaseFunnelDateFilter from "../common/PurchaseFunnelDateFilter";
import { closeFilterOptions, closeMarketOptions } from "../subs/helpers";
import { currentDefaultFiltersSet, defaultFiltersSet } from "../../../utils/utilityFunctions";

interface Props {
  filterBtns: Array<{ id: string; navigateTo: string; name: string }>;
  inComplete?: boolean;
}

const ExecutiveSummaryDisFilters = withRouter((props: RouteComponentProps & Props) => {
  const { history, filterBtns } = props;
  const dispatch = useDispatch();
  const { showFilterOptions, setShowFilterOptions } = useContext(FilterContext);

  const {
    date_range: dateRangeParamValue,
    market: marketParamValue,
    brand: brandParamValue,
    lead_group: leadGroupParamValue,
    leads_source: leadsSourceParamValue,
  } = useSelector((state: RootStateOrAny) => state.parameters);
  const [marketFilterValue, setMarketFilterValue] = useState(marketParamValue);
  const [leadsSourceFilterValue, setLeadsSourceFilterValue] = useState(leadsSourceParamValue);

  useEffect(() => {
    const setDefaultFilters = !defaultFiltersSet("Executive Summary - Digitally Influenced Sales");
    if (setDefaultFilters) {
      dispatch(setParameters(DEFAULT_EXECUTIVE_SUMMARY_DIS));
      currentDefaultFiltersSet("Executive Summary - Digitally Influenced Sales");
    }
  }, []);

  const { data, refetch } = useQuery({
    queryKey: ["filtersData", history.location.search],
    queryFn: getExecutiveSummaryDisFilters,
    initialData: {},
    enabled: false,
  });

  const { brand, market: markets, leads_source: leadsSources } = data;

  useEffect(() => {
    refetch();
  }, [history.location.search]);

  //reset filter button functionality
  const resetFilters = useCallback(() => {
    dispatch(setParameters(DEFAULT_EXECUTIVE_SUMMARY_DIS));
  }, []);

  //Reset date range param value
  useEffect(() => {
    if (dateRangeParamValue) {
      const isValidDate = DIS_PRESET_DATE_PARAMS.includes(dateRangeParamValue) || isMonthlyCustomDate(dateRangeParamValue);
      if (!isValidDate) dispatch(setSpecificParameter("date_range", "This financial year"));
    } else {
      dispatch(setSpecificParameter("date_range", "This financial year"));
    }
  }, [dateRangeParamValue]);

  //Validate market param
  useEffect(() => {
    if (markets && markets.length > 0 && marketParamValue != "All") {
      const selectedMarkets = marketParamValue.split(",");
      if (selectedMarkets.every((market: string) => markets.includes(market))) return;
      const validMarkets: Array<string> = [];
      selectedMarkets.forEach((market: string) => markets.includes(market) && validMarkets.push("market"));
      validMarkets.length > 0
        ? dispatch(setSpecificParameter("market", validMarkets.join(",")))
        : dispatch(setSpecificParameter("market", "All"));
    }
  }, [marketParamValue, markets]);

  //Validate leads source param
  useEffect(() => {
    if (leadsSourceParamValue && leadsSources && leadsSources.length > 0) {
      const leadSourceArr = leadsSourceParamValue.split(",");
      const leadSourcesOptions = leadsSources.flatMap((item: any) => item.leads_source);
      const leadSourceAreValid =
        leadSourceArr.every((lead: string) => leadSourcesOptions.includes(lead)) ||
        (marketParamValue != "Japan" && leadsSourceParamValue === "All");
      if (!leadSourceAreValid)
        marketParamValue == "Japan"
          ? dispatch(setSpecificParameter("leads_source", leadSourcesOptions[0]))
          : dispatch(setSpecificParameter("leads_source", "All"));
    } else if (!leadsSourceParamValue) {
      dispatch(setSpecificParameter("leads_source", "All"));
    }
  }, [leadsSourceParamValue, leadsSources, marketParamValue]);

  //Validate leads group param
  useEffect(() => {
    if (leadGroupParamValue && leadsSources && leadsSources.length > 0) {
      const leadGroupArr = leadGroupParamValue.split(",");
      const leadGroupOptions = leadsSources.flatMap((item: any) => item.lead_group);
      const leadGroupAreValid =
        leadGroupArr.every((lead: string) => leadGroupOptions.includes(lead)) ||
        (marketParamValue != "Japan" && leadGroupParamValue === "All");
      if (!leadGroupAreValid)
        marketParamValue == "Japan"
          ? dispatch(setSpecificParameter("lead_group", leadGroupOptions[0]))
          : dispatch(setSpecificParameter("lead_group", "All"));
    } else if (!leadGroupParamValue) {
      dispatch(setSpecificParameter("lead_group", "All"));
    }
  }, [leadGroupParamValue, leadsSources, marketParamValue]);

  // Set market filter value
  useEffect(() => {
    if (markets && marketParamValue) {
      const selectedMarketsArr = marketParamValue.split(",");
      const marketSourceValue =
        selectedMarketsArr?.length === markets.length
          ? "All"
          : selectedMarketsArr?.length > 1
            ? `${selectedMarketsArr?.length} market selected`
            : marketParamValue;

      setMarketFilterValue(marketSourceValue);
    }
  }, [markets, marketParamValue]);

  // Set leads source filter value
  useEffect(() => {
    const leadSourcesOptions = leadsSources?.flatMap((item: any) => item.leads_source);

    if (leadSourcesOptions && leadsSourceParamValue) {
      const selectedLeadsSourcesArr = leadsSourceParamValue.split(",");
      const leadsSourceSourceValue =
        selectedLeadsSourcesArr?.length === leadSourcesOptions.length
          ? "All"
          : selectedLeadsSourcesArr?.length > 1
            ? `${selectedLeadsSourcesArr?.length} leads sources selected`
            : leadsSourceParamValue;

      setLeadsSourceFilterValue(leadsSourceSourceValue);
    }
  }, [leadsSources, leadsSourceParamValue, marketParamValue, brandParamValue]);

  const showFilterOptionsFn = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const optionsId = dataset?.optionsUl as string;
    const shownClass = "showOptions";

    const optionsElement = document.getElementById(optionsId) as HTMLElement;

    if (showFilterOptions && optionsElement.classList.contains(shownClass)) {
      closeFilterOptions();
      closeMarketOptions();
      setShowFilterOptions(false);
    } else {
      closeFilterOptions();
      optionsElement?.classList.add(shownClass);
      setShowFilterOptions(true);
    }
  };

  const handleFilterOptionClick = (evt: React.MouseEvent<HTMLElement>) => {
    const {
      currentTarget: { dataset },
    } = evt;
    const filterName = dataset?.filter;
    const optionValue = dataset?.value;

    if (filterName && optionValue) {
      dispatch(setSpecificParameter(filterName, optionValue));

      eventTracking(MixpanelEvents.filter_change, {
        filter: filterName,
        value: optionValue,
        dashboard: "Executive Summary",
        page: "Digitally Influenced Sales",
      });
    }
    closeFilterOptions();
    closeMarketOptions();
    setShowFilterOptions(false);
  };

  const handleMarketFilterSubmission = useCallback(() => {
    const parameterName = "market";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = selectedValues?.length === markets?.length ? "All" : selectedValues?.join(",");
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, {
      filter: parameterName,
      value: filterValue,
      dashboard: "Executive Summary",
      page: "Digitally Influenced Sales",
    });
    closeFilterOptions();
  }, [markets]);

  const handleBrandFilterSubmission = useCallback(() => {
    const parameterName = "brand";
    const selectedCheckboxes: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      `[type="checkbox"][data-filter-name="${parameterName}"][data-options-type="single"]:checked`
    );
    const selectedValues: Array<string> = [];
    selectedCheckboxes?.forEach((checkbox) => selectedValues.push(checkbox.value));
    const filterValue = selectedValues?.length === brand?.length ? "All" : selectedValues?.join(",");
    dispatch(setSpecificParameter(parameterName, filterValue));
    eventTracking(MixpanelEvents.filter_change, {
      filter: parameterName,
      value: filterValue,
      dashboard: "Executive Summary",
      page: "Digitally Influenced Sales",
    });
    closeFilterOptions();
  }, [brand]);

  const digitalLeadsSourceOptions = useMemo(() => {
    if (leadsSources) {
      const options: any = [{ value: "All", checked: false }];
      leadsSources.forEach((leadsSource: any) => {
        options.push({
          value: leadsSource.lead_group,
          checked: false,
          indeterminate: false,
          options: leadsSource.leads_source.map((item: string) => ({
            value: item,
            checked: false,
          })),
        });
      });
      return options;
    }
    return [];
  }, [leadsSources]);

  // Set params
  useEffect(() => {
    const params = new URLSearchParams();

    params.set("date_range", dateRangeParamValue);
    params.set("brand", brandParamValue);
    params.set("market", marketParamValue);
    params.set("lead_group", leadGroupParamValue);
    params.set("leads_source", leadsSourceParamValue);
    history.push({ search: `?${params.toString()}` });
  }, [dateRangeParamValue, brandParamValue, marketParamValue, leadGroupParamValue, leadsSourceParamValue]);

  return (
    <FiltersLayout extraClass={"iframeFilters-midas"} resetFilters={resetFilters} filterBtns={filterBtns}>
      <div className="filte">
        {/*Date range*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <PurchaseFunnelDateFilter
            value={dateRangeParamValue}
            handleFilterOptionClick={handleFilterOptionClick}
            onFilterClick={showFilterOptionsFn}
          />
        </ErrorBoundary>

        {/*Brand*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DefaultFilter
            filterName={"brand"}
            list={brand ?? []}
            filterValue={brandParamValue}
            filterLabel={"BRAND"}
            handleFilterOptionClick={handleFilterOptionClick}
          />
        </ErrorBoundary>

        {/*Geography*/}
        <ErrorBoundary fallback={<ErrorMsg />}>
          {markets?.length > 1 ? (
            <MultiSelectFilter
              parentKey="market"
              filterName={"GEOGRAPHY"}
              // @ts-ignore
              filterList={markets ?? []}
              value={marketFilterValue}
              parameterName={"market"}
              parameterValue={marketParamValue}
              onShowFilterOptions={showFilterOptionsFn}
              handleFilterSubmission={handleMarketFilterSubmission}
            />
          ) : (
            <DefaultFilter
              filterName={"market"}
              list={markets}
              filterValue={marketParamValue}
              filterLabel={"GEOGRAPHY"}
              handleFilterOptionClick={handleFilterOptionClick}
            />
          )}
        </ErrorBoundary>

        {/* Lead Source */}
        <ErrorBoundary fallback={<ErrorMsg />}>
          <DigitalLeadsSourcesFilter
            parentKey="leads_source"
            filterList={digitalLeadsSourceOptions}
            filterName="DIGITAL LEADS SOURCE"
            value={leadsSourceFilterValue}
            parameterName={"leads_source"}
            parameterValue={leadsSourceParamValue}
            onShowFilterOptions={showFilterOptionsFn}
            dashboard="Executive Summary"
            page="Digitally Influenced Sales"
          />
        </ErrorBoundary>
      </div>
    </FiltersLayout>
  );
});

export default ExecutiveSummaryDisFilters;
