import moment from "moment";
import { USE_OWNERSHIP_DATE_FILTER_OPTIONS } from "../constants";
import { newApiFetchV2, newFetchDataV2 } from "./utils";
export const apiParamsToObjectUseOwnership = () => {
  const parameters = new URLSearchParams(window.location.search);

  const dateRangeParam = parameters?.get("date_range");
  const brandParameter = parameters?.get("brand");
  const marketParameter = parameters.get("market") == "All" ? [] : parameters.get("market")?.split(",");

  const paramObj: any = {
    brand: brandParameter,
    market: marketParameter,
  };

  if (dateRangeParam) {
    if (USE_OWNERSHIP_DATE_FILTER_OPTIONS.includes(dateRangeParam)) {
      paramObj["date_range"] = dateRangeParam.split(" ").join("_").toLocaleLowerCase();
    } else {
      paramObj["date_range"] = "custom";
      paramObj["start_date"] = moment(dateRangeParam.split("-")[0], "MMMM YYYY").format("YYYY-MM-DD");
      paramObj["end_date"] = moment(dateRangeParam.split("-")[1], "MMMM YYYY").endOf("month").format("YYYY-MM-DD");
    }
  }
  return paramObj;
};

export const getUseOwnershipDataDictionary = (page: string) => newFetchDataV2(`/use-ownership/data-dictionary?page=${page}`);

export const getOverviewAvgVocRatingAcrossPages = () =>
  newApiFetchV2("/use-ownership/overview/average-voc-rating-across-pages", apiParamsToObjectUseOwnership());

export const getOverviewOnlineAccountAndEngagement = () =>
  newApiFetchV2("/use-ownership/overview/online-account-and-engagement", apiParamsToObjectUseOwnership());

export const getOverviewServiceLeads = () => newApiFetchV2("/use-ownership/overview/service-leads", apiParamsToObjectUseOwnership());

export const getOverviewEnrolledCcsCustomers = () =>
  newApiFetchV2("/use-ownership/overview/enrolled-ccs-customers", apiParamsToObjectUseOwnership());

export const getOverviewAvgMonthlyActiveUsers = () =>
  newApiFetchV2("/use-ownership/overview/avg-monthly-active-users", apiParamsToObjectUseOwnership());

export const getOverviewConnectedCarsSold = () =>
  newApiFetchV2("/use-ownership/overview/connected-cars-sold", apiParamsToObjectUseOwnership());

export const getOverviewUserEngagement = () => newApiFetchV2("/use-ownership/overview/user-engagement", apiParamsToObjectUseOwnership());

export const getOverviewRenewalRate = () => newApiFetchV2("/use-ownership/overview/renewal-rate", apiParamsToObjectUseOwnership());

export const getOverviewChurnRate = () => newApiFetchV2("/use-ownership/overview/churn-rate", apiParamsToObjectUseOwnership());

export const getOverviewEngagementTrend = () =>
  newApiFetchV2("/use-ownership/overview/ccs-engagement-trend", apiParamsToObjectUseOwnership());

export const getOverviewRenewalTrend = () => newApiFetchV2("/use-ownership/overview/ccs-renewal-trend", apiParamsToObjectUseOwnership());

export const getOnlineAccAndEngPrivateSales = () =>
  newApiFetchV2("/use-ownership/online-account-and-engagement/private-sales", apiParamsToObjectUseOwnership());

export const getOnlineAccAndEngEnrolledUsersTrend = () =>
  newApiFetchV2("/use-ownership/online-account-and-engagement/enrolled-users-trend", apiParamsToObjectUseOwnership());

export const getOnlineAccAndEngAccountCreationTrend = () =>
  newApiFetchV2("/use-ownership/online-account-and-engagement/account-creation-trend", apiParamsToObjectUseOwnership());

export const getOnlineAccAndEngLinkedCars = () =>
  newApiFetchV2("/use-ownership/online-account-and-engagement/proportion-of-users-that-have-linked-a-car", apiParamsToObjectUseOwnership());

export const getOnlineAccAndEngEngagementVsActiveUsers = () =>
  newApiFetchV2("/use-ownership/online-account-and-engagement/first-engagement-users-vs-active-users", apiParamsToObjectUseOwnership());

export const getOnlineAccAndEngStartAccountCreation = () =>
  newApiFetchV2("/use-ownership/online-account-and-engagement/users-start-account-creation", apiParamsToObjectUseOwnership());

export const getOnlineAccAndEngCompleteAccountCreation = () =>
  newApiFetchV2("/use-ownership/online-account-and-engagement/users-complete-account-creation", apiParamsToObjectUseOwnership());

export const getOnlineAccAndEngAccountCreationAdjusted = () =>
  newApiFetchV2("/use-ownership/online-account-and-engagement/account-creation-adjusted", apiParamsToObjectUseOwnership());

export const getOnlineAccAndEngActiveUsers = () =>
  newApiFetchV2("/use-ownership/online-account-and-engagement/active-users", apiParamsToObjectUseOwnership());

export const getOnlineAccAndEngFirstEngagement = () =>
  newApiFetchV2("/use-ownership/online-account-and-engagement/users-with-first-engagement", apiParamsToObjectUseOwnership());

export const getOnlineAccAndEngAvgVocRating = () =>
  newApiFetchV2("/use-ownership/online-account-and-engagement/avg-voc-rating", apiParamsToObjectUseOwnership());

export const getOnlineAccAndEngEnrolledUsersLinked = () =>
  newApiFetchV2("/use-ownership/online-account-and-engagement/enrolled-users-linked", apiParamsToObjectUseOwnership());

export const getOnlineAccAndEngVehiclesAdded = () =>
  newApiFetchV2("/use-ownership/online-account-and-engagement/vehicles-added", apiParamsToObjectUseOwnership());

export const getServiceLeadServiceRecommendationVisits = () =>
  newApiFetchV2("/use-ownership/service-lead/service-recommendation-visits", apiParamsToObjectUseOwnership());

export const getServiceLeadServiceBookingFormVisits = () =>
  newApiFetchV2("/use-ownership/service-lead/service-booking-form-visits", apiParamsToObjectUseOwnership());

export const getServiceLeadConversionRate = () =>
  newApiFetchV2("/use-ownership/service-lead/conversion-rate", apiParamsToObjectUseOwnership());

export const getServiceLeadServiceLeads = () => newApiFetchV2("/use-ownership/service-lead/service-leads", apiParamsToObjectUseOwnership());

export const getServiceLeadBookingFormsVisitsVsServiceLeads = () =>
  newApiFetchV2("/use-ownership/service-lead/service-booking-forms-visits-vs-service-leads", apiParamsToObjectUseOwnership());

export const getServiceLeadBookingFormVisitsByChannelOrTrend = (groupBy: string, timeline: boolean) =>
  newApiFetchV2(
    `/use-ownership/service-lead/service-booking-form-visits-by-channel?group_by=${groupBy}&timeline=${timeline}`,
    apiParamsToObjectUseOwnership()
  );

export const getServiceLeadServiceBookingFormsOpened = () =>
  newApiFetchV2(`/use-ownership/service-lead/service-booking-forms-opened`, apiParamsToObjectUseOwnership());

export const getSelfServiceUserVisitingSelfLearningPage = () =>
  newApiFetchV2("/use-ownership/self-service/users-visiting-self-learning-page", apiParamsToObjectUseOwnership());

export const getSelfServiceSelfLearningPageVisitsAndTimeSpent = () =>
  newApiFetchV2("/use-ownership/self-service/self-learning-page-visits-and-time-spent", apiParamsToObjectUseOwnership());

export const getSelfServiceSelfLearningPageVisitsByChannel = (trend: boolean) =>
  newApiFetchV2(
    `/use-ownership/self-service/self-learning-page-visits-by-channel-or-by-trend?group_by=channel&trend=${trend}`,
    apiParamsToObjectUseOwnership()
  );

export const getSelfServiceSelfLearningPageVisitsByEntryPoint = (trend: boolean) =>
  newApiFetchV2(
    `/use-ownership/self-service/self-learning-page-visits-by-channel-or-by-trend?group_by=entry_point&trend=${trend}`,
    apiParamsToObjectUseOwnership()
  );

export const getSelfServiceVocRatingForSelfLearningPage = () =>
  newApiFetchV2("/use-ownership/self-service/voc-rating-for-self-learning-page", apiParamsToObjectUseOwnership());

export const getSelfServiceVocRatingForSelfLearningPageOverTime = () =>
  newApiFetchV2("/use-ownership/self-service/voc-rating-for-self-learning-page-over-time", apiParamsToObjectUseOwnership());

export const getSelfServiceTopicClassificationVerbatimData = () =>
  newApiFetchV2("/use-ownership/self-service/topic-classification-verbatim-data", apiParamsToObjectUseOwnership());

export const getSelfServiceTopicClassificationDissatisfaction = () =>
  newApiFetchV2("/use-ownership/self-service/topic-classification-dissatisfaction", apiParamsToObjectUseOwnership());

export const getSelfServiceTopicClassificationSatisfaction = () =>
  newApiFetchV2("/use-ownership/self-service/topic-classification-satisfaction", apiParamsToObjectUseOwnership());

export const getSelfServicePopularSearchTermsViaGoogle = () =>
  newApiFetchV2("/use-ownership/self-service/popular-search-terms-via-google", apiParamsToObjectUseOwnership());
