import { Button, Dialog, DialogContent, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ErrorBoundary } from "@sentry/react";
import React, { Dispatch, SetStateAction } from "react";
import { ErrorMsg } from "../../../../components/AppMessages";
import { CYAN, WHITE } from "../../../../constants";

interface Props {
  open: boolean;
  toggleOpen: () => void;
  visibleColumns: string[];
  setVisibleColumns: Dispatch<SetStateAction<string[]>>;
  columns: Array<Record<string, string>>;
}

const ColumnVisibilityDialog = (props: Props) => {
  const { open, toggleOpen, setVisibleColumns, visibleColumns, columns } = props;

  const classes = makeStyles({
    button: {
      textAlign: "center",
      fontSize: "0.75rem",
      width: "95%",
      marginBottom: "5px",
      borderColor: `#ECECEC !important`,
      color: `${WHITE} !important`,
      borderRadius: "0px !important",
      borderWidth: "0.5px !important",
      "&:hover": {
        backgroundColor: "inherit !important",
        borderColor: `#ECECEC !important`,
        borderWidth: "0.5px !important",
      },
    },
    buttonActive: {
      backgroundColor: CYAN,
      borderColor: `${CYAN} !important`,
      "&:hover": {
        backgroundColor: `${CYAN} !important`,
        borderColor: `${CYAN} !important`,
      },
    },
  })();

  const toogleColumnVisible = (value: string) => {
    setVisibleColumns((prevState) => {
      if (prevState.includes(value)) {
        return prevState.filter((item) => item != value);
      } else {
        return [...prevState, value];
      }
    });
  };

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <Dialog onClose={toggleOpen} open={open} scroll="body">
        <DialogContent style={{ width: "400px", padding: 0 }}>
          <Grid container style={{ backgroundColor: "#0d101a", padding: 10, textAlign: "center" }}>
            {columns.map((column, index) => (
              <Grid key={index} item md={6}>
                <Button
                  className={`${classes.button} ${visibleColumns.includes(column.id) ? classes.buttonActive : ""}`}
                  variant="outlined"
                  onClick={() => toogleColumnVisible(column.id)}
                  disableTouchRipple
                  disableFocusRipple
                >
                  {column.title}
                </Button>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </ErrorBoundary>
  );
};

export default ColumnVisibilityDialog;
