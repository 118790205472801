import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { Control, Controller, FieldErrors, UseFormSetValue } from "react-hook-form";
import { Model } from "../../../constants/interface/admin/common";
import { FormFields } from "../Dialogs/AnnotationDialog";

interface Props {
  control: Control<FormFields>;
  models: Model[];
  filteredModelsList: string[];
  setValue: UseFormSetValue<FormFields>;
  errors: FieldErrors | undefined;
}

const ModelsField = (props: Props) => {
  const { control, models, filteredModelsList, errors } = props;

  const modelOptions = models
    .filter((model: { model: string }) => filteredModelsList.includes(model.model))
    .sort((a: Model, b: Model) => {
      if (a.model == "All") return -1;
      return a.model > b.model ? 1 : -1;
    });

  return (
    <Controller
      name="models"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          options={modelOptions}
          disableClearable
          getOptionLabel={(option: Model) => option.model}
          getOptionSelected={(option, value) => option.model.trim().toLowerCase() == value.model.trim().toLowerCase()}
          value={value[0]}
          onChange={(event, selectedValue) => {
            onChange([selectedValue]);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Model"
              placeholder="Model"
              error={errors?.models ? true : false}
              helperText={errors?.models?.message}
            />
          )}
        />
      )}
      rules={{
        required: {
          value: true,
          message: "Please select a model",
        },
      }}
    />
  );
};

export default ModelsField;
