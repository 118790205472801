import React from "react";
import "../../assets/styles/component/loadingEllipsis.scss";

interface Props {
  isLoading: boolean;
  className?: string;
}

const LoadingEllipsis = (props: Props): JSX.Element => {
  const { isLoading, className } = props;

  return (
    <div
      className={`overlay ${className ? className : ""} ${isLoading ? "show" : ""}`}
      onClick={(evt) => {
        evt.stopPropagation();
        evt.preventDefault();
      }}
    >
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default LoadingEllipsis;
