import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { ALL_OPTION_NO_SPACE } from "../../../constants";
import { LeadSource } from "../../../constants/interface/admin/common";
import { FormFields } from "../Dialogs/AnnotationDialog";

interface Props {
  control: Control<FormFields>;
  leadSources: LeadSource[];
  filteredLeadSources: string[];
  errors: FieldErrors | undefined;
}

const LeadSourcesField = (props: Props) => {
  const { control, leadSources, filteredLeadSources, errors } = props;
  return (
    <Controller
      name="lead_sources"
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          multiple
          options={leadSources.filter(
            (leadSource: LeadSource) =>
              filteredLeadSources.includes(leadSource.lead_source) || leadSource.lead_source == ALL_OPTION_NO_SPACE
          )}
          disableCloseOnSelect
          getOptionLabel={(option: LeadSource) => option.lead_source}
          getOptionSelected={(option, value) => option.lead_source == value.lead_source}
          value={value}
          onChange={(_, data) => {
            onChange(data);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Lead Source"
              placeholder="Lead Source"
              error={errors?.lead_sources ? true : false}
              helperText={errors?.lead_sources?.message}
            />
          )}
        />
      )}
      rules={{
        required: {
          value: true,
          message: "Please select a lead source",
        },
      }}
    />
  );
};

export default LeadSourcesField;
