import { getPurchaseFunnelParameters } from "../api/purchaseFunnelApi";
import { ISeoFilters } from "../constants/interface";
import { brandPerformanceApiParamsToObject } from "./brandPerformanceApi";
import { getCpoParams } from "./cpoDashboardApi";
import { cpoOperationalParams } from "./cpoOperationalApi";
import { ecommerceApiParamsToObject } from "./ecommerce";
import { getEuropeActivationParameters } from "./europeActivationParameters";
import { getParams as getExecutiveSummaryProductPerformanceParams } from "./executiveSummaryProductPerformanceApi";
import { getParams as getExecutiveSummaryDisParams } from "./executiveSummaryWebsiteApi";
import { journeyExcellenceApiParamsToObject } from "./journeyExcellenceApi";
import { midasApiParamsToObject } from "./midasApi";
import { msrpApiParamsToObject } from "./msrpApi";
import { getQueryParams as getProductPerformanceParams } from "./productPerformanceApi";
import { getParams as getTopFlopParams } from "./topFlopApi";
import { apiParamsToObjectUseOwnership } from "./useOwnershipApi";
import { apiFetchV2, fetchDataV2, newApiFetch, newApiFetchV2, newFetchDataV2, postDataV2 } from "./utils";
import { getParams as getJourneyExcellenceMarketPerformanceSummaryParams } from "./JourneyExcellenceMarketPerformanceSummary";
import { getParams as getDmeoParams } from "./dmeoApi";

export const getGeoScopeData = () => fetchDataV2("/authentication/sign-up-geos", {});
export const getCpoFiltersData = () => newApiFetchV2("/certified-pre-owned/get-filters", getCpoParams());
export const getCcsCockpitFilterData = () => newFetchDataV2("/ccs/get-filter-cockpit", {});
export const getAppRatingsFilterData = () => newFetchDataV2("/ccs/get-filter-appreview-data", {});
export const getUserGroupMarkets = () => newApiFetch("/markets", {});
export const getMtmMarketsData = () => newApiFetch("/mtm_api/markets", {});
export const getSeoFilters = (): Promise<ISeoFilters> => newApiFetch("/seo/_filters", {});

export const getEuropeActivationFilterData = () =>
  postDataV2("/europe-activation-ratio-reporting/get-filters", getEuropeActivationParameters());

export const getEuropeActivationMarketGroups = () => fetchDataV2("/europe-activation-ratio-reporting/get-market-groups");

export const getDisFilters = () => newApiFetchV2("/dis/filters", getPurchaseFunnelParameters());

export const getTopFlopFilters = () => newApiFetchV2("/top-flop/get-filters", getTopFlopParams());

export const getProductMarketingOptimizationFilters = (brand: string) =>
  newApiFetchV2(`/product-marketing-optimization/get-filters-data?use_cache=true`, { brand: brand });

export const getMidasFilters = (params: string) => apiFetchV2("/midas/fmi-run-rate/filters?use_cache=true", midasApiParamsToObject(params));

export const getMidasFmiByModelFilters = (params: string) =>
  apiFetchV2("/midas/fmi-by-model/filters?use_cache=true", midasApiParamsToObject(params));

export const getMidasMediaMixFilters = (params: string) =>
  apiFetchV2("/midas/media-mix/filters?use_cache=true", midasApiParamsToObject(params));

export const getMidasMtpCategoryFilters = (params: string) =>
  apiFetchV2("/midas/mtp-category/filters?use_cache=true", midasApiParamsToObject(params));

export const getMidasStrategicDealerSupportFilters = (params: string) =>
  apiFetchV2("/midas/strategic-dealer-support/filters?use_cache=true", midasApiParamsToObject(params));

export const getMidasStrategicCEFilters = (params: string) =>
  apiFetchV2("/midas/strategic-ce/filters?use_cache=true", midasApiParamsToObject(params));

export const getMidasFixedFilters = (params: string) => apiFetchV2("/midas/fixed/filters?use_cache=true", midasApiParamsToObject(params));

export const getMidasSpendVsTrafficFilters = (params: string) =>
  apiFetchV2("/midas/spend-vs-traffic/filters?use_cache=true", midasApiParamsToObject(params));

export const getProductPerformanceFilters = () => apiFetchV2("/product-performance/filters", getProductPerformanceParams());

export const getUaeFilters = () => fetchDataV2("/ccs-activation-ratio/get-uae-filters?use_cache=true", {});

export const getVocFilters = () => fetchDataV2("/voc/filters");

export const getCpoOperationalFilters = (params: string) => apiFetchV2("/cpo-operational/filters", cpoOperationalParams(params));

export const getEcommerceFilters = () => apiFetchV2("/ecom/filters", { ...ecommerceApiParamsToObject() });

export const getMsrpFilters = () => apiFetchV2("/msrp/filters", msrpApiParamsToObject());

export const getJourneyExcellenceFilters = (metric: string) => {
  let path = "/journey-excellence/fmi/filters";
  if (metric == "Online Journey") {
    path = "/journey-excellence/online-journey/filters";
  } else if (metric == "Leads Management System") {
    path = "/journey-excellence/lms/filters";
  } else if (metric == "Product Journey") {
    path = "/journey-excellence/product-journey/filters";
  }
  return apiFetchV2(path, journeyExcellenceApiParamsToObject());
};

export const getJourneyExcellenceMarketPerformanceSummaryFilters = () =>
  newApiFetchV2("/mtm/filters", getJourneyExcellenceMarketPerformanceSummaryParams());
export const getOCEModelsByBrandFilters = () => fetchDataV2("/filters/get-all-models-by-brand", {});
export const getOCEMarketsByBrandFilters = () => fetchDataV2("/filters/get-all-markets-by-brand", {});
export const getOCEChannelsByBrandFilters = () => fetchDataV2("/filters/get-all-channels-by-brand", {});

export const getOCEAllRegionsFilters = () => fetchDataV2("/filters/get-all-regions", {});
export const getOCEAllMarketRegions = () => fetchDataV2("/filters/get-all-market-regions", {});

export const getUseOwnershipFilters = (page: string) => {
  return apiFetchV2(`/use-ownership/filters?page=${page.replaceAll(" ", "%20")}`, apiParamsToObjectUseOwnership());
};

export const getBrandPerformanceVolumeFilters = (params: string) =>
  apiFetchV2("/brand-performance/brand-volume/filters?use_cache=true", brandPerformanceApiParamsToObject(params));
export const getBrandPerformanceOaOFilters = (params: string) =>
  apiFetchV2("/brand-performance/brand-oao/filters?use_cache=true", brandPerformanceApiParamsToObject(params));
export const getBrandPerformanceImageAttributesFilters = (params: string) =>
  apiFetchV2("/brand-performance/brand-image-attributes/filters?use_cache=true", brandPerformanceApiParamsToObject(params));

export const getExecutiveSummaryDisFilters = () => apiFetchV2("/executive-summary/dis/filters", getExecutiveSummaryDisParams());
export const getExecutiveSummaryProductPerformanceFilters = () =>
  apiFetchV2("/executive-summary/product-performance/filters", getExecutiveSummaryProductPerformanceParams());

export const getDmeoFilters = () => newApiFetchV2("/dmeo/filters", getDmeoParams());
