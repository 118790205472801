import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { ErrorMsg, NoDataMsg } from "../../../../components/AppMessages";
import { MtpSpendChart } from "../../../../components/Charts/Midas/MtpSpendChart";
import LoadingEllipsis from "../../../../components/Loading/LoadingEllipsis";
import { ThemeContext } from "../../../../context";
import { maxValue } from "../Helpers/helpers";

interface Props {
  seriesList: Array<{ name: string; seriesColor: string }>;
}

const MtpSpendGraph = (props: Props) => {
  const themeContext = useContext(ThemeContext);
  const { seriesList } = props;
  const loading = useSelector((state: RootStateOrAny) => state.loading.midas_mtp_spend);
  const data = useSelector((state: RootStateOrAny) => state.midas.midas_mtp_spend);
  const categoryOrder = ["Media", "Strategic", "Fixed"];

  const { max: q1Max, min: q1Min } = useMemo(() => maxValue(data?.q1), [data]);

  const { max: q2Max, min: q2Min } = useMemo(() => maxValue(data?.q2), [data]);
  const { max: q3Max, min: q3Min } = useMemo(() => maxValue(data?.q3), [data]);
  const { max: q4Max, min: q4Min } = useMemo(() => maxValue(data?.q4), [data]);

  const q1ChartData = useMemo(() => {
    if (data?.q1?.length)
      return data?.q1
        ?.map((val: { yr_scnr_rv: string; mtp_category: string; amount: number }) => {
          const result: any = { yr_scnr_rv: val.yr_scnr_rv, mtp_category: val.mtp_category };
          result[`${val.yr_scnr_rv}_amount`] = val.amount;
          return result;
        })
        .sort(
          (a: { mtp_category: string }, b: { mtp_category: string }) =>
            categoryOrder?.indexOf(a.mtp_category) - categoryOrder?.indexOf(b.mtp_category)
        );
    return [];
  }, [data]);

  const q2ChartData = useMemo(() => {
    if (data?.q2?.length)
      return data?.q2
        ?.map((val: { yr_scnr_rv: string; mtp_category: string; amount: number }) => {
          const result: any = { yr_scnr_rv: val.yr_scnr_rv, mtp_category: val.mtp_category };
          result[`${val.yr_scnr_rv}_amount`] = val.amount;
          return result;
        })
        .sort(
          (a: { mtp_category: string }, b: { mtp_category: string }) =>
            categoryOrder?.indexOf(a.mtp_category) - categoryOrder?.indexOf(b.mtp_category)
        );
    return [];
  }, [data]);

  const q3ChartData = useMemo(() => {
    if (data?.q3?.length)
      return data?.q3
        ?.map((val: { yr_scnr_rv: string; mtp_category: string; amount: number }) => {
          const result: any = { yr_scnr_rv: val.yr_scnr_rv, mtp_category: val.mtp_category };
          result[`${val.yr_scnr_rv}_amount`] = val.amount;
          return result;
        })
        .sort(
          (a: { mtp_category: string }, b: { mtp_category: string }) =>
            categoryOrder?.indexOf(a.mtp_category) - categoryOrder?.indexOf(b.mtp_category)
        );
    return [];
  }, [data]);

  const q4ChartData = useMemo(() => {
    if (data?.q4?.length)
      return data?.q4
        ?.map((val: { yr_scnr_rv: string; mtp_category: string; amount: number }) => {
          const result: any = { yr_scnr_rv: val.yr_scnr_rv, mtp_category: val.mtp_category };
          result[`${val.yr_scnr_rv}_amount`] = val.amount;
          return result;
        })
        .sort(
          (a: { mtp_category: string }, b: { mtp_category: string }) =>
            categoryOrder?.indexOf(a.mtp_category) - categoryOrder?.indexOf(b.mtp_category)
        );
    return [];
  }, [data]);

  return (
    <div className="mtp_spend_quarterly primary_background">
      <div className="main_content ">
        <div className="charts">
          <div className="quarter_breakdown">
            {q1ChartData?.length ? <span className="quarter_title">Q1</span> : null}
            {q1ChartData?.length && !loading ? (
              <ErrorBoundary fallback={<ErrorMsg />}>
                <MtpSpendChart
                  minVal={q1Min}
                  maxVal={q1Max}
                  data={q1ChartData}
                  theme={themeContext.theme}
                  seriesList={seriesList}
                  chartName="mtpSpendChartQ1"
                  isLoading={false}
                />
              </ErrorBoundary>
            ) : loading ? (
              <LoadingEllipsis isLoading={loading} />
            ) : (
              <NoDataMsg message="No data available" />
            )}
          </div>
          <div className="quarter_breakdown">
            {q2ChartData?.length ? <span className="quarter_title">Q2</span> : null}
            {q2ChartData?.length && !loading ? (
              <ErrorBoundary fallback={<ErrorMsg />}>
                <MtpSpendChart
                  maxVal={q2Max}
                  minVal={q2Min}
                  data={q2ChartData}
                  seriesList={seriesList}
                  theme={themeContext.theme}
                  chartName="mtpSpendChartQ2"
                  isLoading={false}
                />
              </ErrorBoundary>
            ) : loading ? (
              <LoadingEllipsis isLoading={loading} />
            ) : (
              <NoDataMsg message="No data available" />
            )}
          </div>
          <div className="quarter_breakdown">
            {q3ChartData?.length ? <span className="quarter_title">Q3</span> : null}
            {q3ChartData?.length && !loading ? (
              <ErrorBoundary fallback={<ErrorMsg />}>
                <MtpSpendChart
                  maxVal={q3Max}
                  minVal={q3Min}
                  data={q3ChartData}
                  seriesList={seriesList}
                  theme={themeContext.theme}
                  chartName="mtpSpendChartQ3"
                  isLoading={false}
                />
              </ErrorBoundary>
            ) : loading ? (
              <LoadingEllipsis isLoading={loading} />
            ) : (
              <NoDataMsg message="No data available" />
            )}
          </div>
          <div className="quarter_breakdown">
            {q4ChartData?.length ? <span className="quarter_title">Q4</span> : null}
            {q4ChartData?.length && !loading ? (
              <ErrorBoundary fallback={<ErrorMsg />}>
                <MtpSpendChart
                  maxVal={q4Max}
                  minVal={q4Min}
                  data={q4ChartData}
                  seriesList={seriesList}
                  theme={themeContext.theme}
                  chartName="mtpSpendChartQ4"
                  isLoading={false}
                />
              </ErrorBoundary>
            ) : loading ? (
              <LoadingEllipsis isLoading={loading} />
            ) : (
              <NoDataMsg message="No data available" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MtpSpendGraph;
