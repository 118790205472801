// Todo: Add missing dates for last data refresh alert
import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { setLastDataRefresh } from "../../../actions";
import { fetchVisitsData } from "../../../api/Ariya";
import { DrilldownLayout } from "../../../components/Layouts/Ariya";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { extractMonitorData } from "../helpers/dataFunctions";
import styles from "./styles.module.scss";
import { HandraisersConversion } from "./Tables";

export const HandraisersInsightMarketSplit = () => {
  const dispatch = useDispatch();
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.ariya_monitor);

  // Usage tracking
  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "Ariya", page: "Ariya Handraisers Insights - Market split" });
  }, []);

  // Last data refresh plus Visits trend
  useEffect(() => {
    fetchVisitsData().then((response) => {
      if (response && !("error" in response)) {
        const {
          chart_data: { year, month, day },
        } = extractMonitorData(response);
        dispatch(setLastDataRefresh("ariya_monitor", `${year}-${month}-${day}`));
      }
    });
  }, []);

  return (
    <DrilldownLayout
      title="NAE Hand-raisers - Market Split"
      titleDescription="Click on country to see top 10 dealers"
      lastDataRefresh={lastDataRefresh}
      previousPagePath="/ariya/handraisers"
    >
      <Box display={"grid"} gridGap={15} className={styles.cardContainer}>
        <HandraisersConversion />
      </Box>
    </DrilldownLayout>
  );
};
