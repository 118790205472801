import { ErrorBoundary } from "@sentry/react";
import React from "react";
import "../../assets/styles/component/documentation.scss";
import "../../assets/styles/pages/info.scss";
import { ErrorMsg } from "../AppMessages";
import { DashboardMenu, InfoMenu } from "../Menu";

interface Props {
  menu: JSX.Element;
  content: JSX.Element;
}

export const InfoLayout = (props: Props) => {
  const { menu, content } = props;

  return (
    <ErrorBoundary fallback={<ErrorMsg />}>
      <div className="pageWrapper" id="mainDashboard">
        <div className="dashboard" id="infoDashboard">
          <DashboardMenu />
          <div className="info_page_dashboard">
            <InfoMenu />

            <div className="info_container">
              {menu}
              <div className="info_section info_content data_documentation">{content}</div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
