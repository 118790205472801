import { Button } from "@material-ui/core";
import { ErrorBoundary } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  fetchActivationDataRefresh,
  fetchEuropeActivationFilters,
  fetchEuropeActivationMarketGroups,
  setPageName,
  setPageTitleDescription,
  setSpecificParameter,
} from "../../../actions";
import {
  getEuropeActivationByCountry,
  getEuropeActivationByDealer,
  getEuropeActivationByModel,
  getEuropeActivationOverall,
} from "../../../api/europeActivationApi";
import "../../../assets/styles/pages/ccsEuropeActivation.scss";
import { ErrorMsg } from "../../../components/AppMessages";
import { CcsEuropeActivationFilters } from "../../../components/Filters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { BackToCockpitButton, PageTitle } from "../../../components/PageContent/subs";
import { ToggleSwitch } from "../../../components/Toogle";
import { BackgroundContext, DashboardBannerContext } from "../../../context";
import { eventTracking, MixpanelEvents } from "../../../utils/userTracking";
import { isNull } from "../../../utils/utilityFunctions";
import { ActivationBreakdown, ActivationBreakdownG8, ActivationOverview } from "./subs";

const bannerText = "The last 3 months data represents the activation ratio based on the latest report available and is likely to change.";

export const EuropeActivation = withRouter((props: RouteComponentProps): JSX.Element => {
  const dispatch = useDispatch();
  const { setHideBackgroundImage } = useContext(BackgroundContext);
  const { setBannerText, setShowBanner } = useContext(DashboardBannerContext);

  const { history, location } = props;

  //Parameter values
  const marketParamValue = useSelector((state: RootStateOrAny) => state.activation_parameters.market);
  const dateRangeParamValue = useSelector((state: RootStateOrAny) => state.activation_parameters.date_range);
  const activationFormatParamValue = useSelector((state: RootStateOrAny) => state.activation_parameters.activation_format);
  const marketGroupParamValue = useSelector((state: RootStateOrAny) => state.activation_parameters.market_group);

  //Data values
  const { overall } = useSelector((state: RootStateOrAny) => state.europe_activation.overall_data);
  const { data: cockpitActivationData } = useSelector((state: RootStateOrAny) => state.europe_activation.cockpit_activation_breakdown);
  const {
    country_data: countryData,
    model_data: modelData,
    dealer_data: dealerData,
  } = useSelector((state: RootStateOrAny) => state.europe_activation);

  // Last data refresh
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.activation);

  const g8ChartData = useMemo(
    () =>
      cockpitActivationData
        ? cockpitActivationData.map(
            (row: {
              dates: string;
              volume: number | null;
              total: number | null;
              rate: number | null;
              bp_g8_private_activation_ratio: number | null;
            }) => ({
              ...row,
              rate: isNull(row.rate) ? null : (row.rate as number) * 100,
              bp_g8_private_activation_ratio: isNull(row.bp_g8_private_activation_ratio)
                ? null
                : (row.bp_g8_private_activation_ratio as number) * 100,
            })
          )
        : [],
    [cockpitActivationData]
  );

  //Loading
  const {
    activation_overall_data: isOverallDataLoading,
    activation_country_data: isCountryDataLoading,
    activation_model_data: isModelDataLoading,
    activation_dealer_data: isDealerDataLoading,
  } = useSelector((state: RootStateOrAny) => state.loading);

  //Overall data
  const [activationRate, setActivationRate] = useState<number | "n/a">("n/a");
  const [activationVolume, setActivationVolume] = useState<number | "n/a">("n/a");
  const [warrantyStart, setWarrantyStart] = useState<number | "n/a">("n/a");

  //Breakdown data
  const [showCumulativeData, setShowCumulativeData] = useState(false);

  const showRates = activationFormatParamValue === "rates";

  // Usage tracking
  useEffect(() => {
    eventTracking(MixpanelEvents.page_view, { dashboard: "CCS", page: "CCS Europe Activation" });
  }, [location.pathname]);

  // Sets page name
  useEffect(() => {
    Promise.all([
      dispatch(setPageName("Europe activation ratio reporting")),
      dispatch(setPageTitleDescription(" (Scope: retail, excluding fleet and dealers' units)")),
      dispatch(setSpecificParameter("region", "Europe")),
      setHideBackgroundImage(true),
      dispatch(fetchActivationDataRefresh()),
    ]);

    return () => {
      Promise.all([setShowBanner(false), setBannerText(""), setHideBackgroundImage(false), dispatch(setPageTitleDescription(""))]);
    };
  }, [dispatch, setHideBackgroundImage]);

  // Sets banner if any of last 3 months is selected in the date range
  useEffect(() => {
    const startOfLast3Months: any = moment().subtract(2, "months").startOf("month");
    const selectedDates = dateRangeParamValue.split(",");
    const lastSelectedDate = selectedDates[selectedDates.length - 1];

    if (moment(lastSelectedDate, "MMMM YYYY").isSameOrAfter(startOfLast3Months)) {
      Promise.all([setShowBanner(true), setBannerText(bannerText)]);
    } else {
      Promise.all([setShowBanner(false), setBannerText("")]);
    }
  }, [dateRangeParamValue]);

  //Fetch filters market group again when the market param value changes
  useEffect(() => {
    dispatch(fetchEuropeActivationMarketGroups());
  }, [marketParamValue, dateRangeParamValue, dispatch]);

  //Fetch filters again when the market param value changes
  useEffect(() => {
    dispatch(fetchEuropeActivationFilters());
  }, [marketParamValue, dateRangeParamValue, marketGroupParamValue, dispatch]);

  const {
    data: overallActivationData,
    isFetching: overallActivationDataLoading,
    refetch: refetchOverallActivationChart,
  } = useQuery({
    queryKey: ["overallActivationData"],
    queryFn: () => getEuropeActivationOverall(true, showCumulativeData),
    enabled: false,
    initialData: {},
  });

  const {
    data: counrtyActivationChartData,
    isFetching: countryActivationChartDataLoading,
    refetch: refetchCountryActivationChart,
  } = useQuery({
    queryKey: ["countryActivationChartBreakdown"],
    queryFn: () => getEuropeActivationByCountry(true, showCumulativeData),
    enabled: false,
    initialData: {},
  });

  const {
    data: counrtyActivationTableData,
    isFetching: countryActivationTableDataLoading,
    refetch: refetchCountryActivationTable,
  } = useQuery({
    queryKey: ["countryActivationTableBreakdown"],
    queryFn: () => getEuropeActivationByCountry(false, showCumulativeData),
    enabled: false,
    initialData: [],
  });

  const {
    data: modelActivationChartData,
    isFetching: modelActivationChartDataLoading,
    refetch: refetchModelActivationChart,
  } = useQuery({
    queryKey: ["modelActivationChartBreakdown"],
    queryFn: () => getEuropeActivationByModel(true, showCumulativeData),
    enabled: false,
    initialData: {},
  });

  const {
    data: modelActivationTableData,
    isFetching: modelActivationTableDataLoading,
    refetch: refetchModelActivationTable,
  } = useQuery({
    queryKey: ["modelActivationTableBreakdown"],
    queryFn: () => getEuropeActivationByModel(false, showCumulativeData),
    enabled: false,
    initialData: [],
  });

  const {
    data: dealerActivationChartData,
    isFetching: dealerActivationChartDataLoading,
    refetch: refetchDealerActivationChart,
  } = useQuery({
    queryKey: ["dealerActivationChartBreakdown"],
    queryFn: () => getEuropeActivationByDealer(true, showCumulativeData),
    enabled: false,
    initialData: {},
  });

  const {
    data: dealerActivationTableData,
    isFetching: dealerActivationTableDataLoading,
    refetch: refetchDealerActivationTable,
  } = useQuery({
    queryKey: ["dealerActivationTableBreakdown"],
    queryFn: () => getEuropeActivationByDealer(false, showCumulativeData),
    enabled: false,
    initialData: [],
  });

  //Fetch chart data when cumulative data
  useEffect(() => {
    refetchOverallActivationChart();
    refetchCountryActivationChart();
    refetchCountryActivationTable();
    refetchModelActivationChart();
    refetchModelActivationTable();
    refetchDealerActivationChart();
    refetchDealerActivationTable();
  }, [history.location.search, showCumulativeData]);

  useEffect(() => {
    dispatch(setSpecificParameter("cumulative", showCumulativeData));
  }, [showCumulativeData]);

  //Overall data manipulation
  useEffect(() => {
    if (overall && overall.length > 0) {
      const { rate, volume, total_warranty_start } = overall[0];

      const rateValue = Math.round(rate * 100);
      const volumeValue = volume ? volume : "n/a";
      const totalWarranty = total_warranty_start;

      Promise.all([setActivationVolume(volumeValue), setActivationRate(rateValue), setWarrantyStart(totalWarranty)]);
    }
  }, [overall]);

  const [currentActive, setCurrentActive] = useState("");
  useEffect(() => {
    showRates ? setCurrentActive("ratio") : setCurrentActive("volume");
  }, [showRates]);

  const handleToggleChange = () => {
    const activationFormatValue = showRates ? "volumes" : "rates";
    dispatch(setSpecificParameter("activation_format", activationFormatValue));
  };

  const onBtnLinkClick = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      evt.stopPropagation();
      const {
        currentTarget: { dataset },
      } = evt;
      const pageLink = dataset?.page;
      props.history.push({ pathname: pageLink, search: "" });
    },
    [props.history]
  );

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<CcsEuropeActivationFilters />}
        lastDataRefresh={lastDataRefresh}
        dataDocumentation="ccs_data"
        pageTitle={
          <PageTitle
            dataDocumentation="ccs_data"
            showPageTitleDescription={true}
            backButton={<BackToCockpitButton id={"euro_activation"} />}
          />
        }
      >
        <>
          <ErrorBoundary fallback={<ErrorMsg />}>
            <div className="ccs_europe_activation_container">
              <ToggleSwitch
                activeToggleLabel={"Ratio"}
                inactiveToggleLabel={"Volumes"}
                active={showRates}
                handleToggleClick={handleToggleChange}
                toggleTitle={"See activation: "}
                toggleClassName="activation_cumulative_toggle"
              />
              <ToggleSwitch
                inactiveToggleLabel={"Cumulative"}
                activeToggleLabel={"Non-cumulative"}
                active={!showCumulativeData}
                handleToggleClick={() => setShowCumulativeData(!showCumulativeData)}
                toggleTitle={"See chart data: "}
                toggleClassName="activation_form"
              />

              {/* ACTIVATION OVERVIEW TILE */}
              <ErrorBoundary fallback={<ErrorMsg />}>
                <ActivationOverview
                  title={"Activation " + currentActive + " overview"}
                  rate={overallActivationData?.overall?.rate ?? "n/a"}
                  volume={overallActivationData?.overall?.volume ?? "n/a"}
                  warrantyStart={overallActivationData?.overall?.total_warranty_start ?? "n/a"}
                  isLoading={overallActivationDataLoading}
                  chartData={overallActivationData?.data ?? []}
                  cumulative={showCumulativeData}
                  isActivationPage={true}
                />
              </ErrorBoundary>

              <div className="ccs_activation_rate_breakdown">
                {/* ACTIVATION BREAKDOWN BY COUNTRY  */}
                <ErrorBoundary fallback={<ErrorMsg />}>
                  {marketGroupParamValue === "G8" ? (
                    <ActivationBreakdownG8
                      title={"Activation " + currentActive + " by country"}
                      chartId={"activation_country_chart"}
                      chartData={counrtyActivationChartData}
                      tableData={counrtyActivationTableData}
                      tableId={"activation_country_table"}
                      dataKey={"geography"}
                      tableHeader={"Country"}
                      isLoading={countryActivationChartDataLoading || countryActivationTableDataLoading}
                      isCumulative={{ cumulative: showCumulativeData, startDate: dateRangeParamValue?.split(",")[0] }}
                    />
                  ) : (
                    <ActivationBreakdown
                      title={"Activation " + currentActive + " by country (top 5)"}
                      chartId={"activation_country_chart"}
                      chartData={counrtyActivationChartData}
                      tableData={counrtyActivationTableData}
                      tableId={"activation_country_table"}
                      dataKey={"geography"}
                      tableHeader={"Country"}
                      isLoading={isCountryDataLoading}
                      isCumulative={{ cumulative: showCumulativeData, startDate: dateRangeParamValue?.split(",")[0] }}
                    />
                  )}{" "}
                </ErrorBoundary>

                {/* ACTIVATION BREAKDOWN BY MODEL  */}
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <ActivationBreakdown
                    title={"Activation " + currentActive + " by model"}
                    chartId={"activation_model_chart"}
                    chartData={modelActivationChartData}
                    tableData={modelActivationTableData}
                    tableId={"activation_model_table"}
                    dataKey={"model"}
                    tableHeader={"Model"}
                    isLoading={modelActivationChartDataLoading || modelActivationTableDataLoading}
                    isCumulative={{ cumulative: showCumulativeData, startDate: dateRangeParamValue?.split(",")[0] }}
                  />
                </ErrorBoundary>

                {/* ACTIVATION BREAKDOWN BY DEALER  */}
                <ErrorBoundary fallback={<ErrorMsg />}>
                  <ActivationBreakdown
                    title={"Activation " + currentActive + " by dealer (top 5)"}
                    chartId={"activation_dealer_chart"}
                    chartData={dealerActivationChartData}
                    tableData={dealerActivationTableData}
                    tableId={"activation_dealer_table"}
                    dataKey={"dealer"}
                    tableHeader={"Dealer"}
                    isLoading={dealerActivationChartDataLoading || dealerActivationTableDataLoading}
                    isCumulative={{ cumulative: showCumulativeData, startDate: dateRangeParamValue?.split(",")[0] }}
                  />
                </ErrorBoundary>
              </div>
            </div>
            <div className="container_footer hide_on_desktop">
              <Button
                variant={"outlined"}
                className={"filter_btn_element"}
                data-page={"/ccs_vital_signs/ccs_cockpit"}
                onClick={(evt) => onBtnLinkClick(evt)}
                size={"small"}
              >
                CCS vital signs
              </Button>

              <Button
                variant={"outlined"}
                className={"filter_btn_element"}
                data-page={"/ccs_vital_signs/ccs_journey"}
                onClick={(evt) => onBtnLinkClick(evt)}
                size={"small"}
              >
                CCS journey
              </Button>
            </div>
          </ErrorBoundary>
        </>
      </DefaultPageContent>
    </DashboardLayout>
  );
});
