import { XYChart } from "@amcharts/amcharts4/charts";
import moment from "moment";
import { circleBulletRadius } from "../../../components/Charts/helpers/configurations";
import { BIG_NUMBER_ABBREVIATION } from "../../../constants";

interface IGraphData {
  counts: [number | string];
  day: number;
  month: number;
  name: string;
  year: number;
}

export const getGraphData = (data: IGraphData[]) => {
  if (!data) return [];

  return data.map(({ counts, day, month, year }, idx) => ({
    value: +counts[0],
    label: moment(`${day}-${month}-${year}`, "DD-MM-YYYY").format("DD MMM YYYY"),
    showBullet: idx === data.length - 1,
    bulletRadius: idx === data.length - 1 ? circleBulletRadius + 1 : circleBulletRadius - 1,
  }));
};

export const ariyaChartConfiguration = (chart: XYChart) => {
  chart.zoomOutButton.disabled = true;
  chart.fontSize = "0.75rem";
  chart.numberFormatter.numberFormat = "#.";
  chart.paddingLeft = 0;
  chart.paddingRight = 0;
  chart.paddingBottom = 0;
  chart.fontFamily = "nissan";
  chart.numberFormatter.bigNumberPrefixes = BIG_NUMBER_ABBREVIATION;
  if (chart.preloader) chart.preloader.disabled = true;
};
