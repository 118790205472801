import { ErrorBoundary } from "@sentry/react";
import React, { useContext, useEffect, useMemo } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  fetchMidasMediaMixLastDataRefresh,
  fetchMidasMtpCategoryFilters,
  fetchMtpCategoryDataByQuarter,
  setPageName,
} from "../../../actions";
import "../../../assets/styles/pages/MidasMtpCategory.scss";
import "../../../assets/styles/pages/midasUnderConstruction.scss";
import { ErrorMsg } from "../../../components/AppMessages";
import MarketingInvastmentDashboardFilters from "../../../components/Filters/MarketingInvastmentDashboardFilters";
import { DashboardLayout } from "../../../components/Layouts";
import { DefaultPageContent } from "../../../components/PageContent";
import { PageTitle } from "../../../components/PageContent/subs";
import { MIDAS_BTN_FILTERS } from "../../../constants";
import { DashboardBannerContext } from "../../../context";
import { getAvailableYearScenarios } from "../helpers";
import MtpCategoryTables from "./components/MtpCategoryTables";

const MtpCategory = withRouter((props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const { setBannerText, setShowBanner } = useContext(DashboardBannerContext);
  const lastDataRefresh = useSelector((state: RootStateOrAny) => state.data_refresh.midas_fmi_last_refresh_date);
  const { brand, nsc, period, local_currency_name, yr_scnr_rv, mdl_nm_rev, unit, mc_group, subgroup, market, model } = useSelector(
    (state: RootStateOrAny) => state.parameters
  );

  const pageName = "MTP category";
  const periodClass = useMemo(() => (period == "FY Month" ? "month_period" : "quarter_period"), [period]);

  const data = useSelector((state: RootStateOrAny) => state.midas.midas_mtp_category_data_by_quarter);
  const yrScenarios: string[] = useMemo(() => getAvailableYearScenarios(data?.full_year), [data]);
  const yrScnrClass = useMemo(() => (yrScenarios?.length == 1 ? "one_yr_scnr_no_var" : ""), [yrScenarios]);

  //Endpoints that depend on parameters
  useEffect(() => {
    Promise.all([dispatch(fetchMtpCategoryDataByQuarter())]);
  }, [brand, yr_scnr_rv, nsc, period, local_currency_name, mdl_nm_rev, unit, mc_group, subgroup, market, model]);

  useEffect(() => {
    Promise.all([dispatch(fetchMidasMtpCategoryFilters())]);
  }, [brand, yr_scnr_rv, nsc, mdl_nm_rev, period, mc_group, subgroup, market]);

  useEffect(() => {
    Promise.all([
      // Sets page name
      dispatch(setPageName(pageName)),
      dispatch(fetchMidasMediaMixLastDataRefresh()),
    ]);
    setBannerText("Forex Rate based from latest BP");
    setShowBanner(false);
  }, [dispatch]);

  return (
    <DashboardLayout>
      <DefaultPageContent
        filter={<MarketingInvastmentDashboardFilters filterBtns={MIDAS_BTN_FILTERS} hasModel hasPeriod isCustom hasUnit />}
        dataDocumentation="midas"
        lastDataRefresh={lastDataRefresh}
        pageTitle={<PageTitle dataDocumentation="midas" />}
      >
        <div className="midas_mtp_category_sotu">
          <div className={`tables ${periodClass} ${yrScnrClass}`}>
            <ErrorBoundary fallback={<ErrorMsg />}>
              <MtpCategoryTables />
            </ErrorBoundary>
          </div>
        </div>
      </DefaultPageContent>
    </DashboardLayout>
  );
});

export default MtpCategory;
