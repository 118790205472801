import { ColumnSeries, DateAxis, LineSeries, XYChart } from "@amcharts/amcharts4/charts";
import { percent } from "@amcharts/amcharts4/core";
import { BIG_NUMBER_ABBREVIATION } from "../../../constants";
import { columnSeriesConfiguration, configureDateAxis, legendConfiguration, lineSeriesConfiguration } from "./configurations";
import { getTooltipDateFormat, getTooltipTextFormat } from "./helpers";
import { OceSeriesConfigInt, ThemeInt } from "./types";

export const configureOCEDateAxis = (dateAxis: DateAxis, theme: ThemeInt, granularity: string) => {
  configureDateAxis(dateAxis, theme);
  dateAxis.baseInterval = granularity === "monthly" ? { timeUnit: "month", count: 1 } : { timeUnit: "day", count: 1 };
  dateAxis.dateFormats.setKey("day", "dd MMM yyyy");
  dateAxis.dateFormats.setKey("week", "dd MMM yyyy");
  dateAxis.dateFormats.setKey("month", "MMM yyyy");
  dateAxis.periodChangeDateFormats.setKey("day", "dd MMM yyyy");
  dateAxis.periodChangeDateFormats.setKey("week", "dd MMM yyyy");
  dateAxis.periodChangeDateFormats.setKey("month", "MMM");
  dateAxis.tooltipDateFormat = getTooltipDateFormat(granularity);
  dateAxis.startLocation = 0.2;
  dateAxis.endLocation = 0.8;
  dateAxis.renderer.minGridDistance = granularity === "weekly" ? 50 : 100;
};

export const configureOCELineSeries = (lineSeries: LineSeries, options: OceSeriesConfigInt) => {
  const bullet = lineSeriesConfiguration(lineSeries, options);
  lineSeries.dataFields.dateX = "date";

  bullet.tooltipText = getTooltipTextFormat(options.granularity);

  return bullet;
};

export const configureOCEColumnSeries = (columnSeries: ColumnSeries, options: OceSeriesConfigInt) => {
  columnSeriesConfiguration(columnSeries, options);
  columnSeries.columns.template.tooltipText = getTooltipTextFormat(options.granularity);
  columnSeries.columns.template.width = percent(90);
};

export const legendConfigurationOCE = (theme: ThemeInt) => {
  const legend = legendConfiguration(theme);
  legend.reverseOrder = true;

  const marker = legend.markers.template.children.getIndex(0);

  if (marker) {
    marker.strokeWidth = 0.5;
  }

  legend.markers.template.width = 15;
  legend.markers.template.height = 15;

  return legend;
};

export const oceChartConfiguration = (chart: XYChart) => {
  chart.zoomOutButton.disabled = true;
  chart.fontSize = "var(--regular_font_size)";
  chart.numberFormatter.numberFormat = "#.";
  chart.paddingLeft = 0;
  chart.paddingBottom = 0;
  chart.fontFamily = "nissan";
  chart.numberFormatter.bigNumberPrefixes = BIG_NUMBER_ABBREVIATION;
  if (chart.preloader) chart.preloader.disabled = true;
};
